import React, { useState } from 'react';
import styles from './main.module.css';
import { Navbar } from '../../components/main/navbar'
import { OrderList } from '../../components/main/order-list'
import { Filter } from '../../components/main/filter'
import { MapContainer } from '../../components/shared/map-container'
import { useMapContainerWidth } from '../../hooks/useMapContainerWidthHeight';
import { InitialMain } from './initial-main';
import { useEffect } from 'react';
import { Monitoring } from '../../components/main/moitoring';
import NewOrder from "../../Entries/Main/components/NewOrder";
import ViewOrder from "../../Entries/Main/components/ViewOrder";
import EditOrder from "../../Entries/Main/components/EditPickup&DropOff";
import AdvancedAssaigning from "../../Entries/Main/components/AdvancedAssaigning/Driver";
import Voip from "../../Entries/Voip";
import { useDispatch, useSelector } from 'react-redux';
import { DriverList } from "../../components/main/driver-list";
import MapNewOrderPin from '../../components/shared/Map-NewOrder-Select-pin';
import MapEditOrderPin from '../../components/shared/Map-EditOrder-Select-pin';
import { MapNewOrderPinOnMap } from '../../components/shared/Map-NewOrder-pin-onMap';
import { MapViewOrderPinOnMap } from '../../components/shared/Map-ViewOrder-pin-onMap';
import { MapEditOrderPinOnMap } from '../../components/shared/Map-EditOrder-pin-onMap';
import { MapTrackDriverPinOnMap } from '../../components/shared/Map-TrackDriver-pin-onMap';
import { MapOrderRouteOnMap } from '../../components/shared/Map-Order-Route-OnMap';
import EstimatedRoutePanel from '../../components/shared/Map-Estimated-Route-Panel';
import New_Fav_SelectedGenerator from '../../components/shared/Map-New-Fav-SelectedGenerator';
import New_Event_SelectedGenerator from '../../components/shared/Map-New-Event-SelectedGenerator';
import { MapCircleRipple } from '../../components/shared/Map-CircleRipple';
import { MapNewEventPinOnMap } from '../../components/shared/Map-New-Events-Pins-On-Map';
import { ErrorHandling } from '../../components/shared/error-handling';
import { useMapContainerHeight } from '../../hooks/useMapContainerHeight';
import { useCSSTOTEXT } from '../../hooks/useCSSTOTEXT';
import { ViewOrderMonitoring } from '../../components/view-order/monitoring';
import { ResetNewOrder } from '../../Redux/actions/actionNames';

export function MainLayout(props) {
    // const MapContainerWidth = useMapContainerWidth();
    let { MapContainerHeight, hideNavbar } = useMapContainerHeight();
    let CSSTOTEXT = useCSSTOTEXT()

    const [loaded, setLoaded] = useState(false);
    const AdvancedAssaigningOpenDialoge = useSelector((state) => state.AdvancedAssaigning.OpenDialoge)
    const mainLoaded = useSelector((state) => state.SharedData.mainLoaded)
    const dispatch = useDispatch();

    useEffect(() => {
       !mainLoaded && InitialMain((call) => {
            setLoaded(call);
        })
    }, [mainLoaded])


    useEffect(() => {
        dispatch(ResetNewOrder())
      }, []);

    if (loaded || mainLoaded) {
        return <>
            <div className={styles.mapListContainer}>
                <div className={styles.FilterOrderListContainer}>
                    <Filter />
                    <OrderList />
                </div>
                <MapNewOrderPin />
                <MapEditOrderPin />
                {/* <EstimatedRoutePanel /> */}
                <New_Fav_SelectedGenerator />
                <New_Event_SelectedGenerator />
                {/* <MapContainer style={{ width: MapContainerWidth, height: MapContainerHeight, zIndex: "6" }} className={styles.mainMapContainer} onCenterChange={mapOnCenterChange}>
                    <Monitoring />
                    <MapNewOrderPinOnMap />
                    <MapViewOrderPinOnMap />
                    <MapEditOrderPinOnMap />
                    <MapTrackDriverPinOnMap />
                    <MapOrderRouteOnMap />
                    <MapCircleRipple />
                    <MapNewEventPinOnMap />
                </MapContainer> */}
                <NewOrder />
                {/* <ViewOrder />
                <EditOrder /> */}

                {/* <DriverList /> */}

                {/* {AdvancedAssaigningOpenDialoge &&
                    <AdvancedAssaigning />
                } */}
                <Voip />
                
                 {/* <div className={styles.monitoringContainer} style={{ [`${CSSTOTEXT["--main-inverseDefaultFloat"]}`]: "24px" }}>
                    <ViewOrderMonitoring />
                </div> */}

            </div>
            {props.children}
            {/* <ErrorHandling /> */}
        </>
    } else {
        return <div style={{ float: "left", marginLeft: "calc(50vw - 40px)", marginTop: 'calc(50vh - 40px)' }}>
            <div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>


    }


}