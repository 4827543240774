import { UpdateTravelInList } from "../Redux/actions/actionNames";
import { store } from '../Redux/store';


export const UpdateOrdersDriverList = async (socketOrder) => {
  let storeData = store.getState();

  let Orders = storeData.DriversList.OrderList

  if(Orders[socketOrder.id]){
    store.dispatch(
      UpdateTravelInList({
        id: socketOrder.id,
        data: {status : socketOrder.status},
        full: false,
      })
    );
  }
};
