import { SetDriverListData, SetDriverListLoading } from "../../../../../../Redux/actions/actionNames";
import { store } from "../../../../../../Redux/store";
import { DriverApi } from "../../../../../../service/API/driver";

const _ = require("lodash");

export const getDriverList = async (list, loadMore ,update) => {
  store.dispatch(SetDriverListLoading(true))
  let storeData = store.getState();

  
  let regexText = storeData.DriversList.regex
  let isOnline = storeData.DriversList.isOnline
  let lastElement = _.last(list);

     let params = {
        cursor: "lastOnlineTime",
        cursorSort: "ascending",
        size : update ? (storeData.DriversList.Driverlist.drivers.length > 30 ? storeData.DriversList.Driverlist.drivers.length : 30) : 30 ,
        isOnline: regexText.length > 0 ? null : isOnline ,
        regexText : regexText.length > 0 ?regexText: null ,
        lastCursor : (loadMore && lastElement) ? lastElement.lastOnlineTime : null
     }

     let Driver = new DriverApi()
     let data = await Driver.List(removeNull(params))
     if(data){
      store.dispatch(SetDriverListData(data.data,loadMore))
      store.dispatch(SetDriverListLoading(false))
     }else{
      store.dispatch(SetDriverListLoading(false))
     }

  // let lastElement = _.last(Listdata);
  // let storeData = store.getState();

  // let selectedStatus = JSON.parse(storeData.OrderList.selectedStatus)
  // let selectedServices = JSON.parse(storeData.OrderList.selectedServices)
  // let selectedVehicleType = JSON.parse(storeData.OrderList.selectedVehicleType)
  // let selectedZone = JSON.parse(storeData.OrderList.zones)

  // let status =  FilterStatusByCategory(categoryName , selectedStatus)

  // if(selectedStatus.length > 0 && status == 0 ){
  //   store.dispatch(SetCategoryOrderListData({ key: categoryName, loadMore, dataLength: 0,orders:[],nextCursor:null,endCursor:null , startCursor:null}))
  // }else{
  //   store.dispatch(SetCategoryOrderListLoading({ key: categoryName, loading: true, error: false }))

  //    let params = {
  //   cursor: storeData.OrderList.cursor,
  //   cursorSort: storeData.OrderList.cursorSort,
  //   "dueDate[start]": storeData.OrderList.DateRange
  //     ? momentJalaali(storeData.OrderList.DateRange.startDate).unix() + "000"
  //     : null,
  //   "dueDate[end]": storeData.OrderList.DateRange
  //     ? (storeData.OrderList.DateRange.endDate ? momentJalaali(storeData.OrderList.DateRange.endDate).unix() + "000" : null)
  //     : null,
  //   services: selectedServices,
  //   zones : selectedZone,
  //   status: status ,
  //   vehicleTypes: selectedVehicleType,
  //   isScheduled: ((storeData.OrderList.now && storeData.OrderList.isScheduled) || (!storeData.OrderList.now && !storeData.OrderList.isScheduled) ) ? null :  (   (storeData.OrderList.now && !storeData.OrderList.isScheduled ) ? false :  true),
  //   size: 4,
  //   regexText: storeData.OrderList.search.regexText,
  //   regexFields: String(storeData.OrderList.search.regexText).length > 0 ? storeData.OrderList.search.regexFields.value : [],
  //   category: categoryName,
  //   type: storeData.OrderList.ActiveOrderType,
  //   lastCursor: lastElement ? lastElement[storeData.OrderList.cursor] : null,
  //   ...patch
  // }

  // if (String(params.regexText).length > 2) {
  //   store.dispatch(SetSearchOrderListRegexRequested(true))
  //   store.dispatch(SetSearchOrderListRegexLoading(true))
  // }
  // let Order = new OrderApi()
  // let data = await Order.OrdersList(removeNull(params))
  // store.dispatch(SetCategoryOrderListData({ key: categoryName, loadMore, dataLength: data.orders.length, ...data }))
  // store.dispatch(SetCategoryOrderListLoading({ key: categoryName, loading: false, error: false }))
  // if (String(params.regexText).length > 2) {
  //   store.dispatch(SetSearchOrderListRegexLoading(false))
  // }
  // }


 

};


const removeNull = (objIn) => {
  let objOut = objIn;
  Object.keys(objOut).forEach(key => {
    if (objOut[key] === null) {
      delete objOut[key];
    }
  });
  return objOut
}
