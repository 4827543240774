import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import add1 from "../../../../../../assets/ic1.svg";
import add2 from "../../../../../../assets/icon-ic-notes1.svg";
import add3 from "../../../../../../assets/icon-ic-person.svg";
import { Set_DropOff_Inputs, Set_latlng, Set_Pickup_Inputs ,ChooseoOnMapimg, SetopenAddressBookInNewOrder } from "../../../../../../Redux/actions/actionNames";
import note from "../../../../../../assets/ic-note-for-driver.svg";
import email from "../../../../../../assets/ic_person.svg";
import { getCenterMap, setCenterMap } from "../../../../../../helper/module";



var _ = require("lodash");

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.anchorEl = React.createRef();
  }

  showPoper = () => {
    this.setState({ showpoper: true });
  };

  setAddressToPD = () => {

    setCenterMap(this.props.mapRef , {lng: this.props.AddressData.coordinates[0], lat: this.props.AddressData.coordinates[1]})

    try {
      getCenterMap(this.props.mapRef , {lng: this.props.AddressData.coordinates[0], lat: this.props.AddressData.coordinates[1]})
      this.props.dispatch(
        Set_latlng({
          lng: this.props.AddressData.coordinates[0],
          lat: this.props.AddressData.coordinates[1],
        })
      );
      // this.props.mapRef.current.leafletElement.setView({lng: this.props.AddressData.coordinates[0], lat: this.props.AddressData.coordinates[1]}, 17);
    } catch (error) {
      this.props.dispatch(
        Set_latlng({
          lng: this.props.AddressData.coordinates[0],
          lat: this.props.AddressData.coordinates[1],
        })
      );
    }

    if (this.props.sourceOfAddressBookInNewOrder.type == "dropoff") {


      this.props.dispatch(
        Set_DropOff_Inputs({
          name: "address",
          value: this.props.AddressData.address,
          firstDropOff: this.props.sourceOfAddressBookInNewOrder.id == "d123",
          id: this.props.sourceOfAddressBookInNewOrder.id,
        })
      );

      this.props.dispatch(
        Set_DropOff_Inputs({
          name: "adresss_loc",
          value:  {lng: this.props.AddressData.coordinates[0], lat: this.props.AddressData.coordinates[1]} ,
          firstDropOff: this.props.sourceOfAddressBookInNewOrder.id == "d123",
          id: this.props.sourceOfAddressBookInNewOrder.id,
        })
      );

      this.props.dispatch(
        Set_DropOff_Inputs({
          name: "block",
          value: this.props.AddressData.buildingBlock,
          firstDropOff: this.props.sourceOfAddressBookInNewOrder.id == "d123",
          id: this.props.sourceOfAddressBookInNewOrder.id,
        })
      );


      this.props.dispatch(
        Set_DropOff_Inputs({
          name: "floor",
          value: this.props.AddressData.floor,
          firstDropOff: this.props.sourceOfAddressBookInNewOrder.id == "d123",
          id: this.props.sourceOfAddressBookInNewOrder.id,
        })
      );

      this.props.dispatch(
        Set_DropOff_Inputs({
          name: "recieverFullName",
          value: this.props.AddressData.fullName,
          firstDropOff: this.props.sourceOfAddressBookInNewOrder.id == "d123",
          id: this.props.sourceOfAddressBookInNewOrder.id,
        })
      );


      this.props.dispatch(
        Set_DropOff_Inputs({
          name: "unit",
          value: this.props.AddressData.room,
          firstDropOff: this.props.sourceOfAddressBookInNewOrder.id == "d123",
          id: this.props.sourceOfAddressBookInNewOrder.id,
        })
      );

      this.props.dispatch(
        Set_DropOff_Inputs({
          name: "recieverPhoneNumber",
          value: (this.props.AddressData.phone && this.props.AddressData.phone.length > 0) ? (String(this.props.AddressData.phone).startsWith("00") ? 
          "+"+String(this.props.AddressData.phone).slice(2, this.props.AddressData.phone.length) : String(this.props.AddressData.phone) ) : "",
          firstDropOff: this.props.sourceOfAddressBookInNewOrder.id == "d123",
          id: this.props.sourceOfAddressBookInNewOrder.id,
        })
      );


      this.props.dispatch(
        Set_DropOff_Inputs({
          name: "noteForDriver",
          value: this.props.AddressData.description,
          firstDropOff: this.props.sourceOfAddressBookInNewOrder.id == "d123",
          id: this.props.sourceOfAddressBookInNewOrder.id,
        })
      );



      this.props.dispatch(
        Set_DropOff_Inputs({
          name: "email",
          value: this.props.AddressData.email,
          firstDropOff: this.props.sourceOfAddressBookInNewOrder.id == "d123",
          id: this.props.sourceOfAddressBookInNewOrder.id,
        })
      );





    } else {


      this.props.dispatch(
        Set_Pickup_Inputs({
          name: "address",
          value: this.props.AddressData.address,
        })
      );


      this.props.dispatch(
        Set_Pickup_Inputs({
          name: "adresss_loc",
          value:  {lng: this.props.AddressData.coordinates[0], lat: this.props.AddressData.coordinates[1]} ,
        })
      );

      this.props.dispatch(
        Set_Pickup_Inputs({
          name: "block",
          value: this.props.AddressData.buildingBlock,
        })
      );

      this.props.dispatch(
        Set_Pickup_Inputs({
          name: "floor",
          value: this.props.AddressData.floor,
        })
      );

      this.props.dispatch(
        Set_Pickup_Inputs({
          name: "senderFullName",
          value: this.props.AddressData.fullName,
        })
      );


      this.props.dispatch(
        Set_Pickup_Inputs({
          name: "senderPhoneNumber",
          value: (this.props.AddressData.phone && this.props.AddressData.phone.length > 0) ? (String(this.props.AddressData.phone).startsWith("00") ? 
          "+"+String(this.props.AddressData.phone).slice(2, this.props.AddressData.phone.length) : String(this.props.AddressData.phone) ) : "",
        })
      );

      this.props.dispatch(
        Set_Pickup_Inputs({
          name: "unit",
          value: this.props.AddressData.room,
        })
      );

      this.props.dispatch(
        Set_Pickup_Inputs({
          name: "noteForDriver",
          value: this.props.AddressData.description,
        })
      );
      this.props.dispatch(
        Set_Pickup_Inputs({
          name: "email",
          value: this.props.AddressData.email,
        })
      );


      
    }
    this.props.dispatch(SetopenAddressBookInNewOrder(false, "", null, ""));

  };


  getBlockFloorRoom =()=>{
    if(this.props.AddressData.buildingBlock.length > 0 || this.props.AddressData.room.length > 0 || this.props.AddressData.floor.length > 0){
      let arr=[];
      this.props.AddressData.buildingBlock.length > 0 && arr.push(this.props.AddressData.buildingBlock)
      this.props.AddressData.room.length > 0 && arr.push(this.props.AddressData.room)
      this.props.AddressData.floor.length > 0 && arr.push(this.props.AddressData.floor)


      return arr.join(" , ")
    }else {
      return false
    }
  }

  getNamePhone =()=>{
    if(this.props.AddressData.phone.length > 0 || this.props.AddressData.fullName.length > 0 ){

      let arr=[];

      this.props.AddressData.phone.length > 0 && arr.push(this.props.AddressData.phone)
      this.props.AddressData.fullName.length > 0 && arr.push(this.props.AddressData.fullName)

      return arr.join(" , ")
    }else {
      return false
    }
  }


  getNote = () => {
    if (
      this.props.AddressData.description.length > 0 
    ) {
      return this.props.AddressData.description
    } else {
      return false;
    }
  };


  getEmail= () => {
    if (
      this.props.AddressData.email.length > 0 
    ) {
      return this.props.AddressData.email
    } else {
      return false;
    }
  };



  render() {
    return (
      <div className="addressItemMenuContainer" onClick={this.setAddressToPD}>
        <span className="admcT"> {this.props.AddressData.title}</span>
       {(this.props.AddressData.isDefaultPickupAddress) && <span className="admcDFP"> Default pickup</span>}
        {/* <span className="admcMM" onClick={this.showPoper} ref={this.anchorEl}>
          {" "}
          ...
        </span> */}

        <div className="admcI">
          <img src={add1} />
          <span> {this.props.AddressData.address}</span>
        </div>

        {this.getBlockFloorRoom() && <div className="admcI">
          <img src={add2} />
          <span> {this.getBlockFloorRoom()}</span>
        </div>}

       {this.getNamePhone() && <div className="admcI">
          <img src={add3} />
          <span> {this.getNamePhone()}</span>
        </div>}

        {this.getNote() && (
          <div className="admcI">
            <img src={note} />
            <span> {this.getNote()}</span>
          </div>
        )}

      {this.getEmail() && (
          <div className="admcI">
            <img src={email} />
            <span> {this.getEmail()}</span>
          </div>
        )}

      </div>
    );
  }
}

const mapStateToProps = (state) => ({
 mapRef: state.Map.mapRef,
  center: state.Map.center,
  sourceOfAddressBookInNewOrder:
  state.Addressbook.sourceOfAddressBookInNewOrder,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
