import React, { useLayoutEffect, useRef, useState } from "react";
import { Map, Marker, TileLayer, Popup, Polyline } from "react-leaflet";
import L from "leaflet";
import Pin from "../Pin";
import ReactDOMServer from "react-dom/server";
import styles from './style.module.css';
import { GetColorByStatus } from "../../../../utils/get-colors-by-status";

const createtIcon = (status,number , numberStyle , color) => {
  return new L.divIcon({
    className: "",
    html: ReactDOMServer.renderToString(CreateIconSelectedOsm(status,number , numberStyle , color)),
    iconSize: [40, 41],
    iconAnchor: [0, 0],
  });
};



const CreateIconSelectedOsm = (status,number,numberStyle , color) => {
  return <Pin color={color ? color : "#"+GetColorByStatus(status)} bigSize={true}
  style={{ marginLeft: "-15px", marginTop: "-51px" ,marginBottom:"10px"}} number={number} numberStyle={numberStyle} />

};


const App = ({ position ,status ,number, numberStyle }) => {
  
  return <>
    <Marker
      animate={true}
      position={position}
      icon={createtIcon(status ,number , numberStyle)}
    >

    </Marker>
  </>
};

export default App


