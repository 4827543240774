import kioskenLogo from "../../../assets/kioskenLogo.png";
import FavIcon from "../../../assets/PROJECTS/kiosken.png";

export default function Kiosken () {
    
    return global.config = {
  
    "ProjectName": "kiosken",
    "LogoType": kioskenLogo,
    "BaseApi": "https://rest.kiosken.site",
    "BaseSocket": "https://socket.kiosken.site",
    "BaseTrack": "https://admin.kiosken.site",
    "MapCenter": {
        "lat": "55.6758616265238",
        "lng": "12.579197214577436"
    },
    "Lang": "en",
    "Direction": "ltr",
    "AllLanguages": [{
        "lang": "en",
        "direction": "ltr",
        "i18Lang": "English"
    }],
    "FavIcon": FavIcon,
    "Country": "DK",
    "thirdPartyLoginSupport": true  ,
    mapType :"osm", /* osm | google */ 
    googleApiKey : ""
} }