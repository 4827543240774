import * as React from 'react'
import styles from './style.module.css';
import Button from '../../../design-system/lib/Button';
import { ADDRESS_BOOK, AUTO_DISPATCH, CLOSE, CLOSE_DARK, CUSTOMER_TYPE, DRAG, DROPOFF, HISTORY, INFO_CIRCLE, LOCATION, MAP, PERSON, PICKUP } from '../../../assets';
import DropDown from '../../../design-system/lib/DropDown';
import Modal from '../../../design-system/lib/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { Set_Auto_ActiveVehicleTypeـPickupDeliveryType_Dropoff, Set_Auto_ActiveVehicleTypeـPickupDeliveryType_Pickup, Set_DropOff_Inputs, Set_Pickup_address, Set_SearchAutoCompleteLoading, Set_latlng, SetopenAddressBookInNewOrder } from '../../../Redux/actions/actionNames';
import { getCenterMap, setCenterMap } from '../../../helper/module';
import { PlaceIdDetailsAction, SearchAutoCompleteAction, SearchAutoCompleteAction_local_address } from '../../../Redux/actions/actions';
import { useTranslation } from 'react-i18next';
import Input from '../../../design-system/lib/Input';
import IconProvider from '../../../design-system/lib/IconProvider';
import Typography from '../../../design-system/lib/Typography';
import CustomerListItem from '../customer/components/list-Item';
import SearchList from '../../../design-system/lib/SearchList';
import { v4 as uuidv4 } from 'uuid';
import _, { isEmpty } from "lodash";
import RecentAddressItem from './components/recentAddressItem';
import AddressItem from './components/addressItem';
import DeliveryInfoNewOrder from './components/info';
import clsx from 'clsx';
import removeDropOff from "../../../assets/removeDropOff.svg";
import { UseCRUDDropoffs } from '../../../hooks/useCRUD_Dropoffs';


function DeliveryNewOrder({ handleCollapse, dropOffsCount, isDraggingOver, index, last, first, addDropOff, id, removeDrop, orderTypeActive, dropOffCount, ...props }) {
  let { items, sEtItems } = UseCRUDDropoffs()

  console.log("DeliveryNewOrder ", dropOffsCount, isDraggingOver, index, last, first, id, orderTypeActive, dropOffCount, props)

  const [isLocalAddressActive, setIsLocalAddressActive] = React.useState(global.config.dispatcherSettings.isLocalAddressActive);
  const [localSearchIsActive, setLocalSearchIsActive] = React.useState(global.config.dispatcherSettings.isLocalAddressActive ? true : false);
  // const [id, setid] = React.useState("dropoff");
  const [ordered, setOrdered] = React.useState(true);
  const [showpoper, setShowpoper] = React.useState(false);
  const [showpoperSaved, setShowpoperSaved] = React.useState(false);
  const [getFavAddressLoading, setGetFavAddressLoading] = React.useState(false);
  const [searchAddrees, setSearchAddrees] = React.useState("");
  const [sessionToken, setSessionToken] = React.useState(null);
  const BusinessId = useSelector((state) => state.NewOrder.newOrder.customer.BusinessId)

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const customerId = useSelector((state) => state.NewOrder.newOrder.customer.id)
  const mapRef = useSelector((state) => state.Map.mapRef)
  const center = useSelector((state) => state.Map.center)
  const adresss_loc = props.adresss_loc;
  const emailg = props.email
  const senderFullName = props.recieverFullName
  const senderPhoneNumber = props.recieverPhoneNumber
  const activePickupDropOff = useSelector((state) => state.NewOrder.newOrder.activePickupDropOff)
  const suggestListPickUpDropOffLoading = useSelector((state) => state.NewOrder.newOrder.suggestListPickUpDropOffLoading)
  const suggestPickUpDropOffListData = useSelector((state) => state.NewOrder.newOrder.suggestPickUpDropOffListData)
  const activeVehicleType = useSelector((state) => state.NewOrder.newOrder.service.activeVehicleType)
  const recentAddress = useSelector((state) => state.NewOrder.newOrder.customer.recentAddreess)

  const auto_activeVehicleType = props.driver.auto_driver.auto_activeVehicleType
  const manual_activeVehicleType = props.driver.manual_driver.manual_activeVehicleType
  const driveroptions = props.driver.options

  const delayedQuery = React.useCallback(_.debounce((...pr) => HandleChange(...pr), 500), []);
  const auto_suggestListData = useSelector((state) => state.NewOrder.newOrder.driver.auto_driver.auto_suggestListData)
  const openAddressBook = useSelector((state) => state.Addressbook.openAddressBookInNewOrder)
  const addressBookSource = useSelector((state) => state.Addressbook.openAddressBookInNewOrder
    ? state.Addressbook.sourceOfAddressBookInNewOrder
      ? state.Addressbook.sourceOfAddressBookInNewOrder.type == "dropoff" &&
      id == state.Addressbook.sourceOfAddressBookInNewOrder.id
      : false
    : false)

    const delivery_manual_id = useSelector((state) => state.NewOrder.newOrder.dropOffs.firstDropOff.driver.manual_driver.manual_id)


  const HandleChange = React.useCallback((value, localSearchIsActive, sessionToken) => {

    let centerr = getCenterMap(mapRef, center)
    String(value).length > 0 && dispatch(Set_SearchAutoCompleteLoading(true));

    console.log("localSearchIsActive ", localSearchIsActive)
    if (!localSearchIsActive) {
      String(value).length > 0 && dispatch(
        SearchAutoCompleteAction(dispatch, {
          query: value,
          latitude: centerr.lat,
          longitude: centerr.lng,
          sessionToken: sessionToken
        })
      );
    } else {
      String(value).length > 0 && dispatch(
        SearchAutoCompleteAction_local_address(dispatch, {
          query: value
        })
      );
    }


  }, [localSearchIsActive, sessionToken]);


  const handlePickUpInputs = React.useCallback((e, name) => {

    if (name === "address" || e?.target.id === "address") {

      setSessionToken(sessionToken ? sessionToken : uuidv4())



      // dispatch(
      //   Set_DropOff_Inputs({
      //     name: "adresss_loc",
      //     value: { lng: "", lat: "" },
      //     firstDropOff: first,
      //     id: id == "dropoff" ? "dropoff" : id,
      //   })
      // );
      // dispatch(
      //   Set_DropOff_Inputs({
      //     name: "placeId",
      //     value: "",
      //     firstDropOff: first,
      //     id: id == "dropoff" ? "dropoff" : id,
      //   })
      // );
      // console.log("dwrwewdfwee",e)


      if (e && e?.target.value != "") {
        e?.target?.value.length > 2 && delayedQuery(e?.target.value, localSearchIsActive, sessionToken)
      }

    }

    if(e){
        dispatch(
      Set_DropOff_Inputs({
        name: name || e?.target.id,
        value: e?.target.value,
        firstDropOff: first,
        id: id == "dropoff" ? "dropoff" : id,
      })
    );
    }
  
  }, [sessionToken, localSearchIsActive, id, first]);

  const handleChangeLocalSearch = React.useCallback((value) => {

    setLocalSearchIsActive(value == t("Map") ? false : true)
    // setTimeout(() => {
    //   notThrottleHandleChange(String(address), sessionToken)
    // }, 200);

  }, [localSearchIsActive, sessionToken, props.address]);

  const notThrottleHandleChange = React.useCallback((value) => {

    let centerrr = getCenterMap(mapRef, center)
    String(value).length > 0 && dispatch(Set_SearchAutoCompleteLoading(true));



    if (!localSearchIsActive) {
      String(value).length > 0 && dispatch(
        SearchAutoCompleteAction(dispatch, {
          query: value,
          latitude: centerrr.lat,
          longitude: centerrr.lng,
          sessionToken: sessionToken
        })
      );
    } else {
      String(value).length > 0 && dispatch(
        SearchAutoCompleteAction_local_address(dispatch, {
          query: value
        })
      );
    }

  }, [localSearchIsActive, sessionToken]);


  React.useEffect(() => {
    notThrottleHandleChange(String(props.address), sessionToken)
  }, [localSearchIsActive])


  const serachListData = React.useCallback(() => {

    if (_.isEmpty(props.address)) {
      if (_.isEmpty(customerId)) {
        return null //error customer
      } else {
        if (_.isEmpty(recentAddress)) {
          return null //error recentAddress
        } else {
          return recentAddress
        }
      }
    } else {
      if (_.isEmpty(suggestPickUpDropOffListData)) {
        return null //error suggestPickUpDropOffListData
      } else {
        return suggestPickUpDropOffListData
      }
    }


  }, [recentAddress, customerId, props.address, suggestPickUpDropOffListData]);


  const GetserachListItem = React.useCallback(() => {

    if (_.isEmpty(props.address)) {
      return RecentAddressItem
    } else {
      return AddressItem
    }


  }, [recentAddress, customerId, props.address, suggestPickUpDropOffListData]);


  const GetserachListItemOnclick = React.useCallback(() => {

    if (_.isEmpty(props.address)) {
      return handleRecentList
    } else {
      return handleList
    }


  }, [recentAddress, customerId, props.address, suggestPickUpDropOffListData]);


  const GetSearchListError = React.useCallback(() => {

    //no recent address
    if (recentAddress.length == 0 && !_.isEmpty(customerId) && _.isEmpty(props.address)) {
      return {
        errorDescription: t("NoRecentAddressD"),
        errorTitle: t("NoRecentAddress"),
        errorIcon: HISTORY,
      }
    }

    //CustomerIsNotChoosed

    if (_.isEmpty(customerId) && _.isEmpty(props.address)) {
      return {
        errorDescription: t("CustomerIsNotChoosedD"),
        errorTitle: t("CustomerIsNotChoosed"),
        errorIcon: CUSTOMER_TYPE,
      }
    }



    if ((!suggestListPickUpDropOffLoading &&
      suggestPickUpDropOffListData.length === 0 && !_.isEmpty(props.address)) || (!_.isEmpty(props.address) && !suggestListPickUpDropOffLoading && !suggestPickUpDropOffListData)) {
      return {
        errorDescription: t("NoResultfromLocalDatabaseD"),
        errorTitle: t(localSearchIsActive ? "NoResultfromLocalDatabase" : "NoResultfromMapService"),
        errorIcon: null,
      }
    }

  }, [recentAddress, customerId, props.address, suggestPickUpDropOffListData, suggestListPickUpDropOffLoading, localSearchIsActive]);

  const handleRecentList = React.useCallback((item) => {

    dispatch(
      Set_latlng({ lng: item.coordinates[0], lat: item.coordinates[1] })
    );

    dispatch(Set_DropOff_Inputs({
      name: "address",
      value: item.address,
      firstDropOff: first,
      id: id == "dropoff" ? "dropoff" : id,
    }))
    dispatch(Set_DropOff_Inputs({
      name: "adresss_loc",
      value: { lng: item.coordinates[0], lat: item.coordinates[1] },
      firstDropOff: first,
      id: id == "dropoff" ? "dropoff" : id,
    }))
    dispatch(Set_DropOff_Inputs({
      name: "block",
      value: item.buildingBlock,
      firstDropOff: first,
      id: id == "dropoff" ? "dropoff" : id,
    }))
    dispatch(Set_DropOff_Inputs({
      name: "floor",
      value: item.floor,
      firstDropOff: first,
      id: id == "dropoff" ? "dropoff" : id,
    }))

    dispatch(Set_DropOff_Inputs({
      name: "unit",
      value: item.room,
      firstDropOff: first,
      id: id == "dropoff" ? "dropoff" : id,
    }))
    mapSetView({
      lng: item.coordinates[0],
      lat: item.coordinates[1],
    });

    handleShowInfo()

  }, [first, id]);

  const mapSetView = (e) => {
    setCenterMap(mapRef, e)
  };
  const handleList = React.useCallback((item) => {


    if (item.coordinates) {

      dispatch(
        Set_latlng({ lng: item.coordinates[0], lat: item.coordinates[1] })
      );

      dispatch(
        Set_DropOff_Inputs({
          name: "address",
          value: item.secondaryAddress
            ? item.address + " , " + item.secondaryAddress
            : item.address,
          firstDropOff: first,
          id: id == "dropoff" ? "dropoff" : id,
        })
      );

      dispatch(
        Set_DropOff_Inputs({
          name: "adresss_loc",
          value: {
            lng: item.coordinates[0],
            lat: item.coordinates[1],
          },
          firstDropOff: first,
          id: id == "dropoff" ? "dropoff" : id,
        })
      );


      mapSetView({
        lng: item.coordinates[0],
        lat: item.coordinates[1],
      });

      setSessionToken(null)

      handleShowInfo()

    } else {

      let ss = sessionToken ? sessionToken : uuidv4()
      setSessionToken(ss)


      dispatch(
        PlaceIdDetailsAction(dispatch, { placeId: item.placeId, sessionToken: ss }, (res) => {

          setSessionToken(null)


          dispatch(
            Set_latlng({ lng: res.coordinates[0], lat: res.coordinates[1] })
          );

          dispatch(
            Set_DropOff_Inputs({
              name: "address",
              value: res.address,
              firstDropOff: first,
              id: id == "dropoff" ? "dropoff" : id,
            })
          );
          dispatch(
            Set_DropOff_Inputs({
              name: "adresss_loc",
              value: {
                lng: res.coordinates[0],
                lat: res.coordinates[1],
              },
              firstDropOff: first,
              id: id == "dropoff" ? "dropoff" : id,
            })
          );
          dispatch(
            Set_latlng({
              lng: res.coordinates[0],
              lat: res.coordinates[1],
            })
          );
        })
      );

      handleShowInfo()
    }
  }, [first, id]);

  const resetPickup = React.useCallback(() => {


    if (first && adresss_loc.lat != "" && dropOffCount > 1) {

      dispatch(
        Set_DropOff_Inputs({
          name: "address",
          value: items[1].address,
          firstDropOff: first,
          id: id == "dropoff" ? "dropoff" : id,
        })
      );
      dispatch(
        Set_DropOff_Inputs({
          name: "adresss_loc",
          value: items[1].adresss_loc,
          firstDropOff: first,
          id: id == "dropoff" ? "dropoff" : id,
        })
      );
      dispatch(
        Set_DropOff_Inputs({
          name: "placeId",
          value: items[1].placeId,
          firstDropOff: first,
          id: id == "dropoff" ? "dropoff" : id,
        })
      );



      dispatch(
        Set_DropOff_Inputs({
          name: "block",
          value: items[1].block,
          firstDropOff: first,
          id: id == "dropoff" ? "dropoff" : id,
        })
      );
      dispatch(
        Set_DropOff_Inputs({
          name: "driver",
          value: items[1].driver,
          firstDropOff: first,
          id: id == "dropoff" ? "dropoff" : id,
        })
      );
      dispatch(
        Set_DropOff_Inputs({
          name: "email",
          value: items[1].email,
          firstDropOff: first,
          id: id == "dropoff" ? "dropoff" : id,
        })
      );
      dispatch(
        Set_DropOff_Inputs({
          name: "floor",
          value: items[1].floor,
          firstDropOff: first,
          id: id == "dropoff" ? "dropoff" : id,
        })
      );

      dispatch(
        Set_DropOff_Inputs({
          name: "recieverFullName",
          value: items[1].recieverFullName,
          firstDropOff: first,
          id: id == "dropoff" ? "dropoff" : id,
        })
      );

      dispatch(
        Set_DropOff_Inputs({
          name: "recieverPhoneNumber",
          value: items[1].recieverPhoneNumber,
          firstDropOff: first,
          id: id == "dropoff" ? "dropoff" : id,
        })
      );

      dispatch(
        Set_DropOff_Inputs({
          name: "scheduleDateAfter",
          value: items[1].scheduleDateAfter,
          firstDropOff: first,
          id: id == "dropoff" ? "dropoff" : id,
        })
      );


      dispatch(
        Set_DropOff_Inputs({
          name: "scheduleDateBefore",
          value: items[1].scheduleDateBefore,
          firstDropOff: first,
          id: id == "dropoff" ? "dropoff" : id,
        })
      );

      dispatch(
        Set_DropOff_Inputs({
          name: "unit",
          value: items[1].unit,
          firstDropOff: first,
          id: id == "dropoff" ? "dropoff" : id,
        })
      );


      handleCollapse({
        type: null,
        id: ""
      });

      removeDrop(items[1].id)
    } else {

      dispatch(
        Set_DropOff_Inputs({
          name: "address",
          value: "",
          firstDropOff: first,
          id: id == "dropoff" ? "dropoff" : id,
        })
      );
      dispatch(
        Set_DropOff_Inputs({
          name: "adresss_loc",
          value: { lng: "", lat: "" },
          firstDropOff: first,
          id: id == "dropoff" ? "dropoff" : id,
        })
      );
      dispatch(
        Set_DropOff_Inputs({
          name: "placeId",
          value: "",
          firstDropOff: first,
          id: id == "dropoff" ? "dropoff" : id,
        })
      );


      handleCollapse({
        type: null,
        id: ""
      });
    }


  }, [first, id, items, adresss_loc, dropOffCount]);



  const handleShowInfo = React.useCallback(() => {

    const isOpen = activePickupDropOff.id == id

    const myEvent = new CustomEvent("closeSliders", { detail: null });
    document.dispatchEvent(myEvent);


    if (!isOpen) {
      handleCollapse({
        type: "dropoff",
        id: id,
        firstDropOff: first
      });
    } else {
      setTimeout(() => {
        handleCollapse({
          type: null,
          id: ""
        });
      }, 100);
    }

  }, [first, id, activePickupDropOff]);




  const emailValidate = (email) => {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }



  const vehicleRequiredCheck = React.useCallback(() => {

    if (orderTypeActive == "PickupDelivery") {


      let activeVehicleType = delivery_manual_id != "" && delivery_manual_id != null ? manual_activeVehicleType  : auto_activeVehicleType ;

      if (activeVehicleType) {
        if (activeVehicleType.options.length == 0) {
          return true
        } else {
          let isNotExist = false;
          let arrFiltered = activeVehicleType.options.filter((item) => item.isRequired);
          arrFiltered.forEach(element => {
            if (!VehicleOptionisSaved(element.id)) {
              isNotExist = true;
            }
          });
          return isNotExist == false
        }
      } else {
        return true
      }
    } else {
      return true
    }

  }, [props.driver, auto_activeVehicleType, manual_activeVehicleType, driveroptions, orderTypeActive]);


  const VehicleOptionisSaved = React.useCallback((id) => {
    console.log("VehicleOptionisSaved  ", id, driveroptions)

    let booll = false;
    booll = driveroptions.find((item) => {
      return item.id == id;
    });
    if (booll) {
      return true
    } else {
      return false
    }
  }, [driveroptions]);


  const pickupRequiredCheck = React.useCallback(() => {
    if (activeVehicleType) {
      let name = false, email = false, phone = false;

      if (activeVehicleType.setting.isDropoffAddressNameRequired) {
        if (senderFullName && String(senderFullName).trim().length > 0) {
          name = true
        } else {
          name = false
        }
      } else {
        name = true
      }

      if (activeVehicleType.setting.isDropoffAddressPhoneRequired) {
        if (senderPhoneNumber && String(senderPhoneNumber).trim().length > 0) {
          phone = true
        } else {
          phone = false
        }
      } else {
        phone = true
      }


      if (activeVehicleType.setting.isDropoffAddressEmailRequired) {
        if (emailg && String(emailg).length > 0 && emailValidate(String(emailg))) {
          email = true
        } else {
          email = false
        }
      } else {
        email = true
      }



      return (name && phone && email);

    } else {
      return false
    }

  }, [activeVehicleType, emailg, senderPhoneNumber, senderFullName]);


  const handleOpenAddressbook = React.useCallback(() => {

    const myEvent = new CustomEvent("closeSliders", { detail: null });
    document.dispatchEvent(myEvent);

    setTimeout(() => {
      if (customerId || BusinessId) {
        if (openAddressBook && addressBookSource) {
          dispatch(SetopenAddressBookInNewOrder(false, "", null, ""));
        } else {
          dispatch(
            SetopenAddressBookInNewOrder(
              true,
              "dropoff",
              id,
              "AddressbookDropOf"
            )
          );
        }
      }
    },
      100);
  }, [customerId, BusinessId, openAddressBook, addressBookSource, id]);


  // const handleChangeLocalSearch = React.useCallback((value) => {


  // }, []);


  React.useEffect(() => {

    if (!auto_activeVehicleType && auto_suggestListData.length > 0) {
      dispatch(Set_Auto_ActiveVehicleTypeـPickupDeliveryType_Dropoff(auto_suggestListData[0]))
    }

  }, [auto_activeVehicleType, auto_suggestListData])



  React.useEffect(() => {

    document.addEventListener('closeSliders', () => {
      //

      handleCollapse({
        type: null,
        id: ""
      });

      //
    })


    return () => {
      document.removeEventListener("closeSliders");
    };

  }, [])

  if (isEmpty(String(adresss_loc.lat))) {
    return <div className={clsx(styles.PickupOnlyNewOrderContainer, styles[orderTypeActive])}>
      {["Ondemand"].indexOf(
        orderTypeActive
      ) > -1 && (
          <hr className={styles.hr} />
        )}

      <div className={styles.PickupOnlyNewOrderContainerFirstRow}>

        {(orderTypeActive == "Ondemand" && dropOffCount > 1) ? <span className={styles.dropNumber} style={{ marginInlineEnd: "2px" }}>{index + 1}</span> : <IconProvider Icon={DROPOFF} size='16' />}

        <Typography text={t(["Ondemand"].indexOf(
          orderTypeActive
        ) > -1 ? "Dropoff" : "Delivery")} weight='Body_Middle_Bold' style={{ lineHeight: "15px" }} />


      </div>

      <div className={styles.PickupOnlyNewOrderContainerSecondRow}>
        <SearchList onChange={(e) => handlePickUpInputs(e, "address")} style={{ zIndex: "1" }} value={props.address} placeHolder={t("SearchAddress")} isloading={suggestListPickUpDropOffLoading} listItemOnclick={GetserachListItemOnclick()} error={GetSearchListError()} errorDescription={GetSearchListError()?.errorDescription} errorTitle={GetSearchListError()?.errorTitle} errorIcon={GetSearchListError()?.errorIcon}
          ListItemChildren={GetserachListItem()} list={serachListData()} inputInnerChildren={isLocalAddressActive ? (<DropDown size='Small' placement='bottomEnd' text={localSearchIsActive ? t("Local") : t("Map")} data={[t("Map"), t("Local")]} onClick={handleChangeLocalSearch} />) : null}
        />
        <Button icon={LOCATION} size='Medium' type='Secondary' style={{ marginInlineEnd: "7px" }} onClick={handleShowInfo} isActive={activePickupDropOff.id == id} />
        <Button icon={ADDRESS_BOOK} size='Medium' type='Secondary' disabled={((customerId && String(customerId).length > 0) || (BusinessId && String(BusinessId).length > 0)) ? false : true} onClick={handleOpenAddressbook} isActive={addressBookSource} />
        {(orderTypeActive == "Ondemand" && !first && dropOffCount > 1) && <Button size={'Small'} icon={CLOSE_DARK} type='Secondary' style={{ marginInlineStart: "4px", backgroundColor: "transparent", padding: "8px 8px" }} onClick={() => (orderTypeActive == "Ondemand" && !first && dropOffCount > 1) ? removeDrop(id) : resetPickup()} />}

      </div>
    </div>
  }

  return (
    <>

      <div className={clsx(styles.PickupOnlyNewOrderSelectedContainer, styles[orderTypeActive])}>
        {["Ondemand"].indexOf(
          orderTypeActive
        ) > -1 && (
            <hr className={styles.hr2} />
          )}
        <div className={styles.PickupOnlyNewOrderSelectedContainerFrow}>
          {dropOffCount > 1 && <IconProvider Icon={DRAG} size='16' style={{ cursor: "pointer", marginInlineEnd: "8px" }} />}

          {(orderTypeActive == "Ondemand" && dropOffCount > 1) ? <span className={styles.dropNumber} style={{ marginInlineEnd: "2px" }}>{index + 1}</span> : <IconProvider Icon={DROPOFF} size='16' />}
          <Typography text={props.address} weight='Body_Small_Regular' style={{
            width: "293px", overflow: 'hidden',
            textOverflow: 'ellipsis', whiteSpace: 'nowrap', flex: "1", lineHeight: "15px"
          }} />
          <Button icon={INFO_CIRCLE} size='Medium' type='Secondary' badge={/* (!vehicleRequiredCheck() || */ !pickupRequiredCheck() && true} onClick={handleShowInfo} isActive={activePickupDropOff.id == id} />
          <Button size={'Small'} icon={CLOSE_DARK} type='Secondary' style={{ marginInlineStart: "4px", backgroundColor: "transparent", padding: "8px 8px" }} onClick={() => (orderTypeActive == "Ondemand" && !first && dropOffCount > 1) ? removeDrop(id) : resetPickup()} />

        </div>


       {( /* !vehicleRequiredCheck() || */ !pickupRequiredCheck()) && <Typography text={t("CompleteRequiredFeileds")} weight='Body_Small_Regular' style={{
          width: "345px", overflow: 'hidden',
          textOverflow: 'ellipsis', whiteSpace: 'nowrap', flex: "1"
        }} textColor='Red' />} 

      </div>

      {activePickupDropOff.id == id && <DeliveryInfoNewOrder {...props} id={id} first={first} handleShowInfo={handleShowInfo} />}
    </>

  )
}

export default DeliveryNewOrder