import { throttle, isEmpty } from 'lodash';
import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';


export function useDriverMonitoringPin(driverId ,id) {
  const [driverIsSelected, setDriverIsSelected] = useState(true);
  const selectedOrderDriver = useSelector((state) => state.Monitoring.selectedOrderDriver)

  const deboucesSetOrderIsSelected = throttle(value => {
    setDriverIsSelected(value)
  }, 40);

  const SetOrderIsSelected = useCallback(value => deboucesSetOrderIsSelected(value), []);


  useEffect(() => {
   if(selectedOrderDriver){
      if(selectedOrderDriver == driverId){
        setDriverIsSelected(true)
      }else if(driverIsSelected){
        setDriverIsSelected(false)
      }
    }else if(!driverIsSelected){
      setDriverIsSelected(true)
    }


  }, [ selectedOrderDriver , driverIsSelected  , driverId])


  return { driverIsSelected };
  
};




/*
import { throttle, isEmpty } from 'lodash';
import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';


export function useMonitoringPin(orderId, orderDriverId ,id) {
  const [orderIsSelected, setOrderIsSelected] = useState(true);
  const [orderPopUp, setOrderPopUp] = useState(false);
  const selectedOrder = useSelector((state) => state.Monitoring.selectedOrder)
  const selectedOrderDriver = useSelector((state) => state.Monitoring.selectedOrderDriver)
  const OrderPinPopUp = useSelector((state) => state.Monitoring.OrderPinPopUp)

  const deboucesSetOrderIsSelected = throttle(value => {
    setOrderIsSelected(value)
  }, 40);
  const deboucesSetOrderPopUp = throttle(value => {
    setOrderPopUp(value)
  }, 40);

  const SetOrderIsSelected = useCallback(value => deboucesSetOrderIsSelected(value), []);
  const SetOrderPopUp = useCallback(value => deboucesSetOrderPopUp(value), []);


  useEffect(() => {
    if (((selectedOrder || selectedOrderDriver) ? (selectedOrder == orderId || selectedOrderDriver == orderDriverId) : true) != orderIsSelected) {
      SetOrderIsSelected((selectedOrder || selectedOrderDriver) ? (selectedOrder == orderId || selectedOrderDriver == orderDriverId) : true)
    }
    if ((OrderPinPopUp == id) != orderPopUp) {
      SetOrderPopUp(OrderPinPopUp == id)
    }
  }, [selectedOrder, selectedOrderDriver,OrderPinPopUp])


  return { orderIsSelected, orderPopUp };
};





*/
