import React from "react";
import { Map, Marker, TileLayer, Popup, Polyline } from "react-leaflet";
import L from "leaflet";
import { connect } from "react-redux";
import ReactDOMServer from "react-dom/server";
import PickupPin from "../../map-pickup-pin/Pin";
import DropOffPin from "../../map-dropoff-pin/Pin";
import { DropOffPinWithNumber } from "../../map-dropoff-pin-with-number";


const createtIconPickup = (color) => {
  return new L.divIcon({
    className: "",
    html: ReactDOMServer.renderToString(<PickupPin color={"#"+color}/>),
    iconSize: [54, 57],
    iconAnchor: [38, 50],
  });
};


const createtIconDropOff = (color) => {
  return new L.divIcon({
    className: "",
    html: ReactDOMServer.renderToString(<DropOffPin color={"#"+color}/>),
    iconSize: [54, 57],
    iconAnchor: [37, 54],
  });
};



class App extends React.Component {
 
  render() {
    let originShow = this.props.pickupAdresss_loc.lng !== "";
    let destinationShow = this.props.dropOffsAdresss_loc.lng !== "";
    let OthersdestinationShow = this.props.otherDropOffs.length > 0;
    return (
      <React.Fragment>
           {originShow ? (
            <Marker
              animate={true}
              position={this.props.pickupAdresss_loc}
              icon={createtIconPickup("1795ff")}

            ></Marker>
          ) : null}
          {destinationShow ? (this.props.otherDropOffs.length == 0 ?
            <Marker
              animate={true}
              position={this.props.dropOffsAdresss_loc}
              icon={createtIconDropOff("0064d2")}

            ></Marker> :   <DropOffPinWithNumber numberStyle={{ position: "absolute", width: "20px", height: "20px", left: "5px", top: "5px", fontSize: "14px", fontWeight: "bold", fontFamily: 'sf_pro', textAlign: "center" }} mapType={this.props.mapType} status={null} 
            position={this.props.dropOffsAdresss_loc} number={1} />
          ) : null}

          {OthersdestinationShow
            ? this.props.otherDropOffs.map((dropoff , i) => {
                return (
                  <DropOffPinWithNumber numberStyle={{ position: "absolute", width: "20px", height: "20px", left: "5px", top: "5px", fontSize: "14px", fontWeight: "bold", fontFamily: 'sf_pro', textAlign: "center" }} mapType={this.props.mapType} status={null} position={dropoff.adresss_loc} number={i + 2 }/>
                );
              })
            : null}
       </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  activePickupDropOff :state.EditOrder.editOrder.activePickupDropOff,
  center:state.Map.center,
  pickupAddress: state.EditOrder.editOrder.pickup.address,
  pickupAdresss_loc: state.EditOrder.editOrder.pickup.adresss_loc,
  dropOffAddress: state.EditOrder.editOrder.dropOffs.firstDropOff.address,
  dropOffsAdresss_loc: state.EditOrder.editOrder.dropOffs.firstDropOff.adresss_loc,
  otherDropOffs: state.EditOrder.editOrder.dropOffs.otherDropOffs,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(mapStateToProps, mapDispatchToProps)(App);
