import React, { useLayoutEffect, useState } from "react";
import { OverlayView } from '@react-google-maps/api';
import { useDispatch, useSelector } from "react-redux";
import styles from './style.module.css';
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import { getFavAddressListAction, OrderInfoAction } from "../../../../Redux/actions/actions";
import { ChangeViewTravelDialoge, SetDriverPopUp, SetOrderPinPopUp, SetOrderPinPopUpPosition, SetSelectedMonitoringDriver, SetSelectedMonitoringOrder } from "../../../../Redux/actions/actionNames";
import { ClickAwayListener } from "@material-ui/core";
import history from "../../../../service/history";



const App = () => {
  const OrderPinPopUp = useSelector((state) => state.Monitoring.OrderPinPopUp)
  const OrderPinPopUpPosition = useSelector((state) => state.Monitoring.OrderPinPopUpPosition)

  if (!OrderPinPopUp || !OrderPinPopUpPosition) {
    return null
  }
  return <CreatePopup OrderPinPopUp={OrderPinPopUp} OrderPinPopUpPosition={OrderPinPopUpPosition} />

};

export default App





const CreatePopup = ({ OrderPinPopUp, OrderPinPopUpPosition }) => {
  const [width, setWidth] = useState(0);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  function OpenOrderDetails(){
    let data= JSON.parse(JSON.stringify(OrderPinPopUpPosition))
    
    history.push(`/order/${data.orderId}`);
    dispatch(
      ChangeViewTravelDialoge(true, data.orderId, null)
    );
    // dispatch(
    //   OrderInfoAction(dispatch, {
    //     orderId: data.orderId,
    //   })
    // );
    data.customerId && dispatch(
      getFavAddressListAction(dispatch, { id: data.customerId, page: 0, perpage: 100 })
    );
    // dispatch(
    //   ChangeViewTravelDialoge(true, data.orderId, data.customerId)
    // );

    disableSelectedDriver()

  }

  function disableSelectedDriver(){
    dispatch(SetSelectedMonitoringOrder(null))
    dispatch(SetSelectedMonitoringDriver(null))
    dispatch(SetDriverPopUp(null))
    dispatch(SetOrderPinPopUp(null))
    dispatch(SetOrderPinPopUpPosition(null))
  }

  useLayoutEffect(() => {

    if ( OrderPinPopUpPosition && !width) {
    setTimeout(() => {
      var element = document.getElementById(`${OrderPinPopUp}popup`);
      var positionInfo = element && element.getBoundingClientRect();
      var width = positionInfo && positionInfo.width;
      width && setWidth(width);
    }, 100);
    }
  }, [OrderPinPopUpPosition]);


  return <OverlayView
    position={OrderPinPopUpPosition.position}
    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET} >
    <ClickAwayListener onClickAway={disableSelectedDriver}>

      <div id={`${OrderPinPopUp}popup`} className={"monitoringDriverSelected markerIcon " + styles.OsmMarkerPopUp} style={{ left: `-${(width / 2) - 0}px` ,opacity: width ? 1 : 0 }}>
        {/* <span className={styles.OsmMarkerPopUpName}> {t(OrderPinPopUpPosition.tag)} </span> */}
        <span className={styles.OsmMarkerPopUpButton} onClick={OpenOrderDetails}> {t("Details")} </span>
      </div>
</ClickAwayListener>
  </OverlayView>

}
