import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import arrow from "../../../../../assets/chevron-down.svg";
import trash from "../../../../../assets/ic-trash.svg";
import removeDropOff from "../../../../../assets/removeDropOff.svg";
import add from "../../../../../assets/add.svg";
import savedarrow from "../../../../../assets/icon-arrow-triangle-up.svg";
import Collapse from "@material-ui/core/Collapse";
import { withTranslation, Trans } from "react-i18next";
import Backdrop from "@material-ui/core/Backdrop";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import { withStyles } from "@material-ui/core/styles";
import {
  Add_dropoff,
  Set_DropOff_Inputs,
  Set_Pickup_address,
  Set_SearchAutoCompleteLoading,
  Set_latlng,
  OpenNewFav,
  SetopenAddressBookInNewOrder,
  Set_PureـPickupDeliveryType_Dropoff,
} from "../../../../../Redux/actions/actionNames";
import {
  SearchAutoCompleteAction,
  SearchAddressAction,
  PlaceIdDetailsAction,
  getFavAddressInfoAction,
  SearchAutoCompleteAction_local_address,
} from "../../../../../Redux/actions/actions";
import closeCircle from "../../../../../assets/close-cricle.svg";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import CircularProgress from "@material-ui/core/CircularProgress";
import Schedule from "./Schedule";
import momentJalaali from "moment-jalaali";
import { get_lang } from "../../../../../helper/UserData/userdate";
import { DetectOndemandOrReserve } from "../../../../../helper/worker/DetectOndemandOrReserve";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { getCenterMap, getCountryWithDomain, setCenterMap } from "../../../../../helper/module";
import newAddressbook from "../../../../../assets/icon-ic-pin-dark.svg";
import DropOffdriver from "./DropOff_driver";
import { debounce, isEmpty, throttle } from "lodash";
import icclockcolorfull from "../../../../../assets/ic-clock-colorfull.svg";
import iconicperson from "../../../../../assets/icon-ic-person.svg";
import icClock from "../../../../../assets/ic-clock.svg";
import iconIcPin from "../../../../../assets/icon-ic-pin.svg";
import drag_drop from "../../../../../assets/drag_drop.svg";
import { v4 as uuidv4 } from 'uuid';
import address_database from "../../../../../assets/address_database.webp";
import MapSearch from "../../../../../assets/MapSearch.webp";

const BackdropCustom = withStyles({
  backdrop: {
    zIndex: 5000,
    backgroundColor: "rgba(0, 0, 0, 0)",
    color: "#00000",
  },
})((props) => (
  <Backdrop
    {...props}
    style={{
      zIndex: 5000,
      backgroundColor: "rgba(0, 0, 0, 0)",
      color: "#00000",
    }}
  />
));

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

      isLocalAddressActive :global.config.dispatcherSettings.isLocalAddressActive ,
      localSearchIsActive : global.config.dispatcherSettings.isLocalAddressActive ? true : false ,
      cursor:null,

      showDropDown: false,
      id: props.id === "dropoff" ? "dropoff" : this.props.id,
      loading: false,
      ordered: true,
      showpoper: false,
      showpoperSaved: false,
      getFavAddressLoading: false,
      searchAddrees: "",
      list: [],
      canSelectReserveForDropOff: false,
      sessionToken: null
    };
    this.anchorEl = React.createRef();
    this.throttleHandleChange = debounce(
      this.throttleHandleChange.bind(this),
      800
    );
  }


  componentDidUpdate() {
    this._setCursorPositions();
  }

  _setCursorPositions = () => {
    //reset the cursor position for input
    if(this.anchorEl.current && this.state.cursor){
       this.anchorEl.current.selectionStart = this.state.cursor;
       this.anchorEl.current.selectionEnd = this.state.cursor;
    }

  };

  handleDropDown = () => {
    this.setState({
      showDropDown: !this.state.showDropDown
    })
  };
  OpenDropDown = (e) => {
    this.setState({
      showDropDown: true,
      cursor : e.target.selectionStart
    })
  };

  CloseDropDown = () => {
    this.setState({
      showDropDown: false
    })
  };



  throttleHandleChange(value) {
    let center = getCenterMap(this.props.mapRef, this.props.center)
    this.props.dispatch(Set_SearchAutoCompleteLoading(true));


    if(!this.state.localSearchIsActive){
        this.props.dispatch(
            SearchAutoCompleteAction(this.props.dispatch, {
              query: value,
              latitude: center.lat,
              longitude: center.lng,
              sessionToken: this.state.sessionToken
            })
          );
    }else{
      this.props.dispatch(
        SearchAutoCompleteAction_local_address(this.props.dispatch, {
          query: value
        })
      );
    }

  

  }

  notThrottleHandleChange(value) {
    let center = getCenterMap(this.props.mapRef, this.props.center)
    this.props.dispatch(Set_SearchAutoCompleteLoading(true));


   
    if(!this.state.localSearchIsActive){
      this.props.dispatch(
          SearchAutoCompleteAction(this.props.dispatch, {
            query: value,
            latitude: center.lat,
            longitude: center.lng,
            sessionToken: this.state.sessionToken
          })
        );
  }else{
    this.props.dispatch(
      SearchAutoCompleteAction_local_address(this.props.dispatch, {
        query: value
      })
    );
  }

  }



  handleshowpoper = () => {
    if (this.props.activePickupDropOff.id != this.props.id && !this.state.showpoper) {
      this.handleCollapse();
      setTimeout(() => {
        this.setState({
          showpoper: !this.state.showpoper,
          searchAddrees: "",
          showpoperSaved: false,
        });
      }, 600);
    } else {
      this.setState({
        showpoper: !this.state.showpoper,
        searchAddrees: "",
        showpoperSaved: false,
      });
    }


  };
  handleshowpoperDrop = () => {
    if (this.props.customerId || this.props.BusinessId) {
      this.setState({
        showpoperSaved: !this.state.showpoperSaved,
        searchAddrees: "",
        showpoper: false,
      });
    }
  };

  openAddressbook = () => {
    if (this.props.openAddressBook && this.props.addressBookSource) {
      this.props.dispatch(SetopenAddressBookInNewOrder(false, "", null, ""));
    } else if (this.props.customerId || this.props.BusinessId) {
      this.props.dispatch(
        SetopenAddressBookInNewOrder(
          true,
          "dropoff",
          this.props.id,
          "AddressbookDropOf"
        )
      );
    }
  };


  handleRecentList = (item) => {
    if (this.props.activePickupDropOff.id != this.props.id) {
      this.handleCollapse();
    }
    this.CloseDropDown()

    this.props.dispatch(
      Set_latlng({ lng: item.coordinates[0], lat: item.coordinates[1] })
    );

    this.props.dispatch(Set_DropOff_Inputs({
      name: "address",
      value: item.address,
      firstDropOff: this.props.first,
      id: this.props.id == "dropoff" ? "dropoff" : this.props.id,
    }))
    this.props.dispatch(Set_DropOff_Inputs({
      name: "adresss_loc",
      value: { lng: item.coordinates[0], lat: item.coordinates[1] },
      firstDropOff: this.props.first,
      id: this.props.id == "dropoff" ? "dropoff" : this.props.id,
    }))
    this.props.dispatch(Set_DropOff_Inputs({
      name: "block",
      value: item.buildingBlock,
      firstDropOff: this.props.first,
      id: this.props.id == "dropoff" ? "dropoff" : this.props.id,
    }))
    this.props.dispatch(Set_DropOff_Inputs({
      name: "floor",
      value: item.floor,
      firstDropOff: this.props.first,
      id: this.props.id == "dropoff" ? "dropoff" : this.props.id,
    }))

    this.props.dispatch(Set_DropOff_Inputs({
      name: "unit",
      value: item.room,
      firstDropOff: this.props.first,
      id: this.props.id == "dropoff" ? "dropoff" : this.props.id,
    }))

    this.mapSetView({
      lng: item.coordinates[0],
      lat: item.coordinates[1],
    });

  }


  validate(email) {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }



  handleSavedDropInput = (e) => {
    this.setState({
      searchAddrees: e.target.value,
    });
  };

  idgenerator = () => {
    var number = Math.random(); // 0.9394456857981651
    number.toString(36); // '0.xtis06h6'
    var id = number.toString(36).substr(2, 9); // 'xtis06h6'
    return id.toString();
  };

  handleCollapse = () => {
    this.props.handleCollapse({
      type: "dropoff",
      id: this.props.id,
      firstDropOff: this.props.first
    });
  };

  handleDropOffInputs = (e, name) => {
    if (e.target.id === "address" || name === "address") {

      this.setState({
        sessionToken: this.state.sessionToken ? this.state.sessionToken : uuidv4(),
        cursor : e.target.selectionStart

      })


      this.props.dispatch(
        Set_DropOff_Inputs({
          name: "adresss_loc",
          value: { lng: "", lat: "" },
          firstDropOff: this.props.first,
          id: this.props.id == "dropoff" ? "dropoff" : this.props.id,
        })
      );
      this.props.dispatch(
        Set_DropOff_Inputs({
          name: "placeId",
          value: "",
          firstDropOff: this.props.first,
          id: this.props.id == "dropoff" ? "dropoff" : this.props.id,
        })
      );
      if (e.target.value != "") {
        // this.props.dispatch(
        //   SearchAutoCompleteAction(this.props.dispatch, {
        //     query: e.target.value,
        //     latitude: this.props.mapRef.current.leafletElement.getCenter().lat,
        //     longitude: this.props.mapRef.current.leafletElement.getCenter().lng,
        //   })
        // );
        e.target.value.length > 2 && this.throttleHandleChange(e.target.value, this.state.sessionToken)
      }
    }
    this.props.dispatch(
      Set_DropOff_Inputs({
        name: name || e.target.id,
        value: e.target.value,
        firstDropOff: this.props.first,
        id: this.props.id == "dropoff" ? "dropoff" : this.props.id,
      })
    );
  };


  
  handleChangeLocalSearch= () => {
    this.setState({
      localSearchIsActive: !this.state.localSearchIsActive
    },()=>{
      String(this.props.address).length > 2 && this.notThrottleHandleChange(String(this.props.address), this.state.sessionToken)
    })
  };


  handleList = (item) => {
    if (this.props.activePickupDropOff.id != this.props.id) {
      this.handleCollapse();
    }
    this.CloseDropDown()
    if (item.coordinates) {

      this.props.dispatch(
        Set_latlng({
          lng: item.coordinates[0],
          lat: item.coordinates[1],
        })
      );

      this.props.dispatch(
        Set_DropOff_Inputs({
          name: "address",
          value: item.secondaryAddress
            ? item.address + " , " + item.secondaryAddress
            : item.address,
          firstDropOff: this.props.first,
          id: this.props.id == "dropoff" ? "dropoff" : this.props.id,
        })
      );
      this.props.dispatch(
        Set_DropOff_Inputs({
          name: "adresss_loc",
          value: {
            lng: item.coordinates[0],
            lat: item.coordinates[1],
          },
          firstDropOff: this.props.first,
          id: this.props.id == "dropoff" ? "dropoff" : this.props.id,
        })
      );

      this.props.dispatch(
        Set_latlng({
          lng: item.coordinates[0],
          lat: item.coordinates[1],
        })
      );

      this.setState({
        sessionToken: null
      })


    } else {

      this.setState({
        sessionToken: this.state.sessionToken ? this.state.sessionToken : uuidv4()
      }, () => {
        this.props.dispatch(
          PlaceIdDetailsAction(this.props.dispatch, { placeId: item.placeId, sessionToken: this.state.sessionToken }, (res) => {

            this.setState({
              sessionToken: null
            })

            this.props.dispatch(
              Set_latlng({
                lng: res.coordinates[0],
                lat: res.coordinates[1],
              })
            );
            this.props.dispatch(
              Set_DropOff_Inputs({
                name: "address",
                value: res.address,
                firstDropOff: this.props.first,
                id: this.props.id == "dropoff" ? "dropoff" : this.props.id,
              })
            );
            this.props.dispatch(
              Set_DropOff_Inputs({
                name: "adresss_loc",
                value: {
                  lng: res.coordinates[0],
                  lat: res.coordinates[1],
                },
                firstDropOff: this.props.first,
                id: this.props.id == "dropoff" ? "dropoff" : this.props.id,
              })
            );
            this.props.dispatch(
              Set_latlng({
                lng: res.coordinates[0],
                lat: res.coordinates[1],
              })
            );
          })
        );
      })



    }
  };

  componentDidMount() {
    this.canSelectReserveForDropOff();
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      id: nextProps.id,
    });
    this.canSelectReserveForDropOff(nextProps);
  }

  resetPickup = () => {
    this.props.dispatch(
      Set_DropOff_Inputs({
        name: "address",
        value: "",
        firstDropOff: this.props.first,
        id: this.props.id == "dropoff" ? "dropoff" : this.props.id,
      })
    );
    this.props.dispatch(
      Set_DropOff_Inputs({
        name: "adresss_loc",
        value: { lng: "", lat: "" },
        firstDropOff: this.props.first,
        id: this.props.id == "dropoff" ? "dropoff" : this.props.id,
      })
    );
    this.props.dispatch(
      Set_DropOff_Inputs({
        name: "placeId",
        value: "",
        firstDropOff: this.props.first,
        id: this.props.id == "dropoff" ? "dropoff" : this.props.id,
      })
    );
  };

  removeDropOff = () => {
    this.props.removeDrop(
      this.props.id == "dropoff" ? "dropoff" : this.props.id
    );
  };

  hanldeOpenNewFav = () => {
    this.props.dispatch(OpenNewFav(true));
  };

  mapSetView = (e) => {
    setCenterMap(this.props.mapRef, e)

    // this.props.mapRef.current.leafletElement.setView(e, 17);
  };

  handleListFavAddress = (e) => {
    this.setState({
      showpoperSaved: false,
      getFavAddressLoading: true,
    });
    // address: "Somewhere"
    // customerId: "ckfezc72o01f1xxo81la4bvl9"
    // id: "ckh3mcgda0d847bo87td8fk1v"
    // title: "HomeTown"

    this.props.dispatch(
      getFavAddressInfoAction(
        this.props.dispatch,
        { customerId: e.customerId, id: e.id },
        (res) => {

          this.props.dispatch(
            Set_latlng({ lng: res.coordinates[0], lat: res.coordinates[1] })
          );

          this.setState({
            getFavAddressLoading: false,
          });
          this.mapSetView({ lng: res.coordinates[0], lat: res.coordinates[1] });

          this.props.dispatch(
            Set_DropOff_Inputs({
              name: "address",
              value: res.address,
              firstDropOff: this.props.first,
              id: this.props.id == "dropoff" ? "dropoff" : this.props.id,
            })
          );
          this.props.dispatch(
            Set_DropOff_Inputs({
              name: "adresss_loc",
              value: { lng: res.coordinates[0], lat: res.coordinates[1] },
              firstDropOff: this.props.first,
              id: this.props.id == "dropoff" ? "dropoff" : this.props.id,
            })
          );
          this.props.dispatch(
            Set_DropOff_Inputs({
              name: "block",
              value: res.buildingBlock,
              firstDropOff: this.props.first,
              id: this.props.id == "dropoff" ? "dropoff" : this.props.id,
            })
          );
          this.props.dispatch(
            Set_DropOff_Inputs({
              name: "floor",
              value: res.floor,
              firstDropOff: this.props.first,
              id: this.props.id == "dropoff" ? "dropoff" : this.props.id,
            })
          );

          this.props.dispatch(
            Set_DropOff_Inputs({
              name: "unit",
              value: res.room,
              firstDropOff: this.props.first,
              id: this.props.id == "dropoff" ? "dropoff" : this.props.id,
            })
          );
          this.props.dispatch(
            Set_DropOff_Inputs({
              name: "senderFullName",
              value: res.fullName,
              firstDropOff: this.props.first,
              id: this.props.id == "dropoff" ? "dropoff" : this.props.id,
            })
          );
          this.props.dispatch(
            Set_DropOff_Inputs({
              name: "senderPhoneNumber",
              value: res.phone,
              firstDropOff: this.props.first,
              id: this.props.id == "dropoff" ? "dropoff" : this.props.id,
            })
          );
          this.props.dispatch(
            Set_DropOff_Inputs({
              name: "noteForDriver",
              value: res.description,
              firstDropOff: this.props.first,
              id: this.props.id == "dropoff" ? "dropoff" : this.props.id,
            })
          );
        }
      )
    );
  };

  canSelectReserveForDropOff = (props) => {
    DetectOndemandOrReserve(
      {
        pickup: props ? props.pickup : this.props.pickup,
        dropOffs: props
          ? [props.firstDropOff, props.otherdropOffs]
          : [this.props.id === "dropoff", this.props.otherdropOffs],
      },
      (call) => {
        if (call == "reserve") {
          this.setState({
            canSelectReserveForDropOff: true,
          });
        } else {
          this.setState({
            canSelectReserveForDropOff: false || ["Delivery", "PickupDelivery"].indexOf(
              this.props.orderTypeActive
            ) > -1,
          });
        }
      }
    );
  };

  phoneNumberOnChange = (res, id) => {
    this.props.dispatch(
      Set_DropOff_Inputs({
        name: id,
        value: res,
        firstDropOff: this.props.first,
        id: this.props.id == "dropoff" ? "dropoff" : this.props.id,
      })
    );
  };

  handleChooseDriver = () => {
    
    this.props.dispatch(Set_PureـPickupDeliveryType_Dropoff(
      { chooseDriver: !this.props.showDriver }
    ))
  }

  render() {
    const { t } = this.props;
    return (
      <passerngerContainer
        className={`dropOffCustomized itemxdnd ${this.props.last && "LastdropOffCustomized"
          }`}
      >
        <div className="pickuptitleContainer">
          <pickuptitle>
            <span>
              {" "}
              {this.props.dropOffsCount > 1 && <img src={drag_drop} className="drag_drop_cursor" />}
              <Trans i18nKey={"DropOff"}> </Trans> {this.props.number}{" "}
            </span>
            {!this.props.isDraggingOver && ["Ondemand"].indexOf(this.props.orderTypeActive) > -1 && this.props.last && <img onClick={() => this.props.addDropOff()} className="adddropoffinnerdrop" src={add} />}

            {!this.props.isDraggingOver && <img
              onClick={this.removeDropOff}
              src={removeDropOff}
              className={`adddropoffinnerdrop ${this.props.first && "displayNone"}`}
            />}

            {["PickupDelivery"].indexOf(
              this.props.orderTypeActive
            ) > -1 &&
              <div
                className="forceAssaignConatiner choosedriverpickupdriver"
                onClick={this.handleChooseDriver}
              >
                <span>
                  {" "}
                  <Trans i18nKey={"ChooseDriver"}> </Trans>{" "}
                </span>
                <div
                  className={`forceAssaigncheckbox ${this.props.showDriver && "ActiveforceAssaigncheckbox"
                    }`}
                >
                  {" "}
                </div>
              </div>}

          </pickuptitle>{" "}


        </div>

        <div className={`pickupInputContainer `}>
          <ClickAwayListener onClickAway={this.CloseDropDown}>
            <div className={`NewOrderPickupContainer_DRDO ${(this.state.showDropDown && this.props.adresss_loc.lat == "") && "DriverManualSelectContainer_DRDO_Active"}`} >
              <div className="pickupDropOffInputResetContainer">
              {this.state.isLocalAddressActive &&  <img src={this.state.localSearchIsActive ? address_database : MapSearch} onClick={this.handleChangeLocalSearch} className={"localSearchIsActiveicon"} />}

                <input
                autoFocus="autofocus"
                  value={this.props.address}
                  onChange={(e) => this.handleDropOffInputs(e, "address")}
                  autocomplete={"16f2bc57" + this.props.number}
                  placeholder={t(this.state.localSearchIsActive ? "SearchinLocalDatabase" : "SearchinMapService")}
                  ref={this.anchorEl}
                  onClick={this.OpenDropDown}
                />

                {this.props.adresss_loc.lat !== "" && (
                  <img
                    src={closeCircle}
                    onClick={this.resetPickup}
                    style={{margin:"0  8.6px"}}

                  />
                )}

              </div>

              {this.props.adresss_loc.lat == "" && <React.Fragment>


                {(this.state.showDropDown && this.props.recentAddress.length == 0 && !isEmpty(this.props.customerId) && isEmpty(this.props.address)) &&
                  <div className="pickupDropOffRecentAddress">
                    <img src={icclockcolorfull} />
                    <p className="NoRecentAddress"><Trans i18nKey={"NoRecentAddress"}> </Trans></p>
                    <p className="NoRecentAddressD"><Trans i18nKey={"NoRecentAddressD"}> </Trans></p>
                  </div>
                }


                {(this.state.showDropDown && isEmpty(this.props.customerId) && isEmpty(this.props.address)) &&
                  <div className="pickupDropOffRecentAddress">
                    <img src={iconicperson} />
                    <p className="NoRecentAddress"><Trans i18nKey={"CustomerIsNotChoosed"}> </Trans></p>
                    <p className="NoRecentAddressD"><Trans i18nKey={"CustomerIsNotChoosedD"}> </Trans></p>
                  </div>
                }

                {(this.state.showDropDown && !isEmpty(this.props.customerId) && !isEmpty(this.props.recentAddress) && isEmpty(this.props.address)) &&
                  <div className="pickupDropOffRecentAddressItemListContainer">
                    {this.props.recentAddress.map((item) => {
                      return <div className="pickupDropOffRecentAddressItemList" onClick={() => {
                        this.handleRecentList(item);
                      }}>
                        <img src={icClock} />
                        <span> {item.address}</span>
                        <p> {`${item.buildingBlock} ${!isEmpty(item.room) ? ", " + item.room : ""} ${!isEmpty(item.floor) ? ", " + item.floor : ""}`} {(isEmpty(item.buildingBlock) && isEmpty(item.room) && isEmpty(item.floor)) && "----"} </p>
                      </div>
                    })}
                  </div>
                }




                {(this.state.showDropDown && !isEmpty(this.props.address) && this.props.suggestPickUpDropOffListData.length > 0 &&
                  !this.props.suggestListPickUpDropOffLoading) &&
                  <div className="pickupDropOffRecentAddressItemListContainer">
                    {this.props.suggestPickUpDropOffListData.map((item) => {
                      return <div className="pickupDropOffRecentAddressItemList" onClick={() => {
                        this.handleList(item);
                      }}>
                        <img src={iconIcPin} />
                        <span> {item.address}</span>
                        <p> {isEmpty(item.secondaryAddress) ? "----" : item.secondaryAddress} </p>
                      </div>
                    })}
                  </div>
                }


                {(this.state.showDropDown && this.props.suggestListPickUpDropOffLoading && !isEmpty(this.props.address)) &&
                  <div className="pickupDropOffRecentAddress">
                    <img src={iconIcPin} />
                    <p className="NoRecentAddress"><Trans i18nKey={"poperLoading"}> </Trans></p>
                    <p className="NoRecentAddressD"><Trans i18nKey={"fetchingdata"}> </Trans></p>
                  </div>
                }


                {(this.state.showDropDown && !this.props.suggestListPickUpDropOffLoading &&
                  this.props.suggestPickUpDropOffListData.length === 0 && !isEmpty(this.props.address)) &&
                  <div className="pickupDropOffRecentAddress">
                    <img src={iconIcPin} />
                    <p className="NoRecentAddress"><Trans i18nKey={this.state.localSearchIsActive ? "NoResultfromLocalDatabase" : "NoResultfromMapService"}> </Trans></p>
                    <p className="NoRecentAddressD"><Trans i18nKey={"NoResultfromLocalDatabaseD"}> </Trans></p>
                  </div>
                }



              </React.Fragment>}




            </div>

          </ClickAwayListener>







          <img
            style={{
              opacity:
                this.props.customerId || this.props.BusinessId ? "1" : ".5",
              cursor:
                this.props.customerId || this.props.BusinessId
                  ? "pointer"
                  : "not-allowed",
            }}
            onClick={() => this.openAddressbook()}
            src={newAddressbook}
            className={`newOrder_AddressBookToggle ${this.props.addressBookSource && "newOrder_AddressBookToggleActive"}`}
          />

          <img
            onClick={this.handleCollapse}
            src={arrow}
            className={`pickupCollIconVR ${this.props.activePickupDropOff.id === this.props.id && "rotate180"
              }`}
          />


        </div>

        <Collapse
          className="pickupcollapsecontainer pickupcollapsecontainer yodmvydj"
          in={this.props.activePickupDropOff.id === this.props.id}
          timeout="auto"
          unmountOnExit
          style={{ marginTop: "2px", border: "none" }}

        >
          {this.state.getFavAddressLoading && (
            <CircularProgress size={18} className="pickupLoading" />
          )}

          {/* <savedDrop
            onClick={this.handleshowpoperDrop}
            className={` ${!this.props.customerId && "savedDropDisable"}`}
          >
            <span>{t("Saved")}</span>
            <img
              src={savedarrow}
              className={` ${this.state.showpoperSaved && "rotate180"}`}
            />
          </savedDrop> */}
          {this.props.activeVehicleType
            ? this.props.activeVehicleType.setting &&
            this.props.activeVehicleType.setting.isAddressDetailsActive && (
              <React.Fragment>

                {(this.props.activeVehicleType.setting.isDropoffAddressRoomFloorBlockActive) && <>

                  <input
                    value={this.props.block}
                    onChange={this.handleDropOffInputs}
                    id={"block"}
                    name={"62fd670b" + this.props.number}
                    autocomplete={"62fd670b" + this.props.number}
                    className="blockPickup"
                    placeholder={t("block")}
                  />
                  <input
                    value={this.props.floor}
                    onChange={this.handleDropOffInputs}
                    id={"floor"}
                    name={"be4c90f8" + this.props.number}
                    autocomplete={"be4c90f8" + this.props.number}
                    className="floorPickup"
                    placeholder={t("Floor")}
                  />
                  <input
                    value={this.props.unit}
                    onChange={this.handleDropOffInputs}
                    id={"unit"}
                    name={"59ee84aa" + this.props.number}
                    autocomplete={"59ee84aa" + this.props.number}
                    className="UnitPickup"
                    placeholder={t("Unit")}
                  />

                </>}

                <div className="fourInputsContainer">


                {(this.props.activeVehicleType.setting.isDropoffAddressNameActive) && <div className="inputWithRequired">

                  <input
                    value={this.props.recieverFullName}
                    onChange={this.handleDropOffInputs}
                    id={"recieverFullName"}
                    name={"eb89c0d4+this.props.number"}
                    autocomplete={"eb89c0d4" + this.props.number}
                    className="senderfullnamePickup"
                    placeholder={t("Recieverfullname")}
                  />

                  {(this.props.activeVehicleType.setting.isDropoffAddressNameRequired) && <span className="inputRequired">{this.props.t("Required")}</span>
                  }
                </div>}

                {(this.props.activeVehicleType && this.props.activeVehicleType.setting && this.props.activeVehicleType.setting.isDropoffAddressPhoneActive) &&
                  <div className="inputWithRequired" >
                    <PhoneInput
                      defaultCountry={global.config.Country}
                      placeholder={this.props.t("Phonenumber")}
                      value={this.props.recieverPhoneNumber}
                      onChange={(PrePhoneInput) =>
                        this.phoneNumberOnChange(
                          PrePhoneInput,
                          "recieverPhoneNumber"
                        )
                      }
                      id={"recieverPhoneNumber"}
                      className="senderphonenumberPickup"
                    />

                    {(this.props.activeVehicleType && this.props.activeVehicleType.setting && this.props.activeVehicleType.setting.isDropoffAddressPhoneRequired) && <span className="inputRequired">{this.props.t("Required")}</span>
                    }
                  </div>}

                {(this.props.activeVehicleType && this.props.activeVehicleType.setting && this.props.activeVehicleType.setting.isDropoffAddressEmailActive) && <div className="inputWithRequired"
                  style={{ border: this.props.email.length > 0 ? this.validate(this.props.email) ? "solid 1px rgba(128, 128, 128, 0.2)" : "1px solid red" : "solid 1px rgba(128, 128, 128, 0.2)" }}
                >
                  <input
                    value={this.props.email}
                    onChange={this.handleDropOffInputs}
                    id={"email"}
                    name={"420d2de0" + this.props.number}
                    autocomplete={"420d2de0" + this.props.number}
                    className="notesfordriverPickup"
                    placeholder={t("email")}
                    // style={{
                    //   border: this.props.email.length > 0 ? this.validate(this.props.email) ? "solid 1px rgba(128, 128, 128, 0.2)" : "1px solid red" : "solid 1px rgba(128, 128, 128, 0.2)",
                    //   width: this.props.email.length > 0 ? this.validate(this.props.email) ? "none" : "calc(100% - 11px)" : "none",
                    //   height: this.props.email.length > 0 ? this.validate(this.props.email) ? "32px" : "32px" : "32px"
                    // }}
                  />

                  {(this.props.activeVehicleType && this.props.activeVehicleType.setting && this.props.activeVehicleType.setting.isDropoffAddressEmailRequired) && <span className="inputRequired">{this.props.t("Required")}</span>
                  }
                </div>}


                <div className="inputWithRequired" >

                  <input
                    value={this.props.noteForDriver}
                    onChange={this.handleDropOffInputs}
                    id={"noteForDriver"}
                    name={"420d2de0" + this.props.number}
                    autocomplete={"420d2de0" + this.props.number}
                    className="notesfordriverPickup"
                    placeholder={t("notesfordriver")}
                  />
                </div>
                </div>
              </React.Fragment>
            )
            : null}
          {/*this.state.canSelectReserveForDropOff && */this.props.ActivescheduleSetting && (
            <Schedule
              firstDropOff={this.props.first}
              id={this.props.id}
              scheduleDateBefore={this.props.scheduleDateBefore}
              scheduleDateAfter={this.props.scheduleDateAfter}
            />
          )}
        </Collapse>

        <BackdropCustom open={this.state.showpoperSaved}>
          <Popper
            style={{
              width: "475px",
              zIndex: "12",
              marginTop: "0px",
              maxHeight: "150x",
              left: "25px !important",
            }}
            timeout={500}
            placement={"bottom-end"}
            id={"passenger"}
            open={this.state.showpoperSaved}
            anchorEl={this.anchorEl.current}
            className="left25"
          >
            <ClickAwayListener onClickAway={this.handleshowpoperDrop}>
              <Paper
                className="xxx"
                style={{
                  width: "476px",
                  float: "right",
                  borderRadius: "8px",
                  paddingTop: "5px",
                  marginTop: "10px",
                  right: "271px",
                  maxHeight: "201px",
                  overflowY: "scroll",
                  overflowX: "hidden",
                }}
                elevation={3}
              >
                <input
                  value={this.state.searchAddrees}
                  onChange={this.handleSavedDropInput}
                  className="savedAddressSearch"
                  placeholder={this.props.t("Search")}
                />
                <div
                  style={{
                    maxHeight: "111px",
                    overflowY: "scroll",
                    overflowX: "hidden",
                  }}
                >
                  {this.props.favAddresss.length === 0 &&
                    this.props.customerId.length > 0 && (
                      <div>
                        <span className="NotravelsListText">
                          <Trans i18nKey={"Noaddressavailable"}> </Trans>
                        </span>

                        <div
                          onClick={this.hanldeOpenNewFav}
                          className="divManagefavoriteaddresses"
                          style={{ marginTop: "10px" }}
                        >
                          <span className="Managefavoriteaddresses">
                            <Trans i18nKey={"Managefavoriteaddresses"}> </Trans>
                          </span>
                        </div>
                      </div>
                    )}

                  {this.props.favAddresss.length > 0 &&
                    this.props.favAddresss
                      .filter((item) => {
                        return this.state.searchAddrees.length === 0
                          ? true
                          : item.title.includes(this.state.searchAddrees);
                      })
                      .map((item) => {
                        return (
                          <favAddressselectorInList
                            onClick={() => {
                              this.handleListFavAddress(item);
                            }}
                          >
                            <name> {item.title}</name>
                            <addres> {item.address}</addres>
                          </favAddressselectorInList>
                        );
                      })}
                </div>

                {this.props.favAddresss.length !== 0 &&
                  this.props.customerId.length > 0 && (
                    <div
                      className="divManagefavoriteaddresses"
                      onClick={this.hanldeOpenNewFav}
                    >
                      <span className="Managefavoriteaddresses">
                        <Trans i18nKey={"Managefavoriteaddresses"}> </Trans>
                      </span>
                    </div>
                  )}
              </Paper>
            </ClickAwayListener>
          </Popper>
        </BackdropCustom>
        {["PickupDelivery"].indexOf(this.props.orderTypeActive) > -1 &&
          this.props.showDriver && <DropOffdriver />}
      </passerngerContainer>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  pickup: state.NewOrder.newOrder.pickup,
  firstDropOff: state.NewOrder.newOrder.dropOffs.firstDropOff,
  otherdropOffs: state.NewOrder.newOrder.dropOffs.otherDropOffs,
  showDriver: state.NewOrder.newOrder.dropOffs.firstDropOff.driver.chooseDriver,

  favAddresss: state.FavAddress.list,
  customerId: state.NewOrder.newOrder.customer.id,
  BusinessId: state.NewOrder.newOrder.customer.BusinessId,

  mapRef: state.Map.mapRef,
  center: state.Map.center,
  activePickupDropOff: state.NewOrder.newOrder.activePickupDropOff,
  list: state.NewOrder.newOrder.dropOffs.list,
  loading: state.NewOrder.newOrder.dropOffs.loading,
  center: state.Map.center,
  suggestListPickUpDropOffLoading:
    state.NewOrder.newOrder.suggestListPickUpDropOffLoading,
  suggestListPickUpDropOffOrdered:
    state.NewOrder.newOrder.suggestListPickUpDropOffOrdered,
  suggestPickUpDropOffListData:
    state.NewOrder.newOrder.suggestPickUpDropOffListData,
  lang: state.LocalData.lang,
  activeVehicleType: state.NewOrder.newOrder.service.activeVehicleType,
  addressBookSource: state.Addressbook.openAddressBookInNewOrder
    ? state.Addressbook.sourceOfAddressBookInNewOrder
      ? state.Addressbook.sourceOfAddressBookInNewOrder.type == "dropoff" &&
      ownProps.id == state.Addressbook.sourceOfAddressBookInNewOrder.id
      : false
    : false,
  openAddressBook: state.Addressbook.openAddressBookInNewOrder,
  orderTypeActive: state.NewOrder.newOrder.orderTypeActive,
  ActivescheduleSetting: state.NewOrder.newOrder.service.activeVehicleType ? state.NewOrder.newOrder.service.activeVehicleType.scheduleSetting.hasDropoffScheduling : false,
  recentAddress: state.NewOrder.newOrder.customer.recentAddreess

});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
