import React, { useRef, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { GoogleMap, LoadScript, useJsApiLoader } from "@react-google-maps/api";
import "./index.css";
import {
  AddMonitoringOrdersBounding,
  SetDriverPopUp,
  SetOrderPinPopUp,
  SetOrderPinPopUpPosition,
  SetSelectedMonitoringDriver,
  SetSelectedMonitoringOrder,
  Set_latlng,
  Set_Map_Ref,
  Set_map_loaded,
} from "../../../../Redux/actions/actionNames";
import BoundGenerater from "./boundGenerater";
import DriverOrdersPinBounding from "./driverOrdersPinBounding";
import { getCenterMap } from "../../../../helper/module";
import styles from '../style.module.css';
import IconProvider from "../../../../design-system/lib/IconProvider";
import { INFO_CIRCLE } from "../../../../assets";
import Typography from "../../../../design-system/lib/Typography";

var _ = require("lodash");

const customStyle = [
  {
    featureType: "landscape",
    elementType: "all",
    stylers: [
      {
        color: "#f8f8f8",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#ececec",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#666565",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.icon",
    stylers: [
      {
        color: "#8c8c8c",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#008303",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#cbcbcb",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit.station",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#7b7b7b",
      },
    ],
  },
  {
    featureType: "transit.station",
    elementType: "labels.icon",
    stylers: [
      {
        color: "#8c8c8c",
      },
    ],
  },
  {
    featureType: "transit.station.rail",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#c9e5ef",
      },
    ],
  },
];


const App = (props) => {
  const [zoom, setzoom] = useState(14);
  const [loaded, setloaded] = useState(false);
  const [finishedmoving, setfinishedmoving] = useState(false);
  const [Driverselected, setDriverselected] = useState(null);
  const ref = useRef(null);
  const dispatch = useDispatch();
  const mapLoaded = useSelector((state) => state.Map.loaded);
  const FavAddressOpenDialoge = useSelector((state) => state.FavAddress.openDialoge);
  const OpenNRDialoge = useSelector((state) => state.NewOrder.OpenDialoge);
  const NRactivePickupDropOff = useSelector((state) => state.NewOrder.newOrder.activePickupDropOff);
  const NRpickupAddress = useSelector((state) => state.NewOrder.newOrder.pickup.address);
  const NRpickupAdresss_loc = useSelector((state) => state.NewOrder.newOrder.pickup.adresss_loc);
  const NRdropOffAddress = useSelector((state) => state.NewOrder.newOrder.dropOffs.firstDropOff.address);
  const NRdropOffsAdresss_loc = useSelector((state) => state.NewOrder.newOrder.dropOffs.firstDropOff.adresss_loc);
  const NRotherDropOffs = useSelector((state) =>  state.NewOrder.newOrder.dropOffs.otherDropOffs);
  const Globalsettings = useSelector((state) => state.SharedData.settings);
  const OpenVRDialoge = useSelector((state) => state.ViewTravel.OpenDialoge);
  const VRpureData = useSelector((state) => state.ViewTravel.pureData);
  const EROpenERDialoge = useSelector((state) => state.EditOrder.OpenDialoge);
  const ERactivePickupDropOff = useSelector((state) => state.EditOrder.editOrder.activePickupDropOff);
  const ERpickupAddress = useSelector((state) => state.EditOrder.editOrder.pickup.address);
  const ERpickupAdresss_loc = useSelector((state) => state.EditOrder.editOrder.pickup.adresss_loc);
  const ERdropOffAddress = useSelector((state) => state.EditOrder.editOrder.dropOffs.firstDropOff.address);
  const ERdropOffsAdresss_loc = useSelector((state) => state.EditOrder.editOrder.dropOffs.firstDropOff.adresss_loc);
  const ERotherDropOffs = useSelector((state) => state.EditOrder.editOrder.dropOffs.otherDropOffs);
  const center = useSelector((state) => state.Map.center);
  const showEventTimeLine = useSelector((state) => state.ViewTravel.eventTimeLine.show);
  const googleMapLoaded = useSelector((state) =>  state.SharedData.googleMapLoaded);
  const orderType = useSelector((state) => state.ViewTravel.pureData
  ? state.ViewTravel.pureData.type
    ? state.ViewTravel.pureData.type
    : null
  : null,);
  const loading = useSelector((state) => state.ViewTravel.loading);
  const error = useSelector((state) => state.ViewTravel.error);
  const mapRef = useSelector((state) => state.Map.mapRef);
  const MonitoringOrdersOpen = useSelector((state) => state.Monitoring.orders.open);
  const ordersBoundingList = useSelector((state) => state.Monitoring.ordersBoundingList);
  const ordersBoundingDriver = useSelector((state) => state.Monitoring.ordersBoundingDriver);

  const mapOnClick = () => {
    // dispatch(SetSelectedMonitoringOrder(null))
    // dispatch(SetSelectedMonitoringDriver(null))
    // dispatch(SetDriverPopUp(null))
    // dispatch(SetOrderPinPopUp(null))
    // dispatch(SetOrderPinPopUpPosition(null))
  };

  const handleonZoomChanged = () => {
    if (
      ordersBoundingList.length > 0 ||
      ordersBoundingDriver
    ) {
      // dispatch(
      //   AddMonitoringOrdersBounding(
      //     { ordersBoundingList: [], ordersBoundingDriver: null },
      //     "135"
      //   )
      // );
    }
  };

  const handleCenterChanged = () => {
    if (ref) {
      const newCenter = getCenterMap(mapRef, center);
      // onCenterChange(newCenter);
    }
  };

  const handleOnLoad = (map) => {
    dispatch(Set_Map_Ref(map));
    // setState({
    //   ref:map
    // })
    // setState({ref:map})
  };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: global.config.dispatcherSettings.applicationMap.apiKey
  })


   return (
      <div className={props.className} style={{ ...props.style }} id="googlemapUniqeId">
        {(Globalsettings && Globalsettings.dispatcherHomeMessage && String(Globalsettings.dispatcherHomeMessage).length > 0) && <div className={styles.helloMessageContainer}>
          <IconProvider Icon={INFO_CIRCLE} />
          <Typography weight="Body_Middle_Regular" textColor="TextDark" text={Globalsettings.dispatcherHomeMessage} />
        </div>}

        {/* {!mapLoaded && <LoadScript
          loadingElement={<></>} onError={(e) => { dispatch(Set_map_loaded(true)); setState({ loaded: false }) }} onLoad={() => { dispatch(Set_map_loaded(true)); setState({ loaded: true }) }}
          googleMapsApiKey={global.config.dispatcherSettings.applicationMap.apiKey}>



        </LoadScript>} */}

        {isLoaded  && <GoogleMap
          options={{
            fullscreenControl: false,
            linksControl: false,
            panControl: false,
            addressControl: false,
            enableCloseButton: false,
            zoomControl: false,
            enableCloseButton: false,
            styles: customStyle,
            gestureHandling: "greedy",
          }}
          id="map"
          mapContainerStyle={{
            width: "100%",
            height: "100%",
          }}
          style={{
            width: "100%",
            height: "100%",
          }}
          onLoad={handleOnLoad}
          center={center}
          zoom={zoom}
          onDragEnd={handleCenterChanged}
          onResize={handleCenterChanged}
          onZoomChanged={handleonZoomChanged}
          ref={ref}
          onClick={mapOnClick}
        >
          {props.children}
          {mapRef &&
            (!_.isEmpty(VRpureData) || isNewOrder()) && (
              <BoundGenerater />
            )}
          {mapRef && _.isEmpty(VRpureData) && (
            <DriverOrdersPinBounding />
          )}
        </GoogleMap>}
      </div>
    );
};

function isNewOrder() {
  if (String(window.location.href).includes("neworder")) {
    return true
  } else {
    return false
  }
}

export default App