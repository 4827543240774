import acistants from "../../../assets/SkiptheQ_logotype.png";
import FavIcon from "../../../assets/PROJECTS/SkiptheQ.png";

export default function SkiptheQ() {

    return global.config = {
        "ProjectName": "SkiptheQ",
        "LogoType": acistants,
        "BaseApi": "https://rest.skiptheq.com",
        "BaseSocket": "https://socket.skiptheq.com",
        "BaseTrack": "https://admin.skiptheq.com",
        "MapCenter": {
            "lat": "47.3769",
            "lng": "8.5417"
        },
        "Lang": "en",
        "Direction": "ltr",
        "AllLanguages": [
                 {
                "lang": "en",
                "direction": "Ltr",
                "i18Lang": "English",
            }, {
                "lang": "fr",
                "direction": "Ltr",
                "i18Lang": "French",
            }],
        "FavIcon": FavIcon,
        "Country": "CA",
        "thirdPartyLoginSupport": false,
        mapType: "osm", /* osm | google */
        googleApiKey: ""
    }
}