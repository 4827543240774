import EzDeliverys from "../../../assets/EzDelivery.png";
import FavIcon from "../../../assets/PROJECTS/EzDeliveryFavIcon.png";

export default function EzDelivery() {
    
    return  global.config = {
    "ProjectName": "EzDelivery",
    "LogoType": EzDeliverys,
    "BaseApi": "https://rest.ezdeliveryservices.com",
    "BaseSocket": "https://socket.ezdeliveryservices.com",
    "BaseTrack": "https://track.ezdeliveryservices.com",
    "MapCenter": {
        "lat": "40.71272522340908",
        "lng": "-74.0042424056128"
    },
    "Lang": "en",
    "Direction": "ltr",
    "AllLanguages": [{
        "lang": "en",
        "direction": "ltr",
        "i18Lang": "English"
    },{
        "lang": "fr",
        "direction": "ltr",
        "i18Lang": "French"
    },{
        "lang": "es",
        "direction": "ltr",
        "i18Lang": "spanish",
    }],
    "FavIcon": FavIcon,
    "Country": "US",
    "thirdPartyLoginSupport": true  ,
    mapType :"osm", /* osm | google */ 
    googleApiKey : ""
} }