import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TableInputContainFilter_Add, TableInputContainFilter_Delete, TableInputContainFilter_Update } from '../Redux/actions/actionNames';




export function GetTableInputContainFilter(regexField) {
  const [data, setData] = useState({ value: "" });
  const pureData = useSelector((state) => state.Table.filters.regex)
  const dispatch = useDispatch();



  useEffect(() => {

    let index = pureData.findIndex(x => x.field == regexField)
    if (index > -1) {
      setData({ value: pureData[index].text })
    }else{
      setData({ value: "" })
    }

  }, [pureData]);

  useEffect(() => {

    let index = pureData.findIndex(x => x.field == regexField)
    if (index > -1) {
      setData({ value: pureData[index].text })
    }

  }, []);


  const OnChange = React.useCallback((e) => {

    if (!e || String(e).length == 0) {
      dispatch(TableInputContainFilter_Delete(regexField))

    } else {
      let index = pureData.findIndex(x => x.field == regexField)
      if (index > -1) {
        dispatch(TableInputContainFilter_Update({ field: regexField, text: e }))
      } else {
        dispatch(TableInputContainFilter_Add({ field: regexField, text: e }))
      }

    }

    console.log("GetTableInputContainFilter OnChange", e)

  }, [data, pureData, regexField]);


  return { [`${regexField}_data`]: data.value, [`${regexField}_OnChange`]: OnChange };
};

