


export const GetColorByStatus =  (Status ,order) => {
    if(order){
        
        if(order.status == "Pending" && !order.isDispatching){
            return "7748ff"
        }
    }
    
    if(!Status){
        return "0064d2"
    }
    const findStatus = getStatus.findIndex((element) => {return element.status == Status}) ;
    return getStatus[findStatus > -1 ? findStatus : 5].color



};

const getStatus = [
    {status: 'Assigned' ,color: "25B7FF"},
    {status: 'Pending' ,color: "E025FF "},
    {status: 'Started' ,color: "004cc4"},
    {status: 'Arrived' ,color: "004cc4"},
    {status: 'PickedUp' ,color: "004cc4"},
    {status: 'Delivering' ,color: "004cc4"},
    {status: 'Done' ,color: "009b62"},
    {status: 'SupportCanceled' ,color: "484848"},
    {status: 'DriverCanceled' ,color: "484848"},
    {status: 'CustomerCanceled' ,color: "484848"},
    {status: 'Collecting' ,color: "25B7FF"},
    {status: 'Transit' ,color: "dcb900"},
    {status: 'Unassigned' ,color: "E99514"},

    {status: 'PickupFailed' ,color: "d01400"},
    {status: 'Lost' ,color: "d01400"},
    {status: 'Returned' ,color: "484848"},
    {status: 'Draft' ,color: "484848"},
    {status: 'Confirmed' ,color: "e99514"},
    {status: 'PickupRouted' ,color: "e99514"},
    {status: 'ReadyForPickup' ,color: "004cc4"},
    {status: 'PickedUp' ,color: "004cc4"},
    {status: 'AtWarehouse' ,color: "E99514"},
    {status: 'DeliveryRouted' ,color: "1080ff"},
    {status: 'ReadyForDelivery' ,color: "004cc4"},
    {status: 'OutForDelivery' ,color: "004cc4"},
    {status: 'Delivered' ,color: "009b62"},
    {status: 'NotDelivered' ,color: "d01400"},
    {status: 'ForReturn' ,color: "d01400"},

]
