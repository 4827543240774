import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import arrow from "../../../../../assets/chevron-down.svg";
import pickupDone from "../../../../../assets/icon-pickup-done.svg";

import Collapse from "@material-ui/core/Collapse";
import { withTranslation, Trans } from "react-i18next";
import Backdrop from "@material-ui/core/Backdrop";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import { withStyles } from "@material-ui/core/styles";
import {
  Set_Pickup_Inputs,
  Set_Pickup_address,
  Set_SearchAutoCompleteLoading,
  Set_latlng,
  ChangeViewTravelDialoge,
  show_eventTimeLine,
} from "../../../../../Redux/actions/actionNames";
import {
  SearchAutoCompleteAction,
  SearchAddressAction,
  PlaceIdDetailsAction,
  OrderInfoAction,
} from "../../../../../Redux/actions/actions";
import closeCircle from "../../../../../assets/close-cricle.svg";
import { PickupDone } from "../../../../../helper/module";
import jalali from "jalali-moment";
import momentJalaali from "moment-jalaali";
import { get_lang } from "../../../../../helper/UserData/userdate";
import { DeleteOrderFromLIST } from "../../../../../service/socket/helper";

const BackdropCustom = withStyles({
  backdrop: {
    zIndex: 5000,
    backgroundColor: "rgba(0, 0, 0, 0)",
    color: "#00000",
  },
})((props) => (
  <Backdrop
    {...props}
    style={{
      zIndex: 5000,
      backgroundColor: "rgba(0, 0, 0, 0)",
      color: "#00000",
    }}
  />
));

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.id,
    };
    this.anchorEl = React.createRef();
  }

  handleCollapse = () => {
    this.props.handleCollapse(this.state.id);
  };

  getOrderInfo =()=>{
    this.props.dispatch(show_eventTimeLine(false))

    if(this.props.orderTypes != this.props.type){
      DeleteOrderFromLIST(this.props.orderId);
    }
    
    this.props.dispatch(
      OrderInfoAction(this.props.dispatch, {
        orderId: this.props.pickupOrderId,
      })
    );
    this.props.dispatch(
      ChangeViewTravelDialoge(true, this.props.pickupOrderId, this.props.customerId)
    );
  }

  render() {
    const { t } = this.props;
    return (
      <passerngerContainer
        className="pickupCustomized vrpickupCustomized"
      >
        <vrpickupdetails className="vrPDpickupdetails">
        <span onClick={this.getOrderInfo} className="vrHeaderLinkToPandD" style={{marginTop:this.props.address ? "6px" :"2px",marginRight:"0px",marginLeft:"0px"}}> <Trans i18nKey={"Details"}> </Trans></span>

          <span className="title" style={{color:"#484848" , fontWeight:"bold",marginTop:!this.props.pickupOrderStatus &&"5px"}}>
            <Trans i18nKey={"Pickup2"}> </Trans>
          </span>
         {this.props.pickupOrderStatus && <span style={{fontWeight:"bold",marginTop:!this.props.pickupOrderStatus &&"5px" ,marginLeft:"8px" ,marginRight:"8px"}} className={`title VR_${this.props.pickupOrderStatus}`} >
            ( <Trans i18nKey={"EDAS_"+this.props.pickupOrderStatus}> </Trans> )
          </span>}

         {this.props.address && <div className={` copey`} style={{width:"320px",marginTop:'8px' }}>
            {this.props.address}
          </div>
}
        </vrpickupdetails>


      </passerngerContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  lang: state.LocalData.lang,
  address: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.pickup
      ? state.ViewTravel.pureData.pickup.address? 
      state.ViewTravel.pureData.pickup.address
      : null
      : null
    : null,

    pickupOrderId:  state.ViewTravel.pureData
    ? state.ViewTravel.pureData.pickupOrderId
      ? state.ViewTravel.pureData.pickupOrderId
      : ""
    : "",
    customerId:state.ViewTravel.pureData
    ? state.ViewTravel.pureData.customerId
      ? state.ViewTravel.pureData.customerId
      : null
    : null,
    pickupOrderStatus :state.ViewTravel.pureData
    ? state.ViewTravel.pureData.pickupOrderStatus
      ? state.ViewTravel.pureData.pickupOrderStatus
      : null
    : null,


    orderId: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.id
      ? state.ViewTravel.pureData.id
      : null
    : null,
  
  type: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.id
      ? state.ViewTravel.pureData.type
      : null
    : null,
    orderTypes:state.OrderList.ActiveOrderType
  


});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
