import { SetOrderListStatsData, SetOrderListStatsLoading } from "../Redux/actions/actionNames";
import { store } from "../Redux/store";
import { OrderApi } from "../service/API/order";
import momentJalaali from "moment-jalaali";
const _ = require("lodash");

let Order = new OrderApi()



export const HandleUpdateOrdersStatistics = async (patch ,mustLoading) => {
    let storeData = store.getState();
    
    let selectedStatus = JSON.parse(storeData.OrderList.selectedStatus)
    let selectedServices = JSON.parse(storeData.OrderList.selectedServices)
    let selectedVehicleType = JSON.parse(storeData.OrderList.selectedVehicleType)
    let selectedZone = JSON.parse(storeData.OrderList.zones)


    let params ={
        type : storeData.OrderList.ActiveOrderType ,
        "dueDate[start]": storeData.OrderList.DateRange
        ? momentJalaali(storeData.OrderList.DateRange.startDate).unix() + "000"
        : null,
      "dueDate[end]": storeData.OrderList.DateRange
        ? (storeData.OrderList.DateRange.endDate ? momentJalaali(storeData.OrderList.DateRange.endDate).unix() + "000" : null)
        : null,
      services: selectedServices,
      status: selectedStatus,
      zones : selectedZone,
      vehicleTypes: selectedVehicleType,
      isScheduled: ((storeData.OrderList.now && storeData.OrderList.isScheduled) || (!storeData.OrderList.now && !storeData.OrderList.isScheduled) ) ? null :  (   (storeData.OrderList.now && !storeData.OrderList.isScheduled ) ? false :  true),
      ...patch
    }
    mustLoading && store.dispatch(SetOrderListStatsLoading(true))
    let data = await Order.Statistics(removeNull(params))
    store.dispatch(SetOrderListStatsData(data))
    mustLoading &&  store.dispatch(SetOrderListStatsLoading(false))

};

export const UpdateOrdersStatistics = _.throttle(HandleUpdateOrdersStatistics, 1000, {leading: false,trailing:true});





const removeNull = (objIn) => {
    let objOut = objIn;
    Object.keys(objOut).forEach(key => {
      if (objOut[key] === null) {
        delete objOut[key];
      }
    });
    return objOut
  }