import caseWorldLogo from "../../../assets/caseWorldLogo.png";
import FavIcon from "../../../assets/PROJECTS/caseWorld.png";

export default function CaseWorld () {
    
    return global.config = {
    "ProjectName": "Case World",
    "LogoType": caseWorldLogo,
    "BaseApi": "https://rest.caseworldweb.com",
    "BaseSocket": "https://socket.caseworldweb.com",
    "BaseTrack": "https://panel.caseworldweb.com",
    "MapCenter": {
        "lat": "49.28140185602322",
        "lng": "-123.11961570009016"
    },
    "Lang": "en",
    "Direction": "ltr",
    "AllLanguages": [{
        "lang": "en",
        "direction": "ltr",
        "i18Lang": "English"
    }],
    "FavIcon": FavIcon,
    "Country": "CA",
    "thirdPartyLoginSupport": true  ,
    mapType :"osm", /* osm | google */ 
    googleApiKey : ""
} }