import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import arrow from "../../../../assets/chevron-down.svg";
import event_flash from "../../../../assets/ArrowEvents.svg";
import Collapse from "@material-ui/core/Collapse";
import momentJalaali from "moment-jalaali";
import { get_calendarType } from '../../../../helper/UserData/userdate';
import styles from './style.module.css';

class App extends React.Component {


  eventOnclick = () => {
    this.props.onClick(this.props.id == this.props.selectedEvent ? null : this.props.id, this.props.id == this.props.selectedEvent ? null : this.props.coordinates)
  }


  render() {

    return (
      <div className={styles.EventTimeLine_ListItem}>

        <div className={styles.lineContainer}>
          <div className={styles.upper} style={{ backgroundColor: this.props.isFirst && "white" }}></div>
          <div className={styles.loweer} style={{ backgroundColor: this.props.isLast && "white" }}></div>
        </div>
        <div className={styles.circule}></div>
        {this.props.firstItemInArray && <span className={styles.ltext}>  {momentJalaali(this.props.createdAt).locale(get_calendarType()).format("D MMM YY")} </span>
        }
        <div className={styles.detailsContainer} onClick={this.eventOnclick}>

          <div className={styles.detailsContainer_details}>
            <span className={styles.status}> {this.props.title} </span>
            <span className={styles.source}> <Trans i18nKey={`${this.props.source}`}> </Trans> </span>
            <span className={styles.time}>  {momentJalaali(this.props.createdAt).locale(get_calendarType()).format("HH:mm")} </span>
            <span className={styles.statusDescription}> {this.props.description} </span>
            <img
              src={arrow} className={styles.collapse}
              style={{ transform: this.props.selectedEvent === this.props.id ? "rotate(180deg)" : "", transition: ".2s all ease-in-out" }}
            />

          </div>

          <Collapse
            className={styles.detailsContaine_collapse}
            in={this.props.selectedEvent === this.props.id}
            timeout="auto"
            unmountOnExit
          >
            <p className={styles.detailsContaine_collapse_description}>
              {this.props.name ? this.props.name : "-----"}
            </p>
            <div className={styles.events_detailsContaine_collaps_prenext}>
              <span className={`VR_${this.props.orderPreviousStatus}`} ><Trans i18nKey={`status${this.props.orderPreviousStatus}`}> </Trans> </span>
              <img
                src={event_flash} className={styles.collapse} />
              <span className={`VR_${this.props.orderStatus}`}> <Trans i18nKey={`status${this.props.orderStatus}`}> </Trans> </span>

            </div>
          </Collapse>
        </div>



      </div>
    );
  }
}

const mapStateToProps = (state) => ({

});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
