


export const GetCategoryByStatus =  (Status , isDispatching , type , driverAssignmentModel ,isDelayed , isPossibleForDelay) => {
    if(isDelayed || isPossibleForDelay){
        return "Delayed"
    }else if( Status == "Pending" && !isDispatching  && driverAssignmentModel == "automatic"){
        return 'DispatchLater'
    }else{
        if(Status == "Pending" && type== "PickupDelivery"){
            return 'Pending'
        }else{
            let index =  getStatus.findIndex((element) => String(element.status) == String(Status) )
            return getStatus[index > -1 ? index : 0].Category
        }
    }




};

const getStatus = [
    {status: 'Assigned' ,Category: "Assigned"},
    {status: 'Pending' ,Category: "Dispatching"},
    {status: 'Started' ,Category: "InProgress"},
    {status: 'Arrived' ,Category: "InProgress"},
    {status: 'PickedUp' ,Category: "InProgress"},
    {status: 'Collecting' ,Category: "Collecting"},
    {status: 'Delivering' ,Category: "Delivering"},
    {status: 'Done' ,Category: "Done"},
    {status: 'SupportCanceled' ,Category: "Canceled"},
    {status: 'DriverCanceled' ,Category: "Canceled"},
    {status: 'CustomerCanceled' ,Category: "Canceled"},
    {status: 'Unassigned' ,Category: "Unassigned"},
    {status: 'Transit' , Category: "Transit"}
]
