import React from "react";
import { Marker } from '@react-google-maps/api';
import { connect } from "react-redux";
import event from "../../../../assets/pin_event.svg";



class App extends React.Component {
  render() {
    let originShow = this.props.loc != null;
    return (
      <React.Fragment>
        {this.props.FavAddressOpenDialoge && originShow ? (
          <Marker
            position={{lat : this.props.loc[0] , lng:this.props.loc[1]}}
            icon={event}
          ></Marker>
        ) : null}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  FavAddressOpenDialoge: state.ViewTravel.eventTimeLine.newEvent.show,
  loc: state.ViewTravel.eventTimeLine.newEvent.mapLocation,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(mapStateToProps, mapDispatchToProps)(App);
