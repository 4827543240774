import React from "react";

import styles from './style.module.css';
import icCalendar from '../../../../../assets/ic/calendar.svg';
import clsx from 'clsx';
import Dialog from "@material-ui/core/Dialog";
import dayjs from "dayjs";
import jalaliday from "jalaliday";
import momentJalaali from "moment-jalaali";
import calendarIsSame from "../../../../../helper/module/calendarIsSame";
import { List, ListItem, ListItemText, Button } from "@material-ui/core";
import { defaultRanges } from "../../../../../helper/module/defaultDateRange";
import { Calendar } from "react-modern-calendar-datepicker";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { withTranslation, Trans } from "react-i18next";
import { connect } from "react-redux";
import { get_calendarType, get_id, get_lang } from "../../../../../helper/UserData/userdate";
import { ListFilterSetDateRange } from "../../../../../Redux/actions/actionNames";
import jalali from "jalali-moment";

dayjs.extend(jalaliday);

// export const DateRangePicker = ({className}) => {

//     return <div className={clsx(styles.date , className)}>
//         <img src={icCalendar} alt="icon"   className={styles.icCalendar}></img>
//         <span className={styles.filterText}>
//             5 July 22:30- 23:00
//         </span>

//     </div>

// };


class DateRangePicker extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showpoper: false,
            selectedDayRange: {
                from: null,
                to: null,
            },
            customDateRanges: defaultRanges(
                get_calendarType() == "jalali" ? "fa" : "en"
            ),
        };
        this.handleshowpoper = this.handleshowpoper.bind(this);
    }

    handleshowpoper = (e) => {
        this.setState({
            showpoper: !this.state.showpoper,
        });
        this.setState({
            customDateRanges: defaultRanges(
                get_calendarType() == "jalali" ? "fa" : "en"
            )
        })
    };

    setDateRangePicker = (e) => {
        if (this.state.selectedDayRange.from && this.state.selectedDayRange.to) {

            let from = jalali(
                this.state.selectedDayRange.from.day +
                "/" +
                this.state.selectedDayRange.from.month +
                "/" +
                this.state.selectedDayRange.from.year,
                get_calendarType() == "jalali" ? "jDD/jMM/jYYYY" : "DD/MM/YYYY"
            );


            let to = this.state.selectedDayRange.to ? jalali(
                this.state.selectedDayRange.to.day +
                "/" +
                this.state.selectedDayRange.to.month +
                "/" +
                this.state.selectedDayRange.to.year,
                get_calendarType() == "jalali" ? "jDD/jMM/jYYYY" : "DD/MM/YYYY"
            ) : null;

            let from_save = from.toString()
            let to_save = to && to.toString()


            this.props.dispatch(
                ListFilterSetDateRange({
                    endDate: to ? (to_save == from_save ? to.endOf("day").toDate() : to.endOf("day").toDate()) : null,
                    startDate: from.startOf("day").toDate(),
                })
            );
            // this.props.dispatch(
            //     GetOrderListAction(this.props.dispatch, {
            //         orderTypes: this.props.orderTypes,
            //         status: this.props.activeStatus.map((status) => status).join("|"),
            //         regexText: this.props.regex,
            //         regexFields: this.props.regex.length > 0 ? this.props.regexField.value : null,
            //         page: 1,
            //         perpage: 10,
            //         services: this.props.AllActiveServices.map((service) => service.id).join(
            //             "|"
            //         ),
            //         vehicleTypes: this.props.AllActiveVehicletypes.map(
            //             (service) => service.id
            //         ).join("|"),
            //         startDate: from.startOf("day").unix() + "000",
            //         endDate: to ? to.endOf("day").unix() + "000" : null,
            //         userId: this.props.justMeFilter ? get_id() : null,
            //         [this.props.AdvancedSort.key]: this.props.AdvancedSort.value
            //     })
            // );

            this.setState({
                showpoper: !this.state.showpoper,
            });
        }


    };

    clearDateRangePicker = (e) => {
        this.props.dispatch(ListFilterSetDateRange(null));
        // this.props.dispatch(
        //     GetOrderListAction(this.props.dispatch, {
        //         orderTypes: this.props.orderTypes,
        //         status: this.props.activeStatus.map((status) => status).join("|"),
        //         regexText: this.props.regex,
        //         regexFields: this.props.regex.length > 0 ? this.props.regexField.value : null,
        //         page: 1,
        //         perpage: 10,
        //         services: this.props.AllActiveServices.map((service) => service.id).join(
        //             "|"
        //         ),
        //         vehicleTypes: this.props.AllActiveVehicletypes.map(
        //             (service) => service.id
        //         ).join("|"),
        //         startDate: null,
        //         endDate: null,
        //         userId: this.props.justMeFilter ? get_id() : null,
        //         [this.props.AdvancedSort.key]: this.props.AdvancedSort.value
        //     })
        // );
        this.setState({
            showpoper: !this.state.showpoper,
            selectedDayRange: {
                from: null,
                to: null,
            },
        });
    };

    setRange = (e) => {
        this.setState({
            selectedDayRange: {
                from: e.from,
                to: e.to,
            },
        });
    };

    detectCalenderLocal = () => {
        //JALALI_EN , fa ,  GREGORIAN_FA  , en
        if (get_calendarType() == "jalali") {
            if (get_lang() == "fa") {
                return "fa";
            } else {
                return "JALALI_EN";
            }
        } else {
            if (get_lang() == "fa") {
                return "GREGORIAN_FA";
            } else {
                return "en";
            }
        }
    };

    optimizeDateFromCalender = (e) => {
        
        this.setState({
            selectedDayRange: e
        });
    }
    
    componentDidMount(){

        if(!this.props.mainLoaded){
            let defaultDate = this.state.customDateRanges[0];

            let from = momentJalaali(
                defaultDate.from.day +
                "/" +
                defaultDate.from.month +
                "/" +
                defaultDate.from.year,
                get_calendarType() == "jalali" ? "jDD/jMM/jYYYY" : "DD/MM/YYYY"
            );
    
    
            let to = defaultDate.to ? momentJalaali(
                defaultDate.to.day +
                "/" +
                defaultDate.to.month +
                "/" +
                defaultDate.to.year,
                get_calendarType() == "jalali" ? "jDD/jMM/jYYYY" : "DD/MM/YYYY"
            ) : null;
    
            let from_save = from.toString()
            let to_save = to && to.toString()
    
    
            this.props.dispatch(
                ListFilterSetDateRange({
                    endDate:  null,
                    // endDate: to ? (to_save == from_save ? to.endOf("day").toDate() : to.toDate()) : null,
                    startDate: from.toDate(),
                })
            );
        }
      
    }


    dateRangePickerTitle = () => {
        if(this.props.DateRange){
            if(this.props.DateRange.startDate){
                if(this.props.DateRange.endDate){
                    if(String(momentJalaali(this.props.DateRange.startDate).locale(get_lang()).format("D MMM")) == String(momentJalaali(this.props.DateRange.endDate).locale(get_lang()).format("D MMM"))){
                        return String(momentJalaali(this.props.DateRange.startDate).locale(get_lang()).format("D MMM"))
                    }else{
                        return `${momentJalaali(this.props.DateRange.startDate).locale(get_lang()).format("D MMM")} 
                        - ${momentJalaali(this.props.DateRange.endDate).locale(get_lang()).format("D MMM")}`
                    }
                }else{
                    return String(momentJalaali(this.props.DateRange.startDate).locale(get_lang()).format("D MMM"))
                }
            }else if(this.props.DateRange.endDate){
                return String(momentJalaali(this.props.DateRange.endDate).locale(get_lang()).format("D MMM"))
            }
        }else{
        return this.props.t("SelectDateRange") 
        }
    }


    render() {


        return (
            <React.Fragment>

                <div className={clsx(styles.date, this.props.className)} onClick={this.handleshowpoper}>
                    <img src={icCalendar} alt="icon" className={styles.icCalendar}></img>
                    <span className={styles.filterText}>
                        {this.dateRangePickerTitle()}
                    </span>

                </div>


                <Dialog
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    open={this.state.showpoper}
                    maxWidth="md"
                    onClose={this.handleshowpoper}
                    disableAutoFocus
                    disableEnforceFocus 
                    disableRestoreFocus 
                >
                    {/* <DateRangePicker
              open={this.state.showpoper}
              toggle={this.handleshowpoper}
              onChange={(e) => {}}
              onConfirm={this.setDateRangePicker}
              onClear={this.clearDateRangePicker}
              initialDateRange={this.props.DateRange ? this.props.DateRange : []}
            /> */}

                    <div className="daterangepickercontainer">
                        <div className="customRangePicker">
                            <List>
                                {this.state.customDateRanges.map((range, idx) => (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <ListItem
                                        button
                                        key={idx}
                                        onClick={(e) => {
                                            this.setRange(range);
                                        }}
                                    >
                                        <ListItemText
                                            primaryTypographyProps={{
                                                variant: "body2",
                                                style: {
                                                    fontWeight: calendarIsSame(
                                                        { from: range.from, to: range.to },
                                                        this.state.selectedDayRange
                                                    )
                                                        ? "bold"
                                                        : "normal",
                                                    textAlign: "center",
                                                },
                                            }}
                                            className="daterangepickerrange"
                                        >
                                            <Trans i18nKey={range.label}> </Trans>
                                        </ListItemText>
                                    </ListItem>
                                ))}
                            </List>
                        </div>

                        <Calendar
                            value={this.state.selectedDayRange}
                            onChange={(e) => {
                                this.optimizeDateFromCalender(e)
                            }}
                            colorPrimary="#148be9" // added this
                            colorPrimaryLight="#148be940" // and this
                            locale={this.detectCalenderLocal()} //JALALI_EN , fa ,  GREGORIAN_FA  , en
                        />
                        <Button
                            className={`calenderConfirm ${(!this.state.selectedDayRange.from || !this.state.selectedDayRange.to) && "grey"}`}
                            onClick={this.setDateRangePicker}
                        >
                            {" "}
                            <Trans i18nKey="confirm"> </Trans>{" "}
                        </Button>
                        {/* <Button
                            className="calenderCancel"
                            onClick={this.clearDateRangePicker}
                        >
                            {" "}
                            <Trans i18nKey="Cancel"> </Trans>{" "}
                        </Button> */}
                    </div>
                </Dialog>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    DateRange: state.OrderList.DateRange,
    mainLoaded: state.SharedData.mainLoaded
});

const mapDispatchToProps = (dispatch) => ({
    dispatch: dispatch,
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(DateRangePicker));




