import React from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import uploadIcon from "./../../../../assets/upload2.svg";



class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }


    render() {
        let { t } = this.props;
        return (
        
        <div style={{ width: "100%", height: "100%", float: "left", display: "flex", justifyContent: "center", flexDirection: "column" }}>
            <div className="uploadingCsvConatiner">
                    <img src={uploadIcon}  />
                    <p> {t("Uploading")} </p>
                    <div className="uploadProccessBar">
                        <span className="uploadProccessBarInner" style={{width: `${this.props.ActiveItem.percentUpload}%`}}> </span>
                    </div>    
            </div>
        </div>
        );
    }
}

const mapStateToProps = (state) => ({

});
const mapDispatchToProps = (dispatch) => ({
    dispatch: dispatch,
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(App));
