import React from "react";
import deliveryProfile from "../../../assets/delivery-man.png";

const Pin = ({ hasActiveOrder, style, onClick, driver }) => {

  return <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="40"
    height="41"
    fill="none"
    viewBox="0 0 40 41"
    onClick={onClick && onClick}
    style={{ ...style }}

  >
    <path
      fill="#fff"
      d="M38 18.445c0 8.04-5.019 14.877-12.02 17.403-3.823 1.927-5.179 5.148-6.103 5.148-1.048.127-2.59-3.152-6.966-5.495C6.461 32.696 2 26.077 2 18.445 2 8.258 10.059 0 20 0s18 8.258 18 18.445z"
    ></path>
    <path
      fill={hasActiveOrder ? "#004CC4" : "#39B526"}
      d="M38 18.445c0 8.04-5.019 14.877-12.02 17.403-3.823 1.927-5.179 5.148-6.103 5.148-1.048.127-2.59-3.152-6.966-5.495C6.461 32.696 2 26.077 2 18.445 2 8.258 10.059 0 20 0s18 8.258 18 18.445z"
    ></path>
    <circle cx="20" cy="18.5" r="16" fill="#fff"></circle>
    <circle
      cx="20"
      cy="18.5"
      r="16"
      fill={hasActiveOrder ? "#004CC4" : "#39B526"}
      fillOpacity="0.1"
    ></circle>

    <g mask={`url(#mask0_1526_31762${driver.id})`}>
      <ellipse
        cx="19.879"
        cy="18.497"
        fill={`url(#pattern1${driver.id})`}
        rx="16.27"
        ry="15.065"
      ></ellipse>
    </g>
    <defs>
      <pattern
        id={`pattern1${driver.id}`}
        width="1"
        height="1"
        patternContentUnits="objectBoundingBox"
      >
        <use
          transform="matrix(.00195 0 0 .0021 0 -.04)"
          xlinkHref={`#image${driver.id}`}
        ></use>
      </pattern>
      <image
        id={`image${driver.id}`}
        width={512}
        height={512}
        preserveAspectRatio="none" href={driver.avatarPhoto.length > 0 ? driver.avatarPhoto : deliveryProfile}
      />
    </defs>
  </svg>







};

export default Pin



