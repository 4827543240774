import doorposts from "../../../assets/doorpost.png";
import FavIcon from "../../../assets/PROJECTS/doorpost.png";

export default function doorpost() {
    
    return  global.config = {
    "ProjectName": "DoorPost",
    "LogoType": doorposts,
    "BaseApi": "https://rest.doorpost.com.au",
    "BaseSocket": "https://socket.doorpost.com.au",
    "BaseTrack": "https://panel.doorpost.com.au",
    "MapCenter": {
        "lat": "46.13531111753044",
        "lng": "-60.184021911779666"
    },
    "Lang": "en",
    "Direction": "ltr",
    "AllLanguages": [{
        "lang": "en",
        "direction": "ltr",
        "i18Lang": "English"
    }, {
        "lang": "fa",
        "direction": "rtl",
        "i18Lang": "Persian"
    }, {
        "lang": "fr",
        "direction": "ltr",
        "i18Lang": "French"
    }, {
        "lang": "ro",
        "direction": "ltr",
        "i18Lang": "Romanian"
    }],
    "FavIcon": FavIcon,
    "Country": "US",
    "thirdPartyLoginSupport": true  ,
    mapType :"osm", /* osm | google */ 
    googleApiKey : ""
} }