import * as React from 'react'
import styles from './style.module.css';
import { UseCRUDDropoffs } from '../../../hooks/useCRUD_Dropoffs';
import DeliveryNewOrder from '../delivery';
import clsx from 'clsx';
import PickupNewOrder from '../pickup';
import AddDropOff from '../addDropOff';
import { useDispatch } from 'react-redux';
import { Set_ActivePickupDropOff } from '../../../Redux/actions/actionNames';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: 0,
  margin: `0`,
  float: "left",
  width: "100%",
  // change background colour if dragging
  // background: isDragging ? "lightgreen" : "grey",

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: "#fbfdff",
  padding: 0,
  width: "100%",
  float: "left",
  position: "relative",
  marginTop: "2px"
});

const queryAttr = "data-rbd-drag-handle-draggable-id";


function DeliveryContainers({ handleCollapse, orderTypeActive, addDropOff, removeDrop }) {
  let { items, sEtItems } = UseCRUDDropoffs()
  const dispatch = useDispatch();
  const [placeholderProps, setPlaceholderProps] = React.useState({});

  // const [items, setItems] = useState(getItems(10));

  const onDragEnd = React.useCallback((result) => {
    dispatch(Set_ActivePickupDropOff({
      id: "",
      type: null
    }));

    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const itemsx = reorder(
      items,
      result.source.index,
      result.destination.index
    );

    sEtItems(itemsx)
    setPlaceholderProps({})
  }, [items]);

  const onDragUpdate = React.useCallback((update) => {
    if (!update.destination) {
      return;
    }
    const draggableId = update.draggableId;
    const destinationIndex = update.destination.index;

    const domQuery = `[${queryAttr}='${draggableId}']`;
    const draggedDOM = document.querySelector(domQuery);

    if (!draggedDOM) {
      return;
    }

    const { clientHeight, clientWidth } = draggedDOM;
    const clientY = parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) + [...draggedDOM.parentNode.children]
      .slice(0, destinationIndex)
      .reduce((total, curr) => {
        const style = curr.currentStyle || window.getComputedStyle(curr);
        const marginBottom = parseFloat(style.marginBottom);
        return total + curr.clientHeight + marginBottom;
      }, 0);

    setPlaceholderProps({
      clientHeight,
      clientWidth,
      clientY,
      clientX: parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingLeft)
    });
  }, [items]);

  return (
    <div className={clsx(styles[orderTypeActive])}>

      {["PickupDelivery", "Pickup", "Ondemand"].indexOf(
        orderTypeActive
      ) > -1 && (
          <PickupNewOrder
            handleCollapse={handleCollapse} />
        )}


      {["Delivery", "PickupDelivery", "Ondemand"].indexOf(
        orderTypeActive
      ) > -1 && <DragDropContext onDragEnd={onDragEnd} onDragUpdate={onDragUpdate}>
          <Droppable droppableId="droppableNewOrder">
            {(providedx) => (
             <div ref={providedx.innerRef} {...providedx.droppableProps}>
                {items.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index} isDragDisabled={items.length == 1}>
                    {(provided, snapshot) => (
                      <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      >
                        <DeliveryNewOrder
                          dropOffsCount={items.length}
                          isDraggingOver={snapshot.isDragging}
                          id={item.id}
                          handleCollapse={handleCollapse}
                          last={items.length - 1 == index}
                          {...item}
                          index={index}
                          first={index == 0}
                          addDropOff={addDropOff}
                          removeDrop={removeDrop}
                          dropOffCount={items.length}
                          orderTypeActive={orderTypeActive}
                        />

                      </div>
                    )}
                  </Draggable>
                ))}
                {providedx.placeholder}
              </div>
            )}
          </Droppable>

        </DragDropContext>}

      {/* {["Delivery", "PickupDelivery", "Ondemand"].indexOf(
        orderTypeActive
      ) > -1 && items.map((item, index) => (

        <DeliveryNewOrder
          dropOffsCount={items.length}
          isDraggingOver={false}
          id={item.id}
          handleCollapse={handleCollapse}
          last={items.length - 1 == index}
          dropOffCount={items.length}
          {...item}
          index={index}
          first={index == 0}
          addDropOff={() => { }}
          removeDrop={removeDrop}
          orderTypeActive={orderTypeActive}
        />
      ))} */}

      <AddDropOff orderTypeActive={orderTypeActive} addDropOff={addDropOff} />

    </div>
  )
}

export default DeliveryContainers