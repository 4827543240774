import HHiOiO from "../../../assets/HiOlogotype.png";
import FavIcon from "../../../assets/PROJECTS/HiO.png";

export default function HiO() {
    
    return  global.config = {
    "ProjectName": "HiO",
    "LogoType": HHiOiO,
    "BaseApi": "https://rest.hio.ro",
    "BaseSocket": "https://socket.hio.ro",
    "BaseTrack": "https://panel.hio.ro",
    "MapCenter": {
        "lat": "47.163279",
        "lng": "27.586531"
    },
    "Lang": "en",
    "Direction": "ltr",
    "AllLanguages": [{
        "lang": "en",
        "direction": "ltr",
        "i18Lang": "English"
    }, {
        "lang": "ro",
        "direction": "ltr",
        "i18Lang": "Romanian"
    }],
    "FavIcon": FavIcon,
    "Country": "RO",
    "thirdPartyLoginSupport": true  ,
    mapType :"osm", /* osm | google */ 
    googleApiKey : ""
}}