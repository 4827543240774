import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import arrow from "../../../../../assets/chevron-down.svg";
import Collapse from "@material-ui/core/Collapse";
import { withTranslation, Trans } from "react-i18next";
import turnOn from "../../../../../assets/turn-on.svg";
import arrow2 from "../../../../../assets/arrow-triangle-down.svg";
import remove from "../../../../../assets/ic-remove.svg";
import icDriver from "../../../../../assets/ic-driver.png";

import bike from "../../../../../assets/bike.svg";
import { withStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import ListItem from "./driverOptions/list";
import SelectableItem from "./driverOptions/selectable";
import {
  Set_Auto_Driver,
  Set_Auto_ActiveVehicleType,
  Set_Manual_ActiveVehicleType,
  Set_Force_Assaigning,
  Set_searchDriverInput,
} from "../../../../../Redux/actions/actionNames";
import { SearchDriverAction, Voip_OutgoingCall } from "../../../../../Redux/actions/actions";
import { getBaseTrackByUrl } from "../../../../../helper/module";
import close from "../../../../../assets/ic-close.svg";
import { get_id, get_lang } from "../../../../../helper/UserData/userdate";
import ManualVehicleType from "./driver/manualVehicleType";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import call from "../../../../../assets/ic-call.svg";
import { snack } from "../../../../../utils/SnackbarUtilsConfigurator";

const BackdropCustom = withStyles({
  backdrop: {
    zIndex: 5000,
    backgroundColor: "rgba(0, 0, 0, 0)",
    color: "#00000",
  },
})((props) => (
  <Backdrop
    {...props}
    style={{
      zIndex: 5000,
      backgroundColor: "rgba(0, 0, 0, 0)",
      color: "#00000",
    }}
  />
));

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropDown: false,
      id: "driver",
      showpoperAuto: false,
      showpoperManual: false,
      list: [0, 1, 2, 3, 5, 6, 9, 5, 8],
      loading: false,
      ordered: false,
      Auto: true,
      idd: this.idgenerator(),
      activeVehicleTypes: [
        {
          type: "list",
          title: "weight",
          id: "weight",
          data: [
            {
              name: "under 1 kg",
              id: "12qceqd",
            },
            {
              name: "under 2 kg",
              id: "12rcesd",
            },
            {
              name: "under 3 kg",
              id: "dwdqced",
            },
            {
              name: "under 4 kg",
              id: "1dwdced",
            },
          ],
        },
        {
          type: "boolean",
          title: "need packed",
          id: "need packed",
        },
      ],
    };
    this.anchorElAuto = React.createRef();
    this.anchorElManual = React.createRef();
  }



  makeOutgoingCall=()=>{
    this.props.dispatch(Voip_OutgoingCall(this.props.dispatch , {
      "to": this.props.manual_number,
      "orderId": null,
      "customerId": null,
      "driverId": this.props.manual_id,
      "dispatcherId": get_id()
    }))
  }


  handleDropDown = () => {
    this.setState({
      showDropDown: !this.state.showDropDown
    })
  };
  OpenDropDown = () => {
    this.setState({
      showDropDown: true
    })
  };

  CloseDropDown = () => {
    this.setState({
      showDropDown: false
    })
  };


  idgenerator = () => {
    var number = Math.random(); // 0.9394456857981651
    number.toString(36); // '0.xtis06h6'
    var id = number.toString(36).substr(2, 9); // 'xtis06h6'
    return id.toString();
  };

  componentDidMount() {
    this.props.setId(this.state.id);
  }

  idgenerator = () => {
    var number = Math.random(); // 0.9394456857981651
    number.toString(36); // '0.xtis06h6'
    var id = number.toString(36).substr(2, 9); // 'xtis06h6'
    return id.toString();
  };

  handleCollapse = () => {
    this.props.handleCollapse({
      type: "driver",
      id: "driver",
    });
  };

  handleshowpoperAuto = () => {
    if (this.props.auto_suggestListData.length > 0) {
      this.setState({
        showpoperAuto: !this.state.showpoperAuto,
      });
    } else {
      if (this.props.auto_suggestListLoading) {
        snack.warning_i18("poperLoading")
      } else {
        snack.warning_i18("noVehicleType")
      }
    }
  };

  handleshowpoperManual = () => {
    this.setState({
      showpoperManual: !this.state.showpoperManual,
    });
    this.SearchDriverAction()
  };

  handleAutoManual = () => {
    this.props.dispatch(Set_Auto_Driver(!this.props.auto));
  };

  handleForceAssaigning = () => {
    this.props.dispatch(Set_Force_Assaigning(!this.props.forceAssaigning));
  };

  handleAutoVehicleTypeSelect = (service) => {
    this.props.dispatch(Set_Auto_ActiveVehicleType(service));
  };

  async handleManualVehicleTypeSelect(service) {
    this.setState({
      showDropDown: false
    })
    // let index = -1;
    //   index = service.vehicletypes.length > 0  ? this.props.auto_suggestListData.findIndex((vehicleType) => {
    //   return vehicleType.id == service.vehicletypes[0];
    // }) : -1 ;
    let array = [];
    this.FindLocalVehicletype(
      service.vehicleTypes,
      this.props.auto_suggestListData,
      (call) => {
        if (call) {
          let manual_activeVehicleType = null;

          if (this.props.manual_activeVehicleType) {
            manual_activeVehicleType = this.props.manual_activeVehicleType;
          } else {
            if (call.length != 0) {
              manual_activeVehicleType = call[0];
            } else {
              snack.warning_i18("DriverVehicleTypeError")
            }
          }

          manual_activeVehicleType &&
            this.props.dispatch(
              Set_Manual_ActiveVehicleType({
                manual_activeVehicleType: manual_activeVehicleType,
                driverAllvehicletypes: call,
                // manual_name: service.givenName,
                // manual_family: service.familyName,
                manual_name: service.name,
                manual_family: "",
                manual_number: service.phone,
                manual_id: service.id,
                manual_code: service.username,
              })
            );
        }
      }
    );
    // if (index > -1) {
    //   VehicleType = this.props.auto_suggestListData[index];
    // }
    // this.props.dispatch(
    //   Set_Manual_ActiveVehicleType({
    //     manual_activeVehicleType: {
    //       ...VehicleType,
    //       options: [],
    //     },
    //     manual_name: service.givenName,
    //     manual_family: service.familyName,
    //     manual_number: service.phone,
    //     manual_id: service.id,
    //     manual_code: service.username,
    //   })
    // );

    //  
  }

  InputOnChange = (e) => {
    this.props.dispatch(
      Set_Manual_ActiveVehicleType({
        [e.target.name.split("-")[0]]: e.target.value,
        manual_id: null,
        searchDriverInput: e.target.value,
        driverAllvehicletypes: [],
      })
    );

    if (!this.state.showpoperManual) {
      this.setState({
        showpoperManual: true,
      });
    }

    this.SearchDriverAction(e.target.value)

  };


  SearchDriverAction = (input) => {
    this.props.dispatch(
      SearchDriverAction(this.props.dispatch, {
        searchRegex: input ? input : this.props.searchDriverInput,
        vehicleTypes: this.props.manual_activeVehicleType ? this.props.manual_activeVehicleType.id : "",

        // givenName: this.props.manual_name,
        // username: this.props.manual_code,
        // familyName: this.props.manual_family,
        // phone: this.props.manual_number,
      })
    );
  }


  FindLocalVehicletype = (driverVehicletypes, localservives, call) => {
    let vehicletype = [];

    driverVehicletypes.forEach((element) => {
      localservives.forEach((localservive) => {
        if (element == localservive.id) {
          vehicletype.push(localservive);
        }
      });
    });

    call(vehicletype);

   
  };

  resetDriver = () => {
    this.props.dispatch(
      Set_Manual_ActiveVehicleType({
        driverAllvehicletypes: [],
        searchDriverInput: "",
        manual_activeVehicleType: null,
        manual_name: "",
        manual_family: "",
        manual_number: "",
        manual_id: "",
        manual_code: "",
      })
    );
  };

  InputOnChangeManual = (e) => {
    this.props.dispatch(Set_searchDriverInput(e.target.value));
  };


  getavailableVehicles = (Vehicles) => {

    let availableVehicles = [];
    Vehicles.forEach((Vehicle) => {
      if (Vehicle.customerTypes.indexOf(this.props.customer.type) > -1) {
        if (Vehicle.includeCustomers.indexOf('all') > -1 || Vehicle.includeCustomers.indexOf(this.props.customer.id) > -1) {
          if (Vehicle.excludeCustomers.indexOf(this.props.customer.id) == -1) {
            availableVehicles.push(Vehicle.title)
          }
        }
      }
    })

    return availableVehicles
  }


  getVehicleError = () => {
    let currentService = this.props.auto ? this.props.auto_activeVehicleType.title : this.props.manual_activeVehicleType.title
    let availableVehicles = this.getavailableVehicles(this.props.auto ? this.props.auto_suggestListData : this.props.manual_suggestListData);

    let S1 = String(this.props.t("serviceCustomerError1")).replace("xxx", currentService) + (availableVehicles.length > 0 ? String(this.props.t("serviceCustomerError3")).replace("xxx", availableVehicles.join(", ")) : "")
    return S1
  }
  render() {
    const { t } = this.props;
    return (
      <passerngerContainer
        className="serviceCustomized"
        style={{ boxShadow: "rgba(128, 128, 128, 0.16) 0px 1px 6px 0px", marginTop: "12px", height: (this.state.showDropDown && !this.props.auto) ? "77px" : "" }}
      >
        <drivertitle onClick={this.handleCollapse}>
          <span>
            {" "}
            <Trans i18nKey={"driver"}> </Trans>{" "}
          </span>
          <personBusinessSwitchContainer className="driverAMswitch">
            <detail
              id="details"
              onClick={this.handleAutoManual}
              className={`${this.props.auto ? "details" : "DEactive"}`}
            >
              <Trans i18nKey={"Auto"}> </Trans>
            </detail>
            <history
              id="history"
              onClick={this.handleAutoManual}
              className={`${!this.props.auto ? "details" : "DEactive"}`}
            >
              <Trans i18nKey={"Manual"}> </Trans>
            </history>
          </personBusinessSwitchContainer>

          {!this.props.auto && <div
            className="forceAssaignConatiner"
            onClick={this.handleForceAssaigning}
          >
            <span>
              {" "}
              <Trans i18nKey={"ForceAssign"}> </Trans>{" "}
            </span>
            <div
              className={`forceAssaigncheckbox ${this.props.forceAssaigning && "ActiveforceAssaigncheckbox"
                }`}
            >
              {" "}
            </div>
          </div>
          }   </drivertitle>

        <div className="pickupcollapsecontainer yodmvydj" style={{ width: "calc(100% - 37px)", marginLeft: "16px", marginRight: "16px", border: "none" }}>
          {this.props.auto && (
            <React.Fragment>
              <autodriverselector
                ref={this.anchorElAuto}
                onClick={this.handleshowpoperAuto}
              >
                {this.props.auto_activeVehicleType &&
                  this.props.auto_suggestListData.length > 0 ? (
                  <React.Fragment>
                    <img
                      src={this.props.auto_activeVehicleType.icon}
                      className="serviceimage"
                    />
                    <servicename>
                      {this.props.auto_activeVehicleType.title}
                    </servicename>
                  </React.Fragment>
                ) : (
                  <servicename style={{ marginLeft: "19px" }}>
                    {" "}
                    <Trans i18nKey={"pleaseSellectParsel"}> </Trans>
                  </servicename>
                )}

                <img
                  src={arrow2}
                  className={` servicearrow ${this.state.showpoperAuto && "rotate180"
                    }`}
                />
              </autodriverselector>
              <serviceOptions
                className={`${this.props.auto_activeVehicleType &&
                  this.props.auto_activeVehicleType.options.length == 0 &&
                  "displayNone"
                  }`}
                style={{ marginLeft: "0px", width: "100%" }}
              >
                {this.props.auto_activeVehicleType &&
                  this.props.auto_activeVehicleType.options.map((item) => {
                    return item.type === "list" ? (
                      <ListItem {...item} />
                    ) : (
                      <SelectableItem {...item} />
                    );
                  })}
              </serviceOptions>
            </React.Fragment>
          )}

          {!this.props.auto && (
            <div>
              {(this.props.manual_id == "" ||
                this.props.manual_id == null) && (
                  <div class="drivermanualContainer">
                    <div style={{ flex: "1" }}>
                      <ClickAwayListener onClickAway={this.CloseDropDown}>
                        <div className={`DriverManualSelectContainer_DRDO ${(this.state.showDropDown && this.props.manual_suggestListOrdered) && "DriverManualSelectContainer_DRDO_Active"}`} style={{zIndex:"12"}}>

                          <input
                            onChange={this.InputOnChange}
                            ref={this.anchorElManual}
                            value={this.props.searchDriverInput}
                            name={"manual_code-" + this.state.idd}
                            autocomplete="z1x2wd"
                            onClick={this.OpenDropDown}
                            className="drivermanualInput"
                            placeholder={t("CodeNameFamilyPhonenumber")}
                          />
                          <div className="lrorjrb">
                            {!this.props.manual_suggestListLoading &&
                              this.state.showDropDown &&
                              this.props.manual_suggestListData.length !== 0 &&
                              this.props.manual_suggestListData.map((item, i) => {
                                return (
                                  <div
                                    className={`newOrder_DriverListItemContainer ${i == 0 && "noBorder"}`}
                                    onClick={() => {
                                      this.handleManualVehicleTypeSelect(item);
                                    }}
                                  >

                                    <img src={icDriver} />
                                    <span className="newOrder_DriverListItemName">
                                      {`${item.name} `}

                                    </span>
                                    <span className="newOrder_DriverListItemNumber">
                                      {" "}
                                      {item.phone}{" "}
                                    </span>
                                    <span className="newOrder_DriverListItemCode">
                                      {" "}
                                      {item.username}{" "}
                                    </span>
                                  </div>
                                );
                              })}

                            {this.props.manual_suggestListLoading &&
                              this.props.manual_suggestListOrdered &&
                              this.state.showDropDown &&
                              (
                                <div>
                                  <span
                                    className="NotravelsListHeader"
                                    style={{ marginTop: "12px" }}
                                  >
                                    <Trans i18nKey={"poperLoading"}> </Trans>
                                  </span>
                                  <span className="NotravelsListText">
                                    <Trans i18nKey={"fetchingdata"}> </Trans>
                                  </span>
                                </div>
                              )}

                            {!this.props.manual_suggestListLoading &&
                              this.props.manual_suggestListOrdered &&
                              this.state.showDropDown &&
                              this.props.manual_suggestListData.length === 0 && (
                                <div>
                                  <span
                                    className="NotravelsListHeader"
                                    style={{ marginTop: "12px" }}
                                  >
                                    <Trans i18nKey={"Noinformationfound"}> </Trans>
                                  </span>
                                  <span className="NotravelsListText">
                                    <Trans i18nKey={"Pleasetryanotherkeyword"}> </Trans>
                                  </span>
                                </div>
                              )}

                          </div>

                        </div>
                      </ClickAwayListener>
                    </div>
                    <ManualVehicleType />

                  </div>
                )}


              {(this.props.manual_id != "" &&
                this.props.manual_id != null) && (
                  <React.Fragment>
                    <div className="driverInfoManualContainer" style={{position:"relative"}}>
                      <img src={remove} onClick={this.resetDriver} />
                      <div className="namePhoneContainer">
                        <p className="npcName"> {`${this.props.manual_name} ${this.props.manual_family}`} </p>
                        <p className="npcPhone"> {`${this.props.manual_number} `} </p>
                      </div>

                      <div className="namePhoneContainer usernameContainer">
                        <p className="npcName"> {`${this.props.manual_code}`} </p>
                        <p className="npcPhone"><Trans i18nKey={"Username"}> </Trans> </p>
                      </div>

                    {global.config.dispatcherSettings.isVoipActvie &&  <img src={call} onClick={this.makeOutgoingCall} className="newOrderDriverVoip" />}

                    </div>
                    {this.props.manual_activeVehicleType && <manualDriverActiveServic>
                      {" "}
                      <img
                        src={this.props.manual_activeVehicleType.icon}
                        className="serviceimage"
                      />{" "}
                      <span> {this.props.manual_activeVehicleType.title} </span>
                    </manualDriverActiveServic>}
                    <serviceOptions style={{ marginLeft: "0px", width: "100%" }}>
                      {this.props.manual_activeVehicleType &&
                        this.props.manual_activeVehicleType.options.map(
                          (item) => {
                            return item.type === "list" ? (
                              <ListItem {...item} />
                            ) : (
                              <SelectableItem {...item} />
                            );
                          }
                        )}
                    </serviceOptions>
                  </React.Fragment>
                )}{" "}
            </div>
          )}
        </div>

        {/* <BackdropCustom
          open={
            this.state.showpoperManual &&
            (this.props.manual_suggestListLoading ||
              this.props.manual_suggestListOrdered)
          }
          onClick={this.handleshowpoperManual}
        >
          <Popper
            style={{
              width: "355px",
              zIndex: "6",
              marginTop: "0px",
              maxHeight: "150x",
            }}
            placement={"bottom-end"}
            id={"passenger"}
            open={
              this.state.showpoperManual &&
              (this.props.manual_suggestListLoading ||
                this.props.manual_suggestListOrdered)
            }
            anchorEl={this.anchorElManual.current}
          >
            <Paper
              style={{
                width: "355px",
                float: "right",
                borderRadius: "8px",
                paddingTop: "5px",
                marginTop: "10px",
                paddingBottom: "5px",
                right: "271px",
                maxHeight: "150px",
                overflowY: "scroll",
                overflowX: "hidden",
              }}
              elevation={3}
            >
              {!this.props.manual_suggestListLoading &&
                this.props.manual_suggestListData.length !== 0 &&
                this.props.manual_suggestListData.map((item) => {
                  return (
                    <div
                      className="DriverListItemContainer"
                      onClick={() => {
                        this.handleManualVehicleTypeSelect(item);
                      }}
                    >
                      <span className="DriverListItemName">
                        {`${item.name} `}

                      </span>
                      <span className="DriverListItemNumber">
                        {" "}
                        {item.phone}{" "}
                      </span>
                      <span className="DriverListItemCode">
                        {" "}
                        {item.username}{" "}
                      </span>
                    </div>
                  );
                })}

              {this.props.manual_suggestListLoading && (
                <div>
                  <span
                    className="NotravelsListHeader"
                    style={{ marginTop: "12px" }}
                  >
                    <Trans i18nKey={"poperLoading"}> </Trans>
                  </span>
                  <span className="NotravelsListText">
                    <Trans i18nKey={"fetchingdata"}> </Trans>
                  </span>
                </div>
              )}

              {!this.props.manual_suggestListLoading &&
                this.props.manual_suggestListOrdered &&
                this.props.manual_suggestListData.length === 0 && (
                  <div>
                    <span
                      className="NotravelsListHeader"
                      style={{ marginTop: "12px" }}
                    >
                      <Trans i18nKey={"Noinformationfound"}> </Trans>
                    </span>
                    <span className="NotravelsListText">
                      <Trans i18nKey={"Pleasetryanotherkeyword"}> </Trans>
                    </span>
                  </div>
                )}
            </Paper>
          </Popper>
        </BackdropCustom> */}

        <BackdropCustom
          open={this.state.showpoperAuto}
          onClick={this.handleshowpoperAuto}
        >
          <Popper
            style={{
              width: "475px",
              zIndex: "12",
              marginTop: "0px",
              maxHeight: "150x",
            }}
            placement={"bottom"}
            id={"passenger"}
            open={this.state.showpoperAuto}
            anchorEl={this.anchorElAuto.current}
          >
            <Paper
              style={{
                width: "476px",
                float: "right",
                borderRadius: "8px",
                paddingTop: "5px",
                marginTop: "10px",
                paddingBottom: "5px",
                right: "271px",
                maxHeight: "150px",
                overflowY: "scroll",
                overflowX: "hidden",
              }}
              elevation={3}
            >
              {this.props.auto_suggestListData &&
                this.props.auto_suggestListData.map((item) => {
                  return (
                    <autodriverselector
                      className="autodriverselectorInList"
                      onClick={() => {
                        this.handleAutoVehicleTypeSelect(item);
                      }}
                    >
                      <img src={item.icon} className="serviceimage" />
                      <servicename>{item.title}</servicename>
                    </autodriverselector>
                  );
                })}
            </Paper>
          </Popper>
        </BackdropCustom>

        {/* {((this.props.customer && this.props.customer.id.length > 0) && this.props.sendOrder.VehicleError && (this.props.auto ? this.props.auto_activeVehicleType : this.props.manual_activeVehicleType) && !this.props.sendOrder.serviceError) && <p className="ServiceError">
          {this.getVehicleError()}
        </p>} */}
      </passerngerContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  auto: state.NewOrder.newOrder.driver.auto,
  auto_activeVehicleType:
    state.NewOrder.newOrder.driver.auto_driver.auto_activeVehicleType,
  auto_suggestListLoading:
    state.NewOrder.newOrder.driver.auto_driver.auto_suggestListLoading,
  auto_suggestListData:
    state.NewOrder.newOrder.driver.auto_driver.auto_suggestListData,
  auto_suggestListOrdered:
    state.NewOrder.newOrder.driver.auto_driver.auto_suggestListOrdered,
  manual_activeVehicleType:
    state.NewOrder.newOrder.driver.manual_driver.manual_activeVehicleType,
  manual_name: state.NewOrder.newOrder.driver.manual_driver.manual_name,
  manual_code: state.NewOrder.newOrder.driver.manual_driver.manual_code,
  manual_family: state.NewOrder.newOrder.driver.manual_driver.manual_family,
  manual_number: state.NewOrder.newOrder.driver.manual_driver.manual_number,
  manual_id: state.NewOrder.newOrder.driver.manual_driver.manual_id,
  manual_suggestListLoading:
    state.NewOrder.newOrder.driver.manual_driver.manual_suggestListLoading,
  manual_suggestListData:
    state.NewOrder.newOrder.driver.manual_driver.manual_suggestListData,
  manual_suggestListOrdered:
    state.NewOrder.newOrder.driver.manual_driver.manual_suggestListOrdered,
  activePickupDropOff: state.NewOrder.newOrder.activePickupDropOff,
  lang: state.LocalData.lang,
  forceAssaigning: state.NewOrder.newOrder.driver.forceAssaigning,
  searchDriverInput:
    state.NewOrder.newOrder.driver.manual_driver.searchDriverInput,
  sendOrder: state.NewOrder.newOrder.sendOrder,
  customer: state.NewOrder.newOrder.customer,

});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
