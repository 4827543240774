import i18n from 'i18next';
import { store } from '../Redux/store';
import { GetCategoryByStatus } from "./get-category-by-status";
import { AddMonitoringOrdersList, DecreaseStatsCategoryNumber, DeleteCategoryOrdersList, IncreaseStatsCategoryNumber, RemoveMonitoringOrders, UpdateCategoryOrdersList, UpdateMonitoringOrdersList, UpdateSearchOrdersList } from "../Redux/actions/actionNames";
import _ from "lodash";
import { getCategoryList } from "../components/main/order-list/components/category-collapse-list/action/getCategoryList";
import { UpdateOrdersStatistics } from "./update-ordersStatistics";


export const UpdateAddOrderBySocket = async (socketOrder) => {
  let storeData = store.getState();

    DeleteOrderBySocket(socketOrder, storeData)



  if (String(storeData.OrderList.search.regexText).length > 2) {

    let index = false;
    index = await storeData.OrderList.search.data.findIndex((order) => { return order.id == socketOrder.id })
    if (index > -1) {
      //update search
      store.dispatch(UpdateSearchOrdersList(socketOrder))
    }

  } else {

    if (await checkStatus(socketOrder, storeData)) {



      if (storeData.Monitoring.orders.open) {
        if (storeData.Monitoring.orders.data[socketOrder.id]) {
          store.dispatch(UpdateMonitoringOrdersList(socketOrder))
        } else {
          store.dispatch(UpdateMonitoringOrdersList(socketOrder))
        }
      }

      let category =  GetCategoryByStatus(socketOrder.status , socketOrder.isDispatching, socketOrder.type , socketOrder.driverAssignmentModel , socketOrder.isDelayed , socketOrder.isPossibleForDelay)
      // let secondCategory =  (socketOrder.isDelayed ? "Delayed" : null)
      let thirdCategory =  (storeData.OrderList.list["Pinned"].data[socketOrder.id] ? "Pinned" : null)

      let categoryData = await storeData.OrderList.list[category]
      let Cursor = storeData.OrderList.cursor
      if (categoryData.data[socketOrder.id] ||/* secondCategory || */ thirdCategory) {

        //update order in Category
        store.dispatch(UpdateCategoryOrdersList({ key: category, data: socketOrder }))
        // secondCategory && store.dispatch(UpdateCategoryOrdersList({ key: secondCategory, data: socketOrder }))
        thirdCategory && store.dispatch(UpdateCategoryOrdersList({ key: thirdCategory, data: socketOrder }))

        UpdateOrdersStatistics()


      } else {

        if (_.isEmpty(categoryData.data)) {
          // getCategoryList(category , null , null , {size : 4})
          // store.dispatch(IncreaseStatsCategoryNumber(category))
          UpdateOrdersStatistics()

        } else {
          // store.dispatch(IncreaseStatsCategoryNumber(category))
          UpdateOrdersStatistics()


          if (storeData.OrderList.cursorSort == "ascending") {
            if (socketOrder[Cursor] < categoryData.endCursor) {
              store.dispatch(UpdateCategoryOrdersList({ key: category, data: socketOrder }))
            }
          } else {
            if (socketOrder[Cursor] > categoryData.endCursor) {
              store.dispatch(UpdateCategoryOrdersList({ key: category, data: socketOrder }))
            }
          }

          // let start = Math.max(categoryData.startCursor, categoryData.endCursor);
          // let end = Math.min(categoryData.startCursor, categoryData.endCursor);

          // if (socketOrder[Cursor] < start && socketOrder[Cursor] > end) {
          //   // add order
          //   store.dispatch(UpdateCategoryOrdersList({ key: category, data: socketOrder }))
          // }
        }

      }
    } else {

      if(DeleteOrderIsExist(socketOrder, storeData)){
        DeleteOrderBySocket(socketOrder, storeData)
        UpdateOrdersStatistics()
      }

      if (storeData.Monitoring.orders.open) {
        if (storeData.Monitoring.orders.data[socketOrder.id]) {
          store.dispatch(RemoveMonitoringOrders(socketOrder))
        }
      }
    }

  }
};



const DeleteOrderBySocket = async (socketOrder, storeData) => {
  let CurrentCategory =  GetCategoryByStatus(socketOrder.status , socketOrder.isDispatching, socketOrder.type , socketOrder.driverAssignmentModel, socketOrder.isDelayed , socketOrder.isPossibleForDelay);
  let OtherCategories =  ["Pending", "DispatchLater", "Dispatching", "Assigned", "InProgress", "Collecting", "Delivering", "Transit", "Canceled", "Done"].filter((value) => { return value != CurrentCategory })
  OtherCategories.forEach(async (item) => {
    let categoryData = await storeData.OrderList.list[item].data
    if (categoryData[socketOrder.id]) {

      store.dispatch(DeleteCategoryOrdersList({ key: item, data: socketOrder }))
      // store.dispatch(DecreaseStatsCategoryNumber(item))

    }

  })
};



const checkStatus = async (socketOrder, storeData) => {
  let selectedStatus = await JSON.parse(storeData.OrderList.selectedStatus)
  if (selectedStatus.length > 0) {

    
    let index = await selectedStatus.indexOf(socketOrder.status)
    return index > -1
  } else {
    return true
  }
}



const DeleteOrderIsExist = async (socketOrder, storeData) => {
  let OrderIsExist = false ;
  ["Pending", "Assigned", "InProgress", "Collecting", "Delivering", "Transit", "Canceled", "Done"].forEach(async (item) => {
    if (storeData.OrderList.list[item].data[socketOrder.id]) {
      OrderIsExist = true
    }
  })
  return OrderIsExist
};
