import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import arrow from "../../../../../assets/chevron-down.svg";
import trash from "../../../../../assets/ic-trash.svg";
import dropoffDone from "../../../../../assets/icon-dropoff-done.svg";

import Collapse from "@material-ui/core/Collapse";
import { withTranslation, Trans } from "react-i18next";
import Backdrop from "@material-ui/core/Backdrop";
import { withStyles } from "@material-ui/core/styles";
import Pod from "./pod";
import jalali from "jalali-moment";
import momentJalaali from "moment-jalaali";
import { get_lang } from "../../../../../helper/UserData/userdate";
import { OrderInfoAction } from "../../../../../Redux/actions/actions";
import { ChangeViewTravelDialoge, show_eventTimeLine } from "../../../../../Redux/actions/actionNames";
import { DeleteOrderFromLIST } from "../../../../../service/socket/helper";


const BackdropCustom = withStyles({
  backdrop: {
    zIndex: 5000,
    backgroundColor: "rgba(0, 0, 0, 0)",
    color: "#00000",
  },
})((props) => (
  <Backdrop
    {...props}
    style={{
      zIndex: 5000,
      backgroundColor: "rgba(0, 0, 0, 0)",
      color: "#00000",
    }}
  />
));



class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.id,
    };
    this.anchorEl = React.createRef();
  }


  handleCollapse = () => {
    this.props.handleCollapse(this.state.id);
  };


  
  getOrderInfo =()=>{
    this.props.dispatch(show_eventTimeLine(false))

    if(this.props.orderTypes != this.props.type){
      DeleteOrderFromLIST(this.props.orderId);
    }

    this.props.dispatch(
      OrderInfoAction(this.props.dispatch, {
        orderId: this.props.deliveryOrderId,
      })
    );
    this.props.dispatch(
      ChangeViewTravelDialoge(true, this.props.deliveryOrderId, this.props.customerId)
    );
  }


  render() {
    return (
      <passerngerContainer
        className={`dropOffCustomized  noboxshadow vrpickupCustomized ${
          this.props.last && "LastdropOffCustomized"
        }`}
      >
        <vrpickupdetails className="vrPDpickupdetails"
        >

          {/* {this.props.drop.status !== "Pending" ? (
            <img className="squree" src={dropoffDone} />
          ) : (
            <squree />
          )} */}
          <span onClick={this.getOrderInfo} className="vrHeaderLinkToPandD" style={{marginTop:this.props.address ? "6px" :"2px",marginRight:"0px",marginLeft:"0px"}}> <Trans i18nKey={"Details"}> </Trans></span>

        <span className="title" style={{color:"#484848" , fontWeight:"bold",marginTop:!this.props.deliveryOrderStatus &&"5px"}}>
            {" "}
            <Trans i18nKey={"Dropoff2"}> </Trans>{" "}
          </span>

          {this.props.deliveryOrderStatus && <span style={{fontWeight:"bold",marginTop:!this.props.deliveryOrderStatus &&"5px" ,marginLeft:"8px" ,marginRight:"8px"}} className={`title VR_${this.props.deliveryOrderStatus}`} >
            ( <Trans i18nKey={"EDAS_"+this.props.deliveryOrderStatus}> </Trans> )
          </span>}

          {this.props.address && <div className={` copey`} style={{width:"320px",marginTop:'8px' }}>
            {this.props.address}
          </div>
}
          {/* <span
            className={`copey`}
          >
            {this.props.drop && this.props.drop.address}
          </span> */}

        </vrpickupdetails>

        
 
      </passerngerContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  lang: state.LocalData.lang,
  address: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.dropoff
      ? state.ViewTravel.pureData.dropoff.address? 
      state.ViewTravel.pureData.dropoff.address
      : null
      : null
    : null,
  deliveryOrderId:  state.ViewTravel.pureData
  ? state.ViewTravel.pureData.deliveryOrderId
    ? state.ViewTravel.pureData.deliveryOrderId
    : ""
  : "",
  customerId:state.ViewTravel.pureData
  ? state.ViewTravel.pureData.customerId
    ? state.ViewTravel.pureData.customerId
    : null
  : null,

  deliveryOrderStatus :state.ViewTravel.pureData
  ? state.ViewTravel.pureData.deliveryOrderStatus
    ? state.ViewTravel.pureData.deliveryOrderStatus
    : null
  : null,

  orderId: state.ViewTravel.pureData
  ? state.ViewTravel.pureData.id
    ? state.ViewTravel.pureData.id
    : null
  : null,

type: state.ViewTravel.pureData
  ? state.ViewTravel.pureData.id
    ? state.ViewTravel.pureData.type
    : null
  : null,
  orderTypes:state.OrderList.ActiveOrderType


});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
