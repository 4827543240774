import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import close from "../../../../../assets/close.svg";
import { withTranslation, Trans } from "react-i18next";
import {
  OrderondemandAction,
  OrderEditondemandAction,
  OrderEditPickupDropOffondemandAction,
  OrderEditPickupDropOffPickupAction,
  OrderEditPickupDropOffDeliveryAction,
  OrderEditPickupDropOffPickupDeliveryAction,
} from "../../../../../Redux/actions/actions";
import { get_id, get_currencyCode, get_lang } from "../../../../../helper/UserData/userdate";
import {
  Edit_Travel_Set_Price,
  Edit_Travel_Operate_Changed,
} from "../../../../../Redux/actions/actionNames";
// import ChangeMethod from "./changeMethod";
import momentJalaali from "moment-jalaali";
import { isArray } from "lodash";

var numeral = require("numeral");

class App extends React.Component {
  constructor(props){
    super(props);
    this.state ={
      loading : false
    }
  }


  priceOnChnage = (e) => {
    this.props.dispatch(Edit_Travel_Operate_Changed());
    !this.props.price.loading &&
      this.props.dispatch(Edit_Travel_Set_Price(e.target.value, true));
  };

  EditOrder = () => {

    if (
      (this.props.price.done ||
      this.props.operatorChanged ||
      !this.props.sendOrder.loading )&& 
      this.props.sendOrder.canSaveOrder
    ) {
      this.setState({loading : true})

      if(this.props.orderType== "Ondemand"){
        return  this.EditOndemand(this.props)
      }else if(this.props.orderType== "Pickup"){
        return this.EditPickup(this.props)
      }else if(this.props.orderType== "Delivery"){
        return this.EditDelivery(this.props)
      }else if(this.props.orderType== "PickupDelivery"){
        return this.EditPickupDelivery(this.props)
      }

  
    }
  };

  EditOndemand =(props)=>{
    props.dispatch(
      OrderEditPickupDropOffondemandAction(
        props.dispatch,
        {
          isScheduled: DetectIsSchedule(props.pickup) ||  DetectIsSchedule(props.firstDropOff) || DetectIsSchedule(props.otherdropOffs),
          dispatcherId:get_id(),
          pickup: {
            customerDescription:props.pickup.customerDescription,
            address: props.pickup.address,
            coordinates: [
              props.pickup.adresss_loc.lng,
              props.pickup.adresss_loc.lat,
            ],
            buildingBlock : props.pickup.buildingBlock,
            floor: props.pickup.floor,
            fullName: props.pickup.fullName,
            phone: props.pickup.phone || "",
            email: props.pickup.email,
            placeId: "",
            room: props.pickup.room,
            schedulePickupNow: ( !props.pickup.scheduleDateAfter  && !props.pickup.scheduleDateBefore ) ? true : false,
          scheduleDateAfter: momentJalaali(
            props.pickup.scheduleDateAfter
          ).isValid()
            ? momentJalaali(
                Number(props.pickup.scheduleDateAfter + "000")
              ).unix()
            : 0,
          scheduleDateBefore: momentJalaali(
            props.pickup.scheduleDateBefore
          ).isValid()
            ? momentJalaali(
                Number(props.pickup.scheduleDateBefore + "000")
              ).unix()
            : 0,
          },
          dropoffs: [
            {
              customerDescription:props.firstDropOff.customerDescription,
              id: props.firstDropOff.id ,
              address: props.firstDropOff.address,
              coordinates: [
                props.firstDropOff.adresss_loc.lng,
                props.firstDropOff.adresss_loc.lat,
              ],
              buildingBlock: props.firstDropOff.buildingBlock,
              floor: props.firstDropOff.floor,
              fullName: props.firstDropOff.fullName,
              phone: props.firstDropOff.phone ,
              email: props.firstDropOff.email,
              placeId: "",
              room: props.firstDropOff.room,
              scheduleDateAfter: momentJalaali(
                props.firstDropOff.scheduleDateAfter
              ).isValid()
                ? momentJalaali(
                    Number(
                      props.firstDropOff.scheduleDateAfter + "000"
                    )
                  ).unix()
                : 0,
              scheduleDateBefore: momentJalaali(
                props.firstDropOff.scheduleDateBefore
              ).isValid()
                ? momentJalaali(
                    Number(
                      props.firstDropOff.scheduleDateBefore + "000"
                    )
                  ).unix()
                : 0,
            },
            ...props.otherdropOffs.map((drop) => {
              return {
                customerDescription: drop.customerDescription,
                id:drop.newDropOff ? undefined  : drop.id,
                address: drop.address,
                coordinates: [drop.adresss_loc.lng, drop.adresss_loc.lat],
                buildingBlock: drop.buildingBlock,
                floor: drop.floor,
                fullName: drop.fullName,
                phone: drop.phone ,
                email: drop.email,
                placeId: "",
                room: drop.room,
                scheduleDateAfter: momentJalaali(
                  drop.scheduleDateAfter
                ).isValid()
                  ? momentJalaali(
                      Number(drop.scheduleDateAfter + "000")
                    ).unix()
                  : 0,
                scheduleDateBefore: momentJalaali(
                  drop.scheduleDateBefore
                ).isValid()
                  ? momentJalaali(
                      Number(drop.scheduleDateBefore + "000")
                    ).unix()
                  : 0,
              };
            }),
          ],

        },
            props.id ,()=>{
              this.setState({loading : false})

            }
      )
    );
  }

  EditPickupDelivery =(props)=>{
    props.dispatch(
      OrderEditPickupDropOffPickupDeliveryAction(
        props.dispatch,
        {
          dispatcherId:get_id(),
          pickup: {
            addressDetail : props.pickup.addressDetail ,
            address: props.pickup.address,
            coordinates: [
              props.pickup.adresss_loc.lng,
              props.pickup.adresss_loc.lat,
            ],
            fullName: props.pickup.fullName,
            phone: props.pickup.phone || "",
            email: props.pickup.email,
            placeId: "",
            completeAfter: momentJalaali(
            props.pickup.scheduleDateAfter
          ).isValid()
            ? momentJalaali(
                Number(props.pickup.scheduleDateAfter + "000")
              ).unix() || 0
            : 0,
            completeBefore: momentJalaali(
            props.pickup.scheduleDateBefore
          ).isValid()
            ? momentJalaali(
                Number(props.pickup.scheduleDateBefore + "000")
              ).unix() || 0
            : 0,
          },
          delivery:  {
            addressDetail : props.firstDropOff.addressDetail ,
            id: props.firstDropOff.id ,
            address: props.firstDropOff.address,
            coordinates: [
              props.firstDropOff.adresss_loc.lng,
              props.firstDropOff.adresss_loc.lat,
            ],
            fullName: props.firstDropOff.fullName,
            phone: props.firstDropOff.phone || "",
            email: props.firstDropOff.email,
            placeId: "",
            completeAfter: momentJalaali(
              props.firstDropOff.scheduleDateAfter
            ).isValid()
              ? momentJalaali(
                  Number(
                    props.firstDropOff.scheduleDateAfter + "000"
                  )
                ).unix() || 0
              : 0,
              completeBefore: momentJalaali(
              props.firstDropOff.scheduleDateBefore
            ).isValid()
              ? momentJalaali(
                  Number(
                    props.firstDropOff.scheduleDateBefore + "000"
                  )
                ).unix() || 0
              : 0,
          },

        },
            props.id,()=>{
              this.setState({loading : false})

            }
      )
    );
  }

  EditPickup =(props)=>{
    props.dispatch(
      OrderEditPickupDropOffPickupAction(
        props.dispatch,
        {
          isScheduled: DetectIsSchedule(props.pickup) ,
          dispatcherId:get_id(),
          pickup: {
            address: props.pickup.address,
            coordinates: [
              props.pickup.adresss_loc.lng,
              props.pickup.adresss_loc.lat,
            ],
            buildingBlock : props.pickup.buildingBlock,
            customerDescription : props.pickup.customerDescription,
            floor: props.pickup.floor,
            fullName: props.pickup.fullName,
            phone: props.pickup.phone || "",
            email: props.pickup.email,
            placeId: "",
            room: props.pickup.room,
          scheduleDateAfter: momentJalaali(
            props.pickup.scheduleDateAfter
          ).isValid()
            ? momentJalaali(
                Number(props.pickup.scheduleDateAfter + "000")
              ).unix()
            : 0,
          scheduleDateBefore: momentJalaali(
            props.pickup.scheduleDateBefore
          ).isValid()
            ? momentJalaali(
                Number(props.pickup.scheduleDateBefore + "000")
              ).unix()
            : 0,
          },
        },
            props.id,()=>{
              this.setState({loading : false})

            }
      )
    );
  }

  EditDelivery =(props)=>{
    props.dispatch(
      OrderEditPickupDropOffDeliveryAction(
        props.dispatch,
        {
          isScheduled:  DetectIsSchedule(props.firstDropOff) ,
          dispatcherId:get_id(),
          dropoff: {
            id: props.firstDropOff.id ,
            address: props.firstDropOff.address,
            coordinates: [
              props.firstDropOff.adresss_loc.lng,
              props.firstDropOff.adresss_loc.lat,
            ],
            buildingBlock: props.firstDropOff.block,
            floor: props.firstDropOff.floor,
            fullName: props.firstDropOff.recieverFullName,
            phone: props.firstDropOff.recieverPhoneNumber || "",
            email: props.firstDropOff.email,
            placeId: "",
            room: props.firstDropOff.unit,
            customerDescription: props.firstDropOff.customerDescription,
            scheduleDateAfter: momentJalaali(
              props.firstDropOff.scheduleDateAfter
            ).isValid()
              ? momentJalaali(
                  Number(
                    props.firstDropOff.scheduleDateAfter + "000"
                  )
                ).unix()
              : 0,
            scheduleDateBefore: momentJalaali(
              props.firstDropOff.scheduleDateBefore
            ).isValid()
              ? momentJalaali(
                  Number(
                    props.firstDropOff.scheduleDateBefore + "000"
                  )
                ).unix()
              : 0,
          },

        },
            props.id,()=>{
              this.setState({loading : false})

            }
      )
    );
  }


  IsActiveFooterBtn=()=>{
    // this.props.price.loading ? 
    // "grey"
    // // : !this.props.price.done ?
    // // "grey" 
    // : this.props.operatorChanged  ?
    // "":
    // "grey"

    // (this.props.auto
    //   ? !this.props.price.done ||
    //     !this.props.operatorChanged ||
    //     this.props.sendOrder.loading
    //   : !this.props.price.done ||
    //     !this.props.operatorChanged ||
    //     this.props.sendOrder.loading ||
    //     !this.props.manual_id) && "grey"

    

  }


  render() {
    return (
      <nRFooter style={{boxShadow:"unset"}}>
        <costConrainer>
          <cost style={{fontSize:"14px",lineHeight:"1.9"}}>
          {this.props.price.loading ? (
              <Trans i18nKey="poperLoading"> </Trans>
            ) : this.props.price.done ? (
              Number(this.props.price.price).toLocaleString(get_lang(), { style:"currency", currency:get_currencyCode(),'minimumFractionDigits':0,'maximumFractionDigits':2})
            ) : (
              Number(this.props.ViewTrvelprice).toLocaleString(get_lang(), { style:"currency", currency:get_currencyCode(),'minimumFractionDigits':0,'maximumFractionDigits':2})
            )}
            {/*`${this.props.currencyCode} ${numeral(
              this.props.ViewTrvelprice
            ).format("0,0")}`*/}
          </cost>
          <price>
            {" "}
            <Trans i18nKey="Price"> </Trans>{" "}
          </price>
        </costConrainer>

        {/* <vr /> */}
        {/* <ChangeMethod /> */}
        <sendOrder
          className={`${
            (!this.props.price.done || !this.props.sendOrder.canSaveOrder ||
                !this.props.operatorChanged ||
                this.state.loading ) && "grey"
          }`}
          onClick={this.EditOrder}
        >
          {this.state.loading ? (
            <Trans i18nKey="poperLoading"> </Trans>
          ) : (
            <Trans i18nKey={"Savechanges"}> </Trans>
          )}
        </sendOrder>
      </nRFooter>
    );
  }
}

const mapStateToProps = (state) => ({
  id: state.EditOrder.editOrder.id,
  currencyCode: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.currencyCode
    : "",
  ViewTrvelprice: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.price
    : "",
    schedulePickupNow:state.ViewTravel.pureData ? state.ViewTravel.pureData.pickup.schedulePickupNow : false ,
  type: state.ViewTravel.pureData.type,
  status: state.ViewTravel.pureData.status,
  code: state.ViewTravel.pureData.code,
  price: state.EditOrder.editOrder.price,
  sendOrder: state.EditOrder.editOrder.editOrder,
  operatorChanged: state.EditOrder.operatorChanged,
  customer: state.EditOrder.editOrder.customer,
  serviceactiveVehicleType: state.ViewTravel.pureData.service,
  serviceoptions: state.EditOrder.editOrder.service.options,
  auto: state.EditOrder.editOrder.driver.auto,
  driveroptions: state.EditOrder.editOrder.driver.options,
  auto_activeVehicleType:
    state.EditOrder.editOrder.driver.auto_driver.auto_activeVehicleType,
  manual_name: state.EditOrder.editOrder.driver.manual_driver.manual_name,
  manual_family:
    state.EditOrder.editOrder.driver.manual_driver.manual_family,
  manual_id: state.EditOrder.editOrder.driver.manual_driver.manual_id,
  manual_activeVehicleType:
    state.EditOrder.editOrder.driver.manual_driver.manual_activeVehicleType,
  pickup: state.EditOrder.editOrder.pickup,
  pickupadresss_loc: state.EditOrder.editOrder.pickup.adresss_loc,
  firstDropOff: state.EditOrder.editOrder.dropOffs.firstDropOff,
  firstDropOffadresss_loc:
    state.EditOrder.editOrder.dropOffs.firstDropOff.adresss_loc,
  otherdropOffs: state.EditOrder.editOrder.dropOffs.otherDropOffs,
  paymentMethod: state.EditOrder.paymentMethod,
  baseFarePrice: state.EditOrder.baseFarePrice,
  surcharges: state.EditOrder.surcharges,
  orderType: state.ViewTravel.pureData
  ? state.ViewTravel.pureData.type
    ? state.ViewTravel.pureData.type
    : null
  : null,
  isScheduled : state.ViewTravel.pureData ? state.ViewTravel.pureData.isScheduled : false

});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));




function DetectIsSchedule(dropOff) {
  let IsSchedule = false
  if(isArray(dropOff)){
    dropOff.forEach((item)=>{
      if ( (Number(item.scheduleDateBefore) > 0 ) || ( Number(item.scheduleDateAfter )) > 0) {
        IsSchedule = true
      } 
    })
    return IsSchedule
  }else{
    if ( (Number(dropOff.scheduleDateBefore) > 0  ) || ( Number(dropOff.scheduleDateAfter )) > 0) {
      return true
    } else {
      return false
  
    }
  }
 

}



