import React from "react";
import Osm from "./osm";
import Google from "./google";
import { useSelector } from "react-redux";


export const MapOrderRouteOnMap = (props) => {
    const pureData = useSelector((state) => state.ViewTravel.pureData)

    if(!pureData){
        return null
    }
    if (props.mapType == "google") {
        return <Google {...props} />
    } else {
        return <Osm {...props} />
    }

};




