import Dropp from "../../../assets/STRIKEAFRICA_logotype.png";
import FavIcon from "../../../assets/PROJECTS/STRIKEAFRICA.png";

export default function STRIKEAFRICA() {
    
    return  global.config = {
    "ProjectName": "STRIKEAFRICA",
    "LogoType": Dropp,
    "BaseApi": "https://rest.strikeapp.link",
    "BaseSocket": "https://socket.strikeapp.link",
    "BaseTrack": "https://panel.strikeapp.link",
    "MapCenter": {
        "lat": 5.361650994487029,
        "lng": -4.005867157045573
    },
    "Lang": "fr",
    "Direction": "ltr",
    "AllLanguages": [{
        "lang": "en",
        "direction": "ltr",
        "i18Lang": "English"
    }],
    "FavIcon": FavIcon,
    "Country": "CI",
    "thirdPartyLoginSupport": false  ,
    mapType :"osm", /* osm | google */ 
    googleApiKey : ""
}}
