import styles from './style.module.css';
import iconsearch from '../../../../../../../assets/icon-ic-search.svg';
import closeiconsearch from '../../../../../../../assets/ic-close.svg';

import {SearchFilter} from "../search-filter"
import { useDispatch, useSelector } from 'react-redux';
import { SetSearchOrderListRegexLoading, SetSearchOrderListRegexRequested, SetSearchOrderListRegexText } from '../../../../../../../Redux/actions/actionNames';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';
import { useCallback, useEffect, useState } from 'react';
import { UpdateOrdersStatistics } from '../../../../../../../utils/update-ordersStatistics';

export const SearchInput = () => {
    const dispatch = useDispatch();
    const repositoryRegexText = useSelector((state) => state.OrderList.search.regexText)
    const [regexText , setRegexText] = useState("");

    const { t } = useTranslation();
    const debouncedChangeHandler = useCallback(
        debounce(requestSearch, 300)
      , []);

    function search(e){
        setRegexText(e.target.value)
        if(String(e.target.value).length > 0 ){
            debouncedChangeHandler(e.target.value)
        }else if(String(repositoryRegexText).length > 0 && String(e.target.value).length == 0){
            requestSearch(e.target.value)
            dispatch(SetSearchOrderListRegexRequested(false))
            dispatch(SetSearchOrderListRegexLoading(false))
            UpdateOrdersStatistics()

        }
    }

    useEffect(()=>{
        setRegexText(repositoryRegexText)
    } , [repositoryRegexText] )

    function closeSearch(e){
        setRegexText("")
        dispatch(SetSearchOrderListRegexText(""))
        dispatch(SetSearchOrderListRegexRequested(false))
        dispatch(SetSearchOrderListRegexLoading(false))
        UpdateOrdersStatistics()
    }
    function requestSearch(value){
        dispatch(SetSearchOrderListRegexText(value))
    }
    
    return <div className={styles.searchInput}>

        <img src={iconsearch} className={styles.iconSearchInput}/>
        <input placeholder={t('Search')} value={regexText} onChange={search} className={styles.searchInputText} />
        {String(regexText).length > 0 && <img src={closeiconsearch} className={styles.closeiconsearch} onClick={closeSearch}/>}
        <SearchFilter />

    </div>

};




