import { debounce, isEmpty ,throttle } from 'lodash';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UpdateCategoryOrdersListSorted } from '../Redux/actions/actionNames';
import { SortToArrayCategoryListData } from '../service/worker/SortToArrayCategoryListData';


const useThrottledDispatch = () => {
  const dispatch = useDispatch();
  const throttled = debounce(
    (v) => {
      // comment this out to throttle
      dispatch(v);
    },
    200,
    { leading: false, trailing: true }
  );
  return useCallback(
    (value) => {
      throttled(UpdateCategoryOrdersListSorted(value));
    },
    [throttled]
  );
};


export function GetCategoryListData({ cursor, cursorSort, categoryName }) {
  const [data, setData] = useState([]);
  const [workerStatic, setWrkerStatic] = useState(null);
  const pureData = useSelector((state) => state.OrderList.list[categoryName].data)
  const sortedData = useSelector((state) => state.OrderList.list[categoryName].sortedData)
  const dispatch = useThrottledDispatch();



  const deboucesSetData = debounce(value => {
    // setData(value)
    dispatch({key : categoryName , data: value})
  }, 300);


  const SetData = useCallback(value => deboucesSetData(value), []);
  // const SendToWorker = useMemo(() => {
  //   SortToArrayCategoryListData(workerStatic, pureData, cursor, cursorSort, (worker) => {
  //     setWrkerStatic(worker)
  //   }, (call) => {
  //     SetData(call)
  //     // setData(call)
  //   })
  // }, [pureData, cursor, cursorSort]);



  useEffect(() => {
    if (isEmpty(data) && isEmpty(pureData)) {
      // nothing
    } else {

      SortToArrayCategoryListData(workerStatic, pureData, cursor, cursorSort, (worker) => {
        setWrkerStatic(worker)
      }, (call) => {
        // SetData(call)
        setData(call)
      })
      
    }


  }, [pureData]);

  return { data , categoryIsEmpty: isEmpty(pureData) };
};

