import React, { useLayoutEffect, useRef, useState } from "react";
import { Map, Marker, TileLayer, Popup, Polyline } from "react-leaflet";
import L from "leaflet";
import Pin from "../Pin";
import ReactDOMServer from "react-dom/server";
import deliveryProfile from "../../../../assets/delivery-man.png";
import styles from './style.module.css';
import { SetActiveDriverList, SetDriverListOpenDialoge, SetDriverPopUp, SetOrderPinPopUp, SetOrderPinPopUpPosition, SetSelectedMonitoringDriver, SetSelectedMonitoringOrder } from "../../../../Redux/actions/actionNames";
import { useDispatch, useSelector } from "react-redux";
import { GetDriverInfoAction, GetDriverOrderListAction } from "../../../../Redux/actions/actions";
import "./customPopup.css";
import { useTranslation } from "react-i18next";
import { useDriverMonitoringPin } from "../../../../hooks/useDriverMonitoringPin";
import { getOrderMonitoringBounding } from "../getOrderMonitoring";

const createtIcon = (hasActiveOrder, Driverselected, driver, selectedDriver) => {
  return new L.divIcon({
    className: "",
    html: ReactDOMServer.renderToString(CreateIconSelectedOsm(hasActiveOrder, Driverselected, driver, selectedDriver)),
    iconSize: [40, 41],
    iconAnchor: [20, 42],
  });
};



const CreateIconSelectedOsm = (hasActiveOrder, Driverselected, data, selectedDriver) => {
  return <div>
    <Pin hasActiveOrder={hasActiveOrder} driver={data} style={!selectedDriver && { opacity: ".2" }} />

    {/* {Driverselected == data.id  && <div id={`${data.id}popup`} className={"monitoringDriverSelected markerIcon "+styles.OsmMarkerPopUp} style={{ left: `-${(width / 2) - 25}px`}}>
      <span className={styles.OsmMarkerPopUpName}> {data.name} </span>
      <span className={styles.OsmMarkerPopUpButton} onClick={OpenUserProfile} > Profileee </span>
    </div>} */}

  </div>

};


const App = ({ position, hasActiveOrder, driver }) => {
  const [width, setWidth] = useState(0);
  const dispatch = useDispatch();
  const DriverPopUp = useSelector((state) => state.Monitoring.DriverPopUp)
  const selectedOrderDriver = useSelector((state) => state.Monitoring.selectedOrderDriver)
  const selectedOrder = useSelector((state) => state.Monitoring.selectedOrder)
  const mapRef = useSelector((state) => state.Map.mapRef)
  const cursor = useSelector((state) => state.OrderList.cursor)
  const cursorSort = useSelector((state) => state.OrderList.cursorSort)
  const { t, i18n } = useTranslation();
  let {driverIsSelected} = useDriverMonitoringPin(driver.id)

  useLayoutEffect(() => {
    if (DriverPopUp && !width) {
      var element = document.getElementById(`${driver.id}popup`);
      var positionInfo = element && element.getBoundingClientRect();
      var width = positionInfo && positionInfo.width;
      width && setWidth(width);
    }
  }, [DriverPopUp]);


  function HandleDriverselect() {
    dispatch(SetDriverPopUp(driver.id))
    dispatch(SetSelectedMonitoringDriver(driver.id))

    dispatch(SetSelectedMonitoringOrder(null))
    dispatch(SetOrderPinPopUp(null))
    dispatch(SetOrderPinPopUpPosition(null))

    getOrderMonitoringBounding(driver)


  };

  function OpenUserProfile() {
    mapRef.current.leafletElement.closePopup()
    // dispatch(SetDriverListOpenDialoge(false))
    setTimeout(() => {
      dispatch(SetActiveDriverList(driver));
      dispatch(GetDriverInfoAction(dispatch, driver.id))
      dispatch(SetDriverListOpenDialoge(true))
    }, 110);

    dispatch(
      GetDriverOrderListAction(dispatch, {
        cursor: cursor,
        cursorSort: cursorSort,
        status: ["Assigned", "PickedUp", "Started", "Arrived"],
        size: 200,
        driverId: driver.id,
      } , false)
    );
  };


  if(!driverIsSelected){
    return null
  }
  
  return <>
    <Marker
      animate={true}
      position={position}
      onclick={HandleDriverselect}
      icon={createtIcon(hasActiveOrder, DriverPopUp ? DriverPopUp : null, driver, (selectedOrderDriver || selectedOrder) ? selectedOrderDriver == driver.id : true)}
    >

      <Popup id={`${driver.id}popup`} className={"monitoringDriverSelected markerIcon driverPopup " + styles.OsmMarkerPopUp} style={{ left: `-${(width / 2) - 60}px !important` }}>
        <span className={styles.OsmMarkerPopUpName}> {driver.name} </span>
        <span className={styles.OsmMarkerPopUpButton} onClick={OpenUserProfile} > {t("Profile")} </span>
      </Popup>

    </Marker>

    {/* {DriverPopUp == driver.id  && <div position={position} id={`${driver.id}popup`} className={"monitoringDriverSelected markerIcon "+styles.OsmMarkerPopUp} style={{ left: `-${(width / 2) - 25}px`}}>
      <span className={styles.OsmMarkerPopUpName}> {driver.name} </span>
      <span className={styles.OsmMarkerPopUpButton} onClick={OpenUserProfile} > Profileee </span>
    </div>} */}
  </>
};

export default App


