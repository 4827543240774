import logoType from "../../../assets/WeeDeliver_logotype.png";
import FavIcon from "../../../assets/PROJECTS/WeeDeliver_fav_icon.png";

export default function WeeDeliver() {
    
    return global.config = {
    "ProjectName": "WeeDeliver",
    "LogoType": logoType,
    "BaseApi": "https://rest.weedeliver.live",
    "BaseSocket": "https://socket.weedeliver.live",
    "BaseTrack": "https://track.weedeliver.live",
    "MapCenter": {
        "lat": "39.95272956692207",
        "lng": "-75.16731254160085"
    },
    "Lang": "en",
    "Direction": "ltr",
    "AllLanguages": [
        {
        "lang": "en",
        "direction": "ltr",
        "i18Lang": "English"
    }],
    "FavIcon": FavIcon,
    "Country": "US",
    "thirdPartyLoginSupport": true  ,
    mapType :"osm", /* osm | google */ 
    googleApiKey : ""
} }