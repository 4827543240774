import React from 'react';
import {LoginLayout} from '../layout/login/login'


function LoginPage(props) {
    return <LoginLayout {...props}/>
}

export default LoginPage;

