import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import Language from "../../../../../../assets/icon-ic-language.svg";
import { get_lang, set_lang, set_direction } from "../../../../../../helper/UserData/userdate";
import Backdrop from "@material-ui/core/Backdrop";
import { withStyles } from "@material-ui/core/styles";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import IconClose from "../../../../../../assets/icon-close.svg";
import tik from "../../../../../../assets/icon-ic-check.svg";

import { rtlLtrCssPatchSelector } from "../../../../../../utils";
import { ServicesApi } from "../../../../../../service/API/service";
import { SetSharedDataServices, SetSharedDataVehicleTypes } from "../../../../../../Redux/actions/actionNames";
import { restClient } from "../../../../../../service/API/axios";

const BackdropCustom = withStyles({
  backdrop: {
    zIndex: 5000,
    backgroundColor: "rgba(0, 0, 0, 0)",
    color: "#00000",
  },
})((props) => (
  <Backdrop
    {...props}
    style={{
      zIndex: 5000,
      width: "100vw",
      height: "100vh",
      backgroundColor: "#00000066",
      color: "#00000",
    }}
  />
));

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openMenu: false,
    };
  }

  handleOpen = () => {
    this.setState({ openMenu: !this.state.openMenu });
  };

  updateSocket = (lang) => {
    this.props.socket.query['language'] = lang ;
    this.props.socket.disconnect().connect()
  };


  handleLanguage = async (item)=>{
    rtlLtrCssPatchSelector(item.direction , item.lang )

    set_lang(item.lang);
    // restClient.defaults.headers.common["Accept-Language"] = item.lang
    restClient.defaults.headers["Accept-Language"]= item.lang

    set_direction(item.direction);
    this.props.dispatch({
      type: "changeDirection",
      direction: item.direction,
      fullDirection:item.direction,
      lang: item.lang,
    });
    this.props.i18n.changeLanguage(
      item.lang
    );
    this.setState({ status: this.state });
    this.setState({ openMenu: !this.state.openMenu });
    this.updateSocket(item.lang)

    const Services = new ServicesApi();
    const servicesData = await Services.Services()
    this.props.dispatch(SetSharedDataServices(servicesData.Services))
    const vehicleTypeData = await Services.VehicleType()
    this.props.dispatch(SetSharedDataVehicleTypes(vehicleTypeData.VehicleTypes))

  }

  render() {
    return (
      <React.Fragment>
        <span onClick={this.handleOpen} className="langContainer">
          <div className="SignOutContainer">
            <img className="SignOut" src={Language} />
            <span className="LogoutText">
              {" "}
              <Trans i18nKey={"Language"}> </Trans>
            </span>
            <span className="CurrLanguage">
              {" "}
              <Trans i18nKey={get_lang()}> </Trans>
            </span>
          </div>
        </span>

        {this.state.openMenu && (
          <BackdropCustom open={this.state.openMenu}>
            <Paper
              style={{ width: "376px", height: "auto", borderRadius: "8px",paddingBottom:"10px" }}
            >
              <ClickAwayListener onClickAway={this.handleOpen}>
                <div style={{ width: "100%", height: "100%" }}>
                  <div className="podheader">
                    <img
                      src={IconClose}
                      className="close"
                      onClick={this.handleOpen}
                    />
                    <span className="textheader"> {this.props.t("Language")} </span>
                  </div>

                    {global.config.AllLanguages.map((item)=>{
                     return (
                       <div
                         className={
                           item.lang == get_lang()
                             ? "itemLanguageActive"
                             : "itemLanguageInActive"
                         }
                         onClick={()=>{this.handleLanguage(item)}}
                       >
                         <span className="languageText">
                           {" "}
                           <Trans i18nKey={item.i18Lang}> </Trans>
                         </span>
                         {item.lang == get_lang() ? (
                           <img className="tik" src={tik} />
                         ) : null}
                       </div>
                     );  
                    })}

                </div>
              </ClickAwayListener>
            </Paper>
          </BackdropCustom>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  socket: state.Socket.socket,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
