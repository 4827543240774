import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { PerssianLabel } from "./locales/fa/translation";
import { EnglishLabel } from "./locales/en/translation";
import { FrenchLabel } from "./locales/fr/translation";
import { RomanianLabel } from "./locales/ro/translation";
import { DutchLabel } from "./locales/nl/translation";
import { FinnishLabel } from "./locales/fi/translation";
import { SpanishLabel } from "./locales/es/translation";
import { Portuguese } from "./locales/pt/translation";
import { GreekLabel } from "./locales/el/translation";
import { AustrianGermanLabel } from "./locales/deu/translation";
import { GermanLabel } from "./locales/de/translation";

import LanguageDetector from 'i18next-browser-languagedetector';




i18n.use(initReactI18next) // passes i18n down to react-i18next
.use(LanguageDetector)
.init({
  resources: {
    en: {
      translation: EnglishLabel,
    },
    el: {
      translation: GreekLabel,
    },
    fa: {
      translation: PerssianLabel,
    },
    fr: {
      translation: FrenchLabel,
    },
    ro: {
      translation: RomanianLabel,
    },
    nl: {
      translation: DutchLabel,
    },
    fi: {
      translation: FinnishLabel,
    },
    es: {
      translation: SpanishLabel,
    },
    pt: {
      translation: Portuguese,
    },
    'de-AT': {
      translation: AustrianGermanLabel,
    },
    de: {
      translation: GermanLabel,
    },
  },
  lng: "en",
  debug: false,
  fallbackLng: 'en',

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
