import { Component } from "react";
import { connect } from "react-redux";
import { CalculatePriceWorker } from "../../../helper/worker/CalculatePriceWorker";
import { DetectOndemandOrReserve } from "../../../helper/worker/DetectOndemandOrReserve";
import { CalculatePriceReserveWorker } from "../../../helper/worker/CalculatePriceReserveWorker";
import { CanOrderCalculateTravelCost } from "../../../helper/worker/CanOrderCalculateTravelCost";
import momentJalaali from "moment-jalaali";
import { EdittPriceNewOrderChanged, EdittPriceNewOrder_manualy } from "../../../Redux/actions/actionNames";

class App extends Component {
  componentDidMount() {
    DetectOndemandOrReserve(
      {
        pickup: this.props.pickup,
        dropOffs: [this.props.firstDropOff, this.props.otherdropOffs],
      },
      (call) => {
        if (call === "ondeman") {
          CalculatePriceWorker(
            {
              // customerType:this.props.person ? "individual" :"business",
              serviceactiveVehicleType: this.props.serviceactiveVehicleType,
              serviceoptions: this.props.serviceoptions,
              auto: this.props.auto,
              driveroptions: this.props.driveroptions,
              auto_activeVehicleType: this.props.auto_activeVehicleType,
              manual_activeVehicleType:
                this.props.manual_activeVehicleType,
              pickup: {
                address: this.props.pickup.address,
                // block: this.props.pickup.block,
                // floor: this.props.pickup.floor,
                // senderFullName: this.props.pickup.senderFullName,
                // senderPhoneNumber: this.props.pickup.senderPhoneNumber,
                // unit: this.props.pickup.unit,
              },
              pickupadresss_loc: this.props.pickupadresss_loc,
              firstDropOff: {
                address: this.props.firstDropOff.address,
                // block: this.props.firstDropOff.block,
                // floor: this.props.firstDropOff.floor,
                // recieverFullName: this.props.firstDropOff.recieverFullName,
                // recieverPhoneNumber: this.props.firstDropOff.recieverPhoneNumber,
                // unit: this.props.firstDropOff.unit,
              },
              firstDropOffadresss_loc: this.props.firstDropOffadresss_loc,
              // customerType:this.props.person ? "individual" :"business",
              otherdropOffs: this.props.otherdropOffs.map((drop) => {
                return {
                  ...{ address: drop.address },
                  ...{ adresss_loc: drop.adresss_loc },
                  ...{ block: drop.block },
                  ...{ floor: drop.floor },
                  ...{ id: drop.id },
                  ...{ recieverFullName: drop.recieverFullName },
                  ...{ recieverPhoneNumber: drop.recieverPhoneNumber },
                  ...{ unit: drop.unit },
                };
              }),
            },
            this.props.dispatch
          );
          this.props.dispatch(EdittPriceNewOrderChanged(false))

        } else {
          CalculatePriceReserveWorker(
            {
              // customerType:this.props.person ? "individual" :"business",


              serviceactiveVehicleType: this.props.serviceactiveVehicleType,
              serviceoptions: this.props.serviceoptions,
              auto: this.props.auto,
              driveroptions: this.props.driveroptions,
              auto_activeVehicleType: this.props.auto_activeVehicleType,
              manual_activeVehicleType:
                this.props.manual_activeVehicleType,
              pickup: {
                address: this.props.pickup.address,
                scheduleDateAfter: momentJalaali(
                  this.props.pickup.scheduleDateAfter
                ).isValid()
                  ? momentJalaali(
                    Number(this.props.pickup.scheduleDateAfter + "000")
                  ).unix()
                  : 0,
                scheduleDateBefore: momentJalaali(
                  this.props.pickup.scheduleDateBefore
                ).isValid()
                  ? momentJalaali(
                    Number(this.props.pickup.scheduleDateBefore + "000")
                  ).unix()
                  : 0,
                // senderFullName: this.props.pickup.senderFullName,
                // senderPhoneNumber: this.props.pickup.senderPhoneNumber,
                // unit: this.props.pickup.unit,
              },
              pickupadresss_loc: this.props.pickupadresss_loc,
              firstDropOff: {
                address: this.props.firstDropOff.address,
                scheduleDateAfter: momentJalaali(
                  this.props.firstDropOff.scheduleDateAfter
                ).isValid()
                  ? momentJalaali(
                    Number(
                      this.props.firstDropOff.scheduleDateAfter + "000"
                    )
                  ).unix()
                  : 0,
                scheduleDateBefore: momentJalaali(
                  this.props.firstDropOff.scheduleDateBefore
                ).isValid()
                  ? momentJalaali(
                    Number(
                      this.props.firstDropOff.scheduleDateBefore + "000"
                    )
                  ).unix()
                  : 0,
                // block: this.props.firstDropOff.block,
                // floor: this.props.firstDropOff.floor,
                // recieverFullName: this.props.firstDropOff.recieverFullName,
                // recieverPhoneNumber: this.props.firstDropOff.recieverPhoneNumber,
                // unit: this.props.firstDropOff.unit,
              },
              firstDropOffadresss_loc: this.props.firstDropOffadresss_loc,
              // customerType:this.props.person ? "individual" :"business",
              otherdropOffs: this.props.otherdropOffs.map((drop) => {
                return {
                  ...{ address: drop.address },
                  ...{ adresss_loc: drop.adresss_loc },
                  ...{ block: drop.block },
                  ...{ floor: drop.floor },
                  ...{ id: drop.id },
                  ...{ recieverFullName: drop.recieverFullName },
                  ...{ recieverPhoneNumber: drop.recieverPhoneNumber },
                  ...{ unit: drop.unit },
                  ...{
                    scheduleDateAfter: momentJalaali(
                      drop.scheduleDateAfter
                    ).isValid()
                      ? momentJalaali(
                        Number(drop.scheduleDateAfter + "000")
                      ).unix()
                      : 0,
                  },
                  ...{
                    scheduleDateBefore: momentJalaali(
                      drop.scheduleDateBefore
                    ).isValid()
                      ? momentJalaali(
                        Number(drop.scheduleDateBefore + "000")
                      ).unix()
                      : 0,
                  },
                };
              }),
            },
            this.props.dispatch
          );
          this.props.dispatch(EdittPriceNewOrderChanged(false))

        }
      }
    );
  }

  componentWillReceiveProps(nextProps) {
    CanOrderCalculateTravelCost(
      {
        calculatedPrice: (this.props.Pricechanged == false && (nextProps.Pricechanged !== this.props.Pricechanged)) ? true : false,
        person: this.props.person,
        serviceactiveVehicleType: this.props.serviceactiveVehicleType,
        serviceoptions: this.props.serviceoptions,
        driveroptions: this.props.driveroptions,
        auto_activeVehicleType: this.props.auto_activeVehicleType,
        manual_activeVehicleType:  this.props.manual_activeVehicleType,
        pickupadresss_loc: this.props.pickupadresss_loc,
        
        pickupscheduleDateAfter: momentJalaali(
          this.props.pickupscheduleDateAfter 
        ).isValid()
          ? momentJalaali(
            Number(this.props.pickupscheduleDateAfter  + "000")
          ).unix()
          : 0,
          pickupscheduleDateBefore: momentJalaali(
            this.props.pickupscheduleDateBefore 
          ).isValid()
            ? momentJalaali(
              Number(this.props.pickupscheduleDateBefore  + "000")
            ).unix()
            : 0,

        firstDropOffadresss_loc: this.props.firstDropOffadresss_loc,
        otherdropOffs: this.props.otherdropOffs.map((drop) => {
          return {
            ...{ adresss_loc: drop.adresss_loc },
          };
        }),
      },
      {
        calculatedPrice: (nextProps.Pricechanged == true && (nextProps.Pricechanged !== this.props.Pricechanged)) ? false : false,
        person: nextProps.person,
        serviceactiveVehicleType: nextProps.serviceactiveVehicleType,
        serviceoptions: nextProps.serviceoptions,
        driveroptions: nextProps.driveroptions,
        auto_activeVehicleType: nextProps.auto_activeVehicleType ,
        manual_activeVehicleType: nextProps.manual_activeVehicleType,
        pickupadresss_loc: nextProps.pickupadresss_loc,
        pickupscheduleDateAfter: momentJalaali(
          nextProps.pickupscheduleDateAfter 
        ).isValid()
          ? momentJalaali(
            Number(nextProps.pickupscheduleDateAfter  + "000")
          ).unix()
          : 0,
          pickupscheduleDateBefore: momentJalaali(
            nextProps.pickupscheduleDateBefore 
          ).isValid()
            ? momentJalaali(
              Number(nextProps.pickupscheduleDateBefore  + "000")
            ).unix()
            : 0,
        firstDropOffadresss_loc: nextProps.firstDropOffadresss_loc,
        otherdropOffs: nextProps.otherdropOffs.map((drop) => {
          return {
            ...{ adresss_loc: drop.adresss_loc },
          };
        }),
      },
      (call) => {

        console.log("CanOrderCalculateTravelCost ondemand 1 ",call)
        if (call) {
          DetectOndemandOrReserve(
            {
              pickup: nextProps.pickup,
              dropOffs: [nextProps.firstDropOff, nextProps.otherdropOffs],
            },
            (call) => {
              console.log("CanOrderCalculateTravelCost ondemand DetectOndemandOrReserve1 ",call)

              if (call === "ondeman") {
                CalculatePriceWorker(
                  {
                    // customerType:nextProps.person ? "individual" :"business",
                     PREV : (nextProps.manually_changed && nextProps.PriceCalculated) ? {
                      surcharges: nextProps.PriceCalculated.surcharges.map((surchargesItem)=>{
                        return        {
                          id: surchargesItem.id,
                          surchargeId: surchargesItem.surchargeId,
                          price: surchargesItem.price
                        }
                      }) ,
                      baseFarePrice: nextProps.PriceCalculated.baseFarePrice,
                      tax: nextProps.PriceCalculated.tax,
                      promoCode: nextProps.customer.id.length > 0  ? nextProps.PriceCalculated.promoCode : "",
                      ...nextProps.customer.id.length > 0 ?{  customerId:  nextProps.customer.id } : {}

                    } : null,
                    serviceactiveVehicleType: nextProps.serviceactiveVehicleType,
                    serviceoptions: nextProps.serviceoptions,
                    auto: nextProps.auto,
                    driveroptions: nextProps.driveroptions,
                    auto_activeVehicleType: nextProps.auto_activeVehicleType,
                    manual_activeVehicleType:
                      nextProps.manual_activeVehicleType,
                    pickup: {
                      address: nextProps.pickup.address,
                      // block: nextProps.pickup.block,
                      // floor: nextProps.pickup.floor,
                      // senderFullName: nextProps.pickup.senderFullName,
                      // senderPhoneNumber: nextProps.pickup.senderPhoneNumber,
                      // unit: nextProps.pickup.unit,
                    },
                    pickupadresss_loc: nextProps.pickupadresss_loc,
                    firstDropOff: {
                      address: nextProps.firstDropOff.address,
                      // block: nextProps.firstDropOff.block,
                      // floor: nextProps.firstDropOff.floor,
                      // recieverFullName: nextProps.firstDropOff.recieverFullName,
                      // recieverPhoneNumber: nextProps.firstDropOff.recieverPhoneNumber,
                      // unit: nextProps.firstDropOff.unit,
                    },
                    firstDropOffadresss_loc: nextProps.firstDropOffadresss_loc,
                    // customerType:nextProps.person ? "individual" :"business",
                    otherdropOffs: nextProps.otherdropOffs.map((drop) => {
                      return {
                        ...{ address: drop.address },
                        ...{ adresss_loc: drop.adresss_loc },
                        ...{ block: drop.block },
                        ...{ floor: drop.floor },
                        ...{ id: drop.id },
                        ...{ recieverFullName: drop.recieverFullName },
                        ...{ recieverPhoneNumber: drop.recieverPhoneNumber },
                        ...{ unit: drop.unit },
                      };
                    }),
                  },
                  this.props.dispatch
                );

                this.props.dispatch(EdittPriceNewOrderChanged(false))
              } else {
                CalculatePriceReserveWorker(
                  {
                    // customerType:nextProps.person ? "individual" :"business",

                    PREV : ( nextProps.manually_changed &&  nextProps.PriceCalculated) ? {
                      surcharges: nextProps.PriceCalculated.surcharges.map((surchargesItem)=>{
                        return        {
                          id: surchargesItem.id,
                          surchargeId: surchargesItem.surchargeId,
                          price: surchargesItem.price
                        }
                      }) ,
                      baseFarePrice: nextProps.PriceCalculated.baseFarePrice,
                      tax: nextProps.PriceCalculated.tax,
                      promoCode: nextProps.customer.id.length > 0  ? nextProps.PriceCalculated.promoCode : "",
                      ...nextProps.customer.id.length > 0 ?{  customerId:  nextProps.customer.id } : {}

                    } : null,
                    serviceactiveVehicleType: nextProps.serviceactiveVehicleType,
                    serviceoptions: nextProps.serviceoptions,
                    auto: nextProps.auto,
                    driveroptions: nextProps.driveroptions,
                    auto_activeVehicleType: nextProps.auto_activeVehicleType,
                    manual_activeVehicleType:
                      nextProps.manual_activeVehicleType,
                    pickup: {
                      address: nextProps.pickup.address,
                      scheduleDateAfter: momentJalaali(
                        nextProps.pickup.scheduleDateAfter
                      ).isValid()
                        ? momentJalaali(
                          Number(nextProps.pickup.scheduleDateAfter + "000")
                        ).unix()
                        : 0,
                      scheduleDateBefore: momentJalaali(
                        nextProps.pickup.scheduleDateBefore
                      ).isValid()
                        ? momentJalaali(
                          Number(nextProps.pickup.scheduleDateBefore + "000")
                        ).unix()
                        : 0,
                      // senderFullName: nextProps.pickup.senderFullName,
                      // senderPhoneNumber: nextProps.pickup.senderPhoneNumber,
                      // unit: nextProps.pickup.unit,
                    },
                    pickupadresss_loc: nextProps.pickupadresss_loc,
                    // customerType:nextProps.person ? "individual" :"business",
                    firstDropOff: {
                      address: nextProps.firstDropOff.address,
                      scheduleDateAfter: momentJalaali(
                        nextProps.firstDropOff.scheduleDateAfter
                      ).isValid()
                        ? momentJalaali(
                          Number(
                            nextProps.firstDropOff.scheduleDateAfter + "000"
                          )
                        ).unix()
                        : 0,
                      scheduleDateBefore: momentJalaali(
                        nextProps.firstDropOff.scheduleDateBefore
                      ).isValid()
                        ? momentJalaali(
                          Number(
                            nextProps.firstDropOff.scheduleDateBefore + "000"
                          )
                        ).unix()
                        : 0,
                      // block: nextProps.firstDropOff.block,
                      // floor: nextProps.firstDropOff.floor,
                      // recieverFullName: nextProps.firstDropOff.recieverFullName,
                      // recieverPhoneNumber: nextProps.firstDropOff.recieverPhoneNumber,
                      // unit: nextProps.firstDropOff.unit,
                    },
                    firstDropOffadresss_loc: nextProps.firstDropOffadresss_loc,
                    otherdropOffs: nextProps.otherdropOffs.map((drop) => {
                      return {
                        ...{ address: drop.address },
                        ...{ adresss_loc: drop.adresss_loc },
                        ...{ block: drop.block },
                        ...{ floor: drop.floor },
                        ...{ id: drop.id },
                        ...{ recieverFullName: drop.recieverFullName },
                        ...{ recieverPhoneNumber: drop.recieverPhoneNumber },
                        ...{ unit: drop.unit },
                        ...{
                          scheduleDateAfter: momentJalaali(
                            drop.scheduleDateAfter
                          ).isValid()
                            ? momentJalaali(
                              Number(drop.scheduleDateAfter + "000")
                            ).unix()
                            : 0,
                        },
                        ...{
                          scheduleDateBefore: momentJalaali(
                            drop.scheduleDateBefore
                          ).isValid()
                            ? momentJalaali(
                              Number(drop.scheduleDateBefore + "000")
                            ).unix()
                            : 0,
                        },
                      };
                    }),
                  },
                  this.props.dispatch
                );
                this.props.dispatch(EdittPriceNewOrderChanged(false))

              }


              if(nextProps.manually_changed){
                this.props.dispatch(EdittPriceNewOrder_manualy(false))
              }
            }
          );
        }
      }
    );
  }

  render() {
    return null;
  }
}

const mapStateToProps = (state) => ({
  person: state.NewOrder.newOrder.customer.person,
  serviceactiveVehicleType: state.NewOrder.newOrder.service.activeVehicleType,
  serviceoptions: state.NewOrder.newOrder.service.options,
  Pricechanged: state.NewOrder.newOrder.price.changed,
  manually_changed: state.NewOrder.newOrder.price.changed,
  PriceCalculated: state.NewOrder.newOrder.price.price,
  auto: state.NewOrder.newOrder.driver.auto,
  driveroptions: state.NewOrder.newOrder.driver.options,
  auto_activeVehicleType:
    state.NewOrder.newOrder.driver.auto_driver.auto_activeVehicleType,
  manual_activeVehicleType:
    state.NewOrder.newOrder.driver.manual_driver.manual_activeVehicleType,
  pickup: state.NewOrder.newOrder.pickup,
  pickupadresss_loc: state.NewOrder.newOrder.pickup.adresss_loc,
  firstDropOff: state.NewOrder.newOrder.dropOffs.firstDropOff,
  firstDropOffadresss_loc:
    state.NewOrder.newOrder.dropOffs.firstDropOff.adresss_loc,
  otherdropOffs: state.NewOrder.newOrder.dropOffs.otherDropOffs,
  customer: state.NewOrder.newOrder.customer,
  pickupscheduleDateAfter:state.NewOrder.newOrder.pickup.scheduleDateAfter,
  pickupscheduleDateBefore:state.NewOrder.newOrder.pickup.scheduleDateBefore,

});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
