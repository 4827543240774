import HKerryExpressiO from "../../../assets/ClaraBiofuels.png";
import FavIcon from "../../../assets/PROJECTS/ClaraBiofuels_fav_icon.png";

export default function ClaraBiofuels() {
    
    return  global.config = {
    "ProjectName": "Dispatcher",
    "LogoType": HKerryExpressiO,
    "BaseApi": "https://rest.clarabiofuels.com",
    "BaseSocket": "https://socket.clarabiofuels.com",
    "BaseTrack": "https://panel.clarabiofuels.com",
    "MapCenter": {
        "lat": 14.634358724054849,
        "lng": -90.50800997393661
    },
    "Lang": "en",
    "Direction": "ltr",
    "AllLanguages": [{
        "lang": "en",
        "direction": "ltr",
        "i18Lang": "English"
    } ,{
        "lang": "es",
        "direction": "ltr",
        "i18Lang": "spanish",
    }],
    "FavIcon": FavIcon,
    "Country": "GT",
    "thirdPartyLoginSupport": true  ,
    mapType :"osm", /* osm | google */ 
    googleApiKey : ""
}}