import React from "react";
import driverPin from "../../../assets/pin-driver-have-request-copy-18@3x.png";

 const Pin = ({style , onClick}) => {

  return <img onClick={onClick && onClick} style={{width:"38.2px" , height:"44.2px",...style}} src={driverPin} />


};

export default Pin

