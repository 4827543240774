import "./index.css";

import React, { useCallback, useEffect, useRef, useState } from "react";
import clsx from "clsx";
import styles from './style.module.css';
import colors from '../colors/colors.module.css';
import IconProvider from "../IconProvider";
import ProgressIndicator from "../ProgressIndicator";
import { ARROW_DOWN_BOLD, ArrowRight1, CLOSE ,filterPlus , ic_circle_close } from "../../../assets/index";
import { CSSTransition } from 'react-transition-group';
import Typography from "../Typography";
import { CheckBoxFilterProps } from "./CheckBoxFilter.types";
import DropDown from "../DropDown";
import Input from "../Input";
import Checkbox from "../Checkbox";
import { isEqual } from "lodash";
import { v4 as uuidv4 } from 'uuid';


const CheckBoxFilter: React.FC<CheckBoxFilterProps> = ({list , From, To, clearOnClick, errorText, i18, onChange, placement, state, value, title  }: CheckBoxFilterProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const [currentValue, setCurrentValue] = useState<any>(value);
    const [uuid] = useState(uuidv4());    
    const [currentplacement, setCurrentplacement] = useState(placement);
    const [elmId, setElmId] = useState(`"elmDrop${uuidv4()}`);
    const [mapZindex, setMapZindex] = useState(0);
    useEffect(() => {
       setTimeout(() => {
         try {
               const element = document.getElementById('googlemapUniqeId');
               setMapZindex(element.style.zIndex)
         } catch (error) {
         }
       }, 1000);


       }, []);


    const dropdownNode: any = useClickOutside(() => {
        setIsOpen(false);
    })


    
    const buttonOncick = useCallback((e:any) => {

        // if(!isOpen){
        //     setTimeout(() => {
        //         if(value.length == 0){
        //             setIsOpen(true);
        //         }else{
        //             setCurrentValue([]);onChange([])
        //         }

        //     }, 50);
        // }
        if(!isOpen){
            setIsOpen(true);
            setCurrentValue(value)
        }


        const myDiv = document.getElementById(uuid);
        const rect = myDiv?.getBoundingClientRect();
    
     
        if((window.innerWidth - Number(rect?.right)) < 300){
            setCurrentplacement("bottomEnd")
        }else{
            setCurrentplacement("bottomStart")
        }

      }, [isOpen , value ,uuid]);

    const isCheked = useCallback((item:any) => {
         let x = currentValue.findIndex((zon:any) => {return zon == item})
         return x > -1
      }, [currentValue ]);


      const valueToString = useCallback(() => {
        let x:any = []
        value.forEach((elementx:any) => {
            list.forEach((elementy:any) => {
                if(elementy.key == elementx){
                    x.push(` ${elementy.value}`)
                }
            });
        });

        return x.toString()
     }, [value , list ]);


    const checkBoxOnchange = useCallback((item:any) => {
        if(isCheked(item.key)){
            let x = currentValue.filter((zon:any) => {return zon != item.key})
            setCurrentValue(x)
        }else{
            let xx = [...currentValue , item.key]
            // onChange(xx)
            setCurrentValue(xx)
        }
     }, [currentValue ]);


     useEffect(() => {
        if(mapZindex != 0){
 try {
            if (isOpen ) {
              const element = document.getElementById('googlemapUniqeId');
              const element3 = document.getElementById('monitoringContainerUniqeId');

              element.style.zIndex = String(Number(mapZindex) - 1);
              element3.style.zIndex = '7';

            } else {
              const element = document.getElementById('googlemapUniqeId');
              const element3 = document.getElementById('monitoringContainerUniqeId');

              element.style.zIndex = mapZindex;
              element3.style.zIndex = '8';

            }
        } catch (error) {
          
        }
        }
       
      
      
        }, [isOpen]);

     const openEditCustomer = React.useCallback(() => {


        if(placement == "topStart"){
            let elm = document.getElementById(uuid)
   
            var box = elm.getBoundingClientRect();
    
            var body = document.body;
            var docEl = document.documentElement;
        
            var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
            var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;
        
            var clientTop = docEl.clientTop || body.clientTop || 0;
            var clientLeft = docEl.clientLeft || body.clientLeft || 0;
            var clientRight = ((docEl.clientWidth || body.clientWidth) - box.width - box.left) || 0;
 
 
        
            var top  = box.top  
            var left = box.left 
 
            return {top: `${Math.round(top)}px`, [clientRight <= 200 ? "right" :"left"] :  (clientRight <= 200 ? `${Math.round(clientRight)}px` : `${Math.round(left)}px`)};

        }else if(placement == "topEnd"){
            let elm = document.getElementById(uuid)
   
            var box = elm.getBoundingClientRect();
    
            var body = document.body;
            var docEl = document.documentElement;
        
            var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
            var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;
        
            var clientTop = docEl.clientTop || body.clientTop || 0;
            var clientLeft = docEl.clientLeft || body.clientLeft || 0;
            var clientRight = ((docEl.clientWidth || body.clientWidth) - box.width - box.left) || 0;
 
 
        
            var top  = box.top 
            var left = box.left + scrollLeft - clientLeft;
 
 
            return  {top: `${Math.round(top)}px`, right : `${Math.round(clientRight)}px`};

         
        }else if(placement == "bottomStart"){
            let elm = document.getElementById(uuid)
   
            var box = elm.getBoundingClientRect();
    
            var body = document.body;
            var docEl = document.documentElement;
        
            var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
            var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;
        
            var clientTop = docEl.clientTop || body.clientTop || 0;
            var clientLeft = docEl.clientLeft || body.clientLeft || 0;
            var clientRight = ((docEl.clientWidth || body.clientWidth) - box.width - box.left) || 0;
 
 
        
            var top  = box.top +  box.height + 10;
            var left = box.left + scrollLeft - clientLeft;
 
 
            return {top: `${Math.round(top)}px`, [clientRight <= 200 ? "right" :"left"] :  (clientRight <= 200 ? `${Math.round(clientRight)}px` : `${Math.round(box.left)}px`)};
       
        }else if(placement == "bottomEnd"){
            let elm = document.getElementById(uuid)
   
            var box = elm.getBoundingClientRect();
    
            var body = document.body;
            var docEl = document.documentElement;
        
            var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
            var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;
        
            var clientTop = docEl.clientTop || body.clientTop || 0;
            var clientLeft = docEl.clientLeft || body.clientLeft || 0;
            var clientRight = ((docEl.clientWidth || body.clientWidth) - box.width - box.left) || 0;
 
 
        
            var top  = box.top +  box.height + 10;
            var left = box.left + scrollLeft - clientLeft;
 
 
            return {top: `${Math.round(top)}px`, [clientRight <= 200 ? "right" :"left"] :  (clientRight <= 200 ? `${Math.round(clientRight)}px` : `${Math.round(box.left)}px`)};
        }else{
            let elm = document.getElementById(uuid)
   
            var box = elm.getBoundingClientRect();
    
            var body = document.body;
            var docEl = document.documentElement;
        
            var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
            var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;
        
            var clientTop = docEl.clientTop || body.clientTop || 0;
            var clientLeft = docEl.clientLeft || body.clientLeft || 0;
            var clientRight = ((docEl.clientWidth || body.clientWidth) - box.width - box.left) || 0;
 
 
        
            var top  = box.top  
            var left = box.left 
 
            return {top: `${Math.round(top)}px`, [clientRight <= 200 ? "right" :"left"] :  (clientRight <= 200 ? `${Math.round(clientRight)}px` : `${Math.round(left)}px`)};

        }


        
      }, [elmId , placement]);


    return (
        <> 
        
        <div  onClick={buttonOncick}  id={uuid}  className={clsx(styles.button ,value.length > 0 && styles.Activebutton , isOpen && styles.buttonNOhOVER)} >

        {value.length > 0 ? <>
                 <IconProvider Icon={ic_circle_close} size="14" onClick={(e)=>{
                            e.stopPropagation();
                            setCurrentValue([]);onChange([])
                }} /> <span className={styles.title}> {`${title}`}<div style={{height:"12px",width:"1px" ,backgroundColor:"rgba(128, 128, 128, 1)",borderRadius:"2px"}} /><Typography weight='Body_Small_Medium' textColor={'TextDark'} text={valueToString()}/> </span>
              
            </> :<span className={styles.title}><IconProvider Icon={filterPlus} size="14"  onClick={(e)=>{
                
            }} />  {title}</span>}



    
        </div>

        {isOpen && <CSSTransition
                in={isOpen}
                timeout={300}
                classNames="dropdown-animation"
                unmountOnExit
                onEnter={() => setIsOpen(true)}
                onExited={() => setIsOpen(false)}
                style={openEditCustomer() ?  openEditCustomer() :{}}
            >
                <div  ref={dropdownNode}  className={clsx(styles.dropdownBox, styles[currentplacement])}>

<div className={styles.dropdownBoxInner}> {list.map((item:any)=>{
                        return  <Typography weight='Body_Small_Regular' textColor={'TextDark'} text={<><Checkbox size="Small" state={isCheked(item.key) ? "checked" :"unchecked"} onClick={()=>{}}  /> {item.value}</>} className={styles.dropItem} style={{ cursor: "pointer" }} onClick={()=>{checkBoxOnchange(item)}} />
                    })}

</div>
                   

                {/* <Typography weight='Body_Small_Regular' textColor={'TextDark'} text={<><Checkbox size="Small" state="unchecked"  /> One</>} className={styles.dropItem} style={{ cursor: "pointer" }} />
                <Typography weight='Body_Small_Regular' textColor={'TextDark'} text={<><Checkbox size="Small" state="unchecked"  /> Two</>} className={styles.dropItem} style={{ cursor: "pointer" }} />
                <Typography weight='Body_Small_Regular' textColor={'TextDark'} text={<><Checkbox size="Small" state="unchecked"  /> Three</>} className={styles.dropItem} style={{ cursor: "pointer" }} />
                <Typography weight='Body_Small_Regular' textColor={'TextDark'} text={<><Checkbox size="Small" state="unchecked"  /> Four</>} className={styles.dropItem} style={{ cursor: "pointer" }} />
                <Typography weight='Body_Small_Regular' textColor={'TextDark'} text={<><Checkbox size="Small" state="unchecked"  /> Five</>} className={styles.dropItem} style={{ cursor: "pointer" }} /> */}

                <div className={styles.footer}>
                        <Typography weight='Body_Tiny_Bold' textColor={'TextDark'} text={"Clear"} className={styles.drop} style={{ cursor: "pointer" }} onClick={(e)=>{
                            e.stopPropagation();
                            setCurrentValue([]);onChange([])
                }}  />
                        <Typography weight='Body_Tiny_Bold' textColor={'Primary'} text={"Apply"} className={styles.drops} style={{ opacity: (!isEqual(currentValue , value)) ? "1" : ".4",  cursor: "pointer" }} onClick={()=>{!isEqual(currentValue , value) && onChange(currentValue);setTimeout(() => {
                            setIsOpen(false)
                        }, 100)}} />

                    </div>

                </div>
            </CSSTransition>}
        </>
       

    );
};

export default CheckBoxFilter;



const useClickOutside = (handler: any) => {
    const domNode = useRef<any>();
    useEffect(() => {
        const eventHandler = (event: any) => {
            if (!domNode?.current?.contains(event.target)) {
                handler();
            }
            // uncomment this for dropdown close on menu click
            // handler();
        }
        document.addEventListener("mousedown", eventHandler);
        return () => {
            document.removeEventListener('mousedown', eventHandler);
        }
    });
    return domNode;
}




