import pinkExpress from "../../../assets/pinkExpress.png";
import FavIcon from "../../../assets/PROJECTS/pinkExpress_fav_icon.png";

export default function PinkExpress() {

    return global.config = {
        "ProjectName": "PiNK.Express",
        "LogoType": pinkExpress,
        "BaseApi": "https://rest.pink.express",
        "BaseSocket": "https://socket.pink.express",
        "BaseTrack": "https://admin.pink.express",
        "MapCenter": {
            "lat": 48.209089137900904,
            "lng": 16.37791009677984
        },
        "Lang": 'de-AT',
        "Direction": "ltr",
        "AllLanguages": [
            {
                "lang": 'de-AT',
                "direction": "ltr",
                "i18Lang": "AustrianGerman"
            },
            {
                "lang": "en",
                "direction": "ltr",
                "i18Lang": "English"
            }],
        "FavIcon": FavIcon,
        "Country": "AT",
        "thirdPartyLoginSupport": true,
        mapType: "osm", /* osm | google */
        googleApiKey: ""
    }
}