import Fabb from "../../../assets/fablogotype.png";
import FavIcon from "../../../assets/PROJECTS/fab.png";


export default function Fab() {

    return  global.config = {
    "ProjectName": "Fab Bud dispatcher",
    "LogoType": Fabb,
    "BaseApi": "https://rest.fab.com.np",
    "BaseSocket": "https://socket.fab.com.np",
    "BaseTrack": "https://panel.fab.com.np",
    "MapCenter": {
        "lat": "27.673402",
        "lng": "85.427407"
    },
    "Lang": "en",
    "Direction": "ltr",
    "AllLanguages": [{
        "lang": "en",
        "direction": "ltr",
        "i18Lang": "English"
    }, {
        "lang": "fa",
        "direction": "rtl",
        "i18Lang": "Persian"
    }, {
        "lang": "fr",
        "direction": "ltr",
        "i18Lang": "French"
    }, {
        "lang": "ro",
        "direction": "ltr",
        "i18Lang": "Romanian"
    }],
    "FavIcon": FavIcon,
    "Country": "NP",
    "thirdPartyLoginSupport": true  ,
    mapType :"osm", /* osm | google */ 
    googleApiKey : ""
}}