import myWorker from "./test.worker";
import { SetEstimatedRoute } from "../../../Redux/actions/actionNames";

export const EstimateRouteWorker = (dispatch ,data) => {
  const worker = new myWorker();
  worker.postMessage(data);
  worker.addEventListener("message", (event) => {
    dispatch(SetEstimatedRoute({ waypoints: event.data }));
    worker.terminate();
  });
};
