import { Component } from "react";
import { connect } from "react-redux";
import Ondemand from "./ondemand";
import Pickup from "./pickup";
import Delivery from "./delivery";
import PickupDelivery from "./pickupDelivery";


class App extends Component {

  render() {
    let orderType = this.props.orderTypeActive

    if(orderType== "Ondemand"){
      return <Ondemand />
    }else if(orderType== "Pickup"){
      return <Pickup />
    }else if(orderType== "Delivery"){
      return <Delivery />
    }else if(orderType== "PickupDelivery"){
      return <PickupDelivery />
    }else{
      return null
    }
  }
}

const mapStateToProps = (state) => ({
  person: state.NewOrder.newOrder.customer.person,
  serviceactiveVehicleType: state.NewOrder.newOrder.service.activeVehicleType,
  serviceoptions: state.NewOrder.newOrder.service.options,

  auto: state.NewOrder.newOrder.driver.auto,
  driveroptions: state.NewOrder.newOrder.driver.options,
  auto_activeVehicleType:
    state.NewOrder.newOrder.driver.auto_driver.auto_activeVehicleType,
  manual_activeVehicleType:
    state.NewOrder.newOrder.driver.manual_driver.manual_activeVehicleType,
  pickup: state.NewOrder.newOrder.pickup,
  pickupadresss_loc: state.NewOrder.newOrder.pickup.adresss_loc,
  firstDropOff: state.NewOrder.newOrder.dropOffs.firstDropOff,
  firstDropOffadresss_loc:
    state.NewOrder.newOrder.dropOffs.firstDropOff.adresss_loc,
  otherdropOffs: state.NewOrder.newOrder.dropOffs.otherDropOffs,

  orderTypeActive: state.NewOrder.newOrder.orderTypeActive,

});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
