import "./index.css";

import React, { useCallback, useEffect, useRef, useState } from "react";
import clsx from "clsx";
import styles from './style.module.css';
import colors from '../colors/colors.module.css';
import IconProvider from "../IconProvider";
import ProgressIndicator from "../ProgressIndicator";
import { ARROW_DOWN_BOLD, ArrowRight1, CLOSE,filterPlus , ic_circle_close  } from "../../../assets/index";
import { CSSTransition } from 'react-transition-group';
import Typography from "../Typography";
import { RadioButtonFilterProps } from "./RadioButtonFilter.types";
import DropDown from "../DropDown";
import Input from "../Input";
import Radiobutton from "../Radiobutton";
import { isEqual } from "lodash";
import { v4 as uuidv4 } from 'uuid';


const RadioButtonFilter: React.FC<RadioButtonFilterProps> = ({ list, From, To, clearOnClick, errorText, i18, onChange, placement, state, value, title }: RadioButtonFilterProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const [currentState, setCurrentState] = useState("Isequal");
    const [currentValue, setCurrentValue] = useState<any>(value);
    const [uuid] = useState(uuidv4());    
    const [currentplacement, setCurrentplacement] = useState(placement);



    const buttonOncick = useCallback(() => {
        if (!isOpen) {
            // setTimeout(() => {
            //     if (!value) {
            //         setIsOpen(true);
            //     }
            //     setCurrentValue(null); onChange(null)
            // }, 50);

            setIsOpen(true);
            setCurrentValue(value)
            
        }


        
        const myDiv = document.getElementById(uuid);
        const rect = myDiv?.getBoundingClientRect();
    
     
        if((window.innerWidth - Number(rect?.right)) < 300){
            setCurrentplacement("bottomEnd")
        }else{
            setCurrentplacement("bottomStart")
        }

    }, [isOpen, value, currentValue,uuid]);


    const dropdownNode: any = useClickOutside(() => {
        setIsOpen(false);
    })




    return (
        <div ref={dropdownNode} onClick={buttonOncick} id={uuid}  className={clsx(styles.button, value && styles.Activebutton, isOpen && styles.buttonNOhOVER)} >

            {value ? <>
                   <IconProvider Icon={ic_circle_close} size="14" onClick={(e)=>{
                            e.stopPropagation();
                            setCurrentValue(null); onChange(null)
                }} /><span className={styles.title}> {`${title}`}<div style={{height:"12px",width:"1px" ,backgroundColor:"rgba(128, 128, 128, 1)",borderRadius:"2px"}} /><Typography weight='Body_Small_Medium' textColor={'TextDark'} text={value} /> </span>
             
            </> : <span className={styles.title}><IconProvider Icon={filterPlus} size="14"  onClick={(e)=>{
                
            }} /> {title}</span>}



            <CSSTransition
                in={isOpen}
                timeout={300}
                classNames="dropdown-animation"
                unmountOnExit
                onEnter={() => setIsOpen(true)}
                onExited={() => setIsOpen(false)}
            >
                <div className={clsx(styles.dropdownBox, styles[currentplacement])}>

                    {list.map((item: any) => {
                        return <Typography weight='Body_Small_Regular' textColor={'TextDark'} text={<><Radiobutton size="Small" state={currentValue == item.key ? "checked" : "unchecked"} onClick={()=>{}} /> {item.value}</>} className={styles.dropItem} style={{ cursor: "pointer" }} onClick={()=>{setCurrentValue(item.key)}} />
                    })}
                    {/* <Typography weight='Body_Small_Regular' textColor={'TextDark'} text={<><Radiobutton size="Small" state="unchecked"  /> One</>} className={styles.dropItem} style={{ cursor: "pointer" }} />
                <Typography weight='Body_Small_Regular' textColor={'TextDark'} text={<><Radiobutton size="Small" state="unchecked"  /> Two</>} className={styles.dropItem} style={{ cursor: "pointer" }} />
                <Typography weight='Body_Small_Regular' textColor={'TextDark'} text={<><Radiobutton size="Small" state="unchecked"  /> Three</>} className={styles.dropItem} style={{ cursor: "pointer" }} />
                <Typography weight='Body_Small_Regular' textColor={'TextDark'} text={<><Radiobutton size="Small" state="unchecked"  /> Four</>} className={styles.dropItem} style={{ cursor: "pointer" }} />
                <Typography weight='Body_Small_Regular' textColor={'TextDark'} text={<><Radiobutton size="Small" state="unchecked"  /> Five</>} className={styles.dropItem} style={{ cursor: "pointer" }} /> */}

                    <div className={styles.footer}>
                        <Typography weight='Body_Tiny_Bold' textColor={'TextDark'} text={"Clear"} className={styles.drop} style={{ cursor: "pointer" }}  onClick={(e)=>{
                            e.stopPropagation();
                            setCurrentValue(null); onChange(null)
                }}  />
                        <Typography weight='Body_Tiny_Bold' textColor={'Primary'} text={"Apply"} className={styles.drop} style={{ opacity: (!isEqual(currentValue , value)) ? "1" : ".4", cursor: "pointer" }} onClick={()=>{!isEqual(currentValue , value) && onChange(currentValue);setTimeout(() => {
                            setIsOpen(false)
                        }, 100)}} />
                    </div>

                </div>
            </CSSTransition>
        </div>

    );
};

export default RadioButtonFilter;



const useClickOutside = (handler: any) => {
    const domNode = useRef<any>();
    useEffect(() => {
        const eventHandler = (event: any) => {
            if (!domNode.current.contains(event.target)) {
                handler();
            }
            // uncomment this for dropdown close on menu click
            // handler();
        }
        document.addEventListener("mousedown", eventHandler);
        return () => {
            document.removeEventListener('mousedown', eventHandler);
        }
    });
    return domNode;
}




