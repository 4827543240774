import GettyLogo from "../../../assets/GettyLogo.png";
import FavIcon from "../../../assets/PROJECTS/Getty.png";

export default function Getty() {
    
    return  global.config = {
    "ProjectName": "Getty Dispatch",
    "LogoType": GettyLogo,
    "BaseApi": "https://rest.gettico.com",
        "BaseSocket": "https://socket.gettico.com",
        "BaseTrack": "https://track.gettico.com",
    "MapCenter": {
        "lat": 33.42188997878557,
        "lng": 44.55873298890225
    },
    "Lang": "en",
    "Direction": "ltr",
    "AllLanguages": [{
        "lang": "en",
        "direction": "ltr",
        "i18Lang": "English"
    }],
    "FavIcon": FavIcon,
    "Country": "IQ",
    "thirdPartyLoginSupport": true  ,
    mapType :"osm", /* osm | google */ 
    googleApiKey : ""
} }