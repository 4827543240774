import Dropp from "../../../assets/RyderX_logo.png";
import FavIcon from "../../../assets/PROJECTS/RyderX.png";

export default function RyderX() {
    
    return  global.config = {
    "ProjectName": "RyderX",
    "LogoType": Dropp,
    "BaseApi": "https://rest.ryderx.at",
    "BaseSocket": "https://socket.ryderx.at",
    "BaseTrack": "https://track.ryderx.at",
    "MapCenter": {
        "lat": 48.207163478689694,
        "lng": 16.372014684940364
    },
    "Lang": "en",
    "Direction": "ltr",
    "AllLanguages": [ {
        "lang": 'de-AT',
        "direction": "ltr",
        "i18Lang": "AustrianGerman"
    },{
        "lang": "en",
        "direction": "ltr",
        "i18Lang": "English"
    }],
    "FavIcon": FavIcon,
    "Country": "AT",
    "thirdPartyLoginSupport": false  ,
    mapType :"osm", /* osm | google */ 
    googleApiKey : ""
}}
