import NOBODIIGO from "../../../assets/NOBODI.png";
import FavIcon from "../../../assets/PROJECTS/FavIcon_NOBODI.png";



export default function NOBODI() {
    
    return global.config = {
    "ProjectName": "NOBODI ",
    "LogoType": NOBODIIGO,
    "BaseApi": "https://rest.nobodi.com",
    "BaseSocket": "https://socket.nobodi.com",
    "BaseTrack": "https://admin.nobodi.com",
    "MapCenter": {
        "lat": 51.50713950458875,
        "lng": -0.12564119077725647
    },
    "Lang": "en",
    "Direction": "ltr",
    "AllLanguages": [{
        "lang": "en",
        "direction": "ltr",
        "i18Lang": "English"
    }],
    "FavIcon": FavIcon,
    "Country": "GB",
    "thirdPartyLoginSupport": true  ,
    mapType :"osm", /* osm | google */ 
    googleApiKey : ""
}}