import React, { useEffect, useState } from 'react';
import { SidebarLogoType } from './components/logotype';
import { SidebarSlider } from './components/slider';
import styles from './style.module.css';
import clsx from 'clsx';
import { SidebarOrderTypes } from './components/orderTypes';
import { SidebarDivider } from './components/divider';
import { SidebarNotification } from './components/notification';
import { SidebarProfile } from './components/profile';
import { LocalData } from '../../../service/localStorage';



const stringToBoolean = (stringValue:any) => {
  switch(String(stringValue?.toLowerCase()?.trim())){
      case "true": 
      case "yes": 
      case "1": 
        return true;

      case "false": 
      case "no": 
      case "0": 
      case null: 
      case undefined:
        return false;

      default: 
        return JSON.parse(stringValue);
  }
}

const localData = new LocalData();

export const Sidebar = ({getIsOpen}:any) => {
  const [isOpen, setIsOpen] = useState<boolean>(localData.get("ReduxLocal_Sidebar") ? stringToBoolean(localData.get("ReduxLocal_Sidebar")) : false);

    const HandlesetIsOpen = React.useCallback((e:any) => {

        setIsOpen(e)
        getIsOpen(e)
      }, [isOpen]);

  useEffect(() => {
    HandlesetIsOpen(localData.get("ReduxLocal_Sidebar") ? stringToBoolean(localData.get("ReduxLocal_Sidebar")) : false)
  }, [])

  useEffect(() => {
    localData.set("ReduxLocal_Sidebar", isOpen)
  }, [isOpen])

      
    return <div className={clsx(styles.Sidebar , isOpen && styles.isOpen)}>

                {/* <SidebarLogoType isOpen={isOpen} /> */}
                <SidebarSlider setIsOpen={HandlesetIsOpen} isOpen={isOpen}  />
                <SidebarOrderTypes isOpen={isOpen} />
                <SidebarDivider style={{marginBottom:"10px"}} />
                <SidebarNotification  isOpen={isOpen} />
                <SidebarDivider style={{marginBottom:"10px",marginTop:"10px"}} />
                <SidebarProfile isOpen={isOpen} />
    </div>

};




