import React from 'react';
import { ViewOrderLayout } from '../layout/view-order/ViewOrder'
import Hotkeys from 'react-hot-keys';
import { DownloadReactState } from '../utils/downloadReactState';


function ViewOrderPage({mapWidth}) {

    const downloadReactState = () => {
        DownloadReactState()
    };



    return <Hotkeys
        keyName="ctrl+shift+e,control+e"
        onKeyUp={downloadReactState}>
        <ViewOrderLayout mapWidth={mapWidth} />
    </Hotkeys>
}

export default ViewOrderPage;

