import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import { getBaseTrackByUrl } from "../../../../../helper/module";
import { get_lang } from "../../../../../helper/UserData/userdate";
import { isEmpty } from "lodash";
import profilePlace from "../../../../../assets/profile-place-holder.svg";
var _ = require("lodash");

class App extends React.Component {
  serviceOptionsNameExtracter = () => {
    let names = [];
    if (this.props.options) {
      this.props.options.map((option) => {
        if (option.type === "boolean") {
          names.push(option.title);
        } else {
          names.push(option.dataTitle);
        }
      });
      return names.join(" , ");
    } else {
      return "";
    }
  };

  OrderLabelgenerate = (
    driverAssignmentModel = this.props.driverAssignmentModel ,
    currentCandidateDrivers = this.props.currentCandidateDrivers
  ) => {

    if (driverAssignmentModel == "automatic"  && ["Pending"].indexOf(this.props.status)> -1 ) {
      return (
        //dispatching
        <React.Fragment>
          <img src={this.props.vehicletype && this.props.vehicletype.icon} />
            <div className="vr_statusvehicleContainer">
              <span className="vr_dispatchinglabel"> {this.props.t("Dispatching")} </span>
              <span className="vr_vehicleTypeName"> {this.props.vehicletype && this.props.vehicletype.title} </span>
            </div>
            <span className="vr_JVHT_Options"> {this.serviceOptionsNameExtracter()}  </span>
            {/* <span className="driverName"> {this.props.t("Dispatching")} </span>
            <img src={search} className={`car`} /> */}
        </React.Fragment>
      );
    }else if (driverAssignmentModel == "automatic" && ["Pending"].indexOf(this.props.status) == -1) {
      return (
        //NoDriver
        <React.Fragment>
        <img src={profilePlace} />
          <div className="vr_statusvehicleContainer">
            <span className="vr_dispatchinglabel SenderWalletred"> {this.props.t("NoDriver")} </span>
            <span className="vr_vehicleTypeName"> {this.props.t("youcanselectdriverintheedit")} </span>
          </div>
          {/* <span className="driverName"> {this.props.t("Dispatching")} </span>
          <img src={search} className={`car`} /> */}
      </React.Fragment>
        // <span className="driverName noDriver">{this.props.t("NoDriver")} </span>
      );
    }  else if (driverAssignmentModel == "manual" && isEmpty(currentCandidateDrivers)) {
      return (
         //NoDriver
        // <span className="driverName noDriver">{this.props.t("NoDriver")} </span>
        <React.Fragment>
        <img src={profilePlace} />
          <div className="vr_statusvehicleContainer">
            <span className="vr_dispatchinglabel SenderWalletred"> {this.props.t("NoDriver")} </span>
            <span className="vr_vehicleTypeName"> {this.props.t("youcanselectdriverintheedit")} </span>
          </div>
          {/* <span className="driverName"> {this.props.t("Dispatching")} </span>
          <img src={search} className={`car`} /> */}
      </React.Fragment>
      );
    }else if (
      driverAssignmentModel == "manual" &&
      !isEmpty(currentCandidateDrivers) && currentCandidateDrivers[0].status == "received"
    ) {
       //waitforconfirm
      return (
        <React.Fragment>
        <img src={currentCandidateDrivers[0].avatarPhoto} />
            <div className="vr_statusvehicleContainer">
                <span className="vr_dispatchinglabel "> {currentCandidateDrivers[0].name}  <span className="waitforconfirm"> {this.props.t("waitforconfirm2")}</span> </span>
                <span className="vr_vehicleTypeName"> {currentCandidateDrivers[0].phone}  </span>
              </div>
              <span className="vr_JVHT_Options"> {this.serviceOptionsNameExtracter()}  </span>

        </React.Fragment>
      );
    } else if (
      driverAssignmentModel == "manual" &&
      !isEmpty(currentCandidateDrivers) && currentCandidateDrivers[0].status == "rejected"
    ) {
      return (
         //Rejected
         <React.Fragment>
         <img src={currentCandidateDrivers[0].avatarPhoto} />
             <div className="vr_statusvehicleContainer">
                 <span className="vr_dispatchinglabel "> {currentCandidateDrivers[0].name}  <span className="rejected_vr"> {this.props.t("Rejected2")}</span> </span>
                 <span className="vr_vehicleTypeName"> {currentCandidateDrivers[0].phone}  </span>
               </div>
               <span className="vr_JVHT_Options"> {this.serviceOptionsNameExtracter()}  </span>
 
         </React.Fragment>
      );
    } else if (
      driverAssignmentModel == "manual" &&
      !isEmpty(currentCandidateDrivers) && currentCandidateDrivers[0].status == "initial"
    ) {
      return (
        <React.Fragment>
        <img src={currentCandidateDrivers[0].avatarPhoto} />
            <div className="vr_statusvehicleContainer">
                <span className="vr_dispatchinglabel "> {currentCandidateDrivers[0].name}  <span className="waitforconfirm"> {this.props.t("waitforconfirm2")}</span> </span>
                <span className="vr_vehicleTypeName"> {currentCandidateDrivers[0].phone}  </span>
              </div>
              <span className="vr_JVHT_Options"> {this.serviceOptionsNameExtracter()}  </span>

        </React.Fragment>
      );
    }else if (
      driverAssignmentModel == "manual" &&
      !isEmpty(currentCandidateDrivers) && currentCandidateDrivers[0].status == "notifying"
    ){
      return (
        <React.Fragment>
        <img src={currentCandidateDrivers[0].avatarPhoto} />
            <div className="vr_statusvehicleContainer">
                <span className="vr_dispatchinglabel "> {currentCandidateDrivers[0].name}  <span className="waitforconfirm"> {this.props.t("waitforconfirm2")}</span> </span>
                <span className="vr_vehicleTypeName"> {currentCandidateDrivers[0].phone}  </span>
              </div>
              <span className="vr_JVHT_Options"> {this.serviceOptionsNameExtracter()}  </span>

        </React.Fragment>
      );
    }else{
      return (
        <React.Fragment>
             <img src={this.props.vehicletype && this.props.vehicletype.icon} />
             <name>
             {this.props.vehicletype && this.props.vehicletype.title} 

          <optionss>{this.serviceOptionsNameExtracter()} </optionss>
        </name>

        </React.Fragment>
      );
    }

  };

  render() {
    if (!_.isEmpty(this.props.driver) /* && _.isEmpty(this.props.vehicletype) */) {
      return null;
    } else if(_.isEmpty(this.props.vehicletype)){
      return null;

    }else {
      const { t } = this.props;
      return (
        <serviceVr>
          {this.OrderLabelgenerate()}
          {/* <img src={this.props.vehicletype && this.props.vehicletype.icon} />
          <span>   </span> */}
          {/* <name>
            {this.props.vehicletype && this.props.vehicletype.title}
            <optionss>{this.serviceOptionsNameExtracter()}</optionss>
          </name> */}
        </serviceVr>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  driver:state.ViewTravel.pureData
  ? state.ViewTravel.pureData.driver
    ? state.ViewTravel.pureData.driver
    : null
  : null,
  vehicletype: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.vehicleType
      ? state.ViewTravel.pureData.vehicleType
      : null
    : null,
    options: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.vehicleType.options
      ? state.ViewTravel.pureData.vehicleType.options
      : null
    : null,

    driverAssignmentModel : state.ViewTravel.pureData
    ? state.ViewTravel.pureData.driverAssignmentModel
      ? state.ViewTravel.pureData.driverAssignmentModel
      : null
    : null,

    currentCandidateDrivers :state.ViewTravel.pureData
    ? state.ViewTravel.pureData.currentCandidateDrivers
      ? state.ViewTravel.pureData.currentCandidateDrivers
      : null
    : null,


    status:state.ViewTravel.pureData
    ? state.ViewTravel.pureData.status
      ? state.ViewTravel.pureData.status
      : null
    : null,


  lang: state.LocalData.lang,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
