import { useEffect } from "react";


export const useForceUpdate = () => {

  useEffect(() => {

    var myHeaders = new Headers();
    myHeaders.append('pragma', 'no-cache');
    myHeaders.append('cache-control', 'no-cache');

    var myInit = {
      method: 'GET',
      headers: myHeaders,
    };

    fetch('/meta.json', myInit)
      .then((response) => response.json())
      .then((meta) => {
        const latestVersion = meta.version;
        const currentVersion = global.appVersion;

        const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);
        if (shouldForceRefresh) {


          if (caches) {
            // Service worker cache should be cleared with caches.delete()
            caches.keys().then(function (names) {
              for (let name of names) caches.delete(name);
            });
          }

          // delete browser cache and hard reload
          window.location.reload(true);
        } else {

        }
      });
  }, []);
};



const semverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA.split(/\./g);

  const versionsB = versionB.split(/\./g);
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }
  return false;
};