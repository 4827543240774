import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import arrow from "../../../../../../assets/arrow-triangle-down.svg";
import box from "../../../../../../assets/box-copy.svg";
import { withStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import { withTranslation, Trans } from "react-i18next";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import Grow from "@material-ui/core/Grow";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import {
  Add_Driver_Option,
  Remove_Driver_Option,
  Set_Manual_ActiveVehicleTypeـPickupDeliveryType_Pickup,
} from "../../../../../../Redux/actions/actionNames";
import { get_lang } from "../../../../../../helper/UserData/userdate";
import { isEmpty } from "lodash";

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showpoper: false,
      active: false,
      selected: null,
    };
    this.anchorEl = React.createRef();
  }

  handleshowpoper = (v, a) => {
      this.setState({
        showpoper: a ? !this.state.showpoper : v,
      });
  };

  handleSelectedItem = (item) => {
    this.props.dispatch(
      Set_Manual_ActiveVehicleTypeـPickupDeliveryType_Pickup({
        manual_activeVehicleType: item,
      })
    );
    this.setState({
      selected: item,
      showpoper: false,
    });
  };

  optionisSaved() {
    let booll = false;
    booll = this.props.options.find((item) => {
      return item.id === this.props.id;
    });
    if (booll) {
      return true;
    } else {
      return false;
    }
  }

  optionText() {
    // let booll = false;
    // booll = this.props.options.find((item) => {
    //   return item.id === this.props.id;
    // });
    
    if (this.props.manual_activeVehicleType) {
      return true;
    } else {
      return false;
    }
  }

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <clickableservice
          ref={this.anchorEl}
          onClick={() => this.handleshowpoper(true, 1)}
          className={`manualVehicleType  ${null
            // isEmpty(this.props.manual_id) && "notallowed"
          }`}
          //   className={`${this.optionisSaved() && "avtiveServiceOptions"}`}
        >
          <img
            src={arrow}
            className={`${this.state.showpoper && "rotate180"}`}
          />
          {this.optionText() ? (  
              this.props.manual_activeVehicleType.title
          ) : (
            <Trans i18nKey={"Allvehicletypes"}> </Trans>
          )}
        </clickableservice>

        <Popper
          open={this.state.showpoper}
          anchorEl={this.anchorEl.current}
          role={undefined}
          style={{
            zIndex: 6,
            marginTop: "10px",
            border: "solid 1px rgba(128, 128, 128, 0.2)",
            borderRadius: "8px",
            boxShadow: "0 2px 8px 0 rgba(128, 128, 128, 0.24)",
          }}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              timeout={{ enter: 150, exit: 150 }}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener
                  onClickAway={() => this.handleshowpoper(false, 0)}
                >
                  <MenuList
                    autoFocusItem={this.state.activestop}
                    id="menu-list-grow"
                    onKeyDown={(e) => {
                      this.handleListKeyDownTime(e);
                    }}
                  >
                    <MenuItem
                          className="serviceListOption"
                          onClick={() => this.handleSelectedItem(null)}
                        >
                          {this.props.t("Allvehicletypes")}
                        </MenuItem> 
                    {this.props.auto_suggestListData.map((itemM, i) => {
                      return (
                        <MenuItem
                          className="serviceListOption"
                          onClick={() => this.handleSelectedItem(itemM)}
                        >
                          {itemM.title}
                        </MenuItem>
                      );
                    })}
                    {this.props.auto_suggestListData.length == 0 && (
                      <span style={{ fontSize: "15px", margin: "0 20px" }}>
                        <Trans i18nKey={"Noinformationfound"}> </Trans>
                      </span>
                    )}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  options: state.NewOrder.newOrder.pickup.driver.options,
  lang: state.LocalData.lang,
  manual_id: state.NewOrder.newOrder.pickup.driver.manual_driver.manual_id,
  driverAllvehicletypes:
    state.NewOrder.newOrder.pickup.driver.manual_driver.driverAllvehicletypes,
  manual_activeVehicleType:
    state.NewOrder.newOrder.pickup.driver.manual_driver.manual_activeVehicleType,
  auto_suggestListData:
    state.NewOrder.newOrder.driver.auto_driver.auto_suggestListData,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
