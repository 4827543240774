import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getDirectionByUrl } from "../../helper/module";
import { get_calendarType, get_id, get_lang, get_refreshToken, set_calendarType, set_currencyCode, set_defaultLocation, set_direction, set_lang, set_settings_dispatcher } from "../../helper/UserData/userdate";
import { DispatcherApi } from "../../service/API/dispatcher";
import { ServicesApi } from "../../service/API/service";

import { LocalData } from "../../service/localStorage";
import { setupSocket } from "../../service/socket/Setup_socket";
import { faviconUpdate } from "../../utils";
import i18n from '../../service/i18/i18n';
import { store } from "../../Redux/store/index";
import { ChangeViewTravelDialoge, ListFilterSetDateRange, SetMainLoaded, SetSharedDataServices, SetSharedDataVehicleTypes, SetSharedDataZone, Set_latlng, Set_OrderList_OrderTypes, SetSettings } from "../../Redux/actions/actionNames";
import { UpdateOrdersStatistics } from "../../utils/update-ordersStatistics";
import { defaultRanges } from "../../helper/module/defaultDateRange";
import momentJalaali from "moment-jalaali";
import { GetMonitoringVehicleTypeAction, OrderInfoAction } from "../../Redux/actions/actions";
import { loadGoogleMapsScript } from "../../utils/loadGoogleMapsScript";
import { getDefState, getsetActiveOrderType } from "../../Redux/reducers/order-list";
import history from "../../service/history";


const localData = new LocalData();




export const InitialViewOrder = async (orderId , call) => {
  const Dispatcher = new DispatcherApi();
  const Services = new ServicesApi();

  
  const dispatch = store.dispatch


  const VehicleTypedata = await Services.VehicleType()
  dispatch(SetSharedDataVehicleTypes(VehicleTypedata.VehicleTypes))

    let storeData = store.getState();
    if(!storeData.SharedData.mainLoaded){

      dispatch(
        ChangeViewTravelDialoge(true, orderId, null)
      );
      dispatch(
        OrderInfoAction(dispatch, {
          orderId: orderId,
        })
      );
        let defaultDate = defaultRanges(
          get_calendarType() == "jalali" ? "fa" : "en"
      )[0];
    
      let from = momentJalaali(
          defaultDate.from.day +
          "/" +
          defaultDate.from.month +
          "/" +
          defaultDate.from.year,
          get_calendarType() == "jalali" ? "jDD/jMM/jYYYY" : "DD/MM/YYYY"
      );
    
    
    
        const socket = storeData.LocalData.socket
    
    
        const data = await Dispatcher.Setting({ userId: get_id(), refreshToken: get_refreshToken() });
        dispatch(SetSettings(data))
        await Dispatcher.Account({ userId: get_id(), refreshToken: get_refreshToken() })
        var priority = ["Ondemand", "Pickup", "Delivery", "PickupDelivery"];
        let orderTypes = await data.orderTypes.sort((a, b) => priority.indexOf(a) - priority.indexOf(b))
        if(MustReRoute()){
          history.push(orderTypes[0])
        }

        if (!getDefState(orderTypes[0]).DateRange) {
          dispatch(
              ListFilterSetDateRange({
                endDate: null,
                startDate: from.startOf("day").toDate(),
  
                  // endDate:  to.toDate(),
                  // startDate: from.toDate(),
              })
          );
            }
        dispatch(Set_OrderList_OrderTypes(getsetActiveOrderType()))


        if (!getDefState(orderTypes[0]).DateRange) {
          UpdateOrdersStatistics({ type: orderTypes[0], "dueDate[start]": momentJalaali(from).unix() + "000", "dueDate[end]": null })
        } else {
          UpdateOrdersStatistics({ type: orderTypes[0] })
        }    
        let dispatcherSettings = {
          role:data.role ,

            applicationMap : data.applicationMap ,
            calendarType : data.calendarType ,
            currencyCode : data.currencyCode ,
            currencyFormat : data.currencyFormat ,
            defaultLocation : data.defaultLocation ,
            isVoipActvie: data.isVoipActvie ,
            orderTypes: orderTypes ,
            storageBaseUrl: data.storageBaseUrl ,
            stripePublicApiKey: data.stripePublicApiKey ,
            isLocalAddressActive: data.isLocalAddressActive  ,
            stripeMoto : data.stripeMoto

        }

        // loadGoogleMapsScript(data.applicationMap)

         global.config = {
            ...global.config,
            dispatcherSettings : dispatcherSettings
        }
        set_settings_dispatcher(dispatcherSettings)
    
    
    
        set_defaultLocation({
          lng: data.defaultLocation[0],
          lat: data.defaultLocation[1],
        });
    
        dispatch(GetMonitoringVehicleTypeAction(dispatch, {
          latitude: data.defaultLocation[1],
          longitude: data.defaultLocation[0]
      }, (vht) => {
    
      }))
        dispatch(
          Set_latlng({
            lng: data.defaultLocation[0],
            lat: data.defaultLocation[1],
          })
        );
    
    
        set_currencyCode(data.currencyCode);
        set_calendarType(data.calendarType);
    
        if (get_lang()) {
            dispatch({
              type: "changeDirection",
              direction: getDirectionByUrl(),
              fullDirection: getDirectionByUrl(),
              lang: global.config.Lang,
            });
            i18n.changeLanguage(get_lang());
          } else {
            set_lang(global.config.Lang);
            set_direction(getDirectionByUrl());
            dispatch({
              type: "changeDirection",
              direction: getDirectionByUrl(),
              fullDirection: getDirectionByUrl(),
              lang: global.config.Lang,
            });
            i18n.changeLanguage(global.config.Lang);
          }
    
          faviconUpdate()
          !socket && setupSocket(dispatch);
    
    
          const servicesData = await Services.Services()
          dispatch(SetSharedDataServices(servicesData.Services))
          const zoneData = await Services.Zone()
          dispatch(SetSharedDataZone(zoneData.Zones))


          dispatch(SetMainLoaded(true))
          call(true)

    
    }else{
            call(true)

    }

};







export const MustReRoute=()=>{
  var priority = ["", "/","login"];
  if(priority.indexOf(window.location.pathname) > -1 ){
    return true
  }else{
    return false
  }
 
}