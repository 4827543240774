import { Component } from "react";
import { connect } from "react-redux";
import { Set_Error_CheckVehicleByCustomer } from "../../../../../Redux/actions/actionNames";


class App extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    // if(["Delivery", "Pickup", "Ondemand"].indexOf(
    //   this.props.orderTypeActive
    // ) > -1 ){
    //   try {
    //     let vehicle = this.props.auto ? this.props.auto_activeVehicleType : this.props.manual_activeVehicleType
    //     if (vehicle) {
    
    //       let excludeCustomers = vehicle.excludeCustomers || [];
    //       let includeCustomers = vehicle.includeCustomers || [];
    
    //       if (vehicle.customerTypes.indexOf(this.props.customer.type) > -1) {
    //         if (includeCustomers.indexOf("all") > -1 || includeCustomers.indexOf(this.props.customer.id) > -1) {
    //           if (excludeCustomers.indexOf(this.props.customer.id) == -1) {
    //             this.props.dispatch(Set_Error_CheckVehicleByCustomer(false, 1))
    //           } else {
    //             this.props.dispatch(Set_Error_CheckVehicleByCustomer(true, 2))
    //           }
    //         } else {
    //           this.props.dispatch(Set_Error_CheckVehicleByCustomer(true, 3))
    //         }
    //       } else {
    //         this.props.dispatch(Set_Error_CheckVehicleByCustomer(true, 4))
    //       }
    //     } else {
    //       this.props.dispatch(Set_Error_CheckVehicleByCustomer(false, 1))
    //     }
    
    //   } catch (error) {
    //     this.props.dispatch(Set_Error_CheckVehicleByCustomer(false, 100))

    //   }
    // }else {
    //   this.props.dispatch(Set_Error_CheckVehicleByCustomer(false, 100))

    // }
 
  }

  componentWillReceiveProps(nextProps) {
    // 
    // if(["Delivery", "Pickup", "Ondemand"].indexOf(
    //   nextProps.orderTypeActive
    // ) > -1 ){
    //     try {
    //       let vehicle = nextProps.auto ? nextProps.auto_activeVehicleType : nextProps.manual_activeVehicleType
    //       if (vehicle) {

    //         let excludeCustomers = vehicle.excludeCustomers || [];
    //         let includeCustomers = vehicle.includeCustomers || [];

    //         if (vehicle.customerTypes.indexOf(nextProps.customer.type) > -1) {
    //           if (includeCustomers.indexOf("all") > -1 || includeCustomers.indexOf(nextProps.customer.id) > -1) {
    //             if (excludeCustomers.indexOf(nextProps.customer.id) == -1) {
    //               nextProps.dispatch(Set_Error_CheckVehicleByCustomer(false, 1))
    //             } else {
    //               nextProps.dispatch(Set_Error_CheckVehicleByCustomer(true, 2))
    //             }
    //           } else {
    //             nextProps.dispatch(Set_Error_CheckVehicleByCustomer(true, 3))
    //           }
    //         } else {
    //           nextProps.dispatch(Set_Error_CheckVehicleByCustomer(true, 4))
    //         }
    //       } else {
    //         nextProps.dispatch(Set_Error_CheckVehicleByCustomer(false, 1))
    //       }
    //     } catch (error) {
    //       nextProps.dispatch(Set_Error_CheckVehicleByCustomer(false, 100))

    //     }
   
    //   }else {
    //     this.props.dispatch(Set_Error_CheckVehicleByCustomer(false, 100))
  
    //   }

  }

  render() {
    return null
  }
}

const mapStateToProps = (state) => ({
  customer: state.NewOrder.newOrder.customer,


  auto: state.NewOrder.newOrder.driver.auto,
  auto_activeVehicleType:
    state.NewOrder.newOrder.driver.auto_driver.auto_activeVehicleType,
  manual_activeVehicleType:
    state.NewOrder.newOrder.driver.manual_driver.manual_activeVehicleType,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
