import React from 'react';
import styles from './login.module.css';
import { Login } from '../../components/login'



export function LoginLayout(props) {
    return <div
        className={styles.App}
    >
        <Login {...props}/>
    </div>

}


