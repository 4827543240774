import { Component } from "react";
import { connect } from "react-redux";
import { Set_Error_CheckServiceByCustomer } from "../../../../../Redux/actions/actionNames";


class App extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
  //   if (this.props.serviceactiveVehicleType) {

  //   let excludeCustomers = this.props.serviceactiveVehicleType.excludeCustomers || [];
  //   let includeCustomers = this.props.serviceactiveVehicleType.includeCustomers || [];

  //   if (this.props.serviceactiveVehicleType.customerTypes.indexOf(this.props.customer.type) > -1) {
  //     if (includeCustomers.indexOf("all") > -1 || includeCustomers.indexOf(this.props.customer.id) > -1) {
  //       if (excludeCustomers.indexOf(this.props.customer.id) == -1) {
  //         this.props.dispatch(Set_Error_CheckServiceByCustomer(false, 1))
  //       } else {
  //         this.props.dispatch(Set_Error_CheckServiceByCustomer(true, 2))
  //       }
  //     } else {
  //       this.props.dispatch(Set_Error_CheckServiceByCustomer(true, 3))
  //     }
  //   } else {
  //     this.props.dispatch(Set_Error_CheckServiceByCustomer(true, 4))
  //   }
  // }else{
  //   this.props.dispatch(Set_Error_CheckServiceByCustomer(false,1))
  // }

}

  componentWillReceiveProps(nextProps) {
    // if (nextProps.serviceactiveVehicleType) {
    //   let excludeCustomers = nextProps.serviceactiveVehicleType.excludeCustomers || [];
    //   let includeCustomers = nextProps.serviceactiveVehicleType.includeCustomers || [];

    //   if (nextProps.serviceactiveVehicleType.customerTypes.indexOf(nextProps.customer.type) > -1) {
    //     if (includeCustomers.indexOf("all") > -1 || includeCustomers.indexOf(nextProps.customer.id) > -1) {
    //       if (excludeCustomers.indexOf(nextProps.customer.id) == -1) {
    //         nextProps.dispatch(Set_Error_CheckServiceByCustomer(false, 1))
    //       } else {
    //         nextProps.dispatch(Set_Error_CheckServiceByCustomer(true, 2))
    //       }
    //     } else {
    //       nextProps.dispatch(Set_Error_CheckServiceByCustomer(true, 3))
    //     }
    //   } else {
    //     nextProps.dispatch(Set_Error_CheckServiceByCustomer(true, 4))

    //   }
    // }else{
    //   nextProps.dispatch(Set_Error_CheckServiceByCustomer(false,1))
    // }



  }

  render() {
    return null
  }
}

const mapStateToProps = (state) => ({
  serviceactiveVehicleType: state.NewOrder.newOrder.service.activeVehicleType,
  customer: state.NewOrder.newOrder.customer,

});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
