import { useSelector } from 'react-redux';
import { getDirectionByUrl } from '../helper/module';




export function useCSSTOTEXT() {
  const direction = getDirectionByUrl()


  const ltr={
    "--main-direction": "ltr",
    "--main-inverseDirection": "rtl" ,
    "--main-textAlign": "left",
    "--main-defaultFloat" : "left",
    "--main-inverseDefaultFloat" : "right"
  }

  const rtl={
    "--main-direction": "rtl",
    "--main-inverseDirection": "ltr" ,
    "--main-textAlign": "right",
    "--main-defaultFloat" : "right",
    "--main-inverseDefaultFloat" : "left"
  }

  
  return  (direction && direction === "ltr") ? ltr:rtl  ;
};

