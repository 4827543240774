import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import { Add_Service_Option, Add_Service_Option_bulkImport, Remove_Service_Option, Remove_Service_Option_bulkImport } from "../../../../../../../Redux/actions/actionNames";

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showpoper: false,
      active:false,
      selected:null
    };
    this.anchorEl = React.createRef();
  }

  handleactive = (e) => {
    if (this.optionisSaved() && String(e.target.value).trim().length == 0) {
      this.props.dispatch(Remove_Service_Option_bulkImport(this.props.id ,this.props.bulkId))
    } else {
      this.props.dispatch(Add_Service_Option_bulkImport({
        id:this.props.id,
        inputValue:e.target.value,
        type:this.props.type,
        update:this.optionisSaved()
      },this.props.bulkId))
    }

  };


  optionisSaved() {
    let booll = false;
     booll = this.props.options.find((item) => {
      return item.id === this.props.id;
    });
    if(booll){
      return true
    }else{
      return false
    }
  }

  render() {
    const { t } = this.props;
    return (
        <div className="serviceOptionInputTypeContainer" style={{margin:"0px",width:"100%"}}>
           <p>{`:${this.props.title}`} </p>
          <input placeholder={`${this.props.hint}`} type={this.props.inputType} onChange={this.handleactive} style={{width:"calc(100% - 40px)"}} />
          {(this.props.isRequired ? (!this.optionisSaved()) : false) && <span>{this.props.t("Required")}</span>}
        </div>
    );
  }
}

const mapStateToProps = (state , ownProps) => ({
  activeVehicleType: ownProps.ActiveItem.service.selectedService ,
  options:ownProps.ActiveItem.service.options,
  lang :state.LocalData.lang ,
  bulkId : ownProps.ActiveItem.id

});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
