import * as React from 'react'
import styles from './style.module.css';
import Button from '../../../design-system/lib/Button';
import { AUTO_DISPATCH, CLOSE, MAP } from '../../../assets';
import DropDown from '../../../design-system/lib/DropDown';
import Modal from '../../../design-system/lib/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { Add_Service_Option, NewTravelResetReserve, New_Order_Reset_DropOff, New_Order_Reset_DropOffs, New_Order_Reset_Pickup, New_Travel_PaymentMethod, Remove_Service_Option, Set_Auto_ActiveVehicleType, Set_NewOrder_OrderType, Set_Service_ActiveVehicleType } from '../../../Redux/actions/actionNames';
import { getCenterMap } from '../../../helper/module';
import { GetVehicletypeByServiceAction, OrderDeliveryAction, OrderPickupAction, OrderPickupDeliveryAction, OrderondemandAction, OrderscheduleAction } from '../../../Redux/actions/actions';
import { useTranslation } from 'react-i18next';
import Input from '../../../design-system/lib/Input';
import Typography from '../../../design-system/lib/Typography';
import { get_currencyCode, get_id, get_lang } from '../../../helper/UserData/userdate';
import ChangeMethod from '../changeMethod/changeMethod';
import { DetectOndemandOrReserve } from '../../../helper/worker/DetectOndemandOrReserve';
import momentJalaali from "moment-jalaali";
import { isEmpty } from "lodash";
import PriceNewOrder from '../price';
const { uuid } = require('uuidv4');


function FooterNewOrder() {
  const uidx = JSON.stringify(uuid())
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const price = useSelector((state) => state.NewOrder.newOrder.price)
  // const uid = useSelector((state) => state.NewOrder.uid)
  const uid = uuid()

  const sendOrder = useSelector((state) => state.NewOrder.newOrder.sendOrder)
  const codAmount = useSelector((state) => state.NewOrder.newOrder.codAmount)
  const customer = useSelector((state) => state.NewOrder.newOrder.customer)
  const paymentMethod = useSelector((state) => state.NewOrder.newOrder.paymentMethod.paymentMethod)
  const paymentSide = useSelector((state) => state.NewOrder.newOrder.paymentMethod.paymentSide)
  const paymentProvider = useSelector((state) => state.NewOrder.newOrder.paymentMethod.paymentProvider)
  const cardInfo = useSelector((state) => state.NewOrder.newOrder.paymentMethod.cardInfo)
  const paymentMethodId = useSelector((state) => state.NewOrder.newOrder.paymentMethod.paymentMethodId)
  const serviceactiveVehicleType = useSelector((state) => state.NewOrder.newOrder.service.activeVehicleType)
  const serviceoptions = useSelector((state) => state.NewOrder.newOrder.service.options)
  const auto = useSelector((state) => state.NewOrder.newOrder.driver.auto)
  const driveroptions = useSelector((state) => state.NewOrder.newOrder.driver.options)
  const manual_name = useSelector((state) => state.NewOrder.newOrder.driver.manual_driver.manual_name)
  const manual_family = useSelector((state) => state.NewOrder.newOrder.driver.manual_driver.manual_family)
  const manual_id = useSelector((state) => state.NewOrder.newOrder.driver.manual_driver.manual_id)
  const pickup = useSelector((state) => state.NewOrder.newOrder.pickup)
  const pickupadresss_loc = useSelector((state) => state.NewOrder.newOrder.pickup.adresss_loc)
  const firstDropOff = useSelector((state) => state.NewOrder.newOrder.dropOffs.firstDropOff)
  const otherdropOffs = useSelector((state) => state.NewOrder.newOrder.dropOffs.otherDropOffs)
  const balance = useSelector((state) => state.NewOrder.newOrder.customer.balance)
  const forceAssaigning = useSelector((state) => state.NewOrder.newOrder.driver.forceAssaigning)
  const orderTypeActive = useSelector((state) => state.NewOrder.newOrder.orderTypeActive)
  const ListOrderTypes = useSelector((state) => state.OrderList.ActiveOrderType)
  const SelectedInCommingCallAction = useSelector((state) => state.Voip.SelectedInCommingCallAction)
  const referenceID = useSelector((state) => state.NewOrder.newOrder.referenceID)
  const type = useSelector((state) => state.NewOrder.newOrder.customer.type)
  const canWalletBeNegative = useSelector((state) => state.NewOrder.newOrder.customer.canWalletBeNegative || false)
  const auto_suggestListData = useSelector((state) => state.NewOrder.newOrder.driver.auto_driver.auto_suggestListData)
  const manual_activeVehicleType = useSelector((state) => state.NewOrder.newOrder.driver.manual_driver.manual_activeVehicleType)
  const auto_activeVehicleType = useSelector((state) => state.NewOrder.newOrder.driver.auto_driver.auto_activeVehicleType)
  const [dialoge, setDialoge] = React.useState(false);
  const draft = useSelector((state) => state.NewOrder.newOrder.draft)
  const notes = useSelector((state) => state.NewOrder.newOrder.notes)


  const WalletCheck = React.useCallback(() => {
    if(!paymentSide || !paymentMethod){
      return "red"
    }else{
      if (paymentSide + paymentMethod == "SenderWallet") {
        if (price.price && price.done) {
          return WalletBalanceBussinessCheck()
        } else {
          return "grey";
        }
      } else {
        return "grey";
      }
    }
   
  }, [paymentSide, paymentMethod ,price]);


  const WalletBalanceBussinessCheck = React.useCallback(() => {
      if (Number(
        price.price.price
      ) > Number(balance)) {
        if (price.price && (customer.balance - price.price.price) >= Number(customer.minimumBalance)) {
          return "green";
        } else {
          return "red";
        }
      }else{
        return "green";
      }


    }, [customer ,price]);


    const  SendOndemandOrder=React.useCallback(() => {
      let MustAssaignVoipId = SelectedInCommingCallAction.type && SelectedInCommingCallAction.type == "NewOrder" ;
      DetectOndemandOrReserve(
        {
          pickup: pickup,
          dropOffs: [firstDropOff, otherdropOffs],
        },
        (call) => {
          if (call === "ondeman") {
            dispatch(
              OrderondemandAction(
                dispatch,
                {
                  uid : uid ,
                  surcharges: price.price?.surcharges?.map((surchargesItem)=>{
                    return        {
                      id: surchargesItem.id,
                      surchargeId: surchargesItem.surchargeId,
                      price: surchargesItem.price
                    }
                  }) ,
                  baseFarePrice: price.price.baseFarePrice,
                  tax: price.price.tax,
                  promoCode: price.promoCode ,
                  voipRecordId : MustAssaignVoipId ? SelectedInCommingCallAction.Data.id : null ,
                  codAmount: Number(codAmount),
                  isScheduled: false ,
                  customerId: customer.person
                    ? customer.id
                    : customer.BusinessId,
                  pickup: { 
                    address: pickup.address,
                    coordinates: [
                      pickup.adresss_loc.lng,
                      pickup.adresss_loc.lat,
                    ],
                    schedulePickupNow: false,
                    "scheduleDateAfter": 0,
                    "scheduleDateBefore": 0 ,
                    buildingBlock: pickup.block,
                    customerDescription: pickup.noteForDriver,
                    floor: pickup.floor,
                    fullName: pickup.senderFullName,
                    phone: pickup.senderPhoneNumber,
                    placeId: "",
                    room: pickup.unit,
                    email: pickup.email,
  
                  },
                  dropoffs: [
                    {
                      address: firstDropOff.address,
                      coordinates: [
                        firstDropOff.adresss_loc.lng,
                        firstDropOff.adresss_loc.lat,
                      ],
                      "scheduleDateAfter": 0,
                      "scheduleDateBefore": 0 ,
                      buildingBlock: firstDropOff.block,
                      floor: firstDropOff.floor,
                      fullName: firstDropOff.recieverFullName,
                      phone: firstDropOff.recieverPhoneNumber || "",
                      placeId: "",
                      room: firstDropOff.unit,
                      email:  firstDropOff.email,
                      customerDescription:
                        firstDropOff.noteForDriver,
                    },
                    ...otherdropOffs.map((drop) => {
                      return {
                        address: drop.address,
                        coordinates: [
                          drop.adresss_loc.lng,
                          drop.adresss_loc.lat,
                        ],
                        "scheduleDateAfter": 0,
                        "scheduleDateBefore": 0 ,
                        buildingBlock: drop.block,
                        floor: drop.floor,
                        fullName: drop.recieverFullName,
                        phone: drop.recieverPhoneNumber,
                        placeId: "",
                        room: drop.unit,
                        email:  drop.email,
                        customerDescription: drop.noteForDriver,
                      };
                    }),
                  ],

                  service: {
                    id: serviceactiveVehicleType.id,
                    options: [
                      ...serviceoptions.map((op) => {
                        return { id: op.id, dataId: op.item ? op.item.id ? op.item.id  || null :null : null ,  inputValue: op.inputValue ? op.inputValue : null};
                      }),
                    ],
                  },
                  paymentMethod: paymentMethod,
                  paymentSide: paymentSide,
                  paymentProvider: paymentProvider,
                  cardInfo: cardInfo,
                  paymentMethodId: paymentMethodId,
                  vehicleType: {
                    id: manual_id == "" || manual_id == null
                      ? auto_activeVehicleType &&
                        auto_activeVehicleType.id
                      : manual_activeVehicleType &&
                        manual_activeVehicleType.id,
                    options: [
                      ...driveroptions.map((op) => {
                        return { id: op.id, dataId: op.item ? op.item.id ? op.item.id  || "" :"" : "" };
                      }),
                    ],
                  },
                  referenceId :referenceID ,
                  isForceAssignActive: forceAssaigning ,
                  driverAssignmentModel: auto == null ? "manual" :  (manual_id == "" || manual_id == null ? "automatic" : "manual") ,
                  driverId: manual_id == "" || manual_id == null ? null : (isEmpty(manual_id) ? null : manual_id ),
                  userId: get_id(),
                },
                customer.number,
                manual_id == "" || manual_id == null
                  ? null
                  : {
                      id: manual_id,
                      name:
                        manual_name + " " + manual_family,
                    },
                    ListOrderTypes
              )
            );
          } else {
            dispatch(
              OrderscheduleAction(
                dispatch,
                {
                  uid : uid ,
                  surcharges: price.price?.surcharges?.map((surchargesItem)=>{
                    return        {
                      id: surchargesItem.id,
                      surchargeId: surchargesItem.surchargeId,
                      price: surchargesItem.price
                    }
                  }) ,
                  baseFarePrice: price.price.baseFarePrice,
                  tax: price.price.tax,
                  promoCode: price.promoCode ,
                  codAmount: Number(codAmount),
                  isScheduled: true ,
                  // businessId: !customer.person
                  //   ? customer.BusinessId
                  //   : "",
                  // customerId: customer.person
                  //   ? customer.id
                  //   : "",
                  customerId: !customer.person
                  ? customer.BusinessId
                  : customer.id,
                  vehicleType:{
                    id:manual_id == "" || manual_id == null
                    ? auto_activeVehicleType &&
                      auto_activeVehicleType.id
                    : manual_activeVehicleType &&
                      manual_activeVehicleType.id,
                      options: [
                        ...driveroptions.map((op) => {
                          return { id: op.id, dataId: op.item ? op.item.id ? op.item.id  || "" :"" : "" };
                        }),
                      ],
                  },
                  pickup: {
                    address: pickup.address,
                    coordinates: [
                      pickup.adresss_loc.lng,
                      pickup.adresss_loc.lat,
                    ],
                    buildingBlock: pickup.block,
                    customerDescription: pickup.noteForDriver,
                    floor: pickup.floor,
                    fullName: pickup.senderFullName,
                    phone: pickup.senderPhoneNumber,
                    placeId: "",
                    room: pickup.unit,
                    email: pickup.email,
                    schedulePickupNow:
                      pickup.scheduleDateAfter ||
                      pickup.scheduleDateBefore
                        ? false
                        : true,
                    scheduleDateAfter: momentJalaali(
                      pickup.scheduleDateAfter
                    ).isValid()
                      ? momentJalaali(
                          Number(pickup.scheduleDateAfter + "000")
                        ).unix()
                      : 0,
                    scheduleDateBefore: momentJalaali(
                      pickup.scheduleDateBefore
                    ).isValid()
                      ? momentJalaali(
                          Number(pickup.scheduleDateBefore + "000")
                        ).unix()
                      : 0,
                  },
                  dropoffs: [
                    {
                      address: firstDropOff.address,
                      coordinates: [
                        firstDropOff.adresss_loc.lng,
                        firstDropOff.adresss_loc.lat,
                      ],
                      buildingBlock: firstDropOff.block,
                      floor: firstDropOff.floor,
                      fullName: firstDropOff.recieverFullName,
                      phone: firstDropOff.recieverPhoneNumber || "",
                      placeId: "",
                      email:  firstDropOff.email,
                      room: firstDropOff.unit,
                      customerDescription:
                        firstDropOff.noteForDriver,
                      scheduleDateAfter: momentJalaali(
                        firstDropOff.scheduleDateAfter
                      ).isValid()
                        ? momentJalaali(
                            Number(
                              firstDropOff.scheduleDateAfter + "000"
                            )
                          ).unix()
                        : 0,
                      scheduleDateBefore: momentJalaali(
                        firstDropOff.scheduleDateBefore
                      ).isValid()
                        ? momentJalaali(
                            Number(
                              firstDropOff.scheduleDateBefore + "000"
                            )
                          ).unix()
                        : 0,
                    },
                    ...otherdropOffs.map((drop) => {
                      return {
                        address: drop.address,
                        coordinates: [
                          drop.adresss_loc.lng,
                          drop.adresss_loc.lat,
                        ],
                        buildingBlock: drop.block,
                        floor: drop.floor,
                        fullName: drop.recieverFullName,
                        phone: drop.recieverPhoneNumber,
                        placeId: "",
                        room: drop.unit,
                        email:  drop.email,
                        customerDescription: drop.noteForDriver,
                        scheduleDateAfter: momentJalaali(
                          drop.scheduleDateAfter
                        ).isValid()
                          ? momentJalaali(
                              Number(drop.scheduleDateAfter + "000")
                            ).unix()
                          : 0,
                        scheduleDateBefore: momentJalaali(
                          drop.scheduleDateBefore
                        ).isValid()
                          ? momentJalaali(
                              Number(drop.scheduleDateBefore + "000")
                            ).unix()
                          : 0,
                      };
                    }),
                  ],
                  // options: [
                  //   ...driveroptions.map((op) => {
                  //     return { id: op.id, dataId: op.item ? op.item.id : "" };
                  //   }),
                  // ],
                  service: {
                    id: serviceactiveVehicleType.id,
                    options: [
                      ...serviceoptions.map((op) => {
                        return { id: op.id, dataId: op.item ? op.item.id ? op.item.id  || null :null : null ,  inputValue: op.inputValue ? op.inputValue : null};
                      }),
                    ],
                  },
                  paymentMethod: paymentMethod,
                  paymentSide: paymentSide,
                  paymentProvider: paymentProvider,
                  cardInfo: cardInfo,
                  paymentMethodId: paymentMethodId,
                  referenceId :referenceID ,
                  // vehicletypeId: auto
                  //   ? auto_activeVehicleType &&
                  //     auto_activeVehicleType.id
                  //   : manual_activeVehicleType &&
                  //     manual_activeVehicleType.id,
                  isForceAssignActive: forceAssaigning ,
                  driverAssignmentModel: auto == null ? "manual" :  (manual_id == "" || manual_id == null ? "automatic" : "manual") ,
                  driverId: manual_id == "" || manual_id == null ? null : (isEmpty(manual_id) ? null : manual_id ),
                  userId: get_id(),
                },
                customer.number,
                manual_id == "" || manual_id == null
                  ? null
                  : {
                      id: manual_id,
                      name:
                        manual_name + " " + manual_family,
                    },
                    ListOrderTypes
              )
            );
          }
        }
      );
    }, [price , uid ,sendOrder,codAmount,customer,paymentMethod,paymentSide,
      paymentProvider,cardInfo,paymentMethodId,serviceactiveVehicleType,
      serviceoptions,auto,driveroptions,manual_name,manual_family,manual_id,
      pickup,pickupadresss_loc,firstDropOff,otherdropOffs,balance,forceAssaigning,
      orderTypeActive,ListOrderTypes,SelectedInCommingCallAction,referenceID,type,canWalletBeNegative]);
  
    const SendPickupOrder=React.useCallback(() => {
      let MustAssaignVoipId = SelectedInCommingCallAction.type && SelectedInCommingCallAction.type == "NewOrder" ;
  
  
      let vehicleTypeIsOk = manual_id == "" || manual_id == null
      ? auto_activeVehicleType &&
        auto_activeVehicleType.id
      : manual_activeVehicleType &&
        manual_activeVehicleType.id ;
  
  
  
      dispatch(
        OrderPickupAction(
          dispatch,
          {
            uid : uid ,
            surcharges: price.price?.surcharges?.map((surchargesItem)=>{
              return        {
                id: surchargesItem.id,
                surchargeId: surchargesItem.surchargeId,
                price: surchargesItem.price
              }
            }) ,
            baseFarePrice: price.price.baseFarePrice,
            tax: price.price.tax,
            voipRecordId : MustAssaignVoipId ? SelectedInCommingCallAction.Data.id : null ,
            codAmount: Number(codAmount),
            isScheduled: DetectIsSchedule(pickup) ,
            customerId: customer.person
              ? customer.id
              : customer.BusinessId,
            pickup: { 
              address: pickup.address,
              coordinates: [
                pickup.adresss_loc.lng,
                pickup.adresss_loc.lat,
              ],
              scheduleDateAfter: momentJalaali(
                pickup.scheduleDateAfter
              ).isValid()
                ? momentJalaali(
                    Number(pickup.scheduleDateAfter + "000")
                  ).unix()
                : 0,
              scheduleDateBefore: momentJalaali(
                pickup.scheduleDateBefore
              ).isValid()
                ? momentJalaali(
                    Number(pickup.scheduleDateBefore + "000")
                  ).unix()
                : 0,
              buildingBlock: pickup.block,
              customerDescription: pickup.noteForDriver,
              floor: pickup.floor,
              fullName: pickup.senderFullName,
              phone: pickup.senderPhoneNumber,
              placeId: "",
              room: pickup.unit,
              email: pickup.email,
            },
            service: {
              id: serviceactiveVehicleType.id,
              options: [
                ...serviceoptions.map((op) => {
                  return { id: op.id, dataId: op.item ? op.item.id ? op.item.id  || null :null : null ,  inputValue: op.inputValue ? op.inputValue : null};
                }),
              ],
            },
            paymentMethod: paymentMethod,
            paymentSide: paymentSide,
            paymentProvider: paymentProvider,
            cardInfo: cardInfo,
            paymentMethodId: paymentMethodId,
            referenceId :referenceID ,
            vehicleType: vehicleTypeIsOk ?  {
              id: manual_id == "" || manual_id == null
                ? auto_activeVehicleType &&
                  auto_activeVehicleType.id
                : manual_activeVehicleType &&
                  manual_activeVehicleType.id,
              options: [
                ...driveroptions.map((op) => {
                  return { id: op.id, dataId: op.item ? op.item.id ? op.item.id  || "" :"" : "" };
                }),
              ],
            } : null ,
            isForceAssignActive: vehicleTypeIsOk ?  forceAssaigning : false ,
            driverAssignmentModel: vehicleTypeIsOk ? manual_id == "" || manual_id == null ? "automatic" : "manual" : "automatic" ,
            driverId: vehicleTypeIsOk ? (manual_id == "" || manual_id == null ? null : (isEmpty(manual_id) ? null : manual_id )) : null,
  
            userId: get_id(),
          },
          customer.number,
          manual_id == "" || manual_id == null
            ? null
            : {
                id: manual_id,
                name:
                  manual_name + " " + manual_family,
              },
              ListOrderTypes
        )
      );
    }, [price , uid ,sendOrder,codAmount,customer,paymentMethod,paymentSide,
      paymentProvider,cardInfo,paymentMethodId,serviceactiveVehicleType,
      serviceoptions,auto,driveroptions,manual_name,manual_family,manual_id,
      pickup,pickupadresss_loc,firstDropOff,otherdropOffs,balance,forceAssaigning,
      orderTypeActive,ListOrderTypes,SelectedInCommingCallAction,referenceID,type,canWalletBeNegative]);
  
    const SendDeliveryOrder=React.useCallback(() => {
      let MustAssaignVoipId = SelectedInCommingCallAction.type && SelectedInCommingCallAction.type == "NewOrder" ;
  
  
     let vehicleTypeIsOk = manual_id == "" || manual_id == null
      ? auto_activeVehicleType &&
        auto_activeVehicleType.id
      : manual_activeVehicleType &&
        manual_activeVehicleType.id ;
  
  
      dispatch(
        OrderDeliveryAction(
          dispatch,
          {
            uid : uid ,
            surcharges: price.price?.surcharges?.map((surchargesItem)=>{
              return        {
                id: surchargesItem.id,
                surchargeId: surchargesItem.surchargeId,
                price: surchargesItem.price
              }
            }) ,
            baseFarePrice: price.price.baseFarePrice,
            tax: price.price.tax,
            voipRecordId : MustAssaignVoipId ? SelectedInCommingCallAction.Data.id : null ,
            codAmount: Number(codAmount),
            isScheduled: DetectIsSchedule(firstDropOff) ,
            customerId: customer.person
              ? customer.id
              : customer.BusinessId,
            service: {
              id: serviceactiveVehicleType.id,
              options: [
                ...serviceoptions.map((op) => {
                  return { id: op.id, dataId: op.item ? op.item.id ? op.item.id  || null :null : null ,  inputValue: op.inputValue ? op.inputValue : null};
                }),
              ],
            },
  
            dropoff:{
              address: firstDropOff.address,
              coordinates: [
                firstDropOff.adresss_loc.lng,
                firstDropOff.adresss_loc.lat,
              ],
              scheduleDateAfter: momentJalaali(
                firstDropOff.scheduleDateAfter
              ).isValid()
                ? momentJalaali(
                    Number(
                      firstDropOff.scheduleDateAfter + "000"
                    )
                  ).unix()
                : 0,
              scheduleDateBefore: momentJalaali(
                firstDropOff.scheduleDateBefore
              ).isValid()
                ? momentJalaali(
                    Number(
                      firstDropOff.scheduleDateBefore + "000"
                    )
                  ).unix()
                : 0,
              buildingBlock: firstDropOff.block,
              floor: firstDropOff.floor,
              fullName: firstDropOff.recieverFullName,
              phone: firstDropOff.recieverPhoneNumber || "",
              placeId: "",
              room: firstDropOff.unit,
              customerDescription:
                firstDropOff.noteForDriver,
                email: firstDropOff.email,
            },
            paymentMethod: paymentMethod,
            paymentSide: paymentSide,
            paymentProvider: paymentProvider,
            cardInfo: cardInfo,
            paymentMethodId: paymentMethodId,
            referenceId :referenceID ,
            vehicleType: vehicleTypeIsOk ? {
              id: manual_id == "" || manual_id == null
                ? auto_activeVehicleType &&
                  auto_activeVehicleType.id
                : manual_activeVehicleType &&
                  manual_activeVehicleType.id,
              options: [
                ...driveroptions.map((op) => {
                  return { id: op.id, dataId: op.item ? op.item.id ? op.item.id  || "" :"" : "" };
                }),
              ],
            } : null,
            isForceAssignActive: vehicleTypeIsOk ?  forceAssaigning :false ,
            driverAssignmentModel: auto == null ? "manual" :  (manual_id == "" || manual_id == null ? "automatic" : "manual") ,
            // driverAssignmentModel: vehicleTypeIsOk ?  manual_id == "" || manual_id == null ? "automatic" : "manual" : "automatic" ,
            driverId: vehicleTypeIsOk ? (manual_id == "" || manual_id == null ? null : (isEmpty(manual_id) ? null : manual_id )) : null,
            userId: get_id(),
          },
          customer.number,
          manual_id == "" || manual_id == null
            ? null
            : {
                id: manual_id,
                name:
                  manual_name + " " + manual_family,
              },
              ListOrderTypes
        )
      );
    }, [price , uid ,sendOrder,codAmount,customer,paymentMethod,paymentSide,
      paymentProvider,cardInfo,paymentMethodId,serviceactiveVehicleType,
      serviceoptions,auto,driveroptions,manual_name,manual_family,manual_id,
      pickup,pickupadresss_loc,firstDropOff,otherdropOffs,balance,forceAssaigning,
      orderTypeActive,ListOrderTypes,SelectedInCommingCallAction,referenceID,type,canWalletBeNegative]);
  
    const SendPickupDeliveryOrder=React.useCallback(() => {
      let MustAssaignVoipId = SelectedInCommingCallAction.type && SelectedInCommingCallAction.type == "NewOrder" ;
  
      
      dispatch(
        OrderPickupDeliveryAction(
          dispatch,
          {
            referenceId :referenceID ,
            uid : uid ,
            baseFarePrice: price.price.baseFarePrice,
            tax: price.price.tax,
            // promoCode: price.promoCode ,
            // voipRecordId : MustAssaignVoipId ? SelectedInCommingCallAction.Data.id : null ,
            codAmount: Number(codAmount),
            // isScheduled: DetectIsSchedule(pickup) || DetectIsSchedule(firstDropOff)  ,
            customerId: customer.person
              ? customer.id
              : customer.BusinessId,
            service: {
              id: serviceactiveVehicleType.id,
              options: [
                ...serviceoptions.map((op) => {
                  return { id: op.id, dataId: op.item ? op.item.id ? op.item.id  || null :null : null ,  inputValue: op.inputValue ? op.inputValue : null};
                }),
              ],
            },
  
            pickup:{
              address: pickup.address,
              coordinates: [
                pickup.adresss_loc.lng,
                pickup.adresss_loc.lat,
              ],
              // scheduleDateAfter: momentJalaali(
              //   pickup.scheduleDateAfter
              // ).isValid()
              //   ? momentJalaali(
              //       Number(pickup.scheduleDateAfter + "000")
              //     ).unix()
              //   : 0,
              // scheduleDateBefore: momentJalaali(
              //   pickup.scheduleDateBefore
              // ).isValid()
              //   ? momentJalaali(
              //       Number(pickup.scheduleDateBefore + "000")
              //     ).unix()
              //   : 0,
              // buildingBlock: pickup.block,
              // customerDescription: pickup.noteForDriver,
              // floor: pickup.floor,
              fullName: pickup.senderFullName,
              phone: pickup.senderPhoneNumber,
              placeId: "",
              addressDetail : pickup.addressDetail,
              // room: pickup.unit,
              email: pickup.email,
            // vehicleType: /* pickup.driver.chooseDriver ?  */{
            //   id: pickup.driver.manual_driver.manual_id == "" || pickup.driver.manual_driver.manual_id == null
            //     ? pickup.driver.auto_driver.auto_activeVehicleType &&
            //       pickup.driver.auto_driver.auto_activeVehicleType.id
            //     : pickup.driver.manual_driver.manual_activeVehicleType &&
            //       pickup.driver.manual_driver.manual_activeVehicleType.id,
            //   options: [
            //     ...pickup.driver.options.map((op) => {
            //       return { id: op.id, dataId: op.item ? op.item.id ? op.item.id  || "" :"" : "" };
            //     }),
            //   ],
            // },
            // } : {
            //   id:((auto_suggestListData && auto_suggestListData.length > 0) ? auto_suggestListData[0].id : null),
            //   options:[]
            // },
            
            // isForceAssignActive: /* pickup.driver.chooseDriver ?  pickup.driver.forceAssaigning : false , */ pickup.driver.forceAssaigning ,
            // driverAssignmentModel: /* pickup.driver.chooseDriver ? pickup.driver.auto ? "automatic" : "manual" :  "automatic" , */  pickup.driver.auto ? "automatic" : "manual" ,
            // driverId: /*  pickup.driver.chooseDriver ? pickup.driver.manual_driver.manual_id == "" || pickup.driver.manual_driver.manual_id == null ? null : pickup.driver.manual_driver.manual_id : null, */ pickup.driver.manual_driver.manual_id == "" || pickup.driver.manual_driver.manual_id == null ? null : pickup.driver.manual_driver.manual_id ,
          
          },
  
          delivery:{ 
              address: firstDropOff.address,
              addressDetail : firstDropOff.addressDetail,
              coordinates: [
                firstDropOff.adresss_loc.lng,
                firstDropOff.adresss_loc.lat,
              ],
              // scheduleDateAfter: momentJalaali(
              //   firstDropOff.scheduleDateAfter
              // ).isValid()
              //   ? momentJalaali(
              //       Number(
              //         firstDropOff.scheduleDateAfter + "000"
              //       )
              //     ).unix()
              //   : 0,
              // scheduleDateBefore: momentJalaali(
              //   firstDropOff.scheduleDateBefore
              // ).isValid()
              //   ? momentJalaali(
              //       Number(
              //         firstDropOff.scheduleDateBefore + "000"
              //       )
              //     ).unix()
              //   : 0,
              // buildingBlock: firstDropOff.block,
              // floor: firstDropOff.floor,
              fullName: firstDropOff.recieverFullName,
              phone: firstDropOff.recieverPhoneNumber || "",
              placeId: "",
              // room: firstDropOff.unit,
              // customerDescription:
              //   firstDropOff.noteForDriver,
                email: firstDropOff.email,
            // vehicleType:/* firstDropOff.driver.chooseDriver ? */ {
            //   id: firstDropOff.driver.manual_driver.manual_id == "" || firstDropOff.driver.manual_driver.manual_id == null
            //     ? firstDropOff.driver.auto_driver.auto_activeVehicleType &&
            //       firstDropOff.driver.auto_driver.auto_activeVehicleType.id
            //     : firstDropOff.driver.manual_driver.manual_activeVehicleType &&
            //       firstDropOff.driver.manual_driver.manual_activeVehicleType.id,
            //   options: [
            //     ...firstDropOff.driver.options.map((op) => {
            //       return { id: op.id, dataId: op.item ? op.item.id ? op.item.id  || "" :"" : "" };
            //     }),
            //   ],
            // } /* : {
            //   id:((auto_suggestListData && auto_suggestListData.length > 0) ? auto_suggestListData[0].id : null),
            //   options:[]
            // } */,
            // isForceAssignActive:  firstDropOff.driver.forceAssaigning ,
            // driverAssignmentModel: firstDropOff.driver.auto ? "automatic" : "manual"  ,
            // driverId: firstDropOff.driver.manual_driver.manual_id == "" || firstDropOff.driver.manual_driver.manual_id == null ? null : firstDropOff.driver.manual_driver.manual_id,
          },
            dispatcherId:get_id(),
            draft:draft,
            note:notes,
            paymentMethod: paymentMethod,
            paymentSide: paymentSide,
            paymentProvider: paymentProvider,
            cardInfo: cardInfo,
            paymentMethodId: paymentMethodId,
            // userId: get_id(),
          },
          customer.number,
          null,
              ListOrderTypes
        )
      );
    }, [price , uid ,sendOrder,codAmount,customer,paymentMethod,paymentSide,draft,notes,
      paymentProvider,cardInfo,paymentMethodId,serviceactiveVehicleType,
      serviceoptions,auto,driveroptions,manual_name,manual_family,manual_id,
      pickup,pickupadresss_loc,firstDropOff,otherdropOffs,balance,forceAssaigning,
      orderTypeActive,ListOrderTypes,SelectedInCommingCallAction,referenceID,type,canWalletBeNegative]);
  
  
    const SendOrder = React.useCallback(() => {
      
      if (sendOrder.canSaveOrder && !sendOrder.serviceError && !sendOrder.VehicleError  && !sendOrder.loading && WalletCheck() !== "red") {
        
        if(orderTypeActive== "Ondemand"){
          SendOndemandOrder()
        }else if(orderTypeActive== "Pickup"){
          
          SendPickupOrder()
        }else if(orderTypeActive== "Delivery"){
          SendDeliveryOrder()
        }else if(orderTypeActive== "PickupDelivery"){
          SendPickupDeliveryOrder()
        }
  
    
      }
    }, [price , uid ,sendOrder,codAmount,customer,paymentMethod,paymentSide,
      paymentProvider,cardInfo,paymentMethodId,serviceactiveVehicleType,
      serviceoptions,auto,driveroptions,manual_name,manual_family,manual_id,
      pickup,pickupadresss_loc,firstDropOff,otherdropOffs,balance,forceAssaigning,
      orderTypeActive,ListOrderTypes,SelectedInCommingCallAction,referenceID,type,canWalletBeNegative]);


      console.log( "dwdwdwdwdwdwd ",uid)
    return (
    <div className={styles.footerContainer} style={{zIndex : dialoge ? "6" : "5"}}>


      <div className={styles.priceContainer}>
        <Typography weight='Body_Middle_Bold' text={price.loading ? (
          t("poperLoading")
        ) : price.done ? (
          Number(price.price.price - (price.price.promoAmount > 0 ? price.price.promoAmount : 0)).toLocaleString(get_lang(), {
            style: "currency",
            currency: get_currencyCode(),
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          })
        ) : (
          "0"
        )} />

        <Typography weight='Subtitle_Tiny_Regular' text={t("Price")} />
      </div>

      <ChangeMethod />
      <PriceNewOrder dialoge={dialoge} setDialoge={setDialoge} />

      <Button onClick={SendOrder} isLoading={sendOrder.loading} size='Medium' type='Primary' text={t("CreateOrder")} style={{marginInlineStart:"10px"}} disabled={(!sendOrder.canSaveOrder || sendOrder.serviceError /*|| sendOrder.VehicleError */ || sendOrder.loading || WalletCheck() == "red")} />


    </div>
  )
}

export default FooterNewOrder



function DetectIsSchedule(pickup) {
  if ( (Number(pickup.scheduleDateBefore) > 0 ||  pickup.scheduleDateBefore != null ) || ( Number(pickup.scheduleDateAfter || pickup.scheduleDateAfter != null )) > 0) {
    return true
  } else {
    return false

  }

}
