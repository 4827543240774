import { useSelector } from "react-redux";
import { Table_setData, Table_setStatus } from "../../../../Redux/actions/actionNames";
import { store } from "../../../../Redux/store";
import { restClient } from "../../../../service/API/axios";
import momentJalaali from "moment-jalaali";
const _ = require("lodash");

export const getTableOrderList = async (prev, next, type) => {
  // let lastElement = _.last(Listdata);
  let storeData = store.getState();
  const table = storeData.Table.tableInit

  table?.toggleAllRowsSelected(false)


  store.dispatch(Table_setStatus({ key: "isLoading", value: true }))

  // if(!storeData.Table.list.orders){
  //     store.dispatch(Table_setStatus({key:"isLoading" , value:true}))
  // }
  try {
    const data = JSON.parse(JSON.stringify({
      after: next ? storeData.Table.list.endCursor : null,
      before: prev ? storeData.Table.list.startCursor : null,
      "size": storeData.Table.perPage,
      // "status[]": "Pending",
      // "dueDate[start]": "1688224655137",
      // "dueDate[end]": "1706714255137",
      ...storeData.Table.filters,
      "type": type,
      "columns": ['customerId', 'driver', 'pickup', 'dropoff', 'dropoffs', 'delivery', 'isScheduled', 'currentCandidateDrivers', 'driverAssignmentModel', 'driverId', 'delayAt', 'baseETA', 'realETA', 'isPinned', 'isDelayed', 'isPossibleForDelay', 'candidateDriversCount', 'dispatchAt', 'isDispatching', 'vehicleType', 'isUrgent', 'service', 'routeName', 'paymentMethod', 'paymentSide', 'paymentProvider', 'codAmount', 'note', 'referenceId', 'stage', 'customer', 'zone', 'price', 'trackOrder', 'customerType', 'estimatedDistanceInMeters', 'estimatedDuration', 'customerReceiptUrl', 'source', 'statusUpdatedAt', 'shipmentLabelUrl']
    }));



    const response = await restClient.request({
      method: 'get',
      url: "/api/dispatcher/order",
      params: gtelteSetter(removeEmptyObject(removeEmptyObject(removeNullFields(data))))
    });

    store.dispatch(Table_setData(response.data.data))
    store.dispatch(Table_setStatus({ key: "isLoading", value: false }))
    store.dispatch(Table_setStatus({ key: "page", value: (next || prev) ? (next ? storeData.Table.page + 1 : storeData.Table.page - 1) : 1 }))
    store.dispatch(Table_setStatus({ key: "hasError", value: false }))
  } catch (error) {
    store.dispatch(Table_setStatus({ key: "isLoading", value: false }))
    store.dispatch(Table_setStatus({ key: "hasError", value: true }))

  }
};




function removeNullFields(obj) {
  for (var key in obj) {
    if (obj[key] === null) {
      delete obj[key];
    } else if (typeof obj[key] === 'object') {
      removeNullFields(obj[key]);
    }
  }
  return obj;
}

export function removeEmptyObject(obj) {
  for (var key in obj) {
    if (typeof obj[key] == 'object' && _.isEmpty(obj[key])) {
      delete obj[key];
    }else if(typeof obj[key] == 'object' && !_.isEmpty(obj[key])){
      removeEmptyObject(obj[key])
    }
  }
  return obj;
}


function gtelteSetter(obj) {
  for (var key in obj) {
    if (["gte", "equal"].indexOf(key) > -1) {
      if (_.isObject(obj[key])) {
        obj[key] = momentJalaali(obj[key].day + "/" + obj[key].month + "/" + obj[key].year + " 00:01", "DD/MM/YYYY HH:mm").format("x")
      }
    } else if (["lte"].indexOf(key) > -1) {
      if (_.isObject(obj[key])) {
        obj[key] = momentJalaali(obj[key].day + "/" + obj[key].month + "/" + obj[key].year + " 23:59", "DD/MM/YYYY HH:mm").format("x")
      }
    } else if (typeof obj[key] === 'object') {
      gtelteSetter(obj[key]);
    }
  }
  return obj;
}
