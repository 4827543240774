import React from "react";
import { Map, Marker, TileLayer, Popup, Polyline } from "react-leaflet";
import L from "leaflet";
import { connect } from "react-redux";
import ReactDOMServer from "react-dom/server";
import PickupPin from "../../map-pickup-pin/Pin";
import DropOffPin from "../../map-dropoff-pin/Pin";
import { DropOffPinWithNumber } from "../../map-dropoff-pin-with-number";

const createtIconPickup = (color) => {
  return new L.divIcon({
    className: "",
    html: ReactDOMServer.renderToString(<PickupPin color={"#"+color}/>),
    iconSize: [54, 57],
    iconAnchor: [10, 35],
  });
};


const createtIconDropOff = (color) => {
  return new L.divIcon({
    className: "",
    html: ReactDOMServer.renderToString(<DropOffPin color={"#"+color}/>),
    iconSize: [21, 37],
    iconAnchor: [9.5, 32],
  });
};




class App extends React.Component {
 
  render() {
    let originShow = this.props.pickupAdresss_loc.lng !== "" && ["PickupDelivery", "Pickup", "Ondemand"].indexOf(
      this.props.orderTypeActive
    ) > -1;
    let destinationShow = this.props.dropOffsAdresss_loc.lng !== "" && ["PickupDelivery", "Delivery", "Ondemand"].indexOf(
      this.props.orderTypeActive
    ) > -1;
    let OthersdestinationShow = this.props.otherDropOffs.length > 0;
    return (
      <React.Fragment>
           {originShow ? (
            <Marker
              animate={true}
              position={this.props.pickupAdresss_loc}
              icon={createtIconPickup("1795ff")}
            ></Marker>
          ) : null}


          {destinationShow ? (  this.props.otherDropOffs.length == 0 ? 
              <Marker
              animate={true}
              position={this.props.dropOffsAdresss_loc}
              icon={createtIconDropOff("1795ff")}
            ></Marker>
          :
           <DropOffPinWithNumber numberStyle={{position:"absolute",width:"20px",height:"20px",left:"5px",top:"5px",fontSize:"14px",fontWeight:"bold",fontFamily:'sf_pro',textAlign:"center"}}
           mapType={this.props.mapType} 
           position={this.props.dropOffsAdresss_loc} number={1}/>
           
          ) : null}

          

          {OthersdestinationShow
            ? this.props.otherDropOffs.map((dropoff , i) => {
                return  <DropOffPinWithNumber numberStyle={{position:"absolute",width:"20px",height:"20px",left:"5px",top:"5px",fontSize:"14px",fontWeight:"bold",fontFamily:'sf_pro',textAlign:"center"}}
                mapType={this.props.mapType} 
                position={dropoff.adresss_loc} number={i+2}/>
              })
            : null}
       </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  OpenNRDialoge:state.NewOrder.OpenDialoge ,
  OpenVRDialoge:state.ViewTravel.OpenDialoge ,
  activePickupDropOff :state.NewOrder.newOrder.activePickupDropOff,
  center:state.Map.center,
  pickupAddress: state.NewOrder.newOrder.pickup.address,
  pickupAdresss_loc: state.NewOrder.newOrder.pickup.adresss_loc,
  dropOffAddress: state.NewOrder.newOrder.dropOffs.firstDropOff.address,
  dropOffsAdresss_loc: state.NewOrder.newOrder.dropOffs.firstDropOff.adresss_loc,
  otherDropOffs: state.NewOrder.newOrder.dropOffs.otherDropOffs,

  orderTypeActive: state.NewOrder.newOrder.orderTypeActive,

});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(mapStateToProps, mapDispatchToProps)(App);
