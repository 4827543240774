import { debounce, isEmpty } from 'lodash';
import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SetCategoryCollapse } from '../Redux/actions/actionNames';
import { SortToArrayCategoryListData } from '../service/worker/SortToArrayCategoryListData';


export function useCategoryData({ cursor, cursorSort, categoryName }) {
  const dataLength = useSelector((state) => state.OrderList.list[categoryName].dataLength)
  const loading = useSelector((state) => state.OrderList.list[categoryName].loading)
  const error = useSelector((state) => state.OrderList.list[categoryName].error)
  const startCursor = useSelector((state) => state.OrderList.list[categoryName].startCursor)
  const endCursor = useSelector((state) => state.OrderList.list[categoryName].endCursor)
  const nextCursor = useSelector((state) => state.OrderList.list[categoryName].nextCursor)
  // const pureData = useSelector((state) => state.OrderList.list[categoryName].data)


  return { loading, error, startCursor, endCursor, nextCursor, dataLength   };
};




