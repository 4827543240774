  import React, { useState, useEffect } from "react";
  import { connect } from "react-redux";
  import { withTranslation, Trans } from "react-i18next";
  import Slide from "@material-ui/core/Slide";
  import arrow from "../../../../../../../assets/arrow-triangle-down.svg";
  import ClickAwayListener from "@material-ui/core/ClickAwayListener";
  import ic_pin_dark from "../../../../../../../assets/ic_pin_dark.svg";
  import { Set_New_Event } from "../../../../../../../Redux/actions/actionNames";
import { OrderNewEvent_create_Action } from "../../../../../../../Redux/actions/actions";
import { get_id } from "../../../../../../../helper/UserData/userdate";



  class App extends React.Component {
    constructor(props){
      super(props);
      this.state={
        states:[ 'Created',
        'Unassigned',
        'Assigned',
        'Started',
        'Arrived',
        'PickedUp',
        'OrderDone',
        'DropoffDone',
        'CustomerFeedback',
        'AddPod',
        'CustomerCanceled',
        'DriverCanceled',
        'SupportCanceled',
        'Updated',
        'DriverAssignment',
        'DriverReject',
        'Collecting',
        'InTransit',
        'Delivering'],
        CustomerVisibility:false ,
        description: ""
      }
    }

    handleCheckBox=()=>{
      this.setState({
        CustomerVisibility: !this.state.CustomerVisibility,
      });
    }


    handleshowpoperOrderStatus = () => {
      this.setState({
        showpoperOrderStatus: !this.state.showpoperOrderStatus,
      });
    };


    handleOrderStatus =(item)=>{
      this.setState({
        OrderStatus: item,
      });
    }


    selectLoaction=()=>{
      if(this.props.LocationOnMap &&  this.props.loc){
        this.props.dispatch(Set_New_Event({mapLocation : null}))
      }
      this.props.dispatch(Set_New_Event({canSelectLocation :!this.props.LocationOnMap}))
    }


    onback=()=>{
      this.props.dispatch(Set_New_Event({canSelectLocation : false}))
      this.props.showCreateDialoge()
      this.props.dispatch(Set_New_Event({mapLocation : null}))

    }


    createNewEvent=()=>{
      if(this.state.OrderStatus && this.state.description.length > 0 ){
            this.props.dispatch(OrderNewEvent_create_Action(this.props.dispatch, {
              "dispatcherId": get_id(),
              "type": this.state.OrderStatus,
              "description": this.state.description,
              "isVisibleToCustomer": this.state.CustomerVisibility,
              "coordinates": this.props.loc ? [this.props.loc[1],this.props.loc[0]] : null
            } , this.props.orderId ))
      }
    }


    descriptionONchange=(e)=>{
      this.setState({
        description:e.target.value
      })
    }

    render() {
      return (
          <div >
            <div className="EventTimeLine_Header">
            <span className="ETLHT"> <Trans i18nKey={"CreateEvent"}> </Trans> </span>
            <span onClick={this.onback} className="ETLHTc"> <Trans i18nKey={"Close"}> </Trans> </span>
            </div>

            <serviceselector
              ref={this.anchorEl}
              onClick={this.handleshowpoperOrderStatus}
              style={{
                maxHeight:"350px",
                overflowY:"scroll",
                boxShadow: this.state.showpoperOrderStatus
                  ? "0 2px 24px 0 rgba(128, 128, 128, 0.24)"
                  : "",
              }}
              className="orderStateSelector"
              
            >
              {!this.state.showpoperOrderStatus &&
                <React.Fragment>
                  <servicename>
                    <Trans i18nKey={this.state.OrderStatus ? "event_"+this.state.OrderStatus : "PleaseSelect" }> </Trans>
                  </servicename>
                  <img
                    src={arrow}
                    className={` servicearrow ${
                      this.state.showpoperOrderStatus && "rotate180"
                    }`}
                  />
                  <span>
                      <Trans i18nKey={"Title" }> </Trans>
                  </span>  
                </React.Fragment>
              }

              {this.state.showpoperOrderStatus ? (
                <ClickAwayListener
                  onClickAway={() => this.handleshowpoperOrderStatus()}
                >
                  <div>
                    {this.state.states.map((item) => {
                      return (
                        <serviceselectorInList
                          onClick={() => this.handleOrderStatus(item)}
                        >
                          <servicename
                            style={{ fontWeight: "normal" }}
                            className={
                              this.state.OrderStatus
                                ? this.state.OrderStatus == item
                                  ? "xxx"
                                  : ""
                                : ""
                            }
                          >
                            <Trans i18nKey={"event_"+item}> </Trans>{" "}
                          </servicename>
                        </serviceselectorInList>
                      );
                    })}
                  </div>
                </ClickAwayListener>
              ) : null}
            </serviceselector>


            <div className="orderStatusDescriptionCV_Container">
                    
                    <span className="d15rp8"><Trans i18nKey={"Description" }> </Trans></span>
                    <input className="e2e834" placeholder={this.props.t("detailsAboutEvent")}onChange={this.descriptionONchange} />
                    <input onClick={this.handleCheckBox} className="che965" checked={this.state.CustomerVisibility} type="checkbox" /> <span onClick={this.handleCheckBox} className="cviwdw5"><Trans i18nKey={"CustomerVisibility" }> </Trans> </span>

                    <div style={{borderTop:"1px solid #eee",width:"100%" , float:'left',marginTop:"15px"}}>

                          <div className={`locationOnMap ${this.props.LocationOnMap && "locationOnMapSelected"}`} onClick={this.selectLoaction}>
                              <img src={ic_pin_dark} />
                              <span><Trans i18nKey={"LocationOnMap" }> </Trans></span>
                          </div>  

                    </div>  
            </div>  


            <span className={`CreateEventBTN ${(!this.state.OrderStatus || this.state.description.length == 0 ) && "CreateEventBTNDA"}`} onClick={this.createNewEvent}> 
                 <Trans i18nKey={"CreateEvent"}> </Trans> 
            </span>

            
          </div>
      );
    }
  }

  const mapStateToProps = (state) => ({
      showEventTimeLine:state.ViewTravel.eventTimeLine.show,
      LocationOnMap:state.ViewTravel.eventTimeLine.newEvent.canSelectLocation ,
      loc: state.ViewTravel.eventTimeLine.newEvent.mapLocation,
      orderId: state.ViewTravel.pureData
      ? state.ViewTravel.pureData.id
        ? state.ViewTravel.pureData.id
        : null
      : null,
  
  });
  const mapDispatchToProps = (dispatch) => ({
    dispatch: dispatch,
  });
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation()(App));
