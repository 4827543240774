import React, { useCallback, useRef, useState } from 'react'
import styles from './style.module.css';
import Button from '../../../design-system/lib/Button';
import { Add_to_route, BARCODE, Edit } from '../../../assets';
import Typography from '../../../design-system/lib/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { Table_ClearSelectedOrders } from '../../../Redux/actions/actionNames';
import TableEditStatusSelectedOrders from './components/editStatus';
import { OrderApi } from '../../../service/API/order';
import { v4 as uuidv4 } from 'uuid';
import { restClient } from '../../../service/API/axios';
import { snack } from '../../../utils/SnackbarUtilsConfigurator';

const FileDownload = require('js-file-download');


function TableSelectedOrders() {
  const selectedOrders = useSelector((state) => state.Table.selectedOrders)
  const table = useSelector((state) => state.Table.tableInit)
  const [ShipmentLabelLoading, setShipmentLabelLoading] = useState(false);

  const dispatch = useDispatch();


  const clearAllSelectedOrders = React.useCallback(() => {
    dispatch(Table_ClearSelectedOrders())
    table.toggleAllRowsSelected(false)

  }, [table]);


  const ShipmentLabelPdfExport = React.useCallback(async () => {
    setShipmentLabelLoading(true)

    restClient.post('/api/v1/dispatcher/order/shipment-label/export-pdf', {
                ids: Object.keys(selectedOrders)
            }, {
                responseType: 'blob',
                headers: {
                  withCredentials: true,
                    'x-bypass-auth': true
                }
            }).then(function(response) {
              console.log("ShipmentLabelPdfExport x2",response);
              // window.open(URL.createObjectURL(response.data));

                var blob = new Blob([response.data], { type: 'application/pdf' });
                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = response.headers["content-disposition"].replace("attachment; filename=", '');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                setShipmentLabelLoading(false)

                snack.success_i18('Shipmentlabeldownloaded')

            }).catch(function(err) {
                console.log(err);
            })
    
    // let Order = new OrderApi()
    // let data = await Order.ShipmentLabelPdfExport({
    //   "ids": Object.keys(selectedOrders)
    // })

    // if (data) {
    //   var blob = new Blob([data], { type: 'application/pdf' });
    //   console.log("BatchUpdateStatus ", data)
    //   FileDownload(blob, `ShipmentLabelPdfExport-${uuidv4()}.pdf`)

    // }
  }, [selectedOrders]);




  if(Object.keys(selectedOrders).length == 0) {
    return null
  }
  return (
    <div className={styles.TableFilter}>

      <Typography weight='Body_Middle_Medium' text={`${Object.keys(selectedOrders).length} selected`} textColor='TextDark' />
     
      {/* <Button size='Medium' type='Secondary' text='Add to Route' icon={Add_to_route} /> */}
      {/* <Button size='Medium' type='Secondary' text='Edit Status' icon={Edit} onClick={()=>{handleEditStatus()}} /> */}
    
      <TableEditStatusSelectedOrders />
      <Button isLoading={ShipmentLabelLoading} size='Medium' type='Secondary' text='Shipment Label' icon={BARCODE} onClick={ShipmentLabelPdfExport} />
      <Typography weight='Body_Middle_Medium' text='Clear All' className={styles.Clear} onClick={clearAllSelectedOrders}/>

      {/* <Button size='Medium' type='Secondary' text='Date' icon={Plus_Dark} />
        <Button size='Medium' type='Secondary' text='Order Type' icon={Plus_Dark} />
        <Button size='Medium' type='Secondary' text='Status' icon={Plus_Dark} />
        <Button size='Medium' type='Secondary' text='Stage' icon={Plus_Dark} />
        <Button size='Medium' type='Secondary' text='Zone' icon={Plus_Dark} />
        <Button size='Medium' type='Secondary' text='Route' icon={Plus_Dark} />
        <Button size='Medium' type='Secondary' text='More filters' icon={Plus_Dark} />

        <Button size='Medium' type='Secondary' icon={Search_Table} style={{marginInlineStart:"auto"}} />
        <div className={styles.divider} />
        <Button size='Medium' type='Secondary' icon={Refresh_Table} style={{backgroundColor:"unset"}} />
        <Button size='Medium' type='Secondary' icon={Settings} style={{backgroundColor:"unset"}}/> */}

     {/* <TableEditStatusSelectedOrders /> */}
    </div>
  )
}


export default TableSelectedOrders