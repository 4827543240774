import myWorker from "./test.worker";

export const SortToArrayCategoryListData = (workerStatic , pureData , cursor , cursorSort ,setWorker, call) => {

  if (workerStatic) {
    workerStatic.terminate();
    workerStatic = new myWorker();
    setWorker(workerStatic);
    workerStatic.postMessage({pureData , cursor , cursorSort});
    workerStatic.addEventListener("message", (event) => {
      call(event.data);
    });
  } else {
    workerStatic = new myWorker();
    setWorker(workerStatic);
    workerStatic.postMessage({pureData , cursor , cursorSort});
    workerStatic.addEventListener("message", (event) => {
      call(event.data);
    });
  }
};
