import React from "react";
import clsx from "clsx";
import styles from './style.module.css';
import { ProgressBarProps } from "./ProgressBar.types";



const ProgressBar = ({ className,style,width}) => {
    return (<progress className={clsx(styles["pure-material-progress-linear"] ,className && className )}  style={style ? { width:width ? width:"" , ...style} : {width:width ? width:""}}/>);
};

export default ProgressBar;

