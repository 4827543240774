import YONIYONI from "../../../assets/VroomVroom_logotype.png";
import FavIcon from "../../../assets/PROJECTS/VroomVroom.png";

export default function YONI() {
    
    return  global.config = {
    "ProjectName": "Dispatcher",
    "LogoType": YONIYONI,
    "BaseApi": "https://rest.vroomvroom.ph",
    "BaseSocket": "https://socket.vroomvroom.ph",
    "BaseTrack": "https://panel.vroomvroom.ph",
    "MapCenter": {
        "lat": "14.555765743871088",
        "lng": "121.02359693965525"
    },
    "Lang": "en",
    "Direction": "ltr",
    "AllLanguages": [{
        "lang": "en",
        "direction": "ltr",
        "i18Lang": "English"
    }],
    "FavIcon": FavIcon,
    "Country": "PH",
    "thirdPartyLoginSupport": false
} }