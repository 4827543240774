import logoType from "../../../assets/Sprint.png";
import FavIcon from "../../../assets/PROJECTS/Sprint_fav_icon.png";

export default function Sprint() {
    
    return global.config = {
    "ProjectName": "Sprint",
    "LogoType": logoType,
    "BaseApi": "https://odrest.sprint.xyz",
    "BaseSocket": "https://odsocket.sprint.xyz",
    "BaseTrack": "https://odadmin.sprint.xyz",
    "MapCenter": {
        "lat": "30.042266282460883",
        "lng": "31.246358811339153"
    },
    "Lang": "en",
    "Direction": "ltr",
    "AllLanguages": [{
        "lang": "en",
        "direction": "ltr",
        "i18Lang": "English"
    }],
    "FavIcon": FavIcon,
    "Country": "EG",
    "thirdPartyLoginSupport": true  ,
    mapType :"osm", /* osm | google */ 
    googleApiKey : ""
} }