import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';


export function useGetSearchListFilter() {
  const regexText = useSelector((state) => state.OrderList.search.regexText)
  const ActiveregexFields = useSelector((state) => state.OrderList.search.regexFields.value)
  const searchRequested = useSelector((state) => state.OrderList.search.requested)
  const searchLoading = useSelector((state) => state.OrderList.search.loading)
  const nextCursor = useSelector((state) => state.OrderList.search.nextCursor)
  const data = useSelector((state) => state.OrderList.search.data)
  const hasNextPage = useSelector((state) => state.OrderList.search.hasNextPage)

  


 
  return {searchRequested , searchLoading ,regexText ,ActiveregexFields ,nextCursor ,data , hasNextPage};
};




