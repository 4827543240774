import styles from './style.module.css';


export const DetailsContainer = (props) => {

    return <div className={styles.detailsContainer}>
        {props.children}
    </div>

};




