import * as React from 'react'
import styles from './style.module.css';
import IconProvider from '../../../design-system/lib/IconProvider';
import Typography from '../../../design-system/lib/Typography';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Status, status_dark } from '../../../assets';
import DropDown from '../../../design-system/lib/DropDown';
import { Set_newOrder_draft } from '../../../Redux/actions/actionNames';



function StatusNewOrder() {
  const { t, i18n } = useTranslation();
  const draft = useSelector((state) => state.NewOrder.newOrder.draft)
  const dispatch = useDispatch();
  const orderTypeActive = useSelector((state) => state.NewOrder.newOrder.orderTypeActive)

  if(orderTypeActive != "PickupDelivery"){
    return null
  }
  return (
    <div className={styles.CustomerNewOrderContainer}>
      <div className={styles.customerTitleContainer}>
        <IconProvider Icon={status_dark} size="16" />
        <Typography weight='Body_Middle_Bold' text={t("status")} />
      </div>

      <DropDown style={{with:"calc(100% - 20px)"}} className={styles.DropDown} size='Medium' text={draft ? t("statusDraft") : t("statusConfirmed")} placement='bottomEnd' data={[{title:"Draft",draft:true},{title:"Confirmed",draft:false}]} isRequired={false} requiredText={t("Required")} onClick={(JOJO) => {dispatch(Set_newOrder_draft(JOJO.draft))}} isSelected={false}   />


    </div>
  )
}

export default StatusNewOrder