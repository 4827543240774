import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import arrow from "../../../../../assets/chevron-down.svg";
import trash from "../../../../../assets/ic-trash.svg";
import dropoffDone from "../../../../../assets/icon-dropoff-done.svg";

import Collapse from "@material-ui/core/Collapse";
import { withTranslation, Trans } from "react-i18next";
import Backdrop from "@material-ui/core/Backdrop";
import { withStyles } from "@material-ui/core/styles";
import Pod from "./pod";
import jalali from "jalali-moment";
import momentJalaali from "moment-jalaali";
import { get_lang } from "../../../../../helper/UserData/userdate";
import { isOrderInDone } from "../../../../../helper/module";
import ic_calender from "../../../../../assets/Mutual/ic_calender.svg";


const BackdropCustom = withStyles({
  backdrop: {
    zIndex: 5000,
    backgroundColor: "rgba(0, 0, 0, 0)",
    color: "#00000",
  },
})((props) => (
  <Backdrop
    {...props}
    style={{
      zIndex: 5000,
      backgroundColor: "rgba(0, 0, 0, 0)",
      color: "#00000",
    }}
  />
));



class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.id,
    };
    this.anchorEl = React.createRef();
  }


  handleCollapse = () => {
    this.props.handleCollapse(this.state.id);
  };



  render() {
    
    const { t } = this.props;
    let reserveIsValid = this.props.drop
      ? (this.props.drop.scheduleDateBefore
          ? momentJalaali(this.props.drop.scheduleDateBefore).isValid()
          : false) ||
        (this.props.drop.scheduleDateAfter
          ? momentJalaali(this.props.drop.scheduleDateAfter).isValid()
          : false)
      : false;

    return (
      <passerngerContainer
        className={`dropOffCustomized  noboxshadow vrpickupCustomized ${
          this.props.last && "LastdropOffCustomized"
        }`}
      >
        <vrpickupdetails
          onClick={this.handleCollapse}
        >
   
       
          {/* {this.props.drop.status !== "Pending" ? (
            <img className="squree" src={dropoffDone} />
          ) : (
            <squree />
          )} */}

          <vrPickupCircle style={{backgroundColor:"#58a7ff"}}/>
          <span className="title">
            {" "}
            <Trans i18nKey={"Dropoff2"}> </Trans>{" "}
            {reserveIsValid && <span className="eruhfny"><img src={ic_calender} /> {momentJalaali(this.props.drop.scheduleDateAfter || this.props.drop.scheduleDateBefore).locale(get_lang()).format("D MMM HH:mm")} </span>}

          </span>
          <img
          onClick={this.handleCollapse}
          src={arrow}
          className={`pickupCollIconVRx ${
            this.props.activeCollapse === this.state.id && "rotate180"
          }`}
        />
         { isOrderInDone(this.props.status)   && (
          <Pod {...this.props.drop} style={{ marginTop: /* reserve */ reserveIsValid ? "8px" : "" }} />
        )}
          <span
            className={`copey`}
          >
            {this.props.drop && this.props.drop.address}
          </span>
          {/* {reserveIsValid ? (
            <span className={`miniAddressVR ViewOrderReservText`}>
              <span> {this.props.t("for")} </span>
              {
               (this.props.drop.scheduleDateAfter ?  momentJalaali(Number(this.props.drop.scheduleDateAfter)).format(
                  get_lang() == "fa"
                    ? "HH:mm jYYYY/jMM/jDD"
                    : "YYYY/MM/DD HH:mm"
                ):"") +((this.props.drop.scheduleDateBefore && this.props.drop.scheduleDateAfter) ?
                " - " : "") +
               (this.props.drop.scheduleDateBefore ? momentJalaali(Number(this.props.drop.scheduleDateBefore)).format(
                  get_lang() == "fa"
                    ? "HH:mm jYYYY/jMM/jDD"
                    : "YYYY/MM/DD HH:mm"
                ):"")}
            </span>
          ) : null} */}
        </vrpickupdetails>

        
        
        {this.props.drop && (
          <Collapse
            className="pickupcollapsecontainer"
            in={this.props.activeCollapse === this.state.id}
            timeout="auto"
            unmountOnExit
          >
            {/* {this.props.drop.status !== "Pending" && (
              <vrpa> {`${this.props.t("Deliveredat") + jalali(new Date(this.props.drop.deliveryDate)).locale(get_lang()).format(" HH:mm") }`}</vrpa>
            )}

            <vrpa>
              {this.props.drop &&
                this.props.t("address") + " : " + this.props.drop.address}
            </vrpa>
            <vrbfr
              style={{
                display:
                  this.props.drop &&
                  (this.props.drop.buildingBlock.length > 0 ||
                    this.props.drop.floor.length > 0 ||
                    this.props.drop.room.length > 0)
                    ? ""
                    : "none",
              }}
            >
              {`${
                this.props.drop.buildingBlock.length > 0
                  ? this.props.t("block") +
                    " : " +
                    this.props.drop.buildingBlock
                  : ""
              } ${
                this.props.drop &&
                (this.props.drop.buildingBlock.length > 0 ? "  |   " : "") +
                  (this.props.drop.floor.length > 0
                    ? this.props.t("Floor") + " : " + this.props.drop.floor
                    : "")
              } ${
                this.props.drop &&
                (this.props.drop.room.length > 0 ? "  |   " : "") +
                  (this.props.drop.room.length > 0
                    ? this.props.t("Unit") + " :  " + this.props.drop.room
                    : "")
              }`}
            </vrbfr>
            <vrbfr
              style={{
                display:
                  this.props.drop &&
                  (this.props.drop.fullName > 0 ||
                    this.props.drop.phone.length > 0)
                    ? ""
                    : "none",
              }}
            >
              {`${
                this.props.drop &&
                this.props.t("Recieverfullname") +
                  " : " +
                  this.props.drop.fullName
              } ${
                this.props.drop &&
                (this.props.drop.phone.length > 0 ? "  |   " : "") +
                  (this.props.t("Recieverphonenumber") +
                    " : " +
                    this.props.drop.phone)
              }`}
            </vrbfr>
            <vrnote
              style={{
                display:
                  this.props.drop &&
                  this.props.drop.customerDescription.length > 0
                    ? ""
                    : "none",
              }}
            >
              {this.props.drop &&
                this.props.t("notesfordriver") +
                  " : " +
                  this.props.drop.customerDescription}
            </vrnote> */}

        <div className="vrPickupCollapseDetail">
              <span className="xyu">
                {" "}
                {this.props.drop.buildingBlock.length > 0
                  ? this.props.drop.buildingBlock
                  : "-"}{" "}
              </span>
              <span className="xyt"> {this.props.t("block")} </span>
            </div>
            <div className="vrPickupCollapseDetail">
              <span className="xyu">
                {" "}
                {this.props.drop.floor.length > 0
                  ? this.props.drop.floor
                  : "-"}{" "}
              </span>
              <span className="xyt"> {this.props.t("Floor")} </span>
            </div>

            <div className="vrPickupCollapseDetail">
              <span className="xyu">
                {" "}
                {this.props.drop.room.length > 0
                  ? this.props.drop.room
                  : "-"}{" "}
              </span>
              <span className="xyt"> {this.props.t("Unit")} </span>
            </div>

            <div className="vrPickupCollapseDetail">
              <span className="xyu">
                {" "}
                {this.props.drop.fullName.length > 0
                  ? this.props.drop.fullName
                  : "-"}{" "}
              </span>
              <span className="xyt"> {this.props.t("Recieverfullname")} </span>
            </div>

            <div className="vrPickupCollapseDetail" style={{}}>
              <span className="xyu">
                {" "}
                {this.props.drop.phone.length > 0
                  ? this.props.drop.phone
                  : "-"}{" "}
              </span>
              <span className="xyt"> {this.props.t("Recieverphonenumber")} </span>
            </div>


            <div className="vrPickupCollapseDetail">
              <span className="xyu">
                {" "}
                {(this.props.drop.email && this.props.drop.email.length > 0)
                  ? this.props.drop.email
                  : "-"}{" "}
              </span>
              <span className="xyt"> {this.props.t("email")} </span>
            </div>

            
            <div className="vrPickupCollapseDetail">
              <span className="xyu">
                {" "}
                {this.props.drop.customerDescription.length > 0
                  ? this.props.drop.customerDescription
                  : "-"}{" "}
              </span>
              <span className="xyt"> {this.props.t("notesfordriver")} </span>
            </div>

          </Collapse>
        )}
      </passerngerContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  lang: state.LocalData.lang,
  status: state.ViewTravel.pureData ? state.ViewTravel.pureData.status : "",

});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
