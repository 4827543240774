import logotype from "../../../assets/BIG_TIC_IT_logotype.png";
import FavIcon from "../../../assets/PROJECTS/BIG_TIC_IT_fav_icon.png";

export default function BIG_TIC_IT() {
    
    return  global.config = {
    "ProjectName": "BIG TIC-IT",
    "LogoType": logotype,
    "BaseApi": "https://rest.bigtic-it.com",
    "BaseSocket": "https://socket.bigtic-it.com",
    "BaseTrack": "https://admin.bigtic-it.com",
    "MapCenter": {
        "lat": "49.28428085843864",
        "lng": "-123.12012744926915"
    },
    "Lang": "en",
    "Direction": "ltr",
    "AllLanguages": [{
        "lang": "en",
        "direction": "ltr",
        "i18Lang": "English"
    }, {
        "lang": "fr",
        "direction": "ltr",
        "i18Lang": "French"
    }],
    "FavIcon": FavIcon,
    "Country": "CA",
    "thirdPartyLoginSupport": true  ,
    mapType :"osm", /* osm | google */ 
    googleApiKey : ""
} }