import React from "react";
import clsx from "clsx";
import styles from './style.module.css';
import { IconProviderProps } from "./IconProvider.types";
// import { ADD_CREDIT_CARD } from "../../static/index.js";


const IconProvider: React.FC<IconProviderProps> = ({ onClick, style, className, ref, size, Icon }: IconProviderProps) => {
    return (
        <img src={Icon} onClick={onClick && onClick} ref={ref} className={clsx(styles[`size${size}`], className && className)} style={style ? style : {}} />
    );
};

export default IconProvider;

