import Axios from "axios";
import { getCategoryList } from "../../components/main/order-list/components/category-collapse-list/action/getCategoryList";
import { RefreshToken } from "../../helper/api";
import { get_id, get_refreshToken, set_name, set_username } from "../../helper/UserData/userdate";
import { OrderInfoAction } from "../../Redux/actions/actions";
import { store } from "../../Redux/store";
import { restClient } from "./axios";

export class BatchApi {

  async getBatchOperationInfo(data) {
    try {


      const response = await restClient.request({
        method: 'get',
        url: `/api/v1/dispatcher/batch/${data}`
      });


      return response.data.data

    } catch (error) {
     if(error.response){
      if (error.response.status === 403) {
        localStorage.clear()
        window.location.reload();
      } else if (error.response) {
        return Promise.reject({
          code: error.response.data.code,
          message: error.response.data.message,
          status: error.response.status,
        })
      } else {
        return Promise.reject()
      }
     }
    }


  }

}


