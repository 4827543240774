import { Component } from "react";
import { connect } from "react-redux";
import { CalculatePriceWorker } from "../../../../../helper/worker/CalculatePriceWorker";
import { DetectOndemandOrReserve } from "../../../../../helper/worker/DetectOndemandOrReserve";
import { CalculatePrice_Pickup_Worker } from "../../../../../helper/worker/CalculatePrice_Pickup_Worker";
import { CanOrderCalculateTravelCost } from "../../../../../helper/worker/CanOrderCalculateTravelCost";
import momentJalaali from "moment-jalaali";

class App extends Component {
  componentDidMount() {
    CalculatePrice_Pickup_Worker(
      {
        customerType:this.props.person ,

        serviceactiveVehicleType: this.props.serviceactiveVehicleType,
        serviceoptions: this.props.serviceoptions,
        auto: this.props.auto,
        driveroptions: this.props.driveroptions,
        auto_activeVehicleType: this.props.auto_activeVehicleType,
        manual_activeVehicleType:
          this.props.manual_activeVehicleType,
        pickup: {
          address: this.props.pickup.address,
          scheduleDateAfter: momentJalaali(
            this.props.pickup.scheduleDateAfter
          ).isValid()
            ? momentJalaali(
                Number(this.props.pickup.scheduleDateAfter + "000")
              ).unix()
            : 0,
          scheduleDateBefore: momentJalaali(
            this.props.pickup.scheduleDateBefore
          ).isValid()
            ? momentJalaali(
                Number(this.props.pickup.scheduleDateBefore + "000")
              ).unix()
            : 0,
          // senderFullName: this.props.pickup.senderFullName,
          // senderPhoneNumber: this.props.pickup.senderPhoneNumber,
          // unit: this.props.pickup.unit,
        },
        pickupadresss_loc: this.props.pickupadresss_loc,
        isScheduled:DetectIsSchedule(this.props.pickup)

      },
      this.props.dispatch
    );
  }

  componentWillReceiveProps(nextProps) {
    
    CanOrderCalculateTravelCost(
      {
        customerType:this.props.person ,
        serviceactiveVehicleType: this.props.serviceactiveVehicleType,
        serviceoptions: this.props.serviceoptions,
        driveroptions: this.props.driveroptions,
        auto_activeVehicleType: this.props.auto
          ? this.props.auto_activeVehicleType
          : null,
        manual_activeVehicleType: this.props.auto
          ? 0
          : this.props.manual_activeVehicleType,
        pickupadresss_loc: this.props.pickupadresss_loc,
      },
      {
        customerType:nextProps.person ,
        serviceactiveVehicleType: nextProps.serviceactiveVehicleType,
        serviceoptions: nextProps.serviceoptions,
        driveroptions: nextProps.driveroptions,
        auto_activeVehicleType: nextProps.auto
          ? nextProps.auto_activeVehicleType
          : null,
        manual_activeVehicleType: nextProps.auto
          ? 0
          : nextProps.manual_activeVehicleType,
        pickupadresss_loc: nextProps.pickupadresss_loc,
      },
      (call) => {
        

        if (call) {
          CalculatePrice_Pickup_Worker(
            {
              customerType:nextProps.person,
              
              serviceactiveVehicleType: nextProps.serviceactiveVehicleType,
              serviceoptions: nextProps.serviceoptions,
              auto: nextProps.auto,
              driveroptions: nextProps.driveroptions,
              auto_activeVehicleType: nextProps.auto_activeVehicleType,
              manual_activeVehicleType:
                nextProps.manual_activeVehicleType,
              pickup: {
                address: nextProps.pickup.address,
                scheduleDateAfter: momentJalaali(
                  nextProps.pickup.scheduleDateAfter
                ).isValid()
                  ? momentJalaali(
                      Number(nextProps.pickup.scheduleDateAfter + "000")
                    ).unix()
                  : 0,
                scheduleDateBefore: momentJalaali(
                  nextProps.pickup.scheduleDateBefore
                ).isValid()
                  ? momentJalaali(
                      Number(nextProps.pickup.scheduleDateBefore + "000")
                    ).unix()
                  : 0,
                // senderFullName: nextProps.pickup.senderFullName,
                // senderPhoneNumber: nextProps.pickup.senderPhoneNumber,
                // unit: nextProps.pickup.unit,
              },
              pickupadresss_loc: nextProps.pickupadresss_loc,
              isScheduled:DetectIsSchedule(nextProps.pickup)
            },
            this.props.dispatch
          );
        }
      }
    );
  }

  render() {
    return null;
  }
}

const mapStateToProps = (state) => ({
  person: state.NewOrder.newOrder.customer.type || null,
  serviceactiveVehicleType: state.NewOrder.newOrder.service.activeVehicleType,
  serviceoptions: state.NewOrder.newOrder.service.options,

  auto: state.NewOrder.newOrder.driver.auto,
  driveroptions: state.NewOrder.newOrder.driver.options,
  auto_activeVehicleType:
    state.NewOrder.newOrder.driver.auto_driver.auto_activeVehicleType,
  manual_activeVehicleType:
    state.NewOrder.newOrder.driver.manual_driver.manual_activeVehicleType,
  pickup: state.NewOrder.newOrder.pickup,
  pickupadresss_loc: state.NewOrder.newOrder.pickup.adresss_loc,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(App);



function DetectIsSchedule(pickup) {
  if ( (Number(pickup.scheduleDateBefore) > 0 ||  pickup.scheduleDateBefore != null ) || ( Number(pickup.scheduleDateAfter || pickup.scheduleDateAfter != null )) > 0) {
    return true
  } else {
    return false

  }

}
