import { useSelector } from 'react-redux';
import styles from './style.module.css';
export const StatsItem = ({ name, stats, icon }) => {
    const categories = useSelector((state) => state.OrderList.categories)

    const scrollToQuestionNode = () => {
        try {
            const element = document.getElementById(name);
            element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        } catch (error) {

        }
    }

    if(categories.indexOf(name)  == -1){
        return null
    }


    return <div className={styles.StatsItem} onClick={scrollToQuestionNode}>

        <img src={icon} />
        <span> {stats[name]} </span>

    </div>


};




