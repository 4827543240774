import { OrdersList } from "./components/orders-list"


export const PHUBD_Map = (props, force) => {


    return <>
            <OrdersList {...props} />
    </>

    
};




