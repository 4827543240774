import Dropp from "../../../assets/Pickmydrop_logotype.png";
import FavIcon from "../../../assets/PROJECTS/Pickmydrop.png";

export default function Pickmydrop() {
    
    return  global.config = {
    "ProjectName": "Pickmydrop",
    "LogoType": Dropp,
    "BaseApi": "https://rest.pickmydrop.co.uk",
    "BaseSocket": "https://socket.pickmydrop.co.uk",
    "BaseTrack": "https://manage.pickmydrop.co.uk",
    "MapCenter": {
        "lat": "53.003733735771824",
        "lng": "-2.1837788408263186"
    },
    "Lang": "en",
    "Direction": "ltr",
    "AllLanguages": [{
        "lang": "en",
        "direction": "ltr",
        "i18Lang": "English"
    }],
    "FavIcon": FavIcon,
    "Country": "GB",
    "thirdPartyLoginSupport": true  ,
    mapType :"google", /* osm | google */ 
    googleApiKey : ""
}}