import React from "react";

const Pickup = ({ color = "#1080FF", style ,number ,numberStyle , bigSize}) => {

  return <div style={{width:"30px",height:"54px",position:"relative",...style}} >
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={37}
    height={53}
    fill="none"
  >
    <path
      fill={color}
      fillRule="evenodd"
      d="M15 .002c-8.284 0-15 6.716-15 15 0 7.948 6.182 14.453 14 14.967v23.033a1 1 0 0 0 2 0V29.969c7.818-.514 14-7.019 14-14.967 0-8.284-6.716-15-15-15Z"
      clipRule="evenodd"
    />
    <rect width={26} height={26} x={2} y={2} fill="#fff" rx={13} />
  </svg>
    <span style={{...numberStyle ,color:color}}>{number}</span>
  </div>

};

export default Pickup



