import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import customerProfile from "../../../../../assets/customerProfile.jpg";
import { getBaseTrackByUrl } from "../../../../../helper/module";
import { get_currencyCode, get_lang } from "../../../../../helper/UserData/userdate";

class App extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <customerInformation>
    {this.props.customer && this.props.customerType !== "business" &&
        <balance>
          {" "}
          {this.props.customerType == "individual" && `${t("Balance")}: `}
          <balanceB>
          {this.props.customerType == "business" ? t("Business") : ""}

            {this.props.customerType == "individual" &&`${
              this.props.customerWallet &&
              Number(1000.5).toLocaleString(get_lang(), { style:"currency", currency:get_currencyCode(),'minimumFractionDigits':0,'maximumFractionDigits':2})
            }`}{" "}
          </balanceB>
        </balance>}
        {this.props.customer.person && <img src={`${(this.props.customer.avatarPhoto && this.props.customer.avatarPhoto.length) != 0 ? this.props.customer.avatarPhoto : customerProfile }`} />}
        <name>{this.props.customer.person ? this.props.customer.name : this.props.customer.BusinessName}</name>
        <number>{this.props.customer.person ?  this.props.customer.phone : this.props.customer.BusinessNumber}</number>
      </customerInformation>
    );
  }
}

const mapStateToProps = (state) => ({
  customer :state.EditOrder.editOrder ? state.EditOrder.editOrder.customer ? state.EditOrder.editOrder.customer : null : null,
  business :state.ViewTravel.pureData ? state.ViewTravel.pureData.business ? state.ViewTravel.pureData.business : null : null,
  customerType : state.ViewTravel.pureData ? state.ViewTravel.pureData.customerType ? state.ViewTravel.pureData.customerType : null : null,
  customerWallet:state.EditOrder.editOrder ? state.EditOrder.editOrder.customer ? state.EditOrder.editOrder.customer.balance : null : null,
  currencyCode : state.EditOrder.editOrder ? state.EditOrder.editOrder.price ? state.EditOrder.editOrder.price.currencyCode : null : null,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
