import styles from './style.module.css';
import forward from "../../../assets/forward.svg";
import pickupdot from "../../../assets/pickupdot.svg";
import dropoffdot from "../../../assets/dropoffdot.svg";
import { useDispatch, useSelector } from 'react-redux';
import schedule from "../../../assets/schedule.svg";
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { GetCategoryByStatus } from '../../../utils/get-category-by-status';
import pending from "../../../assets/ic-pending.svg";
import collecting from "../../../assets/ic-collecting.svg";
import delay from "../../../assets/ic-timer-delay.svg";
import progress from "../../../assets/ic-in-progress-voip.svg";
import delivering from "../../../assets/ic-delivering.svg";
import canceled from "../../../assets/ic-canceled.svg";
import done from "../../../assets/ic-done.svg";
import transit from "../../../assets/ic-in-transit.svg";
import { GetColorByStatus } from '../../../utils/get-colors-by-status';
import { Link } from 'react-router-dom';
import dispatch_later from "../../../assets/dispatch_later.svg";
import dispatching from "../../../assets/dispatching.svg";
import unassigned from "../../../assets/unassigned.svg";
import IconProvider from '../../../design-system/lib/IconProvider';
import { CALL, Customer, FORWARD, Routes_deactive, notes } from '../../../assets';
import Typography from '../../../design-system/lib/Typography';
import Button from '../../../design-system/lib/Button';
import { Voip_OutgoingCall } from '../../../Redux/actions/actions';



export const ViewOrderNote = () => {
    const note = useSelector((state) => state.ViewTravel.pureData.note)
    const orderType = useSelector((state) => state.ViewTravel.pureData.type)

    const dispatch = useDispatch();

    const { t } = useTranslation();

 

    if (["PickupDelivery"].indexOf(orderType) > -1 && (note && String(note).length > -1)) {
        return <div className={styles.RouteContainer}>
            <div className={styles.RouteRow}>
                <IconProvider Icon={notes} size='16' />
                <div className={styles.columnRow}>
                    <Typography weight='Body_Small_Regular' text={note} />
                </div>

            </div>
        </div>
    } else {
        return null
    }


};




