import * as React from 'react'
import styles from './style.module.css';
import IconProvider from '../../../../../design-system/lib/IconProvider';
import { BUSINESS, PROFILE} from '../../../../../assets';
import Typography from '../../../../../design-system/lib/Typography';




function CustomerListItem({data , onclick}) {
  return (
    <div className={styles.CustomerListItemContainer} onClick={onclick && onclick}>
        <IconProvider Icon={data.type == "business" ? BUSINESS : PROFILE} size='16' />

        <div className={styles.namePhoneContainer}>
            {data.name && <Typography weight='Body_Tiny_Medium' text={data.name} />}
            {data.phone && <Typography weight='Body_Tiny_Regular' text={data.phone} />}

        </div>
    </div>
  )
}

export default CustomerListItem