import React from "react";
import sad from "../../assets/sad.png";
import { Helmet } from "react-helmet";

const App = (props) => {
  return (
    <div className="App">
      <Helmet>
        <title> {`Onro`}</title>
      </Helmet>
      <img
        src={sad}
        style={{
          width: "150px",
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "150px",
        }}
      />
      <span
        style={{
          width: "100%",
          height: "25px",
          marginTop: "16px",
          fontFamily: "IRANSansfanum",
          fontSize: "16px",
          fontWeight: "bold",
          float: "right",
          textAlign: "center",
          color: " #484848",
        }}
      >
        {`Onro`}
      </span>

      <span
        style={{
          width: "90%",
          marginLeft: "5%",
          marginRight: "5%",
          height: "auto",
          marginTop: "16px",
          fontFamily: "IRANSansfanum",
          fontSize: "16px",
          fontWeight: "bold",
          float: "right",
          textAlign: "center",
          color: " #484848",
        }}
      >
       Use laptops or home systems to use this service
      </span>

      <span style={{
        backgroundColor:"#148be9",
        borderRadius:"8px",
        padding:"8px",
        color:"white",
        float:"left",
        marginLeft:"calc(50% - 54px)",
        marginTop:"25px"
      }}
       onClick={props.setIgnoreMatch}> Open anyway  </span>
    </div>
  );
};

export default App;
