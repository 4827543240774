import React, { useCallback, useRef, useState } from 'react'
import styles from './style.module.css';
import { useSelector } from 'react-redux';
import Button from '../../../design-system/lib/Button';
import { BARCODE, BOX, CLOSE, DeliveryOnly_active, Deliveryonly_deactive, Edit, IC_SEARCH, Ic_danger, LINK_CIRCLE, LOCATION, MORE, PATH, PickupOnly_Deactive, RECEIPT, ROUTE_OPTIMIZE, Search_Table, TIMER, box_gray, flag, ic_done, ic_fail, ic_home, trash } from '../../../assets';
import Typography from '../../../design-system/lib/Typography';
import { get_lang } from '../../../helper/UserData/userdate';
import jalali from "jalali-moment";
import Tag from '../../../design-system/lib/Tag';
import IconProvider from '../../../design-system/lib/IconProvider';
import Input from '../../../design-system/lib/Input';
import { useParams } from "react-router-dom";
import { Link } from 'react-router-dom';
import history from '../../../service/history';


function ListItems({ stops, route , filteredText , setFilteredText , getOrderData , selectedStop , routeId , selectedStopId}) {

  let DeliveryCount = stops.filter((item)=> String(item.address).toLocaleLowerCase().includes(String(filteredText).toLocaleLowerCase())).filter((item) => { return item.stage == "Delivery" })
  let PickupCount = stops.filter((item)=> String(item.address).toLocaleLowerCase().includes(String(filteredText).toLocaleLowerCase())).filter((item) => { return item.stage == "Pickup" })



  console.log("ListItems ",routeId)
  return (
    <div className={styles.container} >
      <Input type={"text"} iconStart={IC_SEARCH} placeHolder='Search stops' value={filteredText ? String(filteredText).trim() : ""} onChange={(e)=>{setFilteredText(e.target.value && (String(e.target.value).length > 0) ? e.target.value : "" )}} ContainerclassName={styles.input} />

     {String(filteredText).length == 0 && <div className={styles.row}>
        {(DeliveryCount?.length > 0) && <Tag backgroundColor='GrayUltraLight' iconSize='16' color='TextNormal' size='Medium' text={`Delivery: ${DeliveryCount.length}`} icon={Deliveryonly_deactive} weight='Body_Small_Medium' style={{ borderRadius: "8px" }} />}
        {(PickupCount?.length > 0) && <Tag backgroundColor='GrayUltraLight' iconSize='16' color='TextNormal' size='Medium' text={`Pickup: ${PickupCount.length}`} icon={PickupOnly_Deactive} weight='Body_Small_Medium' style={{ borderRadius: "8px" }} />}
      </div>}

      <div className={styles.listItemsContainer}>

      {String(filteredText).length == 0 &&  <div className={`${styles.itemContainer} ${styles.firstEnd}`} style={{ marginTop: "10px", minHeight: "25px" }}>
          <IconProvider Icon={ic_home} className={styles.stopIcon} />
          <div className={styles.addressStage}>
            <Typography weight='Body_Small_Medium' text={route.startLocation.address} textColor='TextDark' className={styles.address} />
          </div>
        </div>}


        {String(filteredText).length == 0 && stops.map((stop, i) => {
          return <Link to={`/route/${routeId}/${stop.id}`}  style={{textDecoration :"unset"}} ><div onClick={()=>{getOrderData(stop.id,stop)}} className={`${styles.item} ${stop.id} ${selectedStop?.id} ${selectedStopId == stop.id ? styles.selectedStop : ""}`}> <div className={styles.itemContainer} style={{border : ((i == (stops.length - 1)) && !route.endLocation) && "0px"}}>
            <span className={stop.stage == "Pickup" ? styles.stopIconP : styles.stopIconD} style={{opacity: ["Successful", "Failed"].indexOf(stop.status) > -1 ? "60%":"100%"}}>{i + 1}</span>
            <div className={styles.addressStage}>
              <Typography weight='Body_Small_Medium' text={stop.address} textColor='TextDark' className={styles.address}  style={{opacity: ["Successful", "Failed"].indexOf(stop.status) > -1 ? "60%":"100%"}} />
              <Tag backgroundColor='GrayUltraLight' iconSize='16' color='TextNormal' size='Medium' text={stop.stage} icon={stop.stage == "Pickup" ? PickupOnly_Deactive : Deliveryonly_deactive} weight='Body_Small_Medium' style={{ borderRadius: "8px" }} />
            </div>
            {["Successful", "Failed"].indexOf(stop.status) > -1 && <IconProvider Icon={stop.status == "Successful" ? ic_done : ic_fail} size='16' style={{ marginInlineEnd: "2px" ,marginInlineStart:"auto" }} />}

          </div>
          </div></Link> 
        })}


        {String(filteredText).length > 0 && stops.filter((item)=> String(item.address).toLocaleLowerCase().includes(String(filteredText).toLocaleLowerCase())).map((stop, i) => {
          return <Link to={`/route/${routeId}/${stop.id}`}  style={{textDecoration :"unset"}} ><div onClick={()=>{getOrderData(stop.id,stop)}} className={`${styles.item} ${stop.id} ${selectedStop?.id} ${selectedStop?.id == stop.id ? styles.selectedStop : ""}`}> <div className={styles.itemContainerSearch}>
            <div className={styles.addressStage}>
              <Typography weight='Body_Small_Medium' text={stop.address} textColor='TextDark' className={styles.address} />
              <Tag backgroundColor='GrayUltraLight' iconSize='16' color='TextNormal' size='Medium' text={stop.stage} icon={stop.stage == "Pickup" ? PickupOnly_Deactive : Deliveryonly_deactive} weight='Body_Small_Medium' style={{ borderRadius: "8px" }} />
            </div>
            {["Successful", "Failed"].indexOf(stop.status) > -1 && <IconProvider Icon={stop.status == "Successful" ? ic_done : ic_fail} size='16' style={{ marginInlineEnd: "2px" ,marginInlineStart:"auto" }} />}

          </div>
          </div></Link>
        })}




        {(String(filteredText).length == 0 && route.endLocation) && <div className={`${styles.itemContainer} ${styles.firstEnd}`} style={{ marginTop: "-10px" ,border:"unset" }}>
          <IconProvider Icon={flag} className={styles.stopIcon} style={{outline:"5px solid transparent"}} />
          <div className={styles.addressStage}>
            <Typography weight='Body_Small_Medium' text={route.endLocation.address} textColor='TextDark' className={styles.address} />
          </div>

        </div>}

        { (String(filteredText).length > 0 && stops.filter((item)=> String(item.address).toLocaleLowerCase().includes(String(filteredText).toLocaleLowerCase())).length == 0)   && <NoResulut clearOnclick={()=>{setFilteredText("")}} />}


        {stops.length == 0 && <AddYourOrdersError />}


      </div>

    </div>
  )
}


export default ListItems




function AddYourOrdersError({ reload }) {

  return (
      <div className='jojojoj'
          style={{ display: 'flex', position: 'relative', marginTop: `calc(50% - 104px)`, width: "100%", flexDirection: 'column', transition: "all .3s ease-in-out", justifyContent: "center", alignItems: "center" }}>
          <div className={styles.dialog}>
              <IconProvider Icon={BOX} style={{ marginBottom: "16px" }} />
              <Typography weight='Body_Middle_Bold' text='Add your orders' style={{ textAlign: "center", marginBottom: "4px" }} />
              <Typography weight='Body_Tiny_Regular' text='You can add orders from orders pages' style={{ textAlign: "center", marginBottom: "16px" }} />
              <Button size='Medium' type='Primary' text='Go to Orders' onClick={()=>{history.push("/PickupDelivery")}} />
          </div>
      </div>
  )
}


function NoResulut({clearOnclick}) {

  return (
      <div className='jojojoj'
          style={{ display: 'flex', position: 'relative', height: `calc(100vh - 104px)`, width: "100%", flexDirection: 'column', transition: "all .3s ease-in-out", justifyContent: "start", alignItems: "center" ,paddingTop:"30px"}}>
          <div className={styles.dialog}>
              <IconProvider Icon={LOCATION} style={{ marginBottom: "16px" }} size='24' />
              <Typography weight='Body_Middle_Bold' text='No Stop was found' style={{ textAlign: "center", marginBottom: "4px" }} />
              <Typography weight='Body_Tiny_Regular' text='Please check it again or try another keyword' style={{ textAlign: "center", marginBottom: "16px" }} />
                <Button size='Medium' type='Primary' text='Clear' onClick={clearOnclick} />
          </div>
      </div>
  )
}
