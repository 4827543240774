import { useState } from 'react';
import styles from './style.module.css';
import Collapse from "@material-ui/core/Collapse";
import icPin from "../../../../../assets/ic-pin.svg";
import iconArrowTriangleUp from "../../../../../assets/icon-arrow-triangle-up.svg";
import icArrowDown from "../../../../../assets/ic-arrow-down.svg";
import { OrderListItem } from "../../../../shared/order-Item";
import { useGetFiltersList } from '../../../../../hooks/useGetFiltersList';
import { useCategoryData } from '../../../../../hooks/useCategoryData';
import { useEffect } from 'react';
import { getsearchList } from './action/getsearchList';
import { useTranslation } from 'react-i18next';
import { useOrderListCanShowMore } from '../../../../../hooks/useOrderListCanShowMore';
import { useDispatch, useSelector } from 'react-redux';
import { SetCategoryOrderListData, SetCategoryOrderListLoading } from '../../../../../Redux/actions/actionNames';
import { useGetSearchListFilter } from '../../../../../hooks/useGetSearchListFilter';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { CircularProgress } from '@material-ui/core';
import { GetCategoryByStatus } from '../../../../../utils/get-category-by-status';


export const SearchHandlerList = ({ }) => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    let { cursor, cursorSort, DateRange, selectedServices, selectedStatus, selectedVehicleType, isScheduled, changed ,zones,now } = useGetFiltersList()
    let { searchRequested, searchLoading, regexText, ActiveregexFields, nextCursor, data ,hasNextPage } = useGetSearchListFilter()
    const ActiveOrderType = useSelector((state) => state.OrderList.ActiveOrderType)




    useEffect(() => {
        if (regexText.length > 0 ) {
            getsearchList(null, null)
        }
    }, [cursor, cursorSort, changed, regexText, selectedServices, selectedStatus, selectedVehicleType, isScheduled ,zones ,now , ActiveOrderType , DateRange])






    const loadMore = () => {
        getsearchList(null, data, true)
    }

    const getCategoryName = (item) => {
        return GetCategoryByStatus(item.status , item.isDispatching, item.type , item.driverAssignmentModel , item.isDelayed , item.isPossibleForDelay)
    }

    if (String(regexText).length == 0 ) {
        return null
    } else {
        return <div className={styles.categoryCollapseList} id={"search"}>
            {data.length > 0 && <span className={styles.Searchresult}>
                {t("Searchresult")}
            </span>}

            <ReactCSSTransitionGroup
                transitionName="example"
                transitionEnterTimeout={500}
                transitionLeaveTimeout={300}>
                {data.map((item, i) => {
                    return <OrderListItem data={item} categoryName={getCategoryName(item)} key={i}  showDelayLate={true}/>
                })}
            </ReactCSSTransitionGroup>

            {hasNextPage && !searchLoading &&
                <div className={styles.looadmoreContainer} onClick={loadMore}>
                    <span className={styles.looadmoreText}>
                        {t("loadMore")}
                    </span>
                    <img className={styles.looadmoreIcon} src={icArrowDown} alt="" />
                </div>
            }

            {/* <Collapse
                className={styles.CategoryCollapse}
                in={openCollapse && data.length > 0}
                timeout={500}
                unmountOnExit
            >
                <ReactCSSTransitionGroup
                    transitionName="example"
                    transitionEnterTimeout={500}
                    transitionLeaveTimeout={300}>
                    {data.map((item, i) => {
                        return <OrderListItem data={item} categoryName={categoryName} key={i} />
                    })}
                </ReactCSSTransitionGroup>

                {loading &&
                    <CircularProgress size={18} className={styles.CircularProgress} />

                }

                {canShowMore && !loading &&
                    <div className={styles.looadmoreContainer} onClick={loadMore}>
                        <span className={styles.looadmoreText}>
                            {t("loadMore")}
                        </span>
                        <img className={styles.looadmoreIcon} src={icArrowDown} alt="" />
                    </div>
                }


            </Collapse> */}
        </div>
    }

};




