import React from "react";
import { connect } from "react-redux";
import { Polyline } from "react-leaflet";

class App extends React.Component {
  render() {
    if (/*Is_InProgress_Travel(this.props.status) && */ this.props.EstimatedActive &&  this.props.EstimatedWaypoints) {
      return (
        <Polyline positions={this.props.EstimatedWaypoints} color="#39b526"  />
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => ({

  status: state.ViewTravel.pureData ? state.ViewTravel.pureData.status : "",
  EstimatedActive: state.ViewTravel.estimatedRoute.active,
  EstimatedWaypoints: state.ViewTravel.estimatedRoute.waypoints,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(mapStateToProps, mapDispatchToProps)(App);
