import styles from './style.module.css';
import clsx from 'clsx';
// TODO: Remove this
import logo from '../../../../../assets/saas/onro/icon/ic_Logo.svg'

export const Logo = ({className}) => {
    
    return <img className={"LogoType"} src={global.config.LogoType} alt="logo"  />

};




