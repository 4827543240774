
import axios from "axios";
import packageJson from '../../../package.json';
import { AxiosQueueManager } from 'axios-queue-js';


const httpCustomerqueue = new AxiosQueueManager({ queueSize: 1, client: axios.create({
  baseURL: global.config.BaseApi,
  timeout: 50000,    
  headers: {
    "retry": 2,
    "retryDelay": 300,
    "retryCount":0,
    "X-Api-Source":'dispatcher',
    "X-App-Version-Code": String(packageJson.version) ,
    "X-App-Version-Name": String(packageJson.version),
}
 })});

 export default httpCustomerqueue