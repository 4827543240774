import styles from './style.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Button from '../../../../../design-system/lib/Button';
import { CLOSE, CLOSE_DARK } from '../../../../../assets';
import Typography from '../../../../../design-system/lib/Typography';
import { SetDriverListOpenDialoge, SetSelectedMonitoringDriver, SetSelectedMonitoringOrder } from '../../../../../Redux/actions/actionNames';


export const DriversListHeader = () => {
    const dispatch = useDispatch();
    const DriverlistLoading = useSelector((state) => state.DriversList.DriverlistLoading)
    const onlineCount = useSelector((state) => state.DriversList.stats.isOnline)
    const offlineCount = useSelector((state) => state.DriversList.stats.isOffline)

    const { t } = useTranslation();



    function handleOpenCloseDialoge() {
        dispatch(SetDriverListOpenDialoge(false))
        dispatch(SetSelectedMonitoringDriver(null))
        dispatch(SetSelectedMonitoringOrder(null))

      }
    
    
    return <div className={styles.container}>
        <Button size='Medium' type='Secondary' icon={CLOSE} onClick={()=>{handleOpenCloseDialoge()}}/>
        <Typography text={t("Drivers")} weight='Body_Middle_Bold' />
    </div>

};


