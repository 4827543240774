import * as React from 'react'
import styles from './style.module.css';
import IconProvider from '../../../../../design-system/lib/IconProvider';
import { BUSINESS, CLOSE, PERSON } from '../../../../../assets';
import Typography from '../../../../../design-system/lib/Typography';
import Button from '../../../../../design-system/lib/Button';
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { NewCustomerHandleChange, OpenEditCustomer, SetRecentAddress, Set_Passenger_Na_Fa_Ph } from '../../../../../Redux/actions/actionNames';
import { useTranslation } from 'react-i18next';
import { Edit_individual_info, Get_User_Recent_Address, Register_New_Customer } from '../../../../../Redux/actions/actions';
import { Get_Addressbook_list_Api } from '../../../../../helper/api';
import Input from '../../../../../design-system/lib/Input';
import { isEmpty } from 'lodash';




function EditCustomerRegister() {
  const editCustomerIsOpen = useSelector((state) => state.NewOrder.newOrder.editCustomerIsOpen)
  const id = useSelector((state) => state.NewOrder.newOrder.customer.id)
  const customer = useSelector((state) => state.NewOrder.newOrder.customer)
  const [name, setName] = React.useState(customer.givenName);
  const [family, setFamily] = React.useState(customer.familyName);
  const [phone, setPhone] = React.useState(customer.phone);
  const [email, setEmail] = React.useState(customer.email);


  const { t, i18n } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const [customerId, setCustomerId] = React.useState(null);
  const [error, setError] = React.useState(null);

  const dispatch = useDispatch();


  const closeNewCustomer = () => {
    dispatch(OpenEditCustomer(false))
  };


  const handleChange = (key, value) => {
    setError(null)

    switch (key) {
      case "name":
        setName(value)
        break;
      case "family":
        setFamily(value)
        break;

      case "phone":
        setPhone(value)
        break;

      case "email":
        setEmail(value)
        break;

    }
  }

  React.useEffect(() => {
    if (customer) {
      setName(customer.givenName);
      setFamily(customer.familyName);
      setPhone(customer.phone);
      setEmail(customer.email);
    }

  }, [customer])


  const Emailvalidate = (email) => {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }



  const registerUser = React.useCallback(() => {

    setLoading(true)

    dispatch(Edit_individual_info(dispatch , {
      id:id , 
      info : {
        "phone": phone,
        "givenName": name,
        "familyName": family,
        "email": email
      }
    },(call)=>{
      

      dispatch(
        Set_Passenger_Na_Fa_Ph({
          name: "name",
          value: call.name,
        })
      );
      dispatch(
        Set_Passenger_Na_Fa_Ph({
          name: "phone",
          value: String(call.phone).startsWith("00") ? 
          "+"+String(call.phone).slice(2, call.phone.length) : String(call.phone),
        })
      );


  
      dispatch(
        Set_Passenger_Na_Fa_Ph({
          name: "email",
          value: call.email,
        })
      );
  
      dispatch(
        Set_Passenger_Na_Fa_Ph({
          name: "familyName",
          value: call.familyName,
        })
      );
  
      dispatch(
        Set_Passenger_Na_Fa_Ph({
          name: "givenName",
          value: call.givenName,
        })
      );

      setLoading(false)
      dispatch(OpenEditCustomer(false))


    }))

  }, [name, family, phone, email, error , id]);

  React.useEffect(() => {

    document.addEventListener('closeSliders', () => {
     //
     closeNewCustomer()
      //
    })
    return () => {
      document.removeEventListener("closeSliders");
  };
  }, [])

  if (!editCustomerIsOpen) {
    return null
  }

  let canRegister = (name?.length > 2 || family?.length > 2) && (phone?.length > 3 && (email?.length > 0 ? Emailvalidate(email) : true) ? isValidPhoneNumber(phone) : false);


  return (
    <div className={styles.NewCustomerRegisterContainer}>
      <div className={styles.largeHeader}>
        <Button icon={CLOSE} size="Medium" type="Secondary" onClick={closeNewCustomer} />
        <div className={styles.largeHeaderTitles}>
          <Typography text={t("EditCustomer")} weight="Body_Middle_Bold" className={styles.SmallTitle} style={{ margin: "0", padding: "0" }} />
        </div>
      </div>

      <div className={styles.body}>

      <Input type={"text"} value={name} placeHolder={t("Fname")} onChange={(e) => { handleChange("name", isEmpty(e.target.value) ? "" : e.target.value) }}  />


        {/* <div className={styles.inputContainer}>
          <input type="text" placeholder={t("Fname")} value={name} className={styles.input} onChange={(e) => { handleChange("name", e.target.value) }} />
        </div> */}

      <Input type={"text"} value={family} placeHolder={t("Lname")} onChange={(e) => { handleChange("family", isEmpty(e.target.value) ? "" : e.target.value) }}  />


        {/* <div className={styles.inputContainer}>
          <input type="text" placeholder={t("Lname")} value={family} className={styles.input} onChange={(e) => { handleChange("family", e.target.value) }} />
        </div> */}
        <div className={styles.phoneNumberinputContainer}>

        <PhoneInput
          defaultCountry={global.config.Country}
          placeholder={t("Phonenumber")}
          value={phone}
          onChange={(PrePhoneInput) => {
            handleChange("phone", PrePhoneInput)
          }
          }
          // disabled={(customer && String(customer.type) != "business") ? true : false}
          className={clsx(styles.inputContainer, "senderphonenumberPickup" , (phone && !isValidPhoneNumber(phone) && String(phone).length > 5 && customer.type =="business") && styles.errorBorder)}
        />
      {(phone && !isValidPhoneNumber(phone) && String(phone).length > 5 && customer.type =="business" ) && <span className={styles.error}> {t("phonenumberisnotcorrect")} </span>}

      </div>
        {/* <div className={styles.inputContainer}>
          <input type="text" placeholder={t("EmailOtional")} className={styles.input} value={email} onChange={(e) => { handleChange("email", e.target.value) }} />
        </div> */}
      <Input error={(email && String(email).length > 0 && !Emailvalidate(email)) && t("Emailisnotcorrect")} type={"text"} value={email} placeHolder={t("EmailOtional")} onChange={(e) => { handleChange("email", e.target.value) }}  />


        <Button disabled={!canRegister} isLoading={loading} text={t("Savechanges")} size="Medium" type="Primary" style={{ marginTop: "4px" }} onClick={registerUser} />

        {(error) && <Typography text={error} weight="Body_Small_Regular" style={{ marginTop: "4px" }} textColor='Red' />}

      </div>


    </div>
  )
}

export default EditCustomerRegister