import * as React from 'react';
import Drawer from 'newmaterial-ui/Drawer';
import { Settings, CLOSE, DRAG } from '../../../assets';
import Button from '../../../design-system/lib/Button';
import styles from './style.module.css';
import Typography from '../../../design-system/lib/Typography';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import styled from "@emotion/styled";
import { getTableDefaultColumn } from '../table-list/action/getTableDefaultColumn';
import IconProvider from '../../../design-system/lib/IconProvider';
import { useDispatch, useSelector } from 'react-redux';
import { Table_Init, Table_setStatus } from '../../../Redux/actions/actionNames';
import Checkbox from '../../../design-system/lib/Checkbox';


const initial = getTableDefaultColumn().filter((ido) => ido.id != "select")

const staticColumns = ["select"]

const grid = 8;


const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const QuoteItem = styled.div`
  width: 300px;
`;


function Quote({ quote, index }) {
  const tabledefaultColumnStates = useSelector((state) => state.Table.defaultColumnStates)
  const dispatch = useDispatch();


  const handleChange = React.useCallback((e , id) => {

    if(e.getIsVisible()){
      dispatch(Table_setStatus({"key" : "defaultColumnStates" , value : {...tabledefaultColumnStates , [id] : false} }))
    }else{
      dispatch(Table_setStatus({"key" : "defaultColumnStates" , value : {...tabledefaultColumnStates , [id] : true} }))
    }

    e.getToggleVisibilityHandler()
    
  }, [tabledefaultColumnStates]);


  return (
    // <Draggable draggableId={quote.id} index={index} isDragDisabled={true /*quote.id == 'select'*/}>
    //   {provided => (
    <QuoteItem
      // ref={provided.innerRef}
      // {...provided.draggableProps}
      // {...provided.dragHandleProps}
      className={styles.item}
      quote={quote}
    >
      <input
        type="checkbox"
        className={' cursor-pointer ' + styles.checkbox}
        checked={quote.getIsVisible()}
        onChange={()=>{handleChange(quote , quote.id )}}
      />
      <Typography weight='Body_Middle_Medium' text={quote.id} />

      {/* {quote.id != 'select' && <IconProvider Icon={DRAG} />} */}
    </QuoteItem>
    //   )}
    // </Draggable>
  );
}


function QuoteList({ quotes }) {
  return quotes.map((quote, index) => (
    <Quote quote={quote} index={index} key={quote.id} />
  ));
};

export default function TableListColumnSetting() {
  const tableInit = useSelector((state) => state.Table.tableInit)
  const [isOpen, setIsOpen] = React.useState(false);
  const [defaultColumnStates, setDefaultColumnStates] = React.useState(null);
  const tabledefaultColumnStates = useSelector((state) => state.Table.defaultColumnStates)
  const dispatch = useDispatch();

  console.log("lojdhw ", JSON.stringify(defaultColumnStates))

  React.useEffect(() => {
    if (tableInit && !defaultColumnStates) {
      let xx = {}
      tableInit.getAllLeafColumns().forEach(element => {
        xx[element.id] = element.getIsVisible()
      });
      setDefaultColumnStates(xx)
    }
  }, [tableInit, defaultColumnStates])



  return (
    <>
      <Button size='Medium' type='Secondary' icon={Settings} style={{ backgroundColor: "unset" }} onClick={() => setIsOpen(true)} />
      <Drawer
        anchor={'right'}
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <div className={styles.container}>
          <div className={styles.header}>
            <Button size='Medium' type='Secondary' icon={CLOSE} onClick={() => setIsOpen(false)} />
            <Typography weight='Body_Middle_Bold' text='Config Table' />
          </div>

          <div className={styles.body}>
            <Typography weight='Body_Middle_Bold' text='Active Columns' />
            {/* 
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="list">
                {provided => (
                  <div ref={provided.innerRef} {...provided.droppableProps} className={styles.innerRef}>
                    <QuoteList quotes={state.quotes} />
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext> */}



            <div className={styles.innerRef}>
              {console.log("can_toggle_show ",tableInit?.getAllLeafColumns())}
              {tableInit?.getAllLeafColumns().filter((ido) => ido.id != "select").map((quote, index) => (
                <QuoteItem

                  className={styles.item}

                >

                  <Checkbox size='Small' disabled={quote?.columnDef.hasOwnProperty("can_toggle_show") ? !quote?.columnDef.can_toggle_show : false} state={defaultColumnStates ? (defaultColumnStates[quote.id] ? "checked" : "unchecked") : "unchecked"} onClick={(e) => {


                    if (!quote?.columnDef.hasOwnProperty("can_toggle_show") || quote?.columnDef.can_toggle_show == true) {

                      dispatch(Table_setStatus({"key" : "defaultColumnStates" , value : {...tabledefaultColumnStates , [quote.id] : !defaultColumnStates[quote.id]} }))

                      setDefaultColumnStates({ ...defaultColumnStates, [quote.id]: !defaultColumnStates[quote.id] })
                      quote.toggleVisibility(!defaultColumnStates[quote.id])
                    }

                  }} />
                  {/* <input
                    type="checkbox"
                    className={' cursor-pointer ' + styles.checkbox}
                    checked={defaultColumnStates ? defaultColumnStates[quote.id] : false}
                    // onChange={quote.getToggleVisibilityHandler()}
                    onChange={(e)=>{
                      console.log(quote)
                      setDefaultColumnStates({...defaultColumnStates , [quote.id]:!defaultColumnStates[quote.id]})
                      quote.toggleVisibility(!defaultColumnStates[quote.id])
                    
                    }}
                  /> */}
                  <Typography weight='Body_Middle_Medium' text={quote.id} />

                </QuoteItem>
              ))}
            </div>

          </div>
        </div>

      </Drawer>
    </>
  );
}


