import React from "react";
import Osm from "./osm";
import Google from "./google";
import { useSelector } from "react-redux";


export const MapTrackDriverPinOnMap = (props) => {
    const VRpureData = useSelector((state) => state.ViewTravel.pureData)

    if(VRpureData){
        if (props.mapType == "google") {
            return <Google VRpureData={VRpureData} />
        } else {
            return <Osm VRpureData={VRpureData} />
        }
    }else{
        return null
    }


};




