import { ClickAwayListener } from "@material-ui/core";
import { OverlayView } from "@react-google-maps/api";
import { useDispatch, useSelector } from "react-redux";
import { GetMonitoringOrdersListData } from "../../../../../hooks/GetMonitoringOrdersListData";
import { SetDriverPopUp, SetSelectedMonitoringDriver, SetSelectedMonitoringOrder, SetOrderPinPopUp } from "../../../../../Redux/actions/actionNames";
import { GetColorByStatus } from "../../../../../utils/get-colors-by-status";
import { DropOffPin } from "../../../../shared/map-dropoff-pin";
import { PickupPin } from "../../../../shared/map-pickup-pin";
import { OrderPinPopUp } from "../../../../shared/order-pin-popup";
import styles from './style.module.css';

export const OrdersList = (props) => {
    const TableList = useSelector((state) => state.Table.list?.orders)



    return <>
    (TableList && TableList.length != 0) &&{TableList?.map((order, i) => {
        return <>
            {order.pickup && <PickupPin order={order} key={order.id + "" + i + "" + 1} id={order.id + "" + i + "" + 1} {...props} position={{ lat: order.pickup.coordinates[1], lng: order.pickup.coordinates[0] }} />}
            {order.delivery && <DropOffPin order={order} key={order.id + "" + i + "" + 2} id={order.id + "" + i + "" + 2} {...props} position={{ lat: order.delivery.coordinates[1], lng: order.delivery.coordinates[0] }} />}
        </>
    })}

    <OrderPinPopUp {...props} />

</>




};


