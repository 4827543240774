import * as React from 'react'
import styles from './style.module.css';
import IconProvider from '../../../../../design-system/lib/IconProvider';
import { BUSINESS, CLOSE, PERSON } from '../../../../../assets';
import Typography from '../../../../../design-system/lib/Typography';
import Button from '../../../../../design-system/lib/Button';
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { NewCustomerHandleChange, SetRecentAddress, Set_Passenger_Na_Fa_Ph } from '../../../../../Redux/actions/actionNames';
import { useTranslation } from 'react-i18next';
import { Get_User_Recent_Address, Register_New_Customer } from '../../../../../Redux/actions/actions';
import { Get_Addressbook_list_Api } from '../../../../../helper/api';
import Input from '../../../../../design-system/lib/Input';
import { isEmpty } from 'lodash';




function NewCustomerRegister() {
  const newCustomerIsOpen = useSelector((state) => state.NewOrder.newOrder.newCustomer.open)
  const name = useSelector((state) => state.NewOrder.newOrder.newCustomer.name)
  const family = useSelector((state) => state.NewOrder.newOrder.newCustomer.family)
  const phone = useSelector((state) => state.NewOrder.newOrder.newCustomer.phone)
  const email = useSelector((state) => state.NewOrder.newOrder.newCustomer.email)
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const [customerId, setCustomerId] = React.useState(null);
  const [error, setError] = React.useState(null);

  const dispatch = useDispatch();


  const closeNewCustomer = () => {
    // dispatch(NewCustomerHandleChange({ key: "name", value: "" }))
    // dispatch(NewCustomerHandleChange({ key: "family", value: "" }))
    // dispatch(NewCustomerHandleChange({ key: "phone", value: "" }))
    // dispatch(NewCustomerHandleChange({ key: "email", value: "" }))

    dispatch(NewCustomerHandleChange({ key: "open", value: false }))
  };


  const handleChange = (key, value) => {
    setError(null)

    dispatch(NewCustomerHandleChange({ key, value }))

  }


  const Emailvalidate = (email) => {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }



  const registerUser = React.useCallback(() => {
    setLoading(true)

    dispatch(Register_New_Customer(dispatch, {
      "phone": phone,
      "givenName": name,
      "familyName": family,
      "email": email
    }, (call, error) => {
      setLoading(false)
      setError(error ? error : false)
      setCustomerId(call ? call : null)
      if (call, !error) {
        dispatch(SetRecentAddress([]))

        dispatch(Get_User_Recent_Address(dispatch, call, null))

        // if (person) {
        dispatch(
          Set_Passenger_Na_Fa_Ph({
            name: "type",
            value: "individual",
          })
        );
        dispatch(
          Set_Passenger_Na_Fa_Ph({
            name: "name",
            value: name + " " + family,
          })
        );
        dispatch(
          Set_Passenger_Na_Fa_Ph({
            name: "familyName",
            value: family,
          })
        );

        dispatch(
          Set_Passenger_Na_Fa_Ph({
            name: "givenName",
            value: name,
          })
        );

        dispatch(
          Set_Passenger_Na_Fa_Ph({
            name: "phone",
            value: phone,
          })
        );
        dispatch(
          Set_Passenger_Na_Fa_Ph({
            name: "id",
            value: call
          })
        );

        dispatch(
          Set_Passenger_Na_Fa_Ph({
            name: "email",
            value: email
          })
        );
        dispatch(
          Set_Passenger_Na_Fa_Ph({
            name: "balance",
            value: "0",
          })
        );
        dispatch(
          Set_Passenger_Na_Fa_Ph({
            name: "avatarPhoto",
            value: null,
          })
        );
        closeNewCustomer()

      }
    }))


  }, [name, family, phone, email, error]);


  React.useEffect(() => {

    document.addEventListener('closeSliders', () => {
     //
     closeNewCustomer()
      //
    })
    return () => {
      document.removeEventListener("closeSliders");
  };
  }, [])

  if (!newCustomerIsOpen) {
    return null
  }

  let canRegister = (name?.length > 2 || family?.length > 2) && (phone?.length > 3 && (email?.length > 0 ? Emailvalidate(email) : true) ? isValidPhoneNumber(phone) : false);


  return (
    <div className={styles.NewCustomerRegisterContainer}>
      <div className={styles.largeHeader}>
        <Button icon={CLOSE} size="Medium" type="Secondary" onClick={closeNewCustomer} />
        <div className={styles.largeHeaderTitles}>
          <Typography text={t("NewCustomer")} weight="Body_Middle_Bold" className={styles.SmallTitle} style={{ margin: "0", padding: "0" }} />
        </div>
      </div>

      <div className={styles.body}>
        <Input type={"text"} value={name} placeHolder={t("Fname")} onChange={(e) => { handleChange("name", isEmpty(e.target.value) ? "" : e.target.value) }} />

        {/* <div className={styles.inputContainer}>

          <Input type={"text"} value={name} placeHolder={t("Fname")} onChange={(e) => { handleChange("name", e.target.value) }} />
          <input type="text" placeholder={t("Fname")} value={name} className={styles.input} onChange={(e) => { handleChange("name", e.target.value) }} />
        </div> */}

        <Input type={"text"} value={family} placeHolder={t("Lname")} onChange={(e) => { handleChange("family", isEmpty(e.target.value) ? "" : e.target.value) }} />

        {/* <div className={styles.inputContainer}>
          <input type="text" placeholder={t("Lname")} value={family} className={styles.input} onChange={(e) => { handleChange("family", e.target.value) }} />
        </div> */}

        <div className={styles.phoneNumberinputContainer}>
          <PhoneInput
            defaultCountry={global.config.Country}
            placeholder={t("Phonenumber")}
            value={phone}
            onChange={(PrePhoneInput) => {
              handleChange("phone", PrePhoneInput)
            }
            }
            className={clsx(styles.inputContainer, "senderphonenumberPickup", (phone && !isValidPhoneNumber(phone) && String(phone).length > 5 ) && styles.errorBorder)}
          />
          {(phone && !isValidPhoneNumber(phone) && String(phone).length > 5 ) && <span className={styles.error}> {t("phonenumberisnotcorrect")} </span>}


        </div>


        <Input error={(email && String(email).length > 0 && !Emailvalidate(email)) && t("Emailisnotcorrect")} type={"text"} value={email} placeHolder={t("EmailOtional")} onChange={(e) => { handleChange("email", e.target.value) }} />

        {/* <div className={styles.inputContainer}>
          <input type="text" placeholder={t("EmailOtional")} className={styles.input} value={email} onChange={(e) => { handleChange("email", e.target.value) }} />
        </div> */}


        <Button disabled={!canRegister} isLoading={loading} text={t("Registercustomer")} size="Medium" type="Primary" style={{ marginTop: "4px" }} onClick={registerUser} />

        {(error) && <Typography text={error ? error : t("Emailisnotcorrect")} weight="Body_Small_Regular" style={{ marginTop: "4px" }} textColor='Red' />}

      </div>


    </div>
  )
}

export default NewCustomerRegister