import React, { useEffect, useLayoutEffect, useState } from "react";
import { Map, Marker, TileLayer, Popup, Polyline } from "react-leaflet";
import L from "leaflet";
import Pin from "../Pin";
import ReactDOMServer from "react-dom/server";
import { useDispatch, useSelector } from "react-redux";
import styles from './style.module.css';
import { AddMonitoringOrdersBounding, ChangeViewTravelDialoge, SetDriverPopUp, SetOrderPinPopUp, SetOrderPinPopUpPosition, SetSelectedMonitoringDriver, SetSelectedMonitoringOrder } from "../../../../Redux/actions/actionNames";
import { GetColorByStatus } from "../../../../utils/get-colors-by-status";
import { useMonitoringPin } from "../../../../hooks/useMonitoringPin";
import { useTranslation } from "react-i18next";
import { getFavAddressListAction, OrderInfoAction } from "../../../../Redux/actions/actions";
import "./customPopup.css";
import history from "../../../../service/history";


const App = () => {
  const OrderPinPopUp = useSelector((state) => state.Monitoring.OrderPinPopUp)
  const OrderPinPopUpPosition = useSelector((state) => state.Monitoring.OrderPinPopUpPosition)

  if (!OrderPinPopUp || !OrderPinPopUpPosition) {
    return null
  }
  return <CreatePopup OrderPinPopUp={OrderPinPopUp} OrderPinPopUpPosition={OrderPinPopUpPosition} />

};

export default App


const CreatePopup = ({ OrderPinPopUp, OrderPinPopUpPosition }) => {
  const [width, setWidth] = useState(0);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const mapRef = useSelector((state) => state.Map.mapRef)

  function OpenOrderDetails(){
    let data= JSON.parse(JSON.stringify(OrderPinPopUpPosition))
    history.push(`/order/${data.orderId}`);
    dispatch(
      ChangeViewTravelDialoge(true, data.orderId, null)
    );

    // dispatch(
    //   OrderInfoAction(dispatch, {
    //     orderId: data.orderId,
    //   })
    // );
    data.customerId && dispatch(
      getFavAddressListAction(dispatch, { id: data.customerId, page: 0, perpage: 100 })
    );
    // dispatch(
    //   ChangeViewTravelDialoge(true, data.orderId, data.customerId)
    // );

    disableSelectedDriver()
  }

  function disableSelectedDriver(){
    dispatch(SetSelectedMonitoringOrder(null))
    dispatch(SetSelectedMonitoringDriver(null))
    dispatch(SetDriverPopUp(null))
    dispatch(SetOrderPinPopUp(null))
    dispatch(SetOrderPinPopUpPosition(null))
    mapRef.current.leafletElement.closePopup()
    dispatch(AddMonitoringOrdersBounding({ordersBoundingList : [] , ordersBoundingDriver: null},"852"))
  }

  // useLayoutEffect(() => {

  //   if ( OrderPinPopUpPosition && !width) {
  //   setTimeout(() => {
  //     var element = document.getElementById(`${OrderPinPopUp}popup`);
  //     var positionInfo = element && element.getBoundingClientRect();
  //     var width = positionInfo && positionInfo.width;
  //     width && setWidth(width);
  //   }, 100);
  //   }
  // }, [OrderPinPopUpPosition]);


  return <Popup position={OrderPinPopUpPosition.position} onClose={disableSelectedDriver} id={`${OrderPinPopUp}popup`} className={"monitoringDriverSelected markerIcon orderPin " + styles.OsmMarkerPopUp} >
      <span className={styles.OsmMarkerPopUpButton} onClick={OpenOrderDetails} > {t("Details")} </span>
    </Popup>

}



