import Dropp from "../../../assets/DMO_logotype.png";
import FavIcon from "../../../assets/PROJECTS/DMO.png";

export default function DMO() {
    
    return  global.config = {
    "ProjectName": "DMO",
    "LogoType": Dropp,
    "BaseApi": "https://rest.dmoapp.in",
    "BaseSocket": "https://socket.dmoapp.in",
    "BaseTrack": "https://panel.dmoapp.in",
    "MapCenter": {
        "lat": 23.34558865814294,
        "lng": 85.30900950575472
    },
    "Lang": "en",
    "Direction": "ltr",
    "AllLanguages": [{
        "lang": "en",
        "direction": "ltr",
        "i18Lang": "English"
    }],
    "FavIcon": FavIcon,
    "Country": "IN",
    "thirdPartyLoginSupport": false  ,
    mapType :"osm", /* osm | google */ 
    googleApiKey : ""
}}
