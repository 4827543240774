import React, { useCallback, useEffect, useRef, useState } from 'react'
import styles from './style.module.css';
import Button from '../../../design-system/lib/Button';
import { IC_SEARCH, Plus_Dark, REFRESH_CIRCLE, Refresh_Table, Search_Table, Settings } from '../../../assets';
import { useDispatch, useSelector } from 'react-redux';
import TableListColumnSetting from '../table-list-column-setting';
import InputFilter from '../../../design-system/lib/InputFilter';
import PhoneFilter from '../../../design-system/lib/PhoneFilter';
import CheckBoxFilter from '../../../design-system/lib/CheckBoxFilter';
import RadioButtonFilter from '../../../design-system/lib/RadioButtonFilter';
import DateFilter from '../../../design-system/lib/DateFilter';
import useMeasure from 'react-use-measure';
import { GetTableInputContainFilter } from '../../../hooks/GetTableInputContainFilter';
import { GetTableCheckboxFilter } from '../../../hooks/GetTableCheckboxFilter';
import { GetTableRadioButtonFilter } from '../../../hooks/GetTableRadioButtonFilter';
import { GetTableRangeFilter } from '../../../hooks/GetTableRangeFilter';
import { GetTableOrderListWithFilters } from '../../../hooks/GetTableOrderListWithFilters';
import Addilter from './addFilters';
import { GetTableInputRangeContainFilter } from '../../../hooks/GetTableInputRangeContainFilter';
import InputFilterwithRange from '../../../design-system/lib/InputFilterwithRange';
import { GetTableSubRangeFilter } from '../../../hooks/GetTableSubRangeFilter';
import { GetTableSubCheckboxFilter } from '../../../hooks/GetTableSubCheckboxFilter';
import { GetStatusList } from '../selectedOrders/components/editStatus';
import { DispatcherApi } from '../../../service/API/dispatcher';
import { GetTableMapOrderListWithFilters } from '../../../hooks/GetTableMapOrderListWithFilters';
import { ResetNewOrder } from '../../../Redux/actions/actionNames';



function ZoneConverter(data) {
  let zones = [];

  data.forEach(element => {
    zones.push({
      key: element.id, value: element.name
    })
  });
  return zones
}

function failureConverter(data) {
  let zones = [];

  data.forEach(element => {
    zones.push({
      key: String(element.id), value: element.text
    })
  });
  return zones
}


function TypeConverter(data) {
  let types = [];

  data.forEach(element => {
    types.push({
      id: element, title: element
    })
  });
  return types
}

function StatusConverter(data) {
  let types = [];

  data.forEach(element => {
    types.push({
      id: element.title, title: element.title
    })
  });
  return types
}

function MapTableFilters({ heightOnChange }) {
  const services = useSelector((state) => state.SharedData.services)
  const vehicleTypes = useSelector((state) => state.SharedData.vehicleTypes)
  const zone = useSelector((state) => state.SharedData.zone)
  const createdAt = useSelector((state) => state.SharedData.createdAt)
  const statusUpdatedAt = useSelector((state) => state.SharedData.statusUpdatedAt)
  const price = useSelector((state) => state.SharedData.price)
  const codAmount = useSelector((state) => state.SharedData.codAmount)
  const dueDate = useSelector((state) => state.SharedData.dueDate)
  const activeFilters = useSelector((state) => state.Table.activeFilters)
  const stages = [{id :"Delivery",title:"Delivery"},{id:"Pickup",title:"Pickup"}]
  const paymentMethods = [{id :"Cash",title:"Cash"},{id:"Wallet",title:"Wallet"}]
  const [pickupFailure, setPickupFailure] = useState(null);
  const [deliveryFailure, setDeliveryFailure] = useState(null);


  let xxxx = GetTableMapOrderListWithFilters()

  let { stages_data, stages_OnChange, stages_list } = GetTableCheckboxFilter("stages", stages)
  const [filter1, setFilter1] = useState(null);
  const [filter1state, setFilter1state] = useState("Contains");
  const [filter2, setFilter2] = useState(null);
  const [filter2state, setFilter2state] = useState("Contains");
  const [filter3, setFilter3] = useState([]);
  const [filter4, setFilter4] = useState(null);
  const [filter5, setFilter5] = useState({ equal: null, gte: null, lte: null });
  const [ref, { height }] = useMeasure()
  let { code_data, code_OnChange } = GetTableInputContainFilter("code")
  let { customerName_data, customerName_OnChange } = GetTableInputContainFilter("customerName")
  let { customerPhone_data, customerPhone_OnChange } = GetTableInputContainFilter("customerPhone")
  let { driverPhone_data, driverPhone_OnChange } = GetTableInputContainFilter("driverPhone")
  let { driverName_data, driverName_OnChange } = GetTableInputContainFilter("driverName")
  let { driverUsername_data, driverUsername_OnChange } = GetTableInputContainFilter("driverUsername")
  let { pickupDescription_data, pickupDescription_OnChange } = GetTableInputContainFilter("pickupDescription")
  let { address_data, address_OnChange } = GetTableInputContainFilter("address")
  let { referenceId_data, referenceId_OnChange } = GetTableInputContainFilter("referenceId")
  let { note_data, note_OnChange } = GetTableInputContainFilter("note")
  let { pickupAddress_data, pickupAddress_OnChange } = GetTableInputContainFilter("pickupAddress")
  let { pickupAddressDetail_data, pickupAddressDetail_OnChange } = GetTableInputContainFilter("pickupAddressDetail")
  let { pickupFullName_data, pickupFullName_OnChange } = GetTableInputContainFilter("pickupFullName")
  let { pickupPhone_data, pickupPhone_OnChange } = GetTableInputContainFilter("pickupPhone")
  let { deliveryAddress_data, deliveryAddress_OnChange } = GetTableInputContainFilter("deliveryAddress")
  let { deliveryFullName_data, deliveryFullName_OnChange } = GetTableInputContainFilter("deliveryFullName")
  let { deliveryPhone_data, deliveryPhone_OnChange } = GetTableInputContainFilter("deliveryPhone")
  let { deliveryAddressDetail_data, deliveryAddressDetail_OnChange } = GetTableInputContainFilter("deliveryAddressDetail")
  let { customerEmail_data, customerEmail_OnChange } = GetTableInputContainFilter("customerEmail")
  let { routeName_data, routeName_OnChange } = GetTableInputContainFilter("routeName")
  let { services_data, services_OnChange, services_list } = GetTableCheckboxFilter("services", services)
  let { vehicleTypes_data, vehicleTypes_OnChange, vehicleTypes_list } = GetTableCheckboxFilter("vehicleTypes", vehicleTypes)
  let { zones_data, zones_OnChange, zones_list } = GetTableCheckboxFilter("zones", zone, ZoneConverter)
  let { type_data, type_OnChange, type_list } = GetTableRadioButtonFilter("type", TypeConverter(["Ondemand", "PickupDelivery", "Pickup", "Delivery"]))
  let { createdAt_data, createdAt_OnChange } = GetTableRangeFilter("createdAt", createdAt)
  let { statusUpdatedAt_data, statusUpdatedAt_OnChange } = GetTableRangeFilter("statusUpdatedAt", statusUpdatedAt)
  let { price_data, price_OnChange } = GetTableInputRangeContainFilter("price", price)
  let { codAmount_data, codAmount_OnChange } = GetTableInputRangeContainFilter("codAmount", codAmount)
  let { dueDate_data, dueDate_OnChange } = GetTableRangeFilter("dueDate", dueDate)
  let { status_data, status_OnChange, status_list } = GetTableCheckboxFilter("status", StatusConverter(GetStatusList()))
  let { paymentMethods_data, paymentMethods_OnChange, paymentMethods_list } = GetTableCheckboxFilter("paymentMethods", paymentMethods)
  
  let { pickupcompleteAfter_data, pickupcompleteAfter_OnChange } = GetTableSubRangeFilter("pickup", "completeAfter")
  let { pickupcompleteBefore_data, pickupcompleteBefore_OnChange } = GetTableSubRangeFilter("pickup", "completeBefore")
  let { pickupzones_data, pickupzones_OnChange, pickupzones_list } = GetTableSubCheckboxFilter("pickup", "zones" , zone, ZoneConverter)

  let { deliverycompleteAfter_data, deliverycompleteAfter_OnChange } = GetTableSubRangeFilter("delivery", "completeAfter")
  let { deliverycompleteBefore_data, deliverycompleteBefore_OnChange } = GetTableSubRangeFilter("delivery", "completeBefore")
  let { deliveryzones_data, deliveryzones_OnChange, deliveryzones_list } = GetTableSubCheckboxFilter("delivery", "zones", zone, ZoneConverter)

  let { deliveryattemptsCount_data, deliveryattemptsCount_OnChange } = GetTableSubRangeFilter("delivery", "attemptsCount")
  let { deliverylastAttemptReasons_data, deliverylastAttemptReasons_OnChange, deliverylastAttemptReasons_list } = GetTableSubCheckboxFilter("delivery", "lastAttemptReasons" , pickupFailure, failureConverter)
  let { pickupfailedReasons_data, pickupfailedReasons_OnChange, pickupfailedReasons_list } = GetTableSubCheckboxFilter("pickup", "failedReasons" , deliveryFailure, failureConverter)
  const dispatch = useDispatch();


  useEffect(() => {
    height && heightOnChange(height + 54)
  }, [height])


  useEffect(() => {
    FailureReason()
  }, [])

  useEffect(() => {
    dispatch(ResetNewOrder())
  }, []);

  const FailureReason = React.useCallback(async(id) => {
    let Dispatcher = new DispatcherApi()
    let dataP = await Dispatcher.FailureReason({type: 'PickupFailure' })
    if(dataP){
        setDeliveryFailure(dataP)
    }
    let dataD = await Dispatcher.FailureReason({type: 'DeliveryFailure' })
    if(dataD){
      setPickupFailure(dataD)
    }

  }, [activeFilters]);

  const filterIsSelected = React.useCallback((id) => {
    let xx = activeFilters.findIndex((item) => { return item.stage == id }) > -1
    return xx
  }, [activeFilters]);


  const getOrdersMonitoring = React.useCallback((id) => {
    let xx = activeFilters.findIndex((item) => { return item.stage == id }) > -1
    return xx
  }, [activeFilters]);



  useEffect(() => {
    
  }, [activeFilters])



  return (
    <div className={styles.TableFilter} ref={ref}>
      {/* <Button size='Small' type='Secondary' icon={Search_Table} /> */}

      <InputFilter type={"text"} states={['Contains']} title='Code' placement='bottomStart' value={code_data} onChange={code_OnChange} state={filter1state} stateOnChange={setFilter1state} />
      <CheckBoxFilter title='Stages' placement='bottomStart' list={stages_list} value={stages_data} onChange={stages_OnChange} />
      <CheckBoxFilter title='Status' placement='bottomStart' list={status_list} value={status_data} onChange={status_OnChange} />
      <InputFilter type={"text"} states={['Contains']} title='RouteName' placement='bottomStart' value={routeName_data} onChange={routeName_OnChange} state={filter1state} stateOnChange={setFilter1state} />
      <CheckBoxFilter title='Services' placement='bottomStart' list={services_list} value={services_data} onChange={services_OnChange} />
      <DateFilter states={['Isbetween']} title='CreatedAt' placement='bottomStart' value={createdAt_data} onChange={createdAt_OnChange} />
      <DateFilter states={['Isbetween']} title='StatusUpdatedAt' placement='bottomStart' value={statusUpdatedAt_data} onChange={statusUpdatedAt_OnChange} />
      <CheckBoxFilter title='pickupzones' placement='bottomStart' list={pickupzones_list} value={pickupzones_data} onChange={pickupzones_OnChange} />
      <CheckBoxFilter title='deliveryzones' placement='bottomStart' list={deliveryzones_list} value={deliveryzones_data} onChange={deliveryzones_OnChange} />

      {filterIsSelected("customerName") && <InputFilter type={"text"} states={['Contains']} title='Customer Name' placement='bottomStart' value={customerName_data} onChange={customerName_OnChange} state={filter1state} stateOnChange={setFilter1state} />
      }
      {filterIsSelected("customerPhone") && <InputFilter type={"text"} states={['Contains']} title='Customer Phone' placement='bottomStart' value={customerPhone_data} onChange={customerPhone_OnChange} state={filter1state} stateOnChange={setFilter1state} />
      }
      {filterIsSelected("driverPhone") && <InputFilter type={"text"} states={['Contains']} title='Driver Phone' placement='bottomStart' value={driverPhone_data} onChange={driverPhone_OnChange} state={filter1state} stateOnChange={setFilter1state} />
      }
      {filterIsSelected("driverName") && <InputFilter type={"text"} states={['Contains']} title='Driver Name' placement='bottomStart' value={driverName_data} onChange={driverName_OnChange} state={filter1state} stateOnChange={setFilter1state} />
      }
      {filterIsSelected("driverUsername") && <InputFilter type={"text"} states={['Contains']} title='Driver Username' placement='bottomStart' value={driverUsername_data} onChange={driverUsername_OnChange} state={filter1state} stateOnChange={setFilter1state} />
      }
      {filterIsSelected("pickupDescription") && <InputFilter type={"text"} states={['Contains']} title='Pickup Description' placement='bottomStart' value={pickupDescription_data} onChange={pickupDescription_OnChange} state={filter1state} stateOnChange={setFilter1state} />
      }
      {filterIsSelected("address") && <InputFilter type={"text"} states={['Contains']} title='Address' placement='bottomStart' value={address_data} onChange={address_OnChange} state={filter1state} stateOnChange={setFilter1state} />
      }
      {filterIsSelected("referenceId") && <InputFilter type={"text"} states={['Contains']} title='ReferenceId' placement='bottomStart' value={referenceId_data} onChange={referenceId_OnChange} state={filter1state} stateOnChange={setFilter1state} />
      }
      {filterIsSelected("note") && <InputFilter type={"text"} states={['Contains']} title='Note' placement='bottomStart' value={note_data} onChange={note_OnChange} state={filter1state} stateOnChange={setFilter1state} />
      }
      {filterIsSelected("pickupAddress") && <InputFilter type={"text"} states={['Contains']} title='Pickup Address' placement='bottomStart' value={pickupAddress_data} onChange={pickupAddress_OnChange} state={filter1state} stateOnChange={setFilter1state} />
      }
      {filterIsSelected("pickupAddressDetail") && <InputFilter type={"text"} states={['Contains']} title='Pickup Address Detail' placement='bottomStart' value={pickupAddressDetail_data} onChange={pickupAddressDetail_OnChange} state={filter1state} stateOnChange={setFilter1state} />
      }
      {filterIsSelected("pickupFullName") && <InputFilter type={"text"} states={['Contains']} title='Pickup FullName' placement='bottomStart' value={pickupFullName_data} onChange={pickupFullName_OnChange} state={filter1state} stateOnChange={setFilter1state} />
      }
      {filterIsSelected("pickupPhone") && <InputFilter type={"text"} states={['Contains']} title='Pickup Phone' placement='bottomStart' value={pickupPhone_data} onChange={pickupPhone_OnChange} state={filter1state} stateOnChange={setFilter1state} />
      }
      {filterIsSelected("deliveryAddress") && <InputFilter type={"text"} states={['Contains']} title='Delivery Address' placement='bottomStart' value={deliveryAddress_data} onChange={deliveryAddress_OnChange} state={filter1state} stateOnChange={setFilter1state} />
      }
      {filterIsSelected("deliveryFullName") && <InputFilter type={"text"} states={['Contains']} title='Delivery FullName' placement='bottomStart' value={deliveryFullName_data} onChange={deliveryFullName_OnChange} state={filter1state} stateOnChange={setFilter1state} />
      }
      {filterIsSelected("deliveryPhone") && <InputFilter type={"text"} states={['Contains']} title='Delivery Phone' placement='bottomStart' value={deliveryPhone_data} onChange={deliveryPhone_OnChange} state={filter1state} stateOnChange={setFilter1state} />
      }
      {filterIsSelected("deliveryAddressDetail") && <InputFilter type={"text"} states={['Contains']} title='Delivery AddressDetail' placement='bottomStart' value={deliveryAddressDetail_data} onChange={deliveryAddressDetail_OnChange} state={filter1state} stateOnChange={setFilter1state} />
      }
      {filterIsSelected("customerEmail") && <InputFilter type={"text"} states={['Contains']} title='Customer Email' placement='bottomStart' value={customerEmail_data} onChange={customerEmail_OnChange} state={filter1state} stateOnChange={setFilter1state} />
      }
      

      {/* {filterIsSelected("services") && <CheckBoxFilter title='Services' placement='bottomStart' list={services_list} value={services_data} onChange={services_OnChange} />
      } */}
      {filterIsSelected("vehicleTypes") && <CheckBoxFilter title='VehicleTypes' placement='bottomStart' list={vehicleTypes_list} value={vehicleTypes_data} onChange={vehicleTypes_OnChange} />
      }
      {filterIsSelected("zones") && <CheckBoxFilter title='Zones' placement='bottomStart' list={zones_list} value={zones_data} onChange={zones_OnChange} />
      }
      {filterIsSelected("type") && <RadioButtonFilter title='Type' placement='bottomStart' list={type_list} value={type_data} onChange={type_OnChange} />
      }
      {filterIsSelected("dueDate") && <DateFilter states={['Isbetween']} title='DueDate' placement='bottomStart' value={dueDate_data} onChange={dueDate_OnChange} />
      }
      {/* {filterIsSelected("createdAt") && <DateFilter states={['Isbetween']} title='CreatedAt' placement='bottomStart' value={createdAt_data} onChange={createdAt_OnChange} />
      } */}
      {/* {filterIsSelected("statusUpdatedAt") && <DateFilter states={['Isbetween']} title='StatusUpdatedAt' placement='bottomStart' value={statusUpdatedAt_data} onChange={statusUpdatedAt_OnChange} />
      } */}
      {filterIsSelected("price") && <InputFilterwithRange type="number" states={['Isbetween', 'Isequal']} state={"Isequal"} title='Price' placement='bottomStart' value={price_data} onChange={price_OnChange} />
      }
      {/* {filterIsSelected("codAmount") && <InputFilter states={['Isbetween', 'Isequal']} title='CodAmount' placement='bottomStart' value={codAmount_data} onChange={codAmount_OnChange} />
      } */}

      {filterIsSelected("codAmount") && <InputFilterwithRange type="number" states={['Isbetween', 'Isequal']} state={"Isequal"} title='CodAmount' placement='bottomStart' value={codAmount_data} onChange={codAmount_OnChange} />
      }

      {filterIsSelected("pickupcompleteAfter") && <DateFilter states={['Isbetween']} title='Pickup CompleteAfter' placement='bottomStart' value={pickupcompleteAfter_data} onChange={pickupcompleteAfter_OnChange} />
      }
      {filterIsSelected("pickupcompleteBefore") && <DateFilter states={['Isbetween']} title='Pickup CompleteBefore' placement='bottomStart' value={pickupcompleteBefore_data} onChange={pickupcompleteBefore_OnChange} />
      }
      {/* {filterIsSelected("pickupzones") && <CheckBoxFilter title='pickupzones' placement='bottomStart' list={pickupzones_list} value={pickupzones_data} onChange={pickupzones_OnChange} />
      } */}
      {filterIsSelected("deliverycompleteAfter") && <DateFilter states={['Isbetween']} title='Delivery CompleteAfter' placement='bottomStart' value={deliverycompleteAfter_data} onChange={deliverycompleteAfter_OnChange} />
      }
      {filterIsSelected("deliverycompleteBefore") && <DateFilter states={['Isbetween']} title='Delivery CompleteBefore' placement='bottomStart' value={deliverycompleteBefore_data} onChange={deliverycompleteBefore_OnChange} />
      }
      {/* {filterIsSelected("deliveryzones") && <CheckBoxFilter title='deliveryzones' placement='bottomStart' list={deliveryzones_list} value={deliveryzones_data} onChange={deliveryzones_OnChange} />
      } */}
       {filterIsSelected("paymentMethods") && <CheckBoxFilter title='Payment Methods' placement='bottomStart' list={paymentMethods_list} value={paymentMethods_data} onChange={paymentMethods_OnChange} />
      }
      {filterIsSelected("deliveryAttemptNumber")  && <InputFilterwithRange type="number"  state={"Isequal"}  states={['Isbetween', 'Isequal']} title='Delivery Attempt Number' placement='bottomStart' value={deliveryattemptsCount_data} onChange={deliveryattemptsCount_OnChange} />
      }
      {(filterIsSelected("deliveryLastAttemptReason") && pickupFailure)  && <CheckBoxFilter title='Delivery Last Attempt Reason' placement='bottomStart' list={deliverylastAttemptReasons_list} value={deliverylastAttemptReasons_data} onChange={deliverylastAttemptReasons_OnChange} />
      }
      {(filterIsSelected("pickupFailedReason") && deliveryFailure)  && <CheckBoxFilter title='Pickup Failed Reason' placement='bottomStart' list={pickupfailedReasons_list} value={pickupfailedReasons_data} onChange={pickupfailedReasons_OnChange} />
          }

      <Addilter />
      {/* <RadioButtonFilter title='RadioButton' placement='bottomStart' list={type_list} value={type_data} onChange={type_OnChange} /> */}

      {/*           
            <PhoneFilter title='Phone' placement='bottomStart' defaultCountry={global.config.Country} value={filter2} onChange={setFilter2}  state={filter2state} stateOnChange={setFilter2state}  />
            <CheckBoxFilter title='CheckBox' placement='bottomStart' list={[{ key: "1", value: "1" }, { key: "2", value: "2" }, { key: "3", value: "3" },{ key: "1", value: "1" }, { key: "2", value: "2" }, { key: "3", value: "3" }]} value={filter3} onChange={setFilter3} />
            <RadioButtonFilter title='RadioButton' placement='bottomStart' list={[{ key: "1", value: "1" }, { key: "2", value: "2" }, { key: "3", value: "3" }]} value={filter4} onChange={setFilter4} />
            <DateFilter title='Date' placement='bottomStart' value={filter5} onChange={setFilter5} /> */}

      {/* <div className={styles.divider} />
        <Button size='Medium' type='Secondary' icon={Refresh_Table} style={{backgroundColor:"unset"}} />
        <TableListColumnSetting /> */}
    </div>
  )
}


export default MapTableFilters


