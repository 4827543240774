import * as actionTypes from "../actions/actionTypes";
var _ = require("lodash");


let sampleImport = {
  type: "Draft",
  status: "Draft",
  createdAt: "5 July 22:25",
  File: null,
  id: "test",
  orderType: null,
  service: {
    selectedService: null,
    options: []
  },
  vehicleType: {
    selectedVehicleType: null,
    options: []
  }
};


const init = {
  draftList: [],
  List: [],
  selectedItem: null,
  selectedItemType: null,
};



export const BulkImport = (state = init, action) => {

  switch (action.type) {

    

    case actionTypes.remove_draft_bulkImport:
      return {
        ...state,
        draftList: state.draftList.filter((item)=>{
          return item.id != action.state
        })
      }

    case actionTypes.setBulkImportList:
      return {
        ...state,
        List : _.keyBy(action.state, "id")
      }


    case actionTypes.createNewDraft:
      return {
        ...state,
        draftList: [...state.draftList, { ...sampleImport, ...action.state }]
      }

    case actionTypes.setActiveServiceImport:
      return {
        ...state,
        draftList: state.draftList.map((item) => {
          if (item.id != action.state.id) {
            return item
          } else {
            return {
              ...item,
              ...action.state
            }
          }
        })
      }

      case actionTypes.create_new_BulkImport_List:
        return {
          ...state,
          List: {
            ...state.List,
            [action.state.id]: {
              ...action.state,
            },
          },
        };



      case actionTypes.setActiveServiceImportList:
        return {
          ...state,
          List: {
            ...state.List,
            [action.state.id]: {
              ...state.List[action.state.id],
              ...action.state,
            },
          },
        };

    case actionTypes.add_Service_Option_bulkImport:
      return {
        ...state,
        draftList: state.draftList.map((item) => {
          if (item.id != action.id) {
            return item
          } else {
            return {
              ...item,
              service: {
                ...item.service,
                options: action.state.update ? item.service.options.map((itemx) => {
                  if(itemx.id == action.state.id){
                    return {
                      ...itemx ,
                      inputValue : action.state.inputValue
                    };
                  }else{
                    return itemx
                  }
    
                }) : [...item.service.options, action.state]
              }

            }
          }
        })
      };

    case actionTypes.remove_Service_Option_bulkImport:
      return {
        ...state,
        draftList: state.draftList.map((item) => {
          if (item.id != action.id) {
            return item
          } else {
            return {
              ...item,
              service: {
                ...item.service,
                options: item.service.options.filter(
                  (item) => item.id !== action.state
                ),
              }

            }
          }
        })
      };



    case actionTypes.add_VehicleType_Option_bulkImport:
      return {
        ...state,
        draftList: state.draftList.map((item) => {
          if (item.id != action.id) {
            return item
          } else {
            return {
              ...item,
              vehicleType: {
                ...item.vehicleType,
                options: [...item.vehicleType.options, action.state]
              }

            }
          }
        })
      };



    case actionTypes.remove_VehicleType_Option_bulkImport:
      return {
        ...state,
        draftList: state.draftList.map((item) => {
          if (item.id != action.id) {
            return item
          } else {
            return {
              ...item,
              vehicleType: {
                ...item.vehicleType,
                options: item.vehicleType.options.filter(
                  (item) => item.id !== action.state
                ),
              }

            }
          }
        })
      };

    case actionTypes.setActiveImport:
      return {
        ...state,
        selectedItem: action.state,
        selectedItemType :action.bType
      }


    default:
      return state;
  }
};

export default BulkImport;
