import { Component } from "react";
import { connect } from "react-redux";
import { CalculatePriceWorker } from "../../../../../helper/worker/CalculatePriceWorker";
import Ondemand from "../ondemandLogic/calculatePriceCheck_WithoutHttp";
// import Pickup from "./pickup";
// import Delivery from "./delivery";
import PickupDelivery from "../pickupDeliveryLogic/calculatePriceCheck_WithoutHttp";

class App extends Component {
 
  render() {
    if(this.props.orderType== "Ondemand"){
      return <Ondemand />
    }else if(this.props.orderType== "PickupDelivery"){
      return <PickupDelivery />
    }
    /*else if(orderType== "Pickup"){
      return <Pickup />
    }else if(orderType== "Delivery"){
      return <Delivery />
    }else if(orderType== "PickupDelivery"){
      return <PickupDelivery />
    }else{
      return null
    }
    */
   return null
  }
}

const mapStateToProps = (state) => ({
  orderType: state.ViewTravel.pureData
  ? state.ViewTravel.pureData.type
    ? state.ViewTravel.pureData.type
    : null
  : null,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
