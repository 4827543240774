// import { setGlobalSettings } from "../../helper/module/projects";
import { LocalData } from "./local-data";
import { combineReducers } from "redux";
import { Map } from "./map";
import { NewOrder } from "./newTravel";
import { ViewTravel } from "./viewTravel";
import { EditOrder } from "./editOrder";
import { FavAddress } from "./FavAddress";
import { DriversList } from "./DriversList";
import { Addressbook } from "./addressbook";
import { AdvancedAssaigning } from "./AdvancedAssaigning";
import { BulkImport } from "./BulkImport";
import { OrderList } from "./order-list";
import { SharedData } from "./SharedData";
import { Monitoring } from "./monitoring";
import { Voip } from "./voip";
import { Socket } from "./socket";
import { ErrorHandling } from "./error-handling";
import Table from "./table";
import { Route } from "./route";

// setGlobalSettings()

export const reducers = combineReducers({
  LocalData: LocalData,
  Map: Map,
  NewOrder: NewOrder,
  ViewTravel: ViewTravel,
  NewOrder: NewOrder,
  ViewTravel: ViewTravel,
  FavAddress: FavAddress,
  EditOrder: EditOrder,
  DriversList: DriversList,
  AdvancedAssaigning: AdvancedAssaigning,
  Addressbook,
  BulkImport,
  OrderList: OrderList,
  SharedData: SharedData,
  Monitoring: Monitoring,
  Voip:Voip ,
  Socket:Socket ,
  ErrorHandling :ErrorHandling,
  Table:Table,
  Route:Route
});


