import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import arrow from "../../../../../assets/chevron-down.svg";
import Collapse from "@material-ui/core/Collapse";
import { withTranslation, Trans } from "react-i18next";
import turnOn from "../../../../../assets/turn-on.svg";
import arrow2 from "../../../../../assets/arrow-triangle-down.svg";
import remove from "../../../../../assets/ic-remove.svg";
import bike from "../../../../../assets/bike.svg";
import { withStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import ListItem from "./Pickup_driver_Components/driverOptions/list";
import SelectableItem from "./Pickup_driver_Components/driverOptions/selectable";
import {
  Set_Auto_DriverـPickupDeliveryType_Pickup,
  Set_Auto_ActiveVehicleTypeـPickupDeliveryType_Pickup,
  Set_Manual_ActiveVehicleTypeـPickupDeliveryType_Pickup,
  Set_Force_AssaigningـPickupDeliveryType_Pickup,
  Set_searchDriverInputـPickupDeliveryType_Pickup,

} from "../../../../../Redux/actions/actionNames";
import { SearchDriverAction, SearchDriverAction_Pickup } from "../../../../../Redux/actions/actions";
import { getBaseTrackByUrl } from "../../../../../helper/module";
import close from "../../../../../assets/ic-close.svg";
import { get_lang } from "../../../../../helper/UserData/userdate";
import ManualVehicleType from "./Pickup_driver_Components/manualVehicleType";
import { SearchDriver_Pickup } from "../../../../../helper/api";
import { snack } from "../../../../../utils/SnackbarUtilsConfigurator";

const BackdropCustom = withStyles({
  backdrop: {
    zIndex: 5000,
    backgroundColor: "rgba(0, 0, 0, 0)",
    color: "#00000",
  },
})((props) => (
  <Backdrop
    {...props}
    style={{
      zIndex: 5000,
      backgroundColor: "rgba(0, 0, 0, 0)",
      color: "#00000",
    }}
  />
));

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "driver",
      showpoperAuto: false,
      showpoperManual: false,
      list: [0, 1, 2, 3, 5, 6, 9, 5, 8],
      loading: false,
      ordered: false,
      Auto: true,
      idd: this.idgenerator(),
      activeVehicleTypes: [
        {
          type: "list",
          title: "weight",
          id: "weight",
          data: [
            {
              name: "under 1 kg",
              id: "12qceqd",
            },
            {
              name: "under 2 kg",
              id: "12rcesd",
            },
            {
              name: "under 3 kg",
              id: "dwdqced",
            },
            {
              name: "under 4 kg",
              id: "1dwdced",
            },
          ],
        },
        {
          type: "boolean",
          title: "need packed",
          id: "need packed",
        },
      ],
    };
    this.anchorElAuto = React.createRef();
    this.anchorElManual = React.createRef();
  }

  idgenerator = () => {
    var number = Math.random(); // 0.9394456857981651
    number.toString(36); // '0.xtis06h6'
    var id = number.toString(36).substr(2, 9); // 'xtis06h6'
    return id.toString();
  };

  componentDidMount() {
    // this.props.setId(this.state.id);
    if(!this.props.auto_activeVehicleType && this.props.auto_suggestListData.length > 0){
      this.props.dispatch(Set_Auto_ActiveVehicleTypeـPickupDeliveryType_Pickup(this.props.auto_suggestListData[0]))
    }
  }

  idgenerator = () => {
    var number = Math.random(); // 0.9394456857981651
    number.toString(36); // '0.xtis06h6'
    var id = number.toString(36).substr(2, 9); // 'xtis06h6'
    return id.toString();
  };

  handleCollapse = () => {
    // this.props.handleCollapse({
    //   type: "driver",
    //   id: "driver",
    // });
  };
  componentWillReceiveProps(nextProps){
      var mustResetVehicleType = true;
        nextProps.auto_suggestListData.forEach((element) => {
          if (element.id == nextProps.auto_activeVehicleType.id) {
            mustResetVehicleType = false;
          }
        });
        mustResetVehicleType && this.props.dispatch(Set_Auto_ActiveVehicleTypeـPickupDeliveryType_Pickup(this.props.auto_suggestListData[0]))
    
  }

  handleshowpoperAuto = () => {
    if (this.props.auto_suggestListData.length > 0) {
      this.setState({
        showpoperAuto: !this.state.showpoperAuto,
      });
    } else {
      if (this.props.auto_suggestListLoading) {
        snack.warning_i18("poperLoading")
      } else {
        snack.warning_i18("noVehicleType")
       
      }
    }
  };

  handleshowpoperManual = () => {
    this.setState({
      showpoperManual: !this.state.showpoperManual,
    });
    this.SearchDriverAction()
  };

  handleAutoManual = () => {
    this.props.dispatch(Set_Auto_DriverـPickupDeliveryType_Pickup(!this.props.auto));
  };

  handleForceAssaigning = () => {
    this.props.dispatch(Set_Force_AssaigningـPickupDeliveryType_Pickup(!this.props.forceAssaigning));
  };

  handleAutoVehicleTypeSelect = (service) => {
    this.props.dispatch(Set_Auto_ActiveVehicleTypeـPickupDeliveryType_Pickup(service));
  };

  async handleManualVehicleTypeSelect(service) {
    // let index = -1;
    //   index = service.vehicletypes.length > 0  ? this.props.auto_suggestListData.findIndex((vehicleType) => {
    //   return vehicleType.id == service.vehicletypes[0];
    // }) : -1 ;
    let array = [];
    this.FindLocalVehicletype(
      service.vehicleTypes,
      this.props.auto_suggestListData,
      (call) => {
        if (call) {
          let manual_activeVehicleType = null;

          if (this.props.manual_activeVehicleType) {
            manual_activeVehicleType = this.props.manual_activeVehicleType;
          } else {
            if (call.length != 0) {
              manual_activeVehicleType = call[0];
            } else {
              snack.warning_i18("DriverVehicleTypeError")

            }
          }

          manual_activeVehicleType &&
            this.props.dispatch(
              Set_Manual_ActiveVehicleTypeـPickupDeliveryType_Pickup({
                manual_activeVehicleType: manual_activeVehicleType,
                driverAllvehicletypes: call,
                // manual_name: service.givenName,
                // manual_family: service.familyName,
                manual_name: service.name,
                manual_family: "",
                manual_number: service.phone,
                manual_id: service.id,
                manual_code: service.username,
              })
            );
        }
      }
    );
    // if (index > -1) {
    //   VehicleType = this.props.auto_suggestListData[index];
    // }
    // this.props.dispatch(
    //   Set_Manual_ActiveVehicleTypeـPickupDeliveryType_Pickup({
    //     manual_activeVehicleType: {
    //       ...VehicleType,
    //       options: [],
    //     },
    //     manual_name: service.givenName,
    //     manual_family: service.familyName,
    //     manual_number: service.phone,
    //     manual_id: service.id,
    //     manual_code: service.username,
    //   })
    // );

    //  
  }

  InputOnChange = (e) => {
    this.props.dispatch(
      Set_Manual_ActiveVehicleTypeـPickupDeliveryType_Pickup({
        [e.target.name.split("-")[0]]: e.target.value,
        manual_id: null,
        searchDriverInput: e.target.value,
        driverAllvehicletypes: [],
      })
    );

    if (!this.state.showpoperManual) {
      this.setState({
        showpoperManual: true,
      });
    }

    this.SearchDriverAction(e.target.value)

  };


  SearchDriverAction=(input)=>{
    this.props.dispatch(
      SearchDriverAction_Pickup(this.props.dispatch, {
        searchRegex: input ? input : this.props.searchDriverInput,
        vehicleTypes:this.props.manual_activeVehicleType ? this.props.manual_activeVehicleType.id : "",

        // givenName: this.props.manual_name,
        // username: this.props.manual_code,
        // familyName: this.props.manual_family,
        // phone: this.props.manual_number,
      },{
        from:"Pickup",
        level:"newOrder"
      })
    );
  }
  

  FindLocalVehicletype = (driverVehicletypes, localservives, call) => {
    let vehicletype = [];

    driverVehicletypes.forEach((element) => {
      localservives.forEach((localservive) => {
        if (element == localservive.id) {
          vehicletype.push(localservive);
        }
      });
    });

    call(vehicletype);

    // if(driverVehicletypes.length > 0){
    //   // while(!vehicletype){
    //        for(let i =0 ; i < localservives.length ; i++){
    //         if(driverVehicletypes[0] == localservives[i].id){
    //           vehicletype.push(localservives[i])
    //           call(vehicletype)
    //           break;
    //           }
    //         }
    //   //       driverVehicletypes.shift() ;
    //   // }
    //   call([])

    // }else{
    //   call([])
    // }
  };

  resetDriver = () => {
    this.props.dispatch(
      Set_Manual_ActiveVehicleTypeـPickupDeliveryType_Pickup({
        driverAllvehicletypes:[],
        searchDriverInput:"",
        manual_activeVehicleType: null,
        manual_name: "",
        manual_family: "",
        manual_number: "",
        manual_id: "",
        manual_code: "",
      })
    );
  };

  InputOnChangeManual = (e) => {
    this.props.dispatch(Set_searchDriverInputـPickupDeliveryType_Pickup(e.target.value));
  };

  render() {
    const { t } = this.props;
    return (
      <passerngerContainer
      className="serviceCustomized pickup_driver"
      >
        <drivertitle onClick={this.handleCollapse}>
          <span>
            {" "}
            <Trans i18nKey={"driver"}> </Trans>{" "}
          </span>
          <personBusinessSwitchContainer className="driverAMswitch">
            <detail
              id="details"
              onClick={this.handleAutoManual}
              className={`${this.props.auto ? "details" : "DEactive"}`}
            >
              <Trans i18nKey={"Auto"}> </Trans>
            </detail>
            <history
              id="history"
              onClick={this.handleAutoManual}
              className={`${!this.props.auto ? "details" : "DEactive"}`}
            >
              <Trans i18nKey={"Manual"}> </Trans>
            </history>
          </personBusinessSwitchContainer>

    {!this.props.auto && <div
            className="forceAssaignConatiner"
            onClick={this.handleForceAssaigning}
          >
            <span>
              {" "}
              <Trans i18nKey={"ForceAssign"}> </Trans>{" "}
            </span>
            <div
              className={`forceAssaigncheckbox ${
                this.props.forceAssaigning && "ActiveforceAssaigncheckbox"
              }`}
            >
              {" "}
            </div>
          </div>
     }   </drivertitle>

        <div className="pickupcollapsecontainer pickupcollapsecontainer yodmvydj" style={{border:"none"}}>
          {this.props.auto && (
            <React.Fragment>
              <autodriverselector
                ref={this.anchorElAuto}
                onClick={this.handleshowpoperAuto}
              >
                {this.props.auto_activeVehicleType &&
                this.props.auto_suggestListData.length > 0 ? (
                  <React.Fragment>
                    <img
                      src={this.props.auto_activeVehicleType.icon}
                      className="serviceimage"
                    />
                    <servicename>
                      {this.props.auto_activeVehicleType.title}
                    </servicename>
                  </React.Fragment>
                ) : (
                  <servicename style={{ marginLeft: "19px" }}>
                    {" "}
                    <Trans i18nKey={"pleaseSellectParsel"}> </Trans>
                  </servicename>
                )}

                <img
                  src={arrow2}
                  className={` servicearrow ${
                    this.state.showpoperAuto && "rotate180"
                  }`}
                />
              </autodriverselector>
              <serviceOptions
                className={`${
                  this.props.auto_activeVehicleType &&
                  this.props.auto_activeVehicleType.options.length == 0 &&
                  "displayNone"
                }`}
                style={{ marginLeft: "0px", width: "100%" }}
              >
                {this.props.auto_activeVehicleType &&
                  this.props.auto_activeVehicleType.options.map((item) => {
                    return item.type === "list" ? (
                      <ListItem {...item} />
                    ) : (
                      <SelectableItem {...item} />
                    );
                  })}
              </serviceOptions>
            </React.Fragment>
          )}

          {!this.props.auto && (
            <div>
              {(this.props.manual_id == "" ||
            this.props.manual_id == null) &&  (
                <div class="drivermanualContainer">
                    <input
                    onChange={this.InputOnChange}
                    ref={this.anchorElManual}
                    value={this.props.searchDriverInput}
                    name={"manual_code-" + this.state.idd}
                    autocomplete="z1x2wd"
                    onClick={this.handleshowpoperManual}
                    className="driverCode"
                    placeholder={t("CodeNameFamilyPhonenumber")}
                  /> 
                  <ManualVehicleType />
               
                </div>
              )}
              {(this.props.manual_id != "" &&
            this.props.manual_id != null) && (
                <React.Fragment>
                  <div className="driverInfoManualContainer">
                      <img src={remove} onClick={this.resetDriver} />
                      <div className="namePhoneContainer">
                           <p className="npcName"> {`${this.props.manual_name} ${this.props.manual_family}`} </p>
                           <p className="npcPhone"> {`${this.props.manual_number} `} </p>
                      </div>  

                      <div className="namePhoneContainer usernameContainer">
                           <p className="npcName"> {`${this.props.manual_code}`} </p>
                           <p className="npcPhone"><Trans i18nKey={"Username"}> </Trans> </p>
                      </div>  
                      
                  </div>
                {this.props.manual_activeVehicleType &&  <manualDriverActiveServic>
                    {" "}
                    <img
                      src={this.props.manual_activeVehicleType.icon}
                      className="serviceimage"
                    />{" "}
                   <span> {this.props.manual_activeVehicleType.title} </span>
                  </manualDriverActiveServic>}
                  <serviceOptions style={{ marginLeft: "0px", width: "100%" }}>
                    {this.props.manual_activeVehicleType &&
                      this.props.manual_activeVehicleType.options.map(
                        (item) => {
                          return item.type === "list" ? (
                            <ListItem {...item} />
                          ) : (
                            <SelectableItem {...item} />
                          );
                        }
                      )}
                  </serviceOptions>
                </React.Fragment>
              )}{" "}
            </div>
          )}
        </div>

        <BackdropCustom
          open={
            this.state.showpoperManual &&
            (this.props.manual_suggestListLoading ||
              this.props.manual_suggestListOrdered)
          }
          onClick={this.handleshowpoperManual}
        >
          <Popper
            style={{
              width: "355px",
              zIndex: "23",
              marginTop: "0px",
              maxHeight: "150x",
            }}
            placement={"bottom-end"}
            id={"passenger"}
            open={
              this.state.showpoperManual &&
              (this.props.manual_suggestListLoading ||
                this.props.manual_suggestListOrdered)
            }
            anchorEl={this.anchorElManual.current}
          >
            <Paper
              style={{
                width: "355px",
                float: "right",
                borderRadius: "8px",
                paddingTop: "5px",
                marginTop: "10px",
                paddingBottom: "5px",
                right: "271px",
                maxHeight: "150px",
                overflowY: "scroll",
                overflowX: "hidden",
              }}
              elevation={3}
            >
              {!this.props.manual_suggestListLoading &&
                this.props.manual_suggestListData.length !== 0 &&
                this.props.manual_suggestListData.map((item) => {
                  return (
                    <div
                      className="DriverListItemContainer"
                      onClick={() => {
                        this.handleManualVehicleTypeSelect(item);
                      }}
                    >
                      <span className="DriverListItemName">
                        {/* {`${item.givenName} ${item.familyName}`} */}
                        {`${item.name} `}

                      </span>
                      <span className="DriverListItemNumber">
                        {" "}
                        {item.phone}{" "}
                      </span>
                      <span className="DriverListItemCode">
                        {" "}
                        {item.username}{" "}
                      </span>
                    </div>
                  );
                })}

              {this.props.manual_suggestListLoading && (
                <div>
                  <span
                    className="NotravelsListHeader"
                    style={{ marginTop: "12px" }}
                  >
                    <Trans i18nKey={"poperLoading"}> </Trans>
                  </span>
                  <span className="NotravelsListText">
                    <Trans i18nKey={"fetchingdata"}> </Trans>
                  </span>
                </div>
              )}

              {!this.props.manual_suggestListLoading &&
                this.props.manual_suggestListOrdered &&
                this.props.manual_suggestListData.length === 0 && (
                  <div>
                    <span
                      className="NotravelsListHeader"
                      style={{ marginTop: "12px" }}
                    >
                      <Trans i18nKey={"Noinformationfound"}> </Trans>
                    </span>
                    <span className="NotravelsListText">
                      <Trans i18nKey={"Pleasetryanotherkeyword"}> </Trans>
                    </span>
                  </div>
                )}
            </Paper>
          </Popper>
        </BackdropCustom>

        <BackdropCustom
          open={this.state.showpoperAuto}
          onClick={this.handleshowpoperAuto}
        >
          <Popper
            style={{
              width: "475px",
              zIndex: "12",
              marginTop: "0px",
              maxHeight: "150x",
            }}
            placement={"bottom"}
            id={"passenger"}
            open={this.state.showpoperAuto}
            anchorEl={this.anchorElAuto.current}
          >
            <Paper
              style={{
                width: "476px",
                float: "right",
                borderRadius: "8px",
                paddingTop: "5px",
                marginTop: "10px",
                paddingBottom: "5px",
                right: "271px",
                maxHeight: "150px",
                overflowY: "scroll",
                overflowX: "hidden",
              }}
              elevation={3}
            >
              {this.props.auto_suggestListData &&
                this.props.auto_suggestListData.map((item) => {
                  return (
                    <autodriverselector
                      className="autodriverselectorInList"
                      onClick={() => {
                        this.handleAutoVehicleTypeSelect(item);
                      }}
                    >
                      <img src={item.icon} className="serviceimage" />
                      <servicename>{item.title}</servicename>
                    </autodriverselector>
                  );
                })}
            </Paper>
          </Popper>
        </BackdropCustom>
      </passerngerContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  auto: state.NewOrder.newOrder.pickup.driver.auto,
  activeServiceType:state.NewOrder.newOrder.service.activeVehicleType,

  auto_activeVehicleType:
    state.NewOrder.newOrder.pickup.driver.auto_driver.auto_activeVehicleType,
  auto_suggestListLoading:
    state.NewOrder.newOrder.driver.auto_driver.auto_suggestListLoading,
  auto_suggestListData:
    state.NewOrder.newOrder.driver.auto_driver.auto_suggestListData,
  auto_suggestListOrdered:
    state.NewOrder.newOrder.driver.auto_driver.auto_suggestListOrdered,
  manual_activeVehicleType:
    state.NewOrder.newOrder.pickup.driver.manual_driver.manual_activeVehicleType,
  manual_name: state.NewOrder.newOrder.pickup.driver.manual_driver.manual_name,
  manual_code: state.NewOrder.newOrder.pickup.driver.manual_driver.manual_code,
  manual_family: state.NewOrder.newOrder.pickup.driver.manual_driver.manual_family,
  manual_number: state.NewOrder.newOrder.pickup.driver.manual_driver.manual_number,
  manual_id: state.NewOrder.newOrder.pickup.driver.manual_driver.manual_id,
  manual_suggestListLoading:
    state.NewOrder.newOrder.pickup.driver.manual_driver.manual_suggestListLoading,
  manual_suggestListData:
    state.NewOrder.newOrder.pickup.driver.manual_driver.manual_suggestListData,
  manual_suggestListOrdered:
    state.NewOrder.newOrder.pickup.driver.manual_driver.manual_suggestListOrdered,
  activePickupDropOff: state.NewOrder.activePickupDropOff,
  lang: state.LocalData.lang,
  forceAssaigning: state.NewOrder.newOrder.pickup.driver.forceAssaigning,
  searchDriverInput:
    state.NewOrder.newOrder.pickup.driver.manual_driver.searchDriverInput,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
