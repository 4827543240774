import HViaheroiO from "../../../assets/Viahero_logotype.png";
import FavIcon from "../../../assets/PROJECTS/Viahero.png";

export default function Viahero() {
    
    return  global.config = {
    "ProjectName": "Viahero",
    "LogoType": HViaheroiO,
    "BaseApi": "https://rest.viahero.ph",
    "BaseSocket": "https://socket.viahero.ph",
    "BaseTrack": "https://manage.viahero.ph",
    "MapCenter": {
        "lat": "14.574400731056794",
        "lng": "121.08529112288042"
    },
    "Lang": "en",
    "Direction": "ltr",
    "AllLanguages": [{
        "lang": "en",
        "direction": "ltr",
        "i18Lang": "English"
    }],
    "FavIcon": FavIcon,
    "Country": "PH",
    "thirdPartyLoginSupport": true  ,
    mapType :"osm", /* osm | google */ 
    googleApiKey : ""
}}