import PrivitridesLogo from "../../../assets/PrivitridesLogo.png";
import FavIcon from "../../../assets/PROJECTS/Privitrides.png";

export default function Privitrides () {
    
    return global.config = {
    "ProjectName": "Privitrides",
    "LogoType": PrivitridesLogo,
    "BaseApi": "https://rest.privitrides.com",
    "BaseSocket": "https://socket.privitrides.com",
    "BaseTrack": "https://admin.privitrides.com",
    "MapCenter": {
        "lat": "36.09991641189004",
        "lng": "-80.24420675144678"
    },
    "Lang": "en",
    "Direction": "ltr",
    "AllLanguages": [{
        "lang": "en",
        "direction": "ltr",
        "i18Lang": "English"
    },{
        "lang": "es",
        "direction": "ltr",
        "i18Lang": "spanish",
    }],
    "FavIcon": FavIcon,
    "Country": "US",
    "thirdPartyLoginSupport": true  ,
    mapType :"osm", /* osm | google */ 
    googleApiKey : ""
} }