import { store } from "../../Redux/store";
import { UpdateTravelInList } from "../../Redux/actions/actionNames";

export function SocketOrderHandle(data) {
  const state = store.getState();

  state.Socket.socket.emit(
    "dispatcher:order:ondemand:handle",
    data,
    (res) => {
      store.dispatch(
        UpdateTravelInList({
          id: res.data.id,
          data: { code: res.data.code, status: res.data.status },
          full: false,
        })
      );
    }
  );
}

export function SocketTrackDriver(dispatch, socket, data, call) {
  try {
    socket.emit("dispatcher:order:track-driver", data, (res) => {
      try {
        call(res.data);
      } catch (error) {}
    });
  } catch (error) {}
}

export function SocketOrderDone(dispatch, socket, data, call) {
  socket.emit("dispatcher:order:ondemand:done", data, (res) => {
    
    try {
      call(res.data);
    } catch (error) {}
  });
}

export function SocketOrderCancel(dispatch, socket, data, call) {
  socket.emit("dispatcher:order:cancel", data, (res) => {
    
    try {
      call(res.data);
    } catch (error) {}
  });
}
