


export const StopGetColorByStatus =  (Status ,order) => {

    if(!Status){
        return "#1080FF"
    }
    const findStatus = (element) => element.status == Status ;
    return getStatus[findStatus > -1 ? getStatus.findIndex(findStatus) : 0].color



};

const getStatus = [
    {status: 'Active' ,color: "#1080FF"},
    {status: 'Successful' ,color: "#009B62 "},
    {status: 'Failed' ,color: "#EA2D00"},


]
