import * as React from 'react'
import styles from './style.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CLOSE, EDIT } from '../../../../assets';
import Typography from '../../../../design-system/lib/Typography';
import Button from '../../../../design-system/lib/Button';
import Modal from '../../../../design-system/lib/Modal';
import EditPriceNewOrder from '../edit';
import { get_currencyCode, get_lang } from '../../../../helper/UserData/userdate';



function ViewPriceNewOrder({ handleClose }) {
  const price = useSelector((state) => state.NewOrder.newOrder.price.price)
  const { t, i18n } = useTranslation();
  const [openEdit, setOpenEdit] = React.useState(false);
  const paymentMethod = useSelector((state) => state.NewOrder.newOrder.paymentMethod.paymentMethod)
  const paymentSide = useSelector((state) => state.NewOrder.newOrder.paymentMethod.paymentSide)
  const CODV = useSelector((state) => state.NewOrder.newOrder.codAmount)

  const dispatch = useDispatch();






  return (
    <div className={styles.NewCustomerRegisterContainer}>
      <div className={styles.largeHeader}>
        <Button icon={CLOSE} size="Medium" type="Secondary" onClick={handleClose} />
        <div className={styles.largeHeaderTitles}>
          <Typography text={t("Price")} weight="Body_Middle_Bold" className={styles.SmallTitle} style={{ margin: "0", padding: "0" }} />
        </div>

        <Button icon={EDIT} isActive={openEdit} size="Medium" type="Secondary" onClick={() => { { setOpenEdit(!openEdit) } }} style={{ marginInlineStart: "auto" }} />

      </div>

      <div className={styles.body}>
        <div className={styles.item}>
          <Typography text={t("Fee")} weight="Body_Small_Regular" style={{ color: "#808080" }} />
          <Typography text={Number(price.baseFarePrice).toLocaleString(get_lang(), {
            style: "currency",
            currency: get_currencyCode(),
          })} weight="Body_Small_Regular" style={{ color: "#242424" }} />
        </div>

        {paymentSide && <div className={styles.item}>
          <Typography text={t("paymentmethod")} weight="Body_Small_Regular" style={{ color: "#808080" }} />
          <Typography text={t(paymentSide + paymentMethod)} weight="Body_Small_Regular" style={{ color: "#242424" }} />
        </div>}


        <div className={styles.item}>
          <Typography text={t("tax")} weight="Body_Small_Regular" style={{ color: "#808080" }} />
          <Typography text={Number(price.tax).toLocaleString(get_lang(), {
            style: "currency",
            currency: get_currencyCode(),
          })} weight="Body_Small_Regular" style={{ color: "#242424" }} />
        </div>

        {price.surcharges?.map((surch) => {
          return <div className={styles.item}>
            <Typography text={String(t("surcharge").replace("xxx", surch.title))} weight="Body_Small_Regular" style={{ color: "#808080" }} />
            <Typography text={Number(surch.price).toLocaleString(get_lang(), {
              style: "currency",
              currency: get_currencyCode(),
            })} weight="Body_Small_Regular" style={{ color: "#242424" }} />
          </div>
        })}

       {price.promoAmount > 0 && <div className={styles.item}>
          <Typography text={t("Promotion")} weight="Body_Small_Regular" style={{ color: "#808080" }} />
          <Typography text={Number(price.promoAmount).toLocaleString(get_lang(), {
            style: "currency",
            currency: get_currencyCode(),
          })} weight="Body_Small_Regular" style={{ color: "#242424" }} />
        </div>}

        <div className={styles.item}>
          <Typography text={t("Total")} weight="Body_Small_Regular" style={{ color: "#484848" }} />
          <Typography text={Number(price.price - (price.promoAmount ? price.promoAmount : 0)).toLocaleString(get_lang(), {
            style: "currency",
            currency: get_currencyCode(),
          })} weight="Body_Small_Bold" style={{ color: "#242424" }} />
        </div>

        {/* <div className={styles.item}>
            <Typography text={t("Total")} weight="Body_Small_Regular" style={{color:"#484848"}}/>
            <Typography text={"$ 10"} weight="Body_Small_Bold" style={{color:"#242424"}}/>
          </div> */}

        <div className={styles.item}>
          <Typography text={t("CashOnDelivery")} weight="Body_Small_Regular" style={{ color: "#484848" }} />
          <Typography text={Number(CODV).toLocaleString(get_lang(), {
            style: "currency",
            currency: get_currencyCode(),
          })} weight="Body_Small_Bold" style={{ color: "#242424" }} />
        </div>
      </div>

      {openEdit && <EditPriceNewOrder price={price} handleClose={() => { { setOpenEdit(!openEdit) } }} />}


    </div>
  )
}

export default ViewPriceNewOrder