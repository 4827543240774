import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import Slide from "@material-ui/core/Slide";
import add from "../../../../../../../assets/add.svg";
import { show_eventTimeLine, Set_viewOrder_events } from "../../../../../../../Redux/actions/actionNames";
import Eventitem from "./item";
import AudioItem from "./audioItem";

import momentJalaali from "moment-jalaali";
import { get_calendarType } from "../../../../../../../helper/UserData/userdate";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedEvent: null,
      list: [
      ]
    }
    this.setEvents = this.setEvents.bind(this)
  }

  eventShowHandler = () => {
    this.props.dispatch(show_eventTimeLine(false))
  }

  eventOnclick = (id, cordinate) => {
    this.setState({
      selectedEvent: id
    })
    if (cordinate) {
      this.props.dispatch(Set_viewOrder_events({ activeEventCordinator: cordinate }))

    } else {
      this.props.dispatch(Set_viewOrder_events({ activeEventCordinator: null }))

    }

  }

  componentDidMount = () => {
    this.setEvents()
  }

  componentWillReceiveProps = (nextProps) => {
    this.setEvents(nextProps)

  }

  setEvents = (nextProps) => {

    if (this.state.list.length != (nextProps ? nextProps.data.length : this.props.data.length)) {
      let data = nextProps ? nextProps.data : this.props.data;

      const groups = data.reduce((groups, game) => {
        const date = momentJalaali(game.createdAt).locale(get_calendarType()).format("DD/MM/YYYY")
        if (!groups[date]) {
          groups[date] = [];
        }
        groups[date].push(game);
        return groups;
      }, {});

      const groupArrays = Object.keys(groups).map((date) => {
        return {
          date,
          events: groups[date]
        };
      });
      this.setState({
        list: groupArrays
      })
    }
  }

  render() {

    


    return (
      <div style={{ width: "100%", height: "100%" }}>
        <div className="EventTimeLine_Header">
          <span className="ETLHT"> <Trans i18nKey={"Events"}> </Trans> </span>
          <img src={add} onClick={this.props.showCreateDialoge} />
          <span onClick={this.eventShowHandler} className="ETLHTc"> <Trans i18nKey={"hide"}> </Trans> </span>
        </div>

        <div className="EventTimeLine_ListContainer">

          {this.state.list.map((item, i) => {

            {
              return item.events.map((itemx, ii) => {
                return ["IncomingCall" , "OutgoingCall"].indexOf(itemx.type) >  -1 ? 
                <AudioItem firstItemInArray={ii == 0} selectedEvent={this.state.selectedEvent} onClick={this.eventOnclick} {...itemx} id={String(String(i) + String(ii))} isFirst={ii == 0 && i == 0} isLast={ii == item.events.length - 1 && i == this.state.list.length - 1} /> 
                :
                <Eventitem firstItemInArray={ii == 0} selectedEvent={this.state.selectedEvent} onClick={this.eventOnclick} {...itemx} id={String(String(i) + String(ii))} isFirst={ii == 0 && i == 0} isLast={ii == item.events.length - 1 && i == this.state.list.length - 1} />
              })
            }


          })}



        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  showEventTimeLine: state.ViewTravel.eventTimeLine.show,
  loading: state.ViewTravel.eventTimeLine.loading,
  data: state.ViewTravel.eventTimeLine.data,
  error: state.ViewTravel.eventTimeLine.error,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
