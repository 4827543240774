import locos from "../../../assets/loc_logotype.png";
import FavIcon from "../../../assets/PROJECTS/loco.png";

export default function loco() {
    
    return global.config = {
    "ProjectName": "Loco Delivers",
    "LogoType": locos,
    "BaseApi": "https://rest.loco.direct",
    "BaseSocket": "https://socket.loco.direct",
    "BaseTrack": "https://portal.loco.direct",
    "MapCenter": {
        "lat": "3.146436",
        "lng": "101.687713"
    },
    "Lang": "en",
    "Direction": "ltr",
    "AllLanguages": [{
        "lang": "en",
        "direction": "ltr",
        "i18Lang": "English"
    }, {
        "lang": "fa",
        "direction": "rtl",
        "i18Lang": "Persian"
    }, {
        "lang": "fr",
        "direction": "ltr",
        "i18Lang": "French"
    }, {
        "lang": "ro",
        "direction": "ltr",
        "i18Lang": "Romanian"
    }],
    "FavIcon": FavIcon,
    "Country": "MY",
    "thirdPartyLoginSupport": true  ,
    mapType :"osm", /* osm | google */ 
    googleApiKey : ""
} }