import logoType from "../../../assets/Swiftee.png";
import FavIcon from "../../../assets/PROJECTS/Swiftee_fav_icon.png";

export default function Swiftee() {
    
    return global.config = {
    "ProjectName": "Swiftee",
    "LogoType": logoType,
    "BaseApi": "https://rest.swiftee.co.uk",
    "BaseSocket": "https://socket.swiftee.co.uk",
    "BaseTrack": "https://admin.swiftee.co.uk",
    "MapCenter": {
        "lat": "51.507357639804184",
        "lng": "-0.12757305191165574"
    },
    "Lang": "en",
    "Direction": "ltr",
    "AllLanguages": [{
        "lang": "en",
        "direction": "ltr",
        "i18Lang": "English"
    }],
    "FavIcon": FavIcon,
    "Country": "GB",
    "thirdPartyLoginSupport": true  ,
    mapType :"osm", /* osm | google */ 
    googleApiKey : ""
} }