import React from "react";
import { Marker, OverlayView } from '@react-google-maps/api';
import { connect } from "react-redux";

import ReactDOMServer from "react-dom/server";
import PickupPin from "../../map-pickup-pin/Pin";
import DropOffPin from "../../map-dropoff-pin/Pin";
import { DropOffPinWithNumber } from "../../map-dropoff-pin-with-number";
import StartPin from "../startPin";
import EndPin from "../endPin";
import { DropOffPinWithNumber_style2 } from "../../map-dropoff-pin-with-number-2";

class App extends React.Component {

  render() {

    return (
      <React.Fragment>
          <OverlayView
          position={{ lat: this.props.ROUTE.startLocation.coordinates[1], lng: this.props.ROUTE.startLocation.coordinates[0] }}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        >
          <StartPin />
        </OverlayView>

        {this.props.ROUTE.endLocation && <OverlayView
          position={{ lat: this.props.ROUTE.endLocation.coordinates[1], lng: this.props.ROUTE.endLocation.coordinates[0] }}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        >
          <EndPin />
        </OverlayView>}



        {this.props.STOPS.map((stop) => {
          return <DropOffPinWithNumber_style2 bigSize={false} numberStyle={{ position: "absolute", width: "20px", height: "20px", left: "5px", top: "6.5px", fontSize: "14px", fontWeight: "bold", fontFamily: 'sf_pro', textAlign: "center" }}
            mapType={this.props.mapType}
            isPickup={stop.stage == "Pickup"}
            status={stop.status}
            position={{ lat: stop.coordinates[1], lng: stop.coordinates[0] }} number={stop.position} />

        })}

        {/* {destinationShow ?
          (this.props.otherDropOffs.length == 0 ? <Marker
            position={this.props.dropOffsAdresss_loc}
            icon={{
              anchor: { x: 15, y: 50 },
              url: 'data:image/svg+xml;charset=utf-8,' +
                encodeURIComponent(ReactDOMServer.renderToStaticMarkup(<DropOffPin  bigSize={true}  color={"#0064d2"} />))
            }}
          ></Marker> : <DropOffPinWithNumber  bigSize={true}  numberStyle={{ position: "absolute", width: "20px", height: "20px", left: "5px", top: "7px", fontSize: "14px", fontWeight: "bold", fontFamily: 'sf_pro', textAlign: "center" }}
            mapType={this.props.mapType}
            position={this.props.dropOffsAdresss_loc} number={1} />) : null}

        {OthersdestinationShow
          ? this.props.otherDropOffs.map((dropoff, i) => {
            return <DropOffPinWithNumber  bigSize={true}  numberStyle={{ position: "absolute", width: "20px", height: "20px", left: "5px", top: "7px", fontSize: "14px", fontWeight: "bold", fontFamily: 'sf_pro', textAlign: "center" }}
              mapType={this.props.mapType}
              position={dropoff.adresss_loc} number={i + 2} />
          })
          : null} */}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({

  center: state.Map.center,
  STOPS: ownProps.STOPS,
  ROUTE: ownProps.ROUTE

});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(mapStateToProps, mapDispatchToProps)(App);
