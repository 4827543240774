import React, { useCallback, useRef, useState } from 'react'
import styles from './style.module.css';
import { useSelector } from 'react-redux';
import Button from '../../../design-system/lib/Button';
import { BARCODE, BOX, CLOSE, DRAG, DeliveryOnly_active, Deliveryonly_deactive, Edit, IC_SEARCH, Ic_danger, LINK_CIRCLE, MORE, PATH, PickupOnly_Deactive, RECEIPT, ROUTE_OPTIMIZE, Search_Table, TIMER, box_gray, flag, ic_done, ic_fail, ic_home, trash } from '../../../assets';
import Typography from '../../../design-system/lib/Typography';
import { get_lang } from '../../../helper/UserData/userdate';
import jalali from "jalali-moment";
import Tag from '../../../design-system/lib/Tag';
import { ClickAwayListener, Paper, Popper } from '@material-ui/core';
import IconProvider from '../../../design-system/lib/IconProvider';
import Input from '../../../design-system/lib/Input';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";


const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: 0,
  margin: `0`,
  float: "left",
  width: "100%",
  // change background colour if dragging
  // background: isDragging ? "lightgreen" : "grey",

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: "#fbfdff",
  padding: 0,
  width: "100%",
  float: "left",
  position: "relative",
  marginTop: "2px"
});

const queryAttr = "data-rbd-drag-handle-draggable-id";



function ListItemsReorder({ data , setData , setIsreorder}) {
  const [placeholderProps, setPlaceholderProps] = React.useState({});
  const [prevData, setPrevData] = useState(data);


  const onDragEnd = React.useCallback((result) => {

    if (!result.destination) {
      return;
    }

    const itemsx = reorder(
      data.stops,
      result.source.index,
      result.destination.index
    ).map((itex , i) => {
      return {
        ...itex , position : i+1
      }
    });

    setData({...data , stops:itemsx})
    setPlaceholderProps({})
  }, [data.stops]);

  const onDragUpdate = React.useCallback((update) => {
    if (!update.destination) {
      return;
    }
    const draggableId = update.draggableId;
    const destinationIndex = update.destination.index;

    const domQuery = `[${queryAttr}='${draggableId}']`;
    const draggedDOM = document.querySelector(domQuery);

    if (!draggedDOM) {
      return;
    }

    const { clientHeight, clientWidth } = draggedDOM;
    const clientY = parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) + [...draggedDOM.parentNode.children]
      .slice(0, destinationIndex)
      .reduce((total, curr) => {
        const style = curr.currentStyle || window.getComputedStyle(curr);
        const marginBottom = parseFloat(style.marginBottom);
        return total + curr.clientHeight + marginBottom;
      }, 0);

    setPlaceholderProps({
      clientHeight,
      clientWidth,
      clientY,
      clientX: parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingLeft)
    });
  }, [data.stops]);






  return (
    <div className={styles.container} >
      <div className={styles.listItemsContainer}>


        <DragDropContext onDragEnd={onDragEnd} onDragUpdate={onDragUpdate}>
          <Droppable droppableId="ListItemsReorder">
            {(providedx) => (
              <div ref={providedx.innerRef} {...providedx.droppableProps} style={{position:"relative"}}>
                {data.stops.map((stop, index) => (
                  <Draggable key={stop.id} draggableId={stop.id} index={index} isDragDisabled={data.stops.length == 1}>
                    {(provided, snapshot) => (
                      <div
                      isDraggingOver={snapshot.isDragging}
                      id={stop.id}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <div className={styles.itemContainer} style={{borderInlineStart: snapshot.isDragging ? "0px" : (index == data.stops.length - 1) ? "0px": "1px dashed #D9D9D9"}}>
                          <span className={stop.stage == "Pickup" ? styles.stopIconP : styles.stopIconD} >{index + 1}</span>
                          <div className={styles.addressStage}>
                            <Typography weight='Body_Small_Medium' text={stop.address} textColor='TextDark' className={stop.address} />
                            <Tag backgroundColor='GrayUltraLight' iconSize='16' color='TextNormal' size='Medium' text={stop.stage} icon={stop.stage == "Pickup" ? PickupOnly_Deactive : Deliveryonly_deactive} weight='Body_Small_Medium' style={{ borderRadius: "8px", marginBottom: '10px' }} />
                          </div>
                          {["Successful", "Failed"].indexOf(stop.status) > -1 && <IconProvider Icon={stop.status == "Successful" ? ic_done : ic_fail} size='16' style={{ marginInlineEnd: "2px", marginInlineStart: "auto" }} />}
                          {["Successful", "Failed"].indexOf(stop.status) == -1 && <IconProvider Icon={ DRAG} size='16' style={{ marginInlineEnd: "2px", marginInlineStart: "auto" }} />}

                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {providedx.placeholder}
   

       {placeholderProps.clientWidth && <div className={styles.itemContainer} style={{
                    position: "absolute",
                    top: placeholderProps.clientY,
                    left: placeholderProps.clientX,
                    height: placeholderProps.clientHeight,
                    width: placeholderProps.clientWidth,
                  }}>
            

          </div>}
              </div>
            )}
          </Droppable>

        </DragDropContext>

{/* 
        {data.stops.map((stop, i) => {
          return <div className={styles.itemContainer}>
            <span className={stop.stage == "Pickup" ? styles.stopIconP : styles.stopIconD} >{stop.position}</span>
            <div className={styles.addressStage}>
              <Typography weight='Body_Small_Medium' text={stop.address} textColor='TextDark' className={stop.address} />
              <Tag backgroundColor='GrayUltraLight' iconSize='16' color='TextNormal' size='Medium' text={stop.stage} icon={stop.stage == "Pickup" ? PickupOnly_Deactive : Deliveryonly_deactive} weight='Body_Small_Medium' style={{ borderRadius: "8px", marginBottom: '10px' }} />
            </div>
            {["Successful", "Failed"].indexOf(stop.status) > -1 && <IconProvider Icon={stop.status == "Successful" ? ic_done : ic_fail} size='16' style={{ marginInlineEnd: "2px", marginInlineStart: "auto" }} />}

          </div>
        })} */}

      </div>
    </div>
  )
}


export default ListItemsReorder


