import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NewTravelDropOffSetReserve, Set_DropOff_fields_Dnd } from '../Redux/actions/actionNames';




export function UseCRUDDropoffs() {
  const [items, setItems] = useState([]);
  const firstDropOff = useSelector((state) => state.NewOrder.newOrder.dropOffs.firstDropOff)
  const otherDropOffs = useSelector((state) => state.NewOrder.newOrder.dropOffs.otherDropOffs)
  const dispatch = useDispatch();


  useEffect(() => {
    let arr = [];
    arr.push(firstDropOff);
    otherDropOffs.forEach((drop) => {
      arr.push(drop);
    })

    setItems(arr)
  }, [firstDropOff, otherDropOffs]);

  useEffect(() => {
    let arr = [];
    arr.push(firstDropOff);
    otherDropOffs.forEach((drop) => {
      arr.push(drop);
    })
    setItems(arr)
  }, []);



  useEffect(() => {


  }, [items]);



  function sEtItems(itemss) {
    if (itemss.length > 0) {
      itemss.forEach((drop, ii) => {
        if (ii == 0) {

          dispatch(
            Set_DropOff_fields_Dnd({
              index: ii,
              value: drop,
              firstDropOff: true
            })
          );
        } else {
          dispatch(
            Set_DropOff_fields_Dnd({
              index: ii - 1,
              value: drop,
              firstDropOff: false
            })
          );
        }

      })
    }
    setItems(itemss)
  }

  return { items, sEtItems };
};

