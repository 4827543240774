import { useTranslation } from 'react-i18next';
import styles from './style.module.css';


export const DueTime = ({ChangeScheduled , isScheduled , resetFilter , isNow}) => {
    const { t, i18n } = useTranslation();


    return <div className={styles.container} >
        <span className={styles.filterTilte}>Due time</span>
        <span className={styles.clear} onClick={resetFilter}>clear</span>

        <div className={styles.itemContainer}>
            <span className={styles.item +" "+ (isNow && styles.activeItem)} onClick={()=>{ChangeScheduled({key : "now" , value: !isNow})}}> {t("now")} </span>
            <span className={styles.item+" "+ (isScheduled && styles.activeItem)} onClick={()=>{ChangeScheduled({key : "Scheduled" , value: !isScheduled})}}> {t("Scheduled")} </span>
        </div>

        
    </div>

};




