import "./index.css";

import React, { useCallback, useEffect, useRef, useState } from "react";
import clsx from "clsx";
import styles from './style.module.css';
import colors from '../colors/colors.module.css';
import IconProvider from "../IconProvider";
import ProgressIndicator from "../ProgressIndicator";
import { ARROW_DOWN_BOLD, ArrowRight1, CLOSE ,filterPlus , ic_circle_close } from "../../../assets/index";
import { CSSTransition } from 'react-transition-group';
import Typography from "../Typography";
import { InputFilterProps } from "./InputFilter.types";
import DropDown from "../DropDown";
import Input from "../Input";
import {isEmpty, isEqual, isNull, isObject, isString} from "lodash";
import { v4 as uuidv4 } from 'uuid';


const statesSetter = (states :any)=>{
    return states.map((state :any)=>{ 
        return { key: state, title: state }
    })
}



const InputFilter: React.FC<InputFilterProps> = ({ From, states, To, clearOnClick, errorText, i18, onChange, placement, state, value, title ,stateOnChange }: InputFilterProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const [activeStates, setActiveStates] = useState(statesSetter(states));
    const [uuid] = useState(uuidv4());    
    const [currentState, setCurrentState] = useState(state);
    const [currentValue, setCurrentValue] = useState<any>(value);
    const myRef :any = useRef(null);

    const [currentplacement, setCurrentplacement] = useState(placement);


    const dropdownNode: any = useClickOutside(() => {
        setIsOpen(false);
    })



    const buttonOncick = useCallback((e:any) => {
        e.stopPropagation()
        // if(!isOpen){
        //     setTimeout(() => {
        //         if(!value){
        //             setIsOpen(true);
        //         }
        //         setCurrentValue(null);onChange(null)
        //     }, 50);
        // }

        setIsOpen(true);
        // setCurrentValue(value)

        const myDiv = document.getElementById(uuid);
        const rect = myDiv?.getBoundingClientRect();
    
     
        if((window.innerWidth - Number(rect?.right)) < 300){
            setCurrentplacement("bottomEnd")
        }else{
            setCurrentplacement("bottomStart")
        }


      }, [isOpen , value , currentState , uuid]);

    function ItemsList(data: any, currentState: any, setCurrentState: any) {
        return (
            <div className={styles.itemsConatiner}>
                {data.map((item: any) => {
                    return <div onClick={() => { setCurrentState(item.key); setCurrentValue(null) ; stateOnChange(item.key) }}>
                        <Typography weight={currentState == item.key ? 'Body_Tiny_Bold' :'Body_Tiny_Regular'} textColor={'extNormal'} text={item.title} className={styles.dropItem} />
                    </div>
                })}
            </div>
        )
    }


    let canClear = currentState == "Isbetween" ? (currentValue ? (String(currentValue?.from).length > 0 || String(currentValue?.to).length > 0) : false) : (currentValue ? String(currentValue).length > 0 : false)



    return (
        <div ref={dropdownNode} onClick={buttonOncick} id={uuid} className={clsx(styles.button , value && styles.Activebutton , isOpen && styles.buttonNOhOVER)} >

            {value ? <>
                <IconProvider Icon={ic_circle_close} size="14"  onClick={(e)=>{
                            e.stopPropagation();
                            setCurrentValue(null);
                            onChange(null)
                }} /><span ref={myRef} className={styles.title}> {`${title}`} <div style={{height:"12px",width:"1px" ,backgroundColor:"rgba(128, 128, 128, 1)",borderRadius:"2px"}} /><Typography weight='Body_Small_Medium' textColor={'TextDark'} text={isString(value) ? ` ${value}` : ` ${value.from} - ${value.to}`}/> </span>
                
            </> :<span ref={myRef} className={styles.title}> <IconProvider Icon={filterPlus} size="14"  onClick={(e)=>{
                
                }} /> {title}</span>}



            <CSSTransition
                in={isOpen}
                timeout={300}
                classNames="dropdown-animation"
                unmountOnExit
                onEnter={() => setIsOpen(true)}
                onExited={() => setIsOpen(false)}
            >
                <div className={clsx(styles.dropdownBox, styles[currentplacement])}>
                    {states?.length > 1 &&<DropDown ItemsList={ItemsList(activeStates, currentState, setCurrentState)} text={currentState} placement="over" size="Small" data={[{ key: "Isequal", title: "Isequal" }, { key: "Isbetween", title: "Isbetween" }, { key: "Contains", title: "Contains" }]} checkSelected="full" className={styles.drop} />}

                    {currentState == "Isbetween" && <div className={styles.IsbetweenContainer}>
                    <Input type={"text"} placeHolder="From"  value={(currentValue && currentValue.from ? currentValue.from :"" )} onChange={(e: any) => { setCurrentValue({from:e.target.value,to:(currentValue && currentValue.to)?currentValue.to:"" }) }} />
                    <IconProvider Icon={ArrowRight1} size="16" />
                    <Input type={"text"} placeHolder="To"   value={(currentValue && currentValue.to ? currentValue.to :"" )} onChange={(e: any) => { setCurrentValue({to:e.target.value,from:(currentValue && currentValue.from)?currentValue.from:"" }) }} />
                    </div>}
                    {currentState != "Isbetween" && <Input type={"text"} placeHolder="Enter value" value={currentValue ? currentValue :"" } onChange={(e: any) => { isEmpty(e.target.value) ? setCurrentValue(null) : setCurrentValue(e.target.value) }} />}
                    {/* <Typography weight='Body_Small_Regular' textColor={'Red'} text={"Error Text"} className={styles.Error} /> */}

                    <div className={styles.footer}>
                        <Typography weight='Body_Tiny_Bold' textColor={'TextDark'} text={"Clear"} className={styles.dropItem} style={{ cursor: "pointer" }}  onClick={(e)=>{
                            e.stopPropagation();
                            setCurrentValue(null);
                            onChange(null)
                }}  />
                        <Typography weight='Body_Tiny_Bold' textColor={'Primary'} text={"Apply"} className={styles.dropItem} style={{ opacity: (!isEqual(currentValue , value)) ? "1" : ".4", cursor: "pointer" }} onClick={()=>{!isEqual(currentValue , value) && onChange(currentValue);setTimeout(() => {
                            setIsOpen(false)
                        }, 100);}} />

                    </div>
                </div>
            </CSSTransition>
        </div>

    );
};

export default InputFilter;



const useClickOutside = (handler: any) => {
    const domNode = useRef<any>();
    useEffect(() => {
        const eventHandler = (event: any) => {
            if (!domNode.current.contains(event.target)) {
                handler();
            }
            // uncomment this for dropdown close on menu click
            // handler();
        }
        document.addEventListener("mousedown", eventHandler);
        return () => {
            document.removeEventListener('mousedown', eventHandler);
        }
    });
    return domNode;
}




