import HKerryExpressiO from "../../../assets/Bristol.png";
import FavIcon from "../../../assets/PROJECTS/Bristol_fav_icon.png";

export default function Bristol() {
    
    return  global.config = {
    "ProjectName": "Bristol",
    "LogoType": HKerryExpressiO,
    "BaseApi": "https://rest.bristolcountycouriers.com",
    "BaseSocket": "https://socket.bristolcountycouriers.com",
    "BaseTrack": "https://panel.bristolcountycouriers.com",
    "MapCenter": {
        "lat": 41.701462747317485,
        "lng": -71.15504551730703
    },
    "Lang": "en",
    "Direction": "ltr",
    "AllLanguages": [{
        "lang": "en",
        "direction": "ltr",
        "i18Lang": "English"
    }],
    "FavIcon": FavIcon,
    "Country": "US",
    "thirdPartyLoginSupport": true  ,
    mapType :"osm", /* osm | google */ 
    googleApiKey : ""
}}