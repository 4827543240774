import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import {
  ChangePersonBusiness,
} from "../../../../../Redux/actions/actionNames";
import { withTranslation, Trans } from "react-i18next";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      switch: "details",
    };
  }

  handleSwitch = (e) => {
   this.props.dispatch(ChangePersonBusiness())
  };

  render() {
    return (
      <personBusinessSwitchContainer>
        <detail
          id="details"
          onClick={this.handleSwitch}
          className={`${this.props.person ? "details" : "DEactive"}`}
        >
          <Trans i18nKey={"Person"}> </Trans>
        </detail>
        <history
          id="history"
          onClick={this.handleSwitch}
          className={`${!this.props.person ? "details" : "DEactive"}`}
        >
          <Trans i18nKey={"Business"}> </Trans>
        </history>
      </personBusinessSwitchContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  person:state.NewOrder.newOrder.customer.person ,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
