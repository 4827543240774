import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import time from "../../../../../assets/icon-es-time.svg";
import path from "../../../../../assets/icon-es-path.svg";
import discount from "../../../../../assets/icon-ic-discount.svg";
import cod from "../../../../../assets/ic-cod.svg";
import timer from "../../../../../assets/ic-timer-x.svg";
import delay from "../../../../../assets/ic-timer-delay.svg";
import momentJalaali from "moment-jalaali";
import { withTranslation, Trans } from "react-i18next";
import { get_calendarType, get_currencyCode, get_lang } from "../../../../../helper/UserData/userdate";
import { GetNextStatusListItem } from "../../../../../utils/getNextStatus_ListItem";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      minutes: 0
    }

    this.intervalId = null

  }
  

  componentDidMount(){
    if (["Pending", "Assigned", "Started", "Arrived", "PickedUp"].indexOf(this.props.status) > -1) {
      if (this.props.realETA) {
          if (this.props.isPossibleForDelay) {
            this.setState({
              minutes:Math.round(parseInt(Math.abs(this.props.realETA - this.props.delayAt) / 1000) / 60)
            })
          }else if (this.props.isDelayed) {
            this.setState({
              minutes:Math.round(parseInt(Math.abs(Date.now() - this.props.delayAt) / 1000) / 60)
            })
            this.intervalId = setInterval(() => {
              this.setState({
                minutes:Math.round(parseInt(Math.abs(Date.now() - this.props.delayAt) / 1000) / 60)
              })
          }, 50000);
          }

        }
      }
  }

  componentWillReceiveProps(){
    if (["Pending", "Assigned", "Started", "Arrived", "PickedUp"].indexOf(this.props.status) > -1) {
      if (this.props.realETA) {
        if(!this.intervalId){
          if (this.props.isDelayed && !this.props.isPossibleForDelay) {
            this.setState({
              minutes:Math.round(parseInt(Math.abs(Date.now() - this.props.delayAt) / 1000) / 60)
            })
            this.intervalId = setInterval(() => {
              this.setState({
                minutes:Math.round(parseInt(Math.abs(Date.now() - this.props.delayAt) / 1000) / 60)
              })
          }, 50000);
          }
        }
        }
      }else{
        clearInterval(this.intervalId)
      }
  }

  componentWillUnmount(){
    clearInterval(this.intervalId)
  }

  render() {
    
    let canShowEtaDelay = (["Pending", "Assigned", "Started", "Arrived", "PickedUp"].indexOf(this.props.status) > -1 && this.props.realETA)

    return (
      <extraDetailsContainer>

        {!!canShowEtaDelay && <extraDetail>
          <img src={timer} style={{width:"24px",height:"24px"}} />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <value> {momentJalaali(this.props.realETA).locale(get_calendarType()).format("HH:mm")} </value>
            <key>
              {String(this.props.t(`${GetNextStatusListItem(this.props.status, this.props.orderType)}`)).replace("xxx", "")}
            </key>{" "}
          </div>
        </extraDetail>}


        {(!!canShowEtaDelay && this.props.isPossibleForDelay && Number(this.state.minutes) > 0) && <extraDetail>
          <img src={delay} style={{width:"24px",height:"24px",marginTop:"2px"}} />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <value> {String(this.props.t(`min`)).replace("xxx" , this.state.minutes)} </value>
            <key>
              {String(this.props.t(`late`))}
            </key>
          </div>
        </extraDetail>}

        {(!!canShowEtaDelay && this.props.isDelayed && !this.props.isPossibleForDelay && Number(this.state.minutes) > 0) && <extraDetail>
          <img src={delay} style={{width:"24px",height:"24px",marginTop:"2px"}} />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <value> {String(this.props.t(`min`)).replace("xxx" , this.state.minutes)} </value>
            <key>
              {String(this.props.t(`delay`))}
            </key>
          </div>
        </extraDetail>}



        {["Ondemand","PickupDelivery"].indexOf(this.props.orderType) > -1 &&<extraDetail> <img src={path} />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <value> {this.props.Espath} </value>
            <key><Trans i18nKey={"Espath"}> </Trans></key>
          </div>
        </extraDetail>}



        {Number(this.props.Cod) > 0 && <extraDetail>
          {" "}
          <img src={cod} />
          <div style={{ display: "flex", flexDirection: "column" }}>

            <value> {Number(this.props.Cod).toLocaleString(get_lang(), {
              style: "currency",
              currency: get_currencyCode(),
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
            })}</value>
            <key>
              <Trans i18nKey={"COD"}> </Trans>
            </key>{" "}
          </div>
        </extraDetail>}


      </extraDetailsContainer>
    );

  }
}

const mapStateToProps = (state) => ({
  Promocode: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.promoCode
    : false,
  Espath: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.estimatedDistanceText
    : 0,
  Cod: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.codAmount
    : 0,
  pureData: state.ViewTravel.pureData
    ? state.ViewTravel.pureData
    : null,

  status: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.status
    : "",

  realETA: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.realETA
    : 0,

  delayAt: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.delayAt
    : 0,

  orderType: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.type
    : "",

  isPossibleForDelay: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.isPossibleForDelay
    : false,

  isDelayed: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.isDelayed
    : false,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
