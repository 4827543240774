import onros from "../../../assets/saas/onro/icon/ic_Logo.svg";
import FavIcon from "../../../assets/PROJECTS/onro.png";

export default function onro() {
    
    return global.config = {
    "ProjectName": "dispatcher",
    "LogoType": onros,
    "BaseApi": "https://rest.onro.io",
    "BaseSocket": "https://socket.onro.io",
    "BaseTrack": "https://panel.onro.io",
    "MapCenter": {
        "lat": "3.1475",
        "lng": "101.693333"
    },
    "Lang": "en",
    "Direction": "ltr",
    "AllLanguages": [{
        "lang": "en",
        "direction": "ltr",
        "i18Lang": "English"
    },{
        "lang": "el",
        "direction": "ltr",
        "i18Lang": "Greek"
    },{
        "lang": "fa",
        "direction": "rtl",
        "i18Lang": "Persian"
    }, {
        "lang": "fr",
        "direction": "ltr",
        "i18Lang": "French"
    }, {
        "lang": "ro",
        "direction": "ltr",
        "i18Lang": "Romanian"
    },{
        "lang": "pt",
        "direction": "Ltr",
        "i18Lang": "Portuguese"
    }],
    "FavIcon": FavIcon,
    "Country": "US",
    "thirdPartyLoginSupport": true  ,
    mapType :"osm", /* osm | google */ 
    googleApiKey : ""
} }