import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import star from "./../../../../../assets/icon-star.svg";
import { getBaseTrackByUrl } from "../../../../../helper/module";
import { get_id, get_lang } from "../../../../../helper/UserData/userdate";
import deliveryProfile from "../../../../../assets/delivery-man.png";
import call from "../../../../../assets/ic-call.svg";
import { Voip_OutgoingCall } from "../../../../../Redux/actions/actions";

var _ = require("lodash");

class App extends React.Component {


  makeOutgoingCall = () => {
    
    this.props.dispatch(Voip_OutgoingCall(this.props.dispatch, {
      "to": this.props.driver.phone,
      "orderId": this.props.orderId,
      "customerId": null,
      "driverId": this.props.driverId,
      "dispatcherId": get_id()
    }))

  }

  serviceOptionsNameExtracter = () => {
    let names = [];
    if (this.props.options) {
      this.props.options.map((option) => {
        if (option.type === "boolean") {
          names.push(option.title);
        } else {
          names.push(option.dataTitle);
        }
      });
      return names.join(" , ")
    } else {
      return "";
    }
  };


  render() {
    if (!_.isEmpty(this.props.driver)) {
      const { t } = this.props;
      return (
        <driverInformation>
          <optionss>{this.serviceOptionsNameExtracter()}</optionss>
          <img src={this.props.driver.avatarPhoto.length > 0 ? this.props.driver.avatarPhoto : deliveryProfile} onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = deliveryProfile;
          }} />
          <div className="vr_statusvehicleContainer">
            <name>{this.props.driver.name}</name>
            <number>
              <img src={star} />
              {this.props.driver.rate}
            </number>
          </div>
          {global.config.dispatcherSettings.isVoipActvie && <img src={call} onClick={this.makeOutgoingCall} className="viewOrderCustomerVoip" />}

          <hr />

          <img
            className="imgcar"
            src={
              this.props.vehicletype &&
              this.props.vehicletype.icon
            }
            style={{ marginTop: "9px" }}
          />




          <vehicleTypesContainer>
            <item>
              <key> {this.props.driver.vehicle.plate}</key>
              <value>
                {this.props.driver.vehicle.model +
                  " " +
                  this.props.driver.vehicle.color}
              </value>
            </item>
            <item>
              <key>{this.props.driver.username}</key>
              <value>
                <Trans i18nKey={"Username"}> </Trans>
              </value>
            </item>
            <item>
              <key>{this.props.driver.phone}</key>
              <value>
                <Trans i18nKey={"Phone"}> </Trans>
              </value>{" "}
            </item>
          </vehicleTypesContainer>
        </driverInformation>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => ({
  vehicletype: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.vehicleType
      ? state.ViewTravel.pureData.vehicleType
      : null
    : null,
  driver: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.driver
      ? state.ViewTravel.pureData.driver
      : null
    : null,
  options: state.ViewTravel.pureData ? state.ViewTravel.pureData.vehicleType
    ? state.ViewTravel.pureData.vehicleType.options
      ? state.ViewTravel.pureData.vehicleType.options
      : null
    : null
    : null,
  lang: state.LocalData.lang,

  driverId: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.driverId
      ? state.ViewTravel.pureData.driverId
      : null
    : null,

  orderId: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.id
      ? state.ViewTravel.pureData.id
      : null
    : null,

});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
