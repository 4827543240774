import React from "react";

const StartPin = (props) => {

  return <svg
    xmlns="http://www.w3.org/2000/svg"
    width={37}
    height={53}
    fill="none"
    style={{
      transform: "scale(1.5)",
      marginLeft: '-5px',
      marginTop: '-41px'
    }}
    {...props}
  >
    <path
      fill="#484848"
      fillRule="evenodd"
      d="M10.012 0C4.485 0 .004 4.48.004 10.008c0 5.316 4.145 9.664 9.379 9.989v15.532a.626.626 0 0 0 1.25 0V19.997c5.239-.32 9.387-4.67 9.387-9.989C20.02 4.481 15.54 0 10.012 0Z"
      clipRule="evenodd"
    />
    <rect
      width={17.348}
      height={17.348}
      x={1.332}
      y={1.334}
      fill="#fff"
      rx={8.674}
    />
    <path
      fill="#484848"
      d="M14.395 7.315 11.197 4.76a1.916 1.916 0 0 0-2.397.001L5.624 7.309a1.558 1.558 0 0 0-.62 1.23v4.913c0 .493.401.895.895.895h8.214a.896.896 0 0 0 .895-.895V8.533a1.562 1.562 0 0 0-.613-1.218Z"
      opacity={0.4}
    />
    <path
      fill="#484848"
      d="M8.559 13.453v.895h2.9v-2.693a1.448 1.448 0 0 0-1.53-1.45c-.755.04-1.37.736-1.37 1.552v1.696Z"
    />
  </svg>


};

export default StartPin



