import styles from './style.module.css';
import forward from "../../../assets/forward.svg";
import pickupdot from "../../../assets/pickupdot.svg";
import dropoffdot from "../../../assets/dropoffdot.svg";
import { useDispatch, useSelector } from 'react-redux';
import schedule from "../../../assets/schedule.svg";
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { GetCategoryByStatus } from '../../../utils/get-category-by-status';
import pending from "../../../assets/ic-pending.svg";
import collecting from "../../../assets/ic-collecting.svg";
import delay from "../../../assets/ic-timer-delay.svg";
import progress from "../../../assets/ic-in-progress-voip.svg";
import delivering from "../../../assets/ic-delivering.svg";
import canceled from "../../../assets/ic-canceled.svg";
import done from "../../../assets/ic-done.svg";
import transit from "../../../assets/ic-in-transit.svg";
import { GetColorByStatus } from '../../../utils/get-colors-by-status';
import { Link } from 'react-router-dom';
import dispatch_later from "../../../assets/dispatch_later.svg";
import dispatching from "../../../assets/dispatching.svg";
import unassigned from "../../../assets/unassigned.svg";
import IconProvider from '../../../design-system/lib/IconProvider';
import { CALL, Customer, FORWARD, Routes_deactive } from '../../../assets';
import Typography from '../../../design-system/lib/Typography';
import Button from '../../../design-system/lib/Button';
import { Voip_OutgoingCall } from '../../../Redux/actions/actions';
import history from '../../../service/history';
import { get_id } from '../../../helper/UserData/userdate';



export const ViewOrderRoute = () => {
    const stage = useSelector((state) => state.ViewTravel.pureData.stage)

    const route = useSelector((state) => state.ViewTravel.pureData.route)
    const orderType = useSelector((state) => state.ViewTravel.pureData.type)
    const driver = useSelector((state) => state.ViewTravel.pureData.driver)
    const driverId = useSelector((state) => state.ViewTravel.pureData.driverId)
    const orderId = useSelector((state) => state.ViewTravel.pureData.id)
    const pickup = useSelector((state) => state.ViewTravel.pureData.pickup)
    const delivery = useSelector((state) => state.ViewTravel.pureData.delivery)

    const dispatch = useDispatch();

    const { t } = useTranslation();

    function makeOutgoingCall() {
        dispatch(Voip_OutgoingCall(dispatch, {
            "to": driver.phone,
            "orderId": orderId,
            "customerId": null,
            "driverId": driverId,
            "dispatcherId": get_id()
        }))

    }


    if (["PickupDelivery"].indexOf(orderType) > -1) {
        return <div className={styles.RouteContainer}>
            <div className={styles.RouteRow}>
                <IconProvider Icon={Routes_deactive} size='16' />
                <div className={styles.columnRow}>
                    <Typography weight='Body_Middle_Medium' text={route ? route.name : "No Route"} />
                    {route && <Typography weight='Subtitle_Tiny_Regular' text={route.status} />}
                </div>



                {route && <Button onClick={()=>{history.push(`/route/${route.id}/0`)}} size='Medium' type='Secondary' icon={FORWARD} text='Details' style={{ marginInlineStart: "auto" }} />}
            </div>

            {(driver && route) && <>
                <hr className={styles.hr} />

                <div className={styles.RouteRow}>
                    <IconProvider Icon={driver.avatarPhoto?.length > 0 ? driver.avatarPhoto :  Customer} size='32' />
                    <div className={styles.columnRow} style={{ marginInlineStart: "8px" }}>
                        <Typography weight='Body_Middle_Medium' text={driver.name} />
                        <Typography weight='Subtitle_Tiny_Regular' text={"@"+driver.username} textColor='TextNormal' />
                    </div>



                    <img className={styles.call} src={CALL} onClick={() => makeOutgoingCall()} />
                </div>
            </>}

        </div>
    } else {
        return null
    }


};




