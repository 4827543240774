import React from 'react';
import styles from './style.module.css';
import iconIcSearch from '../../../../assets/ic-dispatching-x.svg';
import confirm from '../../../../assets/ic-confirm-x.svg';
import reject from '../../../../assets/ic-close-red-x.svg';
import dispatch_later_tooltip from '../../../../assets/dispatch_later_tooltip.svg';
import rejected from '../../../../assets/ic-driver-rejected.svg';
import waitconfirm from '../../../../assets/ic-driver-wait-confirm.svg';
import radar from '../../../../assets/ic-radar.svg';
import queue from '../../../../assets/ic-queue.svg';
import noDriver from '../../../../assets/ic-driver-not-selected.svg';
import icDriver from '../../../../assets/ic-driver-new.svg';
import icTimer from '../../../../assets/ic-timer.svg';
import icTimerDelay from '../../../../assets/ic-timer-delay.svg';
import { useTranslation } from 'react-i18next';
import { GetNextStatusListItem } from '../../../../utils/getNextStatus_ListItem';
import momentJalaali from "moment-jalaali";
import { get_calendarType, get_lang } from '../../../../helper/UserData/userdate';
import { useState } from 'react';
import { GetColorByStatus } from '../../../../utils/get-colors-by-status';
import { useEffect } from 'react';
import { Tooltip, TooltipProps, tooltipClasses } from 'newmaterial-ui';
import { styled } from 'newmaterial-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { SetActiveDriverList, SetDriverListOpenDialoge, SetSelectedMonitoringDriver } from '../../../../Redux/actions/actionNames';
import { GetDriverInfoAction, GetDriverOrderListAction } from '../../../../Redux/actions/actions';
import jalali from "jalali-moment";
import useIsWindowFocused from '../../../../hooks/useIsWindowFocused';
import Axios from 'axios';
import queue_urgent from "../../../../assets/queue_urgent.svg";
import radar_urgent from "../../../../assets/radar_urgent.svg";

const _ = require('lodash');

export const OrderStatusRow = ({ data, showDelayLate, disableListItem }) => {
    const cursor = useSelector((state) => state.OrderList.cursor)
    const cursorSort = useSelector((state) => state.OrderList.cursorSort)
    const activeDriver = useSelector((state) => state.DriversList.activeDriver)

    const { t, i18n } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const dispatch = useDispatch();
    const DispatchLater = data.status == "Pending" && !data.isDispatching && data.driverAssignmentModel == "automatic";

    const getDriverOrders = React.useCallback((id , noLoading) => {

        dispatch(
            GetDriverOrderListAction(dispatch, {
                cursor: cursor,
                cursorSort: cursorSort,
                status: ["Assigned", "PickedUp", "Started", "Arrived"],
                size: 200,
                driverId: id,
            }, false)
        );

    }, [cursor, cursorSort]);



    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: ' #fff',
            borderRadius: "8px",
            padding: "0",
            border: 'none',
            boxShadow: "0 4px 12px 0 rgba(0, 0, 0, 0.24)",
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 220,
            fontSize: theme.typography.pxToRem(12),
            marginBottom: "0px !important"
        },
    }));


    function OpenUserProfile() {
        disableListItem(false)
        if (activeDriver) {
            if(activeDriver.id == data.driverId){
                dispatch(SetSelectedMonitoringDriver(data.driverId))

                dispatch(SetDriverListOpenDialoge(true))
    
                getDriverOrders(data.driverId , true)
            }else{
                dispatch(SetActiveDriverList({ ...data.driver, id: data.driverId }));
                dispatch(GetDriverInfoAction(dispatch, data.driverId))
                dispatch(SetSelectedMonitoringDriver(data.driverId))
    
                dispatch(SetDriverListOpenDialoge(true))
    
                getDriverOrders(data.driverId , false)
            }

        } else {
            dispatch(SetActiveDriverList({ ...data.driver, id: data.driverId }));
            dispatch(GetDriverInfoAction(dispatch, data.driverId))
            dispatch(SetSelectedMonitoringDriver(data.driverId))

            dispatch(SetDriverListOpenDialoge(true))

            getDriverOrders(data.driverId , false)
        }

    };

    function OpenUserProfileRejectedWaiting() {

        if (activeDriver) {
            if(activeDriver.id == data.driverId){
                dispatch(SetDriverListOpenDialoge(true))
                dispatch(SetSelectedMonitoringDriver(data.currentCandidateDrivers[0].driverId))
                getDriverOrders(data.driverId , true)

            }else{
                dispatch(SetActiveDriverList({ id: data.currentCandidateDrivers[0].driverId }));
                dispatch(GetDriverInfoAction(dispatch, data.currentCandidateDrivers[0].driverId))
                dispatch(SetSelectedMonitoringDriver(data.currentCandidateDrivers[0].driverId))
        
                dispatch(SetDriverListOpenDialoge(true))
                getDriverOrders(data.driverId , false)
            }
        }else{
            dispatch(SetActiveDriverList({ id: data.currentCandidateDrivers[0].driverId }));
            dispatch(GetDriverInfoAction(dispatch, data.currentCandidateDrivers[0].driverId))
            dispatch(SetSelectedMonitoringDriver(data.currentCandidateDrivers[0].driverId))
    
            dispatch(SetDriverListOpenDialoge(true))
            getDriverOrders(data.driverId , false)
        }


    };

    return (
        <div className={styles.OrderStatusRowContainer}>
            <span className={styles.Status} style={{ color: `#${GetColorByStatus(data.status)}` }}>{t("EDAS_" + data.status)}</span>
            {showDelayLate && <EtaDelayLateContainer status={data.status} realETA={data.realETA} isPossibleForDelay={data.isPossibleForDelay} delayAt={data.delayAt} type={data.type} isDelayed={data.isDelayed} />}

            {(!_.isEmpty(data.driver) && !DispatchLater) && <HtmlTooltip title={
                <div className={styles.popUpContainer}>
                    <div className={styles.popUpTitleDescContainer}>
                        <span className={styles.popUpTitle} > {data.driver.name} </span>
                    </div>
                    <span className={styles.popUpBtn} onClick={OpenUserProfile}>
                        {t("Profile")}
                    </span>
                </div>
            } placement="top" onOpen={() => { disableListItem(true) }} onClose={() => { disableListItem(false) }} >
                <span className={styles.OrderStatusRowContainerInner}><img src={icDriver} alt="" className={styles.searchIcon} />
                    <span className={styles.Dispatching}> {data.driver.name} </span></span>
            </HtmlTooltip>}


            {(_.isEmpty(data.driver) && !DispatchLater && ["Pending"].indexOf(data.status) == -1 && data.type != "PickupDelivery") &&

                <span className={styles.OrderStatusRowContainerInner}>
                    <img src={noDriver} alt="" className={styles.searchIcon} />
                    <span className={styles.Dispatching}> {t("NoDriver")} </span>
                </span>
            }

            {
                (["Pending"].indexOf(data.status) > -1 && !DispatchLater && data.driverAssignmentModel == "automatic" && _.isEmpty(data.driver) && data.candidateDriversCount == 0) &&
                <HtmlTooltip title={
                    <div className={styles.popUpContainer}>
                        <div className={styles.popUpTitleDescContainer}>
                            <span className={styles.popUpTitle} > {t("Waitingforavailabledrivers")} </span>
                        </div>
                    </div>
                } placement="top" onOpen={() => { disableListItem(true) }} onClose={() => { disableListItem(false) }}>
                    <span className={styles.OrderStatusRowContainerInner}><img src={data.isUrgent ? queue_urgent : queue} alt="" className={styles.searchIcon} />
                        <span className={styles.Dispatching}> {t("Queue")} </span></span>
                </HtmlTooltip>

            }


            {
                DispatchLater &&
                <HtmlTooltip title={
                    <div className={styles.popUpContainer}>
                        <div className={styles.popUpTitleDescContainer}>
                            <span className={styles.popUpTitle} > {String(t("Dispatchat")).replace("xxx", jalali(data.dispatchAt)
                                .locale(get_lang())
                                .format("D MMM HH:mm"))} </span>
                        </div>
                    </div>
                } placement="top" onOpen={() => { disableListItem(true) }} onClose={() => { disableListItem(false) }}>
                    <span className={styles.OrderStatusRowContainerInner}><img src={dispatch_later_tooltip} alt="" className={styles.searchIcon} />
                        <span className={styles.Dispatching}> {
                            jalali(data.dispatchAt)
                                .locale(get_lang())
                                .format("D MMM HH:mm")}  </span></span>
                </HtmlTooltip>

            }





            {
                (["Pending"].indexOf(data.status) > -1 && !DispatchLater && data.driverAssignmentModel == "automatic" && _.isEmpty(data.driver) && data.candidateDriversCount > 0) &&
                <HtmlTooltip title={
                    <div className={styles.popUpContainer}>
                        <div className={styles.popUpTitleDescContainer}>
                            <span className={styles.popUpTitle} > {String(t("OfferingToDrivers")).replace("xxx", data.candidateDriversCount)}  </span>
                        </div>
                    </div>
                } placement="top" onOpen={() => { disableListItem(true) }} onClose={() => { disableListItem(false) }}>
                    <span className={styles.OrderStatusRowContainerInner}><img src={data.isUrgent ? radar_urgent : radar} alt="" className={styles.searchIcon} />
                        <span className={styles.Dispatching}> {data.candidateDriversCount} </span></span>
                </HtmlTooltip>

            }




            {
                (["Pending"].indexOf(data.status) > -1 && !DispatchLater && data.driverAssignmentModel == "manual" && _.isEmpty(data.driver) && data.currentCandidateDrivers[0] && ["received", "notifying", "initial"].indexOf(data.currentCandidateDrivers[0].status) > -1) &&
                <HtmlTooltip title={
                    <div className={styles.popUpContainer}>
                        <div className={styles.popUpTitleDescContainer}>
                            <span className={styles.popUpTitle} > {data.currentCandidateDrivers[0].name} </span>
                            <span className={styles.popUpDesc} > <img src={waitconfirm} /> {t("WaitforAccept")} </span>
                        </div>
                        <span className={styles.popUpBtn} onClick={OpenUserProfileRejectedWaiting}>
                            {t("Profile")}
                        </span>
                    </div>
                } placement="top" onOpen={() => { disableListItem(true) }} onClose={() => { disableListItem(false) }}>
                    <span className={styles.OrderStatusRowContainerInner}><img src={waitconfirm} alt="" className={styles.searchIcon} />
                        <span className={styles.Dispatching}> {data.currentCandidateDrivers[0].name} </span></span>
                </HtmlTooltip>

            }




            {
                (["Pending"].indexOf(data.status) > -1 && !DispatchLater && data.driverAssignmentModel == "manual" && _.isEmpty(data.driver) && data.currentCandidateDrivers[0] && ["rejected"].indexOf(data.currentCandidateDrivers[0].status) > -1) &&
                <HtmlTooltip title={
                    <div className={styles.popUpContainer}>
                        <div className={styles.popUpTitleDescContainer}>
                            <span className={styles.popUpTitle} > {data.currentCandidateDrivers[0].name} </span>
                            <span className={styles.popUpDesc} > <img src={rejected} /> {t("Rejected")} </span>
                        </div>
                        <span className={styles.popUpBtn} onClick={OpenUserProfileRejectedWaiting}>
                            {t("Profile")}
                        </span>
                    </div>
                } placement="top" onOpen={() => { disableListItem(true) }} onClose={() => { disableListItem(false) }}>
                    <span className={styles.OrderStatusRowContainerInner}><img src={rejected} alt="" className={styles.searchIcon} />
                        <span className={styles.Dispatching}> {data.currentCandidateDrivers[0].name} </span></span>
                </HtmlTooltip>

            }




            {(data.vehicleType && data.type != "PickupDelivery") &&
                <HtmlTooltip title={
                    <div className={styles.popUpContainer}>
                        <div className={styles.popUpTitleDescContainer}>
                            <span className={styles.popUpTitle} > {data.vehicleType.title} </span>
                        </div>
                    </div>
                } placement="top" onOpen={() => { disableListItem(true) }} onClose={() => { disableListItem(false) }}>
                    <span className={styles.OrderStatusRowContainerInner} style={{
                        backgroundColor: "rgba(128, 128, 128, 0.06)",
                        borderRadius: "100px",
                        marginInlineStart: "6px",
                        padding: "3px 6px"
                    }}>
                        <span className={styles.Dispatching} style={{ maxWidth: "65px" }}> {data.vehicleType.title} </span></span>
                </HtmlTooltip>

            }

        </div>
    );
};



export const EtaDelayLateContainer = ({ status, realETA, isPossibleForDelay, delayAt, type, isDelayed }) => {
    const { t, i18n } = useTranslation();
    const [nextStatusMin, setNextStatusMin] = React.useState(0);

    useEffect(() => {
        if (realETA && !isPossibleForDelay && !isDelayed) {
            let from = Math.floor(Date.now() / 1000);
            let to = momentJalaali(realETA).locale(get_calendarType()).unix();
            if (to >= from) {
                let min = (to - from) / 60;
                setNextStatusMin(Math.round(min));
                const interval = setInterval(() => {
                    let from = Math.floor(Date.now() / 1000);
                    let min = (to - from) / 60;

                    setNextStatusMin(Math.round(min));
                }, 20000);
                return () => clearInterval(interval);
            }
        }
    }, [realETA, isPossibleForDelay, isDelayed]);



    if (["Pending", "Assigned", "Started", "Arrived", "PickedUp"].indexOf(status) > -1) {
        if (realETA) {
            if (isPossibleForDelay) {
                return <LateComponent status={status} realETA={realETA} delayAt={delayAt} type={type} />
            } else if (isDelayed) {
                return <DelayComponent status={status} realETA={realETA} delayAt={delayAt} type={type} />
            } else if (nextStatusMin > 0) {
                return <span className={styles.tillAt}> {/* <img src={icTimer} alt="" className={styles.icTimer} /> */} {String(t(`${GetNextStatusListItem(status, type)}`)).replace("xxx", nextStatusMin)} </span>
            } else {
                return null
            }
        } else {
            return null
        }
    } else {

        return null
    }
};

export const LateComponent = ({ status, realETA, delayAt, type }) => {
    const { t, i18n } = useTranslation();
    let minutes = Math.round(parseInt(Math.abs(realETA - delayAt) / 1000) / 60);

    return <>
        {/* <span className={styles.tillAt}> <img className={styles.icTimer} src={icTimer} alt="" />  {String(t(`${GetNextStatusListItem(status, type)}`)).replace("xxx", momentJalaali(realETA).locale(get_calendarType()).format("HH:mm"))} </span> */}
        {Number(minutes) > 0 && <span className={styles.tillAt}>  <img className={styles.icTimer} src={icTimerDelay} alt="" /> {String(t(`minlate`)).replace("xxx", minutes)} </span>} </>
    // return <span className={styles.tillAt}>
    //     <span className={styles.delayRed}><img className={styles.icTimer} src={icTimerDelay} alt="" /> {String(t(`minlate`)).replace("xxx", minutes)}</span>
    //     ({String(t(`${GetNextStatusListItem(status, type)}`)).replace("xxx", momentJalaali(realETA).locale(get_calendarType()).format("HH:mm"))})
    // </span>
}



export const DelayComponent = ({ status, realETA, delayAt, type }) => {
    const { t, i18n } = useTranslation();
    const [minutes, setMinutes] = useState(0);
    const windowIsActive = useIsWindowFocused();
    const timestamp = useSelector((state) => state.LocalData.timestamp)

    // function getMinute(ddd) {
    //     return Math.round(parseInt(Math.abs(timestamp - delayAt) / 1000) / 60)
    // }


    // useEffect(() => {

    //     Axios.get('https://worldtimeapi.org/api/ip')
    //     .then((response)=> {
    //         // handle success
    //         setMinutes(getMinute(Number(response.data.unixtime + '000')))
    //     })
    //     .catch( (error)=> {
    //         // handle error

    //         setMinutes(getMinute(Date.now()))
    //     })

    // }, [windowIsActive]);


    // useEffect(() => {
    //     Axios.get('https://worldtimeapi.org/api/ip')
    //     .then((response)=> {
    //         // handle success
    //         setMinutes(getMinute(Number(response.data.unixtime + '000')))
    //     })
    //     .catch( (error)=> {
    //         // handle error

    //         setMinutes(getMinute(Date.now()))
    //     })
    //     const newIntervalId = setInterval(() => {
    //         Axios.get('https://worldtimeapi.org/api/ip')
    //         .then((response)=> {
    //             // handle success
    //             setMinutes(getMinute(Number(response.data.unixtime + '000')))
    //         })
    //         .catch( (error)=> {
    //             // handle error

    //             setMinutes(getMinute(Date.now()))
    //         })
    //     }, 50000);
    //     return () => clearInterval(newIntervalId);
    // }, [windowIsActive]);

    return <>
        {/* <span className={styles.tillAt}> <img className={styles.icTimer} src={icTimer} alt="" />  {String(t(`${GetNextStatusListItem(status, type)}`)).replace("xxx", momentJalaali(realETA).locale(get_calendarType()).format("HH:mm"))} </span> */}
        {Number(timestamp) > 0 && <span className={styles.tillAt}>  <img className={styles.icTimer} src={icTimerDelay} alt="" />  {`${parseInt(Math.abs(timestamp - delayAt) / 1000 / 60)} m`} </span>} </>
    // return <span className={styles.tillAt}> <span className={styles.delayRed}><img className={styles.icTimer} src={icTimerDelay} alt="" />  {String(t(`mindelay`)).replace("xxx", minutes)}</span> ({String(t(`${GetNextStatusListItem(status, type)}`)).replace("xxx", momentJalaali(realETA).locale(get_calendarType()).format("HH:mm"))}) </span>
}
