import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import styles from './style.module.css';
import clsx from "clsx";
import close from "../../../assets/close.svg";
import { OfferingDetailsOpen, PriceDetailsOpen, SetViewOrderPickupDropoffDetails, show_eventTimeLine } from "../../../Redux/actions/actionNames";
import Receipt from "../../../assets/receipt.svg";
import notes from "../../../assets/notes23.svg";
import { isOrderInDone } from "../../../helper/module";
import { isEmpty } from "lodash";
import { get_lang } from "../../../helper/UserData/userdate";
import radar from '../../../assets/ic-radar.svg';
import tickblue from '../../../assets/tickblue.svg';
import close_red from '../../../assets/close_red.svg';
import driver_rejected from '../../../assets/driver_rejected.svg';
import driver from '../../../assets/driver.svg';
import driver_accepted from '../../../assets/driver_accepted.svg';

import { OrderApi } from "../../../service/API/order";


class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }


  closeDialoge = () => {
    this.props.dispatch(OfferingDetailsOpen(false))

    this.props.dispatch(show_eventTimeLine(false))
    this.props.dispatch(PriceDetailsOpen(false))
    this.props.dispatch(SetViewOrderPickupDropoffDetails(null))

  }

  confirm = (item) => {
    let Order = new OrderApi()
    Order.DriverAccept(this.props.Data.id ,item.id )
  }


  reject = (item) => {
    let Order = new OrderApi()
    Order.DriverReject(this.props.Data.id ,item.id)
  }



  render() {

    return (

      <div className={clsx(styles.EventTimeLineContainer, this.props.open && styles.EventTimeLineContainerOpen)}>
        <div className={styles.EventTimeLine_Header}>
          <img onClick={this.closeDialoge} className={styles.close} src={close} />
          <span className={styles.eventsTitle}> <Trans i18nKey={"Offerings"}> </Trans> </span>

        </div>

        <div className={styles.card}>

          {this.props.currentCandidateDrivers?.map((item) => {
            if (['initial', 'notifying', 'received'].indexOf(item.status) > -1) {
              return <div className={styles.detailDriver}>
                <img src={radar} className={styles.detailDriverIcon} />
                <span className={styles.driverName}> {item.name} </span>
                <div className={styles.btnContainer}>
                  <span className={styles.btn} onClick={()=>this.confirm(item)}> <img src={tickblue} /> <Trans i18nKey={"confirm"}> </Trans> </span>
                  <span className={styles.btn} onClick={()=>this.reject(item)} >  <img src={close_red} /><Trans i18nKey={"Reject"}> </Trans> </span>

                </div>
              </div>
            } else if(['assigned'].indexOf(item.status) > -1) {
              return <div className={styles.detailRejectedDriver}>
                <img src={driver_accepted} className={styles.detailRejectedDriverIcon} />
                <span className={styles.driverName}> {item.name}</span>
                <span className={styles.status}>{item.status}</span>
              </div>
            } else if(['rejected'].indexOf(item.status) > -1) {
              return <div className={styles.detailRejectedDriver}>
                <img src={driver_rejected} className={styles.detailRejectedDriverIcon} />
                <span className={styles.driverName}> {item.name} </span>
                <span className={styles.status}>{item.status}</span>

              </div>
            }else{
              return <div className={styles.detailRejectedDriver}>
              <img src={driver} className={styles.detailRejectedDriverIcon} />
              <span className={styles.driverName}> {item.name} </span>
              <span className={styles.status}>{item.status}</span>

            </div>
            }
          })}



        </div>


      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  open: state.ViewTravel.offering_details,
  showEventTimeLine: state.ViewTravel.eventTimeLine.show,
  currentCandidateDrivers: state.ViewTravel.pureData ? state.ViewTravel.pureData.currentCandidateDrivers : [],

  Data: state.ViewTravel.pureData ? state.ViewTravel.pureData : false,
  baseFarePrice: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.baseFarePrice
    : 0,
  surcharges: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.surcharges
    : [],

});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
