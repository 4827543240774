import React from "react";
import { connect } from "react-redux";
import { Polyline } from '@react-google-maps/api';
import { Is_InProgress_Travel } from "../../../../helper/module";



const options = {
  strokeColor: '#39b526',
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillColor: '#39b526',
  fillOpacity: 0.35,
  clickable: false,
  draggable: false,
  editable: false,
  visible: true,
  radius: 30000,
  zIndex: 1
};

class App extends React.Component {
  render() {
    if (/*Is_InProgress_Travel(this.props.status) && */ this.props.EstimatedActive &&  this.props.EstimatedWaypoints) {
      return (
        <Polyline path={this.props.EstimatedWaypoints.map((item)=>{return {lat :item[0] , lng:item[1]}})} color="#39b526"  options={options} />
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => ({

  status: state.ViewTravel.pureData ? state.ViewTravel.pureData.status : "",
  EstimatedActive: state.ViewTravel.estimatedRoute.active,
  EstimatedWaypoints: state.ViewTravel.estimatedRoute.waypoints,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(mapStateToProps, mapDispatchToProps)(App);
