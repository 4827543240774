import { store } from '../Redux/store';
import _, { isEmpty } from "lodash";
import momentJalaali from "moment-jalaali";

export const MatchOrderWithFiltersList = async (socketOrder, call) => {
  let storeData = store.getState();

  if (storeData.OrderList.ActiveOrderType == socketOrder.type || (storeData.ViewTravel.OpenDialoge ? (storeData.ViewTravel.pureData ? storeData.ViewTravel.pureData.type == socketOrder.type : false) : false)) {
    if (await checkDateRange(socketOrder, storeData) && await checkIsScheduled(socketOrder, storeData) && await checkService(socketOrder, storeData) && await checkVehicle(socketOrder, storeData) && await checkZone(socketOrder, storeData) && await checkDispatchLater(socketOrder, storeData)) {
      call(true)
    }
  }
}


const checkDateRange = async (socketOrder, storeData) => {
  let Cursor = await storeData.OrderList.cursor
  let startDate = await storeData.OrderList.DateRange
    ? momentJalaali(storeData.OrderList.DateRange.startDate).unix() + "000"
    : null
  let endDate = await storeData.OrderList.DateRange
    ? (storeData.OrderList.DateRange.endDate ? momentJalaali(storeData.OrderList.DateRange.endDate).unix() + "000" : null)
    : null

  if (Number(startDate)) {

    if (socketOrder[Cursor] >= Number(startDate)) {
      if (endDate) {
        if (socketOrder[Cursor] <= Number(endDate)) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    } else {
      return false
    }

  } else if (Number(endDate)) {
    if (socketOrder[Cursor] <= Number(endDate)) {
      return true
    } else {
      return false
    }
  } else {
    return true
  }
}


const checkIsScheduled = async (socketOrder, storeData) => {
  // ((storeData.OrderList.now && storeData.OrderList.isScheduled) || (!storeData.OrderList.now && !storeData.OrderList.isScheduled) ) ? null : 
  //  (   (storeData.OrderList.now && !storeData.OrderList.isScheduled ) ? false :  true)

  if ((storeData.OrderList.now && storeData.OrderList.isScheduled) || (!storeData.OrderList.now && !storeData.OrderList.isScheduled)) {
    return true
  } else if (_.isEqual(storeData.OrderList.isScheduled, socketOrder.isScheduled)) {
    return true
  } else {
    return false
  }

  // if (_.isEqual(storeData.OrderList.isScheduled, socketOrder.isScheduled)) {
  //   return true
  // } else {
  //   return false
  // }
}


const checkService = async (socketOrder, storeData) => {
  let selectedServices = await JSON.parse(storeData.OrderList.selectedServices)
  if (selectedServices.length > 0) {
    let index = await selectedServices.indexOf(socketOrder.service.id) > -1
    return index 
  } else {
    return true
  }
}


const checkVehicle = async (socketOrder, storeData) => {
  if (socketOrder.status == "PickupDelivery") {
    return true
  } else {
    let selectedVehicleType = await JSON.parse(storeData.OrderList.selectedVehicleType)
    if (selectedVehicleType.length > 0) {
      let index = await selectedVehicleType.indexOf(socketOrder.vehicleTypeId) > -1
      return index
    } else {
      return true
    }
  }

}

const checkZone = async (socketOrder, storeData) => {
  let zones = await JSON.parse(storeData.OrderList.zones)
  if (zones.length > 0) {
    let index = await zones.indexOf(socketOrder.zoneId) > -1
    return index
  } else {
    return true
  }
}


const checkDispatchLater = async (socketOrder, storeData) => {

  if (socketOrder.isScheduled) {

    let startDate = await storeData.OrderList.DateRange?.startDate ? Number(momentJalaali(storeData.OrderList.DateRange.startDate).unix() + "000") : null
    let endDate = await storeData.OrderList.DateRange?.endDate ? Number(momentJalaali(storeData.OrderList.DateRange.endDate).unix() + "000") : null

    if (startDate && endDate) {

      let arr = [];

      if (!isEmpty(socketOrder.pickup)) {
        arr.push(socketOrder.pickup);
      }

      if (!isEmpty(socketOrder.dropoff)) {
        arr.push(socketOrder.dropoff);
      }

      if (!isEmpty(socketOrder.dropoffs)) {
        arr.concat(socketOrder.dropoffs);
      }

      arr.forEach(element => {
        if (Number(element.scheduleDateAfter) <= Number(endDate) && Number(element.scheduleDateAfter) >= Number(startDate)) {
          return true ;
        }
      });

      return false;

    } else {
      return true;
    }

  } else {
    return true;
  }

}