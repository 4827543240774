import { Component } from "react";
import { connect } from "react-redux";
import Delivery from "./delivery";


class App extends Component {

  render() {

      return <Delivery />

  }
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
