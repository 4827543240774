import HKerryExpressiO from "../../../assets/Solutions1000.png";
import FavIcon from "../../../assets/PROJECTS/Solutions1000_fav_icon.png";

export default function Solutions1000() {
    
    return  global.config = {
    "ProjectName": "1000 Solutions",
    "LogoType": HKerryExpressiO,
    "BaseApi": "https://rest.1000solutions.ae",
    "BaseSocket": "https://socket.1000solutions.ae",
    "BaseTrack": "https://track.1000solutions.ae",
    "MapCenter": {
        "lat": 23.849715915483884,
        "lng": 54.14074233520282
    },
    "Lang": "en",
    "Direction": "ltr",
    "AllLanguages": [{
        "lang": "en",
        "direction": "ltr",
        "i18Lang": "English"
    }],
    "FavIcon": FavIcon,
    "Country": "AE",
    "thirdPartyLoginSupport": true  ,
    mapType :"osm", /* osm | google */ 
    googleApiKey : ""
}}