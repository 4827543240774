import * as React from 'react'
import styles from './style.module.css';
import IconProvider from '../../../../../design-system/lib/IconProvider';
import { BUSINESS, CLOSE, PERSON } from '../../../../../assets';
import Typography from '../../../../../design-system/lib/Typography';
import Button from '../../../../../design-system/lib/Button';
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { NewCustomerHandleChange, SetRecentAddress, Set_DropOff_Inputs, Set_Passenger_Na_Fa_Ph, Set_Pickup_Inputs, Set_Pickup_address } from '../../../../../Redux/actions/actionNames';
import { useTranslation } from 'react-i18next';
import { Get_User_Recent_Address, Register_New_Customer } from '../../../../../Redux/actions/actions';
import { Get_Addressbook_list_Api } from '../../../../../helper/api';
import Input from '../../../../../design-system/lib/Input';
import DeliveryDriverNewOrder from '../delivery_driver';
import Schedule from '../../../Schedule/Schedule';




function DeliveryInfoNewOrder(props) {
  const addressDetail = props.addressDetail;
  const block = props.block;
  const floor = props.floor;
  const unit = props.unit;
  const email = props.email;
  const senderFullName = props.recieverFullName;
  const senderPhoneNumber = props.recieverPhoneNumber;
  const noteForDriver = props.noteForDriver;
  const ActivescheduleSetting = useSelector((state) => state.NewOrder.newOrder.service.activeVehicleType ? state.NewOrder.newOrder.service.activeVehicleType.scheduleSetting.isActive : false)
  const scheduleDateBefore = props.scheduleDateBefore;
  const scheduleDateAfter = props.scheduleDateAfter;
  const orderTypeActive = useSelector((state) => state.NewOrder.newOrder.orderTypeActive)
  const serviceactiveVehicleType = useSelector((state) => state.NewOrder.newOrder.service.activeVehicleType)

  const { t, i18n } = useTranslation();


  const dispatch = useDispatch();


  const closeDialoge = () => {
    props.handleShowInfo()
  };


  const Emailvalidate = (email) => {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  const handlePickUpInputs = React.useCallback((e, name) => {



    dispatch(
      Set_DropOff_Inputs({
        name: name,
        value: e.target.value ? e.target.value : "",
        firstDropOff: props.first,
        id: props.id,
      })
    );

  }, [props]);


  const phoneNumberOnChange = React.useCallback((res, id) => {

    dispatch(
      Set_DropOff_Inputs({
        name: id,
        value: res,
        firstDropOff: props.first,
        id: props.id,
      })
    );


  }, [props]);


  console.log("pro56ps15 ", props)

  return (
    <div className={styles.NewCustomerRegisterContainer}>
      <div className={styles.largeHeader}>
        <Button icon={CLOSE} size="Medium" type="Secondary" onClick={closeDialoge} />
        <div className={styles.largeHeaderTitles}>
          <Typography text={t("Dropoff")} weight="Body_Middle_Bold" className={styles.SmallTitle} style={{ margin: "0", padding: "0" }} />
        </div>
      </div>

      {/* {orderTypeActive == "PickupDelivery" && <div className={styles.body}>
        <DeliveryDriverNewOrder {...props} />
      </div>} */}
      <div className={styles.body}>
        <Typography weight='Body_Middle_Medium' text={t("Receiverinfo")} />

        <div className={styles.inputsConatiner}>
          {(serviceactiveVehicleType?.setting?.isPickupAddressRoomFloorBlockActive && orderTypeActive != "PickupDelivery") && <div className={styles.threeCOLUMNS}>
            <Input type={"text"} placeHolder={t("block")} value={block} inputStyle={{ width: "inherit" }} onChange={(e) => { handlePickUpInputs(e, "block") }} />
            <Input type={"text"} placeHolder={t("Floor")} value={floor} inputStyle={{ width: "inherit" }} onChange={(e) => { handlePickUpInputs(e, "floor") }} />
            <Input type={"text"} placeHolder={t("Unit")} value={unit} inputStyle={{ width: "inherit" }} onChange={(e) => { handlePickUpInputs(e, "unit") }} />
          </div>}

          {(serviceactiveVehicleType?.setting?.isAddressDetailsActive && orderTypeActive == "PickupDelivery") &&
            <Input type={"text"} placeHolder={t("addressDetail")} value={addressDetail} inputStyle={{ width: "inherit" }} onChange={(e) => { handlePickUpInputs(e, "addressDetail") }} />
          }


          {serviceactiveVehicleType?.setting?.isDropoffAddressNameActive && <Input isRequired={serviceactiveVehicleType?.setting?.isDropoffAddressNameRequired} isRequiredText={t("Required")} type={"text"} placeHolder={t("Fullname")} value={senderFullName} onChange={(e) => { handlePickUpInputs(e, "recieverFullName") }} />}


          {serviceactiveVehicleType?.setting?.isDropoffAddressPhoneActive && <div className={styles.PhoneInput}>
            <PhoneInput
              defaultCountry={global.config.Country}
              placeholder={t("Phonenumber")}
              value={senderPhoneNumber}
              onChange={(PrePhoneInput) => {
                phoneNumberOnChange(PrePhoneInput, "recieverPhoneNumber")
              }
              }
              className={clsx(styles.inputContainer, "senderphonenumberPickup")}
            />
            {serviceactiveVehicleType &&
              serviceactiveVehicleType.setting &&
              serviceactiveVehicleType.setting.isDropoffAddressPhoneRequired && String(senderPhoneNumber).length == 0 && (
                <span className={`inputRequired ${styles.PhoneInputRequired}`}>
                  {t("Required")}
                </span>
              )}
          </div>}

          {serviceactiveVehicleType?.setting?.isDropoffAddressEmailActive && <Input isRequired={serviceactiveVehicleType?.setting?.isDropoffAddressEmailRequired} error={Emailvalidate(email) ? "" : t("Emailisnotcorrect")} isRequiredText={t("Required")} type={"text"} value={email} placeHolder={t("email")} onChange={(e) => { handlePickUpInputs(e, "email") }} />}
          {orderTypeActive != "PickupDelivery" && <Input type={"text"} value={noteForDriver} placeHolder={t("notesfordriver")} onChange={(e) => { handlePickUpInputs(e, "noteForDriver") }} />
          }
        </div>

      </div>

      {ActivescheduleSetting && <div className={styles.body}>
        <Typography text={t("ScheduleDelivery(Optional)")} weight="Body_Middle_Bold" className={styles.SmallTitle} style={{ margin: "0", padding: "0" }} />

        <Schedule orderType={orderTypeActive}
          scheduleDateBefore={scheduleDateBefore}
          scheduleDateAfter={scheduleDateAfter}
          firstDropOff={props.first}
          id={props.id}

        />
      </div>}

    </div>
  )
}

export default DeliveryInfoNewOrder