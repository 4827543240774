import ReflexcourierIGO from "../../../assets/Reflexcourier.png";
import FavIcon from "../../../assets/PROJECTS/FavIcon_Reflexcourier.png";



export default function Reflexcourier() {
    
    return global.config = {
    "ProjectName": "Reflexcourier",
    "LogoType": ReflexcourierIGO,
    "BaseApi": "https://rest.reflexcourier.ca",
    "BaseSocket": "https://socket.reflexcourier.ca",
    "BaseTrack": "https://admin.reflexcourier.ca",
    "MapCenter": {
        "lat": 43.66263306012005,
        "lng": -79.3821779229343
    },
    "Lang": "en",
    "Direction": "ltr",
    "AllLanguages": [{
        "lang": "en",
        "direction": "ltr",
        "i18Lang": "English"
    }],
    "FavIcon": FavIcon,
    "Country": "CA",
    "thirdPartyLoginSupport": true  ,
    mapType :"osm", /* osm | google */ 
    googleApiKey : ""
}}