import React from "react";

const Delivery = ({ color = "#1080FF", style ,number ,numberStyle , bigSize}) => {

  return <div style={{width:"30px",height:"54px",position:"relative",...style}} >
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={54}
    fill="none"
  >
    <path
      fill={color}
      fillRule="evenodd"
      d="M6 .002a6 6 0 0 0-6 6v18a6 6 0 0 0 6 6h8v23a1 1 0 0 0 2 0v-23h8a6 6 0 0 0 6-6v-18a6 6 0 0 0-6-6H6Z"
      clipRule="evenodd"
    />
    <rect width={26} height={26} x={2} y={2} fill="#fff" rx={4} />
  </svg>
    <span style={{...numberStyle ,color:color}}>{number}</span>
  </div>

};

export default Delivery



