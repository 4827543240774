import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import customerProfile from "../../../../../assets/customerProfile.jpg";
import { getBaseTrackByUrl } from "../../../../../helper/module";
import {
  get_currencyCode,
  get_id,
  get_lang,
} from "../../../../../helper/UserData/userdate";
import defaultCustomer from "../../../../../assets/user_account_person_avatar_icon_131248.png";
import call from "../../../../../assets/ic-call.svg";
import { Voip_OutgoingCall } from "../../../../../Redux/actions/actions";

class App extends React.Component {

  constructor(props){
    super(props);

    this.makeOutgoingCall = this.makeOutgoingCall.bind(this)
  }

  makeOutgoingCall=()=>{
    
    this.props.dispatch(Voip_OutgoingCall(this.props.dispatch , {
      "to": this.props.customer
          ? this.props.customer.phone
          : this.props.business
          ? this.props.business.phone
          : "" ,
      "orderId": this.props.orderId,
      "customerId": this.props.customerId,
      "driverId": null ,
      "dispatcherId": get_id()
    }))

  }

  
  render() {
    const { t } = this.props;
    return (
      <customerInformation>
        {this.props.customer &&  (
          <balance>
            {" "}
            { `${t("Balance")}: `}
            <balanceB>
              {/* {this.props.customerType == "business" ? t("Business") : t("individual")} */}
              {
                `${
                  this.props.customer &&
                  Number(this.props.customer.balance).toLocaleString(
                    get_lang(),
                    {
                      style: "currency",
                      currency: get_currencyCode(),
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 2,
                    }
                  )
                }`}
            </balanceB>
          </balance>
        )}

        {this.props.customer && (
          <img
            src={`${
              this.props.customer &&
              this.props.customer.avatarPhoto &&
              this.props.customer.avatarPhoto.length !== 0
                ? this.props.customer.avatarPhoto
                : customerProfile
            }`}
          />
        )}
        <div className="vr_statusvehicleContainer">
        <name>
          {this.props.customer
            ? this.props.customer.name
            : this.props.business
            ? this.props.business.title
            : ""}
        </name>
        <number>
          {this.props.customer
            ? this.props.customer.phone
            : this.props.business
            ? this.props.business.phone
            : ""}
        </number>
        </div>
       {global.config.dispatcherSettings.isVoipActvie && <img src={call} onClick={this.makeOutgoingCall} className="viewOrderCustomerVoip" />}

   
      </customerInformation>
    );
  }
}

const mapStateToProps = (state) => ({
  customer: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.customer
      ? state.ViewTravel.pureData.customer
      : null
    : null,
  business: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.business
      ? state.ViewTravel.pureData.business
      : null
    : null,
  customerType: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.customerType
      ? state.ViewTravel.pureData.customerType
      : null
    : null,
  customerWallet: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.customer
      ? state.ViewTravel.pureData.customer
      : null
    : null,
  currencyCode: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.currencyCode
      ? state.ViewTravel.pureData.currencyCode
      : null
    : null,

    orderId: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.id
      ? state.ViewTravel.pureData.id
      : null
    : null,


    customerId: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.customerId
      ? state.ViewTravel.pureData.customerId
      : null
    : null,

});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
