import React, { useCallback, useRef, useState } from 'react'
import styles from './style.module.css';
import { useSelector } from 'react-redux';
import Button from '../../../design-system/lib/Button';
import { RouteApi } from '../../../service/API/route';
import { useParams } from "react-router-dom";
import { snack } from '../../../utils/SnackbarUtilsConfigurator';


function FooterReorder({ data , setData , setIsreorder}) {
  const [loading, setLoading] = useState(false);

  let { id } = useParams();


  const handleSave = React.useCallback(async() => {
    console.log("handleSave ",data)
    setLoading(true)
    let Route = new RouteApi()
    let datax = await Route.ReOrder({
      routeId:data.route.id,
      stops:data.stops.map((item)=> {return {
        "id": item.id,
        "position": item.position
      }})
    })



    if(datax && !datax.error){
      let dataxx = await Route.getRouteDetails(id)
      setData(dataxx)
      setIsreorder(false)
      setLoading(false)
    }else{

      if(datax.message){
        snack.error(datax.message)
      }
      setLoading(false)

    }
  }, [data , id]);


  return (  
    <div className={styles.Footer}>
        <Button size='Medium' type='Primary' text='Save' onClick={handleSave} isLoading={loading} />

    </div> 
  )
}


export default FooterReorder