import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import { CanEditEarning, CanEditPrice, CanEditPriceEarning, CanEditTravel } from "../../../../../../../helper/module";
import { Edit_Travel_Operate_Changed } from "../../../../../../../Redux/actions/actionNames";
import price from "../../../../../../../assets/ic_dollar.svg";
import cancell from "../../../../../../../assets/ic-close.svg";
import Dialog from "@material-ui/core/Dialog";
import {
  get_currencyCode,
  get_lang,
} from "../../../../../../../helper/UserData/userdate";
import Price from "./price";
import Earning from "./earning";
import Modal from "../../../../../../../design-system/lib/Modal";
import styles from './style.module.css';
import Switch from "../../../../../../../design-system/lib/Switch";
import clsx from "clsx";
import Typography from "../../../../../../../design-system/lib/Typography";
import Input from "../../../../../../../design-system/lib/Input";
import { CLOSE, COD, TICKET_DISCOUNT } from "../../../../../../../assets";
import { LearnMore } from "../../../../../../../components/learnMore";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fee: "0",
      surcharges: [],
      priceChange: false,
      active: 1,
    };
  }

  handleTab = (num) => {
    this.setState({
      active: num,
    });
  };

  EditpriceOnChange = () => {
    this.props.priceChange && this.props.priceChange(false)
    if (this.state.priceChange) {
      this.setState({
        priceChange: !this.state.priceChange,
        surcharges: [],
        fee: "0",
      });
    } else {
      this.setState({
        priceChange: !this.state.priceChange,
        surcharges: this.props.surcharges,
        fee: this.props.baseFarePrice,
      });
    }
  };

  closeDialoge = () => {
    this.props.priceChange && this.props.priceChange(false)
    this.setState({
      priceChange: false,
      surcharges: [],
      fee: "0",
    });
    this.props.func()
  }

  feeOnchange = (e) => {
    this.props.dispatch(Edit_Travel_Operate_Changed());

    this.setState({
      fee: e.target.value,
    });
  };


  render() {
    const { t } = this.props;
    let canShowEarning = CanEditEarning(this.props.Data.type, this.props.Data.status)
    let canShowPrice = CanEditPrice(this.props.Data.status) && !this.props.Data.isPickupDelivery
    
    return (
      <React.Fragment>
      
        {
          !this.props.noC && <span onClick={this.EditpriceOnChange}>
           {this.props.children}
          </span>
        }


        {/*(this.state.priceChange || this.props.open || true) && <Modal size='Large' title={t("EditPrice")} onclose={() => { }} ConfirmationOnClick={() => { }}
          ConfirmationText='Save'
          body={<div className={styles.bodyContainer}>
            <div className={styles.bodyHeaderContainer}>
              <div className={clsx(styles.bodyHeaderBtnContainer, styles.bodyHeaderBtnContainerActive)}>
                <span>{t("Price")}</span>
                <div className={styles.bodyHeaderBtnBLineContainer}></div>
              </div>
              <div className={styles.bodyHeaderBtnContainer}>
                <span>{t("Earning")}</span>
                <div className={styles.bodyHeaderBtnBLineContainer}></div>
              </div>
            </div>

            <div className={styles.bodyInContainer}>
          <div className={styles.bodyInSurchargeContainer}>
                <div className={styles.bodyInSurchargeItemContainer}>
                  <Typography weight="Body_Small_Regular" text={"Fee"} textColor="TextNormal" />
                  <Input type={"number"} value={"54"} disabled  />
                </div>
                <div className={styles.bodyInSurchargeItemContainer}>
                  <Typography weight="Body_Small_Regular" text={"Surcharge"} textColor="TextNormal" />
                  <Input type={"number"} value={"54"} disabled  />
                </div>
                <div className={styles.bodyInSurchargeItemContainer}>
                  <Typography weight="Body_Small_Regular" text={"Surcharge"} textColor="TextNormal" />
                  <Input type={"number"} value={"54"}   />
                </div>
              </div> 

              <div  className={styles.hr} />
              <div className={styles.bodyInSurchargeContainer}>
                <div className={styles.bodyInSurchargeItemContainer}>
                <Typography weight="Body_Small_Regular" text={t("COD")} textColor="TextNormal" />
                <Input type={"number"} value={"54"} iconStart={COD} IconStyle={{width:"16px",height:"16px"}} />
                </div>
                <div className={styles.bodyInSurchargeItemContainer}>
                  <Typography weight="Body_Small_Regular" text={t("Promotion")} textColor="TextNormal" />
                  <Input type={"string"} value={"54"} iconStart={TICKET_DISCOUNT} IconStyle={{width:"16px",height:"16px"}} />
                  </div>
              </div>*
            </div>


          </div>}
          bodyStyle={{ backgroundColor: "transparent", width: "100%", padding: "0px" }}
          ConfirmationLoading={false}
          ConfirmationDisabled={false}
          
        />*/}

 <Dialog
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          open={this.state.priceChange || this.props.open}
          className="EditpriceOnChange"
          disableAutoFocus
          disableEnforceFocus 
          disableRestoreFocus 
          style={{borderRadius:"8px"}}
        >
          <div
            className="EditPriceContainer"
            style={{ height: this.props.noC ? "312px" : "auto", width: "520px", overflow: "hidden",backgroundColor:"#F4F4F7" ,border:"unset"}}
          >
            <div className="header" style={{height:"53px",backgroundColor:"white"}}>
              <img
                className="close"
                src={CLOSE}
                onClick={this.closeDialoge}
                style={{ width:"16px",height:"16px",borderRadius:"8px",padding:"8px",marginTop:"10px",marginInlineStart:"16px"}}
              />
              <span className="title" style={{width:"auto",marginInlineStart:"12px",marginTop:"18px"}}>                     
                  {(CanEditEarning(this.props.Data.type, this.props.Data.status) && (CanEditPrice(this.props.Data.status) && !this.props.Data.isPickupDelivery)) ? this.props.t("EditPrice") : ((CanEditPrice(this.props.Data.status) && !this.props.Data.isPickupDelivery) ? this.props.t("EditPrice2") : CanEditEarning(this.props.Data.type, this.props.Data.status) ? this.props.t("EditEarning") : this.props.t("Pricedetails"))}
              </span>

              {(CanEditEarning(this.props.Data.type, this.props.Data.status) || (CanEditPrice(this.props.Data.status) )) && <div style={{float:"right",height:"54px",width:"auto",display:"flex",flexDirection:"row",justifyContent:"center",alignItems:"center",marginInlineEnd:"16px"}}><LearnMore where={`${this.props.Data.type}EditPrice`}  hastext={false} placement={"bottomStart"}  /></div> }
            </div>


            <div
              className="content"
              style={{
                paddingBottom: "0px",
                height: "auto",
                overflowY: "hidden",
                position: "relative",
                backgroundColor:"#F4F4F7" ,
                marginTop:"6px",
                paddingTop:"0px"
              }}
            >
              {(canShowEarning && canShowPrice) && <div className="switchDriverInfo switchPriceInfo" style={{ borderBottom: "1px solid rgba(128, 128, 128, 0.2)" ,backgroundColor:"white",paddingTop:"10px",height:"52px",padding:"0px"}}>
                {canShowPrice && <span
                  className={`tab ${this.state.active === 1 && "tabActive"}`}
                  style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",height:"48px",fontSize:"14px",transition:"unset"}}
                  onClick={() => {
                    this.handleTab(1);
                  }}
                >
                  {" "}
                  <Trans i18nKey={"Price"}> </Trans>
                </span>}
                {canShowEarning && <span
                  className={`tab ${this.state.active === 2 && "tabActive"}`}
                  style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",height:"48px",fontSize:"14px",transition:"unset"}}
                  onClick={() => {
                    this.handleTab(2);
                  }}
                >
                  {" "}
                  <Trans i18nKey={"Earning"}> </Trans>{" "}
                </span>}
              </div>}

              {(canShowEarning && canShowPrice) ? (this.state.active === 1 ? <Price noC={this.props.noC} Data={this.props.Data} close={this.closeDialoge} /> : <Earning noC={this.props.noC} close={this.closeDialoge} />) : (canShowEarning ? <Earning noC={this.props.noC}  close={this.closeDialoge} /> : <Price noC={this.props.noC} Data={this.props.Data} close={this.closeDialoge}/>)}
            </div>
          </div>
        </Dialog>
       
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  Data: state.ViewTravel.pureData ? state.ViewTravel.pureData : false,
  baseFarePrice: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.baseFarePrice
    : 0,
  surcharges: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.surcharges
    : [],
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
