import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Header from "./components/header";
import OrderStatus from "./components/orderStatus";
import CustomerInformation from "./components/customerInformation";
import Service from "./components/service";
import JustVehicleType from "./components/justVehicleType";
import DriverInformation from "./components/driverInformation";
// import History_TimeLine from "./components/history_TimeLine";
import Addresses from "./components/addresses";
import Footer from "./components/footer";
import ExtraDetails from "./components/extraDetails";
import SwipeableViews from 'react-swipeable-views';
import loading from "../../../../assets/loading.gif";
import reload from "../../../../assets/reload.png";
import Pickup_P_and_D_details from "./components/pickup_P_and_D_details";
import Dropoff_P_and_D_details from "./components/dropoff_P_and_D_details";
import Event_TimeLine from "./components/event_TimeLine";

import { withTranslation, Trans } from "react-i18next";
import Slide from "@material-ui/core/Slide";
import { OrderInfoAction, OrderGetEventsAction } from "../../../../Redux/actions/actions";
import { get_id } from "../../../../helper/UserData/userdate";
import { DownloadReactState } from "../../../../utils/downloadReactState";
import Hotkeys from 'react-hot-keys';

class App extends React.Component {

  retryOrderInfo = () => {
    this.props.dispatch(
      OrderInfoAction(this.props.dispatch, {
        orderId: this.props.id
      })
    );
  };

  downloadReactState = () => {
    DownloadReactState()
  };



  render() {
    return (

      <Slide
        direction="down"
        in={this.props.OpenDialoge}
        mountOnEnter
        unmountOnExit
      >
        <div className="NewOrder">
          <Hotkeys

            keyName="ctrl+e,control+e"
            onKeyUp={this.downloadReactState}
          >

            <Header />
            {!this.props.loading && !this.props.error && (
              <div
                style={{
                  width: "100%",
                  height: "calc(100% - 112px)",
                  float: "left",
                  marginTop: "56px",
                  overflowY: "none",
                  borderTop: "1px solid #eeeeee",
                  backgroundColor: "#fbfdff",
                }}
              >

                <vRItemsContainer
                  value={0}
                  index={0}
                  dir={String(this.props.direction).toLowerCase()}
                >
                  <OrderStatus />
                  <CustomerInformation />
                  <Service />
                  <JustVehicleType />
                  <DriverInformation />
                  <Addresses />

                  {["Delivery", "PickupDelivery"].indexOf(this.props.orderType) > -1 &&
                    (this.props.isPickupDelivery || ["PickupDelivery"].indexOf(this.props.orderType) > -1) && <div className="noboxshadow"><Pickup_P_and_D_details /></div>}
                  {["Pickup", "PickupDelivery"].indexOf(this.props.orderType) > -1 &&
                    (this.props.isPickupDelivery || ["PickupDelivery"].indexOf(this.props.orderType) > -1) && <div className="noboxshadow">
                      <Dropoff_P_and_D_details /></div>}

                  {this.props.pureData && <ExtraDetails />}

                </vRItemsContainer>

                {/* <React.Fragment
                  value={1}
                  index={1}
                  dir={String(this.props.direction).toLowerCase()}
                > */}
                {/* <historyContainer>
                    <History_TimeLine />
                  </historyContainer> */}
                {/* </React.Fragment> */}
              </div>
            )}

            {!this.props.loading && !this.props.error && <Footer />}

            {this.props.loading && (
              <React.Fragment>
                <img src={loading} className="gifLoading" />
                <span className="loading">
                  <Trans i18nKey={"fetchingdata"}> </Trans>
                </span>
              </React.Fragment>
            )}

            {!this.props.loading && this.props.error && (
              <React.Fragment>
                <img
                  onClick={this.retryOrderInfo}
                  src={reload}
                  className="errorrELOAD"
                />
                <span className="loading">
                  <Trans i18nKey={"orderInfoError"}> </Trans>
                </span>
              </React.Fragment>
            )}
            <Event_TimeLine />
          </Hotkeys>
        </div>

      </Slide>
    );
  }
}

const mapStateToProps = (state) => ({
  customerId: state.ViewTravel.customerId,
  id: state.ViewTravel.ID,
  OpenDialoge: state.ViewTravel.OpenDialoge,
  loading: state.ViewTravel.loading,
  error: state.ViewTravel.error,
  history: state.ViewTravel.history,
  direction: state.LocalData.direction,
  orderType: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.type
      ? state.ViewTravel.pureData.type
      : null
    : null,
  isPickupDelivery: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.id
      ? state.ViewTravel.pureData.isPickupDelivery
      : null
    : null,
  pureData: state.ViewTravel.pureData
    ? state.ViewTravel.pureData
    : null,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
