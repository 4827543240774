import AurelPackLogo from "../../../assets/AurelPacklogotype.png";
import FavIcon from "../../../assets/PROJECTS/AurelPack.png";

export default function AurelPack() {
    
    return  global.config = {
    "ProjectName": "AurelPack",
    "LogoType": AurelPackLogo,
    "BaseApi": "https://rest.aurelapp.com",
    "BaseSocket": "https://socket.aurelapp.com",
    "BaseTrack": "https://tr.aurelapp.com",
    "MapCenter": {
        "lat": "18.888906899683207",
        "lng": "-70.1641798985629"
    },
    "Lang": "es",
    "Direction": "ltr",
    "AllLanguages": [ {
        "lang": "es",
        "direction": "ltr",
        "i18Lang": "spanish",
    },{
        "lang": "en",
        "direction": "ltr",
        "i18Lang": "English"
    },{
        "lang": "fr",
        "direction": "ltr",
        "i18Lang": "French"
    },{
        "lang": "pt",
        "direction": "Ltr",
        "i18Lang": "Portuguese"
    }],
    "FavIcon": FavIcon,
    "Country": "DO",
    "thirdPartyLoginSupport": true  ,
    mapType :"osm", /* osm | google */ 
    googleApiKey : ""
}}