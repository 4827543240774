import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import box from "../../../../../../assets/box-copy.svg";
import { withStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import { withTranslation, Trans } from "react-i18next";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import Grow from "@material-ui/core/Grow";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { Remove_Service_Option, Add_Service_Option } from "../../../../../../Redux/actions/actionNames";
import { get_lang } from "../../../../../../helper/UserData/userdate";

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showpoper: false,
      active:false,
      selected:null
    };
    this.anchorEl = React.createRef();
  }

  handleactive = (e) => {
    if (this.optionisSaved() && String(e.target.value).trim().length == 0) {
      this.props.dispatch(Remove_Service_Option(this.props.id))
    } else {
      this.props.dispatch(Add_Service_Option({
        id:this.props.id,
        inputValue:e.target.value,
        type:this.props.type,
        update:this.optionisSaved()
      }))
    }

  };


  optionisSaved() {
    let booll = false;
     booll = this.props.options.find((item) => {
      return item.id === this.props.id;
    });
    if(booll){
      return true
    }else{
      return false
    }
  }

  render() {
    const { t } = this.props;
    return (
        <div className="serviceOptionInputTypeContainer" style={{margin:"0px"}}>
           <p>{`:${this.props.title}`} </p>
          <input placeholder={`${this.props.hint}`} type={this.props.inputType} onChange={this.handleactive} />
          {(this.props.isRequired ? (!this.optionisSaved()) : false) && <span>{this.props.t("Required")}</span>}
        </div>
    );
  }
}

const mapStateToProps = (state) => ({
  options:state.NewOrder.newOrder.service.options,
  lang :state.LocalData.lang

});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
