export const FrenchLabel = {
    Addresses: "Adresses",
    AllKey: "Tout",
    Arrived: "En cours",
    ascending: "Ascendant",
    Assigned: "Assigné",
    Auto: "Automatique",
    Business: "Professionnel",
    Businessname: "Nom de l'entreprise",
    Cancel: "Annuler",
    CancellText: "Après l'annulation, la demande est annulée pour le livreur.",
    Canceltravel: "Annuler la demande",
    Cash: "Cash",
    CustomerCanceled: "Annulé",
    Close: "Fermer",
    Commission: "Commission",
    ContactFullname: "Nom complet du contact",
    Contactphonenumber: "Téléphone du contact",
    Copylink: "Copier le lien",
    Createdsuccessfully: "Créé avec succès",
    Balance: "Crédit",
    Deliveredat: "Livré à",
    descending: "Descendant",
    Details: "Détails",
    Done: "Finalisé",
    DriverCanceled: "Annulé",
    Drivers: "Livreurs",
    DropOff: "Dépôt",
    Edit: "Modifier",
    EditPrice: "Prix et gain",
    EditPrice2: "Prix",
    EditEarning: "Revenus",
    EditOrder: "Modifier",
    Editaddress: "Modifier l'adresse",
    End: "Annuler la demande",
    EndDate: "Date de fin",
    EndlText: "La demande du customer et du livreur est alors traitée avec succès et le montant est déduit du compte du customer.",
    Endtravel: "complète",
    Endtrip: "Fin de la demande",
    English: "Anglais",
    Enter_the_following_information_to_enter: "Entrez les informations suivantes pour entrer",
    Espath: "Es. path",
    Estimated: "Estimé",
    Estime: "Estimation de temps",
    Fee: "Frais",
    Firstname: "Prénom",
    Floor: "Etage",
    Fname: "Vore prénom",
    Fornow: "Immédiat",
    now:"À présent",
    Haveorder: "Faire une demande",
    History: "Historique",
    Info: "Info",
    Language: "Langue",
    Last7Days: "7 derniers jours",
    LastMonth: "Le mois dernier",
    Last3Month:"3 mois dernier",
    LastWeek: "Semaine dernière",
    Lastname: "Nom de famille",
    Lname: "Votre nom",
    Logout: "Déconnecter",
    Managefavoriteaddresses: "Gestion des adresses",
    Manual: "Manuelle",
    MeKey: "Moi",
    Neworder: "Demande",
    Noaddressavailable: "Aucune adresse disponible",
    Noinformationfound: "Aucune information trouvée",
    Nonotes: "Aucune notes",
    Nophoto: "Aucune Photo",
    Nosignature: "Aucune signature",
    Notes: "Notes",
    Notloggedin: "ne s'est pas connecté",
    Offline: "Hors ligne",
    Online: "En ligne",
    Service: "service",
    Passenger: "Customer",
    Pending: "En entente",
    Persian: "Persian",
    French: "French",
    Person: "Particulier",
    Phone: "Téléphone",
    Phonenumber: "Téléphone",
    Photo: "Photo",
    PickedUp: "Ramassé",
    Pickup: "Pick-Up",
    Pleasetryanotherkeyword: "\r\nVeuillez essayer un autre mot-clé",
    Pnumber: "Numéro de téléphone",
    Price: "Prix",
    Pricedetails: "Détails des prix",
    Promocode: "Code promo",
    Receipt: "Reçu",
    Receiver: "Par le destinataire",
    ReceiverCash: "Payer par récepteur ",
    Orders: "Requête",
    Saveaddress: "Enregistrer l'adresse",
    Saved: "Enregistré",
    Savedaddresses: "Adresses enregistrées",
    Scheduled: "Programmé",
    Search: "Rechercher",
    SelectDateRange: "Plage de dates",
    SendOrder: "Envoyer ",
    Sender: "Par l'expéditeur",
    SenderCash: "Cash (par l'expéditeur)",
    SenderWallet: "Portefeuille",
    Vehicletype: "Type de véhicule",
    Settime: "Définir l'heure",
    Signature: "Signature",
    StartDate: "Date de début",
    Started: "Démarré",
    SupportCanceled: "Annulé",
    ThisMonth: "Ce mois",
    ThisWeek: "Cette semaine",
    Title: "Titre",
    Today: "Aujourd'hui",
    Unassigned: "Non assigné",
    Unit: "Unité",
    Updatedsuccessfully: "Mis à jour avec succès",
    Username: "Nom d'utilisateur",
    Wallet: "Crédit",
    Yesterday: "Hier",
    address: "Adresse",
    after: "après :",
    before: "avant :",
    clear: "Transparent",
    customer: "customer",
    confirm: "Confirmer",
    confirmCancel: "Ok",
    confirmEnd: "Oui",
    confirmLogout: "Oui, déconnecter",
    dispatcher: "Dispatcher",
    driver: "Livreur",
    email: "Adresse mail",
    en: "Anglais",
    fa: "Persian",
    fr: "français",
    fetchingdata: "Recpetion de l'information...",
    filter: "Filtrer",
    haslogined: "s'est connecté",
    km: "km",
    lastonlinetime: "Dernière heure de connexion",
    login: "Identifiants",
    loginstatus: "Statut de connexion",
    logotTitle: "Se déconnecter du compte ?",
    logoutDescription: "Vous serez déconnecté de votre compte et vous devrez vous connecter ultérieurement.",
    monitoring: "Surveillance",
    new: "Nouvelle",
    noVehicleType: "Il n'y a pas de type de service à afficher",
    notesfordriver: "Notes pour le livreur",
    otherdropoffs: "autres dépôts",
    password: "Mot de passe",
    paymentmethod: "Méthode de paiement",
    phonenumber: "Téléphone",
    pickupDescription: "Description du ramassage",
    Pickupnote:"Note de ramassage",
    pleaseSellectParsel: "Veuillez sélectionner le type de colis",
    pod: "Preuve de livraison",
    poperLoading: "Patientez svp",
    regexcustomerName: "Nom customer",
    regexcustomerPhone: "Téléphone customer",
    regexcode: "code",
    regexdriverName: "Nom livreur",
    regexdriverPhone: "Téléphone livreur",
    regexdriverUsername: "Nom d'utilisateur livreur",
    repeatOrder: "Répéter la demande",
    Shipmentlabel:"Étiquette d'expédition",
    orderInfoError: "Erreur de réception des informations",
    vehicletypes: "Types de services",
    skipLogout: "Annuler",
    "sort[createdAt]": "Date",
    "sort[pickupScheduleDate]": "Horaire de ramassage",
    "sort[statusUpdatedAt]": "Trier par mise à jour à",
    status: "État",
    statusArrived: "Arrivé",
    statusAssigned: "Assigné",
    statusCanceled: "Annulé",
    statusCustomerCanceled: "Annulé par le customer",
    statusDone: "Finalisé",
    statusDriverCanceled: "Annulé par le livreur",
    statusInProgress: "État en cours",
    statusPending: "En entente",
    "statusPickedUp": "Ramassé",
    statusStarted: "Démarré",
    statusSupportCanceled: "Annulé par le support",
    statusUnassigned: "Non Assigné",
    surcharge: "Surcharge",
    Adddropoff: "Ajouter",
    AllServices: "Tous les colis",
    AllVehicletypes: "Tous les types de services",
    "Block/Building": "Bloc / bâtiment",
    code: "code",
    Editorder: "Modifier",
    Pickedupat: "Ramassé à ",
    Order: "Demander",
    all: "Toute",
    for: "Pour  ",
    noorder: "pas de requête",
    rememberme: "souviens-toi de moi",
    Address: "Adresse",
    Code: "code",
    business: "Professionnel",
    username: "Nom d'utilisateur",
    enterDataCorrectly: "Entrez les informations correctement.",
    controlThatbeforeLoweEqualAfter: `Le temps « Avant : » ne peut pas être inférieur à  « Après : »`,
    Schedule: "Programmé",
    Date: "Date",
    Time: "Temps",
    noDateToShow: "Il n'y a pas de Date à afficher",
    noTimeToShow: "Il n'y a pas de temps à afficher",
    Apply: "Appliquer",



    lowBalance: "Solde Faible",
    balanceError: "l'équilibre doit être au moins égal au prix de commande",
    Methods: "Méthodes",



    Addressbook: "Carnet d'adresses",
    AddressbookSearchbar: "Recherche par adresse, titre et identifiant",
    AddressbookDropOf: "Carnet d'adresses (déposer)",
    AddressbookPickup: "Carnet d'adresses (ramassage)",
    hide: "Cacher",
    Nosavedaddress: "Aucune adresse enregistrée",


    ForceAssign: "Forcer assignant",
    Allvehicletypes: "Tous les types de véhicules",

    Dispatching: "Dispatching",
    NoDriver: "No Driver",
    waitforconfirm: "xxx (attendez pour confirmer)",
    waitforconfirm2: "Attendez confirmer",

    Dispatching: "Expéditrice",
    NoDriver: "Sans chauffeur",
    OtherDetails: "Autres détails",
    StatusandDriver: "Statut & conducteur",
    EditStatusandDriver: "Edit Statut et pilote",

    Current: "Courante",
    EDAS_Pending: "En attente ",
    EDAS_Assigned: "Attribué",
    EDAS_PickedUp: "Ramassé",
    EDAS_Unassigned: "Non attribué",
    EDAS_Started: "Commencé",
    EDAS_Done: "Terminé",
    EDAS_CustomerCanceled: "Client annulé",
    EDAS_DriverCanceled: "Pilote annulé",
    EDAS_SupportCanceled: "Support annulé",
    EDAS_Arrived: "Arrivée",


    Pleasechooseadrivertochangestatus: "Veuillez choisir un pilote pour changer d'état",
    icWarningEdit: "Les options changeantes peuvent effets sur le prix",

    Vehicletypewillbechanged: "Type de véhicule sera changé",
    Yourcurrentvehicletypeanditwillbechangedandeffectsonprice: "Votre type de véhicule actuel sera changé et effets sur le prix",
    Rejected: "Rejetée",
    Rejected2: "(Rejetée)",

    DriverVehicleTypeError: "Il n'y a pas de type de véhicule pour le conducteur",
    CodeNameFamilyPhonenumber: "Code, nom, famille, numéro de téléphone",
    youcanselectdriverintheedit: "Vous pouvez sélectionner le pilote dans le pilote d'édition et le statut",

    Romanian: "Roumaine",


    Earning: "Revenus",
    savePrice: "Payer le prix",
    saveEarning: "Enregistrer la gagnant",
    Total: "Le total",
    Pricedetails: "Détails du prix",
    "EditPickupDropOff": "Edit Addresses",

    noMoreData: "Il n'y a plus d'informations",
    Dutch:"néerlandaise",
    Ondemand: "On Demand",
    PD: "P & D",
    PD2: "P&D",
    PickupDropoff: "Pickup , Dropoff",
    PickupFlag: "P",
    DropOffFlag: "D",
    PickupandDelivery: "Pickup and Delivery",
    PickupDelivery: "Pickup and Delivery",
    Delivery: "Delivery",
    ServiceOptions: "Service Options",
    Searchbynameorphonenumber: "Search by name or phone number",
    Fullname: "Nom et prénom",
    type: "taper",
    only: "xxx seule",
    ChooseDriver: "Choisir le pilote",
    Events: "Événements",
    Collecting: "Collecting",
    Transit: "transit",
    Delivering: "Délivrer",
    EDAS_Collecting: "Collecting",
    EDAS_Transit: "transit",
    EDAS_Delivering: "Délivrer",
    statusCollecting: "Collecting",
    statusTransit: "transit",
    statusDelivering: "Délivrer",
    status2: "statut",
    Creator: "Créatrice",
    Creationdate: "Date de création",
    Orderid: "Numéro de commande",
    Pickup2: "Ramasser",
    Dropoff2: "Déposer",
    senderfullname: "Nom de l'expéditeur",
    block: "Bloc",
    senderphonenumber: "Numéro de l'expéditeur",
    Recieverfullname: "Nom du récepteur",
    Recieverphonenumber: "Numéro de récepteur",
    PleaseSelect: "Veuillez sélectionner",
    CreateEvent: "Créer un évènement",
    Description: "La description",
    detailsAboutEvent: "Détails sur l'événement",
    CustomerVisibility: "Visibilité du client",
    LocationOnMap: "Emplacement sur la carte",


    event_Created:"Établie",
    event_Unassigned:"Non attribué",
    event_Assigned:"Attribué",
    event_Started:"A débuté",
    event_Arrived:"Arrivée",
    event_PickedUp:"Ramassée",
    event_OrderDone:"Commandement",
    event_DropoffDone:"Densité",
    event_CustomerFeedback:"Commentaires des clients",
    event_AddPod:"Ajouter de la pod",
    event_CustomerCanceled:"Client annulé",
    event_DriverCanceled:"Pilote annulé",
    event_SupportCanceled:"Support annulé",
    event_Updated:"Mis à jour",
    event_DriverAssignment:"Affectation du conducteur",
    event_DriverReject:"driverReject",
    event_Collecting:"Collecte",
    event_InTransit:"En transit",
    event_Delivering:"Délivrer",
    event:"Événement" ,

    tab_Delivery:"Livraison",
    tab_Ondemand:"Sur demande",
    tab_Pickup:"Ramasser",
    tab_PickupDelivery :"P & D" ,
    CurrentStatus:"Statut actuel",
    Changestatus:"Changement de statut",
    Newstatus:"Nouveau statut",
    Savechanges:"Sauvegarder les modifications",
    Other:"Autre" ,
    serviceCustomerError1 :"xxx n'est pas disponible pour ce client." ,
    serviceCustomerError2 :"Il / elle ne peut choisir que xxx service",
    serviceCustomerError3 :" Il / elle ne peut choisir que xxx ",
    OrderCode:"Code de commande" ,


    CopyOrderlink:"Copier le lien de commande",
    CopyPickuplink:"Copier le lien de ramassage" ,
    CopyDeliverylink:"Copier le lien de livraison" ,
    Bulkimport:"Import en vrac",
    Downloadsamplecsv:"Télécharger échantillon CSV",
    processing:"Traitement" ,
    done :"Achevée" ,
    failed:"Échoué",
    Draft:"Brouillon" ,
    Uploading:"Téléchargement" ,
    Draganddropyourfile:"Faites glisser votre fichier",
    Onlycsvformatissupported:"Seul le format .csv est pris en charge" ,
    ORyoucan:"Ou tu peux",
    Openfile:"Fichier ouvert" ,
    Noimportsyet:"Aucune importation encore" ,
    NoimportsyetDesc :"Vous pouvez voir la liste de vos importations ici et vérifier leur statut",
    nosupportdialogeTitle:"Format de fichier non pris en charge",
    nosupportdialogeText : "Le fichier XXX n'est pas pris en charge.Vous ne pouvez télécharger que le fichier .csv, y compris les informations de commande" ,
    ok:"d'accord" ,
    Import  :"Importer",
    previewTitle :"Aperçu et choisissez Options",
    previewDesc:"Ceci est le premier élément de vos fichiers à vérifier que vos informations sont dans la structure de droite" ,
    Upload:"Télécharger", 
    Remove:"Retirer" ,
    ServiceandOrdertype:"Type de service et de commande" ,
    Fileisproccessing:"Le fichier est en train de traitement ...",
    "proccessingDesc":"Vous pouvez vous rafraîchir pour voir si le résultat est prêt",
    serverError:"erreur du serveur",
    isRequired:"xxx est requis" ,
    Result:"Résultat",
    ResultText:"Vous pouvez voir les problèmes ici et télécharger ces lignes pour fixer séparément",
    ErrorDetails:"Commandes avec erreur",
    ErrorDetailsText:"Les commandes avec les erreurs sont répertoriées dans le fichier que vous pouvez télécharger ci-dessous.Il contient des détails d'erreur pour chaque ligne.",
    DownloadErroredRows:"Télécharger des lignes erronées" ,
    importedsuccessfully:"Les commandes xxx sont importées avec succès" ,
    ordershaserrors:"Les commandes xxx ont des erreurs",
    refresh:"Rafraîchir",
    CashOnDelivery:"Paiement à la livraison",
    EnterAmount:"Entrer le montant",
    COD:"Paiement à la livraison",
    Surchargexxx:"Supplément xxx",
    AddCreditCard:"Ajouter une carte de crédit / débit",
    CustomerWallet:"Portefeuille client",
    Add:"Ajouter",
    Addedsuccessfully: "Ajouté avec succès",
    NoRecentAddress:"Aucune adresse récente",
    NoRecentAddressD:"Les adresses récentes du client seront répertoriées ici",
    CustomerIsNotChoosed:"Le client n'est pas choisi",
    CustomerIsNotChoosedD:"Choisissez le client pour montrer son adresse récente",
    SearchAddress:"Adresse de recherche",
    NoCustomerfound: "Aucun client trouvé" ,
    NoCustomerfoundD:"Vous pouvez créer le client s'il en est une nouvelle",
    NewCustomer : "Nouveau client",
    Existing:"Existante",
    EmailOtional:"Email (facultatif)" ,
    phonenumberisnotcorrect:"Le numéro de téléphone est incorrect",
    Emailisnotcorrect:"Le courrier électronique n'est pas correct" ,
    Registercustomer:"Enregistrer le client",
    incoming:"Appel entrant",
    outgoing:"outgoing Call",
    recording:"appel d'enregistrement",
    driveroperatorCallStatus:"Conducteur appelé opérateur",
    customeroperatorCallStatus:"Client appelé opérateur",
    newCustomeroperatorCallStatus:"Nouveau client appelé opérateur",
    operatordriverCallStatus:"Opérateur appelé pilote",
    operatorcustomerCallStatus:"Opérateur appelé client",
    operatornewCustomerCallStatus:"Opérateur appelé nouveau client",
    dispatcher_Voip:"Opératrice",
    driver_Voip:"Conductrice",
    customer_Voip:"Cliente",
    activeorders:"xxx commandes actives",
    Noactiveorder :"Aucune commande active " ,
    CreateOrder:"Créer une commande",
    call:"Appel",
    spanish:"espagnole",
    es : "espagnole",
    networkError:"Pas de connexion Internet",
    createdAt:"Créé à",
    dueDate:"Date d'échéance",
    Noresultfound:"Aucun résultat trouvé",
    loadMore:"Charger plus",
    Searchresult:"Résultat de la recherche",
    "PinTop":"Plaque d'orage",
    "UnPin":"unPin",
    "Itemsonmap":"Éléments sur carte",
    "Orders":"Ordres",
    "Drivers":"Conductrices",
    "min":"xxx Min" ,
    Confirming:"Confirmant",
    delay:"Retard",
    late:"En retard",
    OrderDetails:"Détails de la commande",
    Profile:"Profil",
    Waitingforavailabledrivers:"En attente de conducteurs disponibles",
    Queue:"File d'attente",
    OfferingToDrivers:"Offrant aux pilotes xxx",
    WaitforAccept:"Attendez d'accepter",



    pleaseSellectService: 'Veuillez sélectionner le type de service',
    SendOrderr: 'Envoyer lordre',
    todayat: 'Aujourd hui à',
    tomorrowat: 'Demain à',
    nextWeekat: 'La semaine prochaine à',
    lastDayat: 'Dernier jour à',
    lastWeekat: 'La semaine dernière à',
    "AssignedAt":"Attribuer en xxx min",
    "StartedAt":"Commencer par xxx min",
    "ArrivedAt":"Arriver en xxx min",
    "PickedUpAt":"Ramassage en xxx min",
    "DoneAt":"Terminer en xxx min",
    mindelay: 'Délai xxxm',
    minlate: 'xxxm tardif',
    Thereisnoorder:"Il n'y a pas d'ordre",
    mapitems:"Carte",
    Offerings:"Offrandes",
    Reject:"Rejeter",
    dropoffCustomerDescription:"Note de dépôt",
    customerPhoto:"Photo client" ,
    driverPhoto:"Photo de conducteur" ,
    NocustomerPhoto:"Pas de photo client" ,
    NodriverPhoto:"Pas de photo de pilote",
    initial:"initiale",
    notifying:"avis",
    received:"reçue",
    assigned:"attribué",
    rejected:"rejetée",
    canceled:"annulé",
    standby:"Etre prêt",
    terminated:"terminé",
    urgentTitle:"Passer à une répartition urgente?",
    urgentDesc:"En permettant d'urgence, l'ordre sera en première priorité pour la répartition",
    urgentConfirm:"Oui, permettez d'urgence",
    urgentClose:"Non, annuler",
    Urgent:"Urgente",
    pop:"Preuve de ramassage",
    photos :"Photos",
    Portuguese:"Portugais",
    Dispatchat:"Expédier xxx",
    AutoDispatch:"Expédition automatique" ,
    Manual:"Manuel",
    Dispatchtime:"Temps d'expédition" ,
    Later:"Plus tard",
    DispatchingNow:"Envoiant maintenant",
    Required:"Requis" ,

    NoResultfromLocalDatabase:"Aucun résultat de la base de données locale",
    NoResultfromLocalDatabaseD:"Veuillez le vérifier à nouveau ou essayer un autre mot-clé",
    NoResultfromMapService:"Aucun résultat du service MAP",
    SearchinMapService:"Recherche dans le service de carte" ,
    SearchinLocalDatabase:"Rechercher dans la base de données locale",
    Creditcardnotadded:"Carte de crédit non ajoutée",
    OptimizeDropoffs:"Optimiser les départs",
    "Greek":"Grecque",
    "el":"Grecque",
    "ReferenceID":"Pièce d'identité",
    "EnterId":"Entrez ID",
    "de":"Allemande",
    "German":"Allemande",
    "AustrianGerman":"Allemand autrichien",
    "Options":"options",
    "VehicleOptions":"Options de véhicules",
    "EditCustomer":"Éditer le client",
    "NoDriverfound":"Aucun conducteur trouvé",
    "NoDriverfoundDesc":"Vérifiez vos informations et essayez autre chose",
    "ClearField":"Champ libre",
    "Map":"Carte",
    "Local":"Locale",
    "CompleteRequiredFeileds":"Compléter les champs requis",
    "Senderinfo":"Informations sur l'expéditeur",
    "SchedulePickup(Optional)":"Pickup de planification (facultatif)",
    "ScheduleDelivery(Optional)": "Schedule Delivery (Optional)",

    "AddDropoff":"Ajouter un dépôt",
    "EditPrice":"Modifier le prix",
    "Promotion":"promotion",
    "tax":"Impôt",
    "surcharge":"Supplément xxx",
    "Receiverinfo":"Informations sur le récepteur",
    "tryagain":"Something went wrong, please try again",



    "Selected date and time":"Selected date and time",
    "Reorder stops":"Reorder stops",
    "Bulk Edit Status Summary":"Bulk Edit Status Summary",
    "Successful":"Successful",
    "Failed":"Failed",
    "Order ID":"Order ID",
    "Details":"Details",
    "Result":"Result",
    "Pickup Routed":"Pickup Routed",
    "Delivery Routed":"Delivery Routed",
    "Ref. xxx":"Ref. xxx",
    "Zone":"Zone",
    "Clear":"Clear",
    "Apply":"Apply",
    "No result found":"No result found",
    "Type your keyword":"Type your keyword",
    "Type in the input to start search":"Type in the input to start search",
    "We are sorry ...":"We are sorry ...",
    "The page you are trying to access has restricted access.":"The page you are trying to access has restricted access.",
    "Please refer to your system administrator.":"Please refer to your system administrator.",
    "Failed":"Failed",
    "Done": "Done",
    "Schedule":"Schedule",
    "Proof of delivery":"Proof of delivery",
    "Signature":"Signature",
    "Driver Photo":"Driver Photo",
    "Notes":"Notes",
    "Order Info":"Order Info",
    "Order":"Order",
    "Price":"Price",
    "P_HUB_D Orders":'P_HUB_D Orders',
    "Routes":"Routes",
    "Default pickup":"Default pickup",
    'More':'More',
    'Route name':'Route name',
    'Driver':'Driver',
    'Pickup Orders':'Pickup Orders',
    'Delivery Orders':'Delivery Orders',
    'Ready For Pickup':'Ready For Pickup',
    'PricingAndEarning':"Pricing & Earning",
    "Pricing" : "Pricing",
    "save":"Save",
    "Learn":"Learn",
    "Scheduleoverlap":"The Schedule Pickup time must be before the Schedule Delivery time and have no overlap",
    "PleaseSelectCustomer":"Please select customer",
    "NoRegisteredCustomer":"No registered customer",
    "CreateAtLeastOneCustomerToCreateOrder":"Create at least one customer to create order",
};