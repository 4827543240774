import React from "react";
import { Map, Marker, TileLayer, Popup, Polyline } from "react-leaflet";
import L from "leaflet";
import { connect } from "react-redux";
import ReactDOMServer from "react-dom/server";
import PickupPin from "../../map-pickup-pin/Pin";
import DropOffPin from "../../map-dropoff-pin/Pin";
import { DropOffPinWithNumber } from "../../map-dropoff-pin-with-number";
import StartPin from "../startPin";
import EndPin from "../endPin";
import { DropOffPinWithNumber_style2 } from "../../map-dropoff-pin-with-number-2";
import { FeatureGroup } from "react-leaflet";


const createtIconPickup = (color) => {
  return new L.divIcon({
    className: "",
    html: ReactDOMServer.renderToString(<StartPin color={"#" + color} />),
    iconSize: [37, 53],
    iconAnchor: [-9, 0],
  });
};


const createtIconDropOff = (color) => {
  return new L.divIcon({
    className: "",
    html: ReactDOMServer.renderToString(<EndPin color={"#" + color} />),
    iconSize: [37, 53],
    iconAnchor: [-4, 18],
  });
};


class App extends React.Component {

  render() {


    return (
      <FeatureGroup ref={this.props.osmFeatureGroup}>
        <Marker
          animate={true}
          position={{ lat: this.props.ROUTE.startLocation.coordinates[1], lng: this.props.ROUTE.startLocation.coordinates[0] }}
          icon={createtIconPickup("1795ff")}
        ></Marker>

        {this.props.ROUTE.endLocation && <Marker
          animate={true}
          position={{ lat: this.props.ROUTE.endLocation.coordinates[1], lng: this.props.ROUTE.endLocation.coordinates[0] }}
          icon={createtIconDropOff("1795ff")}
        ></Marker>}



        {this.props.STOPS.map((stop) => {
          return <DropOffPinWithNumber_style2 bigSize={false} numberStyle={{ position: "absolute", width: "20px", height: "20px", left: "5px", top: "5px", fontSize: "14px", fontWeight: "bold", fontFamily: 'sf_pro', textAlign: "center" }}
            mapType={this.props.mapType}
            isPickup={stop.stage == "Pickup"}
            status={stop.status}
            position={{ lat: stop.coordinates[1], lng: stop.coordinates[0] }} number={stop.position} />

        })}
      </FeatureGroup>
  
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  center: state.Map.center,
  STOPS: ownProps.STOPS,
  ROUTE: ownProps.ROUTE,
  osmFeatureGroup: state.Map.osmFeatureGroup,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(mapStateToProps, mapDispatchToProps)(App);
