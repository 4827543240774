import landebers from "../../../assets/landeber_logo.png";
import FavIcon from "../../../assets/PROJECTS/landeber.png";

export default function landeber() {
    
    return  global.config = {
    "ProjectName": "Landeber",
    "LogoType": landebers,
    "BaseApi": "https://rest.landeber.com",
    "BaseSocket": "https://socket.landeber.com",
    "BaseTrack": "https://admin.landeber.com",
    "MapCenter": {
        "lat": "51.5062092709252",
        "lng": "-0.13112440635871198"
    },
    "Lang": "en",
    "Direction": "ltr",
    "AllLanguages": [{
        "lang": "en",
        "direction": "ltr",
        "i18Lang": "English"
    }],
    "FavIcon": FavIcon,
    "Country": "GB",
    "thirdPartyLoginSupport": true  ,
    mapType :"osm", /* osm | google */ 
    googleApiKey : ""
} }