import React from 'react';
import {MainLayout} from '../layout/main/main'


function MainPage() {
    return <MainLayout/>

}

export default MainPage;

