import BeyondDeliveryLogo from "../../../assets/BeyondDeliveryLogo.png";
import FavIcon from "../../../assets/PROJECTS/BeyondDelivery.png";

export default function BeyondDelivery () {
    
    return global.config = {
    "ProjectName": "Beyond Delivery",
    "LogoType": BeyondDeliveryLogo,
    "BaseApi": "https://rest.beyond-delivery.com",
    "BaseSocket": "https://socket.beyond-delivery.com",
    "BaseTrack": "https://admin.beyond-delivery.com",
    "MapCenter": {
        "lat": "29.375167662347614",
        "lng": "47.97568694063233"
    },
    "Lang": "en",
    "Direction": "ltr",
    "AllLanguages": [{
        "lang": "en",
        "direction": "ltr",
        "i18Lang": "English"
    }],
    "FavIcon": FavIcon,
    "Country": "KW",
    "thirdPartyLoginSupport": true  ,
    mapType :"osm", /* osm | google */ 
    googleApiKey : ""
} }