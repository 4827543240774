import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import { CanEditTravel } from "../../../../../../../helper/module";
import { Edit_Travel_Operate_Changed } from "../../../../../../../Redux/actions/actionNames";
import price from "../../../../../../../assets/ic_dollar.svg";
import cancell from "../../../../../../../assets/ic-close.svg";
import infoo from "../../../../../../../assets/icon-photo-copy.svg";
import Dialog from "@material-ui/core/Dialog";
import {
  get_currencyCode,
  get_lang,
} from "../../../../../../../helper/UserData/userdate";


class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      priceChange: false,
      surchargeActive:null
    };
  }



  closeDialoge = () => {
    this.props.priceChange && this.props.priceChange(false)
    this.setState({
      priceChange: false,
      surcharges: [],
      fee: "0",
    });

  }

 

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>

      { this.state.surchargeActive &&  <Dialog
                 disableAutoFocus
                 disableEnforceFocus 
                 disableRestoreFocus 
          style={{}}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          open={this.state.surchargeActive}
          onClose={()=>{this.setState({surchargeActive : null})}}
          className="EditpriceOnChange"
        >
          <div
            className="EditPriceContainer"
            style={{ height:  "auto", width: "376px", overflow: "hide" }}
          >
            <div className="header">
              <img
                className="close"
                src={cancell}
                onClick={()=>{this.setState({surchargeActive : null})}}
                style={{ border: "1px solid rgba(128, 128, 128, 0.2)" }}
              />
              <span className="title">{(["keyValue" , "total"].indexOf(this.state.surchargeActive.type) > -1 ) ? this.state.surchargeActive.title :  String(this.props.t("Surchargexxx")).replace("xxx", this.state.surchargeActive.title) }</span>
            </div>


            <div
              className="content"
              style={{
                paddingTop: "0px",
                height: "274px",
                overflow: "hide",
                position: "relative",
              }}
            >
             
             <div className="podNote">
                    <span className="title"> {this.props.t("Notes")} </span>

                    <span className="note">
                      {this.state.surchargeActive.description}
                    </span>
                  </div>

                  <div className="podSignContainer" style={{marginRight :"calc(50% - 87px)" , marginLeft:"calc(50% - 87px)"}}>
                    <span className="title"> {this.props.t("Photo")} </span>
                    <div className="image">
                      {this.state.surchargeActive.photo.length > 0 ? (
                        <img
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "contain",
                          }}
                          src={ this.state.surchargeActive.photo}
                        />
                      ) : (
                        <span className="nophotosign">
                          {" "}
                          {this.props.t("Nophoto")}{" "}
                        </span>
                      )}
                    </div>
                  </div>

            </div>
          </div>
        </Dialog>}

        <Dialog
          style={{}}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          open={(this.state.priceChange || this.props.open) && !this.state.surchargeActive}
          onClose={this.closeDialoge}
          className="EditpriceOnChange"
          disableAutoFocus
          disableEnforceFocus 
          disableRestoreFocus 
        >
          <div
            className="EditPriceContainer"
            style={{ height:  "auto", width: "416px", overflow: "hide" }}
          >
            <div className="header">
              <img
                className="close"
                src={cancell}
                onClick={this.closeDialoge}
                style={{ border: "1px solid rgba(128, 128, 128, 0.2)" }}
              />
              <span className="title">{this.props.t("Pricedetails")}</span>
            </div>


            <div
              className="content"
              style={{
                paddingTop: "0px",
                height: "auto",
                overflow: "hide",
                position: "relative",
              }}
            >
             
             {
               (this.props.Data && this.props.Data.dispatcherReceipt) ? 
               this.props.Data.dispatcherReceipt.map((item , i)=>{
                 return <rowStuff style={{borderBottom : (i != this.props.Data.dispatcherReceipt.length -1 )? "1px solid rgba(128, 128, 128, 0.2)" :"" }} className="rowStuff_factor_price">
                 <textt style={{color : item.style == "regular" ? "#808080" :"#484848"}}>
                    {(["keyValue" , "total"].indexOf(item.type) > -1 ) ? item.title :  String(this.props.t("Surchargexxx")).replace("xxx", item.title) }
                   {
                     item.type == "surcharge" &&  <img src={infoo} className="coifhefdwdwc" onClick={()=>{this.setState({surchargeActive : item.data})}} />
                   }

                 </textt>
                 <span style={{fontWeight : item.style == "regular" ? "normal" :"bold"}}>
                 { item.value }
                 </span>
               </rowStuff>
               })

               : null
             }

            </div>
          </div>
        </Dialog>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state , ownProps) => ({
  Data: state.ViewTravel.pureData ? state.ViewTravel.pureData : false,
  baseFarePrice: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.baseFarePrice
    : 0,
  surcharges: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.surcharges
    : [],

});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
