import GetItPickedLogo from "../../../assets/GetItPicked_logotype.png";
import FavIcon from "../../../assets/PROJECTS/GetItPicked.png";

export default function GetItPicked() {
    
    return  global.config = {
    "ProjectName": "GetItPicked",
    "LogoType": GetItPickedLogo,
    "BaseApi": "https://rest.getitpicked.com",
    "BaseSocket": "https://socket.getitpicked.com",
    "BaseTrack": "https://admin.getitpicked.com",
    "MapCenter": {
        "lat": "44.66640107687384",
        "lng": "-63.56726556239336"
    },
    "Lang": "en",
    "Direction": "ltr",
    "AllLanguages": [{
        "lang": "en",
        "direction": "ltr",
        "i18Lang": "English"
    }],
    "FavIcon": FavIcon,
    "Country": "CA",
    "thirdPartyLoginSupport": true  ,
    mapType :"osm", /* osm | google */ 
    googleApiKey : ""
}}
