import { makeData } from "../../components/table/table-list/makeData2";
import { LocalData } from "../../service/localStorage";
import * as actionTypes from "../actions/actionTypes";
const localData = new LocalData();

export const defRoute = {

  filters:   {

    status: [] /* [""] */,
    driverId: null,
    createdAt: { equal: null, gte: null, lte: null },
    name: "",


  }

};

export const initRoute = {

  filters: localData.get("ReduxLocal_Routefilters") ? {...JSON.parse(localData.get("ReduxLocal_Routefilters")),
    name: "",} :  {

    status: [] /* [""] */,
    driverId: null,
    createdAt: { equal: null, gte: null, lte: null },
    name: "",


  }

};
export const Route = (state = initRoute, action) => {
  switch (action.type) {



    case actionTypes.handleEditRouteFilter_Name:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.state.key]: action.state.value
        }
      };

    case actionTypes.clearAll_Route_ActiveFilter:

      return { filters : {

        status: [] /* [""] */,
        driverId: null,
        createdAt: { equal: null, gte: null, lte: null },
        name: "",
    
    
      } };
    default:
      return state;
  }
};

export default Route;