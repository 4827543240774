import { ClickAwayListener } from "@material-ui/core";
import { OverlayView } from "@react-google-maps/api";
import { useDispatch, useSelector } from "react-redux";
import { GetMonitoringOrdersListData } from "../../../../../hooks/GetMonitoringOrdersListData";
import { SetDriverPopUp, SetSelectedMonitoringDriver, SetSelectedMonitoringOrder, SetOrderPinPopUp } from "../../../../../Redux/actions/actionNames";
import { GetColorByStatus } from "../../../../../utils/get-colors-by-status";
import { DropOffPin } from "../../../../shared/map-dropoff-pin";
import { PickupPin } from "../../../../shared/map-pickup-pin";
import { OrderPinPopUp } from "../../../../shared/order-pin-popup";
import styles from './style.module.css';

export const OrdersList = (props) => {
    let orders = GetMonitoringOrdersListData()
    const ordersOpen = useSelector((state) => state.Monitoring.orders.open)
    const orderId = useSelector((state) => state.ViewTravel.ID)
    const ordersBoundingList = useSelector((state) => state.Monitoring.ordersBoundingList) 


    if(ordersOpen){
        return <>
        {orders.filter((order)=> String(order.id) != String(orderId)).map((order, i) => {
            return <>
                {order.pickup && <PickupPin order={order} key={order.id + "" + i + "" + 1} id={order.id + "" + i + "" + 1} {...props} position={{ lat: order.pickup.coordinates[1], lng: order.pickup.coordinates[0] }} />}
                {order.dropoff && <DropOffPin order={order} key={order.id + "" + i + "" + 2} id={order.id + "" + i + "" + 2} {...props} position={{ lat: order.dropoff.coordinates[1], lng: order.dropoff.coordinates[0] }} />}
                {order.dropoffs && order.dropoffs.map((drops, ii) => {
                    return <DropOffPin order={order} key={order.id + "" + i + "" + 3 + "" + ii} id={order.id + "" + i + "" + 3 + "" + ii} {...props} position={{ lat: drops.coordinates[1], lng: drops.coordinates[0] }} />
                })}
            </>
        })}

        <OrderPinPopUp {...props} />

    </>
    }else{
        if ( ordersBoundingList.length == 0) {
            return null
        }else{
            return <>
        {ordersBoundingList.filter((order)=> String(order.id) != String(orderId)).map((order, i) => {
            return <>
                {order.pickup && <PickupPin order={order} key={order.id + "" + i + "" + 1} id={order.id + "" + i + "" + 1} {...props} position={{ lat: order.pickup.coordinates[1], lng: order.pickup.coordinates[0] }} />}
                {order.dropoff && <DropOffPin order={order} key={order.id + "" + i + "" + 2} id={order.id + "" + i + "" + 2} {...props} position={{ lat: order.dropoff.coordinates[1], lng: order.dropoff.coordinates[0] }} />}
                {order.dropoffs && order.dropoffs.map((drops, ii) => {
                    return <DropOffPin order={order} key={order.id + "" + i + "" + 3 + "" + ii} id={order.id + "" + i + "" + 3 + "" + ii} {...props} position={{ lat: drops.coordinates[1], lng: drops.coordinates[0] }} />
                })}
            </>
        })}

        <OrderPinPopUp {...props} />

    </>
        }
    }
    



};


