import React from "react";
import clsx from "clsx";
import styles from './style.module.css';
import colors from '../colors/colors.module.css';
import { ModalProps } from "./Modal.types";
import IconProvider from "../IconProvider";
import ProgressIndicator from "../ProgressIndicator";
import Button from "../Button";
import Typography from "../Typography";
import { CLOSE } from "../../../assets";
import { Dialog } from 'newmaterial-ui';


const Modal: React.FC<ModalProps> = ({ConfirmationDisabled=false,HeaderExtraBtn ,bodyStyle, body, onclose, footer, className, ref, onClick, title, CancelOnClick, ConfirmationOnClick, description, ConfirmationText, CancelText, icon, size, subtitle, ConfirmationLoading }: ModalProps) => {


    if (size == "Small") {
        return (
            <Dialog onClose={onclose} open={true}>

                <div className={styles.modal}  >

                <div className={styles.backDrop} onClick={onclose}  ></div>
                <div ref={ref} className={clsx(styles.Small, className && className)} >
                    <IconProvider Icon={icon} size="32" onClick={onclose} />
                    <Typography text={title} weight="Body_Middle_Bold" className={styles.SmallTitle} />
                    <Typography text={description} weight="Body_Small_Regular" className={styles.SmallDescription} />
                    <div className={styles.buttonsContainer}>
                        <Button size={'Medium'} type='Primary' text={ConfirmationText} onClick={ConfirmationOnClick} />
                        <Button size={'Medium'} type='Secondary' text={CancelText} onClick={CancelOnClick} />
                    </div>
                </div>
            </div>
            </Dialog>

        )
    } else {
        return (
            <Dialog onClose={onclose} open={true}>

            <div className={styles.modal}  >
                <div className={styles.backDrop} onClick={onclose}  ></div>

                <div onClick={onClick && onClick} ref={ref} className={clsx(styles.Large, className && className)} >
                    <div className={styles.largeHeader}>
                        <Button icon={CLOSE} size="Medium" type="Secondary" onClick={onclose} />
                        <div className={styles.largeHeaderTitles}>
                            <Typography text={title} weight="Body_Middle_Bold" className={styles.SmallTitle} style={{ margin: "0", padding: "0" }} />
                            {subtitle && <Typography text={subtitle} weight="Subtitle_Tiny_Regular" className={styles.SmallDescription} style={{ margin: "0", padding: "0" }} />}
                        </div>

                        {HeaderExtraBtn && HeaderExtraBtn}
                    </div>
                    <div className={styles.largeBody} style={bodyStyle ? bodyStyle : {}}>
                        {body}
                    </div>

                    <div className={styles.largeFooter}>
                        <Button size="Medium" className={styles.largeFooterddwdwdwdwdw} type="Primary" style={{color:"white"}} text={ConfirmationText} onClick={ConfirmationOnClick} isLoading={ConfirmationLoading} disabled={ConfirmationDisabled} />
                        {footer}
                    </div>

                </div>
            </div>
            </Dialog>

        )
    }


};

export default Modal;

