import * as React from 'react'
import styles from './style.module.css';
import IconProvider from '../../../../../design-system/lib/IconProvider';
import Typography from '../../../../../design-system/lib/Typography';
import { HISTORY } from '../../../../../assets';
import { isEmpty } from "lodash";




function RecentAddressItem({ data, onclick }) {

  let stylee ={
    maxWidth: '324px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }

  return (
    <div className={styles.CustomerListItemContainer} onClick={onclick && onclick}>
      <IconProvider Icon={HISTORY} size='16' />

      <div className={styles.namePhoneContainer}>
        <Typography weight='Body_Tiny_Medium' text={data.address} style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',...stylee
        }} />
        <Typography weight='Body_Tiny_Regular' text={(isEmpty(data.buildingBlock) && isEmpty(data.room) && isEmpty(data.floor)) ? "----" : `${data.buildingBlock} ${!isEmpty(data.room) ? ", " + data.room : ""} ${!isEmpty(data.floor) ? ", " + data.floor : ""}`} style={stylee} />

      </div>
    </div>
  )
}

export default RecentAddressItem