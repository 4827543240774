import * as React from 'react'
import styles from './style.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CLOSE, COD, TICKET_DISCOUNT } from '../../../../assets';
import Typography from '../../../../design-system/lib/Typography';
import Button from '../../../../design-system/lib/Button';
import Modal from '../../../../design-system/lib/Modal';
import Input from '../../../../design-system/lib/Input';
import ChangeMethod from '../../changeMethod/changeMethod';
import { get_currencyCode, get_lang } from '../../../../helper/UserData/userdate';
import { SetCodAmount, EdittPriceNewOrder, EditPrice_PromoCode_NewOrderChanged, EdittPriceNewOrder_manualy } from '../../../../Redux/actions/actionNames';



function EditPriceNewOrder({ handleClose }) {
  const price = useSelector((state) => state.NewOrder.newOrder.price)
  const CODV = useSelector((state) => state.NewOrder.newOrder.codAmount)
  const customer = useSelector((state) => state.NewOrder.newOrder.customer)

  const newCustomerIsOpen = useSelector((state) => state.NewOrder.newOrder.newCustomer.open)
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const [pricePrev, setPricePrev] = React.useState(price.price);
  const [CODPrev, setCODPrev] = React.useState(CODV);
  const [surcharges, setSurcharges] = React.useState(price.price.surcharges);
  const [Promocode, setPromocode] = React.useState(price.promoCode);

  const [prevPrice, setPrevPrice] = React.useState(price);
  const orderTypeActive = useSelector((state) => state.NewOrder.newOrder.orderTypeActive)
  const activeVehicleType = useSelector((state) => state.NewOrder.newOrder.service.activeVehicleType)




  const dispatch = useDispatch();

  const CODinputOnchange = (e) => {
    // dispatch(SetCodAmount(e.target.value))
    setCODPrev(e.target.value)
  }



  const baseFarePriceChangeHandler = React.useCallback((e) => {
    setPricePrev({ ...pricePrev, baseFarePrice: e.target.value })

  }, [pricePrev]);


  const taxPriceChangeHandler = React.useCallback((e) => {
    setPricePrev({ ...pricePrev, tax: e.target.value })

  }, [pricePrev]);

  const surchargesChangeHandler = React.useCallback((e, index) => {

    let newArr = pricePrev.surcharges.map((item, i) => {
      if (index == i) {
        return { ...item, price: e.target.value };
      } else {
        return item;
      }
    });

    setPricePrev({
      ...pricePrev,
      surcharges: newArr
    })

  }, [pricePrev]);


  const saveHandler = React.useCallback((e) => {
    dispatch(EditPrice_PromoCode_NewOrderChanged(Promocode))
    dispatch(EdittPriceNewOrder({ ...pricePrev, promoCode: Promocode }))
    dispatch(SetCodAmount(CODPrev))
    dispatch(EdittPriceNewOrder_manualy(true))

    // handleClose()
  }, [CODPrev, pricePrev, pricePrev, Promocode]);


  React.useEffect(() => {
    if (price.done && !prevPrice.done) {
      handleClose()
    } else {
      setPrevPrice(price)
    }
  }, [price])


  return (
    <Modal size='Large' title={t("EditPrice")} onclose={handleClose} body={<>
      <div className={styles.conatiner}>
        <div className={styles.rowContainer}>
          <div className={styles.row}>
            <Typography text={t("Fee")} weight="Body_Small_Regular" style={{ color: "#484848" }} />
            <Input type={"number"} value={pricePrev.baseFarePrice ? pricePrev.baseFarePrice : ""} onChange={baseFarePriceChangeHandler} />
          </div>

          <div className={styles.row}>
            <Typography text={t("tax")} weight="Body_Small_Regular" style={{ color: "#484848" }} />
            <Input type={"number"} value={pricePrev.tax ? pricePrev.tax : ""} onChange={taxPriceChangeHandler} />
          </div>

          {pricePrev?.surcharges?.map((item, i) => {
            return <div className={styles.row}>
              <Typography text={item.title} weight="Body_Small_Regular" style={{ color: "#484848" }} />
              <Input type={"number"} value={item.price ? item.price : ""} placeHolder='' onChange={(e) => { surchargesChangeHandler(e, i) }} />
            </div>
          })}

        </div>


       {((activeVehicleType && activeVehicleType.setting.isCODActive) || (customer.id.length > 0 && ["Ondemand", "PickupDelivery"].indexOf(orderTypeActive) > -1)) && <span className={styles.hr}></span>}

        <div className={styles.rowContainer}>
          {(activeVehicleType && activeVehicleType.setting.isCODActive) && <div className={styles.row}>
              <Typography text={t("CashOnDelivery")} weight="Body_Small_Regular" style={{ color: "#484848" }} />
              <Input onChange={CODinputOnchange} iconStart={COD} type={"number"} placeHolder={t("CashOnDelivery")} value={CODPrev ? CODPrev :""} />
            </div>}

          {(customer.id.length > 0 && ["Ondemand" ].indexOf(orderTypeActive) > -1) && <div className={styles.row}>
            <Typography text={t("Promotion")} weight="Body_Small_Regular" style={{ color: "#484848" }} />
            <Input iconStart={TICKET_DISCOUNT} placeHolder={t("Promocode")} type={"text"} value={Promocode ? Promocode : ""} onChange={(e) => { setPromocode(e.target.value) }} />
          </div>}

        </div>
      </div>
    </>} footer={<div className={styles.footerContainer}>
      <div className={styles.priceContainer}>
        <Typography weight='Body_Middle_Bold' text={price.loading ? (
          t("poperLoading")
        ) : price.done ? (
          Number(price.price.price).toLocaleString(get_lang(), {
            style: "currency",
            currency: get_currencyCode(),
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          })
        ) : (
          "0"
        )} />

        <Typography weight='Subtitle_Tiny_Regular' text={t("Price")} />
      </div>

      <div className={styles.verticalLine}></div>

      <ChangeMethod />
    </div>} ConfirmationText={t("Savechanges")} ConfirmationOnClick={saveHandler} ConfirmationLoading={price.loading} />
  )
}

export default EditPriceNewOrder