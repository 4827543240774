import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import arrow2 from "../../../../../assets/arrow-triangle-down.svg";
import ListItem from "./driverOptions/list";
import SelectableItem from "./driverOptions/selectable";
// import ManualVehicleType from "./manualVehicleType";
import remove from "../../../../../assets/ic-remove.svg";
import Backdrop from "@material-ui/core/Backdrop";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import { withStyles } from "@material-ui/core/styles";
import { AdvancedAssaigning_firstDriver_auto, AdvancedAssaigning_firstDriver_auto_switch, AdvancedAssaigning_firstDriver_manual, AdvancedAssaigning_firstDriver_options_add, AdvancedAssaigning_firstDriver_options_reset } from "../../../../../Redux/actions/actionNames";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import Grow from "@material-ui/core/Grow";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { SearchDriver_AdvancedAssaigning_Action, Voip_OutgoingCall } from "../../../../../Redux/actions/actions";
import { isEmpty } from "lodash";
import call from "../../../../../assets/ic-call.svg";
import { get_id } from "../../../../../helper/UserData/userdate";
import { snack } from "../../../../../utils/SnackbarUtilsConfigurator";
var _ = require("lodash");




const BackdropCustom = withStyles({
  backdrop: {
    zIndex: 5000,
    backgroundColor: "rgba(0, 0, 0, 0)",
    color: "#00000",
  },
})((props) => (
  <Backdrop
    {...props}
    style={{
      zIndex: 5000,
      backgroundColor: "rgba(0, 0, 0, 0)",
      color: "#00000",
    }}
  />
));






class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showpoperManualDriver: null,
      showpoperAuto: null,
      showpoperManual: null,
      ShowDialogeVehicleChanged: false,
    };
    this.anchorEl = React.createRef();
    this.anchorElAuto = React.createRef();
    this.anchorElManual = React.createRef();
    this.anchorElStatus = React.createRef();
    this.findDriverVehicleType = this.findDriverVehicleType.bind(this)
  } 

  isEmpty(obj) {
    return !obj ? true : Object.keys(obj).length === 0;
  }


  makeOutgoingCall = () => {
    this.props.dispatch(Voip_OutgoingCall(this.props.dispatch, {
      "to": this.props.manual_number,
      "orderId": this.props.orderId,
      "customerId": null,
      "driverId": this.props.manual_id,
      "dispatcherId": get_id()
    }))
  }

  checkMustsetDriver = () => {
    let rule = this.props.activeRules || this.props.initialRule


    if (rule) {
      if (rule.MustsetDriver || !rule.SendDriverOptional && rule.NotshowDriverPanel) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  handleshowpoperAuto = () => {
    this.setState({
      showpoperAuto: !this.state.showpoperAuto,
    });

  };


  handleAutoVehicleTypeSelect = (service) => {
    this.props.dispatch(AdvancedAssaigning_firstDriver_auto(service));
    this.props.dispatch(AdvancedAssaigning_firstDriver_options_reset(this.props.auto ? "auto" : "manual"));

  };


  handleAutoManual = (auto) => {
    this.props.dispatch(AdvancedAssaigning_firstDriver_auto_switch(auto));
  };


  handleshowpoperManual = (v, a) => {
    this.setState({
      showpoperManual: a ? !this.state.showpoperManual : v,
    });
  };

  handleManualSelectedItem = (item) => {
    this.props.dispatch(
      AdvancedAssaigning_firstDriver_manual({
        manual_activeVehicleType: item,
        manual_suggestListData: []
      })
    );
    this.props.dispatch(AdvancedAssaigning_firstDriver_options_reset(this.props.auto ? "auto" : "manual"));

    this.setState({
      showpoperManual: null,
    });
    this.SearchDriverAction();


  };



  optionText(option_id) {
    if (this.props.manual_activeVehicleType) {
      return true;
    } else {
      return false;
    }
  }

  handleshowpoperManualDriver = (v, a) => {
    this.setState({
      showpoperManualDriver: a ? !this.state.showpoperManualDriver : v,
    });
  };

  closeshowpoperManualDriver = () => {
    this.setState({
      showpoperManualDriver: null
    })
  }


  InputOnChange = (e) => {
    this.props.dispatch(
      AdvancedAssaigning_firstDriver_manual({
        manual_id: null,
        searchDriverInput: e.target.value,
        manual_suggestListData: [],
      })
    );

    if (!this.state.showpoperManualDriver) {
      this.setState({
        showpoperManualDriver: true,
      });
    }

    this.SearchDriverAction(e.target.value);
  };


  SearchDriverAction = (input) => {
    this.props.dispatch(
      SearchDriver_AdvancedAssaigning_Action(
        this.props.dispatch,
        {
          searchRegex: input ? input : this.props.searchDriverInput,
          vehicleTypes: this.props.manual_activeVehicleType
            ? this.props.manual_activeVehicleType.id
            : this.props.vehicleTypes.map(
              (vehicleType) => vehicleType.id
            ).join("|"),
        },
        "firstDriver"
      )
    );
  };


  async findDriverVehicleType(driver) {
    let vehicleTypes = _.keyBy(this.props.vehicleTypes, "id")

    if (this.props.manual_activeVehicleType) {
      return this.props.manual_activeVehicleType
    } else {
      let foundedVehicle = null;
      for (let i = 0; i < driver.vehicleTypes.length; i++ && !foundedVehicle) {
        if (vehicleTypes[driver.vehicleTypes[i]]) {
          foundedVehicle = vehicleTypes[driver.vehicleTypes[i]];
          return vehicleTypes[driver.vehicleTypes[i]]
        } else if (i == driver.vehicleTypes.length - 1) {
          snack.warning_i18("DriverVehicleTypeError")
          return null
        }
      }
    }

    // driver.vehicleTypes
    // this.props.vehicleTypes
    // this.props.OrderPureData.vehicleType.id


  }



  async handleManualVehicleTypeSelect(service) {
    

    let vehicleType = await this.findDriverVehicleType(service)

    if (vehicleType) {
      
      this.setState({
        showpoperManualDriver: null
      })
      this.props.dispatch(
        AdvancedAssaigning_firstDriver_manual({
          manual_activeVehicleType: vehicleType,
          manual_name: service.name,
          manual_number: service.phone,
          manual_id: service.id,
          manual_code: service.username,
        })
      );

      this.props.dispatch(AdvancedAssaigning_firstDriver_options_reset(this.props.auto ? "auto" : "manual"));

      console.log("handleManualVehicleTypeSelect ",this.props.vehicleTypePureData.id ,vehicleType.id , this.props.vehicleTypePureData.options?.length > 0)
      if(this.props.vehicleTypePureData.id == vehicleType.id && this.props.vehicleTypePureData.options?.length > 0 ){
        this.setVehicleTypeOptionsFromPureData(vehicleType,"manual")
      }
    }


  }


  reMustsetDriver = () => {
    this.props.dispatch(
      AdvancedAssaigning_firstDriver_manual({
        searchDriverInput: "",
        manual_name: "",
        name: "",
        manual_family: "",
        manual_number: "",
        manual_id: "",
        manual_code: "",
      })
    );
  };

  NeedDriver = () => {
    let rule = this.props.activeRules ?  this.props.activeRules :  this.props.initialRule

    if (rule) {
      if (rule.NotshowDriverPanel) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }


  finderDriverVehicleType = (id, call) => {
    this.props.vehicleTypes.forEach((localservive) => {
      if (id == localservive.id) {
        call(localservive);
      }
    });
  };

  init = () => {
    if (!this.isEmpty(this.props.OrderPureData.driver)) {
      console.log("insit x1")
      this.finderDriverVehicleType(
        this.props.OrderPureData.vehicleType.id,
        (call) => {
          this.props.dispatch(
            AdvancedAssaigning_firstDriver_manual({
              manual_activeVehicleType: call,
              manual_name: this.props.OrderPureData.driver.name,
              manual_number: this.props.OrderPureData.driver.phone,
              manual_id: this.props.OrderPureData.driverId,
              manual_code: this.props.OrderPureData.driver.username,
            })
          );
          this.props.dispatch(
            AdvancedAssaigning_firstDriver_auto(call)
          );
          this.setVehicleTypeOptionsFromPureData(call , "manual")
        }
      );
      this.handleAutoManual(false)

    } else if (this.props.driverAssignmentModel == "manual" && !this.isEmpty(this.props.OrderPureData.currentCandidateDrivers) ) {
      console.log("insit x2")

      this.finderDriverVehicleType(
        this.props.OrderPureData.vehicleType.id,
        (call) => {
          this.handleAutoManual(false)

          this.props.dispatch(
            AdvancedAssaigning_firstDriver_manual({
              manual_activeVehicleType: call,
              manual_name: this.props.OrderPureData.currentCandidateDrivers[0].name,
              manual_number: this.props.OrderPureData.currentCandidateDrivers[0].phone,
              manual_id: this.props.OrderPureData.currentCandidateDrivers[0].driverId,
              manual_code: this.props.OrderPureData.currentCandidateDrivers[0].username,
            })
          );
          this.props.dispatch(
            AdvancedAssaigning_firstDriver_auto(call)
          );
          this.setVehicleTypeOptionsFromPureData(call , "manual")
        }
      );

    } else if (this.props.driverAssignmentModel == "automatic"){
      console.log("insit x3")

      this.handleAutoManual(true)
      this.finderDriverVehicleType(
        this.props.OrderPureData.vehicleType.id,
        (call) => {
          this.props.dispatch(
            AdvancedAssaigning_firstDriver_manual({
              manual_activeVehicleType: call
            })
          );
          this.props.dispatch(
            AdvancedAssaigning_firstDriver_auto(call)
          );
          this.setVehicleTypeOptionsFromPureData(call , "auto")
        }
      );

    }else if(this.props.driverAssignmentModel == "manual" ){
      console.log("insit x4")

      this.finderDriverVehicleType(
        this.props.OrderPureData.vehicleType.id,
        (call) => {

          this.props.dispatch(
            AdvancedAssaigning_firstDriver_manual({
              manual_activeVehicleType: call
            })
          );
          this.props.dispatch(
            AdvancedAssaigning_firstDriver_auto(call)
          );
          this.setVehicleTypeOptionsFromPureData(call)
        }
      );
      this.handleAutoManual(false)
    }
  }


  setVehicleTypeOptionsFromPureData = (vehicleType ,flag) => {

    this.props.OrderPureData &&
      this.props.OrderPureData.vehicleType.options.forEach((Pureoption) => {
        vehicleType.options.forEach((option) => {

          if (Pureoption.id == option.id) {
            this.props.dispatch(
              AdvancedAssaigning_firstDriver_options_add({
                name: null,
                id: option.id,
                item: Pureoption.dataId
                  ? { id: Pureoption.dataId, title: Pureoption.dataTitle }
                  : null,
                type: Pureoption.dataId ? "list" : "boolean",
              },flag ? flag : this.props.auto ? "auto" : "manual")
            );
          }
        });
      });
  };




  getDriverInfoFromPureDataDriverOrCandidate = () => {
    if (!this.isEmpty(this.props.OrderPureData.driver)) {
      return {
        manual_name: this.props.OrderPureData.driver.name,
        manual_number: this.props.OrderPureData.driver.phone,
        manual_id: this.props.OrderPureData.driverId,
        manual_code: this.props.OrderPureData.driver.username,
      };
    } else if (!this.isEmpty(this.props.OrderPureData.currentCandidateDrivers)) {
      return {
        manual_name: this.props.OrderPureData.currentCandidateDrivers[0].name,
        manual_number: this.props.OrderPureData.currentCandidateDrivers[0].phone,
        manual_id: this.props.OrderPureData.currentCandidateDrivers[0].driverId,
        manual_code: this.props.OrderPureData.currentCandidateDrivers[0].username,
      };
    }
  };

  componentDidMount = () => {
    this.init()
  }



  render() {
    const { t } = this.props;


    if(!this.NeedDriver()){
      return null
    }
    return (
      <div className={`ondemandDriver pickupDriver ${!this.NeedDriver() && "pickupDriverInactive"}`}>
        <span className="title">
          {" "}
          <Trans i18nKey={"driver"}> </Trans>{" "}
        </span>

        {!this.checkMustsetDriver() && (
          <personBusinessSwitchContainer className="driverAMswitch">
            <detail
              id="details"
              onClick={() => this.handleAutoManual(true)}
              className={`${this.props.auto ? "details" : "DEactive"
                }`}
            >
              <Trans i18nKey={"Auto"}> </Trans>
            </detail>
            <history
              id="history"
              onClick={() => this.handleAutoManual(false)}
              className={`${!this.props.auto ? "details" : "DEactive"
                }`}
            >
              <Trans i18nKey={"Manual"}> </Trans>
            </history>
          </personBusinessSwitchContainer>
        )}


        <div className="pickupcollapsecontainer yodmvydj">
          {this.props.auto && !this.checkMustsetDriver() && (
            <React.Fragment>
              <autodriverselector
                ref={this.anchorElAuto}
                onClick={this.handleshowpoperAuto}
              >
                {this.props.auto_activeVehicleType ? (
                  <React.Fragment>
                    <img
                      src={this.props.auto_activeVehicleType.icon}
                      className="serviceimage"
                    />
                    <servicename>
                      {this.props.auto_activeVehicleType.title}
                    </servicename>
                  </React.Fragment>
                ) : (
                  <servicename style={{ marginLeft: "19px" }}>
                    {" "}
                    <Trans i18nKey={"pleaseSellectParsel"}> </Trans>
                  </servicename>
                )}

                <img
                  src={arrow2}
                  className={` servicearrow ${this.state.showpoperAuto && "rotate180"
                    }`}
                />
              </autodriverselector>
              <serviceOptions
                className={`${this.props.auto_activeVehicleType &&
                  this.props.auto_activeVehicleType.options.length ==
                  0 &&
                  "displayNone"
                  }`}
                style={{ marginLeft: "0px", width: "100%" }}
              >
                {this.props.auto_activeVehicleType &&
                  this.props.auto_activeVehicleType.options.map(
                    (item) => {
                      return item.type === "list" ? (
                        <ListItem {...item} auto={this.props.auto} />
                      ) : (
                        <SelectableItem {...item} auto={this.props.auto} />
                      );
                    }
                  )}
              </serviceOptions>
            </React.Fragment>
          )}

          {(!this.props.auto || this.checkMustsetDriver()) && (
            <div>
              {(this.props.manual_id == "" ||
                this.props.manual_id == null) && (
                  <div class="drivermanualContainer">
                    {/* <ManualVehicleType /> */}
                    <clickableservice
                      ref={this.anchorEl}
                      onClick={() => this.handleshowpoperManual(true, 1)}
                      // className={`manualVehicleType manualVehicleTypeEdit  ${isEmpty(this.props.manual_id)  && "notallowed"}`}
                      className={`manualVehicleType manualVehicleTypeEdit `}
                    //   className={`${this.optionisSaved() && "avtiveServiceOptions"}`}
                    >
                      <img
                        src={arrow2}
                        className={`${this.state.showpoper && "rotate180"}`}
                      />
                      {this.optionText() ? (
                        this.props.manual_activeVehicleType.title
                      ) : (
                        <Trans i18nKey={"Allvehicletypes"}> </Trans>
                      )}
                    </clickableservice>

                    <Popper
                      open={this.state.showpoperManual}
                      anchorEl={this.anchorEl.current}
                      role={undefined}
                      style={{
                        zIndex: 6,
                        marginTop: "10px",
                        border: "solid 1px rgba(128, 128, 128, 0.2)",
                        borderRadius: "8px",
                        boxShadow: "0 2px 8px 0 rgba(128, 128, 128, 0.24)",
                      }}
                      transition
                      disablePortal
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          timeout={{ enter: 150, exit: 150 }}
                          style={{
                            transformOrigin:
                              placement === "bottom" ? "center top" : "center bottom",
                          }}
                        >
                          <Paper>
                            <ClickAwayListener
                              onClickAway={() => this.handleshowpoperManualDriver(false, 0)}
                            >
                              <MenuList
                                autoFocusItem={this.state.activestop}
                                id="menu-list-grow"
                                style={{maxHeight:"130px",overflowY:"auto"}}
                                onKeyDown={(e) => {
                                  this.handleListKeyDownTime(e);
                                }}
                              >
                                <MenuItem
                                  className="serviceListOption"
                                  onClick={() => this.handleManualSelectedItem(null)}
                                >
                                  {this.props.t("Allvehicletypes")}
                                </MenuItem>
                                {this.props.vehicleTypes.map((itemM, i) => {
                                  return (
                                    <MenuItem
                                      className="serviceListOption"
                                      onClick={() => this.handleManualSelectedItem(itemM)}
                                    >
                                      {itemM.title}
                                    </MenuItem>
                                  );
                                })}
                                {this.props.vehicleTypes.length == 0 && (
                                  <span style={{ fontSize: "15px", margin: "0 20px" }}>
                                    <Trans i18nKey={"Noinformationfound"}> </Trans>
                                  </span>
                                )}
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                    <input
                      onChange={this.InputOnChange}
                      ref={this.anchorElManual}
                      value={this.props.searchDriverInput}
                      name={"manual_code-" + this.state.idd}
                      onClick={this.handleshowpoperManualDriver}
                      autocomplete="z1x2wd"
                      className="driverCode"
                      placeholder={t("CodeNameFamilyPhonenumber")}
                    />
                  </div>
                )}
              {this.props.manual_id != "" &&
                this.props.manual_id != null && (
                  <React.Fragment>
                    <div
                      className="AddvancedAssaigningdriverInfoManualContainer"
                      style={{ width: "300px" }}
                    >
                      <img
                        src={remove}
                        onClick={this.reMustsetDriver}
                        className="trfhtrs"
                      />
                      <div className="namePhoneContainer">
                        <p className="npcName">
                          {" "}
                          {`${this.props.manual_name}`}{" "}
                        </p>
                        <p className="npcPhone">
                          {" "}
                          {`${this.props.manual_number} `}{" "}
                        </p>
                      </div>

                      <div className="namePhoneContainer usernameContainer">
                        <p className="npcName">
                          {" "}
                          {`${this.props.manual_code}`}{" "}
                        </p>
                        <p className="npcPhone">
                          <Trans i18nKey={"Username"}> </Trans>{" "}
                        </p>
                      </div>

                      {global.config.dispatcherSettings.isVoipActvie && <img src={call} onClick={this.makeOutgoingCall} className="advancedAssaigningDriverEdit" />}


                    </div>
                    {this.props.manual_activeVehicleType && (
                      <manualDriverActiveServic>
                        {" "}
                        <img
                          src={
                            this.props.manual_activeVehicleType.icon
                          }
                          className="serviceimage"
                        />{" "}
                        <span>
                          {" "}
                          {
                            this.props.manual_activeVehicleType.title
                          }{" "}
                        </span>
                      </manualDriverActiveServic>
                    )}
                    <serviceOptions
                      style={{ marginLeft: "0px", width: "100%" }}
                    >
                      {this.props.manual_activeVehicleType &&
                        this.props.manual_activeVehicleType.options.map(
                          (item) => {
                            return item.type === "list" ? (
                              <ListItem {...item} auto={this.props.auto} />
                            ) : (
                              <SelectableItem {...item} auto={this.props.auto} />
                            );
                          }
                        )}
                    </serviceOptions>
                  </React.Fragment>
                )}{" "}
            </div>
          )}
        </div>


        <BackdropCustom
          open={this.state.showpoperAuto}
          onClick={this.handleshowpoperAuto}
        >
          <Popper
            style={{
              width: "475px",
              zIndex: "1300",
              marginTop: "0px",
              maxHeight: "150x",
            }}
            placement={"bottom"}
            id={"passenger"}
            open={this.state.showpoperAuto}
            anchorEl={this.anchorElAuto.current}
          >
            <Paper
              style={{
                width: "476px",
                float: "right",
                borderRadius: "8px",
                paddingTop: "5px",
                marginTop: "10px",
                paddingBottom: "5px",
                right: "271px",
                maxHeight: "150px",
                overflowY: "scroll",
                overflowX: "hidden",
              }}
              elevation={3}
            >
              {this.props.vehicleTypes.map((item) => {
                return (
                  <autodriverselector
                    className="autodriverselectorInList"
                    onClick={() => {
                      this.handleAutoVehicleTypeSelect(item);
                    }}
                  >
                    <img src={item.icon} className="serviceimage" />
                    <servicename>{item.title}</servicename>
                  </autodriverselector>
                );
              })}
            </Paper>
          </Popper>
        </BackdropCustom>


        <BackdropCustom
          open={
            this.state.showpoperManualDriver &&
            this.props.manual_suggestListData.length > 0
          }
          onClick={this.closeshowpoperManualDriver}
        >
          <Popper
            style={{
              width: "355px",
              zIndex: "1500",
              marginTop: "0px",
              maxHeight: "150x",
            }}
            placement={"bottom-end"}
            id={"passenger"}
            open={
              this.state.showpoperManualDriver &&
              this.props.manual_suggestListData.length > 0
            }
            anchorEl={this.anchorElManual.current}
          >
            <Paper
              style={{
                width: "355px",
                float: "right",
                borderRadius: "8px",
                paddingTop: "5px",
                marginTop: "10px",
                paddingBottom: "5px",
                right: "271px",
                maxHeight: "150px",
                overflowY: "scroll",
                overflowX: "hidden",
              }}
              elevation={3}
            >
              {!this.props.manual_suggestListLoading &&
                this.props.manual_suggestListData.length !== 0 &&
                this.props.manual_suggestListData.map((item) => {
                  return (
                    <div
                      className="DriverListItemContainer"
                      onClick={() => {
                        this.handleManualVehicleTypeSelect(item);
                      }}
                    >
                      <span className="DriverListItemName">
                        {/* {`${item.givenName} ${item.familyName}`} */}
                        {`${item.name}`}

                      </span>
                      <span className="DriverListItemNumber">
                        {" "}
                        {item.phone}{" "}
                      </span>
                      <span className="DriverListItemCode">
                        {" "}
                        {item.username}{" "}
                      </span>
                    </div>
                  );
                })}

              {this.props.manual_suggestListLoading && (
                <div>
                  <span
                    className="NotravelsListHeader"
                    style={{ marginTop: "12px" }}
                  >
                    <Trans i18nKey={"poperLoading"}> </Trans>
                  </span>
                  <span className="NotravelsListText">
                    <Trans i18nKey={"fetchingdata"}> </Trans>
                  </span>
                </div>
              )}

              {!this.props.manual_suggestListLoading &&
                this.props.manual_suggestListOrdered &&
                this.props.manual_suggestListData.length === 0 && (
                  <div>
                    <span
                      className="NotravelsListHeader"
                      style={{ marginTop: "12px" }}
                    >
                      <Trans i18nKey={"Noinformationfound"}> </Trans>
                    </span>
                    <span className="NotravelsListText">
                      <Trans i18nKey={"Pleasetryanotherkeyword"}>
                        {" "}
                      </Trans>
                    </span>
                  </div>
                )}
            </Paper>
          </Popper>
        </BackdropCustom>

      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  vehicleTypePureData: state.ViewTravel.pureData
  ? state.ViewTravel.pureData.vehicleType
    ? state.ViewTravel.pureData.vehicleType
    : null
  : null,
  orderType: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.type
      ? state.ViewTravel.pureData.type
      : null
    : null,
  orderId: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.id
      ? state.ViewTravel.pureData.id
      : null
    : null,
  activeRules: state.AdvancedAssaigning.activeRules,
  initialRule: state.AdvancedAssaigning.initialRule,
  vehicleTypes: state.AdvancedAssaigning.vehicleTypes,
  auto_activeVehicleType: state.AdvancedAssaigning.firstDriver.auto.auto_activeVehicleType,
  auto: state.AdvancedAssaigning.firstDriver.autoManualSwitch,
  manual_activeVehicleType: state.AdvancedAssaigning.firstDriver.manual.manual_activeVehicleType,
  searchDriverInput: state.AdvancedAssaigning.firstDriver.manual.searchDriverInput,
  manual_suggestListData: state.AdvancedAssaigning.firstDriver.manual.manual_suggestListData,
  manual_name: state.AdvancedAssaigning.firstDriver.manual.manual_name,
  manual_code: state.AdvancedAssaigning.firstDriver.manual.manual_code,
  manual_family: state.AdvancedAssaigning.firstDriver.manual.manual_family,
  manual_number: state.AdvancedAssaigning.firstDriver.manual.manual_number,
  manual_id: state.AdvancedAssaigning.firstDriver.manual.manual_id,
  OrderPureData: state.ViewTravel.pureData ? state.ViewTravel.pureData : null,
  customerId: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.customerId
      ? state.ViewTravel.pureData.customerId
      : null
    : null,

    driverAssignmentModel: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.driverAssignmentModel
      ? state.ViewTravel.pureData.driverAssignmentModel
      : null
    : null,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
