import {LocalData} from "../../service/localStorage";
const localData = new LocalData();

export function operator_info(data) {
  set_accessToken(data.accessToken);
  set_id(data.userId);
  set_refreshToken(data.refreshToken);
  set_username(data.username);
  set_name(data.name);
}

export function reset() {
  localData.remove("adminToken")
  localData.remove("accessToken");
  localData.remove("refreshToken");
  localData.remove("id");
  window.location.reload();
}

export function resetToLogin() {
  localData.remove("adminToken")
  localData.remove("accessToken");
  localData.remove("refreshToken");
  localData.remove("id");

}


export function set_username(data) {
  localData.set("username", data)
}

export function get_username() {
  return localData.get("username");
}

export function set_name(data) {
  localData.set("name", data);
}

export function get_name() {
  return localData.get("name");
}

export function set_accessToken(data) {
  localData.set("accessToken", data);
}

export function get_accessToken() {
  return localData.get("accessToken");
}


export function set_adminToken(data) {
  localData.set("adminToken", data);
}

export function get_adminToken() {
  return localData.get("adminToken");
}

export function set_refreshToken(data) {
  localData.set("refreshToken", data);
}

export function get_refreshToken() {
  return localData.get("refreshToken");
}

export function set_defaultLocation(data) {
  localData.set("defaultLocation", JSON.stringify(data));
}

export function get_defaultLocation() {
  return JSON.parse(localData.get("defaultLocation"));
}

export function set_currencyCode(data) {
  localData.set("currencyCode", data);
}

export function get_currencyCode() {
  return localData.get("currencyCode");
}

export function set_lang(data) {
  localData.set("lang", data);
}

export function get_lang() {
  return localData.get("lang") ? localData.get("lang") : global.config.Lang;
}
export function set_direction(data) {
  localData.set("direction", data);
}

export function get_direction() {
  return localData.get("direction");
}

export function set_calendarType(data) {
  localData.set("calendarType", data);
}

export function set_tab_orderTypes(data) {
  localData.set("tab_orderTypes", JSON.stringify(data));
}

export function get_tab_orderTypes() {
  return global.config.dispatcherSettings ? global.config.dispatcherSettings.orderTypes : [];
}


export function set_settings_dispatcher(data) {
  localData.set("settings", JSON.stringify(data));
}

export function get_settings_dispatcher() {
  return localData.get("settings") ? JSON.parse(localData.get("settings")) : {};
}

export function get_calendarType() {
  return localData.get("calendarType");
}

export function set_id(data) {
  localData.set("id", data);
}

export function get_id() {
  return localData.get("id");
}

export function set_project(data) {
  localData.set("project", JSON.stringify(data));
}

export function get_project() {
  return JSON.parse(localData.get("project"));
}
