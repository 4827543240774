import acistants from "../../../assets/Annanow_pro_logotype.png";
import FavIcon from "../../../assets/PROJECTS/Annanow_pro.png";

export default function Annanow_pro() {

    return global.config = {
        "ProjectName": "Annanow",
        "LogoType": acistants,
        "BaseApi": "https://rest.anna-now.com",
        "BaseSocket": "https://socket.anna-now.com",
        "BaseTrack": "https://panel.anna-now.com",
        "MapCenter": {
            "lat": "47.3769",
            "lng": "8.5417"
        },
        "Lang": "de",
        "Direction": "ltr",
        "AllLanguages": [
            {
                "lang": "de",
                "direction": "Ltr",
                "i18Lang": "germany",
            }
            , {
                "lang": "en",
                "direction": "Ltr",
                "i18Lang": "English",
            }, {
                "lang": "fr",
                "direction": "Ltr",
                "i18Lang": "French",
            }],
        "FavIcon": FavIcon,
        "Country": "DE",
        "thirdPartyLoginSupport": false,
        mapType: "osm", /* osm | google */
        googleApiKey: ""
    }
}