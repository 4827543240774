import React from "react";
import { Marker } from '@react-google-maps/api';
import { connect } from "react-redux";
import ReactDOMServer from "react-dom/server";
import PickupPin from "../../map-pickup-pin/Pin";
import DropOffPin from "../../map-dropoff-pin/Pin";
import { GetColorByStatus } from "../../../../utils/get-colors-by-status";
import { OverlayView } from '@react-google-maps/api';
import { DropOffPinWithNumber } from "../../map-dropoff-pin-with-number";
import { pickup_isPickuped } from "../../../../helper/module";

// const createtIcon = (icon) => {
//   return new L.Icon({
//     iconUrl: icon,
//     iconAnchor: [20.5, 60],
//     popupAnchor: [0, -35],
//     iconSize: [65, 65],
//     shadowSize: [29, 40],
//     shadowAnchor: [7, 40],
//   });
// };

const createtIcon = (number) => {
  return <div>

  </div>
};
class App extends React.Component {

  render() {
    if (this.props.FavAddressOpenDialoge) {
      return null
    }
    let originShow = (!this.props.OpenNDialoge && !this.props.OpenERDialoge) && !this.props.OpenERDialoge && this.props.VRpureData && ["Pickup", "PickupDelivery", "Ondemand"].indexOf(this.props.orderType) > -1;
    let destinationShow = (!this.props.OpenNDialoge && !this.props.OpenERDialoge) && !this.props.OpenERDialoge && this.props.VRpureData;
    let destination2Show = this.props.VRpureData && this.props.VRpureData.dropoff;
    let deliveryShow =  this.props.VRpureData && this.props.VRpureData.delivery;

    return (
      <React.Fragment>



        {originShow ? (
          <Marker
            position={{ lat: this.props.VRpureData.pickup.coordinates[1], lng: this.props.VRpureData.pickup.coordinates[0] }}
            icon={{
              anchor: { x: 15, y: 50 },
              url: 'data:image/svg+xml;charset=utf-8,' +
                encodeURIComponent(ReactDOMServer.renderToStaticMarkup(<PickupPin bigSize={true} color={pickup_isPickuped(this.props.VRpureData.status) ? "#009b62" : "#" + GetColorByStatus(this.props.VRpureData.status)} />))
            }}
          ></Marker>
        ) : null}

        {/* {(this.props.VRpureData.dropoffs || this.props.VRpureData.dropoff) ? (
          destination2Show &&
          (this.props.VRpureData.dropoffs.length == 0 ? <Marker
            position={{ lat: this.props.VRpureData.dropoff.coordinates[1], lng: this.props.VRpureData.dropoff.coordinates[0] }}
            icon={{
              anchor: { x: 27, y: 41 },
              url: 'data:image/svg+xml;charset=utf-8,' +
                encodeURIComponent(ReactDOMServer.renderToStaticMarkup(<DropOffPin bigSize={true} color={this.props.VRpureData.dropoff.status == "Done" ? "#009b62" : "#" + GetColorByStatus(this.props.VRpureData.status)} />))
            }}
          ></Marker> : <DropOffPinWithNumber bigSize={true} numberStyle={{ position: "absolute", width: "20px", height: "20px", left: "5px", top: "7px", fontSize: "14px", fontWeight: "bold", fontFamily: 'sf_pro', textAlign: "center" }} mapType={this.props.mapType} status={this.props.VRpureData.status} position={{ lat: this.props.VRpureData.dropoff.coordinates[1], lng: this.props.VRpureData.dropoff.coordinates[0] }} number={1} />
          )
        ) : (
          <Marker
            position={{ lat: this.props.VRpureData.delivery.coordinates[1], lng: this.props.VRpureData.delivery.coordinates[0] }}
            icon={{
              anchor: { x: 27, y: 41 },
              url: 'data:image/svg+xml;charset=utf-8,' +
                encodeURIComponent(ReactDOMServer.renderToStaticMarkup(<DropOffPin bigSize={true} color={this.props.VRpureData.delivery.status == "Done" ? "#009b62" : "#" + GetColorByStatus(this.props.VRpureData.status)} />))
            }}
          ></Marker>
        )} */}


        {/* 
        <Marker
            position={{ lat: this.props.VRpureData.dropoffs[0].coordinates[1], lng: this.props.VRpureData.dropoffs[0].coordinates[0] }}
          ></Marker>
 */}

{(destinationShow && !deliveryShow)
          ? ((!destination2Show && this.props.VRpureData.dropoffs.length == 1) ? <Marker
            position={{ lat: this.props.VRpureData.dropoffs[0].coordinates[1], lng: this.props.VRpureData.dropoffs[0].coordinates[0] }}
            icon={{
              anchor: { x: 14, y: 50 },
              url: 'data:image/svg+xml;charset=utf-8,' +
                encodeURIComponent(ReactDOMServer.renderToStaticMarkup(<DropOffPin bigSize={true} color={this.props.VRpureData.dropoffs[0].status == "Done" ? "#009b62" : "#" + GetColorByStatus(this.props.VRpureData.status)} />))
            }}
          ></Marker> : this.props.VRpureData.dropoffs.map((dropoff, i) => {
            return <DropOffPinWithNumber bigSize={true} numberStyle={{
              position: "absolute", width: "20px", height: "20px", left: "5px", top: "7px",
              fontSize: "14px", fontWeight: "bold", fontFamily: 'sf_pro', textAlign: "center"
            }} mapType={this.props.mapType} color={dropoff.status == "Done" ? "#009b62" : null}
              status={this.props.VRpureData.status} position={{ lat: dropoff.coordinates[1], lng: dropoff.coordinates[0] }} number={i + 1} />

          }))
          : null}

          {destination2Show &&<Marker
            position={{ lat: destination2Show.coordinates[1], lng: destination2Show.coordinates[0] }}
            icon={{
              anchor: { x: 14, y: 50 },
              url: 'data:image/svg+xml;charset=utf-8,' +
                encodeURIComponent(ReactDOMServer.renderToStaticMarkup(<DropOffPin bigSize={true} color={destination2Show.status == "Done" ? "#009b62" : "#" + GetColorByStatus(this.props.VRpureData.status)} />))
            }}
          ></Marker> 

          }


{deliveryShow &&  <Marker
            position={{ lat: deliveryShow.coordinates[1], lng: deliveryShow.coordinates[0] }}
            icon={{
              anchor: { x: 14, y: 50 },
              url: 'data:image/svg+xml;charset=utf-8,' +
                encodeURIComponent(ReactDOMServer.renderToStaticMarkup(<DropOffPin bigSize={true} color={deliveryShow.status == "Done" ? "#009b62" : "#" + GetColorByStatus(this.props.VRpureData.status)} />))
            }}
          ></Marker>  }
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  FavAddressOpenDialoge: state.FavAddress.openDialoge,
  OpenVRDialoge: state.ViewTravel.OpenDialoge,
  VRpureData: state.ViewTravel.pureData,
  OpenNDialoge: state.NewOrder.OpenDialoge,
  OpenERDialoge: state.EditOrder.OpenDialoge,
  orderType: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.type
      ? state.ViewTravel.pureData.type
      : null
    : null,

});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(mapStateToProps, mapDispatchToProps)(App);
