import React from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import { read, writeFileXLSX, utils } from "xlsx";
import * as CSV from 'csv-string';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/styles';



const styles = theme => ({
  table: {
    minWidth: "100%",
    minHeight: '400px',
    overflowY: "scroll",
    borderRadius: "10px",
    boxShadow: "none"
  },
  root: {
    overflowY: "scroll",
    height: "100%",
    float: "left",
    position: "absolute",
    borderRadius: "10px",
    boxShadow: "none"
  },
  thclassessroot: {
    padding :"0"
  },

  tbody:{
    display:"flex",
    flexDirection:"column",
    alignItems:"center"
  },
  tableRow: {
    height: 36,
    width:"100%",
    display:"inline-table",
  },
  tableCell: {
    padding: "0px 20px"
  },
  tableCellh: {
    padding: "0px 20px",
    width : 200
  },

});


function createData(name, calories) {
  return { name, calories };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
];


class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      dataEroor: false,
      done: false,
    }
  }


  componentDidMount() {
    this.getData()
  }


  componentWillReceiveProps(nextProps) {
    try {
      if (nextProps.ActiveItem.orderType && !this.props.ActiveItem.orderType) {
        this.getData()
      }
    } catch (error) {

    }
  }


  getJson = (worksheet) => {
    return utils.sheet_to_json(worksheet, {
      raw: true,
    });
  }

  getData = () => {
    let acceptedFile = this.props.ActiveItem.File
    

    let fileReader = new FileReader();
    let chunkSizeToUse = (400 * 1024) // 1 MB chunks
    let offset = (0 - chunkSizeToUse)
    offset += chunkSizeToUse
    fileReader.readAsText(acceptedFile.slice(offset, (offset + chunkSizeToUse)))

    fileReader.onload = (event) => {
      let data = event.target.result;
      let workbook = read(data, { type: "string" /*, sheetRows: 2 */ });
      var first_sheet_name = workbook.SheetNames[0];
      var worksheet = workbook.Sheets[first_sheet_name];
      let rowObject = this.getJson(worksheet)
      if (rowObject.length > 0) {
        this.setState({
          data: rowObject[0],
          dataEroor: false,
          done: true
        })
        this.props.requiredsCheck(rowObject[0])
      } else {
        this.setState({
          data: null,
          dataEroor: true,
          done: true
        })
      }


      

      // let css = {}

      // Object.keys(rowObject[0]).forEach(function (key) {  
      //     css[CSV.parse(key)[0][0]] = CSV.parse(String(rowObject[0][key]))[0][0];
      //   })

    }
    // props.setFile(acceptedFile)

  }


  render() {
    let { t } = this.props;
    const { classes } = this.props;

    

    return (

      <div className="bulkImportDataPreviewContainer">

        {this.props.requiredsError > 7 ? <p className="datapreviewTableLoading">{this.props.t("orderInfoError")}</p> : (
          this.state.done ? (this.state.dataEroor ? "error" : (
            <TableContainer component={Paper} className={classes.root}>
              <Table className={classes.table} aria-label="simple table">
                <TableBody className={classes.tbody}>
                  {Object.keys(this.state.data).map((row) => (
                    <TableRow key={row} className={classes.tableRow}>
                      <TableCell className={classes.tableCell}   align="left">{String(this.state.data[row])}</TableCell>
                      <TableCell className="bulkImportDataPreviewRowKey"  className={classes.tableCellh}   component="th" scope="row">
                        {row}
                      </TableCell>

                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )) :

            <p className="datapreviewTableLoading">{this.props.t("poperLoading")}</p>
        )

        }



      </div>
    );
  }
}

const mapStateToProps = (state) => ({

});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withStyles(styles)(App)));
