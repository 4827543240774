import * as React from 'react'
import styles from './style.module.css';
import IconProvider from '../../../../../design-system/lib/IconProvider';
import { BUSINESS, CLOSE, PERSON } from '../../../../../assets';
import Typography from '../../../../../design-system/lib/Typography';
import Button from '../../../../../design-system/lib/Button';
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { NewCustomerHandleChange, SetRecentAddress, Set_Passenger_Na_Fa_Ph, Set_Pickup_Inputs, Set_Pickup_address } from '../../../../../Redux/actions/actionNames';
import { useTranslation } from 'react-i18next';
import { Get_User_Recent_Address, Register_New_Customer } from '../../../../../Redux/actions/actions';
import { Get_Addressbook_list_Api } from '../../../../../helper/api';
import Input from '../../../../../design-system/lib/Input';
import PickupDriverNewOrder from '../pickup_driver';
import Schedule from '../../../Schedule/Schedule';




function PickupInfoNewOrder({handleShowInfo}) {
  const addressDetail = useSelector((state) => state.NewOrder.newOrder.pickup.addressDetail)
  const block = useSelector((state) => state.NewOrder.newOrder.pickup.block)
  const floor = useSelector((state) => state.NewOrder.newOrder.pickup.floor)
  const unit = useSelector((state) => state.NewOrder.newOrder.pickup.unit)
  const email = useSelector((state) => state.NewOrder.newOrder.pickup.email)
  const senderFullName = useSelector((state) => state.NewOrder.newOrder.pickup.senderFullName)
  const senderPhoneNumber = useSelector((state) => state.NewOrder.newOrder.pickup.senderPhoneNumber)
  const noteForDriver = useSelector((state) => state.NewOrder.newOrder.pickup.noteForDriver)
  const ActivescheduleSetting = useSelector((state) => state.NewOrder.newOrder.service.activeVehicleType ? state.NewOrder.newOrder.service.activeVehicleType.scheduleSetting.isActive : false)
  const scheduleDateBefore = useSelector((state) => state.NewOrder.newOrder.pickup.scheduleDateBefore)
  const scheduleDateAfter = useSelector((state) => state.NewOrder.newOrder.pickup.scheduleDateAfter)
  const orderTypeActive = useSelector((state) => state.NewOrder.newOrder.orderTypeActive)
  const serviceactiveVehicleType = useSelector((state) => state.NewOrder.newOrder.service.activeVehicleType)

  const { t, i18n } = useTranslation();


  const dispatch = useDispatch();


  const closeDialoge = () => {
    handleShowInfo()
  };


  const Emailvalidate = (email) => {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }


  const handlePickUpInputs = (e, name) => {
    dispatch(
      Set_Pickup_Inputs({
        name: name,
        value: e.target.value ? e.target.value : "",
      })
    );
  };

  const phoneNumberOnChange = (res, id) => {
    dispatch(
      Set_Pickup_Inputs({
        name: id,
        value: res,
      })
    );
  };

  return (
    <div className={styles.NewCustomerRegisterContainer}>
      <div className={styles.largeHeader}>
        <Button icon={CLOSE} size="Medium" type="Secondary" onClick={closeDialoge} />
        <div className={styles.largeHeaderTitles}>
          <Typography text={t("Pickup")} weight="Body_Middle_Bold" className={styles.SmallTitle} style={{ margin: "0", padding: "0" }} />
        </div>
      </div>
{/* 
      {orderTypeActive == "PickupDelivery" && <div className={styles.body}>
        <PickupDriverNewOrder />
      </div>} */}
      <div className={styles.body}>
        <Typography weight='Body_Middle_Medium' text={t("Senderinfo")} />

        <div className={styles.inputsConatiner}>
          {(serviceactiveVehicleType?.setting?.isPickupAddressRoomFloorBlockActive && orderTypeActive != "PickupDelivery") && <div className={styles.threeCOLUMNS}>
            <Input type={"text"} placeHolder={t("block")} value={block} inputStyle={{ width: "inherit" }} onChange={(e) => { handlePickUpInputs(e, "block") }} />
            <Input type={"text"} placeHolder={t("Floor")} value={floor} inputStyle={{ width: "inherit" }} onChange={(e) => { handlePickUpInputs(e, "floor") }} />
            <Input type={"text"} placeHolder={t("Unit")} value={unit} inputStyle={{ width: "inherit" }} onChange={(e) => { handlePickUpInputs(e, "unit") }} />
          </div>}

          {(serviceactiveVehicleType?.setting?.isAddressDetailsActive && orderTypeActive == "PickupDelivery") &&
            <Input type={"text"} placeHolder={t("addressDetail")} value={addressDetail} inputStyle={{ width: "inherit" }} onChange={(e) => { handlePickUpInputs(e, "addressDetail") }} />
           }

          {serviceactiveVehicleType?.setting?.isPickupAddressNameActive && <Input isRequired={serviceactiveVehicleType?.setting?.isPickupAddressNameRequired} isRequiredText={t("Required")} type={"text"} placeHolder={t("Fullname")} value={senderFullName} onChange={(e) => { handlePickUpInputs(e, "senderFullName") }} />}


          {serviceactiveVehicleType?.setting?.isPickupAddressPhoneActive && <div className={styles.PhoneInput}>
            <PhoneInput
              defaultCountry={global.config.Country}
              placeholder={t("Phonenumber")}
              value={senderPhoneNumber}
              onChange={(PrePhoneInput) => {
                phoneNumberOnChange(PrePhoneInput, "senderPhoneNumber")
              }
              }
              className={clsx(styles.inputContainer, "senderphonenumberPickup")}
            />
            {serviceactiveVehicleType &&
              serviceactiveVehicleType.setting &&
              serviceactiveVehicleType.setting.isPickupAddressPhoneRequired && String(senderPhoneNumber).length == 0 && (
                <span className={`inputRequired ${styles.PhoneInputRequired}`}>
                  {t("Required")}
                </span>
              )}
          </div>}

          {(serviceactiveVehicleType?.setting?.isPickupAddressEmailActive ) && <Input isRequired={serviceactiveVehicleType?.setting?.isPickupAddressEmailRequired} isRequiredText={t("Required")} error={Emailvalidate(email) ? "" : t("Emailisnotcorrect")} type={"text"} value={email} placeHolder={t("email")} onChange={(e) => { handlePickUpInputs(e, "email") }} />}
         {orderTypeActive != "PickupDelivery" && <Input type={"text"} value={noteForDriver} placeHolder={t("notesfordriver")} onChange={(e) => { handlePickUpInputs(e, "noteForDriver") }} />}

        </div>

      </div>

      {ActivescheduleSetting && <div className={styles.body}>
        <Typography text={t("SchedulePickup(Optional)")} weight="Body_Middle_Bold" className={styles.SmallTitle} style={{ margin: "0", padding: "0" }} />

        <Schedule orderType={orderTypeActive}
          scheduleDateBefore={scheduleDateBefore}
          scheduleDateAfter={scheduleDateAfter}
        />
      </div>}

    </div>
  )
}

export default PickupInfoNewOrder