import aOfferLogo from "../../../assets/aOfferLogo.png";
import FavIcon from "../../../assets/PROJECTS/aOffer.png";

export default function AOffer () {
    
    return global.config = {
  
    "ProjectName": "AOffer",
    "LogoType": aOfferLogo,
    "BaseApi": "https://rest.aofferonline.com",
    "BaseSocket": "https://socket.aofferonline.com",
    "BaseTrack": "https://panel.aofferonline.com",
    "MapCenter": {
        "lat": "30.052283288915845",
        "lng": "31.24218992754401"
    },
    "Lang": "en",
    "Direction": "ltr",
    "AllLanguages": [{
        "lang": "en",
        "direction": "ltr",
        "i18Lang": "English"
    }],
    "FavIcon": FavIcon,
    "Country": "EG",
    "thirdPartyLoginSupport": true  ,
    mapType :"osm", 
    googleApiKey : ""
} }