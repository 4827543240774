import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import arrow from "../../../../../assets/chevron-down.svg";
import address_database from "../../../../../assets/address_database.webp";
import MapSearch from "../../../../../assets/MapSearch.webp";
import closeInput from "../../../../../assets/closeInput.webp";

import Collapse from "@material-ui/core/Collapse";
import { withTranslation, Trans } from "react-i18next";
import Backdrop from "@material-ui/core/Backdrop";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import { withStyles } from "@material-ui/core/styles";
import {
  Set_Pickup_Inputs,
  Set_Pickup_address,
  Set_SearchAutoCompleteLoading,
  Set_latlng,
  OpenNewFav,
  SetopenAddressBookInNewOrder,
  Set_PureـPickupDeliveryType_Pickup,
} from "../../../../../Redux/actions/actionNames";
import {
  SearchAutoCompleteAction,
  SearchAddressAction,
  PlaceIdDetailsAction,
  getFavAddressInfoAction,
  SearchAutoCompleteAction_local_address,
} from "../../../../../Redux/actions/actions";
import closeCircle from "../../../../../assets/close-cricle.svg";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import CircularProgress from "@material-ui/core/CircularProgress";
import Schedule from "./Schedule";
import momentJalaali from "moment-jalaali";
import { get_lang } from "../../../../../helper/UserData/userdate";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { getCenterMap, getCountryWithDomain, setCenterMap } from "../../../../../helper/module";
import newAddressbook from "../../../../../assets/icon-ic-pin-dark.svg";
import PickupDriver from "./Pickup_driver";
import { debounce, isEmpty, throttle } from "lodash";
import icclockcolorfull from "../../../../../assets/ic-clock-colorfull.svg";
import iconicperson from "../../../../../assets/icon-ic-person.svg";
import icClock from "../../../../../assets/ic-clock.svg";
import iconIcPin from "../../../../../assets/icon-ic-pin.svg";
import { v4 as uuidv4 } from 'uuid';


const BackdropCustom = withStyles({
  backdrop: {
    zIndex: 5000,
    backgroundColor: "rgba(0, 0, 0, 0)",
    color: "#00000",
  },
})((props) => (
  <Backdrop
    {...props}
    style={{
      zIndex: 5000,
      backgroundColor: "rgba(0, 0, 0, 0)",
      color: "#00000",
    }}
  />
));

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLocalAddressActive: global.config.dispatcherSettings.isLocalAddressActive,
      localSearchIsActive: global.config.dispatcherSettings.isLocalAddressActive ? true : false,

      showDropDown: false,
      id: "pickup",
      loading: false,
      ordered: true,
      showpoper: false,
      showpoperSaved: false,
      getFavAddressLoading: false,
      searchAddrees: "",
      list: [0, 1, 2, 3, 5, 6, 9, 5, 8],
      sessionToken: null
    };
    this.anchorEl = React.createRef();
    this.mapSetView = this.mapSetView.bind(this);
    this.handlePickUpInputs = this.handlePickUpInputs.bind(this);

    this.throttleHandleChange = debounce(
      this.throttleHandleChange.bind(this),
      800
    );
  }

  handleChangeLocalSearch = () => {
    this.setState({
      localSearchIsActive: !this.state.localSearchIsActive
    }, () => {
      String(this.props.address).length > 2 && this.notThrottleHandleChange(String(this.props.address), this.state.sessionToken)
    })
  };


  handleDropDown = () => {
    this.setState({
      showDropDown: !this.state.showDropDown
    })
  };
  OpenDropDown = () => {
    this.setState({
      showDropDown: true
    })
  };

  CloseDropDown = () => {
    this.setState({
      showDropDown: false
    })
  };


  throttleHandleChange(value) {
    let center = getCenterMap(this.props.mapRef, this.props.center)
    this.props.dispatch(Set_SearchAutoCompleteLoading(true));


    if (!this.state.localSearchIsActive) {
      this.props.dispatch(
        SearchAutoCompleteAction(this.props.dispatch, {
          query: value,
          latitude: center.lat,
          longitude: center.lng,
          sessionToken: this.state.sessionToken
        })
      );
    } else {
      this.props.dispatch(
        SearchAutoCompleteAction_local_address(this.props.dispatch, {
          query: value
        })
      );
    }



  }

  notThrottleHandleChange(value) {
    let center = getCenterMap(this.props.mapRef, this.props.center)
    this.props.dispatch(Set_SearchAutoCompleteLoading(true));



    if (!this.state.localSearchIsActive) {
      this.props.dispatch(
        SearchAutoCompleteAction(this.props.dispatch, {
          query: value,
          latitude: center.lat,
          longitude: center.lng,
          sessionToken: this.state.sessionToken
        })
      );
    } else {
      this.props.dispatch(
        SearchAutoCompleteAction_local_address(this.props.dispatch, {
          query: value
        })
      );
    }

  }


  handleshowpoper = () => {
    if (this.props.activePickupDropOff.type != "pickup" && !this.state.showpoper) {
      this.handleCollapse();
      setTimeout(() => {
        this.setState({
          showpoper: !this.state.showpoper,
          searchAddrees: "",
          showpoperSaved: false,
        });
      }, 600);
    } else {
      this.setState({
        showpoper: !this.state.showpoper,
        searchAddrees: "",
        showpoperSaved: false,
      });
    }

  };

  openAddressbook = () => {
    if (this.props.customerId || this.props.BusinessId) {
      if (this.props.openAddressBook && this.props.addressBookSource) {
        this.props.dispatch(SetopenAddressBookInNewOrder(false, "", null, ""));
      } else {
        this.props.dispatch(
          SetopenAddressBookInNewOrder(
            true,
            "pickup",
            null,
            "AddressbookPickup"
          )
        );
      }
    }
  };

  handleshowpoperDrop = () => {
    if (this.props.customerId || this.props.BusinessId) {
      this.setState({
        showpoperSaved: !this.state.showpoperSaved,
        searchAddrees: "",
        showpoper: false,
      });
    }
  };

  handleSavedDropInput = (e) => {
    this.setState({
      searchAddrees: e.target.value,
    });
  };

  componentDidMount() {
    this.props.setId(this.state.id);
  }

  idgenerator = () => {
    var number = Math.random(); // 0.9394456857981651
    number.toString(36); // '0.xtis06h6'
    var id = number.toString(36).substr(2, 9); // 'xtis06h6'
    return id.toString();
  };

  handleCollapse = () => {
    this.props.handleCollapse({
      type: "pickup",
      id: "pickup",
    });
  };

  handlePickUpInputs = (e, name) => {
    if (name === "address" || e.target.id === "address") {

      this.setState({
        sessionToken: this.state.sessionToken ? this.state.sessionToken : uuidv4()
      })

      this.props.dispatch(
        Set_Pickup_address({
          adresss_loc: { lng: "", lat: "" },
          placeId: "",
        })
      );
      if (e.target.value != "") {
        // this.props.dispatch(
        //   SearchAutoCompleteAction(this.props.dispatch, {
        //     query: e.target.value,
        //     latitude: this.props.mapRef.current.leafletElement.getCenter().lat,
        //     longitude: this.props.mapRef.current.leafletElement.getCenter().lng,
        //   })
        // );
        e.target.value.length > 2 && this.throttleHandleChange(e.target.value, this.state.sessionToken)
      }

    }
    this.props.dispatch(
      Set_Pickup_Inputs({
        name: name || e.target.id,
        value: e.target.value,
      })
    );
  };

  phoneNumberOnChange = (res, id) => {
    this.props.dispatch(
      Set_Pickup_Inputs({
        name: id,
        value: res,
      })
    );
  };

  handleList = (item) => {
    if (this.props.activePickupDropOff.type != "pickup") {
      this.handleCollapse();
    }
    this.CloseDropDown()
    if (item.coordinates) {

      this.props.dispatch(
        Set_latlng({ lng: item.coordinates[0], lat: item.coordinates[1] })
      );


      this.props.dispatch(
        Set_Pickup_address({
          ...item,
          address: item.secondaryAddress
            ? item.address + " , " + item.secondaryAddress
            : item.address,
          adresss_loc: { lng: item.coordinates[0], lat: item.coordinates[1] },
        })
      );
      this.mapSetView({
        lng: item.coordinates[0],
        lat: item.coordinates[1],
      });

      this.setState({
        sessionToken: null
      })



    } else {

      this.setState({
        sessionToken: this.state.sessionToken ? this.state.sessionToken : uuidv4()
      }, () => {
        this.props.dispatch(
          PlaceIdDetailsAction(this.props.dispatch, { placeId: item.placeId, sessionToken: this.state.sessionToken }, (res) => {

            this.setState({
              sessionToken: null
            })

            this.props.dispatch(
              Set_latlng({ lng: res.coordinates[0], lat: res.coordinates[1] })
            );

            this.props.dispatch(
              Set_Pickup_address({
                ...item,
                address: res.address,
                adresss_loc: { lng: res.coordinates[0], lat: res.coordinates[1] },
              })
            );
            this.props.dispatch(
              Set_latlng({
                lng: res.coordinates[0],
                lat: res.coordinates[1],
              })
            );
          })
        );

      })

    }
  };

  resetPickup = () => {
    this.props.dispatch(
      Set_Pickup_address({
        address: "",
        adresss_loc: { lng: "", lat: "" },
        placeId: "",
      })
    );
  };

  handleRecentList = (item) => {
    if (this.props.activePickupDropOff.type != "pickup") {
      this.handleCollapse();
    }

    this.props.dispatch(
      Set_latlng({ lng: item.coordinates[0], lat: item.coordinates[1] })
    );



    this.CloseDropDown()
    this.props.dispatch(
      Set_Pickup_address({
        ...item,
        address: item.address,
        adresss_loc: { lng: item.coordinates[0], lat: item.coordinates[1] },
        block: item.buildingBlock,
        floor: item.floor,
        unit: item.room
      })
    );
    this.mapSetView({
      lng: item.coordinates[0],
      lat: item.coordinates[1],
    });

  }

  hanldeOpenNewFav = () => {
    this.props.dispatch(OpenNewFav(true));
  };

  mapSetView = (e) => {
    setCenterMap(this.props.mapRef, e)

    // this.props.mapRef.current.leafletElement.setView(e, 17);
  };

  handleListFavAddress = (e) => {
    this.setState({
      showpoperSaved: false,
      getFavAddressLoading: true,
    });




    this.props.dispatch(
      getFavAddressInfoAction(
        this.props.dispatch,
        { customerId: e.customerId, id: e.id },
        (res) => {
          this.setState({
            getFavAddressLoading: false,
          });
          this.props.dispatch(
            Set_latlng({ lng: res.coordinates[0], lat: res.coordinates[1] })
          );
          this.mapSetView({ lng: res.coordinates[0], lat: res.coordinates[1] });

          this.props.dispatch(
            Set_Pickup_Inputs({
              name: "address",
              value: res.address,
            })
          );
          this.props.dispatch(
            Set_Pickup_Inputs({
              name: "adresss_loc",
              value: { lng: res.coordinates[0], lat: res.coordinates[1] },
            })
          );
          this.props.dispatch(
            Set_Pickup_Inputs({
              name: "block",
              value: res.buildingBlock,
            })
          );
          this.props.dispatch(
            Set_Pickup_Inputs({
              name: "floor",
              value: res.floor,
            })
          );

          this.props.dispatch(
            Set_Pickup_Inputs({
              name: "unit",
              value: res.room,
            })
          );
          this.props.dispatch(
            Set_Pickup_Inputs({
              name: "senderFullName",
              value: res.fullName,
            })
          );
          this.props.dispatch(
            Set_Pickup_Inputs({
              name: "senderPhoneNumber",
              value: res.phone,
            })
          );
          this.props.dispatch(
            Set_Pickup_Inputs({
              name: "noteForDriver",
              value: res.description,
            })
          );
        }
      )
    );
  };


  validate(email) {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }



  handleChooseDriver = () => {

    this.props.dispatch(Set_PureـPickupDeliveryType_Pickup(
      { chooseDriver: !this.props.showDriver }
    ))
  }

  render() {
    const { t } = this.props;
    return (
      <passerngerContainer className="pickupCustomized">
        <div className="pickuptitleContainer">
          <pickuptitle>
            <span>
              {" "}
              <Trans i18nKey={"Pickup"}> </Trans>{" "}
            </span>
            {["PickupDelivery"].indexOf(
              this.props.orderTypeActive
            ) > -1 &&
              <div
                className="forceAssaignConatiner choosedriverpickupdriver"
                onClick={this.handleChooseDriver}
              >
                <span>
                  {" "}
                  <Trans i18nKey={"ChooseDriver"}> </Trans>{" "}
                </span>
                <div
                  className={`forceAssaigncheckbox ${this.props.showDriver && "ActiveforceAssaigncheckbox"
                    }`}
                >
                  {" "}
                </div>
              </div>}
          </pickuptitle>


        </div>
        <div className={`pickupInputContainer `} >
          <ClickAwayListener onClickAway={this.CloseDropDown}>
            <div className={`NewOrderPickupContainer_DRDO ${(this.state.showDropDown && this.props.adresss_loc.lat == "") && "DriverManualSelectContainer_DRDO_Active"}`} style={{ zIndex: "12" }}>
              <div className="pickupDropOffInputResetContainer">
                {this.state.isLocalAddressActive && <img src={this.state.localSearchIsActive ? address_database : MapSearch} onClick={this.handleChangeLocalSearch} className={"localSearchIsActiveicon"} />}
                <input
                  value={this.props.address}
                  onChange={(e) => this.handlePickUpInputs(e, "address")}
                  autocomplete="93658369"

                  ref={this.anchorEl}
                  onClick={this.OpenDropDown}
                  placeholder={t(this.state.localSearchIsActive ? "SearchinLocalDatabase" : "SearchinMapService")}
                />



                {this.props.adresss_loc.lat !== "" && (
                  <img
                    src={closeCircle}
                    onClick={this.resetPickup}
                    style={{ margin: "0  8.6px" }}
                  />
                )}


              </div>

              {this.props.adresss_loc.lat == "" && <React.Fragment>
                {(this.state.showDropDown && this.props.recentAddress.length == 0 && !isEmpty(this.props.customerId) && isEmpty(this.props.address)) &&
                  <div className="pickupDropOffRecentAddress">
                    <img src={icclockcolorfull} />
                    <p className="NoRecentAddress"><Trans i18nKey={"NoRecentAddress"}> </Trans></p>
                    <p className="NoRecentAddressD"><Trans i18nKey={"NoRecentAddressD"}> </Trans></p>
                  </div>
                }



                {(this.state.showDropDown && isEmpty(this.props.customerId) && isEmpty(this.props.address)) &&
                  <div className="pickupDropOffRecentAddress">
                    <img src={iconicperson} />
                    <p className="NoRecentAddress"><Trans i18nKey={"CustomerIsNotChoosed"}> </Trans></p>
                    <p className="NoRecentAddressD"><Trans i18nKey={"CustomerIsNotChoosedD"}> </Trans></p>
                  </div>
                }

                {(this.state.showDropDown && !isEmpty(this.props.customerId) && !isEmpty(this.props.recentAddress) && isEmpty(this.props.address)) &&
                  <div className="pickupDropOffRecentAddressItemListContainer">
                    {this.props.recentAddress.map((item) => {
                      return <div className="pickupDropOffRecentAddressItemList" onClick={() => {
                        this.handleRecentList(item);
                      }}>
                        <img src={icClock} />
                        <span> {item.address}</span>
                        <p> {`${item.buildingBlock} ${!isEmpty(item.room) ? ", " + item.room : ""} ${!isEmpty(item.floor) ? ", " + item.floor : ""}`} {(isEmpty(item.buildingBlock) && isEmpty(item.room) && isEmpty(item.floor)) && "----"} </p>
                      </div>
                    })}
                  </div>
                }




                {(this.state.showDropDown && !isEmpty(this.props.address) && this.props.suggestPickUpDropOffListData.length > 0 &&
                  !this.props.suggestListPickUpDropOffLoading) &&
                  <div className="pickupDropOffRecentAddressItemListContainer">
                    {this.props.suggestPickUpDropOffListData.map((item) => {
                      return <div className="pickupDropOffRecentAddressItemList" onClick={() => {
                        this.handleList(item);
                      }}>
                        <img src={iconIcPin} />
                        <span> {item.address}</span>
                        <p> {isEmpty(item.secondaryAddress) ? "----" : item.secondaryAddress} </p>
                      </div>
                    })}
                  </div>
                }


                {(this.state.showDropDown && this.props.suggestListPickUpDropOffLoading && !isEmpty(this.props.address)) &&
                  <div className="pickupDropOffRecentAddress">
                    <img src={iconIcPin} />
                    <p className="NoRecentAddress"><Trans i18nKey={"poperLoading"}> </Trans></p>
                    <p className="NoRecentAddressD"><Trans i18nKey={"fetchingdata"}> </Trans></p>
                  </div>
                }


                {(this.state.showDropDown && !this.props.suggestListPickUpDropOffLoading &&
                  this.props.suggestPickUpDropOffListData.length === 0 && !isEmpty(this.props.address)) &&
                  <div className="pickupDropOffRecentAddress">
                    <img src={iconIcPin} />
                    <p className="NoRecentAddress"><Trans i18nKey={this.state.localSearchIsActive ? "NoResultfromLocalDatabase" : "NoResultfromMapService"}> </Trans></p>
                    <p className="NoRecentAddressD"><Trans i18nKey={"NoResultfromLocalDatabaseD"}> </Trans></p>
                  </div>
                }



              </React.Fragment>}






            </div>

          </ClickAwayListener>

          <img
            style={{
              opacity:
                this.props.customerId || this.props.BusinessId ? "1" : ".5",
              cursor:
                this.props.customerId || this.props.BusinessId
                  ? "pointer"
                  : "not-allowed",
            }}
            onClick={() => this.openAddressbook()}
            src={newAddressbook}
            className={`newOrder_AddressBookToggle ${this.props.addressBookSource && "newOrder_AddressBookToggleActive"}`}
          />



          <img
            onClick={this.handleCollapse}
            src={arrow}
            className={`pickupCollIconVR megl0  ${this.props.activePickupDropOff.type === "pickup" && "rotate180"
              }`}
          />


        </div>
        <Collapse
          className="pickupcollapsecontainer pickupcollapsecontainer yodmvydj"
          in={this.props.activePickupDropOff.type === "pickup"}
          timeout="auto"
          unmountOnExit
          style={{ marginTop: "2px", border: "none" }}
        >
          {this.state.getFavAddressLoading && (
            <CircularProgress size={18} className="pickupLoading" />
          )}
          {this.props.activeVehicleType
            ? this.props.activeVehicleType.setting &&
            this.props.activeVehicleType.setting.isAddressDetailsActive && (
              <React.Fragment>

                {(this.props.activeVehicleType.setting.isPickupAddressRoomFloorBlockActive) && <>

                  <input
                    value={this.props.block}
                    onChange={this.handlePickUpInputs}
                    id="block"
                    name="5a7a593f"
                    autocomplete="5a7a593f"
                    className="blockPickup"
                    placeholder={t("block")}
                  />
                  <input
                    value={this.props.floor}
                    onChange={this.handlePickUpInputs}
                    id="floor"
                    name="9e26dc9a"
                    autocomplete="9e26dc9a"
                    className="floorPickup"
                    placeholder={t("Floor")}
                  />
                  <input
                    value={this.props.unit}
                    onChange={this.handlePickUpInputs}
                    id="unit"
                    name="cf0d4937"
                    autocomplete="cf0d4937"
                    className="UnitPickup"
                    placeholder={t("Unit")}
                  />

                </>}

                <div className="fourInputsContainer">

                  {(this.props.activeVehicleType.setting.isPickupAddressNameActive) &&
                    <div className="inputWithRequired">
                      <input
                        value={this.props.senderFullName}
                        name="abd27ae9"
                        autocomplete="abd27ae9"
                        onChange={this.handlePickUpInputs}
                        id="senderFullName"
                        className="senderfullnamePickup"
                        placeholder={t("senderfullname")}
                      />

                      {(this.props.activeVehicleType.setting.isPickupAddressNameRequired) && <span className="inputRequired">{this.props.t("Required")}</span>
                      }
                    </div>}


                  {(this.props.activeVehicleType.setting.isPickupAddressPhoneActive) &&
                    <div className="inputWithRequired" >

                      <PhoneInput
                        defaultCountry={global.config.Country}
                        placeholder={this.props.t("Phonenumber")}
                        value={this.props.senderPhoneNumber}
                        onChange={(PrePhoneInput) =>
                          this.phoneNumberOnChange(
                            PrePhoneInput,
                            "senderPhoneNumber"
                          )
                        }
                        id="senderPhoneNumber"
                        className="senderphonenumberPickup"
                      />

                      {(this.props.activeVehicleType && this.props.activeVehicleType.setting && this.props.activeVehicleType.setting.isPickupAddressPhoneRequired) && <span className="inputRequired">{this.props.t("Required")}</span>
                      }
                    </div>}

                  {(this.props.activeVehicleType.setting.isPickupAddressEmailActive) && <div className="inputWithRequired"
                    style={{ border: this.props.email.length > 0 ? this.validate(this.props.email) ? "solid 1px rgba(128, 128, 128, 0.2)" : "1px solid red" : "solid 1px rgba(128, 128, 128, 0.2)" }}
                  >

                    <input
                      value={this.props.email}
                      name="4efb28c6"
                      autocomplete="4efb28c6"
                      onChange={this.handlePickUpInputs}
                      id="email"
                      className="notesfordriverPickup"
                      placeholder={t("email")}
                    // style={{
                    //   border: this.props.email.length > 0 ? this.validate(this.props.email) ? "solid 1px rgba(128, 128, 128, 0.2)" : "1px solid red" : "solid 1px rgba(128, 128, 128, 0.2)",
                    //   width: this.props.email.length > 0 ? this.validate(this.props.email) ? "none" : "calc(100% - 11px)" : "none",
                    //   height: this.props.email.length > 0 ? this.validate(this.props.email) ? "32px" : "32px" : "32px"
                    // }}
                    />

                    {(this.props.activeVehicleType.setting.isPickupAddressEmailRequired) && <span className="inputRequired">{this.props.t("Required")}</span>
                    }
                  </div>}

                  <div className="inputWithRequired" >

                    <input
                      value={this.props.noteForDriver}
                      name="4efb28c6"
                      autocomplete="4efb28c6"
                      onChange={this.handlePickUpInputs}
                      id="noteForDriver"
                      className="notesfordriverPickup"
                      placeholder={t("notesfordriver")}
                    />
                  </div>

                </div>

              </React.Fragment>
            )
            : null}

          {this.props.ActivescheduleSetting && <Schedule orderType={this.props.orderTypeActive}
            scheduleDateBefore={this.props.scheduleDateBefore}
            scheduleDateAfter={this.props.scheduleDateAfter}
          />}
        </Collapse>

        <Popper
          style={{
            width: "475px",
            zIndex: "12",
            marginTop: "0px",
            maxHeight: "198px",
          }}
          placement={"bottom-end"}
          id={"passenger"}
          open={this.state.showpoperSaved}
          anchorEl={this.anchorEl.current}
          className="left25"
        >
          {" "}
          <ClickAwayListener onClickAway={this.handleshowpoperDrop}>
            <Paper
              className="xxx"
              style={{
                width: "476px",
                float: "right",
                borderRadius: "8px",
                paddingTop: "5px",
                marginTop: "10px",
                right: "271px",
                maxHeight: "201px",
                overflowY: "scroll",
                overflowX: "hidden",
              }}
              elevation={3}
            >
              <input
                value={this.state.searchAddrees}
                onChange={this.handleSavedDropInput}
                className="savedAddressSearch"
                placeholder={this.props.t("Search")}
              />
              <div
                style={{
                  maxHeight: "111px",
                  overflowY: "scroll",
                  overflowX: "hidden",
                }}
              >
                {this.props.favAddresss.length === 0 &&
                  this.props.customerId.length > 0 && (
                    <div>
                      <span className="NotravelsListText">
                        <Trans i18nKey={"Noaddressavailable"}> </Trans>
                      </span>

                      <div
                        onClick={this.hanldeOpenNewFav}
                        className="divManagefavoriteaddresses"
                        style={{ marginTop: "10px" }}
                      >
                        <span className="Managefavoriteaddresses">
                          <Trans i18nKey={"Managefavoriteaddresses"}> </Trans>
                        </span>
                      </div>
                    </div>
                  )}

                {this.props.favAddresss.length > 0 &&
                  this.props.favAddresss
                    .filter((item) => {
                      return this.state.searchAddrees.length === 0
                        ? true
                        : item.title.includes(this.state.searchAddrees);
                    })
                    .map((item) => {
                      return (
                        <favAddressselectorInList
                          onClick={() => {
                            this.handleListFavAddress(item);
                          }}
                        >
                          <name> {item.title}</name>
                          <addres> {item.address}</addres>
                        </favAddressselectorInList>
                      );
                    })}
              </div>

              {this.props.favAddresss.length !== 0 &&
                this.props.customerId.length > 0 && (
                  <div
                    className="divManagefavoriteaddresses"
                    onClick={this.hanldeOpenNewFav}
                  >
                    <span className="Managefavoriteaddresses">
                      <Trans i18nKey={"Managefavoriteaddresses"}> </Trans>
                    </span>
                  </div>
                )}
            </Paper>
          </ClickAwayListener>
        </Popper>
        {["PickupDelivery"].indexOf(this.props.orderTypeActive) > -1 &&
          this.props.showDriver && <PickupDriver />}
      </passerngerContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  favAddresss: state.FavAddress.list,
  customerId: state.NewOrder.newOrder.customer.id,
  BusinessId: state.NewOrder.newOrder.customer.BusinessId,
  showDriver: state.NewOrder.newOrder.pickup.driver.chooseDriver,
  mapRef: state.Map.mapRef,
  center: state.Map.center,
  address: state.NewOrder.newOrder.pickup.address,
  adresss_loc: state.NewOrder.newOrder.pickup.adresss_loc,
  block: state.NewOrder.newOrder.pickup.block,
  floor: state.NewOrder.newOrder.pickup.floor,
  unit: state.NewOrder.newOrder.pickup.unit,
  email: state.NewOrder.newOrder.pickup.email,
  senderFullName: state.NewOrder.newOrder.pickup.senderFullName,
  senderPhoneNumber: state.NewOrder.newOrder.pickup.senderPhoneNumber,
  noteForDriver: state.NewOrder.newOrder.pickup.noteForDriver,
  scheduleDateBefore: state.NewOrder.newOrder.pickup.scheduleDateBefore,
  scheduleDateAfter: state.NewOrder.newOrder.pickup.scheduleDateAfter,
  list: state.NewOrder.newOrder.pickup.list,
  loading: state.NewOrder.newOrder.pickup.loading,
  activePickupDropOff: state.NewOrder.newOrder.activePickupDropOff,
  center: state.Map.center,
  suggestListPickUpDropOffLoading:
    state.NewOrder.newOrder.suggestListPickUpDropOffLoading,
  suggestListPickUpDropOffOrdered:
    state.NewOrder.newOrder.suggestListPickUpDropOffOrdered,
  suggestPickUpDropOffListData:
    state.NewOrder.newOrder.suggestPickUpDropOffListData,
  lang: state.LocalData.lang,
  activeVehicleType: state.NewOrder.newOrder.service.activeVehicleType,
  addressBookSource: state.Addressbook.openAddressBookInNewOrder
    ? state.Addressbook.sourceOfAddressBookInNewOrder
      ? state.Addressbook.sourceOfAddressBookInNewOrder.type == "pickup"
      : false
    : false,
  openAddressBook: state.Addressbook.openAddressBookInNewOrder,
  orderTypeActive: state.NewOrder.newOrder.orderTypeActive,
  ActivescheduleSetting: state.NewOrder.newOrder.service.activeVehicleType ? state.NewOrder.newOrder.service.activeVehicleType.scheduleSetting.isActive : false,
  recentAddress: state.NewOrder.newOrder.customer.recentAddreess
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
