import React, { useCallback, useEffect, useRef, useState } from 'react'
import styles from './style.module.css';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../../../../design-system/lib/Modal';
import Typography from '../../../../../design-system/lib/Typography';
import DropDown from '../../../../../design-system/lib/DropDown';
import { ADD_SQUARE, CLOSE_DARK, Edit, FLAG, ROUTE_OPTIMIZE, Routes_deactive, SEARCH_NOT_FOUND, Status, home } from '../../../../../assets';
import Button from '../../../../../design-system/lib/Button';
import { RouteApi } from '../../../../../service/API/route';
import { OrderApi } from '../../../../../service/API/order';
import { get_id } from '../../../../../helper/UserData/userdate';
import { snack } from '../../../../../utils/SnackbarUtilsConfigurator';
import Input from '../../../../../design-system/lib/Input';
import SearchList from '../../../../../design-system/lib/SearchList';
import { useTranslation } from 'react-i18next';
import Switch from '../../../../../design-system/lib/Switch';
import { getCenterMap } from '../../../../../helper/module';
import { v4 as uuidv4 } from 'uuid';
import { PlaceIdDetailsAction, SearchAutoCompleteAction, SearchDriverAction, Voip_OutgoingCall } from '../../../../../Redux/actions/actions';
import AddressItem from '../../../../shared/AddressItem';
import DriverListItem from '../../../../shared/DriverListItem';
import { SetSharedDataVehicleTypes, Set_Manual_ActiveVehicleType, Set_SearchAutoComplete } from '../../../../../Redux/actions/actionNames';
import { ServicesApi } from '../../../../../service/API/service';
import { debounce } from '@material-ui/core';
import call from "../../../../../assets/call.svg";
import star from "../../../../../assets/star.svg";
import deliveryProfile from "../../../../../assets/delivery-man.png";
import IconProvider from '../../../../../design-system/lib/IconProvider';



function TableAddRoute({ onClose }) {
  const auto_activeVehicleType = useSelector((state) => state.NewOrder.newOrder.driver.auto_driver.auto_activeVehicleType)
  const auto_suggestListLoading = useSelector((state) => state.NewOrder.newOrder.driver.auto_driver.auto_suggestListLoading)
  const auto_suggestListData = useSelector((state) => state.SharedData.vehicleTypes )
  const auto_suggestListOrdered = useSelector((state) => state.NewOrder.newOrder.driver.auto_driver.auto_suggestListOrdered)
  const manual_activeVehicleType = useSelector((state) => state.NewOrder.newOrder.driver.manual_driver.manual_activeVehicleType)
  const manual_name = useSelector((state) => state.NewOrder.newOrder.driver.manual_driver.manual_name)
  const manual_code = useSelector((state) => state.NewOrder.newOrder.driver.manual_driver.manual_code)
  const manual_family = useSelector((state) => state.NewOrder.newOrder.driver.manual_driver.manual_family)
  const manual_id = useSelector((state) => state.NewOrder.newOrder.driver.manual_driver.manual_id)
  const manual_suggestListLoading = useSelector((state) => state.NewOrder.newOrder.driver.manual_driver.manual_suggestListLoading)
  const manual_suggestListData = useSelector((state) => state.NewOrder.newOrder.driver.manual_driver.manual_suggestListData)
  const manual_suggestListOrdered = useSelector((state) => state.NewOrder.newOrder.driver.manual_driver.manual_suggestListOrdered)
  const lang = useSelector((state) => state.LocalData.lang)
  const searchDriverInput = useSelector((state) => state.NewOrder.newOrder.driver.manual_driver.searchDriverInput)
  const forceAssaigning = useSelector((state) => state.NewOrder.newOrder.driver.forceAssaigning)
  const sendOrder = useSelector((state) => state.NewOrder.newOrder.sendOrder)
  const customer = useSelector((state) => state.NewOrder.newOrder.customer)
  const options = useSelector((state) => state.NewOrder.newOrder.driver.options)
  const manual_number = useSelector((state) => state.NewOrder.newOrder.driver.manual_driver.manual_number)
  const driverAllvehicletypes = useSelector((state) => state.NewOrder.newOrder.driver.manual_driver.driverAllvehicletypes)
  const activeService = useSelector((state) => state.NewOrder.newOrder.service.activeVehicleType)
  const manual_vehicleTypes = useSelector((state) => state.NewOrder.newOrder.driver.manual_driver.manual_vehicleTypes)


  const suggestListLoading = useSelector((state) => state.NewOrder.newOrder.suggestListPickUpDropOffLoading)
  const suggestListData = useSelector((state) => state.NewOrder.newOrder.suggestPickUpDropOffListData)


  const mapRef = useSelector((state) => state.Map.mapRef)
  const center = useSelector((state) => state.Map.center)

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [driver, setDriver] = useState({
    name:"",
    id:null
  });
  const [isLoadDeliveriesMandatory, setIsLoadDeliveriesMandatory] = useState("deactivated" );

  const [routeName, setRouteName] = useState("");
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [createRouteLoading, setCreateRouteLoading] = useState(false);

  const [sessionToken, setSessionToken] = useState(uuidv4());
  const [firstRoute, setFirstRoute] = useState({
    "coordinates": null,
    "address": ""
  });
  const [lastRoute, setLastRoute] = useState({
    "coordinates": null,
    "address": ""
  });

  const StartChangeHandler = React.useCallback((e) => {
    if(e){
      setFirstRoute({ ...firstRoute, address: e.target.value })
    }else{
      dispatch(Set_SearchAutoComplete([]));
    }

    if (e && String(e.target.value).length > 0) {
      debouncedSearchAddress(e.target.value)
    }

  }, [mapRef, center, sessionToken, firstRoute]);



  
  const EndChangeHandler = React.useCallback((e) => {
    if(e){
      setLastRoute({ ...lastRoute, address: e.target.value })
    }else{
      dispatch(Set_SearchAutoComplete([]));
    }
    if (e && String(e.target.value).length > 0) {
      debouncedSearchAddress(e.target.value)
    }

  }, [mapRef, center, sessionToken, lastRoute]);


  const StarthandleList = React.useCallback((item) => {

    if (item.coordinates) {

      setFirstRoute({
        "coordinates": item.coordinates,
        "address": item.secondaryAddress
        ? item.address + " , " + item.secondaryAddress
        : item.address
      })


    } else {

      let ss = sessionToken ? sessionToken : uuidv4()


      dispatch(
        PlaceIdDetailsAction(dispatch, { placeId: item.placeId, sessionToken: ss }, (res) => {

          setFirstRoute({
            "coordinates": res.coordinates,
            "address": res.address,
          })

        })
      );

    }
  }, [firstRoute , dispatch]);

  const EndhandleList = React.useCallback((item) => {

    if (item.coordinates) {

      setLastRoute({
        "coordinates": item.coordinates,
        "address": item.secondaryAddress
        ? item.address + " , " + item.secondaryAddress
        : item.address
      })


    } else {

      let ss = sessionToken ? sessionToken : uuidv4()


      dispatch(
        PlaceIdDetailsAction(dispatch, { placeId: item.placeId, sessionToken: ss }, (res) => {

          setLastRoute({
            "coordinates": res.coordinates,
            "address": res.address,
          })

        })
      );

    }
  }, [lastRoute , dispatch]);


  
  const handleManualVehicleTypeSelect = (service) => {

    FindLocalVehicletype(
      service.vehicleTypes,
      auto_suggestListData,
      (call) => {
        if (call) {
          let activeVehicleType = null;

          if (manual_activeVehicleType) {
            activeVehicleType = manual_activeVehicleType;
          } else {
            if (call.length != 0) {
              activeVehicleType = call[0];
            } else {
              snack.warning_i18("DriverVehicleTypeError")
            }
          }

          activeVehicleType &&
            dispatch(
              Set_Manual_ActiveVehicleType({

                manual_activeVehicleType: activeVehicleType,
                driverAllvehicletypes: call,
                manual_name: service.name,
                manual_family: "",
                manual_number: service.phone,
                manual_id: service.id,
                manual_code: service.username,
                manual_vehicleTypes : service.vehicleTypes
              })
            );
        }
      }
    );

  }



  const FindLocalVehicletype = React.useCallback((driverVehicletypes, localservives, call) => {
    let vehicletype = [];

    driverVehicletypes.forEach((element) => {
      localservives.forEach((localservive) => {
        if (element == localservive.id) {
          vehicletype.push(localservive);
        }
      });
    });

    call(vehicletype);
  }, [firstRoute , dispatch]);


  const SearchDriver = React.useCallback((input, manual) => {

    dispatch(
      SearchDriverAction(dispatch, {
        searchRegex: input ? input : driver.name,
        vehicleTypes: manual ? manual.id : (selectedVehicle ? selectedVehicle.id : ""),
      })
    );

  }, [driver, selectedVehicle]); 

  const SearchAddress = React.useCallback((value) => {
    console.log("SearchAddress ",value)
    let a_center = getCenterMap(mapRef, center);
    dispatch(
      SearchAutoCompleteAction(dispatch, {
        query: value,
        latitude: a_center.lat,
        longitude: a_center.lng,
        sessionToken: sessionToken,
      })
    );
  }, [mapRef , center]); 



  const handleSelectVehicle = React.useCallback((e) => {

   setSelectedVehicle(e)

   debouncedSearchDriver(driver.name , e)


  }, [driver, selectedVehicle]); 

  const InputOnChange = (e) => {
  

    if(e){
      setDriver({
        name:e.target.value,
        id:null
      })
      debouncedSearchDriver(e.target.value)
    }

  };


  const handleSelectedItem = (item) => {
    setDriver(item)
  };





  useEffect(() => {
    (async () => {
      const Services = new ServicesApi();
        const vehicleTypeData = await Services.VehicleType()
        dispatch(SetSharedDataVehicleTypes(vehicleTypeData.VehicleTypes))
    })();
}, []);


  const HandleCreateRoute = React.useCallback( async () => {

    setCreateRouteLoading(true)
    let Route = new RouteApi()
    let data = await Route.createRoute({
      "loadDeliveriesStep":isLoadDeliveriesMandatory,
      "name": routeName,
      "startLocation": {
          "coordinates":firstRoute.coordinates,
          "address": firstRoute.address
      },
      ... lastRoute.coordinates ? {"endLocation": {
          "coordinates": lastRoute.coordinates,
          "address": lastRoute.address
      }}:{},
      ... (driver.id && activeIndex == 0) ? {"driverId": driver.id} : {},
      "dispatcherId": get_id()
  })

  if(data){
    onClose(false , data)
    setCreateRouteLoading(false)
    const myEvent = new CustomEvent("routeAdded");
    document.dispatchEvent(myEvent);
  }


  }, [activeIndex, firstRoute , lastRoute , driver ,routeName,onClose , isLoadDeliveriesMandatory]); 


  const debouncedSearchDriver = useCallback(
    debounce(SearchDriver, 500)
  , []);

  const debouncedSearchAddress = useCallback(
    debounce(SearchAddress, 500)
  , []);


  function makeOutgoingCall() {
    dispatch(Voip_OutgoingCall(dispatch, {
      "to": driver.phone,
      "orderId": null,
      "customerId": null,
      "driverId": driver.id,
      "dispatcherId": get_id()
    }))
  }



  return (
    <>
      <Modal size='Large' title='Add Route' onclose={()=>{  onClose(false)}} ConfirmationOnClick={HandleCreateRoute}
        ConfirmationText='Add'
        body={<div className={styles.bodyContainer}>
          <div className={styles.routeInfoContainerx} style={{ height: "145px" }}>
            <Typography weight='Body_Middle_Bold' textColor='TextDark' text='Route info' style={{ marginBottom: "12px" }} />
            <Input ContainerclassName={styles.simpleInput} IconStyle={{ width: "16px", height: "16px" }} placeHolder='Route name' value={routeName} onChange={(e) => { setRouteName(e.target?.value ? e.target?.value : "") }} type={"string"} iconStart={Routes_deactive} />
        
            <SearchList onChange={StartChangeHandler} style={{ zIndex: "3" }} value={firstRoute.address} placeHolder={"Start Location"} isloading={suggestListLoading} listItemOnclick={StarthandleList} error={false} errorDescription={""} errorTitle={""} errorIcon={null}
              ListItemChildren={AddressItem} noResult={String(firstRoute.address).length > 0 &&(!suggestListData || suggestListData.length == 0)} noResultIcon={SEARCH_NOT_FOUND} noResultTitle='No result found' list={suggestListData.length > 0 ? suggestListData : null} inputIcon={<img src={home} />} containerClassName={styles.start}
            />
            <SearchList onChange={EndChangeHandler} style={{ zIndex: "1" }} value={lastRoute.address} placeHolder={"End Location ( Optional )"} isloading={suggestListLoading} listItemOnclick={EndhandleList} error={false} errorDescription={""} errorTitle={""} errorIcon={null}
              ListItemChildren={AddressItem} noResult={String(lastRoute.address).length > 0 &&(!suggestListData || suggestListData.length == 0)} noResultIcon={SEARCH_NOT_FOUND} noResultTitle='No result found' list={suggestListData.length > 0 ? suggestListData : null} inputIcon={<img src={FLAG} />} containerClassName={styles.end}
            />
          </div>


          <div className={styles.routeInfoContainer} style={{maxHeight: "49px" ,height:"auto"}}>
            <div className={styles.DriverTitleContainer} >
              <Typography weight='Body_Middle_Bold' textColor='TextDark' text='Options' />
            </div>
            <div className={styles.DriverTitleContainer} >
              <Typography weight='Body_Middle_Regular' textColor='TextDark' text='Load Deliveries:' />
              <Switch items={[t("No"),t("Optional"),t("Force")]} index={isLoadDeliveriesMandatory=="deactivated" ? 0 : (isLoadDeliveriesMandatory=="optional" ? 1 : 2)} handleSelection={(e) => { setIsLoadDeliveriesMandatory(e==0 ? "deactivated" : (e==1 ? "optional" : "mandatory")) }} />
            </div>
          </div>

          {driver.id && 
          <div className={styles.firstRow} style={{ height: "46px", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
            <span className={styles.profileContainer}>
              <img className={styles.dprof} src={(driver && driver?.avatarPhoto?.length > 0) ? driver?.avatarPhoto : deliveryProfile} onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = deliveryProfile;
              }} />
              {driver && <span className={styles.starContainer} ><img src={star} /><span>{driver.rate}</span></span>
              }

            </span>

            <div className={styles.nameUsernameContainer}>
              <span className={styles.name}>{driver.name}</span>
              <span className={styles.username}> @{driver.username} </span>
            </div>

            <img className={styles.call} src={call} onClick={() => makeOutgoingCall()} />
            <IconProvider Icon={CLOSE_DARK} style={{ marginInlineStart: "20px", cursor: "pointer" }} onClick={() => { setDriver({   name:"",
    id:null}) }} />
        </div>}


          {!driver.id && <div className={styles.routeInfoContainer} style={{maxHeight:activeIndex == 0 && "69px" ,minHeight:activeIndex == 0 && "69px" ,height:"auto"}}>
            <div className={styles.DriverTitleContainer} style={{marginBottom: activeIndex==1 ? "0px" :""}}>
              <Typography weight='Body_Middle_Bold' textColor='TextDark' text='Driver' />
              <Switch items={[t("Manual"), t("Unassigned")]} index={activeIndex} handleSelection={(e) => { setActiveIndex(e) }} />
            </div>
            {activeIndex == 0 && <SearchList containerClassName={styles.driverSearchSelelct} onChange={InputOnChange} style={{ zIndex: "1" }} value={driver.name} placeHolder={"Code, Name, Family, Phone number"} isloading={manual_suggestListLoading} listItemOnclick={handleSelectedItem} error={false} errorDescription={""} errorTitle={""} errorIcon={null}
              ListItemChildren={DriverListItem} list={(manual_suggestListOrdered && String(driver.name).length > 0) ? (manual_suggestListData.length > 0 ? manual_suggestListData : null) : null}
              noResult={String(driver.name).length > 0 &&(!manual_suggestListData || manual_suggestListData.length == 0)} noResultIcon={SEARCH_NOT_FOUND} noResultTitle='No result found'
              inputInnerChildren={<DropDown  style={{zIndex:"10"}} size='Small' placement='topEnd' text={selectedVehicle ? selectedVehicle.title : t("Allvehicletypes")} data={auto_suggestListData} onClick={handleSelectVehicle} />}
            />}
          </div>}


        </div>}
        bodyStyle={{ backgroundColor: "transparent", width: "100%", padding: "0px" }}
        ConfirmationLoading={createRouteLoading}
        ConfirmationDisabled={activeIndex==0 ? (!driver.id || !firstRoute.coordinates  || String(routeName).trim().length == 0) : (!firstRoute.coordinates  || String(routeName).trim().length == 0)}
      />
    </>

  )
}


export default TableAddRoute




