import { SetCategoryOrderListData, SetCategoryOrderListLoading, SetSearchOrderListData, SetSearchOrderListRegexLoading, SetSearchOrderListRegexRequested } from "../../../../../../Redux/actions/actionNames";
import { store } from "../../../../../../Redux/store";
import { OrderApi } from "../../../../../../service/API/order";
import momentJalaali from "moment-jalaali";
import { FilterStatusByCategory } from "../../../../../../utils/filter-status-by-category";
import { restClient } from "../../../../../../service/API/axios";
import _, { isNumber, isObject } from 'lodash';
import Axios from "axios";

const CancelToken = Axios.CancelToken;

let cancelQuerySearch;


function removeNullFields(obj) {
  for (var key in obj) {
    if (obj[key] === null) {
      delete obj[key];
    } else if (typeof obj[key] === 'object') {
      removeNullFields(obj[key]);
    }
  }
  return obj;
}

function gtelteSetter(obj) {
  for (var key in obj) {
    if (["gte", "equal"].indexOf(key) > -1) {
      if (isObject(obj[key])) {
        obj[key] = momentJalaali(obj[key].day + "/" + obj[key].month + "/" + obj[key].year + " 00:01", "DD/MM/YYYY HH:mm").format("x")
      }
    } else if (["lte"].indexOf(key) > -1) {
      if (isObject(obj[key])) {
        obj[key] = momentJalaali(obj[key].day + "/" + obj[key].month + "/" + obj[key].year + " 23:59", "DD/MM/YYYY HH:mm").format("x")
      }
    } else if (typeof obj[key] === 'object') {
      gtelteSetter(obj[key]);
    }
  }
  return obj;
}




export const getsearchList = async (categoryName, Listdata, loadMore) => {
  cancelQuerySearch && cancelQuerySearch("holy ...");

  // const intervalId = setInterval(() => {
  //   store.dispatch(SetSearchOrderListRegexRequested(true))
  //   store.dispatch(SetSearchOrderListRegexLoading(true))
  // }, 500);

  let lastElement = _.last(Listdata);


  let storeData = store.getState();
  let endCursor = storeData.OrderList.search.endCursor
  let hasNextPage = storeData.OrderList.search.hasNextPage
  let selectedStatus = JSON.parse(storeData.OrderList.selectedStatus)
  let selectedServices = JSON.parse(storeData.OrderList.selectedServices)
  let selectedVehicleType = JSON.parse(storeData.OrderList.selectedVehicleType)
  let status = categoryName ? FilterStatusByCategory(categoryName, selectedStatus) : selectedStatus
  let selectedZone = JSON.parse(storeData.OrderList.zones)

  let regex = [];

  if (storeData.OrderList.search.regexText && String(storeData.OrderList.search.regexText).length > 0) {
    storeData.OrderList.search.regexFields.value.forEach(element => {
      regex.push({ text: String(storeData.OrderList.search.regexText), field: String(element) })
    });
  }

  let params = {
    cursor: storeData.OrderList.cursor,
    cursorSort: storeData.OrderList.cursorSort,
    "dueDate[gte]": storeData.OrderList.DateRange
      ? momentJalaali(storeData.OrderList.DateRange.startDate).unix() + "000"
      : null,
    "dueDate[lte]": storeData.OrderList.DateRange
      ? (storeData.OrderList.DateRange.endDate ? momentJalaali(storeData.OrderList.DateRange.endDate).unix() + "000" : null)
      : null,
    services: selectedServices,
    status: status,
    zones: selectedZone,
    vehicleTypes: selectedVehicleType,
    isScheduled: ((storeData.OrderList.now && storeData.OrderList.isScheduled) || (!storeData.OrderList.now && !storeData.OrderList.isScheduled)) ? null : ((storeData.OrderList.now && !storeData.OrderList.isScheduled) ? false : true),
    size: categoryName ? 4 : 20,
    // regexText: storeData.OrderList.search.regexText,
    // regexFields: storeData.OrderList.search.regexFields.value,
    category: categoryName,
    type: storeData.OrderList.ActiveOrderType,
    columns: ['status', 'isUrgent', 'customerId', 'driver', 'pickup', 'dropoff', 'dropoffs', 'delivery', 'isScheduled', 'currentCandidateDrivers', 'driverAssignmentModel', 'driverId', 'delayAt', 'baseETA', 'realETA', 'isPinned', 'isDelayed', 'isPossibleForDelay', 'candidateDriversCount', 'dispatchAt', 'isDispatching', 'vehicleType'],
    regex: regex.length > 0 ? regex : null,
    after : loadMore ?  (hasNextPage ? endCursor : null):null ,

    // lastCursor: lastElement ? lastElement[storeData.OrderList.cursor] : null
  }
  console.log("5dparams ", params,)

  store.dispatch(SetSearchOrderListRegexRequested(true))
  store.dispatch(SetSearchOrderListRegexLoading(true))

  const response = await restClient.request({
    method: 'get',
    url: "/api/dispatcher/order",
    params: gtelteSetter(removeNullFields(params)),
    cancelToken: new CancelToken(function executor(c) {
      cancelQuerySearch = c;
    }),
  });


  // let Order = new OrderApi()
  // let data = await Order.OrdersList(removeNull(params))

  if (response) {
    // clearInterval(intervalId)
    store.dispatch(SetSearchOrderListRegexLoading(false))
    store.dispatch(SetSearchOrderListData({ loadMore, dataLength: response.data.data.orders.length, ...response.data.data }))
  } else {
    // clearInterval(intervalId)
    store.dispatch(SetSearchOrderListRegexLoading(false))
  }


};


const removeNull = (objIn) => {
  let objOut = objIn;
  Object.keys(objOut).forEach(key => {
    if (objOut[key] === null) {
      delete objOut[key];
    }
  });
  return objOut
}