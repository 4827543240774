import styles from './style.module.css';
import deliveryProfile from "../../../../../assets/delivery-man.png";
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Tag from '../../../../../design-system/lib/Tag';
import { box_gray } from '../../../../../assets';

export const DriversListItem = ({ avatarPhoto, ordersCount, isOnline, name ,onClick , username}) => {
    const dispatch = useDispatch();
    const repositoryRegexText = useSelector((state) => state.OrderList.search.regexText)
    const { t } = useTranslation();


    function handleTab(e) {

    }


    return <div className={styles.DriversListItemContainer} onClick={onClick}>
        <img src={avatarPhoto.length > 0 ? avatarPhoto : deliveryProfile} onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = deliveryProfile;
        }} alt="" className={clsx(styles.avatar, (ordersCount > 0 && isOnline) && styles.withOrder, (ordersCount == 0 && isOnline) && styles.noOrder)} />
        <div className={styles.twoColumnContaiiner}>
            <span className={styles.name}>
                {name}
            </span>
            <span className={styles.orders}>
            @{username} {/* {ordersCount} {t("Orders")} */}
            <Tag iconSize='12' backgroundColor='GrayUltraLight' color='TextDark' weight='Body_Small_Regular' size='Small' icon={box_gray} text={`${ordersCount} ${t("Orders")}`} />
            </span>
        </div>
    </div>

};




