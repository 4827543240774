import GripxIGO from "../../../assets/Gripx.png";
import FavIcon from "../../../assets/PROJECTS/FavIcon_Gripx.png";



export default function Gripx() {
    
    return global.config = {
    "ProjectName": "Gripx",
    "LogoType": GripxIGO,
    "BaseApi": "https://rest.gripx.ph",
    "BaseSocket": "https://socket.gripx.ph",
    "BaseTrack": "https://backoffice.gripx.ph",
    "MapCenter": {
        "lat": 14.599484846179033,
        "lng": 120.98419354674783
    },
    "Lang": "en",
    "Direction": "ltr",
    "AllLanguages": [{
        "lang": "en",
        "direction": "ltr",
        "i18Lang": "English"
    }],
    "FavIcon": FavIcon,
    "Country": "PH",
    "thirdPartyLoginSupport": true  ,
    mapType :"osm", /* osm | google */ 
    googleApiKey : ""
}}