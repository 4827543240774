import React, { useLayoutEffect, useState } from "react";
import { Marker, OverlayView } from '@react-google-maps/api';
import Pin from "../Pin";
import FreePin from "../FreePin";
import deliveryProfile from "../../../../assets/delivery-man.png";
import { ClickAwayListener } from "@material-ui/core";
import styles from './style.module.css';
import { useDispatch, useSelector } from "react-redux";
import { SetActiveDriverList, SetDriverListOpenDialoge, SetDriverPopUp, SetSelectedMonitoringDriver } from "../../../../Redux/actions/actionNames";
import { GetDriverInfoAction, GetDriverOrderListAction } from "../../../../Redux/actions/actions";
import { useTranslation } from "react-i18next";
import { useDriverMonitoringPin } from "../../../../hooks/useDriverMonitoringPin";
import "./customPopup.css";
import { getOrderMonitoringBounding } from "../getOrderMonitoring";


const App = ({ position, hasActiveOrder, driver }) => {
  const [width, setWidth] = useState(0);
  const dispatch = useDispatch();
  const DriverPopUp = useSelector((state) => state.Monitoring.DriverPopUp)
  const selectedOrderDriver = useSelector((state) => state.Monitoring.selectedOrderDriver)
  const cursor = useSelector((state) => state.OrderList.cursor)
  const cursorSort = useSelector((state) => state.OrderList.cursorSort)

  const selectedOrder = useSelector((state) => state.Monitoring.selectedOrder)
  const { t, i18n } = useTranslation();
  let {driverIsSelected} = useDriverMonitoringPin(driver.id)

  function disableSelectedDriver() {
    dispatch(SetDriverPopUp(null))
    dispatch(SetSelectedMonitoringDriver(null))
  };

  function HandleDriverselect(Driverselected) {
    dispatch(SetDriverPopUp(Driverselected.id))
    dispatch(SetSelectedMonitoringDriver(Driverselected.id))
    getOrderMonitoringBounding(Driverselected)

  };


  function OpenUserProfile(selectedDriver) {
    // dispatch(SetDriverListOpenDialoge(false))
    dispatch(SetActiveDriverList(selectedDriver));
    dispatch(GetDriverInfoAction(dispatch, selectedDriver.id))
    dispatch(SetDriverListOpenDialoge(true))
    dispatch(SetDriverPopUp(null))

    dispatch(
      GetDriverOrderListAction(dispatch, {
        cursor: cursor,
        cursorSort: cursorSort,
        status: ["Assigned", "PickedUp", "Started", "Arrived"],
        columns: ['status', 'isUrgent', 'customerId', 'driver', 'pickup', 'dropoff', 'dropoffs', 'delivery', 'isScheduled', 'currentCandidateDrivers', 'driverAssignmentModel', 'driverId', 'delayAt', 'baseETA', 'realETA', 'isPinned', 'isDelayed', 'isPossibleForDelay', 'candidateDriversCount', 'dispatchAt', 'isDispatching', 'vehicleType'],
        size: 200,
        driverId: selectedDriver.id,
      } , false)
    );
  };


  useLayoutEffect(() => {

    if (DriverPopUp && !width) {
      var element = document.getElementById(`${driver.id}popup`);
      var positionInfo = element && element.getBoundingClientRect();
      var width = positionInfo && positionInfo.width;
      width && setWidth(width);
    }
  }, [DriverPopUp]);

  if(!driverIsSelected){
    return null
  }

  return <OverlayView
    position={position}
    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
  >
    <>

      <Pin hasActiveOrder={hasActiveOrder} driver={driver} onClick={() => { HandleDriverselect(driver) }}
          style={{ marginLeft: "-21px", marginTop: "-50px" ,marginBottom:"10px"}} />

      {DriverPopUp == driver.id &&
        <ClickAwayListener onClickAway={disableSelectedDriver}>
          <div id={`${driver.id}popup`} className={"monitoringDriverSelected markerIcon " + styles.OsmMarkerPopUp}
            style={{ left: `-${(width / 2) - 0}px` }}>
            <span className={styles.OsmMarkerPopUpName}> {driver.name} </span>
            <span className={styles.OsmMarkerPopUpButton} onClick={()=>OpenUserProfile(driver)}> {t("Profile")} </span>
          </div>
        </ClickAwayListener>}
    </>
  </OverlayView>

};

export default App



{/* <Marker
position={position}
icon= {{
  anchor: { x: 14, y: 14 },
  url: 'data:image/svg+xml;charset=utf-8,' +
  encodeURIComponent(ReactDOMServer.renderToStaticMarkup(<Pin hasActiveOrder={hasActiveOrder} driver={driver} />))
}}
onClick={() => { HandleDriverselect && HandleDriverselect(driver) }}
></Marker> */}