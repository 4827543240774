import Kwikrb from "../../../assets/Kwikrlogotype.png";
import FavIcon from "../../../assets/PROJECTS/Kwikr.png";


export default function Kwikr() {

    return  global.config = {
    "ProjectName": "Kwikr",
    "LogoType": Kwikrb,
    "BaseApi": "https://rest.kwikr.com",
    "BaseSocket": "https://socket.kwikr.com",
    "BaseTrack": "https://portal.kwikr.com",
    "MapCenter": {
        "lat": "51.504251327098515",
        "lng": "-0.11538650727355824"
    },
    "Lang": "en",
    "Direction": "ltr",
    "AllLanguages": [{
        "lang": "en",
        "direction": "ltr",
        "i18Lang": "English"
    }],
    "FavIcon": FavIcon,
    "Country": "GB",
    "thirdPartyLoginSupport": true  ,
    mapType :"osm", /* osm | google */ 
    googleApiKey : ""
}}