import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import arrow from "../../../../../../../assets/chevron-down.svg";
import play_btn from "../../../../../../../assets/ic_play.svg";
import pause_btn from "../../../../../../../assets/ic_pause.svg";
import Collapse from "@material-ui/core/Collapse";
import momentJalaali from "moment-jalaali";
import { get_accessToken, get_calendarType, get_lang } from '../../../../../../../helper/UserData/userdate';
import WaveSurfer from 'wavesurfer.js';
import { debounce, throttle } from "lodash";
import { GetEventVoipRecorded_action } from "../../../../../../../Redux/actions/actions";

class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            canPlay: false ,
            pause : true,
            remainingTime : "00:00" ,
            errorInloadData:false
        }

        this.wavesurfer =null
        this.toggle = this.toggle.bind(this)
        this.togglePlayPause =this.togglePlayPause.bind(this);
        this.throttleHandleChange = throttle(
            this.throttleHandleChange.bind(this),
            1000
        );
    }


    throttleHandleChange(value) {
        if(this.wavesurfer.isPlaying()) {
            var totalTime = this.wavesurfer.getDuration(),
                currentTime = this.wavesurfer.getCurrentTime(),
                remainingTime = totalTime - currentTime;

                this.setState({
                    remainingTime : convertHMS(remainingTime.toFixed(1))
                })
            
            // document.getElementById('time-total').innerText = totalTime.toFixed(1);
            // document.getElementById('time-current').innerText = currentTime.toFixed(1);
            // document.getElementById('time-remaining').innerText = remainingTime.toFixed(1);
        }
      }

    eventOnclick = () => {
        this.getEstimatedDuration()
        this.props.onClick(this.props.id == this.props.selectedEvent ? null : this.props.id, this.props.id == this.props.selectedEvent ? null : this.props.coordinates)
    }

    toggle() {

    try {
         if(this.props.isCallRecordingReady){
    
                this.wavesurfer = WaveSurfer.create({
                    container: `#${this.props.voipRecordId}`,
                    waveColor: 'violet',
                    progressColor: '#1080ff',
                    waveColor: '#96c7ff',
                    barWidth: 2.6,
                    barHeight: 1, // the height of the wave
                    barGap: 2,
                    height: 20,
                    barRadius: 2,
                    xhr : {  mode: 'cors', method: 'GET', requestHeaders: [ { key: 'Accept-Language', value:  get_lang() }, { key: 'Authorization', value: "Bearer " + get_accessToken() } ]}
        
                });
        
                this.wavesurfer.on('ready',  ()=> {
                    this.getEstimatedDuration()
                    this.setState({
                        canPlay: true
                    })
                });
                this.wavesurfer.on('audioprocess', this.throttleHandleChange);
        
                this.wavesurfer.on('error', ()=> {
                    this.setState({
                        errorInloadData : true
                    })
                });
        
                this.wavesurfer.load(`${global.config.BaseApi}/api/dispatcher/voip/${this.props.voipRecordId}/file`);
               
        
            }
           
    } catch (error) {
        
        this.setState({
            errorInloadData : true
        })
    }
    }

    componentDidMount() {
        this.toggle()
    }

    componentWillReceiveProps() {
        //   if(this.props.selectedEvent === this.props.id){
        //       this.toggle()
        //   }
    }

    componentWillUnmount(){
        if(this.wavesurfer){
            this.wavesurfer.destroy()
        }
    }


    getEstimatedDuration(){
        if(this.wavesurfer){
            var totalTime = this.wavesurfer.getDuration(),
            currentTime = this.wavesurfer.getCurrentTime(),
            remainingTime = totalTime - currentTime;

            this.setState({
                remainingTime : convertHMS(remainingTime.toFixed(1))
            })
        

        }
        // if(this.wavesurfer){
        //     this.wavesurfer.destroy()
        // }else{
        //     return "00:00"
        // }
    }

    togglePlayPause(){
        if(this.wavesurfer && this.wavesurfer.isReady){
            if(this.state.pause){
                this.wavesurfer.play()
                this.setState({
                    pause: false
                })
    
            }else{
                this.wavesurfer.pause()
                this.setState({
                    pause: true
                })
            }
        }
      
    }



    render() {
        return (
            <div className="EventTimeLine_ListItem">

                <div className="lineContainer">
                    <div className="upper" style={{ backgroundColor: this.props.isFirst && "white" }}></div>
                    <div className="loweer" style={{ backgroundColor: this.props.isLast && "white" }}></div>
                </div>
                <div className="circule"></div>
                {this.props.firstItemInArray && <span className="ltext">  {momentJalaali(this.props.createdAt).locale(get_calendarType()).format("D MMM YY")} </span>
                }
                <div className="detailsContainer" >

                    <div className="detailsContainer_details">
                        <span className="status"> {this.props.title} { /* <Trans i18nKey={ "incoming"}> </Trans> */} </span>
                        {/* <span className="source"> <Trans i18nKey={`${this.props.source}`}> </Trans> </span> */}
                        <span className="time">  {momentJalaali(this.props.createdAt).locale(get_calendarType()).format("HH:mm")} </span>
                        <span className="statusDescription">{this.props.description}  </span>
                       { this.props.isCallRecordingReady &&
                            <img
                            src={arrow} className={`collapse `} onClick={this.eventOnclick}
                            style={{ transform: this.props.selectedEvent === this.props.id ? "rotate(180deg)" : "", transition: ".2s all ease-in-out" }}
                        />
                     }


                    </div>

                    <div
                        className={`detailsContaine_collapse_hide ${this.props.selectedEvent === this.props.id && "detailsContaine_collapse_Open"}`}
                    >
                    {this.props.source == "dispatcher" &&  <p className="detailsContaine_collapse_description">
                        <Trans i18nKey={(this.props.source ) + "_Voip"}> </Trans>  <span className="duprcui"> {this.props.name} </span>
                        </p>}
                        <div className="events_detailsContaine_collaps_prenext_audio">
                            {!this.state.errorInloadData && <React.Fragment>
                                <img className="playPause" src={this.state.pause ?play_btn : pause_btn } onClick={this.togglePlayPause} />
                                <div id={this.props.voipRecordId} className="waveform" style={{width :"170px"}}></div>
                                <span className="estimated">{this.state.remainingTime}</span> 
                            </React.Fragment>}

                            {this.state.errorInloadData && <span className="errorINloadingVoipFile"> {this.props.t("orderInfoError")} </span>}
                        </div>
                    </div>
                </div>



            </div>
        );
    }
}

const mapStateToProps = (state) => ({

});
const mapDispatchToProps = (dispatch) => ({
    dispatch: dispatch,
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(App));




function convertHMS(value) {
    const sec = parseInt(value, 10); // convert value to number if it's string
    let hours   = Math.floor(sec / 3600); // get hours
    let minutes = Math.floor((sec - (hours * 3600)) / 60); // get minutes
    let seconds = sec - (hours * 3600) - (minutes * 60); //  get seconds
    // add 0 if value < 10; Example: 2 => 02
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
    return minutes+':'+seconds; // Return is HH : MM : SS
}
