export const SpanishLabel = {
    Enter_the_following_information_to_enter: "Enter the following information to enter",
    login: "login",
    Username: "Username",
    password: "Password",
    Orders: "Orders",
    Online: "Online",
    Offline: "Offline",
    Info: "Info",
    email: "Email",
    vehicletypes: "servicetypes",
    loginstatus: "login status",
    lastonlinetime: "last online time",
    fetchingdata: "Receiving information...",
    poperLoading: "please wait",
    Noinformationfound: "No information found",
    Drivers: "Drivers",
    otherdropoffs: "other dropoffs",
    paymentmethod: "Payment method",
    Receiver: "By Receiver",
    Sender: "By Sender",
    Balance: "Balance",
    Cash: "Cash",
    Wallet: "Wallet",
    Close: "Close",
    noVehicleType: "There is no service type to display",
    Fname: "First name",
    Lname: "Last name",
    Pnumber: "Phone number",
    driver: "Driver",
    pleaseSellectService: "Please Select Service Type",
    Pleasetryanotherkeyword: "Please try another keyword",
    DropOff: "Drop off",
    Noaddressavailable: "No address available",
    Managefavoriteaddresses: "Manage addresses",
    address: "Address",
    Saved: "Saved",
    block: "Block",
    Floor: "Floor",
    Unit: "Unit",
    notesfordriver: "Notes for driver",
    Price: "Price",
    EditOrder: "Edit order",
    Firstname: "First name",
    Lastname: "Last name",
    Phonenumber: "Phone number",
    Search: "Search",
    Service: "Service",
    Passenger: "Customer",
    Pickup: "Pickup",
    Pricedetails: "Price details",
    Fee: "Fee",
    Fornow: "For now",
    now:"Now",
    Scheduled: "Scheduled",
    before: "before :",
    Settime: "Set time",
    after: "after :",
    confirm: "Confirm",
    Cancel: "Cancel",
    Addresses: "Addresses",
    Savedaddresses: "Saved addresses",
    new: "New",
    Editaddress: "Edit address",
    Saveaddress: "Save address",
    Language: "Language",
    en: "English",
    fa: "Persian",
    English: "English",
    Persian: "Persian",
    Logout: "Log out",
    logotTitle: "Log out from account?",
    logoutDescription: "You will be logged out from account and you should login later",
    confirmLogout: "Yes, log out",
    skipLogout: "Cancel",
    ascending: "Ascending",
    descending: "Descending",
    "sort[createdAt]": "Date",
    "sort[pickupScheduleDate]": "Pickup Schedule Time ",
    "sort[statusUpdatedAt]": "Sort by update at",
    SelectDateRange: "Date Range",
    StartDate: "Start Date",
    EndDate: "End Date",
    Today: "Today",
    Yesterday: "Yesterday",
    ThisWeek: "This Week",
    LastWeek: "Last Week",
    Last7Days: "Last 7 Days",
    ThisMonth: "This Month",
    LastMonth: "Last Month",
    Last3Month:"Last 3 Month",
    monitoring: "Monitoring",
    Haveorder: "Have order",
    Estimated: "Estimated",
    ReceiverCash: "Pay by Receiver",
    SenderCash: "Cash ",
    SenderWallet: "Wallet",
    Auto: "Auto",
    Manual: "Manual",
    SendOrderr: "Send Order",
    Neworder: "New order",
    Person: "Individual",
    Business: "business",
    Businessname: "Business name",
    phonenumber: "Phone number",
    Adddropoff: "Add drop off",
    AllKey: "All",
    MeKey: "Me",
    Vehicletype: "Vehicle type",
    status: "Status",
    statusInProgress: "In Progress",
    statusCanceled: "Canceled",
    statusPending: "Pending",
    statusUnassigned: "Unassigned",
    statusDone: "Done",
    dispatcher: "operator",
    customer: "mobile",
    regexcode: "Code",
    regexcustomerPhone: "Customer Phone",
    regexcustomerName: "Customer Name",
    regexdriverPhone: "Driver Phone",
    regexdriverName: "Driver Name",
    regexdriverUsername: "Driver Username",
    pickupDescription: "pickup Description",
    Pickupnote:"Nota de recogida",
    filter: "Filter",
    clear: "Clear",
    orderInfoError: "Error receiving information",
    Details: "Details",
    History: "History",
    Phone: "Phone",
    km: "km",
    Espath: "Es. path",
    Receipt: "Receipt",
    Copylink: "Copy link",
    repeatOrder: "Repeat Order",
    Shipmentlabel:"Shipment label",
    Edit: "Edit",
    EditPrice: "Price & Earning",
    EditPrice2: "Price",
    EditEarning: "Earning",
    Endtrip: "End order",
    Canceltravel: "Cancel Order",
    CancellText: "After cancellation, the order is canceled for the driver",
    confirmCancel: "Yes",
    Endtravel: "complete",
    EndlText: "Upon completion, the order for the Customer and driver is successfully completed and the amount is deducted from the Customer account",
    confirmEnd: "Yes",
    End: "Cancel Order",
    Deliveredat: "Delivered at ",
    pod: "Proof of delivery",
    pop:"Proof of pickup",
    Notes: "Notes",
    Nonotes: "No notes",
    Photo: "Photo",
    Signature: "Signature",
    Nosignature: "No signature",
    Nophoto: "No photo",
    Pending: "Pending",
    Unassigned: "Unassigned",
    Assigned: "In Progress",
    PickedUp: "In Progress",
    Started: "In Progress",
    Done: "Done",
    CustomerCanceled: "Canceled",
    DriverCanceled: "Canceled",
    SupportCanceled: "Canceled",
    Arrived: "In Progress",
    Createdsuccessfully: "Created successfully",
    Estime: "Es. time",
    Promocode: "Promo code",
    Updatedsuccessfully: "Updated successfully",
    statusCustomerCanceled: "Customer Canceled",
    statusAssigned: "Assigned",
    statusStarted: "Started",
    statusPickedUp: "PickedUp",
    statusDriverCanceled: "Driver Canceled",
    statusArrived: "Arrived",
    statusSupportCanceled: "Support Canceled",
    Title: "Title",
    ContactFullname: "Contact Full name",
    Contactphonenumber: "Contact phone number",
    Commission: "Commission",
    surcharge: "Surcharge",
    haslogined: "has logged in",
    Notloggedin: "Not logged in",
    Schedule: "Schedule",
    Date: "Date",
    Time: "Time",
    noDateToShow: "There is no Date to display",
    noTimeToShow: "There is no Time to display",
    Apply: "Apply",
    lowBalance: "low balance",
    balanceError: " balance must be at least equal to order price",
    Methods: "Methods",
    Addressbook: "Address book",
    AddressbookSearchbar: "Search by address, title and ID",
    AddressbookDropOf: "Address book (Drop off)",
    AddressbookPickup: "Address book (Pickup)",
    hide: "Hide",
    Nosavedaddress: "No saved address",



    Romanian: "romanian",
    business: "business",
    username: "User Name",
    rememberme: "remember me",
    SendOrder: "Send Order",
    code: "Code",
    noorder: "noOrder",
    pleaseSellectParsel: "Please Select Service",
    Order: "Order",
    Code: "Code",
    Editorder: "Edit Order",
    all: "All",
    Address: "Address",
    "Block/Building": "Block/Building",
    Pickedupat: "Picked up at ",
    for: "For",
    fr: "French",
    AllVehicletypes: "All Service types",
    AllServices: "All Services",
    French: "French",
    enterDataCorrectly: "Enter information correctly.",
    controlThatbeforeLoweEqualAfter: `Time "Before :" cannot be less than "After :" `,
    ForceAssign: "Force Assign",
    Allvehicletypes: "All vehicle types",
    waitforconfirm: "xxx ( Wait for confirm )",
    waitforconfirm2: "Wait for confirm",
    Dispatching: "Dispatching",
    NoDriver: "No Driver",
    OtherDetails: "Other Details",
    StatusandDriver: "Status & Driver",
    EditStatusandDriver: "Edit Status and Driver",
    Current: "Current",

    EDAS_Pending: "Pending",
    EDAS_Assigned: "Assigned",
    EDAS_PickedUp: "PickedUp",
    EDAS_Unassigned: "Unassigned",
    EDAS_Started: "Started",
    EDAS_Done: "Done",
    EDAS_CustomerCanceled: "Customer Canceled",
    EDAS_DriverCanceled: "Driver Canceled",
    EDAS_SupportCanceled: "Support Canceled",
    EDAS_Arrived: "Arrived",

    Pleasechooseadrivertochangestatus: "Please choose a driver to change status",
    icWarningEdit: "Changing Optinons may effects on price",
    Vehicletypewillbechanged: "Vehicle type will be changed",
    Yourcurrentvehicletypeanditwillbechangedandeffectsonprice: "Your current vehicle type will be changed and effects on price",
    Rejected: "Rejected",
    Rejected2: "(Rejected)",
    DriverVehicleTypeError: "There is no vehicle type for Driver",
    CodeNameFamilyPhonenumber: "Code, Name, Family, Phone number",
    youcanselectdriverintheedit: "You can select driver in the edit driver and status",


    Earning: "Earning",
    savePrice: "Save Price",
    saveEarning: "Save Earning",
    Total: "Total",
    Pricedetails: "Price details",
    "EditPickupDropOff": "Edit Addresses",

    noMoreData: "There is no more information",
    Dutch:"dutch",
    Ondemand: "On Demand",
    PD: "P & D",
    PD2: "P&D",
    PickupDropoff: "Pickup , Dropoff",
    PickupFlag: "P",
    DropOffFlag: "D",
    PickupandDelivery: "Pickup and Delivery",
    PickupDelivery: "Pickup and Delivery",
    Delivery: "Delivery",



    ServiceOptions: "Service Options",
    Searchbynameorphonenumber: "Search by name or phone number",
    Fullname: "Full name",
    type: "type",
    only: "xxx only",
    ChooseDriver: "Choose Driver",
    Events: "Events",
    Collecting: "Collecting",
    Transit: "Transit",
    Delivering: "Delivering",
    EDAS_Collecting: "Collecting",
    EDAS_Transit: "Transit",
    EDAS_Delivering: "Delivering",
    statusCollecting: "Collecting",
    statusTransit: "Transit",
    statusDelivering: "Delivering",
    status2: "status",
    Creator: "Creator",
    Creationdate: "Creation date",
    Orderid: "Order id",
    Pickup2: "Pickup",
    Dropoff2: "Dropoff",
    senderfullname: "Sender Name",
    block: "Block",
    senderphonenumber: "Sender number",
    Recieverfullname: "Receiver Name",
    Recieverphonenumber: "Receiver Number",
    PleaseSelect: "Please Select",
    CreateEvent: "Create Event",
    Description: "Description",
    detailsAboutEvent: "Details about event",
    CustomerVisibility: "Customer Visibility",
    LocationOnMap: "Location on map" ,


    event_Created:"Created",
    event_Unassigned:"Unassigned",
    event_Assigned:"Assigned",
    event_Started:"Started",
    event_Arrived:"Arrived",
    event_PickedUp:"PickedUp",
    event_OrderDone:"Order Done",
    event_DropoffDone:"Dropoff Done",
    event_CustomerFeedback:"Customer Feedback",
    event_AddPod:"Add Pod",
    event_CustomerCanceled:"Customer Canceled",
    event_DriverCanceled:"Driver Canceled",
    event_SupportCanceled:"Support Canceled",
    event_Updated:"Updated",
    event_DriverAssignment:"Driver Assignment",
    event_DriverReject:"Driver Reject",
    event_Collecting:"Collecting",
    event_InTransit:"In Transit",
    event_Delivering:"Delivering",
    event:"Event" ,
    tab_Delivery:"Delivery",
    tab_Ondemand:"On Demand",
    tab_Pickup:"Pickup",
    tab_PickupDelivery :"P&D",
    CurrentStatus:"Current Status",
    Changestatus:"Change status",
    Newstatus:"New status" ,
    Savechanges:"Save changes",
    Other:"Other" ,
    serviceCustomerError1 :"xxx isn't available for this customer." ,
    serviceCustomerError2 :" He/She only can choose xxx Service",
    serviceCustomerError3 :" He/She only can choose xxx ",
    OrderCode:"Order Code",


    todayat:"Today at" ,
    tomorrowat:"Tomorrow at" ,
    nextWeekat:"Next Week at" ,
    lastDayat:"Last Day at" ,
    lastWeekat:"Last Week at",

    CopyOrderlink:"Copy Order link" ,
    CopyPickuplink:"Copy Pickup link" ,
    CopyDeliverylink:"Copy Delivery link" ,
    Bulkimport:"Bulk import",
    Downloadsamplecsv:"Download sample CSV" ,
    processing:"Proccessing" ,
    done :"Finished" ,
    failed:"Failed",
    Draft:"Draft" ,
    Uploading:"Uploading" ,
    Draganddropyourfile:"Drag and drop your file",
    Onlycsvformatissupported:"Only .csv format is supported" ,
    ORyoucan:"OR you can",
    Openfile:"Open file" ,
    Noimportsyet:"No imports yet" ,
    NoimportsyetDesc :"You can see the list of your imports here and check their status",
    nosupportdialogeTitle:"File Formart not supported",
    nosupportdialogeText : "xxx file is not supported. you can only upload .csv file including order info" ,
    ok:"ok" ,
    Import  :"Import",
    previewTitle :"Preview and choose Options",
    previewDesc:"This is the first item of your files to check your info is in the right structure" ,
    Upload:"Upload", 
    Remove:"Remove" ,
    ServiceandOrdertype:"Service and Order type" ,
    Fileisproccessing:"File is proccessing ...",
    "proccessingDesc":"You can refresh to see if the result is ready",
    serverError:"Server Error",
    isRequired:"xxx is required" ,
    Result:"Result",
    ResultText:"You can see the problems here and download these rows to fix seperatly",
    ErrorDetails:"Orders with error",
    ErrorDetailsText:"Orders with errors are listed in the file that you can download below. It contains error details for each row.",
    DownloadErroredRows:"Download errored rows" ,
    importedsuccessfully:"xxx orders are imported successfully" ,
    ordershaserrors:"xxx orders has errors",
    refresh:"Refresh",
    CashOnDelivery:"Cash on Delivery",
    EnterAmount:"Enter amount",
    COD:"Cash on Delivery",
    Surchargexxx:"Surcharge xxx" ,
    AddCreditCard:"Add Credit/Debit Card" ,
    CustomerWallet:"Customer Wallet" ,
    Add:"Add",
    Addedsuccessfully: "Added successfully",
    NoRecentAddress:"No recent address",
    NoRecentAddressD:"Customer recent addresses will be listed here",
    CustomerIsNotChoosed:"No se elige al cliente",
    CustomerIsNotChoosedD:"Elija el cliente para mostrar su dirección reciente",
    SearchAddress:"Search address" ,
    NoCustomerfound: "No Customer found" ,
    NoCustomerfoundD:"You can create the customer if it’s a new one",
    NewCustomer : "New Customer" ,
    Existing:"Existing",
    EmailOtional:"Email ( Optional )" ,
    phonenumberisnotcorrect:"phone number is not correct" ,
    Emailisnotcorrect:"Email is not correct" ,
    Registercustomer:"Register customer" ,
    incoming:"Incomming Call",
    outgoing:"outgoing Call",
    recording:"recording Call",
    driveroperatorCallStatus:"Driver called operator",
    customeroperatorCallStatus:"Customer called operator",
    newCustomeroperatorCallStatus:"New customer called operator",
    operatordriverCallStatus:"Operator called driver",
    operatorcustomerCallStatus:"Operator called customer",
    operatornewCustomerCallStatus:"Operator called new customer",
    dispatcher_Voip:"Operator",
    driver_Voip:"Driver",
    customer_Voip:"Customer",
    activeorders:"xxx active orders",
    Noactiveorder :"No active order " ,
    CreateOrder:"Create Order",
    call:"Call",
    spanish:"Spanish",
    es : "Spanish",
    networkError:"No Internet Connection",
    createdAt:"Creado en",
    dueDate:"Fecha de vencimiento",
    Noresultfound:"No se han encontrado resultados",
    loadMore:"Load more",
    Searchresult:"Search result",
    "PinTop":"Tapa de alfiler",
    "UnPin":"Un pino",
    "Itemsonmap":"Elementos en el mapa",
    "Orders":"Pedidos",
    "Drivers":"Conductoras",
    "min":"xxx min" ,
    Confirming:"Confirming",
    delay:"Delay",
    late:"Late",
    OrderDetails:"Detalles del pedido",
    Profile:"Perfil",
    Waitingforavailabledrivers:"Esperando a los conductores disponibles",
    Queue:"Cola",
    OfferingToDrivers:"Ofreciendo a los controladores xxx",
    WaitforAccept:"Esperar a aceptar" ,



    "AssignedAt":"Asignar en xxx min",
    "StartedAt":"Comience en xxx min",
    "ArrivedAt":"Llegue en xxx min",
    "PickedUpAt":"Recogida en xxx min",
    "DoneAt":"Terminar en xxx min",
    mindelay: 'xxxm RETRAJE',
    minlate: 'xxxm tarde',
    Thereisnoorder:"No hay pedido",
    mapitems:"Mapa de mapa",
    Offerings:"Ofrendas",
    Reject:"Rechazar",
    dropoffCustomerDescription:"Nota de entrega",
    customerPhoto:"Foto de cliente" ,
    driverPhoto:"Foto del conductor" ,
    NocustomerPhoto:"Sin foto del cliente" ,
    NodriverPhoto:"Sin foto del conductor",
    initial:"inicial",
    notifying:"notificación",
    received:"recibió",
    assigned:"asignado",
    rejected:"rechazada",
    canceled:"cancelada",
    standby:"apoyar",
    terminated:"terminada",
    urgentTitle:"¿Cambiar al envío urgente?",
    urgentDesc:"Al habilitar urgente, la orden será en la primera prioridad para enviar",
    urgentConfirm:"Sí, habilite urgente",
    urgentClose:"No, cancelar",
    Urgent:"Urgente",
    photos :"Fotos",
    Portuguese:"portugués",
    Dispatchat:"Envío xxx",
    AutoDispatch:"Envío automático" ,
    Manual:"manual",
    Dispatchtime:"Tiempo del envío" ,
    Later:"Más tarde",
    DispatchingNow:"Mandelo ahora",
    Required:"Requerida" ,

    NoResultfromLocalDatabase:"No hay resultado de la base de datos local",
    NoResultfromLocalDatabaseD:"Por favor revise nuevamente o intente otra palabra clave",
    NoResultfromMapService:"No hay resultado del servicio de mapa",
    SearchinMapService:"Buscar en el servicio de mapas" ,
    SearchinLocalDatabase:"Buscar en la base de datos local",
    Creditcardnotadded:"Tarjeta de crédito no agregada",
    OptimizeDropoffs:"Optimizar las caídas",
    "Greek":"Griega",
    "el":"Griega",
    "ReferenceID":"Identificación de referencia",
    "EnterId":"Ingrese ID",
    "de":"Alemana",
    "German":"Alemana",
    "AustrianGerman":"Alemán austriaco",
    "Options":"Opción",
    "VehicleOptions":"Opciones de vehículos",
    "EditCustomer":"Editar cliente",
    "NoDriverfound":"No se encontró ningún controlador",
    "NoDriverfoundDesc":"Revise su información y pruebe algo más",
    "ClearField":"Campo libre",
    "Map":"Mapa",
    "Local":"local",
    "CompleteRequiredFeileds":"Completos campos requeridos",
    "Senderinfo":"Información del remitente",
    "SchedulePickup(Optional)":"Programar recogida (opcional)",
    "ScheduleDelivery(Optional)": "Schedule Delivery (Optional)",

    "AddDropoff":"Agregar caída",
    "EditPrice":"Precio de edición",
    "Promotion":"Promoción",
    "tax":"Impuesto",
    "surcharge":"Recargo xxx",
    "Receiverinfo":"Información del receptor",
    "tryagain":"Something went wrong, please try again",



    "Selected date and time":"Selected date and time",
    "Reorder stops":"Reorder stops",
    "Bulk Edit Status Summary":"Bulk Edit Status Summary",
    "Successful":"Successful",
    "Failed":"Failed",
    "Order ID":"Order ID",
    "Details":"Details",
    "Result":"Result",
    "Pickup Routed":"Pickup Routed",
    "Delivery Routed":"Delivery Routed",
    "Ref. xxx":"Ref. xxx",
    "Zone":"Zone",
    "Clear":"Clear",
    "Apply":"Apply",
    "No result found":"No result found",
    "Type your keyword":"Type your keyword",
    "Type in the input to start search":"Type in the input to start search",
    "We are sorry ...":"We are sorry ...",
    "The page you are trying to access has restricted access.":"The page you are trying to access has restricted access.",
    "Please refer to your system administrator.":"Please refer to your system administrator.",
    "Failed":"Failed",
    "Done": "Done",
    "Schedule":"Schedule",
    "Proof of delivery":"Proof of delivery",
    "Signature":"Signature",
    "Driver Photo":"Driver Photo",
    "Notes":"Notes",
    "Order Info":"Order Info",
    "Order":"Order",
    "Price":"Price",
    "P_HUB_D Orders":'P_HUB_D Orders',
    "Routes":"Routes",
    "Default pickup":"Default pickup",
    'More':'More',
    'Route name':'Route name',
    'Driver':'Driver',
    'Pickup Orders':'Pickup Orders',
    'Delivery Orders':'Delivery Orders',
    'Ready For Pickup':'Ready For Pickup',
    'PricingAndEarning':"Pricing & Earning",
    "Pricing" : "Pricing",
    "save":"Save",
    "Learn":"Learn",
    "Scheduleoverlap":"The Schedule Pickup time must be before the Schedule Delivery time and have no overlap",
    "PleaseSelectCustomer":"Please select customer",
    "NoRegisteredCustomer":"No registered customer",
    "CreateAtLeastOneCustomerToCreateOrder":"Create at least one customer to create order",
};