import * as actionTypes from "../actions/actionTypes";
import { get_accessToken, get_lang } from "../../helper/UserData/userdate";
import { getDirectionByUrl, getLangByUrl } from "../../helper/module";

const init = {
  socket:null,
  access_token: get_accessToken() ? get_accessToken() : null,
  direction: /* getDirectionByUrl() */ null,
  fullDirection: "left",
  timestamp:0

};
export const LocalData = (state = init, action) => {
  switch (action.type) {
    case actionTypes.SETSOCKET :
      return {
          ...state,
          socket :action.state
      };
      case actionTypes.setTime:
        return {
            ...state,
            timestamp :action.state
        };
    case "changeDirection":
      return {
        ...state,
        direction: action.direction,
        fullDirection: action.fullDirection,
        lang: action.lang,
      };
    case actionTypes.set_accesstoken:
      return {
        ...state,
        access_token: action.state,
      };
    
    default:
      return state;
  }
};

export const removeByKey = (object, deleteKey) => {
  return Object.keys(object)
    .filter((key) => key !== deleteKey)
    .reduce((result, current) => {
      result[current] = object[current];
      return result;
    }, {});
};
