import { SetOrderListStatsData, SetOrderListStatsLoading } from "../Redux/actions/actionNames";
import { store } from "../Redux/store";


export const FilterStatusByCategory =  (categoryName , statusfilters) => {
    let status = statusfilters.filter((sta)=> {return getStatus[categoryName].indexOf(sta) > -1 })  
    return status
};

const getStatus = {
    DispatchLater : ['Pending'],
    Unassigned : ['Unassigned'],
    Dispatching: ['Pending'],
    Assigned : ['Assigned'],
    Pending : ['Pending'],
    InProgress : ['Started', 'Arrived', 'PickedUp' , 'Assigned'],
    Collecting : ['Collecting'],
    Delivering : ['Delivering'],
    Done : ['Done'],
    Canceled : ['SupportCanceled', 'DriverCanceled', 'CustomerCanceled'],
    Delayed: ['Assigned','Pending' ,'Started', 'Arrived', 'PickedUp','Collecting','Delivering','Done','SupportCanceled', 'DriverCanceled', 'CustomerCanceled'],
    Pinned : ['Assigned','Pending' ,'Started', 'Arrived', 'PickedUp','Collecting','Delivering','Done','SupportCanceled', 'DriverCanceled', 'CustomerCanceled', 'Unassigned'],
    Transit : ["Transit"]

}

