import PickNDropLL from "../../../assets/PickNDrop.png";
import FavIcon from "../../../assets/PROJECTS/PickNDrop_fav_icon.png";

export default function PickNDrop() {
    
    return  global.config = {
    "ProjectName": "PickNDrop",
    "LogoType": PickNDropLL,
    "BaseApi": "https://rest.pickndrop.io",
    "BaseSocket": "https://socket.pickndrop.io",
    "BaseTrack": "https://track.pickndrop.io",
    "MapCenter": {
        "lat": 33.893913785443885,
        "lng": 35.502049555898594
    },
    "Lang": "en",
    "Direction": "ltr",
    "AllLanguages": [{
        "lang": "en",
        "direction": "ltr",
        "i18Lang": "English"
    }, {
        "lang": "fr",
        "direction": "ltr",
        "i18Lang": "French"
    }],
    "FavIcon": FavIcon,
    "Country": "LB",
    "thirdPartyLoginSupport": true  ,
    mapType :"osm", /* osm | google */ 
    googleApiKey : ""
} }