import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import arrow from "../../../../../assets/chevron-down.svg";
import Collapse from "@material-ui/core/Collapse";
import { withTranslation, Trans } from "react-i18next";
import Backdrop from "@material-ui/core/Backdrop";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import { withStyles } from "@material-ui/core/styles";
import {
  Set_Pickup_Inputs,
  Set_Pickup_address,
  Set_SearchAutoCompleteLoading,
  Set_latlng,
  Edit_Travel_set_pickup_inputs,
  Edit_Travel_set_pickup,
  Edit_Travel_Operate_Changed,
  OpenNewFav,
  SetopenAddressBookInNewOrder,
} from "../../../../../Redux/actions/actionNames";
import {
  SearchAutoCompleteAction,
  SearchAddressAction,
  PlaceIdDetailsAction,
  getFavAddressInfoAction,
  SearchAutoCompleteAction_local_address,
} from "../../../../../Redux/actions/actions";
import closeCircle from "../../../../../assets/close-cricle.svg";
import savedarrow from "../../../../../assets/icon-arrow-triangle-up.svg";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import CircularProgress from "@material-ui/core/CircularProgress";
import Schedule from "./Schedule";
import momentJalaali from "moment-jalaali";
import { get_lang } from "../../../../../helper/UserData/userdate";
import _, { debounce, isEmpty, throttle } from "lodash";
import PhoneInput from "react-phone-number-input";
import {
  getCenterMap,
  getCountryWithDomain,
  setCenterMap,
} from "../../../../../helper/module";
import { useLocalServices } from "../../../../../hooks";
import { v4 as uuidv4 } from "uuid";
import address_database from "../../../../../assets/address_database.webp";
import MapSearch from "../../../../../assets/MapSearch.webp";
import iconicperson from "../../../../../assets/icon-ic-person.svg";
import icClock from "../../../../../assets/ic-clock.svg";
import iconIcPin from "../../../../../assets/icon-ic-pin.svg";
import icclockcolorfull from "../../../../../assets/ic-clock-colorfull.svg";
import styles from './style.module.css';
import { ADDRESS_BOOK, CLOSE_DARK, HISTORY, INFO_CIRCLE, LOCATION, PICKUP } from "../../../../../assets";
import Typography from "../../../../../design-system/lib/Typography";
import IconProvider from "../../../../../design-system/lib/IconProvider";
import SearchList from "../../../../../design-system/lib/SearchList";
import DropDown from "../../../../../design-system/lib/DropDown";
import Button from "../../../../../design-system/lib/Button";
import RecentAddressItem from "../../../../../components/new-order/pickup/components/recentAddressItem";
import AddressItem from "../../../../../components/new-order/pickup/components/addressItem";
import PickupInfoEditOrder from "./pickup/info";

const BackdropCustom = withStyles({
  backdrop: {
    zIndex: 5000,
    backgroundColor: "rgba(0, 0, 0, 0)",
    color: "#00000",
  },
})((props) => (
  <Backdrop
    {...props}
    style={{
      zIndex: 5000,
      backgroundColor: "rgba(0, 0, 0, 0)",
      color: "#00000",
    }}
  />
));

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "pickup",
      loading: false,
      ordered: true,
      showpoper: false,
      showpoperSaved: false,
      getFavAddressLoading: false,
      searchAddrees: "",
      list: [0, 1, 2, 3, 5, 6, 9, 5, 8],
      sessionToken: null,
      isLocalAddressActive: global.config.dispatcherSettings.isLocalAddressActive,
      localSearchIsActive: global.config.dispatcherSettings.isLocalAddressActive ? true : false,
    };
    this.anchorEl = React.createRef();
    this.throttleHandleChange = debounce(
      this.throttleHandleChange.bind(this),
      800
    );
  }

  handleChangeLocalSearch = () => {
    this.setState(
      {
        localSearchIsActive: !this.state.localSearchIsActive,
      },
      () => {
        this.notThrottleHandleChange(String(this.props.address))
      }
    );
  };

  notThrottleHandleChange(value) {
    let center = getCenterMap(this.props.mapRef, this.props.center);

    if (!this.state.localSearchIsActive) {
      this.props.dispatch(
        SearchAutoCompleteAction(this.props.dispatch, {
          query: value,
          latitude: center.lat,
          longitude: center.lng,
          sessionToken: this.state.sessionToken,
        })
      );
    } else {
      this.props.dispatch(
        SearchAutoCompleteAction_local_address(this.props.dispatch, {
          query: value,
        })
      );
    }
  }

  throttleHandleChange(value) {
    let center = getCenterMap(this.props.mapRef, this.props.center);

    if (!this.state.localSearchIsActive) {
      this.props.dispatch(
        SearchAutoCompleteAction(this.props.dispatch, {
          query: value,
          latitude: center.lat,
          longitude: center.lng,
          sessionToken: this.state.sessionToken,
        })
      );
    } else {
      this.props.dispatch(
        SearchAutoCompleteAction_local_address(this.props.dispatch, {
          query: value,
        })
      );
    }
  }

  handleshowpoper = () => {
    this.setState({
      showpoper: !this.state.showpoper,
      searchAddrees: "",
      showpoperSaved: false,
    });
  };

  handleshowpoperDrop = () => {
    if (this.props.customerId) {
      this.setState({
        showpoperSaved: !this.state.showpoperSaved,
        searchAddrees: "",
        showpoper: false,
      });
    }
  };

  handleSavedDropInput = (e) => {
    this.setState({
      searchAddrees: e.target.value,
    });
  };

  componentDidMount() {
    this.props.setId(this.state.id);
  }

  idgenerator = () => {
    var number = Math.random(); // 0.9394456857981651
    number.toString(36); // '0.xtis06h6'
    var id = number.toString(36).substr(2, 9); // 'xtis06h6'
    return id.toString();
  };

  handleCollapse = () => {
    if(this.props.activePickupDropOff.type === "pickup"){
      this.setState({
        showpoper: false,
        searchAddrees: "",
        showpoperSaved: false,
      });
      this.props.handleCollapse({
        type: "pickup",
        id: "pickup",
      });
    }else{
      this.props.handleCollapse({
        type: "pickup",
        id: "pickup",
      });
    }

  };

  handlePhoneInput = (value, id) => {
    this.props.dispatch(Edit_Travel_Operate_Changed());
    this.props.dispatch(
      Edit_Travel_set_pickup_inputs({
        name: id,
        value: value,
      })
    );
  };

  handlePickUpInputs = (e, name, value, id) => {
 try {
  this.props.dispatch(Edit_Travel_Operate_Changed());

  if ((id ? id : e.target.id) === "address" || name === "address") {
    this.setState({
      sessionToken: this.state.sessionToken
        ? this.state.sessionToken
        : uuidv4(),
    });

    this.props.dispatch(
      Edit_Travel_set_pickup_inputs({
        adresss_loc: { lng: "", lat: "" },
        placeId: "",
      })
    );
    if (value ? value : e.target.value) {
      this.props.dispatch(Set_SearchAutoCompleteLoading(true));

    }
    if (value || e.target.value != "") {
      // this.props.dispatch(
      //   SearchAutoCompleteAction(this.props.dispatch, {
      //     query: e.target.value,
      //     latitude: this.props.mapRef.current.leafletElement.getCenter().lat,
      //     longitude: this.props.mapRef.current.leafletElement.getCenter().lng,
      //   })
      // );
      (value ? value : e.target.value) &&
        this.throttleHandleChange(
          value ? value : e.target.value,
          this.state.sessionToken
        );
    }
  }
  this.props.dispatch(
    Edit_Travel_set_pickup_inputs({
      name: id || name || e.target.id,
      value: value ? value : e.target.value,
    })
  );
 } catch (error) {
  
 }
  };

  handleList = (item) => {
    if (item.coordinates) {
      this.props.dispatch(Edit_Travel_Operate_Changed());

      this.setState({
        sessionToken: null,
      });

      this.props.dispatch(
        Edit_Travel_set_pickup({
          ...item,
          address: item.secondaryAddress
            ? item.address + " , " + item.secondaryAddress
            : item.address,
          adresss_loc: { lng: item.coordinates[0], lat: item.coordinates[1] },
        })
      );
      this.props.dispatch(
        Set_latlng({
          lng: item.coordinates[0],
          lat: item.coordinates[1],
        })
      );
    } else {
      this.setState(
        {
          sessionToken: this.state.sessionToken
            ? this.state.sessionToken
            : uuidv4(),
        },
        () => {
          this.props.dispatch(
            PlaceIdDetailsAction(
              this.props.dispatch,
              { placeId: item.placeId, sessionToken: this.state.sessionToken },
              (res) => {
                this.setState({
                  sessionToken: null,
                });

                this.props.dispatch(
                  Edit_Travel_set_pickup({
                    ...item,
                    address: res.address,
                    adresss_loc: {
                      lng: res.coordinates[0],
                      lat: res.coordinates[1],
                    },
                  })
                );

                this.props.dispatch(
                  Set_latlng({
                    lng: res.coordinates[0],
                    lat: res.coordinates[1],
                  })
                );
              }
            )
          );
        }
      );
    }
  };

  validate(email) {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  resetPickup = () => {
    this.props.dispatch(Edit_Travel_Operate_Changed());
    this.props.dispatch(
      Edit_Travel_set_pickup({
        address: "",
        adresss_loc: { lng: "", lat: "" },
        placeId: "",
      })
    );
  };

  hanldeOpenNewFav = () => {
    this.props.dispatch(OpenNewFav(true));
  };

  mapSetView = (e) => {
    setCenterMap(this.props.mapRef, e);
  };

  handleListFavAddress = (e) => {
    this.resetPickup();
    this.props.dispatch(Edit_Travel_Operate_Changed());

    this.setState({
      showpoperSaved: false,
      getFavAddressLoading: true,
    });
    if (this.props.customerId) {
      this.props.dispatch(
        getFavAddressInfoAction(
          this.props.dispatch,
          { customerId: e.customerId, id: e.id },
          (res) => {
            this.setState({
              getFavAddressLoading: false,
            });
            this.mapSetView({
              lng: res.coordinates[0],
              lat: res.coordinates[1],
            });

            this.props.dispatch(
              Edit_Travel_set_pickup({
                address: res.address,
                adresss_loc: {
                  lng: res.coordinates[0],
                  lat: res.coordinates[1],
                },
                buildingBlock: res.buildingBlock,
                floor: res.floor,
                unit: res.room,
                senderFullName: res.fullName,
                senderPhoneNumber: res.phone,
                noteForDriver: res.description,
              })
            );
          }
        )
      );
    }
  };



  handleRecentList = (item) => {

     // this.props.dispatch(
     //   Set_latlng({ lng: item.coordinates[0], lat: item.coordinates[1] })
     // );

     // dispatch(
     //   Set_Pickup_address({
     //     ...item,
     //     address: item.address,
     //     adresss_loc: { lng: item.coordinates[0], lat: item.coordinates[1] },
     //     block: item.buildingBlock,
     //     floor: item.floor,
     //     unit: item.room
     //   })
     // );
     // mapSetView({
     //   lng: item.coordinates[0],
     //   lat: item.coordinates[1],
     // });

     // handleShowInfo()


     this.props.dispatch(Edit_Travel_Operate_Changed());

     this.setState({
      sessionToken: null,
    });

     this.props.dispatch(
       Edit_Travel_set_pickup({
         ...item,
         address: item.address,
         adresss_loc: { lng: item.coordinates[0], lat: item.coordinates[1] },
       })
     );
     this.props.dispatch(
       Set_latlng({
         lng: item.coordinates[0],
         lat: item.coordinates[1],
       })
     );

   }


  mapSetView = (e) => {
    // setCenterMap(mapRef, e)
  };




  GetserachListItemOnclick = () => {

    if (_.isEmpty(this.props.address)) {
      return this.handleRecentList
    } else {
      return this.handleList
    }





  };

  GetSearchListError = () => {

    //no recent address
    if (this.props.recentAddress?.length == 0 && _.isEmpty(this.props.address)) {
      return {
        errorDescription: this.props.t("NoRecentAddressD"),
        errorTitle: this.props.t("NoRecentAddress"),
        errorIcon: HISTORY,
      }
    }

     //CustomerIsNotChoosed


     if ((!this.props.suggestListPickUpDropOffLoading &&
       this.props.suggestPickUpDropOffListData.length === 0 && !_.isEmpty(this.props.address)) || (!_.isEmpty(this.props.address) && !this.props.suggestListPickUpDropOffLoading && !this.props.suggestPickUpDropOffListData)) {
       return {
         errorDescription: this.props.t("NoResultfromLocalDatabaseD"),
         errorTitle: this.props.t(this.state.localSearchIsActive ? "NoResultfromLocalDatabase" : "NoResultfromMapService"),
         errorIcon: null,
       }
     }

   };



  GetserachListItem = () => {

    if (_.isEmpty(this.props.address)) {
      return RecentAddressItem
    } else {
      return AddressItem
    }


     }


  serachListData = () => {

       if (_.isEmpty(this.props.address)) {

        if (_.isEmpty(this.props.recentAddress)) {
          return null //error recentAddress
        } else {
          return this.props.recentAddress
        }

      } else {
        if (_.isEmpty(this.props.suggestPickUpDropOffListData)) {
          return null //error suggestPickUpDropOffListData
        } else {
          return this.props.suggestPickUpDropOffListData
        }
      }


     }


  handleShowInfo = () => {

    const isOpen = this.props.activePickupDropOff.type === "pickup"

    const myEvent = new CustomEvent("closeSliders", { detail: null });
    document.dispatchEvent(myEvent);


    this.handleCollapse()

  }



  openAddressbook = () => {

     const myEvent = new CustomEvent("closeSliders", { detail: null });
     document.dispatchEvent(myEvent);


     setTimeout(() => {
       if (this.props.openAddressBook && this.props.addressBookSource) {
         this.props.dispatch(SetopenAddressBookInNewOrder(false, "", null, ""));
       } else {
         this.props.dispatch(
           SetopenAddressBookInNewOrder(
             true,
             "pickup",
             null,
             "AddressbookPickup"
           )
         );
       }
  }, 100);
   }


   pickupRequiredCheck = () => {
    if (this.props.activeVehicleType) {
      let name = false, email = false, phone = false;

      if (this.props.activeVehicleType.setting.isPickupAddressNameRequired) {
        if (this.props.senderFullName && String(this.props.senderFullName).trim().length > 0) {
          name = true
        } else {
          name = false
        }
      } else {
        name = true
      }

      if (this.props.activeVehicleType.setting.isPickupAddressPhoneRequired) {
        if (this.props.senderPhoneNumber && String(this.props.senderPhoneNumber).trim().length > 0) {
          phone = true
        } else {
          phone = false
        }
      } else {
        phone = true
      }


      if (this.props.activeVehicleType.setting.isPickupAddressEmailRequired) {
        if (this.props.email && String(this.props.email).length > 0 && emailValidate(String(this.props.email))) {
          email = true
        } else {
          email = false
        }
      } else {
        email = true
      }



      return (name && phone && email);

    } else {
      return false
    }

  }



  render() {
    const { t } = this.props;

    console.log("dwdwrefregfddf ", this.props)


    if (isEmpty(String(this.props.adresss_loc.lat))) {
      return <div className={styles.PickupOnlyNewOrderContainer}>

        <div className={styles.PickupOnlyNewOrderContainerFirstRow}>
          <IconProvider Icon={PICKUP} size='16' />
          <Typography text={t("Pickup")} weight='Body_Middle_Bold' />
        </div>

        <div className={styles.PickupOnlyNewOrderContainerSecondRow}>
          <SearchList onChange={(e) => this.handlePickUpInputs(e, "address")} style={{ zIndex: "2" }} value={this.props.address} placeHolder={t("SearchAddress")} isloading={this.props.suggestListPickUpDropOffLoading} listItemOnclick={this.GetserachListItemOnclick()} error={this.GetSearchListError()} errorDescription={this.GetSearchListError()?.errorDescription} errorTitle={this.GetSearchListError()?.errorTitle} errorIcon={this.GetSearchListError()?.errorIcon}
            ListItemChildren={this.GetserachListItem()} list={this.serachListData()} inputInnerChildren={this.state.isLocalAddressActive ? (<DropDown size='Small' placement='bottomEnd' text={this.state.localSearchIsActive ? t("Local") : t("Map")} data={[t("Map"), t("Local")]} onClick={this.handleChangeLocalSearch} />) : null}
          />
          <Button icon={LOCATION} size='Medium' type='Secondary' style={{ marginInlineEnd: "7px" }} onClick={this.handleCollapse} isActive={this.props.activePickupDropOff.type === "pickup"} />
          <Button icon={ADDRESS_BOOK} size='Medium' type='Secondary' disabled={(String(this.props.customerId).length > 0) ? false : true} onClick={this.openAddressbook} isActive={this.props.addressBookSource} />
        </div>





      </div>
    } else {

      return (<div className={styles.pickupContainer}>
        <img src={PICKUP} />
        <Typography weight="Body_Small_Regular" text={this.props.address} />

        <Button icon={INFO_CIRCLE} size='Medium' type='Secondary' badge={ !this.pickupRequiredCheck() && true} onClick={this.handleShowInfo} isActive={this.props.activePickupDropOff.type === "pickup"}  style={{marginInlineEnd:"12px"}} />
        <Button size={'Small'} icon={CLOSE_DARK} type='Secondary' style={{ marginInlineStart: "4px", backgroundColor: "transparent", padding: "8px 8px" }} onClick={this.resetPickup} />



        {this.props.activePickupDropOff.type === "pickup" && <PickupInfoEditOrder handleShowInfo={this.handleShowInfo} />}



      </div>)
    }



    // return (
    //   <passerngerContainer className="pickupCustomized EditAddresspasserngerContainer">
    //     <pickuptitle
    //       onClick={this.handleCollapse}
    //       className="EditAddressPickuptitle"
    //     >
    //       <span>
    //         {" "}
    //         <Trans i18nKey={"Pickup"}> </Trans>{" "}
    //       </span>
    //       <span className="miniAddress  EditAddressMiniAddress">
    //         {this.props.activePickupDropOff.type !== "pickup" &&
    //           this.props.address}
    //       </span>
    //     </pickuptitle>

    //     <img
    //       onClick={this.handleCollapse}
    //       src={arrow}
    //       className={`pickupCollIconVR  pickupCollIconEADDRESS ${this.props.activePickupDropOff.type === "pickup" && "rotate180"
    //         }`}
    //     />
    //     <span className="reservDetail  EditAddressReservDetail">
    //       {" "}
    //       {this.props.activePickupDropOff.type !== "pickup" &&
    //         (momentJalaali(this.props.scheduleDateBefore).isValid() &&
    //           this.props.scheduleDateBefore > 0
    //           ? momentJalaali(this.props.scheduleDateBefore).format(
    //             get_lang() == "fa" ? "HH:mm jMM/jDD" : "MM/DD HH:mm"
    //           )
    //           : momentJalaali(this.props.scheduleDateAfter).isValid() &&
    //             this.props.scheduleDateAfter > 0
    //             ? momentJalaali(this.props.scheduleDateAfter).format(
    //               get_lang() == "fa" ? "HH:mm jMM/jDD" : "MM/DD HH:mm"
    //             )
    //             : null)}
    //     </span>

    //     <Collapse
    //       className="pickupcollapsecontainer yodmvydj"
    //       in={this.props.activePickupDropOff.type === "pickup"}
    //       timeout="auto"
    //       unmountOnExit
    //       style={{ border: "0px", paddingTop: "37px" }}
    //     >

    //       <ClickAwayListener onClickAway={()=>{
    //         this.setState({
    //           showpoper: false
    //         });
    //       }}>
    //         <div className={`NewOrderPickupContainer_DRDO ${(this.state.showpoper && this.props.adresss_loc.lat == "") && "DriverManualSelectContainer_DRDO_Active"}`} style={{ zIndex: "12px", margin: "0px", left: "12px", top: "55px", width: "483px" }}>
    //           <div className="pickupDropOffInputResetContainer">
    //             {this.state.isLocalAddressActive && <img src={this.state.localSearchIsActive ? address_database : MapSearch} onClick={this.handleChangeLocalSearch} className={"localSearchIsActiveicon"} />}

    //             <input
    //               value={this.props.address}
    //               onChange={(e) => this.handlePickUpInputs(e, "address")}
    //               autocomplete="93658369"

    //               ref={this.anchorEl}
    //               onClick={()=>{
    //                 this.setState({
    //                   showpoper: true
    //                 });
    //               }}
    //               placeholder={t(this.state.localSearchIsActive ? "SearchinLocalDatabase" : "SearchinMapService")}
    //             />



    //             {this.props.adresss_loc.lat !== "" && (
    //               <img
    //                 src={closeCircle}
    //                 onClick={this.resetPickup}
    //                 style={{ margin: "0  8.6px" }}
    //               />
    //             )}


    //           </div>

    //           {this.props.adresss_loc.lat == "" && <React.Fragment>

    //             {(this.state.showpoper && !isEmpty(this.props.address) && this.props.suggestPickUpDropOffListData.length > 0 &&
    //               !this.props.suggestListPickUpDropOffLoading) &&
    //               <div className="pickupDropOffRecentAddressItemListContainer">
    //                 {this.props.suggestPickUpDropOffListData.map((item) => {
    //                   return <div className="pickupDropOffRecentAddressItemList" onClick={() => {
    //                     this.handleList(item);
    //                   }}>
    //                     <img src={iconIcPin} />
    //                     <span> {item.address}</span>
    //                     <p> {isEmpty(item.secondaryAddress) ? "----" : item.secondaryAddress} </p>
    //                   </div>
    //                 })}
    //               </div>
    //             }



    //             {(this.state.showpoper && this.props.suggestListPickUpDropOffLoading && !isEmpty(this.props.address)) &&
    //               <div className="pickupDropOffRecentAddress">
    //                 <img src={iconIcPin} />
    //                 <p className="NoRecentAddress"><Trans i18nKey={"poperLoading"}> </Trans></p>
    //                 <p className="NoRecentAddressD"><Trans i18nKey={"fetchingdata"}> </Trans></p>
    //               </div>
    //             }


    //             {(this.state.showpoper && isEmpty(this.props.address)) &&
    //               <div className="pickupDropOffRecentAddress">
    //                 <img src={iconIcPin} />
    //                 <p className="NoRecentAddress"><Trans i18nKey={"Noinformationfound"}> </Trans></p>
    //                 <p className="NoRecentAddressD"><Trans i18nKey={"Pleasetryanotherkeyword"}> </Trans></p>
    //               </div>
    //             }



    //             {(this.state.showpoper && !this.props.suggestListPickUpDropOffLoading &&
    //               this.props.suggestPickUpDropOffListData.length === 0 && !isEmpty(this.props.address)) &&
    //               <div className="pickupDropOffRecentAddress">
    //                 <img src={iconIcPin} />
    //                 <p className="NoRecentAddress"><Trans i18nKey={this.state.localSearchIsActive ? "NoResultfromLocalDatabase" : "NoResultfromMapService"}> </Trans></p>
    //                 <p className="NoRecentAddressD"><Trans i18nKey={"NoResultfromLocalDatabaseD"}> </Trans></p>
    //               </div>
    //             }



    //           </React.Fragment>}






    //         </div>

    //       </ClickAwayListener>

    //       {/* <div
    //         className={`editAddressPickupContainer ${
    //           this.props.adresss_loc.lat !== "" && "negative50"
    //         }`}
    //       >
    //         {this.state.isLocalAddressActive && (
    //           <img
    //             src={
    //               this.state.localSearchIsActive ? address_database : MapSearch
    //             }
    //             onClick={this.handleChangeLocalSearch}
    //           />
    //         )}

    //         <input
    //           value={this.props.address}
    //           onChange={(e) => this.handlePickUpInputs(e, "address")}
    //           autocomplete="93658369"
    //           className={`editAddressPickup`}
    //           ref={this.anchorEl}
    //           onClick={this.handleshowpoper}
    //           placeholder={t("address")}
    //         />
    //       </div>

    //       {this.state.getFavAddressLoading && (
    //         <CircularProgress size={18} className="pickupLoading" />
    //       )}
    //       {this.props.adresss_loc.lat !== "" && (
    //         <img
    //           className={"resetPickup EditAddressresetPickup"}
    //           src={closeCircle}
    //           onClick={this.resetPickup}
    //         />
    //       )} */}

    //       {this.props.activeVehicleType &&
    //         this.props.activeVehicleType.setting
    //           .isPickupAddressRoomFloorBlockActive && (
    //           <>
    //             <input
    //               value={this.props.buildingBlock}
    //               onChange={this.handlePickUpInputs}
    //               id="buildingBlock"
    //               name="5a7a593f"
    //               autocomplete="5a7a593f"
    //               className="blockPickup"
    //               placeholder={t("block")}
    //             />
    //             <input
    //               value={this.props.floor}
    //               onChange={this.handlePickUpInputs}
    //               id="floor"
    //               name="9e26dc9a"
    //               autocomplete="9e26dc9a"
    //               className="floorPickup"
    //               placeholder={t("Floor")}
    //             />
    //             <input
    //               value={this.props.unit}
    //               onChange={this.handlePickUpInputs}
    //               id="unit"
    //               name="cf0d4937"
    //               autocomplete="cf0d4937"
    //               className="UnitPickup"
    //               placeholder={t("Unit")}
    //             />
    //           </>
    //         )}

    //       <div className="fourInputsContainer">
    //         {this.props.activeVehicleType &&
    //           this.props.activeVehicleType.setting
    //             .isPickupAddressNameActive && (
    //             <div className="inputWithRequired">
    //               <input
    //                 value={this.props.senderFullName}
    //                 name="abd27ae9"
    //                 autocomplete="abd27ae9"
    //                 onChange={this.handlePickUpInputs}
    //                 id="senderFullName"
    //                 className="senderfullnamePickup"
    //                 placeholder={t("senderfullname")}
    //               />
    //               {this.props.activeVehicleType.setting
    //                 .isPickupAddressNameRequired && (
    //                   <span className="inputRequired">
    //                     {this.props.t("Required")}
    //                   </span>
    //                 )}
    //             </div>
    //           )}

    //         {this.props.activeVehicleType &&
    //           this.props.activeVehicleType.setting
    //             .isPickupAddressPhoneActive && (
    //             <div className="inputWithRequired">
    //               <PhoneInput
    //                 defaultCountry={global.config.Country}
    //                 // onChange={(e)=>{this.fieldsOnChange("phone",e.target.value)}}
    //                 value={this.props.senderPhoneNumber}
    //                 onChange={(eee) =>
    //                   this.handlePhoneInput(eee, "senderPhoneNumber")
    //                 }
    //                 id={"senderPhoneNumber"}
    //                 name={"bbddca44" + this.props.number}
    //                 autocomplete={"bbddca44" + this.props.number}
    //                 className="senderphonenumberPickup"
    //                 placeholder={t("senderphonenumber")}
    //               />

    //               {this.props.activeVehicleType &&
    //                 this.props.activeVehicleType.setting &&
    //                 this.props.activeVehicleType.setting
    //                   .isPickupAddressPhoneRequired && (
    //                   <span className="inputRequired">
    //                     {this.props.t("Required")}
    //                   </span>
    //                 )}
    //             </div>
    //           )}

    //         {this.props.activeVehicleType &&
    //           this.props.activeVehicleType.setting
    //             .isPickupAddressEmailActive && (
    //             <div
    //               className="inputWithRequired"
    //               style={{
    //                 border:
    //                   this.props.email.length > 0
    //                     ? this.validate(this.props.email)
    //                       ? "solid 1px rgba(128, 128, 128, 0.2)"
    //                       : "1px solid red"
    //                     : "solid 1px rgba(128, 128, 128, 0.2)",
    //               }}
    //             >
    //               <input
    //                 value={this.props.email}
    //                 name="4efb28cd6"
    //                 autocomplete="4efb28cd6"
    //                 onChange={this.handlePickUpInputs}
    //                 id="email"
    //                 className="notesfordriverPickup"
    //                 placeholder={t("email")}
    //               />

    //               {this.props.activeVehicleType.setting
    //                 .isPickupAddressEmailRequired && (
    //                   <span className="inputRequired">
    //                     {this.props.t("Required")}
    //                   </span>
    //                 )}
    //             </div>
    //           )}

    //         <div className="inputWithRequired">
    //           <input
    //             value={this.props.noteForDriver}
    //             name="4efb28c6"
    //             autocomplete="4efb28c6"
    //             onChange={this.handlePickUpInputs}
    //             id="noteForDriver"
    //             className="notesfordriverPickup"
    //             placeholder={t("notesfordriver")}
    //           />
    //         </div>
    //       </div>
    //       <ServiceCustomHook serviceId={this.props.currentService}>
    //         <Schedule
    //           forceShow
    //           scheduleDateBefore={this.props.scheduleDateBefore}
    //           scheduleDateAfter={this.props.scheduleDateAfter}
    //         />
    //       </ServiceCustomHook>
    //     </Collapse>

    //     {/* <BackdropCustom
    //       open={this.state.showpoper}
    //       onClick={this.handleshowpoper}
    //     >
    //       <Popper
    //         style={{
    //           width: "475px",
    //           zIndex: "11",
    //           marginTop: "0px",
    //           maxHeight: "150x",
    //           minHeight: "87px",
    //           left: "0 !important",
    //         }}
    //         placement={"bottom"}
    //         id={"passenger"}
    //         open={this.state.showpoper}
    //         anchorEl={this.anchorEl.current}
    //       >
    //         <Paper
    //           className="xxx"
    //           style={{
    //             width: "476px",
    //             float: "right",
    //             borderRadius: "8px",
    //             paddingTop: "5px",
    //             marginTop: "10px",
    //             right: "271px",
    //             maxHeight: "150px",
    //             overflowY: "scroll",
    //             overflowX: "hidden",
    //           }}
    //         >
    //           <div
    //             style={{
    //               maxHeight: "150px",
    //               overflowY: "scroll",
    //               overflowX: "hidden",
    //             }}
    //           >
    //             {this.props.suggestPickUpDropOffListData
    //               ? this.props.suggestPickUpDropOffListData.length > 0 &&
    //                 !this.props.suggestListPickUpDropOffLoading &&
    //                 this.props.suggestPickUpDropOffListData.map((item) => {
    //                   if (item.secondaryAddress) {
    //                     return (
    //                       <favAddressselectorInList
    //                         onClick={() => {
    //                           this.handleList(item);
    //                         }}
    //                       >
    //                         <name> {item.address}</name>
    //                         <addres> {item.secondaryAddress}</addres>
    //                       </favAddressselectorInList>
    //                     );
    //                   } else {
    //                     return (
    //                       <serviceselectorInList
    //                         onClick={() => {
    //                           this.handleList(item);
    //                         }}
    //                       >
    //                         <addressEllipce> {item.address}</addressEllipce>
    //                       </serviceselectorInList>
    //                     );
    //                   }
    //                 })
    //               : null}

    //             {this.props.suggestListPickUpDropOffLoading && (
    //               <div>
    //                 <span
    //                   className="NotravelsListHeader"
    //                   style={{ marginTop: "10px" }}
    //                 >
    //                   <Trans i18nKey={"poperLoading"}> </Trans>
    //                 </span>
    //                 <span className="NotravelsListText">
    //                   <Trans i18nKey={"fetchingdata"}> </Trans>
    //                 </span>
    //               </div>
    //             )}

    //             {this.props.suggestPickUpDropOffListData
    //               ? !this.props.suggestListPickUpDropOffLoading &&
    //                 this.props.suggestPickUpDropOffListData.length === 0 && (
    //                   <div>
    //                     <span
    //                       className="NotravelsListHeader"
    //                       style={{ marginTop: "10px" }}
    //                     >
    //                       <Trans i18nKey={"Noinformationfound"}> </Trans>
    //                     </span>
    //                     <span className="NotravelsListText">
    //                       <Trans i18nKey={"Pleasetryanotherkeyword"}> </Trans>
    //                     </span>
    //                   </div>
    //                 )
    //               : null}
    //           </div>
    //         </Paper>
    //       </Popper>
    //     </BackdropCustom> */}

    //     <Popper
    //       style={{
    //         width: "475px",
    //         zIndex: "11",
    //         marginTop: "0px",
    //         maxHeight: "198px",
    //       }}
    //       placement={"bottom-end"}
    //       id={"passenger"}
    //       open={this.state.showpoperSaved}
    //       anchorEl={this.anchorEl.current}
    //       className="left25"
    //     >
    //       <ClickAwayListener onClickAway={this.handleshowpoperDrop}>
    //         <Paper
    //           className="xxx"
    //           style={{
    //             width: "476px",
    //             float: "right",
    //             borderRadius: "8px",
    //             paddingTop: "5px",
    //             marginTop: "10px",
    //             right: "271px",
    //             maxHeight: "201px",
    //             overflowY: "scroll",
    //             overflowX: "hidden",
    //           }}
    //           elevation={3}
    //         >
    //           <input
    //             value={this.state.searchAddrees}
    //             onChange={this.handleSavedDropInput}
    //             className="savedAddressSearch"
    //             placeholder={this.props.t("Search")}
    //           />
    //           <div
    //             style={{
    //               maxHeight: "111px",
    //               overflowY: "scroll",
    //               overflowX: "hidden",
    //             }}
    //           >
    //             {this.props.customerId
    //               ? this.props.favAddresss.length === 0 &&
    //               this.props.customerId.length > 0 && (
    //                 <div>
    //                   <span className="NotravelsListText">
    //                     <Trans i18nKey={"Noaddressavailable"}> </Trans>
    //                   </span>

    //                   <div
    //                     onClick={this.hanldeOpenNewFav}
    //                     className="divManagefavoriteaddresses"
    //                     style={{ marginTop: "10px" }}
    //                   >
    //                     <span className="Managefavoriteaddresses">
    //                       <Trans i18nKey={"Managefavoriteaddresses"}> </Trans>
    //                     </span>
    //                   </div>
    //                 </div>
    //               )
    //               : null}

    //             {this.props.customerId
    //               ? this.props.favAddresss.length > 0 &&
    //               this.props.customerId.length > 0 &&
    //               this.props.favAddresss
    //                 .filter((item) => {
    //                   return this.state.searchAddrees.length === 0
    //                     ? true
    //                     : item.title.includes(this.state.searchAddrees);
    //                 })
    //                 .map((item) => {
    //                   return (
    //                     <favAddressselectorInList
    //                       onClick={() => {
    //                         this.handleListFavAddress(item);
    //                       }}
    //                     >
    //                       <name> {item.title}</name>
    //                       <addres> {item.address}</addres>
    //                     </favAddressselectorInList>
    //                   );
    //                 })
    //               : null}
    //           </div>
    //           {this.props.customerId
    //             ? this.props.favAddresss.length !== 0 &&
    //             this.props.customerId.length > 0 && (
    //               <div
    //                 onClick={this.hanldeOpenNewFav}
    //                 className="divManagefavoriteaddresses"
    //               >
    //                 <span className="Managefavoriteaddresses">
    //                   <Trans i18nKey={"Managefavoriteaddresses"}> </Trans>
    //                 </span>
    //               </div>
    //             )
    //             : null}
    //         </Paper>
    //       </ClickAwayListener>
    //     </Popper>
    //   </passerngerContainer>
    // );
  }
}

const mapStateToProps = (state) => ({
  favAddresss: state.FavAddress.list,
  customerId: state.FavAddress.customerId,
  mapRef: state.Map.mapRef,
  center: state.Map.center,
  address: state.EditOrder.editOrder.pickup.address,
  adresss_loc: state.EditOrder.editOrder.pickup.adresss_loc,
  buildingBlock: state.EditOrder.editOrder.pickup.buildingBlock,
  floor: state.EditOrder.editOrder.pickup.floor,
  unit: state.EditOrder.editOrder.pickup.unit,
  senderFullName: state.EditOrder.editOrder.pickup.fullName,
  email: state.EditOrder.editOrder.pickup.email,
  senderPhoneNumber: state.EditOrder.editOrder.pickup.phone,
  noteForDriver: state.EditOrder.editOrder.pickup.noteForDriver,
  list: state.EditOrder.editOrder.pickup.list,
  loading: state.EditOrder.editOrder.pickup.loading,
  activePickupDropOff: state.EditOrder.editOrder.activePickupDropOff,
  center: state.Map.center,
  suggestListPickUpDropOffLoading:
    state.EditOrder.editOrder.suggestListPickUpDropOffLoading,
  suggestListPickUpDropOffOrdered:
    state.EditOrder.editOrder.suggestListPickUpDropOffOrdered,
  suggestPickUpDropOffListData:
    state.EditOrder.editOrder.suggestPickUpDropOffListData,
  scheduleDateBefore: state.EditOrder.editOrder.pickup.scheduleDateBefore,
  scheduleDateAfter: state.EditOrder.editOrder.pickup.scheduleDateAfter,
  isScheduled: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.isScheduled
    : false,
  openAddressBook: state.Addressbook.openAddressBookInNewOrder,
  addressBookSource: state.Addressbook.openAddressBookInNewOrder
    ? state.Addressbook.sourceOfAddressBookInNewOrder
      ? state.Addressbook.sourceOfAddressBookInNewOrder.type == "pickup"
      : false
    : false,
  activeVehicleType: state.EditOrder.editOrder.service
    ? state.EditOrder.editOrder.service.activeVehicleType
      ? state.EditOrder.editOrder.service.activeVehicleType
      : null
    : null,
  currentService: state.EditOrder.editOrder.service
    ? state.EditOrder.editOrder.service.activeVehicleType
      ? state.EditOrder.editOrder.service.activeVehicleType.id
      : null
    : null,
  ActivescheduleSetting: state.EditOrder.editOrder.service.activeVehicleType
    ? state.EditOrder.editOrder.service.activeVehicleType.scheduleSetting
      ? state.EditOrder.editOrder.service.activeVehicleType.scheduleSetting
        .isActive
      : false
    : false,
  recentAddress: state.EditOrder.editOrder.customer.recentAddreess
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));

function ServiceCustomHook(props) {
  const service = useLocalServices(props.serviceId);

  return <>{service && service.scheduleSetting.isActive && props.children}</>;
}


export const emailValidate=(email)=> {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}
