import styles from './style.module.css';
import { PickupRow } from './pickup-row/index';
import { DeliveryRow } from './delivery-row/index';
import { OrderStatusRow } from './order-status-row/index';
import MenuItem from '@material-ui/core/MenuItem';
import React, { useCallback } from 'react';
import Menu from '@material-ui/core/Menu';
import icPin from "../../../assets/ic-pin.svg";
import icDriver from "../../../assets/ic-driver2.svg";
import { OrderApi } from '../../../service/API/order';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { store } from '../../../Redux/store';
import { getFavAddressListAction, OrderInfoAction } from '../../../Redux/actions/actions';
import { ChangeViewTravelDialoge, Edit_Travel_Reset, SetActiveDriverList, SetDriverListOpenDialoge, SetSelectedMonitoringDriver, SetSelectedMonitoringOrder } from '../../../Redux/actions/actionNames';
import clsx from 'clsx';
import { GetColorByStatus } from '../../../utils/get-colors-by-status';
import { Link } from 'react-router-dom';
import history from '../../../service/history';
const _ = require('lodash');


const initialState = {
  mouseX: null,
  mouseY: null,
};

export const OrderListItem = ({ categoryName, data, OnPin, OnUnPin, canPinOrder, canUnPinOrder , showDelayLate ,lastItem ,}) => {
  const [state, setState] = React.useState(initialState);
  const [listItemDisabled, setListItemDisabled] = React.useState(false);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  function disableListItem(value) {
    setListItemDisabled(value)
  }

  const handleClick = (event) => {
    event.preventDefault();
    if((canPinOrder && !checkOrderIsPined(data.id)) ||  canUnPinOrder){
      setState({
        mouseX: event.clientX - 2,
        mouseY: event.clientY - 4,
      });
    }
  };

  const MenuHandleClick = (event) => {
    event.preventDefault();
    handleClose()
  };

  const handleClose = () => {
    setState(initialState);
  };

  const PinToTop = () => {
    OnPin();
    handleClose()
  };

  const UnPin = () => {
    OnUnPin();
    handleClose()
  };

  const checkOrderIsPined = (id) => {
    let storeData = store.getState();
    const Pinned = storeData.OrderList.list.Pinned.data

    return Pinned[id] ? true : false
  };


  // function CheckForVoipAssaign(){
  //   let MustAssaignVoipId = this.props.SelectedInCommingCallAction.type && this.props.SelectedInCommingCallAction.type == "Search" ;
  //   let CheckCustomer = MustAssaignVoipId ? this.props.SelectedInCommingCallAction.Data.customerId == this.props.customerId :false ;
  //   let allDriver = MustAssaignVoipId ? new Array(...this.props.currentCandidateDrivers) : [];
  //   this.props.driver && allDriver.push({ driverId: this.props.driverId})
  //   let checkDriver = MustAssaignVoipId ? allDriver.find(driver => driver.driverId == this.props.SelectedInCommingCallAction.Data.driverId) : false ;
  //   if(MustAssaignVoipId && (checkDriver || CheckCustomer)){
  //     this.props.dispatch(SetEventForOrderFromList(this.props.dispatch , {
  //       orderId:this.props.id ,
  //       voipRecordId :this.props.SelectedInCommingCallAction.Data.id
  //     }))
  //     this.props.dispatch(SetVoipIncommingCallAction({
  //       type : null ,
  //       Data : null
  //     }))
  //   }else {
  //     this.props.dispatch(SetVoipIncommingCallAction({
  //       type : null ,
  //       Data : null
  //     }))
  //   }

  // }
  function OncLIck() {
  try {
    //   dispatch(SetActiveDriverList(null));
    //  /* !isViewOrderOpen() && */ dispatch(SetSelectedMonitoringDriver(null))
    //   dispatch(SetDriverListOpenDialoge(false))
    //   dispatch(SetSelectedMonitoringOrder(false))
  } catch (error) {
    
  }

    // dispatch(Edit_Travel_Reset())

    // dispatch(
    //   ChangeViewTravelDialoge(true, data.id, data.customerId)
    // );
    // dispatch(
    //   OrderInfoAction(dispatch, {
    //     orderId: data.id,
    //   })
    // );
    // dispatch(
    //   getFavAddressListAction(dispatch, { id: data.customerId, page: 0, perpage: 100 })
    // );
    // dispatch(
    //   ChangeViewTravelDialoge(true, data.id, data.customerId)
    // );

    // this.CheckForVoipAssaign()
    setTimeout(() => {
      history.push(`/order/${data.id}`);
    }, 50);
  };


  
  const isViewOrderOpen = () => {
    if (String(window.location.href).includes("/order/")) {
        return true
    } else {
        return false
    }
}





  return <>
   <div  onClick={()=>{ !listItemDisabled &&  OncLIck()} } className={clsx(styles.orderListItem , lastItem && styles.orderListItemNoBorder  )} onContextMenu={handleClick}>
      <div className={clsx(styles.orderListItemFlag /*,styles[`${categoryName}CategoryFlag`]*/)} style={{ backgroundColor: `#${GetColorByStatus(data.status)}` }}></div>
      <div className={styles.orderListInner}>
        <PickupRow data={data} />
        <DeliveryRow dropoff={_.last(data.dropoffs) || data.dropoff} data={data} />
        <BorderDotted  dropoff={_.last(data.dropoffs) || data.dropoff} data={data}/>
        <OrderStatusRow data={data} showDelayLate={showDelayLate} disableListItem={disableListItem} />
      </div>



    </div>
    {state.mouseY && <Menu
    onContextMenu={MenuHandleClick}
    PaperProps={{
      style: {
        borderRadius: "8px",
        boxShadow:"0 1px 12px 0 rgba(128, 128, 128, 0.2)"
      }
     }}
      keepMounted
      open={state.mouseY !== null}
      onClose={handleClose}
      anchorReference="anchorPosition"
      anchorPosition={
        state.mouseY !== null && state.mouseX !== null
          ? { top: state.mouseY, left: state.mouseX }
          : undefined
      }
    >
      {

        (canPinOrder && !checkOrderIsPined(data.id)) && <MenuItem className={styles.ContextMenuItem} onClick={PinToTop}><img className={styles.ContextMenuItemImg} src={icPin} alt="" />  {t("PinTop")}</MenuItem>
      }
      {
        canUnPinOrder && <MenuItem className={styles.ContextMenuItem} onClick={UnPin}><img className={styles.ContextMenuItemImg} src={icPin} alt="" /> {t("UnPin")} </MenuItem>
      }


      {/* <MenuItem className={styles.ContextMenuItem} onClick={handleClose}><img className={styles.ContextMenuItemImg} src={icDriver} alt="" /> {t("StatusandDriver")} </MenuItem> */}
    </Menu>}
  </>


};




export const BorderDotted = ({ data , dropoff }) => {
  if (_.isEmpty(dropoff)) {
    return null
}
  if(["Ondemand","PickupDelivery"].indexOf(data.type) > -1){
    return <span className={styles.BorderDotted}> </span>
  }else{
    return null
  }
}
