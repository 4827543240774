import React from "react";
import { connect } from "react-redux";
import originIcon from "../../assets/pin_pickup.svg";
import destinationIcon from "../../assets/pin_dropoff.svg";
import {
  Set_Pickup_address,
  Set_DropOff_Inputs,
  Set_ActivePickupDropOff,
} from "../../Redux/actions/actionNames";
import { withTranslation, Trans } from "react-i18next";
import { GetLocationPlacenameAction } from "../../Redux/actions/actions";
import { getCenterMap } from "../../helper/module";
import ReactDOMServer from "react-dom/server";

import PickupPin from "./map-pickup-pin/Pin";
import DropOffPin from "./map-dropoff-pin/Pin";
import Pin from "./map-dropoff-pin-with-number/Pin";


class MapNewOrderPin extends React.Component {
  markerOnClick = () => {

    let center = getCenterMap(this.props.mapRef, this.props.center)
    let xope = JSON.parse(JSON.stringify(this.props.activePickupDropOff));

    this.props.dispatch(
      GetLocationPlacenameAction(
        this.props.dispatch,
        {
          latitude: center.lat,
          longitude: center.lng,

        },
        (RES) => {
          let originShow = xope.type === "pickup";
          let destinationShow =
            xope.type === "dropoff";
          if (originShow) {
            this.props.dispatch(
              Set_Pickup_address({
                address: RES.address,
                placeId: RES.placeId,
                adresss_loc: {
                  lng: center.lng,
                  lat: center.lat,
                },
              })
            );
            // setTimeout(() => {
            //   this.props.dispatch(Set_ActivePickupDropOff({id: "" ,type: null}));
            // }, 400);
          } else {
            this.props.dispatch(
              Set_DropOff_Inputs({
                name: "address",
                value: RES.address,
                firstDropOff: xope.firstDropOff,
                id: xope.id,
              })
            );
            this.props.dispatch(
              Set_DropOff_Inputs({
                name: "placeId",
                value: RES.placeId,
                firstDropOff: xope.firstDropOff,
                id: xope.id,
              })
            );
            this.props.dispatch(
              Set_DropOff_Inputs({
                name: "adresss_loc",
                value: {
                  lng: center.lng,
                  lat: center.lat,
                },
                firstDropOff: xope.firstDropOff,
                id: xope.id,
              })
            );
            // setTimeout(() => {
            //   this.props.dispatch(Set_ActivePickupDropOff({id: "" ,type: null}));
            // }, 400);
          }


          // if (this.props.FORCE) {
          //   this.props.dispatch(Set_ActivePickupDropOff({
          //     type: null,
          //     id: "",
          //   }))
          // }

          // this.props.dispatch(Set_ActivePickupDropOff({
          //   type: this.props.activePickupDropOff.type,
          //   id: this.props.activePickupDropOff.id,
          // }));

          
        }
      )
    );


  };


  getNumber = () => {


    var index = -1;
    [{ ...this.props.firstDropOff }, ...this.props.otherDropOffs].some((entry, i) => {
      if (entry.id == this.props.activePickupDropOff.id) {
        index = i;
      }
    });

    return index + 1

  };

  othersDestinationShowAfterSelect = () => {
    let selectedDropoof = this.props.otherDropOffs.find((drop) => {
      return drop.id === this.props.activePickupDropOff.id;
    });
    if (selectedDropoof) {
      if (selectedDropoof.adresss_loc.lng === "") {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  render() {
    let originShow =
      this.props.activePickupDropOff.type === "pickup" &&
      this.props.pickupAdresss_loc.lng === "" && ["PickupDelivery", "Pickup", "Ondemand"].indexOf(
        this.props.orderTypeActive
      ) > -1;
    let destinationShow =
      this.props.activePickupDropOff.type === "dropoff" &&
      this.props.dropOffsAdresss_loc.lng === "" && ["PickupDelivery", "Delivery", "Ondemand"].indexOf(
        this.props.orderTypeActive
      ) > -1;
    let othersDestinationShow =
      this.props.activePickupDropOff.type === "dropoff" &&
      !this.props.activePickupDropOff.firstDropOff;

    let othersDestinationShowAfterSelect = this.othersDestinationShowAfterSelect();

    return (
      <div>
        {this.props.OpenDialoge && !this.props.FavAddressOpenDialoge && originShow && (
          <img
            onClick={this.markerOnClick}
            src={'data:image/svg+xml;charset=utf-8,' +
              encodeURIComponent(ReactDOMServer.renderToStaticMarkup(<PickupPin color={"#1795ff"} />))}
            alt=""
            className={`originShow ${global.config.dispatcherSettings.applicationMap.type == "google" && "originShowGoogle"} ${this.props.hideNavbar && 'hideNavbarOriginShow'}`}
          />
        )}

        {this.props.OpenDialoge && !this.props.FavAddressOpenDialoge && destinationShow && (
          this.props.otherDropOffs.length == 0 ? <img
            onClick={this.markerOnClick}
            src={'data:image/svg+xml;charset=utf-8,' +
              encodeURIComponent(ReactDOMServer.renderToStaticMarkup(<DropOffPin color={"#0064d2"} />))}
            alt=""
            className={`originShow ${global.config.dispatcherSettings.applicationMap.type == "google" && "originShowGoogle"} ${this.props.hideNavbar && 'hideNavbarOriginShow'}`}
          /> : <Pin onClick={this.markerOnClick}
            className={`originShow ${global.config.dispatcherSettings.applicationMap.type == "google" && "originShowGoogle"} ${this.props.hideNavbar && 'hideNavbarOriginShow'}`}
            color={"#0064d2"} style={{ position: "absolute", width: "40px", height: "53px" }} bigSize={true} number={this.getNumber()} numberStyle={{ position: "absolute", width: "20px", height: "20px", left: "5px", top: "7px", fontSize: "14px", fontWeight: "bold", fontFamily: 'sf_pro', textAlign: "center" }} />


        )}



        {this.props.OpenDialoge && !this.props.FavAddressOpenDialoge &&
          othersDestinationShow && !destinationShow &&
          othersDestinationShowAfterSelect && (
            this.props.otherDropOffs.length == 0 ? <img
              onClick={this.markerOnClick}
              src={'data:image/svg+xml;charset=utf-8,' +
                encodeURIComponent(ReactDOMServer.renderToStaticMarkup(<DropOffPin color={"#0064d2"} />))}
              alt=""
              className={`originShow ${global.config.dispatcherSettings.applicationMap.type == "google" && "originShowGoogle"} ${this.props.hideNavbar && 'hideNavbarOriginShow'}`}
            /> : <Pin onClick={this.markerOnClick}
              className={`originShow ${global.config.dispatcherSettings.applicationMap.type == "google" && "originShowGoogle"} ${this.props.hideNavbar && 'hideNavbarOriginShow'}`}
              color={"#0064d2"} style={{ position: "absolute", width: "40px", height: "53px" }} bigSize={true} number={this.getNumber()} numberStyle={{ position: "absolute", width: "20px", height: "20px", left: "5px", top: "7px", fontSize: "14px", fontWeight: "bold", fontFamily: 'sf_pro', textAlign: "center" }} />


            // <img
            //   onClick={this.markerOnClick}
            //   src={'data:image/svg+xml;charset=utf-8,' +
            //   encodeURIComponent(ReactDOMServer.renderToStaticMarkup(<DropOffPin color={"#0064d2"}/>))}
            //   alt=""
            //   className={`originShow ${global.config.dispatcherSettings.applicationMap.type == "google" && "originShowGoogle"} ${this.props.hideNavbar && 'hideNavbarOriginShow'}`}
            //   />
          )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  mapRef: state.Map.mapRef,
  center: state.Map.center,
  FavAddressOpenDialoge: state.FavAddress.openDialoge,
  OpenDialoge: state.NewOrder.OpenDialoge || ownProps.FORCE,
  center: state.Map.center,
  activePickupDropOff: state.NewOrder.newOrder.activePickupDropOff,
  loading: state.Map.realtimePlaceName.loading,
  address: state.Map.realtimePlaceName.address,
  placeId: state.Map.realtimePlaceName.placeId,
  pickupAddress: state.NewOrder.newOrder.pickup.address,
  pickupAdresss_loc: state.NewOrder.newOrder.pickup.adresss_loc,
  dropOffAddress: state.NewOrder.newOrder.dropOffs.firstDropOff.address,
  dropOffsAdresss_loc:
    state.NewOrder.newOrder.dropOffs.firstDropOff.adresss_loc,
  otherDropOffs: state.NewOrder.newOrder.dropOffs.otherDropOffs,
  firstDropOff: state.NewOrder.newOrder.dropOffs.firstDropOff,

  orderTypeActive: state.NewOrder.newOrder.orderTypeActive,

});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(MapNewOrderPin));
