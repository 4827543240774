import Axios from "axios";
import {restClient} from "./axios";
// import {dashboardRest, restClient} from "./axios";

export class LoginApi {
  
  async login({username ,password}) {
    // try {
    //   const response = await dashboardRest.request({
    //     method : 'POST',
    //     url: 'https://dispatcher.onro.org/admin/auth/login',
    //     data: {
    //       username,
    //       password
    //     }
    //   });

      
    //   return {
    //     userId : response.data.data.userId,
    //     accessToken : response.data.data.accessToken,
    //     refreshToken : response.data.data.refreshToken,
    //     name : response.data.data.name,
    //     username : response.data.data.username,
    //   }

    // } catch (error) {
    //   if(error.response){
    //     return Promise.reject({
    //       code : error.response.data.code,
    //       message: error.response.data.message
    //     })
    //   }else {
    //     return Promise.reject()
    //   }
    // }

    try {
      const response = await restClient.request({
        method : 'POST',
        url: '/api/v1/dispatcher/auth/login',
        data: {
          username,
          password
        }
      });

      
      return {
        userId : response.data.data.userId,
        accessToken : response.data.data.accessToken,
        refreshToken : response.data.data.refreshToken,
        name : response.data.data.name,
        username : response.data.data.username,
        adminToken: response.data.data.adminToken,
      }

    } catch (error) {
      if(error.response){
        return Promise.reject({
          code : error.response.data.code,
          message: error.response.data.message
        })
      }else {
        return Promise.reject()
      }
    }


  }


  async Dashboardlogin(adminToken) {
    try {
      const response = await Axios.request({
        method : 'POST',
        url: '/admin/auth/dispatcher-login',
        data: {
          adminToken
        }
      });

      console.log("Dashboardlogin ",response)

      return {
        userId : response.data.data.userId,
        accessToken : response.data.data.accessToken,
        refreshToken : response.data.data.refreshToken,
        name : response.data.data.name,
        username : response.data.data.username,
        adminToken: response.data.data.adminToken,
        code: response.data.code,
      }

    } catch (error) {
      if(error.response){
        return Promise.reject({
          code : error.response.data.code,
          message: error.response.data.message
        })
      }else {
        return Promise.reject()
      }
    }


  }
}
