import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import { get_id, get_lang } from "../../../../../helper/UserData/userdate";
import styles from './index.module.css';
import { get_available_pick_dates } from "../../../../../helper/module";
import arrow2 from "../../../../../assets/arrow-triangle-down.svg";
import jalali from "jalali-moment";
import { ClickAwayListener, Paper, Popper } from "@material-ui/core";

var _ = require("lodash");







class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showpoper: false,
      showpoperHour: false,
      dispatchLaterData: get_available_pick_dates(),
      selectedDate: null,
      selectedDay: null,
      hour: Number(jalali(props.PickupOrderInfo.dispatchAt).locale(get_lang()).format("HH")),
      minute:  Number(jalali(props.PickupOrderInfo.dispatchAt).locale(get_lang()).format("mm")),
    };
    this.anchorEl = React.createRef();
    this.anchorEl2 = React.createRef();

  }


  handleDispatchLater = (val) => {
    this.props.handleDispatchLater(val)
  };

  handleshowpoper = () => {
    this.setState({
      showpoper: !this.state.showpoper,
    });
  };

  handleshowpoperHour = () => {
    this.setState({
      showpoperHour: !this.state.showpoperHour,
    },()=>{
      if(this.state.showpoperHour){
        setTimeout(() => {
       try {
           var hour = document.getElementById(`hour${this.state.hour}`);
           var minute = document.getElementById(`minute${this.state.minute}`);
           hour.scrollIntoView({block: "end"});
           minute.scrollIntoView({block: "end"});
       } catch (error) {
        
       }

        }, 100);
      }
    });
  };

  selectDay = (val) => {
    this.setState({
      selectedDay: val,
    }, () => {
      let monthYear = jalali(this.state.selectedDate.month).locale(get_lang()).format("YYYY/MM")
      this.props.handleSelectedTimeStamp(Number(jalali.from(`${monthYear}/${this.state.selectedDay} ${this.state.hour}:${this.state.minute}`, get_lang(), "YYYY/MM/DD HH:mm").locale(get_lang()).format("x")))
    });
  };

  selectHour = (val) => {
    this.setState({
      hour: val,
    }, () => {
      let monthYear = jalali(this.state.selectedDate.month).locale(get_lang()).format("YYYY/MM")
      this.props.handleSelectedTimeStamp(Number(jalali.from(`${monthYear}/${this.state.selectedDay} ${this.state.hour}:${this.state.minute}`, get_lang(), "YYYY/MM/DD HH:mm").locale(get_lang()).format("x")))

    });
  };

  selectMinute = (val) => {
    this.setState({
      minute: val,
    }, () => {
      let monthYear = jalali(this.state.selectedDate.month).locale(get_lang()).format("YYYY/MM")
      this.props.handleSelectedTimeStamp(Number(jalali.from(`${monthYear}/${this.state.selectedDay} ${this.state.hour}:${this.state.minute}`, get_lang(), "YYYY/MM/DD HH:mm").locale(get_lang()).format("x")))

    });
  };


  selectMonth = (val) => {
    this.setState({
      selectedDate: val,
      selectedDay: val.from,
    }, () => {
      let monthYear = jalali(this.state.selectedDate.month).locale(get_lang()).format("YYYY/MM")
      this.props.handleSelectedTimeStamp(Number(jalali.from(`${monthYear}/${this.state.selectedDay} ${this.state.hour}:${this.state.minute}`, get_lang(), "YYYY/MM/DD HH:mm").locale(get_lang()).format("x")))

    });
  };

  checkMonthSelected = (val) => {
    return jalali(val.month).locale(get_lang()).format("MMMM") == jalali(this.state.selectedDate.month).locale(get_lang()).format("MMMM")
  };



  componentDidMount() {
    if (this.state.dispatchLaterData) {
      this.setState({
        selectedDate: this.state.dispatchLaterData[0],
        selectedDay: this.state.dispatchLaterData[0].from
      }, () => {
        let monthYear = jalali(this.state.selectedDate.month).locale(get_lang()).format("YYYY/MM")
        this.props.handleSelectedTimeStamp(Number(jalali.from(`${monthYear}/${this.state.selectedDay} ${this.state.hour}:${this.state.minute}`, get_lang(), "YYYY/MM/DD HH:mm").locale(get_lang()).format("x")))

      })
    }
  }

  render() {
    const { t } = this.props;


    if (["Pending"].indexOf(this.props.PickupOrderInfo.status) == -1) {
      return null
    }

    return (
      <div className={styles.dispatchTimeContainer}>
        <div className={styles.firstRow}>
          {this.props.isDispatching && <span className={styles.Dispatchtime}>  {t("Pickup")} <span style={{ fontWeight: "normal" }}>{t("Dispatchtime")}</span> {(!this.props.canEdit ) && <span className={styles.DispatchingNow}>{t("DispatchingNow")} </span>} </span>
          }
          {!this.props.isDispatching && <span className={styles.Dispatchtime}>{t("Pickup")} <span style={{ fontWeight: "normal" }}>{t("Dispatchtime")}</span> {(!this.props.canEdit && !this.props.isDispatching) && <span className={styles.DispatchingNow}>{String(t("Dispatchat")).replace("xxx", jalali(this.props.PickupOrderInfo.dispatchAt)
            .locale(get_lang())
            .format("D MMM HH:mm"))} </span>}</span>}

          {this.props.canEdit && <personBusinessSwitchContainer >
            <history
              id="history"
              onClick={() => this.handleDispatchLater(false)}
              className={`${!this.props.dispatchLater ? "details" : "DEactive"
                }`}
            >
              <Trans i18nKey={"now"}> </Trans>
            </history>
            <detail
              id="details"
              onClick={() => this.handleDispatchLater(true)}
              className={`${this.props.dispatchLater ? "details" : "DEactive"
                }`}
            >
              <Trans i18nKey={"Later"}> </Trans>
            </detail>

          </personBusinessSwitchContainer>}
          {!this.props.canEdit && <span className={styles.Edit} onClick={() => { this.props.handleCanEdit("pickupcanEdit") }}><Trans i18nKey={"Edit"}> </Trans></span>}

        </div>


        {(this.props.dispatchLater && this.props.canEdit) && <div className={styles.pickersContainers}>
          <div className={styles.picker} ref={this.anchorEl} onClick={this.handleshowpoper}> <span> {this.props.selectedTimeStamp && jalali(this.props.selectedTimeStamp).locale(get_lang()).format("DD MMMM")} </span> <img src={arrow2} /> </div>
          <div className={styles.picker} ref={this.anchorEl2} onClick={this.handleshowpoperHour}> <span>{`${this.state.hour}:${this.state.minute}`}</span>  <img src={arrow2} /></div>
          {/* <div className={styles.picker}> <span>{this.state.minute}</span>  <img src={arrow2} /></div> */}
        </div>}

        {this.state.showpoper &&
          <Popper
            open={this.state.showpoper}
            anchorEl={this.anchorEl.current}
            placement={"bottom"}

            style={{
              width: "160px",
              height: "80px",
              marginTop: "5px",
              // border: "solid 1px rgba(128, 128, 128, 0.2)",
              borderRadius: "10px",
              // backgroundColor: "white",
              //  boxShadow: "0 1px 12px 0 rgba(128, 128, 128, 0.2)",
              // zIndex: "1",
              //  position:"fixed !important"
            }}
            className="Popperxxx"
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Paper>
                <ClickAwayListener
                  onClickAway={(e) => {
                    this.handleshowpoper(null)
                  }}
                >
                  <div className={styles.pickerDrop}>
                    <div className={styles.pickerDay}> {range(this.state.selectedDate.from, this.state.selectedDate.to).map((day) => {
                      return <span onClick={() => { this.selectDay(day) }} className={day == this.state.selectedDay && styles.activeSelectedDay}>{day}</span>
                    })}
                    </div>

                    <div className={styles.pickerMonth}>
                      {this.state.dispatchLaterData.map((date) => {
                        return <span onClick={() => { this.selectMonth(date) }} className={this.checkMonthSelected(date) && styles.activeSelectedDay}>{jalali(date.month).locale(get_lang()).format("MMMM")}</span>
                      })}
                    </div>
                  </div>
                </ClickAwayListener>
              </Paper>
            )}
          </Popper>
        }

        {this.state.showpoperHour &&
          <Popper
            open={this.state.showpoperHour}
            anchorEl={this.anchorEl2.current}
            placement={"bottom"}

            style={{
              width: "160px",
              height: "80px",
              marginTop: "5px",
              // border: "solid 1px rgba(128, 128, 128, 0.2)",
              borderRadius: "10px",
              // backgroundColor: "white",
              //  boxShadow: "0 1px 12px 0 rgba(128, 128, 128, 0.2)",
              // zIndex: "1",
              //  position:"fixed !important"
            }}
            className="Popperxxx"
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Paper>
                <ClickAwayListener
                  onClickAway={(e) => {
                    this.handleshowpoperHour(null)
                  }}
                >
                  <div className={styles.pickerDrop}>
                    <div className={styles.pickerTime}> {range(0, 23).map((hour) => {
                      return <span  id={`hour${hour}`}  onClick={() => { this.selectHour(hour) }} className={hour == this.state.hour && styles.activeSelectedDay}>{hour}</span>
                    })}
                    </div>

                    <div className={styles.pickerTime} style={{ border: "none" }}>
                      {range(1, 59).map((minute) => {
                        return <span id={`minute${minute}`} onClick={() => { this.selectMinute(minute) }} className={minute == this.state.minute && styles.activeSelectedDay}>{minute}</span>
                      })}
                    </div>
                  </div>
                </ClickAwayListener>
              </Paper>
            )}
          </Popper>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  dispatchAt: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.dispatchAt
      ? state.ViewTravel.pureData.dispatchAt
      : null
    : null,
  status: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.status
      ? state.ViewTravel.pureData.status
      : null
    : null,
  isDispatching: state.ViewTravel.pureData.isDispatching,
  driverAssignmentModel: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.driverAssignmentModel
      ? state.ViewTravel.pureData.driverAssignmentModel
      : null
    : null,

  pureData: state.ViewTravel.pureData
    ? state.ViewTravel.pureData
      ? state.ViewTravel.pureData
      : null
    : null,

  PickupOrderInfo: state.AdvancedAssaigning.PickupOrderInfo,

});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));


function range(start, end) {
  return Array(end - start + 1).fill().map((_, idx) => (start + idx).toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false
  }))
}