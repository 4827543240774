import React from "react";
import { Map, Marker, TileLayer, Popup, Polyline } from "react-leaflet";
import L from "leaflet";
import { connect } from "react-redux";
import event from "../../../../assets/pin_event.svg";

const createtIcon = (icon) => {
  return new L.Icon({
    iconUrl: icon,
    iconAnchor: [20.5, 60],
    popupAnchor: [0, -35],
    iconSize: [65, 65],
    shadowSize: [29, 40],
    shadowAnchor: [7, 40],
  });
};

class App extends React.Component {
  render() {
    let originShow = this.props.loc !== null;
    return (
      <React.Fragment>
        {this.props.FavAddressOpenDialoge && originShow ? (
          <Marker
            animate={true}
            position={this.props.loc}
            icon={createtIcon(event)}
          ></Marker>
        ) : null}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  FavAddressOpenDialoge: state.ViewTravel.eventTimeLine.newEvent.show,
  loc: state.ViewTravel.eventTimeLine.newEvent.mapLocation,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(mapStateToProps, mapDispatchToProps)(App);
