import React, { Component, useState } from "react";
import ReactDOM from "react-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { UseCRUDDropoffs } from "../../../../../hooks/useCRUD_Dropoffs";
import Dropoff from "./dropoff";
import { Set_ActivePickupDropOff } from "../../../../../Redux/actions/actionNames";
import { useDispatch } from "react-redux";

// fake data generator
const getItems = count =>
  Array.from({ length: count }, (v, k) => k).map(k => ({
    id: `item-${k}`,
    content: `item   fef wef we ewf wef ewf wefe fwe fef we f wef wef we f wef we f ewf ewf wf${k}`
  }));

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: 0,
  margin: `0`,
  float:"left",
  // change background colour if dragging
  // background: isDragging ? "lightgreen" : "grey",

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: "#fbfdff",
  padding: 0,
  width: "100%" ,
  float:"left",
  position: "relative",
  marginTop:"2px"
});

const queryAttr = "data-rbd-drag-handle-draggable-id";


export const DropoffsContainer = ({ handleCollapse , addDropOff , removeDrop}) => {
    let { items, sEtItems } = UseCRUDDropoffs()
    const [placeholderProps, setPlaceholderProps] = useState({});
    const dispatch = useDispatch();

    // const [items, setItems] = useState(getItems(10));


  function onDragEnd(result) {
    dispatch(Set_ActivePickupDropOff({id: "" ,
      type: null}));

    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const itemsx = reorder(
      items,
      result.source.index,
      result.destination.index
    );

    sEtItems(itemsx)
    setPlaceholderProps({})
  }

  const onDragUpdate = update => {
    if(!update.destination){
      return;
    }
		const draggableId = update.draggableId;
		const destinationIndex = update.destination.index;

		const domQuery = `[${queryAttr}='${draggableId}']`;
		const draggedDOM = document.querySelector(domQuery);

		if (!draggedDOM) {
			return;
		}

		const { clientHeight, clientWidth } = draggedDOM;
		const clientY = parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) + [...draggedDOM.parentNode.children]
			.slice(0, destinationIndex)
			.reduce((total, curr) => {
				const style = curr.currentStyle || window.getComputedStyle(curr);
				const marginBottom = parseFloat(style.marginBottom);
				return total + curr.clientHeight + marginBottom;
			}, 0);

		setPlaceholderProps({
			clientHeight,
			clientWidth,
      clientY,
      clientX: parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingLeft)
		});
	};

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
    return (
      <DragDropContext onDragEnd={onDragEnd}  onDragUpdate={onDragUpdate}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {items.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index} isDragDisabled={items.length == 1}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                              <Dropoff
                                dropOffsCount={items.length}
                                isDraggingOver = {snapshot.isDragging}
                                id={item.id}
                                handleCollapse={handleCollapse}
                                last={items.length - 1 == index}
                                {...item}
                                first={index == 0}
                                addDropOff={addDropOff}
                                removeDrop={removeDrop}
                            />
                            
                    </div>
                  )}
                </Draggable>
              ))}
				{provided.placeholder}
            {/* <CustomPlaceholder snapshot={snapshot} /> */}
            {/* <div style={{
              position: "absolute",
              top: placeholderProps.clientY,
              left: placeholderProps.clientX,
              height: placeholderProps.clientHeight,
              background: "lightgrey",
              width: placeholderProps.clientWidth,
            }}/> */}
             
            </div>
          )}
        </Droppable>
        
      </DragDropContext>
    );
}


export default DropoffsContainer











// import React from "react";
// import { render } from "react-dom";
// import RLDD from "react-list-drag-and-drop/lib/RLDD";
// import { UseCRUDDropoffs } from "../../../../../hooks/useCRUD_Dropoffs";
// import Dropoff from "./dropoff";
// import "./RLDD.css";


// export const DropoffsContainer = ({ handleCollapse , addDropOff}) => {

//     let { items, sEtItems } = UseCRUDDropoffs()

//     function itemRenderer(item, index) {
//         return (
//             <Dropoff
//             id={item.id}
//             handleCollapse={handleCollapse}
//             last={items.length === 1}
//             {...item}
//             firstDropOff={index == 0}
//             addDropOff={addDropOff}
//           />
//         );
//     }

//     function handleRLDDChange(reorderedItems) {
//         
//         sEtItems(reorderedItems);
//     }
    
//     

//     return (
//         <RLDD
//             cssClasses="example"
//             items={items}
//             itemRenderer={itemRenderer}
//             onChange={handleRLDDChange}
//         />
//     );

// }

