import otos from "../../../assets/oto_logotype.png";
import FavIcon from "../../../assets/PROJECTS/oto.png";

export default function oto() {
    
    return global.config = {
    "ProjectName": "OTO",
    "LogoType": otos,
    "BaseApi": "https://rest.otokh.com",
    "BaseSocket": "https://socket.otokh.com",
    "BaseTrack": "https://panel.otokh.com",
    "MapCenter": {
        "lat": "11.605189",
        "lng": "104.911424"
    },
    "Lang": "en",
    "Direction": "ltr",
    "AllLanguages": [{
        "lang": "en",
        "direction": "ltr",
        "i18Lang": "English"
    }, {
        "lang": "fa",
        "direction": "rtl",
        "i18Lang": "Persian"
    }, {
        "lang": "fr",
        "direction": "ltr",
        "i18Lang": "French"
    }, {
        "lang": "ro",
        "direction": "ltr",
        "i18Lang": "Romanian"
    }],
    "FavIcon": FavIcon,
    "Country": "KH",
    "thirdPartyLoginSupport": true  ,
    mapType :"osm", /* osm | google */ 
    googleApiKey : ""
} }