import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './index.module.css';
import Forbidden from "../../assets/403_Forbidden.png";
import Typography from '../../design-system/lib/Typography';
import Button from '../../design-system/lib/Button';


export function RestrictedAccessLayout() {
    return <div class={styles.layoutContainer}>

        <img src={Forbidden} width={690} style={{marginBottom:"16px"}} />
        <Typography weight='Body_Middle_Bold' style={{fontSize:"36px",marginBottom:"12px"}} text={"We are sorry ..."} />
        <Typography weight='Body_Middle_Regular' style={{width:"325px",textAlign:'center'}} text={"The page you are trying to access has restricted access."} />
        <Typography weight='Body_Middle_Regular' style={{width:"325px",textAlign:'center',marginBottom:"16px"}} text={"Please refer to your system administrator."} />
        <a href='/'>
        <Button size='Small' type='Primary' text='Go to Home' />
        </a>
    </div>


}
