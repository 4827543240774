import { Component } from "react";
import { connect } from "react-redux";
import { CalculatePriceWorker } from "../../../../../helper/worker/CalculatePriceWorker";
import { OrderondemandAction } from "../../../../../Redux/actions/actions";
import { get_id } from "../../../../../helper/UserData/userdate";
import { SetCanSaveTravel } from "../../../../../Redux/actions/actionNames";
import { isEmpty } from "lodash";

class App extends Component {

  
  componentWillReceiveProps(nextProps) {
    if (nextProps.price.done && (nextProps.customer.person  ?   nextProps.customer.id.length > 0 : nextProps.customer.BusinessId.length > 0) && this.WalletBalance(nextProps) && this.serviceRequiredCheck(nextProps) && this.vehicleRequiredCheck(nextProps)  && this.pickupRequiredCheck(nextProps) && this.firstDropOffRequiredCheck(nextProps) && this.otherDropOffRequiredCheck(nextProps)) {
      nextProps.dispatch(
        SetCanSaveTravel({ canSaveOrder: true, loading: false })
      );
    } else {
      nextProps.dispatch(
        SetCanSaveTravel({ canSaveOrder: false, loading: false })
      );
    }
  }

  WalletBalance = (props) => {
      if(props.paymentSide+props.paymentMethod == "SenderWallet"){
        if (props.price.price && (props.customer.balance - props.price.price.price) >= props.customer.minimumBalance) {
          return true
        } else {
          return false
        }
      }else{
        return true
      }
  };


  serviceRequiredCheck = (props) => {

    if (props.serviceactiveVehicleType) {
      if (props.serviceactiveVehicleType.options.length == 0) {
        return true
      } else {
        let isNotExist = false;
        let arrFiltered = props.serviceactiveVehicleType.options.filter((item) => item.isRequired);
        arrFiltered.forEach(element => {
          if (!this.optionisSaved(props, element.id)) {
            isNotExist = true;
          }
        });
        return isNotExist == false
      }
    } else {
      return false
    }
  };


  optionisSaved(props, id) {
    let booll = false;
    booll = props.serviceoptions.find((item) => {
      return item.id == id;
    });
    if (booll) {
      return true
    } else {
      return false
    }
  }



  vehicleRequiredCheck = (props) => {
    let activeVehicleType = props.auto ? props.auto_activeVehicleType  : props.manual_activeVehicleType ;
    if (activeVehicleType) {
      if (activeVehicleType.options.length == 0) {
        return true
      } else {
        let isNotExist = false;
        let arrFiltered = activeVehicleType.options.filter((item) => item.isRequired);
        arrFiltered.forEach(element => {
          if (!this.VehicleOptionisSaved(props, element.id)) {
            isNotExist = true;
          }
        });
        return isNotExist == false
      }
    } else {
      return false
    }
  };



  emailValidate(email) {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }


  VehicleOptionisSaved(props, id) {
    let booll = false;
    booll = props.driveroptions.find((item) => {
      return item.id == id;
    });
    if (booll) {
      return true
    } else {
      return false
    }
  }


  pickupRequiredCheck = (props) => {
    if (props.serviceactiveVehicleType) {
      let name = false, email = false, phone = false;

      if (props.serviceactiveVehicleType.setting.isPickupAddressNameRequired) {
        if (props.pickup.senderFullName && String(props.pickup.senderFullName).trim().length > 0) {
          name = true
        } else {
          name = false
        }
      } else {
        name = true
      }

      if (props.serviceactiveVehicleType.setting.isPickupAddressPhoneRequired) {
        if (props.pickup.senderPhoneNumber && String(props.pickup.senderPhoneNumber).trim().length > 0) {
          phone = true
        } else {
          phone = false
        }
      } else {
        phone = true
      }


      if (props.serviceactiveVehicleType.setting.isPickupAddressEmailRequired) {
        if (props.pickup.email && String(props.pickup.email).length > 0 && this.emailValidate(String(props.pickup.email))) {
          email = true
        } else {
          email = false
        }
      } else {
        email = true
      }



      return (name && phone && email);

    } else {
      return false
    }

  }

  firstDropOffRequiredCheck = (props) => {
    if (props.serviceactiveVehicleType) {
      let name = false, email = false, phone = false;

      if (props.serviceactiveVehicleType.setting.isDropoffAddressNameRequired) {
        if (props.firstDropOff.recieverFullName && String(props.firstDropOff.recieverFullName).trim().length > 0) {
          name = true
        } else {
          name = false
        }
      } else {
        name = true
      }

      if (props.serviceactiveVehicleType.setting.isDropoffAddressPhoneRequired) {
        if (props.firstDropOff.recieverPhoneNumber && String(props.firstDropOff.recieverPhoneNumber).trim().length > 0) {
          phone = true
        } else {
          phone = false
        }
      } else {
        phone = true
      }


      if (props.serviceactiveVehicleType.setting.isDropoffAddressEmailRequired) {
        if (props.firstDropOff.email && String(props.firstDropOff.email).length > 0 && this.emailValidate(String(props.firstDropOff.email))) {
          email = true
        } else {
          email = false
        }
      } else {
        email = true
      }



      return (name && phone && email);

    } else {
      return false
    }

  }


  otherDropOffRequiredCheck = (props) => {
    if (props.serviceactiveVehicleType) {
      if (!isEmpty(props.otherdropOffs)) {
        let status = true;    
  
        props.otherdropOffs.forEach((dropOff, index) => {    
          let name = false, email = false, phone = false;
    
          if (props.serviceactiveVehicleType.setting.isDropoffAddressNameRequired) {
            if (dropOff.recieverFullName && String(dropOff.recieverFullName).trim().length > 0) {
              

              name = true
            } else {
              name = false
              status = false
            }
          } else {
            name = true
          }
    
          if (props.serviceactiveVehicleType.setting.isDropoffAddressPhoneRequired) {
            if (dropOff.recieverPhoneNumber && String(dropOff.recieverPhoneNumber).trim().length > 0) {
              

              phone = true
            } else {
              phone = false
              status = false
            }
          } else {
            phone = true
          }
    
    
          if (props.serviceactiveVehicleType.setting.isDropoffAddressEmailRequired) {
            if (dropOff.email && String(dropOff.email).length > 0 && this.emailValidate(String(dropOff.email))) {
              

              email = true
            } else {
              email = false
              status = false
            }
          } else {
            email = true
          }
    
        });

        if (status) {
          return true
        } else {
          return false
        }
    
      } else {
        
        return true
      }
    } else {
      
      return false
    }

  }


  render() {
    return null;
  }
}

const mapStateToProps = (state) => ({
  price: state.NewOrder.newOrder.price,
  customer: state.NewOrder.newOrder.customer,

  paymentMethod: state.NewOrder.newOrder.paymentMethod.paymentMethod,
  paymentSide: state.NewOrder.newOrder.paymentMethod.paymentSide,
  type: state.NewOrder.newOrder.customer.type,
  balance: state.NewOrder.newOrder.customer.balance,

  serviceactiveVehicleType: state.NewOrder.newOrder.service.activeVehicleType,
  serviceoptions: state.NewOrder.newOrder.service.options,
  auto: state.NewOrder.newOrder.driver.auto,
  driveroptions: state.NewOrder.newOrder.driver.options,
  auto_activeVehicleType:
    state.NewOrder.newOrder.driver.auto_driver.auto_activeVehicleType,
  manual_activeVehicleType:
    state.NewOrder.newOrder.driver.manual_driver.manual_activeVehicleType,
  pickup: state.NewOrder.newOrder.pickup,
  pickupadresss_loc: state.NewOrder.newOrder.pickup.adresss_loc,
  firstDropOff: state.NewOrder.newOrder.dropOffs.firstDropOff,
  firstDropOffadresss_loc:
    state.NewOrder.newOrder.dropOffs.firstDropOff.adresss_loc,
  otherdropOffs: state.NewOrder.newOrder.dropOffs.otherDropOffs,

});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
