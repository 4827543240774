import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import styles from './style.module.css';
import clsx from "clsx";
import close from "../../../assets/close.svg";
import { PriceDetailsOpen, SetViewOrderPickupDropoffDetails } from "../../../Redux/actions/actionNames";
import dropofft1 from "../../../assets/dropofft1.svg";
import pickupt1 from "../../../assets/pickupt1.svg";
import schedulet1 from "../../../assets/schedulet1.svg";
import personv from "../../../assets/personv.svg";
import call from "../../../assets/call.svg";
import email from "../../../assets/email_color.svg";

import notesv1 from "../../../assets/notesv1.svg";
import ic_no_photo from "../../../assets/ic_no_photo.svg";

import { isOrderInDone } from "../../../helper/module";
import { isArray, isEmpty } from "lodash";
import { get_id, get_lang } from "../../../helper/UserData/userdate";
import momentJalaali from "moment-jalaali";
import { Voip_OutgoingCall } from "../../../Redux/actions/actions";

import pickupdot from "../../../assets/pickupdot.svg";
import dropoffdot from "../../../assets/dropoffdot.svg";
import Typography from "../../../design-system/lib/Typography";
import { CALL, Customer, DONE_CIRCLE, Deliveryonly_deactive, FORWARD, Failed, PickupOnly_Deactive, Routes_deactive, ic_fail, ic_zoning } from "../../../assets";
import IconProvider from "../../../design-system/lib/IconProvider";
import Button from "../../../design-system/lib/Button";
import history from "../../../service/history";
import Tag from "../../../design-system/lib/Tag";

import ic_barcode_scanned from "../../../assets/IconProvider/ic_barcode_scanned.svg";


class App extends React.Component {

  close = () => {
    this.props.dispatch(SetViewOrderPickupDropoffDetails(null))
  }


  reserveIsValid() {
    return this.props.pickup_dropoff_details.data
      ? (this.props.pickup_dropoff_details.data.scheduleDateBefore
        ? momentJalaali(this.props.pickup_dropoff_details.data.scheduleDateBefore).isValid()
        : false) ||
      (this.props.pickup_dropoff_details.data.scheduleDateAfter
        ? momentJalaali(this.props.pickup_dropoff_details.data.scheduleDateAfter).isValid()
        : false)
      : false;
  }

  reserveToText() {
    return `${momentJalaali(this.props.pickup_dropoff_details.data.scheduleDateAfter).locale(get_lang()).format("D MMM HH:mm")} - ${momentJalaali(this.props.pickup_dropoff_details.data.scheduleDateBefore).locale(get_lang()).format("HH:mm")}`
  }


  makeOutgoingCall = () => {
    let data = this.props.pickup_dropoff_details && this.props.pickup_dropoff_details.data

    this.props.dispatch(Voip_OutgoingCall(this.props.dispatch, {
      "to": data.phone,
      "orderId": this.props.Data.id,
      "customerId": null,
      "driverId": null,
      "dispatcherId": get_id()
    }))
  }

  makeOutgoingCall_Driver = () => {
    let data = this.props.pickup_dropoff_details && this.props.pickup_dropoff_details.data

    this.props.dispatch(Voip_OutgoingCall(this.props.dispatch, {
      "to": data.driver.phone,
      "orderId": this.props.Data.id,
      "customerId": null,
      "driverId": null,
      "dispatcherId": get_id()
    }))
  }




  render() {
    let data = this.props.pickup_dropoff_details && this.props.pickup_dropoff_details.data
    let type = this.props.pickup_dropoff_details && this.props.pickup_dropoff_details.type
    let orderType = this.props.Data && this.props.Data.type
    let isPickupDelivery = this.props.Data && this.props.Data.isPickupDelivery
    let isOptionType = data && isArray(data)

    return (

      <div className={clsx(styles.EventTimeLineContainer, data && styles.EventTimeLineContainerOpen)}>

        <div className={styles.EventTimeLine_Header}>
          <img onClick={this.close} className={styles.close} src={close} />
          <span className={styles.eventsTitle}> <Trans i18nKey={data && type}> </Trans> </span>
        </div>

        {(data && !isOptionType) && <div className={styles.card}>
          <img src={type == "Pickup2" ? pickupdot : dropoffdot} className={styles.pdsicon} />
          <div className={styles.type1Container}>
            <p style={{ fontWeight: "500" }}> {data.address} </p>
            {orderType == "Ondemand" ? <Typography weight="Subtitle_Tiny_Regular" text={<>{data.buildingBlock.length > 0
              ? data.buildingBlock : "" + `${(data.floor.length > 0 && data.buildingBlock.length > 0) ? ", " :""}`}
              {data.floor.length > 0
                ? data.floor : ""  + `${data.room.length > 0 ? `, ${data.room}`:""}`}
       
              {(data.buildingBlock.length == 0 && data.floor.length == 0 && data.room.length == 0) && "--------"} 
              </>
              } /> 
              :

              <Typography weight="Subtitle_Tiny_Regular" text={data.addressDetail?.length > 0 ? data.addressDetail : "--------"} />
            }

            {(orderType == "PickupDelivery" && (data.stop?.position || ["Failed", "Successful"].indexOf(data.stop?.status) > -1)) && <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignContent: "center", gap: "6px" }}>
              {["Failed", "Successful"].indexOf(data.stop?.status) > -1 && <Tag iconSize="16" backgroundColor="GrayUltraLight" color={data.stop.status == "Successful" ? "Green" : "Red"} size="Medium" weight="Body_Small_Regular" icon={data.stop.status == "Successful" ? DONE_CIRCLE : Failed} text="Done" />}
              {data.stop?.position && <Tag iconSize="16" backgroundColor="GrayUltraLight" color={"TextNormal"} size="Medium" weight="Body_Small_Regular" icon={type == "Pickup2" ? PickupOnly_Deactive : Deliveryonly_deactive} text={`Stop ${data.stop?.position}`} />}

            </div>}
          </div>

          {this.reserveIsValid() && <>
            <hr className={styles.hr} />

            <img src={schedulet1} className={styles.schedulet1} />
            <span className={styles.schedulet}>{this.reserveToText()}</span>
          </>}




          {(data.fullName?.length > 0 || data.phone?.length > 0) && <>
            <hr className={styles.hr} />

            <img src={personv} className={styles.personv} />

            <div className={styles.type2Container} style={{ width: "calc(100% - 132px)" }}>
              <p style={{ fontWeight: "500" }}> {data.fullName?.length > 0 ? data.fullName : "------"} </p>

              <Typography weight="Subtitle_Tiny_Regular" text={data.phone?.length > 0 ? data.phone : "------"} />

            </div>

            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", float: "inline-end" }}>

              {(!global.config.dispatcherSettings.isVoipActvie && data.phone?.length > 0) && <a href={`tel:${data.phone}`}><img className={styles.call} style={{ marginTop: "18px" }} src={call} /></a>
              }
              {(global.config.dispatcherSettings.isVoipActvie && data.phone?.length > 0) && <a onClick={this.makeOutgoingCall}><img className={styles.call} style={{ marginTop: "18px" }} src={call} /></a>
              }
              {data.email?.length > 0 && <a href={`mailto:${data.email}`} ><img className={styles.call} style={{ marginTop: "18px" }} src={email} /></a>
              }
            </div>



          </>}


          {(data?.customerDescription?.length > 0) && <>
            <hr className={styles.hr} />

            <img src={notesv1} className={styles.notesv1} />

            <div className={styles.type2Container}>
              <p> {this.props.t("Notes")} </p>
              <Typography weight="Subtitle_Tiny_Regular" text={data.customerDescription} />

            </div>
          </>
          }




        </div>}


        {(data?.route || data?.zone) && <div className={styles.card}>
          <div className={styles.RouteContainer}>
            {data.zone && <div className={styles.RouteRow}>
              <IconProvider Icon={ic_zoning} size='16' />
              <div className={styles.columnRow}>
                <Typography weight='Body_Middle_Medium' text={data.zone.name} />
                <Typography weight='Subtitle_Tiny_Regular' text={"Zone"} />
              </div>


            </div>}

            {(data?.zone) &&
              <hr className={styles.hrx} />

            }

            <div className={styles.RouteRow}>
              <IconProvider Icon={Routes_deactive} size='16' />
              <div className={styles.columnRow}>
                <Typography weight='Body_Middle_Medium' text={data.route ? data.route.name : "No Route"} />
                {data.route && <Typography weight='Subtitle_Tiny_Regular' text={data.route.status} />}
              </div>



              {data.route && <Button onClick={() => { history.push(`/route/${data.route.id}/0`) }} size='Medium' type='Secondary' icon={FORWARD} text='Details' style={{ marginInlineStart: "auto" }} />}
            </div>

            {data.driver && <>
              <hr className={styles.hrx} />

              <div className={styles.RouteRow}>
                <IconProvider Icon={data.driver.avatarPhoto?.length > 0 ? data.driver.avatarPhoto : Customer} size='32' />
                <div className={styles.columnRow} style={{ marginInlineStart: "8px" }}>
                  <Typography weight='Body_Middle_Medium' text={data.driver.name} />
                  <Typography weight='Subtitle_Tiny_Regular' text={"@" + data.driver.username} textColor='TextNormal' />
                </div>



                <img className={styles.call} src={CALL} onClick={() => this.makeOutgoingCall_Driver()} />
              </div>
            </>}

          </div>

        </div>}


        {(orderType == "Ondemand" && type == "Pickup2" && this.props.Data.customerPhoto &&
          String(this.props.Data.customerPhoto).length > 0) && <div className={styles.card}>
            <p className={styles.pod}>{this.props.t(/*"Photos"*/"customerPhoto")}</p>

            <div className={styles.profsContainer} style={{ paddingBottom: "16px" }}>

              <div className={styles.profsItem}>
                <img
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                    borderRadius: "5.14px"
                  }}
                  src={this.props.Data.customerPhoto}
                />

              </div>

            </div>

          </div>
        }

        {(orderType == "Ondemand" && type == "Pickup2" && !isOptionType && data.proofs.length > 0) && <div className={styles.card}>
          <p className={styles.pod}>{this.props.t(/*"Photos"*/"pop")}</p>

          <div className={styles.profsContainer} style={{paddingBottom:"16px"}}>

            {data.proofs.map((item) => {
              return <div className={styles.profsItem}>
                {["photo", "signature"].indexOf(item.type) > -1 && <img
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                    borderRadius:"5.14px"
                  }}
                  src={item.photoUrl ? item.photoUrl : item.signatureUrl}
                />}
                {['barcode-scan'].indexOf(item.type) > -1 && <div className={styles.innerProofItems}>
                <img src={ic_barcode_scanned} style={{marginBottom:"8px"}} />
                  <Typography weight="Body_Tiny_Medium" text={item.barcodeDataKey == "referenceId" ? `Ref.${item.barcodeData}` : `#${item.barcodeData}`} style={{overflowWrap:"break-word" , maxWidth:"82px" , textAlign :" center"}}  />
            
                </div>
                }

              </div>
            })}

          </div>
          {/* <div className={styles.image}>
            {this.props.Data.customerPhoto &&
              String(this.props.Data.customerPhoto).length > 0 ? (
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
                src={this.props.Data.customerPhoto}
              />
            ) : (
              <span className="nophotosign" style={{ marginTop: "62px" }} >
                {" "}
                <img src={ic_no_photo} />
              </span>
            )}
          </div>
          <div className={styles.image}>
            {this.props.Data.driverPhoto && String(this.props.Data.driverPhoto).length > 0 ? (
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
                src={this.props.Data.driverPhoto}
              />
            ) : (
              <span className="nophotosign" style={{ marginTop: "62px" }}>
                {" "}
                <img src={ic_no_photo} />
              </span>
            )}
          </div>

          <span className={styles.Signature}> {this.props.t("customerPhoto")} </span>
          <span className={styles.Photo}> {this.props.t("driverPhoto")} </span> */}

          {(data.popNote &&
            data.popNote.length > 0) && <><hr className={styles.hr} />

              <img src={notesv1} className={styles.notesv1} />

              <div className={styles.type2Container}>
                <p> {this.props.t("Notes")} </p>
                <Typography weight="Subtitle_Tiny_Regular" text={<>{data.popNote &&
                  data.popNote.length > 0
                  ? data.popNote
                  : this.props.t("Nonotes")}</>} />

              </div></>}

          {(data.popNote &&
            data.popNote.length > 0) && <><hr className={styles.hr} />

              <img src={notesv1} className={styles.notesv1} />

              <div className={styles.type2Container}>
                <p> {this.props.t("Notes")} </p>
                <Typography weight="Subtitle_Tiny_Regular" text={<>{data.popNote &&
                  data.popNote.length > 0
                  ? data.popNote
                  : this.props.t("Nonotes")}</>} />

              </div></>}


        </div>
        }

  {(data && !((orderType == "Ondemand" && type == "Pickup2")) && !(orderType == "PickupDelivery" && type == "Pickup2") && !isOptionType && type == "Dropoff2"  && data.proofs.length > 0) && <div  id={"x444"}  className={styles.card}>
          <p className={styles.pod}>{(orderType == "Pickup") ? this.props.t("pop") : this.props.t("pod")}</p>
          {/* <div className={styles.image}>
            {data.podSignature &&
              String(data.podSignature).length > 0 ? (
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
                src={data.podSignature}
              />
            ) : (
              <span className="nophotosign" style={{ marginTop: "62px" }} >
                {" "}
                <img src={ic_no_photo} />
              </span>
            )}
          </div>
          <div className={styles.image}>
            {data.podPhoto && String(data.podPhoto).length > 0 ? (
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
                src={data.podPhoto}
              />
            ) : (
              <span className="nophotosign" style={{ marginTop: "62px" }}>
                {" "}
                <img src={ic_no_photo} />
              </span>
            )}
          </div>

          <span className={styles.Signature}> {this.props.t("Signature")} </span>
          <span className={styles.Photo}> {this.props.t("Photo")} </span> */}

<div className={styles.profsContainer} style={{paddingBottom:"16px"}}>

{data.proofs.map((item) => {
  return <div className={styles.profsItem}>
    {["photo", "signature"].indexOf(item.type) > -1 && <img
      style={{
        width: "100%",
        height: "100%",
        objectFit: "contain",
        borderRadius:"5.14px"
      }}
      src={item.photoUrl ? item.photoUrl : item.signatureUrl}
    />}
    {['barcode-scan'].indexOf(item.type) > -1 && <div className={styles.innerProofItems}>
    <img src={ic_barcode_scanned} style={{marginBottom:"8px"}} />
    <Typography weight="Body_Tiny_Medium" text={item.barcodeDataKey == "referenceId" ? `Ref.${item.barcodeData}` : `#${item.barcodeData}`} style={{overflowWrap:"break-word" , maxWidth:"82px" , textAlign :" center"}}  />

    </div>
    }

  </div>
})}

</div>

          {(data.podNote &&
            data.podNote.length > 0) && <><hr className={styles.hr} />

              <img src={notesv1} className={styles.notesv1} />

              <div className={styles.type2Container}>
                <p> {this.props.t("Notes")} </p>
                <Typography weight="Subtitle_Tiny_Regular" text={<>{data.podNote &&
                  data.podNote.length > 0
                  ? data.podNote
                  : this.props.t("Nonotes")}</>} />

              </div></>}


              

        </div>
        }


         {(data && !((orderType == "Ondemand" && type == "Pickup2")) && !(orderType == "PickupDelivery" && type == "Pickup2") && !isOptionType && type == "Pickup2"  && data.proofs.length > 0) && <div  className={styles.card}>
          <p className={styles.pod}>{(orderType == "Pickup") ? this.props.t("pop") : this.props.t("pod")}</p>
       
          <div className={styles.profsContainer} style={{paddingBottom:"16px"}}>

{data.proofs.map((item) => {
  return <div className={styles.profsItem}>
    {["photo", "signature"].indexOf(item.type) > -1 && <img
      style={{
        width: "100%",
        height: "100%",
        objectFit: "contain",
        borderRadius:"5.14px"
      }}
      src={item.photoUrl ? item.photoUrl : item.signatureUrl}
    />}
    {['barcode-scan'].indexOf(item.type) > -1 && <div className={styles.innerProofItems}>
    <img src={ic_barcode_scanned} style={{marginBottom:"8px"}} />
    <Typography weight="Body_Tiny_Medium" text={item.barcodeDataKey == "referenceId" ? `Ref.${item.barcodeData}` : `#${item.barcodeData}`} style={{overflowWrap:"break-word" , maxWidth:"82px" , textAlign :" center"}}  />

    </div>
    }

  </div>
})}

</div>
          {/* <div className={styles.image}>
            {data.popSignature &&
              String(data.popSignature).length > 0 ? (
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
                src={data.popSignature}
              />
            ) : (
              <span className="nophotosign" style={{ marginTop: "62px" }} >
                {" "}
                <img src={ic_no_photo} />
              </span>
            )}
          </div>
          <div className={styles.image}>
            {data.popPhoto && String(data.popPhoto).length > 0 ? (
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
                src={data.popPhoto}
              />
            ) : (
              <span className="nophotosign" style={{ marginTop: "62px" }}>
                {" "}
                <img src={ic_no_photo} />
              </span>
            )}
          </div>

          <span className={styles.Signature}> {this.props.t("Signature")} </span>
          <span className={styles.Photo}> {this.props.t("Photo")} </span> */}

          <hr className={styles.hr} />

          <img src={notesv1} className={styles.notesv1} />

          <div className={styles.type2Container}>
            <p> {this.props.t("Notes")} </p>
            <Typography weight="Subtitle_Tiny_Regular" text={<>{data.popNote &&
              data.popNote.length > 0
              ? data.popNote
              : this.props.t("Nonotes")}</>} />

          </div>

        </div>
        } 

      

        {(data && ((orderType == "PickupDelivery" && type == "Pickup2")) && !isOptionType  && data.proofs.length > 0) && <div id={"x111"} className={styles.card}>
          <p className={styles.pod}>{this.props.t("pop")}</p>
          
          <div className={styles.profsContainer} style={{paddingBottom:"16px"}}>

{data.proofs.map((item) => {
  return <div className={styles.profsItem}>
    {["photo", "signature"].indexOf(item.type) > -1 && <img
      style={{
        width: "100%",
        height: "100%",
        objectFit: "contain",
        borderRadius:"5.14px"
      }}
      src={item.photoUrl ? item.photoUrl : item.signatureUrl}
    />}
    {['barcode-scan'].indexOf(item.type) > -1 && <div className={styles.innerProofItems}>
    <img src={ic_barcode_scanned} style={{marginBottom:"8px"}} />
    <Typography weight="Body_Tiny_Medium" text={item.barcodeDataKey == "referenceId" ? `Ref.${item.barcodeData}` : `#${item.barcodeData}`} style={{overflowWrap:"break-word" , maxWidth:"82px" , textAlign :" center"}}  />

    </div>
    }

  </div>
})}

</div>

          {/* <div className={styles.image}>
            {data.popSignature &&
              String(data.popSignature).length > 0 ? (
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
                src={data.popSignature}
              />
            ) : (
              <span className="nophotosign" style={{ marginTop: "62px" }} >
                {" "}
                <img src={ic_no_photo} />
              </span>
            )}
          </div>
          <div className={styles.image}>
            {data.popPhoto && String(data.popPhoto).length > 0 ? (
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
                src={data.popPhoto}
              />
            ) : (
              <span className="nophotosign" style={{ marginTop: "62px" }}>
                {" "}
                <img src={ic_no_photo} />
              </span>
            )}
          </div>

          <span className={styles.Signature}> {this.props.t("Signature")} </span>
          <span className={styles.Photo}> {this.props.t("Photo")} </span> */}

          {(data.podNote &&
            data.podNote.length > 0) && <><hr className={styles.hr} />

              <img src={notesv1} className={styles.notesv1} />

              <div className={styles.type2Container}>
                <p> {this.props.t("Notes")} </p>
                <Typography weight="Subtitle_Tiny_Regular" text={<>{data.podNote &&
                  data.podNote.length > 0
                  ? data.podNote
                  : this.props.t("Nonotes")}</>} />

              </div></>}

              {(data.popNote &&
            data.popNote.length > 0) && <><hr className={styles.hr} />

              <img src={notesv1} className={styles.notesv1} />

              <div className={styles.type2Container}>
                <p> {this.props.t("Notes")} </p>
                <Typography weight="Subtitle_Tiny_Regular" text={<>{data.popNote &&
                  data.popNote.length > 0
                  ? data.popNote
                  : this.props.t("Nonotes")}</>} />

              </div></>}

        </div>
        }

        {/* {(data && ((orderType == "PickupDelivery" && type == "Pickup2")) && !isOptionType) && <div id={"x222"}  className={styles.card}>
          <p className={styles.pod}>{this.props.t("pop")}</p>
          <div className={styles.image}>
            {data.popSignature &&
              String(data.popSignature).length > 0 ? (
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
                src={data.popSignature}
              />
            ) : (
              <span className="nophotosign" style={{ marginTop: "62px" }} >
                {" "}
                <img src={ic_no_photo} />
              </span>
            )}
          </div>
          <div className={styles.image}>
            {data.popPhoto && String(data.popPhoto).length > 0 ? (
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
                src={data.popPhoto}
              />
            ) : (
              <span className="nophotosign" style={{ marginTop: "62px" }}>
                {" "}
                <img src={ic_no_photo} />
              </span>
            )}
          </div>

          <span className={styles.Signature}> {this.props.t("Signature")} </span>
          <span className={styles.Photo}> {this.props.t("Photo")} </span>

          {(data.podNote &&
            data.podNote.length > 0) && <><hr className={styles.hr} />

              <img src={notesv1} className={styles.notesv1} />

          <div className={styles.type2Container}>
            <p> {this.props.t("Notes")} </p>
            <Typography weight="Subtitle_Tiny_Regular" text={<>{data.popNote &&
              data.popNote.length > 0
              ? data.popNote
              : this.props.t("Nonotes")}</>} />

              </div></>}

        </div>
        } */}

        {/* {(data && ((orderType == "PickupDelivery" && type == "Pickup2")) && !isOptionType) && <div id={"x333"}  className={styles.card}>
          <p className={styles.pod}>{this.props.t("pop")}</p>
          <div className={styles.image}>
            {data.popSignature &&
              String(data.popSignature).length > 0 ? (
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
                src={data.popSignature}
              />
            ) : (
              <span className="nophotosign" style={{ marginTop: "62px" }} >
                {" "}
                <img src={ic_no_photo} />
              </span>
            )}
          </div>
          <div className={styles.image}>
            {data.popPhoto && String(data.popPhoto).length > 0 ? (
              <img
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
                src={data.popPhoto}
              />
            ) : (
              <span className="nophotosign" style={{ marginTop: "62px" }}>
                {" "}
                <img src={ic_no_photo} />
              </span>
            )}
          </div>

          <span className={styles.Signature}> {this.props.t("Signature")} </span>
          <span className={styles.Photo}> {this.props.t("Photo")} </span>

          <hr className={styles.hr} />

          <img src={notesv1} className={styles.notesv1} />

          <div className={styles.type2Container}>
            <p> {this.props.t("Notes")} </p>
            <Typography weight="Subtitle_Tiny_Regular" text={<>{data.popNote &&
              data.popNote.length > 0
              ? data.popNote
              : this.props.t("Nonotes")}</>} />

          </div>

        </div>
        } */}


        {
          isOptionType && <div className={styles.card}>

            {data.map((opt) => {
              return <div style={{ borderBottom: "1px solid #F4F4F7", width: "calc(100% - 32px)", marginLeft: "16px", marginRight: "16px", height: "49px", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                <span style={{ color: "#808080", fontWeight: "400", fontSize: "14px" }}>{opt.title}</span>
                <span style={{ fontWeight: "400", color: "#242424", fontSize: "13px" }}>{opt.type == "list" ? (opt.dataTitle) : (opt.type == "boolean" ? this.props.t("confirmCancel") : opt.inputValue)}</span>
              </div>
            })}

          </div>
        }


      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  pickup_dropoff_details: state.ViewTravel.pickup_dropoff_details,
  showEventTimeLine: state.ViewTravel.eventTimeLine.show,
  Data: state.ViewTravel.pureData ? state.ViewTravel.pureData : false,
  baseFarePrice: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.baseFarePrice
    : 0,
  surcharges: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.surcharges
    : [],

});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
