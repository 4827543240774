import styles from './style.module.css';
import info from "../../../assets/info.svg";
import pickupdot from "../../../assets/pickupdot.svg";
import dropoffdot from "../../../assets/dropoffdot.svg";
import dropoffdone from "../../../assets/doneDropoff.svg";
import pickup_done from "../../../assets/pickup_done.svg";
import route_optimize from "../../../assets/route_optimize.svg";

import { useDispatch, useSelector } from 'react-redux';
import momentJalaali from "moment-jalaali";
import schedule from "../../../assets/schedule.svg";
import { get_lang } from '../../../helper/UserData/userdate';
import _ from 'lodash';
import { OfferingDetailsOpen, PriceDetailsOpen, SetViewOrderPickupDropoffDetails, show_eventTimeLine } from '../../../Redux/actions/actionNames';
import { useCallback, useEffect } from 'react';
import clsx from 'clsx';
import { pickup_isPickuped } from '../../../helper/module';
import { useTranslation } from 'react-i18next';
import { OrderInfo, optimizeDropoffs_Http } from '../../../helper/api';
import { OrderInfoAction } from '../../../Redux/actions/actions';
import { snack } from '../../../utils/SnackbarUtilsConfigurator';


export const PickupDropoffs = () => {
    const pickup = useSelector((state) => state.ViewTravel.pureData.pickup)
    const dropoff = useSelector((state) => state.ViewTravel.pureData.dropoff)
    const delivery = useSelector((state) => state.ViewTravel.pureData.delivery)

    const dropoffs = useSelector((state) => state.ViewTravel.pureData.dropoffs)
    const orderType = useSelector((state) => state.ViewTravel.pureData.type)
    const orderStatus = useSelector((state) => state.ViewTravel.pureData.status)
    const pureData = useSelector((state) => state.ViewTravel.pureData)
    const pickup_dropoff_details = useSelector((state) => state.ViewTravel.pickup_dropoff_details)
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();


    function reserveIsValid(data) {
        return data
            ? (data.scheduleDateBefore
                ? momentJalaali(data.scheduleDateBefore).isValid()
                : false) ||
            (data.scheduleDateAfter
                ? momentJalaali(data.scheduleDateAfter).isValid()
                : false)
            : false;
    }

    function reserveToText(data) {
        return momentJalaali(data.scheduleDateAfter || data.scheduleDateBefore).locale(get_lang()).format("D MMM HH:mm")
    }

    const pickupInfo = useCallback(() => {
        if (pickup_dropoff_details && String(pickup_dropoff_details.type) == "Pickup2") {
            dispatch(SetViewOrderPickupDropoffDetails(null))
        } else {
            dispatch(SetViewOrderPickupDropoffDetails({ type: "Pickup2", data: pickup }))
        }
        dispatch(OfferingDetailsOpen(false))
        dispatch(show_eventTimeLine(false))
        dispatch(PriceDetailsOpen(false))

    }, [pickup, pickup_dropoff_details]);

    const dropOffInfo = useCallback(() => {
        if (pickup_dropoff_details && String(pickup_dropoff_details.data.id) == String(dropoff.id)) {
            dispatch(SetViewOrderPickupDropoffDetails(null))
        } else {
            dispatch(SetViewOrderPickupDropoffDetails({ type: "Dropoff2", data: dropoff }))
        }
        dispatch(OfferingDetailsOpen(false))
        dispatch(show_eventTimeLine(false))
        dispatch(PriceDetailsOpen(false))

    }, [dropoff, pickup_dropoff_details]);


    const deliveryInfo = useCallback(() => {
        if (pickup_dropoff_details && String(pickup_dropoff_details.data.id) == "delivery") {
            dispatch(SetViewOrderPickupDropoffDetails(null))
        } else {
            dispatch(SetViewOrderPickupDropoffDetails({ type: "Dropoff2", data: {id:"delivery",...delivery} }))
        }
        dispatch(OfferingDetailsOpen(false))
        dispatch(show_eventTimeLine(false))
        dispatch(PriceDetailsOpen(false))

    }, [delivery, pickup_dropoff_details]);

    const odropOffInfo = useCallback((drop) => {
        if (pickup_dropoff_details && String(pickup_dropoff_details.data.id) == String(drop.id)) {
            dispatch(SetViewOrderPickupDropoffDetails(null))
        } else {
            dispatch(SetViewOrderPickupDropoffDetails({ type: "Dropoff2", data: drop }))
        }
        dispatch(OfferingDetailsOpen(false))
        dispatch(show_eventTimeLine(false))
        dispatch(PriceDetailsOpen(false))
    }, [pickup_dropoff_details]);


    const updatePickupDropOffDetails = useCallback(() => {


        if (pickup_dropoff_details && String(pickup_dropoff_details.type) != String("VehicleOptions")) {
            if (String(pickup_dropoff_details.type) == "Pickup2") {

                dispatch(SetViewOrderPickupDropoffDetails({ type: "Pickup2", data: pickup }))
            } else if (dropoff && String(pickup_dropoff_details.data.id) == String(dropoff.id)) {

                try {
                    dispatch(SetViewOrderPickupDropoffDetails({ type: "Dropoff2", data: dropoff }))
                } catch (error) {

                }
            } else if (String(pickup_dropoff_details.data.id) == String("delivery")) {

                try {
                    dispatch(SetViewOrderPickupDropoffDetails({ type: "Dropoff2", data: {id:"delivery",...delivery} }))
                } catch (error) {

                }
            } else {

                try {
                    let dropId = dropoffs.findIndex((drop => drop.id == pickup_dropoff_details.data.id));
                    let drop = dropoffs[dropId];

                    drop && dispatch(SetViewOrderPickupDropoffDetails({ type: "Dropoff2", data: drop }))
                } catch (error) {

                }
            }
        }

    }, [pickup, delivery, dropoff, dropoffs, pickup_dropoff_details]);


    useEffect(() => {

        updatePickupDropOffDetails()

    }, [pureData]);


    const canShowDropOffOptimize = useCallback(() => {
        if (["Ondemand"].indexOf(orderType) == 0 && dropoffs.length > 1) {
            if (["Unassigned", "Pending", "Assigned", "Started", "Arrived", "PickedUp"].indexOf(orderStatus) > -1) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }

    }, [orderType, orderStatus , dropoffs]);


    const handleDropOffOptimize = useCallback(() => {
        snack.info_i18("poperLoading")
        optimizeDropoffs_Http(dispatch, pureData.id, (call) => {
        })
    }, [dispatch, pureData]);




    // if (["PickupDelivery"].indexOf(orderType) > -1) {
    //     return null;
    // }

    return <div className={styles.DriverVehicle}>


        {!_.isEmpty(pickup) && <div className={styles.pdItem} style={{borderBottom : _.isEmpty(delivery) ? "" : "unset"}}>
            <img src={pickup_isPickuped(pureData.status) ? pickup_done : pickupdot} />
            <p className={styles.pdom}>
                   {["PickupDelivery"].indexOf(orderType) > -1 && <span className={clsx(styles.address, styles.pickup)}>{t("Pickup")}</span>}
                   <span className={styles.address}>{pickup.address}</span>
                   </p>
            {/* <span className={styles.address}>{pickup.address}</span> */}
            {reserveIsValid(pickup) && <span className={styles.scheduleContainer}> <img src={schedule} />{reserveToText(pickup)}</span>}
            <img className={clsx(styles.info, (pickup_dropoff_details && String(pickup_dropoff_details.type) == "Pickup2") && styles.infoActive)} src={info} onClick={pickupInfo} />
        </div>
        }

        {!_.isEmpty(dropoff) && <div className={styles.pdItem}>
            <img src={dropoff.status == "Done" ? dropoffdone : dropoffdot} />
            <span className={styles.address}>{dropoff.address}</span>
            {reserveIsValid(dropoff) && <span className={styles.scheduleContainer}> <img src={schedule} />{reserveToText(dropoff)}</span>}
            <img className={clsx(styles.info, (pickup_dropoff_details && String(pickup_dropoff_details.data.id) == String(dropoff.id)) && styles.infoActive)} src={info} onClick={dropOffInfo} />
        </div>}

        {!_.isEmpty(delivery) &&<>
        <div style={{width:"100%",height:"6px",backgroundColor:"#F4F4F7",float:"inline-start"}}></div>
        <div className={styles.pdItem}>
            <img src={delivery.status == "Done" ? dropoffdone : dropoffdot} />
            <p className={styles.pdom}>
                   {["PickupDelivery"].indexOf(orderType) > -1 && <span className={clsx(styles.address, styles.pickup)}>{t("Delivery")}</span>}
                   <span className={styles.address}>{delivery.address}</span>
                   </p>
            {/* <span className={styles.address}>{delivery.address}</span> */}
            {reserveIsValid(delivery) && <span className={styles.scheduleContainer}> <img src={schedule} />{reserveToText(delivery)}</span>}
            <img className={clsx(styles.info, (pickup_dropoff_details && String(pickup_dropoff_details.data.id) == String("delivery")) && styles.infoActive)} src={info} onClick={deliveryInfo} />
        </div>
        </> }
        

        {(!_.isEmpty(dropoffs) && dropoffs.length > 0) &&
            dropoffs.map((drop, i) => {
                return <div className={styles.pdItem}>
                    {dropoffs.length == 1 ? <img src={drop.status == "Done" ? dropoffdone : dropoffdot} /> : (drop.status == "Done" ? <img src={dropoffdone} /> : <span className={styles.dropNum}>{i+1}</span>)}
                    {/* {drop.status == "Done" ? <img src={dropoffdone} /> : <span className={styles.dropNum}>{i+1}</span> } */}
                    {/* <img src={drop.status == "Done" ? dropoffdone : dropoffdot} /> */}
                    <span className={styles.address}>{drop.address}</span>
                    {reserveIsValid(drop) && <span className={styles.scheduleContainer}> <img src={schedule} />{reserveToText(drop)}</span>}
                    <img className={clsx(styles.info, (pickup_dropoff_details && String(pickup_dropoff_details.data.id) == String(drop.id)) && styles.infoActive)} src={info} onClick={() => { odropOffInfo(drop) }} />
                </div>
            })
        }

        {canShowDropOffOptimize() && <div className={styles.routeOptimize} onClick={handleDropOffOptimize}>
            <img src={route_optimize} alt="" />
            <span>{t("OptimizeDropoffs")}</span>
        </div>}



    </div>

};




