import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import close from "../../../../../assets/close.svg";
import { withTranslation, Trans } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import Person_business_switch from "./person_business_switch";
import {
  New_Travel_PaymentMethod,
  SetRecentAddress,
  Set_latlng,
  Set_Passenger_Na_Fa_Ph,
  Set_Pickup_Inputs,
} from "../../../../../Redux/actions/actionNames";
import {
  SearchCustomerAction,
  getFavAddressListAction,
  BusinessSearchAction,
  Get_User_Recent_Address,
  Register_New_Customer,
  Voip_OutgoingCall,
  CustomerGetInfo,
  Edit_individual_info,
} from "../../../../../Redux/actions/actions";
import { Get_Addressbook_list_Api } from "../../../../../helper/api";
import remove from "../../../../../assets/ic-remove.svg";
import customerEdit from "../../../../../assets/customerEdit.svg";
import { getCenterMap } from "../../../../../helper/module";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import userProfile from "../../../../../assets/icon-ic-person.svg";
import userRegister from "../../../../../assets/ic-register-user.svg";
import PhoneInput from "react-phone-number-input";
import { isValidPhoneNumber } from 'react-phone-number-input'
import CircularProgress from "@material-ui/core/CircularProgress";
import warningIc from "../../../../../assets/ic-warning.svg";
import call from "../../../../../assets/ic-call.svg";
import { isEmpty } from "lodash";
import { get_id } from "../../../../../helper/UserData/userdate";

const BackdropCustom = withStyles({
  backdrop: {
    zIndex: 5000,
    backgroundColor: "rgba(0, 0, 0, 0)",
    color: "#00000",
  },
})((props) => (
  <Backdrop
    {...props}
    style={{
      zIndex: 5000,
      backgroundColor: "rgba(0, 0, 0, 0)",
      color: "#00000",
    }}
  />
));

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      Register_New_Customer_ErrorApi: null,
      Register_New_Customer_Loading: false,
      // newCustomerPhone: String(props.phone),
      newCustomerPhone: "",
      newCustomerFirstName: "",
      newCustomerLastName: "",
      newCustomerEmail: "",
      newCustomerPhoneError: false,
      showExisting: true,
      showInputSearchDialoge: false,
      showDropDown: false,
      loading: false,
      ordered: true,
      id: this.idgenerator(),
      isEditingSelectedCustomer: false ,
      isEditingSelectedCustomerData: null ,

    };
    this.anchorEl = React.createRef();
  }

  handleShowExisting = (show) => {
    this.setState({
      showExisting: show
    })
  }

  makeOutgoingCall = () => {
    this.props.dispatch(Voip_OutgoingCall(this.props.dispatch, {
      "to": this.props.phone,
      "orderId": null,
      "customerId": this.props.id,
      "driverId": "",
      "dispatcherId": get_id()
    }))
  }

  componentDidMount() {
    if (!isEmpty(String(this.props.phone))) {
      this.setState({
        showExisting: false,
        newCustomerPhone: !isEmpty(this.props.phone) ? String(this.props.phone) : "",
      })
    }
  }
  handleDropDown = () => {
    this.setState({
      showDropDown: !this.state.showDropDown
    })
  };
  OpenDropDown = () => {
    this.setState({
      showDropDown: true
    })
  };

  CloseDropDown = () => {
    this.setState({
      showDropDown: false
    })
  };


  idgenerator = () => {
    var number = Math.random(); // 0.9394456857981651
    number.toString(36); // '0.xtis06h6'
    var id = number.toString(36).substr(2, 9); // 'xtis06h6'
    return id.toString();
  };

  handleInputSearch = () => {
    if (this.state.showInputSearchDialoge) {
      this.setState({
        showInputSearchDialoge: !this.state.showInputSearchDialoge,
      });
    } else {
      if (
        this.props.suggestListLoading ||
        this.props.suggestListData.length > 0 ||
        this.props.suggestListOrdered
      ) {
        this.setState({
          showInputSearchDialoge: !this.state.showInputSearchDialoge,
        });
      }
    }
  };

  InputOnChange = (e) => {
    // this.props.dispatch(
    //   Set_Passenger_Na_Fa_Ph({
    //     name: "id",
    //     value: "",
    //   })
    // );
    this.props.dispatch(
      Set_Passenger_Na_Fa_Ph({
        name: "search_regex",
        value: e.target.value,
      })
    );

    if (!this.state.showInputSearchDialoge) {
      this.setState({
        showInputSearchDialoge: true,
      });
    }

    this.props.dispatch(
      SearchCustomerAction(this.props.dispatch, e.target.value)
    );
  };

  selectPassenger = (e) => {

      this.props.dispatch(CustomerGetInfo(this.props.dispatch, e.id, (call) => {
        if (this.props.price.price && (call.balance - this.props.price.price.price) >= Number(call.minimumBalance)) {
          this.props.dispatch(New_Travel_PaymentMethod({
            paymentSide: "Sender",
            paymentMethod: "Wallet",
            paymentProvider: null,
            cardInfo: null,
            paymentMethodId: null,
          }));
        }
      }))
  

    this.props.dispatch(SetRecentAddress([]))

    this.props.dispatch(Get_User_Recent_Address(this.props.dispatch, e.id, null))

    this.setState({
      showDropDown: false
    })
    // if (this.props.person) {
    this.props.dispatch(
      Set_Passenger_Na_Fa_Ph({
        name: "type",
        value: e.type,
      })
    );
    this.props.dispatch(
      Set_Passenger_Na_Fa_Ph({
        name: "name",
        value: e.name,
      })
    );
    this.props.dispatch(
      Set_Passenger_Na_Fa_Ph({
        name: "phone",
        value: String(e.phone).startsWith("00") ? 
        "+"+String(e.phone).slice(2, e.phone.length) : String(e.phone),
      })
    );
    this.props.dispatch(
      Set_Passenger_Na_Fa_Ph({
        name: "id",
        value: e.id,
      })
    );
    this.props.dispatch(
      Set_Passenger_Na_Fa_Ph({
        name: "balance",
        value: e.balance,
      })
    );
    
    this.props.dispatch(
      Set_Passenger_Na_Fa_Ph({
        name: "minimumBalance",
        value: e.minimumBalance,
      })
    );

    this.props.dispatch(
      Set_Passenger_Na_Fa_Ph({
        name: "email",
        value: e.email,
      })
    );

    this.props.dispatch(
      Set_Passenger_Na_Fa_Ph({
        name: "familyName",
        value: e.familyName,
      })
    );

    this.props.dispatch(
      Set_Passenger_Na_Fa_Ph({
        name: "givenName",
        value: e.givenName,
      })
    );

    Get_Addressbook_list_Api(
      this.props.dispatch,
      {
        customerId: e.id,
        page: 1,
        perpage: 20,
        searchRegex: "",
      },
      (call) => {
        call && this.checkandSekectDefaultPickup(call);
      }
    );
    this.checkPaymentMustBeCashOrNo(e.balance);
  };

  Emailvalidate(email) {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }


  checkandSekectDefaultPickup = (arr) => {
    arr.forEach((element) => {
      if (element.isDefaultPickupAddress) {
        try {
          getCenterMap(this.props.mapRef, {
            lng: element.coordinates[0],
            lat: element.coordinates[1],
          })

          // this.props.mapRef.current.leafletElement.setView(
          //   {
          //     lng: element.coordinates[0],
          //     lat: element.coordinates[1],
          //   },
          //   17
          // );
        } catch (error) {
          this.props.dispatch(
            Set_latlng({
              lng: element.coordinates[0],
              lat: element.coordinates[1],
            })
          );
        }

        this.props.dispatch(
          Set_Pickup_Inputs({
            name: "address",
            value: element.address,
          })
        );

        this.props.dispatch(
          Set_Pickup_Inputs({
            name: "adresss_loc",
            value: { lng: element.coordinates[0], lat: element.coordinates[1] },
          })
        );

        this.props.dispatch(
          Set_Pickup_Inputs({
            name: "block",
            value: element.buildingBlock,
          })
        );

        this.props.dispatch(
          Set_Pickup_Inputs({
            name: "floor",
            value: element.floor,
          })
        );

        this.props.dispatch(
          Set_Pickup_Inputs({
            name: "senderFullName",
            value: element.fullName,
          })
        );

        this.props.dispatch(
          Set_Pickup_Inputs({
            name: "senderPhoneNumber",
            value: element.phone,
          })
        );

        this.props.dispatch(
          Set_Pickup_Inputs({
            name: "unit",
            value: element.room,
          })
        );
      }
    });
  };

  checkPaymentMustBeCashOrNo(
    userBalance = this.props.UserBalance,
    props = this.props
  ) {
    if (!props.price.loading && props.price.done) {
      if (Number(userBalance) >= Number(props.price.price.price)) {
        //enough balance
      } else if (props.paymentSide + props.paymentMethod == "SenderWallet") {
        // sender wallet And Not Enough Wallet

          if (props.price.price && (props.customer.balance - props.price.price.price) >= props.customer.minimumBalance) {
            // canWalletBeNegative
          } else {
            if (props.serviceSettings.enableReceiverCash) {
              props.dispatch(
                New_Travel_PaymentMethod({
                  paymentSide: "Sender",
                  paymentMethod: "Cash",
                  log:"from 353"
                })
              );
            }
          }
        


      }
    }
  }

  componentWillReceiveProps(nextProps) {
    this.checkPaymentMustBeCashOrNo(nextProps.UserBalance, nextProps);
  }

  resetCustomer = () => {
    this.props.dispatch(
      Set_Passenger_Na_Fa_Ph({
        name: "search_regex",
        value: "",
      })
    );
    

    this.props.dispatch(
      Set_Passenger_Na_Fa_Ph({
        name: "recentAddreess",
        value: [],
      })
    );

    this.props.dispatch(
      Set_Passenger_Na_Fa_Ph({
        name: "suggestListData",
        value: [],
      })
    );

    

    this.props.dispatch(
      Set_Passenger_Na_Fa_Ph({
        name: "type",
        value: "",
      })
    );
    this.props.dispatch(
      Set_Passenger_Na_Fa_Ph({
        name: "name",
        value: "",
      })
    );
    this.props.dispatch(
      Set_Passenger_Na_Fa_Ph({
        name: "phone",
        value: "",
      })
    );
    this.props.dispatch(
      Set_Passenger_Na_Fa_Ph({
        name: "id",
        value: "",
      })
    );
    this.props.dispatch(
      Set_Passenger_Na_Fa_Ph({
        name: "balance",
        value: "",
      })
    );
  };

  editCustomerHttp= () => {
    this.props.dispatch(Edit_individual_info(this.props.dispatch , {
      id:this.state.isEditingSelectedCustomerData.id , 
      info : {
        "phone": this.state.isEditingSelectedCustomerData.phone,
        "givenName": this.state.isEditingSelectedCustomerData.givenName,
        "familyName": this.state.isEditingSelectedCustomerData.familyName,
        "email": this.state.isEditingSelectedCustomerData.email
      }
    },(call)=>{
      

      this.props.dispatch(
        Set_Passenger_Na_Fa_Ph({
          name: "name",
          value: call.name,
        })
      );
      this.props.dispatch(
        Set_Passenger_Na_Fa_Ph({
          name: "phone",
          value: String(call.phone).startsWith("00") ? 
          "+"+String(call.phone).slice(2, call.phone.length) : String(call.phone),
        })
      );


  
      this.props.dispatch(
        Set_Passenger_Na_Fa_Ph({
          name: "email",
          value: call.email,
        })
      );
  
      this.props.dispatch(
        Set_Passenger_Na_Fa_Ph({
          name: "familyName",
          value: call.familyName,
        })
      );
  
      this.props.dispatch(
        Set_Passenger_Na_Fa_Ph({
          name: "givenName",
          value: call.givenName,
        })
      );

      this.setState({
        isEditingSelectedCustomer:null,
        isEditingSelectedCustomerData:null
      })

    }))
  }


  editCustomer = () => {
    this.setState({
      isEditingSelectedCustomerData: this.state.isEditingSelectedCustomer ? null : {
        familyName :this.props.customer.familyName || "" ,
        id:this.props.customer.id ,
        givenName:this.props.customer.givenName || "" ,
        name:this.props.customer.name,
        phone:this.props.customer.phone ,
        email:this.props.customer.email  || "",
      },
      newCustomerPhoneError:false,
      isEditingSelectedCustomer:this.state.isEditingSelectedCustomer ? false :true
    })
  }


  getavailableServices = (services) => {

    let availableServices = [];
    services.forEach((service) => {
      if (service.customerTypes.indexOf(this.props.customer.type) > -1) {
        if (service.includeCustomers.indexOf('all') > -1 || service.includeCustomers.indexOf(this.props.customer.id) > -1) {
          if (service.excludeCustomers.indexOf(this.props.customer.id) == -1) {
            availableServices.push(service.title)
          }
        }
      }
    })

    return availableServices
  }


  getServiceError = () => {
    let currentService = this.props.activeVehicleType.title
    let availableServices = this.getavailableServices(this.props.Allservice);

    let S1 = String(this.props.t("serviceCustomerError1")).replace("xxx", currentService) + (availableServices.length > 0 ? String(this.props.t("serviceCustomerError2")).replace("xxx", availableServices.join(", ")) : "")

    // return `${currentService}  isn't available for this customer. ${availableServices.length > 0 ? `He/She only can choose ${availableServices.join(", ")} Service` : ""} `
    return S1
  }



  handlePhoneInput = (value) => {
    try {
      this.setState({
        newCustomerPhone: value ? value : "",
        newCustomerPhoneError: !isValidPhoneNumber(value)
      })
    } catch (error) {

    }
  };


  handleNewCustomerFields = (value, key) => {
    this.setState({
      [key]: value.target.value
    })
  };


  handleEditCustomerFields = (value, key ,exactValue) => {

    
    this.setState({

      isEditingSelectedCustomerData : {
        ...this.state.isEditingSelectedCustomerData,
        [key]: exactValue ? (value ? value : "") : value.target.value,
        newCustomerPhoneError: key == "phone" ? (value ? false:true) : this.state.newCustomerPhoneError
      }
      
    })
  };




  onFocusNewCustomerInput = () => {
    // this.setState({
    //   newCustomerPhoneError: false
    // })
  }

  onBlurNewCustomerInput = () => {
    this.setState({
      newCustomerPhoneError: !isValidPhoneNumber(this.state.newCustomerPhone)
    })
  }


  openNewCustomer = () => {
    this.setState({
      showExisting: false,
    })
  }

  registerUser = () => {
    this.setState({
      Register_New_Customer_Loading: true
    })
    this.props.dispatch(Register_New_Customer(this.props.dispatch, {
      "phone": this.state.newCustomerPhone,
      "givenName": this.state.newCustomerFirstName ,
      "familyName": this.state.newCustomerLastName ,
      "email": this.state.newCustomerEmail
    }, (call, error) => {
      this.setState({
        Register_New_Customer_Loading: false,
        customerId: call ? call : null,
        Register_New_Customer_ErrorApi: error ? error : false
      })
      if (call, !error) {
        this.props.dispatch(SetRecentAddress([]))

        this.props.dispatch(Get_User_Recent_Address(this.props.dispatch, call, null))

        // if (this.props.person) {
        this.props.dispatch(
          Set_Passenger_Na_Fa_Ph({
            name: "type",
            value: "individual",
          })
        );
        this.props.dispatch(
          Set_Passenger_Na_Fa_Ph({
            name: "name",
            value: this.state.newCustomerFirstName + " " + this.state.newCustomerLastName,
          })
        );
        this.props.dispatch(
          Set_Passenger_Na_Fa_Ph({
            name: "phone",
            value: this.state.newCustomerPhone,
          })
        );
        this.props.dispatch(
          Set_Passenger_Na_Fa_Ph({
            name: "id",
            value: call
          })
        );
        this.props.dispatch(
          Set_Passenger_Na_Fa_Ph({
            name: "balance",
            value: "0",
          })
        );

        Get_Addressbook_list_Api(
          this.props.dispatch,
          {
            customerId: call,
            page: 1,
            perpage: 20,
            searchRegex: "",
          },
          (call) => {
            call && this.checkandSekectDefaultPickup(call);
          }
        );
        this.checkPaymentMustBeCashOrNo(0);
      }
    }))

  }


  render() {
    const { t } = this.props;


    let canRegister = (this.state.newCustomerFirstName.length > 2 || this.state.newCustomerLastName.length > 2) && (this.state.newCustomerPhone.length > 3 && (this.state.newCustomerEmail.length > 0 ? this.Emailvalidate(this.state.newCustomerEmail) : true) ? isValidPhoneNumber(this.state.newCustomerPhone) : false);
    let canSaveChangesEditCustomer = this.state.isEditingSelectedCustomerData ? ((this.state.isEditingSelectedCustomerData.givenName.length > 2 || this.state.isEditingSelectedCustomerData.familyName.length > 2) && (this.state.isEditingSelectedCustomerData.phone.length > 3 && (this.state.isEditingSelectedCustomerData.email.length > 0 ? this.Emailvalidate(this.state.isEditingSelectedCustomerData.email) : true) ? isValidPhoneNumber(this.state.isEditingSelectedCustomerData.phone) : false)) : null;;;

    return (
      <newOrderpasserngerContainer
        style={{
          boxShadow: "rgba(128, 128, 128, 0.16) 0px 1px 6px 0px",
          marginLeft: "15px",
        }}
      >
        {/* <Person_business_switch /> */}
        <passerngertitle>
          <span>
            {" "}
            <Trans i18nKey={"Passenger"}> </Trans>{" "}
          </span>
          {/* {this.props.id && <span className="xty5">{this.props.type == "individual"
                            ? String(this.props.t("only")).replace("xxx", this.props.t("Person")) 
                            : String(this.props.t("only")).replace("xxx", this.props.t("Business"))  }</span>} */}


          {!this.props.id && <personBusinessSwitchContainer className="driverAMswitch" >
            <detail
              id="details"
              onClick={() => this.handleShowExisting(true)}
              className={`${this.state.showExisting ? "details" : "DEactive"}`}
              style={{ minWidth: "40px" }}
            >
              <Trans i18nKey={"Existing"}> </Trans>
            </detail>
            <history
              id="history"
              onClick={() => this.handleShowExisting(false)}
              className={`${!this.state.showExisting ? "details" : "DEactive"}`}
              style={{ minWidth: "40px" }}
            >
              <Trans i18nKey={"new"}> </Trans>
            </history>
          </personBusinessSwitchContainer>}


        </passerngertitle>

        {!this.props.id ? (this.state.showExisting ? (
          <ClickAwayListener onClickAway={() => this.CloseDropDown()}>
            <div className={`customerSelectContainer_DRDO ${(!!this.state.showDropDown && this.props.suggestListOrdered) && "customerSelectContainer_DRDO_Active"}`} style={{ zIndex: "11" }}>
              <input
                value={this.props.search_regex}
                onChange={this.InputOnChange}
                onClick={this.OpenDropDown}
                name={"givenName-" + this.state.id}
                autocomplete={"my-field-name1" + this.state.id}
                placeholder={t("Searchbynameorphonenumber")}
                type="text"
                className="newOrderSelectCustomerInput marginR16"
              />
              {!!this.state.showDropDown && <div className="NewOrder_customersListConatiner">

                {(this.props.suggestListLoading &&
                  !!this.state.showDropDown &&
                  this.props.suggestListOrdered
                ) &&
                  (
                    <div>
                      <span
                        className="NotravelsListHeader"
                        style={{ marginTop: "8px" }}
                      >
                        <Trans i18nKey={"poperLoading"}> </Trans>
                      </span>
                      <span className="NotravelsListText">
                        <Trans i18nKey={"fetchingdata"}> </Trans>
                      </span>
                    </div>
                  )}
                {!this.props.suggestListLoading &&
                  !!this.state.showDropDown &&
                  this.props.suggestListOrdered &&
                  this.props.suggestListData.length === 0 && (
                    <div className="NoCustomerFoundContainer">

                      <img src={userProfile} />
                      <span className="NoCustomerfound">
                        <Trans i18nKey={"NoCustomerfound"}> </Trans>
                      </span>

                      <span className="NoCustomerfoundD">
                        <Trans i18nKey={"NoCustomerfoundD"}> </Trans>
                      </span>

                      <span className="NewCustomer" onClick={this.openNewCustomer}>
                        <Trans i18nKey={"NewCustomer"}> </Trans>
                      </span>

                    </div>
                  )}

                {!this.props.suggestListLoading &&
                  !!this.state.showDropDown &&
                  this.props.suggestListData.map((item) => {
                    return (
                      <div
                        className="PassengerListItemContainer"
                        onClick={() => {
                          this.selectPassenger(item);
                        }}
                      >
                        <img src={
                          userProfile
                        } className="userProf" />
                        <p
                          className="PassengerListItem x96r"
                          style={{ width: "calc(100% - 28px)" }}
                        >
                          {" "}
                          {item.name}{" "}
                          <span>
                            {item.type == "individual"
                              ? this.props.t("Person")
                              : this.props.t("Business")}
                          </span>
                        </p>
                        <span className="PassengerListItemMargin x96t">
                          {" "}
                          {item.phone}{" "}
                        </span>
                      </div>
                    );
                  })}
              </div>}
            </div>
          </ClickAwayListener>
          // <passerngerfildsContainer ref={this.anchorEl}>

          //   <React.Fragment>
          //     <input
          //       value={this.props.search_regex}
          //       onChange={this.InputOnChange}
          //       onFocus={this.handleInputSearch}
          //       onClick={this.handleInputSearch}
          //       name={"givenName-" + this.state.id}
          //       autocomplete={"my-field-name1" + this.state.id}
          //       placeholder={t("Searchbynameorphonenumber")}
          //       type="text"
          //       className="name marginR16"
          //     />

          //   </React.Fragment>
          // </passerngerfildsContainer>
        ) : <div className="newCustomerFildsConatiner">
          <input value={this.state.newCustomerFirstName} placeholder={this.props.t("Fname")} className="newCustomerFildName" onChange={(e) => this.handleNewCustomerFields(e, "newCustomerFirstName")} />
          <input value={this.state.newCustomerLastName} placeholder={this.props.t("Lname")} className="newCustomerFildName floatright" onChange={(e) => this.handleNewCustomerFields(e, "newCustomerLastName")} />
          <PhoneInput
            defaultCountry={global.config.Country}
            // onChange={(e)=>{this.fieldsOnChange("phone",e.target.value)}}
            value={this.state.newCustomerPhone}
            onChange={(eee) => this.handlePhoneInput(eee)}
            onFocus={this.onFocusNewCustomerInput}
            onBlur={this.onBlurNewCustomerInput}
            id={"recieverPhoneNumber"}
            name={"bbddca44" + this.props.number}
            autocomplete={"bbddca44" + this.props.number}
            className="senderphonenumberPickup newCustomerFildName"
            placeholder={t("Phonenumber")}
            style={{ fontSize: "12px", height: "32px" }}
          />
          <input value={this.state.newCustomerEmail} placeholder={this.props.t("EmailOtional")} className="newCustomerFildName floatright" style={{ marginTop: "10px", marginBottom: "2px" }} onChange={(e) => this.handleNewCustomerFields(e, "newCustomerEmail")} />

          {/* {this.state.newCustomerPhoneError ? <p className="phonenumberisnotcorrect">{this.props.t("phonenumberisnotcorrect")}</p> : (this.state.newCustomerEmail.length > 0 ? (!this.Emailvalidate(this.state.newCustomerEmail) ? <p className="phonenumberisnotcorrect">{this.props.t("Emailisnotcorrect")}</p> : null) : null)} */}
          {this.state.newCustomerPhoneError && <p className="phonenumberisnotcorrect">{this.props.t("phonenumberisnotcorrect")}</p>}
          {(this.state.newCustomerEmail.length > 0 ? (!this.Emailvalidate(this.state.newCustomerEmail) ? <p className="phonenumberisnotcorrect" style={{ marginTop: this.state.newCustomerPhoneError && "5px" }}>{this.props.t("Emailisnotcorrect")}</p> : null) : null)}
          <span onClick={canRegister && this.registerUser} className={`registerNewCustomer ${!canRegister && "registerNewCustomerDeactive"}`}>
            {this.state.Register_New_Customer_Loading ?
              (<CircularProgress
                size={18}
                style={{
                  color: "#148be9",
                  float: "right",
                  marginRight: "60px",
                  marginLeft: "60px"
                }}
              />) : (<React.Fragment>
                <img src={userRegister} />
                <span> {this.props.t("Registercustomer")} </span> </React.Fragment>)
            }
          </span>

          {this.state.Register_New_Customer_ErrorApi && <span className="RegisterNewApiError"> <img src={warningIc} /> <span> {this.state.Register_New_Customer_ErrorApi} </span>  </span>}




        </div>) : null}

        {(this.props.id && !this.state.isEditingSelectedCustomer) && (
          <div className="driverInfoManualContainer customerinfoManualContainerNewOrder">
            <img
              src={remove}
              onClick={this.resetCustomer}
              style={{ width: "18px", height: "18px" }}
            />
            <div
              className="namePhoneContainer"
              style={{ width: "auto", flex: "1" }}
            >
              <p className="npcName"> {`${this.props.name}`} </p>
              <p className="npcPhone">
                <Trans i18nKey={"Fullname"}> </Trans>{" "}
              </p>
            </div>

            <div
              className="namePhoneContainer usernameContainer"
              style={{ width: "auto", flex: "1" }}
            >
              <p className="npcName"> {`${this.props.phone}`} </p>
              <p className="npcPhone">
                <Trans i18nKey={"Pnumber"}> </Trans>{" "}
              </p>
            </div>
            <div
              className="namePhoneContainer usernameContainer"
              style={{ width: "auto", flex: "1" }}
            >
              <p className="npcName"> {`${this.props.type}`} </p>
              <p className="npcPhone">
                <Trans i18nKey={"type"}> </Trans>{" "}
              </p>
            </div>

            {global.config.dispatcherSettings.isVoipActvie && <img src={call} className="newOrderCustomerVoip" onClick={this.makeOutgoingCall} />}
           {this.props.type == "individual" && <img
              src={customerEdit}
              onClick={this.editCustomer}
              style={{ width: "18px", height: "18px" ,marginInlineEnd: "16px"}}
            />}
          </div>
        )}

        {this.state.isEditingSelectedCustomer && (
          <div className="newCustomerFildsConatiner">
          <input value={this.state.isEditingSelectedCustomerData.givenName} placeholder={this.props.t("Fname")} className="newCustomerFildName" onChange={(e) => this.handleEditCustomerFields(e, "givenName")} />
          <input value={this.state.isEditingSelectedCustomerData.familyName} placeholder={this.props.t("Lname")} className="newCustomerFildName floatright" onChange={(e) => this.handleEditCustomerFields(e, "familyName")} />
          <PhoneInput
            defaultCountry={global.config.Country}
            // onChange={(e)=>{this.fieldsOnChange("phone",e.target.value)}}
            value={this.state.isEditingSelectedCustomerData.phone}
            onChange={(eee) => this.handleEditCustomerFields(eee , "phone" , true)}
            onFocus={this.onFocusNewCustomerInput}
            onBlur={this.onBlurNewCustomerInput}
            id={"recieverPhoneNumber"}
            name={"bbddca44" + this.props.number}
            autocomplete={"bbddca44" + this.props.number}
            className="senderphonenumberPickup newCustomerFildName"
            placeholder={t("Phonenumber")}
            style={{ fontSize: "12px", height: "32px" }}
          />
          <input value={this.state.isEditingSelectedCustomerData.email} placeholder={this.props.t("EmailOtional")} className="newCustomerFildName floatright" style={{ marginTop: "10px", marginBottom: "2px" }} onChange={(e) => this.handleEditCustomerFields(e, "email")} />

          {/* {this.state.newCustomerPhoneError ? <p className="phonenumberisnotcorrect">{this.props.t("phonenumberisnotcorrect")}</p> : (this.state.newCustomerEmail.length > 0 ? (!this.Emailvalidate(this.state.newCustomerEmail) ? <p className="phonenumberisnotcorrect">{this.props.t("Emailisnotcorrect")}</p> : null) : null)} */}
          {!isValidPhoneNumber(this.state.isEditingSelectedCustomerData.phone) && <p className="phonenumberisnotcorrect">{this.props.t("phonenumberisnotcorrect")}</p>}
          {(this.state.isEditingSelectedCustomerData.email.length > 0 ? (!this.Emailvalidate(this.state.isEditingSelectedCustomerData.email) ? <p className="phonenumberisnotcorrect" style={{ marginTop: !isValidPhoneNumber(this.state.isEditingSelectedCustomerData.phone) && "5px" }}>{this.props.t("Emailisnotcorrect")}</p> : null) : null)}
          <span onClick={canSaveChangesEditCustomer &&  this.editCustomerHttp  } className={`registerNewCustomer editCustomerSavechanges ${!canSaveChangesEditCustomer && "registerNewCustomerDeactive"}`}>
            {this.state.Register_New_Customer_Loading ?
              (<CircularProgress
                size={18}
                style={{
                  color: "#148be9",
                  float: "right",
                  marginRight: "60px",
                  marginLeft: "60px"
                }}
              />) : (<React.Fragment>
                <span  style={{marginLeft:"0px"}}> {this.props.t("Savechanges")} </span> </React.Fragment>)
            }
          </span>

          <span onClick={this.editCustomer} className={`registerNewCustomer`}>
              <span style={{marginLeft:"0px"}}> {this.props.t("Cancel")} </span>
          </span>

          {this.state.Register_New_Customer_ErrorApi && <span className="RegisterNewApiError"> <img src={warningIc} /> <span> {this.state.Register_New_Customer_ErrorApi} </span>  </span>}




        </div>
        )}


        {/* <BackdropCustom
          open={
            this.state.showInputSearchDialoge &&
            (this.props.suggestListLoading || this.props.suggestListOrdered)
          }
          onClick={this.handleInputSearch}
        >
          <Popper
            style={{
              width: "475px",
              zIndex: "6",
              marginTop: "0px",
              maxHeight: "200px",
            }}
            placement={"bottom"}
            id={"passenger"}
            open={
              this.state.showInputSearchDialoge &&
              (this.props.suggestListLoading || this.props.suggestListOrdered)
            }
            anchorEl={this.anchorEl.current}
          >
            <Paper
              style={{
                display: this.state.showInputSearchDialoge ? "" : "none",
                width: "478px",
                float: "right",
                borderRadius: "8px",
                marginTop: "10px",
                padding: "0",
                right: "271px",
                maxHeight: "200px",
                overflowY: "scroll",
                overflowX: "hidden",
              }}
              elevation={3}
            >
              {this.props.suggestListLoading && (
                <div>
                  <span
                    className="NotravelsListHeader"
                    style={{ marginTop: "8px" }}
                  >
                    <Trans i18nKey={"poperLoading"}> </Trans>
                  </span>
                  <span className="NotravelsListText">
                    <Trans i18nKey={"fetchingdata"}> </Trans>
                  </span>
                </div>
              )}
              {!this.props.suggestListLoading &&
                this.props.suggestListOrdered &&
                this.props.suggestListData.length === 0 && (
                  <div>
                    <span
                      className="NotravelsListHeader"
                      style={{ marginTop: "8px" }}
                    >
                      <Trans i18nKey={"Noinformationfound"}> </Trans>
                    </span>
                    <span className="NotravelsListText">
                      <Trans i18nKey={"Pleasetryanotherkeyword"}> </Trans>
                    </span>
                  </div>
                )}

              {!this.props.suggestListLoading &&
                this.props.suggestListData.map((item) => {
                  return (
                    <div
                      className="PassengerListItemContainer"
                      onClick={() => {
                        this.selectPassenger(item);
                      }}
                    >
                      <p
                        className="PassengerListItem x96r"
                        style={{ width: "100%" }}
                      >
                        {" "}
                        {item.name}{" "}
                        <span>
                          {item.type == "individual"
                            ? this.props.t("Person")
                            : this.props.t("Business")}
                        </span>
                      </p>
                      <span className="PassengerListItemMargin x96t">
                        {" "}
                        {item.phone}{" "}
                      </span>
                    </div>
                  );
                })}


            </Paper>
          </Popper>
        </BackdropCustom> */}

        {/* {((this.props.customer && this.props.customer.id.length > 0) && this.props.sendOrder.serviceError) && <p className="ServiceError">
          {this.getServiceError()}
        </p>} */}
      </newOrderpasserngerContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  person: state.NewOrder.newOrder.customer.person,
  BusinessName: state.NewOrder.newOrder.customer.BusinessName,
  BusinessNumber: state.NewOrder.newOrder.customer.BusinessNumber,
  BusinessId: state.NewOrder.newOrder.customer.BusinessId,
  name: state.NewOrder.newOrder.customer.name,
  type: state.NewOrder.newOrder.customer.type,
  canWalletBeNegative: state.NewOrder.newOrder.customer.canWalletBeNegative || false,
  phone: state.NewOrder.newOrder.customer.phone,
  email: state.NewOrder.newOrder.customer.email,
  id: state.NewOrder.newOrder.customer.id,
  search_regex: state.NewOrder.newOrder.customer.search_regex,
  suggestListLoading: state.NewOrder.newOrder.customer.suggestListLoading,
  suggestListData: state.NewOrder.newOrder.customer.suggestListData,
  suggestListOrdered: state.NewOrder.newOrder.customer.suggestListOrdered,
  serviceSettings: state.NewOrder.newOrder.service.activeVehicleType
    ? state.NewOrder.newOrder.service.activeVehicleType.setting
    : { enableReceiverCash: false, isCashBySenderActive: false },
  UserBalance: state.NewOrder.newOrder.customer.balance,
  price: state.NewOrder.newOrder.price,
  paymentMethod: state.NewOrder.newOrder.paymentMethod.paymentMethod,
  paymentSide: state.NewOrder.newOrder.paymentMethod.paymentSide,
  Allservice: state.NewOrder.newOrder.service.serviceVehicleTypes,
  sendOrder: state.NewOrder.newOrder.sendOrder,
  activeVehicleType: state.NewOrder.newOrder.service.activeVehicleType,
  customer: state.NewOrder.newOrder.customer,

});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
