import Dropp from "../../../assets/couriers24_logo.png";
import FavIcon from "../../../assets/PROJECTS/couriers24.png";

export default function couriers24() {
    
    return  global.config = {
    "ProjectName": "24couriers",
    "LogoType": Dropp,
    "BaseApi": "https://rest.24couriers.co.uk",
    "BaseSocket": "https://socket.24couriers.co.uk",
    "BaseTrack": "https://admin.24couriers.co.uk",
    "MapCenter": {
        "lat": 51.508346164191124,
        "lng": -0.12357130681050861
    },
    "Lang": "en",
    "Direction": "ltr",
    "AllLanguages": [{
        "lang": "en",
        "direction": "ltr",
        "i18Lang": "English"
    }],
    "FavIcon": FavIcon,
    "Country": "UK",
    "thirdPartyLoginSupport": false  ,
    mapType :"osm", /* osm | google */ 
    googleApiKey : ""
}}
