import React, { useEffect, useRef, useState } from 'react';
import styles from './Stopdetails.module.css';
import { CALL, CLOSE, COD, COPY, DONE, DONE_CIRCLE, Deliveryonly_deactive, FORWARD, Failed, MORE, personGrey, SCHEDULE, Status, WALLET, email_color, notes, price, status_dark, LINK_CIRCLE, BARCODE, Edit, Ic_danger, QUESTION } from '../../../assets';
import Button from '../../../design-system/lib/Button';
import Typography from '../../../design-system/lib/Typography';
import Tag from '../../../design-system/lib/Tag';
import IconProvider from '../../../design-system/lib/IconProvider';
import { ClickAwayListener, Paper, Popper } from '@material-ui/core';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { get_calendarType, get_currencyCode, get_id, get_lang } from '../../../helper/UserData/userdate';
import { Voip_OutgoingCall } from '../../../Redux/actions/actions';
import { useDispatch } from 'react-redux';
import momentJalaali from "moment-jalaali";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import ProgressBar from '../../../design-system/lib/ProgressBar';
import ProgressIndicator from '../../../design-system/lib/ProgressIndicator';
import { snack } from '../../../utils/SnackbarUtilsConfigurator';
import pickupdot from "../../../assets/pickupdot.svg";
import { isEmpty } from 'lodash';
import ic_barcode_scanned from "../../../assets/IconProvider/ic_barcode_scanned.svg";


export function Stopdetails({ setOrderData, data, reload, routeId }) {
        const [editAnchorEl, setEditAnchorEl] = useState(null);
        const [showPopper, setShowPopper] = useState(false);
        const Popperref = useRef(null);
        const dispatch = useDispatch();
        const { t, i18n } = useTranslation();


        const handleEditClick = (event) => {
                setShowPopper(true)
                setEditAnchorEl(editAnchorEl ? null : event.currentTarget);
        };

        const makeOutgoingCall = (customerId = null, driverId = null, phone, orderId) => {

                dispatch(Voip_OutgoingCall(dispatch, {
                        "to": phone,
                        "orderId": orderId,
                        "customerId": null,
                        "driverId": null,
                        "dispatcherId": get_id()
                }))
        }


        const getScheduleText = (data) => {

                let selectedSection = data.order.pickup ? data.order.pickup : data.order.delivery
                if (selectedSection.completeAfter || selectedSection.completeBefore) {
                        return `${momentJalaali(selectedSection.completeAfter).locale(get_calendarType()).format("D MMM HH:mm")}${momentJalaali(selectedSection.completeBefore).locale(get_calendarType()).format("- D MMM HH:mm")}`
                } else {
                        return null
                }

        }


        console.log("Stopdetails ", data)


        return <div className={styles.stopdetailscontainer}>
                <div className={styles.container} >
                        <div className={styles.firstrow} ref={Popperref}>
                                <Link to={`/route/${routeId}/0`}  ><Button icon={CLOSE} size='Medium' type='Secondary' onClick={() => { setOrderData(null) }} /></Link>
                                <div className={styles.titleDescriptionContainer}>
                                        <Typography weight='Body_Middle_Bold' text={`Stop ${data?.stop?.position}`} />
                                </div>
                                <Button icon={MORE} size='Medium' type='Secondary' onClick={handleEditClick} />
                        </div>
                </div>

                {(!data.OrderLoading && !data.orderError) && <div className={styles.scrollableContent}>
                        <div className={styles.container} >

                                <div className={styles.secorow}>

                                        <IconProvider Icon={pickupdot} size="16" style={{ marginTop: "8..5px" }} />

                                        <div className={styles.secorowCol}>
                                                <Typography weight='Body_Middle_Medium' text={data.address} />
                                                {/* <Typography style={{ marginBottom: "5px" }} weight='Subtitle_Tiny_Regular' text={(data.order.pickup?.addressDetail || data.order.delivery?.addressDetail) ? (data.order.pickup?.addressDetail || data.order.delivery?.addressDetail) : "Address Details comes here"} /> */}

                                                <div className={styles.rowTagsContainer}>
                                                        <Tag backgroundColor='GrayUltraLight' iconSize='16' color='TextNormal' size='Medium' text={data.stage} icon={Deliveryonly_deactive} weight='Body_Small_Medium' style={{ borderRadius: "8px", marginTop: "4px" }} />
                                                        {["Failed"].indexOf(data.status) > -1 && <Tag backgroundColor='GrayUltraLight' iconSize='16' color='Red' size='Medium' text={"Failed"} icon={Failed} weight='Body_Small_Medium' style={{ borderRadius: "8px", marginTop: "4px" }} />}
                                                        {["Successful"].indexOf(data.status) > -1 && <Tag backgroundColor='GrayUltraLight' iconSize='16' color='Green' size='Medium' text={"Done"} icon={DONE_CIRCLE} weight='Body_Small_Medium' style={{ borderRadius: "8px", marginTop: "4px" }} />}

                                                </div>


                                        </div>


                                </div>
                                {(["Failed"].indexOf(data.status) > -1) &&
                                        <>
                                                <div className={styles.hline}></div>
                                                <div className={styles.customerInfofirstrow}>
                                                        <IconProvider Icon={QUESTION} size="16" />
                                                        <div className={styles.customerInfotitleDescriptionContainer}>
                                                                {(data.order.pickup && data.order.pickup?.failedReason) && <Typography weight='Body_Middle_Regular' text={data.order.pickup?.failedReason?.text} />}
                                                                {(data.order.delivery && data.order.delivery?.lastAttemptReason && !isEmpty(data.order.delivery?.lastAttemptReason)) && <Typography weight='Body_Middle_Regular' text={data.order.delivery?.lastAttemptReason?.text} />}

                                                        </div>


                                                </div>  </>
                                }
                                <div className={styles.hline}></div>

                                <div className={styles.customerInfofirstrow}>
                                        <IconProvider Icon={personGrey} size="16" />
                                        <div className={styles.customerInfotitleDescriptionContainer}>
                                                <Typography weight='Body_Middle_Medium' text={data.order.customer.name} />

                                                <CopyToClipboard text={data.order.customer.phone}
                                                        onCopy={() => { }}>
                                                        <Typography weight='Subtitle_Tiny_Regular' className={styles.Typography1} text={<>{String(data.order.customer.phone).startsWith("+") ? data.order.customer.phone : (String(data.order.customer.phone).startsWith("00") ? "+" + String(data.order.customer.phone).slice(2, data.order.customer.phone.length) : "")} <IconProvider Icon={COPY} size="12" /></>} />

                                                </CopyToClipboard>
                                        </div>
                                        {global.config.dispatcherSettings.isVoipActvie && <Button icon={CALL} size='Medium' type='Secondary' onClick={() => { makeOutgoingCall(null, null, data.order.customer.phone, data.order.id) }} />}
                                        {String(data.order.customer.email).length > 0 &&
                                                <a href={`mailto:${data.order.customer.email}`}><Button icon={email_color} size='Medium' type='Secondary' style={{ marginInlineStart: "12px" }} /></a>
                                        }

                                </div>


                                {getScheduleText(data) && <>   <div className={styles.hline}></div>
                                        <div className={styles.customerInfofirstrow}>
                                                <IconProvider Icon={SCHEDULE} size="16" />
                                                <div className={styles.customerInfotitleDescriptionContainer}>
                                                        <Typography weight='Body_Middle_Medium' text={getScheduleText(data)} />
                                                        <Typography weight='Subtitle_Tiny_Regular' className={styles.Typography1} text={"Schedule"} />
                                                </div>
                                        </div></>}

                        </div>
                        {/*(String({ ...data?.order?.pickup ? data?.order?.pickup : data?.order?.delivery }.podSignature).length > 0 || String({ ...data?.order?.pickup ? data?.order?.pickup : data?.order?.delivery }.podPhoto).length > 0 || String({ ...data?.order?.pickup ? data?.order?.pickup : data?.order?.delivery }.podNote).length > 0) && */(["Successful"].indexOf(data.status) > -1 && data.order[String(data.stage).toLowerCase()]?.proofs.length > 0) && <div className={styles.profofdeliveryContainer}>
                                <Typography weight='Body_Middle_Medium' text={t(data.stage == "Pickup" ? "pop" : "pod")} />
                          
                              {data?.stage &&  <div className={styles.profsContainer} style={{paddingBottom:"16px"}}>

                                        {data.order[String(data.stage).toLowerCase()]?.proofs.map((item) => {
                                        return <div className={styles.profsItem}>
                                        {["photo", "signature"].indexOf(item.type) > -1 && <img
                                        style={{
                                                width: "100%",
                                                height: "100%",
                                                objectFit: "contain",
                                                borderRadius:"5.14px"
                                        }}
                                        src={item.photoUrl ? item.photoUrl : item.signatureUrl}
                                        />}
                                        {['barcode-scan'].indexOf(item.type) > -1 && <div className={styles.innerProofItems}>
                                        <img src={ic_barcode_scanned} style={{marginBottom:"8px"}} />
                                        <Typography weight="Body_Tiny_Medium" text={item.barcodeDataKey == "referenceId" ? `Ref.${item.barcodeData}` : `#${item.barcodeData}`} style={{overflowWrap:"break-word" , maxWidth:"82px" , textAlign :" center"}}  />

                                        </div>
                                        }

                                        </div>
                                        })}

                                        </div>}
                          
                             

                                {((data.stage == "Pickup") ? String(data?.order?.pickup?.popNote).length > 0 : String(data?.order?.delivery?.podNote).length > 0) ? 
                                <div className={styles.customerInfofirstrow} style={{ marginTop: "16px" }}>
                                <IconProvider Icon={notes} size="16" />
                                <div className={styles.customerInfotitleDescriptionContainer}>
                                        <Typography weight='Body_Middle_Regular' text={((data.stage == "Pickup") ? String(data?.order?.pickup?.popNote) : String(data?.order?.delivery?.podNote))} />
                                        <Typography weight='Subtitle_Tiny_Regular' text={"Notes"} />
                                </div>
                        </div>:""}
                                {/* {String({ ...data?.order?.pickup ? data?.order?.pickup : data?.order?.delivery }.podNote).length > 0 && <>  <div className={styles.hline} style={{ marginTop: "16px" }}></div>
                                        <div className={styles.customerInfofirstrow} style={{ marginTop: "16px" }}>
                                                <IconProvider Icon={notes} size="16" />
                                                <div className={styles.customerInfotitleDescriptionContainer}>
                                                        <Typography weight='Body_Middle_Regular' text={{ ...data?.order?.pickup ? data?.order?.pickup : data?.order?.delivery }.podNote} />
                                                        <Typography weight='Subtitle_Tiny_Regular' text={"Notes"} />
                                                </div>
                                        </div></>} */}
                        </div>}
                        <div className={styles.customerInfo}>
                                <Typography weight='Body_Middle_Medium' text={"Order Info"} />

                                <div className={styles.customerInfofirstrow}>
                                        <IconProvider Icon={status_dark} size="16" />
                                        <div className={styles.customerInfotitleDescriptionContainer}>
                                                <Typography weight='Body_Small_Medium' text={data.stage == "Pickup" ? data.order?.pickup?.status : data.order?.delivery?.status} className={styles[`${data.stage == "Pickup" ? data.order?.pickup?.status : data.order?.delivery?.status}span`]} />
                                                <Typography weight='Subtitle_Tiny_Regular' text={data.order.code} />
                                        </div>
                                        <Link to={`/order/${data.order.id}`}>
                                                <Button icon={FORWARD} text={"Order"} size='Medium' type='Secondary' onClick={handleEditClick} />
                                        </Link>
                                </div>

                                {data.order.driver && <> <div className={styles.hline}></div>

                                        <div className={styles.customerInfofirstrow}>
                                                <IconProvider Icon={personGrey} size="16" />
                                                <div className={styles.customerInfotitleDescriptionContainer}>
                                                        <Typography weight='Body_Middle_Medium' text={data.order.driver.name} />
                                                        <CopyToClipboard text={data.order.driver.phone}
                                                                onCopy={() => { }}>
                                                                <Typography weight='Subtitle_Tiny_Regular' className={styles.Typography1} text={<>{String(data.order.driver.phone).startsWith("+") ? data.order.driver.phone : (String(data.order.driver.phone).startsWith("00") ? "+" + String(data.order.driver.phone).slice(2, data.order.driver.phone.length) : "")} <IconProvider Icon={COPY} size="12" /></>} />

                                                        </CopyToClipboard>                                                </div>
                                                {global.config.dispatcherSettings.isVoipActvie && <Button icon={CALL} size='Medium' type='Secondary' onClick={() => { makeOutgoingCall(null, null, data.order.driver.phone, data.order.id) }} />}
                                        </div></>}

                                {String(data.order.note).length > 0 && <> <div className={styles.hline}></div>

                                        <div className={styles.customerInfofirstrow}>
                                                <IconProvider Icon={notes} size="16" />
                                                <div className={styles.customerInfotitleDescriptionContainer}>
                                                        <Typography weight='Body_Middle_Regular' text={data.order.note} />
                                                        <Typography weight='Subtitle_Tiny_Regular' text={"Notes"} />
                                                </div>
                                        </div></>}

                                <div className={styles.hline}></div>

                                <div className={styles.customerInfofirstrow}>
                                        <IconProvider Icon={price} size="16" />
                                        <div className={styles.customerInfotitleDescriptionContainer} style={{ marginInlineEnd: "12px" }}>
                                                <Typography weight='Body_Middle_Regular' text={`${Number(data.order.price).toLocaleString(get_lang(), {
                                                        style: "currency",
                                                        currency: get_currencyCode(),
                                                })} - ${t(String(data.order.paymentSide + data.order.paymentMethod))}`/*"250$ - Wallet"*/} />
                                                <Typography weight='Subtitle_Tiny_Regular' text={"Price"} />
                                        </div>


                                </div>

                                {data.order.codAmount > 0 && <>
                                        <div className={styles.hline}></div>


                                        <div className={styles.customerInfofirstrow}>
                                                <IconProvider Icon={COD} size="16" />
                                                <div className={styles.customerInfotitleDescriptionContainer} style={{ marginInlineEnd: "12px" }}>
                                                        <Typography weight='Body_Middle_Regular' text={`${Number(data.order.codAmount).toLocaleString(get_lang(), {
                                                                style: "currency",
                                                                currency: get_currencyCode(),
                                                        })}`} />
                                                        <Typography weight='Subtitle_Tiny_Regular' text={t("CashOnDelivery")} />
                                                </div>


                                        </div>
                                </>}



                        </div>



                        {/* <div className={styles.profofdeliveryContainer}>
                                <Typography weight='Body_Middle_Medium' text={"Schedule"} />
                                <div className={styles.ScheduleContainer}>
                                        <div className={styles.Schedule}>
                                                <Typography weight='Body_Tiny_Regular' text={"Complete After"} color="TextLight" />
                                                <Input type={"text"} iconStart={SCHEDULE} placeHolder='Now' />
                                        </div>
                                        <div className={styles.Schedule}>
                                                <Typography weight='Body_Tiny_Regular' text={"Complete Before"} color="TextLight" />
                                                <Input type={"text"} iconStart={SCHEDULE} placeHolder='Anytime' />
                                        </div>
                                </div>

                        </div> */}

                        {/* <div className={styles.profofdeliveryContainer} style={{ gap: "12px" }}>
                                <Typography weight='Body_Middle_Medium' text={"Sender info"} />
                                <Input type={"text"} placeHolder='Anytime' value="Block 1" className={styles.senderInfoInput} />
                                <Input type={"text"} placeHolder='Anytime' value="John" className={styles.senderInfoInput} />
                                <Input type={"text"} placeHolder='Anytime' value="+1 12345678900" className={styles.senderInfoInput} />
                                <Input type={"text"} placeHolder='Email' value="" className={styles.senderInfoInput} />
                        </div> */}

                </div>
                }
                {(!data.OrderLoading && !data.orderError) && <Popper
                        open={showPopper}
                        anchorEl={Popperref.current}
                        role={undefined}
                        placement={"bottom-end"}
                        id='routedetailseditAnchorEl'
                        // style={{
                        //   // width: "160px",
                        //   // marginBottom: "4px",
                        //   // border: "solid 1px rgba(128, 128, 128, 0.2)",
                        //   borderRadius: "10px",
                        //   // backgroundColor: "white",
                        //   boxShadow: "0 1px 12px 0 rgba(128, 128, 128, 0.2)",
                        //   position:"fixed !important"

                        //   // zIndex: "1"
                        // }}
                        //   className="Popperxxx"
                        transition
                        disablePortal
                >
                        {({ TransitionProps, placement }) => (
                                <Paper style={{ borderRadius: "8px", zIndex: "2" }}>
                                        <ClickAwayListener
                                                onClickAway={(e) => {
                                                        setShowPopper(false)
                                                }}
                                        >
                                                <div className={styles.popupItemContainer}>
                                                        <CopyToClipboard text={data.order.trackOrder}
                                                                onCopy={() => { setShowPopper(false); snack.success_i18('Addedsuccessfully') }}>
                                                                <div className={styles.item}> <IconProvider Icon={LINK_CIRCLE} size='16' /> <Typography weight='Body_Middle_Regular' text='Track Link' /> </div>
                                                        </CopyToClipboard>
                                                        <CopyToClipboard text={data.order.shipmentLabelUrl}
                                                                onCopy={() => { setShowPopper(false); snack.success_i18('Addedsuccessfully') }}>
                                                                <div className={styles.item}> <IconProvider Icon={BARCODE} size='16' /> <Typography weight='Body_Middle_Regular' text='Shipment Label' /> </div>
                                                        </CopyToClipboard>
                                                        {/* <CopyToClipboard text={data.order.trackOrder}
                                                        onCopy={() => { }}>
                                                        <div className={styles.item}> <IconProvider Icon={Edit} size='16' /> <Typography weight='Body_Middle_Regular' text='Edit Stop' /> </div>
                                                </CopyToClipboard>
                                                      */}

                                                </div>
                                        </ClickAwayListener>
                                </Paper>
                        )}
                </Popper>}

                {data.OrderLoading && <Loading />}
                {data.orderError && <DetailsError reload={reload} orderId={data.id} stop={data} />}
        </div>

}





const Loading = () => {
        return <div style={{ float: "left", marginTop: 'calc(50vh - 40px)' }}>
                <ProgressIndicator style={{ fontSize: "12px" }} />
        </div>
}


function DetailsError({ reload, orderId, stop }) {

        return (
                <div className='jojojoj'
                        style={{ display: 'flex', position: 'relative', height: `calc(100vh - 104px)`, width: "100%", flexDirection: 'column', transition: "all .3s ease-in-out", justifyContent: "center", alignItems: "center" }}>
                        <div className={styles.dialog}>
                                <IconProvider Icon={Ic_danger} style={{ marginBottom: "16px" }} />
                                <Typography weight='Body_Middle_Bold' text='Error in loading data' style={{ textAlign: "center", marginBottom: "4px" }} />
                                <Typography weight='Body_Tiny_Regular' text='There was a problem loading data. please try again or contact support' style={{ textAlign: "center", marginBottom: "16px" }} />
                                <Button size='Medium' type='Primary' text='Try again' onClick={() => { reload(orderId, stop) }} />
                        </div>
                </div>
        )
}
