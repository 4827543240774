import acistants from "../../../assets/acistant.png";
import FavIcon from "../../../assets/PROJECTS/acistant.png";

export default function acistant() {
    
    return  global.config =  {
        "ProjectName": "acistant",
        "LogoType": acistants,
        "BaseApi": "https://rest.acistant.xyz",
        "BaseSocket": "https://socket.acistant.xyz",
        "BaseTrack": "https://panel.acistant.xyz",
        "MapCenter": {
            "lat": "10.001389",
            "lng": "53.565278"
        },
        "Lang": "en",
        "Direction": "ltr",
        "AllLanguages": [{
            "lang": "en",
            "direction": "ltr",
            "i18Lang": "English"
        }, {
            "lang": "fa",
            "direction": "rtl",
            "i18Lang": "Persian"
        }, {
            "lang": "fr",
            "direction": "ltr",
            "i18Lang": "French"
        }, {
            "lang": "ro",
            "direction": "ltr",
            "i18Lang": "Romanian"
        }],
        "FavIcon": FavIcon,
        "Country": "US",
        "thirdPartyLoginSupport": true ,
        mapType :"osm", /* osm | google */ 
        googleApiKey : ""
    } }