import * as React from 'react'
import styles from './style.module.css';
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Schedule from '../../Schedule';
import { useLocalServices } from '../../../../../../../hooks';
import { Edit_Travel_Operate_Changed, Edit_Travel_set_Dropoff_inputs, Edit_Travel_set_pickup, Set_Pickup_Inputs } from '../../../../../../../Redux/actions/actionNames';
import { CLOSE } from '../../../../../../../assets';
import Typography from '../../../../../../../design-system/lib/Typography';
import Input from '../../../../../../../design-system/lib/Input';
import Button from '../../../../../../../design-system/lib/Button';




function DropoffInfoEditOrder({handleShowInfo ,addressDetail ,floor ,room,email, fullName ,phone, customerDescription ,scheduleDateBefore ,scheduleDateAfter ,buildingBlock ,id , first}) {
  const serviceId = useSelector((state) => state.ViewTravel.pureData.service.id)
  const serviceactiveVehicleType = useSelector((state) => state.EditOrder.editOrder.service.activeVehicleType)


  const unit = room;
  const senderFullName = fullName;
  const senderPhoneNumber = phone;
  const ActivescheduleSetting = serviceactiveVehicleType ? serviceactiveVehicleType.scheduleSetting.isActive : false;
  const orderTypeActive = useSelector((state) => state.ViewTravel.pureData.type)
  const block = buildingBlock;

  const CustomerDescription = customerDescription
  const { t, i18n } = useTranslation();


  const dispatch = useDispatch();


  const closeDialoge = () => {
    handleShowInfo()
  };


  const Emailvalidate = (email) => {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }


  const handlePickUpInputs = (e, name) => {
    dispatch(Edit_Travel_Operate_Changed());


    dispatch(
      Edit_Travel_set_Dropoff_inputs({
        name: name,
        value: e.target.value ? e.target.value : "",
        firstDropOff:  first,
        id:  id,
      })
    );




  };

  const phoneNumberOnChange = (res, ddd) => {
    dispatch(Edit_Travel_Operate_Changed());

    dispatch(
      Edit_Travel_set_Dropoff_inputs({
        name: ddd,
        value: res,
        firstDropOff:  first,
        id:  id,
      })
    );
  };

  console.log("dwdwdw ",serviceactiveVehicleType ,phone)

  return (
    <div className={styles.NewCustomerRegisterContainer} style={{marginInlineStart:"538px"}}>
      <div className={styles.largeHeader}>
        <Button icon={CLOSE} size="Medium" type="Secondary" onClick={closeDialoge} />
        <div className={styles.largeHeaderTitles}>
          <Typography text={t("DropOff")} weight="Body_Middle_Bold" className={styles.SmallTitle} style={{ margin: "0", padding: "0" }} />
        </div>
      </div>
{/* 
      {orderTypeActive == "PickupDelivery" && <div className={styles.body}>
        <PickupDriverNewOrder />
      </div>} */}
      <div className={styles.body}>
        <Typography weight='Body_Middle_Medium' text={t("Senderinfo")} />

        <div className={styles.inputsConatiner}>
          {(serviceactiveVehicleType?.setting?.isDropoffAddressRoomFloorBlockActive && orderTypeActive != "PickupDelivery") && <div className={styles.threeCOLUMNS}>
            <Input type={"text"} placeHolder={t("block")} value={block} inputStyle={{ width: "inherit" }} onChange={(e) => { handlePickUpInputs(e, "buildingBlock") }} />
            <Input type={"text"} placeHolder={t("Floor")} value={floor} inputStyle={{ width: "inherit" }} onChange={(e) => { handlePickUpInputs(e, "floor") }} />
            <Input type={"text"} placeHolder={t("Unit")} value={unit} inputStyle={{ width: "inherit" }} onChange={(e) => { handlePickUpInputs(e, "room") }} />
          </div>}

          {(serviceactiveVehicleType?.setting?.isAddressDetailsActive && orderTypeActive == "PickupDelivery") &&
            <Input type={"text"} placeHolder={t("addressDetail")} value={addressDetail} inputStyle={{ width: "inherit" }} onChange={(e) => { handlePickUpInputs(e, "addressDetail") }} />
           }

          {serviceactiveVehicleType?.setting?.isDropoffAddressNameActive && <Input isRequired={serviceactiveVehicleType?.setting?.isDropoffAddressNameRequired} isRequiredText={t("Required")} type={"text"} placeHolder={t("Fullname")} value={senderFullName} onChange={(e) => { handlePickUpInputs(e, "fullName") }} />}


          {serviceactiveVehicleType?.setting?.isDropoffAddressPhoneActive && <div className={styles.PhoneInput}>
            <PhoneInput
              defaultCountry={global.config.Country}
              placeholder={t("Phonenumber")}
              value={senderPhoneNumber}
              onChange={(PrePhoneInput) => {
                phoneNumberOnChange(PrePhoneInput, "phone")
              }
              }
              className={clsx(styles.inputContainer, "senderphonenumberPickup")}
            />
            {serviceactiveVehicleType &&
              serviceactiveVehicleType.setting &&
              serviceactiveVehicleType.setting.isDropoffAddressPhoneRequired && (senderPhoneNumber ? String(senderPhoneNumber).trim().length == 0 : true) && (
                <span className={`inputRequired ${styles.PhoneInputRequired}`}>
                  {t("Required")}
                </span>
              )}
          </div>}

          {(serviceactiveVehicleType?.setting?.isDropoffAddressEmailActive ) && <Input isRequired={serviceactiveVehicleType?.setting?.isDropoffAddressEmailRequired} isRequiredText={t("Required")} error={Emailvalidate(email) ? "" : t("Emailisnotcorrect")} type={"text"} value={email} placeHolder={t("email")} onChange={(e) => { handlePickUpInputs(e, "email") }} />}
         {orderTypeActive != "PickupDelivery" && <Input type={"text"} value={CustomerDescription} placeHolder={t("notesfordriver")} onChange={(e) => { handlePickUpInputs(e, "customerDescription") }} />}

        </div>

      </div>

      {ActivescheduleSetting && <div className={styles.body}>
        <Typography text={t("SchedulePickup(Optional)")} weight="Body_Middle_Bold" className={styles.SmallTitle} style={{ margin: "0", padding: "0" }} />

        <Schedule orderType={orderTypeActive}
          scheduleDateBefore={scheduleDateBefore}
          scheduleDateAfter={scheduleDateAfter}
          activeVehicleType={serviceactiveVehicleType}
          activePickupDropOff={{type:"delivery"}}
          firstDropOff={first}
          id={id}
        />
      </div>}

    </div>
  )
}

export default DropoffInfoEditOrder