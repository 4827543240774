import wadeliverys from "../../../assets/wadelivery_logotype.png";
import FavIcon from "../../../assets/PROJECTS/wadelivery.png";

export default function wadelivery() {
    
    return global.config = {
    "ProjectName": "wa! Delivery",
    "LogoType": wadeliverys,
    "BaseApi": "https://rest.wadelivery.com",
    "BaseSocket": "https://socket.wadelivery.com",
    "BaseTrack": "https://dashboard.wadelivery.com",
    "MapCenter": {
        "lat": "18.533517",
        "lng": "73.864845"
    },
    "Lang": "en",
    "Direction": "ltr",
    "AllLanguages": [{
        "lang": "en",
        "direction": "ltr",
        "i18Lang": "English"
    }],
    "FavIcon": FavIcon,
    "Country": "IN",
    "thirdPartyLoginSupport": true  ,
    mapType :"osm", /* osm | google */ 
    googleApiKey : ""
} }