import styles from './style.module.css';
import {SearchInput} from './components/search-input';
import {SearchSort} from './components/search-sort';

export const SearchBar = () => {
    
    return <div className={styles.SearchBar}>

       <SearchInput />
       <SearchSort />

    </div>

};




