import clsx from 'clsx';
import { useEffect, useRef } from 'react';
import { useState } from 'react';
import styles from './style.module.css';
import chevron from '../../../assets/icon-chevron-right.png';
import { SearchInput } from './components/search-input';
import { DriversTabs } from './components/drivers-tabs';
import { DriversList } from './components/drivers-list';
import { useTranslation } from 'react-i18next';
import { SetDriverListOpenDialoge, SetDriverStatistics, SetSelectedMonitoringDriver } from '../../../Redux/actions/actionNames';
import { useDispatch, useSelector } from 'react-redux';
import DriverInfo from './components/driverInfo';
import { UpdateDriversStatistics } from '../../../utils/update-driverStatistics';
import { DriversListHeader } from './components/header';
import IconProvider from '../../../design-system/lib/IconProvider';
import Typography from '../../../design-system/lib/Typography';
import Button from '../../../design-system/lib/Button';
import { DRIVER, DRIVER_1 } from '../../../assets';
import { GetDriverListData } from '../../../hooks/GetDriverListData';


export const DriverList = ({ className, style }) => {


  const openViewOrder = useSelector((state) => state.ViewTravel.OpenDialoge)
  const openNewOrder = useSelector((state) => state.NewOrder.OpenDialoge)
  const openDialoge = useSelector((state) => state.DriversList.openDialoge)
  const [toggleTextHeight, setToggleTextHeight] = useState(0);
  const ref = useRef(null);
  const { t, i18n } = useTranslation();
  const regex = useSelector((state) => state.DriversList.regex)
  const isOnline = useSelector((state) => state.DriversList.isOnline)
  const activeDriver = useSelector((state) => state.DriversList.activeDriver)
  const DriverlistLoading = useSelector((state) => state.DriversList.DriverlistLoading)
  const onlineCount = useSelector((state) => state.DriversList.stats.isOnline)
  const offlineCount = useSelector((state) => state.DriversList.stats.isOffline)
  let { data } = GetDriverListData({ cursor:"lastOnlineTime", cursorSort:"ascending" })

  const dispatch = useDispatch();


 useEffect(() => {
    setToggleTextHeight(ref.current.clientWidth);
   }, []);


  useEffect(() => {
    // if (!openDialoge) {
    //   Initial()
    // }

    Initial()


  }, [openDialoge])


  async function Initial() {

    UpdateDriversStatistics()


    // let params = {
    //   page: 1,
    //   perpage: 40,
    //   isOnline: isOnline,
    //   regexText: String(regex).length > 0 ? String(regex) : null
    // }
    // dispatch(
    //   SetDriverListloadingOrderedPage({
    //     DriverlistLoading: true,
    //     DriverlistPage: 1,
    //     Driverlist: [],
    //     canLoadmore: false
    //   })
    // );
    // let Driver = new DriverApi()
    // let data = await Driver.List(removeNull(params))
    // dispatch(SetDriverListData(data.data, true))
    // dispatch(
    //   SetDriverListloadingOrderedPage({
    //     DriverlistLoading: false,
    //     DriverlistPage: 1,
    //     canLoadmore: data.data.length == 40
    //   })
    // );
  }


  function handleOpenCloseDialoge() {
    if (openDialoge) {
      dispatch(SetSelectedMonitoringDriver(null))
    }
    dispatch(SetDriverListOpenDialoge(!openDialoge))
  }



  return <div className={clsx(styles.driverListContainer, !openDialoge && styles.driverListOpen, (openViewOrder || openNewOrder) && styles.fullHeight, className)} style={style ? style : {}}  >
    {/* <DriversListHeader /> */}
    <div
      onClick={() => handleOpenCloseDialoge()}
      className={styles.toggle}
    >
      <img src={chevron} alt="" className={clsx(styles.toggleIcon, !openDialoge && styles.toggleIconOpen)} />
      <span ref={ref} className={styles.toggleText} style={{ height: `${toggleTextHeight}px`, marginInlineStart: `-${toggleTextHeight - 23}px` }}>
        {t("Drivers")}
      </span>

    </div>


  {(onlineCount != 0 || offlineCount != 0) &&  <div style={{ width: "100%", height: "100%", float: "left", backgroundColor: "white" }}>
      <SearchInput />
      <DriversTabs />

      {(data?.length != 0 && regex.length > 0) && <span className={styles.Searchresult}>
        {t("Searchresult")}
      </span>}

      <DriversList />

      {activeDriver && <DriverInfo />}
    </div>}

    {(!DriverlistLoading && (onlineCount == 0 && offlineCount == 0)) &&<div style={{ width: "100%", height: "100%", float: "left", backgroundColor: "white", border: "1px solid #f4f4f7" }}>
      <div className='jojojoj'
        style={{ display: 'flex', position: 'relative', height: `auto`, width: "100%", flexDirection: 'column', transition: "all .3s ease-in-out", justifyContent: "start", alignItems: "center", marginTop: "54px" }}>
        <div className={styles.dialog}>
          <IconProvider Icon={DRIVER_1} size='32' style={{ marginBottom: "16px" }} />
          <Typography weight='Body_Middle_Bold' text='No Driver' style={{ textAlign: "center", marginBottom: "4px" }} />
          <Typography weight='Body_Tiny_Regular' text='Add your driver in admin center' style={{ textAlign: "center", marginBottom: "16px" }} />
          {/* <Button size='Medium' type='Primary' text='Add Driver' onClick={()=>{ }} /> */}
        </div>
      </div>
    </div>}

  </div>

};






export const removeNull = (obj) => {
  console.log("removeNull ", obj)
  for (var key in obj) {
    if (obj[key] === null || !obj[key]) {
      delete obj[key];
    } else if (typeof obj[key] === 'object') {
      removeNull(obj[key]);
    }
  }
  return obj;
}