import { useState, useEffect, useRef, useCallback } from "react";

import styles from './style.module.css';
import clsx from 'clsx';
import { get_tab_orderTypes } from "../../../../../helper/UserData/userdate";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { SetOrderListFiltersDueTime, SetOrderListFiltersServices, SetOrderListFiltersStatus, SetOrderListFiltersVehicleTypes, SetOrderListFiltersZone, Set_OrderList_OrderTypes } from "../../../../../Redux/actions/actionNames";
import { UpdateOrdersStatistics } from "../../../../../utils/update-ordersStatistics";
import { useGetFiltersList } from '../../../../../hooks/useGetFiltersList';
import _, { debounce } from "lodash";

export const OrderTypeFilter = () => {
    const stats = useSelector((state) => state.OrderList.stats.data)
    let { cursor, cursorSort, DateRange, selectedServices, selectedStatus, selectedVehicleType, isScheduled, changed, zones ,now} = useGetFiltersList()

    const ref = useRef(null);
    const dispatch = useDispatch();
    const [refWidth, setRefWidth] = useState(10);
    const [thisorderTypes, setThisorderTypes] = useState(10);

    const [activeTabIndex, setActiveTabIndex] = useState(-1);
    const [activeTab, setActiveTab] = useState(null);
    const orderTypes = get_tab_orderTypes();
    const ActiveOrderType = useSelector((state) => state.OrderList.ActiveOrderType)

    // const debouncedSave = useCallback(
    //     debounce(() => UpdateOrdersStatistics(), 100),
    //     [], 
    // );

    useEffect(() => {
        
        if(!_.isEqual( orderTypes, thisorderTypes)){
            setThisorderTypes(orderTypes);
            // handleTab(0, orderTypes[0])
        }
        
    }, [orderTypes])


    useEffect(() => {
        setThisorderTypes(orderTypes);  
    }, [])

    
    useEffect(() => {
        // debouncedSave()
        UpdateOrdersStatistics()
    }, [cursor, cursorSort, DateRange, selectedServices, selectedStatus, selectedVehicleType, isScheduled, changed, zones ,now])

    useEffect(() => {
        setRefWidth(ref.current ? ref.current.offsetWidth : 10)
    })

    function handleTab(i, item) {
        dispatch(SetOrderListFiltersZone([]))
        dispatch(SetOrderListFiltersStatus([]))
        dispatch(SetOrderListFiltersServices([]))
        dispatch(SetOrderListFiltersDueTime({key : "now", value : false }))
        dispatch(SetOrderListFiltersDueTime({key : "isScheduled", value : false }))
        dispatch(SetOrderListFiltersVehicleTypes([]))

        
        setActiveTab(item)
        setActiveTabIndex(i);
        dispatch(Set_OrderList_OrderTypes(item))
        UpdateOrdersStatistics({ type: item })
    }


    return <>
    </>

    // if (orderTypes.length == 1) {
    //     return null
    // } else {
    //     return <div className={styles.frame} ref={ref} style={{ left: `calc(50vw - ${refWidth / 2}px)` }}>

    //         {orderTypes.map((item, i) => {
    //             return <OrderTypeItem key={i} ActiveOrderType={ActiveOrderType} name={item} stats={stats} index={i} count={10} activeTab={activeTab} setActiveTab={handleTab} />
    //         })}

    //     </div>
    // }


};




export const OrderTypeItem = ({ name, index, count, activeTab, setActiveTab, ActiveOrderType }) => {
    const isActive = ActiveOrderType == name ? true : false;
    const { t, i18n } = useTranslation();

    const handleClick = () => {
        setActiveTab(index, name);
    };

    return <div className={styles.itemContainer} onClick={handleClick}>

        <div className={styles.itemButtonContainer}>

            <div className={styles.itemFrame}>
                <span className={clsx(styles.itemText, isActive ? styles.itemTextActive : null)}> {t("tab_" + name)} </span>

                {/* {stats && <div className={clsx(styles.itemStatus, isActive ? styles.itemStatusActive : null, styles.itemStatusSpan)}>
                    {stats[name]}
                </div>} */}

            </div>
        </div>

        {<div className={clsx(styles.itemBorder, isActive ? styles.itemBorderActive : null)} />}



    </div>


};