import { SetCategoryOrderListData, SetCategoryOrderListLoading, SetSearchOrderListRegexLoading, SetSearchOrderListRegexRequested } from "../../../../../../Redux/actions/actionNames";
import { store } from "../../../../../../Redux/store";
import { OrderApi } from "../../../../../../service/API/order";
import momentJalaali from "moment-jalaali";
import { FilterStatusByCategory } from "../../../../../../utils/filter-status-by-category";
const _ = require("lodash");

export const getCategoryList = async (categoryName, Listdata, loadMore , patch={}) => {
  let lastElement = _.last(Listdata);


  let storeData = store.getState();

  let selectedStatus = JSON.parse(storeData.OrderList.selectedStatus)
  let selectedServices = JSON.parse(storeData.OrderList.selectedServices)
  let selectedVehicleType = JSON.parse(storeData.OrderList.selectedVehicleType)
  let selectedZone = JSON.parse(storeData.OrderList.zones)
  const pureData = storeData.OrderList.list[categoryName].data

  let dataLength = Object.keys(pureData).length

  let status =  FilterStatusByCategory( categoryName , selectedStatus)
  

  if(selectedStatus.length > 0 && status.length == 0 ){
    store.dispatch(SetCategoryOrderListData({ key: categoryName, loadMore, dataLength: 0,orders:[],nextCursor:null,endCursor:null , startCursor:null}))
    store.dispatch(SetCategoryOrderListLoading({ key: categoryName, loading: false, error: false }))

  }else{
    store.dispatch(SetCategoryOrderListLoading({ key: categoryName, loading: true, error: false }))

    
  // let regex = [] ;

  // if(storeData.OrderList.search.regexText && String(storeData.OrderList.search.regexText).length > 0){
  //   storeData.OrderList.search.regexFields.value.forEach(element => {
  //     regex.push({text:storeData.OrderList.search.regexText,field:element})
  //     });
  // }
     let params = {
    cursor: storeData.OrderList.cursor,
    cursorSort: storeData.OrderList.cursorSort,
    "dueDate[gte]": storeData.OrderList.DateRange
      ? momentJalaali(storeData.OrderList.DateRange.startDate).unix() + "000"
      : null,
    "dueDate[lte]": storeData.OrderList.DateRange
      ? (storeData.OrderList.DateRange.endDate ? momentJalaali(storeData.OrderList.DateRange.endDate).unix() + "000" : null)
      : null,
    services: selectedServices,
    zones : selectedZone,
    status: status ,
    vehicleTypes: selectedVehicleType,
    isScheduled: ((storeData.OrderList.now && storeData.OrderList.isScheduled) || (!storeData.OrderList.now && !storeData.OrderList.isScheduled) ) ? null :  (   (storeData.OrderList.now && !storeData.OrderList.isScheduled ) ? false :  true),
    size: Math.max(dataLength, 4),
    // regexText: storeData.OrderList.search.regexText,
    // regexFields: String(storeData.OrderList.search.regexText).length > 0 ? storeData.OrderList.search.regexFields.value : [],
    category: categoryName,
    // regex : regex.length > 0 ? regex : null,
    type: storeData.OrderList.ActiveOrderType,
    // lastCursor: lastElement ? lastElement[storeData.OrderList.cursor] : null,
    after : loadMore ?  (storeData.OrderList.list[categoryName].hasNextPage ? storeData.OrderList.list[categoryName].endCursor : null):null ,
    columns: ['status', 'isUrgent', 'customerId', 'driver', 'pickup', 'dropoff', 'dropoffs', 'delivery', 'isScheduled', 'currentCandidateDrivers', 'driverAssignmentModel', 'driverId', 'delayAt', 'baseETA', 'realETA', 'isPinned', 'isDelayed', 'isPossibleForDelay', 'candidateDriversCount', 'dispatchAt', 'isDispatching', 'vehicleType'],
    ...patch
  }

  console.log("55params ",params)

  // if (String(params.regexText).length > 2) {
  //   store.dispatch(SetSearchOrderListRegexRequested(true))
  //   store.dispatch(SetSearchOrderListRegexLoading(true))
  // }
  let Order = new OrderApi()
  let data = await Order.OrdersList(removeNull(params))
  store.dispatch(SetCategoryOrderListData({ key: categoryName, loadMore :  loadMore ?  (storeData.OrderList.list[categoryName].hasNextPage ? true : false): false, dataLength: data.orders.length, ...data }))
  store.dispatch(SetCategoryOrderListLoading({ key: categoryName, loading: false, error: false }))
  // if (String(params.regexText).length > 2) {
  //   store.dispatch(SetSearchOrderListRegexLoading(false))
  // }
  }


 

};

const removeNull = (objIn) => {
  let objOut = objIn;
  Object.keys(objOut).forEach(key => {
    if (objOut[key] === null) {
      delete objOut[key];
    }
  });
  return objOut
}