import React, { useCallback, useRef, useState } from 'react'
import styles from './style.module.css';
import { useSelector } from 'react-redux';
import Button from '../../../design-system/lib/Button';
import { BARCODE, CLOSE, Edit, LINK_CIRCLE, MORE, PATH, RECEIPT, ROUTE_OPTIMIZE, TIMER, box_gray, trash } from '../../../assets';
import Typography from '../../../design-system/lib/Typography';
import { get_lang } from '../../../helper/UserData/userdate';
import jalali from "jalali-moment";
import Tag from '../../../design-system/lib/Tag';
import { ClickAwayListener, Paper, Popper } from '@material-ui/core';
import IconProvider from '../../../design-system/lib/IconProvider';
import { Link } from "react-router-dom";


function HeaderReorder({ data , setData , setIsreorder}) {
  const [prevData, setPrevData] = useState(data);


  const handleBack = React.useCallback(() => {
    setData(prevData)
    setIsreorder(false)
  }, [prevData]);


  return (
    <div className={styles.container} >
      <div className={styles.firstrow}>

        <Button icon={CLOSE} size='Medium' type='Secondary' onClick={handleBack} />

        <div className={styles.titleDescriptionContainer}>
          <Typography weight='Body_Middle_Bold' text={"Reorder stops"} />
        </div>
      </div>


    </div>
  )
}


export default HeaderReorder