import { store } from "../../Redux/store";
import {
  OrderInfoAction,
  OrderGetEventsAction,
} from "../../Redux/actions/actions";
import { get_id } from "../../helper/UserData/userdate";
import {
  OpenCloseEditTravel,
  ChangeViewTravelDialoge,
  ResetViewTravel,
  ResetNewFav,
  Edit_Travel_Reset,
  DeleteTravelInList,
} from "../../Redux/actions/actionNames";

export function CheckThisTravelIsActive(data, call) {
  const state = store.getState();
  if (state.ViewTravel.OpenDialoge && state.ViewTravel.ID == data.id) {
    store.dispatch(
      OrderInfoAction(store.dispatch, {
        orderId: data.id,
      })
    );
    call && call(true);
  } else {
    call && call(false);
  }
}

export function CheckThisTravelIsActiveWithOutOrder(data, call) {
  const state = store.getState();
  if (
    isOrderIsOpen(data.id) &&
    (state.ViewTravel.pureData
      ? state.ViewTravel.pureData.id == data.id
      : state.ViewTravel.ID == data.id)
  ) {
    if (state.ViewTravel.eventTimeLine.show) {
      store.dispatch(OrderGetEventsAction(store.dispatch, data.id));
      call && call(true);
    } else {
      call && call(true);
    }
  } else {
    call && call(false);
  }
}

export function DeleteOrderFromLIST(id) {
  const state = store.getState();
  if (
    (state.ViewTravel.OpenDialoge || state.EditOrder.OpenDialoge) &&
    state.ViewTravel.ID == id
  ) {
    //of edit page
    if (state.EditOrder.OpenDialoge) {
      try {
        store.dispatch(OpenCloseEditTravel());
        store.dispatch(Edit_Travel_Reset());

        setTimeout(() => {
          store.dispatch(ChangeViewTravelDialoge(false));
          store.dispatch(ResetViewTravel());
          store.dispatch(ResetNewFav());
        }, 500);
      } catch (error) {}
    } else {
      store.dispatch(ChangeViewTravelDialoge(false));
      store.dispatch(ResetViewTravel());
      store.dispatch(ResetNewFav());
    }

    //delete data
    store.dispatch(DeleteTravelInList(id));
  } else {
    //delete data
    store.dispatch(DeleteTravelInList(id));
  }
}




const isOrderIsOpen = (id) => {
  if (String(window.location.href).includes(id)) {
    return true
  } else {
    return false
  }
}