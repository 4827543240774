import { Component } from "react";
import { connect } from "react-redux";
import { CalculatePriceWorker } from "../../../../../../helper/worker/CalculatePriceWorker";
import { DetectOndemandOrReserve } from "../../../../../../helper/worker/DetectOndemandOrReserve";
import { CalculatePriceReserveWorker } from "../../../../../../helper/worker/CalculatePriceReserveWorker";
import momentJalaali from "moment-jalaali";
import { CanOrderCalculateTravelCost } from "../../../../../../helper/worker/CanOrderCalculateTravelCost";
import { SetEditRequestForceCalculateCost } from "../../../../../../Redux/actions/actionNames";

class App extends Component {

  componentDidMount() {
    if (!DetectIsSchedule(this.props.pickup) && !DetectIsSchedule(this.props.firstDropOff) ) {

      CalculatePriceWorker(
        {
          customerType:this.props.person ? "individual" :"business",
          orderId : this.props.id,
          // surcharges: this.props.surcharges.map((item) => {
          //   return { price: Number(item.price), id: item.id };
          // }),
          serviceactiveVehicleType: this.props.serviceactiveVehicleType,
          serviceoptions: this.props.serviceoptions,
          auto: this.props.auto,
          driveroptions: this.props.driveroptions,
          auto_activeVehicleType: this.props.auto_activeVehicleType,
          manual_activeVehicleType:
            this.props.manual_activeVehicleType,
          pickup: {
            address: this.props.pickup.address,
          },
          pickupadresss_loc: this.props.pickupadresss_loc,
          firstDropOff: {
            address: this.props.firstDropOff.address,
          },
          firstDropOffadresss_loc: this.props.firstDropOffadresss_loc,
          otherdropOffs: this.props.otherdropOffs,
        },
        this.props.dispatch
      );
    } else {

      CalculatePriceReserveWorker(
        {
          customerType:this.props.person ? "individual" :"business",
          orderId : this.props.id,
          // surcharges: this.props.surcharges.map((item) => {
          //   return { price: Number(item.price), id: item.id };
          // }),
          serviceactiveVehicleType: this.props.serviceactiveVehicleType,
          serviceoptions: this.props.serviceoptions,
          auto: this.props.auto,
          driveroptions: this.props.driveroptions,
          auto_activeVehicleType: this.props.auto_activeVehicleType,
          manual_activeVehicleType:
            this.props.manual_activeVehicleType,
          pickup: {
            address: this.props.pickup.address,
            scheduleDateAfter: momentJalaali(
              this.props.pickup.scheduleDateAfter
            ).isValid()
              ? momentJalaali(
                  Number(this.props.pickup.scheduleDateAfter)
                ).unix()
              : 0,
            scheduleDateBefore: momentJalaali(
              this.props.pickup.scheduleDateBefore
            ).isValid()
              ? momentJalaali(
                  Number(this.props.pickup.scheduleDateBefore)
                ).unix()
              : 0,
          },
          pickupadresss_loc: this.props.pickupadresss_loc,
          firstDropOff: {
            address: this.props.firstDropOff.address,
            scheduleDateAfter: momentJalaali(
              this.props.firstDropOff.scheduleDateAfter
            ).isValid()
              ? momentJalaali(
                  Number(this.props.firstDropOff.scheduleDateAfter)
                ).unix()
              : 0,
            scheduleDateBefore: momentJalaali(
              this.props.firstDropOff.scheduleDateBefore
            ).isValid()
              ? momentJalaali(
                  Number(this.props.firstDropOff.scheduleDateBefore)
                ).unix()
              : 0,
          },
          firstDropOffadresss_loc: this.props.firstDropOffadresss_loc,
          otherdropOffs: this.props.otherdropOffs.map((drop) => {
            return {
              ...{ address: drop.address },
              ...{ adresss_loc: drop.adresss_loc },
              ...{ block: drop.block },
              ...{ floor: drop.floor },
              ...{ id: drop.id },
              ...{ recieverFullName: drop.recieverFullName },
              ...{ recieverPhoneNumber: drop.recieverPhoneNumber },
              ...{ unit: drop.unit },
              ...{
                scheduleDateAfter: momentJalaali(
                  drop.scheduleDateAfter
                ).isValid()
                  ? momentJalaali(
                      Number(drop.scheduleDateAfter)
                    ).unix()
                  : 0,
              },
              ...{
                scheduleDateBefore: momentJalaali(
                  drop.scheduleDateBefore
                ).isValid()
                  ? momentJalaali(
                      Number(drop.scheduleDateBefore)
                    ).unix()
                  : 0,
              },
            };
          }),
        },
        this.props.dispatch
      );
    }
  }

  componentWillReceiveProps(nextProps) {
    CanOrderCalculateTravelCost(
      {
        pickupadresss_loc: this.props.pickupadresss_loc,
        firstDropOffadresss_loc: this.props.firstDropOffadresss_loc,
        otherdropOffs: this.props.otherdropOffs.map((item)=>{
          return{ ...item.adresss_loc }
        }),
      },
      {
        pickupadresss_loc: nextProps.pickupadresss_loc,
        firstDropOffadresss_loc: nextProps.firstDropOffadresss_loc,
        otherdropOffs: nextProps.otherdropOffs.map((item)=>{
          return{ ...item.adresss_loc }
        }),
      },
      (call) => {
        
        

        if (call || nextProps.forceCalculateCost) {
          nextProps.dispatch(SetEditRequestForceCalculateCost(false))

          if (!DetectIsSchedule(nextProps.pickup) && !DetectIsSchedule(nextProps.firstDropOff) ) {

            CalculatePriceWorker(
              {
                customerType:nextProps.person ? "individual" :"business",
                orderId : nextProps.id,
                // surcharges: nextProps.surcharges.map((item) => {
                //   return { price: Number(item.price), id: item.id };
                // }),
                serviceactiveVehicleType: this.props.serviceactiveVehicleType,
                serviceoptions: this.props.serviceoptions,
                auto: nextProps.auto,
                driveroptions: nextProps.driveroptions,
                auto_activeVehicleType: nextProps.auto_activeVehicleType,
                manual_activeVehicleType:
                  nextProps.manual_activeVehicleType,
                pickup: {
                  address: nextProps.pickup.address,
                },
                pickupadresss_loc: nextProps.pickupadresss_loc,
                firstDropOff: {
                  address: nextProps.firstDropOff.address,
                },
                firstDropOffadresss_loc: nextProps.firstDropOffadresss_loc,
                otherdropOffs: nextProps.otherdropOffs,
              },
              this.props.dispatch
            );
          } else {

            CalculatePriceReserveWorker(
              {
                customerType:nextProps.person ? "individual" :"business",
                orderId : nextProps.id,
                // surcharges: nextProps.surcharges.map((item) => {
                //   return { price: Number(item.price), id: item.id };
                // }),
                serviceactiveVehicleType: this.props.serviceactiveVehicleType,
                serviceoptions: this.props.serviceoptions,
                auto: nextProps.auto,
                driveroptions: nextProps.driveroptions,
                auto_activeVehicleType: nextProps.auto_activeVehicleType,
                manual_activeVehicleType:
                  nextProps.manual_activeVehicleType,
                pickup: {
                  address: nextProps.pickup.address,
                  scheduleDateAfter: momentJalaali(
                    nextProps.pickup.scheduleDateAfter
                  ).isValid()
                    ? momentJalaali(
                        Number(nextProps.pickup.scheduleDateAfter)
                      ).unix()
                    : 0,
                  scheduleDateBefore: momentJalaali(
                    nextProps.pickup.scheduleDateBefore
                  ).isValid()
                    ? momentJalaali(
                        Number(nextProps.pickup.scheduleDateBefore)
                      ).unix()
                    : 0,
                },
                pickupadresss_loc: nextProps.pickupadresss_loc,
                firstDropOff: {
                  address: nextProps.firstDropOff.address,
                  scheduleDateAfter: momentJalaali(
                    nextProps.firstDropOff.scheduleDateAfter
                  ).isValid()
                    ? momentJalaali(
                        Number(nextProps.firstDropOff.scheduleDateAfter)
                      ).unix()
                    : 0,
                  scheduleDateBefore: momentJalaali(
                    nextProps.firstDropOff.scheduleDateBefore
                  ).isValid()
                    ? momentJalaali(
                        Number(nextProps.firstDropOff.scheduleDateBefore)
                      ).unix()
                    : 0,
                },
                firstDropOffadresss_loc: nextProps.firstDropOffadresss_loc,
                otherdropOffs: nextProps.otherdropOffs.map((drop) => {
                  return {
                    ...{ address: drop.address },
                    ...{ adresss_loc: drop.adresss_loc },
                    ...{ block: drop.block },
                    ...{ floor: drop.floor },
                    ...{ id: drop.id },
                    ...{ recieverFullName: drop.recieverFullName },
                    ...{ recieverPhoneNumber: drop.recieverPhoneNumber },
                    ...{ unit: drop.unit },
                    ...{
                      scheduleDateAfter: momentJalaali(
                        drop.scheduleDateAfter
                      ).isValid()
                        ? momentJalaali(
                            Number(drop.scheduleDateAfter)
                          ).unix()
                        : 0,
                    },
                    ...{
                      scheduleDateBefore: momentJalaali(
                        drop.scheduleDateBefore
                      ).isValid()
                        ? momentJalaali(
                            Number(drop.scheduleDateBefore)
                          ).unix()
                        : 0,
                    },
                  };
                }),
              },
              this.props.dispatch
            );
          }
        }
      }
    );
  }

  render() {
    return null;
  }
}

const mapStateToProps = (state) => ({
  forceCalculateCost : state.EditOrder.forceCalculateCost,
  person: state.EditOrder.editOrder.customer.person,
  serviceactiveVehicleType:
    state.EditOrder.editOrder.service.activeVehicleType,
  serviceoptions: state.EditOrder.editOrder.service.options,
  auto: state.EditOrder.editOrder.driver.auto,
  driveroptions: state.EditOrder.editOrder.driver.options,
  auto_activeVehicleType:
    state.EditOrder.editOrder.driver.auto_driver.auto_activeVehicleType ? state.EditOrder.editOrder.driver.auto_driver.auto_activeVehicleType : {},
  manual_activeVehicleType:
    state.EditOrder.editOrder.driver.manual_driver.manual_activeVehicleType ? state.EditOrder.editOrder.driver.manual_driver.manual_activeVehicleType : {},
  pickup: state.EditOrder.editOrder.pickup,
  pickupadresss_loc: state.EditOrder.editOrder.pickup.adresss_loc,
  firstDropOff: state.EditOrder.editOrder.dropOffs.firstDropOff,
  firstDropOffadresss_loc:
    state.EditOrder.editOrder.dropOffs.firstDropOff.adresss_loc,
  otherdropOffs: state.EditOrder.editOrder.dropOffs.otherDropOffs,
  surcharges: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.surcharges
    : [],

    id: state.EditOrder.editOrder.id,


});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(App);



function DetectIsSchedule(dropOff) {
  if ( (Number(dropOff.scheduleDateBefore) > 0 ) || ( Number(dropOff.scheduleDateAfter  )) > 0) {
    return true
  } else {
    return false

  }

}
