import styles from './style.module.css';

export const Save = ({onPress , changedData}) => {
    return <span onClick={()=>{onPress()}} className={styles.Save + " "+ (changedData && styles.SaveActive)}>Save</span>

};




