import { useSelector } from "react-redux";
import { Table_setData, Table_setStatus } from "../../../../Redux/actions/actionNames";
import { store } from "../../../../Redux/store";
import { restClient } from "../../../../service/API/axios";
import { removeEmptyObject } from "../../../shared/table/action/getTableOrderList";
const _ = require("lodash");

export const getTableOrderList = async (prev , next ) => {
  // let lastElement = _.last(Listdata);
    let storeData = store.getState();
    const table = storeData.Table.tableInit

    table?.toggleAllRowsSelected(false)



if(!storeData.Table.list.orders){
    store.dispatch(Table_setStatus({key:"isLoading" , value:true}))
}
try {
    const response = await restClient.request({
      method: 'get',
      url: "/api/dispatcher/order",
      params: removeEmptyObject(removeEmptyObject(removeNull({
        after:next ? storeData.Table.list.endCursor : null,
        before :prev ? storeData.Table.list.startCursor : null,
        "size": storeData.Table.perPage,
        // "status[]": "Pending",
        // "dueDate[start]": "1688224655137",
        // "dueDate[end]": "1706714255137",
        "type":storeData.OrderList.ActiveOrderType,
        "columns": ['customerId' , 'driver' , 'pickup' , 'dropoff' , 'dropoffs' , 'delivery' , 'isScheduled' , 'currentCandidateDrivers' , 'driverAssignmentModel' , 'driverId' , 'delayAt' , 'baseETA' , 'realETA' , 'isPinned' , 'isDelayed' , 'isPossibleForDelay' , 'candidateDriversCount' , 'dispatchAt' , 'isDispatching' , 'vehicleType' , 'isUrgent' , 'service' , 'routeName' , 'paymentMethod' , 'paymentSide' , 'paymentProvider' , 'codAmount' , 'note' , 'referenceId' , 'stage' , 'customer' , 'zone' , 'price' , 'trackOrder' , 'customerType' , 'estimatedDistanceInMeters' , 'estimatedDuration' , 'customerReceiptUrl' , 'source' , 'statusUpdatedAt' , 'shipmentLabelUrl']
      })))
    });
    store.dispatch(Table_setData(response.data.data))
    store.dispatch(Table_setStatus({key:"isLoading" , value:false}))
    store.dispatch(Table_setStatus({key:"page" , value:(next || prev) ? (next ? storeData.Table.page + 1 : storeData.Table.page - 1) : 1}))

} catch (error) {
  store.dispatch(Table_setStatus({key:"isLoading" , value:false}))
  store.dispatch(Table_setStatus({key:"hasError" , value:true}))

}




};



const removeNull = (objIn) => {
  let objOut = objIn;
  Object.keys(objOut).forEach(key => {
    if (objOut[key] === null) {
      delete objOut[key];
    }
  });
  return objOut
}