import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import CalculatePrice from "./calculatePrice";
import CanSaveOrder from "./canSaveOrder";
import CheckServiceByCustomer from "./checkServiceByCustomer";
import CheckVehicleByCustomer from "./checkVehicleTypeByCustomer";
import Header from "./components/header";
import Footer from "./components/footer";
import Passenger from "./components/passenger";
import Service from "./components/service";
import Pickup from "./components/pickup";
import Dropoff from "./components/dropoff";
import Driver from "./components/driver";
import COD from "./components/COD";
import Plus from "../../../../assets/iconfinder_plus_172525.svg";
import { withTranslation, Trans } from "react-i18next";
import Slide from "@material-ui/core/Slide";
import {
  Add_dropoff,
  Set_ActivePickupDropOff,
  Remove_dropoff,
} from "../../../../Redux/actions/actionNames";
import { ServicesVehicletypeAction } from "../../../../Redux/actions/actions";
import Addressbook from "./components/Addressbook";
import DropoffsContainer from "./components/dropoffs-container";
import ReferenceID from "./components/ReferenceID";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      idArrays: [],
      dropoffs: [],
    };
  }
  handleCollapse = (id) => {
    this.props.dispatch(Set_ActivePickupDropOff(id));
    this.setState({
      [id]: !this.state[id],
    });

    this.setState((state) => {
      let x = {};
      state.idArrays.map((id) => {
        x[id] = false;
      });

      return { ...x, [id]: !this.state[id] };
    });
  };

  setId = (id) => {
    
    this.setState((state) => {
      return { idArrays: [...state.idArrays, id + ""], [id]: false };
    });
  };

  addDropOff = () => {
    this.props.dispatch(Add_dropoff(this.idgenerator()));
  };

  idgenerator = () => {
    var number = Math.random(); // 0.9394456857981651
    number.toString(36); // '0.xtis06h6'
    var id = number.toString(36).substr(2, 9); // 'xtis06h6'
    return id.toString();
  };

  
  removeDropOff = (id) => {
    this.props.dispatch(Remove_dropoff(id));
  };
  
  
  componentDidMount() {
    // 
    // this.props.dispatch(ServicesVehicletypeAction(this.props.dispatch , this.props.center))
  }


  render() {
    return (
      <Slide
        direction="down"
        in={this.props.OpenDialoge}
        mountOnEnter
        unmountOnExit
      >
        <div className="NewOrder" style={{zIndex:10,direction:"var(--main-inverseDirection)"}}>
          <Header />

          <itemContainers>
            <Service />
            <Passenger />
            {["Delivery", "Pickup", "Ondemand"].indexOf(
              this.props.orderTypeActive
            ) > -1 && (
                <Driver
                  setId={this.setId}
                  handleCollapse={this.handleCollapse}
                  firstDropOff
                  addDropOff={this.addDropOff}
                />
              )}
            <div className="noboxshadow">
              {["PickupDelivery", "Pickup", "Ondemand"].indexOf(
                this.props.orderTypeActive
              ) > -1 && (
                  <Pickup
                    setId={this.setId}
                    handleCollapse={this.handleCollapse}
                  />
                )}


              {["PickupDelivery", "Delivery", "Ondemand"].indexOf(
                this.props.orderTypeActive
              ) > -1 && <DropoffsContainer setId={this.setId} removeDrop={this.removeDropOff}
                handleCollapse={this.handleCollapse} addDropOff={this.addDropOff} />}


              {/* {["PickupDelivery", "Delivery", "Ondemand"].indexOf(
                this.props.orderTypeActive
              ) > -1 && (
                  <Dropoff
                    id={"dropoff"}
                    setId={this.setId}
                    handleCollapse={this.handleCollapse}
                    last={this.props.dropoffs.length === 0}
                    {...this.props}
                    firstDropOff={true}
                    addDropOff={this.addDropOff}
                  />
                )}

              {["Ondemand"].indexOf(this.props.orderTypeActive) > -1 &&
                this.props.dropoffs.map((drop, i) => {
                  return (
                    <Dropoff
                      id={drop.id}
                      handleCollapse={this.handleCollapse}
                      {...drop}
                      number={i + 2}
                      last={i + 1 === this.props.dropoffs.length}
                      removeDrop={this.removeDropOff}
                      addDropOff={this.addDropOff}
                    />
                  );
                })} */}

              <div style={{display:"flex",flexDirection:"row-reverse",float:"left",width:"100%",justifyContent:"space-between"}}>
                <COD />
                
                {["Pickup", "Delivery", "Ondemand"].indexOf(
                this.props.orderTypeActive
              ) > -1 && <ReferenceID />}

                
              </div>
             
            </div>
          </itemContainers>
          <CalculatePrice />
          <CanSaveOrder />
          <CheckServiceByCustomer />
          <CheckVehicleByCustomer orderTypeActive={this.props.orderTypeActive} />
          <Footer />
          <Addressbook />
        </div>
      </Slide>
    );
  }
}

const mapStateToProps = (state) => ({
  person: state.NewOrder.newOrder.customer.person,
  OpenDialoge: state.NewOrder.OpenDialoge,
  dropoffs: state.NewOrder.newOrder.dropOffs.otherDropOffs,
  address: state.NewOrder.newOrder.dropOffs.firstDropOff.address,
  adresss_loc: state.NewOrder.newOrder.dropOffs.firstDropOff.adresss_loc,
  block: state.NewOrder.newOrder.dropOffs.firstDropOff.block,
  floor: state.NewOrder.newOrder.dropOffs.firstDropOff.floor,
  unit: state.NewOrder.newOrder.dropOffs.firstDropOff.unit,
  email: state.NewOrder.newOrder.dropOffs.firstDropOff.email,
  recieverFullName:
    state.NewOrder.newOrder.dropOffs.firstDropOff.recieverFullName,
  recieverPhoneNumber:
    state.NewOrder.newOrder.dropOffs.firstDropOff.recieverPhoneNumber,
  noteForDriver: state.NewOrder.newOrder.dropOffs.firstDropOff.noteForDriver,
  center: state.Map.center,
  scheduleDateAfter:
    state.NewOrder.newOrder.dropOffs.firstDropOff.scheduleDateAfter,
  scheduleDateBefore:
    state.NewOrder.newOrder.dropOffs.firstDropOff.scheduleDateBefore,

  list: state.Addressbook.list,
  orderTypeActive: state.NewOrder.newOrder.orderTypeActive,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
