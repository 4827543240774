import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styles from './style.module.css';
import useForceUpdate from 'use-force-update';
import { useEffect } from 'react';
import { ServicesApi } from '../../../../../service/API/service';
import { SetSharedDataVehicleTypes } from '../../../../../Redux/actions/actionNames';


export const VehicleType = ({ selectedVehicleType, ChangeVehicleType }) => {

    const services = useSelector((state) => state.SharedData.services)
    const vehicleTypes = useSelector((state) => state.SharedData.vehicleTypes)
    const { t, i18n } = useTranslation();
    const forceUpdate = useForceUpdate();
    const dispatch = useDispatch();


    function vehicleTypeIsSelected(vehicleType) {
        return selectedVehicleType.findIndex((ivehicleType) => { return ivehicleType == vehicleType.id }) > -1
    }

    function selectVehicleType(service) {
        let newVehicleTypes = selectedVehicleType

        if (vehicleTypeIsSelected(service)) {
            ChangeVehicleType(newVehicleTypes.filter((istatus) => { return istatus != service.id }))
            forceUpdate()
        } else {
            newVehicleTypes.push(service.id)
            ChangeVehicleType(newVehicleTypes)
            forceUpdate()
        }
    }


    useEffect(() => {
      if(services.length > 0 && vehicleTypes.length == 0){

        const fetchData = async () => {
            const Services = new ServicesApi();
            const data = await Services.VehicleType()
            dispatch(SetSharedDataVehicleTypes(data.VehicleTypes))

          }

        fetchData()
          
      }
    },[ services])

    return <div className={styles.container} >
        <p className={styles.filterTilte}>Vehicle type</p>
        <div className={styles.itemContainer}>

            {vehicleTypes.map((vehicleType, i) => {
                return <span key={i} onClick={()=>{selectVehicleType(vehicleType)}} className={styles.item + " " + (vehicleTypeIsSelected(vehicleType) && styles.activeItem)}> {vehicleType.title} </span>
            })}
        </div>

    </div>

};




