import FlytoCouriers from "../../../assets/FlytoCourier.png";
import FavIcon from "../../../assets/PROJECTS/FlytoCourierFavIcon.png";

export default function Flyto() {
    
    return  global.config = {
    "ProjectName": "Flyto",
    "LogoType": FlytoCouriers,
    "BaseApi": "https://rest.flyto.fi",
    "BaseSocket": "https://socket.flyto.fi",
    "BaseTrack": "https://admin.flyto.fi",
    "MapCenter": {
        "lat": "60.16945646726855",
        "lng": "24.936962147076756"
    },
    "Lang": "en",
    "Direction": "ltr",
    "AllLanguages": [{
        "lang": "en",
        "direction": "ltr",
        "i18Lang": "English"
    }],
    "FavIcon": FavIcon,
    "Country": "FI",
    "thirdPartyLoginSupport": true  ,
    mapType :"osm", /* osm | google */ 
    googleApiKey : ""
} }