import myWorker from "./test.worker";

export const ObjectToArray = (workerStatic , pureData ,setWorker, call) => {

  if (workerStatic) {
    workerStatic.terminate();
    workerStatic = new myWorker();
    setWorker(workerStatic);
    workerStatic.postMessage(pureData);
    workerStatic.addEventListener("message", (event) => {
      call(event.data);
    });
  } else {
    workerStatic = new myWorker();
    setWorker(workerStatic);
    workerStatic.postMessage(pureData);
    workerStatic.addEventListener("message", (event) => {
      call(event.data);
    });
  }
};
