import EaglePosts from "../../../assets/EaglePost_logotype.png";
import FavIcon from "../../../assets/PROJECTS/EaglePost.png";

export default function EaglePost() {
    
    return global.config = {
    "ProjectName": "Eagle Post",
    "LogoType": EaglePosts,
    "BaseApi": "https://rest.EaglePost.com",
    "BaseSocket": "https://socket.eaglepost.com",
    "BaseTrack": "https://track.EaglePost.com",
    "MapCenter": {
        "lat": "36.18881826183549",
        "lng": "44.009864343939064"
    },
    "Lang": "en",
    "Direction": "ltr",
    "AllLanguages": [{
        "lang": "en",
        "direction": "ltr",
        "i18Lang": "English"
    }],
    "FavIcon": FavIcon,
    "Country": "IQ",
    "thirdPartyLoginSupport": true  ,
    mapType :"osm", /* osm | google */ 
    googleApiKey : ""
} }