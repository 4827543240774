import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import box from "../../../../../../../../assets/box-copy.svg";
import { withStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import { withTranslation, Trans } from "react-i18next";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import Grow from "@material-ui/core/Grow";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { Add_Driver_Option_EditOrder, Remove_Driver_Option_EditOrder } from "../../../../../../../../Redux/actions/actionNames";
import { get_lang } from "../../../../../../../../helper/UserData/userdate";

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showpoper: false,
      active:false,
      selected:null
    };
    this.anchorEl = React.createRef();
  }

  canChangeOptions=()=>{
    let vehicleTypeId = this.props.auto
      ? this.props.auto_activeVehicleType.id
      : this.props.manual_activeVehicleType.id;
    if (this.props.OrderPureData.vehicleType.id != vehicleTypeId) {
        return true
    } else {
      return false
    }
  }

  handleactive = () => {
    if (this.optionisSaved()) {
      this.props.dispatch(Remove_Driver_Option_EditOrder(this.props.id))
    } else {
      this.props.dispatch(Add_Driver_Option_EditOrder({
        name:this.props.name,
        id:this.props.id,
        type:"boolean"
      }))
    }

  };


  optionisSaved() {
    let booll = false;
     booll = this.props.options.find((item) => {
      return item.id === this.props.id;
    });
    if(booll){
      return true
    }else{
      return false
    }
  }

  render() {
    const { t } = this.props;
    return (
      <React.Fragment >
        <clickableservice ref={this.anchorEl} id={this.props.id} onClick={()=>{this.canChangeOptions() && this.handleactive()}} className={`${this.optionisSaved() && "avtiveServiceOptions"}  ${!this.canChangeOptions() && "notallowed"}`}>
        {this.props.title}
        </clickableservice>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  options:state.ViewTravel.DriverAndStatus.options,
  lang :state.LocalData.lang ,

  OrderPureData: state.ViewTravel.pureData ? state.ViewTravel.pureData : null,
  auto: state.ViewTravel.DriverAndStatus.auto,
  auto_activeVehicleType:
    state.ViewTravel.DriverAndStatus.auto_driver.auto_activeVehicleType,
    manual_activeVehicleType:
    state.ViewTravel.DriverAndStatus.manual_driver.manual_activeVehicleType,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
