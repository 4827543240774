import React from 'react';
import { RestrictedAccessLayout } from '../layout/restricted-access';


function RestrictedAccess() {



    return <RestrictedAccessLayout />
}

export default RestrictedAccess;

