import { Component } from "react";
import { connect } from "react-redux";
import PickupDelivery from "./pickupDelivery";


class App extends Component {

  render() {
 
      return <PickupDelivery />

  }
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
