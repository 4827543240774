import React, { useState, useEffect } from "react";
import { withTranslation, Trans } from "react-i18next";
import { connect } from "react-redux";
import close from "../../assets/ic-close.svg";
import incoming from "../../assets/vuesax-bulk-call-incoming.svg";
import progress from "../../assets/ic-in-progress-voip.svg";
import { ChangeNewTravelDialoge, ChangeViewTravelDialoge, NewCustomerHandleChange, RequsetListRegex, RequsetListRegexFields, ResetNewFav, ResetNewOrder, ResetViewTravel, SetSearchOrderListRegexFields, SetSearchOrderListRegexText, SetVoipIncommingCall, SetVoipIncommingCallAction, Set_Passenger_Na_Fa_Ph, Set_TravelList, Set_TravelList_loading, Set_TravelList_page, rebuildNewOrder } from "../../Redux/actions/actionNames";
import { CustomerGetInfo, GetOrderListAction, Get_User_Recent_Address, ServicesVehicletypeAction } from "../../Redux/actions/actions";
import momentJalaali from "moment-jalaali";
import { get_id } from "../../helper/UserData/userdate";
import { isEmpty } from "lodash";
import { Get_Addressbook_list_Api } from "../../helper/api";
import { Redirect } from "react-router-dom";
import { getsearchList } from "../../components/main/order-list/components/search-handler-list/action/getsearchList";
import history from "../../service/history";



class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hide: false,
      InCommingCall: null
    };

    this.searchCustomerOrders = this.searchCustomerOrders.bind(this)
    this.searchDriverOrders = this.searchDriverOrders.bind(this)
    this.handleOnClose = this.handleOnClose.bind(this)
    this.handleOnCloseBtn = this.handleOnCloseBtn.bind(this)
    this.setCustomerToNewOrder = this.setCustomerToNewOrder.bind(this)

  }



  removePLus = (from) => {
    if (String(from).startsWith('+')) {
      return from.substring(1, this.state.InCommingCall.from.length)
    } else {
      return from
    }
  }

  isBulkImport = () => {
    if (String(window.location.href).includes("BulkImport")) {
      return true
    } else {
      return false
    }
  }


  isNewOrder = () => {
    if (String(window.location.href).includes("neworder")) {
      return true
    } else {
      return false
    }
  }


  searchCustomerOrders = () => {

    this.props.dispatch(SetSearchOrderListRegexFields({ value: ['all'], i18: 'all' }))
    this.props.dispatch(SetSearchOrderListRegexText(this.removePLus(this.state.InCommingCall.from)))

    this.props.dispatch(ResetNewOrder())
    this.props.dispatch(ResetNewFav());

    this.props.dispatch(SetVoipIncommingCallAction({
      type: "Search",
      Data: this.state.InCommingCall
    }))



    // this.props.dispatch(Set_TravelList([]));
    // this.props.dispatch(Set_TravelList_loading(1));

    // this.props.dispatch(RequsetListRegexFields({ value: "code|customerPhone|customerName|driverPhone|driverName|driverUsername|pickupDescription", i18: "all" }));
    // this.props.dispatch(RequsetListRegex(this.removePLus(this.state.InCommingCall.from)));

    // this.props.dispatch(
    //   GetOrderListAction(this.props.dispatch, {
    //     orderTypes: this.props.orderTypes,
    //     status: this.props.activeStatus.map((status) => status).join("|"),
    //     regexText: this.removePLus(this.state.InCommingCall.from),
    //     regexFields: "code|customerPhone|customerName|driverPhone|driverName|driverUsername|pickupDescription",
    //     page: 1,
    //     perpage: 10,
    //     services: this.props.AllActiveServices.map((service) => service.id).join(
    //       "|"
    //     ),
    //     vehicleTypes: this.props.AllActiveVehicletypes.map(
    //       (service) => service.id
    //     ).join("|"),
    //     startDate: this.props.DateRange
    //       ? momentJalaali(this.props.DateRange.startDate).unix() + "000"
    //       : null,
    //     endDate: this.props.DateRange
    //       ? (this.props.DateRange.endDate ? momentJalaali(this.props.DateRange.endDate).unix() + "000" : null)
    //       : null,
    //     userId: this.props.justMeFilter ? get_id() : null,
    //     [this.props.AdvancedSort.key]: this.props.AdvancedSort.value

    //   })
    // );
    // this.props.dispatch(Set_TravelList_page(1));
    this.props.dispatch(ResetViewTravel());
    history.push("/Ondemand");

    // this.setState({
    //   redirect: "/orders"
    // })

    // if (this.props.ViewOrderOpen) {
    //   setTimeout(() => {
    //     this.props.dispatch(ResetViewTravel());
    //   }, 400);
    //   this.setState({
    //     redirect: "/orders"
    //   })
    // }

    // if (this.isBulkImport()) {
    //   this.setState({
    //     redirect: "/orders"
    //   })
    // }
    this.handleOnClose()


    // getsearchList(null)
  }

  searchDriverOrders = () => {


    this.props.dispatch(ResetNewOrder())
    this.props.dispatch(ResetNewFav());

    this.props.dispatch(SetVoipIncommingCallAction({
      type: "Search",
      Data: this.state.InCommingCall
    }))
    // this.props.dispatch(Set_TravelList([]));
    // this.props.dispatch(Set_TravelList_loading(1));
    this.props.dispatch(SetSearchOrderListRegexFields({ value: ['all'], i18: 'all' }))

    // this.props.dispatch(RequsetListRegexFields({ value: "code|customerPhone|customerName|driverPhone|driverName|driverUsername|pickupDescription", i18: "all" }));
    // this.props.dispatch(RequsetListRegex(this.removePLus(this.state.InCommingCall.from)));
    this.props.dispatch(SetSearchOrderListRegexText(this.removePLus(this.state.InCommingCall.from)))

    // this.props.dispatch(
    //   GetOrderListAction(this.props.dispatch, {
    //     orderTypes: this.props.orderTypes,
    //     status: this.props.activeStatus.map((status) => status).join("|"),
    //     regexText: this.removePLus(this.state.InCommingCall.from),
    //     regexFields: "code|customerPhone|customerName|driverPhone|driverName|driverUsername|pickupDescription",
    //     page: 1,
    //     perpage: 10,
    //     services: this.props.AllActiveServices.map((service) => service.id).join(
    //       "|"
    //     ),
    //     vehicleTypes: this.props.AllActiveVehicletypes.map(
    //       (service) => service.id
    //     ).join("|"),
    //     startDate: this.props.DateRange
    //       ? momentJalaali(this.props.DateRange.startDate).unix() + "000"
    //       : null,
    //     endDate: this.props.DateRange
    //       ? (this.props.DateRange.endDate ? momentJalaali(this.props.DateRange.endDate).unix() + "000" : null)
    //       : null,
    //     userId: this.props.justMeFilter ? get_id() : null,
    //     [this.props.AdvancedSort.key]: this.props.AdvancedSort.value

    //   })
    // );
    // this.props.dispatch(Set_TravelList_page(1));

    this.props.dispatch(ResetViewTravel());
    history.push("/Ondemand");

    // this.setState({
    //   redirect: "/orders"
    // })



    // if (this.props.ViewOrderOpen) {
    //   setTimeout(() => {
    //     this.props.dispatch(ResetViewTravel());
    //   }, 400);
    //   this.setState({
    //     redirect: "/orders"
    //   })
    // }

    // if (this.isBulkImport()) {
    //   this.setState({
    //     redirect: "/orders"
    //   })
    // }


    this.handleOnClose()

  }

  componentDidMount = () => {
    this.setState({
      InCommingCall: this.props.InCommingCall,
      hide: !this.props.InCommingCall
    })

  }


  setCustomerToNewOrder(data) {

    if (this.state.InCommingCall.source == "customer") {
      this.props.dispatch(CustomerGetInfo(this.props.dispatch, data, (call) => {
        this.props.dispatch(Get_User_Recent_Address(this.props.dispatch, call.id, null))
        this.props.dispatch(
          Set_Passenger_Na_Fa_Ph({
            name: "type",
            value: call.type,
          })
        );
        this.props.dispatch(
          Set_Passenger_Na_Fa_Ph({
            name: "name",
            value: call.name,
          })
        );
        this.props.dispatch(
          Set_Passenger_Na_Fa_Ph({
            name: "phone",
            value: call.phone,
          })
        );
        this.props.dispatch(
          Set_Passenger_Na_Fa_Ph({
            name: "id",
            value: call.id,
          })
        );
        this.props.dispatch(
          Set_Passenger_Na_Fa_Ph({
            name: "balance",
            value: call.balance,
          })
        );


        this.props.dispatch(
          Set_Passenger_Na_Fa_Ph({
            name: "minimumBalance",
            value: call.minimumBalance,
          })
        );

        this.props.dispatch(
          Set_Passenger_Na_Fa_Ph({
            name: "email",
            value: call.email,
          })
        );

        this.props.dispatch(
          Set_Passenger_Na_Fa_Ph({
            name: "familyName",
            value: call.familyName,
          })
        );

        this.props.dispatch(
          Set_Passenger_Na_Fa_Ph({
            name: "givenName",
            value: call.givenName,
          })
        );



        Get_Addressbook_list_Api(
          this.props.dispatch,
          {
            customerId: call.id,
            page: 1,
            perpage: 20,
            searchRegex: "",
          },
          (call) => {
          }
        );



      }))
    } else if (this.state.InCommingCall.source == "newCustomer") {
      if (String(this.state.InCommingCall.from).startsWith("00")) {
        // this.props.dispatch(
        //   Set_Passenger_Na_Fa_Ph({
        //     name: "search_regex",
        //     value: "+" + this.state.InCommingCall.from.substring(2, this.state.InCommingCall.from.length),
        //   })
        // );


        this.props.dispatch(NewCustomerHandleChange({ key: "open", value: true }))
        this.props.dispatch(NewCustomerHandleChange({ key: "phone", value: "+" + this.state.InCommingCall.from.substring(2, this.state.InCommingCall.from.length) }))

      } else {
        // this.props.dispatch(
        //   Set_Passenger_Na_Fa_Ph({
        //     name: "search_regex",
        //     value: this.state.InCommingCall.from,
        //   })
        // );


        this.props.dispatch(NewCustomerHandleChange({ key: "open", value: true }))
        this.props.dispatch(NewCustomerHandleChange({ key: "phone", value: this.state.InCommingCall.from }))

      }


    }



  }

  componentWillReceiveProps(nextProps) {
    if (!this.state.InCommingCall) {

      this.setState({
        InCommingCall: nextProps.InCommingCall,
        hide: true
      }, () => {
        setTimeout(() => {
          this.setState({
            hide: false
          })
        }, 350);
      })

    } else if (!nextProps.InCommingCall) {
      this.setState({
        hide: true
      }, () => {
        setTimeout(() => {
          this.setState({
            InCommingCall: nextProps.InCommingCall,
          })
        }, 350);
      })
    } else if (nextProps.InCommingCall.id != this.state.InCommingCall.id) {
      this.setState({
        hide: true
      }, () => {
        setTimeout(() => {
          this.setState({
            InCommingCall: nextProps.InCommingCall,
            hide: false
          })
        }, 350)
      })
    }
  }

  NewTravelHandler = () => {

    if (this.isNewOrder()) {
      this.props.dispatch(rebuildNewOrder())

      setTimeout(() => {
        this.props.dispatch(ResetNewOrder())


        this.props.dispatch(SetVoipIncommingCallAction({
          type: "NewOrder",
          Data: this.state.InCommingCall
        }))
        this.setCustomerToNewOrder(this.state.InCommingCall.customerId)
        this.props.dispatch(ResetViewTravel());
        history.push("/neworder");
        this.handleOnClose()

      }, 100);
    } else {
      this.props.dispatch(ChangeViewTravelDialoge(false));
      this.props.dispatch(SetVoipIncommingCallAction({
        type: "NewOrder",
        Data: this.state.InCommingCall
      }))
      this.setCustomerToNewOrder(this.state.InCommingCall.customerId)
      this.props.dispatch(ResetViewTravel());
      history.push("/neworder");
      this.handleOnClose()
    }








  };


  handleOnClose() {
    this.props.dispatch(SetVoipIncommingCall(null))
  }


  handleOnCloseBtn() {
    this.props.dispatch(SetVoipIncommingCall(null))
    this.props.dispatch(SetVoipIncommingCallAction({
      type: null,
      Data: null
    }))
  }


  render() {


    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />
    }
    return (
      <React.Fragment>

        {this.state.InCommingCall && <div className={`voip_inApp_Banner_Container ${this.state.hide && "voip_inApp_Banner_Container_Hide"}`}>
          <img src={close} className="voip_close" onClick={this.handleOnCloseBtn} />
          <img src={incoming} className="voip_incoming_svg" />
          {!isEmpty(this.state.InCommingCall.name) && <p className="voip_name"> {this.state.InCommingCall.name} </p>}
          <p className="voip_phone"> {this.state.InCommingCall.from} </p>
          <div className="voip_flex_hor_container">
            {this.state.InCommingCall.source == "driver" && <p className="voip_user_type_driver"> {this.props.t("driver")}</p>}
            {this.state.InCommingCall.source == "customer" && <p className="voip_user_type_customer"> {this.props.t("Passenger")}</p>}
            {this.state.InCommingCall.source == "newCustomer" && <p className="voip_user_type_customer_new"> {this.props.t("new")}</p>}

            {this.state.InCommingCall.activeOrdersCount > 0 && <span className="voip_active_orders"> <img src={progress} />  {String(this.props.t("activeorders").replace("xxx", this.state.InCommingCall.activeOrdersCount))}</span>}
            {this.state.InCommingCall.source != "newCustomer" && (this.state.InCommingCall.activeOrdersCount == 0 && <span className="voip_noactive_orders">  {this.props.t("Noactiveorder")}</span>)}

            {(this.state.InCommingCall.source == "customer" || this.state.InCommingCall.source == "newCustomer") && <span className="voip_action_base_first" onClick={this.NewTravelHandler}> {this.props.t("CreateOrder")} </span>}
            {(this.state.InCommingCall.source == "customer" || this.state.InCommingCall.source == "driver") &&

              <span className="voip_action_base_second" onClick={this.state.InCommingCall.source == "driver" ? this.searchDriverOrders : this.searchCustomerOrders}> {this.props.t("Orders")}</span>

            }
          </div>

        </div>}

      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  // orderTypes: state.OrderList.ActiveOrderType,
  // activeStatus: state.LocalData.orderList.activeStatus,
  // AllActiveVehicletypes: state.LocalData.orderList.AllActiveVehicletypes,
  // AllActiveServices: state.LocalData.orderList.AllActiveServices,
  // DateRange: state.LocalData.orderList.DateRange,
  // justMeFilter: state.LocalData.orderList.justMeFilter,
  // AdvancedSort: state.LocalData.orderList.AdvancedSort,
  InCommingCall: state.Voip.InCommingCall,
  ViewOrderOpen: state.ViewTravel.OpenDialoge,
  NewOrderOpenDialoge: state.NewOrder.OpenDialoge,
  ViewTravelOpenDialoge: state.ViewTravel.OpenDialoge,

});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
