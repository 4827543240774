import styles from './style.module.css';
import { SliderClose  , SliderOpens} from '../../../../../assets';


interface ISidebarSlider {
    setIsOpen : Function
    isOpen : boolean
  }
export const SidebarSlider = ({setIsOpen , isOpen}:ISidebarSlider) => {


    return <div className={styles.SidebarSlider}>
        <img src={isOpen ? SliderClose : SliderOpens} onClick={()=>{setIsOpen(!isOpen)}}/>

    </div>

};




