import { LoginApi } from "../../service/API";
import { LocalData } from "../../service/localStorage";
const loginApi = new LoginApi();
const localData = new LocalData();

export const LoginAction = async ({username, password}) => {
  try {

    // {userId , accessToken, refreshToken , name , username}
    const data = await loginApi.login({username , password});
    localData.set('adminToken' , data.adminToken);
    localData.set('accessToken' , data.accessToken);
    localData.set('id' , data.userId);
    localData.set('refreshToken' , data.refreshToken);
    localData.set('username' , data.username);
    localData.set('name' , data.name);;

    return {
     ...data
    }

  } catch (error) {

    return Promise.reject(error);
   
  }
  
};




