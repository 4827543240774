import Axios from "axios";
import { getCategoryList } from "../../components/main/order-list/components/category-collapse-list/action/getCategoryList";
import { RefreshToken } from "../../helper/api";
import { get_id, get_refreshToken, set_name, set_username } from "../../helper/UserData/userdate";
import { OrderInfoAction } from "../../Redux/actions/actions";
import { store } from "../../Redux/store";
import { restClient } from "./axios";
import { snack } from "../../utils/SnackbarUtilsConfigurator";
const queryString = require('query-string');

let cancelToken
export class OrderApi {

  async Info(id) {
    try {

      // if (typeof cancelToken != typeof undefined) {
      //   cancelToken.cancel("Operation canceled due to new request.")
      // }
      // cancelToken = Axios.CancelToken.source()


      const response = await restClient.request({
        method: 'get',
        url: `/api/v1/dispatcher/order/${id}`
      });


      return response.data.data

    } catch (error) {
     if(error.response){
      if (error.response.status === 403) {
        localStorage.clear()
        window.location.reload();
      } else if (error.response) {
        return Promise.reject({
          code: error.response.data.code,
          message: error.response.data.message,
          status: error.response.status,
        })
      } else {
        return Promise.reject()
      }
     }
    }


  }

  async LeanInfo(id) {
    try {

      // if (typeof cancelToken != typeof undefined) {
      //   cancelToken.cancel("Operation canceled due to new request.")
      // }
      // cancelToken = Axios.CancelToken.source()


      const response = await restClient.request({
        method: 'get',
        url: `/api/v1/dispatcher/order/${id}`,
        params: {
          lean:true
        },

      });


      return response.data.data

    } catch (error) {
     if(error.response){
      if (error.response.status === 403) {
        localStorage.clear()
        window.location.reload();
      } else if (error.response) {
        snack.warning(error.response.data.message)
        return Promise.reject({
          code: error.response.data.code,
          message: error.response.data.message,
          status: error.response.status,
        })
      } else {
        return Promise.reject()
      }
     }
    }


  }

  async Statistics(params) {
    try {

      // if (typeof cancelToken != typeof undefined) {
      //   cancelToken.cancel("Operation canceled due to new request.")
      // }
      // cancelToken = Axios.CancelToken.source()


      const response = await restClient.request({
        method: 'get',
        url: '/api/dispatcher/order/statistics',
        params: params,
        paramsSerializer: params => {
          return queryString.stringify(params , {arrayFormat: 'bracket'})
        },
        // cancelToken : cancelToken.token

      });


      return {
        "Pending": response.data.data.Pending,
        "Assigned": response.data.data.Assigned,
        "Canceled": response.data.data.Canceled,
        "Collecting": response.data.data.Collecting,
        "Delivering": response.data.data.Delivering,
        "Done": response.data.data.Done,
        "InProgress": response.data.data.InProgress,
        "Transit": response.data.data.Transit,
        "Delayed": response.data.data.Delayed,
        "Pickup": response.data.data.Pickup,
        "Delivery": response.data.data.Delivery,
        "PickupDelivery": response.data.data.PickupDelivery,
        "Ondemand": response.data.data.Ondemand,
        "Pinned": response.data.data.Pinned,
        "DispatchLater": response.data.data.DispatchLater,
        "Dispatching": response.data.data.Dispatching,
        "Unassigned": response.data.data.Unassigned

      }

    } catch (error) {
     if(error.response){
      if (error.response.status === 403) {
        localStorage.clear()
        window.location.reload();
      } else if (error.response) {
        return Promise.reject({
          code: error.response.data.code,
          message: error.response.data.message,
          status: error.response.status,
        })
      } else {
        return Promise.reject()
      }
     }
    }


  }

  async OrdersList(params) {
    try {

      const response = await restClient.request({
        method: 'get',
        url: '/api/dispatcher/order',
        params: params,
        paramsSerializer: params => {
          return queryString.stringify(params , {arrayFormat: 'bracket'})
        }
      });

      return {
        "orders": response.data.data.orders,
        "startCursor": response.data.data.startCursor,
        "endCursor": response.data.data.endCursor,
        "nextCursor": response.data.data.nextCursor,
        "hasNextPage": response.data.data.hasNextPage,
      }
      

    } catch (error) {
      if (error.response && error.response.status === 403) {
        localStorage.clear()
        window.location.reload();
      } else if (error.response) {
        return Promise.reject({
          code: error.response.data.code,
          message: error.response.data.message,
          status: error.response.status,
        })
      } else {
        return Promise.reject()
      }
    }


  }

  async SearchOrdersList(params) {
    try {

      const response = await restClient.request({
        method: 'get',
        url: '/api/dispatcher/order',
        params: params,
        paramsSerializer: params => {
          return queryString.stringify(params , {arrayFormat: 'bracket'})
        }
      });

      return {
        "orders": response.data.data.orders,
        "startCursor": response.data.data.startCursor,
        "endCursor": response.data.data.endCursor,
        "nextCursor": response.data.data.nextCursor,
      }

    } catch (error) {
      if (error.response && error.response.status === 403) {
        localStorage.clear()
        window.location.reload();
      } else if (error.response) {
        return Promise.reject({
          code: error.response.data.code,
          message: error.response.data.message,
          status: error.response.status,
        })
      } else {
        return Promise.reject()
      }
    }


  }

  async Pin(orderId) {
    try {

      const response = await restClient.request({
        method: 'patch',
        url: `/api/dispatcher/order/${orderId}/pin`,
        data: {
          dispatcherId :get_id()
        },
        paramsSerializer: params => {
          return queryString.stringify(params , {arrayFormat: 'bracket'})
        }
      });

      getCategoryList("Pinned")

      return {
        "data": response.data.data
      }

    } catch (error) {
      if (error.response && error.response.status === 403) {
        localStorage.clear()
        window.location.reload();
      } else if (error.response) {
        return Promise.reject({
          code: error.response.data.code,
          message: error.response.data.message,
          status: error.response.status,
        })
      } else {
        return Promise.reject()
      }
    }


  }

  async UnPin(orderId) {
    try {

      const response = await restClient.request({
        method: 'patch',
        url: `/api/dispatcher/order/${orderId}/unpin`,
        data: {
          dispatcherId :get_id()
        },
        paramsSerializer: params => {
          return queryString.stringify(params , {arrayFormat: 'bracket'})
        }
      });

      getCategoryList("Pinned")

      return {
        "data": response.data.data
      }

    } catch (error) {
      if (error.response && error.response.status === 403) {
        localStorage.clear()
        window.location.reload();
      } else if (error.response) {
        return Promise.reject({
          code: error.response.data.code,
          message: error.response.data.message,
          status: error.response.status,
        })
      } else {
        return Promise.reject()
      }
    }


  }


  async MarkUrgent(orderId) {
    try {

      const response = await restClient.request({
        method: 'patch',
        url: `/api/dispatcher/order/${orderId}/urgent`,
        data: {
          dispatcherId :get_id()
        }
      });

      store.dispatch(
        OrderInfoAction(store.dispatch, {
            orderId: orderId,
        })
    );
      return {
        "data": response.data.data
      }

    } catch (error) {
      if (error.response && error.response.status === 403) {
        localStorage.clear()
        window.location.reload();
      } else if (error.response) {
        return Promise.reject({
          code: error.response.data.code,
          message: error.response.data.message,
          status: error.response.status,
        })
      } else {
        return Promise.reject()
      }
    }


  }

  async DriverAccept(orderId , DriverId) {
    try {

      const response = await restClient.request({
        method: 'patch',
        url: `/api/dispatcher/order/${orderId}/driver-accept`,
        data: {
          dispatcherId :get_id(),
          orderCandidateDriverId:DriverId
        }
      });

      store.dispatch(
        OrderInfoAction(store.dispatch, {
            orderId: orderId,
        })
    );
      return {
        "data": response.data.data
      }

    } catch (error) {
      if (error.response && error.response.status === 403) {
        localStorage.clear()
        window.location.reload();
      } else if (error.response) {
        return Promise.reject({
          code: error.response.data.code,
          message: error.response.data.message,
          status: error.response.status,
        })
      } else {
        return Promise.reject()
      }
    }


  }


  async DriverReject(orderId , DriverId) {
    try {

      const response = await restClient.request({
        method: 'patch',
        url: `/api/dispatcher/order/${orderId}/driver-reject`,
        data: {
          dispatcherId :get_id(),
          orderCandidateDriverId:DriverId
        }
      });

      store.dispatch(
        OrderInfoAction(store.dispatch, {
            orderId: orderId,
        })
    );
      return {
        "data": response.data.data
      }

    } catch (error) {
      if (error.response && error.response.status === 403) {
        localStorage.clear()
        window.location.reload();
      } else if (error.response) {
        return Promise.reject({
          code: error.response.data.code,
          message: error.response.data.message,
          status: error.response.status,
        })
      } else {
        return Promise.reject()
      }
    }


  }

  async Monitoring(params) {
    try {

      const response = await restClient.request({
        method: 'get',
        url: `/api/dispatcher/order/monitoring`,
        params: params,
        // paramsSerializer: params => {
        //   return queryString.stringify(params , {arrayFormat: 'bracket'})
        // }
      });

      return {
        "data": response.data.data
      }

    } catch (error) {
      if (error.response && error.response && error.response.status === 403) {
        localStorage.clear()
        window.location.reload();
      } else if (error.response) {
        return Promise.reject({
          code: error.response.data.code,
          message: error.response.data.message,
          status: error.response.status,
        })
      } else {
        return Promise.reject()
      }
    }


  }


  async BatchUpdateStatus(data) {
    try {

      const response = await restClient.request({
        method: 'post',
        url: `/api/v1/dispatcher/order/pickup-delivery/batch/update-status`,
        data
      });


      return response.data.data

    } catch (error) {
      if (error.response && error.response.status === 403) {
        localStorage.clear()
        window.location.reload();
      } else if (error.response) {
        return Promise.reject({
          code: error.response.data.code,
          message: error.response.data.message,
          status: error.response.status,
        })
      } else {
        return Promise.reject()
      }
    }


  }


  async ShipmentLabelPdfExport(data) {
    try {

      const response = await restClient.request({
        method: 'post',
        url: `/api/v1/dispatcher/order/shipment-label/export-pdf`,
        data,
        headers:{
          responseType: 'blob'
        }
      });


      return response.data

    } catch (error) {
      if (error.response && error.response.status === 403) {
        localStorage.clear()
        window.location.reload();
      } else if (error.response) {
        return Promise.reject({
          code: error.response.data.code,
          message: error.response.data.message,
          status: error.response.status,
        })
      } else {
        return Promise.reject()
      }
    }


  }

}


