import HKerryExpressiO from "../../../assets/Kerry_logotype.png";
import FavIcon from "../../../assets/PROJECTS/Kerry.png";

export default function KerryExpress_Instance() {
    
    return  global.config = {
    "ProjectName": "Kerry Express",
    "LogoType": HKerryExpressiO,
    "BaseApi": "https://rest.automoto.ph",
    "BaseSocket": "https://socket.automoto.ph",
    "BaseTrack": "https://admin.automoto.ph",
    "MapCenter": {
        "lat": "14.574400731056794",
        "lng": "121.08529112288042"
    },
    "Lang": "en",
    "Direction": "ltr",
    "AllLanguages": [{
        "lang": "en",
        "direction": "ltr",
        "i18Lang": "English"
    }],
    "FavIcon": FavIcon,
    "Country": "PH",
    "thirdPartyLoginSupport": true  ,
    mapType :"osm", /* osm | google */ 
    googleApiKey : ""
}}