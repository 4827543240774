import { Component } from "react";
import { connect } from "react-redux";

import { SetCanSaveEditOrder, SetCanSaveTravel } from "../../../../../../Redux/actions/actionNames";
import { isEmpty } from "lodash";

class App extends Component {
  componentDidUpdate() {}

  componentWillReceiveProps(nextProps) {
    if (nextProps.price.done && this.firstDropOffRequiredCheck(nextProps) ) {
      nextProps.dispatch(
        SetCanSaveEditOrder(true)
      );
    } else {
      nextProps.dispatch(
        SetCanSaveEditOrder(false)
      );
    }
  }

  firstDropOffRequiredCheck = (props) => {
    if (props.serviceactiveVehicleType) {
      let name = false, email = false, phone = false;

      if (props.serviceactiveVehicleType.setting.isDropoffAddressNameRequired) {
        if (props.firstDropOff.fullName && String(props.firstDropOff.fullName).trim().length > 0) {
          name = true
        } else {
          name = false
        }
      } else {
        name = true
      }

      if (props.serviceactiveVehicleType.setting.isDropoffAddressPhoneRequired) {
        if (props.firstDropOff.phone && String(props.firstDropOff.phone).trim().length > 0) {
          phone = true
        } else {
          phone = false
        }
      } else {
        phone = true
      }


      if (props.serviceactiveVehicleType.setting.isDropoffAddressEmailRequired) {
        if (props.firstDropOff.email && String(props.firstDropOff.email).length > 0 && this.emailValidate(String(props.firstDropOff.email))) {
          email = true
        } else {
          email = false
        }
      } else {
        email = true
      }


      

      return (name && phone && email);

    } else {
      

      return false
    }

  }

  

  emailValidate(email) {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  render() {
    return null;
  }
}

const mapStateToProps = (state) => ({
  price: state.NewOrder.newOrder.price,
  customer: state.NewOrder.newOrder.customer,

  serviceactiveVehicleType: state.EditOrder.editOrder.service.activeVehicleType,
  serviceoptions: state.NewOrder.newOrder.service.options,
  auto: state.NewOrder.newOrder.driver.auto,
  driveroptions: state.NewOrder.newOrder.driver.options,
  auto_activeVehicleType:
    state.NewOrder.newOrder.driver.auto_driver.auto_activeVehicleType,
  manual_activeVehicleType:
    state.NewOrder.newOrder.driver.manual_driver.manual_activeVehicleType,
  pickup: state.EditOrder.editOrder.pickup,
  pickupadresss_loc: state.NewOrder.newOrder.pickup.adresss_loc,
  firstDropOff: state.EditOrder.editOrder.dropOffs.firstDropOff,
  firstDropOffadresss_loc:
    state.NewOrder.newOrder.dropOffs.firstDropOff.adresss_loc,
  otherdropOffs: state.EditOrder.editOrder.dropOffs.otherDropOffs,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
