import { useState } from 'react';
import { Language, Notifiaction, Settings } from '../../../../../assets';
import SidebarButton from '../../../../../design-system/lib/SidebarButton';
import styles from './style.module.css';
import { ClickAwayListener, withStyles } from '@material-ui/core';
import Backdrop from "@material-ui/core/Backdrop";
import { Paper } from 'newmaterial-ui';
import { rtlLtrCssPatchSelector } from '../../../../../utils';
import { get_lang, set_direction, set_lang } from '../../../../../helper/UserData/userdate';
import { restClient } from '../../../../../service/API/axios';
import { useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { ServicesApi } from '../../../../../service/API/service';
import { SetSharedDataServices, SetSharedDataVehicleTypes } from '../../../../../Redux/actions/actionNames';
import IconClose from "../../../../../assets/icon-close.svg";
import tik from "../../../../../assets/icon-ic-check.svg";


export const SidebarNotification = ({ isOpen }) => {
  const [openMenu, setOpenMenu] = useState(false);
  const socket = useSelector((state) => state.Socket.socket)
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const Globalsettings = useSelector((state) => state.SharedData.settings)


  const handleOpen = () => {
    setOpenMenu(!openMenu);
  };

  const updateSocket = (lang) => {
    socket.query['language'] = lang;
    socket.disconnect().connect()
  };


  const handleLanguage = async (item) => {
    rtlLtrCssPatchSelector(item.direction, item.lang)

    set_lang(item.lang);
    // restClient.defaults.headers.common["Accept-Language"] = item.lang
    restClient.defaults.headers["Accept-Language"] = item.lang

    set_direction(item.direction);
    dispatch({
      type: "changeDirection",
      direction: item.direction,
      fullDirection: item.direction,
      lang: item.lang,
    });
    i18n.changeLanguage(
      item.lang
    );
    setOpenMenu(!openMenu);
    updateSocket(item.lang)

    const Services = new ServicesApi();
    const servicesData = await Services.Services()
    dispatch(SetSharedDataServices(servicesData.Services))
    const vehicleTypeData = await Services.VehicleType()
    dispatch(SetSharedDataVehicleTypes(vehicleTypeData.VehicleTypes))

  }


  return <><div className={styles.SidebarNotification}>
    <SidebarButton size='full' type='Primary' disabled={true} activeIcon={Notifiaction} deActiveIcon={Notifiaction} isActive={false} isOpen={isOpen} text='Notifications' />
    <SidebarButton onClick={handleOpen} size='full' type='Primary' disabled={false} activeIcon={Language} deActiveIcon={Language} isActive={false} isOpen={isOpen} text='Language' />
   { Globalsettings?.role?.haveAdminAccess && <a href={'/admin/home'}><SidebarButton size='full' type='Primary' disabled={false} activeIcon={Settings} deActiveIcon={Settings} isActive={false} isOpen={isOpen} text='Admin Center' /></a>}

  </div>
    {openMenu && (
      <BackdropCustom open={openMenu}>
        <Paper
          style={{ width: "376px", height: "auto", borderRadius: "8px", paddingBottom: "10px" }}
        >
          <ClickAwayListener onClickAway={handleOpen}>
            <div style={{ width: "100%", height: "100%" }}>
              <div className="podheader">
                <img
                  src={IconClose}
                  className="close"
                  onClick={handleOpen}
                />
                <span className="textheader"> {t("Language")} </span>
              </div>

              {global.config.AllLanguages.map((item) => {
                return (
                  <div
                    className={
                      item.lang == get_lang()
                        ? "itemLanguageActive"
                        : "itemLanguageInActive"
                    }
                    onClick={() => { handleLanguage(item) }}
                  >
                    <span className="languageText">
                      {" "}
                      <Trans i18nKey={item.i18Lang}> </Trans>
                    </span>
                    {item.lang == get_lang() ? (
                      <img className="tik" src={tik} />
                    ) : null}
                  </div>
                );
              })}

            </div>
          </ClickAwayListener>
        </Paper>
      </BackdropCustom>
    )}
  </>

};




const BackdropCustom = withStyles({
  backdrop: {
    zIndex: 5000,
    backgroundColor: "rgba(0, 0, 0, 0)",
    color: "#00000",
  },
})((props) => (
  <Backdrop
    {...props}
    style={{
      zIndex: 5000,
      width: "100vw",
      height: "100vh",
      backgroundColor: "#00000066",
      color: "#00000",
    }}
  />
));
