import { makeData } from "../../components/table/table-list/makeData2";
import { LocalData } from "../../service/localStorage";
import * as actionTypes from "../actions/actionTypes";
const localData = new LocalData();

export const defTable = {
  defaultColumnStates:  { "OrderType": true, "stage": true, "Status": true, "routeName": true, "createdAt": true,"zone": true, "statusUpdatedAt": true,  "note": true, "DeliveryLastAttemptReason": true, "DeliveryattemptsCount": true, "PickupFailedReason": true, "referenceId": true, "PickupAddress": true, "DeliveryAddress": true, "driverName": true, "driverPhone": true, "customerName": true, "customerPhone": true, "service": true, "price": true, "codAmount": true, "estimatedDistanceInMeters": true, "estimatedDuration": true, "Payment": true, "PickupAddressDetail": true, "PickupCompleteAfter": true, "PickupcompleteBefore": true, "PickupFullName": true, "PickupPhone": true, "PickupEmail": true, "PickupPodNote": true, "PickupPodPhoto": true, "PickupPodSignature": true, "PickupZone": true, "DeliveryAddressDetail": true, "DeliveryCompleteAfter": true, "DeliverycompleteBefore": true, "DeliveryFullName": true, "DeliveryPhone": true, "DeliveryEmail": true, "DeliveryPodNote": true, "DeliveryPodPhoto": true, "DeliveryPodSignature": true, "DeliveryZone": true, "driverVehicle": true, "driverUsername": true, "customerType": true, "customerEmail": true, "paymentProvider": true, "source": true, "Receipt": true, "shipmentLabelUrl": true, "trackOrder": true },
  defaultColumnOrders:  null,
  selectedOrders: {},
  routeList: {
    open: false,
    list: [],
  },
  tableInit: null,
  list: {
    endCursor: null,
    hasNextPage: false,
    hasPreviousPage: false,
    orders: null,
    startCursor: null,
    totalCount: null
  },
  isLoading: false,
  hasError: false,
  perPage: 30,
  page: 1,

  selectedBulkEditStatusSummary: null,
  activeFilters:  [],
  filters:  {
    cursor: "createdAt",
    cursorSort: "desc",
    services: [] /* [""] */,
    zones: [] /* [""] */,
    vehicleTypes: [] /* [""] */,
    status: [] /* [""] */,
    stages: [] /* [""] */,
    paymentMethods: [] /* [""] */,
    type: null,
    dueDate: { equal: null, gte: null, lte: null },
    createdAt: { equal: null, gte: null, lte: null },
    statusUpdatedAt: { equal: null, gte: null, lte: null },
    price: { equal: null, gte: null, lte: null },
    codAmount: { equal: null, gte: null, lte: null },
    pickup: {
      failedReasons:[],
      completeAfter: {
        equal: null,
        gte: null,
        lte: null
      },
      completeBefore: {
        equal: null,
        gte: null,
        lte: null
      },
      zones: [] /* [] */
    },
    delivery: {
      lastAttemptReasons:[],
      attemptsCount: {
        equal: null,
        gte: null,
        lte: null
      },
      completeAfter: {
        equal: null,
        gte: null,
        lte: null
      },
      completeBefore: {
        equal: null,
        gte: null,
        lte: null
      },
      zones: [] /* [] */
    },
    regex: [] /*[
      {
        field :"",
        text:""
      },
      {
        field :"",
        text:""
      }
    ]*/

  }

};


export const initTable = {
  defaultColumnStates: localData.get("ReduxLocal_TabledefaultColumnStates") ? JSON.parse(localData.get("ReduxLocal_TabledefaultColumnStates")) : { "OrderType": true, "stage": true, "Status": true, "routeName": true, "createdAt": true,"zone": true, "statusUpdatedAt": true,  "note": true, "DeliveryLastAttemptReason": true, "DeliveryattemptsCount": true, "PickupFailedReason": true, "referenceId": true, "PickupAddress": true, "DeliveryAddress": true, "driverName": true, "driverPhone": true, "customerName": true, "customerPhone": true, "service": true, "price": true, "codAmount": true, "estimatedDistanceInMeters": true, "estimatedDuration": true, "Payment": true, "PickupAddressDetail": true, "PickupCompleteAfter": true, "PickupcompleteBefore": true, "PickupFullName": true, "PickupPhone": true, "PickupEmail": true, "PickupPodNote": true, "PickupPodPhoto": true, "PickupPodSignature": true, "PickupZone": true, "DeliveryAddressDetail": true, "DeliveryCompleteAfter": true, "DeliverycompleteBefore": true, "DeliveryFullName": true, "DeliveryPhone": true, "DeliveryEmail": true, "DeliveryPodNote": true, "DeliveryPodPhoto": true, "DeliveryPodSignature": true, "DeliveryZone": true, "driverVehicle": true, "driverUsername": true, "customerType": true, "customerEmail": true, "paymentProvider": true, "source": true, "Receipt": true, "shipmentLabelUrl": true, "trackOrder": true },
  defaultColumnOrders: localData.get("ReduxLocal_TabledefaultColumnOrders") ? JSON.parse(localData.get("ReduxLocal_TabledefaultColumnOrders")) : null,
  selectedOrders: {},
  routeList: {
    open: false,
    list: [],
  },
  tableInit: null,
  list: {
    endCursor: null,
    hasNextPage: false,
    hasPreviousPage: false,
    orders: null,
    startCursor: null,
    totalCount: null
  },
  isLoading: false,
  hasError: false,
  perPage: 30,
  page: 1,

  selectedBulkEditStatusSummary: null,
  activeFilters:  localData.get("ReduxLocal_TableactiveFilters") ? JSON.parse(localData.get("ReduxLocal_TableactiveFilters")) :  [],
  filters: localData.get("ReduxLocal_Tablefilters") ? JSON.parse(localData.get("ReduxLocal_Tablefilters")) : {
    cursor: "createdAt",
    cursorSort: "desc",
    services: [] /* [""] */,
    zones: [] /* [""] */,
    vehicleTypes: [] /* [""] */,
    status: [] /* [""] */,
    stages: [] /* [""] */,
    paymentMethods: [] /* [""] */,
    type: null,
    dueDate: { equal: null, gte: null, lte: null },
    createdAt: { equal: null, gte: null, lte: null },
    statusUpdatedAt: { equal: null, gte: null, lte: null },
    price: { equal: null, gte: null, lte: null },
    codAmount: { equal: null, gte: null, lte: null },
    pickup: {
      failedReasons:[],
      completeAfter: {
        equal: null,
        gte: null,
        lte: null
      },
      completeBefore: {
        equal: null,
        gte: null,
        lte: null
      },
      zones: [] /* [] */
    },
    delivery: {
      lastAttemptReasons:[],
      attemptsCount: {
        equal: null,
        gte: null,
        lte: null
      },
      completeAfter: {
        equal: null,
        gte: null,
        lte: null
      },
      completeBefore: {
        equal: null,
        gte: null,
        lte: null
      },
      zones: [] /* [] */
    },
    regex: [] /*[
      {
        field :"",
        text:""
      },
      {
        field :"",
        text:""
      }
    ]*/

  }

};
export const Table = (state = initTable, action) => {
  switch (action.type) {
    case actionTypes.Table_SelectOrder:

      const orderIsSelectedNow = state.selectedOrders[action.state];

      if (orderIsSelectedNow) {
        return state;
      } else {
        return {
          ...state,
          selectedOrders: { ...state.selectedOrders, [action.state.id]: action.state }
        };
      }


    case actionTypes.Table_SelecteMultiOrders:
      let newList = {}
      action.state.forEach((od) => {
        newList[od.id] = od
      });


      return {
        ...state,
        selectedOrders: { ...state.selectedOrders, ...newList }
      };


    case actionTypes.Table_DeSelectOrder:
      let xx = state.selectedOrders;
      delete xx[action.state]
      return {
        ...state,
        selectedOrders: { ...xx }
      };


    case actionTypes.table_setRouteList:
      return {
        ...state,
        routeList: {
          ...state.routeList,
          [action.state.key]: action.state.value
        }
      };


    case actionTypes.Table_ClearSelectedOrders:

      return {
        ...state,
        selectedOrders: {}
      };

    case actionTypes.Table_setData:

      return {
        ...state,
        list: action.state
      };


    case actionTypes.Table_Init:

      return {
        ...state,
        tableInit: action.state
      };

    case actionTypes.Table_setStatus:

      return {
        ...state,
        [action.state.key]: action.state.value
      };
    case actionTypes.Table_setSelectedBulkEditStatusSummary:

      return {
        ...state,
        selectedBulkEditStatusSummary: action.state
      };

    case actionTypes.TableInputContainFilter_Add:

      return {
        ...state,
        filters: {
          ...state.filters,
          regex: [...state.filters.regex, action.state]
        }
      };

    case actionTypes.TableInputContainFilter_Update:

      return {
        ...state,
        filters: {
          ...state.filters,
          regex: state.filters.regex.map((item) => {
            if (item.field == action.state.field) {
              return action.state
            } else {
              return item
            }
          })
          // regex : state.filters.regex.filter((item)=>{
          //   return item.field != 
          // })
        }
      };

    case actionTypes.TableInputContainFilter_Delete:

      return {
        ...state,
        filters: {
          ...state.filters,
          regex: state.filters.regex.filter((item) => {
            return item.field != action.state
          })
        }
      };


    case actionTypes.TableCheckboxFilter_Update:

      return {
        ...state,
        filters: {
          ...state.filters,
          [action.state.key]: action.state.value
        }
      };

    case actionTypes.TableRadioButtonFilter_Update:

      return {
        ...state,
        filters: {
          ...state.filters,
          [action.state.key]: action.state.value
        }
      };

    case actionTypes.TableRangeFilter_Update:

      return {
        ...state,
        filters: {
          ...state.filters,
          [action.state.key]: action.state.value
        }
      };

      case actionTypes.TableSubRangeFilter_Update:

      return {
        ...state,
        filters: {
          ...state.filters,
          [action.state.key]: {
            ...state.filters[action.state.key],
            [action.state.sub]:action.state.value
          }
        }
      };


      case actionTypes.Add_ActiveFilter:

      return {
        ...state,
        activeFilters: [...state.activeFilters,action.state]
      };
      case actionTypes.clearAll_ActiveFilter:

      return {
        ...state,
        filters: {
          cursor: "createdAt",
          cursorSort: "desc",
          services: [] /* [""] */,
          zones: [] /* [""] */,
          vehicleTypes: [] /* [""] */,
          status: [] /* [""] */,
          stages: [] /* [""] */,
          paymentMethods: [] /* [""] */,
          type: null,
          dueDate: { equal: null, gte: null, lte: null },
          createdAt: { equal: null, gte: null, lte: null },
          statusUpdatedAt: { equal: null, gte: null, lte: null },
          price: { equal: null, gte: null, lte: null },
          codAmount: { equal: null, gte: null, lte: null },
          pickup: {
            failedReasons:[],
            completeAfter: {
              equal: null,
              gte: null,
              lte: null
            },
            completeBefore: {
              equal: null,
              gte: null,
              lte: null
            },
            zones: [] /* [] */
          },
          delivery: {
            lastAttemptReasons:[],
            attemptsCount: {
              equal: null,
              gte: null,
              lte: null
            },
            completeAfter: {
              equal: null,
              gte: null,
              lte: null
            },
            completeBefore: {
              equal: null,
              gte: null,
              lte: null
            },
            zones: [] /* [] */
          },
          regex: [] /*[
            {
              field :"",
              text:""
            },
            {
              field :"",
              text:""
            }
          ]*/
      
        }
      };


    case actionTypes.Table_reset:

      return { ...initTable };
    default:
      return state;
  }
};

export default Table;