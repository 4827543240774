import styles from './style.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import chevron_up from "../../../assets/chevron_up.svg";
import map from "../../../assets/map.svg";
import { Collapse } from 'newmaterial-ui';
import clsx from 'clsx';
import { MonitoringHandleActiveDrivers, MonitoringHandleActiveOrders } from '../../../Redux/actions/actionNames';
import { useTranslation } from 'react-i18next';
import { getOrderMonitoring } from './action/getOrderMonitoring';
import { getDriverMonitoring } from './action/getDriverMonitoring';
import { useGetFiltersList } from '../../../hooks/useGetFiltersList';
import { useGetSearchListFilter } from '../../../hooks/useGetSearchListFilter';


export const ViewOrderMonitoring = () => {
    const ordersOpen = useSelector((state) => state.Monitoring.orders.open)
    const driversOpen = useSelector((state) => state.Monitoring.drivers.open)
    const [ordersInterval, setOrdersInterval] = useState(null);
    const [driversInterval, setDriversInterval] = useState(null);
    const [open, setOpen] = useState(false);
    const { t, i18n } = useTranslation();
    let { DateRange, selectedServices, selectedStatus, selectedVehicleType, isScheduled, changed, zones, now } = useGetFiltersList()
    const ActiveOrderType = useSelector((state) => state.OrderList.ActiveOrderType)
    let { regexText, ActiveregexFields } = useGetSearchListFilter()



    useEffect(() => {
        if (ordersOpen) {
            getOrderMonitoring()
            // if(!ordersInterval){
            //     setOrdersInterval(setInterval(() => {
            //         getOrderMonitoring()
            //     }, 300000))
            // }
        } else {
            clearInterval(ordersInterval)
        }
    }, [ordersOpen, ActiveOrderType, DateRange, selectedServices, selectedStatus, selectedVehicleType, isScheduled, now, changed, zones, regexText, ActiveregexFields])


    useEffect(() => {
        if(!driversOpen){
            return 
        }
        getDriverMonitoring()
        const interval = setInterval(() => {
            getDriverMonitoring()
        }, 10000);
        return () => clearInterval(interval);
      }, [driversOpen]);


    // useEffect(() => {
    //     if (driversOpen) {
    //         getDriverMonitoring()
    //         if (!driversInterval) {
    //             setDriversInterval(setInterval(() => {
    //                 getDriverMonitoring()
    //             }, 10000))
    //             return () => {
    //                 clearInterval(driversInterval)
    //               };
    //         }
    //     } else {
    //         clearInterval(driversInterval)
    //         return () => {
    //             clearInterval(driversInterval)
    //           };
    //     }
    //     return () => {
    //         clearInterval(driversInterval)
    //       };
    // }, [driversOpen])



    return <div className={styles.monitoringContainer}>

        <div className={styles.monitoring} onClick={() => setOpen(!open)}>
            <img src={map} className={styles.map} />
            <span className={styles.title}>{t("mapitems")}</span>
            <img src={chevron_up} className={clsx(styles.chevron_up, open && styles.chevron_down)} />
        </div>

        <Collapse
            className={styles.monitoringCollapse}
            in={open}
            timeout={150}
            unmountOnExit
            mountOnEnter
        >
            <div className={styles.monitoringCollapseInner}>

                <OrderCheckbox />
                <DriverCheckbox /></div>
        </Collapse>
    </div>
};





const OrderCheckbox = ({ checked, ...props }) => {

    const ordersOpen = useSelector((state) => state.Monitoring.orders.open)
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    function handleOpenCloseOrders() {
        dispatch(MonitoringHandleActiveOrders(!ordersOpen))
    }


    return (
        <div className={styles.popperItemFrame}>
            <span className={styles.popperItemText}> {t("Orders")}  </span>
            <input className={clsx(styles.checkboxWrapper, ordersOpen ? styles.checked : '')} type="checkbox"
                onClick={handleOpenCloseOrders}
                {...props}
            />
        </div>
    );
};


const DriverCheckbox = ({ checked, ...props }) => {
    const driversOpen = useSelector((state) => state.Monitoring.drivers.open)
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();


    function handleOpenCloseDrivers() {
        dispatch(MonitoringHandleActiveDrivers(!driversOpen))
    }

    return (
        <div className={styles.popperItemFrame} style={{ marginBottom: "0" }}>
            <span className={styles.popperItemText}> {t("Drivers")} </span>
            <input className={clsx(styles.checkboxWrapper, driversOpen ? styles.checked : '')} type="checkbox"
                onClick={handleOpenCloseDrivers}
                {...props}
            />
        </div>
    );
};