import * as React from 'react'
import styles from './style.module.css';
import Button from '../../../design-system/lib/Button';
import { ADDRESS_BOOK, AUTO_DISPATCH, CLOSE, CLOSE_DARK, CUSTOMER_TYPE, HISTORY, INFO_CIRCLE, LOCATION, MAP, PERSON, PICKUP } from '../../../assets';
import DropDown from '../../../design-system/lib/DropDown';
import Modal from '../../../design-system/lib/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { Add_Service_Option, NewTravelResetReserve, New_Order_Reset_DropOff, New_Order_Reset_DropOffs, New_Order_Reset_Pickup, New_Travel_PaymentMethod, Remove_Service_Option, Set_Auto_ActiveVehicleType, Set_Auto_ActiveVehicleTypeـPickupDeliveryType_Pickup, Set_NewOrder_OrderType, Set_Pickup_Inputs, Set_Pickup_address, Set_SearchAutoCompleteLoading, Set_Service_ActiveVehicleType, Set_latlng, SetopenAddressBookInNewOrder } from '../../../Redux/actions/actionNames';
import { getCenterMap, setCenterMap } from '../../../helper/module';
import { GetVehicletypeByServiceAction, PlaceIdDetailsAction, SearchAutoCompleteAction, SearchAutoCompleteAction_local_address } from '../../../Redux/actions/actions';
import { useTranslation } from 'react-i18next';
import Input from '../../../design-system/lib/Input';
import IconProvider from '../../../design-system/lib/IconProvider';
import Typography from '../../../design-system/lib/Typography';
import CustomerListItem from '../customer/components/list-Item';
import SearchList from '../../../design-system/lib/SearchList';
import { v4 as uuidv4 } from 'uuid';
import _, { isEmpty } from "lodash";
import RecentAddressItem from './components/recentAddressItem';
import AddressItem from './components/addressItem';
import PickupInfoNewOrder from './components/info';


function PickupNewOrder({handleCollapse}) {
  const [isLocalAddressActive, setIsLocalAddressActive] = React.useState(global.config.dispatcherSettings.isLocalAddressActive);
  const [localSearchIsActive, setLocalSearchIsActive] = React.useState(global.config.dispatcherSettings.isLocalAddressActive ? true : false);
  const [id, setid] = React.useState("pickup");
  const [ordered, setOrdered] = React.useState(true);
  const [showpoper, setShowpoper] = React.useState(false);
  const [showpoperSaved, setShowpoperSaved] = React.useState(false);
  const [getFavAddressLoading, setGetFavAddressLoading] = React.useState(false);
  const [searchAddrees, setSearchAddrees] = React.useState("");
  const [sessionToken, setSessionToken] = React.useState(null);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const auto = useSelector((state) => state.NewOrder.newOrder.pickup.driver.auto)
  const favAddresss = useSelector((state) => state.FavAddress.list)
  const customerId = useSelector((state) => state.NewOrder.newOrder.customer.id)
  const BusinessId = useSelector((state) => state.NewOrder.newOrder.customer.BusinessId)
  const showDriver = useSelector((state) => state.NewOrder.newOrder.pickup.driver.chooseDriver)
  const mapRef = useSelector((state) => state.Map.mapRef)
  const center = useSelector((state) => state.Map.center)
  const address = useSelector((state) => state.NewOrder.newOrder.pickup.address)
  const adresss_loc = useSelector((state) => state.NewOrder.newOrder.pickup.adresss_loc)
  const block = useSelector((state) => state.NewOrder.newOrder.pickup.block)
  const floor = useSelector((state) => state.NewOrder.newOrder.pickup.floor)
  const unit = useSelector((state) => state.NewOrder.newOrder.pickup.unit)
  const emailg = useSelector((state) => state.NewOrder.newOrder.pickup.email)
  const senderFullName = useSelector((state) => state.NewOrder.newOrder.pickup.senderFullName)
  const senderPhoneNumber = useSelector((state) => state.NewOrder.newOrder.pickup.senderPhoneNumber)
  const noteForDriver = useSelector((state) => state.NewOrder.newOrder.pickup.noteForDriver)
  const scheduleDateBefore = useSelector((state) => state.NewOrder.newOrder.pickup.scheduleDateBefore)
  const scheduleDateAfter = useSelector((state) => state.NewOrder.newOrder.pickup.scheduleDateAfter)
  const list = useSelector((state) => state.NewOrder.newOrder.pickup.list)
  const loading = useSelector((state) => state.NewOrder.newOrder.pickup.loading)
  const activePickupDropOff = useSelector((state) => state.NewOrder.newOrder.activePickupDropOff)
  const suggestListPickUpDropOffLoading = useSelector((state) => state.NewOrder.newOrder.suggestListPickUpDropOffLoading)
  const suggestListPickUpDropOffOrdered = useSelector((state) => state.NewOrder.newOrder.suggestListPickUpDropOffOrdered)
  const suggestPickUpDropOffListData = useSelector((state) => state.NewOrder.newOrder.suggestPickUpDropOffListData)
  const lang = useSelector((state) => state.LocalData.lang)
  const activeVehicleType = useSelector((state) => state.NewOrder.newOrder.service.activeVehicleType)
  const openAddressBook = useSelector((state) => state.Addressbook.openAddressBookInNewOrder)
  const orderTypeActive = useSelector((state) => state.NewOrder.newOrder.orderTypeActive)
  const recentAddress = useSelector((state) => state.NewOrder.newOrder.customer.recentAddreess)
  const ActivescheduleSetting = useSelector((state) => state.NewOrder.newOrder.service.activeVehicleType ? state.NewOrder.newOrder.service.activeVehicleType.scheduleSetting.isActive : false)
  const addressBookSource = useSelector((state) => state.Addressbook.openAddressBookInNewOrder
    ? state.Addressbook.sourceOfAddressBookInNewOrder
      ? state.Addressbook.sourceOfAddressBookInNewOrder.type == "pickup"
      : false
    : false)
    const auto_activeVehicleType = useSelector((state) => state.NewOrder.newOrder.pickup.driver.auto_driver.auto_activeVehicleType)
    const manual_activeVehicleType = useSelector((state) => state.NewOrder.newOrder.pickup.driver.manual_driver.manual_activeVehicleType)
    const driveroptions = useSelector((state) => state.NewOrder.newOrder.pickup.driver.options)

  const delayedQuery = React.useCallback(_.debounce((...pr) => HandleChange(...pr), 500), []);
  const auto_suggestListData = useSelector((state) => state.NewOrder.newOrder.driver.auto_driver.auto_suggestListData)
  const pickup_manual_id = useSelector((state) => state.NewOrder.newOrder.pickup.driver.manual_driver.manual_id)


  const HandleChange = React.useCallback((value, localSearchIsActive, sessionToken) => {

    let centerr = getCenterMap(mapRef, center)
    String(value).length > 0 && dispatch(Set_SearchAutoCompleteLoading(true));

    console.log("localSearchIsActive ", localSearchIsActive)
    if (!localSearchIsActive) {
      String(value).length > 0 && dispatch(
        SearchAutoCompleteAction(dispatch, {
          query: value,
          latitude: centerr.lat,
          longitude: centerr.lng,
          sessionToken: sessionToken
        })
      );
    } else {
      String(value).length > 0 && dispatch(
        SearchAutoCompleteAction_local_address(dispatch, {
          query: value
        })
      );
    }


  }, [localSearchIsActive, sessionToken]);


  const handlePickUpInputs = React.useCallback((e, name) => {

    if (name === "address" || e?.target.id === "address") {

      setSessionToken(sessionToken ? sessionToken : uuidv4())

      // dispatch(
      //   Set_Pickup_address({
      //     adresss_loc: { lng: "", lat: "" },
      //     placeId: "",
      //   })
      // );


      if (e && e?.target.value != "") {
        e?.target?.value.length > 2 && delayedQuery(e?.target.value, localSearchIsActive, sessionToken)
      }


      console.log("dwrwewdfwee",e)
    }
    if(e){
         dispatch(
      Set_Pickup_Inputs({
        name: name || e?.target.id,
        value: e?.target.value,
      })
    );
    }
 
  }, [sessionToken, localSearchIsActive]);

  const handleChangeLocalSearch = React.useCallback((value) => {

    setLocalSearchIsActive(value == t("Map") ? false : true)
    // setTimeout(() => {
    //   notThrottleHandleChange(String(address), sessionToken)
    // }, 200);

  }, [localSearchIsActive, sessionToken, address]);

  const notThrottleHandleChange = React.useCallback((value) => {

    let centerrr = getCenterMap(mapRef, center)
    String(value).length > 0 && dispatch(Set_SearchAutoCompleteLoading(true));



    if (!localSearchIsActive) {
      String(value).length > 0 && dispatch(
        SearchAutoCompleteAction(dispatch, {
          query: value,
          latitude: centerrr.lat,
          longitude: centerrr.lng,
          sessionToken: sessionToken
        })
      );
    } else {
      String(value).length > 0 && dispatch(
        SearchAutoCompleteAction_local_address(dispatch, {
          query: value
        })
      );
    }

  }, [localSearchIsActive, sessionToken]);


  React.useEffect(() => {
    notThrottleHandleChange(String(address), sessionToken)
  }, [localSearchIsActive])


  const serachListData = React.useCallback(() => {

    if (_.isEmpty(address)) {
      if (_.isEmpty(customerId)) {
        return null //error customer
      } else {
        if (_.isEmpty(recentAddress)) {
          return null //error recentAddress
        } else {
          return recentAddress
        }
      }
    } else {
      if (_.isEmpty(suggestPickUpDropOffListData)) {
        return null //error suggestPickUpDropOffListData
      } else {
        return suggestPickUpDropOffListData
      }
    }


  }, [recentAddress, customerId, address, suggestPickUpDropOffListData]);


  const GetserachListItem = React.useCallback(() => {

    if (_.isEmpty(address)) {
      return RecentAddressItem
    } else {
      return AddressItem
    }


  }, [recentAddress, customerId, address, suggestPickUpDropOffListData]);


  const GetserachListItemOnclick = React.useCallback(() => {

    if (_.isEmpty(address)) {
      return handleRecentList
    } else {
      return handleList
    }


  }, [recentAddress, customerId, address, suggestPickUpDropOffListData]);


  const GetSearchListError = React.useCallback(() => {

    //no recent address
    if (recentAddress.length == 0 && !_.isEmpty(customerId) && _.isEmpty(address)) {
      return {
        errorDescription: t("NoRecentAddressD"),
        errorTitle: t("NoRecentAddress"),
        errorIcon: HISTORY,
      }
    }

    //CustomerIsNotChoosed

    if (_.isEmpty(customerId) && _.isEmpty(address)) {
      return {
        errorDescription: t("CustomerIsNotChoosedD"),
        errorTitle: t("CustomerIsNotChoosed"),
        errorIcon: CUSTOMER_TYPE,
      }
    }



    if ((!suggestListPickUpDropOffLoading &&
      suggestPickUpDropOffListData.length === 0 && !_.isEmpty(address)) || (!_.isEmpty(address) && !suggestListPickUpDropOffLoading && !suggestPickUpDropOffListData)) {
      return {
        errorDescription: t("NoResultfromLocalDatabaseD"),
        errorTitle: t(localSearchIsActive ? "NoResultfromLocalDatabase" : "NoResultfromMapService"),
        errorIcon: null,
      }
    }

  }, [recentAddress, customerId, address, suggestPickUpDropOffListData, suggestListPickUpDropOffLoading, localSearchIsActive]);


  const handleRecentList = (item) => {

    dispatch(
      Set_latlng({ lng: item.coordinates[0], lat: item.coordinates[1] })
    );

    dispatch(
      Set_Pickup_address({
        ...item,
        address: item.address,
        adresss_loc: { lng: item.coordinates[0], lat: item.coordinates[1] },
        block: item.buildingBlock,
        floor: item.floor,
        unit: item.room
      })
    );
    mapSetView({
      lng: item.coordinates[0],
      lat: item.coordinates[1],
    });

    handleShowInfo()

  }

  const mapSetView = (e) => {
    setCenterMap(mapRef, e)
  };


  const handleList = (item) => {

    if (item.coordinates) {

      dispatch(
        Set_latlng({ lng: item.coordinates[0], lat: item.coordinates[1] })
      );


      dispatch(
        Set_Pickup_address({
          ...item,
          address: item.secondaryAddress
            ? item.address + " , " + item.secondaryAddress
            : item.address,
          adresss_loc: { lng: item.coordinates[0], lat: item.coordinates[1] },
        })
      );
      mapSetView({
        lng: item.coordinates[0],
        lat: item.coordinates[1],
      });

      setSessionToken(null)

      handleShowInfo()


    } else {

      let ss = sessionToken ? sessionToken : uuidv4()
      setSessionToken(ss)


      dispatch(
        PlaceIdDetailsAction(dispatch, { placeId: item.placeId, sessionToken: ss }, (res) => {

          setSessionToken(null)


          dispatch(
            Set_latlng({ lng: res.coordinates[0], lat: res.coordinates[1] })
          );

          dispatch(
            Set_Pickup_address({
              ...item,
              address:res.address,
              // address: item.secondaryAddress
              //   ? item.address + " , " + item.secondaryAddress
              //   : item.address,
              adresss_loc: { lng: res.coordinates[0], lat: res.coordinates[1] },
            })
          );
          dispatch(
            Set_latlng({
              lng: res.coordinates[0],
              lat: res.coordinates[1],
            })
          );
          handleShowInfo()

        })
      );


    }
  };


  const resetPickup = () => {
    dispatch(
      Set_Pickup_address({
        address: "",
        adresss_loc: { lng: "", lat: "" },
        placeId: "",
      })
    );
  };

  const handleShowInfo = React.useCallback(() => {

    const isOpen = activePickupDropOff.type === "pickup"
    
    const myEvent = new CustomEvent("closeSliders",{ detail:null});
    document.dispatchEvent(myEvent);


    if(!isOpen){
      handleCollapse({
        type: "pickup",
        id: "pickup",
      });
    }else{
         setTimeout(() => {
     handleCollapse({
      type: null,
      id: ""
     });
   }, 100);
    }

  }, [activePickupDropOff]);


  const emailValidate=(email)=> {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

 

  const vehicleRequiredCheck = React.useCallback(() => {

    if (orderTypeActive == "PickupDelivery") {
    let activeVehicleType = pickup_manual_id != "" && pickup_manual_id != null ? manual_activeVehicleType  : auto_activeVehicleType ;

    if (activeVehicleType) {
      if (activeVehicleType.options.length == 0) {
        console.log("vehicleRequiredCheck , 382 " )
        return true
      } else {
        let isNotExist = false;
        let arrFiltered = activeVehicleType.options.filter((item) => item.isRequired);
        arrFiltered.forEach(element => {
          if (!VehicleOptionisSaved( element.id)) {
            isNotExist = true;
          }
        });
        console.log("vehicleRequiredCheck , 392 " )
        return isNotExist == false
      }
    } else {
      console.log("vehicleRequiredCheck , 396 " )
      return true
    }

  }else {
    console.log("vehicleRequiredCheck , 396 " )
    return true
  }
  }, [auto , auto_activeVehicleType , manual_activeVehicleType , driveroptions , orderTypeActive]);


  const VehicleOptionisSaved=React.useCallback((id) => {
    console.log("VehicleOptionisSaved  ",id,driveroptions )

    let booll = false;
    booll = driveroptions.find((item) => {
      return item.id == id;
    });
    if (booll) {
      return true
    } else {
      return false
    }
  }, [driveroptions]);


  const pickupRequiredCheck = React.useCallback(() => {
    if (activeVehicleType) {
      let name = false, email = false, phone = false;

      if (activeVehicleType.setting.isPickupAddressNameRequired) {
        if (senderFullName && String(senderFullName).trim().length > 0) {
          name = true
        } else {
          name = false
        }
      } else {
        name = true
      }

      if (activeVehicleType.setting.isPickupAddressPhoneRequired) {
        if (senderPhoneNumber && String(senderPhoneNumber).trim().length > 0) {
          phone = true
        } else {
          phone = false
        }
      } else {
        phone = true
      }


      if (activeVehicleType.setting.isPickupAddressEmailRequired) {
        if (emailg && String(emailg).length > 0 && emailValidate(String(emailg))) {
          email = true
        } else {
          email = false
        }
      } else {
        email = true
      }



      return (name && phone && email);

    } else {
      return false
    }

  }, [activeVehicleType , emailg , senderPhoneNumber , senderFullName]);

  // const handleChangeLocalSearch = React.useCallback((value) => {


  // }, []);


  const openAddressbook = React.useCallback(() => {

    const myEvent = new CustomEvent("closeSliders",{ detail:null});
    document.dispatchEvent(myEvent);


  setTimeout(() => {
    if (customerId || BusinessId) {
      if (openAddressBook && addressBookSource) {
        dispatch(SetopenAddressBookInNewOrder(false, "", null, ""));
      } else {
        dispatch(
          SetopenAddressBookInNewOrder(
            true,
            "pickup",
            null,
            "AddressbookPickup"
          )
        );
      }
    }
  }, 100);
  }, [customerId , BusinessId , openAddressBook , addressBookSource]);




  React.useEffect(() => {

    if (!auto_activeVehicleType && auto_suggestListData.length > 0) {
      dispatch(Set_Auto_ActiveVehicleTypeـPickupDeliveryType_Pickup(auto_suggestListData[0]))
    }

  }, [auto_activeVehicleType , auto_suggestListData])



  React.useEffect(() => {

    document.addEventListener('closeSliders', () => {
     //
     handleCollapse({
      type: null,
      id: ""
    });

      //
    })
    return () => {
      document.removeEventListener("closeSliders");
  };
  }, [])
  

  if (isEmpty(String(adresss_loc.lat))) {
    return <div className={styles.PickupOnlyNewOrderContainer}>

      <div className={styles.PickupOnlyNewOrderContainerFirstRow}>
        <IconProvider Icon={PICKUP} size='16' />
        <Typography text={t("Pickup")} weight='Body_Middle_Bold' />
      </div>

      <div className={styles.PickupOnlyNewOrderContainerSecondRow}>
        <SearchList onChange={(e) => handlePickUpInputs(e, "address")} style={{ zIndex: "2" }} value={address} placeHolder={t("SearchAddress")} isloading={suggestListPickUpDropOffLoading} listItemOnclick={GetserachListItemOnclick()} error={GetSearchListError()} errorDescription={GetSearchListError()?.errorDescription} errorTitle={GetSearchListError()?.errorTitle} errorIcon={GetSearchListError()?.errorIcon}
          ListItemChildren={GetserachListItem()} list={serachListData()} inputInnerChildren={isLocalAddressActive ? (<DropDown size='Small' placement='bottomEnd' text={localSearchIsActive ? t("Local") : t("Map") } data={[t("Map"), t("Local")]} onClick={handleChangeLocalSearch} />) : null}
        />
        <Button icon={LOCATION} size='Medium' type='Secondary' style={{marginInlineEnd:"7px"}} onClick={handleShowInfo}  isActive={activePickupDropOff.type === "pickup"}/>
        <Button icon={ADDRESS_BOOK} size='Medium' type='Secondary' disabled={((customerId && String(customerId).length > 0) || (BusinessId && String(BusinessId).length > 0)) ? false : true} onClick={openAddressbook} isActive={addressBookSource} />
      </div>
    </div>
  }

  return (
    <>

      <div className={styles.PickupOnlyNewOrderSelectedContainer}>

        <div className={styles.PickupOnlyNewOrderSelectedContainerFrow}>
          <IconProvider Icon={PICKUP} size='16' />
          <Typography text={address} weight='Body_Small_Regular' style={{
            width: "345px", overflow: 'hidden',
            textOverflow: 'ellipsis', whiteSpace: 'nowrap', flex: "1"
          }} />
          <Button icon={INFO_CIRCLE} size='Medium' type='Secondary' badge={( /* !vehicleRequiredCheck() || */ !pickupRequiredCheck() ) && true} onClick={handleShowInfo} isActive={activePickupDropOff.type === "pickup"} />
          <Button size={'Small'} icon={CLOSE_DARK} type='Secondary' style={{ marginInlineStart: "4px", backgroundColor: "transparent", padding: "8px 8px" }} onClick={resetPickup} />

        </div>


       {( /* !vehicleRequiredCheck() || */ !pickupRequiredCheck() ) && <Typography text={t("CompleteRequiredFeileds")} weight='Body_Small_Regular' style={{
          width: "345px", overflow: 'hidden',
          textOverflow: 'ellipsis', whiteSpace: 'nowrap', flex: "1"
        }} textColor='Red' />}

      </div>

      {activePickupDropOff.type === "pickup" && <PickupInfoNewOrder handleShowInfo={handleShowInfo} />}
    </>

  )
}

export default PickupNewOrder