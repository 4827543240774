import joradeliverys from "../../../assets/joradelivery_logo.png";
import FavIcon from "../../../assets/PROJECTS/joradelivery.png";

export default function Joradelivery() {
    
    return  global.config = {
    "ProjectName": "JORA Delivery",
    "LogoType": joradeliverys,
    "BaseApi": "https://rest.joradelivery.com",
    "BaseSocket": "https://socket.joradelivery.com",
    "BaseTrack": "https://admin.joradelivery.com",
    "MapCenter": {
        "lat": "35.24101664620797",
        "lng": "-80.84326217789926"
    },
    "Lang": "en",
    "Direction": "ltr",
    "AllLanguages": [{
        "lang": "en",
        "direction": "ltr",
        "i18Lang": "English"
    }],
    "FavIcon": FavIcon,
    "Country": "US",
    "thirdPartyLoginSupport": true  ,
    mapType :"osm", /* osm | google */ 
    googleApiKey : ""
} }