import AnypointIGO from "../../../assets/Anypoint.png";
import FavIcon from "../../../assets/PROJECTS/FavIcon_Anypoint.png";



export default function Anypoint() {
    
    return global.config = {
    "ProjectName": "Any Point",
    "LogoType": AnypointIGO,
    "BaseApi": "https://rest.anypoint.live",
    "BaseSocket": "https://socket.anypoint.live",
    "BaseTrack": "https://admin.anypoint.live",
    "MapCenter": {
        "lat": 24.73142866200158,
        "lng": 46.663227047365936
    },
    "Lang": "en",
    "Direction": "ltr",
    "AllLanguages": [{
        "lang": "en",
        "direction": "ltr",
        "i18Lang": "English"
    }],
    "FavIcon": FavIcon,
    "Country": "SA",
    "thirdPartyLoginSupport": true  ,
    mapType :"osm", /* osm | google */ 
    googleApiKey : ""
}}