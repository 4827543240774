import TEZRATEZRAFTAARFTAAR from "../../../assets/TEZRAFTAAR_logotype.png";
import FavIcon from "../../../assets/PROJECTS/TEZRAFTAAR.png";

export default function TEZRAFTAAR() {
    
    return global.config = {
    "ProjectName": "TEZRAFTAAR",
    "LogoType": TEZRATEZRAFTAARFTAAR,
    "BaseApi": "https://rest.trcs.pk",
    "BaseSocket": "https://socket.trcs.pk",
    "BaseTrack": "https://manager.trcs.pk",
    "MapCenter": {
        "lat": "33.712284",
        "lng": "73.091881"
    },
    "Lang": "en",
    "Direction": "ltr",
    "AllLanguages": [{
        "lang": "en",
        "direction": "ltr",
        "i18Lang": "English"
    }],
    "FavIcon": FavIcon,
    "Country": "PK",
    "thirdPartyLoginSupport": true  ,
    mapType :"osm", /* osm | google */ 
    googleApiKey : ""
}
}