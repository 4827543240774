import styles from './style.module.css';
import icDropoff from '../../../../assets/ic-dropoff.svg';
import icSchedule from '../../../../assets/ic-schedule-x.svg';
import momentJalaali from "moment-jalaali";
import { get_lang } from "../../../../helper/UserData/userdate";
import { useTranslation } from 'react-i18next';
const _ = require('lodash');

export const DeliveryRow = ({ dropoff, data }) => {
    const { t, i18n } = useTranslation();

    if (_.isEmpty(dropoff)) {
        return null
    }
    return <div className={styles.DeliveryRowContainer}>
         {(data.type == "Ondemand" && data.dropoffs.length > 1) ? ( <span className={styles.moreFlag}>  {`+${data.dropoffs.length - 1}`} <span className={styles.DeliveryRowImageIconBorder} style={{ margin: "0" }}>  <span className={styles.DeliveryRowImageIcon} > </span> </span> </span>) : (<span className={styles.DeliveryRowImageIconBorder}> </span>)} 
        <span className={styles.DeliveryRowAddress}>{dropoff.address}</span>
        {(dropoff.scheduleDateAfter != 0 || dropoff.scheduleDateBefore != 0) &&
            <span className={styles.DeliveryRowSchedule}> <img src={icSchedule} className={styles.DeliveryRowScheduleIcon} /> <span className={styles.DeliveryRowScheduleText}>{momentJalaali(dropoff.scheduleDateAfter || dropoff.scheduleDateBefore).locale(get_lang()).format("D MMM HH:mm")} </span></span>


        }

        {["Delivery", "PickupDelivery"].indexOf(data.type) > -1 &&
            <>
                {data.isPickupDelivery && <span className={styles.OrderTypePD}> {t("tab_PickupDelivery")}</span>}
                {data.type == "Delivery" && <span className={data.isPickupDelivery ? styles.OrderTypewithPD : styles.OrderType}> {t("DropOffFlag")} </span>}
            </>
        }

       
        {/* <span className={styles.OrderType}> D </span> */}

    </div>
};




