import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import {
  SetActiveDriverList,
  ChangeViewTravelDialoge,
  Edit_Travel_Reset,
} from "../../../../Redux/actions/actionNames";
import { GetDriverOrderListAction, getFavAddressListAction, OrderGetEventsAction, OrderInfoAction } from "../../../../Redux/actions/actions";
import { isEmpty } from "lodash";
import pulse from "../../../../assets/Pulse.svg";
import { store } from "../../../../Redux/store";
import { OrderListItem } from "../../../shared/order-Item";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.ps = null;
    this.intervall = null;

  }

  componentDidMount() {
    // try {
    //   const container = document.getElementById("Orderlistitems");
    //   this.ps = new PerfectScrollbar(container);
    //   container.addEventListener("ps-y-reach-end", this.loadMore);
    // } catch (error) { }
    let storeData = store.getState();
    this.getDriverOrders(this.props , false )

    this.intervall = setInterval(() => {
      this.getDriverOrders(this.props , true)
    }, 10000);

  }

  handledriver = (data) => {
    this.props.dispatch(SetActiveDriverList(data));
  };

  ToArray = () => {
    return Object.keys(this.props.OrderList).map(
      (id) => this.props.OrderList[id]
    );
  };

  loadMore = () => {
    // this.props.dispatch(
    //   GetDriverOrderListAction(this.props.dispatch, {
    //     status: "Assigned|PickedUp|Started|Arrived",
    //     perpage: 15,
    //     page: this.props.OrderListOrderedPage + 1,
    //     driverId: this.props.activeDriver.id,
    //   })
    // );
  };

  OncLIck = (driverid, customerId) => {

    this.props.dispatch(OrderInfoAction(this.props.dispatch, {
      orderId: driverid
    }))

    this.props.dispatch(OrderGetEventsAction(this.props.dispatch, driverid))

    // customerId && this.props.dispatch(getFavAddressListAction(this.props.dispatch, {id:customerId , page:0 , perpage:100} ))
    this.props.dispatch(ChangeViewTravelDialoge(true, driverid, customerId));
  };


  componentWillReceiveProps(nextProps) {



      if (Number(nextProps.activeDriver.activeOrdersCount) != Number(this.props.activeDriver.activeOrdersCount)) {
   
        this.getDriverOrders(nextProps  , true)
      }
    

  }


  getDriverOrders=(props , noLoading)=>{
    this.props.dispatch(
      GetDriverOrderListAction(this.props.dispatch, {
        cursor: props.cursor,
        cursorSort: props.cursorSort,
        status: ["Assigned", "PickedUp", "Started", "Arrived"],
        columns: ['status', 'isUrgent', 'customerId', 'driver', 'pickup', 'dropoff', 'dropoffs', 'delivery', 'isScheduled', 'currentCandidateDrivers', 'driverAssignmentModel', 'driverId', 'delayAt', 'baseETA', 'realETA', 'isPinned', 'isDelayed', 'isPossibleForDelay', 'candidateDriversCount', 'dispatchAt', 'isDispatching', 'vehicleType'],
        size: 200,
        driverId: props.activeDriver.id,
      } ,noLoading)
    );
  }


  
  componentWillUnmount(){
    clearInterval(this.intervall);
  }
  render() {
    
    return (
      <React.Fragment>
        <div id="Orderlistitems" className={`Orderlistitems `} style={{ height: !global.config.dispatcherSettings.isVoipActvie && "calc(100% - 108px)",backgroundColor:"white" }}>
          {this.props.OrderListLoading && 
            this.props.OrderListOrderedPage === 0 && this.ToArray().length == 0 ? (
            <div>
              <span className="NotravelsListHeader">
                <Trans i18nKey={"fetchingdata"}> </Trans>{" "}
              </span>
              <span className="NotravelsListText">
                <Trans i18nKey={"poperLoading"}> </Trans>{" "}
              </span>
            </div>
          ) : isEmpty(this.props.OrderList) ? (
            <div>
              <span className="NotravelsListHeader">
                <Trans i18nKey={"Noinformationfound"}> </Trans>
              </span>
            </div>
          ) : null}

          {!isEmpty(this.props.OrderList) &&
            this.ToArray().filter((arrx)=> arrx.driver != null).map((item, i) => {
              return <OrderListItem canPinOrder={false} canUnPinOrder={false} OnPin={() => { }} OnUnPin={() => { }} data={item} categoryName={null} key={i} showDelayLate={false} />
              // return (
              //   <orderlistitem id={item.id} onClick={() => { this.OncLIck(item.id, item.customerId) }} className={`${this.props.ViewTravelid == item.id && "activedriverOrderInlist"}`}>
              //     <headerSection>
              //       <ordertag className={item.status || "new"}>
              //         <Trans i18nKey={item.status}></Trans>
              //       </ordertag>
              //       {/* <orderSource>
              //         <Trans i18nKey={item.source}> </Trans>
              //       </orderSource> */}
              //       <orderCode> {item.code}</orderCode>
              //     </headerSection>
              //     <pickupropoffsContainer>
              //       <pinsContainer style={{ height: "auto", borderLeftStyle: isEmpty(item.pickup) ? "unset" : "dotted" }}>
              //         {!isEmpty(item.pickup) && <circleBlue />}
              //         {((item.dropoffs &&!isEmpty(item.dropoffs) )|| ( item.dropoff && !isEmpty(item.dropoff) )) && <squre style={{ marginTop: isEmpty(item.pickup) ? "7px" : "20px" }} />}
              //       </pinsContainer>

              //       <addresss>
              //         {!isEmpty(item.pickup) && <pickupAddress>{item.pickup.address}</pickupAddress>}
              //         {((item.dropoffs &&!isEmpty(item.dropoffs) )|| ( item.dropoff && !isEmpty(item.dropoff) )) && <dropoffAddress>
              //           {item.dropoffs && item.dropoffs.length > 0 ? item.dropoffs[0].address : item.dropoff.address}
              //         </dropoffAddress>}
              //       </addresss>
              //     </pickupropoffsContainer>

              //     {item.type == "Ondemand" && item.dropoffs.length > 1 ? (
              //       <span className="otherdropoffs otherdropoffs_DriverList">
              //         {`+${item.dropoffs.length - 1}`}
              //       </span>
              //     ) : (
              //         ""
              //       )}
              //     {/* {item.dropoffs && item.dropoffs.length > 1 && (
              //       <moreDropoff>
              //         {`+${item.dropoffs.length - 1}`}{" "}
              //         <Trans i18nKey={"otherdropoffs"}> </Trans>
              //       </moreDropoff>
              //     )} */}

              //     {item.customer && (
              //       <driverDetails>
              //         <img src={driver} className="driver" />
              //         <span className="driverNumber"> {item.customer ? item.customer.phone : item.business.title}</span>
              //       </driverDetails>
              //     )}
              //   </orderlistitem>
              // );
            })}

          <div
            className={`ListTravelItemContainerPlaceHolder ${this.props.OrderListOrderedPage !== 0 &&
                this.props.OrderListLoading && this.ToArray().length == 0
                ? ""
                : "displayNone"
              }`}

            style={{ width: "273px" }}
          >
            <img alt="" src={pulse} className="pulse" />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  open: state.DriversList.open,
  OrderList: state.DriversList.OrderList,
  OrderListLoading: state.DriversList.OrderListLoading,
  OrderListOrdered: state.DriversList.OrderListOrdered,
  OrderListOrderedPage: state.DriversList.OrderListOrderedPage,
  EditOrderopen: state.EditOrder.OpenDialoge,
  NewOrderopen: state.NewOrder.OpenDialoge,
  ViewTravelopen: state.ViewTravel.OpenDialoge,
  list: state.DriversList.list,
  activeDriver: state.DriversList.activeDriver,
  ViewTravelid: state.ViewTravel.ID,
  cursor: state.OrderList.cursor,
  cursorSort: state.OrderList.cursorSort,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
