import { useState } from 'react';
import { Customer, CustomerP } from '../../../../../assets';
import SidebarButton from '../../../../../design-system/lib/SidebarButton';
import styles from './style.module.css';
import { ClickAwayListener, Paper, Slide } from '@material-ui/core';
import { get_name, get_username } from '../../../../../helper/UserData/userdate';
import SignOut from "../../../../../Entries/Main/components/Header/ProfileMenu/logout";


export const SidebarProfile= ({isOpen}:any) => {
    const [openMenu, setOpenMenu] = useState(false);


    return <> <div className={styles.SidebarNotification}> 
                   <SidebarButton size='full' type='Primary' disabled={false} activeIcon={CustomerP} deActiveIcon={CustomerP}  isActive={false} isOpen={isOpen} text='Dispatcher'  onClick={() => setOpenMenu(!openMenu)} />
    </div>
    
    <Slide
            direction="down"
            in={openMenu}
            mountOnEnter
            unmountOnExit
        >
            <Paper style={{position:"absolute"}}>
                <ClickAwayListener onClickAway={() => setOpenMenu(!openMenu)}>
                    <div className="ProfileMenuContainer">
                        <span
                            style={{ width: "100%", float: "left", marginTop: "16px" }}
                        >
                            <img className="USERProfileiMAGE" src={Customer} />
                            <span className="OpeartorName">
                                {get_name() || "Operator"}
                            </span>
                            <span className="OpeartorUserName">
                                {"@" + get_username() || "Operator"}
                            </span>
                        </span>

                        <SignOut />

                        {/* <Language /> */}
                    </div>
                </ClickAwayListener>
            </Paper>
        </Slide>
        
        </>

};




