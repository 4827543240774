import React from "react";
import L from "leaflet";
import { connect } from "react-redux";
import { Is_InProgress_Travel } from "../../helper/module";
import Card from "@material-ui/core/Card";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import { withTranslation, Trans } from "react-i18next";
import { SetEstimatedRoute } from "../../Redux/actions/actionNames";
import { EstimatedRouteAction } from "../../Redux/actions/actions";

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 41,
    height: 20,
    padding: 0,
    float: "left",
    margin: 0,
    marginTop: "8px",
  },
  switchBase: {
    padding: 1,
    float: "right",
    "&$checked": {
      transform: "translateX(+20px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#148be9",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  switchBasertl: {
    padding: 1,
    float: "right",
    right: -20,
    "&$checked": {
      transform: "translateX(-20px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#148be9",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  switchBaseltr: {
    padding: 1,
    float: "right",
    "&$checked": {
      transform: "translateX(+20px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#148be9",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 18,
    height: 18,
  },
  track: {
    borderRadius: 20 / 2,
    backgroundColor: "#646464",
    opacity: 1,
    transition: theme.transitions.create(["background-color"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes[`switchBase${props.direction ? props.direction : ""}`],
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleEstimated = () => {
    if (!this.props.EstimatedActive) {
      this.props.dispatch(
        EstimatedRouteAction(this.props.dispatch, { orderId: this.props.id })
      );
    }
    this.props.dispatch(
      SetEstimatedRoute({ active: !this.props.EstimatedActive })
    );
  };

  render() {
    if(this.props.type !== "Ondemand") {
      return null
    }
    return (
      <React.Fragment>
        {this.props
          .OpenVRDialoge /*&& Is_InProgress_Travel(this.props.status) */ && (
          <div className="estimatedRoute">
            <dash />
            <eSText>
              {" "}
              <Trans i18nKey={"Estimated"}> </Trans>{" "}
            </eSText>

            <IOSSwitch
              direction={this.props.direction}
              className="EstimatedSwitch"
              checked={this.props.EstimatedActive}
              onChange={this.handleEstimated}
              name="checkedMonitor"
            />
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  OpenVRDialoge: state.ViewTravel.OpenDialoge,
  status: state.ViewTravel.pureData ? state.ViewTravel.pureData.status : "",
  EstimatedActive: state.ViewTravel.estimatedRoute.active,
  EstimatedWaypoints: state.ViewTravel.estimatedRoute.waypoints,
  id: state.ViewTravel.ID,
  direction: state.LocalData.direction,

  type: state.ViewTravel.pureData
  ? state.ViewTravel.pureData.id
    ? state.ViewTravel.pureData.type
    : null
  : null,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
