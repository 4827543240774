import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TableRadioButtonFilter_Update } from '../Redux/actions/actionNames';
import { useTranslation } from 'react-i18next';




export function GetTableRadioButtonFilter(selector, PureList) {
  const [data, setData] = useState("");
  const [list, setList] = useState([]);

  const pureData = useSelector((state) => state.Table.filters[selector])
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();


  useEffect(() => {

    if (PureList) {
      let xx = []
      PureList.forEach(element => {
        xx.push({ key: element.id, value: element.title })
      }); 
      setList(xx)
    }

  }, []);

  useEffect(() => {

    if (pureData) {
      setData(pureData)
    }

  }, []);

  useEffect(() => {

    if (pureData) {
      setData(pureData)
    }

  }, [pureData]);


  const OnChange = React.useCallback((e) => {
    dispatch(TableRadioButtonFilter_Update({ key: selector, value: e }))
    setData(e)
  }, [data, pureData, selector]);


  return { [`${selector}_data`]: data, [`${selector}_OnChange`]: OnChange, [`${selector}_list`]: list };
};

