import styles from './style.module.css';


export const ListContainer = (props) => {

    return <div className={styles.listContainer}>
        {props.children}
    </div>

};




