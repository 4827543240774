import React from "react";
import { Marker, OverlayView } from '@react-google-maps/api';
import { connect } from "react-redux";
import { VehicletypesMonitoringDriversAction, TrackOrderDriverAction, GetDriverInfoAction, GetDriverOrderListAction } from "../../../../Redux/actions/actions";
// import { Marker as LeafletMarker } from "leaflet-rotatedmarker";
import { CanTrackDriver, getBaseTrackByUrl } from "../../../../helper/module";
import deliveryProfile from "../../../../assets/delivery-man.png";
import Pin from "../Pin";

import { SetActiveDriverList, SetDriverListOpenDialoge, SetDriverLocation, SetDriverPopUp } from "../../../../Redux/actions/actionNames";

const createtIcon = (data) => {
  return <Pin hasActiveOrder={true} isOnline={data.isOnline} driver={data} style={{marginTop:"-67px",marginLeft:"-39px",marginBottom:"9px"}} />
};

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      location: null,
      last_location: null,
      deg: 0,
    };
    this.intervall = null;
  }

  componentDidMount() {
    if (this.props.pureData && CanTrackDriver(this.props.status)) {
      this.props.dispatch(TrackOrderDriverAction(this.props.dispatch, this.props.id, (call) => {
        this.props.dispatch(SetDriverLocation(call.location,"1"))
        this.setState({
          data: call,
          location: call.location,
        });
      }))
      this.intervall = setInterval(() => {
        this.props.dispatch(TrackOrderDriverAction(this.props.dispatch, this.props.id, (call) => {
          this.props.dispatch(SetDriverLocation(call.location,"2"))

          this.setState({
            data: call,
            location: call.location,
          });
        }))

      }, 5000);
    }
  }

  componentWillReceiveProps(nextProps) {
    // if (
    //   nextProps.id !=
    //   this.props.id &&
    //   this.intervall
    // ) {
    //   clearInterval(this.intervall);
    // }


    if (
      CanTrackDriver(nextProps.status) &&
      nextProps.OpenVRDialoge &&
      !this.intervall
    ) {

      this.props.dispatch(TrackOrderDriverAction(this.props.dispatch, nextProps.id, (call) => {
        this.props.dispatch(SetDriverLocation(call.location,"3"))

        this.setState({
          data: call,
          location: call.location,
        });
      }))

      this.intervall = setInterval(() => {
        this.props.dispatch(TrackOrderDriverAction(this.props.dispatch, nextProps.id, (call) => {
          this.props.dispatch(SetDriverLocation(call.location,"4"))

          this.setState({
            data: call,
            location: call.location,
          });
        }))
      }, 5000);
    } else if(!CanTrackDriver(nextProps.status) && (this.intervall || !nextProps.OpenVRDialoge)) {
      this.props.dispatch(SetDriverLocation(null,"5"))

      clearInterval(this.intervall);
    }
  }

  componentWillUnmount() {
    this.props.dispatch(SetDriverLocation(null,"6"))
    clearInterval(this.intervall);

  }

  openProfile=()=>{
    // this.props.dispatch(SetDriverListOpenDialoge(false))
    this.props.dispatch(GetDriverInfoAction(this.props.dispatch, this.props.driverId))
    this.props.dispatch(SetDriverListOpenDialoge(true))
    this.props.dispatch(SetDriverPopUp(null))
    GetDriverOrderListAction(this.props.dispatch, {
      cursor: this.props.cursor,
      cursorSort: this.props.cursorSort,
      status: ["Assigned", "PickedUp", "Started", "Arrived"],
      size: 200,
      driverId: this.props.driverId,
    } , false)
  }

  render() {
    
    if (
      CanTrackDriver(this.props.status) &&
      this.state.data && this.props.driver_location
    ) {
      return (
        <React.Fragment>

          <OverlayView
            position={{
              lat: this.props.driver_location[1],
              lng: this.props.driver_location[0],
            }}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          >
            {/* {createtIcon(this.state.data)} */}
            <Pin hasActiveOrder={true} onClick={this.openProfile} isOnline={this.state.data.isOnline} driver={this.state.data} style={{marginTop:"-67px",marginLeft:"-39px",marginBottom:"9px"}} />
          </OverlayView>
          
        </React.Fragment>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state, ownProps) => ({
  OpenVRDialoge: state.ViewTravel.OpenDialoge,
  pureData: ownProps.VRpureData,
  status: state.ViewTravel.pureData ? state.ViewTravel.pureData.status : "",
  id: state.ViewTravel.ID,
  socket: state.Socket.socket,
  driver_location: state.ViewTravel.driver_location,
  driverId: state.ViewTravel.pureData ? state.ViewTravel.pureData.driverId : "",
  activeDriver: state.DriversList.activeDriver,
  cursor: state.OrderList.cursor,
  cursorSort: state.OrderList.cursorSort,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(mapStateToProps, mapDispatchToProps)(App);
