import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
// import * as Sentry from '@sentry/browser';
// import { Integrations } from "@sentry/tracing";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Opps from "./Entries/Opps";
import packageJson from "../package.json";
import { useTranslation } from "react-i18next";
import { useForceUpdate } from "./hooks";
import { RouteManager } from "./router";
import { SnackbarProvider } from "notistack";
import { SnackbarUtilsConfigurator, rtlLtrCssPatchSelector } from "./utils";
import { Provider, ErrorBoundary } from "@rollbar/react"; // Provider imports 'rollbar'
import { SetTime } from "./Redux/actions/actionNames";
import { BatchOperationalSnackbar } from "./components/shared/batch-operational-snackbar";


global.appVersion = packageJson.version;
global.local = packageJson.local;


const rollbarConfig = {

  accessToken: "16bf51bb7fef465d9414e8fadd61e0fc",
  captureUncaught: true,
  payload: {
    environment: "testenv",
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: packageJson.version,
      }
    }
  }
};




function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
    </div>
  );
}

const App = (props) => {
  const matches = useMediaQuery("(min-width:1000px)");
  const [ignoreMatch, setIgnoreMatch] = useState(true);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  let dir = i18n.dir();
  document.body.dir = dir;

  let horizontal = dir == "ltr" ? "right" : "left";
  useForceUpdate();
  rtlLtrCssPatchSelector(dir, i18n.language);

  useEffect(() => {
    const intervalId = setInterval(() => {
      dispatch(SetTime(new Date().getTime()));
    }, 1000)

    return () => clearInterval(intervalId);
  }, [])

  return (
    
        <SnackbarProvider
          maxSnack={5}
          anchorOrigin={{ horizontal :"center", vertical: "bottom" }}
          Components={{
            BatchOperationalSnackbar: BatchOperationalSnackbar
          }}
        >
          <SnackbarUtilsConfigurator />
          <div className="App">
            {matches || ignoreMatch ? (
              <RouteManager />
            ) : (
              <Opps setIgnoreMatch={setIgnoreMatch} />
            )}
          </div>
        </SnackbarProvider>

  );
};

export default App;
