import momentJalaali from "moment-jalaali";
import jalali from "jalali-moment";


const getDefaultRanges = (date, locale) => [

    {
        label: 'Today',
        from: {
            day: Number(momentJalaali(date).locale(locale).startOf("day").format(locale == "fa" ? "jDD" : "DD")),
            month: Number(momentJalaali(date).locale(locale).startOf("day").format(locale == "fa" ? "jMM" : "MM")),
            year: Number(momentJalaali(date).locale(locale).startOf("day").format(locale == "fa" ? "jYYYY" : "YYYY")),
            moment: Number(momentJalaali(date).locale(locale).startOf("day")),
        },
        to: {
            day: Number(momentJalaali(date).locale(locale).endOf("day").format(locale == "fa" ? "jDD" : "DD")),
            month: Number(momentJalaali(date).locale(locale).endOf("day").format(locale == "fa" ? "jMM" : "MM")),
            year: Number(momentJalaali(date).locale(locale).endOf("day").format(locale == "fa" ? "jYYYY" : "YYYY")),
            moment: Number(momentJalaali(date).locale(locale).endOf("day")),
        }
    },
    {
        label: 'Yesterday',
        from: {
            day: Number(jalali(date).locale(locale).add(-1, 'day').startOf("day").format('DD')),
            month: Number(jalali(date).locale(locale).add(-1, 'day').startOf("day").format('MM')),
            year: Number(jalali(date).locale(locale).add(-1, 'day').startOf("day").format('YYYY')),
            moment: Number(jalali(date).locale(locale).add(-1, 'day').startOf("day")),
        },
        to: {
            day: Number(jalali(date).locale(locale).add(-1, 'day').endOf("day").format('DD')),
            month: Number(jalali(date).locale(locale).add(-1, 'day').endOf("day").format('MM')),
            year: Number(jalali(date).locale(locale).add(-1, 'day').endOf("day").format('YYYY')),
            moment: Number(jalali(date).locale(locale).add(-1, 'day').endOf("day"))
        }
    },
    {
        label: 'ThisWeek',
        from: {
            day: Number(jalali(date).locale(locale).startOf("week").format('DD')),
            month: Number(jalali(date).locale(locale).startOf("week").format('MM')),
            year: Number(jalali(date).locale(locale).startOf("week").format('YYYY')),
            moment: Number(jalali(date).locale(locale).startOf("week"))
        },
        to: {
            day: Number(momentJalaali(date).locale(locale).endOf("week").format(locale == "fa" ? "jDD" : "DD")),
            month: Number(momentJalaali(date).locale(locale).endOf("week").format(locale == "fa" ? "jMM" : "MM")),
            year: Number(momentJalaali(date).locale(locale).endOf("week").format(locale == "fa" ? "jYYYY" : "YYYY")),
            moment: Number(momentJalaali(date).locale(locale).endOf("week"))
        }

    },
    {
        label: 'LastWeek',
        from: {
            day: Number(jalali(date).locale(locale).add(-7, "day").startOf("week").format('DD')),
            month: Number(jalali(date).locale(locale).add(-7, "day").startOf("week").format('MM')),
            year: Number(jalali(date).locale(locale).add(-7, "day").startOf("week").format('YYYY')),
            moment: Number(jalali(date).locale(locale).add(-7, "day"))
        },
        to: locale == "fa" ? {
            day: Number(jalali(date).locale(locale).endOf("week").format('DD')),
            month: Number(jalali(date).locale(locale).endOf("week").format('MM')),
            year: Number(jalali(date).locale(locale).endOf("week").format('YYYY')),
            moment: Number(jalali(date).locale(locale).endOf("week")),
        } :
            {
                day: Number(jalali(date).locale(locale).add(-7, "day").endOf("week").format('DD')),
                month: Number(jalali(date).locale(locale).add(-7, "day").endOf("week").format('MM')),
                year: Number(jalali(date).locale(locale).add(-7, "day").endOf("week").format('YYYY')),
                moment: Number(jalali(date).locale(locale).add(-7, "day").endOf("week")),
            }
    },
    {
        label: 'Last7Days',
        from: {
            day: Number(jalali(date).locale(locale).add(-7, "day").add(1, "hour").format('DD')),
            month: Number(jalali(date).locale(locale).add(-7, "day").add(1, "hour").format('MM')),
            year: Number(jalali(date).locale(locale).add(-7, "day").add(1, "hour").format('YYYY')),
            moment: Number(jalali(date).locale(locale).add(-7, "day").add(1, "hour")),
        },
        to: {
            day: Number(jalali(date).locale(locale).add(1, "day").endOf("D").format("DD")),
            month: Number(jalali(date).locale(locale).add(1, "day").endOf("D").format( "MM")),
            year: Number(jalali(date).locale(locale).add(1, "day").endOf("D").format( "YYYY")),
            moment: Number(jalali(date).locale(locale).add(1, "day").endOf("D")),
        }
    },
    {
        label: 'ThisMonth',
        from: {
            day: Number(jalali(date).locale(locale).startOf("month").format('DD')),
            month: Number(jalali(date).locale(locale).startOf("month").format('MM')),
            year: Number(jalali(date).locale(locale).startOf("month").format('YYYY')),
            moment: Number(jalali(date).locale(locale).startOf("month")),
        },
        to: {
            day: Number(jalali(date).locale(locale).endOf(locale == "fa" ? "jmonth" : "month").format('DD')),
            month: Number(jalali(date).locale(locale).endOf(locale == "fa" ? "jmonth" : "month").format('MM')),
            year: Number(jalali(date).locale(locale).endOf(locale == "fa" ? "jmonth" : "month").format('YYYY')),
            moment: Number(jalali(date).locale(locale).endOf(locale == "fa" ? "jmonth" : "month")),
        }
    },
    {
        label: 'LastMonth',
        from: {
            day: Number(jalali(date).locale(locale).add(-30, "day").startOf("month").format('DD')),
            month: Number(jalali(date).locale(locale).add(-30, "day").startOf("month").format('MM')),
            year: Number(jalali(date).locale(locale).add(-30, "day").startOf("month").format('YYYY')),
            moment: Number(jalali(date).locale(locale).add(-30, "day").startOf("month")),
        },
        to: {
            day: Number(jalali(date).locale(locale).add(-30, "day").endOf(locale == "fa" ? "jmonth" : "month").format('DD')),
            month: Number(jalali(date).locale(locale).add(-30, "day").endOf(locale == "fa" ? "jmonth" : "month").format('MM')),
            year: Number(jalali(date).locale(locale).add(-30, "day").endOf(locale == "fa" ? "jmonth" : "month").format('YYYY')),
            moment: Number(jalali(date).locale(locale).add(-30, "day").endOf(locale == "fa" ? "jmonth" : "month")),
        }
    },
    {
        label: 'Last3Month',
        from: {
            day: Number(jalali(date).locale(locale).add(-90, "day").format('DD')),
            month: Number(jalali(date).locale(locale).add(-90, "day").format('MM')),
            year: Number(jalali(date).locale(locale).add(-90, "day").format('YYYY')),
            moment: Number(jalali(date).locale(locale).add(-90, "day")),
        },
        to: {
            day: Number(momentJalaali(date).locale(locale).endOf("day").format(locale == "fa" ? "jDD" : "DD")),
            month: Number(momentJalaali(date).locale(locale).endOf("day").format(locale == "fa" ? "jMM" : "MM")),
            year: Number(momentJalaali(date).locale(locale).endOf("day").format(locale == "fa" ? "jYYYY" : "YYYY")),
            moment: Number(momentJalaali(date).locale(locale).endOf("day")),
        }
    },
];

export function defaultRanges(locale) {
    return getDefaultRanges(new Date(), locale)
}  
