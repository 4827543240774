import styles from './style.module.css';
import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import { useState } from 'react';
import { useRef } from 'react';
import Divider from '@material-ui/core/Divider';
import icsort from '../../../../../../../assets/ic-sort.svg';
import { SetOrderListCursor, SetOrderListCursorSort } from '../../../../../../../Redux/actions/actionNames';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';


export const SearchSort = () => {

    const [showpoper, setShowpoper] = useState(false);
    const anchorEl = useRef(null);
    const dispatch = useDispatch();
    const cursor = useSelector((state) => state.OrderList.cursor)
    const cursorSort = useSelector((state) => state.OrderList.cursorSort)
    const { t, i18n } = useTranslation();

    function handleshowpoper() {
        setShowpoper(!showpoper)
    }
    function setCursor(item) {
        if(item != cursor){
            dispatch(SetOrderListCursor(item))
        }
        handleshowpoper()
    }
    function setCursorSort(item) {
        if(item != cursorSort){
            dispatch(SetOrderListCursorSort(item))
        }
        handleshowpoper()
    }


    return <div className={styles.searchSort} ref={anchorEl}>

        <img src={icsort} className={styles.searchSortConditionIcon} onClick={handleshowpoper} />

            <div className={styles.titlesubContainer}>
                <span onClick={handleshowpoper} className={styles.searchSortTitle}> {t(cursor)} </span>
                <span onClick={handleshowpoper} className={styles.searchSortSubTitle}> {t(cursorSort)} </span>
            </div>

        <Popper
            open={showpoper}
            anchorEl={anchorEl.current}
            role={undefined}
            style={{
                zIndex: 6,
            }}
            transition
            disablePortal
        >
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    timeout={{ enter: 150, exit: 150 }}
                    style={{
                        transformOrigin:
                            placement === "bottom" ? "center top" : "center bottom",
                    }}
                >
                    <Paper style={{
                        zIndex: 6,
                        marginTop: "0",
                        border: "solid 1px rgba(128, 128, 128, 0.2)",
                        borderRadius: "10px",
                        boxShadow: "0 2px 8px 0 rgba(128, 128, 128, 0.24)",
                        padding: "0 0 16px 0",
                        width: "170px"
                    }}>
                        <ClickAwayListener onClickAway={handleshowpoper}>
                            <MenuList id="menu-list-grow" style={{ padding: 0 }}>
                                <p className={styles.listTitle}>Sort by</p>

                                <MenuItem
                                    onClick={() => {
                                        setCursor("createdAt")
                                    }}
                                    className={styles.sortListItemContainer}
                                >

                                    <span className={styles.itemTitle}> {t("createdAt")} </span>
                                    <div className={cursor == "createdAt" ? styles.fillCircle : styles.outlineCircle}>  </div>

                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        setCursor("dueDate")
                                    }}
                                    className={styles.sortListItemContainer}
                                >

                                    <span className={styles.itemTitle}> {t("dueDate")}</span>
                                    <div className={cursor == "dueDate" ? styles.fillCircle : styles.outlineCircle}>  </div>

                                </MenuItem>
                                <Divider style={{ margin: "7.5px 16px" }} />

                                <p className={styles.listTitle}>Sort type</p>

                                <MenuItem
                                    onClick={() => {
                                        setCursorSort("asc")
                                    }}
                                    className={styles.sortListItemContainer}
                                >

                                    <span className={styles.itemTitle}> {t("ascending")}</span>
                                    <div className={cursorSort == "asc" ? styles.fillCircle : styles.outlineCircle}>  </div>

                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        setCursorSort("desc")
                                    }}
                                    className={styles.sortListItemContainer}
                                >

                                    <span className={styles.itemTitle}> {t("descending")}</span>
                                    <div className={cursorSort == "desc" ? styles.fillCircle : styles.outlineCircle}>  </div>

                                </MenuItem>
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    </div>

};




