import React, { useCallback, useRef, useState } from 'react'
import styles from './style.module.css';
import Button from '../../../design-system/lib/Button';
import Typography from '../../../design-system/lib/Typography';
import IconProvider from '../../../design-system/lib/IconProvider';
import Avatar from '../../../design-system/lib/Avatar';
import { CALL, STAR } from '../../../assets';
import { useDispatch } from 'react-redux';
import { Voip_OutgoingCall } from '../../../Redux/actions/actions';
import { get_id } from '../../../helper/UserData/userdate';


function Driver({ phone, id, name, username, rate, avatarPhoto }) {

  const dispatch = useDispatch();



  const makeOutgoingCall = React.useCallback(() => {
    dispatch(Voip_OutgoingCall(dispatch, {
      "to": phone,
      "orderId": null,
      "customerId": null,
      "driverId": id,
      "dispatcherId": get_id()
    }))

  }, [id]);



  return (
    <div className={styles.selectedUserContainer}>

      <div className={styles.avatarContainer}>
        <Avatar type='customer' style={{ marginTop: "-3px" }} imageUrl={avatarPhoto} />
        <span className={styles.IconProvider}><IconProvider Icon={STAR} size='12' style={{ width: "10px" }} /> <Typography weight='Body_Middle_Medium' text={rate} />  </span>
      </div>

      <div className={styles.nameUsernameContainer}>
        <Typography weight='Body_Middle_Medium' text={name} />
        <Typography weight='Subtitle_Tiny_Regular' text={username} />
      </div>


      {global.config.dispatcherSettings.isVoipActvie && <Button size={'Medium'} type='Secondary' icon={CALL} style={{ marginInlineStart: "auto" }} onClick={makeOutgoingCall} />}


    </div>
  )
}


export default Driver