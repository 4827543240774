import React from "react";
import Osm from "./osm/index";
import Google from "./googleMaps/index";



export class MapContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mapReady: false
        }
        this.intervalMap = null
    }

    componentDidMount() {
        if (!global.config.dispatcherSettings?.applicationMap) {

            this.intervalMap = setInterval(() => {
                if (global.config.dispatcherSettings?.applicationMap) {
                    clearInterval(this.intervalMap)
                    this.intervalMap = null;
                    this.setState({
                        mapReady: true
                    })
                }
            }, 200);
        } else {
            this.setState({
                mapReady: true
            })
        }
    }


    childrenWithProps = () => {
        return React.Children.map(this.props.children, child => {
            if (React.isValidElement(child)) {
                return React.cloneElement(child, { mapType: global.config.dispatcherSettings.applicationMap ? global.config.dispatcherSettings.applicationMap.type : null  });
            }
            return child;
        });
    }


    render() {

        // return <Osm {...this.props}>
        //     {this.childrenWithProps()}
        // </Osm>

        // return <Google  {...this.props}>
        //     {this.childrenWithProps()}
        // </Google>


        if (this.state.mapReady) {

            if (global.config.dispatcherSettings.applicationMap.type == "google") {

                return <Google {...this.props}>
                    {this.childrenWithProps()}
                </Google>
            } else {
                return <Osm {...this.props}>
                    {this.childrenWithProps()}
                </Osm>
            }
        } else {
            return (null)
        }

    }
}


