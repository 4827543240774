import React from "react";
import ReactDOMServer from "react-dom/server";
import { Map, Marker, TileLayer, Popup, Polyline } from "react-leaflet";
import L from "leaflet";
import { connect } from "react-redux";
import event from "../../../../assets/pin_event.svg";

const createtIcon = (icon) => {
  return new L.DivIcon({
    html: ReactDOMServer.renderToString(<div class="circle-ripple"></div>),
    iconAnchor: [0, 0],
    popupAnchor: [0, -35],
    iconSize: [8, 8],
    shadowSize: [8, 8],
    shadowAnchor: [7, 40],
  });
};

class App extends React.Component {
  render() {
    let showMarker = this.props.OpenVRDialoge && this.props.eventTimeLine && this.props.activeEventCordinator;
    if (!showMarker) {
      return null;
    } else {
      return (
        <React.Fragment>
          <Marker
            key={"CircleRipple"}
            animate={true}
            position={[
              this.props.activeEventCordinator[1],
              this.props.activeEventCordinator[0],
            ]}
            icon={createtIcon()}
          ></Marker>

        </React.Fragment>
      );
    }
  }
}

const mapStateToProps = (state, ownProps) => ({
  OpenVRDialoge: state.ViewTravel.OpenDialoge,
  eventTimeLine: state.ViewTravel.eventTimeLine.show,
  activeEventCordinator: state.ViewTravel.eventTimeLine.activeEventCordinator,

});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(mapStateToProps, mapDispatchToProps)(App);