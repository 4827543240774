import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './Reorder.module.css';
import { useLocation, useParams } from "react-router-dom";
import HeaderReorder from '../../../components/reorder/header';
import FooterReorder from '../../../components/reorder/footer';
import ListItemsReorder from '../../../components/reorder/listItems';


export function ReOrder({data , setData , setIsreorder}) {

    let { id } = useParams();

    return <div className={styles.detailsContainer}>
                <HeaderReorder  data={data} setData={setData}  setIsreorder={setIsreorder}/>
                <ListItemsReorder data={data} items={data.stops} setData={setData}  setIsreorder={setIsreorder}/>
                <FooterReorder data={data} items={data.stops} setData={setData}  setIsreorder={setIsreorder} />
        </div>

}

