import { getDirectionByUrl } from "../helper/module";
import i18n from 'i18next';
import {store} from '../Redux/store';


export const changeDirection = () => {    
    store.dispatch({
        type: "changeDirection",
        direction: getDirectionByUrl(),
        fullDirection: getDirectionByUrl(),
        lang: global.config.Lang
    })
    i18n.changeLanguage(global.config.Lang);
};

 

export const rtlLtrCssPatchSelector = (direction= "ltr" , lang = 'en') => {
    let patch = null
    if(['fr'].includes(lang)){
      // patch exist
      patch = lang;
    }
  
    window["toggleTheme"](direction , patch)
  
  };
  