import logoType from "../../../assets/Superkiwi.png";
import FavIcon from "../../../assets/PROJECTS/Superkiwi_fav_icon.png";

export default function Superkiwi() {
    
    return global.config = {
    "ProjectName": "Superkiwi",
    "LogoType": logoType,
    "BaseApi": "https://rest.sklogistics.do",
    "BaseSocket": "https://socket.sklogistics.do",
    "BaseTrack": "https://panel.sklogistics.do",
    "MapCenter": {
        "lat": "-33.448735182836245",
        "lng": "-70.65675064128112"
    },
    "Lang": "es",
    "Direction": "ltr",
    "AllLanguages": [
        {
            "lang": "es",
            "direction": "ltr",
            "i18Lang": "spanish",
        },
        {
        "lang": "en",
        "direction": "ltr",
        "i18Lang": "English"
    }],
    "FavIcon": FavIcon,
    "Country": "DO",
    "thirdPartyLoginSupport": true  ,
    mapType :"osm", /* osm | google */ 
    googleApiKey : ""
} }