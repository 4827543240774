import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';


export function useLocalServices(serviceId) {
  const [service, setService] = useState(null);
  const services = useSelector((state) => state.SharedData.services)

  useEffect(() => {

    if (serviceId && services) {
      services.forEach(element => {
        if (element.id == serviceId) {
          setService(element)
        }
      });
    }


  }, [services, serviceId]);

  return service;
};




