import io from "socket.io-client";
import { get_id, get_lang, get_refreshToken } from "../../helper/UserData/userdate";
import {
  SetSocket,
  UpdateTravelInList,
  DeleteTravelInList,
  SetVoipIncommingCall,
  SetDriverListloadingOrderedPage,
  UpdateOrdersCountInDriversList,
  ResetOrderListData,
  SetDriverListData,
  SetSettings,
} from "../../Redux/actions/actionNames";
import {
  CheckThisTravelIsActive,
  CheckThisTravelIsActiveWithOutOrder,
  DeleteOrderFromLIST,
} from "./helper";
import {
  getBaseSocketByUrl,
  CheckSocketOrderState,
  CheckSocketOrderOrderType,
  CheckOrdersToUpdatePANDD,
} from "../../helper/module";
import { OrderInfoAction } from "../../Redux/actions/actions";
import packageJson from '../../../package.json';
import { UpdateAddOrderBySocket } from "../../utils/update-add-order-bySocket";
import { UpdateOrdersStatistics } from "../../utils/update-ordersStatistics";
import { MatchOrderWithFiltersList } from "../../utils/MatchingOrderDataWithOrderListFilters-bySocket";
import { UpdateOrdersDriverList } from "../../utils/update-orders-driverList";
import { UpdateDriversStatistics } from "../../utils/update-driverStatistics";
import { store } from "../../Redux/store";
import { getDriverList } from "../../components/main/driver-list/components/drivers-list/action/getDriverList";
import { DispatcherApi } from "../API/dispatcher";

const socketUrl = global.config.BaseSocket;

export const setupSocket = (dispatch) => {



  const socket = io(socketUrl, {
    secure: true,
    query: {
      dispatcherId: get_id(),
      language: get_lang(),
      apiVersion: "v1",
      apiSource: 'web',
      appVersionCode: String(packageJson.version),
      appVersionName: String(packageJson.version)
    },
    reconnection: true,
    reconnect_attempt: 99999,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 1000,
    autoConnect: true,
    transports: ["websocket"],
  });
  let tryConnection = 3;
  var reasonOfDisconnect = null;



  socket.on("connect", (res) => {
    console.log("setupSocket -> connect ", res);
    dispatch(SetSocket(socket));
    if (reasonOfDisconnect) {
      dispatch(ResetOrderListData())
      dispatch(SetDriverListData([]));

      UpdateDriversStatistics()
      getDriverList()
      UpdateOrdersStatistics({}, true)

      reasonOfDisconnect = null
    }
  });

  socket.on("disconnect", (reason) => {
    console.log("setupSocket -> disconnect ", reason);

    if (reason === "ping timeout") {
      reasonOfDisconnect = "net";
    }
    if (reason === "transport close" || reason === "io server disconnect") {
      reasonOfDisconnect = "server";
    }
  });

  socket.on("connect_error", (res) => {
    console.log("setupSocket -> connect_error ", res);

    if (tryConnection === 1) {
      if (reasonOfDisconnect === "net") {
      }

      if (reasonOfDisconnect === "server") {
      }
    }
    if (tryConnection > 0) {
      tryConnection--;
    } else {
      if (navigator.onLine && reasonOfDisconnect === "net") {
        reasonOfDisconnect = "server";
      }

      if (!navigator.onLine && reasonOfDisconnect === "server") {
        reasonOfDisconnect = "net";
      }
    }
  });


  socket.on("dispatcher:general-setting:updated", async (res) => {
    console.log("setupSocket -> dispatcher:general-setting:updated", res);
    const Dispatcher = new DispatcherApi();
    const data = await Dispatcher.Setting({ userId: get_id(), refreshToken: get_refreshToken() });
    dispatch(SetSettings(data))
  });

  socket.on("Error ", (res) => { });


  socket.on("dispatcher:order:updated", (res) => {
    // UpdateOrdersStatistics()
    console.log("setupSocket -> res", res);


    CheckThisTravelIsActiveWithOutOrder(res.data, (call) => {
      if (call) {
        dispatch(
          OrderInfoAction(dispatch, {
            orderId: res.data.id,
          })
        );
      }
    });
    

    MatchOrderWithFiltersList(res.data, (callx) => {
      if (callx) {
        console.log("MatchOrderWithFiltersList callx", callx)
        CheckOrdersToUpdatePANDD(res.data)

        UpdateAddOrderBySocket(res.data)
        // IncreaseDecreaseStatsBySocket(res.data)
      }

    })

    UpdateOrdersDriverList(res.data)



  });


  socket.on("dispatcher:voip:incoming-call", (res) => {
    console.log("voip:incoming-call-socket -> res", res);

    dispatch(SetVoipIncommingCall(res.data))

  });


  socket.on("dispatcher:route:updated", (res) => {
    console.log("setupSocket -> dispatcher:route:updated ", res);

    if(isRoutesOpen()){
      const myEvent = new CustomEvent("routeUpdated");
      document.dispatchEvent(myEvent);
    }


  });

  socket.on("dispatcher:stop:updated", (res) => {
    console.log("setupSocket -> dispatcher:stop:updated ", res);

    if(isStopOpen(res.data.stopId)){
      const myEvent = new CustomEvent("stopUpdated",{
        detail: res.data
      });
      document.dispatchEvent(myEvent);
    }else if(isRoutesOpen(res.data.routeId)){
      const myEvent = new CustomEvent("routeUpdated");
      document.dispatchEvent(myEvent);
    }



  });



  socket.on("dispatcher:driver:updated", (res) => {
    console.log("dispatcher:driver:updated", res);
    let storeData = store.getState();


    if (storeData.DriversList.openDialoge){
      UpdateDriversStatistics();
    }

    if (storeData.DriversList.openDialoge && storeData.DriversList.activeDriver) {
      store.dispatch(UpdateOrdersCountInDriversList(res.data))

      if (storeData.DriversList.activeDriver.id == res.data.id) {
        store.dispatch(
          SetDriverListloadingOrderedPage({
            driverInfo: res.data,
            activeDriver: res.data,
          })
        );
      }
    }

    





  });

  return socket;
};



const isRoutesOpen = () => {
  if (String(window.location.href).includes("route")) {
    return true
  } else {
    return false
  }
}


const isStopOpen = (stopId ) => {
  if (String(window.location.href).includes(String(stopId ))) {
    return true
  } else {
    return false
  }
}