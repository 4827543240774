import styles from './style.module.css';
import { Stats } from "./components/stats"
import { SearchBar } from "./components/search-bar"
import { ListContainer } from "./components/list-container"
import { CategoryCollapseList } from "./components/category-collapse-list"
import { SearchHandlerList } from "./components/search-handler-list"
import { SearchNoresult } from "./components/search-no-result"
import { useDispatch, useSelector } from 'react-redux';
import pending from "../../../../src/assets/ic-pending.svg";
import collecting from "../../../../src/assets/ic-collecting.svg";
import progress from "../../../../src/assets/ic-in-progress-voip.svg";
import delivering from "../../../../src/assets/ic-delivering.svg";
import canceled from "../../../../src/assets/ic-canceled.svg";
import pin from "../../../../src/assets/ic-pin.svg";
import done from "../../../../src/assets/ic-done.svg";
import transit from "../../../../src/assets/ic-in-transit.svg";
import dispatch_later from "../../../../src/assets/dispatch_later.svg";
import dispatching from "../../../../src/assets/dispatching.svg";
import unassigned from "../../../../src/assets/unassigned.svg";
import delay from "../../../../src/assets/ic-timer-delay.svg";
import { useEffect } from 'react';
import { SetOrderListStatsData } from '../../../Redux/actions/actionNames';
import { UpdateOrdersStatistics } from '../../../utils/update-ordersStatistics';

export const OrderList = () => {
    const stats = useSelector((state) => state.OrderList.stats.data)
    const categories = useSelector((state) => state.OrderList.categories)
    const dispatch = useDispatch();

    

    return <div className={styles.orderListContainer}>
        <Stats />
        <ListContainer>
            <SearchBar />
            {stats && <>
                <CategoryCollapseList categoryName="Pinned" canPinOrder={false} canUnPinOrder={true} openDefault={true} stats={stats} icon={pin} />
                <CategoryCollapseList categoryName="Delayed" canPinOrder={true} canUnPinOrder={false} openDefault={true} stats={stats} icon={delay} />
                <CategoryCollapseList categoryName="Unassigned" canPinOrder={true} canUnPinOrder={false} openDefault={true} stats={stats} icon={unassigned} />
                <CategoryCollapseList categoryName="DispatchLater" canPinOrder={true} canUnPinOrder={false} openDefault={false} stats={stats} icon={dispatch_later} />
                <CategoryCollapseList categoryName="Dispatching" canPinOrder={true} canUnPinOrder={false} openDefault={true} stats={stats} icon={dispatching} />
                <CategoryCollapseList categoryName="Pending" canPinOrder={true} canUnPinOrder={false} openDefault={true} stats={stats} icon={dispatching} />
                {/* <CategoryCollapseList categoryName="Assigned" canPinOrder={true} canUnPinOrder={false} openDefault={true} stats={stats} icon={collecting} /> */}
                <CategoryCollapseList categoryName="InProgress" canPinOrder={true} canUnPinOrder={false} openDefault={true} stats={stats} icon={progress} />
                <CategoryCollapseList categoryName="Collecting" canPinOrder={true} canUnPinOrder={false} openDefault={true} stats={stats} icon={collecting} />
                <CategoryCollapseList categoryName="Delivering" canPinOrder={true} canUnPinOrder={false} openDefault={true} stats={stats} icon={delivering} />
                <CategoryCollapseList categoryName="Transit" canPinOrder={true} canUnPinOrder={false} openDefault={true} stats={stats} icon={transit} />
                <CategoryCollapseList categoryName="Canceled" canPinOrder={true} canUnPinOrder={false} openDefault={false} stats={stats} icon={canceled} />
                <CategoryCollapseList categoryName="Done" canPinOrder={true} canUnPinOrder={false} openDefault={false} stats={stats} icon={done} />
          
            </>}

            <SearchHandlerList />

            <SearchNoresult stats={stats} />

        </ListContainer>

    </div>

};




