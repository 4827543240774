import onro from "../../../assets/saas/onro/icon/ic_Logo.svg";
import FavIcon from "../../../assets/PROJECTS/onro.png";

export default function onroORG2() {
    
return global.config = {
        "ProjectName": "آنرو",
        "LogoType": onro,
        "BaseApi": "https://2rest.onro.org",
        "BaseSocket": "https://2socket.onro.org",
        "BaseTrack": "https://2panel.onro.org",
        "MapCenter": {
            "lat": "35.694654",
            "lng": "51.404343"
        },
        "Lang": "en",
        "Direction": "ltr",
        "AllLanguages": [
            {
                "lang": "de",
                "direction": "ltr",
                "i18Lang": "German"
            },{
            "lang": "en",
            "direction": "ltr",
            "i18Lang": "English"
        }, {
            "lang": "el",
            "direction": "ltr",
            "i18Lang": "Greek"
        },{
            "lang": "fa",
            "direction": "rtl",
            "i18Lang": "Persian"
        }, {
            "lang": "fr",
            "direction": "ltr",
            "i18Lang": "French"
        }, {
            "lang": "ro",
            "direction": "ltr",
            "i18Lang": "Romanian"
        },{
            "lang": "pt",
            "direction": "Ltr",
            "i18Lang": "Portuguese"
        }],
        "FavIcon": FavIcon,
        "Country": "US",
        "thirdPartyLoginSupport": true ,
        mapType :"google", /* osm | google */ 
        googleApiKey : "AIzaSyB2fDfV_Pu3XI1KtkDDp9Mgd88OxX9VtSs"
    }

}