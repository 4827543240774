export const Divider = ({className}) => {
    return <span className={className}
    style={{
        width: '1px',
        height: '36px',
        flexGrow: 0,
        // transform: rotate('-270deg')
        backgroundColor: '#f4f4f7'
    }}
    ></span>

};