import PickGoPickGo from "../../../assets/Geshikt_logotype.png";
import FavIcon from "../../../assets/PROJECTS/Geshikt.png";

export default function Geshikt() {
    
    return global.config = {
    "ProjectName": "Geshikt",
    "LogoType": PickGoPickGo,
    "BaseApi": "https://rest.geshikt.be",
    "BaseSocket": "https://socket.geshikt.be",
    "BaseTrack": "https://admin.geshikt.be",
    "MapCenter": {
        "lat": 51.235121623532386,
        "lng": 4.41751081005547
    },
    "Lang": "en",
    "Direction": "ltr",
    "AllLanguages": [{
        "lang": "en",
        "direction": "ltr",
        "i18Lang": "English"
    }, {
        "lang": "nl",
        "direction": "ltr",
        "i18Lang": "Dutch"
    }],
    "FavIcon": FavIcon,
    "Country": "BE",
    "thirdPartyLoginSupport": true  ,
    mapType :"osm", /* osm | google */ 
    googleApiKey : ""
}}