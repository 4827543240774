import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import { CanEditEarning, CanEditTravel } from "../../../../../../../helper/module";
import { Edit_Travel_Operate_Changed } from "../../../../../../../Redux/actions/actionNames";
import price from "../../../../../../../assets/ic-edit-price.svg";
import cancell from "../../../../../../../assets/ic-close.svg";
import Dialog from "@material-ui/core/Dialog";
import {
  get_currencyCode,
  get_id,
  get_lang,
} from "../../../../../../../helper/UserData/userdate";
import {
  OrderEditEarningAction,
  OrderEditondemandAction,
} from "../../../../../../../Redux/actions/actions";
import { isEmpty, toArray } from "lodash";
import Typography from "../../../../../../../design-system/lib/Typography";
import Input from "../../../../../../../design-system/lib/Input";
import styles from './style.module.css';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      earning: null,
      priceChange: false,
      active: 1,
    };
  }

  savePrice = () => {
    if (!isEmpty(this.state.earning)) {
      this.props.dispatch(
        OrderEditEarningAction(
          this.props.dispatch,
          {
            dispatcherId: get_id(),
            earning: this.getEarningForSave(),
          },
          this.props.Data.id
        )
      );
      this.props.close()
    }
  };

  getEarningForSave = () => {
    let x = {};

    this.getEarningList().map((item) => {
      x[item.id] = { amount: Number(item.amount) };
    });

    return x;
  };

  cansaveEarning = () => {
    if (!isEmpty(this.state.earning)) {
      return true;
    } else {
      return false;
    }
  };

  handleTab = (num) => {
    this.setState({
      active: num,
    });
  };

  //   EditpriceOnChange = () => {
  //     if (this.state.priceChange) {
  //       this.setState({
  //         priceChange: !this.state.priceChange,
  //         surcharges: [],
  //         fee: "0",
  //       });
  //     } else {
  //       this.setState({
  //         priceChange: !this.state.priceChange,
  //         surcharges: this.props.surcharges,
  //         fee: this.props.baseFarePrice,
  //       });
  //     }
  //   };

  componentDidMount() {
    this.setState({
      earning: this.props.Data.earning,
    });
  }

  getEarningList = () => {
    if (!isEmpty(this.state.earning)) {
      return Object.keys(this.state.earning).map((key) => ({
        id: key,
        ...this.state.earning[key],
      }));
    } else {
      return [];
    }
  };

  EROnchange = (id, value) => {
    this.setState({
      earning: {
        ...this.state.earning,
        [id]: {
          ...this.state.earning[id],
          amount: value,
        },
      },
    });
  };

  render() {
    const { t } = this.props;
    let CanEditOrder = CanEditEarning(this.props.Data.type , this.props.Data.status);

    if (CanEditOrder && !this.props.noC) {
      return (
        <React.Fragment>

<div className={styles.bodyInContainer} style={{maxHeight:"300px",overflowY:"auto",display:"block"}}>
            <div className={styles.bodyInSurchargeContainer}>
            {this.getEarningList().map((item, i) => {
              return (
                <div className={styles.bodyInSurchargeItemContainer}>
                <Typography weight="Body_Small_Regular" text={`${item.title} 
                ${!!this.props.workingTypeId ? ((this.props.workingTypeId && this.props.workingTypeId == item.id) ?  `(${this.props.t("Current")})` :"") : ""}
                `} textColor="TextNormal" />
                <Input  showCloseIcon={false}  type={"number"} value={item.amount} onChange={(e) => {
                        this.EROnchange(item.id, e.target.value) }}
                        style={{ cursor:item.isEditable ? "" : "not-allowed" }}
                        disabled={!item.isEditable} />
              </div> 


              );
            })}





            </div>



          </div>

 

          <div
           className="footer"
           style={{ bottom: "0px",marginTop:"6px" ,backgroundColor:"white",border:"unset"}}
          >
            <sendOrder
              className={`${!this.cansaveEarning() && "grey"}`}
              style={{ marginTop: "17px"  ,padding:"7.5px 16px",width:"unset",height:"unset"}}
              onClick={this.savePrice}
            >
              <Trans i18nKey={"save"}> </Trans>
            </sendOrder>
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div
            style={{
              float: "left",
              width: "100%",
              height: "296px",
              overflowY: "auto",
            }}
          >
            {this.getEarningList().map((item, i) => {
              return (
                <priceDetail>
                  <titles> {`${item.title} 
                  ${!!this.props.workingTypeId ? ((this.props.workingTypeId && this.props.workingTypeId == item.id) ?  `(${this.props.t("Current")})` :"") : ""}
                  `} : </titles>
                  
                  <value>
                    {Number(item.amount).toLocaleString(get_lang(), {
                      style: "currency",
                      currency: get_currencyCode(),
                    })}
                  </value>
                </priceDetail>
              );
            })}

            {this.getEarningList().length == 0 && (
              <span className="NotravelsListHeader">
                <Trans i18nKey={"Noinformationfound"}> </Trans>
              </span>
            )}
          </div>
        </React.Fragment>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  Data: state.ViewTravel.pureData ? state.ViewTravel.pureData : false,
  workingTypeId :  state.ViewTravel.pureData ? state.ViewTravel.pureData ? state.ViewTravel.pureData.driver ? state.ViewTravel.pureData.driver.workingTypeId || null : null : null : null 
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
