import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styles from './style.module.css';
import useForceUpdate from 'use-force-update';
import { useEffect } from 'react';


export const Service = ({selectedService , ChangeService}) => {
    const services = useSelector((state) => state.SharedData.services)
    const { t, i18n } = useTranslation();
    const forceUpdate = useForceUpdate();


    function serviceIsSelected(service) {
        return selectedService.findIndex((iservice) => {return iservice == service.id}) > -1
    }

    function selectService(service) {
        let newStatus = selectedService

        if (serviceIsSelected(service)) {
            ChangeService(newStatus.filter((istatus) => { return istatus != service.id}))
            forceUpdate()
        } else {
            newStatus.push(service.id)
            ChangeService(newStatus)
            forceUpdate()
        }
    }



    return <div className={styles.container} >
    <p className={styles.filterTilte}>Service</p>
    <div className={styles.itemContainer}>
        {services.map((service , i)=>{
            return <span key={i} onClick={() => { selectService(service) }}  className={styles.item+" "+(serviceIsSelected(service) && styles.activeItem)}> {service.title} </span>
        })}
    </div>
    
</div>

};




