import styles from './style.module.css';
import iconsearch from '../../../../../assets/icon-ic-search.svg';
import closeiconsearch from '../../../../../assets/ic-close.svg';

import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash.debounce';
import { useCallback, useEffect, useState } from 'react';
import { SetDriverListData, SetDriverListloadingOrderedPage, SetDriverListRegex } from '../../../../../Redux/actions/actionNames';
import { DriverApi } from '../../../../../service/API/driver';
import Input from '../../../../../design-system/lib/Input';
import { LearnMore } from '../../../../learnMore';

export const SearchInput = () => {
    const dispatch = useDispatch();
    const regex = useSelector((state) => state.DriversList.regex)
    const isOnline = useSelector((state) => state.DriversList.isOnline)

  

    const { t } = useTranslation();

    function search(e){
       (e && e.target?.value )  && dispatch(SetDriverListRegex(e.target.value))
        // String(e.target.value).length > 0 && requestSearch(e.target.value)
        if(!e || !e.target?.value) {
            dispatch(SetDriverListRegex(""))
        }
    }


   async function closeSearch(e){
    dispatch(SetDriverListData({
      "drivers": [],
      "startCursor": null,
      "endCursor": null,
      "nextCursor": null
  }))
    dispatch(SetDriverListRegex(""))

    //   let params ={
    //     page : 1,
    //     perpage : 40 ,
    //     isOnline : isOnline 
    // }
    // dispatch(
    //     SetDriverListloadingOrderedPage({
    //       DriverlistLoading: true ,
    //       DriverlistPage : 1 ,
    //       Driverlist: [],
    //       canLoadmore: false
    //     })
    //   );
    // let Driver = new DriverApi()
    // let data = await Driver.List(params)
    // dispatch(SetDriverListData(data.data))
    // dispatch(
    //     SetDriverListloadingOrderedPage({
    //       DriverlistLoading: false ,
    //       DriverlistPage : 1,
    //       canLoadmore: data.data.length == 40
    //     })
    //   );
    }


    async function requestSearch(value){
        // let params ={
        //     page : 1,
        //     perpage : 40 ,
        //     regexText : value
        // }
        // dispatch(
        //     SetDriverListloadingOrderedPage({
        //       DriverlistLoading: true ,
        //       DriverlistPage : 1 ,
        //       Driverlist: [],
        //       canLoadmore: false
        //     })
        //   );
        // let Driver = new DriverApi()
        // let data = await Driver.List(params)
        // dispatch(SetDriverListData(data.data , true))
        // dispatch(
        //     SetDriverListloadingOrderedPage({
        //       DriverlistLoading: false ,
        //       DriverlistPage : 1,
        //       canLoadmore: data.data.length == 40
        //     })
        //   );
    }
 
    
    return  <div className={styles.searchInput}>
        <Input type={"text"} ContainerclassName={styles.inputContainerclassName} placeHolder={t('Search')} value={regex} onChange={search} />

        <div className={styles.divider} />
      <LearnMore where={"Drivers"} hastext={false} placement={"bottomStart"} itemRowClassName={styles.LearnMore} />
    </div>
    //

    //     <img src={iconsearch} className={styles.iconSearchInput}/>
    //     <input placeholder={t('Search')} value={regex} onChange={search} className={styles.searchInputText} />
    //     {String(regex).length > 0 && <img src={closeiconsearch} className={styles.closeiconsearch} onClick={closeSearch}/>}

    // 

};




