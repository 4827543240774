import { debounce, isEmpty, throttle } from 'lodash';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UpdateCategoryOrdersListSorted } from '../Redux/actions/actionNames';
import { SortToArrayCategoryListData } from '../service/worker/SortToArrayCategoryListData';


const useThrottledDispatch = () => {
  const dispatch = useDispatch();
  const throttled = debounce(
    (v) => {
      // comment this out to throttle
      dispatch(v);
    },
    200,
    { leading: false, trailing: true }
  );
  return useCallback(
    (value) => {
      throttled(UpdateCategoryOrdersListSorted(value));
    },
    [throttled]
  );
};


export function GetDriverListData({ cursor, cursorSort }) {
  const [data, setData] = useState([]);
  const [workerStatic, setWrkerStatic] = useState(null);
  const pureData = useSelector((state) => state.DriversList.Driverlist.drivers)
  const dispatch = useThrottledDispatch();







  useEffect(() => {
    if (isEmpty(data) && isEmpty(pureData)) {

    } else {
      SortToArrayCategoryListData(workerStatic, pureData, cursor, cursorSort, (worker) => {
        setWrkerStatic(worker)
      }, (call) => {
        setData(call)
      })

    }


  }, [pureData]);

  return { data };
};

