import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import { EditTravelFeeChange, EditTravelSurchargesChange, Edit_Travel_Operate_Changed } from "../../../../../Redux/actions/actionNames";
import { get_currencyCode, get_lang } from "../../../../../helper/UserData/userdate";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      surcharge: [],
    };
  }

  feeOnchange = (e) => {
    this.props.dispatch(Edit_Travel_Operate_Changed());

    this.props.dispatch(EditTravelFeeChange(e.target.value));
  };

  SurchargesOnchange = (e, id) => {
    this.props.dispatch(Edit_Travel_Operate_Changed());

    this.props.dispatch(
      EditTravelSurchargesChange({
        id,
        price: e.target.value,
      })
    );
  };

  render() {
    const { t } = this.props;
    return (
      <priceDetails>
        <titlee>
          <Trans i18nKey={"Pricedetails"}> </Trans>
        </titlee>
        <stuffContainer>
          <leftStuff>
            <rowStuff>
              <textt>
                {" "}
                <Trans i18nKey={"Fee"}> </Trans> :
              </textt>
              <dolar>
                
                 {String(Number(0).toLocaleString(get_lang(), { style:"currency", currency:get_currencyCode()})).replace(/[0-9]/g, '')}</dolar>
              <input
                onChange={this.feeOnchange}
                value={this.props.baseFarePrice}
              />
            </rowStuff>
          </leftStuff>
          {this.props.surcharges.length > 0 && (
            <rightStuff>
              {this.props.surcharges.map((item, i) => {
                return (
                  <rowStuff>
                    <textt>
                      {" "}
                      {`${item.title}`} :
                    </textt>
                    <dolar> {String(Number(0).toLocaleString(get_lang(), { style:"currency", currency:get_currencyCode()})).replace(/[0-9]/g, '')}</dolar>
                    <input
                      onChange={(e) => {
                        this.SurchargesOnchange(e, item.id);
                      }}
                      value={item.price}
                    />
                  </rowStuff>
                );
              })}
            </rightStuff>
          )}
        </stuffContainer>
      </priceDetails>
    );
  }
}

const mapStateToProps = (state) => ({
  pureData: state.ViewTravel.pureData ? state.ViewTravel.pureData : null,
  baseFarePrice : state.EditOrder.baseFarePrice ,
  surcharges: state.EditOrder.surcharges ,
  lang :state.LocalData.lang ,

});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
