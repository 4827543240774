import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { ChangeNewTravelDialoge, ResetNewOrder, OpenCloseEditTravel, Edit_Travel_Reset } from "../../../../../Redux/actions/actionNames";
import { withTranslation, Trans } from "react-i18next";
import { ServicesVehicletypeAction } from "../../../../../Redux/actions/actions";
import styles from './style.module.css';
import { CLOSE } from "../../../../../assets";
import Typography from "../../../../../design-system/lib/Typography";
import { LearnMore } from "../../../../../components/learnMore";

class App extends React.Component {
  NewTravelHandler = () => {
       if (this.props.OpenDialoge) {
         this.props.dispatch(Edit_Travel_Reset());
       } else {
         this.props.dispatch(OpenCloseEditTravel());
       }
//  this.props.dispatch(ResetNewOrder())
//     this.props.dispatch(ServicesVehicletypeAction(this.props.dispatch , this.props.center))
  };

  render() {
    return (
      <nRHeader className={styles.header}>
        <img onClick={this.NewTravelHandler} className={styles.close} src={CLOSE} />
        <Typography weight="Body_Middle_Bold" text={this.props.t("EditPickupDropOff")} textColor="TextDark" />
        <div style={{float:"var(--main-defaultFloat)", marginInline:"auto 16px" ,direction:"ltr"}}>    
                   <LearnMore placement={'bottomEnd'} where={this.props.orderType+"EditAdresses"} hastext={false} />
              </div>

      </nRHeader>
    );
  }
}

const mapStateToProps = (state) => ({
  center:state.Map.center ,
  OpenDialoge: state.EditOrder.OpenDialoge,
  orderType: state.ViewTravel.pureData
  ? state.ViewTravel.pureData.type
    ? state.ViewTravel.pureData.type
    : null
  : null,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(App));
