import styles from './style.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DriversListItem } from '../drivers-list-item';
import icArrowDown from "../../../../../assets/ic-arrow-down.svg";
import { SetActiveDriverList, SetDriverListData, SetDriverListRegex, SetDriverListloadingOrderedPage, SetSelectedMonitoringDriver } from '../../../../../Redux/actions/actionNames';
import { DriverApi } from '../../../../../service/API/driver';
import noResultImg from '../../../../../assets/ic-box.svg';
import { CircularProgress } from '@material-ui/core';
import clsx from 'clsx';
import { GetDriverInfoAction } from '../../../../../Redux/actions/actions';
import { getOrderMonitoringBounding } from './getOrderMonitoring';
import { useEffect } from 'react';
import { getDriverList } from './action/getDriverList';
import { GetDriverListData } from '../../../../../hooks/GetDriverListData';
import ProgressIndicator from '../../../../../design-system/lib/ProgressIndicator';
import { SEARCH_NOT_FOUND } from '../../../../../assets';
import Button from '../../../../../design-system/lib/Button';

export const DriversList = () => {
    const dispatch = useDispatch();
    const Driverlist = useSelector((state) => state.DriversList.Driverlist.drivers)
    const regex = useSelector((state) => state.DriversList.regex)
    const isOnline = useSelector((state) => state.DriversList.isOnline)
    const DriverlistPage = useSelector((state) => state.DriversList.DriverlistPage)
    const canLoadmore = useSelector((state) => state.DriversList.Driverlist.nextCursor)
    const DriverlistLoading = useSelector((state) => state.DriversList.DriverlistLoading)
    const onlineCount = useSelector((state) => state.DriversList.stats.isOnline)
    const offlineCount = useSelector((state) => state.DriversList.stats.isOffline)
    let { data } = GetDriverListData({ cursor:"lastOnlineTime", cursorSort:"ascending" })

    const { t } = useTranslation();


   async function loadMore(e){
        getDriverList(data , true)
        // let params ={
        //     page : DriverlistPage + 1,
        //     perpage : 40 ,
        //     isOnline : isOnline ,
        //     regexText : String(regex).length > 0 ? String(regex) : null
        // }
        // dispatch(
        //     SetDriverListloadingOrderedPage({
        //       DriverlistLoading: true ,
        //       DriverlistPage : 1 
        //     })
        //   );
        // let Driver = new DriverApi()
        // let data = await Driver.List(removeNull(params))
        // dispatch(SetDriverListData(data.data, false))
        // dispatch(
        //     SetDriverListloadingOrderedPage({
        //       DriverlistLoading: false ,
        //       DriverlistPage : DriverlistPage + 1,
        //       canLoadmore: data.data.length == 40
        //     })
        //   );
    }
 
    async function handleDriver(data){
      dispatch(SetActiveDriverList(data));
      dispatch(GetDriverInfoAction(dispatch , data.id))
      dispatch(SetSelectedMonitoringDriver(data.id))

      getOrderMonitoringBounding(data)

    }


    useEffect(() => {
        getDriverList()
        // if(regex.length > 0){
        //     getDriverList()
        // }else {

        //     if(isOnline){
        //         if(Number(onlineCount) > 0){
        //             getDriverList()
        //         }
        //     }else{
        //         if(Number(offlineCount) > 0){
        //             getDriverList()
        //         }
        //     }
        // }
        
    }, [isOnline , regex])

    useEffect(() => {

        if(isOnline && regex.length == 0 ){
            getDriverList(null,false , true)
        }
        
    }, [onlineCount])


    useEffect(() => {

        if(!isOnline  && regex.length == 0  ){
            getDriverList(null,false , true)
        }
        
    }, [offlineCount])

    
    return <div className={clsx(styles.DriversListContainer , regex.length > 0 && styles.DriversListContainerSearch )}>
        {
            data.map((driver)=>{
                return <DriversListItem avatarPhoto={driver.avatarPhoto} ordersCount={driver.activeOrdersCount} isOnline={driver.isOnline} name={driver.name} username={driver.username}  onClick={()=> {handleDriver(driver)}}/>
            })
        }

            {canLoadmore &&
                    <div className={styles.looadmoreContainer} onClick={loadMore}>
                        <span className={styles.looadmoreText}>
                            {t("loadMore")}
                        </span>
                        <img className={styles.looadmoreIcon} src={icArrowDown} alt="" />
                    </div>
                }


             {DriverlistLoading && data.length == 0 &&  <div className={styles.searchNoResultContainer}> 
                <ProgressIndicator theme='On_Light' style={{width:"32px",height:"32px",marginTop:"60px"}} />
              {/* <CircularProgress size={18} style={{margin:"20px calc(50% - 14px)",float:'left'}}/> */}
              </div>}
        


           {!DriverlistLoading && data.length == 0 &&  <div className={styles.searchNoResultContainer}>
            <img src={SEARCH_NOT_FOUND} className={styles.searchNoResultImg}/>
            <p className={styles.searchNoResultTitle}> {t("Noresultfound")}</p>
            {/* <Button size='Medium' type='Primary' text={t("ClearField")} onClick={()=>{dispatch(SetDriverListRegex(""))}} style={{marginTop:"16px"}} /> */}
        </div>}
    </div>

};






const removeNull = (objIn) => {
    let objOut = objIn;
    Object.keys(objOut).forEach(key => {
      if (objOut[key] === null) {
        delete objOut[key];
      }
    });
    return objOut
  }