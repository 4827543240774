import cartingos from "../../../assets/cartingo_logotype.png";
import FavIcon from "../../../assets/PROJECTS/cartingo.png";

export default function cartingo() {
    
    return global.config = {
    "ProjectName": "Catringo",
    "LogoType": cartingos,
    "BaseApi": "https://rest.catrin-go.com",
    "BaseSocket": "https://socket.catrin-go.com",
    "BaseTrack": "https://admin.catrin-go.com",
    "MapCenter": {
        "lat": "33.680788",
        "lng": "-117.821799"
    },
    "Lang": "en",
    "Direction": "ltr",
    "AllLanguages": [{
        "lang": "en",
        "direction": "ltr",
        "i18Lang": "English"
    }, {
        "lang": "fa",
        "direction": "rtl",
        "i18Lang": "Persian"
    }, {
        "lang": "fr",
        "direction": "ltr",
        "i18Lang": "French"
    }, {
        "lang": "ro",
        "direction": "ltr",
        "i18Lang": "Romanian"
    }],
    "FavIcon": FavIcon,
    "Country": "US",
    "thirdPartyLoginSupport": true  ,
    mapType :"osm", /* osm | google */ 
    googleApiKey : ""
} }