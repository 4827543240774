import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table_setData, Table_setStatus } from '../Redux/actions/actionNames';
import { restClient } from '../service/API/axios';
import momentJalaali from "moment-jalaali";

import _, { isNumber, isObject } from 'lodash';
import { removeEmptyObject } from '../components/shared/table/action/getTableOrderList';


function removeNullFields(obj) {
  for (var key in obj) {
      if (obj[key] === null) {
          delete obj[key];
      } else if (typeof obj[key] === 'object') {
          removeNullFields(obj[key]);
      }
  }
  return obj;
}

function gtelteSetter(obj) {
  for (var key in obj) {
      if (["gte","equal"].indexOf(key) > -1) {
        if(isObject(obj[key])){
          obj[key] = momentJalaali(obj[key].day + "/" + obj[key].month + "/" + obj[key].year +" 00:01", "DD/MM/YYYY HH:mm").format("x")
        }
      } else if (["lte"].indexOf(key) > -1) {
        if(isObject(obj[key])){
          obj[key] = momentJalaali(obj[key].day + "/" + obj[key].month + "/" + obj[key].year +" 23:59", "DD/MM/YYYY HH:mm").format("x")
        }
      } else if (typeof obj[key] === 'object') {
        gtelteSetter(obj[key]);
      }
  }
  return obj;
}




export function GetTableOrderListWithFilters() {
  const filters = useSelector((state) => state.Table.filters)
  const table = useSelector((state) => state.Table.tableInit)
  const perPage = useSelector((state) => state.Table.perPage)
  const ActiveOrderType = useSelector((state) => state.OrderList.ActiveOrderType)

  const [currentFilter, setCurrentFilter] = useState(filters);

  const dispatch = useDispatch();

  useEffect(() => {

    if(!_.isEqual(filters , currentFilter)){
      console.log("filters ",filters , currentFilter)

      setCurrentFilter(filters)
      OnChange()
    }
    // OnChange()

  }, [filters])


  const OnChange = React.useCallback(async(e) => {
    dispatch(Table_setStatus({key:"isLoading" , value:true}))
    table?.toggleAllRowsSelected(false)

    try {

      const data = JSON.parse(JSON.stringify({
        "size": perPage,
        // "status[]": "Pending",
        // "dueDate[start]": "1688224655137",
        // "dueDate[end]": "1706714255137",
        ...filters,
        "type":ActiveOrderType,
        "columns": ['customerId' , 'driver' , 'pickup' , 'dropoff' , 'dropoffs' , 'delivery' , 'isScheduled' , 'currentCandidateDrivers' , 'driverAssignmentModel' , 'driverId' , 'delayAt' , 'baseETA' , 'realETA' , 'isPinned' , 'isDelayed' , 'isPossibleForDelay' , 'candidateDriversCount' , 'dispatchAt' , 'isDispatching' , 'vehicleType' , 'isUrgent' , 'service' , 'routeName' , 'paymentMethod' , 'paymentSide' , 'paymentProvider' , 'codAmount' , 'note' , 'referenceId' , 'stage' , 'customer' , 'zone' , 'price' , 'trackOrder' , 'customerType' , 'estimatedDistanceInMeters' , 'estimatedDuration' , 'customerReceiptUrl' , 'source' , 'statusUpdatedAt' , 'shipmentLabelUrl']
      }));



      const response = await restClient.request({
        method: 'get',
        url: "/api/dispatcher/order",
        params: gtelteSetter(removeEmptyObject(removeEmptyObject(removeNullFields(data))))
      });
      dispatch(Table_setData(response.data.data))
      dispatch(Table_setStatus({key:"isLoading" , value:false}))
      dispatch(Table_setStatus({key:"page" , value: 1}))
  
  } catch (error) {
    dispatch(Table_setStatus({key:"isLoading" , value:false}))
    dispatch(Table_setStatus({key:"hasError" , value:true}))
  
  }
    
  }, [filters  ,perPage , ActiveOrderType, table]);



  return {};
};

