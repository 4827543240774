import * as React from 'react'
import { useState, useRef } from 'react'
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {snack} from '../../../../../../../../../utils/SnackbarUtilsConfigurator'
import CreditCardElement from '../../../../../../../../../components/shared/credit-card-element'
import { Add_Payment_Method } from '../../../../../../../../../helper/api'


const CARD_OPTIONS = {
  iconStyle: 'solid',
  style: {
    base: {
      padding :"20px 4px",
      height:"39px",
      backgroundColor:"white",
      iconColor: '#8898AA',
      color: 'black',
      fontWeight: 300,
      fontSize: '16px',
      '::placeholder': {
        color: '#a9b7c4'
      }
    },
    invalid: {
      // iconColor: '#e85746',
      color: '#e85746'
    }
  },
  classes: {
    focus: 'is-focused',
    empty: 'is-empty'
  }
}


const EditCreditCardDetails = ({ onToogleVisibility, isStripeFormVisible }) => (
  <a className="editCardButton" onClick={onToogleVisibility}>
    <i className={`fa fa-${isStripeFormVisible ? 'chevron-left' : 'pencil'}`}></i>
    <span>{isStripeFormVisible ? 'cancel' : 'Edit Credit Card Details'}</span>
  </a>
)

const CreditCardErrors = (props) => (
  <div className="cardErrors" role="alert" style={{color:"#fa755a" , fontSize:"16px" , marginTop:'4px', }}>
    {props.children}
  </div>
)




function App({ setupIntentSecret, successUrl, isCreditCardStored , clientSecret , onClose }) {
  const formRef = useRef(null)
  const [cardErrorMessage, setCardErrorMessage] = useState(null)
  const [loading, setLoading] = React.useState(false);
  const [cardReady, setCardReady] = React.useState(false);
  const [cardDetail, setCardDetail] = React.useState(null);
  const [isStripeFormVisible, setIsStripeFormVisible] = useState(!isCreditCardStored)
  const [stripePaymentMethodId, setStripePaymentMethodId] = useState('')
  const [formComplete, setFormComplete] = useState(false)
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch()
  const customerId = useSelector((state) => state.ViewTravel.pureData.customerId )

  const stripe = useStripe()
  const elements = useElements()

  const toogleVisibility = () => setIsStripeFormVisible(!isStripeFormVisible)

  const handleSubmit = React.useCallback(async (event) => {

    setLoading(true)
    event.preventDefault()



    if (global.config.dispatcherSettings.stripeMoto) {
      Add_Payment_Method({
        "customerId": customerId,
        "cardExpMonth": String(cardDetail.expired).substring(0, 2),
        "cardExpYear": `20${String(cardDetail.expired).substring(2)}`,
        "cardNumber": cardDetail.cardNumber,
        cardCvc: cardDetail.cvc
      }, (call) => {
        if (call) {
          snack.success_i18('Addedsuccessfully')
          setLoading(false)
          onClose(true)
        } else {
          setLoading(false)
        }

      })
    } else {
      setFormComplete(false)

      if (!stripe || !elements) {
        return
      }

      const { error, setupIntent } = await stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement)
        }
      })

      if (setupIntent && setupIntent.status === 'succeeded') {
        // setStripePaymentMethodId(setupIntent.payment_method)
        // formRef.current.submit()
        snack.success_i18('Addedsuccessfully')
        setLoading(false)

        onClose()
      } else {
        setLoading(false)
        setCardErrorMessage(error.message)
      }

    }






  }, [customerId, cardDetail , global]);



  const validateCardElement = (event) => {
    setFormComplete(event.complete)
    setCardErrorMessage(event.error ? event.error.message : null)
  }


  const handleCreditCard = (isOk , data) => {
    if(isOk){
      setCardReady(true)
      setCardDetail(data)
    }else{
      setCardReady(false)
      setCardDetail(null)
    }
  }


  return (
    <React.Fragment>
      <div style={{ width: '511px', height: 'auto', margin: '24px 0 21px 16px', float: "left" }} >

   
      {
          global.config.dispatcherSettings.stripeMoto ? <CreditCardElement onChange={handleCreditCard} /> :
            <>
              <fieldset className={`feohebxte ${cardErrorMessage && 'feohebxteError'}`}>
                <CardElement
                  options={CARD_OPTIONS}
                  className="col-md-12"
                  onChange={validateCardElement}
                />
              </fieldset>
              {cardErrorMessage && <CreditCardErrors sty>{cardErrorMessage}</CreditCardErrors>}
            </>
        }

      </div>

      <div className="footer">
        <sendOrder
          className={`creditcardaddBtn ${(global.config.dispatcherSettings.stripeMoto ? !cardReady : !formComplete ) && "grey"}`}
         onClick={ (cardReady || formComplete ) && handleSubmit}
        >
          {loading ?  <div class="snippet" data-title=".dot-pulse">
          <div class="stage">
            <div class="dot-pulse"></div>
          </div>
        </div> : <Trans i18nKey={"Add"}> </Trans>}
        </sendOrder>
      </div>


    </React.Fragment>
  )
}

export default App