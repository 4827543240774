import CarRecoveryLogo from "../../../assets/CarRecoveryLogo.png";
import FavIcon from "../../../assets/PROJECTS/CarRecovery.png";

export default function CarRecovery () {
    
    return global.config = {
  
    "ProjectName": "911 Car Recovery",
    "LogoType": CarRecoveryLogo,
    "BaseApi": "https://rest.aladden.org",
    "BaseSocket": "https://socket.aladden.org",
    "BaseTrack": "https://panel.aladden.org",
    "MapCenter": {
        "lat": "30.0444117209904",
        "lng": "31.235573120930503"
    },
    "Lang": "en",
    "Direction": "ltr",
    "AllLanguages": [{
        "lang": "en",
        "direction": "ltr",
        "i18Lang": "English"
    }],
    "FavIcon": FavIcon,
    "Country": "EG",
    "thirdPartyLoginSupport": true  ,
    mapType :"osm", /* osm | google */ 
    googleApiKey : ""
} }