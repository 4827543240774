import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import jalali from "jalali-moment";
import { get_lang } from "../../../../../helper/UserData/userdate";

class App extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <orderStatus>
        {/* <item style={{flex : "1",display:"flex"}}>
          <stateTag className={this.props.status || "new"}>
            {" "}
            <Trans i18nKey={"status"+this.props.status || "new"}></Trans>
          </stateTag>
        </item>
        <item>
          <operator>
            <Trans i18nKey={this.props.source}>
              {" "}
            </Trans>
          </operator>
        </item>

        <item>
          <date>
            {this.props.createdAt &&
              jalali(this.props.createdAt)
                .locale(get_lang())
                .format("YYYY/MM/DD")}
          </date>
        </item>

        <itemNb>
          <codeT> {this.props.code}</codeT>
        </itemNb> */}

      <item style={{flex:"1"}}>
          <tvy className={`VR_${this.props.status || "new"}`}>
            {" "}
            <Trans i18nKey={"status" + this.props.status || "new"}></Trans>{" "}
          </tvy>
          <tvz>
            {" "}
            <Trans i18nKey={"status2"}></Trans>{" "}
          </tvz>
        </item>

        <item >
          <tvy>
            {" "}
            <Trans i18nKey={this.props.source}></Trans>{" "}
          </tvy>
          <tvz>
            {" "}
            <Trans i18nKey={"Creator"}></Trans>{" "}
          </tvz>
        </item>

        <item >
          <tvy>
            {" "}
            {this.props.createdAt &&
              jalali(this.props.createdAt)
                .locale(get_lang())
                .format("YYYY/MM/DD")}{" "}
          </tvy>
          <tvz>
            {" "}
            <Trans i18nKey={"Creationdate"}></Trans>{" "}
          </tvz>
        </item>

        <item >
          <tvy>{this.props.code} </tvy>
          <tvz>
            {" "}
            <Trans i18nKey={"OrderCode"}></Trans>{" "}
          </tvz>
        </item>
      </orderStatus>
    );
  }
}

const mapStateToProps = (state) => ({
  lang: state.LocalData.lang,
  status: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.status
      ? state.ViewTravel.pureData.status
      : "new"
    : "new",
  source: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.source
      ? state.ViewTravel.pureData.source
      : false
    : false,
  createdAt: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.createdAt
      ? state.ViewTravel.pureData.createdAt
      : null
    : null,
  code: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.code
      ? state.ViewTravel.pureData.code
      : "#"
    : "#",
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
