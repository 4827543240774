import styles from './style.module.css';



export const SidebarDivider = ({style}:any) => {


    return <div className={styles.SidebarDivider} style={style} >
       

    </div>

};




