import BESwiftys from "../../../assets/BESwifty_logotype.png";
import FavIcon from "../../../assets/PROJECTS/BESwifty.png";

export default function BESwifty() {
    
    return global.config = {
    "ProjectName": "BESwifty",
    "LogoType": BESwiftys,
    "BaseApi": "https://rest.beswifty.co.uk",
    "BaseSocket": "https://socket.beswifty.co.uk",
    "BaseTrack": "https://panel.beswifty.co.uk",
    "MapCenter": {
        "lat": "51.51386279703675",
        "lng": "-0.13229977613094754"
    },
    "Lang": "en",
    "Direction": "ltr",
    "AllLanguages": [{
        "lang": "en",
        "direction": "ltr",
        "i18Lang": "English"
    }],
    "FavIcon": FavIcon,
    "Country": "US",
    "thirdPartyLoginSupport": true
} }