

const init = {
  items: [],
};
export const ErrorHandling = (state = init, action) => {
  switch (action.type) {
    case "addErrorCatch":
      return {
        ...state,
        items : [...state.items , action.state]
      };
    default:
      return state;
  }
};

