import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import arrow2 from "../../../../../src/assets/arrow-triangle-down.svg";
import ic_remove from "../../../../../src/assets/ic_remove.svg";
import Backdrop from "@material-ui/core/Backdrop";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import { withStyles } from "@material-ui/core/styles";
import { getAvailableListChangeStatus } from "../../../../helper/module";
import { AdvancedAssaigning_setRule, AdvancedAssaigning_firstDriver_auto_switch, AdvancedAssaigning_setIniRule, AdvancedAssaigning_secondDriver_auto, AdvancedAssaigning_secondDriver_auto_switch } from "../../../../Redux/actions/actionNames";




const BackdropCustom = withStyles({
  backdrop: {
    zIndex: 5000,
    backgroundColor: "rgba(0, 0, 0, 0)",
    color: "#00000",
  },
})((props) => (
  <Backdrop
    {...props}
    style={{
      zIndex: 5000,
      backgroundColor: "rgba(0, 0, 0, 0)",
      color: "#00000",
    }}
  />
));




class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showEditStatusPoper: false
    }
    this.anchorElStatus = React.createRef();
  }


  onChangeOpenStatus = () => {
    this.setState({
      showEditStatusPoper: !this.state.showEditStatusPoper,
    });
  };

  selectRules = (e) => {
    this.props.dispatch(AdvancedAssaigning_setRule(e))
    if (e && (e.MustsetDriver || e.mustSetDriver)) {
      this.props.dispatch(AdvancedAssaigning_firstDriver_auto_switch(false));
    }

    if (e && e.p_mustSetDriver) {
      this.props.dispatch(AdvancedAssaigning_firstDriver_auto_switch(false));
    }

    if (e && e.d_mustSetDriver) {
      this.props.dispatch(AdvancedAssaigning_secondDriver_auto_switch(false));
    }
  }

  componentDidMount = () => {
    
    getAvailableListChangeStatus({pickup:this.props.PickupOrderInfo && this.props.PickupOrderInfo.status, delivery: this.props.DliveryOrderInfo && this.props.DliveryOrderInfo.status, main : this.props.status},
      this.props.orderType, 1 , (call)=>{
      if (call && (call.MustsetDriver || call.mustSetDriver)) {
        this.props.dispatch(AdvancedAssaigning_firstDriver_auto_switch(false));
      }
  
      if (call && call.p_mustSetDriver) {
        this.props.dispatch(AdvancedAssaigning_firstDriver_auto_switch(false));
      }
  
      if (call && call.d_mustSetDriver) {
        this.props.dispatch(AdvancedAssaigning_secondDriver_auto_switch(false));
      }

      this.props.dispatch(AdvancedAssaigning_setIniRule(call))

      }
    )

  }

  render() {
    const { t } = this.props;
    return (
      <div className="statusSelector">

        <span className="currentStatus">
          <span className={`state VR_${this.props.status || "new"}`}>
            <Trans i18nKey={"status" + this.props.status || "new"}></Trans>{" "}
          </span>
          <span className="">
            <Trans i18nKey={"CurrentStatus"}> </Trans>
          </span>
        </span>


        {!this.props.activeRules && <div
          className="EditSaDStatusContainer"
          ref={this.anchorElStatus}
          onClick={this.onChangeOpenStatus}
        >
          <span> <Trans i18nKey={"Changestatus"}> </Trans></span>
          <img className="EditSaDStatusimgRotatex" src={arrow2} />
        </div>
        }

        {this.props.activeRules && <div
          className="showSaDStatusContainer"
          onClick={() => {
            this.selectRules(null);
          }}

        >
          <img className="image" src={ic_remove} />

          <span className="currentStatus">
            <span className={`state VR_${this.props.activeRules.to}`}>
              <Trans i18nKey={"status" + this.props.activeRules.to}></Trans>{" "}
            </span>
            <span className="">
              <Trans i18nKey={"Newstatus"}> </Trans>
            </span>
          </span>

        </div>
        }


        <BackdropCustom
          open={this.state.showEditStatusPoper}
          onClick={this.onChangeOpenStatus}
        >
          <Popper
            style={{
              width: "230px",
              zIndex: "1300",
              marginTop: "-50px",
              maxHeight: "185px",
            }}
            placement={"bottom-end"}
            id={"passenger"}
            open={this.state.showEditStatusPoper}
            anchorEl={this.anchorElStatus.current}
          >
            <Paper
              style={{
                width: "230px",
                float: "right",
                borderRadius: "8px",
                paddingTop: "5px",
                marginTop: "10px",
                paddingBottom: "5px",
                right: "271px",
                maxHeight: "185px",
                overflowY: "auto",
                overflowX: "hidden",
              }}
              className="hideScrollbar"
              elevation={3}
            >
              {getAvailableListChangeStatus({pickup:this.props.PickupOrderInfo && this.props.PickupOrderInfo.status, delivery: this.props.DliveryOrderInfo && this.props.DliveryOrderInfo.status, main : this.props.status},
                this.props.orderType
              ).map((item) => {
                return (
                  <div
                    onClick={() => {
                      this.selectRules(item);
                    }}
                    className={`EditSaDStatusItem`}
                  >
                    <Trans i18nKey={"EDAS_" + item.to}> </Trans>
                    {/* {this.isCurrentStatus(item.to) && (
                          <span>
                            {" "}
                            <Trans i18nKey={"Current"}> </Trans>{" "}
                          </span>
                        )} */}
                  </div>
                );
              })}
            </Paper>
          </Popper>
        </BackdropCustom>


      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  orderType: state.ViewTravel.pureData.type,
  activeRules: state.AdvancedAssaigning.activeRules,
  initialRule: state.AdvancedAssaigning.initialRule,
  status: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.status
      ? state.ViewTravel.pureData.status
      : "new"
    : "new",

    DliveryOrderInfo : state.AdvancedAssaigning.DliveryOrderInfo,
    PickupOrderInfo : state.AdvancedAssaigning.PickupOrderInfo,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
