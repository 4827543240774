import React from "react";
import clsx from "clsx";
import styles from './style.module.css';
import { AvatarProps } from "./Avatar.types";
import { defaultCustomer } from "../../../assets";



const Avatar: React.FC<AvatarProps> = ({ style, className, ref, status, type, imageUrl, onClick }: AvatarProps) => {
    return (
        type == "customer" ? <img src={imageUrl ? imageUrl : defaultCustomer} className={clsx(styles.customer, className && className)} onClick={onClick && onClick} ref={ref} style={style ? style : {}} />
            : <img src={imageUrl ? imageUrl : defaultCustomer} className={clsx(imageUrl ? styles.driver : styles.driverBasic, status && styles[status], className && className)} onClick={onClick && onClick} ref={ref} style={style ? style : {}} />
    );
};

export default Avatar;

