import styles from './style.module.css';
import icFilter from '../../../../../assets/ic/filter.svg';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { OpenCloseFilterDialoge } from '../../../../../Redux/actions/actionNames';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useGetFiltersList } from '../../../../../hooks/useGetFiltersList';

export const FilterButton = ({className}) => {
    const dispatch = useDispatch();
    const open = useSelector((state) => state.OrderList.filters.openDialoge)
    const { t, i18n } = useTranslation();
    const [filtersCount, setFiltersCount] = useState(0);
    let {  selectedServices, selectedStatus, selectedVehicleType, isScheduled, zones ,now} = useGetFiltersList()

    const openDialoge=()=>{
        dispatch(OpenCloseFilterDialoge())
    }

    useEffect(() => {
        setFiltersCount(0);
        let selectedServiceslength = JSON.parse(selectedServices).length;
        
        let selectedStatuslength = JSON.parse(selectedStatus).length;
        
        let selectedVehicleTypelength = JSON.parse(selectedVehicleType).length;
        
        let zoneslength = JSON.parse(zones).length;
        
        let isScheduledlength = isScheduled ? 1 : 0 ;
        
        let nowlength = now ? 1 : 0 ;
        

        setFiltersCount(selectedServiceslength + selectedStatuslength + selectedVehicleTypelength + zoneslength + isScheduledlength + nowlength)



    }, [selectedServices, selectedStatus, selectedVehicleType, isScheduled, zones ,now ])

    

    return <div className={clsx(styles.filter , className , open && styles.Activefilter)} onClick={openDialoge}> 
        <img src={icFilter} alt="icon"   className={styles.icFilter}></img>
        <span className={styles.filterText}>
            {t("filter")}

            {filtersCount > 0 && <span className={styles.filterCount}> {filtersCount} </span>}
        </span>

    </div>

};




