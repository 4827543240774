import React, { useEffect } from 'react';
import { Sharedayout} from '../layout/shared/shared'
import history from '../service/history';
import { LocalData } from '../service/localStorage';
const localData = new LocalData();


function SharedPage({matches}) {

    useEffect(() => {
        // let path = window.location.pathname ;
        // var priority = ["/Ondemand", "/Pickup", "/Delivery", "/PickupDelivery","/"];
        // if(localData.get("ReduxLocal_ActiveOrderType")){
        //     if(priority.indexOf(window.location.pathname) > -1){
        //         history.push("/"+localData.get("ReduxLocal_ActiveOrderType"))
        //     }else{
        //         history.push("/"+localData.get("ReduxLocal_ActiveOrderType"))
        //         history.push(path)
        //     }
        // }
        
        // if(localData.get("ReduxLocal_ActiveOrderType")){
        //     history.push("/"+localData.get("ReduxLocal_ActiveOrderType"))
        //   }
    }, [])

    return <Sharedayout matches={matches} />

}

export default SharedPage;

