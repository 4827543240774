import * as React from 'react'
import styles from './style.module.css';
import IconProvider from '../../../../../design-system/lib/IconProvider';
import { ADD_CUSTOMER, CALL, CLOSE_DARK, CUSTOMER_TYPE, DRIVER, DRIVER_1, DRIVER_COLOR, EDIT, GROUP_1925, INFO_CIRCLE, PERSON, PROFILE, STAR, WALLET } from '../../../../../assets';
import Typography from '../../../../../design-system/lib/Typography';

import { useTranslation } from 'react-i18next';
import Switch from '../../../../../design-system/lib/Switch';
import DropDown from '../../../../../design-system/lib/DropDown';
import { useDispatch, useSelector } from 'react-redux';
import { Add_Driver_OptionـPickupDeliveryType_Pickup, Remove_Driver_OptionـPickupDeliveryType_Pickup, Set_Auto_ActiveVehicleTypeـPickupDeliveryType_Pickup, Set_Auto_DriverـPickupDeliveryType_Pickup, Set_Force_AssaigningـPickupDeliveryType_Pickup, Set_Manual_ActiveVehicleTypeـPickupDeliveryType_Pickup } from '../../../../../Redux/actions/actionNames';
import Button from '../../../../../design-system/lib/Button';
import SearchList from '../../../../../design-system/lib/SearchList';
import { GetVehicletypeByServiceAction, SearchDriverAction, SearchDriverAction_Pickup, Voip_OutgoingCall } from '../../../../../Redux/actions/actions';
import DriverListItem from './components/list-item.jsx';
import { snack } from '../../../../../utils/SnackbarUtilsConfigurator';
import Avatar from '../../../../../design-system/lib/Avatar';
import { get_id } from '../../../../../helper/UserData/userdate';
import { getCenterMap } from '../../../../../helper/module';
import Tag from '../../../../../design-system/lib/Tag';



function PickupDriverNewOrder() {
  const { t, i18n } = useTranslation();
  const auto = useSelector((state) => state.NewOrder.newOrder.pickup.driver.auto)

  const [activeIndex, setActiveIndex] = React.useState(auto ? 0 : (auto == false ? 1 : 2 ));
  const mapRef = useSelector((state) => state.Map.mapRef)

  const auto_activeVehicleType = useSelector((state) => state.NewOrder.newOrder.pickup.driver.auto_driver.auto_activeVehicleType)
  const auto_suggestListData = useSelector((state) => state.NewOrder.newOrder.driver.auto_driver.auto_suggestListData)
  const manual_activeVehicleType = useSelector((state) => state.NewOrder.newOrder.pickup.driver.manual_driver.manual_activeVehicleType)
  const manual_name = useSelector((state) => state.NewOrder.newOrder.pickup.driver.manual_driver.manual_name)
  const manual_code = useSelector((state) => state.NewOrder.newOrder.pickup.driver.manual_driver.manual_code)
  const manual_family = useSelector((state) => state.NewOrder.newOrder.pickup.driver.manual_driver.manual_family)
  const manual_id = useSelector((state) => state.NewOrder.newOrder.pickup.driver.manual_driver.manual_id)
  const manual_suggestListLoading = useSelector((state) => state.NewOrder.newOrder.pickup.driver.manual_driver.manual_suggestListLoading)
  const manual_suggestListData = useSelector((state) => state.NewOrder.newOrder.pickup.driver.manual_driver.manual_suggestListData)
  const manual_suggestListOrdered = useSelector((state) => state.NewOrder.newOrder.pickup.driver.manual_driver.manual_suggestListOrdered)
  const lang = useSelector((state) => state.LocalData.lang)
  const searchDriverInput = useSelector((state) => state.NewOrder.newOrder.pickup.driver.manual_driver.searchDriverInput)
  const forceAssaigning = useSelector((state) => state.NewOrder.newOrder.pickup.driver.forceAssaigning)
  const sendOrder = useSelector((state) => state.NewOrder.newOrder.sendOrder)
  const customer = useSelector((state) => state.NewOrder.newOrder.customer)
  const options = useSelector((state) => state.NewOrder.newOrder.pickup.driver.options)
  const manual_number = useSelector((state) => state.NewOrder.newOrder.pickup.driver.manual_driver.manual_number)
  const driverAllvehicletypes = useSelector((state) => state.NewOrder.newOrder.pickup.driver.manual_driver.driverAllvehicletypes)
  const activeService = useSelector((state) => state.NewOrder.newOrder.service.activeVehicleType)
  const manual_vehicleTypes = useSelector((state) => state.NewOrder.newOrder.service.manual_vehicleTypes)



  const dispatch = useDispatch();

  React.useEffect(() => {

    if (!auto_activeVehicleType && auto_suggestListData.length > 0) {
      dispatch(Set_Auto_ActiveVehicleTypeـPickupDeliveryType_Pickup(auto_suggestListData[0]))
    }

  }, [])

  React.useEffect(() => {

    if (String(auto).length > 0 && activeIndex == -1) {
      setActiveIndex(auto ? 0 : (auto == false ? 1 : 2))
    }

  }, [auto])



  React.useEffect(() => {
    var mustResetVehicleType = true;
    auto_suggestListData.forEach((element) => {
      if (element.id == auto_activeVehicleType?.id) {
        mustResetVehicleType = false;
      }
    });
    mustResetVehicleType && dispatch(Set_Auto_ActiveVehicleTypeـPickupDeliveryType_Pickup(auto_suggestListData[0]))


  }, [auto_suggestListData])


  React.useEffect(() => {


    // if (activeService && manual_id != "" && manual_id != null) {
    //   FindLocalVehicletype(
    //     manual_vehicleTypes,
    //     auto_suggestListData,
    //     (call) => {
    //       if (call.length > 0) {


    //         let activeVehicleType = null;

    //         if (manual_activeVehicleType) {
    //           let currentVehicleIsOk = call.find((element) => element.id == manual_activeVehicleType.id);;

    //           activeVehicleType = currentVehicleIsOk ? manual_activeVehicleType : call[0];
    //         } else {
    //           if (call.length != 0) {
    //             activeVehicleType = call[0];
    //           } else {
    //             snack.warning_i18("DriverVehicleTypeError")
    //           }
    //         }

    //         activeVehicleType &&
    //           dispatch(
    //             Set_Manual_ActiveVehicleTypeـPickupDeliveryType_Pickup({

    //               manual_activeVehicleType: activeVehicleType,
    //               driverAllvehicletypes: call,
    //             })
    //           );


    //       } else {
    //         dispatch(
    //           Set_Manual_ActiveVehicleTypeـPickupDeliveryType_Pickup({
    //             manual_activeVehicleType: null,
    //             driverAllvehicletypes: []
    //           })
    //         );
    //       }
    //     }
    //   );
    // }



  }, [auto_suggestListData, manual_vehicleTypes, activeService, manual_id])

  const handleAutoVehicleTypeSelect = (service) => {
    dispatch(Set_Auto_ActiveVehicleTypeـPickupDeliveryType_Pickup(service));
  };

  const SelectableItemHandleactive = (OP) => {
    if (SelectableItemOptionisSaved(OP)) {
      dispatch(Remove_Driver_OptionـPickupDeliveryType_Pickup(OP.id))
    } else {
      dispatch(Add_Driver_OptionـPickupDeliveryType_Pickup({
        name: OP.name,
        id: OP.id,
        type: "boolean"
      }))
    }

  };


  const SelectableItemOptionisSaved = (OP) => {
    let booll = false;
    booll = options.find((item) => {
      return item.id === OP.id;
    });
    if (booll) {
      return true
    } else {
      return false
    }
  }

  const listHandleSelectedItem = (item, OP) => {
    dispatch(Remove_Driver_OptionـPickupDeliveryType_Pickup(OP.id))

    dispatch(Add_Driver_OptionـPickupDeliveryType_Pickup({
      name: OP.name,
      id: OP.id,
      item,
      type: "list"
    }))
  };

  const listOptionisSaved = (OP) => {
    let booll = false;
    booll = options.find((item) => {
      return item.id === OP.id;
    });
    if (booll) {
      return true
    } else {
      return false
    }
  }

  const listOptionText = (OP) => {
    let booll = false;
    booll = options.find((item) => {
      return item.id === OP.id;
    });
    if (booll) {
      return booll.item.title
    } else {
      return false
    }
  }

  const handleAutoManual = (inde) => {
    if (inde == 0 || inde == 1) {
      dispatch(Set_Auto_DriverـPickupDeliveryType_Pickup(inde == 0 ? true : (inde == 1 ? false : null)));
      setActiveIndex(inde)
    } else {
      setActiveIndex(inde)
      dispatch(Set_Auto_DriverـPickupDeliveryType_Pickup(null));

    }
  };

  const FindLocalVehicletype = (driverVehicletypes, localservives, call) => {
    console.log("FindLocalVehicletype ", driverVehicletypes, localservives)
    let vehicletype = [];

    if (driverVehicletypes && localservives) {
      driverVehicletypes.forEach((element) => {
        localservives.forEach((localservive) => {
          if (element == localservive.id) {
            vehicletype.push(localservive);
          }
        });
      });
    }

    call(vehicletype);

  };

  const SearchDriver = React.useCallback((input, manual) => {

    dispatch(
      SearchDriverAction_Pickup(dispatch, {
        searchRegex: input ? input : searchDriverInput,
        vehicleTypes: manual ? manual.id : (manual_activeVehicleType ? manual_activeVehicleType.id : ""),
      }, {
        from: "Pickup",
        level: "newOrder"
      })
    );

  }, [searchDriverInput, manual_activeVehicleType]);

  const InputOnChange = (e) => {
    dispatch(
      Set_Manual_ActiveVehicleTypeـPickupDeliveryType_Pickup({
        manual_code: e.target.value,
        manual_id: null,
        searchDriverInput: e.target.value,
        driverAllvehicletypes: [],
      })
    );



   e && SearchDriver(e.target.value)

  };

  const handleSelectedItem = (item) => {
    dispatch(
      Set_Manual_ActiveVehicleTypeـPickupDeliveryType_Pickup({
        manual_activeVehicleType: item,
      })
    );

    SearchDriver(null, item)

  };


  const handleSelectDriverVehicle = (item) => {
    dispatch(
      Set_Manual_ActiveVehicleTypeـPickupDeliveryType_Pickup({
        manual_activeVehicleType: item,
      })
    );
  };

  const handleManualVehicleTypeSelect = (service) => {

    FindLocalVehicletype(
      service.vehicleTypes,
      auto_suggestListData,
      (call) => {
        if (call) {
          let activeVehicleType = null;

          if (manual_activeVehicleType) {
            activeVehicleType = manual_activeVehicleType;
          } else {
            if (call.length != 0) {
              activeVehicleType = call[0];
            } else {
              snack.warning_i18("DriverVehicleTypeError")
            }
          }

          activeVehicleType &&
            dispatch(
              Set_Manual_ActiveVehicleTypeـPickupDeliveryType_Pickup({

                manual_activeVehicleType: activeVehicleType,
                driverAllvehicletypes: call,
                manual_name: service.name,
                manual_family: "",
                manual_number: service.phone,
                manual_id: service.id,
                manual_code: service.username,
                manual_vehicleTypes: service.vehicleTypes
              })
            );
        }
      }
    );

  }


  const makeOutgoingCall = () => {
    dispatch(Voip_OutgoingCall(dispatch, {
      "to": manual_number,
      "orderId": null,
      "customerId": null,
      "driverId": manual_id,
      "dispatcherId": get_id()
    }))
  }


  const resetDriver = () => {
    dispatch(
      Set_Manual_ActiveVehicleTypeـPickupDeliveryType_Pickup({
        driverAllvehicletypes: [],
        searchDriverInput: "",
        manual_activeVehicleType: null,
        manual_name: "",
        manual_family: "",
        manual_number: "",
        manual_id: "",
        manual_code: "",
      })
    );
  };


  const handleForceAssaigning = () => {
    dispatch(Set_Force_AssaigningـPickupDeliveryType_Pickup(!forceAssaigning));
  };



  const Buttonhandleactive = (OP) => {
    if (ButtonoptionisSaved(OP)) {
      dispatch(Remove_Driver_OptionـPickupDeliveryType_Pickup(OP.id))
    } else {
      dispatch(Add_Driver_OptionـPickupDeliveryType_Pickup({
        name: OP.name,
        id: OP.id,
        type: "boolean"
      }))
    }

  };


  const ButtonoptionisSaved = (OP) => {
    let booll = false;
    booll = options.find((item) => {
      return item.id === OP.id;
    });
    if (booll) {
      return true
    } else {
      return false
    }
  }


  const DrophandleSelectedItem = (OP, item) => {
    dispatch(Remove_Driver_OptionـPickupDeliveryType_Pickup(OP.id))

    dispatch(Add_Driver_OptionـPickupDeliveryType_Pickup({
      name: OP.name,
      id: OP.id,
      item,
      type: "list"
    }))

  };


  const DropoptionisSave = (OP) => {
    let booll = false;
    booll = options.find((item) => {
      return item.id === OP.id;
    });
    if (booll) {
      return true
    } else {
      return false
    }
  }

  const DropoptionText = (OP) => {
    let booll = false;
    booll = options.find((item) => {
      return item.id === OP.id;
    });
    if (booll) {
      return booll.item.title
    } else {
      return false
    }
  }


  React.useEffect(() => {

    // let inde = 1
    // dispatch(Set_Auto_DriverـPickupDeliveryType_Pickup(false));
    // setActiveIndex(1)

  }, [])


  if (manual_id != "" && manual_id != null) {
    return <div className={styles.DriverNewOrderContainer} >
      <div className={styles.selectedUserContainer}>

        <div className={styles.avatarContainer}>
          <Avatar type='customer' />
          <span className={styles.IconProvider}><IconProvider Icon={STAR} size='12' style={{ width: "10px" }} /> 4.8 </span>
          {/* <IconProvider Icon={type == "business" ? GROUP_1925 : CUSTOMER_TYPE} className={styles.IconProvider} size='16' /> */}
        </div>

        <div className={styles.nameUsernameContainer}>
          <Typography weight='Body_Middle_Medium' text={manual_name} />
          <Typography weight='Subtitle_Tiny_Regular' text={manual_code} />
        </div>


        {manual_activeVehicleType && <div className={styles.forceVehicleContainer}>
          <DropDown size='Small' icon={manual_activeVehicleType?.icon} text={manual_activeVehicleType?.title} placement='bottomEnd' data={driverAllvehicletypes} onClick={(JOJO) => { handleSelectDriverVehicle(JOJO) }} />
          <Button size={'Small'} type='Secondary' text={t("ForceAssign")} onClick={handleForceAssaigning} isActive={forceAssaigning} />

        </div>}

        {!manual_activeVehicleType && <div className={styles.forceVehicleContainer}> <Tag size='Small' weight='Body_Tiny_Regular' text={t("DriverVehicleTypeError")} backgroundColor='RedUltraLight' color='TextDark' /></div>}

        {/* <Tag size='Large' text={`${getSymbolFromCurrency(get_currencyCode())}${balance}`} backgroundColor='GrayUltraLight' color='TextDark' icon={WALLET} style={{ marginInlineStart: "16px", marginInlineEnd: "auto" }} iconSize='16' /> */}

        {global.config.dispatcherSettings.isVoipActvie && <Button size={'Medium'} type='Secondary' icon={CALL} style={{ marginInlineStart: "12px" }} onClick={makeOutgoingCall} />}


        {/* <IconProvider Icon={CLOSE_DARK} size='16' style={{ marginInlineStart: "12px", cursor: "pointer" ,alignSelf:"start",marginTop:"8px"}} onClick={resetDriver} /> */}
        <Button size={'Small'} icon={CLOSE_DARK} type='Secondary' style={{ marginInlineStart: "12px", alignSelf: "start",  backgroundColor: "transparent", padding: "8px 8px" }} onClick={resetDriver} />


      </div>

      {(manual_activeVehicleType && manual_activeVehicleType.options.length > 0) && <div className={styles.ServiceNewOrderSecondRow2}>
        {manual_activeVehicleType.options.map((item) => {
          return item.type === "list" ? (
            <DropDown size='Small' text={DropoptionisSave(item) ? DropoptionText(item) : item.title} placement='bottomEnd' data={item.data} isRequired={DropoptionisSave(item) ? false : item.isRequired} requiredText={t("Required")} onClick={(JOJO) => DrophandleSelectedItem(item, JOJO)} isSelected={DropoptionisSave(item)} clearText={t("clear")} clearOnClick={() => { DropoptionisSave(item) && dispatch(Remove_Driver_OptionـPickupDeliveryType_Pickup(item.id)) }} />
          ) : <Button size={'Small'} type='Secondary' isRequired={item.isRequired} requiredText={t("Required")} text={item.title} isActive={ButtonoptionisSaved(item)} onClick={() => { Buttonhandleactive(item) }} />
        })}
        {/* <Button size={'Small'} type='Secondary' text='Need Helper' />
                <DropDown size='Small' text='Weight' placement='bottom' data={["10 kg", "20 kg", "30 kg"]} />
                <Button size={'Small'} type='Secondary' text='Width' isRequired requiredText='Required' /> */}

      </div>}
    </div>

  }


  return (
    <div className={styles.DriverNewOrderContainer} style={{
      height: (activeIndex == 1 && (manual_id == "" ||
        manual_id == null)) ? "72px" : "unset"
    }}>
      <div className={styles.DriverTitleContainer}>
        <IconProvider Icon={DRIVER_1} size="16" />
        <Typography weight='Body_Middle_Bold' text={t("driver")} />
        <Switch items={[t("Auto"), t("Manual"), t("Unassigned")]} index={activeIndex} handleSelection={handleAutoManual} />
      </div>

      {((activeIndex == 0 || activeIndex == 2) && (manual_id == "" ||
        manual_id == null)) && <>
          <DropDown size='Medium' text={auto_suggestListData?.length > 0 ? auto_activeVehicleType.title : t("pleaseSellectParsel")}
            icon={auto_suggestListData?.length > 0 && auto_activeVehicleType.icon} placement='bottomEnd' data={auto_suggestListData} onClick={handleAutoVehicleTypeSelect} style={{ width: "calc(100% - 23px)" }} />
          {(auto_activeVehicleType && auto_activeVehicleType.options.length > 0) && <div className={styles.ServiceNewOrderSecondRow}>
            {auto_activeVehicleType.options.map((item, i) => {
              return item.type === "list" ? (
                <DropDown size='Small' text={listOptionisSaved(item) ? listOptionText(item) : item.title} placement={i == 0 ? 'bottomStart' : "bottomEnd"} data={item.data} isRequired={listOptionisSaved(item) ? false : item.isRequired} requiredText={t("Required")} onClick={(JOJO) => listHandleSelectedItem(JOJO, item)} isSelected={listOptionisSaved(item)} clearText={t("clear")} clearOnClick={() => { listOptionisSaved(item) && dispatch(Remove_Driver_OptionـPickupDeliveryType_Pickup(item.id)) }} />
              ) : <Button size={'Small'} type='Secondary' isRequired={item.isRequired} requiredText={t("Required")} text={item.title} isActive={SelectableItemOptionisSaved(item)} onClick={() => { SelectableItemHandleactive(item) }} />
            })}
            {/* <Button size={'Small'} type='Secondary' text='Need Helper' />
                <DropDown size='Small' text='Weight' placement='bottom' data={["10 kg", "20 kg", "30 kg"]} />
                <Button size={'Small'} type='Secondary' text='Width' isRequired requiredText='Required' /> */}

          </div>}

        </>

      }


      {(activeIndex == 1 && (manual_id == "" ||
        manual_id == null)) && <>

          <SearchList style={{ zIndex: "3" }} placeHolder={t("CodeNameFamilyPhonenumber")} onChange={InputOnChange} value={searchDriverInput}
            isloading={manual_suggestListLoading}
            errorIcon={DRIVER_COLOR}
            errorActionOnClick={() => {
              dispatch(
                Set_Manual_ActiveVehicleTypeـPickupDeliveryType_Pickup({
                  manual_code: "",
                  manual_id: null,
                  searchDriverInput: "",
                  driverAllvehicletypes: [],
                  manual_suggestListData: [],
                  manual_suggestListOrdered: false,
                })
              );
            }}
            listItemOnclick={handleManualVehicleTypeSelect}
            list={(manual_suggestListOrdered && String(searchDriverInput).length > 0) ? (manual_suggestListData.length > 0 ? manual_suggestListData : null) : null}
            error={!manual_suggestListLoading && manual_suggestListOrdered && manual_suggestListData.length === 0} errorTitle={t("NoDriverfound")} errorDescription={t("NoDriverfoundDesc")} errorActionTitle={t("ClearField")}
            ListItemChildren={DriverListItem} inputInnerChildren={<DropDown size='Small' placement='bottomEnd' text={manual_activeVehicleType ? manual_activeVehicleType.title : t("Allvehicletypes")} data={auto_suggestListData} onClick={handleSelectedItem} />}
          />


        </>

      }



    </div>
  )
}

export default PickupDriverNewOrder