import * as Sentry from "@sentry/react";
import { setGlobalSettings } from "./helper/module/projects";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./Redux/store/index";
import "./service/i18/i18n";

Sentry.init({
  dsn: "https://99a65be4397d1b8262da181a7d23e8dc@o300354.ingest.us.sentry.io/4506971261763584",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

setGlobalSettings()
const Loader = () => <div className="App">...</div>;


if (process.env.NODE_ENV !== "development"){
  console.log = () => {};
}


ReactDOM.render(
  <Suspense fallback={<Loader />}>
    <Provider store={store}>
        <App />
    </Provider>
  </Suspense>
  ,
  document.getElementById("root")
);
