import React from 'react'
import {  useEffect } from 'react';
import { useSelector } from 'react-redux';


export function useViewOrderMapContainerWidthHeight() {
  const eventTimeLineIsActive = useSelector((state) => state.ViewTravel.eventTimeLine.show )
  const pricedetailsOpen = useSelector((state) => state.ViewTravel.pricedetailsOpen)
  const pickup_dropoff_details = useSelector((state) => state.ViewTravel.pickup_dropoff_details)
  const offering_details = useSelector((state) => state.ViewTravel.offering_details)


  return  {width : (eventTimeLineIsActive || pricedetailsOpen || pickup_dropoff_details || offering_details) ? "calc(100% - 794px)" : "calc(100% - 465px)" ,height:"calc(100%)"}
};




