import React from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import arrow2 from "../../../../../../../src/assets/arrow-triangle-down.svg";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import Grow from "@material-ui/core/Grow";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { SetActiveImportData } from "../../../../../../Redux/actions/actionNames";
import ListItem from "./Options/list";
import SelectableItem from "./Options/selectable";

class App extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showpoper: false,

        };
        this.anchorEl = React.createRef();
    }



    handleSelecttService=(service)=>{
        this.props.dispatch(SetActiveImportData({
            id:this.props.ActiveItem.id,
            vehicleType :{
                selectedVehicleType : service ,
                options : []
            },
        }))
        this.hidepoperService()
    }

    showpoperService = (e) => {
        this.setState({
            showpoper: true,
        });
    };
    hidepoperService = (e) => {
        this.setState({
            showpoper: false,
        });
    };



    render() {
        let { t } = this.props;

        return (
            <React.Fragment>


             <div className="previewVehicletypeContainer">
                        <p className="qopdlyuo"> <Trans i18nKey={"Vehicletype"}> </Trans> </p>
                        <div className="previewVehicletypeListContainer"  ref={this.anchorEl}>
                           {this.state.showpoper ?  <ClickAwayListener onClickAway={this.hidepoperService}>
                                    <MenuList
                                        autoFocusItem={this.state.activestop}
                                        id="menu-list-grow"
                                        style={{ padding: "0px" ,backgroundColor:"white", zIndex:"5" ,boxShadow:"0 2px 24px 0 rgba(128, 128, 128, 0.24)" , borderRadius:"8px",maxHeight:"50vh",overflowY:"auto"}}
                                    >
                                        {this.props.vehicleTypes.map((item) => {
                                            return (
                                                <div
                                                    className="pkifhifs"
                                                    onClick={()=>this.handleSelecttService(item)}
                                                >
                                                    <img src={item.icon} className="serviceimage" />
                                                    <span style={{
                                                        fontWeight: this.props.ActiveItem.vehicleType.selectedVehicleType
                                                            ? this.props.ActiveItem.vehicleType.selectedVehicleType.title == item.title
                                                                ? "bold"
                                                                : ""
                                                            : ""
                                                    }}> {item.title} </span>
                                                </div>
                                            );
                                        })}
                                    </MenuList>
                                </ClickAwayListener> :
                            <div  onClick={this.showpoperService} style={{width:"100%",height:"32px"}}>
                                        <img src={this.props.ActiveItem.vehicleType.selectedVehicleType && this.props.ActiveItem.vehicleType.selectedVehicleType.icon} className="srvimg" />
                                        <span> {this.props.ActiveItem.vehicleType.selectedVehicleType ? this.props.ActiveItem.vehicleType.selectedVehicleType.title : <Trans i18nKey={"PleaseSelect"}> </Trans>} </span>
                                        <img className="srcolllapse" src={arrow2} />  
                            </div>                           
                           }
                        </div>
              

                        <serviceOptions className={(!this.props.ActiveItem.vehicleType.selectedVehicleType ) ? "displayNone" : ""}>
                        {this.props.ActiveItem.vehicleType.selectedVehicleType && this.props.ActiveItem.vehicleType.selectedVehicleType.options.map((item) => {
                            return item.type === "list" ? (
                                <ListItem  ActiveItem={this.props.ActiveItem} {...item} />
                            ) : (
                                    <SelectableItem  ActiveItem={this.props.ActiveItem} {...item} />
                                );
                        })}
                    </serviceOptions>


                    </div>

{/* 
                <Popper
                    open={this.state.showpoper}
                    anchorEl={this.anchorEl.current}
                    role={undefined}
                    placement={"bottom-start"}
                    className="poperDialogePriviewSelectService"
                    transition
                    disablePortal
                    style={{width:"366px"}}
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            timeout={{ enter: 150, exit: 150 }}
                            style={{
                                transformOrigin:
                                    placement === "bottom" ? "center top" : "center bottom",
                            }}
                        >
                            <Paper style={{ borderRadius: "9px" }}>
                                <ClickAwayListener onClickAway={this.hidepoperService}>
                                    <MenuList
                                        autoFocusItem={this.state.activestop}
                                        id="menu-list-grow"
                                        style={{ padding: "0px" }}
                                    >
                                        {this.props.vehicleTypes.map((item) => {
                                            return (
                                                <div
                                                    className="pkifhifs"
                                                    onClick={()=>this.handleSelecttService(item)}
                                                >
                                                    <img src={item.icon} className="serviceimage" />
                                                    <span style={{
                                                        fontWeight: this.props.ActiveItem.vehicleType.selectedVehicleType
                                                            ? this.props.ActiveItem.vehicleType.selectedVehicleType.title == item.title
                                                                ? "bold"
                                                                : ""
                                                            : ""
                                                    }}> {item.title} </span>
                                                </div>
                                            );
                                        })}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper> */}

      
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    vehicleTypes: state.Map.monitoring.vehicleTypes,
    serviceVehicleTypes: state.NewOrder.newOrder.service.serviceVehicleTypes,
    draftList: state.BulkImport.draftList,
    List: state.BulkImport.List,
    selectedItem: state.BulkImport.selectedItem,
});
const mapDispatchToProps = (dispatch) => ({
    dispatch: dispatch,
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(App));
