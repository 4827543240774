import React from "react";
import { Marker } from '@react-google-maps/api';
import { connect } from "react-redux";

import ReactDOMServer from "react-dom/server";
import PickupPin from "../../map-pickup-pin/Pin";
import DropOffPin from "../../map-dropoff-pin/Pin";
import { DropOffPinWithNumber } from "../../map-dropoff-pin-with-number";

class App extends React.Component {

  render() {
    let originShow = this.props.pickupAdresss_loc.lng !== "" && ["PickupDelivery", "Pickup", "Ondemand"].indexOf(
      this.props.orderTypeActive
    ) > -1;
    let destinationShow = this.props.dropOffsAdresss_loc.lng !== "" && ["PickupDelivery", "Delivery", "Ondemand"].indexOf(
      this.props.orderTypeActive
    ) > -1;
    let OthersdestinationShow = this.props.otherDropOffs.length > 0;
    return (
      <React.Fragment>
        {originShow ? (
          <Marker
            position={this.props.pickupAdresss_loc}
            icon={{
              anchor: { x: 15, y: 50 },
              url: 'data:image/svg+xml;charset=utf-8,' +
                encodeURIComponent(ReactDOMServer.renderToStaticMarkup(<PickupPin bigSize={true}  color={"#1795ff"} />))
            }} 
          ></Marker>

        ) : null}
        {destinationShow ?
          (this.props.otherDropOffs.length == 0 ? <Marker
            position={this.props.dropOffsAdresss_loc}
            icon={{
              anchor: { x: 15, y: 50 },
              url: 'data:image/svg+xml;charset=utf-8,' +
                encodeURIComponent(ReactDOMServer.renderToStaticMarkup(<DropOffPin  bigSize={true}  color={"#0064d2"} />))
            }}
          ></Marker> : <DropOffPinWithNumber  bigSize={true}  numberStyle={{ position: "absolute", width: "20px", height: "20px", left: "5px", top: "7px", fontSize: "14px", fontWeight: "bold", fontFamily: 'sf_pro', textAlign: "center" }}
            mapType={this.props.mapType}
            position={this.props.dropOffsAdresss_loc} number={1} />) : null}

        {OthersdestinationShow
          ? this.props.otherDropOffs.map((dropoff, i) => {
            return <DropOffPinWithNumber  bigSize={true}  numberStyle={{ position: "absolute", width: "20px", height: "20px", left: "5px", top: "7px", fontSize: "14px", fontWeight: "bold", fontFamily: 'sf_pro', textAlign: "center" }}
              mapType={this.props.mapType}
              position={dropoff.adresss_loc} number={i + 2} />
          })
          : null}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  OpenNRDialoge: state.NewOrder.OpenDialoge,
  OpenVRDialoge: state.ViewTravel.OpenDialoge,
  activePickupDropOff: state.NewOrder.newOrder.activePickupDropOff,
  center: state.Map.center,
  pickupAddress: state.NewOrder.newOrder.pickup.address,
  pickupAdresss_loc: state.NewOrder.newOrder.pickup.adresss_loc,
  dropOffAddress: state.NewOrder.newOrder.dropOffs.firstDropOff.address,
  dropOffsAdresss_loc: state.NewOrder.newOrder.dropOffs.firstDropOff.adresss_loc,
  otherDropOffs: state.NewOrder.newOrder.dropOffs.otherDropOffs,

  orderTypeActive: state.NewOrder.newOrder.orderTypeActive,

});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(mapStateToProps, mapDispatchToProps)(App);
