import { createRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoginAction } from './use-Login';
import styles from './style.module.css';
import { LocalData } from "../../service/localStorage";
import { changeDirection, faviconUpdate } from '../../utils';
import { Set_AccessToken } from "../../Redux/actions/actionNames";
import { Helmet } from "react-helmet";
import Paper from "@material-ui/core/Paper";
import { useTranslation } from 'react-i18next';
import CircularProgress from "@material-ui/core/CircularProgress";
import { snack } from '../../utils/SnackbarUtilsConfigurator';
import { LoginApi } from '../../service/API';
import ProgressBar from '../../design-system/lib/ProgressBar';
import { RefreshTokenCheck } from '../../helper/api';
import { get_refreshToken } from '../../helper/UserData/userdate';
import {  Redirect } from "react-router-dom";
import { useQuery } from '../../hooks/useQuery';

export const Login = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const localData = new LocalData();
    const [username, setUsername] = useState(localData.get('username') || "");
    const [password, setPassword] = useState(localData.get('password') || "");
    const [istokenCheck, setIstokenCheck] = useState(true);
    const [from, setFrom] = useState(null);
    const [to, setTo] = useState(null);
    const authenticated = useSelector((state) => state.LocalData.access_token);
    const searchParams = useQuery();
    const tokenCheck = searchParams.get("redirect");



    useEffect(() => {
        if (username.length > 2 && password.length > 2) {
            setCanSend(true);
        } else {
            setCanSend(false);
        }
    }, [username, password])


    useEffect(() => {
        if (tokenCheck) {
            setIstokenCheck(true);
            // setFrom(window.location.pathname.replace('/login/redirect=', ''))
            setTo(`${window.location.origin}${tokenCheck}`)

            if (get_refreshToken() && String(get_refreshToken()).length > 10) {
                RefreshTokenCheck(() => {
                    window.location.replace(`${window.location.origin}${tokenCheck}`);
                })
            } else {
                setIstokenCheck(false);
            }


        } else {
            setIstokenCheck(false);
        }
    }, [tokenCheck])

    const [loading, setLoading] = useState(false);
    const [canSend, setCanSend] = useState(username && password ? true : false);

    useEffect(() => {
        faviconUpdate();
        changeDirection();
    }, [])

    const passwordInput = createRef();
    const LoginBtn = createRef();


    const usernameOnKeyUp = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            if (username.length > 2) {
                passwordInput.current.focus();
            }
        }
    }

    const passwordOnKeyUp = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            if (password.length > 2) {
                LoginBtn.current.click();
            }
        }
    }

    const LoginClick = async () => {

        if (canSend && !loading) {
            setLoading(true);

            try {
                const result = await LoginAction({ username, password });
                if (result) {
                    dispatch(Set_AccessToken(result.accessToken));
                    if (tokenCheck) {
                        const loginApi = new LoginApi();                    
                        const Dashboardlogin = await loginApi.Dashboardlogin(result.adminToken);
                        window.location.replace(to);
                    }else{
                        const loginApi = new LoginApi();                    
                        const Dashboardlogin = await loginApi.Dashboardlogin(result.adminToken);
                    }
                }

            } catch (error) {
                if (error.code === -400) {
                    snack.warning_i18(error.message)

                } else if (error.code === -1) {
                    snack.warning_i18("enterDataCorrectly")
                } else {

                }
            }

            setLoading(false);


        } else {
            snack.warning_i18("enterDataCorrectly")
        }
    };

    

    if (isLogin() && !tokenCheck && authenticated) {
        return <Redirect
            to={"/Ondemand"}
        />
    }
    return <>

        <Helmet>
            <title> {global.config.ProjectName}</title>
        </Helmet>
        <Paper
            style={{
                width: "348px",
                height: "313px",
                position: "absolute",
                top: "26%",
                float: "right",
                right: "calc(50vw - 174px)",
                borderRadius: "8px",
            }}
            elevation={3}
        >
            {!istokenCheck && <>
                <img
                    className={styles.LogoType}
                    src={global.config.LogoType}
                    alt=":"
                />
                <span
                    className={styles.enterData}
                >
                    {t("Enter_the_following_information_to_enter")}
                </span>

                <input

                    value={username}
                    onKeyUp={usernameOnKeyUp}
                    className={styles.username}
                    onChange={e => setUsername(e.target.value)}
                    placeholder={t("username")}
                />
                <input
                    type="password"
                    ref={passwordInput}
                    value={password}
                    onKeyUp={passwordOnKeyUp}
                    className={styles.password}
                    onChange={e => setPassword(e.target.value)}
                    placeholder={t("password")}
                />

                <span
                    onClick={LoginClick}
                    ref={LoginBtn}
                    className={styles.loginBtn}
                    style={{
                        backgroundColor: canSend && !loading
                            ? "#148be9"
                            : "rgba(128, 128, 128, 0.4)",
                    }}
                >
                    {loading ? (
                        <CircularProgress
                            size={18}
                            style={{
                                color: "white",
                                float: "right",
                                marginRight: "140px",
                                marginTop: "5px",
                            }}
                        />
                    ) : (

                        t("login")
                    )}
                </span>
            </>}

            {istokenCheck && <div style={{ display: "flex", width: "100%", height: "100%", justifyContent: "center", alignItems: "center" }}>
                <ProgressBar width={"90%"} style={{ borderRadius: "2px" }} />

            </div>
            }

        </Paper>
    </>

};


function isLogin () {
    if (String(window.location.href).includes("login")) {
      return true
    } else {
      return false
    }
  }
  


