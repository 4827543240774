import React from "react";
import ReactDOMServer from "react-dom/server";
import { OverlayView } from '@react-google-maps/api';
import { connect } from "react-redux";
import event from "../../../../assets/pin_event.svg";

class App extends React.Component {
  render() {
    let showMarker = this.props.OpenVRDialoge && this.props.eventTimeLine && this.props.activeEventCordinator;
    if (!showMarker) {
      return null;
    } else {
      return (
        <React.Fragment>
          <OverlayView
            key={"CircleRipple"}
            position={{
              lat: this.props.activeEventCordinator[1],
              lng: this.props.activeEventCordinator[0]
            }}
            mapPaneName="markerLayer"
          >

        <div class="circle-ripple"></div>

          </OverlayView>

        </React.Fragment>
      );
    }
  }
}

const mapStateToProps = (state, ownProps) => ({
  OpenVRDialoge: state.ViewTravel.OpenDialoge,
  eventTimeLine: state.ViewTravel.eventTimeLine.show,
  activeEventCordinator: state.ViewTravel.eventTimeLine.activeEventCordinator,

});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(mapStateToProps, mapDispatchToProps)(App);