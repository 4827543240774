import React from "react";
import clsx from "clsx";
import styles from './style.module.css';
import colors from '../colors/colors.module.css';
import { ButtonProps } from "./Button.types";
import IconProvider from "../IconProvider";
import ProgressIndicator from "../ProgressIndicator";
import TooltipOnro from "../Tooltip";
import Typography from "../Typography";



const SidebarButton: React.FC<ButtonProps> = ({ text, style, size, className, ref, activeIcon, deActiveIcon, isOpen, onClick,
    type, disabled, isActive }: ButtonProps) => {

    return (
        <>
            {/* {!isOpen && <ReactTooltip data-class={styles.popUpTitle} />} */}

            <TooltipOnro title={<div className={styles.tooltipContainer}><Typography weight="Body_Small_Medium" text={text} /></div>} placement="right" >
            <div  onClick={() => { (onClick && !disabled) && onClick() }} ref={ref} className={clsx(styles[type], styles[size],
                styles[`${type}`], disabled && styles[`${type}disabled`], isActive && styles[`${type}isActive`], isActive && styles[`${type}isActive`], styles[`${type}icon`], className && className)}
                style={style ? style : {}} >

                {<IconProvider Icon={isActive ? activeIcon : deActiveIcon} size="16" style={size != "mini" ? { width: "20px", height: "20px" } : {}} />}
                {(text ) && <span> {text} </span>}

            </div>
            </TooltipOnro>
        </>
    );
};

export default SidebarButton;

