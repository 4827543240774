import React, { useCallback, useRef } from 'react'
import styles from './style.module.css';
import { useSelector } from 'react-redux';
import Button from '../../../design-system/lib/Button';


function Footer({setIsreorder}) {

  return (  
    <div className={styles.Footer}>
        <Button size='Medium' type='Primary' text='Reorder Stops' onClick={()=>{setIsreorder(true)}}  />
        <Button size='Medium' type='Secondary' text='Optimize'  />

    </div> 
  )
}


export default Footer