import {
  GetProjectHashName,
  getBaseApiByUrl,
  getLangByUrl,
  getDirectionByUrl,
  checkVoipCreateOrderAction,
} from "../module";
import axios from "axios";
import * as AxiosLogger from 'axios-logger';
import { store } from "../../Redux/store/index";
import { Service } from "axios-middleware";
import {
  Set_TravelList_loading,
  Set_TravelList,
  Set_TravelListWithPage,
  Set_TravelList_page,
  Set_realtimePlaceNameLoading,
  Set_realtimePlaceName,
  Set_SearchAutoCompleteLoading,
  Set_SearchAutoComplete,
  Set_Service_VehicleTypes,
  Set_Driver_auto_VehicleTypes,
  Set_AccessToken,
  SuggestCustomerListLoading,
  SuggestCustomerList,
  Set_Manual_ActiveVehicleType,
  SetPriceNewOrder,
  SetCanSaveTravel,
  ResetNewOrder,
  ChangeNewTravelDialoge,
  AddNewOrderToList,
  LoadingOrderInfo,
  SetOrderInfo,
  SetOrderInfoError,
  EditTravelSetManualActiveVehicleType,
  Edit_Travel_Reset,
  EditTravelSetCanSaveTravel,
  SetMonitoringDrivers,
  SetEstimatedRoute,
  SetListNewFav,
  resetInputNewfav,
  Set_SearchAutoCompleteNewFav,
  Set_latlng,
  SetHistoryData,
  SetDriverListloadingOrderedPage,
  SetDriverListData,
  SetDriverOrdersListData,
  Set_Auto_ActiveVehicleType,
  SetAddressBookListData,
  Set_Manual_ActiveVehicleTypeـPickupDeliveryType_Pickup,
  Set_Manual_ActiveVehicleTypeـPickupDeliveryType_Dropoff,
  Set_viewOrder_events,
  Set_New_Event,
  Set_OrderList_OrderTypes,
  ChangeViewTravelDialoge,
  Set_Order_Sub_Id,
  AdvancedAssaigning_setVehicleType,
  AdvancedAssaigning_firstDriver_manual,
  MonitoringSet_VehicleTypes,
  AdvancedAssaigning_secondDriver_manual,
  OrderListFilterService,
  OrderListFilterVehicletypes,
  SetBulkImportList,
  SetActiveImportData,
  SetActiveImportDataList,
  Create_new_BulkImport_List,
  SetActiveImport,
  Remove_draft_bulkImport,
  UpdateTravelInList,
  SetRecentAddress,
  SetOrderNotFound,
  SetorderError,
  ResetViewTravel
} from "../../Redux/actions/actionNames";
import {
  GetVehicletypeByServiceAction,
  SearchDriverAction,
  OrderInfoAction,
  getFavAddressListAction,
  OrderGetEventsAction,
  GetOrderListAction,
} from "../../Redux/actions/actions";
import {
  operator_info,
  get_accessToken,
  get_id,
  get_refreshToken,
  set_accessToken,
  reset,
  set_defaultLocation,
  set_calendarType,
  set_currencyCode,
  get_lang,
  get_direction,
  set_direction,
  set_lang,
  set_username,
  set_name,
  set_tab_orderTypes,
  set_settings_dispatcher,
  set_adminToken,
  get_settings_dispatcher,
} from "../UserData/userdate";
import { Trans } from "react-i18next";
import { SocketOrderHandle } from "../../service/socket/Emit_handelers";
import { EstimateRouteWorker } from "../worker/EstimateRoute";
import packageJson from '../../../package.json';
import { isEmpty } from "lodash";
import { snack } from "../../utils/SnackbarUtilsConfigurator";
import history from "../../service/history";
import httpCustomerqueue from "./withQueue";
import { LoginApi } from "../../service/API";
import Axios from "axios";
import { getsetActiveOrderType } from "../../Redux/reducers/order-list";


const FileDownload = require('js-file-download');


var _ = require("lodash");

export const httpCustomer = axios.create({
  baseURL: global.config.BaseApi,
  timeout: 50000,
});


const httpCustomerUpload = axios.create({
  baseURL: global.config.BaseApi,
  timeout: 100000000,
});
//const service = new Service(httpCustomer);
const CancelToken = axios.CancelToken;
let cancelcalculatetravelcost;
let cancelorderlist;
let cancelQuerySearch;
let cancelgetlistAddressbook;
// httpCustomer.interceptors.request.use(AxiosLogger.orderLogger, AxiosLogger.errorLogger);
// httpCustomer.interceptors.response.use(AxiosLogger.responseLogger, AxiosLogger.errorLogger);

httpCustomer.defaults.headers.common["retry"] = 2;
httpCustomer.defaults.headers.common["retryDelay"] = 3000;
httpCustomer.defaults.headers.common["retryCount"] = 0;
httpCustomer.defaults.headers.common["X-Api-Source"] = 'dispatcher';
httpCustomer.defaults.headers.common["X-App-Version-Code"] = String(packageJson.version);
httpCustomer.defaults.headers.common["X-App-Version-Name"] = String(packageJson.version);


httpCustomer.defaults.headers.common['Cache-Control'] = 'no-cache';
httpCustomer.defaults.headers.common['Pragma'] = 'no-cache';
httpCustomer.defaults.headers.common['Expires'] = '0';


httpCustomer.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);


// interface RetryQueueItem {
//   resolve: (value?: any) => void;
//   reject: (error?: any) => void;
//   config: AxiosRequestConfig;
// }

const refreshAndRetryQueue = [];
let isRefreshing = false;

httpCustomer.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response && error.response.status === 401  && error.response.data.code == "-9") {
      if (!isRefreshing) {
        isRefreshing = true;
        try {
          const refreshToken = get_refreshToken();
          if (refreshToken) {
            await Axios({
              method: "POST",
              url: `${global.config.BaseApi}/api/v1/dispatcher/auth/token`,
              headers: {
                "Accept-Language": get_lang(),
              },
              data: {
                refreshToken: get_refreshToken(),
                userId: get_id(),
              },
            })
              .then(async (response) => {
                if (response.data.data.accessToken) {
                  set_accessToken(response.data.data.accessToken);
                  set_adminToken(response.data.data.adminToken);
          
                  if (get_settings_dispatcher()?.role?.haveAdminAccess) {
                    const loginApi = new LoginApi();
                    const Dashboardlogin = await loginApi.Dashboardlogin(response.data.data.adminToken);
                  }
                }
                return httpCustomer(originalRequest);
              })
              .catch((errorRefresh) => {
                console.log(errorRefresh);
                localStorage.clear();
              });
            // Repeat all miss request by 401
            refreshAndRetryQueue.forEach(({ config, resolve, reject }) => {
              httpCustomer(config)
                .then((response) => resolve(response))
                .catch((err) => reject(err));
            });
            refreshAndRetryQueue.length = 0;
          } else {
            localStorage.clear();
            window.location.href = "/";
            return Promise.reject(error);
          }
        } catch (refreshError) {
          refreshAndRetryQueue.length = 0;
          localStorage.clear();
        } finally {
          isRefreshing = false;
        }
      }
      return new Promise((resolve, reject) => {
        refreshAndRetryQueue.push({ config: originalRequest, resolve, reject });
      });
    }else if(error.response && error.response.status === 401  && error.response.data.code == "-8"){
      localStorage.clear();
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

// AxiosLogger.setGlobalConfig({
//   prefixText: 'Dispatcher Log',
//   dateFormat: 'HH:MM:ss',
//   status: true,
//   headers: false,
// });


// service.register({
//   onOrder(config) {
//     return config;
//   },
//   onSync(promise) {
//     return promise;
//   },
//   onResponse(response) {
//     return response;
//   },
//   onResponseError(error) {
//     if (error.config.headers.retryCount >= error.config.headers.retry) {
//       return error;
//     }

//     error.config.headers.retryCount += 1;
//     var backoff = new Promise(function (resolve) {
//       setTimeout(function () {
//         resolve();
//       }, error.config.headers.retryDelay || 1);
//     });

//     // Return the promise in which recalls axios to retry the order
//     return backoff.then(function () {
//       return axios(error.config);
//     });
//   },
// });


export const CreateOrderUidCheck = (data) => {
  // if(global.axiosUID){
  //     if(global.axiosUID == data.uid){
  //       return false
  //     }else{
  //       global.axiosUID = data.uid

  //       return true
  //     }
  // }else{
  //   global.axiosUID = data.uid
  //   return true
  // }


  return true

}


export const GetOrderList = async (dispatch, data, func) => {
  // cancelorderlist && cancelorderlist("holy ...");

  dispatch(Set_TravelList_loading(true));
  httpCustomer
    .get(`/api/v1/dispatcher/order/`, {
      params: data,
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      dispatch(Set_TravelList_loading(false));
      if (res.data.data) {
        if (data.page == 1) {
          dispatch(Set_TravelList(res.data.data));
        } else {
          dispatch(Set_TravelListWithPage(res.data.data));
        }

        if (data.page != 1 && res.data.data.length == 0) {
          dispatch(Set_TravelList_page(data.page - 1, 798));
          snack.warning_i18("noMoreData")

        }

      }

    })
    .catch((error) => {
      dispatch(Set_TravelList_page(data.page > 0 ? data.page - 1 : 0, 798));
      dispatch(Set_TravelList_loading(false));
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-8") {
          dispatch(Set_AccessToken(null));
        }

        if (error.response.status === 401 && error.response.data.code == "-9") {
          // RefreshToken(() => {
          //   GetOrderList(dispatch, data);
          // });
        }
      }
    });
};

export const SearchAutoComplete = async (dispatch, data) => {
  cancelQuerySearch && cancelQuerySearch("holy ...");

  dispatch(Set_SearchAutoCompleteLoading(true));

  // .get(`/api/v1/dispatcher/geocoding/search`, {
  httpCustomer
    .get(`/api/v1/dispatcher/geocoding/autocomplete`, {
      params: data,
      cancelToken: new CancelToken(function executor(c) {
        cancelQuerySearch = c;
      }),
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      dispatch(Set_SearchAutoCompleteLoading(false));
      dispatch(Set_SearchAutoComplete(res.data.data));
    })
    .catch((error) => {
      dispatch(Set_realtimePlaceNameLoading(false));

      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-8") {
          dispatch(Set_AccessToken(null));
        }
        if (error.response.status === 401 && error.response.data.code == "-9") {
          // RefreshToken(() => {
          //   SearchAutoComplete(dispatch, data)
          // });
        }
      }
    });
};



export const SearchAutoComplete_local_address = async (dispatch, data) => {

  dispatch(Set_SearchAutoCompleteLoading(true));

  // .get(`/api/v1/dispatcher/geocoding/search`, {
  httpCustomer
    .get(`/api/dispatcher/localaddress/search`, {
      params: data,
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      dispatch(Set_SearchAutoCompleteLoading(false));
      dispatch(Set_SearchAutoComplete(res.data.data));
    })
    .catch((error) => {
      dispatch(Set_SearchAutoCompleteLoading(false));
      dispatch(Set_SearchAutoComplete([]));

      dispatch(Set_realtimePlaceNameLoading(false));

      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-8") {
          dispatch(Set_AccessToken(null));
        }
        if (error.response.status === 401 && error.response.data.code == "-9") {
          // RefreshToken(() => {
          //   SearchAutoComplete(dispatch, data)
          // });
        }
      }
    });
};


export const SearchAutoCompleteWithCal = async (dispatch, data, call) => {
  call({ loading: true });
  httpCustomer
    .get(`/api/v1/dispatcher/geocoding/search`, {
      params: data,
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      call({ loading: false });
      dispatch(Set_SearchAutoCompleteNewFav(res.data.data));
    })
    .catch((error) => {
      call({ loading: false });

      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-8") {
          dispatch(Set_AccessToken(null));
        }
        if (error.response.status === 401 && error.response.data.code == "-9") {
          // RefreshToken(() => {
          //   SearchAutoCompleteWithCal(dispatch, data, call);
          // });
        }
      }
    });
};

export const GetLocationPlacename = async (dispatch, data, call) => {
  dispatch(Set_realtimePlaceNameLoading(true));
  httpCustomer
    .post(`/api/v1/dispatcher/geocoding/reverse`, data, {
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {

      if (isEmpty(res.data.data)) {
        snack.warning(res.data.message)

      } else {
        dispatch(Set_realtimePlaceNameLoading(false));
        dispatch(Set_realtimePlaceName(res.data.data));
        call(res.data.data);
      }
    })
    .catch((error) => {
      dispatch(Set_realtimePlaceNameLoading(false));

      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-8") {
          dispatch(Set_AccessToken(null));
        }
        if (error.response.status === 401 && error.response.data.code == "-9") {
          // RefreshToken(() => {
          //   GetLocationPlacename(dispatch, data, call);
          // });
        }
      }
    });
};

export const SearchAddress = async (dispatch, data) => {
  httpCustomer
    .get(`/api/v1/dispatcher/geocoding/search`, {
      params: data,
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      dispatch(Set_SearchAutoCompleteLoading(false));
      dispatch(Set_SearchAutoComplete(res.data.data));
    })
    .catch((error) => {
      dispatch(Set_realtimePlaceNameLoading(false));

      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-8") {
          dispatch(Set_AccessToken(null));
        }

        if (error.response.status === 401 && error.response.data.code == "-9") {
          // RefreshToken(() => {
          //   SearchAddress(dispatch, data);
          // });
        }
      }
    });
};

export const PlaceIdDetails = async (dispatch, data, call) => {
  httpCustomer
    .get(`/api/v1/dispatcher/geocoding/details`, {
      params: data,
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      call(res.data.data);
    })
    .catch((error) => {
      dispatch(Set_realtimePlaceNameLoading(false));

      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-8") {
          dispatch(Set_AccessToken(null));
        }

        if (error.response.status === 401 && error.response.data.code == "-9") {
          // RefreshToken(() => {
          //   PlaceIdDetails(dispatch, data, call);
          // });
        }
      }
    });
};

export const ServicesVehicletype = async (dispatch, center, customerId) => {
  httpCustomer
    .get(`/api/v1/dispatcher/service`, {
      params: {
        customerId: customerId,
      },

      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      dispatch(Set_Service_VehicleTypes(res.data.data));
      dispatch(OrderListFilterService(res.data.data))
      dispatch(
        GetVehicletypeByServiceAction(dispatch, {
          latitude: center.lat,
          longitude: center.lng,
          serviceId: res.data.data[0].id,
          customerId
        }, (vt) => {
          if (vt) {
            // dispatch(Set_Auto_ActiveVehicleType(vt[0]));
          }

        })
      );
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-8") {
          dispatch(Set_AccessToken(null));
        }
        if (error.response.status === 401 && error.response.data.code == "-9") {
          // RefreshToken(() => {
          //   ServicesVehicletype(dispatch, center, customerId);
          // });
        }
      }
    });
};

export const VehicletypeByService = async (dispatch, data, func) => {
  httpCustomer
    .get(`/api/v1/dispatcher/service/vehicle-type`, {
      params: data,
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {

      if (!data.serviceId) {
        dispatch(OrderListFilterVehicletypes(res.data.data))
      }

      if (!_.isEmpty(res.data.data)) {
        dispatch(Set_Driver_auto_VehicleTypes(res.data.data));
        func(res.data.data)

      } else {
        func(false)
        dispatch(Set_Driver_auto_VehicleTypes([]));

      }
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-8") {
          dispatch(Set_AccessToken(null));
        }

        if (error.response.status === 401 && error.response.data.code == "-9") {
          // RefreshToken(() => {
          //   VehicletypeByService(dispatch, data, func);
          // });
        }
      }
    });
};


export const VehicletypeByService_AdvancedAssaigning = async (dispatch, data, func) => {
  dispatch(AdvancedAssaigning_setVehicleType({
    vehicleTypes: [],
    vehicleTypesLoading: true,
    vehicleTypesError: false,
  }));

  httpCustomer
    .get(`/api/v1/dispatcher/service/vehicle-type`, {
      params: data,
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      if (!_.isEmpty(res.data.data)) {
        dispatch(AdvancedAssaigning_setVehicleType({
          vehicleTypes: res.data.data,
          vehicleTypesLoading: false,
          vehicleTypesError: false,
        }));
        func(res.data.data)

      } else {
        func(false)
        dispatch(AdvancedAssaigning_setVehicleType({
          vehicleTypes: [],
          vehicleTypesLoading: false,
          vehicleTypesError: false,
        }));

      }
    })
    .catch((error) => {
      dispatch(AdvancedAssaigning_setVehicleType({
        vehicleTypesLoading: false,
        vehicleTypesError: true,
      }));

      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-8") {
          dispatch(Set_AccessToken(null));
        }

        if (error.response.status === 401 && error.response.data.code == "-9") {
          // RefreshToken(() => {
          //   VehicletypeByService_AdvancedAssaigning(dispatch, data, func);
          // });
        }
      }
    });
};

export const SearchCustomer = async (dispatch, data) => {
  dispatch(SuggestCustomerListLoading(true));
  httpCustomer
    .get(`/api/v1/dispatcher/autocomplete/customer`, {
      params: {
        searchRegex: data
      },
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      dispatch(SuggestCustomerListLoading(false));
      dispatch(SuggestCustomerList(res.data.data));
    })
    .catch((error) => {
      dispatch(SuggestCustomerListLoading(false));

      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-8") {
          dispatch(Set_AccessToken(null));
        }

        if (error.response.status === 401 && error.response.data.code == "-9") {
          // RefreshToken(() => {
          //   SearchCustomer(dispatch, data);
          // });
        }
      }
    });
};

export const SearchDriver = async (dispatch, data, flag) => {
  // let DispatchAction = (flag && flag=="Edit") ? 

  dispatch(
    Set_Manual_ActiveVehicleType({
      manual_suggestListLoading: true,
    })
  );

  dispatch(
    EditTravelSetManualActiveVehicleType({
      manual_suggestListLoading: true,
    })
  );

  httpCustomer
    .get(`/api/v1/dispatcher/autocomplete/driver`, {
      params: data,
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      dispatch(
        Set_Manual_ActiveVehicleType({
          manual_suggestListLoading: false,
        })
      );
      dispatch(
        EditTravelSetManualActiveVehicleType({
          manual_suggestListLoading: false,
        })
      );

      dispatch(
        Set_Manual_ActiveVehicleType({
          manual_suggestListData: res.data.data,
          manual_suggestListOrdered: true,
        })
      );

      dispatch(
        EditTravelSetManualActiveVehicleType({
          manual_suggestListData: res.data.data,
          manual_suggestListOrdered: true,
        })
      );
    })
    .catch((error) => {
      dispatch(
        Set_Manual_ActiveVehicleType({
          manual_suggestListOrdered: false,
        })
      );
      dispatch(
        EditTravelSetManualActiveVehicleType({
          manual_suggestListOrdered: false,
        })
      );
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-8") {
          dispatch(Set_AccessToken(null));
        }

        if (error.response.status === 401 && error.response.data.code == "-9") {
          // RefreshToken(() => {
          //   SearchDriver(dispatch, data, flag);
          // });
        }
      }
    });
};


export const SearchDriver_AdvancedAssaigning = async (dispatch, data, flag) => {
  // let DispatchAction = (flag && flag=="Edit") ? 

  if (flag == "firstDriver") {
    dispatch(
      AdvancedAssaigning_firstDriver_manual({
        manual_suggestListLoading: true,
      })
    );
  } else if (flag == "secondDriver") {
    dispatch(
      AdvancedAssaigning_secondDriver_manual({
        manual_suggestListLoading: true,
      })
    );
  }


  httpCustomer
    .get(`/api/v1/dispatcher/autocomplete/driver`, {
      params: data,
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      if (flag == "firstDriver") {
        dispatch(
          AdvancedAssaigning_firstDriver_manual({
            manual_suggestListLoading: false,
            manual_suggestListData: res.data.data,
            manual_suggestListOrdered: true,
          })
        );

      } else if (flag == "secondDriver") {
        dispatch(
          AdvancedAssaigning_secondDriver_manual({
            manual_suggestListLoading: false,
            manual_suggestListData: res.data.data,
            manual_suggestListOrdered: true,
          })
        );
      }

    })
    .catch((error) => {
      if (flag == "firstDriver") {
        dispatch(
          AdvancedAssaigning_firstDriver_manual({
            manual_suggestListOrdered: false,
          })
        );
      } else if (flag == "secondDriver") {
        dispatch(
          AdvancedAssaigning_secondDriver_manual({
            manual_suggestListOrdered: false,
          })
        );
      }

      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-8") {
          dispatch(Set_AccessToken(null));
        }

        if (error.response.status === 401 && error.response.data.code == "-9") {
          // RefreshToken(() => {
          //   SearchDriver_AdvancedAssaigning(dispatch, data, flag);
          // });
        }
      }
    });
};



export const SearchDriver_Pickup = async (dispatch, data, flag) => {
  // let DispatchAction = (flag && flag=="Edit") ? 

  dispatch(
    Set_Manual_ActiveVehicleTypeـPickupDeliveryType_Pickup({
      manual_suggestListLoading: true,
    })
  );

  httpCustomer
    .get(`/api/v1/dispatcher/autocomplete/driver`, {
      params: data,
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      dispatch(
        Set_Manual_ActiveVehicleTypeـPickupDeliveryType_Pickup({
          manual_suggestListLoading: false,
        })
      );

      dispatch(
        Set_Manual_ActiveVehicleTypeـPickupDeliveryType_Pickup({
          manual_suggestListData: res.data.data,
          manual_suggestListOrdered: true,
        })
      );


    })
    .catch((error) => {
      dispatch(
        Set_Manual_ActiveVehicleTypeـPickupDeliveryType_Pickup({
          manual_suggestListOrdered: false,
        })
      );

      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-8") {
          dispatch(Set_AccessToken(null));
        }

        if (error.response.status === 401 && error.response.data.code == "-9") {
          // RefreshToken(() => {
          //   SearchDriver_Pickup(dispatch, data, flag);
          // });
        }
      }
    });
};

export const SearchDriver_Dropoff = async (dispatch, data, flag) => {
  // let DispatchAction = (flag && flag=="Edit") ? 

  dispatch(
    Set_Manual_ActiveVehicleTypeـPickupDeliveryType_Dropoff({
      manual_suggestListLoading: true,
    })
  );

  httpCustomer
    .get(`/api/v1/dispatcher/autocomplete/driver`, {
      params: data,
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      dispatch(
        Set_Manual_ActiveVehicleTypeـPickupDeliveryType_Dropoff({
          manual_suggestListLoading: false,
        })
      );

      dispatch(
        Set_Manual_ActiveVehicleTypeـPickupDeliveryType_Dropoff({
          manual_suggestListData: res.data.data,
          manual_suggestListOrdered: true,
        })
      );


    })
    .catch((error) => {
      dispatch(
        Set_Manual_ActiveVehicleTypeـPickupDeliveryType_Dropoff({
          manual_suggestListOrdered: false,
        })
      );

      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-8") {
          dispatch(Set_AccessToken(null));
        }

        if (error.response.status === 401 && error.response.data.code == "-9") {
          // RefreshToken(() => {
          //   SearchDriver_Dropoff(dispatch, data, flag);
          // });
        }
      }
    });
};


export const CalculatePrice = async (dispatch, data) => {
  cancelcalculatetravelcost && cancelcalculatetravelcost("holy ...");

  dispatch(
    SetPriceNewOrder(
      { loading: true, done: false },
      { loading: true, done: false }
    )
  );

  httpCustomer
    .post(`/api/v1/dispatcher/order/ondemand/calculate-price`, data, {
      cancelToken: new CancelToken(function executor(c) {
        cancelcalculatetravelcost = c;
      }),
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      dispatch(
        SetPriceNewOrder(
          { loading: false, price: res.data.data, done: true },
          {
            loading: false,
            done: true,
            ...res.data.data,
            operatorSubmit: false,
          }
        )
      );
    })
    .catch((error) => {
      if (error?.response?.data?.message) {
        snack.warning(error?.response?.data?.message)
      }

      dispatch(
        SetPriceNewOrder(
          { loading: false, done: false },
          { loading: false, price: 0, done: false }
        )
      );

      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-8") {
          dispatch(Set_AccessToken(null));
        }

        if (error.response.status === 401 && error.response.data.code == "-9") {
          // RefreshToken(() => {
          //   CalculatePrice(dispatch, data);
          // });
        }
      }
    });
};

export const CalculatePriceReserve = async (dispatch, data) => {
  dispatch(
    SetPriceNewOrder(
      { loading: true, done: false },
      { loading: true, done: false }
    )
  );

  httpCustomer
    .post(`/api/v1/dispatcher/order/ondemand/calculate-price`, data, {
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      dispatch(
        SetPriceNewOrder(
          { loading: false, price: res.data.data, done: true },
          {
            loading: false,
            done: true,
            ...res.data.data,
            operatorSubmit: false,
          }
        )
      );
    })
    .catch((error) => {
      if (error?.response?.data?.message) {
        snack.warning(error?.response?.data?.message)
      }


      dispatch(
        SetPriceNewOrder(
          { loading: false, done: false },
          { loading: false, price: 0, done: false }
        )
      );

      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-8") {
          dispatch(Set_AccessToken(null));
        }

        if (error.response.status === 401 && error.response.data.code == "-9") {
          // RefreshToken(() => {
          //   CalculatePriceReserve(dispatch, data);
          // });
        }
      }
    });
};


export const CalculatePrice_Pickup = async (dispatch, data) => {
  dispatch(
    SetPriceNewOrder(
      { loading: true, done: false },
      { loading: true, done: false }
    )
  );

  httpCustomer
    .post(`/api/v1/dispatcher/order/pickup/calculate-price`, data, {
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      dispatch(
        SetPriceNewOrder(
          { loading: false, price: res.data.data, done: true },
          {
            loading: false,
            done: true,
            ...res.data.data,
            operatorSubmit: false,
          }
        )
      );
    })
    .catch((error) => {

      dispatch(
        SetPriceNewOrder(
          { loading: false, done: false },
          { loading: false, price: 0, done: false }
        )
      );
      if(error.response && error.response.status != 400){
        snack.warning(error.response.data.message)
      }

      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-8") {
          dispatch(Set_AccessToken(null));
        }

        if (error.response.status === 401 && error.response.data.code == "-9") {
          // RefreshToken(() => {
          //   CalculatePrice_Pickup(dispatch, data);
          // });
        }
      }
    });
};

export const CalculatePrice_PickupDelivery = async (dispatch, data, extraFun) => {
  dispatch(
    SetPriceNewOrder(
      { loading: true, done: false },
      { loading: true, price: 0, done: false }
    )
  );

  httpCustomer
    .post(`/api/v1/dispatcher/order/pickup-delivery/calculate-price`, data, {
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      dispatch(
        SetPriceNewOrder(
          { loading: false, price: res.data.data, done: true },
          {

            ...res.data.data,
            operatorSubmit: false,
            loading: false,
            done: true,
          }
        )
      );
    })
    .catch((error) => {
      snack.warning(error.response.data.message)

      dispatch(
        SetPriceNewOrder(
          { loading: false, done: false },
          { loading: false, price: 0, done: false }
        )
      );

      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-8") {
          dispatch(Set_AccessToken(null));
        }

        if (error.response.status === 401 && error.response.data.code == "-9") {
          // RefreshToken(() => {
          //   CalculatePrice_PickupDelivery(dispatch, data);
          // });
        }
      }
    });
};


export const CalculatePrice_Delivery = async (dispatch, data) => {
  dispatch(
    SetPriceNewOrder(
      { loading: true, done: false },
      { loading: true, price: 0, done: false }
    )
  );

  httpCustomer
    .post(`/api/v1/dispatcher/order/delivery/calculate-price`, data, {
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      dispatch(
        SetPriceNewOrder(
          { loading: false, price: res.data.data, done: true },
          {
            loading: false,
            done: true,
            ...res.data.data,
            operatorSubmit: false,
          }
        )
      );
    })
    .catch((error) => {
      if(error.response && error.response.status != 400){
        snack.warning(error.response.data.message)
      }
      dispatch(
        SetPriceNewOrder(
          { loading: false, done: false },
          { loading: false, price: 0, done: false }
        )
      );

      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-8") {
          dispatch(Set_AccessToken(null));
        }

        if (error.response.status === 401 && error.response.data.code == "-9") {
          // RefreshToken(() => {
          //   CalculatePrice_Delivery(dispatch, data);
          // });
        }
      }
    });
};


export const Orderondemand = async (dispatch, data, customerphone, driver, ListOrderType) => {

  if (CreateOrderUidCheck(data)) {
    let body;
    if (driver && !isEmpty(driver.id)) {
      body = { ...data, driverId: driver.id };
    } else {
      body = data;
    }

    dispatch(SetCanSaveTravel({ loading: true }));
    httpCustomerqueue
      .post(`/api/v1/dispatcher/order/ondemand`, body, {
        headers: {
          "Accept-Language": get_lang(),
          Authorization: "Bearer " + get_accessToken(),
        },
      })
      .then((res) => {
        snack.success_i18('Createdsuccessfully')
        dispatch(SetCanSaveTravel({ loading: false }));

        dispatch(ChangeNewTravelDialoge());
        dispatch(ResetNewOrder());
        dispatch(
          getFavAddressListAction(dispatch, { id: body.customerId, page: 0, perpage: 100 })
        );
        dispatch(ResetViewTravel())
        history.push(`/order/${res.data.data.orderId}`);
        dispatch(ChangeViewTravelDialoge(true, res.data.data.orderId, null));
        checkVoipCreateOrderAction(body)


      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401 && error.response.data.code == "-8") {
            dispatch(Set_AccessToken(null));
          } else if (error.response.status === 401 && error.response.data.code == "-9") {
            // RefreshToken(() => {
            //   Orderondemand(dispatch, data);
            // });
          } else {
            snack.warning(error.response.data.message)

          }
        } else {
          snack.error(error)
        }

        dispatch(SetCanSaveTravel({ loading: false }));

      });
  }


};

export const OrderPickup = async (dispatch, data, customerphone, driver, ListOrderType) => {

  if (CreateOrderUidCheck(data)) {


    let body;
    if (driver && !isEmpty(driver.id)) {
      body = { ...data, driverId: driver.id };
    } else {
      body = data;
    }

    dispatch(SetCanSaveTravel({ loading: true }));
    httpCustomerqueue
      .post(`/api/v1/dispatcher/order/pickup`, body, {
        headers: {
          "Accept-Language": get_lang(),
          Authorization: "Bearer " + get_accessToken(),
        },
      })
      .then((res) => {
        checkVoipCreateOrderAction(body)
        snack.success_i18('Createdsuccessfully')
        dispatch(ResetViewTravel())
        history.push(`/order/${res.data.data.orderId}`);

        dispatch(SetCanSaveTravel({ loading: false }));
        let arr = ListOrderType.split('|');
        if (arr.indexOf("Pickup") > -1) {
          dispatch(
            AddNewOrderToList({
              id: res.data.data.orderId,
              customer: { phone: customerphone },
              code: "",
              status: "new",
              createdAt: new Date().getTime(),
              statusUpdatedAt: new Date().getTime(),
              driver: driver ? { name: driver.name } : {},
              customerId: data.customerId,
              pickup: {
                address: data.pickup.address,
              },
              dropoff: {},
              dropoffs: [],
              type: "Pickup",
            })
          );
        }
        dispatch(ChangeNewTravelDialoge());
        dispatch(ResetNewOrder());
        // SocketOrderHandle({
        //   dispatcherId: get_id(),
        //   orderId: res.data.data.orderId,
        //   accessToken: get_accessToken(),
        //   driverId: driver ? driver.id : "",
        //   bypass: true,
        // });



        dispatch(
          OrderInfoAction(dispatch, {
            orderId: res.data.data.orderId,
          })
        );
        dispatch(
          getFavAddressListAction(dispatch, { id: body.customerId, page: 0, perpage: 100 })
        );
        dispatch(
          ChangeViewTravelDialoge(true, res.data.data.orderId, null)
        );
        // dispatch(
        //   ChangeViewTravelDialoge(true, res.data.data.orderId, body.customerId)
        // );


        // OrderInfo(dispatch , { orderId: this.props.id} , true)

      })
      .catch((error) => {
        dispatch(SetCanSaveTravel({ loading: false }));
        if (error.response) {
          if (error.response.status === 401 && error.response.data.code == "-8") {
            dispatch(Set_AccessToken(null));
          } else if (error.response.status === 401 && error.response.data.code == "-9") {
            // RefreshToken(() => {
            //   OrderPickup(dispatch, data, customerphone, driver, ListOrderType);
            // });
          } else {
            snack.warning(error.response.data.message)
          }
        }
      });
  }

};

export const OrderDelivery = async (dispatch, data, customerphone, driver, ListOrderType) => {


  if (CreateOrderUidCheck(data)) {

    let body;
    if (driver && !isEmpty(driver.id)) {
      body = { ...data, driverId: driver.id };



    } else {
      body = data;
    }

    dispatch(SetCanSaveTravel({ loading: true }));
    httpCustomerqueue
      .post(`/api/v1/dispatcher/order/delivery`, body, {
        headers: {
          "Accept-Language": get_lang(),
          Authorization: "Bearer " + get_accessToken(),
        },
      })
      .then((res) => {
        checkVoipCreateOrderAction(body)
        snack.success_i18('Createdsuccessfully')
        dispatch(ResetViewTravel())
        history.push(`/order/${res.data.data.orderId}`);


        dispatch(SetCanSaveTravel({ loading: false }));
        let arr = ListOrderType.split('|');
        if (arr.indexOf("Delivery") > -1) {
          dispatch(
            AddNewOrderToList({
              id: res.data.data.orderId,
              customer: { phone: customerphone },
              code: "",
              status: "new",
              createdAt: new Date().getTime(),
              statusUpdatedAt: new Date().getTime(),
              driver: driver ? { name: driver.name } : {},
              customerId: data.customerId,
              pickup: {
              },
              dropoff: data.dropoff,
              dropoffs: [],
              type: "Delivery",
            })
          );
        }
        dispatch(ChangeNewTravelDialoge());
        dispatch(ResetNewOrder());
        // SocketOrderHandle({
        //   dispatcherId: get_id(),
        //   orderId: res.data.data.orderId,
        //   accessToken: get_accessToken(),
        //   driverId: driver ? driver.id : "",
        //   bypass: true,
        // });



        dispatch(
          OrderInfoAction(dispatch, {
            orderId: res.data.data.orderId,
          })
        );
        dispatch(
          getFavAddressListAction(dispatch, { id: body.customerId, page: 0, perpage: 100 })
        );
        dispatch(
          ChangeViewTravelDialoge(true, res.data.data.orderId, null)
        );
        // dispatch(
        //   ChangeViewTravelDialoge(true, res.data.data.orderId, body.customerId)
        // );


        // OrderInfo(dispatch , { orderId: this.props.id} , true)
      })
      .catch((error) => {
        dispatch(SetCanSaveTravel({ loading: false }));
        if (error.response) {
          if (error.response.status === 401 && error.response.data.code == "-8") {
            dispatch(Set_AccessToken(null));
          } else if (error.response.status === 401 && error.response.data.code == "-9") {
            // RefreshToken(() => {
            //   OrderDelivery(dispatch, data, customerphone, driver, ListOrderType);
            // });
          } else {
            snack.warning(error.response.data.message)
          }
        }
      });
  }


};

export const OrderPickupDelivery = async (dispatch, data, customerphone, driver, ListOrderType) => {

  if (CreateOrderUidCheck(data)) {
    let body;
    if (driver && !isEmpty(driver.id)) {
      body = { ...data, driverId: driver.id };

    } else {
      body = data;
    }

    dispatch(SetCanSaveTravel({ loading: true }));
    httpCustomerqueue
      .post(`/api/v1/dispatcher/order/pickup-delivery`, body, {
        headers: {
          "Accept-Language": get_lang(),
          Authorization: "Bearer " + get_accessToken(),
        },
      })
      .then((res) => {
        checkVoipCreateOrderAction(body)
        snack.success_i18('Createdsuccessfully')
        dispatch(ResetViewTravel())
        history.push(`/order/${res.data.data.orderId}`);

        dispatch(SetCanSaveTravel({ loading: false }));
        let arr = ListOrderType.split('|');
        if (arr.indexOf("PickupDelivery") > -1) {
          dispatch(
            AddNewOrderToList({
              id: res.data.data.orderId,
              customer: { phone: customerphone },
              code: "",
              status: "new",
              createdAt: new Date().getTime(),
              statusUpdatedAt: new Date().getTime(),
              driver: {},
              customerId: data.customerId,
              pickup: data.pickup.pickup,
              dropoff: data.delivery.dropoff,
              dropoffs: [],
              type: "PickupDelivery",
            })
          );
        }
        dispatch(ChangeNewTravelDialoge());
        dispatch(ResetNewOrder());
        // SocketOrderHandle({
        //   dispatcherId: get_id(),
        //   orderId: res.data.data.orderId,
        //   accessToken: get_accessToken(),
        //   driverId: driver ? driver.id : "",
        //   bypass: true,
        // });



        dispatch(
          OrderInfoAction(dispatch, {
            orderId: res.data.data.orderId,
          })
        );
        dispatch(
          getFavAddressListAction(dispatch, { id: body.customerId, page: 0, perpage: 100 })
        );
        dispatch(
          ChangeViewTravelDialoge(true, res.data.data.orderId, null)
        );
        // dispatch(
        //   ChangeViewTravelDialoge(true, res.data.data.orderId, body.customerId)
        // );

        // OrderInfo(dispatch , { orderId: this.props.id} , true)
      })
      .catch((error) => {
        dispatch(SetCanSaveTravel({ loading: false }));
        if (error.response) {
          if (error.response.status === 401 && error.response.data.code == "-8") {
            dispatch(Set_AccessToken(null));
          } else if (error.response.status === 401 && error.response.data.code == "-9") {
            // RefreshToken(() => {
            //   OrderPickupDelivery(dispatch, data, customerphone, driver, ListOrderType);
            // });
          } else {
            snack.warning(error.response.data.message)
          }
        }
      });
  }



};


export const Orderschedule = async (dispatch, data, customerphone, driver, ListOrderType) => {

  if (CreateOrderUidCheck(data)) {
    let body;
    if (driver && !isEmpty(driver.id)) {
      body = { ...data, driverId: driver.id };
    } else {
      body = data;
    }

    dispatch(SetCanSaveTravel({ loading: true }));
    httpCustomerqueue
      .post(`/api/v1/dispatcher/order/ondemand`, body, {
        headers: {
          "Accept-Language": get_lang(),
          Authorization: "Bearer " + get_accessToken(),
        },
      })
      .then((res) => {
        checkVoipCreateOrderAction(body)
        snack.success_i18('Createdsuccessfully')


        dispatch(SetCanSaveTravel({ loading: false }));
        // let arr = ListOrderType.split('|');
        // if (arr.indexOf("Ondemand") > -1) {
        //   dispatch(
        //     AddNewOrderToList({
        //       id: res.data.data.orderId,
        //       customer: { phone: customerphone },
        //       code: "",
        //       status: "new",
        //       createdAt: new Date().getTime(),
        //       statusUpdatedAt: new Date().getTime(),
        //       driver: driver ? { name: driver.name } : {},
        //       customerId: data.customerId,
        //       dropoffs: [
        //         ...data.dropoffs.map((drop) => {
        //           return { address: drop.address };
        //         }),
        //       ],
        //       pickup: {
        //         address: data.pickup.address,
        //       },
        //       type: "Ondemand",
        //     })
        //   );
        // }

        dispatch(ChangeNewTravelDialoge());
        dispatch(ResetNewOrder());
        // SocketOrderHandle({
        //   dispatcherId: get_id(),
        //   orderId: res.data.data.orderId,
        //   accessToken: get_accessToken(),
        //   driverId: driver ? driver.id : "",
        //   bypass: true,
        // });

        dispatch(
          OrderInfoAction(dispatch, {
            orderId: res.data.data.orderId,
          })
        );
        dispatch(
          getFavAddressListAction(dispatch, { id: body.customerId, page: 0, perpage: 100 })
        );
        dispatch(ResetViewTravel())
        history.push(`/order/${res.data.data.orderId}`);
        dispatch(
          ChangeViewTravelDialoge(true, res.data.data.orderId, null)
        );
        // dispatch(
        //   ChangeViewTravelDialoge(true, res.data.data.orderId, body.customerId)
        // );

        // OrderInfo(dispatch , { orderId: this.props.id} , true)
      })
      .catch((error) => {
        dispatch(SetCanSaveTravel({ loading: false }));
        if (error.response) {
          if (error.response.status === 401 && error.response.data.code == "-8") {
            dispatch(Set_AccessToken(null));
          } else if (error.response.status === 401 && error.response.data.code == "-9") {
            // RefreshToken(() => {
            //   Orderschedule(dispatch, data, customerphone, driver, ListOrderType);
            // });
          } else {
            snack.warning(error.response.data.message)
          }
        }
      });
  }



};

export const RefreshToken = async (func) => {
  httpCustomer
    .post(
      `/api/v1/dispatcher/auth/token`,
      {
        refreshToken: get_refreshToken(),
        userId: get_id(),
      },
      {
        headers: {
          "Accept-Language": get_lang(),
        },
      }
    )
    .then(async (res) => {
      if (res.data.data.accessToken) {
        set_accessToken(res.data.data.accessToken);
        set_adminToken(res.data.data.adminToken);

        if (get_settings_dispatcher()?.role?.haveAdminAccess) {
          const loginApi = new LoginApi();
          const Dashboardlogin = await loginApi.Dashboardlogin(res.data.data.adminToken);
        }
      }

      // if (func) {
      //   setTimeout(() => {
      //     func();
      //   }, 300);
      // }

    })
    .catch((error) => {
      if (error.response && error.response.status === 403) {
        localStorage.clear()
        window.location.reload();
      }
      reset();
    });
};


export const RefreshTokenCheck = async (func) => {
  httpCustomer
    .post(
      `/api/v1/dispatcher/auth/token`,
      {
        refreshToken: get_refreshToken(),
        userId: get_id(),
      },
      {
        headers: {
          "Accept-Language": get_lang(),
        },
      }
    )
    .then(async (res) => {
      if (res.data.data.accessToken) {
        set_accessToken(res.data.data.accessToken);
        set_adminToken(res.data.data.adminToken);

        // if (get_settings_dispatcher()?.role?.haveAdminAccess) {
          const loginApi = new LoginApi();
          const Dashboardlogin = await loginApi.Dashboardlogin(res.data.data.adminToken);
          console.log("Dashboardlogin " ,Dashboardlogin)

          if(Dashboardlogin){
            console.log("Dashboardlogin " ,Dashboardlogin)
            if(String(Dashboardlogin.code) == "-400"){
              window.location.replace(`${window.location.origin}/403`);
            }else{
              func && func()
            }
          }else{
            window.location.replace(`${window.location.origin}/403`);
          }
        // }

  
      }



    })
    .catch((error) => {
      if (error.response.status == 403 || error.response.status == 400 || error.response.status == 401) {
        localStorage.clear()
        window.location.replace(`${window.location.origin}/403`);
        // window.location.replace(`${window.location.origin}/403`);
      }
      reset();
    }).finally(()=>{
      // if (func) {
      //   setTimeout(() => {
      //     func();
      //   }, 300);
      // }
    });
};

export const OrderInfo = async (dispatch, data, JustUpdateItemList) => {
  dispatch(LoadingOrderInfo(true));


  // const apiUrl = new URL(`${global.config.BaseApi}/api/v1/dispatcher/order/info`);
  // const params = data;
  // Object.keys(params).forEach((key) =>
  //   apiUrl.searchParams.append(key, params[key])
  // );


  // fetch(apiUrl,{
  //   method: 'GET', // *GET, POST, PUT, DELETE, etc.
  //   headers: {
  //     "Accept-Language": get_lang(),
  //     Authorization: "Bearer " + get_accessToken(),
  //   },
  // })
  // .then((response) => response.json())
  // .then((res) => {
  //   
  //   dispatch(LoadingOrderInfo(false));
  //   dispatch(SetOrderInfo(res.data));
  // }).catch((error) => {
  //   
  //   dispatch(LoadingOrderInfo(false));

  //   dispatch(SetOrderInfoError(true));
  //   if (error.response) {
  //     if (error.response.status === 401 && error.response.data.code == "-8") {
  //       dispatch(Set_AccessToken(null));
  //     }

  //     if (error.response.status === 401 && error.response.data.code == "-9") {
  //       RefreshToken(() => {
  //         SearchDriver(dispatch, data);
  //       });
  //     }
  //   }
  // });




  httpCustomer
    .get(`/api/v1/dispatcher/order/${data.orderId}`, {
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {

      if (JustUpdateItemList) {
        dispatch(
          UpdateTravelInList({
            id: data.orderId,
            data: res.data.data,
            statusCheck: true,
          })
        );
      } else {
        dispatch(SetOrderNotFound(false));
        dispatch(SetorderError(false));

        if (res.data.data.type == "PickupDelivery") {
          dispatch(Set_Order_Sub_Id([res.data.data.deliveryOrderId, res.data.data.pickupOrderId]))
        }
        dispatch(LoadingOrderInfo(false));
        dispatch(SetOrderInfo(res.data.data));
        dispatch(
          UpdateTravelInList({
            id: data.orderId,
            data: res.data.data,
            statusCheck: true,
          })
        );
      }

    })
    .catch((error) => {
      dispatch(LoadingOrderInfo(false));

      dispatch(SetOrderInfoError(true));
      if (error.response) {
        if (error.response.status == "400") {
          dispatch(SetOrderNotFound(error.response.data.message));
        } else if (error.response.status === 401 && error.response.data.code == "-8") {
          dispatch(Set_AccessToken(null));
        } else if (error.response.status === 401 && error.response.data.code == "-9") {
          // RefreshToken(() => {
          //   OrderInfo(dispatch, data, JustUpdateItemList);
          // });
        } else {
          dispatch(SetorderError(true));
        }
      }
    });
};

export const OrderEditondemand = async (
  dispatch,
  data,
  customerphone,
  driver, id
) => {
  dispatch(EditTravelSetCanSaveTravel({ loading: true }));
  httpCustomer
    .patch(`/api/v1/dispatcher/order/${id || data.id}`, data, {
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      snack.success_i18('Updatedsuccessfully')

      dispatch(Edit_Travel_Reset());
      dispatch(
        OrderInfoAction(dispatch, {
          orderId: id,
        })
      );

    })
    .catch((error) => {
      dispatch(EditTravelSetCanSaveTravel({ loading: false }));
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-8") {
          dispatch(Set_AccessToken(null));
        }

        if (error.response.status === 401 && error.response.data.code == "-9") {
          // RefreshToken(() => {
          //   OrderEditondemand(dispatch,
          //     data,
          //     customerphone,
          //     driver, id);
          // });
        }
      }
    });
};

export const OrderEditPriceondemand = async (
  dispatch,
  data,
  customerphone,
  driver, id
) => {
  dispatch(EditTravelSetCanSaveTravel({ loading: true }));
  httpCustomer
    .patch(`/api/v1/dispatcher/order/ondemand/${id || data.id}/price`, data, {
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      snack.success_i18('Updatedsuccessfully')

      dispatch(Edit_Travel_Reset());
      dispatch(
        OrderInfoAction(dispatch, {
          orderId: id,
        })
      );
    })
    .catch((error) => {
      dispatch(EditTravelSetCanSaveTravel({ loading: false }));
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-8") {
          dispatch(Set_AccessToken(null));
        }

        if (error.response.status === 401 && error.response.data.code == "-9") {
          // RefreshToken(() => {
          //   OrderEditPriceondemand(dispatch,
          //     data,
          //     customerphone,
          //     driver, id);
          // });
        }
      }
    });
};


export const OrderEditPricePickup = async (
  dispatch,
  data,
  customerphone,
  driver, id
) => {
  dispatch(EditTravelSetCanSaveTravel({ loading: true }));
  httpCustomer
    .patch(`/api/v1/dispatcher/order/pickup/${id || data.id}/price`, data, {
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      snack.success_i18('Updatedsuccessfully')


      dispatch(Edit_Travel_Reset());
      dispatch(
        OrderInfoAction(dispatch, {
          orderId: id,
        })
      );
    })
    .catch((error) => {
      dispatch(EditTravelSetCanSaveTravel({ loading: false }));
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-8") {
          dispatch(Set_AccessToken(null));
        }

        if (error.response.status === 401 && error.response.data.code == "-9") {
          // RefreshToken(() => {
          //   OrderEditPricePickup(dispatch,
          //     data,
          //     customerphone,
          //     driver, id);
          // });
        }
      }
    });
};

export const OrderEditPriceDelivery = async (
  dispatch,
  data,
  customerphone,
  driver, id
) => {
  dispatch(EditTravelSetCanSaveTravel({ loading: true }));
  httpCustomer
    .patch(`/api/v1/dispatcher/order/delivery/${id || data.id}/price`, data, {
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      snack.success_i18('Updatedsuccessfully')

      dispatch(Edit_Travel_Reset());
      dispatch(
        OrderInfoAction(dispatch, {
          orderId: id,
        })
      );
    })
    .catch((error) => {
      dispatch(EditTravelSetCanSaveTravel({ loading: false }));
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-8") {
          dispatch(Set_AccessToken(null));
        }

        if (error.response.status === 401 && error.response.data.code == "-9") {
          // RefreshToken(() => {
          //   OrderEditPriceDelivery(dispatch,
          //     data,
          //     customerphone,
          //     driver, id);
          // });
        }
      }
    });
};
export const OrderEditPricePickupDelivery = async (
  dispatch,
  data,
  customerphone,
  driver, id
) => {
  dispatch(EditTravelSetCanSaveTravel({ loading: true }));
  httpCustomer
    .patch(`/api/v1/dispatcher/order/pickup-delivery/${id || data.id}/price`, data, {
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      snack.success_i18('Updatedsuccessfully')

      dispatch(Edit_Travel_Reset());
      dispatch(
        OrderInfoAction(dispatch, {
          orderId: id,
        })
      );
    })
    .catch((error) => {
      dispatch(EditTravelSetCanSaveTravel({ loading: false }));
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-8") {
          dispatch(Set_AccessToken(null));
        }

        if (error.response.status === 401 && error.response.data.code == "-9") {
          // RefreshToken(() => {
          //   OrderEditPricePickupDelivery(dispatch,
          //     data,
          //     customerphone,
          //     driver, id);
          // });
        }
      }
    });
};



export const OrderEditPickupDropoffondemand = async (
  dispatch,
  data, id, call
) => {

  dispatch(EditTravelSetCanSaveTravel({ loading: true }));
  httpCustomer
    .patch(`/api/v1/dispatcher/order/ondemand/${id || data.id}/address`, data, {
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      snack.success_i18('Updatedsuccessfully')

      dispatch(Edit_Travel_Reset());
      dispatch(
        OrderInfoAction(dispatch, {
          orderId: id,
        })
      );

    })
    .catch((error) => {
      dispatch(EditTravelSetCanSaveTravel({ loading: false }));
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-8") {
          dispatch(Set_AccessToken(null));
        } else if (error.response.status === 401 && error.response.data.code == "-9") {
          // RefreshToken(() => {
          //   OrderEditPickupDropoffondemand(dispatch,
          //     data, id, call);
          // });
        } else {
          snack.warning(error.response.data.message)
        }
      }

      call(true)
    });
};


export const OrderEditPickupDropoffPickup = async (
  dispatch,
  data, id, call
) => {

  dispatch(EditTravelSetCanSaveTravel({ loading: true }));
  httpCustomer
    .patch(`/api/v1/dispatcher/order/pickup/${id || data.id}/address`, data, {
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      snack.success_i18('Updatedsuccessfully')

      dispatch(Edit_Travel_Reset());
      dispatch(
        OrderInfoAction(dispatch, {
          orderId: id,
        })
      );

    })
    .catch((error) => {
      dispatch(EditTravelSetCanSaveTravel({ loading: false }));
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-8") {
          dispatch(Set_AccessToken(null));
        } else if (error.response.status === 401 && error.response.data.code == "-9") {
          // RefreshToken(() => {
          //   OrderEditPickupDropoffPickup(dispatch,
          //     data, id, call);
          // });
        } else {
          snack.warning(error.response.data.message)
        }
      }
      call(true)
    });
};


export const OrderEditPickupDropoffDelivey = async (
  dispatch,
  data, id, call
) => {

  dispatch(EditTravelSetCanSaveTravel({ loading: true }));
  httpCustomer
    .patch(`/api/v1/dispatcher/order/delivery/${id || data.id}/address`, data, {
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      snack.success_i18('Updatedsuccessfully')

      dispatch(Edit_Travel_Reset());
      dispatch(
        OrderInfoAction(dispatch, {
          orderId: id,
        })
      );

    })
    .catch((error) => {
      dispatch(EditTravelSetCanSaveTravel({ loading: false }));
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-8") {
          dispatch(Set_AccessToken(null));
        } else if (error.response.status === 401 && error.response.data.code == "-9") {
          // RefreshToken(() => {
          //   OrderEditPickupDropoffDelivey(dispatch,
          //     data, id, call);
          // });
        } else {
          snack.warning(error.response.data.message)
        }
      }
      call(true)
    });
};




export const OrderEditPickupDropoffPickupDelivey = async (
  dispatch,
  data, id, call
) => {

  dispatch(EditTravelSetCanSaveTravel({ loading: true }));
  httpCustomer
    .patch(`/api/v1/dispatcher/order/pickup-delivery/${id || data.id}/address`, data, {
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      snack.success_i18('Updatedsuccessfully')

      dispatch(Edit_Travel_Reset());
      dispatch(
        OrderInfoAction(dispatch, {
          orderId: id,
        })
      );

    })
    .catch((error) => {
      dispatch(EditTravelSetCanSaveTravel({ loading: false }));
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-8") {
          dispatch(Set_AccessToken(null));
        } else if (error.response.status === 401 && error.response.data.code == "-9") {
          // RefreshToken(() => {
          //   OrderEditPickupDropoffPickupDelivey(dispatch,
          //     data, id, call);
          // });
        } else {
          snack.warning(error.response.data.message)
        }
      }
      call(true)
    });
};



export const VehicletypesMonitoringDriversHttp = async (dispatch, data) => {
  httpCustomer
    .get(`/api/v1/dispatcher/monitoring`, {
      params: data,
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      dispatch(SetMonitoringDrivers(res.data.data));
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-8") {
          dispatch(Set_AccessToken(null));
        }

        if (error.response.status === 401 && error.response.data.code == "-9") {
          // RefreshToken(() => {
          //   VehicletypesMonitoringDriversHttp(dispatch, data);
          // });
        }
      }
    });
};

export const EstimatedRoute = async (dispatch, data) => {
  httpCustomer
    .get(`/api/v1/dispatcher/order/${data.orderId}/estimate-route`, {
      params: data,
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      EstimateRouteWorker(dispatch, res.data.data.waypoints);

      // dispatch(SetEstimatedRoute({ waypoints: res.data.data.waypoints }))
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-8") {
          dispatch(Set_AccessToken(null));
        }

        if (error.response.status === 401 && error.response.data.code == "-9") {
          // RefreshToken(() => {
          //   EstimatedRoute(dispatch, data);
          // });
        }
      }
    });
};

export const getFavAddressList = async (dispatch, data) => {
  httpCustomer
    .get(`/api/v1/dispatcher/customer/address?customerId=${data.id}`, {
      params: {
        perpage: data.perpage,
        page: data.page
      },
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      dispatch(
        SetListNewFav({
          customerId: data,
          data: res.data.data,
        })
      );
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-8") {
          dispatch(Set_AccessToken(null));
        }

        if (error.response.status === 401 && error.response.data.code == "-9") {
          // RefreshToken(() => {
          //   getFavAddressList(dispatch, data);
          // });
        }
      }
    });
};

export const getFavAddressInfo = async (dispatch, data, cal) => {
  httpCustomer
    .get(`/api/v1/dispatcher/customer/${data.customerId}/address/${data.id}`, {
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      cal(res.data.data);
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-8") {
          dispatch(Set_AccessToken(null));
        }

        if (error.response.status === 401 && error.response.data.code == "-9") {
          // RefreshToken(() => {
          //   getFavAddressInfo(dispatch, data, cal);
          // });
        }
      }
    });
};

export const DeleteFavAddressHttp = async (dispatch, data, cal) => {
  httpCustomer
    .delete(`/api/v1/dispatcher/customer/${data.customerId}/address/${data.id}`, {
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      cal(res.data.data);
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-8") {
          dispatch(Set_AccessToken(null));
        }

        if (error.response.status === 401 && error.response.data.code == "-9") {
          // RefreshToken(() => {
          //   DeleteFavAddressHttp(dispatch, data, cal);
          // });
        }
      }
    });
};

export const SaveFavAddressHttp = async (dispatch, customerId, data) => {
  httpCustomer
    .post(`/api/v1/dispatcher/customer/${customerId}/address/`, data, {
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      dispatch(getFavAddressListAction(dispatch, { id: customerId, page: 0, perpage: 100 }));
      dispatch(resetInputNewfav());
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-8") {
          dispatch(Set_AccessToken(null));
        }

        if (error.response.status === 401 && error.response.data.code == "-9") {
          // RefreshToken(() => {
          //   SaveFavAddressHttp(dispatch, customerId, data);
          // });
        }
      }
    });
};

export const EditFavAddressHttp = async (dispatch, customerId, data) => {
  httpCustomer
    .patch(
      `/api/v1/dispatcher/customer/${customerId.customerId}/address/${customerId.id}`,
      data,
      {
        headers: {
          "Accept-Language": get_lang(),
          Authorization: "Bearer " + get_accessToken(),
        },
      }
    )
    .then((res) => {
      dispatch(getFavAddressListAction(dispatch, { id: customerId.customerId, page: 0, perpage: 100 }));
      dispatch(resetInputNewfav());
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-8") {
          dispatch(Set_AccessToken(null));
        }

        if (error.response.status === 401 && error.response.data.code == "-9") {
          // RefreshToken(() => {
          //   EditFavAddressHttp(dispatch, customerId, data);
          // });
        }
      }
    });
};

export const GetSettings = async (
  dispatch,
  userId,
  refreshToken,
  accessToken,
  i18n
) => {
  httpCustomer
    .post(
      `/api/v1/dispatcher/setting`,
      {
        userId: userId ? userId : get_id(),
        refreshToken: refreshToken ? refreshToken : get_refreshToken(),
      },
      {
        headers: {
          "Accept-Language": get_lang(),
          Authorization:
            "Bearer " + (accessToken ? accessToken : get_accessToken()),
        },
      }
    )
    .then((res) => {
      try {
        global.config = {
          ...global.config,
          applicationMap: res.data.data.applicationMap,
          isVoipActvie: res.data.data.isVoipActvie
        }

        const myEvent = new CustomEvent("applicationMap", {
          detail: { applicationMap: res.data.data.applicationMap }
        });
        document.dispatchEvent(myEvent);

      } catch (error) {

      }
      set_settings_dispatcher(res.data.data)

      // On-Demand - Pickup - Delivery - P&D
      var priority = ["Ondemand", "Pickup", "Delivery", "PickupDelivery"];
      let orderTypes = res.data.data.orderTypes.sort((a, b) => priority.indexOf(a) - priority.indexOf(b))


      dispatch(Set_OrderList_OrderTypes(getsetActiveOrderType()))
      set_tab_orderTypes(orderTypes);
      window.dispatchEvent(new Event('storage'))
      if (res.data.data) {
        set_defaultLocation({
          lng: res.data.data.defaultLocation[0],
          lat: res.data.data.defaultLocation[1],
        });
        dispatch(
          Set_latlng({
            lng: res.data.data.defaultLocation[0],
            lat: res.data.data.defaultLocation[1],
          })
        );
        set_currencyCode(res.data.data.currencyCode);
        set_calendarType(res.data.data.calendarType);



        dispatch(
          GetOrderListAction(dispatch, {
            orderTypes: /* res.data.data.orderTypes[0] */ getsetActiveOrderType(),
            status: "",
            regexText: "",
            regexFields: null,
            page: 1,
            perpage: 10,
            services: "",
            vehicleTypes: "",
            startDate: null,
            endDate: null,
            userId: null,
            "sort[createdAt]": "-1"
          })
        );


        if (get_lang()) {
          dispatch({
            type: "changeDirection",
            direction: getDirectionByUrl(),
            fullDirection: getDirectionByUrl(),
            lang: global.config.Lang,
          });
          i18n.changeLanguage(get_lang());
        } else {
          set_lang(global.config.Lang);
          set_direction(getDirectionByUrl());
          dispatch({
            type: "changeDirection",
            direction: getDirectionByUrl(),
            fullDirection: getDirectionByUrl(),
            lang: global.config.Lang,
          });
          i18n.changeLanguage(global.config.Lang);
        }
      }
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          // RefreshToken(() => {
          //   GetSettings(
          //     dispatch,
          //     userId,
          //     refreshToken,
          //     null,
          //     i18n
          //   );
          // });
        }

        if (error.response && error.response.status === 403) {
          localStorage.clear()
          window.location.reload();
        }
      }
    });
};

export const LogoutHttp = async () => {
  httpCustomer
    .post(
      `/api/v1/dispatcher/auth/logout`,
      {
        userId: get_id(),
        refreshToken: get_refreshToken(),
      },
      {
        headers: {
          "Accept-Language": get_lang(),
          Authorization: "Bearer " + get_accessToken(),
        },
      }
    )
    .then((res) => {
      localStorage.clear();
      window.location.reload();
    })
    .catch((error) => {
      localStorage.clear();
      window.location.reload();
    });
};

export const AccountInformationHttp = async () => {
  httpCustomer
    .get(`/api/v1/dispatcher/account/` + get_id(), {
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      try {
        set_username(res.data.data.username);
        set_name(res.data.data.name);
      } catch (error) { }
    })
    .catch((error) => { });
};

export const BusinessSearch = async (dispatch, data) => {
  httpCustomer
    .get(`/api/v1/dispatcher/autocomplete/customer/business`, {
      params: data,
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      dispatch(SuggestCustomerListLoading(false));
      dispatch(SuggestCustomerList(res.data.data));
    })
    .catch((error) => {
      dispatch(SuggestCustomerListLoading(false));

      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-8") {
          dispatch(Set_AccessToken(null));
        }
        if (error.response.status === 401 && error.response.data.code == "-9") {
          // RefreshToken(() => {
          //   BusinessSearch(dispatch, data);
          // });
        }
      }
    });
};

export const OrderHistory = async (dispatch, id) => {
  dispatch(
    SetHistoryData({
      loading: true,
    })
  );
  httpCustomer
    .get(`/api/v1/dispatcher/order/${id}/history`, {
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      dispatch(
        SetHistoryData({
          loading: false,
          error: false,
          data: res.data.data,
        })
      );
    })
    .catch((error) => {
      dispatch(
        SetHistoryData({
          loading: false,
          error: true,
          data: [],
        })
      );
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-8") {
          dispatch(Set_AccessToken(null));
        }
        if (error.response.status === 401 && error.response.data.code == "-9") {
          // RefreshToken(() => {
          //   OrderHistory(dispatch, id);
          // });
        }
      }
    });
};

export const CancelOrder = async (dispatch, id, call) => {
  httpCustomer
    .post(
      `/api/v1/dispatcher/order/${id}/cancel`,
      { userId: get_id() },
      {
        headers: {
          "Accept-Language": get_lang(),
          Authorization: "Bearer " + get_accessToken(),

        },
      }
    )
    .then((res) => {
      call(true);
    })
    .catch((error) => { });
};

export const DoneOrder = async (dispatch, id, call) => {
  httpCustomer
    .post(
      `/api/v1/dispatcher/order/${id}/done`,
      { userId: get_id() },
      {
        headers: {
          "Accept-Language": get_lang(),
          Authorization: "Bearer " + get_accessToken(),

        },
      }
    )
    .then((res) => {
      call(true);
    })
    .catch((error) => { });
};

export const TrackOrderDriver = async (dispatch, id, call) => {

  if (id) {
    httpCustomer
      .post(
        `/api/v1/dispatcher/order/${id}/track-driver`,
        {},
        {
          headers: {
            "Accept-Language": get_lang(),
            Authorization: "Bearer " + get_accessToken(),
          },
        }
      )
      .then((res) => {
        call(res.data.data);
      })
      .catch((error) => { });
  }
};

export const GetDriverListHttp = async (dispatch, data) => {
  dispatch(
    SetDriverListloadingOrderedPage({
      DriverlistLoading: true,
      DriverlistOrdered: true,
    })
  );

  httpCustomer
    .get(`/api/v1/dispatcher/driver`, {
      params: data,
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      dispatch(
        SetDriverListloadingOrderedPage({
          DriverlistLoading: false,
          DriverlistPage: data.page,
        })
      );
      dispatch(SetDriverListData(res.data.data));
    })
    .catch((error) => {
      dispatch(
        SetDriverListloadingOrderedPage({
          DriverlistLoading: false,
        })
      );

      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-8") {
          dispatch(Set_AccessToken(null));
        }

        if (error.response.status === 401 && error.response.data.code == "-9") {
          // RefreshToken(() => {
          //   GetDriverListHttp(dispatch, data);
          // });
        }
      }
    });
};

export const GetDriverOrderListHttp = async (dispatch, data, noLoading) => {
  dispatch(
    SetDriverListloadingOrderedPage({
      OrderListLoading: noLoading ? false : true,
      OrderListOrdered: true,
    })
  );

  httpCustomer
    .get(`/api/v1/dispatcher/order/`, {
      params: data,
      cancelToken: new CancelToken(function executor(c) {
        cancelorderlist = c;
      }),
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      if (res.data.data) {
        dispatch(
          SetDriverListloadingOrderedPage({
            OrderListLoading: false,
            // OrderListOrderedPage: data.page,
          })
        );
        dispatch(SetDriverOrdersListData(res.data.data.orders));
      }
    })
    .catch((error) => {
      snack.warning(error.response.data.message)
      dispatch(
        SetDriverListloadingOrderedPage({
          OrderListLoading: false,
          OrderListOrdered: false,
        })
      );
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-8") {
          dispatch(Set_AccessToken(null));
        } else if (error.response.status === 401 && error.response.data.code == "-9") {
          // RefreshToken(() => {
          //   GetDriverOrderListHttp(dispatch, data);
          // });
        }
      }
    });
};



export const GetDriverInfoHttp = async (dispatch, data) => {

  httpCustomer
    .get(`/api/v1/dispatcher/driver/${data}`, {
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      if (res.data.data) {
        dispatch(
          SetDriverListloadingOrderedPage({
            driverInfo: res.data.data,
            activeDriver: res.data.data,
          })
        );
      }
    })
    .catch((error) => {

      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-8") {
          dispatch(Set_AccessToken(null));
        }

        if (error.response.status === 401 && error.response.data.code == "-9") {
          // RefreshToken(() => {
          //   GetDriverInfoHttp(dispatch, data);
          // });
        }
      }
    });
};






export const Get_Addressbook_list_Api = async (dispatch, data, call) => {

  cancelgetlistAddressbook && cancelgetlistAddressbook("holy ...");

  dispatch(SetAddressBookListData({
    listLoading: true,
    listError: false,
  }))

  httpCustomer
    .get(
      `/api/v1/dispatcher/customer/address`, {
      cancelToken: new CancelToken(function executor(c) {
        cancelgetlistAddressbook = c;
      }),
      params: {
        ...data
      },
      headers: {
        "Accept-Language": get_lang(),
        "Content-type": "application/json",
        Authorization: "Bearer " + get_accessToken(),
      },
    }
    )
    .then((res) => {
      call && call(res.data.data)

      dispatch(SetAddressBookListData({
        list: res.data.data,
        listLoading: false,
        listError: false,
        page: res.data.data.length > 0 ? data.page + 1 : data.page,
        update: data.page == 1 ? false : true
      }))

    })
    .catch((error) => {
      dispatch(SetAddressBookListData({
        listLoading: false,
        listError: true,
      }))
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          // RefreshToken(() => {
          //   Get_Addressbook_list_Api(dispatch, data, call);
          // });
          //token exchange
        } else if (error.response && error.response.status === 403) {
          localStorage.clear()
          window.location.reload();
        } else {

        }
      }
    });
};







export const Update_Order_Status_Api = async (dispatch, data, call) => {

  httpCustomer
    .patch(`/api/v1/dispatcher/order/ondemand/${data.id}/status`, data.data, {
      headers: {
        "Accept-Language": get_lang(),
        "Content-type": "application/json",
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      call({
        request: false,
        openEditDriver: true
      })
      dispatch(SetOrderInfo(res.data.data));
      snack.success_i18('Updatedsuccessfully')


    })
    .catch((error) => {
      call({
        request: false,
        openEditDriver: false
      })
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          // RefreshToken(() => {
          //   Update_Order_Status_Api(dispatch, data, call);
          // });
          //token exchange
        } else {
          snack.warning(error.response.data.message)
        }
      }
    });
};



export const OrderEditEarning = async (
  dispatch,
  data, id
) => {
  httpCustomer
    .patch(`/api/v1/dispatcher/order/${id || data.id}/earning`, data, {
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      snack.success_i18('Updatedsuccessfully')

      dispatch(
        OrderInfoAction(dispatch, {
          orderId: id,
        })
      );
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-8") {
          dispatch(Set_AccessToken(null));
        } else if (error.response.status === 401 && error.response.data.code == "-9") {
          // RefreshToken(() => {
          //   OrderEditEarning(dispatch,
          //     data, id);
          // });
        } else {
          snack.warning(error.response.data.message)
        }
      }
    });
};





export const Get_Order_Events_Api = async (dispatch, id) => {


  dispatch(Set_viewOrder_events({
    loading: true,
    error: false,
  }))

  httpCustomer
    .get(
      `/api/v1/dispatcher/order/${id}/event`, {
      headers: {
        "Accept-Language": get_lang(),
        "Content-type": "application/json",
        Authorization: "Bearer " + get_accessToken(),
      },
    }
    )
    .then((res) => {

      dispatch(Set_viewOrder_events({
        loading: false,
        data: res.data.data,
      }))
    })
    .catch((error) => {

      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          // RefreshToken(() => {
          //   Get_Order_Events_Api(dispatch, id);
          // });
        } else if (error.response.status === 403) {
          localStorage.clear()
          window.location.reload();
        } else {
        }
      }
    });
};



export const CreateNewEvent_Http = async (dispatch, data, id) => {
  httpCustomer
    .post(`/api/v1/dispatcher/order/${id}/event`, data, {
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      dispatch(OrderGetEventsAction(dispatch, id))
      dispatch(Set_New_Event({ canSelectLocation: false }))
      dispatch(Set_New_Event({ mapLocation: null }))
      dispatch(Set_New_Event({ show: false }))

    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-8") {
          dispatch(Set_AccessToken(null));
        }

        if (error.response.status === 401 && error.response.data.code == "-9") {
          // RefreshToken(() => {
          //   CreateNewEvent_Http(dispatch, data, id);
          // });
        }
      }
    });
};



export const AdvancedAssaigning_OrderPickupInfo = async (dispatch, data) => {
  dispatch(AdvancedAssaigning_setVehicleType({
    PickupOrderInfo: null,
    PickupOrderLoading: true,
    PickupOrderError: false,
  }));
  httpCustomer
    .get(`/api/v1/dispatcher/order/${data}`, {
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      dispatch(AdvancedAssaigning_setVehicleType({
        PickupOrderInfo: res.data.data,
        PickupOrderLoading: false,
        PickupOrderError: false,
      }));
    })
    .catch((error) => {
      dispatch(AdvancedAssaigning_setVehicleType({
        PickupOrderLoading: false,
        PickupOrderError: true,
      }));
      dispatch(SetOrderInfoError(true));
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-8") {
          dispatch(Set_AccessToken(null));
        }

        if (error.response.status === 401 && error.response.data.code == "-9") {
          // RefreshToken(() => {
          //   AdvancedAssaigning_OrderPickupInfo(dispatch, data);
          // });
        }
      }
    });
};



export const AdvancedAssaigning_OrderDeliveryInfo = async (dispatch, data) => {
  dispatch(AdvancedAssaigning_setVehicleType({
    DliveryOrderInfo: null,
    DliveryOrderLoading: true,
    DliveryOrderError: false,
  }));

  httpCustomer
    .get(`/api/v1/dispatcher/order/${data}`, {
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      dispatch(AdvancedAssaigning_setVehicleType({
        DliveryOrderInfo: res.data.data,
        DliveryOrderLoading: false,
        DliveryOrderError: false,
      }));
    })
    .catch((error) => {
      dispatch(AdvancedAssaigning_setVehicleType({
        DliveryOrderLoading: false,
        DliveryOrderError: true,
      }));
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-8") {
          dispatch(Set_AccessToken(null));
        }

        if (error.response.status === 401 && error.response.data.code == "-9") {
          // RefreshToken(() => {
          //   AdvancedAssaigning_OrderDeliveryInfo(dispatch, data);
          // });
        }
      }
    });
};



export const VehicletypeForMonitoring = async (dispatch, data, func) => {
  httpCustomer
    .get(`/api/v1/dispatcher/service/vehicle-type`, {
      params: data,
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      dispatch(OrderListFilterVehicletypes(res.data.data))

      if (!_.isEmpty(res.data.data)) {
        dispatch(MonitoringSet_VehicleTypes(res.data.data));
        func(res.data.data)

      } else {
        func(false)
        dispatch(MonitoringSet_VehicleTypes([]));

      }
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-8") {
          dispatch(Set_AccessToken(null));
        }

        if (error.response.status === 401 && error.response.data.code == "-9") {
          // RefreshToken(() => {
          //   VehicletypeForMonitoring(dispatch, data, func);
          // });
        }
      }
    });
};





export const Update_Order_PickupDelivery_Status_Api = async (dispatch, data, call) => {


  httpCustomer
    .patch(`/api/v1/dispatcher/order/pickup-delivery/${data.id}/status`, data.data, {
      headers: {
        "Accept-Language": get_lang(),
        "Content-type": "application/json",
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      call({
        request: false,
        openEditDriver: true
      })
      dispatch(SetOrderInfo(res.data.data));
      snack.success_i18('Updatedsuccessfully')

    })
    .catch((error) => {
      call({
        request: false,
        openEditDriver: false
      })
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          // RefreshToken(() => {
          //   Update_Order_PickupDelivery_Status_Api(dispatch, data, call);
          // });
          //token exchange
        } else {
          snack.warning(error.response.data.message)
        }
      }
    });
};




export const Update_Order_Pickup_Status_Api = async (dispatch, data, call) => {


  httpCustomer
    .patch(`/api/v1/dispatcher/order/pickup/${data.id}/status`, data.data, {
      headers: {
        "Accept-Language": get_lang(),
        "Content-type": "application/json",
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      call({
        request: false,
        openEditDriver: true
      })
      dispatch(SetOrderInfo(res.data.data));
      snack.success_i18('Updatedsuccessfully')

    })
    .catch((error) => {
      call({
        request: false,
        openEditDriver: false
      })
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          // RefreshToken(() => {
          //   Update_Order_Pickup_Status_Api(dispatch, data, call);
          // });
          //token exchange
        } else {
          snack.warning(error.response.data.message)
        }
      }
    });
};




export const Update_Order_Delivery_Status_Api = async (dispatch, data, call) => {


  httpCustomer
    .patch(`/api/v1/dispatcher/order/delivery/${data.id}/status`, data.data, {
      headers: {
        "Accept-Language": get_lang(),
        "Content-type": "application/json",
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      call({
        request: false,
        openEditDriver: true
      })
      dispatch(SetOrderInfo(res.data.data));
      snack.success_i18('Updatedsuccessfully')

    })
    .catch((error) => {
      call({
        request: false,
        openEditDriver: false
      })
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          // RefreshToken(() => {
          //   Update_Order_Delivery_Status_Api(dispatch, data, call);
          // });
          //token exchange
        } else {
          snack.warning(error.response.data.message)
        }
      }
    });
};



export const BulkImportList = async (dispatch, data, call) => {
  httpCustomer
    .get(`/api/dispatcher/order/bulk-import/`, {
      params: {},
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      dispatch(SetBulkImportList(res.data.data))
    })
    .catch((error) => {
      call({ loading: false });

      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-8") {
          dispatch(Set_AccessToken(null));
        }
        if (error.response.status === 401 && error.response.data.code == "-9") {
          // RefreshToken(() => {
          //   BulkImportList(dispatch, data, call);
          // });
        }
      }
    });
};





export const BulkImportCsvUpload = async (dispatch, data, id, call) => {

  let percent = 0

  httpCustomerUpload
    .post(`/api/dispatcher/order/bulk-import/csv`, data, {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        percent = Math.floor((loaded * 100) / total)
        // just to see whats happening in the console
        if (percent) {
          dispatch(SetActiveImportData({
            id,
            percentUpload: percent
          }))
        }
      },
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken()
      },
    })
    .then((res) => {

      dispatch(SetActiveImportData({
        id,
        uploading: false,
        uploaded: true,
        status: "processing",
        fromServer: true,
        percentUpload: 100
      }))

      BulkImportGetInfo(dispatch, res.data.data.id, id)
    })
    .catch((error) => {
      dispatch(SetActiveImportData({
        id,
        uploading: false,
        uploaded: false,
        status: "draft"

      }))

      if (error.response.status === 400) {
        snack.warning(error.response.data.message)

      } else {
        snack.warning_i18("serverError")


      }


      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-8") {
          dispatch(Set_AccessToken(null));
        }
        if (error.response.status === 401 && error.response.data.code == "-9") {
          // RefreshToken(() => {
          //   BulkImportList(dispatch, data, call);
          // });
        }
      }
    });
};


export const BulkImportGetInfo = async (dispatch, id, localId) => {


  httpCustomer
    .get(`/api/dispatcher/order/bulk-import/${id}`, {
      params: {},
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      if (localId) {
        dispatch(Create_new_BulkImport_List({
          ...res.data.data,
          info: res.data.data
        }))
        dispatch(SetActiveImport(id, "imported"))
        dispatch(Remove_draft_bulkImport(localId))

      } else {
        dispatch(SetActiveImportDataList({
          id,
          status: res.data.data.status,
          info: res.data.data
        }))
      }



    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-8") {
          dispatch(Set_AccessToken(null));
        }

        if (error.response.status === 401 && error.response.data.code == "-9") {
          // RefreshToken(() => {
          //   BulkImportGetInfo(dispatch, id);
          // });
        }
      }
    });
};



export const BulkImport_GetFailedRecords = async (dispatch, id, fileName) => {
  httpCustomer
    .get(`/api/dispatcher/order/bulk-import/${id}/failed-records/csv`, {
      params: {},
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      FileDownload(res.data, `${fileName}-errors.csv`)
    })
    .catch((error) => {

      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-8") {
          dispatch(Set_AccessToken(null));
        }
        if (error.response.status === 401 && error.response.data.code == "-9") {
          // RefreshToken(() => {
          //   BulkImport_GetFailedRecords(dispatch, id, fileName);
          // });
        }
      }
    });
};




export const Add_Payment_Method = async (data, call) => {
  httpCustomer
    .post(
      `/api/dispatcher/payment/stripe/payment-method`,
      data,
      {
        headers: {
          "Accept-Language": get_lang(),
          Authorization:
            "Bearer " + (get_accessToken()),
        },
      }
    )
    .then((res) => {
      call(res.data.data)
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.data) {
          snack.error(error.response.data.message)
        }
      }

      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          // RefreshToken(() => {
          //   Add_Payment_Method(call, data);
          // });
        }
      }
      call(false)

    });
};



export const Get_Stripe_Cards = async (call, customerId) => {
  httpCustomer
    .get(`/api/dispatcher/payment/stripe/payment-method`, {
      params: {
        customerId
      },
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      call(res.data.data)
    })
    .catch((error) => {

      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-8") {
        }
        if (error.response.status === 401 && error.response.data.code == "-9") {
          // RefreshToken(() => {
          //   Get_Stripe_Cards(call, customerId);
          // });
        }
      }
    });
};



export const Get_User_Recent_Address_Api_Http = async (dispatch, customerId, call) => {
  httpCustomer
    .get(`/api/dispatcher/customer/recent-address`, {
      params: {
        customerId
      },
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      dispatch(SetRecentAddress(res.data.data))
    })
    .catch((error) => {

      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-8") {
          dispatch(Set_AccessToken(null));
        }
        if (error.response.status === 401 && error.response.data.code == "-9") {
          // RefreshToken(() => {
          //   Get_User_Recent_Address_Api_Http(dispatch, customerId, call);
          // });
        }
      }
    });
};




export const Register_New_Customer_Api = async (dispatch, data, call) => {
  httpCustomer
    .post(
      `/api/dispatcher/customer/individual/register`,
      {
        ...data
      },
      {
        headers: {
          "Accept-Language": get_lang(),
          Authorization:
            "Bearer " + (get_accessToken()),
        },
      }
    )
    .then((res) => {
      try {
        call(res.data.data.customerId)
      } catch (error) {
        call(false)
      }
    })
    .catch((error) => {
      call(false, error.response.data.message,)

      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-9") {
          // RefreshToken(() => {
          //   Register_New_Customer_Api(dispatch, data, call);
          // });
        }
      }
    });
};



export const CustomerGetInfoHttp = async (dispatch, data, call) => {

  httpCustomer
    .get(`/api/dispatcher/customer/${data}`, {
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      call(res.data.data)
    })
    .catch((error) => {

      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-8") {
          dispatch(Set_AccessToken(null));
        }

        if (error.response.status === 401 && error.response.data.code == "-9") {
          // RefreshToken(() => {
          //   CustomerGetInfoHttp(dispatch, data, call);
          // });
        }
      }
    });
};



export const SetEventForOrderFromList_Http = async (dispatch, data) => {
  httpCustomer
    .post(`/api/dispatcher/order/${data.orderId}/event/incoming-call`, {
      "dispatcherId": get_id(),
      "voipRecordId": data.voipRecordId
    }, {
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {

    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-8") {
          dispatch(Set_AccessToken(null));
        }

        if (error.response.status === 401 && error.response.data.code == "-9") {
          // RefreshToken(() => {
          //   SetEventForOrderFromList_Http(dispatch, data);
          // });
        }
      }
    });
};


export const GetEventVoipRecorded_http = async (dispatch, data, call) => {

  httpCustomer
    .get(`/api/dispatcher/voip/${data}/file`, {
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      call(res)
    })
    .catch((error) => {
      call(null, true)

      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-8") {
          dispatch(Set_AccessToken(null));
        }

        if (error.response.status === 401 && error.response.data.code == "-9") {
          // RefreshToken(() => {
          //   GetEventVoipRecorded_http(dispatch, data, call);
          // });
        }
      }
    });
};



export const Voip_OutgoingCall_Http = async (dispatch, data) => {
  httpCustomer
    .post(`/api/dispatcher/voip/outgoing`, data, {
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {

    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-8") {
          dispatch(Set_AccessToken(null));
        } if (error.response.status === 401 && error.response.data.code == "-9") {
          // RefreshToken(() => {
          //   Voip_OutgoingCall_Http(dispatch, data);
          // });
        } else {
          snack.warning(error.response.data.message)
        }
      }
    });
};



export const Business_account_http = async (dispatch, data, call) => {
  httpCustomer
    .get(`/api/customer/business/account/${data}`, {
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      call(res.data.data)
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-8") {
          dispatch(Set_AccessToken(null));
        } if (error.response.status === 401 && error.response.data.code == "-9") {
          // RefreshToken(() => {
          //   Business_account_http(dispatch, data, call);
          // });
        } else {
          dispatch({
            type: "addErrorCatch",
            state: { name: "Api" },
          })
          snack.warning(error.response.data.message)
        }
      }
    });
};


export const Edit_individual_info_http = async (dispatch, data, call) => {
  httpCustomer
    .patch(`/api/dispatcher/customer/individual/${data.id}`, data.info, {
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      call(res.data.data)
      snack.success_i18('Updatedsuccessfully')

    })
    .catch((error) => {
      snack.warning(error.response.data.message)

      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-8") {
          dispatch(Set_AccessToken(null));
        } if (error.response.status === 401 && error.response.data.code == "-9") {
          // RefreshToken(() => {
          //   Voip_OutgoingCall_Http(dispatch, data);
          // });
        } else {
          snack.warning(error.response.data.message)
        }
      }
    });
};


export const optimizeDropoffs_Http = async (dispatch, id, call) => {
  httpCustomer
    .post(`/api/dispatcher/order/${id}/optimize-dropoffs`, {
      "dispatcherId": get_id(),
    }, {
      headers: {
        "Accept-Language": get_lang(),
        Authorization: "Bearer " + get_accessToken(),
      },
    })
    .then((res) => {
      snack.success_i18("Updatedsuccessfully")
      call()
      dispatch(
        OrderInfoAction(dispatch, {
          orderId: id,
        })
      );
    })
    .catch((error) => {
      call()
      if (error.response) {
        if (error.response.status === 401 && error.response.data.code == "-8") {
          dispatch(Set_AccessToken(null));
        } if (error.response.status === 401 && error.response.data.code == "-9") {
          // RefreshToken(() => {
          //   optimizeDropoffs_Http(dispatch, id, call);
          // });
        } else {
          snack.warning(error.response.data.message)
        }
      }
    });
};