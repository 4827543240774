import PickGoPickGo from "../../../assets/Ondelivery_logotype.png";
import FavIcon from "../../../assets/PROJECTS/Ondelivery.png";

export default function Ondelivery() {
    
    return global.config = {
    "ProjectName": "On Delivery",
    "LogoType": PickGoPickGo,
    "BaseApi": "https://rest.ondelivery.us",
    "BaseSocket": "https://socket.ondelivery.us",
    "BaseTrack": "https://admin.ondelivery.us",
    "MapCenter": {
        "lat": 42.33129264827773 ,
        "lng": -83.04864974934947
    },
    "Lang": "en",
    "Direction": "ltr",
    "AllLanguages": [{
        "lang": "en",
        "direction": "ltr",
        "i18Lang": "English"
    }],
    "FavIcon": FavIcon,
    "Country": "US",
    "thirdPartyLoginSupport": true  ,
    mapType :"osm", /* osm | google */ 
    googleApiKey : ""
}}