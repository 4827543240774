import caseWorldLogo from "../../../assets/Deliveryondemand.png";
import FavIcon from "../../../assets/PROJECTS/Deliveryondemand_fav_icon.png";

export default function Deliveryondemand () {
    
    return global.config = {
    "ProjectName": "Delivery ondemand",
    "LogoType": caseWorldLogo,
    "BaseApi": "https://rest.deliveryondemand.co",
    "BaseSocket": "https://socket.deliveryondemand.co",
    "BaseTrack": "https://track.deliveryondemand.co",
    "MapCenter": {
        "lat": 28.537489344437816,
        "lng": -81.37801038842399
    },
    "Lang": "en",
    "Direction": "ltr",
    "AllLanguages": [{
        "lang": "en",
        "direction": "ltr",
        "i18Lang": "English"
    }],
    "FavIcon": FavIcon,
    "Country": "US",
    "thirdPartyLoginSupport": true  ,
    mapType :"osm", /* osm | google */ 
    googleApiKey : ""
} }