import logoType from "../../../assets/latreqi.png";
import FavIcon from "../../../assets/PROJECTS/latreqi_fav_icon.png";

export default function latreqi() {
    
    return global.config = {
    "ProjectName": "3latreqi",
    "LogoType": logoType,
    "BaseApi": "https://rest.3latreqi.com",
    "BaseSocket": "https://socket.3latreqi.com",
    "BaseTrack": "https://panel.3latreqi.com",
    "MapCenter": {
        "lat": "20.019661017984113",
        "lng": "41.46562106368876"
    },
    "Lang": "en",
    "Direction": "ltr",
    "AllLanguages": [
        {
            "lang": "en",
            "direction": "ltr",
            "i18Lang": "English"
        }],
    "FavIcon": FavIcon,
    "Country": "SA",
    "thirdPartyLoginSupport": true  ,
    mapType :"osm", /* osm | google */ 
    googleApiKey : "",
    customFont:"arabic"
} }