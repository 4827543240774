export const AustrianGermanLabel = {
    "Enter_the_following_information_to_enter": "Bitte folgende Informationen angeben:",
    "login": "Login",
    "Username": "Benutzername",
    "password": "Passwort",
    "Orders": "Aufträge",
    "Online": "Online",
    "Offline": "Offline",
    "Info": "Info",
    "email": "Email",
    "vehicletypes": "Fahrzeugtypen",
    "loginstatus": "Login Status",
    "lastonlinetime": "Zuletzt online",
    "fetchingdata": "Empfange Daten",
    "poperLoading": "Bitte warten",
    "Noinformationfound": "Keine Informationen gefunden",
    "Drivers": "Fahrer",
    "otherdropoffs": "weitere Zustelladressen",
    "paymentmethod": "Zahlungsmethode",
    "Receiver": "Vom Empfänger",
    "Sender": "Vom Versender",
    "Balance": "Saldo",
    "Cash": "Bar",
    "Wallet": "Konto",
    "Close": "Schliessen",
    "noVehicleType": "Kein Fahrzeugtyp",
    "Fname": "Vorname",
    "Lname": "Nachname",
    "Pnumber": "Mobil-Nummer",
    "driver": "Fahrer",
    "Pleasetryanotherkeyword": "Bitte versuche eine anderes Passwort",
    "DropOff": "Zustelladresse",
    "Noaddressavailable": "Keine Adresse verfügbar",
    "Managefavoriteaddresses": "Adressen verwalten",
    "address": "Adresse",
    "Saved": "Gespeichert",
    "block": "Stiege",
    "Floor": "Stockwerk",
    "Unit": "Türnummer",
    "Recieverfullname": "Firmenname, Person",
    "Recieverphonenumber": "Mobil-Nummer",
    "notesfordriver": "Nachricht für Fahrer",
    "Price": "Preis",
    "EditOrder": "Auftrag anpassen",
    "Firstname": "Vorname",
    "Lastname": "Nachname",
    "Phonenumber": "Mobil-Nummer",
    "senderfullname": "Firmenname, Person",
    "senderphonenumber": "Mobil-Nummer",
    "Search": "Suchen",
    "Service": "Serviceart",
    "Passenger": "Kunde",
    "Pickup": "Abholadresse",
    "Pricedetails": "Preisdetails",
    "Fee": "Gebühr",
    "Fornow": "Ab sofort",
    "Scheduled": "Geplant",
    "before": "Vor:",
    "Settime": "Zeitfenster",
    "after": "Nach:",
    "confirm": "Bestätigen",
    "Cancel": "Schliessen",
    "Addresses": "Adressen",
    "Savedaddresses": "Adressen gespeichert",
    "new": "Neu",
    "Editaddress": "Adresse anpassen",
    "Saveaddress": "Adresse gespeichert",
    "Language": "Sprache",
    "en": "Englisch",
    "fa": "Farsi",
    "English": "Englisch",
    "Persian": "Persian",
    "Logout": "Ausloggen",
    "logotTitle": "Vom Konto ausloggen?",
    "logoutDescription": "Du wirst vom Konto abgemeldet und kannst später wieder einloggen",
    "confirmLogout": "Ja, ausloggen",
    "skipLogout": "Cancel",
    "Ascending": "Aufsteigend",
    "Descending": "Absteigend",
    "sort[createdAt]": "Datum",
    "sort[pickupScheduleDate]": "Geplant",
    "sort[statusUpdatedAt]": "Sortieren nach (Update am)",
    "SelectDateRange": "Datumsbereich",
    "StartDate": "Startdatum",
    "EndDate": "Enddatum",
    "Today": "Heute",
    "Yesterday": "Gestern",
    "ThisWeek": "Diese Woche",
    "LastWeek": "Letzte Woche",
    "Last7Days": "Die letzten 7 Tage",
    "ThisMonth": "Dieser Monat",
    "LastMonth": "Letzter Monat",
    "monitoring": "Überwachen",
    "Haveorder": "Habe Aufträge",
    "Estimated": "Geschätzt",
    "ReceiverCash": "Bar (vom Empfänger)",
    "SenderCash": "Bar",
    "SenderWallet": "Konto",
    "Auto": "Auto",
    "Manual": "Manuell",
    "SendOrderr": "Auftrag einreichen",
    "Neworder": "Einen neuen Auftrag erfassen",
    "Person": "Privatperson",
    "Business": "Firma",
    "Businessname": "Firmenname",
    "phonenumber": "Mobil-Nummer",
    "Adddropoff": "Zustelladresse hinzufügen",
    "Searchbynameorphonenumber": "Suche (nach Name oder Mobil-Nummer)",
    "Fullname": "Vor und Nachname",
    "type": "Typ",
    "only": "nur xxx",
    "ChooseDriver": "Fahrer wählen",
    "AllKey": "Alle",
    "MeKey": "Ich",
    "Vehicletype": "Fahrzeugtyp",
    "status": "Status",
    "statusInProgress": "Im Laufen",
    "statusCanceled": "Storniert",
    "statusPending": "Ausstehend",
    "statusUnassigned": "Nicht zugewiesen",
    "statusDone": "Erledigt",
    "dispatcher": "Dispatcher",
    "customer": "Kunde",
    "regexcode": "Trackcode",
    "regexcustomerPhone": "vom Kunden storniert",
    "regexcustomerName": "Name (Kunde)",
    "regexdriverPhone": "Mobil-Nummer (Fahrer)",
    "regexdriverName": "Name (Fahrer)",
    "regexdriverUsername": "Nutzername (Fahrer)",
    "pickupDescription": "Abholadresse",
    "filter": "Filter",
    "clear": "Löschen",
    "orderInfoError": "Fehlermeldungen / Informationen",
    "Details": "Details",
    "History": "Historie",
    "Phone": "Mobiltelefon",
    "km": "km",
    "Espath": "geschätzer Weg",
    "Receipt": "Quittung",
    "Copylink": "Link kopieren",
    "repeatOrder": "Auftrag wiederholen",
    "Edit": "Ändern",
    "EditPrice": "Preis ändern",
    "Endtrip": "Auftrag ändern",
    "Canceltravel": "Auftrag stornieren",
    "CancellText": "Nach dem stornieren, wir auch der Auftrag beim Fahrer gelöscht",
    "confirmCancel": "Ja",
    "Endtravel": "Vollständig",
    "EndlText": "Nach Erfüllung des Auftrages wir der Betrag direkt vom Kundenkonto abgezogen",
    "confirmEnd": "Ja",
    "End": "Auftrag stornieren",
    "Deliveredat": "Geliefert am",
    "pod": "Zustellnachweis",
    "Notes": "Notizen",
    "Nonotes": "Keine Notizen",
    "Photo": "Foto",
    "Signature": "Unterschrift",
    "Nosignature": "Keine Unterschrift",
    "Nophoto": "Kein Foto",
    "ServiceOptions": "Service Optionen",
    "Events": "Verlauf",
    "status2": "Status",
    "Creator": "Erfasser",
    "Creationdate": "Erstellt (Datum)",
    "Orderid": "Auftrags ID",
    "Pickup2": "Abholadresse",
    "Dropoff2": "Zustelladresse",
    "PleaseSelect": "Bitte auswählen",
    "CreateEvent": "Verlauf ändern",
    "Description": "Beschreibung",
    "detailsAboutEvent": "Details über den Verlauf",
    "CustomerVisibility": "Kundensichtbarkeit",
    "LocationOnMap": "Ort auf der Karte",
    "Pending": "Ausstehend",
    "Unassigned": "Nicht zugewiesen",
    "Assigned": "Zugewiesen",
    "PickedUp": "Abgeholt",
    "Started": "Gestartet",
    "Done": "Erledigt",
    "CustomerCanceled": "Storniert",
    "DriverCanceled": "Wurde storniert",
    "SupportCanceled": "Gelöscht",
    "Arrived": "Angekommen",
    "Createdsuccessfully": "Erfolgreich erstellt",
    "Estime": "Geschätzte Zeit",
    "Promocode": "Promo Code",
    "Updatedsuccessfully": "Erfolgreiches Update",
    "statusAssigned": "Zugewiesen",
    "statusStarted": "Gestartet",
    "statusPickedUp": "Abgeholt",
    "statusDriverCanceled": "vom Fahrer storniert",
    "statusArrived": "Angekommen",
    "statusSupportCanceled": "vom Support storniert",
    "Title": "Titel",
    "ContactFullname": "Kontakt Name",
    "Contactphonenumber": "Kontakt Mobil-Nummer",
    "Commission": "Kommission",
    "surcharge": "Zuschlag",
    "haslogined": "ist eingeloggt",
    "Notloggedin": "nicht eingeloggt",
    "Schedule": "Geplant",
    "Date": "Datum",
    "Time": "Zeit",
    "noDateToShow": "Kein Datum zum anzeigen",
    "noTimeToShow": "Keine Zeit zum anzeigen",
    "Apply": "Anwenden",
    "lowBalance": "Niedriger Saldo",
    "balanceError": "Dein Saldo muss mindestens dem Auftragswert entsprechen",
    "Methods": "Zahlungsmethode",
    "Addressbook": "Adressbuch",
    "AddressbookSearchbar": "Nach Adresse, Firma oder ID suchen",
    "AddressbookDropOf": "Adressbuch (Abholadresse)",
    "AddressbookPickup": "Adressbuch (Zustelladresse)",
    "hide": "Verbergen",
    "Nosavedaddress": "Keine gespeicherten Adressen",
    "Romanian": "Romänisch",
    "business": "Firma",
    "username": "Nutzername",
    "rememberme": "Erinnere mich",
    "SendOrder": "Auftrag absenden",
    "code": "Trackcode",
    "noorder": "Kein Auftrag",
    "pleaseSellectParsel": "Bitte einen Serviceart auswählen",
    "Order": "Auftrag",
    "Code": "Trackcode",
    "Editorder": "Auftrag ändern",
    "all": "Alle",
    "Address": "Adresse",
    "Block/Building": "Stiege",
    "Pickedupat": "Abgeholt am",
    "for": "Für",
    "fr": "Französisch",
    "AllVehicletypes": "Alle Fahrzeugtypen",
    "AllServices": "Alle Servicesarten",
    "French": "Französisch",
    "enterDataCorrectly": "Informationen korrekt eingeben",
    "controlThatbeforeLoweEqualAfter": "Zeit \"Vor:\" kann nicht kleiner als \"Nach:\" sein",
    "ForceAssign": "Zuweisung beschleunigen",
    "Allvehicletypes": "Alle Fahrzeugtypen",
    "waitforconfirm": "xxx (auf Bestätigung warten)",
    "waitforconfirm2": "(Auf Bestätigung warten)",
    "Dispatching": "Zuteilung",
    "NoDriver": "Kein Fahrer",
    "OtherDetails": "Weitere Details",
    "StatusandDriver": "Status und Fahrer",
    "EditStatusandDriver": "Status und Fahrer ändern",
    "Current": "Aktuell",
    "EDAS_Pending": "Ausstehend",
    "EDAS_Assigned": "Zugewiesen",
    "EDAS_PickedUp": "Abgeholt",
    "EDAS_Unassigned": "Nicht zugewiesen",
    "EDAS_Started": "Gestartet",
    "EDAS_Done": "Erledigt",
    "EDAS_CustomerCanceled": "Storniert vom Kunden",
    "EDAS_DriverCanceled": "Storniert vom Fahrer",
    "EDAS_SupportCanceled": "Storniert vom Support",
    "EDAS_Arrived": "Angekommen",
    "Pleasechooseadrivertochangestatus": "Ändern des Status kann den Preis beeinflussen",
    "icWarningEdit": "Fahrzeugtyp wird geändert",
    "Vehicletypewillbechanged": "Dein aktueller Fahrzeugtyp und Preis wird geändert.",
    "Yourcurrentvehicletypeanditwillbechangedandeffectsonprice": "Der gewählte Fahrzeugtyp hat den Preis verändert und wird verrechnet.",
    "Rejected": "Zurückgewiesen",
    "Rejected2": "(Zurückgewiesen)",
    "DriverVehicleTypeError": "Es gibt keinen Fahrzeugtyp für diesen Fahrer",
    "CodeNameFamilyPhonenumber": "Trackcode, Firma, Name, Mobil-Nummer",
    "youcanselectdriverintheedit": "Du kannst den Fahrer im /ändern des Fahrer und Status/ auswählen",
    "Earning": "Verdienst",
    "savePrice": "Preis speichern",
    "saveEarning": "Verdienst speichern",
    "Total": "Total",
    "EditPickupDropOff": "Edit Addresses",
    "noMoreData": "Keine zusätzlichen Informationen",
    "OnDemand": "Direkt",
    "PD": "A nach B",
    "PD2": "A nach BC",
    "PickupDropoff": "Ab und Zustellung",
    "PickupFlag": "A",
    "DropOffFlag": "B",
    "PickupandDelivery": "Abholung und Zustellung",
    "PickupDelivery": "Abholung Zustellung",
    "Delivery": "Zustellung",
    "Collecting": "Beim Beladen",
    "Transit": "Im Transit",
    "Delivering": "Beim Zustellen",
    "EDAS_Collecting": "Beim Beladen",
    "EDAS_Transit": "Unterwegs",
    "EDAS_Delivering": "Beim Zustellen",
    "statusCollecting": "Beim Beladen",
    "statusTransit": "Unterwegs",
    "statusDelivering": "Beim Zustellen",
    "serviceCustomerError1": "xxx ist nicht für den Kunden verfügbar",
    "serviceCustomerError2": "Du kann nur die xxx Serviceart wählen",
    "serviceCustomerError3": "Du kann nur die xxx",
    "OrderCode": "Auftragsnummer",
    "event_Created": "Erstellt",
    "event_Unassigned": "Nicht zugewiesen",
    "event_Assigned": "Zugewiesen",
    "event_Started": "Gestartet",
    "event_Arrived": "Angekommen",
    "event_PickedUp": "Abgeholt",
    "event_OrderDone": "Auftrag erledigt",
    "event_DropoffDone": "Zustellung erledigt",
    "event_CustomerFeedback": "Kundenbewertung",
    "event_AddPod": "Zustellnachweis hinzufügen",
    "event_CustomerCanceled": "Storniert vom Kunden",
    "event_DriverCanceled": "Storniert vom Fahrer",
    "event_SupportCanceled": "Storniert vom Support",
    "event_Updated": "Updated",
    "event_DriverAssignment": "Fahrer zugewiesen",
    "event_DriverReject": "Vom Fahrer abgewiesen",
    "event_Collecting": "Beim Beladen",
    "event_InTransit": "Unterwegs",
    "event_Delivering": "Beim Zustellen",
    "event": "Verlauf",
    "tab_Delivery": "Zustellung",
    "tab_Ondemand": "Direkt",
    "tab_Pickup": "Abholung",
    "tab_PickupDelivery": "A nach B",
    "CurrentStatus": "Aktueller Status",
    "Changestatus": "Status ändern",
    "Newstatus": "Neuer Status",
    "Savechanges": "Änderungen speichern",
    "Other": "Weitere",
    "Shipmentlabel": "Versandlabel",
    "pleaseSellectService": "Bitte Serviceart auswählen",
    "EditPrice2": "Preis ändern",
    "EditEarning": "Verdienst ändern",
    "statusCustomerCanceled": "Storniert vom Kunden",
    "Dutch": "Holländisch",
    "Ondemand": "Direkt",
    "todayat": "Heute um",
    "tomorrowat": "Morgen um",
    "nextWeekat": "Nächste Woche um",
    "lastDayat": "Gestern",
    "lastWeekat": "Letzte Woche",
    "CopyOrderlink": "Auftragslink kopieren",
    "CopyPickuplink": "Abhollink kopieren",
    "CopyDeliverylink": "Zustelllink kopieren",
    "Bulkimport": "Sammelimport",
    "Downloadsamplecsv": "Download Muster csv",
    "processing": "Im Laufen",
    "done": "Erledigt",
    "failed": "Fehler",
    "Draft": "Entwurf",
    "Uploading": "Es wird hochgeladen",
    "Draganddropyourfile": "Datei hier hereinziehen (Drag&Drop)",
    "Onlycsvformatissupported": "Akzeptiere Formate csv",
    "ORyoucan": "Oder du kannst",
    "Openfile": "Offenes Format",
    "Noimportsyet": "Nicht jetzt importiert",
    "NoimportsyetDesc": "Hier kannst du die Liste und Status des Imports sehen",
    "nosupportdialogeTitle": "Format nicht unterstützt",
    "nosupportdialogeText": "xxx-Datei wird nicht unterstützt. Sie können nur eine CSV-Datei mit Auftragsinformationen hochladen",
    "ok": "Ok",
    "Import": "Import",
    "previewTitle": "Vorschau und wählen Sie Optionen",
    "previewDesc": "Das ist die erste Zeile deiner Datei und kontrolliere die richtige Struktur",
    "Upload": "Hochladen",
    "Remove": "Entfernen",
    "ServiceandOrdertype": "Serviceart und Auftragstyp",
    "Fileisproccessing": "Datei wird verarbeitet",
    "proccessingDesc": "Aktualisieren um dem Fortschritt zu sehen",
    "serverError": "Serverfehler",
    "isRequired": "xxx ist erforderlich",
    "Result": "Ergebnis",
    "ResultText": "Sie können die Probleme hier sehen und diese Zeilen herunterladen, um sie separat zu beheben",
    "ErrorDetails": "Fehler Details",
    "ErrorDetailsText": "Aufträge mit Fehlern sind in der Datei aufgeführt, die Sie unten herunterladen können. Es enthält Fehlerdetails für jede Zeile.",
    "DownloadErroredRows": "Runterladen von fehlerhaften Zeilen",
    "importedsuccessfully": "xxx Aufträge sind erfolgreich importiert",
    "ordershaserrors": "xxx Aufträge haben einen Fehler",
    "refresh": "Aktualisierung",
    "CashOnDelivery": "Nachnahme",
    "EnterAmount": "Betrag eingeben",
    "COD": "Nachnahme",
    "Surchargexxx": "Zuschläge xxx",
    "AddCreditCard": "Karte hinzufügen",
    "CustomerWallet": "Guthaben",
    "Add": "Hinzufügen",
    "Addedsuccessfully": "Erfolgreich hinzugefügt",
    "NoRecentAddress": "Keine aktuelle Adresse",
    "NoRecentAddressD": "Aktuelle Kundenadresse ist nicht angeführt",
    "CustomerIsNotChoosed": "Der Kunde wird nicht ausgewählt",
    "CustomerIsNotChoosedD": "Wählen Sie den Kunden aus, um seine aktuelle Adresse anzuzeigen",
    "SearchAddress": "Suche Adresse",
    "NoCustomerfound": "Gewünschter Kunde nicht gefunden",
    "NoCustomerfoundD": "Sie können den Kunden erstellen, wenn es sich um einen neuen handelt",
    "NewCustomer": "Neuer Kunde",
    "Existing": "Alt",
    "EmailOtional": "E-Mail-Adresse (Optional)",
    "phonenumberisnotcorrect": "Mobil-Nummer ist nicht korrekt",
    "Emailisnotcorrect": "E-Mail-Adresse ist nicht korrekt",
    "Registercustomer": "Kundenregistrierung",
    "incoming": "Eingehend",
    "outgoing": "Ausgehend",
    "recording": "Aufnahme",
    "driveroperatorCallStatus": "Fahrer ruft Dispatcher",
    "customeroperatorCallStatus": "Kunde ruft Dispatcher",
    "newCustomeroperatorCallStatus": "Neuer Kunde ruft Dispatcher",
    "operatordriverCallStatus": "Dispatcher ruft Fahrer",
    "operatorcustomerCallStatus": "Dispatcher ruft Kunden",
    "operatornewCustomerCallStatus": "Dispatcher ruft neuen Kunden",
    "dispatcher_Voip": "Dispatcher VOIP",
    "driver_Voip": "Fahrer VOIP",
    "customer_Voip": "Kunde VOIP",
    "activeorders": "xxx aktive Aufträge",
    "Noactiveorder": "Keine aktiven Aufträge",
    "CreateOrder": "Auftrag erstellen",
    "call": "Anrufen",
    "spanish": "Spanisch",
    "es": "Spanisch",
    "networkError": "Keine Internetverbindung",
    "germany": "Deutschland",
    "de": "de",
    "Itisnotpossibletorepeattherequest": "Eine Wiederholung des Auftrages ist nicht möglich",
    "createdAt": "Erstellt von",
    "dueDate": "Fälligkeitsdatum",
    "Noresultfound": "Kein Resultat gefunden",
    "loadMore": "Mehr anzeigen",
    "Searchresult": "Suchergebnis",
    "AssignedAt": "Zugewiesen seit xxx",
    "StartedAt": "Gestartet seit xxx",
    "ArrivedAt": "Angekommen seit xxx",
    "PickedUpAt": "Abgeholt seit xxx",
    "DoneAt": "Erledigt seit xxx",
    "mindelay": "xxx min. Verspätung",
    "minlate": "xxx min. Spät",
    "PinTop": "PinTop",
    "UnPin": "UnPin",
    "Itemsonmap": "Elemente auf der Karte",
    "min": "xxx min.",
    "Confirming": "Bestätigend",
    "delay": "Verspätung",
    "late": "Spät",
    "OrderDetails": "Auftragsdetails",
    "Profile": "Profil",
    "Waitingforavailabledrivers": "Warten auf verfügbare Fahrer",
    "Queue": "Warteschlange",
    "OfferingToDrivers": "Anfrage an xxx Fahrer",
    "WaitforAccept": "Warten Sie auf die Annahme",
    "Thereisnoorder": "Es gibt keine Aufträge",
    "mapitems": "Kartenelemente",
    "Offerings": "Anfrage",
    "Reject": "Ablehnen",
    "dropoffCustomerDescription": "Abhol Informationen",
    "customerPhoto": "Kundenfoto",
    "driverPhoto": "Fahrerfoto",
    "NocustomerPhoto": "Kein Kundenfoto",
    "NodriverPhoto": "Kein Fahrerfoto",
    "initial": "Initialen",
    "notifying": "Benachrichtigung",
    "received": "Empfangen",
    "assigned": "Zugewiesen",
    "rejected": "Abgewiesen",
    "canceled": "Storniert",
    "standby": "Auf Abruf",
    "terminated": "Beendet",
    "urgentTitle": "Wechseln auf ab dringende Zuteilung",
    "urgentDesc": "Bei dringende Zuteilung erhält der Auftrag die höchste Priorität.",
    "urgentConfirm": "Ja, Dringend aktivieren.",
    "urgentClose": "Nein. Schliessen",
    "Urgent": "Dringend",
    "photos": "Fotos",
    "Portuguese": "Portugiesisch",
    "Dispatchat": "Versand an xxx",
    "AutoDispatch": "Automatisiert",
    "Dispatchtime": "Versandzeit",
    "Later": "Später",
    "DispatchingNow": "Versand jetzt",
    "NoResultfromLocalDatabase": "Kein Ergebnis aus der lokalen Datenbank",
    "NoResultfromLocalDatabaseD": "Bitte überprüfen Sie es noch einmal oder versuchen Sie es mit einem anderen Schlüsselwort",
    "NoResultfromMapService": "Kein Ergebnis vom Kartendienst",
    "now": "Absofort",
    "ascending": "Aufsteigend",
    "descending": "Absteigend",
    "Last3Month": "Letzten 3 Monate",
    "Pickupnote": "Abhol Information",
    "pop": "Abholnachweis",
    "Required": "Erforderlich",
    "SearchinMapService": "Suche in der Strassenkarte",
    "SearchinLocalDatabase": "Suche in lokaler Datenbank",
    "Creditcardnotadded": "Kredit/Debitkarte nicht hinzugefügt",
    "OptimizeDropoffs": "Optimierte Zustellungen",
    "Greek": "Griechisch",
    "el": "Griechisch",
    "ReferenceID": "Referenz ID",
    "EnterId": "Lieferschein Nr, Kundenname",
    "undefined": "undefined",
    "German": "Deutsch",
    "AustrianGerman": "Deutsch AT",
    "Options": "Optionen",
    "VehicleOptions": "Fahrzeugoptionen",
    "EditCustomer":"Kunden bearbeiten",
    "NoDriverfound":"Kein Fahrer gefunden",
    "NoDriverfoundDesc":"Überprüfen Sie Ihre Informationen und probieren Sie etwas anderes aus",
    "ClearField":"Klares Feld",
    "Map":"Karte",
    "Local":"Lokal",
    "CompleteRequiredFeileds":"Vollständige erforderliche Felder",
    "Senderinfo":"Absenderinformationen" ,
    "SchedulePickup(Optional)":"Zeitplanabholung (optional)",
    "ScheduleDelivery(Optional)": "Schedule Delivery (Optional)",

    "AddDropoff":"Dropoff hinzufügen",
    "EditPrice":"Preis bearbeiten",
    "Promotion":"Förderung",
    "tax":"Steuer",
    "surcharge":"Zuschlag XXX",
    "Receiverinfo":"Empfängerinformationen",
    "tryagain":"Something went wrong, please try again",



    "Selected date and time":"Selected date and time",
    "Reorder stops":"Reorder stops",
    "Bulk Edit Status Summary":"Bulk Edit Status Summary",
    "Successful":"Successful",
    "Failed":"Failed",
    "Order ID":"Order ID",
    "Details":"Details",
    "Result":"Result",
    "Pickup Routed":"Pickup Routed",
    "Delivery Routed":"Delivery Routed",
    "Ref. xxx":"Ref. xxx",
    "Zone":"Zone",
    "Clear":"Clear",
    "Apply":"Apply",
    "No result found":"No result found",
    "Type your keyword":"Type your keyword",
    "Type in the input to start search":"Type in the input to start search",
    "We are sorry ...":"We are sorry ...",
    "The page you are trying to access has restricted access.":"The page you are trying to access has restricted access.",
    "Please refer to your system administrator.":"Please refer to your system administrator.",
    "Failed":"Failed",
    "Done": "Done",
    "Schedule":"Schedule",
    "Proof of delivery":"Proof of delivery",
    "Signature":"Signature",
    "Driver Photo":"Driver Photo",
    "Notes":"Notes",
    "Order Info":"Order Info",
    "Order":"Order",
    "Price":"Price",
    "P_HUB_D Orders":'P_HUB_D Orders',
    "Routes":"Routes",
    "Default pickup":"Default pickup",
    'More':'More',
    'Route name':'Route name',
    'Driver':'Driver',
    'Pickup Orders':'Pickup Orders',
    'Delivery Orders':'Delivery Orders',
    'Ready For Pickup':'Ready For Pickup',
    'PricingAndEarning':"Pricing & Earning",
    "Pricing" : "Pricing",
    "save":"Save",
    "Learn":"Learn",
    "Scheduleoverlap":"The Schedule Pickup time must be before the Schedule Delivery time and have no overlap",
    "PleaseSelectCustomer":"Please select customer",
    "NoRegisteredCustomer":"No registered customer",
    "CreateAtLeastOneCustomerToCreateOrder":"Create at least one customer to create order",

}; 