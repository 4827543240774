import logoType from "../../../assets/Cityspider.png";
import FavIcon from "../../../assets/PROJECTS/Cityspider_fav_icon.png";

export default function Cityspider() {
    
    return global.config = {
    "ProjectName": "Cityspider",
    "LogoType": logoType,
    "BaseApi": "https://rest.rybit-cityspider.com",
    "BaseSocket": "https://socket.rybit-cityspider.com",
    "BaseTrack": "https://panel.rybit-cityspider.com",
    "MapCenter": {
        "lat": "51.835847464863505",
        "lng": "5.838369016918429"
    },
    "Lang": "en",
    "Direction": "ltr",
    "AllLanguages": [{
        "lang": "en",
        "direction": "ltr",
        "i18Lang": "English"
    },
    {
        "lang": "nl",
        "direction": "ltr",
        "i18Lang": "Dutch"
    }],
    "FavIcon": FavIcon,
    "Country": "NL",
    "thirdPartyLoginSupport": true  ,
    mapType :"osm", /* osm | google */ 
    googleApiKey : ""
} }