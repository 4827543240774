import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import close from "../../../../../assets/close.svg";
import { withTranslation, Trans } from "react-i18next";
import {
  OrderondemandAction,
  OrderscheduleAction,
  OrderPickupAction,
  OrderDeliveryAction,
  OrderPickupDeliveryAction,
} from "../../../../../Redux/actions/actions";
import {
  get_id,
  get_currencyCode,
  get_lang,
} from "../../../../../helper/UserData/userdate";
import { DetectOndemandOrReserve } from "../../../../../helper/worker/DetectOndemandOrReserve";
import momentJalaali from "moment-jalaali";
import ChangeMethod from "./changeMethod";
import { isEmpty } from "lodash";

var numeral = require("numeral");

class App extends React.Component {

  SendOndemandOrder=()=>{
    let MustAssaignVoipId = this.props.SelectedInCommingCallAction.type && this.props.SelectedInCommingCallAction.type == "NewOrder" ;
    DetectOndemandOrReserve(
      {
        pickup: this.props.pickup,
        dropOffs: [this.props.firstDropOff, this.props.otherdropOffs],
      },
      (call) => {
        if (call === "ondeman") {
          this.props.dispatch(
            OrderondemandAction(
              this.props.dispatch,
              {
                uid : this.props.uid ,
                voipRecordId : MustAssaignVoipId ? this.props.SelectedInCommingCallAction.Data.id : null ,
                codAmount: Number(this.props.codAmount),
                isScheduled: false ,
                // businessId: !this.props.customer.person
                //   ? this.props.customer.BusinessId
                //   : "",
                // customerId: this.props.customer.person
                //   ? this.props.customer.id
                //   : "",
                customerId: this.props.customer.person
                  ? this.props.customer.id
                  : this.props.customer.BusinessId,
                pickup: { 
                  address: this.props.pickup.address,
                  coordinates: [
                    this.props.pickup.adresss_loc.lng,
                    this.props.pickup.adresss_loc.lat,
                  ],
                  schedulePickupNow: false,
                  "scheduleDateAfter": 0,
                  "scheduleDateBefore": 0 ,
                  buildingBlock: this.props.pickup.block,
                  customerDescription: this.props.pickup.noteForDriver,
                  floor: this.props.pickup.floor,
                  fullName: this.props.pickup.senderFullName,
                  phone: this.props.pickup.senderPhoneNumber,
                  placeId: "",
                  room: this.props.pickup.unit,
                  email: this.props.pickup.email,

                },
                dropoffs: [
                  {
                    address: this.props.firstDropOff.address,
                    coordinates: [
                      this.props.firstDropOff.adresss_loc.lng,
                      this.props.firstDropOff.adresss_loc.lat,
                    ],
                    "scheduleDateAfter": 0,
                    "scheduleDateBefore": 0 ,
                    buildingBlock: this.props.firstDropOff.block,
                    floor: this.props.firstDropOff.floor,
                    fullName: this.props.firstDropOff.recieverFullName,
                    phone: this.props.firstDropOff.recieverPhoneNumber || "",
                    placeId: "",
                    room: this.props.firstDropOff.unit,
                    email:  this.props.firstDropOff.email,
                    customerDescription:
                      this.props.firstDropOff.noteForDriver,
                  },
                  ...this.props.otherdropOffs.map((drop) => {
                    return {
                      address: drop.address,
                      coordinates: [
                        drop.adresss_loc.lng,
                        drop.adresss_loc.lat,
                      ],
                      "scheduleDateAfter": 0,
                      "scheduleDateBefore": 0 ,
                      buildingBlock: drop.block,
                      floor: drop.floor,
                      fullName: drop.recieverFullName,
                      phone: drop.recieverPhoneNumber,
                      placeId: "",
                      room: drop.unit,
                      email:  drop.email,
                      customerDescription: drop.noteForDriver,
                    };
                  }),
                ],
                // options: [
                //   ...this.props.driveroptions.map((op) => {
                //     return { id: op.id, dataId: op.item ? op.item.id : "" };
                //   }),
                // ],
                service: {
                  id: this.props.serviceactiveVehicleType.id,
                  options: [
                    ...this.props.serviceoptions.map((op) => {
                      return { id: op.id, dataId: op.item ? op.item.id ? op.item.id  || null :null : null ,  inputValue: op.inputValue ? op.inputValue : null};
                    }),
                  ],
                },
                paymentMethod: this.props.paymentMethod,
                paymentSide: this.props.paymentSide,
                paymentProvider: this.props.paymentProvider,
                cardInfo: this.props.cardInfo,
                paymentMethodId: this.props.paymentMethodId,
                // vehicletypeId: this.props.auto
                //   ? this.props.auto_activeVehicleType &&
                //     this.props.auto_activeVehicleType.id
                //   : this.props.manual_activeVehicleType &&
                //     this.props.manual_activeVehicleType.id,

                vehicleType: {
                  id: this.props.auto
                    ? this.props.auto_activeVehicleType &&
                      this.props.auto_activeVehicleType.id
                    : this.props.manual_activeVehicleType &&
                      this.props.manual_activeVehicleType.id,
                  options: [
                    ...this.props.driveroptions.map((op) => {
                      return { id: op.id, dataId: op.item ? op.item.id ? op.item.id  || "" :"" : "" };
                    }),
                  ],
                },
                referenceId :this.props.referenceID ,
                isForceAssignActive: this.props.forceAssaigning ,
                driverAssignmentModel:this.props.auto ? "automatic" : "manual" ,
                driverId: this.props.auto ? null : (isEmpty(this.props.manual_id) ? null : this.props.manual_id ),
                userId: get_id(),
              },
              this.props.customer.number,
              this.props.auto
                ? null
                : {
                    id: this.props.manual_id,
                    name:
                      this.props.manual_name + " " + this.props.manual_family,
                  },
                  this.props.ListOrderTypes
            )
          );
        } else {
          this.props.dispatch(
            OrderscheduleAction(
              this.props.dispatch,
              {
                uid : this.props.uid ,
                codAmount: Number(this.props.codAmount),
                isScheduled: true ,
                // businessId: !this.props.customer.person
                //   ? this.props.customer.BusinessId
                //   : "",
                // customerId: this.props.customer.person
                //   ? this.props.customer.id
                //   : "",
                customerId: !this.props.customer.person
                ? this.props.customer.BusinessId
                : this.props.customer.id,
                vehicleType:{
                  id:this.props.auto
                  ? this.props.auto_activeVehicleType &&
                    this.props.auto_activeVehicleType.id
                  : this.props.manual_activeVehicleType &&
                    this.props.manual_activeVehicleType.id,
                    options: [
                      ...this.props.driveroptions.map((op) => {
                        return { id: op.id, dataId: op.item ? op.item.id ? op.item.id  || "" :"" : "" };
                      }),
                    ],
                },
                pickup: {
                  address: this.props.pickup.address,
                  coordinates: [
                    this.props.pickup.adresss_loc.lng,
                    this.props.pickup.adresss_loc.lat,
                  ],
                  buildingBlock: this.props.pickup.block,
                  customerDescription: this.props.pickup.noteForDriver,
                  floor: this.props.pickup.floor,
                  fullName: this.props.pickup.senderFullName,
                  phone: this.props.pickup.senderPhoneNumber,
                  placeId: "",
                  room: this.props.pickup.unit,
                  email: this.props.pickup.email,
                  schedulePickupNow:
                    this.props.pickup.scheduleDateAfter ||
                    this.props.pickup.scheduleDateBefore
                      ? false
                      : true,
                  scheduleDateAfter: momentJalaali(
                    this.props.pickup.scheduleDateAfter
                  ).isValid()
                    ? momentJalaali(
                        Number(this.props.pickup.scheduleDateAfter + "000")
                      ).unix()
                    : 0,
                  scheduleDateBefore: momentJalaali(
                    this.props.pickup.scheduleDateBefore
                  ).isValid()
                    ? momentJalaali(
                        Number(this.props.pickup.scheduleDateBefore + "000")
                      ).unix()
                    : 0,
                },
                dropoffs: [
                  {
                    address: this.props.firstDropOff.address,
                    coordinates: [
                      this.props.firstDropOff.adresss_loc.lng,
                      this.props.firstDropOff.adresss_loc.lat,
                    ],
                    buildingBlock: this.props.firstDropOff.block,
                    floor: this.props.firstDropOff.floor,
                    fullName: this.props.firstDropOff.recieverFullName,
                    phone: this.props.firstDropOff.recieverPhoneNumber || "",
                    placeId: "",
                    email:  this.props.firstDropOff.email,
                    room: this.props.firstDropOff.unit,
                    customerDescription:
                      this.props.firstDropOff.noteForDriver,
                    scheduleDateAfter: momentJalaali(
                      this.props.firstDropOff.scheduleDateAfter
                    ).isValid()
                      ? momentJalaali(
                          Number(
                            this.props.firstDropOff.scheduleDateAfter + "000"
                          )
                        ).unix()
                      : 0,
                    scheduleDateBefore: momentJalaali(
                      this.props.firstDropOff.scheduleDateBefore
                    ).isValid()
                      ? momentJalaali(
                          Number(
                            this.props.firstDropOff.scheduleDateBefore + "000"
                          )
                        ).unix()
                      : 0,
                  },
                  ...this.props.otherdropOffs.map((drop) => {
                    return {
                      address: drop.address,
                      coordinates: [
                        drop.adresss_loc.lng,
                        drop.adresss_loc.lat,
                      ],
                      buildingBlock: drop.block,
                      floor: drop.floor,
                      fullName: drop.recieverFullName,
                      phone: drop.recieverPhoneNumber,
                      placeId: "",
                      room: drop.unit,
                      email:  drop.email,
                      customerDescription: drop.noteForDriver,
                      scheduleDateAfter: momentJalaali(
                        drop.scheduleDateAfter
                      ).isValid()
                        ? momentJalaali(
                            Number(drop.scheduleDateAfter + "000")
                          ).unix()
                        : 0,
                      scheduleDateBefore: momentJalaali(
                        drop.scheduleDateBefore
                      ).isValid()
                        ? momentJalaali(
                            Number(drop.scheduleDateBefore + "000")
                          ).unix()
                        : 0,
                    };
                  }),
                ],
                // options: [
                //   ...this.props.driveroptions.map((op) => {
                //     return { id: op.id, dataId: op.item ? op.item.id : "" };
                //   }),
                // ],
                service: {
                  id: this.props.serviceactiveVehicleType.id,
                  options: [
                    ...this.props.serviceoptions.map((op) => {
                      return { id: op.id, dataId: op.item ? op.item.id ? op.item.id  || null :null : null ,  inputValue: op.inputValue ? op.inputValue : null};
                    }),
                  ],
                },
                paymentMethod: this.props.paymentMethod,
                paymentSide: this.props.paymentSide,
                paymentProvider: this.props.paymentProvider,
                cardInfo: this.props.cardInfo,
                paymentMethodId: this.props.paymentMethodId,
                referenceId :this.props.referenceID ,
                // vehicletypeId: this.props.auto
                //   ? this.props.auto_activeVehicleType &&
                //     this.props.auto_activeVehicleType.id
                //   : this.props.manual_activeVehicleType &&
                //     this.props.manual_activeVehicleType.id,
                isForceAssignActive: this.props.forceAssaigning ,
                driverAssignmentModel:this.props.auto ? "automatic" : "manual" ,
                driverId: this.props.auto ? null : (isEmpty(this.props.manual_id) ? null : this.props.manual_id ),
                userId: get_id(),
              },
              this.props.customer.number,
              this.props.auto
                ? null
                : {
                    id: this.props.manual_id,
                    name:
                      this.props.manual_name + " " + this.props.manual_family,
                  },
                  this.props.ListOrderTypes
            )
          );
        }
      }
    );
  }

  SendPickupOrder=()=>{
    let MustAssaignVoipId = this.props.SelectedInCommingCallAction.type && this.props.SelectedInCommingCallAction.type == "NewOrder" ;


    let vehicleTypeIsOk = this.props.auto
    ? this.props.auto_activeVehicleType &&
      this.props.auto_activeVehicleType.id
    : this.props.manual_activeVehicleType &&
      this.props.manual_activeVehicleType.id ;



    this.props.dispatch(
      OrderPickupAction(
        this.props.dispatch,
        {
          uid : this.props.uid ,
          voipRecordId : MustAssaignVoipId ? this.props.SelectedInCommingCallAction.Data.id : null ,
          codAmount: Number(this.props.codAmount),
          isScheduled: DetectIsSchedule(this.props.pickup) ,
          customerId: this.props.customer.person
            ? this.props.customer.id
            : this.props.customer.BusinessId,
          pickup: { 
            address: this.props.pickup.address,
            coordinates: [
              this.props.pickup.adresss_loc.lng,
              this.props.pickup.adresss_loc.lat,
            ],
            scheduleDateAfter: momentJalaali(
              this.props.pickup.scheduleDateAfter
            ).isValid()
              ? momentJalaali(
                  Number(this.props.pickup.scheduleDateAfter + "000")
                ).unix()
              : 0,
            scheduleDateBefore: momentJalaali(
              this.props.pickup.scheduleDateBefore
            ).isValid()
              ? momentJalaali(
                  Number(this.props.pickup.scheduleDateBefore + "000")
                ).unix()
              : 0,
            buildingBlock: this.props.pickup.block,
            customerDescription: this.props.pickup.noteForDriver,
            floor: this.props.pickup.floor,
            fullName: this.props.pickup.senderFullName,
            phone: this.props.pickup.senderPhoneNumber,
            placeId: "",
            room: this.props.pickup.unit,
            email: this.props.pickup.email,
          },
          service: {
            id: this.props.serviceactiveVehicleType.id,
            options: [
              ...this.props.serviceoptions.map((op) => {
                return { id: op.id, dataId: op.item ? op.item.id ? op.item.id  || null :null : null ,  inputValue: op.inputValue ? op.inputValue : null};
              }),
            ],
          },
          paymentMethod: this.props.paymentMethod,
          paymentSide: this.props.paymentSide,
          paymentProvider: this.props.paymentProvider,
          cardInfo: this.props.cardInfo,
          paymentMethodId: this.props.paymentMethodId,
          referenceId :this.props.referenceID ,
          vehicleType: vehicleTypeIsOk ?  {
            id: this.props.auto
              ? this.props.auto_activeVehicleType &&
                this.props.auto_activeVehicleType.id
              : this.props.manual_activeVehicleType &&
                this.props.manual_activeVehicleType.id,
            options: [
              ...this.props.driveroptions.map((op) => {
                return { id: op.id, dataId: op.item ? op.item.id ? op.item.id  || "" :"" : "" };
              }),
            ],
          } : null ,
          isForceAssignActive: vehicleTypeIsOk ?  this.props.forceAssaigning : false ,
          driverAssignmentModel: vehicleTypeIsOk ? this.props.auto ? "automatic" : "manual" : "automatic" ,
          driverId: vehicleTypeIsOk ? (this.props.auto ? null : (isEmpty(this.props.manual_id) ? null : this.props.manual_id )) : null,

          userId: get_id(),
        },
        this.props.customer.number,
        this.props.auto
          ? null
          : {
              id: this.props.manual_id,
              name:
                this.props.manual_name + " " + this.props.manual_family,
            },
            this.props.ListOrderTypes
      )
    );
  }

  SendDeliveryOrder=()=>{
    let MustAssaignVoipId = this.props.SelectedInCommingCallAction.type && this.props.SelectedInCommingCallAction.type == "NewOrder" ;


   let vehicleTypeIsOk = this.props.auto
    ? this.props.auto_activeVehicleType &&
      this.props.auto_activeVehicleType.id
    : this.props.manual_activeVehicleType &&
      this.props.manual_activeVehicleType.id ;


    this.props.dispatch(
      OrderDeliveryAction(
        this.props.dispatch,
        {
          uid : this.props.uid ,
          voipRecordId : MustAssaignVoipId ? this.props.SelectedInCommingCallAction.Data.id : null ,
          codAmount: Number(this.props.codAmount),
          isScheduled: DetectIsSchedule(this.props.firstDropOff) ,
          customerId: this.props.customer.person
            ? this.props.customer.id
            : this.props.customer.BusinessId,
          service: {
            id: this.props.serviceactiveVehicleType.id,
            options: [
              ...this.props.serviceoptions.map((op) => {
                return { id: op.id, dataId: op.item ? op.item.id ? op.item.id  || null :null : null ,  inputValue: op.inputValue ? op.inputValue : null};
              }),
            ],
          },

          dropoff:{
            address: this.props.firstDropOff.address,
            coordinates: [
              this.props.firstDropOff.adresss_loc.lng,
              this.props.firstDropOff.adresss_loc.lat,
            ],
            scheduleDateAfter: momentJalaali(
              this.props.firstDropOff.scheduleDateAfter
            ).isValid()
              ? momentJalaali(
                  Number(
                    this.props.firstDropOff.scheduleDateAfter + "000"
                  )
                ).unix()
              : 0,
            scheduleDateBefore: momentJalaali(
              this.props.firstDropOff.scheduleDateBefore
            ).isValid()
              ? momentJalaali(
                  Number(
                    this.props.firstDropOff.scheduleDateBefore + "000"
                  )
                ).unix()
              : 0,
            buildingBlock: this.props.firstDropOff.block,
            floor: this.props.firstDropOff.floor,
            fullName: this.props.firstDropOff.recieverFullName,
            phone: this.props.firstDropOff.recieverPhoneNumber || "",
            placeId: "",
            room: this.props.firstDropOff.unit,
            customerDescription:
              this.props.firstDropOff.noteForDriver,
              email: this.props.firstDropOff.email,
          },
          paymentMethod: this.props.paymentMethod,
          paymentSide: this.props.paymentSide,
          paymentProvider: this.props.paymentProvider,
          cardInfo: this.props.cardInfo,
          paymentMethodId: this.props.paymentMethodId,
          referenceId :this.props.referenceID ,
          vehicleType: vehicleTypeIsOk ? {
            id: this.props.auto
              ? this.props.auto_activeVehicleType &&
                this.props.auto_activeVehicleType.id
              : this.props.manual_activeVehicleType &&
                this.props.manual_activeVehicleType.id,
            options: [
              ...this.props.driveroptions.map((op) => {
                return { id: op.id, dataId: op.item ? op.item.id ? op.item.id  || "" :"" : "" };
              }),
            ],
          } : null,
          isForceAssignActive: vehicleTypeIsOk ?  this.props.forceAssaigning :false ,
          driverAssignmentModel: vehicleTypeIsOk ?  this.props.auto ? "automatic" : "manual" : "automatic" ,
          driverId: vehicleTypeIsOk ? (this.props.auto ? null : (isEmpty(this.props.manual_id) ? null : this.props.manual_id )) : null,
          userId: get_id(),
        },
        this.props.customer.number,
        this.props.auto
          ? null
          : {
              id: this.props.manual_id,
              name:
                this.props.manual_name + " " + this.props.manual_family,
            },
            this.props.ListOrderTypes
      )
    );
  }

  SendPickupDeliveryOrder=()=>{
    let MustAssaignVoipId = this.props.SelectedInCommingCallAction.type && this.props.SelectedInCommingCallAction.type == "NewOrder" ;

    
    this.props.dispatch(
      OrderPickupDeliveryAction(
        this.props.dispatch,
        {
          referenceId :this.props.referenceID ,
          uid : this.props.uid ,
          voipRecordId : MustAssaignVoipId ? this.props.SelectedInCommingCallAction.Data.id : null ,
          codAmount: Number(this.props.codAmount),
          isScheduled: DetectIsSchedule(this.props.pickup)  ,
          customerId: this.props.customer.person
            ? this.props.customer.id
            : this.props.customer.BusinessId,
          service: {
            id: this.props.serviceactiveVehicleType.id,
            options: [
              ...this.props.serviceoptions.map((op) => {
                return { id: op.id, dataId: op.item ? op.item.id ? op.item.id  || null :null : null ,  inputValue: op.inputValue ? op.inputValue : null};
              }),
            ],
          },

          pickup:{
            pickup: { 
            address: this.props.pickup.address,
            coordinates: [
              this.props.pickup.adresss_loc.lng,
              this.props.pickup.adresss_loc.lat,
            ],
            scheduleDateAfter: momentJalaali(
              this.props.pickup.scheduleDateAfter
            ).isValid()
              ? momentJalaali(
                  Number(this.props.pickup.scheduleDateAfter + "000")
                ).unix()
              : 0,
            scheduleDateBefore: momentJalaali(
              this.props.pickup.scheduleDateBefore
            ).isValid()
              ? momentJalaali(
                  Number(this.props.pickup.scheduleDateBefore + "000")
                ).unix()
              : 0,
            buildingBlock: this.props.pickup.block,
            customerDescription: this.props.pickup.noteForDriver,
            floor: this.props.pickup.floor,
            fullName: this.props.pickup.senderFullName,
            phone: this.props.pickup.senderPhoneNumber,
            placeId: "",
            room: this.props.pickup.unit,
            email: this.props.pickup.email,
          },
          vehicleType: this.props.pickup.driver.chooseDriver ?  {
            id: this.props.pickup.driver.auto
              ? this.props.pickup.driver.auto_driver.auto_activeVehicleType &&
                this.props.pickup.driver.auto_driver.auto_activeVehicleType.id
              : this.props.pickup.driver.manual_driver.manual_activeVehicleType &&
                this.props.pickup.driver.manual_driver.manual_activeVehicleType.id,
            options: [
              ...this.props.pickup.driver.options.map((op) => {
                return { id: op.id, dataId: op.item ? op.item.id ? op.item.id  || "" :"" : "" };
              }),
            ],
          } : {
            id:((this.props.auto_suggestListData && this.props.auto_suggestListData.length > 0) ? this.props.auto_suggestListData[0].id : null),
            options:[]
          },
          
          isForceAssignActive: this.props.pickup.driver.chooseDriver ?  this.props.pickup.driver.forceAssaigning : false ,
          driverAssignmentModel: this.props.pickup.driver.chooseDriver ? this.props.pickup.driver.auto ? "automatic" : "manual" :  this.props.serviceactiveVehicleType.setting.driverAssignmentModel ,
          driverId:  this.props.pickup.driver.chooseDriver ? this.props.pickup.driver.auto ? null : this.props.pickup.driver.manual_driver.manual_id : null,
        
        },

        delivery:{ 
          dropoff:{
            address: this.props.firstDropOff.address,
            coordinates: [
              this.props.firstDropOff.adresss_loc.lng,
              this.props.firstDropOff.adresss_loc.lat,
            ],
            scheduleDateAfter: momentJalaali(
              this.props.firstDropOff.scheduleDateAfter
            ).isValid()
              ? momentJalaali(
                  Number(
                    this.props.firstDropOff.scheduleDateAfter + "000"
                  )
                ).unix()
              : 0,
            scheduleDateBefore: momentJalaali(
              this.props.firstDropOff.scheduleDateBefore
            ).isValid()
              ? momentJalaali(
                  Number(
                    this.props.firstDropOff.scheduleDateBefore + "000"
                  )
                ).unix()
              : 0,
            buildingBlock: this.props.firstDropOff.block,
            floor: this.props.firstDropOff.floor,
            fullName: this.props.firstDropOff.recieverFullName,
            phone: this.props.firstDropOff.recieverPhoneNumber || "",
            placeId: "",
            room: this.props.firstDropOff.unit,
            customerDescription:
              this.props.firstDropOff.noteForDriver,
              email: this.props.firstDropOff.email,
          },
          vehicleType: this.props.firstDropOff.driver.chooseDriver ? {
            id: this.props.firstDropOff.driver.auto
              ? this.props.firstDropOff.driver.auto_driver.auto_activeVehicleType &&
                this.props.firstDropOff.driver.auto_driver.auto_activeVehicleType.id
              : this.props.firstDropOff.driver.manual_driver.manual_activeVehicleType &&
                this.props.firstDropOff.driver.manual_driver.manual_activeVehicleType.id,
            options: [
              ...this.props.firstDropOff.driver.options.map((op) => {
                return { id: op.id, dataId: op.item ? op.item.id ? op.item.id  || "" :"" : "" };
              }),
            ],
          } : {
            id:((this.props.auto_suggestListData && this.props.auto_suggestListData.length > 0) ? this.props.auto_suggestListData[0].id : null),
            options:[]
          },
          isForceAssignActive: this.props.firstDropOff.driver.chooseDriver ?  this.props.firstDropOff.driver.forceAssaigning : false ,
          driverAssignmentModel: this.props.firstDropOff.driver.chooseDriver ? this.props.firstDropOff.driver.auto ? "automatic" : "manual" : this.props.serviceactiveVehicleType.setting.driverAssignmentModel ,
          driverId: this.props.firstDropOff.driver.chooseDriver ?  this.props.firstDropOff.driver.auto ? null : this.props.firstDropOff.driver.manual_driver.manual_id : null,
        },
          paymentMethod: this.props.paymentMethod,
          paymentSide: this.props.paymentSide,
          paymentProvider: this.props.paymentProvider,
          cardInfo: this.props.cardInfo,
          paymentMethodId: this.props.paymentMethodId,
          userId: get_id(),
        },
        this.props.customer.number,
        null,
            this.props.ListOrderTypes
      )
    );
  }


  SendOrder = () => {
    
    if (this.props.sendOrder.canSaveOrder && !this.props.sendOrder.serviceError && !this.props.sendOrder.VehicleError  && !this.props.sendOrder.loading && this.WalletCheck() !== "red") {
      
      if(this.props.orderTypeActive== "Ondemand"){
        this.SendOndemandOrder()
      }else if(this.props.orderTypeActive== "Pickup"){
        
        this.SendPickupOrder()
      }else if(this.props.orderTypeActive== "Delivery"){
        this.SendDeliveryOrder()
      }else if(this.props.orderTypeActive== "PickupDelivery"){
        this.SendPickupDeliveryOrder()
      }

  
    }
  };


  WalletCheck = (itm) => {
    if(!this.props.paymentSide || !this.props.paymentMethod){
      return "red"
    }else{
      if (this.props.paymentSide + this.props.paymentMethod == "SenderWallet") {
        if (this.props.price.price && this.props.price.done) {
          return this.WalletBalanceBussinessCheck(this.props)
        } else {
          return "grey";
        }
      } else {
        return "grey";
      }
    }
   
  };


  WalletBalanceBussinessCheck = (props) => {
      if (Number(
        this.props.price.price.price
      ) > Number(this.props.balance)) {
        if (props.price.price && (props.customer.balance - props.price.price.price) >= Number(props.customer.minimumBalance)) {
          return "green";
        } else {
          return "red";
        }
      }else{
        return "green";
      }


  };



  render() {
    return (
      <nRFooter>
        <costConrainer>
          <cost>
            {" "}
            {this.props.price.loading ? (
              <Trans i18nKey="poperLoading"> </Trans>
            ) : this.props.price.done ? (
              Number(this.props.price.price.price).toLocaleString(get_lang(), {
                style: "currency",
                currency: get_currencyCode(),
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              })
            ) : (
              "0"
            )}
          </cost>
          <price>
            {" "}
            <Trans i18nKey="Price"> </Trans>{" "}
          </price>
        </costConrainer>

        <ChangeMethod />
        <sendOrder
          className={`${
            (!this.props.sendOrder.canSaveOrder || this.props.sendOrder.serviceError || this.props.sendOrder.VehicleError  || this.props.sendOrder.loading || this.WalletCheck() == "red") && "grey"
          }`}
          onClick={this.SendOrder}
        >
          {this.props.sendOrder.loading ? (
            <Trans i18nKey="poperLoading"> </Trans>
          ) : (
            <Trans i18nKey={"SendOrder"}> </Trans>
          )}
        </sendOrder>
      </nRFooter>
    );
  }
}

const mapStateToProps = (state) => ({
  price: state.NewOrder.newOrder.price,
  uid: state.NewOrder.uid,
  sendOrder: state.NewOrder.newOrder.sendOrder,
  codAmount:state.NewOrder.newOrder.codAmount ,
  customer: state.NewOrder.newOrder.customer,
  paymentMethod: state.NewOrder.newOrder.paymentMethod.paymentMethod,
  paymentSide: state.NewOrder.newOrder.paymentMethod.paymentSide,
  paymentProvider: state.NewOrder.newOrder.paymentMethod.paymentProvider,
  cardInfo: state.NewOrder.newOrder.paymentMethod.cardInfo,
  paymentMethodId: state.NewOrder.newOrder.paymentMethod.paymentMethodId ,
  serviceactiveVehicleType: state.NewOrder.newOrder.service.activeVehicleType,
  serviceoptions: state.NewOrder.newOrder.service.options,
  auto: state.NewOrder.newOrder.driver.auto,
  driveroptions: state.NewOrder.newOrder.driver.options,
  auto_activeVehicleType:
    state.NewOrder.newOrder.driver.auto_driver.auto_activeVehicleType,
  manual_name: state.NewOrder.newOrder.driver.manual_driver.manual_name,
  manual_family: state.NewOrder.newOrder.driver.manual_driver.manual_family,
  manual_id: state.NewOrder.newOrder.driver.manual_driver.manual_id,
  manual_activeVehicleType:
    state.NewOrder.newOrder.driver.manual_driver.manual_activeVehicleType,
  pickup: state.NewOrder.newOrder.pickup,
  pickupadresss_loc: state.NewOrder.newOrder.pickup.adresss_loc,
  firstDropOff: state.NewOrder.newOrder.dropOffs.firstDropOff,
  firstDropOffadresss_loc:
    state.NewOrder.newOrder.dropOffs.firstDropOff.adresss_loc,
  otherdropOffs: state.NewOrder.newOrder.dropOffs.otherDropOffs,
  balance:state.NewOrder.newOrder.customer.balance ,
  forceAssaigning: state.NewOrder.newOrder.driver.forceAssaigning,
  orderTypeActive: state.NewOrder.newOrder.orderTypeActive,
  ListOrderTypes:state.OrderList.ActiveOrderType ,
  auto_suggestListData:
  state.NewOrder.newOrder.driver.auto_driver.auto_suggestListData,
  SelectedInCommingCallAction: state.Voip.SelectedInCommingCallAction,
  referenceID: state.NewOrder.newOrder.referenceID,

  type: state.NewOrder.newOrder.customer.type,
  canWalletBeNegative: state.NewOrder.newOrder.customer.canWalletBeNegative || false,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));



function DetectIsSchedule(pickup) {
  if ( (Number(pickup.scheduleDateBefore) > 0 ||  pickup.scheduleDateBefore != null ) || ( Number(pickup.scheduleDateAfter || pickup.scheduleDateAfter != null )) > 0) {
    return true
  } else {
    return false

  }

}
