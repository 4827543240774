import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import useForceUpdate from 'use-force-update';
import styles from './style.module.css';


export const Zone = ({iselectedZone  , ChangeZone}) => {
    const zone = useSelector((state) => state.SharedData.zone)
    const { t, i18n } = useTranslation();
    const forceUpdate = useForceUpdate();


    function serviceIsSelected(Zone) {
        return iselectedZone.findIndex((zon) => {return zon == Zone.id}) > -1
    }

    function selectZone(Zone) {
        let newZone = iselectedZone

        if (serviceIsSelected(Zone)) {
            ChangeZone(newZone.filter((zon) => { return zon != Zone.id}))
            forceUpdate()
        } else {
            newZone.push(Zone.id)
            ChangeZone(newZone)
            forceUpdate()
        }
    }

    return <div className={styles.container} >
    <p className={styles.filterTilte}>Zone</p>
    <div className={styles.itemContainer}>
        {zone.map((zon , i)=>{
            return <span key={i} onClick={()=>{selectZone(zon)}} className={styles.item+" "+(serviceIsSelected(zon) && styles.activeItem)}> {zon.name} </span>
        })}
    </div>
    
</div>

};




