import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import { getBaseTrackByUrl } from "../../../../../helper/module";
import { get_lang } from "../../../../../helper/UserData/userdate";

class App extends React.Component {
  serviceOptionsNameExtracter = () => {
    let names = [];
    if (this.props.service) {
      this.props.service.options.map((option) => { 
        if (option.type === "boolean") {
         names.push(option.title) ;
        } else {
         names.push(option.dataTitle) ;
        }
      });  
      return names.join(" , ")
    } else {
      return "";
    }
  };

  render() {
    const { t } = this.props;
    return (
      <serviceVr>
        <img
          src={
            this.props.service &&
             this.props.service.icon
          }
        />
        <name>
          {this.props.service && this.props.service.title}

          <optionss>{this.serviceOptionsNameExtracter()}</optionss>
        </name>
      </serviceVr>
    );
  }
}

const mapStateToProps = (state) => ({
  service: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.service
      ? state.ViewTravel.pureData.service
      : null
    : null,
  lang: state.LocalData.lang,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
