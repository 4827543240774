import React from "react";
import clsx from "clsx";
import styles from './style.module.css';
import { SwitchProps } from "./Switch.types";
import defaultCustomer from "../../../assets/staticIcons/defaultCustomer.svg";
import defaultDriver from "../../../assets/staticIcons/defaultDriver.svg";


const Switch: React.FC<SwitchProps> = ({ style, className, handleSelection,items,index ,type}: SwitchProps) => {
    return (
        <div className={clsx(styles.container ,type && styles[type] )}>
            {items.map((xx , i)=>{
                return <span className={clsx((i == index) && styles.selectedTab) } onClick={()=>{handleSelection(i)}}>
                    {xx}
                </span>
            })}
        </div>
    );
};

export default Switch;

