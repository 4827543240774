import Dropp from "../../../assets/mannaev_logo.png";
import FavIcon from "../../../assets/PROJECTS/mannaev.ico";

export default function Mannaev() {
    
    return  global.config = {
    "ProjectName": "MannaEV Rider | Fast Delivery, Anytime, Anywhere",
    "LogoType": Dropp,
    "BaseApi": "https://rest.mannaev.com",
    "BaseSocket": "https://socket.mannaev.com",
    "BaseTrack": "https://admin.mannaev.com",
    "MapCenter": {
        "lat": 35.89889744137674,
        "lng":14.51367707540481
    },
    "Lang": "en",
    "Direction": "ltr",
    "AllLanguages": [{
        "lang": "en",
        "direction": "ltr",
        "i18Lang": "English"
    }],
    "FavIcon": FavIcon,
    "Country": "MT",
    "thirdPartyLoginSupport": false  ,
    mapType :"osm", /* osm | google */ 
    googleApiKey : ""
}}
