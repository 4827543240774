import logoType from "../../../assets/Flech.png";
import FavIcon from "../../../assets/PROJECTS/Flech_fav_icon.png";

export default function Flech() {

    return global.config = {
    "ProjectName": "Flech",
    "LogoType": logoType,
    "BaseApi": "https://rest.flech.io",
    "BaseSocket": "https://socket.flech.io",
    "BaseTrack": "https://panel.flech.io",
    "MapCenter": {
        "lat": "5.36062046950588",
        "lng": "-4.015062410158686"
    },
    "Lang": "fr",
    "Direction": "ltr",
    "AllLanguages": [{
        "lang": "fr",
        "direction": "ltr",
        "i18Lang": "French"
    },{
        "lang": "en",
        "direction": "ltr",
        "i18Lang": "English"
    }],
    "FavIcon": FavIcon,
    "Country": "CI",
    "thirdPartyLoginSupport": true  ,
    mapType :"osm", /* osm | google */ 
    googleApiKey : ""
} }