import Degrees3Logo from "../../../assets/Degrees3Logo.png";
import FavIcon from "../../../assets/PROJECTS/Degrees3.png";

export default function Degrees3 () {
    
    return global.config = {
    "ProjectName": "Degrees3",
    "LogoType": Degrees3Logo,
    "BaseApi": "https://rest.odos.tech",
    "BaseSocket": "https://socket.odos.tech",
    "BaseTrack": "https://track.odos.tech",
    "MapCenter": {
        "lat": "-19.004371991153743",
        "lng": "29.87113559472689"
    },
    "Lang": "en",
    "Direction": "ltr",
    "AllLanguages": [{
        "lang": "en",
        "direction": "ltr",
        "i18Lang": "English"
    }],
    "FavIcon": FavIcon,
    "Country": "ZW",
    "thirdPartyLoginSupport": true  ,
    mapType :"osm", /* osm | google */ 
    googleApiKey : ""
} }