export const RomanianLabel = {
    "Enter_the_following_information_to_enter": "Introduceți următoarele informații pentru a intra",
    "login": "Autentificare",
    "Username": "Nume de utilizator",
    "password": "Parola",
    "Orders": "Comenzi",
    "Online": "Pe net",
    "Offline": "Deconectat",
    "Info": "Info.",
    "email": "E-mail",
    "vehicletypes": "Tipul de serviciu",
    "loginstatus": "Statutul de conectare",
    "lastonlinetime": "Ultima dată online",
    "fetchingdata": "Primirea informațiilor ...",
    "poperLoading": "Te rugăm sa aștepți",
    "Noinformationfound": "Nu au fost găsite informații",
    "Drivers": "Curieri",
    "otherdropoffs": "Alte livrări",
    "paymentmethod": "Metode de plată",
    "Receiver": "De către Destinatar",
    "Sender": "De către Expeditor",
    "Balance": "Balanța",
    "Cash": "Numerar",
    "Wallet": "Portofel",
    "Close": "Închide",
    "noVehicleType": "Nu există tip de serviciu de afișat",
    "Fname": "Prenume",
    "Lname": "Numele de familie",
    "Pnumber": "Numar de telefon",
    "driver": "Curier",
    "Pleasetryanotherkeyword": "încearca alt cuvant cheie",
    "DropOff": "Drop off",
    "Noaddressavailable": "Nu există nicio adresă disponibilă",
    "Managefavoriteaddresses": "Gestionează adresele",
    "address": "Adrese",
    "Saved": "Salvat",
    "block": "Bloc",
    "Floor": "Etaj",
    "Unit": "Apartament",
    "Recieverfullname": "Nume destinatar",
    "Recieverphonenumber": "Telefon destinatar",
    "notesfordriver": "Notă pentru curier",
    "Price": "Preț",
    "EditOrder": "Editează comanda",
    "Firstname": "Prenume",
    "Lastname": "Numele de familie",
    "Phonenumber": "Numar de telefon",
    "senderfullname": "Nume expeditor",
    "senderphonenumber": "Telefon expeditor",
    "Search": "Cauta",
    "Service": "Serviciu",
    "Passenger": "Client",
    "Pickup": "Pick up",
    "Fee": "Taxă",
    "Fornow": "deocamdata",
    now:"Now",
    "Scheduled": "Programată",
    "before": "Înainte de :",
    "Settime": "Setează ora",
    "after": "După :",
    "confirm": "Confirmare",
    "Cancel": "Anulare",
    "Addresses": "Adrese",
    "Savedaddresses": "Adresele salvate",
    "new": "Nou",
    "Editaddress": "Editează adresa",
    "Saveaddress": "Salvează adresa",
    "Language": "Limba",
    "en": "Engleză",
    "fa": "persană",
    "English": "Engleză",
    "Persian": "persană",
    "Logout": "Deconectare",
    "logotTitle": "Dorești să te deconectezi?",
    "logoutDescription": "O să fii deconectat, ar trebui să te conectezi din nou ",
    "confirmLogout": "Da, deconectare",
    "skipLogout": "Anulare",
    "Ascending": "Crescător",
    "Descending": "Descrescător",
    "sort[createdAt]": "Sortează după dată",
    "sort[pickupScheduleDate]": "Sortează după ora de preluare",
    "sort[statusUpdatedAt]": "Sortați după actualizare la",
    "SelectDateRange": "Interval dată",
    "StartDate": "Dată începere",
    "EndDate": "Dată finaliare",
    "Today": "Astăzi",
    "Yesterday": "Ieri",
    "ThisWeek": "Săptămâna aceasta",
    "LastWeek": "Săptămâna trecută",
    "Last7Days": "Ultimele 7 zile",
    "ThisMonth": "Luna aceasta",
    "LastMonth": "Luna trecută",
    Last3Month:"Ultima 3 luni",
    "monitoring": "Monitorizare",
    "Haveorder": "Are comandă",
    "Estimated": "Estimat",
    ReceiverCash: "Plătiți prin receptor",
    "SenderCash": "Numerar",
    "SenderWallet": "Portofel",
    "Auto": "Auto",
    "Manual": "Manual",
    "SendOrderr": "Trimite comanda",
    "Neworder": "Comandă nouă",
    "Person": "Individual",
    "Business": "Companie",
    "Businessname": "Nume companie",
    "phonenumber": "Numar de telefon",
    "Adddropoff": "Adaugă drop off",
    "Searchbynameorphonenumber": "Caută dupa nume sau număr de telefon",
    "Fullname": "Nume complett",
    "type": "tip",
    "only": "Numai xxx",
    "ChooseDriver": "Selectează curier",
    "AllKey": "Toate",
    "MeKey": "Pe mine",
    "Vehicletype": "Tip de vehicul",
    "status": "Stat",
    "statusInProgress": "În curs",
    "statusCanceled": "Anulată",
    "statusPending": "în asteptare",
    "statusUnassigned": "Fără curier",
    "statusDone": "Finalizată",
    "dispatcher": "Dispecer",
    "customer": "Client",
    "regexcode": "Cod",
    "regexcustomerPhone": "Clientul a anulat comanda",
    "regexcustomerName": "Numele clientului",
    "regexdriverPhone": "Telefon curier",
    "regexdriverName": "Nume curier",
    "regexdriverUsername": "Nume utilizator curier",
    "pickupDescription": "Descriere pick up",
    "filter": "Filtru",
    "clear": "Clar",
    "orderInfoError": "Eroare la primirea informațiilor",
    "Details": "Detalii",
    "History": "Istoric",
    "Phone": "Telefon",
    "km": "km",
    "Espath": "Es. cale",
    "Receipt": "Confirmare",
    "Copylink": "Copiază link-ul ",
    "repeatOrder": "Repetă comanda",
    "Edit": "Editează",
    "EditPrice": "Editează prețul",
    "Endtrip": "Finalizeaza comanda",
    "Canceltravel": "Anulează comanda",
    "CancellText": "După anulare, curierului i se va anula comanda",
    "confirmCancel": "Da",
    "Endtravel": "Complet",
    "EndlText": "La finalizarea cu succes a comenzii, contravaloare va fi reținută din contul clientului",
    "confirmEnd": "Da",
    "End": "Anulează comanda",
    "Deliveredat": "Livrat la",
    "pod": "Dovadă de livrare",
    "Notes": "Note",
    "Nonotes": "Nu există note",
    "Photo": "Poză",
    "Signature": "Semnătură",
    "Nosignature": "Nici o semnătură",
    "Nophoto": "Nici o poză",
    "ServiceOptions": "Opțiuni serviciu",
    "Events": "Status",
    "status2": "status",
    "Creator": "Creator",
    "Creationdate": "Data creare",
    "Orderid": "Număr comandă",
    "Pickup2": "Preluare",
    "Dropoff2": "Livrare",
    "PleaseSelect": "Selectează",
    "CreateEvent": "Crează eveniment",
    "Description": "Descriere",
    "detailsAboutEvent": "Detalii deste eveniment",
    "CustomerVisibility": "Vizibilitate client",
    "LocationOnMap": "Locație pe hartă",
    "Pending": "In așteptare",
    "Unassigned": "Făra curier",
    "Assigned": "Atribuită",
    "PickedUp": "Preluată",
    "Started": "Începută",
    "Done": "Finalizată",
    "CustomerCanceled": "Anulată de client",
    "DriverCanceled": "Anulată de curier",
    "SupportCanceled": "Anulată de asistență",
    "Arrived": "Finalizată",
    "Createdsuccessfully": "Creat cu succes",
    "Estime": "Es. timp",
    "Promocode": "Cod promoțional",
    "Updatedsuccessfully": "Actualizat cu succes",
    "statusAssigned": "Atribuită",
    "statusStarted": "Începută",
    "statusPickedUp": "Opgepikt",
    "statusDriverCanceled": "Curierul a anulat comanda",
    "statusArrived": "Finalizată",
    "statusSupportCanceled": "Asistența a anulat comanda",
    "Title": "Titlu",
    "ContactFullname": "Nume complet contact",
    "Contactphonenumber": "Numar de telefon contact",
    "Commission": "Comision",
    "surcharge": "Suprataxă",
    "haslogined": "Este logat",
    "Notloggedin": "Nu este logat",
    "Schedule": "Programează",
    "Date": "Data",
    "Time": "Timp",
    "noDateToShow": "Nu este data",
    "noTimeToShow": "Nu este timp",
    "Apply": "Aplica",
    "lowBalance": "Balanța redusă",
    "balanceError": "Soldul balnței trebuie sa fie cel putin cat valoarea comenzii",
    "Methods": "Modalitați",
    "Addressbook": "Carte de adrese",
    "AddressbookSearchbar": "Căutați după adresă, titlu și ID",
    "AddressbookDropOf": "Agendă de adrese (drop off)",
    "AddressbookPickup": "Agendă (pick up)",
    "hide": "Ascunde",
    "Nosavedaddress": "Nu există adresă salvată",
    "Romanian": "Română",
    "business": "Afaceri",
    "username": "Nume de utilizator",
    "rememberme": "amintește-ți de mine",
    "SendOrder": "Trimite comanda",
    "code": "Cod",
    "noorder": "nordic",
    "pleaseSellectParsel": "Selectați tipul de paresdel",
    "Order": "Ordin",
    "Code": "Cod",
    "Editorder": "Editați comanda",
    "all": "Toate",
    "Address": "Abordare",
    "Block/Building": "Bloc / clădire",
    "Pickedupat": "Ridicată la ",
    "for": "Pentru",
    "fr": "limba franceza",
    "AllVehicletypes": "Toate tipurile de servicii",
    "AllServices": "Toate serviciile",
    "French": "limba franceza",
    "enterDataCorrectly": "Introduceți informațiile corecte.",
    "controlThatbeforeLoweEqualAfter": "Timpul \"înainte:\" nu poate fi mai mic decât \"după:",
    "ForceAssign": "Atribuie forța",
    "Allvehicletypes": "Toate tipurile de vehicule",
    "waitforconfirm": "xxx (așteptați pentru a confirma)",
    "waitforconfirm2": "Așteptați confirmarea",
    "Dispatching": "Dispecer",
    "NoDriver": "Fără curier",
    "OtherDetails": "Alte detalii",
    "StatusandDriver": "Status și curier",
    "EditStatusandDriver": "Editați starea și curierul",
    "Current": "Actual",
    "EDAS_Pending": "In așteptare",
    "EDAS_Assigned": "Atribuit",
    "EDAS_PickedUp": "Ridicată",
    "EDAS_Unassigned": "Fără curier",
    "EDAS_Started": "Începută",
    "EDAS_Done": "Încheiata",
    "EDAS_CustomerCanceled": "Anulată de client",
    "EDAS_DriverCanceled": "Anulată de curier",
    "EDAS_SupportCanceled": "Anulată de dispecer",
    "EDAS_Arrived": "Livrată",
    "Pleasechooseadrivertochangestatus": "Alege un curier pentru a schimba statusul",
    "icWarningEdit": "Schimbările pot avea impact asupra prețului",
    "Vehicletypewillbechanged": "Tipul vehiculului va fi modificat",
    "Yourcurrentvehicletypeanditwillbechangedandeffectsonprice": "Tipul de vehicul va fi modificat si va avea impact asupra prețului ",
    "Rejected": "Refuzat",
    "Rejected2": "(Refuzat)",
    "DriverVehicleTypeError": "Curierul nu are acest tip de vehicul",
    "CodeNameFamilyPhonenumber": "Cod, prenume,nume, număr de telefon",
    "youcanselectdriverintheedit": "Poți selecta modifica curierul in Status si curier",
    "Earning": "Caștiguri",
    "savePrice": "Salvează prețul",
    "saveEarning": "Salveaza caștigul",
    "Collecting": "Colectează",
    "Transit": "În tranzit",
    "Delivering": "Livrează",
    "EDAS_Collecting": "Colectează",
    "EDAS_Transit": "Tranzit",
    "EDAS_Delivering": "Livrează",
    "statusCollecting": "Colectează",
    "statusTransit": "În tranzit",
    "statusDelivering": "Livrează",
    "event_Created": "Creată",
    "event_Unassigned": "Fără curier",
    "event_Assigned": "Atribuită",
    "event_Started": "Începută",
    "event_Arrived": "Sosit",
    "event_PickedUp": "Preluată",
    "event_OrderDone": "Finalizată",
    "event_DropoffDone": "Livrată",
    "event_CustomerFeedback": "Calificativ client",
    "event_AddPod": "Adaugă POD",
    "event_CustomerCanceled": "Anulată de client",
    "event_DriverCanceled": "Anulată de curier",
    "event_SupportCanceled": "Anulată de asistentă",
    "event_Updated": "Modificată",
    "event_DriverAssignment": "Alocare curier",
    "event_DriverReject": "Refuzată de curier",
    "event_Collecting": "Colectează",
    "event_InTransit": "În tranzit",
    "event_Delivering": "Livrează",
    "event": "Status",
    "tab_Delivery": "Livrare",
    "tab_Ondemand": "La cerere",
    "tab_Pickup": "Preluare",
    "tab_PickupDelivery": "P&L",
    "CurrentStatus": "Status",
    "Changestatus": "Schimbă status",
    "Newstatus": "Status nou",
    "Savechanges": "Salvează",
    "Other": "Alte",
    "Shipmentlabel": "Eticheta de expediere",


    pleaseSellectService: "Selecteaza tipul de serviciu",
    Pricedetails: "Detalii despre preț",
    statusCustomerCanceled: "Anulată de client",
    Total: "total",
    "EditPickupDropOff": "Edit Addresses",
    noMoreData: "Nu mai există informații",
    Dutch: "Olandeză",
    Ondemand: "On Demand",
    PD: "P & D",
    PD2: "P&D",
    PickupDropoff: "Pickup , Dropoff",
    PickupFlag: "P",
    DropOffFlag: "D",
    PickupandDelivery: "Pickup and Delivery",
    PickupDelivery: "Pickup and Delivery",
    Delivery: "Delivery" ,
    serviceCustomerError1 :"xxx nu este disponibil pentru acest client." ,
    serviceCustomerError2 :"El / ea poate alege doar serviciul xxx",
    serviceCustomerError3 :" El / ea poate alege doar xxx",
    OrderCode:"Codul de comanda" ,

    CopyOrderlink:"Copiază link comandă",
    CopyPickuplink:"Copiază link preluare" ,
    CopyDeliverylink:"Copiază link livrare" ,
    Bulkimport:"Importul în vrac" ,
    Downloadsamplecsv:"Descărcați eșantionul CSV.",
    processing:"Prelucrare" ,
    done :"Terminat" ,
    failed:"A eșuat",
    Draft:"Proiect" ,
    Uploading:"Încărcarea" ,
    Draganddropyourfile:"Trageți și plasați fișierul",
    Onlycsvformatissupported:"Numai formatul .csv este acceptat" ,
    ORyoucan:"Sau puteți",
    Openfile:"Deschide fișierul" ,
    Noimportsyet:"Încă nu există importuri" ,
    NoimportsyetDesc :"Puteți vedea lista importurilor dvs. aici și verificați starea acestora",
    nosupportdialogeTitle:"Formatul fișierului nu este acceptat",
    nosupportdialogeText : "Fișierul xxx nu este acceptat.Puteți încărca numai fișierul .csv, inclusiv informații de comandă" ,
    ok:"Bine" ,
    Import  :"import",
    previewTitle :"Previzualizați și alegeți Opțiuni",
    previewDesc:"Acesta este primul element al fișierelor dvs. pentru a verifica informațiile dvs. în structura potrivită" ,
    Upload:"Încărcați", 
    Remove:"Elimina" ,
    ServiceandOrdertype:"Service și tip de comandă" ,
    Fileisproccessing:"Fișierul este procesarea ...",
    "proccessingDesc":"Puteți reîmprospăta pentru a vedea dacă rezultatul este gata",
    serverError:"Eroare de server",
    isRequired:"xxx este necesar" ,
    Result:"Rezultat",
    ResultText:"Puteți vedea problemele aici și puteți descărca aceste rânduri pentru a repara separat",
    ErrorDetails:"Comenzi cu eroare",
    ErrorDetailsText:"Comenzile cu erori sunt listate în fișierul pe care îl puteți descărca mai jos.Conține detalii despre fiecare rând.",
    DownloadErroredRows:"Descărcați rânduri errotate" ,
    importedsuccessfully:"Comenzile xxx sunt importate cu succes" ,
    ordershaserrors:"Comenzile xxx au erori",
    refresh:"Reîmprospăta",
    CashOnDelivery:"Plata la livrare",
    EnterAmount:"Introduceți suma",
    COD:"Plata la livrare",
    Surchargexxx:"Taxă suplimentară xxx",
    AddCreditCard:"Adăugați cardul de credit / debit",
    CustomerWallet:"Portofelul clientului",
    Add:"Adăuga",
    Addedsuccessfully: "Adăugat cu succes",
    NoRecentAddress:"Nu există adresă recentă",
    NoRecentAddressD:"Adresele recente ale clienților vor fi listate aici",
    CustomerIsNotChoosed:"Clientul nu este ales",
    CustomerIsNotChoosedD:"Alegeți clientul pentru a -și arăta adresa recentă",
    SearchAddress:"Adresa de căutare.",
    NoCustomerfound: "Nu a găsit niciun client" ,
    NoCustomerfoundD:"Puteți crea clientul dacă este unul nou",
    NewCustomer : "Client nou",
    Existing:"Existent",
    EmailOtional:"E -mail (opțional)" ,
    phonenumberisnotcorrect:"Numărul de telefon nu este corect",
    Emailisnotcorrect:"E -mailul nu este corect" ,
    Registercustomer:"Înregistrați clientul",
    incoming:"Apel primit",
    outgoing:"apel efectuat",
    recording:"Apel de înregistrare",
    driveroperatorCallStatus:"Driver a sunat operator",
    customeroperatorCallStatus:"Client apelat la operator",
    newCustomeroperatorCallStatus:"Nou client numit operator",
    operatordriverCallStatus:"Operator numit Driver",
    operatorcustomerCallStatus:"Operator numit Client",
    operatornewCustomerCallStatus:"Operatorul a numit client nou",
    dispatcher_Voip:"operator",
    driver_Voip:"Conducător auto",
    customer_Voip:"Client",
    activeorders:"xxx Comenzi active",
    Noactiveorder :"Fără ordine activă " ,
    CreateOrder:"Creați comandă",
    call:"Apel",
    spanish:"Spaniolă",
    es : "Spaniolă",
    networkError:"Fără conexiune internet",
    Noresultfound:"niciun rezultat găsit",
    loadMore:"Incarca mai mult",
    Searchresult:"Rezultatul căutării",
    "PinTop":"Pinul de sus",
    "UnPin":"Een dennen",
    "Itemsonmap":"Elemente de pe hartă",
    "Orders":"Comenzi",
    "Drivers":"Drivers",
    "min":"xxx Min" ,
    Confirming:"Confirmare",
    delay:"Întârziere",
    late:"Târziu",
    OrderDetails:"Comanda Detalii",
    Profile:"Profil",
    Waitingforavailabledrivers:"Așteptați ca șoferii să fie disponibili",
    Queue:"Coadă",
    OfferingToDrivers:"Oferind driverelor xxx",
    WaitforAccept:"Așteptați acceptarea" ,



    ascending: 'Ascendent',
    descending: 'descendentă',
    Pickupnote: 'Notă de preluare',
    EditPrice2: 'Preț',
    EditEarning: 'Câștig',
    todayat: 'Astăzi la',
    tomorrowat: 'Mâine la',
    nextWeekat: 'Săptămâna viitoare la',
    lastDayat: 'Ultima zi la',
    lastWeekat: 'Săptămâna trecută la',
    createdAt: 'Creat la',
    dueDate: 'Data scadentă',
    "AssignedAt":"Alocați în xxx min",
    "StartedAt":"Începeți în xxx min",
    "ArrivedAt":"Sosiți în xxx min",
    "PickedUpAt":"Pickup în XXX Min",
    "DoneAt":"Finalizați în xxx min",
    mindelay: 'xxxm întârziere',
    minlate: 'xxxm târziu',
    Thereisnoorder:"Nu există nicio comandă",
    mapitems:"Hartă",
    Offerings:"Oferte",
    Reject:"Respinge",
    dropoffCustomerDescription:"Notă renunțați",
    customerPhoto:"Fotografie de client" ,
    driverPhoto:"Fotografie de șofer" ,
    NocustomerPhoto:"Fără fotografie cu clientul" ,
    NodriverPhoto:"Fără fotografie de șofer",
    initial:"iniţială",
    notifying:"notificare",
    received:"primit",
    assigned:"atribuit",
    rejected:"respins",
    canceled:"anulat",
    standby:"așteptare",
    terminated:"încheiat",
    urgentTitle:"Schimbați la expediere urgentă?",
    urgentDesc:"Activând urgent, comanda va fi în prima prioritate pentru expediere",
    urgentConfirm:"Da, activați urgent",
    urgentClose:"Nu, Anulați",
    Urgent:"urgent",
    pop:"Dovada preluării",
    photos :"Fotografii",
    Portuguese:"Portugheză",
    Dispatchat:"Expediați la xxx",
    AutoDispatch:"Expediere automată" ,
    Manual:"manual",
    Dispatchtime:"Timp de expediere" ,
    Later:"Mai tarziu",
    DispatchingNow:"Se expediază acum",
    Required:"Necesar" ,

    NoResultfromLocalDatabase:"Niciun rezultat din baza de date locală",
    NoResultfromLocalDatabaseD:"Vă rugăm să îl verificați din nou sau să încercați un alt cuvânt cheie",
    NoResultfromMapService:"Niciun rezultat din serviciul de hartă",
    SearchinMapService:"Căutați în serviciul de hartă" ,
    SearchinLocalDatabase:"Căutați în baza de date locală",
    Creditcardnotadded:"Card de credit nu este adăugat",
    OptimizeDropoffs:"Optimizați abandonul",
    "Greek":"Grec",
    "el":"Grec",
    "ReferenceID":"ID de referinta",
    "EnterId":"Introduceți ID",
    "de":"limba germana",
    "German":"limba germana",
    "AustrianGerman":"German austriac",
    "Options":"Opțiuni",
    "VehicleOptions":"Opțiuni de vehicul",
    "EditCustomer":"Editați clientul",
    "NoDriverfound":"Niciun șofer nu a fost găsit",
    "NoDriverfoundDesc":"Verificați -vă informațiile și încercați altceva",
    "ClearField":"Câmp clar",
    "Map":"Hartă",
    "Local":"local",
    "CompleteRequiredFeileds":"Completați câmpurile necesare",
    "Senderinfo":"Informații ale expeditorului",
    "SchedulePickup(Optional)":"Programarea preluării (opțional)",
    "ScheduleDelivery(Optional)": "Schedule Delivery (Optional)",

    "AddDropoff":"Adăugați dropff",
    "EditPrice":"Editați prețul",
    "Promotion":"Promovare",
    "tax":"Impozit",
    "surcharge":"Suprataxă xxx",
    "Receiverinfo":"Informații despre receptor",
    "tryagain":"Something went wrong, please try again",



    "Selected date and time":"Selected date and time",
    "Reorder stops":"Reorder stops",
    "Bulk Edit Status Summary":"Bulk Edit Status Summary",
    "Successful":"Successful",
    "Failed":"Failed",
    "Order ID":"Order ID",
    "Details":"Details",
    "Result":"Result",
    "Pickup Routed":"Pickup Routed",
    "Delivery Routed":"Delivery Routed",
    "Ref. xxx":"Ref. xxx",
    "Zone":"Zone",
    "Clear":"Clear",
    "Apply":"Apply",
    "No result found":"No result found",
    "Type your keyword":"Type your keyword",
    "Type in the input to start search":"Type in the input to start search",
    "We are sorry ...":"We are sorry ...",
    "The page you are trying to access has restricted access.":"The page you are trying to access has restricted access.",
    "Please refer to your system administrator.":"Please refer to your system administrator.",
    "Failed":"Failed",
    "Done": "Done",
    "Schedule":"Schedule",
    "Proof of delivery":"Proof of delivery",
    "Signature":"Signature",
    "Driver Photo":"Driver Photo",
    "Notes":"Notes",
    "Order Info":"Order Info",
    "Order":"Order",
    "Price":"Price",
    "P_HUB_D Orders":'P_HUB_D Orders',
    "Routes":"Routes",
    "Default pickup":"Default pickup",
    'More':'More',
    'Route name':'Route name',
    'Driver':'Driver',
    'Pickup Orders':'Pickup Orders',
    'Delivery Orders':'Delivery Orders',
    'Ready For Pickup':'Ready For Pickup',
    'PricingAndEarning':"Pricing & Earning",
    "Pricing" : "Pricing",
    "save":"Save",
    "Learn":"Learn",
    "Scheduleoverlap":"The Schedule Pickup time must be before the Schedule Delivery time and have no overlap",
    "PleaseSelectCustomer":"Please select customer",
    "NoRegisteredCustomer":"No registered customer",
    "CreateAtLeastOneCustomerToCreateOrder":"Create at least one customer to create order",

}