import * as React from 'react'
import styles from './style.module.css';
import {isEmpty} from "lodash";
import IconProvider from '../../../design-system/lib/IconProvider';
import Typography from '../../../design-system/lib/Typography';
import { LOCATION } from '../../../assets';




function AddressItem({data , onclick}) {
  console.log("RecentAddressItem ",data)
  return (
    <div className={styles.CustomerListItemContainer} onClick={onclick && onclick}>
        <IconProvider Icon={LOCATION} size='16' />

        <div className={styles.namePhoneContainer}>
            <Typography weight='Body_Tiny_Medium' text={data.address}  style={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}/>
            <Typography weight='Body_Tiny_Regular' text={isEmpty(data.secondaryAddress) ? "----" : data.secondaryAddress} />

        </div>
    </div>
  )
}

export default AddressItem