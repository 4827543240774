import Dropp from "../../../assets/QuickSend_logotype.png";
import FavIcon from "../../../assets/PROJECTS/QuickSend.png";

export default function QuickSend() {
    
    return  global.config = {
    "ProjectName": "QuickSend",
    "LogoType": Dropp,
    "BaseApi": "https://rest.quicksend.ug",
    "BaseSocket": "https://socket.quicksend.ug",
    "BaseTrack": "https://panel.quicksend.ug",
    "MapCenter": {
        "lat": "0.33544504527718905",
        "lng": "32.58207742203401"
    },
    "Lang": "en",
    "Direction": "ltr",
    "AllLanguages": [{
        "lang": "en",
        "direction": "ltr",
        "i18Lang": "English"
    }],
    "FavIcon": FavIcon,
    "Country": "UG",
    "thirdPartyLoginSupport": true  ,
    mapType :"osm", /* osm | google */ 
    googleApiKey : ""
}}