import logotype from "../../../assets/ZoomCurier_logotype.png";
import FavIcon from "../../../assets/PROJECTS/ZoomCurier_fav_icon.png";

export default function Zoomcurier() {
    
    return  global.config = {
    "ProjectName": "Zoom Curier",
    "LogoType": logotype,
    "BaseApi": "https://rest.curier-local.ro",
    "BaseSocket": "https://socket.curier-local.ro",
    "BaseTrack": "https://panel.curier-local.ro",
    "MapCenter": {
        "lat": "46.56731361015399",
        "lng": "26.914722315152293"
    },
    "Lang": "en",
    "Direction": "ltr",
    "AllLanguages": [{
        "lang": "en",
        "direction": "ltr",
        "i18Lang": "English"
    }, {
        "lang": "ro",
        "direction": "ltr",
        "i18Lang": "Romanian"
    }],
    "FavIcon": FavIcon,
    "Country": "RO",
    "thirdPartyLoginSupport": true  ,
    mapType :"osm", /* osm | google */ 
    googleApiKey : ""
} }