import React, { useLayoutEffect, useState } from "react";
import { Marker, OverlayView } from '@react-google-maps/api';
import Pin from "../Pin";
import styles from './style.module.css';
import { useDispatch, useSelector } from "react-redux";
import { GetColorByStatus } from '../../../../utils/get-colors-by-status';


const App = ({ position ,status ,number , numberStyle , bigSize ,color}) => {

  return <OverlayView
    position={position}
    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
  >

      <Pin color={color ? color : "#"+GetColorByStatus(status)} bigSize={bigSize}
          style={{ marginLeft: "-15px", marginTop: "-51px" ,marginBottom:"10px"}} number={number} numberStyle={numberStyle} />

  </OverlayView>

};

export default App



{/* <Marker
position={position}
icon= {{
  anchor: { x: 14, y: 14 },
  url: 'data:image/svg+xml;charset=utf-8,' +
  encodeURIComponent(ReactDOMServer.renderToStaticMarkup(<Pin hasActiveOrder={hasActiveOrder} driver={driver} />))
}}
onClick={() => { HandleDriverselect && HandleDriverselect(driver) }}
></Marker> */}