import React from "react";
import { connect } from "react-redux";
var _ = require("lodash");




class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      location_bounded: false
    };

  }

  componentWillReceiveProps(nextProps) {
    !this.state.location_bounded && this.boundsGenerator(nextProps);

    ((this.props.VRpureData_ID != nextProps.VRpureData_ID) && nextProps.VRpureData_ID ) && this.boundsGenerator(nextProps)

    if(nextProps.driver_location && !this.props.driver_location){
      this.boundsGenerator(nextProps)
    }
  }

  componentDidMount() {
    this.boundsGenerator()
  }



  boundsGenerator = (nextProps) => {

    
    let inData = nextProps ? nextProps : this.props;


    let mapRef = inData.mapRef;
    const bounds = new window.google.maps.LatLngBounds();



    try {
      if (["Delivery"].indexOf(inData.orderType) > -1) {
        if (inData.EROpenERDialoge) {
          if (
            String(inData.ERdropOffsAdresss_loc.lng) !== "" &&
            String(inData.ERdropOffsAdresss_loc.lng) !== ""
          ) {
            var myLatLng = new window.google.maps.LatLng(inData.ERdropOffsAdresss_loc.lat, inData.ERdropOffsAdresss_loc.lng);
            bounds.extend(myLatLng);

            mapRef.fitBounds(bounds);

          } else {
            return null;
          }
        } else if (
          inData.OpenVRDialoge &&
          !inData.EROpenERDialoge &&
          inData.VRpureData_dropoff
        ) {

          var myLatLng = new window.google.maps.LatLng(inData.VRpureData_dropoff.coordinates[1], inData.VRpureData_dropoff.coordinates[0]);
          bounds.extend(myLatLng);

          if (inData.driver_location) {
            bounds.extend(new window.google.maps.LatLng(inData.driver_location[1], inData.driver_location[0]));
          }

          mapRef.fitBounds(bounds);

        } else if (inData.OpenNRDialoge) {
          if (
            String(inData.NRpickupAdresss_loc.lng) !== "" &&
            String(inData.NRdropOffsAdresss_loc.lng) !== ""
          ) {

            bounds.extend(new window.google.maps.LatLng(inData.NRpickupAdresss_loc.lat, inData.NRpickupAdresss_loc.lng));
            bounds.extend(new window.google.maps.LatLng(inData.NRdropOffsAdresss_loc.lat, inData.NRdropOffsAdresss_loc.lng));
            inData.NRotherDropOffs.map((drop) => {
              if (String(drop.adresss_loc.lng) !== "") {
                bounds.extend(new window.google.maps.LatLng(drop.adresss_loc.lat, drop.adresss_loc.lng));
              }
            });

            mapRef.fitBounds(bounds);

          } else {
            return null;
          }
        }
      } else if (["Pickup"].indexOf(inData.orderType) > -1) {
        if (inData.EROpenERDialoge) {
          if (
            String(inData.ERpickupAdresss_loc.lng) !== "" &&
            String(inData.ERdropOffsAdresss_loc.lng) !== ""
          ) {
            bounds.extend(new window.google.maps.LatLng(inData.ERpickupAdresss_loc.lat, inData.ERpickupAdresss_loc.lng));

            mapRef.fitBounds(bounds);


          } else {
            return null;
          }
        } else if (
          inData.OpenVRDialoge &&
          !inData.EROpenERDialoge &&
          inData.VRpureData_pickup
        ) {
          bounds.extend(new window.google.maps.LatLng(inData.VRpureData_pickup.coordinates[1], inData.VRpureData_pickup.coordinates[0]));
          if (inData.driver_location) {
            bounds.extend(new window.google.maps.LatLng(inData.driver_location[1], inData.driver_location[0]));
          }
          mapRef.fitBounds(bounds);

        } else if (inData.OpenNRDialoge) {
          if (
            String(inData.NRpickupAdresss_loc.lng) !== "" &&
            String(inData.NRdropOffsAdresss_loc.lng) !== ""
          ) {
            bounds.extend(new window.google.maps.LatLng(inData.NRpickupAdresss_loc.lat, inData.NRpickupAdresss_loc.lng));
            bounds.extend(new window.google.maps.LatLng(inData.NRdropOffsAdresss_loc.lat, inData.NRdropOffsAdresss_loc.lng));
            inData.NRotherDropOffs.map((drop) => {
              if (String(drop.adresss_loc.lng) !== "") {
                bounds.extend(new window.google.maps.LatLng(drop.adresss_loc.lat, drop.adresss_loc.lng));
              }
            });
            mapRef.fitBounds(bounds);

          } else {
            return null;
          }
        }
      } else if (["PickupDelivery"].indexOf(inData.orderType) > -1) {
        if (inData.EROpenERDialoge) {
          if (
            inData.ERpickupAdresss_loc.lng !== "" &&
            inData.ERdropOffsAdresss_loc.lng !== ""
          ) {
            bounds.extend(new window.google.maps.LatLng(inData.ERpickupAdresss_loc.lat, inData.ERpickupAdresss_loc.lng));
            bounds.extend(new window.google.maps.LatLng(inData.ERdropOffsAdresss_loc.lat, inData.ERdropOffsAdresss_loc.lng));
            inData.ERotherDropOffs.map((drop) => {
              if (drop.adresss_loc.lng !== "") {
                bounds.extend(new window.google.maps.LatLng(drop.adresss_loc.lat, drop.adresss_loc.lng));
              }
            });
            mapRef.fitBounds(bounds);

          } else {
            return null;
          }
        } else if (
          inData.OpenVRDialoge &&
          !inData.EROpenERDialoge &&
          inData.VRpureData_pickup
        ) {

          bounds.extend(new window.google.maps.LatLng(inData.VRpureData_pickup.coordinates[1], inData.VRpureData_pickup.coordinates[0]));
          bounds.extend(new window.google.maps.LatLng(inData.VRpureData_delivery.coordinates[1], inData.VRpureData_delivery.coordinates[0]));
          // inData.VRpureData_dropoffs.map((drop) => {
          //   if (drop.coordinates[1] !== "") {
          //     bounds.extend(new window.google.maps.LatLng(drop.coordinates[1], drop.coordinates[0]));
          //   }
          // });
          if (inData.driver_location) {
            bounds.extend(new window.google.maps.LatLng(inData.driver_location[1], inData.driver_location[0]));
          }
          mapRef.fitBounds(bounds);

        } else if (inData.OpenNRDialoge) {
          if (
            inData.NRpickupAdresss_loc.lng !== "" &&
            inData.NRdropOffsAdresss_loc.lng !== ""
          ) {

            bounds.extend(new window.google.maps.LatLng(inData.NRpickupAdresss_loc.lat, inData.NRpickupAdresss_loc.lng));
            bounds.extend(new window.google.maps.LatLng(inData.NRdropOffsAdresss_loc.lat, inData.NRdropOffsAdresss_loc.lng));
            inData.NRotherDropOffs.map((drop) => {
              if (drop.adresss_loc.lng !== "") {
                bounds.extend(new window.google.maps.LatLng(drop.adresss_loc.lat, drop.adresss_loc.lng));
              }
            });
            mapRef.fitBounds(bounds);

          } else {
            return null;
          }
        }
      } else {
        if (inData.EROpenERDialoge) {
          if (
            String(inData.ERpickupAdresss_loc.lng) !== "" &&
            String(inData.ERdropOffsAdresss_loc.lng) !== ""
          ) {


            bounds.extend(new window.google.maps.LatLng(inData.ERpickupAdresss_loc.lat, inData.ERpickupAdresss_loc.lng));
            bounds.extend(new window.google.maps.LatLng(inData.ERdropOffsAdresss_loc.lat, inData.ERdropOffsAdresss_loc.lng));
            inData.ERotherDropOffs.map((drop) => {
              if (String(drop.adresss_loc.lng) !== "") {
                bounds.extend(new window.google.maps.LatLng(drop.adresss_loc.lat, drop.adresss_loc.lng));
              }
            });
            mapRef.fitBounds(bounds);


          } else {
            return null;
          }
        } else if (
          inData.OpenVRDialoge &&
          !inData.EROpenERDialoge &&
          inData.VRpureData_pickup
        ) {
          if (inData.driver_location) {
            bounds.extend(new window.google.maps.LatLng(inData.driver_location[1], inData.driver_location[0]));
          }
          bounds.extend(new window.google.maps.LatLng(inData.VRpureData_pickup.coordinates[1], inData.VRpureData_pickup.coordinates[0]));
          inData.VRpureData_dropoffs.map((drop) => {
            if (String(drop.coordinates[1]) !== "") {
              bounds.extend(new window.google.maps.LatLng(drop.coordinates[1], drop.coordinates[0]));
            }
          });
          

          

          mapRef.fitBounds(bounds);

        } else if (inData.OpenNRDialoge) {
          if (
            String(inData.NRpickupAdresss_loc.lng) !== "" &&
            String(inData.NRdropOffsAdresss_loc.lng) !== ""
          ) {

            bounds.extend(new window.google.maps.LatLng(inData.NRpickupAdresss_loc.lat, inData.NRpickupAdresss_loc.lng));
            bounds.extend(new window.google.maps.LatLng(inData.NRdropOffsAdresss_loc.lat, inData.NRdropOffsAdresss_loc.lng));
            inData.NRotherDropOffs.map((drop) => {
              if (String(drop.adresss_loc.lng) !== "") {
                bounds.extend(new window.google.maps.LatLng(drop.adresss_loc.lat, drop.adresss_loc.lng));
              }
            });
            mapRef.fitBounds(bounds);

          } else {
            return null;
          }
        }
      }

      this.setState({
        location_bounded : true
      })
    } catch (error) {

    }
    

  };


  render() {

    return (
      null
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  OpenNRDialoge: state.NewOrder.OpenDialoge,
  NRpickupAdresss_loc: state.NewOrder.newOrder.pickup.adresss_loc,
  NRdropOffsAdresss_loc:
    state.NewOrder.newOrder.dropOffs.firstDropOff.adresss_loc,
  NRotherDropOffs: state.NewOrder.newOrder.dropOffs.otherDropOffs,

  OpenVRDialoge: state.ViewTravel.OpenDialoge,
  EROpenERDialoge: state.EditOrder.OpenDialoge,
  ERpickupAdresss_loc: state.EditOrder.editOrder.pickup.adresss_loc,
  ERdropOffsAdresss_loc:
    state.EditOrder.editOrder.dropOffs.firstDropOff.adresss_loc,
  ERotherDropOffs: state.EditOrder.editOrder.dropOffs.otherDropOffs,

  orderType: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.type
      ? state.ViewTravel.pureData.type
      : null
    : null,

  VRpureData_dropoff: state.ViewTravel.pureData ? state.ViewTravel.pureData.dropoff : null,
  VRpureData_delivery: state.ViewTravel.pureData ? state.ViewTravel.pureData.delivery : null,
  VRpureData_ID: state.ViewTravel.pureData ? state.ViewTravel.pureData.id : null,

  VRpureData_pickup: state.ViewTravel.pureData ? state.ViewTravel.pureData.pickup : null,
  VRpureData_dropoffs: state.ViewTravel.pureData ? state.ViewTravel.pureData.dropoffs : null,
  mapRef: state.Map.mapRef,
  driver_location: state.ViewTravel.driver_location,

});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});



export default connect(mapStateToProps, mapDispatchToProps)(App)