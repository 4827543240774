import PickGoPickGo from "../../../assets/PickGoogotype.png";
import FavIcon from "../../../assets/PROJECTS/PickGo.png";

export default function PickGo() {
    
    return global.config = {
    "ProjectName": "Pick & Go",
    "LogoType": PickGoPickGo,
    "BaseApi": "https://rest.pickngosg.com",
    "BaseSocket": "https://socket.pickngosg.com",
    "BaseTrack": "https://panel.pickngosg.com",
    "MapCenter": {
        "lat": "1.364196",
        "lng": "103.868759"
    },
    "Lang": "en",
    "Direction": "ltr",
    "AllLanguages": [{
        "lang": "en",
        "direction": "ltr",
        "i18Lang": "English"
    }],
    "FavIcon": FavIcon,
    "Country": "SG",
    "thirdPartyLoginSupport": true  ,
    mapType :"osm", /* osm | google */ 
    googleApiKey : ""
}}