import React, { createRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './style.module.css';
import { LocalData } from "../../service/localStorage";
import { useTranslation } from 'react-i18next';
import DropDown from '../../design-system/lib/DropDown';
import Book from '../../assets/Book.svg';
import Typography from '../../design-system/lib/Typography';

export const LearnMore = ({placement , where="test" ,hastext , className ,itemRowClassName}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const localData = new LocalData();
    const [from, setFrom] = useState(null);


    let articles ={
        OndemandOrderList : [
            {title :"Order types and statuses",link:"https://onrodesk.atlassian.net/wiki/external/ZmI3MjZiM2E1ZWZmNGYyNmE5NTUxMDc2OGVjNDhhMzY"},
            {title :"ETA & delay management",link:"https://onrodesk.atlassian.net/wiki/external/OTFkZGFmYWZkMWZmNGNlZWFiODQxY2E5MTE2MTQ4OGI"},
            {title :"How to monitor orders",link:"https://onrodesk.atlassian.net/wiki/external/OTMyNzVlNThiMmU3NDVlMjlhMmE3NjFiOGMyZjE1NGM"},
            {title :"How driver selection works in dispatch algorithm",link:"https://onrodesk.atlassian.net/wiki/external/ODUyMzEwNGMxZjQ0NGI0Yzk1OTc0MmE0OWEwYTA1YjQ"},
            {title :"How dispatching works for scheduled orders",link:"https://onrodesk.atlassian.net/wiki/external/MWNkYzBlZjM4NDgzNDYzYWIxZjgyYzdkNjZkODE0OGU"},
            {title :"How to dispatch an order urgently",link:"https://onrodesk.atlassian.net/wiki/external/Nzg1M2QxMTk0NmFmNDMxMThiMTY3YzdjODU1MzQ4NTM"},
            {title :"How to set the default zone (location)",link:"https://onrodesk.atlassian.net/wiki/external/MjE1YmMyYWE0YzdmNGNhYTk0MzE4YTlhYzg5YTVjYzg"},
            // {title :"How to manage access for users",link:"https://onrodesk.atlassian.net/wiki/external/OGY5MzIzODFkOWVmNDBmNTlmZjVhODNjODcyNzczMTQ"},
            // {title :"How to change the user password",link:"https://onrodesk.atlassian.net/wiki/external/MmVhNWQyMDE0NTYwNDlmNGJhODczNjgwZDc0NmJlNDI"},
            // {title :"Having different users to manage different teams",link:"https://onrodesk.atlassian.net/wiki/external/MjEzZWM0ZWEwOTcwNGEwZGE2ZWM5NDM1ODdlNDlmZjU"},
            // {title :"How to create multiple users",link:"https://onrodesk.atlassian.net/wiki/external/YmRlNmMxODIyOTNiNDYwZmE5OWE5NDFhNTgwZTg1ZGI"},
            // {title :"How to manage languages",link:"https://onrodesk.atlassian.net/wiki/external/ZTAzMDMyOTk0M2NhNDkyYjg0MzJjMWM4NDM0YzI2NTc"},
        ],
        PickupDeliveryOrderList : [
            {title :"Order types and statuses",link:"https://onrodesk.atlassian.net/wiki/external/ZmI3MjZiM2E1ZWZmNGYyNmE5NTUxMDc2OGVjNDhhMzY"},
            {title :"P&D workflow basics",link:"https://onrodesk.atlassian.net/wiki/external/MDEwYjc0NmY5MTdhNDA0OGI3NDlkZDgzZjYxZmUwMmY"},
            {title :"How to update P&D order status",link:"https://onrodesk.atlassian.net/wiki/external/N2E0ZTUxMWIzM2Y5NDllZTlkNTRjMTYwNDM3ZDI1NTk"},
            {title :"Orders shipment label",link:"https://onrodesk.atlassian.net/wiki/external/ZjE3MzkzNDg1ZWZlNDQ1Nzk3OGVjMmFjNzE1N2Q4Yjc"},
            {title :"How to manage failed reasons",link:"https://onrodesk.atlassian.net/wiki/external/NzViMzkyNmU1NmU4NDc5NDg2ZDMzNjc2MTJkZDQxMDg"},
            {title :"How to monitor orders",link:"https://onrodesk.atlassian.net/wiki/external/OTMyNzVlNThiMmU3NDVlMjlhMmE3NjFiOGMyZjE1NGM"},
            {title :"How to create a new route",link:"https://onrodesk.atlassian.net/wiki/external/ZTg1M2E5OTRmNDE3NDA2ZThlYjg4ZWRjZDhhNTkyZTA"},
            {title :"How to delete an order from a route",link:"https://onrodesk.atlassian.net/wiki/external/Y2YxNjA0OTJkNDRhNDM4N2E2ZGU1MTYzOGMxMTllMzc"},
            // {title :"How to manage access for users",link:"https://onrodesk.atlassian.net/wiki/external/OGY5MzIzODFkOWVmNDBmNTlmZjVhODNjODcyNzczMTQ"},
            // {title :"How to change the user password",link:"https://onrodesk.atlassian.net/wiki/external/MmVhNWQyMDE0NTYwNDlmNGJhODczNjgwZDc0NmJlNDI"},
            // {title :"Having different users to manage different teams",link:"https://onrodesk.atlassian.net/wiki/external/MjEzZWM0ZWEwOTcwNGEwZGE2ZWM5NDM1ODdlNDlmZjU"},
            // {title :"How to create multiple users",link:"https://onrodesk.atlassian.net/wiki/external/YmRlNmMxODIyOTNiNDYwZmE5OWE5NDFhNTgwZTg1ZGI"},
            // {title :"How to manage languages",link:"https://onrodesk.atlassian.net/wiki/external/ZTAzMDMyOTk0M2NhNDkyYjg0MzJjMWM4NDM0YzI2NTc"},
            {title :"How to set the default zone (location)",link:"https://onrodesk.atlassian.net/wiki/external/MjE1YmMyYWE0YzdmNGNhYTk0MzE4YTlhYzg5YTVjYzg"},
            {title :"How to scan orders at the pickup by the driver app",link:"https://onrodesk.atlassian.net/wiki/external/NGMzZTY5OTk0ZGI4NDFjZjk3ZGUwOWI5MWExYjU5MTU"},
            {title :"How to inbound scan in hub (At warehouse)",link:"https://onrodesk.atlassian.net/wiki/external/YzE4NmFmMzYxYzc0NGQ1OGEwMzkyNjUyNGU5M2E4Y2M"},
            {title :"How to load deliveries",link:"https://onrodesk.atlassian.net/wiki/external/MmQ3NTJkODdhMzYyNDRkMDk5NzgzYjFiZjc5MWViN2Y"},

        ],
        PickupOrderList : [
            {title :"Order types and statuses",link:"https://onrodesk.atlassian.net/wiki/external/ZmI3MjZiM2E1ZWZmNGYyNmE5NTUxMDc2OGVjNDhhMzY"},
            {title :"ETA & delay management",link:"https://onrodesk.atlassian.net/wiki/external/OTFkZGFmYWZkMWZmNGNlZWFiODQxY2E5MTE2MTQ4OGI"},
            {title :"How to monitor orders",link:"https://onrodesk.atlassian.net/wiki/external/OTMyNzVlNThiMmU3NDVlMjlhMmE3NjFiOGMyZjE1NGM"},
            {title :"How driver selection works in dispatch algorithm",link:"https://onrodesk.atlassian.net/wiki/external/ODUyMzEwNGMxZjQ0NGI0Yzk1OTc0MmE0OWEwYTA1YjQ"},
            {title :"How dispatching works for scheduled orders",link:"https://onrodesk.atlassian.net/wiki/external/MWNkYzBlZjM4NDgzNDYzYWIxZjgyYzdkNjZkODE0OGU"},
            {title :"How to dispatch an order urgently",link:"https://onrodesk.atlassian.net/wiki/external/Nzg1M2QxMTk0NmFmNDMxMThiMTY3YzdjODU1MzQ4NTM"},
            // {title :"How to set the default zone (location)",link:"https://onrodesk.atlassian.net/wiki/external/MjE1YmMyYWE0YzdmNGNhYTk0MzE4YTlhYzg5YTVjYzg"},
            // {title :"How to manage access for users",link:"https://onrodesk.atlassian.net/wiki/external/OGY5MzIzODFkOWVmNDBmNTlmZjVhODNjODcyNzczMTQ"},
            // {title :"How to change the user password",link:"https://onrodesk.atlassian.net/wiki/external/MmVhNWQyMDE0NTYwNDlmNGJhODczNjgwZDc0NmJlNDI"},
            // {title :"Having different users to manage different teams",link:"https://onrodesk.atlassian.net/wiki/external/MjEzZWM0ZWEwOTcwNGEwZGE2ZWM5NDM1ODdlNDlmZjU"},
            // {title :"How to create multiple users",link:"https://onrodesk.atlassian.net/wiki/external/YmRlNmMxODIyOTNiNDYwZmE5OWE5NDFhNTgwZTg1ZGI"},
            // {title :"How to manage languages",link:"https://onrodesk.atlassian.net/wiki/external/ZTAzMDMyOTk0M2NhNDkyYjg0MzJjMWM4NDM0YzI2NTc"},

        ],
        DeliveryOrderList : [
            {title :"Order types and statuses",link:"https://onrodesk.atlassian.net/wiki/external/ZmI3MjZiM2E1ZWZmNGYyNmE5NTUxMDc2OGVjNDhhMzY"},
            {title :"ETA & delay management",link:"https://onrodesk.atlassian.net/wiki/external/OTFkZGFmYWZkMWZmNGNlZWFiODQxY2E5MTE2MTQ4OGI"},
            {title :"How to monitor orders",link:"https://onrodesk.atlassian.net/wiki/external/OTMyNzVlNThiMmU3NDVlMjlhMmE3NjFiOGMyZjE1NGM"},
            {title :"How driver selection works in dispatch algorithm",link:"https://onrodesk.atlassian.net/wiki/external/ODUyMzEwNGMxZjQ0NGI0Yzk1OTc0MmE0OWEwYTA1YjQ"},
            {title :"How dispatching works for scheduled orders",link:"https://onrodesk.atlassian.net/wiki/external/MWNkYzBlZjM4NDgzNDYzYWIxZjgyYzdkNjZkODE0OGU"},
            {title :"How to dispatch an order urgently",link:"https://onrodesk.atlassian.net/wiki/external/Nzg1M2QxMTk0NmFmNDMxMThiMTY3YzdjODU1MzQ4NTM"},
            // {title :"How to set the default zone (location)",link:"https://onrodesk.atlassian.net/wiki/external/MjE1YmMyYWE0YzdmNGNhYTk0MzE4YTlhYzg5YTVjYzg"},
            // {title :"How to manage access for users",link:"https://onrodesk.atlassian.net/wiki/external/OGY5MzIzODFkOWVmNDBmNTlmZjVhODNjODcyNzczMTQ"},
            // {title :"How to change the user password",link:"https://onrodesk.atlassian.net/wiki/external/MmVhNWQyMDE0NTYwNDlmNGJhODczNjgwZDc0NmJlNDI"},
            // {title :"Having different users to manage different teams",link:"https://onrodesk.atlassian.net/wiki/external/MjEzZWM0ZWEwOTcwNGEwZGE2ZWM5NDM1ODdlNDlmZjU"},
            // {title :"How to create multiple users",link:"https://onrodesk.atlassian.net/wiki/external/YmRlNmMxODIyOTNiNDYwZmE5OWE5NDFhNTgwZTg1ZGI"},
            // {title :"How to manage languages",link:"https://onrodesk.atlassian.net/wiki/external/ZTAzMDMyOTk0M2NhNDkyYjg0MzJjMWM4NDM0YzI2NTc"},
        ],
        Routes : [
            {title :"How to manage routes",link:"https://onrodesk.atlassian.net/wiki/external/NDA5YTEyMWYwYzlkNDUzMDgxZWNhYTExM2YxNmU2Nzg"},
            // {title :"How to create a new route",link:"https://onrodesk.atlassian.net/wiki/external/ZTg1M2E5OTRmNDE3NDA2ZThlYjg4ZWRjZDhhNTkyZTA"},
            // {title :"Route optimization mechanism",link:"https://onrodesk.atlassian.net/wiki/external/NGI2ZGNmY2UyM2IwNDdlMDllM2FmMzNlNzM1MjNmNWY"},
            // {title :"How to delete an order from a route",link:"https://onrodesk.atlassian.net/wiki/external/Y2YxNjA0OTJkNDRhNDM4N2E2ZGU1MTYzOGMxMTllMzc"},
            // {title :"How to complete a route",link:"https://onrodesk.atlassian.net/wiki/external/OGY2MmJjOWY1N2RmNGY0NjhkYzU4OGYxYjY1N2I0MTA"},
            // {title :"How to change an order's route",link:"https://onrodesk.atlassian.net/wiki/external/MGM3M2I3MjdkNTVkNGRjZGFiODZjNmNhMGIyNTExMDc"},
            // {title :"How to change the status of a route",link:"https://onrodesk.atlassian.net/wiki/external/MzY2YzE3YTM1ZDY4NDVhZDk1MjcwN2Q1NGJmY2IzMDg"},
            // {title :"How to change the driver of a route",link:"https://onrodesk.atlassian.net/wiki/external/NDk2NmVlY2FiMzEwNDU4YjhhN2NjZjVjMTcwMGZlNTI"},
            // {title :"How to assign a route to a driver",link:"https://onrodesk.atlassian.net/wiki/external/OTQ0ZGRlNDI3N2YxNDBhZWI3YzRjODNmYzRiOTUyZDk"},
            // {title :"How to scan orders at the pickup by the driver app",link:"https://onrodesk.atlassian.net/wiki/external/NGMzZTY5OTk0ZGI4NDFjZjk3ZGUwOWI5MWExYjU5MTU"},
            // {title :"How to inbound scan in hub (At warehouse)",link:"https://onrodesk.atlassian.net/wiki/external/YzE4NmFmMzYxYzc0NGQ1OGEwMzkyNjUyNGU5M2E4Y2M"},
            // {title :"How to load deliveries",link:"https://onrodesk.atlassian.net/wiki/external/MmQ3NTJkODdhMzYyNDRkMDk5NzgzYjFiZjc5MWViN2Y"},

        ],
        RouteDetails : [
            {title :"How to delete an order from a route",link:"https://onrodesk.atlassian.net/wiki/external/Y2YxNjA0OTJkNDRhNDM4N2E2ZGU1MTYzOGMxMTllMzc"},
            {title :"How to complete a route",link:"https://onrodesk.atlassian.net/wiki/external/OGY2MmJjOWY1N2RmNGY0NjhkYzU4OGYxYjY1N2I0MTA"},
            {title :"How to change an order's route",link:"https://onrodesk.atlassian.net/wiki/external/MGM3M2I3MjdkNTVkNGRjZGFiODZjNmNhMGIyNTExMDc"},
            {title :"How to change the status of a route",link:"https://onrodesk.atlassian.net/wiki/external/MzY2YzE3YTM1ZDY4NDVhZDk1MjcwN2Q1NGJmY2IzMDg"},
            {title :"How to change the driver of a route",link:"https://onrodesk.atlassian.net/wiki/external/NDk2NmVlY2FiMzEwNDU4YjhhN2NjZjVjMTcwMGZlNTI"},
            {title :"How to assign a route to a driver",link:"https://onrodesk.atlassian.net/wiki/external/OTQ0ZGRlNDI3N2YxNDBhZWI3YzRjODNmYzRiOTUyZDk"},
        ],
        EditRoute : [
            {title :"How to complete a route",link:"https://onrodesk.atlassian.net/wiki/external/OGY2MmJjOWY1N2RmNGY0NjhkYzU4OGYxYjY1N2I0MTA"},
            {title :"How to change the status of a route",link:"https://onrodesk.atlassian.net/wiki/external/MzY2YzE3YTM1ZDY4NDVhZDk1MjcwN2Q1NGJmY2IzMDg"},
            {title :"How to change the driver of a route",link:"https://onrodesk.atlassian.net/wiki/external/NDk2NmVlY2FiMzEwNDU4YjhhN2NjZjVjMTcwMGZlNTI"},
            {title :"How to assign a route to a driver",link:"https://onrodesk.atlassian.net/wiki/external/OTQ0ZGRlNDI3N2YxNDBhZWI3YzRjODNmYzRiOTUyZDk"},
        ],
        Drivers : [
            {title :"How to monitor/manage drivers",link:"https://onrodesk.atlassian.net/wiki/external/MDkxODNjM2U5MWY3NDkwYTgyOWRjZDU0NmFjY2RlNDk"},
        ],
        BulkImport : [
            {title :"Bulk Import",link:"https://onrodesk.atlassian.net/wiki/external/YTk5NjBhOTI5YTMyNDE2NTg3MGZmMGNjOTRhMWFiZmI"},
        ],
        NewOrder : [
            {title :"Order types and statuses",link:"https://onrodesk.atlassian.net/wiki/external/ZmI3MjZiM2E1ZWZmNGYyNmE5NTUxMDc2OGVjNDhhMzY"},
            {title :"How driver selection works in dispatch algorithm",link:"https://onrodesk.atlassian.net/wiki/external/ODUyMzEwNGMxZjQ0NGI0Yzk1OTc0MmE0OWEwYTA1YjQ"},
            {title :"How dispatching works for scheduled orders",link:"https://onrodesk.atlassian.net/wiki/external/MWNkYzBlZjM4NDgzNDYzYWIxZjgyYzdkNjZkODE0OGU"},
            {title :"P&D workflow basics",link:"https://onrodesk.atlassian.net/wiki/external/MDEwYjc0NmY5MTdhNDA0OGI3NDlkZDgzZjYxZmUwMmY"},
            {title :"How to disable/enable a service",link:"https://onrodesk.atlassian.net/wiki/external/MGEyZmQ4YmIwMzQwNGY4NDg5OTdlNDM1ZTM5YmIxMWY"},
            // {title :"How to add vehicle types to a service",link:"https://onrodesk.atlassian.net/wiki/external/NjUwMGQyMjY2ZDBmNDAzODhjNDhlZTAyY2JhMjk4MjU"},
            // {title :"How to enable a service only for a team (group of drivers)",link:"https://onrodesk.atlassian.net/wiki/external/NDVmZjc4M2UwMTYyNDg5MDg5NzNjMDhlMjk0OTczYzA"},
            // {title :"How to enable a service in some zone(s)",link:"https://onrodesk.atlassian.net/wiki/external/ODMyZGUwOWRlMjJlNDYxNmI3MDYyOTFlNWU2YTBjYjk"},
            // {title :"How to enable cash on delivery and set the maximum amount for a service",link:"https://onrodesk.atlassian.net/wiki/external/ZmI5MTIwMTEwZTA3NDljMDhjZTVlMGNkNDA4ZTM4ZWM"},
            // {title :"How do enable/disable/require pickup and dropoff fields",link:"https://onrodesk.atlassian.net/wiki/external/ZmQ1YWEyNmIzNWY1NGE2YmIzMzhjZWQ1YjZkMzg1NDY"},
            // {title :"How to force drivers to take pictures at the pickup step",link:"https://onrodesk.atlassian.net/wiki/external/OWM3YzZiMTQxNTk1NGUyZWIzN2QyNGRmN2E4NDNjMjc"},
            // {title :"How to add service options",link:"https://onrodesk.atlassian.net/wiki/external/ZjNlNmJlNjhlY2Y4NDNlOTkxZjAxYWQyOWU0MTdkZWI"},
            // {title :"How to enable/require proof of delivery for a service",link:"https://onrodesk.atlassian.net/wiki/external/YmY4Njg5OGQyMDM4NDdmNWI4MjlmNGRiODBkNjk4MjU"},
            // {title :"How to filter services for customers",link:"https://onrodesk.atlassian.net/wiki/external/MTNjZTZiODZlOTczNDc2M2JlZWY2YTI2ZWI0NDQwNWE"},
            // {title :"How to manage payment methods for a service",link:"https://onrodesk.atlassian.net/wiki/external/MjM5YWJkMWU5NTk4NDQ4MTgyZDYzMDI3ZWE2NjQ3YmQ"},
            // {title :"How to configure Stripe",link:"https://onrodesk.atlassian.net/wiki/external/MDg3ZDFiNjFlYzIzNDYxMjgxODI5YWExMGE2MDI3ODk"},
            {title :"How to assign/offer an order to a driver",link:"https://onrodesk.atlassian.net/wiki/external/M2JjYTIwYjhlZTQxNDM2Mjk4NmUyZmUzMGJlYjBjNTY"},
            // {title :"How to set a customer minimum balance",link:"https://onrodesk.atlassian.net/wiki/external/ZDg5YjE1YWM1MjY4NDIzMzg4ODJkYWI1MmM4NmI5MGY"},
            // {title :"How to change currency of the system",link:"https://onrodesk.atlassian.net/wiki/external/NWI1NzQ4MDMzNjM1NDMxMDg3MGIzODMyNjE1MTVjODc"},

        ],
        PickupDeliveryViewOrder : [
            {title :"How to edit order details",link:"https://onrodesk.atlassian.net/wiki/external/NzE5OWZkMzlhM2QyNGFlMmI3YTJkM2Q2OGFjYWNlY2Q"},
            {title :"Orders shipment label",link:"https://onrodesk.atlassian.net/wiki/external/ZjE3MzkzNDg1ZWZlNDQ1Nzk3OGVjMmFjNzE1N2Q4Yjc"},
            {title :"How to manage failed reasons",link:"https://onrodesk.atlassian.net/wiki/external/NzViMzkyNmU1NmU4NDc5NDg2ZDMzNjc2MTJkZDQxMDg"},
            {title :"How to delete an order from a route",link:"https://onrodesk.atlassian.net/wiki/external/Y2YxNjA0OTJkNDRhNDM4N2E2ZGU1MTYzOGMxMTllMzc"},
            {title :"How to update P&D order status",link:"https://onrodesk.atlassian.net/wiki/external/N2E0ZTUxMWIzM2Y5NDllZTlkNTRjMTYwNDM3ZDI1NTk"},
            {title :"What is POD?",link:"https://onrodesk.atlassian.net/wiki/external/MjViMjcyMDMwYjhhNDlhNGIwOGYyZDAyMjIzYjcyZjQ"},
            // {title :"How to change currency of the system",link:"https://onrodesk.atlassian.net/wiki/external/NWI1NzQ4MDMzNjM1NDMxMDg3MGIzODMyNjE1MTVjODc"},

        ],
        OndemandViewOrder : [
            {title :"How to dispatch an order urgently",link:"https://onrodesk.atlassian.net/wiki/external/Nzg1M2QxMTk0NmFmNDMxMThiMTY3YzdjODU1MzQ4NTM"},
            {title :"The dispatch threshold mechanism of scheduled orders",link:"https://onrodesk.atlassian.net/wiki/external/Mzg0ODJhMjlhZmZmNDA5Mjk2OGQ1NjI5ODdiZjE4YzU"},
            {title :`How to edit the "Dispatch At" (time of dispatch) for an order`,link:"https://onrodesk.atlassian.net/wiki/external/MDA0MzRhN2JkNmVkNGU1NGI5MDM0YjRkODU2ZTVkNmQ"},
            {title :"ETA & delay management",link:"https://onrodesk.atlassian.net/wiki/external/OTFkZGFmYWZkMWZmNGNlZWFiODQxY2E5MTE2MTQ4OGI"},
            {title :"How to edit order details",link:"https://onrodesk.atlassian.net/wiki/external/NzE5OWZkMzlhM2QyNGFlMmI3YTJkM2Q2OGFjYWNlY2Q"},
            {title :"How to assign/offer an order to a driver",link:"https://onrodesk.atlassian.net/wiki/external/M2JjYTIwYjhlZTQxNDM2Mjk4NmUyZmUzMGJlYjBjNTY"},
            {title :"Orders shipment label",link:"https://onrodesk.atlassian.net/wiki/external/ZjE3MzkzNDg1ZWZlNDQ1Nzk3OGVjMmFjNzE1N2Q4Yjc"},
            {title :"What is POD?",link:"https://onrodesk.atlassian.net/wiki/external/MjViMjcyMDMwYjhhNDlhNGIwOGYyZDAyMjIzYjcyZjQ"},
            {title :"How driver selection works in dispatch algorithm",link:"https://onrodesk.atlassian.net/wiki/external/ODUyMzEwNGMxZjQ0NGI0Yzk1OTc0MmE0OWEwYTA1YjQ"},
            {title :"Viewing drivers offered for an order",link:"https://onrodesk.atlassian.net/wiki/external/ZTU0MGNiNTk2ZDJiNDE2NjhiYWQ4NGFlNjBhY2NjYzI"},

        ],
        PickupViewOrder : [
            {title :"How to dispatch an order urgently",link:"https://onrodesk.atlassian.net/wiki/external/Nzg1M2QxMTk0NmFmNDMxMThiMTY3YzdjODU1MzQ4NTM"},
            {title :"The dispatch threshold mechanism of scheduled orders",link:"https://onrodesk.atlassian.net/wiki/external/Mzg0ODJhMjlhZmZmNDA5Mjk2OGQ1NjI5ODdiZjE4YzU"},
            {title :`How to edit the "Dispatch At" (time of dispatch) for an order`,link:"https://onrodesk.atlassian.net/wiki/external/MDA0MzRhN2JkNmVkNGU1NGI5MDM0YjRkODU2ZTVkNmQ"},
            {title :"ETA & delay management",link:"https://onrodesk.atlassian.net/wiki/external/OTFkZGFmYWZkMWZmNGNlZWFiODQxY2E5MTE2MTQ4OGI"},
            {title :"How to edit order details",link:"https://onrodesk.atlassian.net/wiki/external/NzE5OWZkMzlhM2QyNGFlMmI3YTJkM2Q2OGFjYWNlY2Q"},
            {title :"How to assign/offer an order to a driver",link:"https://onrodesk.atlassian.net/wiki/external/M2JjYTIwYjhlZTQxNDM2Mjk4NmUyZmUzMGJlYjBjNTY"},
            {title :"Orders shipment label",link:"https://onrodesk.atlassian.net/wiki/external/ZjE3MzkzNDg1ZWZlNDQ1Nzk3OGVjMmFjNzE1N2Q4Yjc"},
            {title :"What is POD?",link:"https://onrodesk.atlassian.net/wiki/external/MjViMjcyMDMwYjhhNDlhNGIwOGYyZDAyMjIzYjcyZjQ"},
            {title :"How driver selection works in dispatch algorithm",link:"https://onrodesk.atlassian.net/wiki/external/ODUyMzEwNGMxZjQ0NGI0Yzk1OTc0MmE0OWEwYTA1YjQ"},
            {title :"Viewing drivers offered for an order",link:"https://onrodesk.atlassian.net/wiki/external/ZTU0MGNiNTk2ZDJiNDE2NjhiYWQ4NGFlNjBhY2NjYzI"},
            // {title :"How to change currency of the system",link:"https://onrodesk.atlassian.net/wiki/external/NWI1NzQ4MDMzNjM1NDMxMDg3MGIzODMyNjE1MTVjODc"},

        ],
        DeliveryViewOrder : [
            {title :"How to dispatch an order urgently",link:"https://onrodesk.atlassian.net/wiki/external/Nzg1M2QxMTk0NmFmNDMxMThiMTY3YzdjODU1MzQ4NTM"},
            {title :"The dispatch threshold mechanism of scheduled orders",link:"https://onrodesk.atlassian.net/wiki/external/Mzg0ODJhMjlhZmZmNDA5Mjk2OGQ1NjI5ODdiZjE4YzU"},
            {title :`How to edit the "Dispatch At" (time of dispatch) for an order`,link:"https://onrodesk.atlassian.net/wiki/external/MDA0MzRhN2JkNmVkNGU1NGI5MDM0YjRkODU2ZTVkNmQ"},
            {title :"ETA & delay management",link:"https://onrodesk.atlassian.net/wiki/external/OTFkZGFmYWZkMWZmNGNlZWFiODQxY2E5MTE2MTQ4OGI"},
            {title :"How to edit order details",link:"https://onrodesk.atlassian.net/wiki/external/NzE5OWZkMzlhM2QyNGFlMmI3YTJkM2Q2OGFjYWNlY2Q"},
            {title :"How to assign/offer an order to a driver",link:"https://onrodesk.atlassian.net/wiki/external/M2JjYTIwYjhlZTQxNDM2Mjk4NmUyZmUzMGJlYjBjNTY"},
            {title :"Orders shipment label",link:"https://onrodesk.atlassian.net/wiki/external/ZjE3MzkzNDg1ZWZlNDQ1Nzk3OGVjMmFjNzE1N2Q4Yjc"},
            {title :"What is POD?",link:"https://onrodesk.atlassian.net/wiki/external/MjViMjcyMDMwYjhhNDlhNGIwOGYyZDAyMjIzYjcyZjQ"},
            {title :"How driver selection works in dispatch algorithm",link:"https://onrodesk.atlassian.net/wiki/external/ODUyMzEwNGMxZjQ0NGI0Yzk1OTc0MmE0OWEwYTA1YjQ"},
            {title :"Viewing drivers offered for an order",link:"https://onrodesk.atlassian.net/wiki/external/ZTU0MGNiNTk2ZDJiNDE2NjhiYWQ4NGFlNjBhY2NjYzI"},
            // {title :"How to change currency of the system",link:"https://onrodesk.atlassian.net/wiki/external/NWI1NzQ4MDMzNjM1NDMxMDg3MGIzODMyNjE1MTVjODc"},

        ],
        OndemandEditStatus : [
            {title :"How to assign/offer an order to a driver",link:"https://onrodesk.atlassian.net/wiki/external/M2JjYTIwYjhlZTQxNDM2Mjk4NmUyZmUzMGJlYjBjNTY"},
            {title :"How to edit order details",link:"https://onrodesk.atlassian.net/wiki/external/NzE5OWZkMzlhM2QyNGFlMmI3YTJkM2Q2OGFjYWNlY2Q"},
        ],
        OndemandEditPrice : [
            {title :"How to edit order details",link:"https://onrodesk.atlassian.net/wiki/external/NzE5OWZkMzlhM2QyNGFlMmI3YTJkM2Q2OGFjYWNlY2Q"},
        ],
        PickupEditPrice : [
            {title :"How to edit order details",link:"https://onrodesk.atlassian.net/wiki/external/NzE5OWZkMzlhM2QyNGFlMmI3YTJkM2Q2OGFjYWNlY2Q"},
        ],
        DeliveryEditPrice : [
            {title :"How to edit order details",link:"https://onrodesk.atlassian.net/wiki/external/NzE5OWZkMzlhM2QyNGFlMmI3YTJkM2Q2OGFjYWNlY2Q"},
        ],
        PickupDeliveryEditPrice : [
            {title :"How to edit order details",link:"https://onrodesk.atlassian.net/wiki/external/NzE5OWZkMzlhM2QyNGFlMmI3YTJkM2Q2OGFjYWNlY2Q"},
        ],
        PickupEditStatus : [
            {title :"How to assign/offer an order to a driver",link:"https://onrodesk.atlassian.net/wiki/external/M2JjYTIwYjhlZTQxNDM2Mjk4NmUyZmUzMGJlYjBjNTY"},
            {title :"How to edit order details",link:"https://onrodesk.atlassian.net/wiki/external/NzE5OWZkMzlhM2QyNGFlMmI3YTJkM2Q2OGFjYWNlY2Q"},
            ],
        DeliveryEditStatus : [
            {title :"How to assign/offer an order to a driver",link:"https://onrodesk.atlassian.net/wiki/external/M2JjYTIwYjhlZTQxNDM2Mjk4NmUyZmUzMGJlYjBjNTY"},
            {title :"How to edit order details",link:"https://onrodesk.atlassian.net/wiki/external/NzE5OWZkMzlhM2QyNGFlMmI3YTJkM2Q2OGFjYWNlY2Q"},
             ],
        PickupDeliveryEditStatus : [
            {title :"How to assign/offer an order to a driver",link:"https://onrodesk.atlassian.net/wiki/external/M2JjYTIwYjhlZTQxNDM2Mjk4NmUyZmUzMGJlYjBjNTY"},
            {title :"How to edit order details",link:"https://onrodesk.atlassian.net/wiki/external/NzE5OWZkMzlhM2QyNGFlMmI3YTJkM2Q2OGFjYWNlY2Q"},
            ],
        OndemandEditAdresses : [
            {title :"The dispatch threshold mechanism of scheduled orders",link:"https://onrodesk.atlassian.net/wiki/external/Mzg0ODJhMjlhZmZmNDA5Mjk2OGQ1NjI5ODdiZjE4YzU"},
            {title :"How to edit order details",link:"https://onrodesk.atlassian.net/wiki/external/NzE5OWZkMzlhM2QyNGFlMmI3YTJkM2Q2OGFjYWNlY2Q"},
        ],
        PickupEditAdresses : [
            {title :"The dispatch threshold mechanism of scheduled orders",link:"https://onrodesk.atlassian.net/wiki/external/Mzg0ODJhMjlhZmZmNDA5Mjk2OGQ1NjI5ODdiZjE4YzU"},
            {title :"How to edit order details",link:"https://onrodesk.atlassian.net/wiki/external/NzE5OWZkMzlhM2QyNGFlMmI3YTJkM2Q2OGFjYWNlY2Q"},       
        ],
        DeliveryEditAdresses : [
            {title :"The dispatch threshold mechanism of scheduled orders",link:"https://onrodesk.atlassian.net/wiki/external/Mzg0ODJhMjlhZmZmNDA5Mjk2OGQ1NjI5ODdiZjE4YzU"},
            {title :"How to edit order details",link:"https://onrodesk.atlassian.net/wiki/external/NzE5OWZkMzlhM2QyNGFlMmI3YTJkM2Q2OGFjYWNlY2Q"},        ],
        PickupDeliveryEditAdresses : [
            {title :"The dispatch threshold mechanism of scheduled orders",link:"https://onrodesk.atlassian.net/wiki/external/Mzg0ODJhMjlhZmZmNDA5Mjk2OGQ1NjI5ODdiZjE4YzU"},
            {title :"How to edit order details",link:"https://onrodesk.atlassian.net/wiki/external/NzE5OWZkMzlhM2QyNGFlMmI3YTJkM2Q2OGFjYWNlY2Q"},        ],
    }


    const ItemsList = React.useCallback(() => {
        return (
            <div className={styles.itemsConatiner }>
                {articles[where].map((item) => {
                    return <a  target="_blank" href={item.link} className={itemRowClassName && itemRowClassName}>
                       <img src={Book} />  <Typography weight="Body_Middle_Regular" textColor={'extNormal'} text={item.title} className={styles.dropItem} />
                    </a>
                })}
            </div>
        )
      }, [where , itemRowClassName]);





    return <>

            <DropDown className={className && className} placement={placement} size='Medium' text={hastext ? t("Learn") : null} icon={Book} ItemsList={ItemsList()} data={articles[where]} noArrowIcon={true}  />
 
    </>

};





