import Axios from "axios";
import { getCategoryList } from "../../components/main/order-list/components/category-collapse-list/action/getCategoryList";
import { restClient } from "./axios";
const queryString = require('query-string');


let cancelToken
let cancelTokenList

export class DriverApi {

  async Info(driverId) {
    try {

      const response = await restClient.request({
        method: 'get',
        url: `/api/dispatcher/driver/${driverId}`
      });


      return {
        "data": response.data.data
      }

    } catch (error) {
      if (error.response && error.response.status === 403) {
        localStorage.clear()
        window.location.reload();
      } else if (error.response) {
        return Promise.reject({
          code: error.response.data.code,
          message: error.response.data.message,
          status: error.response.status,
        })
      } else {
        return Promise.reject()
      }
    }


  }

  async Monitoring(params) {
    try {

      const response = await restClient.request({
        method: 'get',
        url: `/api/dispatcher/driver/monitoring`,
        params: params,
        paramsSerializer: params => {
          return queryString.stringify(params , {arrayFormat: 'bracket'})
        }
      });


      return {
        "data": response.data.data
      }

    } catch (error) {
      if (error.response && error.response.status === 403) {
        localStorage.clear()
        window.location.reload();
      } else if (error.response) {
        return Promise.reject({
          code: error.response.data.code,
          message: error.response.data.message,
          status: error.response.status,
        })
      } else {
        return Promise.reject()
      }
    }


  }


  // async List(params) {
  //   try {

  //     const response = await restClient.request({
  //       method: 'get',
  //       url: `/api/dispatcher/driver`,
  //       params: params
  //     });


  //     return {
  //       "data": response.data.data
  //     }

  //   } catch (error) {
  //     if (error.response && error.response.status === 403) {
  //       localStorage.clear()
  //       window.location.reload();
  //     } else if (error.response) {
  //       return Promise.reject({
  //         code: error.response.data.code,
  //         message: error.response.data.message,
  //         status: error.response.status,
  //       })
  //     } else {
  //       return Promise.reject()
  //     }
  //   }


  // }

  async Statistics() {
    try {

      if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel("Operation canceled due to new request.")
      }
      cancelToken = Axios.CancelToken.source()

      const response = await restClient.request({
        method: 'get',
        url: `/api/dispatcher/driver/statistics`,
        cancelToken : cancelToken.token
      });


      return {
        "data": response.data.data
      }

    } catch (error) {
      if (error.response && error.response.status === 403) {
        localStorage.clear()
        window.location.reload();
      } else if (error.response) {
        return Promise.reject({
          code: error.response.data.code,
          message: error.response.data.message,
          status: error.response.status,
        })
      } else {
        return Promise.reject()
      }
    }


  }

  async List(params) {
    try {

      if (typeof cancelTokenList != typeof undefined) {
        cancelTokenList.cancel("Operation canceled due to new request.")
      }
      cancelTokenList = Axios.CancelToken.source()


      const response = await restClient.request({
        method: 'get',
        url: `/api/dispatcher/driver`,
        params: params,
        cancelToken : cancelTokenList.token
      });


      return {
        "data": response.data.data
      }

    } catch (error) {
      if (error.response && error.response.status === 403) {
        localStorage.clear()
        window.location.reload();
      } else if (error.response) {
        return Promise.reject({
          code: error.response.data.code,
          message: error.response.data.message,
          status: error.response.status,
        })
      } else {
        return null
      }
    }


  }

}


