import React, { useCallback, useEffect, useRef, useState } from 'react'
import styles from './style.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { ARROW_LEFT, CLOSE, DRIVER, FORWARD, Ic_danger, ROUTE_OPTIMIZE, Routes_deactive, SEARCH_NOT_FOUND, box_gray } from '../../../assets';
import Button from '../../../design-system/lib/Button';
import Typography from '../../../design-system/lib/Typography';
import Tag from '../../../design-system/lib/Tag';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { ResetNewOrder, Table_setSelectedBulkEditStatusSummary, clearAll_Route_ActiveFilter, handleEditRouteFilter_Name } from '../../../Redux/actions/actionNames';
import jalali from "jalali-moment";
import { get_lang } from '../../../helper/UserData/userdate';
import RouteListNavbar from './route-list-navbar';
import { RouteApi } from '../../../service/API/route';
import IconProvider from '../../../design-system/lib/IconProvider';
import ProgressIndicator from '../../../design-system/lib/ProgressIndicator';
import { GetRouteRangeFilter } from '../../../hooks/GetRouteRangeFilter';
import DateFilter from '../../../design-system/lib/DateFilter';
import { GetRouteCheckboxFilter } from '../../../hooks/GetRouteCheckboxFilter';
import CheckBoxFilter from '../../../design-system/lib/CheckBoxFilter';
import InputSerachFilter from '../../../design-system/lib/InputSerachFilter';
import DriverListItem from '../../shared/DriverListItem';
import { DispatcherApi } from '../../../service/API/dispatcher';
import useMeasure from 'react-use-measure';
import momentJalaali from "moment-jalaali";

import _, { isNumber, isObject, debounce } from 'lodash';
import TableAddRoute from '../selectedOrders/components/addRoute';
import { defRoute, initRoute } from '../../../Redux/reducers/route';
import { useQuery } from '../../../hooks/useQuery';
import { DriverApi } from '../../../service/API/driver';
import EditStatusRoute from './components/editStatus';
import { store } from '../../../Redux/store';


function removeNullFields(obj) {
  for (var key in obj) {
    if (obj[key] === null) {
      delete obj[key];
    } else if (typeof obj[key] === 'object') {
      removeNullFields(obj[key]);
    }
  }
  return obj;
}

function gtelteSetter(obj) {
  for (var key in obj) {
    if (["gte", "equal"].indexOf(key) > -1) {
      if (isObject(obj[key])) {
        obj[key] = momentJalaali(obj[key].day + "/" + obj[key].month + "/" + obj[key].year + " 00:01", "DD/MM/YYYY HH:mm").format("x")
      }
    } else if (["lte"].indexOf(key) > -1) {
      if (isObject(obj[key])) {
        obj[key] = momentJalaali(obj[key].day + "/" + obj[key].month + "/" + obj[key].year + " 23:59", "DD/MM/YYYY HH:mm").format("x")
      }
    } else if (typeof obj[key] === 'object') {
      gtelteSetter(obj[key]);
    }
  }
  return obj;
}



function StatusConverter(data) {
  let types = [];

  data.forEach(element => {
    types.push({
      id: element, title: element
    })
  });
  return types
}


function RouteList() {
  const allFilters = useSelector((state) => state.Route.filters)

  const driverIdRoute = useSelector((state) => state.Route.filters.driverId)
  const status = useSelector((state) => state.Route.filters.status)
  const createdAt = useSelector((state) => state.Route.filters.createdAt)
  const name = useSelector((state) => state.Route.filters.name)

  const dispatch = useDispatch();
  const [routesList, setRoutesList] = useState(null);
  const [loading, setLoading] = useState(false);
  let { createdAt_data, createdAt_OnChange } = GetRouteRangeFilter("createdAt")
  let { status_data, status_OnChange, status_list } = GetRouteCheckboxFilter("status", StatusConverter(['Unassigned', 'Assigned', 'Started', 'Done']))
  const [DriverListloading, setDriverListloading] = useState(false);
  const [DriverList, setDriverList] = useState(null);
  const [ref, { height }] = useMeasure()
  const [showAddRoute, setShowAddRoute] = useState(false);
  const searchParams = useQuery();
  const driveridQuery = searchParams.get("driverid");


  useEffect(() => {
    if (driveridQuery && String(driveridQuery).length > 2) {
      getDriverInfo()
    }

    dispatch(ResetNewOrder())


  }, []);

  useEffect(() => {
    if (driveridQuery && String(driveridQuery).length > 2) {
      getDriverInfo()
    }

  }, [driveridQuery]);


  const getDriverInfo = useCallback(async (e) => {
    let Driver = new DriverApi()
    let driverData = await Driver.Info(driveridQuery)
    if (driverData.data) {
      dispatch(handleEditRouteFilter_Name({
        key: "driverId", value: {
          "id": driverData.data.id,
          "username": driverData.data.username,  
          "phone": driverData.data.phone,
          "name": driverData.data.name
        }
      }))


    }

  }, [driveridQuery]);



  const handleClose = () => {
    // dispatch(Table_setSelectedBulkEditStatusSummary(null))
  }

  // useEffect(() => {

  // }, [selectedBulkEditStatusSummary])

  useEffect(() => {
    document.addEventListener("routeUpdated", (e) => {
      console.log("addEventListener -> dispatcher:route:updated ", e);

      getRoutesList()
    });
    return () => document.removeEventListener("routeUpdated", () => {

    });
  }, []);


  useEffect(() => {
    document.addEventListener("routeAdded", (e) => {

      getRoutesList()


    });
    return () => document.removeEventListener("routeAdded", () => {

    });
  }, []);


  useEffect(() => {
    getRoutesList()
  }, [driverIdRoute, name, status, createdAt]);

  // useEffect(() => {
  //   (async () => {
  //     let Dispatcher = new DispatcherApi()
  //     let data = await Dispatcher.AutocompleteDriver()
  //   })();
  // }, []);

  const getRoutesList = useCallback(async (e) => {
    let storeData = store.getState();
    const driverIdRoutex = storeData.Route.filters.driverId
    const statusx = storeData.Route.filters.status
    const createdAtx = storeData.Route.filters.createdAt
    const namex = storeData.Route.filters.name

    let params = JSON.parse(JSON.stringify({
      status: statusx,
      createdAt: createdAtx,
      name: (namex && String(namex).length > 0) ? namex : null,
      driverId: driverIdRoutex?.id
    }))
    setLoading(true)
    let Route = new RouteApi()
    let data = await Route.getRoutesList(gtelteSetter(removeNullFields(params)))
    setRoutesList(data)
    setLoading(false)

  }, [status , createdAt , name , driverIdRoute]);


  const searchDriverOnchange = useCallback(async (e) => {
    setDriverListloading(true)
    let Dispatcher = new DispatcherApi()
    let data = await Dispatcher.AutocompleteDriver({ searchRegex: e ? e : "" })
    setDriverList(data)
    setDriverListloading(false)

  }, []);

  const debouncedSearchDriver = useCallback(
    debounce(searchDriverOnchange, 500)
    , []);

  const driverHandle = useCallback((e) => {

    dispatch(handleEditRouteFilter_Name({ key: "driverId", value: e }))

  }, []);

  function handleAddRote(e, route) {
    setShowAddRoute(e)
  }
  const canClearAll = React.useCallback(() => {
    return !_.isEqual(defRoute.filters, allFilters)
  }, [allFilters]);

  const ClearAll = React.useCallback(() => {
    dispatch(clearAll_Route_ActiveFilter())
  }, []);


  useEffect(() => {
    try {
        if (showAddRoute) {
          const element = document.getElementById('googlemapUniqeId');
          element.style.zIndex = '6';
        } else {
          const element = document.getElementById('googlemapUniqeId');
          element.style.zIndex = '7';
        }
    } catch (error) {
      
    }
  
  
    }, [showAddRoute]);

  return (
    <div className={styles.container}>
      {/* <RouteListNavbar /> */}

      

      <div className={styles.listMapContainer}>
        <div className={styles.listContainer}>
          <div className={styles.listfilters} ref={ref}>
            <DateFilter states={['Isbetween']} title='Date' placement='bottomStart' value={createdAt_data} onChange={createdAt_OnChange} />
            <CheckBoxFilter title='Status' placement='bottomStart' list={status_list} value={status_data} onChange={status_OnChange} />

            <InputSerachFilter value={driverIdRoute} title='Driver' SearchList_ListItemChildren={DriverListItem} SearchList_error={false} SearchList_errorDescription={""} SearchList_errorIcon={null} SearchList_errorTitle={""}
              SearchList_inputIcon={null} SearchList_isloading={DriverListloading} SearchList_list={DriverList} SearchList_listItemOnclick={(e) => { }} SearchList_noResult={!DriverList || DriverList.length == 0}
              SearchList_noResultIcon={SEARCH_NOT_FOUND} SearchList_noResultTitle={'No result found'} SearchList_onChange={(e) => { }} SearchList_placeHolder={"Search"} SearchList_ListItemOnClick={driverHandle} onChange={debouncedSearchDriver} SearchList_value={null} placement='bottomEnd'
            />
            {canClearAll() && <Typography onClick={ClearAll} weight='Body_Tiny_Bold' textColor='Primary' text='Clear All' style={{ cursor: "pointer" }} />}

          </div>
          <div className={styles.listItemsContainer} style={{ height: `calc(100% - ${height}px)`, marginTop: `${height}px` }}>

            {(routesList && routesList.length > 0 && !loading) && routesList?.map((route) => {
              return <Link to={`/route/${route.id}/0`}  > <div className={styles.routeItem}>
                <div className={clsx(styles.flag, styles[route.status])} />
                <div className={styles.twoRowsContainer}>
                  <div className={styles.firstRow}>
                    <Typography weight='Body_Middle_Medium' text={route.name} style={{ color: "#242424" }} />
                    <span style={{ marginInlineStart: "auto", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "start", gap: "6px" }}> <IconProvider Icon={box_gray} size='16' /> <Typography weight='Body_Small_Regular' text={String(route.stopCount)} style={{ color: "#242424" }} /></span>

                  </div>

                  <div className={styles.secRow}>
                    <Typography weight='Body_Small_Regular' text={route.status} className={styles[route.status + 'span']} />
                    {route.driver && <span style={{ marginInlineStart: "auto", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "start", gap: "6px" }}> <IconProvider Icon={DRIVER} /> <Typography weight='Body_Small_Regular' text={route.driver.name} style={{ color: "#242424" }} /></span>}
                  </div>
                </div>
              </div>
              </Link>
            })}

            {(!routesList || loading) && <ProgressIndicator theme='On_Light' className={styles.ProgressIndicator} />}
            {(routesList && !canClearAll() && routesList.length == 0 && !loading) && <NoRoutes reload={() => { setShowAddRoute(true) }} />}
            {(routesList && canClearAll() && routesList.length == 0 && !loading) && <NoRoutesFilters />}

          </div>
        </div>

  
      </div>
      {showAddRoute && <TableAddRoute onClose={handleAddRote} />}

    </div>
  )
}


export default RouteList



function NoRoutes({ reload, orderId, stop }) {

  return (
    <div className='jojojoj'
      style={{ display: 'flex', position: 'relative', height: `calc(100vh - 104px)`, width: "100%", flexDirection: 'column', transition: "all .3s ease-in-out", justifyContent: "center", alignItems: "center" }}>
      <div className={styles.dialog}>
        <IconProvider Icon={ROUTE_OPTIMIZE} style={{ marginBottom: "16px" }} />
        <Typography weight='Body_Middle_Bold' text='Add your routes' style={{ textAlign: "center", marginBottom: "4px" }} />
        <Typography weight='Body_Tiny_Regular' text='Your routes will be shown here in' style={{ textAlign: "center", marginBottom: "16px" }} />
        <Button size='Medium' type='Primary' text='Add Route' onClick={() => { reload(orderId, stop) }} />
      </div>
    </div>
  )
}


function NoRoutesFilters() {
  const dispatch = useDispatch();



  const ClearAll = React.useCallback(() => {
    dispatch(clearAll_Route_ActiveFilter())
  }, []);
  return (
    <div className='jojojoj'
      style={{ display: 'flex', position: 'relative', height: `calc(100vh - 104px)`, width: "100%", flexDirection: 'column', transition: "all .3s ease-in-out", justifyContent: "center", alignItems: "center" }}>
      <div className={styles.dialog}>
        <IconProvider Icon={Routes_deactive} style={{ marginBottom: "16px" }} />
        <Typography weight='Body_Middle_Bold' text='No route found' style={{ textAlign: "center", marginBottom: "4px" }} />
        <Typography weight='Body_Tiny_Regular' text='Please check your filters or keyword' style={{ textAlign: "center", marginBottom: "16px" }} />
        <Button size='Medium' type='Primary' text='Clear All' onClick={ClearAll} />
      </div>
    </div>
  )
}



function RoutesError({ reload, orderId, stop }) {

  return (
    <div className='jojojoj'
      style={{ display: 'flex', position: 'relative', height: `calc(100vh - 104px)`, width: "100%", flexDirection: 'column', transition: "all .3s ease-in-out", justifyContent: "center", alignItems: "center" }}>
      <div className={styles.dialog}>
        <IconProvider Icon={Ic_danger} style={{ marginBottom: "16px" }} />
        <Typography weight='Body_Middle_Bold' text='Error in loading data' style={{ textAlign: "center", marginBottom: "4px" }} />
        <Typography weight='Body_Tiny_Regular' text='There was a problem loading data. please try again or contact support' style={{ textAlign: "center", marginBottom: "16px" }} />
        <Button size='Medium' type='Primary' text='Try again' onClick={() => { reload(orderId, stop) }} />
      </div>
    </div>
  )
}
