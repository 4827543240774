import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import Slide from "@material-ui/core/Slide";
import arrow from "../../../../assets/arrow-triangle-down.svg";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import ic_pin_dark from "../../../../assets/ic_pin_dark.svg";
import { Set_New_Event } from "../../../../Redux/actions/actionNames";
import { OrderNewEvent_create_Action } from "../../../../Redux/actions/actions";
import { get_id } from "../../../../helper/UserData/userdate";
import styles from './style.module.css';
import backk from "../../../../assets/backk.svg";



class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      states:[
        'Updated',
        'Created',
        'Unassigned',
        'Assigned',
        'Started',
        'Arrived',
        'PickedUp',
        'OrderDone',
        'DropoffDone',
        'CustomerFeedback',
        'AddPod',
        'CustomerCanceled',
        'DriverCanceled',
        'SupportCanceled',
        'DriverAssignment',
        'DriverReject',
        'IncomingCall',
        'OutgoingCall',
        'DispatchOrder',
        'Draft',
        'Confirmed',
        'PickupRouted',
        'ReadyForPickup',
        'AtWarehouse',
        'DeliveryRouted',
        'ReadyForDelivery',
        'OutForDelivery',
        'Delivered',
        'NotDelivered',
        'PickupFailed',
        'Lost',
        'ForReturn',
        'Returned'
    ],
      statesphubd: [
        "Draft",
        "Confirmed",
        "PickupRouted",
        "ReadyForPickup",
        "PickedUp",
        "AtWarehouse",
        "DeliveryRouted",
        "ReadyForDelivery",
        "OutForDelivery",
        "Delivered",
        "NotDelivered",
        "ForReturn",
        "Returned",
        "CustomerCanceled",
        "PickupFailed",
        "SupportCanceled",
        "Lost",
      ],
      statesOld: [
        'Updated','Created',
        'Unassigned',
        'Assigned',
        'Started',
        'Arrived',
        'PickedUp',
        'OrderDone',
        'DropoffDone',
        'CustomerFeedback',
        'AddPod',
        'CustomerCanceled',
        'DriverCanceled',
        'SupportCanceled',
        'DriverAssignment',
        'DriverReject',
        'Collecting',
        'InTransit',
        'Delivering'],
      CustomerVisibility: false,
      description: ""
    }
  }

  handleCheckBox = () => {
    this.setState({
      CustomerVisibility: !this.state.CustomerVisibility,
    });
  }


  handleshowpoperOrderStatus = () => {
    this.setState({
      showpoperOrderStatus: !this.state.showpoperOrderStatus,
    });
  };


  handleOrderStatus = (item) => {
    this.setState({
      OrderStatus: item,
    });
  }


  selectLoaction = () => {
    if (this.props.LocationOnMap && this.props.loc) {
      this.props.dispatch(Set_New_Event({ mapLocation: null }))
    }
    this.props.dispatch(Set_New_Event({ canSelectLocation: !this.props.LocationOnMap }))
  }


  onback = () => {
    this.props.dispatch(Set_New_Event({ canSelectLocation: false }))
    this.props.showCreateDialoge()
    this.props.dispatch(Set_New_Event({ mapLocation: null }))

  }


  createNewEvent = () => {
    if (this.state.OrderStatus && this.state.description.length > 0) {
      this.props.dispatch(OrderNewEvent_create_Action(this.props.dispatch, {
        "dispatcherId": get_id(),
        "type": this.state.OrderStatus,
        "description": this.state.description,
        "isVisibleToCustomer": this.state.CustomerVisibility,
        "coordinates": this.props.loc ? [this.props.loc[1], this.props.loc[0]] : null
      }, this.props.orderId))
    }
  }


  descriptionONchange = (e) => {
    this.setState({
      description: e.target.value
    })
  }

  render() {
    return (
      <div >
        <div className={styles.EventTimeLine_Header}>
          <img onClick={this.onback} className={styles.close} src={backk} />

          <span className={styles.eventsTitle}> <Trans i18nKey={"CreateEvent"}> </Trans> </span>
        </div>

        <div className={styles.card}>
          <div
            ref={this.anchorEl}
            onClick={this.handleshowpoperOrderStatus}
            style={{
              boxShadow: this.state.showpoperOrderStatus
                ? "0 2px 24px 0 rgba(128, 128, 128, 0.24)"
                : "",
            }}
            className={styles.orderStateSelector}

          >
            {!this.state.showpoperOrderStatus &&
              <React.Fragment>
                <span className={styles.stateTitle}>
                  <Trans i18nKey={this.state.OrderStatus ? "event_" + this.state.OrderStatus : "PleaseSelect"}> </Trans>
                </span>
                <img
                  src={arrow}
                  className={`${styles.arrow} ${this.state.showpoperOrderStatus && "rotate180"
                    }`}
                />
                <span className={styles.title}>
                  <Trans i18nKey={"Title"}> </Trans>
                </span>
              </React.Fragment>
            }

            {this.state.showpoperOrderStatus ? (
              <ClickAwayListener
                onClickAway={() => this.handleshowpoperOrderStatus()}
              >
                <div>
                  {/*[...this.props.orderType == "PickupDelivery" ? this.state.statesphubd : this.state.statesOld]*/ this.state.states.map((item) => {
                    return (
                      <div className={styles.stateListItem}
                        onClick={() => this.handleOrderStatus(item)}
                      >
                        <span
                          style={{ fontWeight: "normal" }}
                          className={`${this.state.OrderStatus
                            ? this.state.OrderStatus == item
                              ? styles.xxx
                              : ""
                            : ""}`
                          }
                        >
                          <Trans i18nKey={"event_" + item}> </Trans>{" "}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </ClickAwayListener>
            ) : null}
          </div>


          <div className={styles.orderStatusDescriptionCV_Container}>

            <span className={styles.d15rp8}><Trans i18nKey={"Description"}> </Trans></span>
            <input className={styles.e2e834} placeholder={this.props.t("detailsAboutEvent")} onChange={this.descriptionONchange} />
            <input onClick={this.handleCheckBox} className={styles.che965} checked={this.state.CustomerVisibility} type="checkbox" /> <span onClick={this.handleCheckBox} className={styles.cviwdw5}><Trans i18nKey={"CustomerVisibility"}> </Trans> </span>

            <div style={{ borderTop: "1px solid #eee", width: "100%", float: 'left', marginTop: "15px" }}>

              <div className={`${styles.locationOnMap} ${this.props.LocationOnMap && styles.locationOnMapSelected}`} onClick={this.selectLoaction}>
                <img src={ic_pin_dark} />
                <span><Trans i18nKey={"LocationOnMap"}> </Trans></span>
              </div>

            </div>
          </div>
        </div>




        <span className={`${styles.CreateEventBTN} ${(!this.state.OrderStatus || this.state.description.length == 0) && styles.CreateEventBTNDA}`} onClick={this.createNewEvent}>
          <Trans i18nKey={"CreateEvent"}> </Trans>
        </span>


      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  showEventTimeLine: state.ViewTravel.eventTimeLine.show,
  LocationOnMap: state.ViewTravel.eventTimeLine.newEvent.canSelectLocation,
  loc: state.ViewTravel.eventTimeLine.newEvent.mapLocation,
  orderId: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.id
      ? state.ViewTravel.pureData.id
      : null
    : null,
  orderType: state.ViewTravel.pureData.type

});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
