import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import arrow from "../../../../../../../assets/chevron-down.svg";
import Collapse from "@material-ui/core/Collapse";
import { withTranslation, Trans } from "react-i18next";
import cancell from "../../../../../../../assets/ic-close.svg";
import icWarningEdit from "../../../../../../../assets/ic-warning-edit.svg";
import turnOn from "../../../../../../../assets/turn-on.svg";
import arrow2 from "../../../../../../../assets/arrow-triangle-down.svg";
import remove from "../../../../../../../assets/ic-remove.svg";
import bike from "../../../../../../../assets/bike.svg";
import driver from "../../../../../../../assets/ic-edit-driver.svg";
import { withStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import ListItem from "./driverOptions/list";
import SelectableItem from "./driverOptions/selectable";
import {
  Set_Auto_Driver_EditOrder,
  Set_Auto_ActiveVehicleType_EditOrder,
  Set_Manual_ActiveVehicleType_EditOrder,
  Set_searchDriverInput_EditOrder,
  Set_Edit_Order_DriverAndStatus,
  Add_Driver_Option_EditOrder,
  Reset_DriverAndStatus,
} from "../../../../../../../Redux/actions/actionNames";
import {
  GetVehicletypeByServiceAction,
  SearchDriverAction,
  Update_Order_Status_Action,
} from "../../../../../../../Redux/actions/actions";
import {
  canChangeStatus,
  getAvailableListChangeStatus,
  getBaseTrackByUrl,
  getCenterMap,
} from "../../../../../../../helper/module";
import close from "../../../../../../../assets/ic-close.svg";
import {
  get_id,
  get_lang,
} from "../../../../../../../helper/UserData/userdate";
import ManualVehicleType from "./manualVehicleType";
import Dialog from "@material-ui/core/Dialog";
import { isEmpty } from "lodash";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import { snack } from "../../../../../../../utils/SnackbarUtilsConfigurator";

const BackdropCustom = withStyles({
  backdrop: {
    zIndex: 5000,
    backgroundColor: "rgba(0, 0, 0, 0)",
    color: "#00000",
  },
})((props) => (
  <Backdrop
    {...props}
    style={{
      zIndex: 5000,
      backgroundColor: "rgba(0, 0, 0, 0)",
      color: "#00000",
    }}
  />
));

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ShowDialogeVehicleChanged: false,
      id: "driver",
      showpoperAuto: false,
      showpoperManual: false,
      list: [0, 1, 2, 3, 5, 6, 9, 5, 8],
      loading: false,
      ordered: false,
      Auto: true,
      showEditStatusPoper: false,
      idd: this.idgenerator(),
      openEditDriver: false,
      requested: false,
    };
    this.anchorElAuto = React.createRef();
    this.anchorElManual = React.createRef();
    this.anchorElStatus = React.createRef();
  }

  idgenerator = () => {
    var number = Math.random(); // 0.9394456857981651
    number.toString(36); // '0.xtis06h6'
    var id = number.toString(36).substr(2, 9); // 'xtis06h6'
    return id.toString();
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.loading != this.props.loading) {
      nextProps.dispatch(Reset_DriverAndStatus());
    }

    // if (nextProps.openEditDriver) {
    //   this.componentDidMount();
    // }
  }

  componentDidMount() {
    let center = getCenterMap(this.props.mapRef , this.props.center)

    this.props.dispatch(
      GetVehicletypeByServiceAction(
        this.props.dispatch,
        {
          latitude: this.props.pickupCoordinates
            ? this.props.pickupCoordinates[1]
            : center.lat,
          longitude: this.props.pickupCoordinates
            ? this.props.pickupCoordinates[0]
            : center.lng,
          serviceId: this.props.service,
        },
        (vt) => {}
      )
    );
    // this.props.setId(this.state.id);
    this.setVehicleTypeFromPureData();
    this.props.OrderPureData &&
      (!this.isEmpty(this.props.OrderPureData.driver) ||
        !this.isEmpty(this.props.OrderPureData.currentCandidateDrivers)) &&
      this.MustsetDriverDataFromPureData();

    this.initStatus();
  }

  initStatus = () => {
    if (!this.props.EditSaDStatusText) {
      let vehicleType = getAvailableListChangeStatus(null, this.props.status);

      if (vehicleType.length != 0) {
        if (vehicleType[0].MustsetDriver) {
          this.props.dispatch(Set_Auto_Driver_EditOrder(false));
        }
        this.onChangeDriverStatus({
          ...vehicleType[0],
          i18: "EDAS_" + this.props.status,
          status: this.props.status,
          from: this.props.status,
          to: this.props.status,
          MustsetDriver: !!vehicleType[0].MustsetDriver,
        });
      }
    }
  };

  idgenerator = () => {
    var number = Math.random(); // 0.9394456857981651
    number.toString(36); // '0.xtis06h6'
    var id = number.toString(36).substr(2, 9); // 'xtis06h6'
    return id.toString();
  };

  handleshowpoperAuto = () => {
    if (this.props.auto_suggestListData.length > 0) {
      this.setState({
        showpoperAuto: !this.state.showpoperAuto,
      });
    } else {
      if (this.props.auto_suggestListLoading) {
        snack.warning_i18("poperLoading")

      } else {
        snack.warning_i18("noVehicleType")
   
      }
    }
  };

  handleshowpoperManual = () => {
    this.setState({
      showpoperManual: !this.state.showpoperManual,
    });
    this.SearchDriverAction();
  };

  handleAutoManual = () => {
    this.props.dispatch(Set_Auto_Driver_EditOrder(!this.props.auto));
  };

  handleAutoVehicleTypeSelect = (service) => {
    this.props.dispatch(Set_Auto_ActiveVehicleType_EditOrder(service));
  };

  saveChange = () => {
    let vehicleTypeId = this.props.auto
      ? this.props.auto_activeVehicleType
        ? this.props.auto_activeVehicleType.id
        : null
      : this.props.manual_activeVehicleType
      ? this.props.manual_activeVehicleType.id
      : null;

    if (this.pendingManualCheck()) {
      this.sendRequest();
    } else if (this.props.OrderPureData.vehicleType.id != vehicleTypeId) {
      this.setState({
        ShowDialogeVehicleChanged: true,
      });
    } else {
      this.sendRequest();
    }
  };

  pendingManualCheck = () => {
    if (this.props.EditSaDStatusText) {
      if (this.props.EditSaDStatusText.status == "Pending") {
        if (!this.props.auto) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  async handleManualVehicleTypeSelect(service) {
    this.FindLocalVehicletype(
      service.vehicleTypes,
      this.props.auto_suggestListData,
      (call) => {
        if (call) {
          let manual_activeVehicleType = null;

          if (this.props.manual_activeVehicleType) {
            manual_activeVehicleType = this.props.manual_activeVehicleType;
          } else {
            if (call.length != 0) {
              manual_activeVehicleType = call[0];
            } else {
              snack.warning_i18("DriverVehicleTypeError")
            
            }
          }

          manual_activeVehicleType &&
            this.props.dispatch(
              Set_Manual_ActiveVehicleType_EditOrder({
                manual_activeVehicleType: manual_activeVehicleType,
                driverAllvehicletypes: call,
                manual_name: service.name,
                manual_number: service.phone,
                manual_id: service.id,
                manual_code: service.username,
              })
            );
        }
      }
    );
  }

  InputOnChange = (e) => {
    this.props.dispatch(
      Set_Manual_ActiveVehicleType_EditOrder({
        [e.target.name.split("-")[0]]: e.target.value,
        manual_id: null,
        searchDriverInput: e.target.value,
        driverAllvehicletypes: [],
      })
    );

    if (!this.state.showpoperManual) {
      this.setState({
        showpoperManual: true,
      });
    }

    this.SearchDriverAction(e.target.value);
  };

  SearchDriverAction = (input) => {
    this.props.dispatch(
      SearchDriverAction(
        this.props.dispatch,
        {
          searchRegex: input ? input : this.props.searchDriverInput,
          vehicleTypes: this.props.manual_activeVehicleType
            ? this.props.manual_activeVehicleType.id
            : "",
        },
        "Edit"
      )
    );
  };

  FindLocalVehicletype = (driverVehicletypes, localservives, call) => {
    let vehicletype = [];

    driverVehicletypes.forEach((element) => {
      localservives.forEach((localservive) => {
        if (element == localservive.id) {
          vehicletype.push(localservive);
        }
      });
    });

    call(vehicletype);
  };

  reMustsetDriver = () => {
    this.props.dispatch(
      Set_Manual_ActiveVehicleType_EditOrder({
        driverAllvehicletypes: [],
        searchDriverInput: "",
        manual_activeVehicleType: null,
        manual_name: "",
        name: "",
        manual_family: "",
        manual_number: "",
        manual_id: "",
        manual_code: "",
      })
    );
  };

  InputOnChangeManual = (e) => {
    this.props.dispatch(Set_searchDriverInput_EditOrder(e.target.value));
  };

  onChangeOpenDialoge = () => {
    this.state.openEditDriver &&
      this.props.onClickAway &&
      this.props.onClickAway();
    this.state.openEditDriver && this.props.dispatch(Reset_DriverAndStatus());
    this.setState({
      openEditDriver: !this.state.openEditDriver,
    });
    this.setVehicleTypeFromPureData();
  };

  setVehicleTypeFromPureData = () => {
    let vehicleType = {};
    this.props.OrderPureData &&
      this.props.auto_suggestListData.forEach((vehicle) => {
        if (vehicle.id == this.props.OrderPureData.vehicleType.id) {
          vehicleType = vehicle;
        }
      });
    this.props.dispatch(
      Set_Auto_ActiveVehicleType_EditOrder({
        icon: this.props.OrderPureData.vehicleType.icon,
        id: this.props.OrderPureData.vehicleType.id,
        options: [],
        title: this.props.OrderPureData.vehicleType.title,
        ...vehicleType,
      })
    );

    !this.isEmpty(vehicleType) &&
      this.setVehicleTypeOptionsFromPureData(vehicleType);
  };

  checkDefaultStatusBeforeChangeStatus = () => {
    if (
      [
        "Unassigned",
        "CustomerCanceled",
        "DriverCanceled",
        "SupportCanceled",
        "Unassigned",
      ].indexOf(this.props.status) >= 0
    ) {
      return false;
    }
    return true;
  };

  setVehicleTypeOptionsFromPureData = (vehicleType) => {
    this.props.OrderPureData &&
      this.props.OrderPureData.vehicleType.options.forEach((Pureoption) => {
        vehicleType.options.forEach((option) => {
          if (Pureoption.id == option.id) {
            this.props.dispatch(
              Add_Driver_Option_EditOrder({
                name: null,
                id: option.id,
                item: Pureoption.dataId
                  ? { id: Pureoption.dataId, title: option.dataTitle }
                  : null,
                type: Pureoption.dataId ? "list" : "boolean",
              })
            );
          }
        });
      });
  };

  MustsetDriverDataFromPureData = () => {
    // this.FindLocalVehicletype(
    //   this.vehicleTypes,
    //   this.props.auto_suggestListData,
    //   (call) => {
    //     if (call) {
    //       
    if (this.props.auto) {
      this.props.dispatch(Set_Auto_Driver_EditOrder(false));
    }
    this.finderDriverVehicleType(
      this.props.OrderPureData.vehicleType.id,
      (call) => {
        this.props.dispatch(
          Set_Manual_ActiveVehicleType_EditOrder({
            manual_activeVehicleType: call,
            driverAllvehicletypes: [],
            ...this.getDriverInfoFromPureDataDriverOrCandidate(),
          })
        );
      }
    );

    //     }
    //   }
    // );
  };

  getDriverInfoFromPureDataDriverOrCandidate = () => {
    if (!this.isEmpty(this.props.OrderPureData.driver)) {
      return {
        manual_name: this.props.OrderPureData.driver.name,
        manual_number: this.props.OrderPureData.driver.phone,
        manual_id: this.props.OrderPureData.driverId,
        manual_code: this.props.OrderPureData.driver.username,
      };
    } else if (!this.isEmpty(this.props.OrderPureData.currentCandidateDrivers)) {
      return {
        manual_name: this.props.OrderPureData.currentCandidateDrivers[0].name,
        manual_number: this.props.OrderPureData.currentCandidateDrivers[0].phone,
        manual_id: this.props.OrderPureData.currentCandidateDrivers[0].driverId,
        manual_code: this.props.OrderPureData.currentCandidateDrivers[0].username,
      };
    }
  };

  finderDriverVehicleType = (id, call) => {
    this.props.auto_suggestListData.forEach((localservive) => {
      if (id == localservive.id) {
        call(localservive);
      }
    });
  };

  onChangeOpenStatus = () => {
    this.setState({
      showEditStatusPoper: !this.state.showEditStatusPoper,
    });
  };

  onChangeDriverStatus = (item) => {
    this.props.dispatch(Set_Edit_Order_DriverAndStatus(item));
  };

  getEditSaDStatusText = () => {
    if (this.props.EditSaDStatusText) {
      return this.props.t(this.props.EditSaDStatusText.i18);
    } else {
      return this.props.t("state" + this.props.status);
    }
  };

  isCurrentStatus = (status) => {
    return this.props.status == status;
  };

  isCurrentStatusEdited = (status) => {
    return this.props.EditSaDStatusText == status;
  };

  showEditStatusError = () => {
    if (this.props.EditSaDStatusText) {
      if (
        // ["Assigned", "PickedUp","Started"].indexOf(this.props.EditSaDStatusText.status) >
        // -1
        this.props.EditSaDStatusText.MustsetDriver
      ) {
        return this.props.auto
          ? true
          : this.props.manual_id == "" || this.props.manual_id == null
          ? true
          : false;
      }
    } else {
      return false;
    }
  };

  isEmpty(obj) {
    return !obj ? true : Object.keys(obj).length === 0;
  }

  CanSaveChange() {
    if (this.props.EditSaDStatusText) {
      if (this.props.EditSaDStatusText.MustsetDriver) {
        if (
          this.props.manual_activeVehicleType &&
          this.props.manual_id != "" &&
          this.props.manual_id != null
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        if (
          typeof this.props.EditSaDStatusText.NotshowDriverPanel !=
            "undefined" &&
          this.props.EditSaDStatusText.NotshowDriverPanel
        ) {
          return true;
        } else if (
          typeof this.props.EditSaDStatusText.SendDriverOptional !=
            "undefined" &&
          this.props.EditSaDStatusText.SendDriverOptional
        ) {
          return true;
        } else {
          if (this.props.auto) {
            if (this.props.auto_activeVehicleType) {
              return true;
            } else {
              return false;
            }
          } else {
            if (
              this.props.manual_activeVehicleType &&
              this.props.manual_id != "" &&
              this.props.manual_id != null
            ) {
              return true;
            } else {
              return false;
            }
          }
        }
      }
    } else {
      return false;
    }

    // if (
    //   this.props.EditSaDStatusText &&
    //   typeof this.props.EditSaDStatusText.NotshowDriverPanel != "undefined"
    // ) {
    //   
    //   return this.props.EditSaDStatusText.NotshowDriverPanel ? true : false;
    // } else {
    //   if (this.showEditStatusError()) {
    //     
    //     return false;
    //   } else {
    //     if (this.props.auto) {
    //       if (this.props.auto_activeVehicleType) {
    //         
    //         return true;
    //       } else {
    //         
    //         return false;
    //       }
    //     } else {
    //       if (
    //         this.props.manual_activeVehicleType &&
    //         this.props.manual_id != "" &&
    //         this.props.manual_id != null
    //       ) {
    //         
    //         return true;
    //       } else {
    //         
    //         return false;
    //       }
    //     }
    //   }
    // }
  }

  sendRequest = () => {
    this.setState({
      requested: true,
    });

    let driverVehicle =
      this.props.EditSaDStatusText &&
      (this.props.EditSaDStatusText.MustsetDriver ||
        (this.props.EditSaDStatusText.SendV_D &&
          typeof this.props.EditSaDStatusText.SendV_D != "undefined") ||
        (typeof this.props.EditSaDStatusText.SendDriverOptional !=
          "undefined" &&
          this.props.EditSaDStatusText.SendDriverOptional))
        ? {
            driverId: this.props.auto
              ? null
              : isEmpty(this.props.manual_id)
              ? null
              : this.props.manual_id,
            vehicleType: {
              id: this.props.auto
                ? this.props.auto_activeVehicleType
                  ? this.props.auto_activeVehicleType.id
                  : this.props.OrderPureData.vehicleType.id
                : this.props.manual_activeVehicleType
                ? this.props.manual_activeVehicleType.id
                : this.props.OrderPureData.vehicleType.id,
              options: this.props.options.map((op) => {
                return { id: op.id, dataId: op.item ? op.item.id : "" };
              }),
            },
          }
        : {};

    this.props.dispatch(
      Update_Order_Status_Action(
        this.props.dispatch,
        {
          id: this.props.OrderPureData.id,
          data: {
            status: this.props.EditSaDStatusText
              ? this.props.EditSaDStatusText.status
              : this.props.status,
            dispatcherId: get_id(),
            driverAssignmentModel: this.props.auto ? "automatic" : "manual",
            ...driverVehicle,
          },
        },
        (call) => {
          this.setState({
            request: call.request,
            openEditDriver: call.open,
          });
        }
      )
    );
  };

  showChangeOptions = () => {
    // if (this.props.OrderPureData.vehicleType.id != vehicleTypeId) {
    //   this.setState({
    //     ShowDialogeVehicleChanged: true,
    //   });
    // }

    if (this.props.EditSaDStatusText) {
      let canShowChnageOptionError = !!this.props.EditSaDStatusText
        .canShowChnageOptionError;
      let vehicleTypeId = this.props.auto
        ? this.props.auto_activeVehicleType
          ? this.props.auto_activeVehicleType.id
          : null
        : this.props.manual_activeVehicleType
        ? this.props.manual_activeVehicleType.id
        : null;
      if (
        vehicleTypeId != null &&
        !canShowChnageOptionError &&
        this.props.OrderPureData.vehicleType.id != vehicleTypeId
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }

    return this.props.EditSaDStatusText
      ? this.props.EditSaDStatusText.canShowChnageOptionError
        ? this.props.auto
          ? true
          : !isEmpty(this.props.manual_id) &&
            this.props.manual_activeVehicleType
        : false
      : this.props.auto
      ? true
      : !isEmpty(this.props.manual_id) && this.props.manual_activeVehicleType;
  };

  checkMustsetDriver = () => {
    if (this.props.EditSaDStatusText) {
      if (this.props.EditSaDStatusText.MustsetDriver) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  SelectRule = (item) => {
    this.onChangeDriverStatus({
      i18: "EDAS_" + item.to,
      status: item.to,
      MustsetDriver: item.MustsetDriver,
      ...item,
    });
    item.MustsetDriver && this.props.dispatch(Set_Auto_Driver_EditOrder(false));
  };

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        {canChangeStatus(this.props.status,this.props.OrderPureData.type ) && (
          <thdotItem onClick={this.onChangeOpenDialoge}>
            {" "}
            <img src={driver} className="thdotItemdriver" />{" "}
            <span>
              {" "}
              <Trans i18nKey={"StatusandDriver"}> </Trans>
            </span>
          </thdotItem>
        )}

        <Dialog
          style={{}}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          open={this.state.openEditDriver}
          onClose={this.onChangeOpenDialoge}
          disableAutoFocus
          disableEnforceFocus 
          disableRestoreFocus 
        >
          <div className="EditDriverContainer">
            <div className="header">
              <img
                className="close"
                src={cancell}
                onClick={this.onChangeOpenDialoge}
              />
              <span className="title">
                {this.props.t("EditStatusandDriver")}
              </span>
            </div>

            <span className="status">{this.props.t("status")}:</span>

            <div
              className="EditSaDStatusContainer"
              ref={this.anchorElStatus}
              onClick={this.onChangeOpenStatus}
            >
              <span> {this.getEditSaDStatusText()}</span>
              <img className="EditSaDStatusimgRotatex" src={arrow2} />
            </div>
            {this.showEditStatusError() && (
              <span className="EditSaDStatusError">
                {" "}
                <Trans i18nKey={"Pleasechooseadrivertochangestatus"}>
                  {" "}
                </Trans>{" "}
              </span>
            )}
            <BackdropCustom
              open={this.state.showEditStatusPoper}
              onClick={this.onChangeOpenStatus}
            >
              <Popper
                style={{
                  width: "230px",
                  zIndex: "1300",
                  marginTop: "0px",
                  maxHeight: "185px",
                }}
                placement={"bottom-start"}
                id={"passenger"}
                open={this.state.showEditStatusPoper}
                anchorEl={this.anchorElStatus.current}
              >
                <Paper
                  style={{
                    width: "230px",
                    float: "right",
                    borderRadius: "8px",
                    paddingTop: "5px",
                    marginTop: "10px",
                    paddingBottom: "5px",
                    right: "271px",
                    maxHeight: "185px",
                    overflowY: "auto",
                    overflowX: "hidden",
                  }}
                  className="hideScrollbar"
                  elevation={3}
                >
                  {getAvailableListChangeStatus(
                    this.props.EditSaDStatusText
                      ? this.props.EditSaDStatusText.status
                      : this.props.status,
                    null,
                    this.props.status
                  ).map((item) => {
                    return (
                      <div
                        onClick={() => {
                          this.SelectRule(item);
                        }}
                        className={`EditSaDStatusItem ${
                          this.isCurrentStatusEdited(item.to) &&
                          "EditSaDStatusItemAI"
                        }`}
                      >
                        <Trans i18nKey={"EDAS_" + item.to}> </Trans>
                        {this.isCurrentStatus(item.to) && (
                          <span>
                            {" "}
                            <Trans i18nKey={"Current"}> </Trans>{" "}
                          </span>
                        )}
                      </div>
                    );
                  })}
                  {/* <div
                    onClick={() => {
                      this.onChangeDriverStatus({
                        i18: "EDAS_Pending",
                        status: "Pending",
                      });
                    }}
                    className={`EditSaDStatusItem ${
                      this.isCurrentStatusEdited("Pending") &&
                      "EditSaDStatusItemAI"
                    }`}
                  >
                    <Trans i18nKey={"EDAS_Pending"}> </Trans>
                    {this.isCurrentStatus("Pending") && (
                      <span>
                        {" "}
                        <Trans i18nKey={"Current"}> </Trans>{" "}
                      </span>
                    )}
                  </div>

                  <div
                    onClick={() => {
                      this.onChangeDriverStatus({
                        i18: "EDAS_Assigned",
                        status: "Assigned",
                      });
                    }}
                    className={`EditSaDStatusItem ${
                      this.isCurrentStatusEdited("Assigned") &&
                      "EditSaDStatusItemAI"
                    }`}
                  >
                    <Trans i18nKey={"EDAS_Assigned"}> </Trans>
                    {this.isCurrentStatus("Assigned") && (
                      <span>
                        {" "}
                        <Trans i18nKey={"Current"}> </Trans>{" "}
                      </span>
                    )}
                  </div>

                  <div
                    onClick={() => {
                      this.onChangeDriverStatus({
                        i18: "EDAS_PickedUp",
                        status: "PickedUp",
                      });
                    }}
                    className={`EditSaDStatusItem ${
                      this.isCurrentStatusEdited("PickedUp") &&
                      "EditSaDStatusItemAI"
                    }`}
                  >
                    <Trans i18nKey={"EDAS_PickedUp"}> </Trans>
                    {this.isCurrentStatus("PickedUp") && (
                      <span>
                        {" "}
                        <Trans i18nKey={"Current"}> </Trans>{" "}
                      </span>
                    )}
                  </div>*/}
                </Paper>
              </Popper>
            </BackdropCustom>

            <div className="content">
              {(this.props.EditSaDStatusText
                ? !this.props.EditSaDStatusText.NotshowDriverPanel
                : this.checkDefaultStatusBeforeChangeStatus()) && (
                <passerngerContainer
                  style={{
                    boxShadow: "rgba(128, 128, 128, 0.16) 0px 1px 6px 0px",
                  }}
                  className="serviceCustomized"
                >
                  <drivertitle style={{ cursor: "unset" }}>
                    <span>
                      {" "}
                      <Trans i18nKey={"driver"}> </Trans>{" "}
                    </span>
                    {!this.checkMustsetDriver() && (
                      <personBusinessSwitchContainer className="driverAMswitch">
                        <detail
                          id="details"
                          onClick={this.handleAutoManual}
                          className={`${
                            this.props.auto ? "details" : "DEactive"
                          }`}
                        >
                          <Trans i18nKey={"Auto"}> </Trans>
                        </detail>
                        <history
                          id="history"
                          onClick={this.handleAutoManual}
                          className={`${
                            !this.props.auto ? "details" : "DEactive"
                          }`}
                        >
                          <Trans i18nKey={"Manual"}> </Trans>
                        </history>
                      </personBusinessSwitchContainer>
                    )}
                  </drivertitle>

                  <div className="pickupcollapsecontainer">
                    {this.props.auto && !this.checkMustsetDriver() && (
                      <React.Fragment>
                        <autodriverselector
                          ref={this.anchorElAuto}
                          onClick={this.handleshowpoperAuto}
                        >
                          {this.props.auto_activeVehicleType &&
                          this.props.auto_suggestListData.length > 0 ? (
                            <React.Fragment>
                              <img
                                src={this.props.auto_activeVehicleType.icon}
                                className="serviceimage"
                              />
                              <servicename>
                                {this.props.auto_activeVehicleType.title}
                              </servicename>
                            </React.Fragment>
                          ) : (
                            <servicename style={{ marginLeft: "19px" }}>
                              {" "}
                              <Trans i18nKey={"pleaseSellectParsel"}> </Trans>
                            </servicename>
                          )}

                          <img
                            src={arrow2}
                            className={` servicearrow ${
                              this.state.showpoperAuto && "rotate180"
                            }`}
                          />
                        </autodriverselector>
                        <serviceOptions
                          className={`${
                            this.props.auto_activeVehicleType &&
                            this.props.auto_activeVehicleType.options.length ==
                              0 &&
                            "displayNone"
                          }`}
                          style={{ marginLeft: "0px", width: "100%" }}
                        >
                          {this.props.auto_activeVehicleType &&
                            this.props.auto_activeVehicleType.options.map(
                              (item) => {
                                return item.type === "list" ? (
                                  <ListItem {...item} />
                                ) : (
                                  <SelectableItem {...item} />
                                );
                              }
                            )}
                        </serviceOptions>
                      </React.Fragment>
                    )}

                    {(!this.props.auto || this.checkMustsetDriver()) && (
                      <div>
                        {(this.props.manual_id == "" ||
                          this.props.manual_id == null) && (
                          <div class="drivermanualContainer">
                            <ManualVehicleType />
                            <input
                              onChange={this.InputOnChange}
                              ref={this.anchorElManual}
                              value={this.props.searchDriverInput}
                              name={"manual_code-" + this.state.idd}
                              autocomplete="z1x2wd"
                              onClick={this.handleshowpoperManual}
                              className="driverCode"
                              placeholder={t("CodeNameFamilyPhonenumber")}
                            />
                          </div>
                        )}
                        {this.props.manual_id != "" &&
                          this.props.manual_id != null && (
                            <React.Fragment>
                              <div
                                className="driverInfoManualContainer"
                                style={{ width: "324px" }}
                              >
                                <img
                                  src={remove}
                                  onClick={this.reMustsetDriver}
                                />
                                <div className="namePhoneContainer">
                                  <p className="npcName">
                                    {" "}
                                    {`${this.props.manual_name}`}{" "}
                                  </p>
                                  <p className="npcPhone">
                                    {" "}
                                    {`${this.props.manual_number} `}{" "}
                                  </p>
                                </div>

                                <div className="namePhoneContainer usernameContainer">
                                  <p className="npcName">
                                    {" "}
                                    {`${this.props.manual_code}`}{" "}
                                  </p>
                                  <p className="npcPhone">
                                    <Trans i18nKey={"Username"}> </Trans>{" "}
                                  </p>
                                </div>
                              </div>
                              {this.props.manual_activeVehicleType && (
                                <manualDriverActiveServic>
                                  {" "}
                                  <img
                                    src={
                                      this.props.manual_activeVehicleType.icon
                                    }
                                    className="serviceimage"
                                  />{" "}
                                  <span>
                                    {" "}
                                    {
                                      this.props.manual_activeVehicleType.title
                                    }{" "}
                                  </span>
                                </manualDriverActiveServic>
                              )}
                              <serviceOptions
                                style={{ marginLeft: "0px", width: "100%" }}
                              >
                                {this.props.manual_activeVehicleType &&
                                  this.props.manual_activeVehicleType.options.map(
                                    (item) => {
                                      return item.type === "list" ? (
                                        <ListItem {...item} />
                                      ) : (
                                        <SelectableItem {...item} />
                                      );
                                    }
                                  )}
                              </serviceOptions>
                            </React.Fragment>
                          )}{" "}
                      </div>
                    )}
                  </div>

                  <BackdropCustom
                    open={
                      this.state.showpoperManual &&
                      this.props.manual_suggestListData.length > 0
                    }
                    onClick={this.handleshowpoperManual}
                  >
                    <Popper
                      style={{
                        width: "355px",
                        zIndex: "1500",
                        marginTop: "0px",
                        maxHeight: "150x",
                      }}
                      placement={"bottom-end"}
                      id={"passenger"}
                      open={
                        this.state.showpoperManual &&
                        this.props.manual_suggestListData.length > 0
                      }
                      anchorEl={this.anchorElManual.current}
                    >
                      <Paper
                        style={{
                          width: "355px",
                          float: "right",
                          borderRadius: "8px",
                          paddingTop: "5px",
                          marginTop: "10px",
                          paddingBottom: "5px",
                          right: "271px",
                          maxHeight: "150px",
                          overflowY: "scroll",
                          overflowX: "hidden",
                        }}
                        elevation={3}
                      >
                        {!this.props.manual_suggestListLoading &&
                          this.props.manual_suggestListData.length !== 0 &&
                          this.props.manual_suggestListData.map((item) => {
                            return (
                              <div
                                className="DriverListItemContainer"
                                onClick={() => {
                                  this.handleManualVehicleTypeSelect(item);
                                }}
                              >
                                <span className="DriverListItemName">
                                  {/* {`${item.givenName} ${item.familyName}`} */}
                                  {`${item.name}`}
                                </span>
                                <span className="DriverListItemNumber">
                                  {" "}
                                  {item.phone}{" "}
                                </span>
                                <span className="DriverListItemCode">
                                  {" "}
                                  {item.username}{" "}
                                </span>
                              </div>
                            );
                          })}

                        {this.props.manual_suggestListLoading && (
                          <div>
                            <span
                              className="NotravelsListHeader"
                              style={{ marginTop: "12px" }}
                            >
                              <Trans i18nKey={"poperLoading"}> </Trans>
                            </span>
                            <span className="NotravelsListText">
                              <Trans i18nKey={"fetchingdata"}> </Trans>
                            </span>
                          </div>
                        )}

                        {!this.props.manual_suggestListLoading &&
                          this.props.manual_suggestListOrdered &&
                          this.props.manual_suggestListData.length === 0 && (
                            <div>
                              <span
                                className="NotravelsListHeader"
                                style={{ marginTop: "12px" }}
                              >
                                <Trans i18nKey={"Noinformationfound"}> </Trans>
                              </span>
                              <span className="NotravelsListText">
                                <Trans i18nKey={"Pleasetryanotherkeyword"}>
                                  {" "}
                                </Trans>
                              </span>
                            </div>
                          )}
                      </Paper>
                    </Popper>
                  </BackdropCustom>

                  <BackdropCustom
                    open={this.state.showpoperAuto}
                    onClick={this.handleshowpoperAuto}
                  >
                    <Popper
                      style={{
                        width: "475px",
                        zIndex: "1300",
                        marginTop: "0px",
                        maxHeight: "150x",
                      }}
                      placement={"bottom"}
                      id={"passenger"}
                      open={this.state.showpoperAuto}
                      anchorEl={this.anchorElAuto.current}
                    >
                      <Paper
                        style={{
                          width: "476px",
                          float: "right",
                          borderRadius: "8px",
                          paddingTop: "5px",
                          marginTop: "10px",
                          paddingBottom: "5px",
                          right: "271px",
                          maxHeight: "150px",
                          overflowY: "scroll",
                          overflowX: "hidden",
                        }}
                        elevation={3}
                      >
                        {this.props.auto_suggestListData &&
                          this.props.auto_suggestListData.map((item) => {
                            return (
                              <autodriverselector
                                className="autodriverselectorInList"
                                onClick={() => {
                                  this.handleAutoVehicleTypeSelect(item);
                                }}
                              >
                                <img src={item.icon} className="serviceimage" />
                                <servicename>{item.title}</servicename>
                              </autodriverselector>
                            );
                          })}
                      </Paper>
                    </Popper>
                  </BackdropCustom>
                </passerngerContainer>
              )}

              <div className="footer">
                {this.showChangeOptions() && (
                  <React.Fragment>
                    <img src={icWarningEdit} className="icWarningEditImg" />
                    <span className="icWarningEditSpan">
                      {" "}
                      <Trans i18nKey={"icWarningEdit"}> </Trans>{" "}
                    </span>
                  </React.Fragment>
                )}
                <sendOrder
                  className={`saveChangeEditDriver ${
                    !this.CanSaveChange() && "grey"
                  }`}
                  style={{ marginTop: "17px" }}
                  onClick={this.CanSaveChange() && this.saveChange}
                >
                  <Trans i18nKey={"EditOrder"}> </Trans>
                </sendOrder>
              </div>
            </div>
          </div>
        </Dialog>

        <Dialog
          style={{}}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          open={this.state.ShowDialogeVehicleChanged}
          onClose={() => {
            this.setState({
              ShowDialogeVehicleChanged: false,
            });
          }}
          disableAutoFocus
          disableEnforceFocus 
          disableRestoreFocus 
        >
          {" "}
          <p className="VehicleTypeChngedTitle">
            <Trans i18nKey="Vehicletypewillbechanged"> </Trans>
          </p>
          <DialogContent>
            <DialogContentText
              className="VehicleTypeChngedDescription"
              id="alert-dialog-description"
            >
              <Trans i18nKey="Yourcurrentvehicletypeanditwillbechangedandeffectsonprice">
                {" "}
              </Trans>
            </DialogContentText>
          </DialogContent>
          <div className="VehicleTypeChngedButtonsContainer">
            <Button
              onClick={() => {
                this.setState({
                  ShowDialogeVehicleChanged: false,
                });
              }}
              className="VehicleTypeChngedButton VehicleTypeChngedButton2"
            >
              <Trans i18nKey="End"> </Trans>
            </Button>

            <Button
              onClick={this.sendRequest}
              className="VehicleTypeChngedButton"
            >
              <Trans i18nKey="confirm"> </Trans>
            </Button>
          </div>
        </Dialog>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
 mapRef: state.Map.mapRef,
  center: state.Map.center,
  service: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.service
      ? state.ViewTravel.pureData.service.id
      : ""
    : "",

  options: state.ViewTravel.DriverAndStatus.options,
  loading: state.ViewTravel.loading,

  status: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.status
      ? state.ViewTravel.pureData.status
      : "new"
    : "new",

  driver: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.driver
      ? state.ViewTravel.pureData.driver
      : null
    : null,
  OrderPureData: state.ViewTravel.pureData ? state.ViewTravel.pureData : null,
  EditSaDStatusText: state.ViewTravel.DriverAndStatus.EditSaDStatusText,
  auto: state.ViewTravel.DriverAndStatus.auto,
  auto_activeVehicleType:
    state.ViewTravel.DriverAndStatus.auto_driver.auto_activeVehicleType,
  auto_suggestListLoading:
    state.NewOrder.newOrder.driver.auto_driver.auto_suggestListLoading,
  auto_suggestListData:
    state.NewOrder.newOrder.driver.auto_driver.auto_suggestListData,

  manual_activeVehicleType:
    state.ViewTravel.DriverAndStatus.manual_driver.manual_activeVehicleType,
  manual_name: state.ViewTravel.DriverAndStatus.manual_driver.manual_name,
  manual_code: state.ViewTravel.DriverAndStatus.manual_driver.manual_code,
  manual_family: state.ViewTravel.DriverAndStatus.manual_driver.manual_family,
  manual_number: state.ViewTravel.DriverAndStatus.manual_driver.manual_number,
  manual_id: state.ViewTravel.DriverAndStatus.manual_driver.manual_id,
  manual_suggestListLoading:
    state.EditOrder.editOrder.driver.manual_driver.manual_suggestListLoading,
  manual_suggestListData:
    state.EditOrder.editOrder.driver.manual_driver.manual_suggestListData,
  manual_suggestListOrdered:
    state.ViewTravel.DriverAndStatus.manual_driver.manual_suggestListOrdered,
  activePickupDropOff: state.NewOrder.newOrder.activePickupDropOff,
  lang: state.LocalData.lang,
  forceAssaigning: state.ViewTravel.DriverAndStatus.forceAssaigning,
  searchDriverInput:
    state.ViewTravel.DriverAndStatus.manual_driver.searchDriverInput,
  pickupCoordinates: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.pickup
      ? state.ViewTravel.pureData.pickup.coordinates
      : null
    : null,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
