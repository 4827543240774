import React from "react";
import { Marker } from '@react-google-maps/api';
import Pin from "../Pin";
import ShadowPin from "../ShadowPin";
import ReactDOMServer from "react-dom/server";
import { useDispatch } from "react-redux";
import { useMonitoringPin } from "../../../../hooks/useMonitoringPin";
import { SetDriverPopUp, SetOrderPinPopUp, SetOrderPinPopUpPosition, SetSelectedMonitoringDriver, SetSelectedMonitoringOrder } from "../../../../Redux/actions/actionNames";
import { GetColorByStatus } from "../../../../utils/get-colors-by-status";
import {getOrderMonitoringBounding} from "../getOrderMonitoring";
import { isPickupDelivery } from "../../side-bar/components/orderTypes";



const App = ({ position, id ,  order}) => {
  const dispatch = useDispatch();
  let {orderIsSelected} = useMonitoringPin(order.id, order.driverId ,id)

  function OrderOnclick() {
    dispatch(SetSelectedMonitoringOrder(order.id))
    !isPickupDelivery() &&    dispatch(SetSelectedMonitoringDriver(order.driverId || "xxxxx"))
    dispatch(SetDriverPopUp(null))
    dispatch(SetOrderPinPopUp(id))
    dispatch(SetOrderPinPopUpPosition({position:position , tag:"Dropoff2",orderId:order.id , customerId:order.customerId}))

    !isPickupDelivery() &&   getOrderMonitoringBounding(order)
  }

  if(!orderIsSelected){
    return null
  }

        return <Marker
        position={position}
        icon= {{
          anchor: { x: 10.5, y: 34 },
          url: 'data:image/svg+xml;charset=utf-8,' +
          encodeURIComponent(ReactDOMServer.renderToStaticMarkup(<Pin style={!orderIsSelected && { opacity: ".2" }} 
          color={"#"+GetColorByStatus(order.status , order)}/>))
        }}
        onClick={OrderOnclick}
        ></Marker> 

};

export default App

