import JenaTrucksCouriers from "../../../assets/JenaTrucks_logotype.jpg";
import FavIcon from "../../../assets/PROJECTS/JenaTrucks.png";

export default function JenaTrucks() {
    
    return  global.config = {
    "ProjectName": "JenaTrucks",
    "LogoType": JenaTrucksCouriers,
    "BaseApi": "https://rest.jenatrucks.com",
    "BaseSocket": "https://socket.jenatrucks.com",
    "BaseTrack": "https://admin.jenatrucks.com",
    "MapCenter": {
        "lat": 53.479981058722664,
        "lng": -2.24343192045155
    },
    "Lang": "en",
    "Direction": "ltr",
    "AllLanguages": [{
        "lang": "en",
        "direction": "ltr",
        "i18Lang": "English"
    }],
    "FavIcon": FavIcon,
    "Country": "GB",
    "thirdPartyLoginSupport": true  ,
    mapType :"osm", /* osm | google */ 
    googleApiKey : ""
} }