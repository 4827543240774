import Dropp from "../../../assets/Munchi_logotype.png";
import FavIcon from "../../../assets/PROJECTS/Munchi.png";

export default function Munchi() {
    
    return  global.config = {
    "ProjectName": "Munchi",
    "LogoType": Dropp,
    "BaseApi": "https://rest.gomunchi.com",
    "BaseSocket": "https://socket.gomunchi.com",
    "BaseTrack": "https://dash.gomunchi.com",
    "MapCenter": {
        "lat": "60.169765",
        "lng": "24.938556"
    },
    "Lang": "en",
    "Direction": "ltr",
    "AllLanguages": [{
        "lang": "en",
        "direction": "ltr",
        "i18Lang": "English"
    }],
    "FavIcon": FavIcon,
    "Country": "FI",
    "thirdPartyLoginSupport": true  ,
    mapType :"osm", /* osm | google */ 
    googleApiKey : ""
}}
