import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import arrow from "../../../../../assets/chevron-down.svg";
import pickupDone from "../../../../../assets/icon-pickup-done.svg";

import Collapse from "@material-ui/core/Collapse";
import { withTranslation, Trans } from "react-i18next";
import Backdrop from "@material-ui/core/Backdrop";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import { withStyles } from "@material-ui/core/styles";
import {
  Set_Pickup_Inputs,
  Set_Pickup_address,
  Set_SearchAutoCompleteLoading,
  Set_latlng,
} from "../../../../../Redux/actions/actionNames";
import {
  SearchAutoCompleteAction,
  SearchAddressAction,
  PlaceIdDetailsAction,
} from "../../../../../Redux/actions/actions";
import closeCircle from "../../../../../assets/close-cricle.svg";
import { PickupDone, isOrderInDone } from "../../../../../helper/module";
import jalali from "jalali-moment";
import momentJalaali from "moment-jalaali";
import { get_lang } from "../../../../../helper/UserData/userdate";
import Pod from "./pod";
import ic_calender from "../../../../../assets/Mutual/ic_calender.svg";

const BackdropCustom = withStyles({
  backdrop: {
    zIndex: 5000,
    backgroundColor: "rgba(0, 0, 0, 0)",
    color: "#00000",
  },
})((props) => (
  <Backdrop
    {...props}
    style={{
      zIndex: 5000,
      backgroundColor: "rgba(0, 0, 0, 0)",
      color: "#00000",
    }}
  />
));

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.id,
    };
    this.anchorEl = React.createRef();
  }

  handleCollapse = () => {
    this.props.handleCollapse(this.state.id);
  };

  render() {
    const { t } = this.props;
    let reserveIsValid = this.props.pickup
      ? (this.props.pickup.scheduleDateBefore
        ? momentJalaali(this.props.pickup.scheduleDateBefore).isValid()
        : false) ||
      (this.props.pickup.scheduleDateAfter
        ? momentJalaali(this.props.pickup.scheduleDateAfter).isValid()
        : false)
      : false;

    return (
      <passerngerContainer
        // style={{ paddingTop: reserveIsValid ? "8px" : "16px" }}
        className="pickupCustomized vrpickupCustomized"
      >
        <vrpickupdetails >
     
         
          {/* {PickupDone(this.props.status) ? (
            <img className="squree" src={pickupDone} />
          ) : (
            <circle />
          )} */}

          <vrPickupCircle />
          <span className="title">
            {" "}
            <Trans i18nKey={"Pickup2"}> </Trans>{" "}
            {reserveIsValid && <span className="eruhfny"><img src={ic_calender} /> {momentJalaali(this.props.pickup.scheduleDateAfter || this.props.pickup.scheduleDateBefore).locale(get_lang()).format("D MMM HH:mm")} </span>}
          </span>
          <img
            onClick={this.handleCollapse}
            src={arrow}
            className={`pickupCollIconVRx ${
              this.props.activeCollapse === this.state.id && "rotate180"
              }`}
          />
           { (this.props.type == "Pickup" && isOrderInDone(this.props.status)) && (
            <Pod {...this.props.pickup} style={{ marginTop: /* reserve */ reserveIsValid ? "13px" : "" }} />
          )}

          <span className={` copey`}>
            {this.props.pickup && this.props.pickup.address}
          </span>

          {/* {reserveIsValid ? (
            <span className={`miniAddressVR ViewOrderReservText`}>
              <span> {this.props.t("for")} </span>  {
                (this.props.pickup.scheduleDateAfter
                  ? momentJalaali(
                    Number(this.props.pickup.scheduleDateAfter)
                  ).format(
                    get_lang() == "fa"
                      ? " HH:mm jYYYY/jMM/jDD"
                      : " YYYY/MM/DD HH:mm"
                  )
                  : "") +


                (this.props.pickup.scheduleDateBefore &&
                  this.props.pickup.scheduleDateAfter
                  ? " - "
                  : "") +




                (this.props.pickup.scheduleDateBefore
                  ? momentJalaali(
                    Number(this.props.pickup.scheduleDateBefore)
                  ).format(
                    get_lang() == "fa"
                      ? " HH:mm jYYYY/jMM/jDD"
                      : " YYYY/MM/DD HH:mm"
                  )
                  : "")

              }
            </span>
          ) : null} */}
        </vrpickupdetails>

        {this.props.pickup && this.props.pureData && (
          <Collapse
            className="pickupcollapsecontainer"
            in={this.props.activeCollapse === this.state.id}
            timeout="auto"
            unmountOnExit
          >
            {/* {PickupDone(this.props.status) && (
              <vrpa>{`${
                this.props.t("Pickedupat") +
                jalali(new Date(this.props.pureData.driverPickedUpDate))
                  .locale(get_lang())
                  .format(" HH:mm")
              }`}</vrpa>
            )} */}

            {/* <vrpa>
              {this.props.pickup &&
                this.props.t("address") + " : " + this.props.pickup.address}
            </vrpa>
            <vrbfr
              style={{
                display:
                  this.props.pickup &&
                  (this.props.pickup.buildingBlock.length > 0 ||
                    this.props.pickup.floor.length > 0 ||
                    this.props.pickup.room.length > 0)
                    ? ""
                    : "none",
              }}
            >
              {`${
                this.props.pickup &&
                this.props.t("block") + " : " + this.props.pickup.buildingBlock
              }     ${
                this.props.pickup &&
                (this.props.pickup.buildingBlock.length > 0 ? "  |   " : "") +
                  (this.props.t("Floor") + " : " + this.props.pickup.floor)
              } ${
                this.props.pickup &&
                (this.props.pickup.room.length > 0 ? "  |   " : "") +
                  (this.props.t("Unit") + " : " + this.props.pickup.room)
              }`}
            </vrbfr>
            <vrbfr
              style={{
                display:
                  this.props.pickup &&
                  (this.props.pickup.fullName.length > 0 ||
                    this.props.pickup.floor.length > 0 ||
                    this.props.pickup.phone.length > 0 ||
                    this.props.pickup.buildingBlock.floor > 0)
                    ? ""
                    : "none",
              }}
            >
              {`${
                this.props.pickup &&
                this.props.t("senderfullname") +
                  " : " +
                  this.props.pickup.fullName
              } ${
                this.props.pickup &&
                (this.props.pickup.phone.length > 0 ? "  |   " : "") +
                  (this.props.t("senderphonenumber") +
                    " : " +
                    this.props.pickup.phone)
              }`}
            </vrbfr>
            <vrnote
              style={{
                display:
                  this.props.pickup && this.props.pickup.customerDescription.length > 0
                    ? ""
                    : "none",
              }}
            >
              {this.props.pickup &&
                this.props.t("notesfordriver") +
                  " : " +
                  this.props.pickup.customerDescription}
            </vrnote> */}

            <div className="vrPickupCollapseDetail">
              <span className="xyu">
                {" "}
                {this.props.pickup.buildingBlock.length > 0
                  ? this.props.pickup.buildingBlock
                  : "-"}{" "}
              </span>
              <span className="xyt"> {this.props.t("block")} </span>
            </div>
            <div className="vrPickupCollapseDetail">
              <span className="xyu">
                {" "}
                {this.props.pickup.floor.length > 0
                  ? this.props.pickup.floor
                  : "-"}{" "}
              </span>
              <span className="xyt"> {this.props.t("Floor")} </span>
            </div>

            <div className="vrPickupCollapseDetail">
              <span className="xyu">
                {" "}
                {this.props.pickup.room.length > 0
                  ? this.props.pickup.room
                  : "-"}{" "}
              </span>
              <span className="xyt"> {this.props.t("Unit")} </span>
            </div>

            <div className="vrPickupCollapseDetail">
              <span className="xyu">
                {" "}
                {this.props.pickup.fullName.length > 0
                  ? this.props.pickup.fullName
                  : "-"}{" "}
              </span>
              <span className="xyt"> {this.props.t("senderfullname")} </span>
            </div>

            <div className="vrPickupCollapseDetail" style={{}}>
              <span className="xyu">
                {" "}
                {this.props.pickup.phone.length > 0
                  ? this.props.pickup.phone
                  : "-"}{" "}
              </span>
              <span className="xyt"> {this.props.t("senderphonenumber")} </span>
            </div>

            <div className="vrPickupCollapseDetail">
              <span className="xyu">
                {" "}
                {this.props.pickup.email.length > 0
                  ? this.props.pickup.email
                  : "-"}{" "}
              </span>
              <span className="xyt"> {this.props.t("email")} </span>
            </div>


            <div className="vrPickupCollapseDetail">
              <span className="xyu">
                {" "}
                {this.props.pickup.customerDescription.length > 0
                  ? this.props.pickup.customerDescription
                  : "-"}{" "}
              </span>
              <span className="xyt"> {this.props.t("notesfordriver")} </span>
            </div>

          </Collapse>
        )}
      </passerngerContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  lang: state.LocalData.lang,
  type: state.ViewTravel.pureData ? state.ViewTravel.pureData.type : "",
  status: state.ViewTravel.pureData ? state.ViewTravel.pureData.status : "",
  pickup: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.pickup
      ? state.ViewTravel.pureData.pickup
      : null
    : null,
  pureData: state.ViewTravel.pureData ? state.ViewTravel.pureData : null,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
