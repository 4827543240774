import { Component } from "react";
import { connect } from "react-redux";
import Ondemand from "./ondemand";
import Pickup from "./pickup";
import Delivery from "./delivery";
import PickupDelivery from "./pickupDelivery";


class App extends Component {

  render() {
    let orderType = this.props.orderTypeActive

    if(orderType== "Ondemand"){
      return <Ondemand />
    }else if(orderType== "Pickup"){
      return <Pickup />
    }else if(orderType== "Delivery"){
      return <Delivery />
    }else if(orderType== "PickupDelivery"){
      return <PickupDelivery />
    }else{
      return null
    }

  }
}

const mapStateToProps = (state) => ({
  orderTypeActive: state.NewOrder.newOrder.orderTypeActive,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
