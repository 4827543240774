import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';




class App extends React.Component {



  render() {
    const { t } = this.props;
    return (
      <React.Fragment>

            <span className="EditStatusandDriverLoading">
              <Trans i18nKey={"fetchingdata"}> </Trans>
            </span>
            <Box sx={{ width: '100%', marginTop: "180px", marginBottom: "150px" }}>
              <LinearProgress />
            </Box>

      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
