import { faker } from '@faker-js/faker'

faker.seed(123);

const range = (len) => {
  const arr = []
  for (let i = 0; i < len; i++) {
    arr.push(i)
  }
  return arr
}

const newPerson = () => {
  return {
    OrderType: "P_Hub_D",
    Stage: faker.helpers.shuffle([
      'P',
      'D',
    ])[0],
    Status:  faker.helpers.shuffle([
      'Draft',
      'Confirmed',
      'PickupRouted',
      'PickedUp',
      'AtWarehouse',
      'DeliveryRouted',
      'ReadyForDelivery',
      'OutForDelivery',
      'Delivered',
      'PickupFailed',
      'NotDelivered',
      'CustomerCanceled'
    ])[0],
    Route: "Route Name",
    CreatedAt: "22 May 22:13",
    OrderID: "#123456789"+faker.number.int(),
    PickupAddress: "7 E Ring Drive, Vernon Hills,il, 60061",
    PickupDriver: "Mosayeb",
    DeliveryAddress: "7 E Ring Drive, Vernon Hills,il, 60061",
    DeliveryDriver: "Mosayeb",
    Schedule: "22 May 22:13",

  }
}

export function makeData(...lens) {
  const makeDataLevel = (depth = 0) => {
    const len = lens[depth]
    return range(len).map((d) => {
      return {
        ...newPerson(),
        subRows: lens[depth + 1] ? makeDataLevel(depth + 1) : undefined,
      }
    })
  }

  return makeDataLevel()
}
