import * as actionTypes from "../actions/actionTypes";
var _ = require("lodash");

const init = {
  openDialoge: false,
  customerId: null,
  newFav: {
    currentAddressId:null,
    Title: "",
    Address: "",
    Block: "",
    Floor: "",
    Unit: "",
    ContactFullname: "",
    Contactphonenumber: "",
    Notesfordriver: "",
    loc: null,
    SearchList:[]
  },
  list: [],
};

export const FavAddress = (state = init, action) => {
  switch (action.type) {
    case actionTypes.setNewFav:
      return {
        ...state,
        newFav: {
          ...state.newFav,
          [action.state.name]: action.state.value,
        },
      };
    case actionTypes.openNewFav:
      return {
        ...state,
        openDialoge: action.state,
        newFav:action.state ? {...state.newFav} :  {...init.newFav}
      };
    case actionTypes.resetNewFav:
      return {
        ...init,
      };

    case actionTypes.setListNewFav:
      return {
        ...state,
        list: action.state.data,
        customerId: action.state.customerId,
      };
      case actionTypes.Set_SearchAutoCompleteNewFav:
        return {
          ...state,
          newFav: {
            ...state.newFav,
            SearchList: action.state,
          },
        };
      case actionTypes.deleteFavAddress:
        return {
          ...state,
          list: [...state.list.filter((item)=>{
            return item.id !== action.state
          })],
        };
        case actionTypes.resetInputNewfav:
          return {
            ...state,
            newFav:{...init.newFav}
          };
  
        
    default:
      return state;
  }
};
