import React, { useState, useRef } from 'react';
import styles from './style.module.css';
import iconArrowTriangleDown from '../../../../../../../assets/icon-arrow-triangle-down.svg';
import Popper from "@material-ui/core/Popper";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import { useTranslation } from 'react-i18next';
import { SetSearchOrderListRegexFields } from '../../../../../../../Redux/actions/actionNames';
import { useDispatch, useSelector } from 'react-redux';


export const SearchFilter = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const ActiveregexFields = useSelector((state) => state.OrderList.search.regexFields)

  const regexFields = [
    {
      value:['all'],
      i18: "all",
    },
    { value: ["code"], i18: "regexcode" },
    { value: ["customerPhone"], i18: "regexcustomerPhone" },
    { value: ["customerName"], i18: "regexcustomerName" },
    { value: ["driverPhone"], i18: "regexdriverPhone" },
    { value: ["driverName"], i18: "regexdriverName" },
    { value: ["driverUsername"], i18: "regexdriverUsername" },
    { value: ["pickupDescription"], i18: "Pickupnote" },
    { value: ["address"], i18: "address" },
    { value: ["dropoffCustomerDescription"], i18: "dropoffCustomerDescription" },
    { value: ["referenceId"], i18: "ReferenceID" },

    

  ]
  const [showpoper, setShowpoper] = useState(false);
  const anchorEl = useRef(null);

  function handleshowpoper() {
    setShowpoper(!showpoper)
  }

  function handleRegexFields(item) {
    dispatch(SetSearchOrderListRegexFields(item))
    setShowpoper(!showpoper)
  }

  return <div className={styles.searchFilter} ref={anchorEl}>

    <span className={styles.searchFilterTitle} onClick={handleshowpoper}> {t(ActiveregexFields.i18)} </span>
    <img className={styles.searchFilterimg} onClick={handleshowpoper} src={iconArrowTriangleDown} />

    <Popper
      open={showpoper}
      anchorEl={anchorEl.current}
      role={undefined}
      style={{
        zIndex: 6
      }}
      transition
      disablePortal
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          timeout={{ enter: 150, exit: 150 }}
          style={{
            transformOrigin:
              placement === "bottom" ? "center top" : "center bottom",
          }}
        >
          <Paper style={{
            zIndex: 6,
            marginTop: "0",
            border: "solid 1px rgba(128, 128, 128, 0.2)",
            borderRadius: "10px",
            boxShadow: "0 2px 8px 0 rgba(128, 128, 128, 0.24)"
          }}>
            <ClickAwayListener onClickAway={handleshowpoper}>
              <MenuList id="menu-list-grow">
                {regexFields.map((item) => {
                  return (
                    <MenuItem
                      onClick={() => {
                        handleRegexFields(item);
                      }}
                      className="serviceListOption"
                    >
                      {t(item.i18)}

                    </MenuItem>
                  );
                })}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>

  </div>

};




