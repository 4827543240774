import JumbezLogo from "../../../assets/JumbezLogo.png";
import FavIcon from "../../../assets/PROJECTS/Jumbez.png";

export default function Jumbez () {
    
    return global.config = {
  
    "ProjectName": "Jumbez",
    "LogoType": JumbezLogo,
    "BaseApi": "https://rest.jumbez.com",
    "BaseSocket": "https://socket.jumbez.com",
    "BaseTrack": "https://track.jumbez.com",
    "MapCenter": {
        "lat": "52.07053587732509",
        "lng": "-1.8860622944427006"
    },
    "Lang": "en",
    "Direction": "ltr",
    "AllLanguages": [{
        "lang": "en",
        "direction": "ltr",
        "i18Lang": "English"
    }],
    "FavIcon": FavIcon,
    "Country": "GB",
    "thirdPartyLoginSupport": true  ,
    mapType :"osm", 
    googleApiKey : ""
} }