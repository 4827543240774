import React from 'react'
import {  useEffect } from 'react';
import { useSelector } from 'react-redux';


export function useMapContainerWidthHeight() {
  const FilterPanelIsActive = useSelector((state) => state.OrderList.filters.openDialoge)
  const openNewOrder = useSelector((state) => state.NewOrder.OpenDialoge)

  return {
    width: FilterPanelIsActive ? "calc(100vw - 717px)" : "calc(100vw - 529px)" ,
    height :  openNewOrder  ? "100vh" : "calc(100vh - 52px)" 
  }

};




