import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import box from "../../../../../../assets/box-copy.svg";
import { withStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import { withTranslation, Trans } from "react-i18next";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import Grow from "@material-ui/core/Grow";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { Add_Driver_Option, Remove_Driver_Option, Edit_Driver_Remove_Driver_Option, Edit_Driver_Add_Driver_Option, Edit_Travel_Operate_Changed } from "../../../../../../Redux/actions/actionNames";
import { get_lang } from "../../../../../../helper/UserData/userdate";

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showpoper: false,
      active:false,
      selected:null
    };
    this.anchorEl = React.createRef();
  }

  handleactive = () => {
    // this.props.dispatch(Edit_Travel_Operate_Changed())

    // if (this.optionisSaved()) {
    //   this.props.dispatch(Edit_Driver_Remove_Driver_Option(this.props.id))
    // } else {
    //   this.props.dispatch(Edit_Driver_Add_Driver_Option({
    //     name:this.props.name,
    //     id:this.props.id,
    //     type:"boolean"
    //   }))
    // }

  };


  optionisSaved() {
    let booll = false;
     booll = this.props.options.find((item) => {
      return item.id === this.props.id;
    });
    if(booll){
      return true
    }else{
      return false
    }
  }

  render() {
    const { t } = this.props;
    return (
      <React.Fragment >
        <clickableservice ref={this.anchorEl} id={this.props.id} onClick={this.handleactive} className={`${this.optionisSaved() && "avtiveServiceOptions"}`} style={{cursor: "not-allowed"}}>
        {this.props.title}
        </clickableservice>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  options:state.EditOrder.editOrder.driver.options,
  lang :state.LocalData.lang
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
