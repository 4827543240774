import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import CalculatePrice from "./calculatePrice";
import CalculatePriceCheck_WithoutHttp from "./calculatePriceCheck_WithoutHttp";
import Pricedetails from "./components/Price details";
import CanSaveOrder from "./canSaveOrder";
import Header from "./components/header";
import AddressbookEditOrder from "./components/addressBook/index";
import Footer from "./components/footer";
import Passenger from "./components/customerInformation";
import Service from "./components/service";
import Pickup from "./components/pickup";
import Dropoff from "./components/dropoff";
import Driver from "./components/driver";
import Plus from "../../../../assets/iconfinder_plus_172525.svg";
import { withTranslation, Trans } from "react-i18next";
import Slide from "@material-ui/core/Slide";
import { Add_dropoff, Set_ActivePickupDropOff, Remove_dropoff, EditTravelSetActivePickup, Edit_Travel_add_dropoff, Edit_Travel_remove_dropoff, Edit_Travel_Operate_Changed } from "../../../../Redux/actions/actionNames";
import { Get_User_Recent_Address, ServicesVehicletypeAction } from "../../../../Redux/actions/actions";
import DropoffsContainer from "./components/dropoffs-container";
import styles from './style.module.css';
import { Get_Addressbook_list_Api } from "../../../../helper/api";
import { ADD_SQUARE } from "../../../../assets";
import Typography from "../../../../design-system/lib/Typography";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      idArrays: [],
      dropoffs: [],
    };
  }
  handleCollapse = (id) => {
    this.props.dispatch(EditTravelSetActivePickup(id))
    this.setState({
      [id]: !this.state[id],
    });

    this.setState((state) => {
      let x = {};
      state.idArrays.map((id) => {
        x[id] = false;
      });

      return { ...x, [id]: !this.state[id] };
    });
  };

  setId = (id) => {
    this.setState((state) => {
      return { idArrays: [...state.idArrays, id + ""], [id]: false };
    });
  };

  addDropOff = () => {
    this.props.dispatch(Edit_Travel_Operate_Changed())

    this.props.dispatch(Edit_Travel_add_dropoff(this.idgenerator()))
  };

  idgenerator = () => {
    var number = Math.random(); // 0.9394456857981651
    number.toString(36); // '0.xtis06h6'
    var id = number.toString(36).substr(2, 9); // 'xtis06h6'
    return id.toString();
  };

  componentDidMount() {


    //
    // this.props.dispatch(ServicesVehicletypeAction(this.props.dispatch , this.props.center))
  }

  componentWillReceiveProps(nextProps) {



    if (nextProps.OpenDialoge) {
      this.props.dispatch(Get_User_Recent_Address(this.props.dispatch, nextProps.customerId, null))
      Get_Addressbook_list_Api(
        this.props.dispatch,
        {
          customerId: nextProps.customerId,
          page: 1,
          perpage: 20,
          searchRegex: "",
        },
        (call) => {

        }
      );
    }
  

}


  removeDropOff = (id) => {
    this.props.dispatch(Edit_Travel_remove_dropoff(id))

  }

  render() {
    return (
      <Slide
        direction="down"
        in={this.props.OpenDialoge}
        mountOnEnter
        unmountOnExit
      >
        {/* <div className="NewOrder" style={{position:"unset",direction:"var(--main-inverseDirection)"}}> */}
        <div className="NewOrder" style={{zIndex:10,direction:"var(--main-inverseDirection)",backgroundColor:"rgba(244, 244, 247, 1)",border:"1px solid rgba(244, 244, 247, 1)",boxShadow:"unset",display:"flex",flexDirection:"column"}}>

          <Header />

          <itemContainers className={styles.itemContainers}>
      
            <div className="noboxshadow">

            { (["Ondemand" , "Pickup", "PickupDelivery"].indexOf(this.props.orderType) > -1) && <Pickup
                setId={this.setId}
                handleCollapse={this.handleCollapse}
              />}

            </div>

          </itemContainers>
          <itemContainers className={styles.itemContainers} style={{flex:"1",display:"unset",paddingBottom:"100px",overflowY:"scroll"}}>
      
            <div className="noboxshadow">

 
            {["PickupDelivery", "Delivery", "Ondemand"].indexOf(
                this.props.orderType
              ) > -1 && <DropoffsContainer setId={this.setId} removeDrop={this.removeDropOff} addDropOff={this.addDropOff} toto={this.props.t}
                handleCollapse={this.handleCollapse}  />}

            </div>

      
            {/* {(["Ondemand"].indexOf(this.props.orderType) > -1) &&
              <addDropOff onClick={this.addDropOff}>
                <img
                  className="addDropOff"
                  src={Plus}
                />
                <span className="addDropOffText">
                  {" "}
                  <Trans i18nKey={"Adddropoff"}> </Trans>
                </span>
              </addDropOff>
            } */}



          </itemContainers>
          <CalculatePrice />
          <CanSaveOrder />

          <Footer />

          <AddressbookEditOrder />

        </div>
      </Slide>
    );
  }
}

const mapStateToProps = (state) => ({
  orderType: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.type
      ? state.ViewTravel.pureData.type
      : null
    : null,
    customerId: state.ViewTravel.pureData?.customerId,
  OpenDialoge: state.EditOrder.OpenDialoge,
  dropoffs: state.EditOrder.editOrder.dropOffs.otherDropOffs,
  address: state.EditOrder.editOrder.dropOffs.firstDropOff.address,
  adresss_loc: state.EditOrder.editOrder.dropOffs.firstDropOff.adresss_loc,
  block: state.EditOrder.editOrder.dropOffs.firstDropOff.block,
  email: state.EditOrder.editOrder.dropOffs.firstDropOff.email,
  floor: state.EditOrder.editOrder.dropOffs.firstDropOff.floor,
  unit: state.EditOrder.editOrder.dropOffs.firstDropOff.unit,
  recieverFullName: state.EditOrder.editOrder.dropOffs.firstDropOff.recieverFullName,
  recieverPhoneNumber: state.EditOrder.editOrder.dropOffs.firstDropOff.recieverPhoneNumber,
  noteForDriver: state.EditOrder.editOrder.dropOffs.firstDropOff.noteForDriver,
  center: state.Map.center,
  scheduleDateAfter: state.EditOrder.editOrder.dropOffs.firstDropOff.scheduleDateAfter,
  scheduleDateBefore:
    state.EditOrder.editOrder.dropOffs.firstDropOff.scheduleDateBefore,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(App));
