import { debounce, isEmpty ,throttle } from 'lodash';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UpdateCategoryOrdersListSorted } from '../Redux/actions/actionNames';
import { ObjectToArray } from '../service/worker/SortToArrayCategoryListData copy';
import { SortToArrayCategoryListData } from '../service/worker/SortToArrayCategoryListData';
import { LocalData } from '../service/localStorage';



export function SyncReduxLocal() {
  const DateRange = useSelector((state) => state.OrderList.DateRange)
  const cursorSort = useSelector((state) => state.OrderList.cursorSort)
  const cursor = useSelector((state) => state.OrderList.cursor)
  const ActiveOrderType = useSelector((state) => state.OrderList.ActiveOrderType)
  const filters = useSelector((state) => state.OrderList.filters)
  const isScheduled = useSelector((state) => state.OrderList.isScheduled)
  const selectedServices = useSelector((state) => state.OrderList.selectedServices)
  const selectedStatus = useSelector((state) => state.OrderList.selectedStatus)
  const selectedVehicleType = useSelector((state) => state.OrderList.selectedVehicleType)
  const zones = useSelector((state) => state.OrderList.zones)
  const now = useSelector((state) => state.OrderList.now)
  const dueDate = useSelector((state) => state.OrderList.dueDate)
  const Routefilters = useSelector((state) => state.Route.filters)
  const Tablefilters = useSelector((state) => state.Table.filters)
  const TabledefaultColumnStates = useSelector((state) => state.Table.defaultColumnStates)
  const TableactiveFilters = useSelector((state) => state.Table.activeFilters)
  const ordersOpen = useSelector((state) => state.Monitoring.orders.open)
  const driversOpen = useSelector((state) => state.Monitoring.drivers.open)

  const localData = new LocalData();

  useEffect(() => {

 
    localData.set("ReduxLocal_ActiveOrderType",ActiveOrderType)
    localData.set("ReduxLocal_Routefilters",JSON.stringify({createdAt :Routefilters.createdAt , driverId : Routefilters.driverId , status :  Routefilters.status}))
    localData.set("ReduxLocal_Tablefilters",JSON.stringify(Tablefilters))
    // localData.set("ReduxLocal_TabledefaultColumnStates",JSON.stringify(TabledefaultColumnStates))
    localData.set("ReduxLocal_TableactiveFilters",JSON.stringify(TableactiveFilters))


    localData.set("ReduxLocal_ordersOpen",ordersOpen)
    localData.set("ReduxLocal_driversOpen",driversOpen)
    // localData.set("ReduxLocal_selectedVehicleType",selectedVehicleType)
    // localData.set("ReduxLocal_DateRange",JSON.stringify(DateRange))
    // localData.set("ReduxLocal_zones",zones)
    // localData.set("ReduxLocal_isScheduled",isScheduled)
    // localData.set("ReduxLocal_now",JSON.stringify(now))
    // localData.set("ReduxLocal_dueDate",JSON.stringify(dueDate))
    // localData.set("ReduxLocal_cursorSort",cursorSort)
    // localData.set("ReduxLocal_cursor",cursor)
    // localData.set("ReduxLocal_filters",JSON.stringify(filters))


    localData.set("ReduxLocal_"+ActiveOrderType,JSON.stringify({
      selectedServices :selectedServices,
      selectedStatus:selectedStatus ,
      selectedVehicleType:selectedVehicleType ,
      DateRange:JSON.stringify(DateRange) ,
      zones: zones,
      now: JSON.stringify(now),
      dueDate: JSON.stringify(dueDate),
      cursorSort: cursorSort,
      cursor: cursor,
      filters: JSON.stringify(filters),
      isScheduled :isScheduled
    }))


  }, [DateRange ,driversOpen ,ordersOpen , TabledefaultColumnStates , TableactiveFilters ,  Routefilters , Tablefilters,  dueDate, now, cursorSort ,cursor ,ActiveOrderType , filters,isScheduled ,selectedServices, selectedStatus,selectedVehicleType ,zones]);


  useEffect(() => {

   localData.set("ReduxLocal_TabledefaultColumnStates",JSON.stringify(TabledefaultColumnStates))


  }, [ TabledefaultColumnStates ]);


  return null;
};

