import styles from './style.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import estimer from "../../../assets/estimer.svg";
import espath from "../../../assets/espath.svg";
import { useTranslation } from 'react-i18next';
import Typography from '../../../design-system/lib/Typography';
import { get_lang } from '../../../helper/UserData/userdate';



export const NewOrderEstimate = ({style}) => {
    const price = useSelector((state) => state.NewOrder.newOrder.price.price)


    const { t, i18n } = useTranslation();


    if (!price || !price.distance) {
        return null
    }


    return <div className={styles.EstimateContainer} style ={style && style}>
        <p className={styles.EstimateItem}>
            <img src={espath} />
            <Typography style={{color:"#242424"}} weight='Body_Middle_Regular' text={t("Espath")} />

            <Typography style={{lineHeight:"1.6"}} weight='Body_Tiny_Regular' text={`${lengthFormatter(price.distance , price.lengthUnit , price.lengthUnitFormat , price)} `} />
        </p>
        <p className={styles.EstimateItem}>
            <img src={estimer} />

            <Typography style={{color:"#242424"}} weight='Body_Middle_Regular' text={t("Estime")} />

            <Typography style={{lineHeight:"1.6"}} weight='Body_Tiny_Regular' text={String(t("min")).replace("xxx", Math.floor(price.duration / 60))} />

        </p>


    </div>
};





export const lengthFormatter = (value, lengthUnit, lengthUnitFormat,price) => {
	return Intl.NumberFormat(get_lang(), {style: 'unit', unit: lengthUnit, unitDisplay: lengthUnitFormat}).format(value);
}