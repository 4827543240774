import { isEmpty } from "lodash";
import * as actionTypes from "../actions/actionTypes";
import { v4 as uuidv4 } from 'uuid';

let sampleUser = {
  name: "alireza",
  family: "yarahmadi",
  number: "09160869366",
  id: "Y__alireza",
};

let driverVehicleType = {
  name: "Bagh Bagho",
  id: "fefeffevfe ",
  options: [
    {
      name: "Stop",
      type: "list",
      id: "fuckStop",
      data: [
        { name: "1min to 5min", id: "dwddwffw" },
        { name: "5min to 7min", id: "dwd2dw" },
        { name: "7min to 10min", id: "dwd3qd" },
        { name: "10min to 15min", id: "dwd4ffe" },
        { name: "15min to 25min", id: "dwd52rd" },
      ],
    },
    { name: "air conditioner", type: "boolean", id: "fuckingcoller" },
  ],
};

let manualdriverVehicleType = {
  code: "58632",
  name: "Alireza",
  family: "Yarahamadi",
  number: "09160869366",
  id: "Y__alireza",
  vehicleType: driverVehicleType,
};

let serviceVehicleType = {
  name: "Box",
  id: "Y__alireza",
  options: [
    {
      name: "weight",
      type: "list",
      id: "fuck",
      data: [
        { name: "under 1 kg", id: "dwd1" },
        { name: "under 2 kg", id: "dwd2" },
        { name: "under 3 kg", id: "dwd3" },
        { name: "under 4 kg", id: "dwd4" },
        { name: "under 5 kg", id: "dwd5" },
      ],
    },
    { name: "need packed", type: "boolean", id: "fuckingbool" },
  ],
};

let sampleAddress = {
  address: "dh wdwi diw wdhwdwj dwdw dwd wdwd wwd w9d w",
  adresss_loc: { lng: "51.24561110464772", lat: "29.257175814103338" },
};

let sampleDropOff = {
  id: null,
  address: "",
  adresss_loc: { lng: "", lat: "" },
  block: "",
  floor: "",
  unit: "",
  recieverFullName: "",
  recieverPhoneNumber: "",
  email: "",
  scheduleDateBefore: null,
  scheduleDateAfter: null,
  driver: {
    chooseDriver: false,
    auto: true,
    options: [],
    forceAssaigning: false,
    auto_driver: {
      auto_activeVehicleType: null,
      auto_suggestListLoading: true,
      auto_suggestListData: [],
      auto_suggestListOrdered: false,
    },
    manual_driver: {
      driverAllvehicletypes: [],
      searchDriverInput: "",
      manual_activeVehicleType: null,
      manual_name: "",
      manual_family: "",
      manual_number: "",
      manual_id: "",
      manual_code: "",
      manual_suggestListLoading: false,
      manual_suggestListData: [],
      manual_suggestListOrdered: false,
    },
  },
};

const init = {
  OpenDialoge: false,
  rebuildNewOrder: false,

  uid: null,
  list: [],
  newOrder: {
    orderTypeActive: null,
    editCustomerIsOpen: false,
    draft:true ,
    codAmount: null,
    referenceID: "",
    notes:"",
    newCustomer: {
      open: false,
      name: "",
      family: "",
      phone: "",
      email: "",
      error: null
    },
    price: {
      changed: false,
      promoCode: "",
      loading: false,
      price: null,
      done: false,
    },
    paymentMethod: {
      paymentMethod: null,
      paymentSide: null,
    },
    sendOrder: {
      canSaveOrder: false,
      serviceError: false,
      serviceText: "",
      loading: false,
    },
    suggestListPickUpDropOffLoading: false,
    suggestListPickUpDropOffOrdered: false,
    suggestPickUpDropOffListData: [],
    customer: {
      search_regex: "",
      person: true,
      balance: 0,
      BusinessName: "",
      BusinessNumber: "",
      BusinessId: "",
      name: "",
      family: "",
      number: "",
      id: "",
      phone: "",
      familyName: "",
      givenName: "",
      email: "",
      suggestListLoading: false,
      suggestListData: [],
      suggestListOrdered: false,
      recentAddreess: []
    },
    service: {
      serviceVehicleTypes: [],
      activeVehicleType: null,
      loading: false,
      options: [],
    },
    driver: {
      auto: true,
      options: [],
      forceAssaigning: false,
      auto_driver: {
        auto_activeVehicleType: null,
        auto_suggestListLoading: true,
        auto_suggestListData: [],
        auto_suggestListOrdered: false,
      },
      manual_driver: {
        driverAllvehicletypes: [],
        searchDriverInput: "",
        manual_activeVehicleType: null,
        manual_name: "",
        manual_family: "",
        manual_number: "",
        manual_id: "",
        manual_code: "",
        manual_suggestListLoading: false,
        manual_suggestListData: [],
        manual_suggestListOrdered: false,
      },
    },
    activePickupDropOff: {
      type: null,
      id: "",
    },
    pickup: {
      addressDetail:"",
      address: "",
      placeId: "",
      adresss_loc: { lng: "", lat: "" },
      block: "",
      floor: "",
      unit: "",
      senderFullName: "",
      senderPhoneNumber: "",
      noteForDriver: "",
      email: "",
      list: [
        sampleAddress,
        sampleAddress,
        sampleAddress,
        sampleAddress,
        sampleAddress,
      ],
      scheduleDateBefore: null,
      scheduleDateAfter: null,
      loading: false,
      driver: {
        chooseDriver: false,
        auto: true,
        options: [],
        forceAssaigning: false,
        auto_driver: {
          auto_activeVehicleType: null,
          auto_suggestListLoading: true,
          auto_suggestListData: [],
          auto_suggestListOrdered: false,
        },
        manual_driver: {
          driverAllvehicletypes: [],
          searchDriverInput: "",
          manual_activeVehicleType: null,
          manual_name: "",
          manual_family: "",
          manual_number: "",
          manual_id: "",
          manual_code: "",
          manual_suggestListLoading: false,
          manual_suggestListData: [],
          manual_suggestListOrdered: false,
        },
      },
    },
    dropOffs: {
      firstDropOff: {
        addressDetail:"",
        id: "d123",
        address: "",
        adresss_loc: { lng: "", lat: "" },
        block: "",
        floor: "",
        unit: "",
        recieverFullName: "",
        recieverPhoneNumber: "",
        noteForDriver: "",
        email: "",
        scheduleDateBefore: null,
        scheduleDateAfter: null,
        driver: {
          chooseDriver: false,
          auto: true,
          options: [],
          forceAssaigning: false,
          auto_driver: {
            auto_activeVehicleType: null,
            auto_suggestListLoading: true,
            auto_suggestListData: [],
            auto_suggestListOrdered: false,
          },
          manual_driver: {
            driverAllvehicletypes: [],
            searchDriverInput: "",
            manual_activeVehicleType: null,
            manual_name: "",
            manual_family: "",
            manual_number: "",
            manual_id: "",
            manual_code: "",
            manual_suggestListLoading: false,
            manual_suggestListData: [],
            manual_suggestListOrdered: false,
          },
        },
      },
      otherDropOffs: [],
      list: [],
      loading: false,
    },
  },
};
export const NewOrder = (state = init, action) => {
  switch (action.type) {
    case actionTypes.setcansavetravel:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          sendOrder: {
            ...state.newOrder.sendOrder,
            ...action.state,
          },
        },
      };

    case actionTypes.openEditCustomer:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          editCustomerIsOpen: action.state,
          newCustomer: {
            ...state.newOrder.newCustomer,
            open: false,
          },
        },
      };


    case actionTypes.newCustomerHandleChange:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          editCustomerIsOpen: false,
          newCustomer: {
            ...state.newOrder.newCustomer,
            [action.state.key]: action.state.value,
          },
        },
      };

    case actionTypes.set_CheckServiceByCustomer:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          sendOrder: {
            ...state.newOrder.sendOrder,
            serviceError: action.state,
          },
        },
      };

    case actionTypes.set_CheckVehicleByCustomer:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          sendOrder: {
            ...state.newOrder.sendOrder,
            VehicleError: action.state,
          },
        },
      };

    case actionTypes.setCodAmount:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          codAmount: action.state
        },
      };

    case actionTypes.setReferenceID:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          referenceID: action.state
        },
      };

      case actionTypes.setNotesOrder:
        return {
          ...state,
          newOrder: {
            ...state.newOrder,
            notes: action.state
          },
        };
  
  


    case actionTypes.OPENCLOSENEWTRAVEL:
      return {
        ...state,
        uid: isEmpty(String(action.state)) ? (state.OpenDialoge ? null : uuidv4()) : (action.state ? uuidv4() : null),
        OpenDialoge: isEmpty(String(action.state)) ? !state.OpenDialoge : action.state,
      };



    case actionTypes.set_uid_newOrder:
      return {
        ...state,
        uid: uuidv4(),
      };


      case actionTypes.set_newOrder_draft:
        return {
          ...state,
          newOrder:{
            ...state.newOrder ,
            draft:action.state
          }
          
        };
  

    case actionTypes.setpriceneworder:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          price: {
            ...state.newOrder.price,
            ...action.state,
          },
        },
      };
    case actionTypes.reset_passenger:
      return {
        ...state,
        init,
      };
    case actionTypes.set_passenger_name_family_phoneNumber:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          customer: {
            ...state.newOrder.customer,
            [action.state.name]: action.state.value,
          },
        },
      };
    case actionTypes.changePersonBusiness:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          customer: {
            ...state.newOrder.customer,
            person: !state.newOrder.customer.person,
            suggestListLoading: false,
            suggestListData: [],
            suggestListOrdered: false,
          },
        },
      };
    case actionTypes.suggestcustomerlist:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          customer: {
            ...state.newOrder.customer,
            suggestListOrdered: true,
            suggestListData: action.state,
          },
        },
      };
    case actionTypes.suggestcustomerlistloading:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          customer: {
            ...state.newOrder.customer,
            suggestListLoading: action.state,
          },
        },
      };

    case actionTypes.set_service_VehicleTypes:
      var mustResetService = true;

      if (state.newOrder.service.activeVehicleType) {
        action.state.forEach((element) => {
          if (element.id == state.newOrder.service.activeVehicleType.id) {
            mustResetService = false;
          }
        });
      }

      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          service: {
            ...state.newOrder.service,
            serviceVehicleTypes: action.state,
            activeVehicleType: mustResetService ? (action.state.length > 0 ? action.state[0] : null) : state.newOrder.service.activeVehicleType,
            loading: false,
            options: mustResetService ? [] : state.newOrder.service.options
            // state.newOrder.service.options.length > 0
            //   ? state.newOrder.service.options
            //   : [],
          },
        },
      };
    case actionTypes.set_service_activeVehicleType:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          service: {
            ...state.newOrder.service,
            activeVehicleType: action.state,
            options: [],
          },
        },
      };

    case actionTypes.set_service_VehicleTypes_RepeatData:
      let service = null;
      state.newOrder.service.serviceVehicleTypes.map((item) => {
        if (item.id == action.serviceId) {
          service = item;
        }
      });

      if (service) {
        return {
          ...state,
          newOrder: {
            ...state.newOrder,
            service: {
              ...state.newOrder.service,
              activeVehicleType: service,
              options: action.options.map((item) => {
                return {
                  id: item.id,
                  type: item.type,
                  item: {
                    id: item.dataId,
                    title: item.dataTitle,
                  },
                };
              }),
            },
          },
        };
      } else {
        return {
          ...state,
        };
      }

    case actionTypes.add_service_option:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          service: {
            ...state.newOrder.service,
            options: action.state.update ? state.newOrder.service.options.map((item) => {
              if (item.id == action.state.id) {
                return {
                  ...item,
                  inputValue: action.state.inputValue
                };
              } else {
                return item
              }

            }) : [...state.newOrder.service.options, action.state],
          },
        },
      };
    case actionTypes.remove_service_option:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          service: {
            ...state.newOrder.service,
            options: state.newOrder.service.options.filter(
              (item) => item.id !== action.state
            ),
          },
        },
      };
    case actionTypes.set_auto_driver:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          driver: {
            ...state.newOrder.driver,
            options: [],
            auto: action.state,
          },
        },
      };
    case actionTypes.set_auto_driverـPickupDeliveryType_Pickup:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          pickup: {
            ...state.newOrder.pickup,
            driver: {
              ...state.newOrder.pickup.driver,
              options: [],
              auto: action.state,
            },
          }
        },
      };

    case actionTypes.set_auto_driverـPickupDeliveryType_Dropoff:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          dropOffs: {
            ...state.newOrder.dropOffs,
            firstDropOff: {
              ...state.newOrder.dropOffs.firstDropOff,
              driver: {
                ...state.newOrder.dropOffs.firstDropOff.driver,
                options: [],
                auto: action.state,
              },
            },

          }
        },
      };



    case actionTypes.set_PureـPickupDeliveryType_Pickup:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          pickup: {
            ...state.newOrder.pickup,
            driver: {
              ...state.newOrder.pickup.driver,
              ...action.state
            },
          }
        },
      };


    case actionTypes.set_PureـPickupDeliveryType_Dropoff:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          dropOffs: {
            ...state.newOrder.dropOffs,
            firstDropOff: {
              ...state.newOrder.dropOffs.firstDropOff,
              driver: {
                ...state.newOrder.dropOffs.firstDropOff.driver,
                ...action.state
              },
            },

          }
        },
      };

    case actionTypes.set_Force_Assaigning:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          driver: {
            ...state.newOrder.driver,
            forceAssaigning: action.state,
          },
        },
      };



    case actionTypes.set_Force_AssaigningـPickupDeliveryType_Pickup:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          pickup: {
            ...state.newOrder.pickup,
            driver: {
              ...state.newOrder.pickup.driver,
              forceAssaigning: action.state,
            },
          },
        },
      };


    case actionTypes.set_Force_AssaigningـPickupDeliveryType_Dropoff:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,

          dropOffs: {
            ...state.newOrder.dropOffs,
            firstDropOff: {
              ...state.newOrder.dropOffs.firstDropOff,
              driver: {
                ...state.newOrder.dropOffs.firstDropOff.driver,
                forceAssaigning: action.state,
              },
            },

          }

        },
      };



    case actionTypes.set_auto_activeVehicleType:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          driver: {
            ...state.newOrder.driver,
            options: [],
            auto_driver: {
              ...state.newOrder.driver.auto_driver,
              auto_activeVehicleType: action.state,
            },
          },
        },
      };


    case actionTypes.set_auto_activeVehicleTypeـPickupDeliveryType_Pickup:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          pickup: {
            ...state.newOrder.pickup,
            driver: {
              ...state.newOrder.pickup.driver,
              options: [],
              auto_driver: {
                ...state.newOrder.pickup.driver.auto_driver,
                auto_activeVehicleType: action.state,
              },
            },
          },
        },
      };

    case actionTypes.set_auto_activeVehicleTypeـPickupDeliveryType_Dropoff:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,

          dropOffs: {
            ...state.newOrder.dropOffs,
            firstDropOff: {
              ...state.newOrder.dropOffs.firstDropOff,
              driver: {
                ...state.newOrder.dropOffs.firstDropOff.driver,
                options: [],
                auto_driver: {
                  ...state.newOrder.dropOffs.firstDropOff.driver.auto_driver,
                  auto_activeVehicleType: action.state,
                },
              },
            },

          }

        },
      };

    case actionTypes.rebuildNewOrder:
      return {
        ...init,
        rebuildNewOrder: true,
      };
    case actionTypes.add_driver_option:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          driver: {
            ...state.newOrder.driver,
            options: [...state.newOrder.driver.options, action.state],
          },
        },
      };


    case actionTypes.add_driver_optionـPickupDeliveryType_Pickup:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          pickup: {
            ...state.newOrder.pickup,
            driver: {
              ...state.newOrder.pickup.driver,
              options: [...state.newOrder.pickup.driver.options, action.state],
            },
          },
        },
      };


    case actionTypes.add_driver_optionـPickupDeliveryType_Dropoff:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          dropOffs: {
            ...state.newOrder.dropOffs,
            firstDropOff: {
              ...state.newOrder.dropOffs.firstDropOff,
              driver: {
                ...state.newOrder.dropOffs.firstDropOff.driver,
                options: [...state.newOrder.dropOffs.firstDropOff.driver.options, action.state],
              },
            },

          }

        },
      };


    case actionTypes.remove_driver_option:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          driver: {
            ...state.newOrder.driver,
            options: state.newOrder.driver.options.filter(
              (item) => item.id !== action.state
            ),
          },
        },
      };


    case actionTypes.remove_driver_optionـPickupDeliveryType_Pickup:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          pickup: {
            ...state.newOrder.pickup,
            driver: {
              ...state.newOrder.pickup.driver,
              options: state.newOrder.pickup.driver.options.filter(
                (item) => item.id !== action.state
              ),
            },
          },
        },
      };

    case actionTypes.remove_driver_optionـPickupDeliveryType_Dropoff:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          dropOffs: {
            ...state.newOrder.dropOffs,
            firstDropOff: {
              ...state.newOrder.dropOffs.firstDropOff,
              driver: {
                ...state.newOrder.dropOffs.firstDropOff.driver,
                options: state.newOrder.dropOffs.firstDropOff.driver.options.filter(
                  (item) => item.id !== action.state
                ),
              },
            },

          }

        },
      };




    case actionTypes.set_manual_activeVehicleType:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          driver: {
            ...state.newOrder.driver,
            options: [],
            manual_driver: {
              ...state.newOrder.driver.manual_driver,
              ...action.state,
            }
          },
        },
      };
      case actionTypes.set_auto_suggestListData:
        return {
          ...state,
          newOrder: {
            ...state.newOrder,
            driver: {
              ...state.newOrder.driver,
              options: [],
              auto_driver: {
                ...state.newOrder.driver.auto_driver,
                auto_suggestListData: action.state,
              },
            },
          },
        };
  

      

  
    case actionTypes.set_manual_activeVehicleTypeـPickupDeliveryType_Pickup:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          pickup: {
            ...state.newOrder.pickup,
            driver: {
              ...state.newOrder.pickup.driver,
              options: [],
              manual_driver: {
                ...state.newOrder.pickup.driver.manual_driver,
                ...action.state,
              },
            },
          }
        },
      };

    case actionTypes.set_manual_activeVehicleTypeـPickupDeliveryType_Dropoff:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          dropOffs: {
            ...state.newOrder.dropOffs,
            firstDropOff: {
              ...state.newOrder.dropOffs.firstDropOff,
              driver: {
                ...state.newOrder.dropOffs.firstDropOff.driver,
                options: [],
                manual_driver: {
                  ...state.newOrder.dropOffs.firstDropOff.driver.manual_driver,
                  ...action.state,
                },
              },
            },

          }

        },
      };
    case actionTypes.set_searchDriverInput:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          driver: {
            ...state.newOrder.driver,
            manual_driver: {
              ...state.newOrder.driver.manual_driver,
              searchDriverInput: action.state,
            },
          },
        },
      };

    case actionTypes.set_searchDriverInputـPickupDeliveryType_Pickup:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          pickup: {
            ...state.newOrder.pickup,
            driver: {
              ...state.newOrder.pickup.driver,
              manual_driver: {
                ...state.newOrder.pickup.driver.manual_driver,
                searchDriverInput: action.state,
              },
            },
          },
        },
      };


    case actionTypes.set_searchDriverInputـPickupDeliveryType_Dropoff:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,

          dropOffs: {
            ...state.newOrder.dropOffs,
            firstDropOff: {
              ...state.newOrder.dropOffs.firstDropOff,
              driver: {
                ...state.newOrder.dropOffs.firstDropOff.driver,
                manual_driver: {
                  ...state.newOrder.dropOffs.firstDropOff.driver.manual_driver,
                  searchDriverInput: action.state,
                },
              },
            },

          }

        },
      };


    case actionTypes.set_pickup_inputs:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          pickup: {
            ...state.newOrder.pickup,
            [action.state.name]: action.state.value,
          },
        },
      };
    case actionTypes.set_pickup:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          pickup: {
            ...state.newOrder.pickup,
            ...action.state,
          },
        },
      };

    case actionTypes.add_dropoff:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          dropOffs: {
            ...state.newOrder.dropOffs,
            otherDropOffs: [
              ...state.newOrder.dropOffs.otherDropOffs,
              { ...sampleDropOff, id: action.state },
            ],
          },
        },
      };
    case actionTypes.remove_dropoff:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          activePickupDropOff:
            state.newOrder.activePickupDropOff.id === action.state
              ? {
                type: null,
                id: "",
              }
              : state.newOrder.activePickupDropOff,
          dropOffs: {
            ...state.newOrder.dropOffs,
            otherDropOffs: state.newOrder.dropOffs.otherDropOffs.filter(
              (drop) => drop.id !== action.state
            ),
          },
        },
      };

    case actionTypes.set_activePickupDropOff:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          activePickupDropOff:
            state.newOrder.activePickupDropOff.id === action.state.id
              ? {
                type: null,
                id: "",
              }
              : action.state,
        },
      };
    case actionTypes.set_dropOff_inputs:
      if (action.state.firstDropOff) {
        return {
          ...state,
          newOrder: {
            ...state.newOrder,
            dropOffs: {
              ...state.newOrder.dropOffs,
              firstDropOff: {
                ...state.newOrder.dropOffs.firstDropOff,
                [action.state.name]: action.state.value,
              },
            },
          },
        };
      } else {
        return {
          ...state,
          newOrder: {
            ...state.newOrder,
            dropOffs: {
              ...state.newOrder.dropOffs,
              otherDropOffs: state.newOrder.dropOffs.otherDropOffs.map(
                (DropOff) =>
                  DropOff.id === action.state.id
                    ? {
                      ...DropOff,
                      [action.state.name]: action.state.value,
                    }
                    : DropOff
              ),
            },
          },
        };
      }

      case actionTypes.set_dropOff_inputs_bulk:
        if (action.state.firstDropOff) {
          return {
            ...state,
            newOrder: {
              ...state.newOrder,
              dropOffs: {
                ...state.newOrder.dropOffs,
                firstDropOff: {
                  ...state.newOrder.dropOffs.firstDropOff,
                  ...action.state.value
                },
              },
            },
          };
        } else {
          return {
            ...state,
            newOrder: {
              ...state.newOrder,
              dropOffs: {
                ...state.newOrder.dropOffs,
                otherDropOffs: state.newOrder.dropOffs.otherDropOffs.map(
                  (DropOff) =>
                    DropOff.id === action.state.id
                      ? {
                        ...DropOff,
                        ...action.state.value
                      }
                      : DropOff
                ),
              },
            },
          };
        }
    case actionTypes.set_DropOff_fields_Dnd:
      if (action.state.firstDropOff) {
        return {
          ...state,
          newOrder: {
            ...state.newOrder,
            dropOffs: {
              ...state.newOrder.dropOffs,
              firstDropOff: {
                ...state.newOrder.dropOffs.firstDropOff,
                ...action.state.value,
              },
            },
          },
        };
      } else {
        return {
          ...state,
          newOrder: {
            ...state.newOrder,
            dropOffs: {
              ...state.newOrder.dropOffs,
              otherDropOffs: state.newOrder.dropOffs.otherDropOffs.map(
                (DropOff, i) =>
                  i === action.state.index ? { ...action.state.value }
                    : DropOff
              ),
            },
          },
        }
      }

    case actionTypes.set_SearchAutoComplete:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          suggestListPickUpDropOffLoading: false,
          suggestPickUpDropOffListData: action.state,
        },
      };
    case actionTypes.set_SearchAutoCompleteLoading:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          suggestListPickUpDropOffLoading: action.state,
          suggestListPickUpDropOffOrdered:
            !state.newOrder.suggestListPickUpDropOffOrdered && true,
        },
      };
    case actionTypes.set_driver_auto_vehicleTypes:


      if (action.state.length > 0) {
        var mustResetVehicleType = true;
        if (state.newOrder.driver.auto_driver.auto_activeVehicleType) {
          action.state.forEach((element) => {
            if (element.id == state.newOrder.driver.auto_driver.auto_activeVehicleType.id) {
              mustResetVehicleType = false;
            }
          });
        }
        return {
          ...state,
          newOrder: {
            ...state.newOrder,
            driver: {
              ...state.newOrder.driver,
              options: mustResetVehicleType ? [] : state.newOrder.driver.options,
              auto_driver: {
                ...state.newOrder.driver.auto_driver,
                mustResetVehicleType: mustResetVehicleType,
                auto_activeVehicleType: mustResetVehicleType ? action.state[0] : state.newOrder.driver.auto_driver.auto_activeVehicleType,
                auto_suggestListLoading: false,
                auto_suggestListData: action.state,
                auto_suggestListOrdered: true,
              },
            },
          },
        };
      } else {
        return {
          ...state,

          newOrder: {
            ...state.newOrder,
            driver: {
              ...state.newOrder.driver,
              options: [],
              auto_driver: {
                ...state.newOrder.driver.auto_driver,
                auto_activeVehicleType: null,
                auto_suggestListLoading: false,
                auto_suggestListData: [],
                auto_suggestListOrdered: true,
              },
            },
          },
        };
      }




    case actionTypes.setRecentAddress:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          customer: {
            ...state.newOrder.customer,
            recentAddreess: action.state
          },
        },
      };



    case actionTypes.newTravelPickupSetReserve:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          pickup: {
            ...state.newOrder.pickup,
            [action.state.name]: action.state.value,
          },
        },
      };


    case actionTypes.newTravelResetReserve:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          pickup: {
            ...state.newOrder.pickup,
            scheduleDateBefore: null,
            scheduleDateAfter: null,
          },
          dropOffs: {
            ...state.newOrder.dropOffs,
            firstDropOff: {
              ...state.newOrder.dropOffs.firstDropOff,
              scheduleDateBefore: null,
              scheduleDateAfter: null,
            },
            otherDropOffs: state.newOrder.dropOffs.otherDropOffs.map((Drop) => {
              return {
                ...Drop,
                scheduleDateBefore: null,
                scheduleDateAfter: null,
              }
            }),
          }
        },
      };


    case actionTypes.newTravelDropOffSetReserve:
      if (action.state.firstDropOff) {
        return {
          ...state,
          newOrder: {
            ...state.newOrder,
            dropOffs: {
              ...state.newOrder.dropOffs,
              firstDropOff: {
                ...state.newOrder.dropOffs.firstDropOff,
                [action.state.name]: action.state.value,
              },
            },
          },
        };
      } else {
        return {
          ...state,
          newOrder: {
            ...state.newOrder,
            dropOffs: {
              ...state.newOrder.dropOffs,
              otherDropOffs: state.newOrder.dropOffs.otherDropOffs.map(
                (DropOff) =>
                  DropOff.id === action.state.id
                    ? {
                      ...DropOff,
                      [action.state.name]: action.state.value,
                    }
                    : DropOff
              ),
            },
          },
        };
      }
    case actionTypes.resetneworder:
      return init;
    case actionTypes.NewTravelPaymentMethod:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          paymentMethod: { ...state.newOrder.paymentMethod, ...action.state },
        },
      };

    case actionTypes.set_new_order_OrderType:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          orderTypeActive: action.state,
        },
      };


    case actionTypes.new_Order_Reset_Pickup:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          pickup: init.newOrder.pickup,
        },
      };


    case actionTypes.new_Order_Reset_DropOff:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          dropOffs: {
            ...state.newOrder.dropOffs,
            firstDropOff: init.newOrder.dropOffs.firstDropOff,
          }
        },
      };



    case actionTypes.new_Order_Reset_DropOffs:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          dropOffs: {
            ...state.newOrder.dropOffs,
            otherDropOffs: [],
          }
        },
      };

    case actionTypes.editPriceNewOrder:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          price: {
            ...state.newOrder.price,
            changed: true,
            price: action.state,
          },
        },
      };

    case actionTypes.editPriceNewOrderChanged:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          price: {
            ...state.newOrder.price,
            changed: action.state,

          },
        },
      };

      case actionTypes.editPriceNewOrder_manualy:
        return {
          ...state,
          newOrder: {
            ...state.newOrder,
            price: {
              ...state.newOrder.price,
              manually_changed: action.state,
  
            },
          },
        };
      


    case actionTypes.editPrice_PromoCode_NewOrderChanged:
      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          price: {
            ...state.newOrder.price,
            promoCode: action.state,

          },
        },
      };


      case actionTypes.set_autoDriver_vehicles_auto_suggestListData:

      return {
        ...state,
        newOrder: {
          ...state.newOrder,
          driver: {
            ...state.newOrder.driver,
            auto_driver: {
              ...state.newOrder.driver.auto_driver,
              auto_suggestListLoading: false,
              auto_suggestListData: action.state,
              auto_suggestListOrdered: true,
            },
          },
        },
      };
    default:
      return state;
  }
};
