import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import Slide from "@material-ui/core/Slide";
import ListEvent from "./listEvent";
import CreateEvent from "./CreateEvent";
import { Set_New_Event, Set_viewOrder_events } from "../../../../../../Redux/actions/actionNames";

class App extends React.Component {
  constructor(props){
    super(props);
    this.state={
      createDialoge : false
    }
  }


  handleShowCreateDialoge =()=>{

    this.props.dispatch(Set_New_Event({show :!this.props.showNewEventTimeLine}))
    this.props.dispatch(Set_viewOrder_events({ activeEventCordinator: null }))

  }

  render() {
    return (
      <Slide
        direction="down"
        in={this.props.showEventTimeLine}
        mountOnEnter
        unmountOnExit
      >
        <div className="EventTimeLineContainer">
          {
            this.props.showNewEventTimeLine ? (
              <CreateEvent showCreateDialoge={this.handleShowCreateDialoge}  />
            ):
            (<ListEvent showCreateDialoge={this.handleShowCreateDialoge}/>)
          }
          
          
        </div>
      </Slide>
    );
  }
}

const mapStateToProps = (state) => ({
    showEventTimeLine:state.ViewTravel.eventTimeLine.show,
    showNewEventTimeLine:state.ViewTravel.eventTimeLine.newEvent.show,

});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
