import React from "react";
import clsx from "clsx";
import styles from './style.module.css';
import colors from '../colors/colors.module.css';
import { ButtonProps } from "./Button.types";
import IconProvider from "../IconProvider";
import ProgressIndicator from "../ProgressIndicator";
import TooltipOnro from "../Tooltip";
import Typography from "../Typography";
import ControlledTooltip from "../ControlledTooltip";
import useDimensions from "react-use-dimensions";


const Button: React.FC<ButtonProps> = ({ text, style, className, ref, isLoading, onClick,
    size, type, badge, disabled, icon, isActive, isRequired, requiredText }: ButtonProps) => {
    const [containerRef, { x, y, width }] = useDimensions();

    console.log("widssth ", width, text)

    if (width && width == 147) {
        return (
            <TooltipOnro tooltipClassName={styles.tooltipClassName} title={<Typography weight="Body_Small_Medium" text={text} style={{marginTop:"7px",padding:"0px 10px"}} />} placement='top-start' >
                <div onClick={(e) => { (onClick && !disabled) && onClick(e) }} ref={containerRef} className={clsx(styles[type],
                    styles[`${type}${size}`], disabled && styles[`${type}disabled`], isLoading &&
                styles[`${type}isLoading`], isActive && styles[`${type}isActive`], isActive && styles[`${type}${size}isActive`], icon && styles[`${type}${size}icon`], className && className)}
                    style={style ? style : {}} >

                    {(icon && !isLoading) && <IconProvider Icon={icon} size="16" />}
                    {(text && !isLoading) && <span > {text} </span>}
                    {(badge && typeof badge == "boolean") && <span className={styles.circleBadge} />}
                    {isLoading && <ProgressIndicator theme={type == "Secondary" ? "On_Light" : "On_Dark"} style={{ fontSize: "7px" }} />}
                    {(isRequired && !isLoading && !isActive) && <span className={styles.isRequired}> {requiredText} </span>}

                </div>
            </TooltipOnro>
        );
    } else {
       return <div onClick={(e) => { (onClick && !disabled) && onClick(e) }} ref={containerRef} className={clsx(styles[type],
            styles[`${type}${size}`], disabled && styles[`${type}disabled`], isLoading &&
        styles[`${type}isLoading`], isActive && styles[`${type}isActive`], isActive && styles[`${type}${size}isActive`], icon && styles[`${type}${size}icon`], className && className)}
            style={style ? style : {}} >

            {(icon && !isLoading) && <IconProvider Icon={icon} size="16" />}
            {(text && !isLoading) && <span > {text} </span>}
            {(badge && typeof badge == "boolean") && <span className={styles.circleBadge} />}
            {isLoading && <ProgressIndicator theme={type == "Secondary" ? "On_Light" : "On_Dark"} style={{ fontSize: "7px" }} />}
            {(isRequired && !isLoading && !isActive) && <span className={styles.isRequired}> {requiredText} </span>}

        </div>
    }

};

export default Button;

