import React, { useEffect, useLayoutEffect, useState } from "react";
import { Map, Marker, TileLayer, Popup, Polyline } from "react-leaflet";
import L from "leaflet";
import Pin from "../Pin";
import ReactDOMServer from "react-dom/server";
import { useDispatch, useSelector } from "react-redux";
import styles from './style.module.css';
import { SetDriverPopUp, SetOrderPinPopUp, SetOrderPinPopUpPosition, SetSelectedMonitoringDriver, SetSelectedMonitoringOrder } from "../../../../Redux/actions/actionNames";
import { GetColorByStatus } from "../../../../utils/get-colors-by-status";
import { useMonitoringPin } from "../../../../hooks/useMonitoringPin";
import { getOrderMonitoringBounding } from "../getOrderMonitoring";
import { isPickupDelivery } from "../../side-bar/components/orderTypes";

const createtIcon = (color, selectedOrder) => {
  return new L.divIcon({
    className: "",
    // html: ReactDOMServer.renderToString( <Pin color={"#"+color} style={ !selectedOrder && {opacity:".2"}}/>),
    html: ReactDOMServer.renderToString(<Pin color={"#" + color} style={!selectedOrder && { opacity: ".2" }} />),
    iconSize: [21, 37],
    iconAnchor: [9.5, 33],
  });
};

// const CreateIconSelectedOsm = (id, color, selectedOrder, openPopUp) => {
//   const [width, setWidth] = useState(0);
//   
//   useLayoutEffect(() => {
//     if (openPopUp && !width) {
//       var element = document.getElementById(`${id}popup`);
//       var positionInfo = element && element.getBoundingClientRect();
//       var width = positionInfo && positionInfo.width;
//       width && setWidth(width);
//     }
//   }, [openPopUp]);

//   return <div>
//     <Pin color={"#" + color} style={!selectedOrder && { opacity: ".2" }} />

//     {openPopUp && <div id={`${id}popup`} className={"monitoringDriverSelected markerIcon " + styles.OsmMarkerPopUp} style={{ left: `-${(width / 2) - 27.5}px` }}>
//       <span className={styles.OsmMarkerPopUpName}> Dropoff </span>
//       <span className={styles.OsmMarkerPopUpButton}> Order details </span>
//     </div>}

//   </div>

// };


const App = ({ position, id, order }) => {
  let { orderIsSelected } = useMonitoringPin(order.id, order.driverId, id)

  const dispatch = useDispatch();


  function OrderOnclick() {
    dispatch(SetSelectedMonitoringOrder(order.id))
    !isPickupDelivery() &&    dispatch(SetSelectedMonitoringDriver(order.driverId || "xxxxx"))
    dispatch(SetDriverPopUp(null))
    dispatch(SetOrderPinPopUp(id))
    dispatch(SetOrderPinPopUpPosition({ position: position, tag: "Dropoff2", orderId: order.id, customerId: order.customerId }))

    !isPickupDelivery() &&    getOrderMonitoringBounding(order)

  }

  if (!orderIsSelected) {
    return null
  }

  return <Marker
    animate={true}
    position={position}
    icon={createtIcon(GetColorByStatus(order.status , order), orderIsSelected)}
    onClick={OrderOnclick}

  ></Marker>
};

export default App


