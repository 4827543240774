import { Component } from "react";
import { connect } from "react-redux";
import { CalculatePriceWorker } from "../../../../../../helper/worker/CalculatePriceWorker";

class App extends Component {
  componentDidUpdate() {}

  componentWillReceiveProps(nextProps) {
    if((nextProps.price.price !== this.props.price.price) || (nextProps.operatorChanged !== this.props.operatorChanged)){
      CalculatePriceWorker(
  {
    customerType:nextProps.person ? "individual" :"business",
    serviceactiveVehicleType: nextProps.serviceactiveVehicleType,
    serviceoptions: nextProps.serviceoptions,
    auto: nextProps.auto,
    driveroptions: nextProps.driveroptions,
    auto_activeVehicleType: nextProps.auto_activeVehicleType,
    manual_activeVehicleType: nextProps.manual_activeVehicleType,
    pickup: {
      address: nextProps.pickup.address,
      // block: nextProps.pickup.block,
      // floor: nextProps.pickup.floor,
      // senderFullName: nextProps.pickup.senderFullName,
      // senderPhoneNumber: nextProps.pickup.senderPhoneNumber,
      // unit: nextProps.pickup.unit,
    },
    pickupadresss_loc: nextProps.pickupadresss_loc,
    firstDropOff: {
      address: nextProps.firstDropOff.address,
      // block: nextProps.firstDropOff.block,
      // floor: nextProps.firstDropOff.floor,
      // recieverFullName: nextProps.firstDropOff.recieverFullName,
      // recieverPhoneNumber: nextProps.firstDropOff.recieverPhoneNumber,
      // unit: nextProps.firstDropOff.unit,
    },
    firstDropOffadresss_loc: nextProps.firstDropOffadresss_loc,
    otherdropOffs: nextProps.otherdropOffs,
  },
  this.props.dispatch ,true
);
  }
  }

  render() {
    return null;
  }
}

const mapStateToProps = (state) => ({
  operatorChanged: state.EditOrder.operatorChanged,
  paymentMethod: state.EditOrder.paymentMethod,
  price: state.EditOrder.editOrder.price,
  serviceactiveVehicleType: state.EditOrder.editOrder.service.activeVehicleType,
  serviceoptions: state.EditOrder.editOrder.service.options,
  auto: state.EditOrder.editOrder.driver.auto,
  driveroptions: state.EditOrder.editOrder.driver.options,
  auto_activeVehicleType:
    state.EditOrder.editOrder.driver.auto_driver.auto_activeVehicleType,
  manual_activeVehicleType:
    state.EditOrder.editOrder.driver.manual_driver.manual_activeVehicleType,
  pickup: state.EditOrder.editOrder.pickup,
  pickupadresss_loc: state.EditOrder.editOrder.pickup.adresss_loc,
  firstDropOff: state.EditOrder.editOrder.dropOffs.firstDropOff,
  firstDropOffadresss_loc:
    state.EditOrder.editOrder.dropOffs.firstDropOff.adresss_loc,
  otherdropOffs: state.EditOrder.editOrder.dropOffs.otherDropOffs,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
