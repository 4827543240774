import * as actionTypes from "../actions/actionTypes";
import React from "react";
import { getMapCenterByUrl } from "../../helper/module";
import {get_defaultLocation} from "../../helper/UserData/userdate"
var _ = require("lodash");

const init = {
  loaded:false ,
  realtimePlaceName: {
    address: "",
    coordinates: [],
    placeId: null,
    loading: true,
  },
  zoom: 14,
  center: get_defaultLocation() || global.config.MapCenter /*{ lng: "51.404343", lat: "35.694654" }*/,
  mapRef: React.createRef(),
  osmFeatureGroup:React.createRef(),
  monitoring: {
    active: false,
    haveorder:true,
    activeVehicleType: [],
    vehicleTypes: [],
    drivers:[]
  },
};
export const Map = (state = init, action) => {
  switch (action.type) {
        
    case actionTypes.set_map_loaded:
      return {
        ...state,
        loaded: action.state,
      }
    
    case actionTypes.setMonitoringHaveOrder:
      return {
        ...state,
        monitoring: {
          ...state.monitoring,
          haveorder: action.state,
        },
      }
    case actionTypes.set_Zoom:
      return {
        ...state,
        zoom: action.state,
      };
      case actionTypes.set_REF:
        return {
          ...state,
          mapRef: action.state,
        };
    case actionTypes.set_latlng:
      return {
        ...state,
        center: action.state,
      };
    case actionTypes.set_realtimePlaceName:
      return {
        ...state,
        realtimePlaceName: {
          ...state.realtimePlaceName,
          ...action.state,
        },
      };
    case actionTypes.set_realtimePlaceNameLoading:
      return {
        ...state,
        realtimePlaceName: {
          ...state.realtimePlaceName,
          loading: action.state,
        },
      };
    case actionTypes.monitoringOnOff:
      return {
        ...state,
        monitoring: {
          ...state.monitoring,
          active: !state.monitoring.active,
          activeVehicleType: !state.monitoring.active
            ? state.monitoring.vehicleTypes.map((service) => {
                return service.id;
              })
            : [],
        },
      };

    case actionTypes.monitoringSet_VehicleTypes:
      return {
        ...state,
        monitoring: {
          ...state.monitoring,
          vehicleTypes: action.state,
        },
      };

    case actionTypes.monitoringAddVehicleType:
      return {
        ...state,
        monitoring: {
          ...state.monitoring,
          activeVehicleType: [
            ...state.monitoring.activeVehicleType,
            action.state,
          ],
        },
      };

    case actionTypes.monitoringRemoveVehicleType:
      return {
        ...state,
        monitoring: {
          ...state.monitoring,
          activeVehicleType: state.monitoring.activeVehicleType.filter(
            (serv) => serv !== action.state
          ),
        },
      };
    case actionTypes.setMonitoringDrivers:
      return {
        ...state,
        monitoring: {
          ...state.monitoring,
          drivers: _.keyBy(action.state, "id"),
        },
      };

    default:
      return state;
  }
};

export default Map;
