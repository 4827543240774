import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import box from "../../../../../../../assets/box-copy.svg";
import { withStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import { withTranslation, Trans } from "react-i18next";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import Grow from "@material-ui/core/Grow";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { Remove_Service_Option_bulkImport, Add_Service_Option_bulkImport } from "../../../../../../../Redux/actions/actionNames";
import { get_lang } from "./../../../../../../../helper/UserData/userdate";

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showpoper: false,
      active:false,
      selected:null
    };
    this.anchorEl = React.createRef();
  }

  handleactive = () => {
    if (this.optionisSaved()) {
      this.props.dispatch(Remove_Service_Option_bulkImport(this.props.id,this.props.bulkId))
    } else {
      this.props.dispatch(Add_Service_Option_bulkImport({
        name:this.props.name,
        id:this.props.id,
        type:"boolean"
      },this.props.bulkId))
    }

  };


  optionisSaved() {
    let booll = false;
     booll = this.props.options.find((item) => {
      return item.id === this.props.id;
    });
    if(booll){
      return true
    }else{
      return false
    }
  }

  render() {
    const { t } = this.props;
    return (
      <React.Fragment >
        <clickableservice ref={this.anchorEl} id={this.props.id} onClick={this.handleactive} className={`blimopit ${this.optionisSaved() && "avtiveServiceOptions blimopitActive"}`} style={{margin:"0px"}}>
          {this.props.title}
        </clickableservice>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state,ownProps) => ({
  activeVehicleType: ownProps.ActiveItem.service.selectedService ,
  options:ownProps.ActiveItem.service.options,
  lang :state.LocalData.lang ,
  bulkId : ownProps.ActiveItem.id
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
