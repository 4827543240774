import momentJalaali from "moment-jalaali";
import { AddMonitoringOrdersBounding } from "../../../../../Redux/actions/actionNames";
import { store } from "../../../../../Redux/store";
import { OrderApi } from "../../../../../service/API/order";
const _ = require("lodash");

export const getOrderMonitoringBounding = async (driver) => {

  let storeData = store.getState();

  let selectedStatus = JSON.parse(storeData.OrderList.selectedStatus)
  let selectedServices = JSON.parse(storeData.OrderList.selectedServices)
  let selectedVehicleType = JSON.parse(storeData.OrderList.selectedVehicleType)
  let selectedZone = JSON.parse(storeData.OrderList.zones)

  let regex = [] ;

  if(storeData.OrderList.search.regexText && String(storeData.OrderList.search.regexText).length > 0){
    storeData.OrderList.search.regexFields.value.forEach(element => {
      regex.push({text:storeData.OrderList.search.regexText,field:element})
      });
  }
  

  let params = {
    "dueDate[gte]": storeData.OrderList.DateRange
      ? momentJalaali(storeData.OrderList.DateRange.startDate).unix() + "000"
      : null,
    "dueDate[lte]": storeData.OrderList.DateRange
      ? (storeData.OrderList.DateRange.endDate ? momentJalaali(storeData.OrderList.DateRange.endDate).unix() + "000" : null)
      : null,
    services: selectedServices,
    zones : selectedZone,
    status: selectedStatus ,
    vehicleTypes: selectedVehicleType,
    isScheduled:  ((storeData.OrderList.now && storeData.OrderList.isScheduled) || (!storeData.OrderList.now && !storeData.OrderList.isScheduled) ) ? null :  (   (storeData.OrderList.now && !storeData.OrderList.isScheduled ) ? false :  true),
    regex : regex.length > 0 ? regex : null,

    // regexText: storeData.OrderList.search.regexText,
    // regexFields: String(storeData.OrderList.search.regexText).length > 0 ? storeData.OrderList.search.regexFields.value : [],
    type: storeData.OrderList.ActiveOrderType,
    driverId : driver.id
  }

  let Order = new OrderApi()
  let data = await Order.Monitoring(removeNull(params))
  store.dispatch(AddMonitoringOrdersBounding({ordersBoundingList : data.data , ordersBoundingDriver: driver},"36"))


};

const removeNull = (objIn) => {
  let objOut = objIn;
  Object.keys(objOut).forEach(key => {
    if (objOut[key] === null) {
      delete objOut[key];
    }
  });
  return objOut
}