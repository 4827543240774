import React from "react";
import { connect } from "react-redux";
import originIcon from "../../assets/pin_pickup.svg";
import destinationIcon from "../../assets/pin_dropoff.svg";
import {
  Set_Pickup_address,
  Set_DropOff_Inputs,
  Edit_Travel_set_pickup,
  Edit_Travel_set_Dropoff_inputs,
} from "../../Redux/actions/actionNames";
import { withTranslation, Trans } from "react-i18next";
import { GetLocationPlacenameAction } from "../../Redux/actions/actions";
import { getCenterMap } from "../../helper/module";
import ReactDOMServer from "react-dom/server";

import PickupPin from "./map-pickup-pin/Pin";
import DropOffPin from "./map-dropoff-pin/Pin";
class App extends React.Component {
  markerOnClick = () => {
   
    let center = getCenterMap(this.props.mapRef , this.props.center)

    this.props.dispatch(
      GetLocationPlacenameAction(
        this.props.dispatch,
        {
          latitude:  center.lat,
          longitude: center.lng,
        },
        (RES) => {
          let originShow = this.props.activePickupDropOff.type === "pickup";
          let destinationShow =
            this.props.activePickupDropOff.type === "dropoff";
          if (originShow) {
            this.props.dispatch(
              Edit_Travel_set_pickup({
                address: this.props.address,
                placeId: this.props.placeId,
                adresss_loc: {
                  lng: center.lng,
                  lat: center.lat,
                },
              })
            );
          } else {
            this.props.dispatch(
              Edit_Travel_set_Dropoff_inputs({
                name: "address",
                value: this.props.address,
                firstDropOff: this.props.activePickupDropOff.firstDropOff,
                id: this.props.activePickupDropOff.id,
              })
            );
            this.props.dispatch(
              Edit_Travel_set_Dropoff_inputs({
                name: "placeId",
                value: this.props.placeId,
                firstDropOff: this.props.activePickupDropOff.firstDropOff,
                id: this.props.activePickupDropOff.id,
              })
            );
            this.props.dispatch(
              Edit_Travel_set_Dropoff_inputs({
                name: "adresss_loc",
                value: {
                  lng: center.lng,
                  lat: center.lat,
                },
                firstDropOff: this.props.activePickupDropOff.firstDropOff,
                id: this.props.activePickupDropOff.id,
              })
            );
          }
        }
      )
    );
  };

  othersDestinationShowAfterSelect = () => {
    let selectedDropoof = this.props.otherDropOffs.find((drop) => {
      return drop.id === this.props.activePickupDropOff.id;
    });
    if (selectedDropoof) {
      if (selectedDropoof.adresss_loc.lng === "") {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  render() {
    let originShow =
      this.props.activePickupDropOff.type === "pickup" &&
      this.props.pickupAdresss_loc.lng === "";
    let destinationShow =
      this.props.activePickupDropOff.type === "dropoff" &&
      this.props.dropOffsAdresss_loc.lng === "";
    let othersDestinationShow =
      this.props.activePickupDropOff.type === "dropoff"  &&
      !this.props.activePickupDropOff.firstDropOff;

    let othersDestinationShowAfterSelect = this.othersDestinationShowAfterSelect();

    return (
      <div>
        
        {(this.props.OpenDialoge  && !this.props.FavAddressOpenDialoge && originShow) && (
          <img
            onClick={this.markerOnClick}
            src={'data:image/svg+xml;charset=utf-8,' +
            encodeURIComponent(ReactDOMServer.renderToStaticMarkup(<PickupPin color={"#1795ff"}/>))}
            alt=""
            className={`originShow ${global.config.dispatcherSettings.applicationMap.type == "google" && "originShowGoogle"} ${this.props.hideNavbar && 'hideNavbarOriginShow'}`}
            />
        )}

        {this.props.OpenDialoge  && !this.props.FavAddressOpenDialoge && destinationShow && (
          <img
            onClick={this.markerOnClick}
            src={'data:image/svg+xml;charset=utf-8,' +
            encodeURIComponent(ReactDOMServer.renderToStaticMarkup(<DropOffPin color={"#0064d2"}/>))}
            alt=""
            className={`originShow ${global.config.dispatcherSettings.applicationMap.type == "google" && "originShowGoogle"} ${this.props.hideNavbar && 'hideNavbarOriginShow'}`}
            />
        )}

        {this.props.OpenDialoge  && !this.props.FavAddressOpenDialoge &&
          othersDestinationShow &&
          othersDestinationShowAfterSelect && (
            <img
              onClick={this.markerOnClick}
              src={'data:image/svg+xml;charset=utf-8,' +
            encodeURIComponent(ReactDOMServer.renderToStaticMarkup(<DropOffPin color={"#0064d2"}/>))}
              alt=""
              className={`originShow ${global.config.dispatcherSettings.applicationMap.type == "google" && "originShowGoogle"} ${this.props.hideNavbar && 'hideNavbarOriginShow'}`}
            />
          )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
 mapRef: state.Map.mapRef,
  center: state.Map.center,
  FavAddressOpenDialoge: state.FavAddress.openDialoge,
  OpenDialoge: state.EditOrder.OpenDialoge,
  center: state.Map.center,
  activePickupDropOff: state.EditOrder.editOrder.activePickupDropOff,
  loading: state.Map.realtimePlaceName.loading,
  address: state.Map.realtimePlaceName.address,
  placeId: state.Map.realtimePlaceName.placeId,
  pickupAddress: state.EditOrder.editOrder.pickup.address,
  pickupAdresss_loc: state.EditOrder.editOrder.pickup.adresss_loc,
  dropOffAddress: state.EditOrder.editOrder.dropOffs.firstDropOff.address,
  dropOffsAdresss_loc:
    state.EditOrder.editOrder.dropOffs.firstDropOff.adresss_loc,
  otherDropOffs: state.EditOrder.editOrder.dropOffs.otherDropOffs,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
