import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import person from "../../../../assets/Image1.svg";
import email from "../../../../assets/email.svg";
import car from "../../../../assets/Image2.svg";
import sign from "../../../../assets/Image4.svg";
import circle from "../../../../assets/time-circle.svg";
import jalali from "jalali-moment";
import vehicletypes from "../../../../assets/Image3.svg";
import { get_lang } from "../../../../helper/UserData/userdate";

class App extends React.Component {


  getvehicletypes =()=>{
    let arr = [];
    this.props.driverInfo.vehicleTypes.forEach(element => {
      arr.push(element.title)  ;
    });

    return arr.join(",")
  }
  
  render() {
    if( !this.props.driverInfo ){
      return null
    }
    return (
      <React.Fragment>
        <div style={{backgroundColor:"white",borderTop:"1px solid rgba(244, 244, 247, 1)"}}>
          <div class="driverItemInfocontainer">
            <img src={person} class="icon" />
            <div class="keyvalue">
              <span class="value">{this.props.driverInfo.username}</span>
              <span class="key"> {this.props.driverInfo.email}</span>
            </div>
          </div>


          {/* <div class="driverItemInfocontainer">
            <img src={email} class="icon" />
            <div class="keyvalue">
              <span class="value"> {this.props.driverInfo.email}</span>
              <span class="key"> <Trans i18nKey={"email"}> </Trans> </span>
            </div>
          </div> */}

          <div class="driverItemInfocontainer">
            <img src={car} class="icon" />
            <div class="keyvalue">
              <span class="value"> {this.props.driverInfo.vehicle.plate}</span>
              <span class="key"> {`${this.props.driverInfo.vehicle.color} ${this.props.driverInfo.vehicle.model}`} </span>
            </div>
          </div>

          <div class="driverItemInfocontainer" style={{
                height: "auto",
                minHeight: "54px"
          }}>
            <img src={vehicletypes} class="icon" />
            <div class="keyvalue">
              <span class="value" style={{height:"auto"}}> {this.getvehicletypes()}</span>
              <span class="key" style={{marginBottom:"10px"}}> <Trans i18nKey={"vehicletypes"}> </Trans> </span>
            </div>
          </div>

          <div class="driverItemInfocontainer">
            <img src={sign} class="icon" />
            <div class="keyvalue">
              <span class="value"> <Trans i18nKey={this.props.driverInfo.isLoggedIn ? "haslogined" : "Notloggedin"}> </Trans></span>
              <span class="key"><Trans i18nKey={"lastonlinetime"}> </Trans>{jalali(new Date(this.props.driverInfo.lastOnlineTime)).locale(get_lang()).format(" D MMM HH:mm")} </span>
            </div>
          </div>


{/* 
          <div class="driverItemInfocontainer">
            <img src={circle} class="icon" />
            <div class="keyvalue">
              <span class="value"> {jalali(new Date(this.props.driverInfo.lastOnlineTime)).locale(get_lang()).format("YYYY/MM/DD  HH:mm")}</span>
              <span class="key"> <Trans i18nKey={"lastonlinetime"}> </Trans> </span>
            </div>
          </div> */}

        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  driverInfo: state.DriversList.driverInfo,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
