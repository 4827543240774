import * as actionTypes from "../actions/actionTypes";



const init = {
  history: 0,
  driver_location : null,
  pricedetailsOpen:false ,
  pickup_dropoff_details:null,
  offering_details:null,
  orderNotFound:false,
  get_order_error:false,
  eventTimeLine: {
    show: false,
    loading: false,
    data: [],
    error: false,
    activeEventCordinator: null,
    newEvent:{
      show:false,
      mapLocation:null,
      canSelectLocation:false
    }
  },
  DriverAndStatus: {
    EditSaDStatusText: null,
    auto: true,
    options: [],
    forceAssaigning: false,
    auto_driver: {
      auto_activeVehicleType: null,
      auto_suggestListLoading: true,
      auto_suggestListData: [],
    },
    manual_driver: {
      driverAllvehicletypes: [],
      searchDriverInput: "",
      manual_activeVehicleType: null,
      manual_name: "",
      manual_family: "",
      manual_number: "",
      manual_id: "",
      manual_code: "",
      manual_suggestListLoading: false,
      manual_suggestListData: [],
      manual_suggestListOrdered: false,
    },
  },
  OpenDialoge: false,
  loading: false,
  error: false,
  ID: null,
  Order_SubId:[],
  customerId: null,
  pureData: null,
  estimatedRoute: {
    active: false,
    waypoints: null,
  },
};
export const ViewTravel = (state = init, action) => {
  switch (action.type) {

    case actionTypes.setOrderNotFound:
      return {
        ...state,
        orderNotFound: action.state,
      };
      case actionTypes.setorderError:
        return {
          ...state,
          get_order_error: action.state,
        };
      
    case actionTypes.setDriverLocation:
      return {
        ...state,
        driver_location: action.state,
      };
    case actionTypes.offeringDetailsOpen:
      return {
        ...state,
        offering_details: action.state,
      };

    case actionTypes.setViewOrderPickupDropoffDetails:
      return {
        ...state,
        pickup_dropoff_details: action.state,
      };

    case actionTypes.priceDetailsOpen:
      return {
        ...state,
        pricedetailsOpen: action.state,
      };


    case actionTypes.OPENCLOSEVIEWTRAVEL:
      return {
        ...state,
        pickup_dropoff_details:null,
        OpenDialoge: action.state,
        ID: action.order ? action.order : null,
        customerId: action.customerId ? action.customerId : null,
        // pureData: null,
        // error: false,
      };
    case actionTypes.changeViewTravelHistoryMode:
      return {
        ...state,
        history: action.state,
      };
    case actionTypes.setHistoryData:
      return {
        ...state,
        historyData: {
          ...state.historyData,
          ...action.state,
        },
      };

    case actionTypes.set_Edit_Order_DriverAndStatus:
      return {
        ...state,
        DriverAndStatus: {
          ...state.DriverAndStatus,
          EditSaDStatusText: action.state,
        },
      };
    case actionTypes.LOADINGORDERINFO:
      return {
        ...state,
        loading: action.state,
      };
    case actionTypes.SETORDERINFOERROR:
      return {
        ...state,
        error: action.state,
      };
    case actionTypes.SETORDERINFO:
      return {
        ...state,
        pureData: action.state,
        DriverAndStatus: init.DriverAndStatus,
        error: false,
      };
    case actionTypes.resetViewTravel:
      return init;

    case actionTypes.setViewTravelEstimatedRoute:
      return {
        ...state,
        estimatedRoute: {
          ...state.estimatedRoute,
          ...action.state,
        },
      };


    case actionTypes.set_auto_driver_EditOrder:
      return {
        ...state,

        DriverAndStatus: {
          ...state.DriverAndStatus,
          auto: action.state,
        },
      };



    case actionTypes.set_auto_activeVehicleType_EditOrder:
      return {
        ...state,

        DriverAndStatus: {
          ...state.DriverAndStatus,
          auto_driver: {
            ...state.DriverAndStatus.auto_driver,
            auto_activeVehicleType: action.state,
          },
        },
      };



    case actionTypes.set_manual_activeVehicleType_EditOrder:
      return {
        ...state,

        DriverAndStatus: {
          ...state.DriverAndStatus,

          manual_driver: {
            ...state.DriverAndStatus.manual_driver,
            ...action.state,
          },
        },
      };


    case actionTypes.set_searchDriverInput_EditOrder:
      return {
        ...state,

        DriverAndStatus: {
          ...state.DriverAndStatus,
          manual_driver: {
            ...state.DriverAndStatus.manual_driver,
            searchDriverInput: action.state,
          },
        },
      };



    case actionTypes.add_driver_option_EditOrder:
      return {
        ...state,

        DriverAndStatus: {
          ...state.DriverAndStatus,
          options: [...state.DriverAndStatus.options, action.state],
        },
      };
    case actionTypes.remove_driver_option_EditOrder:
      return {
        ...state,
        DriverAndStatus: {
          ...state.DriverAndStatus,
          options: state.DriverAndStatus.options.filter(
            (item) => item.id !== action.state
          ),
        },
      };

    case actionTypes.reset_DriverAndStatus:
      return {
        ...state,
        DriverAndStatus: init.DriverAndStatus,
      };

    case actionTypes.show_event_TimeLine:
      return {
        ...state,
        eventTimeLine: {
          ...state.eventTimeLine,
          show: action.state
        },
      };

    case actionTypes.set_viewOrder_events:
      return {
        ...state,
        eventTimeLine: {
          ...state.eventTimeLine,
          ...action.state
        },
      };


      case actionTypes.set_New_Event:
        return {
          ...state,
          eventTimeLine: {
            ...state.eventTimeLine,
            newEvent:{
              ...state.eventTimeLine.newEvent,
              ...action.state
            }
          },
        };
  
      case actionTypes.set_Order_Sub_Id:
          return {
            ...state,
            Order_SubId: action.state,
          };
    
  
    default:
      return state;
  }
};




