import { debounce, isEmpty ,throttle } from 'lodash';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UpdateCategoryOrdersListSorted } from '../Redux/actions/actionNames';
import { ObjectToArray } from '../service/worker/SortToArrayCategoryListData copy';
import { SortToArrayCategoryListData } from '../service/worker/SortToArrayCategoryListData';



export function GetMonitoringOrdersListData() {
  const [data, setData] = useState([]);
  const [workerStatic, setWrkerStatic] = useState(null);
  const pureData = useSelector((state) => state.Monitoring.orders.data)

  useEffect(() => {
    if (isEmpty(data) && isEmpty(pureData)) {
    } else {
      ObjectToArray(workerStatic, pureData, (worker) => {
        setWrkerStatic(worker)
      }, (call) => {
        setData(call)
      })
      
    }


  }, [pureData]);

  return data;
};

