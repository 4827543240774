import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TableRadioButtonFilter_Update, TableRangeFilter_Update, TableSubRangeFilter_Update } from '../Redux/actions/actionNames';
import { useTranslation } from 'react-i18next';




export function GetTableSubRangeFilter(selector,sub) {
  const [data, setData] = useState("");

  const pureData = useSelector((state) => state.Table.filters[selector][sub])
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();



  useEffect(() => {

    if (pureData) {
      setData(pureData)
    }

  }, []);

  useEffect(() => {

    if (pureData) {
      setData(pureData)
    }

  }, [pureData]);


  const OnChange = React.useCallback((e) => {
    dispatch(TableSubRangeFilter_Update({ key: selector, sub :sub , value: e }))
    setData(e)
  }, [data, pureData, selector , sub]);


  return { [`${selector}${sub}_data`]: data, [`${selector}${sub}_OnChange`]: OnChange };
};

