import { useEffect } from 'react';
import { useState } from 'react';
import styles from './style.module.css';
import { Allme } from './components/all-me-filter';
import { DueTime } from './components/due-time';
import { Zone } from './components/zone';
import { Status } from './components/status';
import { Service } from './components/service';
import { VehicleType } from './components/vehicle-type';
import { Save } from './components/save';
import { OpenCloseFilterDialoge, ResetSearchFilters, SetOrderListFiltersChanged, SetOrderListFiltersDueTime, SetOrderListFiltersServices, SetOrderListFiltersStatus, SetOrderListFiltersVehicleTypes, SetOrderListFiltersZone } from '../../../Redux/actions/actionNames';
import { useDispatch, useSelector } from 'react-redux';
import { UpdateOrdersStatistics } from '../../../utils/update-ordersStatistics';


export const Filter = () => {
    const openDialoge = useSelector((state) => state.OrderList.filters.openDialoge)
    const selectedStatus = useSelector((state) => state.OrderList.selectedStatus)
    const selectedServices = useSelector((state) => state.OrderList.selectedServices)
    const selectedVehicleType = useSelector((state) => state.OrderList.selectedVehicleType)
    const zones = useSelector((state) => state.OrderList.zones)
    const isScheduled = useSelector((state) => state.OrderList.isScheduled)
    const now = useSelector((state) => state.OrderList.now)
    const ActiveOrderType = useSelector((state) => state.OrderList.ActiveOrderType)


    const [changedData, setChangedData] = useState(false);
    const [iselectedStatus, setIselectedStatus] = useState(JSON.parse(selectedStatus));
    const [iselectedService, setIselectedService] = useState(JSON.parse(selectedServices));
    const [iselectedVehicleType, setIselectedVehicleType] = useState(JSON.parse(selectedVehicleType));
    const [iselectedZone, setIselectedZone] = useState(JSON.parse(zones));
    const [iisScheduled, setIisScheduled] = useState(isScheduled);
    const [orderType, setOrderType] = useState(ActiveOrderType);
    const [inow, setInow] = useState(now);

    const dispatch = useDispatch();

    useEffect(() => {
        if (orderType != ActiveOrderType) {
            setIselectedStatus([])
            setIselectedService([])
            setIselectedVehicleType([])
            setIselectedZone([])
            setIisScheduled(false)
            setOrderType(ActiveOrderType)
        }


    }, [ActiveOrderType])

    useEffect(() => {
        if (!openDialoge && changedData) {
            setChangedData(false);
            setIselectedStatus(JSON.parse(selectedStatus))
            setIselectedService(JSON.parse(selectedServices))
            setIselectedVehicleType(JSON.parse(selectedVehicleType))
            setIselectedZone(JSON.parse(zones))
            setIisScheduled(isScheduled)
            setInow(now)
        }
    }, [openDialoge])


    function ChangeScheduled({ key, value }) {
        if (key == "now") {
            setInow(value)
        } else {
            setIisScheduled(value)
        }
        setChangedData(true)
    }
    function ChangeStatus(value) {
        setIselectedStatus(value)
        setChangedData(true)
    }


    function ChangeService(value) {
        setIselectedService(value)
        setChangedData(true)
    }

    function ChangeVehicleType(value) {
        setIselectedVehicleType(value)
        setChangedData(true)
    }

    function ChangeZone(value) {
        setIselectedZone(value)
        setChangedData(true)
    }

    function resetFilter() {
        setIselectedStatus([])
        setIselectedService([])
        setIselectedVehicleType([])
        setIselectedZone([])
        setIisScheduled(false)
        setInow(false)
        setChangedData(true)
        // dispatch(ResetSearchFilters())
    }

    function save() {
        dispatch(SetOrderListFiltersZone(iselectedZone))
        dispatch(SetOrderListFiltersStatus(iselectedStatus))
        dispatch(SetOrderListFiltersServices(iselectedService))
        dispatch(SetOrderListFiltersDueTime({ key: "now", value: inow }))
        dispatch(SetOrderListFiltersDueTime({ key: "isScheduled", value: iisScheduled }))

        dispatch(SetOrderListFiltersVehicleTypes(iselectedVehicleType))

        // dispatch(SetOrderListFiltersPatch({
        //     selectedServices:iselectedService,
        //     selectedStatus:iselectedStatus,
        //     selectedVehicleType:iselectedVehicleType,
        //     isScheduled:iisScheduled
        // }))


        // dispatch(SetOrderListFiltersChanged(true))
        setChangedData(false)
        dispatch(OpenCloseFilterDialoge())

    }

    // useEffect(()=>{
    //     setTimeout(() => {
    //         setActive(true)
    //     }, 2000);
    // })


    
    return <div className={styles.filterContainer + " " + `${openDialoge && styles.filterContainerActive}`} >
        <div className={styles.filterInnerContainer}>
            {/* <Allme setUserId={ChangeType} userId={iuserId} /> */}
            <DueTime ChangeScheduled={ChangeScheduled} isScheduled={iisScheduled} isNow={inow} resetFilter={resetFilter} />
            <Zone iselectedZone={iselectedZone} ChangeZone={ChangeZone} />
            <Status ChangeStatus={ChangeStatus} SelectedStatus={iselectedStatus} />
            <Service selectedService={iselectedService} ChangeService={ChangeService} />
            <VehicleType selectedVehicleType={iselectedVehicleType} ChangeVehicleType={ChangeVehicleType} />
            {/* {changedData && <Save onPress={save} />} */}
        </div>
        <Save onPress={save} changedData={changedData} />
    </div>

};




