import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styles from './style.module.css';
import useForceUpdate from 'use-force-update';
import { useEffect } from 'react';


export const Status = ({ SelectedStatus, ChangeStatus }) => {
    const status = useSelector((state) => state.OrderList.status)
    const { t, i18n } = useTranslation();
    const forceUpdate = useForceUpdate();


    function statusIsSelected(status) {
        return SelectedStatus.findIndex((istatus) => {return istatus === status}) > -1
    }

    function selectStatus(status) {
        let newStatus = SelectedStatus
        if (statusIsSelected(status)) {
            ChangeStatus(newStatus.filter((istatus) => { return istatus != status }))
            forceUpdate()
        } else {
            newStatus.push(String(status))
            ChangeStatus(newStatus)
            forceUpdate()
        }
    }


    return <div className={styles.container} >
        <p className={styles.filterTilte}>Status</p>
        <div className={styles.itemContainer}>
            {status.map((item , i) => {
                return <span key={i} onClick={() => { selectStatus(item) }} className={styles.item + " " + (statusIsSelected(item) && styles.activeItem)}> {t("EDAS_" + item)} </span>
            })}
        </div>

    </div>

};




