import { Component } from "react";
import { connect } from "react-redux";
import Ondemand from "../ondemandLogic/canSaveOrder";
import PickupDelivery from "../pickupDeliveryLogic/canSaveOrder";
import Pickup from "../pickupLogic/canSaveOrder";
import Delivery from "../deliveryLogic/canSaveOrder";

class App extends Component {

  render() {
    let orderType = this.props.orderType

    if(orderType== "Ondemand"){
      return <Ondemand />
    }else if(orderType== "Pickup"){
      return <Pickup />
    }else if(orderType== "Delivery"){
      return <Delivery />
    }else if(orderType== "PickupDelivery"){
      return <PickupDelivery />
    }else{
      return null
    }  
    return null
  }
}

const mapStateToProps = (state) => ({
  orderType: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.type
      ? state.ViewTravel.pureData.type
      : null
    : null,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
