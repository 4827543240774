import React from "react";
import { connect } from "react-redux";
import originIcon from "../../assets/pin_favorite.svg";
import {
  Set_Pickup_address,
  Set_DropOff_Inputs,
  Edit_Travel_set_pickup,
  Edit_Travel_set_Dropoff_inputs,
  SetNewFav,
} from "../../Redux/actions/actionNames";
import { withTranslation, Trans } from "react-i18next";
import { GetLocationPlacenameAction } from "../../Redux/actions/actions";
import { getCenterMap } from "../../helper/module";

class App extends React.Component {
  markerOnClick = () => {
   
    let center = getCenterMap(this.props.mapRef , this.props.center)

    this.props.dispatch(
      GetLocationPlacenameAction(
        this.props.dispatch,
        {
          latitude:  center.lat,
          longitude: center.lng,
          
        },
        (RES) => {

          this.props.dispatch(
            SetNewFav({
              name:"Address",
              value: RES.address,
            })
          );

          this.props.dispatch(
            SetNewFav({
              name:"loc",
              value:  {
                lng: center.lng,
                lat: center.lat,
              },
            })
          );

        }
      )
    );
  };


  render() {
    let originShow =
      this.props.loc === null;

    return (
      <div>
        {this.props.OpenDialoge && originShow && (
          <img
            onClick={this.markerOnClick}
            src={originIcon}
            alt=""
            className={`originShow ${global.config.dispatcherSettings.applicationMap.type == "google" && "originShowGoogle"}`}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  OpenDialoge: state.FavAddress.openDialoge,
  loc: state.FavAddress.newFav.loc,
 mapRef: state.Map.mapRef,
  center: state.Map.center,

});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
