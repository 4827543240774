import { store } from "../../Redux/store/index";
import {
  ChangeViewTravelDialoge,
  ResetViewTravel,
  ResetNewFav,
  ChangeNewTravelDialoge,
  ChangePersonBusiness,
  Set_Passenger_Na_Fa_Ph,
  SetServiceVehicleTypesRepeatData,
  Set_Manual_ActiveVehicleType,
  Set_Auto_Driver,
  Set_Auto_ActiveVehicleType,
  Set_Pickup_Inputs,
  NewTravelPickupSetReserve,
  Set_DropOff_Inputs,
  NewTravelDropOffSetReserve,
  Add_dropoff,
  New_Travel_PaymentMethod,
  Set_NewOrder_OrderType,
  Set_Service_ActiveVehicleType,
  Set_DropOff_Inputs_bulk,
  SetCodAmount,
  SetReferenceID,
  SetSharedDataVehicleTypes,
  Set_autoDriver_vehicles_auto_suggestListData,
} from "../../Redux/actions/actionNames";
import { Get_User_Recent_Address, ServicesVehicletypeAction } from "../../Redux/actions/actions";
import { DriverApi } from "../../service/API/driver";
import history from "../../service/history";
import { httpCustomer } from "../api";
import { get_accessToken, get_lang } from "../UserData/userdate";
import { ServicesApi } from "../../service/API/service";

const _ = require("lodash");


export const repeatReuqestModule = async (pureData, dispatch) => {

  let data = JSON.parse(JSON.stringify(pureData));
  let storeData = store.getState();
  // dispatch(ServicesVehicletypeAction(dispatch, storeData.Map.center , data.customerId));
  let vehicleTypes = storeData.SharedData.vehicleTypes;
  let services = storeData.SharedData.services;

  let FirstDrop = null;



  if (["PickupDelivery"].indexOf(data.type) > -1) {
    FirstDrop = data.delivery
  } else if (["Delivery"].indexOf(data.type) > -1) {
    FirstDrop = data.dropoff
  } else {
    FirstDrop = data.dropoffs.shift();
  }


  let otherDropOffs = [];
  otherDropOffs = data.dropoffs;


  dispatch(SetCodAmount(data.codAmount || ""))
  dispatch(SetReferenceID(data.referenceId || ""))


  dispatch(SetServiceVehicleTypesRepeatData(data.service.id, data.service.options));

  if (["SenderCash", "SenderWallet", "ReceiverCash"].indexOf(data.paymentSide + data.paymentMethod) == -1) {
    dispatch(New_Travel_PaymentMethod({
      paymentSide: data.paymentSide,
      paymentMethod: data.paymentMethod,
      paymentProvider: data.paymentProvider,
      cardInfo: data.cardInfo,
      paymentMethodId: data.paymentMethodId,

    }));
  }



  let selectedService = services.findIndex((item) => { return item.id == data.service.id })

  if (services[selectedService]) {
    if (services[selectedService].orderTypes.indexOf(data.type) > -1) {
      dispatch(Set_Service_ActiveVehicleType(services[selectedService]))
      dispatch(Set_NewOrder_OrderType(data.type))
    } else {
      let NewSelectedService = await services.findIndex((item) => { return item.orderTypes.indexOf(data.type) > -1 })

      if (services[NewSelectedService]) {
        dispatch(Set_Service_ActiveVehicleType(services[NewSelectedService]))
        dispatch(Set_NewOrder_OrderType(data.type))
      } else {
        dispatch(Set_Service_ActiveVehicleType(services[0]))
        dispatch(Set_NewOrder_OrderType(services[0].orderTypes[0]))
      }
    }
  } else {
    let NewSelectedService = await services.findIndex((item) => { return item.orderTypes.indexOf(data.type) > -1 })
    if (services[NewSelectedService]) {
      dispatch(Set_Service_ActiveVehicleType(services[NewSelectedService]))
      dispatch(Set_NewOrder_OrderType(data.type))
    } else {
      dispatch(Set_Service_ActiveVehicleType(services[0]))
      dispatch(Set_NewOrder_OrderType(services[0].orderTypes[0]))
    }
  }



  // }


  let vehicleTypeoptions = [];
  let pure_vehicleType = {};
  console.log("vehicleTypfeoptioeffens ,", vehicleTypes, data.vehicleType)
  data.vehicleType && vehicleTypes.forEach((item) => {
    if (item.id == data.vehicleType.id) {
      console.log("vehicleTypfeoptioeffens ,", item, item.id, data.vehicleType.id)
      vehicleTypeoptions = item.options;
      pure_vehicleType = item;
    }
  });

  if (!_.isEmpty(data.driver)) {

    // let driverApi = new DriverApi()
    // let driverData = await driverApi.Info(data.driverId)

    //driver is manual
    dispatch(Set_Auto_Driver(false));

    if (data.vehicleType) {
      dispatch(
        Set_Manual_ActiveVehicleType({
          manual_activeVehicleType: {
            ...pure_vehicleType,
            id: data.vehicleType.id,
            icon: data.vehicleType.icon,
            title: data.vehicleType.title,
            options: vehicleTypeoptions,
          },
          // manual_name: data.driver.givenName,
          // manual_family: data.driver.familyName,
          manual_name: data.driver.name,
          manual_family: "",
          manual_number: data.driver.phone,
          manual_id: data.driverId,
          manual_code: data.driver.username,
          manual_vehicleTypes: data.driver.vehicleTypes
        })
      );
    }

  } else {
    //driver is auto
    dispatch(Set_Auto_Driver(true));


    if (data.vehicleType) {
      dispatch(
        Set_Auto_ActiveVehicleType({
          ...pure_vehicleType,
          id: data.vehicleType.id,
          icon: data.vehicleType.icon,
          title: data.vehicleType.title,
          options: vehicleTypeoptions,
        })
      );
    }
    if (data.service.id) {
      const Services = new ServicesApi();
      const VehicleTypedata = await Services.VehicleType({ serviceId: data.service.id })
      dispatch(Set_autoDriver_vehicles_auto_suggestListData(VehicleTypedata.VehicleTypes))
      if (!data.vehicleType && VehicleTypedata.VehicleTypes && VehicleTypedata.VehicleTypes.length > 0) {
        dispatch(
          Set_Auto_ActiveVehicleType(VehicleTypedata.VehicleTypes[0])
        );
      }
    }


  }




  //other drop offs
  if (["Ondemand"].indexOf(data.type) > -1) {

    otherDropOffs.map((item) => {
      let dropId = idgenerator();
      dispatch(Add_dropoff(dropId))



      dispatch(
        Set_DropOff_Inputs_bulk({
          name: null,
          value: {
            address: item.address,
            adresss_loc: { lng: item.coordinates[0], lat: item.coordinates[1] },
            block: item.buildingBlock,
            floor: item.floor,
            unit: item.room,
            recieverFullName: item.fullName,
            recieverPhoneNumber: (item.phone && item.phone.length > 0) ? (String(item.phone).startsWith("+") ? item.phone : (String(item.phone).startsWith("00") ? "+" + String(item.phone).slice(2, item.phone.length) : "")) : "",
            noteForDriver: item.customerDescription,
            email: item.email,
            scheduleDateBefore: item.scheduleDateBefore != 0 ? item.scheduleDateBefore : null,
            scheduleDateAfter: item.scheduleDateAfter != 0 ? item.scheduleDateAfter : null
          },
          firstDropOff: false,
          id: dropId,
        })
      );
      // dispatch(
      //   Set_DropOff_Inputs({
      //     name: "address",
      //     value: item.address,
      //     firstDropOff: false,
      //     id: dropId,
      //   })
      // );
      // dispatch(
      //   Set_DropOff_Inputs({
      //     name: "adresss_loc",
      //     value: { lng: item.coordinates[0], lat: item.coordinates[1] },
      //     firstDropOff: false,
      //     id: dropId,
      //   })
      // );
      // dispatch(
      //   Set_DropOff_Inputs({
      //     name: "block",
      //     value: item.buildingBlock,
      //     firstDropOff: false,
      //     id: dropId,
      //   })
      // );
      // dispatch(
      //   Set_DropOff_Inputs({
      //     name: "floor",
      //     value: item.floor,
      //     firstDropOff: false,
      //     id: dropId,
      //   })
      // );

      // dispatch(
      //   Set_DropOff_Inputs({
      //     name: "unit",
      //     value: item.room,
      //     firstDropOff: false,
      //     id: dropId,
      //   })
      // );
      // dispatch(
      //   Set_DropOff_Inputs({
      //     name: "recieverFullName",
      //     value: item.fullName,
      //     firstDropOff: false,
      //     id: dropId,
      //   })
      // );
      // dispatch(
      //   Set_DropOff_Inputs({
      //     name: "recieverPhoneNumber",
      //     value: (item.phone && item.phone.length > 0) ? (String(item.phone).startsWith("+") ? item.phone : (String(item.phone).startsWith("00") ? "+" + String(item.phone).slice(2, item.phone.length)  : "" ) ) : "",
      //     firstDropOff: false,
      //     id: dropId,
      //   })
      // );
      // dispatch(
      //   Set_DropOff_Inputs({
      //     name: "noteForDriver",
      //     value: item.customerDescription,
      //     firstDropOff: false,
      //     id: dropId,
      //   })
      // );

      // dispatch(
      //   Set_DropOff_Inputs({
      //     name: "email",
      //     value: item.email,
      //     firstDropOff: false,
      //     id: dropId,
      //   })
      // );

      // dispatch(
      //   NewTravelDropOffSetReserve({
      //     name: "scheduleDateBefore",
      //     value: item.scheduleDateBefore != 0 ? item.scheduleDateBefore : null,
      //     firstDropOff: false,
      //     id: dropId,
      //   })
      // );
      // dispatch(
      //   NewTravelDropOffSetReserve({
      //     name: "scheduleDateAfter",
      //     value: item.scheduleDateAfter != 0 ? item.scheduleDateAfter : null,
      //     firstDropOff: false,
      //     id: dropId,
      //   })
      // );
    });

  }



  //pickup


  if (["PickupDelivery", "Ondemand", "Pickup"].indexOf(data.type) > -1) {

    dispatch(
      Set_Pickup_Inputs({
        name: "address",
        value: data.pickup.address,
      })
    );
    dispatch(
      Set_Pickup_Inputs({
        name: "adresss_loc",
        value: {
          lng: data.pickup.coordinates[0],
          lat: data.pickup.coordinates[1],
        },
      })
    );
    dispatch(
      Set_Pickup_Inputs({
        name: "block",
        value: data.pickup.buildingBlock,
      })
    );
    dispatch(
      Set_Pickup_Inputs({
        name: "floor",
        value: data.pickup.floor,
      })
    );

    dispatch(
      Set_Pickup_Inputs({
        name: "unit",
        value: data.pickup.room,
      })
    );
    dispatch(
      Set_Pickup_Inputs({
        name: "senderFullName",
        value: data.pickup.fullName,
      })
    );
    dispatch(
      Set_Pickup_Inputs({
        name: "senderPhoneNumber",
        value: (data.pickup.phone && data.pickup.phone.length > 0) ? (String(data.pickup.phone).startsWith("+") ? data.pickup.phone : (String(data.pickup.phone).startsWith("00") ? "+" + String(data.pickup.phone).slice(2, data.pickup.phone.length) : "")) : "",
      })
    );
    dispatch(
      Set_Pickup_Inputs({
        name: "noteForDriver",
        value: data.pickup.customerDescription,
      })
    );

    dispatch(
      NewTravelPickupSetReserve({
        name: "scheduleDateBefore",
        value:
          (data.pickup.scheduleDateBefore && data.pickup.scheduleDateBefore != 0)
            ? data.pickup.scheduleDateBefore
            : null,
      })
    );
    dispatch(
      NewTravelPickupSetReserve({
        name: "scheduleDateAfter",
        value:
          (data.pickup.scheduleDateAfter && data.pickup.scheduleDateAfter != 0)
            ? data.pickup.scheduleDateAfter
            : null,
      })
    );
    dispatch(
      NewTravelPickupSetReserve({
        name: "email",
        value: data.pickup.email,
      })
    );


  }




  //first fropOff

  if (["PickupDelivery", "Ondemand", "Delivery"].indexOf(data.type) > -1) {

    dispatch(
      Set_DropOff_Inputs_bulk({
        name: null,
        value: {
          address: FirstDrop.address,
          adresss_loc: { lng: FirstDrop.coordinates[0], lat: FirstDrop.coordinates[1] },
          block: FirstDrop.buildingBlock,
          floor: FirstDrop.floor,
          unit: FirstDrop.room,
          recieverFullName: FirstDrop.fullName,
          recieverPhoneNumber: (FirstDrop.phone && FirstDrop.phone.length > 0) ? (String(FirstDrop.phone).startsWith("+") ? FirstDrop.phone : (String(FirstDrop.phone).startsWith("00") ? "+" + String(FirstDrop.phone).slice(2, FirstDrop.phone.length) : "")) : "",
          noteForDriver: FirstDrop.customerDescription,
          email: FirstDrop.email,
          scheduleDateBefore: (FirstDrop.scheduleDateBefore && FirstDrop.scheduleDateBefore != 0) ? FirstDrop.scheduleDateBefore : null,
          scheduleDateAfter: (FirstDrop.scheduleDateAfter && FirstDrop.scheduleDateAfter != 0) ? FirstDrop.scheduleDateAfter : null
        },
        firstDropOff: true,
        id: "dropoff",
      })
    );

    // dispatch(
    //   Set_DropOff_Inputs({
    //     name: "address",
    //     value: FirstDrop.address,
    //     firstDropOff: true,
    //     id: "dropoff",
    //   })
    // );


    // dispatch(
    //   Set_DropOff_Inputs({
    //     name: "adresss_loc",
    //     value: { lng: FirstDrop.coordinates[0], lat: FirstDrop.coordinates[1] },
    //     firstDropOff: true,
    //     id: "dropoff",
    //   })
    // );

    // dispatch(
    //   Set_DropOff_Inputs({
    //     name: "block",
    //     value: FirstDrop.buildingBlock,
    //     firstDropOff: true,
    //     id: "dropoff",
    //   })
    // );


    // dispatch(
    //   Set_DropOff_Inputs({
    //     name: "floor",
    //     value: FirstDrop.floor,
    //     firstDropOff: true,
    //     id: "dropoff",
    //   })
    // );

    // dispatch(
    //   Set_DropOff_Inputs({
    //     name: "unit",
    //     value: FirstDrop.room,
    //     firstDropOff: true,
    //     id: "dropoff",
    //   })
    // );
    // dispatch(
    //   Set_DropOff_Inputs({
    //     name: "recieverFullName",
    //     value: FirstDrop.fullName,
    //     firstDropOff: true,
    //     id: "dropoff",
    //   })
    // );
    // dispatch(
    //   Set_DropOff_Inputs({
    //     name: "recieverPhoneNumber",
    //     value: (FirstDrop.phone && FirstDrop.phone.length > 0) ? (String(FirstDrop.phone).startsWith("+") ? FirstDrop.phone : (String(FirstDrop.phone).startsWith("00") ? "+" + String(FirstDrop.phone).slice(2, FirstDrop.phone.length)  : "" ) ) : "",
    //     firstDropOff: true,
    //     id: "dropoff",
    //   })
    // );
    // dispatch(
    //   Set_DropOff_Inputs({
    //     name: "noteForDriver",
    //     value: FirstDrop.customerDescription,
    //     firstDropOff: true,
    //     id: "dropoff",
    //   })
    // );

    // dispatch(
    //   Set_DropOff_Inputs({
    //     name: "email",
    //     value: FirstDrop.email,
    //     firstDropOff: true,
    //     id: "dropoff",
    //   })
    // );

    // dispatch(
    //   NewTravelDropOffSetReserve({
    //     name: "scheduleDateBefore",
    //     value:
    //       FirstDrop.scheduleDateBefore != 0 ? FirstDrop.scheduleDateBefore : null,
    //     firstDropOff: true,
    //     id: "dropoff",
    //   })
    // );
    // dispatch(
    //   NewTravelDropOffSetReserve({
    //     name: "scheduleDateAfter",
    //     value:
    //       FirstDrop.scheduleDateAfter != 0 ? FirstDrop.scheduleDateAfter : null,
    //     firstDropOff: true,
    //     id: "dropoff",
    //   })
    // );


  }



  // dispatch(ChangeViewTravelDialoge(false));

  httpCustomer
    .get(
      `/api/v1/dispatcher/customer/${data.customerId}`,
      {
        headers: {
          "Accept-Language": get_lang(),
          Authorization: "Bearer " + get_accessToken(),
        },
      }
    )
    .then((res) => {

      dispatch(
        Set_Passenger_Na_Fa_Ph({
          name: "balance",
          value: res.data.data.balance,
        })
      );

      dispatch(
        Set_Passenger_Na_Fa_Ph({
          name: "minimumBalance",
          value: res.data.data.minimumBalance,
        })
      );

      dispatch(
        Set_Passenger_Na_Fa_Ph({
          name: "name",
          value: res.data.data.name,
        })
      );
      dispatch(
        Set_Passenger_Na_Fa_Ph({
          name: "phone",
          value: res.data.data.phone,
        })
      );
      dispatch(
        Set_Passenger_Na_Fa_Ph({
          name: "id",
          value: data.customerId,
        })
      );
      dispatch(
        Set_Passenger_Na_Fa_Ph({
          name: "type",
          value: res.data.data.type,
        })
      );

      dispatch(
        Set_Passenger_Na_Fa_Ph({
          name: "familyName",
          value: res.data.data.familyName,
        })
      );

      dispatch(
        Set_Passenger_Na_Fa_Ph({
          name: "givenName",
          value: res.data.data.givenName,
        })
      );

      dispatch(
        Set_Passenger_Na_Fa_Ph({
          name: "email",
          value: res.data.data.email,
        })
      );

      dispatch(ResetViewTravel());
      // dispatch(ResetNewFav());
      // dispatch(ChangeNewTravelDialoge());

      dispatch(Get_User_Recent_Address(dispatch, data.customerId, null))

      history.push("/neworder")

    })

};

export const idgenerator = () => {
  var number = Math.random(); // 0.9394456857981651
  number.toString(36); // '0.xtis06h6'
  var id = number.toString(36).substr(2, 9); // 'xtis06h6'
  return id.toString();
};
