import React from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import open_file from "../../../../../src/assets/ic/open_file.svg";
import jalali from "jalali-moment";
import { get_lang } from "../../../../helper/UserData/userdate";
import { BulkImport_GetFailedRecords } from "../../../../helper/api";



class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }


    donwloadFailedRecords = () => {
        BulkImport_GetFailedRecords(this.props.dispatch, this.props.ActiveItem.id, String(this.props.ActiveItem.info.fileName).split(".")[0])
    }

    render() {
        let { t } = this.props;
        return (
            <div className="DragOverContainer " style={{height:(this.props.ActiveItem.info && this.props.ActiveItem.info.failedRecordsCount > 0) ? "309px" :"189px"}}>


                <div style={{ width: "100%", height: "auto", float: "left", }}>

                    {this.props.ActiveItem.info ? <React.Fragment>
                        <span className="blkimpdetafname"> {this.props.ActiveItem.info.fileName} </span> <span className={"blkimpdetaorderT"}> {this.props.t(this.props.ActiveItem.info.orderType)} </span>
                        <span className="blkimpdetadate">  {this.props.ActiveItem.info.createdAt &&
                            jalali(this.props.ActiveItem.info.createdAt)
                                .locale(get_lang())
                                .format("D  MMM ,  HH:mm")} </span>
                        <div className="blkimpdetaResultCont">
                            <p className="ttdwd"> {this.props.t("Result")} </p>
                            <p className="ttdwddesc"> {this.props.t("ResultText")} </p>
                            <p className="sususpo">  {String(this.props.t("importedsuccessfully")).replace("xxx", this.props.ActiveItem.info.doneRecordsCount)} </p>
                            <p className="erorfwn"> {String(this.props.t("ordershaserrors")).replace("xxx", this.props.ActiveItem.info.failedRecordsCount)} </p>
                        </div>
                        {this.props.ActiveItem.info.failedRecordsCount > 0 && <div className="blkimpdetaResultCont blkimpdetaResultERRDECont">
                            <p className="ttdwd"> {this.props.t("ErrorDetails")} </p>
                            <p className="ttdwddesc" style={{ marginBottom: "8px" }}> {this.props.t("ErrorDetailsText")} </p>
                        </div>}
                        {this.props.ActiveItem.info.failedRecordsCount > 0 && <div className={"downloadFailedCsvs"} onClick={this.donwloadFailedRecords}>
                            <img src={open_file} />
                            <span> {this.props.t("DownloadErroredRows")} </span>
                        </div>}

                    </React.Fragment> : <div style={{ marginTop: "60px" }}>
                            <span
                                className="NotravelsListHeader"
                                style={{ marginTop: "12px" }}
                            >
                                <Trans i18nKey={"poperLoading"}> </Trans>
                            </span>
                            <span className="NotravelsListText">
                                <Trans i18nKey={"fetchingdata"}> </Trans>
                            </span>
                        </div>
                    }


                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({

});
const mapDispatchToProps = (dispatch) => ({
    dispatch: dispatch,
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(App));


