import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import icaddcreditcard from "../../../../assets/ic-add-credit-card.svg";
import cancell from "../../../../assets/ic-close.svg";
import Dialog from "@material-ui/core/Dialog";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  ElementsConsumer,
  CardElement,
} from "@stripe/react-stripe-js";
import {
  get_lang,
  get_settings_dispatcher,
} from "../../../../helper/UserData/userdate";
import loading from "../../../../assets/loading.gif";
import Form from "./form";
import { Add_Payment_Method } from "../../../../helper/api";
import styles from './style.module.css';
import IconProvider from "../../../../design-system/lib/IconProvider";
import Typography from "../../../../design-system/lib/Typography";
import { ADD_CREDIT_CARD } from "../../../../assets";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialog: false,
      cardStatus: null,
      ready: false,
      clientSecret: global.config.dispatcherSettings.stripeMoto ? true : false,
    };
    this.stripePromise = loadStripe(
      get_settings_dispatcher().stripePublicApiKey
    );
  }

  componentDidMount() {
    if (this.props.customer.id && this.props.customer.id.length > 2) {
      if (!global.config.dispatcherSettings.stripeMoto) {
        Add_Payment_Method({ customerId: this.props.customer.id }, (data) => {
          this.setState({
            clientSecret: data.clientSecret,
          });
        });
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.customer.id && nextProps.customer.id.length > 2) {
      if (!global.config.dispatcherSettings.stripeMoto) {
        Add_Payment_Method({ customerId: nextProps.customer.id }, (data) => {
          this.setState({
            clientSecret: data.clientSecret,
          });
        });
      }
    }
  }

  handleOpenDialoge = (autoSelect = false) => {
    this.setState({
      openDialog: !this.state.openDialog,
    });
    this.props.onClose(autoSelect);
  };

  cardOnChange = (event) => {
    this.setState({
      cardStatus: event,
    });

    // stripePromise
    // .confirmCardSetup('seti_1KkjwTLGEuIAvDMsOL9BqQZX_secret_LRdCmY8YxlyfjYtSAf31KmE5sR7pa', {
    //     payment_method: {
    //         card: card
    //     },
    // })
    // .then(function (result) {
    //
    // });
  };

  cardOnReady = () => {
    this.setState({
      ready: true,
    });
  };

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        {/* <div
          className={`MethodsItem `}
          
          style={{
            opacity: !this.state.clientSecret && ".5",
            cursor: !this.state.clientSecret && "not-allowed",
          }}
        >
          <img src={icaddcreditcard} />
          <span style={{ color: "#1080ff" }}>
            {" "}
            <Trans i18nKey={"AddCreditCard"}> </Trans>{" "}
          </span>
        </div> */}


        <div className={styles.popperMenu_item} onClick={this.state.clientSecret && this.handleOpenDialoge} style={{
          opacity: !this.state.clientSecret && ".5",
          cursor: !this.state.clientSecret && "not-allowed",
        }}>
          <div className={styles.popperMenu_inner_item}>
            <IconProvider Icon={ADD_CREDIT_CARD} size="16" />
            <Typography weight="Body_Middle_Medium" text={this.props.t("AddCreditCard")} />
          </div>
        </div>

        <Elements stripe={this.stripePromise} options={{ locale: get_lang() }}>
          <Dialog
            style={{}}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            open={this.state.openDialog}
            onClose={this.handleOpenDialoge}
            disableAutoFocus
            disableEnforceFocus
            disableRestoreFocus
          >
            <div className="EditDriverContainer" style={{ width: "540px" }}>
              <div className="header">
                <img
                  className="close"
                  src={cancell}
                  onClick={this.handleOpenDialoge}
                />
                <span className="title">{this.props.t("AddCreditCard")}</span>
              </div>
              <Form
                clientSecret={this.state.clientSecret}
                onClose={(autoSelect) => this.handleOpenDialoge(autoSelect)}
              />
            </div>
          </Dialog>
        </Elements>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  customer: state.NewOrder.newOrder.customer,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
