import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import arrow from "../../../../../assets/chevron-down.svg";
import Collapse from "@material-ui/core/Collapse";
import { withTranslation, Trans } from "react-i18next";
import turnOn from "../../../../../assets/turn-on.svg";
import arrow2 from "../../../../../assets/arrow-triangle-down.svg";
import bike from "../../../../../assets/bike.svg";
import close from "../../../../../assets/ic-close.svg";
import { withStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import ListItem from "./driverOptions/list";
import SelectableItem from "./driverOptions/selectable";
import {
  Set_Auto_Driver,
  Set_Auto_ActiveVehicleType,
  Set_Manual_ActiveVehicleType,
  EditTravelSetDriverAuto,
  EditTravelSetManualActiveVehicleType,
  Edit_Travel_Set_Auto_ActiveVehicleType,
  Edit_Travel_reset_driver_options,
  Edit_Travel_Operate_Changed,
  EditTravelSetManualDriver,
} from "../../../../../Redux/actions/actionNames";
import { SearchDriverAction } from "../../../../../Redux/actions/actions";
import { getBaseTrackByUrl } from "../../../../../helper/module";
import { get_lang } from "../../../../../helper/UserData/userdate";
import { snack } from "../../../../../utils/SnackbarUtilsConfigurator";

const BackdropCustom = withStyles({
  backdrop: {
    zIndex: 5000,
    backgroundColor: "rgba(0, 0, 0, 0)",
    color: "#00000",
  },
})((props) => (
  <Backdrop
    {...props}
    style={{
      zIndex: 5000,
      backgroundColor: "rgba(0, 0, 0, 0)",
      color: "#00000",
    }}
  />
));

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "driver",
      showpoperAuto: false,
      showpoperManual: false,
      list: [0, 1, 2, 3, 5, 6, 9, 5, 8],
      loading: false,
      ordered: false,
      Auto: true,
      idd: this.idgenerator(),
      activeVehicleTypes: [
        {
          type: "list",
          title: "weight",
          id: "weight",
          data: [
            {
              name: "under 1 kg",
              id: "12qceqd",
            },
            {
              name: "under 2 kg",
              id: "12rcesd",
            },
            {
              name: "under 3 kg",
              id: "dwdqced",
            },
            {
              name: "under 4 kg",
              id: "1dwdced",
            },
          ],
        },
        {
          type: "boolean",
          title: "need packed",
          id: "need packed",
        },
      ],
    };
    this.anchorElAuto = React.createRef();
    this.anchorElManual = React.createRef();
  }

  idgenerator = () => {
    var number = Math.random(); // 0.9394456857981651
    number.toString(36); // '0.xtis06h6'
    var id = number.toString(36).substr(2, 9); // 'xtis06h6'
    return id.toString();
  };

  componentDidMount() {
    this.props.setId(this.state.id);
  }

  idgenerator = () => {
    var number = Math.random(); // 0.9394456857981651
    number.toString(36); // '0.xtis06h6'
    var id = number.toString(36).substr(2, 9); // 'xtis06h6'
    return id.toString();
  };

  handleCollapse = () => {
    this.props.handleCollapse({
      type: "driver",
      id: "driver",
    });
  };

  handleshowpoperAuto = () => {
    if (this.props.auto_suggestListData.length > 0) {
      this.setState({
        showpoperAuto: !this.state.showpoperAuto,
      });
    } else {
      if (this.props.auto_suggestListLoading) {
        snack.warning_i18("poperLoading")
      } else {
        snack.warning_i18("noVehicleType")
      }
    }
  };

  handleshowpoperManual = () => {
    this.setState({
      showpoperManual: !this.state.showpoperManual,
    });
  };

  handleAutoManual = () => {
    if (
      this.props.auto &&
      this.props.activePickupDropOff.id !== this.state.id
    ) {
      this.props.handleCollapse({
        type: "driver",
        id: "driver",
      });
      this.props.dispatch(Edit_Travel_Operate_Changed());
      this.props.dispatch(EditTravelSetDriverAuto(!this.props.auto));
      this.props.dispatch(Edit_Travel_reset_driver_options());
    } else {
      this.props.dispatch(Edit_Travel_Operate_Changed());
      this.props.dispatch(EditTravelSetDriverAuto(!this.props.auto));
      this.props.dispatch(Edit_Travel_reset_driver_options());
    }
  };

  handleAutoVehicleTypeSelect = (service) => {
    this.props.dispatch(Edit_Travel_Operate_Changed());
    this.props.dispatch(Edit_Travel_Set_Auto_ActiveVehicleType(service));
    this.props.dispatch(Edit_Travel_reset_driver_options());
  };

  handleManualVehicleTypeSelect = (service) => {
    this.props.dispatch(Edit_Travel_Operate_Changed());

    // let index = -1;
    // index = this.props.auto_suggestListData.findIndex((vehicleType) => {
    //   return vehicleType.id == service.vehicletypes[0];
    // });
    // let VehicleType = {};

    // if (index > -1) {
    //   VehicleType = this.props.auto_suggestListData[index];
    // }

    this.FindLocalVehicletype(
      service.vehicleTypes,
      this.props.auto_suggestListData,
      (call) => {
        if (call) {
    
          this.props.dispatch(
            EditTravelSetManualDriver({
              manual_activeVehicleType: {
                id: service.vehicleTypes[0],
                options: [],
                ...call,
              },
              // manual_name: service.givenName,
              manual_name: service.name,
              // manual_family: service.familyName,
              manual_family: "",
              manual_number: service.phone,
              manual_id: service.id,
              manual_code: service.username,
            })
          );
        }
      }
    );

    // this.props.dispatch(Edit_Travel_reset_driver_options());
  };

  InputOnChange = (e) => {
    this.props.dispatch(Edit_Travel_Operate_Changed());
    this.props.dispatch(
      EditTravelSetManualActiveVehicleType({
        [e.target.name.split("-")[0]]: e.target.value,
        manual_id: null,
      })
    );

    if (!this.state.showpoperManual) {
      this.setState({
        showpoperManual: true,
      });
    }

    this.props.dispatch(
      SearchDriverAction(this.props.dispatch, {
        givenName: this.props.manual_name,
        username: this.props.manual_code,
        familyName: this.props.manual_family,
        phone: this.props.manual_number,
        vehicletypes: this.props.viewTravelPureDataVehicleTypes,
      })
    );
  };

  resetDriver = () => {
    this.props.dispatch(
      EditTravelSetManualDriver({
        manual_name: "",
        manual_family: "",
        manual_number: "",
        manual_id: null,
        manual_code: "",
      })
    );
    this.props.dispatch(Edit_Travel_reset_driver_options());
  };

  FindLocalVehicletype = (driverVehicletypes, localservives, call) => {
    let vehicletype = null;

    localservives.forEach((element) => {
      if (element.id == this.props.viewTravelPureDataVehicleTypes) {
        vehicletype = element;
        call(element);
      }
    });

    if (driverVehicletypes.length > 0 && !vehicletype) {
      while (!vehicletype) {
        for (let i = 0; i < localservives.length; i++) {
          if (driverVehicletypes[0] == localservives[i].id) {
            vehicletype = localservives[i];
            call(vehicletype);
            break;
          }
        }
        driverVehicletypes.shift();
      }
      call(0);
    } else {
      call(0);
    }
  };

  render() {
    const { t } = this.props;
    return (
      <passerngerContainer
        style={{ boxShadow: "rgba(128, 128, 128, 0.16) 0px 1px 6px 0px" }}
        className="serviceCustomized"
      >
        {!this.props.auto ? (
          this.props.manual_id ? (
            <img
              onClick={this.resetDriver}
              className="editTravelResetDriverButton"
              src={close}
            />
          ) : (
            ""
          )
        ) : (
          ""
        )}
        <drivertitle onClick={this.handleCollapse}>
          <Trans i18nKey={"driver"}> </Trans>
          <span className="driverTitleVehicleType">
            {this.props.auto
              ? this.props.auto_activeVehicleType &&
                this.props.auto_activeVehicleType.title
              : this.props.manual_id
              ? `${
                  this.props.manual_activeVehicleType.title
                }, ${this.props.manual_code} , ${
                  this.props.manual_name + " " + this.props.manual_family
                }`
              : ""}
          </span>
        </drivertitle>

        <img
          onClick={this.handleCollapse}
          src={arrow}
          className={`pickupCollIcon ${
            this.props.activePickupDropOff.id === this.state.id && "rotate180"
          }`}
        />

        {/* <div
          className={`Manual_Automatic_Switch ${
            this.props.auto && "Manual_Automatic_SwitchActive"
          }`}
          onClick={this.handleAutoManual}
        >
          <img
            className={`Manual_Automatic_SwitchImage ${
              this.props.auto ? "Manual_Automatic_SwitchImageActive" : ""
            }`}
            alt=""
            src={turnOn}
          />
          <span
            className={`Manual_Automatic_SwitchText ${
              this.props.auto ? "Manual_Automatic_SwitchTextActive" : ""
            }`}
          >
            {" "}
            {this.props.auto ? (
              <Trans i18nKey={"Auto"}> </Trans>
            ) : (
              <Trans i18nKey={"Manual"}> </Trans>
            )}
          </span>
        </div> */}

        <Collapse
          className="pickupcollapsecontainer yodmvydj"
          in={this.props.activePickupDropOff.id === this.state.id}
          timeout="auto"
          unmountOnExit
        >
          {this.props.auto && (
            <React.Fragment>
              <autodriverselector
                ref={this.anchorElAuto}
                onClick={this.handleshowpoperAuto}
              >
                {this.props.auto_activeVehicleType &&
                this.props.auto_suggestListData.length > 0 ? (
                  <React.Fragment>
                    <img
                      src={
                        
                        this.props.auto_activeVehicleType.icon
                      }
                      className="serviceimage"
                    />
                    <servicename>
                      {this.props.auto_activeVehicleType.title}
                    </servicename>
                  </React.Fragment>
                ) : (
                  <servicename style={{ marginLeft: "19px" }}>
                    {" "}
                    <Trans i18nKey={"pleaseSellectParsel"}> </Trans>
                  </servicename>
                )}

                <img
                  src={arrow2}
                  className={` servicearrow ${
                    this.state.showpoperAuto && "rotate180"
                  }`}
                />
              </autodriverselector>
              <serviceOptions
                className={`${
                  this.props.auto_activeVehicleType &&
                  this.props.auto_activeVehicleType.options.length == 0 &&
                  "displayNone"
                }`}
                style={{ marginLeft: "0px", width: "100%" }}
              >
                {this.props.auto_activeVehicleType &&
                  this.props.auto_activeVehicleType.options.map((item) => {
                    return item.type === "list" ? (
                      <ListItem {...item} />
                    ) : (
                      <SelectableItem {...item} />
                    );
                  })}
              </serviceOptions>
            </React.Fragment>
          )}

          {!this.props.auto && (
            <React.Fragment>
              <input
                onChange={this.InputOnChange}
                ref={this.anchorElManual}
                value={this.props.manual_code}
                name={"manual_code-" + this.state.idd}
                autocomplete="z1x2wd"
                onClick={this.handleshowpoperManual}
                className="driverCode"
                placeholder={t("username")}
              />
              <input
                name={"manual_name-" + this.state.idd}
                onChange={this.InputOnChange}
                onClick={this.handleshowpoperManual}
                value={this.props.manual_name}
                autocomplete="zsx2wd"
                className="driverFirstName"
                placeholder={t("Fname")}
              />
              <input
                name={"manual_family-" + this.state.idd}
                onChange={this.InputOnChange}
                onClick={this.handleshowpoperManual}
                value={this.props.manual_family}
                autocomplete="zfx2wd"
                className="driverLastName"
                placeholder={t("Lname")}
              />
              <input
                name={"manual_number-" + this.state.idd}
                onChange={this.InputOnChange}
                onClick={this.handleshowpoperManual}
                value={this.props.manual_number}
                autocomplete="zqx2wd"
                className="driverPhoneNumber"
                type="number"
                placeholder={t("Pnumber")}
              />

              <serviceOptions
                className={`${
                  !this.props.manual_activeVehicleType && "displayNone"
                }`}
                style={{ marginLeft: "0px", width: "100%" }}
              >
                {this.props.manual_activeVehicleType && (
                  <manualDriverActiveServic>
                    {" "}
                    <img
                      src={
                       
                        this.props.manual_activeVehicleType.icon
                      }
                      className="serviceimage"
                    />{" "}
                    {this.props.manual_activeVehicleType.title}
                  </manualDriverActiveServic>
                )}
                {this.props.manual_activeVehicleType &&
                  this.props.manual_activeVehicleType.options.map((item) => {
                    return item.type === "list" ? (
                      <ListItem {...item} />
                    ) : (
                      <SelectableItem {...item} />
                    );
                  })}
              </serviceOptions>
            </React.Fragment>
          )}
        </Collapse>

        <BackdropCustom
          open={
            this.state.showpoperManual &&
            (this.props.manual_suggestListLoading ||
              this.props.manual_suggestListOrdered)
          }
          onClick={this.handleshowpoperManual}
        >
          <Popper
            style={{
              width: "480px",
              zIndex: "6",
              marginTop: "0px",
              maxHeight: "150x",
            }}
            placement={"bottom-start"}
            id={"passenger"}
            open={
              this.state.showpoperManual &&
              (this.props.manual_suggestListLoading ||
                this.props.manual_suggestListOrdered)
            }
            anchorEl={this.anchorElManual.current}
          >
            <Paper
              style={{
                width: "476px",
                float: "right",
                borderRadius: "8px",
                paddingTop: "5px",
                marginTop: "10px",
                paddingBottom: "5px",
                right: "271px",
                maxHeight: "150px",
                overflowY: "scroll",
                overflowX: "hidden",
              }}
              elevation={3}
            >
              {!this.props.manual_suggestListLoading &&
                this.props.manual_suggestListData.length !== 0 &&
                this.props.manual_suggestListData.map((item) => {
                  return (
                    <div
                      className="PassengerListItemContainer"
                      onClick={() => {
                        this.handleManualVehicleTypeSelect(item);
                      }}
                    >
                      <span className="DriverListItemCode">
                        {" "}
                        {item.username}{" "}
                      </span>
                      <span className="DriverListItemName">
                        {" "}
                        {item.givenName}{" "}
                      </span>
                      <span className="DriverListItemFamily">
                        {" "}
                        {item.familyName}{" "}
                      </span>
                      <span className="DriverListItemNumber">
                        {" "}
                        {item.phone}{" "}
                      </span>
                    </div>
                  );
                })}

              {this.props.manual_suggestListLoading && (
                <div>
                  <span
                    className="NotravelsListHeader"
                    style={{ marginTop: "12px" }}
                  >
                    <Trans i18nKey={"poperLoading"}> </Trans>
                  </span>
                  <span className="NotravelsListText">
                    <Trans i18nKey={"fetchingdata"}> </Trans>
                  </span>
                </div>
              )}

              {!this.props.manual_suggestListLoading &&
                this.props.manual_suggestListOrdered &&
                this.props.manual_suggestListData.length === 0 && (
                  <div>
                    <span
                      className="NotravelsListHeader"
                      style={{ marginTop: "12px" }}
                    >
                      <Trans i18nKey={"Noinformationfound"}> </Trans>
                    </span>
                    <span className="NotravelsListText">
                      <Trans i18nKey={"Pleasetryanotherkeyword"}> </Trans>
                    </span>
                  </div>
                )}
            </Paper>
          </Popper>
        </BackdropCustom>

        <BackdropCustom
          open={this.state.showpoperAuto}
          onClick={this.handleshowpoperAuto}
        >
          <Popper
            style={{
              width: "475px",
              zIndex: "6",
              marginTop: "0px",
              maxHeight: "150x",
            }}
            placement={"bottom"}
            id={"passenger"}
            open={this.state.showpoperAuto}
            anchorEl={this.anchorElAuto.current}
          >
            <Paper
              style={{
                width: "476px",
                float: "right",
                borderRadius: "8px",
                paddingTop: "5px",
                marginTop: "10px",
                paddingBottom: "5px",
                right: "271px",
                maxHeight: "150px",
                overflowY: "scroll",
                overflowX: "hidden",
              }}
              elevation={3}
            >
              {this.props.auto_suggestListData.map((item) => {
                return (
                  <autodriverselector
                    className="autodriverselectorInList"
                    onClick={() => {
                      this.handleAutoVehicleTypeSelect(item);
                    }}
                  >
                    <img
                      src={ item.icon}
                      className="serviceimage"
                    />
                    <servicename>{item.title}</servicename>
                  </autodriverselector>
                );
              })}
            </Paper>
          </Popper>
        </BackdropCustom>
      </passerngerContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  auto: state.EditOrder.editOrder.driver.auto,
  auto_activeVehicleType:
    state.EditOrder.editOrder.driver.auto_driver.auto_activeVehicleType,
  auto_suggestListLoading:
    state.EditOrder.editOrder.driver.auto_driver.auto_suggestListLoading,
  auto_suggestListData:
    state.EditOrder.editOrder.driver.auto_driver.auto_suggestListData,
  auto_suggestListOrdered:
    state.EditOrder.editOrder.driver.auto_driver.auto_suggestListOrdered,
  manual_activeVehicleType:
    state.EditOrder.editOrder.driver.manual_driver.manual_activeVehicleType,
  manual_name: state.EditOrder.editOrder.driver.manual_driver.manual_name,
  manual_code: state.EditOrder.editOrder.driver.manual_driver.manual_code,
  manual_family:
    state.EditOrder.editOrder.driver.manual_driver.manual_family,
  manual_number:
    state.EditOrder.editOrder.driver.manual_driver.manual_number,
  manual_id: state.EditOrder.editOrder.driver.manual_driver.manual_id,
  manual_suggestListLoading:
    state.EditOrder.editOrder.driver.manual_driver
      .manual_suggestListLoading,
  manual_suggestListData:
    state.EditOrder.editOrder.driver.manual_driver.manual_suggestListData,
  manual_suggestListOrdered:
    state.EditOrder.editOrder.driver.manual_driver
      .manual_suggestListOrdered,
  activePickupDropOff: state.EditOrder.editOrder.activePickupDropOff,
  lang: state.LocalData.lang,
  viewTravelPureDataVehicleTypes: state.ViewTravel.pureData.vehicleType.id,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
