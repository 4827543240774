import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
const _ = require('lodash'); 


export function useGetFiltersList() {
  const zones = useSelector((state) => state.OrderList.zones)
  const selectedServices = useSelector((state) => state.OrderList.selectedServices)
  const selectedStatus = useSelector((state) => state.OrderList.selectedStatus)
  const selectedVehicleType = useSelector((state) => state.OrderList.selectedVehicleType)
  const isScheduled = useSelector((state) => state.OrderList.isScheduled)
  const now = useSelector((state) => state.OrderList.now)
  const cursor = useSelector((state) => state.OrderList.cursor)
  const cursorSort = useSelector((state) => state.OrderList.cursorSort)
  const DateRange = useSelector((state) => state.OrderList.DateRange)
  const changed = useSelector((state) => state.OrderList.filters.changed)

  return {zones , cursor , cursorSort  , DateRange , selectedServices , selectedStatus , selectedVehicleType , isScheduled , changed ,now};
};




