import SnapboxLogo from "../../../assets/SnapboxLogo.png";
import FavIcon from "../../../assets/PROJECTS/Snapbox.png";

export default function Snapbox () {
    
    return global.config = {
    "ProjectName": "Snapbox",
    "LogoType": SnapboxLogo,
    "BaseApi": "https://rest.snapboxjo.com",
    "BaseSocket": "https://socket.snapboxjo.com",
    "BaseTrack": "https://admin.snapboxjo.com",
    "MapCenter": {
        "lat": "31.340090170314284",
        "lng": "36.76341005649451"
    },
    "Lang": "en",
    "Direction": "ltr",
    "AllLanguages": [{
        "lang": "en",
        "direction": "ltr",
        "i18Lang": "English"
    }],
    "FavIcon": FavIcon,
    "Country": "JO",
    "thirdPartyLoginSupport": true  ,
    mapType :"osm", /* osm | google */ 
    googleApiKey : ""
} }