import React from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import Main from "../page/main";
import BulkImport from "../Entries/BulkImport";
import Login from "../page/login";
import ViewOrderPage from "../page/view-order";

import { useSelector, useDispatch } from "react-redux";
import history from "../service/history";
import { RtlLtrCssSelector } from "../helper/module";
import SharedPage from "../page/shared";
import { useMediaQuery } from "newmaterial-ui";
import { Logout } from "../hooks/logout";
import RestrictedAccess from "../page/403";
import NewOrderPage from "../page/new-order";
import { RouteDetails } from "../layout/route-details/RouteDetails";
import { SyncReduxLocal } from "../hooks/SyncReduxLocal";

export const RouteManager = () => {
  const authenticated = useSelector((state) => state.LocalData.access_token);
  const dispatch = useDispatch();
  const matches = useMediaQuery("(max-width:600px)");
  SyncReduxLocal()
  // useGoogleMapsHook()

  return (
    <RtlLtrCssSelector>
      <Router history={history}>
        <Switch>

          <Route path="/403" >
            <RestrictedAccess />
          </Route>


          <Route path="/logout" >
            <Logout />
          </Route>
          <Route path="/login" >
            <Login />
          </Route>

          {/* <PrivateRoute
            authenticated={authenticated}
            path="/neworder"
            dispatch={dispatch}
            name="Home"
            exact
          >
            <NewOrderPage />
          </PrivateRoute> */}
          {/* 
          <PublicRoute path="/login" isAuthenticated={authenticated}>

            <Login tokenCheck={false} />
          </PublicRoute> */}

          <PrivateRoute
            authenticated={authenticated}
            path="/BulkImport"
            dispatch={dispatch}
            name="BulkImport"
            exact
          >
            <BulkImport />
          </PrivateRoute>

          {/* <PrivateRoute path="/route/:id/:stop"
            authenticated={authenticated}
            dispatch={dispatch}
            name="RouteDetails"
          >
            <RouteDetails />
          </PrivateRoute> */}
          {/* <PrivateRoute
            authenticated={authenticated}
            path="/phubd"
            dispatch={dispatch}
            name="phubd"
          >
            <SharedPage matches={matches} />
          </PrivateRoute> */}
          {/* <PrivateRoute
            authenticated={authenticated}
            path="/ondemand"
            dispatch={dispatch}
            name="ondemand"
          >
            <SharedPage matches={matches} />
          </PrivateRoute> */}
          <PrivateRoute
            authenticated={authenticated}
            path="/"
            dispatch={dispatch}
            name="Home"
          >
            <SharedPage matches={matches} />
          </PrivateRoute>
          {/* <PrivateRoute
            authenticated={authenticated}
            path="/Ondemand"
            dispatch={dispatch}
            name="Home"
            exact
          >
            <SharedPage matches={matches} />
          </PrivateRoute> */}
          {/* <PrivateRoute
            authenticated={authenticated}
            path="/order/:id"
            dispatch={dispatch}
            name="Home"
            exact
          >
            <SharedPage matches={matches} />
          </PrivateRoute> */}




          {/* 
                  <PrivateRoute
                    authenticated={authenticated}
                    path="/order/:orderId"
                    dispatch={dispatch}
                    name="Home"
                >
                    <ViewOrderPage />
                </PrivateRoute> */}


          {/* <PrivateRoute
                    authenticated={authenticated}
                    path="/"
                    dispatch={dispatch}
                    name="Home"
                >
                    <Main />
                </PrivateRoute> */}
        </Switch>
      </Router>
    </RtlLtrCssSelector>
  );
};

function PrivateRoute({ children, authenticated, path, dispatch, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        authenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

function PublicRoute({ children, isAuthenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        !isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/Ondemand",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
