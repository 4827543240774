import LoadmanExpressLogo from "../../../assets/LoadmanExpressLogo.png";
import FavIcon from "../../../assets/PROJECTS/LoadmanExpress.png";

export default function LoadmanExpress () {
    
    return global.config = {
    "ProjectName": "LoadmanExpress",
    "LogoType": LoadmanExpressLogo,
    "BaseApi": "https://rest.loadmanexpress.com",
    "BaseSocket": "https://socket.loadmanexpress.com",
    "BaseTrack": "https://track.loadmanexpress.com",
    "MapCenter": {
        "lat": "9.453390439260966",
        "lng": "77.80025457345596"
    },
    "Lang": "en",
    "Direction": "ltr",
    "AllLanguages": [{
        "lang": "en",
        "direction": "ltr",
        "i18Lang": "English"
    }],
    "FavIcon": FavIcon,
    "Country": "IN",
    "thirdPartyLoginSupport": true  ,
    mapType :"osm", /* osm | google */ 
    googleApiKey : ""
} }