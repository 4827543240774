import * as React from 'react'
import styles from './style.module.css';
import Button from '../../../design-system/lib/Button';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { INFO_CIRCLE } from '../../../assets';
import ViewPriceNewOrder from './view';


function PriceNewOrder({dialoge , setDialoge}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const price = useSelector((state) => state.NewOrder.newOrder.price)


  const handleDialoge = React.useCallback(() => {

    const myEvent = new CustomEvent("closeSliders",{ detail:null});
    document.dispatchEvent(myEvent);


    setTimeout(() => {
      setDialoge(!dialoge)
    }, 100);
  }, [dialoge]);


  React.useEffect(() => {

    document.addEventListener('closeSliders', () => {
     //

     setDialoge(false)

      //
    })
    return () => {
      document.removeEventListener("closeSliders");
  };
  }, [])


  if (!price.price) {
    return null
  }

  return (<>
    <Button size='Medium' isActive={dialoge} type='Secondary' icon={INFO_CIRCLE} onClick={handleDialoge} />
    {dialoge && <ViewPriceNewOrder handleClose={() => { setDialoge(!setDialoge) }} />}
  </>)
}

export default PriceNewOrder



