import logotype from "../../../assets/Sanic_logotype.png";
import FavIcon from "../../../assets/PROJECTS/Sanic.png";

export default function Sanic() {
    
    return  global.config = {
    "ProjectName": "Sanic",
    "LogoType": logotype,
    "BaseApi": "https://rest.saniclogistics.com",
    "BaseSocket": "https://socket.saniclogistics.com",
    "BaseTrack": "https://panel.saniclogistics.com",
    "MapCenter": {
        "lat": "-1.2831410178486997",
        "lng": "36.820336478719554"
    },
    "Lang": "en",
    "Direction": "ltr",
    "AllLanguages": [{
        "lang": "en",
        "direction": "ltr",
        "i18Lang": "English"
    }],
    "FavIcon": FavIcon,
    "Country": "KE",
    "thirdPartyLoginSupport": true  ,
    mapType :"osm", /* osm | google */ 
    googleApiKey : ""
} }