import React from "react";
import Osm from "./osm";
import Google from "./google";


export const DriverPin = (props) => {
    if (props.mapType == "google") {
        return <Google {...props} />
    } else {
        return <Osm {...props} />
    }

};




