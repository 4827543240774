import AutomaterIGO from "../../../assets/Automater.png";
import FavIcon from "../../../assets/PROJECTS/FavIcon_Automater.png";



export default function Automater() {
    
    return global.config = {
    "ProjectName": "Autowmater ",
    "LogoType": AutomaterIGO,
    "BaseApi": "https://rest.autowmater.com",
    "BaseSocket": "https://socket.autowmater.com",
    "BaseTrack": "https://panel.autowmater.com",
    "MapCenter": {
        "lat": 25.3081112758138,
        "lng": 51.1895976330117
    },
    "Lang": "en",
    "Direction": "ltr",
    "AllLanguages": [{
        "lang": "en",
        "direction": "ltr",
        "i18Lang": "English"
    }],
    "FavIcon": FavIcon,
    "Country": "US",
    "thirdPartyLoginSupport": true  ,
    mapType :"osm", /* osm | google */ 
    googleApiKey : ""
}}