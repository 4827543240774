import styles from './style.module.css';
import {StatsItem} from './components/stats-item';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
// import { UpdateOrdersStatistics } from '../../../../../utils/update-ordersStatistics';
import pending from "../../../../../assets/ic-pending.svg";
import collecting from "../../../../../assets/ic-collecting.svg";
import delay from "../../../../../assets/ic-timer-delay.svg";
import progress from "../../../../../assets/ic-in-progress-voip.svg";
import delivering    from "../../../../../assets/ic-delivering.svg";
import canceled from "../../../../../assets/ic-canceled.svg";
import pin from "../../../../../assets/ic-pin.svg";
import done from "../../../../../assets/ic-done.svg";
import transit from "../../../../../assets/ic-in-transit.svg";
import dispatch_later from "../../../../../assets/dispatch_later.svg";
import dispatching from "../../../../../assets/dispatching.svg";
import unassigned from "../../../../../assets/unassigned.svg";

export const Stats = () => {
    const stats = useSelector((state) => state.OrderList.stats.data)

    // useEffect(()=>{
    //     if(ActiveOrderType){
    //         UpdateOrdersStatistics()
    //     }
    // },[ActiveOrderType])

    return <div className={styles.statsContainer}>
        {stats && <>
                <StatsItem name="Pinned" stats={stats} icon={pin}/>
                <StatsItem name="Delayed" stats={stats} icon={delay}/>
                <StatsItem name="Unassigned" stats={stats} icon={unassigned}/>
                <StatsItem name="DispatchLater" stats={stats} icon={dispatch_later}/>
                <StatsItem name="Dispatching" stats={stats} icon={dispatching}/>
                <StatsItem name="Pending"  stats={stats} icon={dispatching}/>
                <StatsItem name="Assigned" stats={stats} icon={collecting}/>
                <StatsItem name="InProgress" stats={stats} icon={progress}/>
                <StatsItem name="Collecting" stats={stats} icon={collecting}/>
                <StatsItem name="Transit" stats={stats} icon={transit}/>
                <StatsItem name="Delivering" stats={stats} icon={delivering}/>
                {/* <StatsItem name="Pinned" stats={stats} icon={pin}/>
                <StatsItem name="Delayed" stats={stats} icon={delay}/> */}
                {/* <StatsItem name="Done" stats={stats} icon={done}/> */}
                <StatsItem name="Canceled" stats={stats} icon={canceled}/>
        </>}

    </div>

};




