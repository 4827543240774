import React, { useCallback, useEffect, useRef } from 'react'
import styles from './style.module.css';
import Typography from '../../../design-system/lib/Typography';
import IconProvider from '../../../design-system/lib/IconProvider';
import { Ic_danger } from '../../../assets';
import Button from '../../../design-system/lib/Button';
import { getTableOrderList } from '../../shared/table/action/getTableOrderList';
import { useSelector } from 'react-redux';







function TableError() {
  const ActiveOrderType = useSelector((state) => state.OrderList.ActiveOrderType)

  
  const reloadList = useCallback(() => {
    getTableOrderList(null , null , ActiveOrderType)

  }, []);
  


  return (
    <div className='jojojoj'
      style={{ display: 'flex', position: 'relative', height: `calc(100vh - 104px)`, width: "100%", flexDirection: 'column', transition: "all .3s ease-in-out" ,justifyContent:"center",alignItems:"center"}}>
          <div className={styles.dialog}>
            <IconProvider Icon={Ic_danger} style={{marginBottom:"16px"}} />
            <Typography weight='Body_Middle_Bold' text='Error in loading data' style={{textAlign:"center" , marginBottom:"4px"}} />
            <Typography weight='Body_Tiny_Regular' text='There was a problem loading data. please try again or contact support' style={{textAlign:"center",marginBottom:"16px"}} />
            <Button size='Medium' type='Primary' text='Try again' onClick={reloadList}/>
          </div>
    </div>
  )
}
export default TableError