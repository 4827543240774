import Axios from "axios";
import { getCategoryList } from "../../components/main/order-list/components/category-collapse-list/action/getCategoryList";
import { RefreshToken } from "../../helper/api";
import { get_id, get_refreshToken, set_name, set_username } from "../../helper/UserData/userdate";
import { OrderInfoAction } from "../../Redux/actions/actions";
import { store } from "../../Redux/store";
import { restClient } from "./axios";

export class RouteApi {

  async getRoutesList(params) {
    try {


      const response = await restClient.request({
        method: 'get',
        url: `/api/v1/dispatcher/route`,
        params: params
      });


      return response.data.data

    } catch (error) {
     if(error.response){
      if (error.response.status === 403) {
        localStorage.clear()
        window.location.reload();
      } else if (error.response) {
        return Promise.reject({
          code: error.response.data.code,
          message: error.response.data.message,
          status: error.response.status,
        })
      } else {
        return Promise.reject()
      }
     }
    }


  }

  async getStopInfo(id) {
    try {


      const response = await restClient.request({
        method: 'get',
        url: `/api/v1/dispatcher/stop/${id}`

      });


      return response.data.data

    } catch (error) {
     if(error.response){
      if (error.response.status === 403) {
        localStorage.clear()
        window.location.reload();
      } else if (error.response) {
        return Promise.reject({
          code: error.response.data.code,
          message: error.response.data.message,
          status: error.response.status,
        })
      } else {
        return Promise.reject()
      }
     }
    }


  }


  async getRouteDetails(params) {
    try {


      const response = await restClient.request({
        method: 'get',
        url: `/api/v1/dispatcher/route/${params}/stops`
      });


      return response.data.data

    } catch (error) {
     if(error.response){
      if (error.response.status === 403) {
        localStorage.clear()
        window.location.reload();
      } else if (error.response) {
        return Promise.reject({
          code: error.response.data.code,
          message: error.response.data.message,
          status: error.response.status,
        })
      } else {
        return Promise.reject()
      }
     }
    }


  }
  async createRoute(data) {
    try {

      const response = await restClient.request({
        method: 'post',
        url: `/api/v1/dispatcher/route`,
        data
      });


      return response.data.data

    } catch (error) {
      if (error.response && error.response.status === 403) {
        localStorage.clear()
        window.location.reload();
      } else if (error.response) {
        return Promise.reject({
          code: error.response.data.code,
          message: error.response.data.message,
          status: error.response.status,
        })
      } else {
        return Promise.reject()
      }
    }


  }


  async ReOrder(data) {
    try {

      const response = await restClient.request({
        method: 'post',
        url: `/api/v1/dispatcher/route/reorder-stops`,
        data
      });


      return {error:false
        ,...response.data.data}

    } catch (error) {
      if (error.response && error.response.status === 403) {
        localStorage.clear()
        window.location.reload();
      } else if (error.response) {
        return {
          error:true,
          code: error.response.data.code,
          message: error.response.data.message,
          status: error.response.status,
        }
      } else {
        return null
      }
    }


  }


  async Optimize(data) {
    try {

      const response = await restClient.request({
        method: 'post',
        url: `/api/v1/dispatcher/route/optimize`,
        data
      });


      return response.data.data

    } catch (error) {
      if (error.response && error.response.status === 403) {
        localStorage.clear()
        window.location.reload();
      } else if (error.response) {
        return Promise.reject({
          code: error.response.data.code,
          message: error.response.data.message,
          status: error.response.status,
        })
      } else {
        return Promise.reject()
      }
    }


  }

  async UpdateStatus(data) {
    try {

      const response = await restClient.request({
        method: 'post',
        url: `/api/v1/dispatcher/route/update-status`,
        data
      });


      return response.data.data

    } catch (error) {
      if (error.response && error.response.status === 403) {
        localStorage.clear()
        window.location.reload();
      } else if (error.response) {
        return Promise.reject({
          code: error.response.data.code,
          message: error.response.data.message,
          status: error.response.status,
        })
      } else {
        return Promise.reject()
      }
    }


  }

  async UpdateInfo(data) {
    try {

      const response = await restClient.request({
        method: 'post',
        url: `/api/v1/dispatcher/route/update-info`,
        data
      });


      return response.data.data

    } catch (error) {
      if (error.response && error.response.status === 403) {
        localStorage.clear()
        window.location.reload();
      } else if (error.response) {
        return Promise.reject({
          code: error.response.data.code,
          message: error.response.data.message,
          status: error.response.status,
        })
      } else {
        return Promise.reject()
      }
    }


  }
}


