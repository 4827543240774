import deliros from "../../../assets/deliro.svg";
import FavIcon from "../../../assets/PROJECTS/onro.png";

export default function deliro() {
    
    return  global.config = {
    "ProjectName": "دلیرو",
    "LogoType": deliros,
    "BaseApi": "https://rest.onro.org",
    "BaseSocket": "https://socket.onro.org",
    "BaseTrack": "https://panel.onro.org",
    "MapCenter": {
        "lat": "35.694654",
        "lng": "51.404343"
    },
    "Lang": "fa",
    "Direction": "rtl",
    "AllLanguages": [{
        "lang": "en",
        "direction": "ltr",
        "i18Lang": "English"
    }, {
        "lang": "fa",
        "direction": "rtl",
        "i18Lang": "Persian"
    }, {
        "lang": "fr",
        "direction": "ltr",
        "i18Lang": "French"
    }, {
        "lang": "ro",
        "direction": "ltr",
        "i18Lang": "Romanian"
    }],
    "FavIcon": FavIcon,
    "Country": "US",
    "thirdPartyLoginSupport": true  ,
    mapType :"osm", /* osm | google */ 
    googleApiKey : ""
} }