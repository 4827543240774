import React from "react";

 const Pin = ({color ="red" , style}) => {

    return  <svg
    width={54}
    height={57}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{...style}}
  >
    <g filter="url(#a)">
      <path
        d="M41.477 22.714c0 6.275-4.043 11.611-9.684 13.583-3.078 1.504-4.17 4.018-4.916 4.018-.844.099-2.085-2.46-5.611-4.29-5.195-2.189-8.79-7.354-8.79-13.31 0-7.952 6.492-14.397 14.5-14.397 8.009 0 14.5 6.445 14.5 14.396z"
        fill="#fff"
      />
      <path
        d="M41.477 22.714c0 6.275-4.043 11.611-9.684 13.583-3.078 1.504-4.17 4.018-4.916 4.018-.844.099-2.085-2.46-5.611-4.29-5.195-2.189-8.79-7.354-8.79-13.31 0-7.952 6.492-14.397 14.5-14.397 8.009 0 14.5 6.445 14.5 14.396z"
        fill={color}
      />
      <circle cx={26.869} cy={22.616} r={11.931} fill="#fff" />
      <circle cx={26.977} cy={22.318} r={4} fill={color} />
    </g>
    <defs>
      <filter
        id="a"
        x={-0.723}
        y={0.318}
        width={56}
        height={56}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={4} />
        <feGaussianBlur stdDeviation={6} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_2893_32688"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_2893_32688"
          result="shape"
        />
      </filter>
    </defs>
  </svg>


};

export default Pin



