import _ from "lodash";
import * as actionTypes from "../actions/actionTypes";


const init = {
  googleMapLoaded: false,
  services: [],
  vehicleTypes: [],
  zone: [],
  mainLoaded: false,
  settings: null
};


export const SharedData = (state = init, action) => {
  switch (action.type) {
    case actionTypes.setGoogleMapLoaded:
      return {
        ...state,
        googleMapLoaded: action.state,
      };

    case actionTypes.setSettings:
      return {
        ...state,
        settings: action.state,
      };

    case actionTypes.setMainLoaded:
      return {
        ...state,
        mainLoaded: action.state,
      };
    case actionTypes.setSharedDataServices:
      return {
        ...state,
        services: action.state,
      };
    case actionTypes.setSharedDataZone:
      return {
        ...state,
        zone: action.state,
      };
    case actionTypes.setSharedDataVehicleTypes:
      return {
        ...state,
        vehicleTypes: action.state,
      };


    default:
      return state;
  }
};

