import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Sidebar } from '../../components/shared/side-bar';
import { InitialRouteDetails } from './initial-main';
import styles from './RouteDetails.module.css';
import { useLocation, useParams } from "react-router-dom";
import { RouteApi } from '../../service/API/route';
import ProgressIndicator from '../../design-system/lib/ProgressIndicator';
import IconProvider from '../../design-system/lib/IconProvider';
import { Ic_danger, WARNING } from '../../assets';
import Typography from '../../design-system/lib/Typography';
import Button from '../../design-system/lib/Button';
import clsx from 'clsx';
import Header from '../../components/route-details/header';
import Driver from '../../components/route-details/driver';
import ListItems from '../../components/route-details/listItems';
import { MapContainer } from '../../components/shared/map-container';
import { MapOrderDetailsPinsOnMap } from '../../components/shared/Map-order-details-pinsOnMap';
import { store } from '../../Redux/store';
import Footer from '../../components/route-details/footer';
import { ReOrder } from './reorder/Reorder';
import { OrderApi } from '../../service/API/order';
import { Stopdetails } from './stop-details/Stopdetails';
import Modal from '../../design-system/lib/Modal';
import CustomModal from '../../design-system/lib/CustomModal';
import EditStatusRoute from '../../components/table/route-list/components/editStatus';
import EditStatusSummary from '../../components/table/EditStatusSummary';

export function RouteDetails({setRouteDetails , setRouteDetailsfilteredTexts}) {
    const mainLoaded = useSelector((state) => state.SharedData.mainLoaded)
    const settings = useSelector((state) => state.SharedData.settings)

    const [loaded, setLoaded] = useState(mainLoaded);
    const [isreorder, setIsreorder] = useState(false);
    const [filteredText, setFilteredText] = useState("");
    const [orderData, setOrderData] = useState(null);
    const [orderLoading, setOrderLoading] = useState(false);
    const [orderError, setorderError] = useState(false);
    const [optDialoge, setOptDialoge] = useState(false);
    const [optLoading, setOptLoading] = useState(false);
    const [editStatus, setEditStatus] = useState(false);

    const [data, setData] = useState(null);
    const [isError, setIsError] = useState(false);
    const mapRef = useSelector((state) => state.Map.mapRef)
    const groupRef = useSelector((state) => state.Map.osmFeatureGroup)
    const selectedBulkEditStatusSummary = useSelector((state) => state.Table.selectedBulkEditStatusSummary)

    let { id , stop } = useParams();


    useEffect(() => {
        document.addEventListener("routeUpdated", () => {
            getData(id)
        });
        return () => document.removeEventListener("routeUpdated", getData(id));
    }, [id]);


    useEffect(() => {
        stop && StopInfo(stop)

    }, []);


    useEffect(() => {
        document.addEventListener("stopUpdated", (e)=>{
            console.log("stopUpdated -> dispatcher:stop:updated ",e.detail.stopId);

            StopInfo(e.detail.stopId)
            
            

        }); 
        return () => document.removeEventListener("stopUpdated", ()=>{
          
        });
    },[]);


    const  StopInfo = useCallback(async (e) => {
        console.log("StopInfo -> dispatcher:stop:updated ",e,orderData);


        let Route = new RouteApi()
        let data = await Route.getStopInfo(e)
        getData(id)
        if (data) {
            console.log("StopInfo -> dispatcher:stop:updated ccc ",data,e,orderData);

            setOrderData({ OrderLoading : false , orderError:false , stop :data , ...data})
        } 

      }, [id ,  orderData]);


      const  RouteOptimize = useCallback(async (e) => {
       try {
         setOptLoading(true)
 
 
         let Route = new RouteApi()
         let data = await Route.Optimize({
             routeId:id
         })
         getData(id)
         setOptLoading(false)
         
       } catch (error) {
        setOptLoading(false)
       }


      }, [id ,  orderData]);

    useEffect(() => {
        if (!mainLoaded || !settings) {
            InitialRouteDetails((call) => {
                setLoaded(call);
                if (!data) {
                    getData(id)
                }
            })
        } else {
            if (!data) {
                getData(id)
            }
        }
    }, [mainLoaded, id])

    const getOrderData = async (id, stop) => {
        try {
            setOrderData({ stop: stop, orderError: false, OrderLoading: true })
            setOrderLoading(true)
            let Route = new RouteApi()
            let data = await Route.getStopInfo(id)

            if (data) {
                setOrderData({ ...data, stop: stop, orderError: false, OrderLoading: false })
            } else {
                setOrderData({ stop: stop, orderError: true, OrderLoading: false })
            }
        } catch (error) {
            setOrderData({ stop: stop, orderError: true, OrderLoading: false })

        }
    }



    const getData = async (id) => {
        setIsError(false)

        try {
            let Route = new RouteApi()
            let datax = await Route.getRouteDetails(id)
            setData(datax)
            setRouteDetails(datax)
            setRouteDetailsfilteredTexts(filteredText)
            setIsError(false)
            setTimeout(() => {
                Bounding(datax)
            }, 100);

        } catch (error) {
            setIsError(true)

        }
    }


    const Bounding = React.useCallback((data) => {

        try {
            if (global.config.dispatcherSettings.applicationMap.type == "google") {
                let storeData = store.getState();
                console.log("Bounding s", storeData.Map.mapRef)

                if (!storeData.Map.mapRef) {
                    setTimeout(() => {
                        Bounding(data)
                    }, 1000);
                } else {
                    const bounds = new window.google.maps.LatLngBounds();

                    bounds.extend(new window.google.maps.LatLng(data.route.startLocation.coordinates[1], data.route.startLocation.coordinates[0]));
                    data.route.endLocation && bounds.extend(new window.google.maps.LatLng(data.route.endLocation.coordinates[1], data.route.endLocation.coordinates[0]));
                    data.stops.map((drop) => {
                        bounds.extend(new window.google.maps.LatLng(drop.coordinates[1], drop.coordinates[0]));
                    });

                    storeData.Map.mapRef.fitBounds(bounds);
                }

            } else {
                const map = mapRef.current.leafletElement;  //get native Map instance
                const group = groupRef.current.leafletElement; //get native featureGroup instance


                console.log("Dwdwdwdd ", map, group.getBounds())
                map.fitBounds(group.getBounds());
            }

        } catch (error) {

        }


    }, [mapRef, groupRef]);



    console.log("Boundatading ",orderData)
    if(selectedBulkEditStatusSummary){
        return <div className={styles.innerApp}>
            {/* <Sidebar /> */}
        <EditStatusSummary style={{flex:"1"}} />
    </div>
    }else if (!loaded) {
        return <Loading />
    } else {
        return <div className={styles.container}>

            {/* <Sidebar /> */}

           {(data && editStatus) && <EditStatusRoute  setEditStatus={setEditStatus}  data={data} />}
           {optDialoge && <Modal size='Small' CancelOnClick={()=>{setOptDialoge(false)}} CancelText='No, Cancel' ConfirmationOnClick={()=>{
                setOptDialoge(false)
                RouteOptimize()
            }} ConfirmationText='Yes, Optimize' icon={WARNING}
            title='Optimize Stops?' description='The sequence of stops changes automatically. you can change it manually later'   onclose={()=>{setOptDialoge(false)}}
            />}

          {optLoading &&  <CustomModal >
                <div style={{backgroundColor:"white",width:"80px",height:"80px" ,borderRadius:"8px" ,boxShadow:"0px 1px 6px 0px rgba(128, 128, 128, 0.16)" , display:"flex", flexDirection:"row",justifyContent:"center",alignItems:"center"}}>
                    <ProgressIndicator theme='On_Light' style={{width:"32px",height:"32px"}} />
                </div>
                
            </CustomModal>}

            <div className={styles.right}>
                {isreorder ? <ReOrder setIsreorder={setIsreorder} data={data} setData={(e)=>{setData(e) ; setRouteDetails(e)}} /> :
                    <div className={clsx(styles.detailsContainer, (isError || (!data && !isError)) && styles.whiteBackgroundColor)} sty>
                        {data && <>
                            <Header setEditStatus={setEditStatus} onchange={()=>{getData(id)}}  setOptDialoge={setOptDialoge} setIsreorder={setIsreorder} stopsCount={data?.stops.length} date={data?.route?.createdAt} name={data?.route?.name} data={data} status={data.route.status} durationSeconds={data.route.durationSeconds} distanceMeters={data.route.distanceMeters} />
                            {data?.route?.driver && <Driver {...data?.route?.driver} />}
                            <ListItems selectedStopId={stop} selectedStop={orderData} routeId={id} getOrderData={getOrderData} stops={data?.stops} route={data?.route} setFilteredText={(e)=>{setFilteredText(e) ; setRouteDetailsfilteredTexts(e)}} filteredText={filteredText} />
                        </>

                        }

                        {(!data && !isError) && <ProgressIndicator theme='On_Light' className={styles.ProgressIndicator} />}
                        {isError && <DetailsError reload={() => { getData(id) }} />}
                        {/* {(data?.route?.status == "Unassigned" && data?.stops?.length > 0) && <Footer setIsreorder={setIsreorder}  />} */}
                        {(data?.route && orderData) && <Stopdetails routeId={id} reload={getOrderData} setOrderData={setOrderData} data={orderData} orderLoading={orderLoading} />}

                    </div>}

                {/* <MapContainer customBounds={null} style={{ width: "500px", flex: "1", height: "100%", zIndex: "6", position: "relative" }} className={styles.mainMapContainer} onCenterChange={() => { }} >
                    {data && <MapOrderDetailsPinsOnMap STOPS={data.stops.filter((item) => String(item.address).toLocaleLowerCase().includes(String(filteredText).toLocaleLowerCase()))} ROUTE={data.route} />}
                </MapContainer> */}
            </div>



        </div>

    }

}


const Loading = () => {
    return <div style={{ float: "left", marginLeft: "calc(50vw - 40px)", marginTop: 'calc(50vh - 40px)' }}>
        <div class="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
}


function DetailsError({ reload }) {

    return (
        <div className='jojojoj'
            style={{ display: 'flex', position: 'relative', height: `calc(100vh - 104px)`, width: "100%", flexDirection: 'column', transition: "all .3s ease-in-out", justifyContent: "center", alignItems: "center" }}>
            <div className={styles.dialog}>
                <IconProvider Icon={Ic_danger} style={{ marginBottom: "16px" }} />
                <Typography weight='Body_Middle_Bold' text='Error in loading data' style={{ textAlign: "center", marginBottom: "4px" }} />
                <Typography weight='Body_Tiny_Regular' text='There was a problem loading data. please try again or contact support' style={{ textAlign: "center", marginBottom: "16px" }} />
                <Button size='Medium' type='Primary' text='Try again' onClick={reload} />
            </div>
        </div>
    )
}
