import { MonitoringSetDrivers, MonitoringSetOrders } from "../../../../../../Redux/actions/actionNames";
import { store } from "../../../../../../Redux/store";
import momentJalaali from "moment-jalaali";
import { DriverApi } from "../../../../../../service/API/driver";
const _ = require("lodash");

export const getDriverMonitoring = async () => {

  // let storeData = store.getState();

  let Driver = new DriverApi()
  let data = await Driver.Monitoring({})
  store.dispatch(MonitoringSetDrivers(data))


};

