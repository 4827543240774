import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TableInputContainFilter_Add, TableInputContainFilter_Delete, TableInputContainFilter_Update, TableRangeFilter_Update } from '../Redux/actions/actionNames';




export function GetTableInputRangeContainFilter(selector) {
  const [data, setData] = useState({equal: null, gte: null, lte: null} );
  const pureData = useSelector((state) => state.Table.filters[selector])
  const dispatch = useDispatch();


  useEffect(() => {

    if (pureData) {
      setData(pureData)
    }

  }, []);

  useEffect(() => {

    if (pureData) {
      setData(pureData)
    }

  }, [pureData]);


  const OnChange = React.useCallback((e) => {
    dispatch(TableRangeFilter_Update({ key: selector, value: e }))
    setData(e)
  }, [data, pureData, selector]);



  return { [`${selector}_data`]: data, [`${selector}_OnChange`]: OnChange };
};

