import React from 'react'
import {  useEffect } from 'react';
import { useSelector } from 'react-redux';


export function useMapContainerHeight() {
  const openNewOrder = useSelector((state) => state.NewOrder.OpenDialoge)

  return {MapContainerHeight : ( openNewOrder ) ? "100vh" : "calc(100% - 52px)" , hideNavbar:  openNewOrder }
};




