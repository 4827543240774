import React from "react";

 const EndPin = (props) => {

    return    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={37}
    fill="none"
    style={{
      transform: "scale(1.4)",
      marginLeft: '-5px',
      marginTop: '-41px'
    }}
    {...props}
  >
    <path
      fill="#484848"
      fillRule="evenodd"
      d="M3.813.85A3.75 3.75 0 0 0 .063 4.6v12.5a3.75 3.75 0 0 0 3.75 3.75h5.624v14.874a.625.625 0 1 0 1.25 0V20.849h5.626a3.75 3.75 0 0 0 3.75-3.75V4.6a3.75 3.75 0 0 0-3.75-3.75h-12.5Z"
      clipRule="evenodd"
    />
    <rect
      width={17.333}
      height={17.333}
      x={1.395}
      y={2.181}
      fill="#fff"
      rx={2.5}
    />
    <path
      fill="#484848"
      d="m15.003 7.185-1.647 3.113 1.645 3.042a.468.468 0 0 1-.404.688l-8.28.111V6.967a.466.466 0 0 0-.465-.466h8.74a.466.466 0 0 1 .41.684Z"
      opacity={0.4}
    />
    <path
      fill="#484848"
      fillRule="evenodd"
      d="M6.059 6.35c.207 0 .375.161.375.36v8.654c0 .2-.168.36-.375.36a.368.368 0 0 1-.375-.36V6.71c0-.199.167-.36.375-.36Z"
      clipRule="evenodd"
    />
  </svg>

};

export default EndPin



