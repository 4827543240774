import myWorker from "./test.worker";
import { CalculatePriceAction, CalculatePriceReserveAction, CalculatePrice_Pickup_Action } from "../../../Redux/actions/actions";
import { SetPriceNewOrder } from "../../../Redux/actions/actionNames";

export const CalculatePrice_Pickup_Worker = (data, dispatch, noHttpOrder) => {

  const worker = new myWorker();
  worker.postMessage(data);
  worker.addEventListener("message", (event) => {

    if (event.data.succeessfull) {
      if (noHttpOrder) {
        dispatch(SetPriceNewOrder({}, { done: true }));
      } else {
        dispatch(CalculatePrice_Pickup_Action(dispatch, event.data.data));
      }
    } else {
      dispatch(SetPriceNewOrder({ done: false }, { done: false,where:"202"  }));
    }
    worker.terminate();
  });
};
