import * as actionTypes from "../actions/actionTypes";
var _ = require("lodash");

const init = {
  OpenDialoge: false,
  activeRules: null,
  initialRule : null,
  vehicleTypes: null,
  vehicleTypesLoading: false,
  vehicleTypesError: false,
  Type: null,
  PickupOrderInfo: null,
  PickupOrderLoading: false,
  PickupOrderError: false,
  DliveryOrderInfo: null,
  DliveryOrderLoading: false,
  DliveryOrderError: false,
  firstDriver: {
    autoManualSwitch: true,
    options: [],
    manual: {
      searchDriverInput: "",
      manual_activeVehicleType: null,
      manual_name: "",
      manual_family: "",
      manual_number: "",
      manual_id: "",
      manual_code: "",
      manual_suggestListLoading: false,
      manual_suggestListData: [],
      manual_suggestListOrdered: false,
      options: [],
    },
    auto: {
      auto_activeVehicleType: null,
      options: [],
    }
  },
  secondDriver: {
    autoManualSwitch: true,
    options: [],
    manual: {
      searchDriverInput: "",
      manual_activeVehicleType: null,
      manual_name: "",
      manual_family: "",
      manual_number: "",
      manual_id: "",
      manual_code: "",
      manual_suggestListLoading: false,
      manual_suggestListData: [],
      manual_suggestListOrdered: false,
      options: [],
    },
    auto: {
      auto_activeVehicleType: null,
      options: [],
    }
  }
};

export const AdvancedAssaigning = (state = init, action) => {
  switch (action.type) {
    case actionTypes.advancedAssaigning_handleDialoge:
      return {
        ...state,
        OpenDialoge: action.state
      };

    case actionTypes.advancedAssaigning_setVehicleType:
      return {
        ...state,
        ...action.state
      };

    case actionTypes.advancedAssaigning_firstDriver_auto:
      return {
        ...state,
        firstDriver: {
          ...state.firstDriver,
          auto: {
            ...state.firstDriver.auto,
            auto_activeVehicleType: action.state
          }
        }
      };

    case actionTypes.advancedAssaigning_firstDriver_auto_switch:
      return {
        ...state,
        firstDriver: {
          ...state.firstDriver,
          autoManualSwitch: action.state
        }
      };

    case actionTypes.advancedAssaigning_firstDriver_manual:
      return {
        ...state,
        firstDriver: {
          ...state.firstDriver,
          manual: {
            ...state.firstDriver.manual,
            ...action.state
          }
        }
      };


    case actionTypes.advancedAssaigning_firstDriver_options_add:
      return {
        ...state,
        firstDriver: {
          ...state.firstDriver,
          [action.flag]:{
            ...state.firstDriver[action.flag],
            options: [
              ...state.firstDriver[action.flag].options,
              action.state
            ]
          }
        }
      };

    case actionTypes.advancedAssaigning_firstDriver_options_remove:
      return {
        ...state,
        firstDriver: {
          ...state.firstDriver,
          [action.flag]:{
            ...state.firstDriver[action.flag],
          options: state.firstDriver[action.flag].options.filter(
            (item) => item.id !== action.state
          ),
          }
        }
      };
    case actionTypes.advancedAssaigning_firstDriver_options_reset:
      return {
        ...state,
        firstDriver: {
          ...state.firstDriver,
          [action.flag]:{
            ...state.firstDriver[action.flag],
          options: [],
          }
        }
      };



      case actionTypes.advancedAssaigning_secondDriver_auto:
        return {
          ...state,
          secondDriver: {
            ...state.secondDriver,
            auto: {
              ...state.secondDriver.auto,
              auto_activeVehicleType: action.state
            }
          }
        };
  
      case actionTypes.advancedAssaigning_secondDriver_auto_switch:
        return {
          ...state,
          secondDriver: {
            ...state.secondDriver,
            autoManualSwitch: action.state
          }
        };
  
      case actionTypes.advancedAssaigning_secondDriver_manual:
        return {
          ...state,
          secondDriver: {
            ...state.secondDriver,
            manual: {
              ...state.secondDriver.manual,
              ...action.state
            }
          }
        };
  
  
      case actionTypes.advancedAssaigning_secondDriver_options_add:
        return {
          ...state,
          secondDriver: {
            ...state.secondDriver,
            [action.flag]:{
              ...state.secondDriver[action.flag],
              options: [
                ...state.secondDriver[action.flag].options,
                action.state
              ]
            }
          }
        };
  
      case actionTypes.advancedAssaigning_secondDriver_options_remove:
        return {
          ...state,
          secondDriver: {
            ...state.secondDriver,
            [action.flag]:{
              ...state.secondDriver[action.flag],
            options: state.secondDriver[action.flag].options.filter(
              (item) => item.id !== action.state
            ),
            }
          }
        };
      case actionTypes.advancedAssaigning_secondDriver_options_reset:
        return {
          ...state,
          secondDriver: {
            ...state.secondDriver,
            [action.flag]:{
              ...state.secondDriver[action.flag],
            options: [],
            }
          }
        };



    case actionTypes.advancedAssaigning_setRule:
      return {
        ...state,
        activeRules: action.state
      };

      case actionTypes.advancedAssaigning_setIniRule:
        return {
          ...state,
          initialRule: action.state
        };


    case actionTypes.advancedAssaigning_reset:
      return init;

    default:
      return state;
  }
};

