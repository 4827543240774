import React from "react";
import { Map, Marker, TileLayer, Popup, Polyline } from "react-leaflet";
import L from "leaflet";
import { connect } from "react-redux";
import ReactDOMServer from "react-dom/server";
import PickupPin from "../../map-pickup-pin/Pin";
import DropOffPin from "../../map-dropoff-pin/Pin";
import { GetColorByStatus } from "../../../../utils/get-colors-by-status";
import { DropOffPinWithNumber } from "../../map-dropoff-pin-with-number";
import { pickup_isPickuped } from "../../../../helper/module";


const createtIconPickup = (color) => {
  return new L.divIcon({
    className: "",
    html: ReactDOMServer.renderToString(<PickupPin color={"#" + color} bigSize={true} />),
    iconSize: [54, 57],
    iconAnchor: [14, 50],
  });
};


const createtIconDropOff = (color) => {
  return new L.divIcon({
    className: "",
    html: ReactDOMServer.renderToString(<DropOffPin color={"#" + color} bigSize={true} />),
    iconSize: [54, 57],
    iconAnchor: [14, 54],
  });
};


class App extends React.Component {

  render() {
    if (this.props.FavAddressOpenDialoge) {
      return null
    }
    let originShow = (!this.props.OpenNDialoge && !this.props.OpenERDialoge) && !this.props.OpenERDialoge && this.props.VRpureData && ["Pickup", "PickupDelivery", "Ondemand"].indexOf(this.props.orderType) > -1;
    let destinationShow = (!this.props.OpenNDialoge && !this.props.OpenERDialoge) && !this.props.OpenERDialoge && this.props.VRpureData;
    let destination2Show = this.props.VRpureData && this.props.VRpureData.dropoff;
    let deliveryShow = this.props.VRpureData && this.props.VRpureData.delivery;

    return (
      <React.Fragment>
        {originShow ? (<Marker
          animate={true}
          position={{ lat: this.props.VRpureData.pickup.coordinates[1], lng: this.props.VRpureData.pickup.coordinates[0] }}
          icon={createtIconPickup(pickup_isPickuped(this.props.VRpureData.status) ? "009b62" : GetColorByStatus(this.props.VRpureData.status))}
        ></Marker>
        ) : null}


        {/* {(originShow ) ? (<Marker
          position={{ lat: this.props.VRpureData.pickup.coordinates[1], lng: this.props.VRpureData.pickup.coordinates[0] }}
          ></Marker>
        ) : null} */}


        {(this.props.VRpureData.dropoffs || this.props.VRpureData.dropoff) ? (
          destination2Show && (this.props.VRpureData.dropoffs.length == 0 ?
            <Marker
              animate={true}
              position={{ lat: this.props.VRpureData.dropoff.coordinates[1], lng: this.props.VRpureData.dropoff.coordinates[0] }}
              icon={createtIconDropOff(this.props.VRpureData.dropoff.status == "Done" ? "009b62" : GetColorByStatus(this.props.VRpureData.status))}
            ></Marker>
            : <DropOffPinWithNumber numberStyle={{ position: "absolute", width: "20px", height: "20px", left: "5px", top: "5px", fontSize: "14px", fontWeight: "bold", fontFamily: 'sf_pro', textAlign: "center" }} mapType={this.props.mapType} status={this.props.VRpureData.status} position={{ lat: this.props.VRpureData.dropoff.coordinates[1], lng: this.props.VRpureData.dropoff.coordinates[0] }} number={1} />
          )
        ) : (
          <Marker
            animate={true}
            position={{ lat: this.props.VRpureData.delivery.coordinates[1], lng: this.props.VRpureData.delivery.coordinates[0] }}
            icon={createtIconDropOff(this.props.VRpureData.delivery.status == "Done" ? "009b62" : GetColorByStatus(this.props.VRpureData.status))}
          ></Marker>
        )}


        {/* {(destination2Show && this.props.VRpureData.dropoffs.length == 0 ) ? (<Marker
          position={{ lat: this.props.VRpureData.dropoff.coordinates[1], lng: this.props.VRpureData.dropoff.coordinates[0] }}
        ></Marker>
        ) : null} */}




        {(destinationShow && !deliveryShow)
          ? ((!destination2Show && this.props.VRpureData.dropoffs.length == 1) ? <Marker
            animate={true}
            position={{ lat: this.props.VRpureData.dropoffs[0].coordinates[1], lng: this.props.VRpureData.dropoffs[0].coordinates[0] }}
            icon={createtIconDropOff(this.props.VRpureData.dropoffs[0].status == "Done" ? "009b62" : GetColorByStatus(this.props.VRpureData.status))}
          ></Marker>

            : this.props.VRpureData.dropoffs.map((dropoff, i) => {
              return <DropOffPinWithNumber numberStyle={{ position: "absolute", width: "20px", height: "20px", left: "5px", top: "5px", fontSize: "14px", fontWeight: "bold", fontFamily: 'sf_pro', textAlign: "center" }}
                mapType={this.props.mapType} status={this.props.VRpureData.status}
                color={dropoff.status == "Done" ? "#009b62" : null}
                position={{ lat: dropoff.coordinates[1], lng: dropoff.coordinates[0] }} number={i + 1} />

            }))
          : null}


        {(deliveryShow) && <Marker
          animate={true}
          position={{ lat: deliveryShow.coordinates[1], lng: deliveryShow.coordinates[0] }}
          icon={createtIconDropOff(deliveryShow.status == "Done" ? "009b62" : GetColorByStatus(this.props.VRpureData.status))}
        ></Marker>}


        {(destination2Show) && <Marker
          animate={true}
          position={{ lat: destination2Show.coordinates[1], lng: destination2Show.coordinates[0] }}
          icon={createtIconDropOff(destination2Show.status == "Done" ? "009b62" : GetColorByStatus(this.props.VRpureData.status))}
        ></Marker>}

        {/* {(destinationShow ) ? (<Marker
            position={{ lat: this.props.VRpureData.dropoffs[0].coordinates[1], lng: this.props.VRpureData.dropoffs[0].coordinates[0] }}
            ></Marker>
        ) : null} */}

        {/* {destinationShow
          ? this.props.VRpureData.dropoffs.map((dropoff, i) => {
            return <Marker
            animate={true}
            position={{ lat: dropoff.coordinates[1], lng: dropoff.coordinates[0] }}
          ></Marker>
               })
          : null} */}

      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  FavAddressOpenDialoge: state.FavAddress.openDialoge,
  OpenVRDialoge: state.ViewTravel.OpenDialoge,
  OpenNDialoge: state.NewOrder.OpenDialoge,
  VRpureData: state.ViewTravel.pureData,
  OpenERDialoge: state.EditOrder.OpenDialoge,
  orderType: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.type
      ? state.ViewTravel.pureData.type
      : null
    : null,

});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(mapStateToProps, mapDispatchToProps)(App);
