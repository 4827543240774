import {
  GetOrderList,
  GetLocationPlacename,
  SearchAutoComplete,
  SearchAddress,
  PlaceIdDetails,
  DeleteFavAddressHttp,
  ServicesVehicletype,
  VehicletypeByService,
  SearchCustomer,
  SearchDriver,
  CalculatePrice,
  Orderondemand,
  OrderInfo,
  OrderEditondemand,
  VehicletypesMonitoringDriversHttp,
  EstimatedRoute,
  getFavAddressList,
  getFavAddressInfo,
  SaveFavAddressHttp,
  EditFavAddressHttp,
  SearchAutoCompleteWithCal,
  CalculatePriceReserve,
  Orderschedule,
  BusinessSearch,
  OrderHistory,
  CancelOrder,
  DoneOrder,
  TrackOrderDriver,
  GetDriverListHttp,
  GetDriverOrderListHttp,
  GetDriverInfoHttp,
  Get_Addressbook_list_Api,
  Update_Order_Status_Api,
  OrderEditEarning,
  OrderEditPickupDropoffondemand,
  OrderEditPriceondemand,
  SearchDriver_Pickup,
  SearchDriver_Dropoff,
  CalculatePrice_Pickup,
  CalculatePrice_Delivery,
  CalculatePrice_PickupDelivery,
  OrderPickup,
  OrderDelivery,
  OrderPickupDelivery,
  Get_Order_Events_Api,
  CreateNewEvent_Http,
  VehicletypeByService_AdvancedAssaigning,
  AdvancedAssaigning_OrderDelivery,
  AdvancedAssaigning_OrderPickup,
  AdvancedAssaigning_OrderPickupInfo,
  AdvancedAssaigning_OrderDeliveryInfo,
  SearchDriver_AdvancedAssaigning,
  VehicletypeForMonitoring,
  Update_Order_PickupDelivery_Status_Api,
  Update_Order_Pickup_Status_Api,
  Update_Order_Delivery_Status_Api,
  Get_User_Recent_Address_Api_Http,
  Register_New_Customer_Api,
  CustomerGetInfoHttp,
  SetEventForOrderFromList_Http,
  GetEventVoipRecorded_http,
  Voip_OutgoingCall_Http,
  OrderEditPickupDropoffPickup,
  OrderEditPickupDropoffDelivey,
  OrderEditPickupDropoffPickupDelivey,
  OrderEditPricePickup,
  OrderEditPriceDelivery,
  OrderEditPricePickupDelivery,
  Edit_individual_info_http,
  SearchAutoComplete_local_address,

} from "../../helper/api/index";
import { snack } from "../../utils/SnackbarUtilsConfigurator";
import {
  Set_SearchAutoCompleteLoading,
  SuggestCustomerListLoading,
  DeleteFavAddress,
} from "./actionNames";
import { deleteFavAddress } from "./actionTypes";

export function GetOrderListAction(dispatch, data , func) {
  return async (dispatch) => {
    if (/* navigator.onLine */ true) {
      await GetOrderList(dispatch, data , func);
    } else {
      snack.warning_i18("networkError")
    }
  };
}

export function GetLocationPlacenameAction(dispatch, data, call) {
  return async (dispatch) => {
    if (/* navigator.onLine */ true) {
      await GetLocationPlacename(dispatch, data, call);
    } else {
      snack.warning_i18("networkError")
    }
  };
}

export function SearchAutoCompleteAction(dispatch, data) {
  return async (dispatch) => {
    if (/* navigator.onLine */ true) {
      await SearchAutoComplete(dispatch, data);
    } else {
      dispatch(Set_SearchAutoCompleteLoading(false));
      snack.warning_i18("networkError")
    }
  };
}


export function SearchAutoCompleteAction_local_address(dispatch, data) {
  return async (dispatch) => {
    if (/* navigator.onLine */ true) {
      await SearchAutoComplete_local_address(dispatch, data);
    } else {
      dispatch(Set_SearchAutoCompleteLoading(false));
      snack.warning_i18("networkError")
    }
  };
}

export function SearchAutoCompleteActionWithCall(dispatch, data, cal) {
  return async (dispatch) => {
    if (/* navigator.onLine */ true) {
      await SearchAutoCompleteWithCal(dispatch, data, cal);
    } else {
      cal({ loading: false });
      snack.warning_i18("networkError")
    }
  };
}

export function SearchAddressAction(dispatch, data) {
  return async (dispatch) => {
    if (/* navigator.onLine */ true) {
      await SearchAddress(dispatch, data);
    } else {
      dispatch(Set_SearchAutoCompleteLoading(false));
      snack.warning_i18("networkError")
    }
  };
}

export function PlaceIdDetailsAction(dispatch, data, call) {
  return async (dispatch) => {
    if (/* navigator.onLine */ true) {
      await PlaceIdDetails(dispatch, data, call);
    } else {
      snack.warning_i18("networkError")
    }
  };
}

export function ServicesVehicletypeAction(dispatch, center, customerId) {
  return async (dispatch) => {
    if (/* navigator.onLine */ true) {
      await ServicesVehicletype(dispatch, center, customerId);
    } else {
      snack.warning_i18("networkError")
    }
  };
}

export function GetVehicletypeByServiceAction(dispatch, data , func) {
  return async (dispatch) => {
    if (/* navigator.onLine */ true) {
      await VehicletypeByService(dispatch, data, func);
    } else {
      snack.warning_i18("networkError")
    }
  };
}


export function SearchCustomerAction(dispatch, data) {
  return async (dispatch) => {
    if (/* navigator.onLine */ true) {
      await SearchCustomer(dispatch, data);
    } else {
      snack.warning_i18("networkError")
    }
  };
}

export function SearchDriverAction(dispatch, data ,flag) {
  return async (dispatch) => {
    if (/* navigator.onLine */ true) {
      await SearchDriver(dispatch, data , flag);
    } else {
      snack.warning_i18("networkError")
    }
  };
}

export function SearchDriver_AdvancedAssaigning_Action(dispatch, data ,flag) {
  return async (dispatch) => {
    if (/* navigator.onLine */ true) {
      await SearchDriver_AdvancedAssaigning(dispatch, data , flag);
    } else {
      snack.warning_i18("networkError")
    }
  };
}

export function SearchDriverAction_Pickup(dispatch, data ,flag) {
  return async (dispatch) => {
    if (/* navigator.onLine */ true) {
      await SearchDriver_Pickup(dispatch, data , flag);
    } else {
      snack.warning_i18("networkError")
    }
  };
}

export function SearchDriverAction_Dropoff(dispatch, data ,flag) {
  return async (dispatch) => {
    if (/* navigator.onLine */ true) {
      await SearchDriver_Dropoff(dispatch, data , flag);
    } else {
      snack.warning_i18("networkError")
    }
  };
}



export function CalculatePriceAction(dispatch, data) {
  return async (dispatch) => {
    if (/* navigator.onLine */ true) {
      await CalculatePrice(dispatch, data);
    } else {
      snack.warning_i18("networkError")
    }
  };
}

export function CalculatePriceReserveAction(dispatch, data) {
  return async (dispatch) => {
    if (/* navigator.onLine */ true) {
      await CalculatePriceReserve(dispatch, data);
    } else {
      snack.warning_i18("networkError")
    }
  };
}

export function CalculatePrice_Pickup_Action(dispatch, data) {
  return async (dispatch) => {
    if (/* navigator.onLine */ true) {
      await CalculatePrice_Pickup(dispatch, data);
    } else {
      snack.warning_i18("networkError")
    }
  };
}


export function CalculatePrice_PickupDelivery_Action(dispatch, data) {
  return async (dispatch) => {
    if (/* navigator.onLine */ true) {
      await CalculatePrice_PickupDelivery(dispatch, data);
    } else {
      snack.warning_i18("networkError")
    }
  };
}




export function CalculatePrice_Delivery_Action(dispatch, data) {
  return async (dispatch) => {
    if (/* navigator.onLine */ true) {
      await CalculatePrice_Delivery(dispatch, data);
    } else {
      snack.warning_i18("networkError")
    }
  };
}


export function OrderondemandAction(dispatch, data, customerphone, driverId ,ListOrderType) {
  return async (dispatch) => {
    if (/* navigator.onLine */ true) {
      await Orderondemand(dispatch, data, customerphone, driverId ,ListOrderType);
    } else {
      snack.warning_i18("networkError")
    }
  };
}

export function OrderPickupAction(dispatch, data, customerphone, driverId,ListOrderType) {
  return async (dispatch) => {
    if (/* navigator.onLine */ true) {
      await OrderPickup(dispatch, data, customerphone, driverId,ListOrderType);
    } else {
      snack.warning_i18("networkError")
    }
  };
}


export function OrderDeliveryAction(dispatch, data, customerphone, driverId,ListOrderType) {
  return async (dispatch) => {
    if (/* navigator.onLine */ true) {
      await OrderDelivery(dispatch, data, customerphone, driverId,ListOrderType);
    } else {
      snack.warning_i18("networkError")
    }
  };
}

export function OrderPickupDeliveryAction(dispatch, data, customerphone, driverId,ListOrderType) {
  return async (dispatch) => {
    if (/* navigator.onLine */ true) {
      await OrderPickupDelivery(dispatch, data, customerphone, driverId,ListOrderType);
    } else {
      snack.warning_i18("networkError")
    }
  };
}



export function OrderscheduleAction(dispatch, data, customerphone, driverId,ListOrderType) {
  return async (dispatch) => {
    if (/* navigator.onLine */ true) {
      await Orderschedule(dispatch, data, customerphone, driverId,ListOrderType);
    } else {
      snack.warning_i18("networkError")
    }
  };
}

export function OrderInfoAction(dispatch, data) {
  return async (dispatch) => {
    if (/* navigator.onLine */ true) {
      await OrderInfo(dispatch, data);
    } else {
      snack.warning_i18("networkError")
    }
  };
}

export function OrderEditondemandAction(
  dispatch,
  data,
  customerphone,
  driverId,
  id
) {
  return async (dispatch) => {
    if (/* navigator.onLine */ true) {
      await OrderEditondemand(dispatch, data, customerphone, driverId , id);
    } else {
      snack.warning_i18("networkError")
    }
  };
}



export function OrderEditPriceondemandAction(
  dispatch,
  data,
  customerphone,
  driverId,
  id
) {
  return async (dispatch) => {
    if (/* navigator.onLine */ true) {
      await OrderEditPriceondemand(dispatch, data, customerphone, driverId , id);
    } else {
      snack.warning_i18("networkError")
    }
  };
}


export function OrderEditPricePickupAction(
  dispatch,
  data,
  customerphone,
  driverId,
  id
) {
  return async (dispatch) => {
    if (/* navigator.onLine */ true) {
      await OrderEditPricePickup(dispatch, data, customerphone, driverId , id);
    } else {
      snack.warning_i18("networkError")
    }
  };
}


export function OrderEditPriceDeliveryAction(
  dispatch,
  data,
  customerphone,
  driverId,
  id
) {
  return async (dispatch) => {
    if (/* navigator.onLine */ true) {
      await OrderEditPriceDelivery(dispatch, data, customerphone, driverId , id);
    } else {
      snack.warning_i18("networkError")
    }
  };
}


export function OrderEditPricePickupDeliveryAction(
  dispatch,
  data,
  customerphone,
  driverId,
  id
) {
  return async (dispatch) => {
    if (/* navigator.onLine */ true) {
      await OrderEditPricePickupDelivery(dispatch, data, customerphone, driverId , id);
    } else {
      snack.warning_i18("networkError")
    }
  };
}



export function OrderEditPickupDropOffondemandAction(
  dispatch,
  data,
  id, call
) {
  return async (dispatch) => {
    if (/* navigator.onLine */ true) {
      await OrderEditPickupDropoffondemand(dispatch, data , id , call);
    } else {
      snack.warning_i18("networkError")
    }
  };
}


export function OrderEditPickupDropOffPickupAction(
  dispatch,
  data,
  id, call
) {
  return async (dispatch) => {
    if (/* navigator.onLine */ true) {
      await OrderEditPickupDropoffPickup(dispatch, data , id, call);
    } else {
      snack.warning_i18("networkError")
    }
  };
}


export function OrderEditPickupDropOffDeliveryAction(
  dispatch,
  data,
  id, call
) {
  return async (dispatch) => {
    if (/* navigator.onLine */ true) {
      await OrderEditPickupDropoffDelivey(dispatch, data , id, call);
    } else {
      snack.warning_i18("networkError")
    }
  };
}


export function OrderEditPickupDropOffPickupDeliveryAction(
  dispatch,
  data,
  id, call
) {
  return async (dispatch) => {
    if (/* navigator.onLine */ true) {
      await OrderEditPickupDropoffPickupDelivey(dispatch, data , id, call);
    } else {
      snack.warning_i18("networkError")
    }
  };
}


export function VehicletypesMonitoringDriversAction(dispatch, data) {
  return async (dispatch) => {
    if (/* navigator.onLine */ true) {
      await VehicletypesMonitoringDriversHttp(dispatch, data);
    } else {
      snack.warning_i18("networkError")
    }
  };
}

export function EstimatedRouteAction(dispatch, data) {
  return async (dispatch) => {
    if (/* navigator.onLine */ true) {
      await EstimatedRoute(dispatch, data);
    } else {
      snack.warning_i18("networkError")
    }
  };
}

export function getFavAddressListAction(dispatch, data) {
  return async (dispatch) => {
    if (/* navigator.onLine */ true) {
      await getFavAddressList(dispatch, data);
    } else {
      snack.warning_i18("networkError")
    }
  };
}

export function getFavAddressInfoAction(dispatch, data, cal) {
  return async (dispatch) => {
    if (/* navigator.onLine */ true) {
      await getFavAddressInfo(dispatch, data, cal);
    } else {
      snack.warning_i18("networkError")
    }
  };
}

export function deleteFavAddressAction(dispatch, data, cal) {
  return async (dispatch) => {
    if (/* navigator.onLine */ true) {
      await DeleteFavAddressHttp(dispatch, data, cal);
    } else {
      snack.warning_i18("networkError")
    }
  };
}

export function saveFavAddressAction(dispatch, data, cal) {
  return async (dispatch) => {
    if (/* navigator.onLine */ true) {
      await SaveFavAddressHttp(dispatch, data, cal);
    } else {
      snack.warning_i18("networkError")
    }
  };
}

export function editFavAddressAction(dispatch, data, cal) {
  return async (dispatch) => {
    if (/* navigator.onLine */ true) {
      await EditFavAddressHttp(dispatch, data, cal);
    } else {
      snack.warning_i18("networkError")
    }
  };
}

export function BusinessSearchAction(dispatch, data) {
  return async (dispatch) => {
    if (/* navigator.onLine */ true) {
      await BusinessSearch(dispatch, data);
    } else {
      snack.warning_i18("networkError")
    }
  };
}

export function OrderHistoryAction(dispatch, id) {
  return async (dispatch) => {
    if (/* navigator.onLine */ true) {
      await OrderHistory(dispatch, id);
    } else {
      snack.warning_i18("networkError")
    }
  };
}



export function CancelOrderAction(dispatch, id , call) {
  return async (dispatch) => {
    if (/* navigator.onLine */ true) {
      await CancelOrder(dispatch, id , call);
    } else {
      snack.warning_i18("networkError")
    }
  };
}


export function DoneOrderAction(dispatch, id , call) {
  return async (dispatch) => {
    if (/* navigator.onLine */ true) {
      await DoneOrder(dispatch, id , call);
    } else {
      snack.warning_i18("networkError")
    }
  };
}



export function TrackOrderDriverAction(dispatch, id , call) {
  return async (dispatch) => {
    if (/* navigator.onLine */ true) {
      await TrackOrderDriver(dispatch, id , call);
    } else {
      snack.warning_i18("networkError")
    }
  };
}



export function GetDriverListAction(dispatch, data ) {
  return async (dispatch) => {
    if (/* navigator.onLine */ true) {
      await GetDriverListHttp(dispatch, data);
    } else {
      snack.warning_i18("networkError")
    }
  };
}



export function GetDriverOrderListAction(dispatch , data , noLoading) {
  return async (dispatch) => {
    if (/* navigator.onLine */ true) {
      await GetDriverOrderListHttp(dispatch, data, noLoading);
    } else {
      snack.warning_i18("networkError")
    }
  };
}



export function GetDriverInfoAction(dispatch , data ) {
  return async (dispatch) => {
    if (/* navigator.onLine */ true) {
      await GetDriverInfoHttp(dispatch, data);
    } else {
      snack.warning_i18("networkError")
    }
  };
}



export function Get_Addressbook_list_Action(dispatch, data , call) {

  return async (dispatch) => {
    if (/* navigator.onLine */ true) {

      await Get_Addressbook_list_Api(dispatch, data , call);
    } else {

      snack.warning_i18("networkError")
    }
  };
}




export function Update_Order_Status_Action(dispatch, data , call) {

  return async (dispatch) => {
    if (/* navigator.onLine */ true) {

      await Update_Order_Status_Api(dispatch, data , call);
    } else {

      snack.warning_i18("networkError")
    }
  };
}



export function OrderEditEarningAction(
  dispatch,
  data,
  id
) {
  return async (dispatch) => {
    if (/* navigator.onLine */ true) {
      await OrderEditEarning(dispatch, data , id);
    } else {
      snack.warning_i18("networkError")
    }
  };
}


export function OrderGetEventsAction(
  dispatch,
  id
) {
  return async (dispatch) => {
    if (/* navigator.onLine */ true) {
      await Get_Order_Events_Api(dispatch , id);
    } else {
      snack.warning_i18("networkError")
    }
  };
}



export function OrderNewEvent_create_Action(
  dispatch,
  data,
  id
) {
  return async (dispatch) => {
    if (/* navigator.onLine */ true) {
      await CreateNewEvent_Http(dispatch ,data, id);
    } else {
      snack.warning_i18("networkError")
    }
  };
}



export function GetVehicletypeByService_AdvancedAssaigning_Action(dispatch, data , func) {
  return async (dispatch) => {
    if (/* navigator.onLine */ true) {
      await VehicletypeByService_AdvancedAssaigning(dispatch, data, func);
    } else {
      snack.warning_i18("networkError")
    }
  };
}




export function AdvancedAssaigning_OrderPickupAction(dispatch, data) {
  return async (dispatch) => {
    if (/* navigator.onLine */ true) {
      await AdvancedAssaigning_OrderPickupInfo(dispatch, data);
    } else {
      snack.warning_i18("networkError")
    }
  };
}


export function AdvancedAssaigning_OrderDeliveryAction(dispatch, data) {
  return async (dispatch) => {
    if (/* navigator.onLine */ true) {
      await AdvancedAssaigning_OrderDeliveryInfo(dispatch, data);
    } else {
      snack.warning_i18("networkError")
    }
  };
}


export function GetMonitoringVehicleTypeAction(dispatch, data, func) {
  return async(dispatch) => {
      if (/* navigator.onLine */ true) {
          await VehicletypeForMonitoring(dispatch, data, func);
      } else {
        snack.warning_i18("networkError")
      }
  };
}




export function Update_Order_PickupDelivery_Status_Action(dispatch, data , call) {

  return async (dispatch) => {
    if (/* navigator.onLine */ true) {

      await Update_Order_PickupDelivery_Status_Api(dispatch, data , call);
    } else {

      snack.warning_i18("networkError")
    }
  };
}




export function Update_Order_Pickup_Status_Action(dispatch, data , call) {

  return async (dispatch) => {
    if (/* navigator.onLine */ true) {

      await Update_Order_Pickup_Status_Api(dispatch, data , call);
    } else {

      snack.warning_i18("networkError")
    }
  };
}





export function Update_Order_Delivery_Status_Action(dispatch, data , call) {

  return async (dispatch) => {
    if (/* navigator.onLine */ true) {

      await Update_Order_Delivery_Status_Api(dispatch, data , call);
    } else {

      snack.warning_i18("networkError")
    }
  };
}



export function Get_User_Recent_Address(dispatch, data , call) {

  return async (dispatch) => {
    if (/* navigator.onLine */ true) {

      await Get_User_Recent_Address_Api_Http(dispatch, data , call);
    } else {

      snack.warning_i18("networkError")
    }
  };
}


export function Register_New_Customer(dispatch, data , call) {

  return async (dispatch) => {
    if (/* navigator.onLine */ true) {

      await Register_New_Customer_Api(dispatch, data , call);
    } else {

      snack.warning_i18("networkError")
    }
  };
}


export function CustomerGetInfo(dispatch, data, call) {
  return async (dispatch) => {
    if (/* navigator.onLine */ true) {
      await CustomerGetInfoHttp(dispatch, data, call);
    } else {
      snack.warning_i18("networkError")
    }
  };
}


export function SetEventForOrderFromList(
  dispatch,
  data
) {
  return async (dispatch) => {
    if (/* navigator.onLine */ true) {
      await SetEventForOrderFromList_Http(dispatch,
  data);
    } else {
      snack.warning_i18("networkError")
    }
  };
}


export function GetEventVoipRecorded_action(
  dispatch,
  data ,call
) {
  return async (dispatch) => {
    if (/* navigator.onLine */ true) {
      await GetEventVoipRecorded_http(dispatch,
        data ,call);
    } else {
      snack.warning_i18("networkError")
    }
  };
}


export function Voip_OutgoingCall(
  dispatch,
  data 
) {
  return async (dispatch) => {
    if (/* navigator.onLine */ true) {
      await Voip_OutgoingCall_Http(dispatch,
        data );
    } else {
      snack.warning_i18("networkError")
    }
  };
}


export function Edit_individual_info(
  dispatch,
  data ,call
) {
  return async (dispatch) => {
    if (/* navigator.onLine */ true) {
      await Edit_individual_info_http(dispatch,
        data ,call );
    } else {
      snack.warning_i18("networkError")
    }
  };
}
