import React from "react";
import { connect } from "react-redux";
import event from "../../assets/pin_event.svg";
import { withTranslation, Trans } from "react-i18next";
import { Set_New_Event } from "../../Redux/actions/actionNames";
import { getCenterMap } from "../../helper/module";

class App extends React.Component {
  markerOnClick = () => {
    let center = getCenterMap(this.props.mapRef , this.props.center)

    this.props.dispatch(Set_New_Event({mapLocation :  [
      center.lat,center.lng,
    ]}))

  };


  render() {


    return (
      <div>
        {this.props.showEventTimeLine && !this.props.loc  && (
          <img
            onClick={this.markerOnClick}
            src={event}
            alt=""
            className={`eventSelect  ${global.config.dispatcherSettings.applicationMap.type == "google" && "eventSelectGoogle"} ${this.props.hideNavbar && 'hideEventSelectGoogle'}`}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  loc: state.FavAddress.newFav.loc,
 mapRef: state.Map.mapRef,
  center: state.Map.center,
  showEventTimeLine: state.ViewTravel.eventTimeLine.newEvent.canSelectLocation,
  loc: state.ViewTravel.eventTimeLine.newEvent.mapLocation,

});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
