import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Pickup from "./pickup";
import Dropoff from "./dropoff";

import { withTranslation, Trans } from "react-i18next";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeCollapse: null,
    };
  }
  handleCollapse = (id) => {
    this.setState({
      activeCollapse: this.state.activeCollapse === id ? null : id,
    });
  };

  render() {
    return (
      <div className="noboxshadow">
        {["Pickup", "Ondemand"].indexOf(
          this.props.orderType
        ) > -1 && (
          <Pickup
            handleCollapse={this.handleCollapse}
            activeCollapse={this.state.activeCollapse}
            id={"pick"}
          />
        )}
        {[ "Delivery"].indexOf(this.props.orderType) > -1 &&
          this.props.dropoff && (
            <Dropoff
              handleCollapse={this.handleCollapse}
              activeCollapse={this.state.activeCollapse}
              id={0}
              drop={this.props.dropoff}
              last={true}
            />
          )}

        {["Ondemand"].indexOf(this.props.orderType) > -1 &&
          this.props.dropoffs.map((drop, i) => {
            return (
              <Dropoff
                handleCollapse={this.handleCollapse}
                activeCollapse={this.state.activeCollapse}
                id={i}
                drop={drop}
                last={i == this.props.dropoffs.length - 1}
              />
            );
          })}



      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  dropoffs: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.dropoffs
      ? state.ViewTravel.pureData.dropoffs
      : []
    : [],
  dropoff: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.dropoff
      ? state.ViewTravel.pureData.dropoff
      : null
    : null,
  orderType: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.type
      ? state.ViewTravel.pureData.type
      : null
    : null,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
