import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import styles from './style.module.css';
import clsx from "clsx";
import close from "../../../assets/close.svg";
import { PriceDetailsOpen } from "../../../Redux/actions/actionNames";
import Receipt from "../../../assets/receipt.svg";
import notes from "../../../assets/notes23.svg";
import { isOrderInDone } from "../../../helper/module";
import { isEmpty } from "lodash";
import { get_lang } from "../../../helper/UserData/userdate";


class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }


  closeDialoge = () => {
    this.props.dispatch(PriceDetailsOpen(false))
  }


  render() {

    return (

      <div className={clsx(styles.EventTimeLineContainer, this.props.open && styles.EventTimeLineContainerOpen)}>
        <div className={styles.EventTimeLine_Header}>
          <img onClick={this.closeDialoge} className={styles.close} src={close} />
          <span className={styles.eventsTitle}> <Trans i18nKey={"Pricedetails"}> </Trans> </span>
          {isOrderInDone(this.props.Data.status) && !isEmpty(this.props.Data.customerReceiptUrl) && <span className={styles.Receipt} onClick={() => {
            try {
              var url = new URL(this.props.Data.customerReceiptUrl);
              url.searchParams.append("lng", get_lang());

              window.open(url, "_blank");
            } catch (error) { }
          }}><img src={Receipt} /> <Trans i18nKey={"Receipt"}> </Trans></span>
          }        </div>

        <div className={styles.card}>

          <div className={styles.innerCardRowI}>
            {
              (this.props.Data && this.props.Data.dispatcherReceipt) ?
                this.props.Data.dispatcherReceipt.map((item, i) => {
                  return <div style={{ borderBottom: (i != this.props.Data.dispatcherReceipt.length - 1) ? "1px solid rgba(128, 128, 128, 0.2)" : "" }} className={styles.innerCardRowItem}>
                    <span style={{ color: item.style == "regular" ? "#808080" : "#484848" }}>
                      {(["keyValue", "total"].indexOf(item.type) > -1) ? item.title : String(this.props.t("Surchargexxx")).replace("xxx", item.title)}
                      {/* {
                     item.type == "surcharge" &&  <img src={infoo} className="coifhefdwdwc" onClick={()=>{this.setState({surchargeActive : item.data})}} />
                   } */}

                    </span>
                    <span style={{ fontWeight: item.style == "regular" ? "normal" : "bold" }}>
                      {item.value}
                    </span>
                  </div>
                })

                : null
            }
          </div>


          {
            (this.props.Data && this.props.Data.dispatcherReceipt) ?
              this.props.Data.dispatcherReceipt.map((item, i) => {

                return (item.type == "surcharge" && item.data && item.data.type == 
                "Driver" ) ? <div className={styles.innerCardRowI} style={{ marginTop: "6px", width: "100%" }}>
                  <div className={styles.SurchargeItem}>
                    <p className={styles.SurchargeTitle}>{item.title}</p>

                    {item.data.photo.length > 0 ? (
                      <img
                        style={{
                          objectFit: "contain",
                        }}
                        src={item.data.photo}
                        className={styles.surchargeImage}
                      />
                    ) : (
                      <span className={styles.nophotosign}>
                        {" "}
                        {this.props.t("Nophoto")}{" "}
                      </span>
                    )}

                    <p className={styles.Photo}><Trans i18nKey={"Photo"}> </Trans></p>

                    <span className={styles.noteContainer}>
                      <img
                        src={notes}
                      />
                      <div>
                        <p><Trans i18nKey={"Notes"}> </Trans></p>
                        <span>{String(item.data.description).length == 0 ? this.props.t("Nonotes") : item.data.description} </span>
                      </div>


                    </span>

                  </div>
                </div> : null
              })

              : null
          }


          {/* <div className={styles.innerCardRowI} style={{marginTop:"6px",width:"100%"}}>
            {
               (this.props.Data && this.props.Data.dispatcherReceipt) ? 
               this.props.Data.dispatcherReceipt.map((item , i)=>{

                 return item.type == "surcharge" ? <div className={styles.SurchargeItem}>
                  <p className={styles.SurchargeTitle}>{item.title}</p>

                  {item.data.photo.length > 0 ? (
                        <img
                          style={{
                            objectFit: "contain",
                          }}
                          src={ item.data.photo}
                          className={styles.surchargeImage}
                        />
                      ) : (
                        <span  className={styles.nophotosign}>
                          {" "}
                          {this.props.t("Nophoto")}{" "}
                        </span>
                      )}

                      <p className={styles.Photo}><Trans i18nKey={"Photo"}> </Trans></p>

                    <span className={styles.noteContainer}>
                          <img
                          src={notes}
                        />
                        <div>
                          <p><Trans i18nKey={"Notes"}> </Trans></p>
                          <span>{String(item.data.description).length == 0 ? this.props.t("Nonotes") :item.data.description } </span>
                        </div>

                      
                    </span>
                    
                 </div> : null
               })

               : null
             }
            </div> */}

        </div>


      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  open: state.ViewTravel.pricedetailsOpen,
  showEventTimeLine: state.ViewTravel.eventTimeLine.show,

  Data: state.ViewTravel.pureData ? state.ViewTravel.pureData : false,
  baseFarePrice: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.baseFarePrice
    : 0,
  surcharges: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.surcharges
    : [],

});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
