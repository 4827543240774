import Dropp from "../../../assets/ASKERI_logotype.png";
import FavIcon from "../../../assets/PROJECTS/ASKERI.png";

export default function ASKERI() {
    
    return  global.config = {
    "ProjectName": "ASKERI",
    "LogoType": Dropp,
    "BaseApi": "https://rest.askeriglobal.com",
    "BaseSocket": "https://socket.askeriglobal.com",
    "BaseTrack": "https://admin.askeriglobal.com",
    "MapCenter": {
        "lat": "51.507357639804184",
        "lng": "-0.12757305191165574"
    },
    "Lang": "en",
    "Direction": "ltr",
    "AllLanguages": [{
        "lang": "en",
        "direction": "ltr",
        "i18Lang": "English"
    }],
    "FavIcon": FavIcon,
    "Country": "GB",
    "thirdPartyLoginSupport": true  ,
    mapType :"google", /* osm | google */ 
    googleApiKey : ""
}}