import logoType from "../../../assets/Rappid.png";
import FavIcon from "../../../assets/PROJECTS/Rappid_fav_icon.png";

export default function Rappid() {
    
    return global.config = {
    "ProjectName": "Rappid",
    "LogoType": logoType,
    "BaseApi": "https://rest.tryrappid.com",
    "BaseSocket": "https://socket.tryrappid.com",
    "BaseTrack": "https://admin.tryrappid.com",
    "MapCenter": {
        "lat": "40.678709675204104",
        "lng": "-73.94568419551865"
    },
    "Lang": "en",
    "Direction": "ltr",
    "AllLanguages": [{
        "lang": "en",
        "direction": "ltr",
        "i18Lang": "English"
    },
    {
        "lang": "es",
        "direction": "ltr",
        "i18Lang": "spanish",
    }],
    "FavIcon": FavIcon,
    "Country": "US",
    "thirdPartyLoginSupport": true  ,
    mapType :"osm", /* osm | google */ 
    googleApiKey : ""
} }