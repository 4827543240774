import * as actionTypes from "../actions/actionTypes";
var _ = require("lodash");

const init = {
  stats:{
      isOnline: 0,
      isOffline: 0,
  },
  openDialoge: false,
  regex: "",
  isOnline: true,
  Driverlist: {
    "drivers": {},
    "startCursor": null,
    "endCursor": null,
    "nextCursor": null
  },
  DriverlistPage: 0,
  canLoadmore: false,
  DriverlistLoading: false,
  DriverlistOrdered: false,
  OrderList: {},
  OrderListLoading: false,
  OrderListOrdered: false,
  OrderListOrderedPage: 0,
  activeDriver: null,
  driverInfo: null
};

export const DriversList = (state = init, action) => {
  switch (action.type) {

    case actionTypes.setDriverListLoading:
      return {
        ...state,
        DriverlistLoading : action.state
      };


    case actionTypes.setDriverStatistics:
      return {
        ...state,
        stats:{
             isOnline: action.state.isOnline ,
             isOffline: action.state.isOffline ,
        }
      };
    case actionTypes.setDriverListRegex:
      return {
        ...state,
        regex: action.state,
      };
    case actionTypes.setDriverListOpenDialoge:
      if (!action.state) {
        return {
          ...init
        }
      }
      return {
        ...state,
        openDialoge: action.state,
      };
    case actionTypes.setActiveDriverList:
      return {
        ...state,
        activeDriver: action.state,
        driverInfo: null,
        OrderList: {},
        OrderListOrderedPage: 0,
        DriverlistOrdered: false,
        OrderListOrdered: false,
      };
    case actionTypes.setDriverListloadingOrderedPage:
      return {
        ...state,
        ...action.state,
        activeDriver: _.isEmpty(action.state.activeDriver) ? state.activeDriver : { ...state.activeDriver, ...action.state.activeDriver },
        driverInfo: _.isEmpty(action.state.driverInfo) ? state.driverInfo : { ...state.driverInfo, ...action.state.driverInfo },
      };
    case actionTypes.setDriverListData:
      return {
        ...state,
        Driverlist: {
          ...state.Driverlist ,
          "drivers": action.loadMore ? {..._.keyBy(state.Driverlist.drivers, "id"), ..._.keyBy(action.state.drivers, "id")} : {..._.keyBy(action.state.drivers, "id")} ,
          "startCursor":action.loadMore ? state.Driverlist.startCursor : action.state.startCursor,
          "endCursor": action.state.endCursor,
          "nextCursor": action.state.nextCursor
        }
      };

    case actionTypes.setDriverOrdersListData:
      return {
        ...state,
        // OrderList: { ...state.OrderList, ..._.keyBy(action.state, "id") },
        OrderList: { ..._.keyBy(action.state, "id") },

      };


      case actionTypes.updateOrdersCountInDriversList:
        if(state.Driverlist.drivers[action.state.id]){
           return {
            ...state,
            Driverlist: {
              ...state.Driverlist ,
              "drivers":{
                ...state.Driverlist.drivers,
                [action.state.id]: {
                  ...state.Driverlist.drivers[action.state.id] ,
                  activeOrdersCount:action.state.activeOrdersCount
                }
              }
            }
          };
        }else{
          return {
            ...state
          }
        }
       

    case actionTypes.UPDATETRAVELINLIST:
      // if (state.OrderList[action.state.id]) {
      //   delete state.OrderList[action.state.id];
      // }
      if (state.OrderList[action.state.id]) {
        return {
          ...state,
          OrderList: {
            ...state.OrderList,
            [action.state.id]: {
              ...state.OrderList[action.state.id],
              ...action.state.data,
            },
          },
        };
      } else if (action.state.full) {
        return {
          ...state,

          OrderList: {
            [action.state.id]: {
              ...state.OrderList[action.state.id],
              ...action.state.data,
            },
            ...state.OrderList,
          },
        };
      } else {
        return {
          ...state,
        };
      }

    default:
      return state;
  }
};
