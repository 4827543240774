import * as React from 'react'
import styles from './style.module.css';
import { BUSINESS, DRIVER_COLOR, PROFILE} from '../../../../../../../assets';
import IconProvider from '../../../../../../../design-system/lib/IconProvider';
import Typography from '../../../../../../../design-system/lib/Typography';




function DriverListItem({data , onclick}) {

  return (
    <div className={styles.CustomerListItemContainer} onClick={onclick && onclick}>
        <IconProvider Icon={DRIVER_COLOR} size='16' />

        <div className={styles.namePhoneContainer}>
            {data.name && <Typography weight='Body_Tiny_Medium' style={{width:"100%"}} text={<>{data.name} <Typography weight='Body_Tiny_Medium' text={`@${data.username}`} style={{color:"#808080",marginInlineStart: "auto",marginInlineEnd: "12px", float: "inline-end"}}/></>} />}
            {data.phone && <Typography weight='Body_Tiny_Regular' text={data.phone} />}

        </div>
    </div>
  )
}

export default DriverListItem