import React from 'react';
import { ViewOrderLayout } from '../layout/view-order/ViewOrder'
import Hotkeys from 'react-hot-keys';
import { DownloadReactState } from '../utils/downloadReactState';
import { NewOrderLayout } from '../layout/new-order/NewOrder';


function NewOrderPage({mapWidth , sidebarIsOpen}) {


    return <NewOrderLayout mapWidth={mapWidth} sidebarIsOpen={sidebarIsOpen} />
}

export default NewOrderPage;

