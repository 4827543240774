import { Component } from "react";
import { connect } from "react-redux";
import Ondemand from "./ondemand";

class App extends Component {

  render() {
    let orderType = this.props.orderType

    return <Ondemand />

  }
}

const mapStateToProps = (state) => ({
  orderType: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.type
      ? state.ViewTravel.pureData.type
      : null
    : null,

});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
