
export class LocalData {
  get(key) {
    return localStorage.getItem(String(key));
  }

  set(key, value) {
    localStorage.setItem(String(key), value);
  }

  remove(key) {
    localStorage.removeItem(String(key));
  }

  clear(){
    localStorage.clear()
  }
}
