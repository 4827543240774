import ParcelBeeps from "../../../assets/ParcelBeep.png";
import FavIcon from "../../../assets/PROJECTS/ParcelBeepFavIcon.png";

export default function ParcelBeep() {
    
    return  global.config = {
    "ProjectName": "Parcel Beep",
    "LogoType": ParcelBeeps,
    "BaseApi": "https://rest.parcelbeep.com",
    "BaseSocket": "https://socket.parcelbeep.com",
    "BaseTrack": "https://admin.parcelbeep.com",
    "MapCenter": {
        "lat": "5.605217795374032",
        "lng": "-0.18625258232021014"
    },
    "Lang": "en",
    "Direction": "ltr",
    "AllLanguages": [{
        "lang": "en",
        "direction": "ltr",
        "i18Lang": "English"
    }],
    "FavIcon": FavIcon,
    "Country": "GH",
    "thirdPartyLoginSupport": true  ,
    mapType :"osm", /* osm | google */ 
    googleApiKey : ""
} }