import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
const _ = require("lodash");


export function useOrderListCanShowMore({categoryName , data}) {
  const [canShowMore, setCanShowMore] = useState(false);
  const hasNextPage = useSelector((state) => state.OrderList.list[categoryName].hasNextPage)


  useEffect(()=>{
    if(data.length > 0 && hasNextPage){
      setCanShowMore(true)
    }else{
      setCanShowMore(false)
    }
  },[data])
  return {canShowMore};
};




