import React, { useLayoutEffect, useRef, useState } from "react";
import { Map, Marker, TileLayer, Popup, Polyline } from "react-leaflet";
import L from "leaflet";
import Delivery from "../delivery";
import Pin from "../pickup";
import ReactDOMServer from "react-dom/server";
import styles from './style.module.css';
import { GetColorByStatus } from "../../../../utils/get-colors-by-status";
import { StopGetColorByStatus } from "../../../../utils/stop-get-colors-by-status";

const createtIcon = (status ,number , numberStyle , isPickup , bigSize , color) => {
  return new L.divIcon({
    className: "",
    html: ReactDOMServer.renderToString(CreateIconSelectedOsm(status,number , numberStyle , color , isPickup , bigSize )),
    iconSize: [40, 41],
    iconAnchor: [0, 0],
  });
};



const CreateIconSelectedOsm = (status,number , numberStyle , color , isPickup , bigSize) => {
  return isPickup ?  <Pin color={color ? color :StopGetColorByStatus(status)}  bigSize={bigSize}
  style={{ marginLeft: "-15px", marginTop: "-51px" ,marginBottom:"10px"}} number={number} numberStyle={numberStyle} /> :
  <Delivery color={color ? color : StopGetColorByStatus(status)}  bigSize={bigSize}
  style={{ marginLeft: "-15px", marginTop: "-51px" ,marginBottom:"10px"}} number={number} numberStyle={numberStyle} />

};


const App = ({ position, status, number, numberStyle, bigSize, color, isPickup}) => {
  
  return <>
    <Marker
      animate={true}
      position={position}
      icon={createtIcon(status ,number , numberStyle , isPickup , bigSize , color)}
    >

    </Marker>
  </>
};

export default App


