import logoType from "../../../assets/J-RUN.png";
import FavIcon from "../../../assets/PROJECTS/J-RUN_fav_icon.png";

export default function JRUN() {
    
    return global.config = {
    "ProjectName": "J-RUN",
    "LogoType": logoType,
    "BaseApi": "https://rest.j-run.az",
    "BaseSocket": "https://socket.j-run.az",
    "BaseTrack": "https://panel.j-run.az",
    "MapCenter": {
        "lat": "40.41731581402783",
        "lng": "49.86628736823992"
    },
    "Lang": "en",
    "Direction": "ltr",
    "AllLanguages": [{
        "lang": "en",
        "direction": "ltr",
        "i18Lang": "English"
    }],
    "FavIcon": FavIcon,
    "Country": "AZ",
    "thirdPartyLoginSupport": true  ,
    mapType :"osm", /* osm | google */ 
    googleApiKey : ""
} }
