import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import close from "../../../../../assets/close.svg";
import { ChangeNewTravelDialoge, ResetNewOrder, Set_NewOrder_OrderType, NewTravelResetReserve, New_Order_Reset_DropOffs, New_Order_Reset_DropOff, New_Order_Reset_Pickup, SetVoipIncommingCallAction, Set_Auto_Driver } from "../../../../../Redux/actions/actionNames";
import { withTranslation, Trans } from "react-i18next";
import { ServicesVehicletypeAction } from "../../../../../Redux/actions/actions";
import arrow from "../../../../../assets/arrow-triangle-down.svg";
import { GetVehicletypeByServiceAction } from "../../../../../Redux/actions/actions";
import { Set_Service_ActiveVehicleType, Set_Auto_ActiveVehicleType, New_Travel_PaymentMethod } from "../../../../../Redux/actions/actionNames";
import { withStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { getCenterMap } from "../../../../../helper/module";
import { snack } from "../../../../../utils/SnackbarUtilsConfigurator";
import history from "../../../../../service/history";


const BackdropCustom = withStyles({
  backdrop: {
    zIndex: 5000,
    backgroundColor: "rgba(0, 0, 0, 0)",
    color: "#00000",
  },
})((props) => (
  <Backdrop
    {...props}
    style={{
      zIndex: 5000,
      backgroundColor: "rgba(0, 0, 0, 0)",
      color: "#00000",
    }}
  />
));

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showpoper: false,
      showpoperOrderType: false,
    };
    this.anchorEl = React.createRef();
  }

  NewTravelHandler = () => {
    // this.props.dispatch(ChangeNewTravelDialoge());
    this.props.dispatch(ResetNewOrder())
    history.push("/neworder")    

    // this.props.dispatch(ServicesVehicletypeAction(this.props.dispatch, this.props.center))
    this.props.dispatch(SetVoipIncommingCallAction({
      type : null , 
      Data : null 
    }))
  };

  closeNewOrder = ()=> {

    this.props.dispatch(ResetNewOrder())

  }


  handleshowpoper = () => {
    if (this.props.serviceVehicleTypes.length > 0) {
      this.setState({
        showpoper: !this.state.showpoper,
      });
    } else {
      snack.warning_i18("poperLoading")
    }
  };

  handleshowpoperOrderType = () => {
    if (this.props.serviceOrderTypes.length > 1) {
      this.setState({
        showpoperOrderType: !this.state.showpoperOrderType,
      });
    }

  };



  handleServiceService = (service) => {
    this.props.dispatch(NewTravelResetReserve())
    this.props.dispatch(Set_Service_ActiveVehicleType(service))
    this.props.dispatch(Set_Auto_Driver(service.setting.driverAssignmentModel == "automatic" ? true : false ));

    if(this.props.orderTypeActive){
      if (service.orderTypes.indexOf(this.props.orderTypeActive) == -1) {
        this.handleOrderType(service.orderTypes[0])
      }
    }else{
      this.handleOrderType(service.orderTypes[0])
    }
  

    this.checkSelectedPaymnetMethod(service)
    let center = getCenterMap(this.props.mapRef , this.props.center)

    this.props.dispatch(GetVehicletypeByServiceAction(this.props.dispatch, {
      latitude:  center.lat,
      longitude: center.lng,
      
      serviceId: service.id
    }, (vt) => {

      if (vt) {
        this.props.dispatch(Set_Auto_ActiveVehicleType(vt[0]));
      }

    }))



  }
  componentWillReceiveProps(nextProps) {
    
    if (!nextProps.orderTypeActive) {
      if (nextProps.serviceOrderTypes.length > 0) {
        this.handleOrderType(nextProps.serviceOrderTypes[0])
      }
    }

  }


  checkSelectedPaymnetMethod(service) {
    if (this.props.paymentSide + this.props.paymentMethod == "SenderCash") {
      if (!service.setting.isCashBySenderActive) {
        this.props.dispatch(
          New_Travel_PaymentMethod({
            paymentSide: "Sender",
            paymentMethod: "Wallet",
          })
        );
      }
    } else if (this.props.paymentSide + this.props.paymentMethod == "ReceiverCash") {
      if (!service.setting.enableReceiverCash) {
        this.props.dispatch(
          New_Travel_PaymentMethod({
            paymentSide: "Sender",
            paymentMethod: "Wallet",
          })
        );
      }
    }

  }

  handleOrderType = (type) => {
    this.props.dispatch(Set_NewOrder_OrderType(type))
    if (type == "Pickup") {
      this.props.dispatch(New_Order_Reset_DropOffs())
      this.props.dispatch(New_Order_Reset_DropOff())

    } else if (type == "Delivery") {
      this.props.dispatch(New_Order_Reset_Pickup())
      this.props.dispatch(New_Order_Reset_DropOffs())

    } else if (type == "PickupDelivery") {
      this.props.dispatch(New_Order_Reset_DropOffs())
    }
  }


  componentDidMount(){
    

    if(this.props.serviceVehicleTypes.length > 0 && !this.props.activeVehicleType){
      this.handleServiceService(this.props.serviceVehicleTypes[0])
    }
  }

  render() {
    return (
      <nRHeader>
        <img onClick={this.closeNewOrder} className="close" src={close} />
        {/* <span className="title">
          <Trans i18nKey={"Neworder"}> </Trans>
        </span> */}
        <div className="newOrderHeaderServiceOrderTypeContainer">
          <serviceselector
            ref={this.anchorEl}
            onClick={this.handleshowpoper}
            style={{
              boxShadow: this.state.showpoper
                ? "0 2px 24px 0 rgba(128, 128, 128, 0.24)"
                : "",
              flex: "1",
            }}
            className="newOrderSelectService"
          >
            {this.props.activeVehicleType &&
              this.props.serviceVehicleTypes.length > 0 &&
              !this.state.showpoper ? (
                <React.Fragment>
                  <img
                    src={this.props.activeVehicleType.icon}
                    className="serviceimage"
                  />
                  <servicename>{this.props.activeVehicleType.title}</servicename>
                  {this.props.serviceOrderTypes.length > 1 && <img
                    src={arrow}
                    className={` servicearrow ${
                      this.state.showpoper && "rotate180"
                      }`}
                  />}
                </React.Fragment>
              ) : this.state.showpoper ? null : (
                <servicename style={{ marginLeft: "19px" }}>
                  {" "}
                  <Trans i18nKey={"poperLoading"}> </Trans>
                </servicename>
              )}

            {this.state.showpoper &&
              this.props.serviceVehicleTypes.length > 0 ? (
                <ClickAwayListener onClickAway={() => this.handleshowpoper()}>
                  <div>
                    {this.props.serviceVehicleTypes.map((item) => {
                      return (
                        <serviceselectorInList className="newOrderSelectServiceInList"
                          onClick={() => this.handleServiceService(item)}
                        >
                          <img src={item.icon} className="serviceimage" />
                          <servicename
                            style={{ fontWeight: "normal" }}
                            className={
                              this.props.activeVehicleType
                                ? this.props.activeVehicleType.title == item.title
                                  ? "xxx"
                                  : ""
                                : ""
                            }
                          >
                            {item.title}
                          </servicename>
                        </serviceselectorInList>
                      );
                    })}
                  </div>
                </ClickAwayListener>
              ) : null}
          </serviceselector>

          <serviceselector
            ref={this.anchorEl}
            onClick={this.handleshowpoperOrderType}
            style={{
              boxShadow: this.state.showpoperOrderType
                ? "0 2px 24px 0 rgba(128, 128, 128, 0.24)"
                : "",
            }}
            className="orderTypeSelector"
          >
            {!this.state.showpoperOrderType ? (
              <React.Fragment>
                <servicename>
                  <Trans i18nKey={this.props.orderTypeActive ? this.props.orderTypeActive : "PleaseSelect"}> </Trans>
                </servicename>
                {this.props.serviceOrderTypes.length > 1 && <img
                  src={arrow}
                  className={` servicearrow ${
                    this.state.showpoperOrderType && "rotate180"
                    }`}
                />}
              </React.Fragment>
            ) : this.state.showpoperOrderType ? null : (
              <servicename style={{ marginLeft: "19px" }}>
                {" "}
                <Trans i18nKey={"poperLoading"}> </Trans>
              </servicename>
            )}

            {this.state.showpoperOrderType ? (
              <ClickAwayListener
                onClickAway={() => this.handleshowpoperOrderType()}
              >
                <div>
                  {this.props.serviceOrderTypes.map((item) => {
                    return (
                      <serviceselectorInList
                        onClick={() => this.handleOrderType(item)}
                      >
                        <servicename
                          style={{ fontWeight: "normal" }}
                          className={
                            this.props.orderTypeActive
                              ? this.props.orderTypeActive == item
                                ? "xxx"
                                : ""
                              : ""
                          }
                        >
                          <Trans i18nKey={item}> </Trans>{" "}
                        </servicename>
                      </serviceselectorInList>
                    );
                  })}
                </div>
              </ClickAwayListener>
            ) : null}
          </serviceselector>
        </div>
      </nRHeader>
    );
  }
}

const mapStateToProps = (state) => ({
  OpenDialoge: state.NewOrder.OpenDialoge,
 mapRef: state.Map.mapRef,
  center: state.Map.center,
  activeVehicleType: state.NewOrder.newOrder.service.activeVehicleType,
  serviceOrderTypes: state.NewOrder.newOrder.service.activeVehicleType ? state.NewOrder.newOrder.service.activeVehicleType.orderTypes : [],
  options: state.NewOrder.newOrder.service.options,
  serviceVehicleTypes: state.SharedData.services,
  lang: state.LocalData.lang,
  center: state.Map.center,
  paymentMethod: state.NewOrder.newOrder.paymentMethod.paymentMethod,
  paymentSide: state.NewOrder.newOrder.paymentMethod.paymentSide,
  orderTypeActive: state.NewOrder.newOrder.orderTypeActive,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(App));
