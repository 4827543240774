
export const faviconUpdate = () => {    
    try {
        const favicon = document.getElementById("favicon");
        favicon.href = global.config.FavIcon;
      } catch (error) { }
};

 



