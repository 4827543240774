import React from "react";
import { Map, Marker, TileLayer, Popup, Polyline } from "react-leaflet";
import L from "leaflet";
import { connect } from "react-redux";
import { VehicletypesMonitoringDriversAction, TrackOrderDriverAction, GetDriverInfoAction, GetDriverOrderListAction } from "../../../../Redux/actions/actions";
// import { Marker as LeafletMarker } from "leaflet-rotatedmarker";
import { CanTrackDriver, getBaseTrackByUrl } from "../../../../helper/module";
import { SocketTrackDriver } from "../../../../service/socket/Emit_handelers";
import deliveryProfile from "../../../../assets/delivery-man.png";
import Pin from "../Pin";
import ReactDOMServer from "react-dom/server";
import { SetDriverListOpenDialoge, SetDriverLocation, SetDriverPopUp } from "../../../../Redux/actions/actionNames";

const createtIcon = ( driver) => {
  return new L.divIcon({
    className: "",
    html: ReactDOMServer.renderToString(CreateDriverPinOsm( driver)),
    iconSize: [54, 57],
    iconAnchor: [37, 67],
  });
};


const CreateDriverPinOsm = ( data) => {
  return  <Pin hasActiveOrder={true} driver={data}  isOnline={data.isOnline} />
};
class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      location: null,
      last_location: null,
      deg: 0,
    };
    this.intervall = null;
  }

  componentDidMount() {
    if (this.props.pureData && CanTrackDriver(this.props.status)) {
      this.props.dispatch(TrackOrderDriverAction(this.props.dispatch,this.props.id , (call)=>{
        this.props.dispatch(SetDriverLocation(call.location,"1"))

        this.setState({
          data: call,
          location: call.location,
        });
      }))
      this.intervall = setInterval(() => {
        this.props.dispatch(TrackOrderDriverAction(this.props.dispatch,this.props.id , (call)=>{
          this.props.dispatch(SetDriverLocation(call.location,"2"))

          this.setState({
            data: call,
            location: call.location,
          });
        }))

      }, 5000);
    }
  }

  componentWillReceiveProps(nextProps) {

    if (
      CanTrackDriver(nextProps.status) &&
      nextProps.OpenVRDialoge &&
      !this.intervall
    ) {

      this.props.dispatch(TrackOrderDriverAction(this.props.dispatch,nextProps.id , (call)=>{
        this.props.dispatch(SetDriverLocation(call.location,"1"))
        this.setState({
          data: call,
          location: call.location,
        });
      }))

      this.intervall = setInterval(() => {
        this.props.dispatch(TrackOrderDriverAction(this.props.dispatch,nextProps.id , (call)=>{
          this.props.dispatch(SetDriverLocation(call.location,"1"))
          this.setState({
            data: call,
            location: call.location,
          });
        }))
      }, 5000);
    } else if(!CanTrackDriver(nextProps.status) && (this.intervall || !nextProps.OpenVRDialoge)) {
      this.props.dispatch(SetDriverLocation(null,"5"))

      clearInterval(this.intervall);
    }
  }

  componentWillUnmount(){
    this.props.dispatch(SetDriverLocation(null,"6"))

    clearInterval(this.intervall);

  }

  openProfile=()=>{
    // this.props.dispatch(SetDriverListOpenDialoge(false))
    this.props.dispatch(GetDriverInfoAction(this.props.dispatch, this.props.driverId))
    this.props.dispatch(SetDriverListOpenDialoge(true))
    this.props.dispatch(SetDriverPopUp(null))

    GetDriverOrderListAction(this.props.dispatch, {
      cursor: this.props.cursor,
      cursorSort: this.props.cursorSort,
      status: ["Assigned", "PickedUp", "Started", "Arrived"],
      size: 200,
      driverId: this.props.driverId,
    } , false)
  }

  render() {

    if (
      CanTrackDriver(this.props.status) &&
      this.state.data && this.props.driver_location
    ) {
      return (
        <React.Fragment>
          <Marker
            animate={true}
            position={{
              lat: this.props.driver_location[1],
              lng: this.props.driver_location[0],
            }}
            icon={createtIcon(this.state.data)}
            onClick={this.openProfile}
          ></Marker>

        </React.Fragment>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state, ownProps) => ({
  OpenVRDialoge: state.ViewTravel.OpenDialoge,
  pureData: ownProps.VRpureData,
  status: state.ViewTravel.pureData ? state.ViewTravel.pureData.status : "",
  id: state.ViewTravel.ID,
  socket: state.Socket.socket,
  driver_location: state.ViewTravel.driver_location,
  driverId: state.ViewTravel.pureData ? state.ViewTravel.pureData.driverId : "",
  cursor: state.OrderList.cursor,
  cursorSort: state.OrderList.cursorSort,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(mapStateToProps, mapDispatchToProps)(App);
