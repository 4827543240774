import React, { useCallback, useEffect, useRef, useState } from 'react'
import styles from './style.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { ARROW_LEFT, CLOSE, FORWARD } from '../../../assets';
import Button from '../../../design-system/lib/Button';
import Typography from '../../../design-system/lib/Typography';
import Tag from '../../../design-system/lib/Tag';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { ResetNewOrder, Table_setSelectedBulkEditStatusSummary } from '../../../Redux/actions/actionNames';
import jalali from "jalali-moment";
import { get_lang } from '../../../helper/UserData/userdate';


function EditStatusSummary({style}) {
  const selectedBulkEditStatusSummary = useSelector((state) => state.Table.selectedBulkEditStatusSummary)
  const [tab, setTab] = useState(selectedBulkEditStatusSummary.successfulRecordsCount== 0 ? 1 : 0);
  const dispatch = useDispatch();

  
  console.log("selectedBulkEditStatusSummary ",selectedBulkEditStatusSummary)

  const handleClose = () => {
    dispatch(Table_setSelectedBulkEditStatusSummary(null))
}

useEffect(() => {
  dispatch(ResetNewOrder())
}, []);

useEffect(() => {
  if(selectedBulkEditStatusSummary){
      setTab(selectedBulkEditStatusSummary.successfulRecordsCount== 0 ? 1 : 0)
  }
}, [selectedBulkEditStatusSummary])

  return (
    <div className={styles.EditStatusSummary} style={style ? style : {}}>
      <div className={styles.header}>
        <Button icon={ARROW_LEFT} size="Medium" type="Secondary" onClick={handleClose} />
        <div className={styles.largeHeaderTitles}>
          <Typography text={"Bulk Edit Status Summary"} weight="Body_Middle_Bold" className={styles.SmallTitle} style={{ margin: "0", padding: "0" }} />
          <Typography text={jalali(selectedBulkEditStatusSummary.doneAt)
                                .locale(get_lang())
                                .format("DD MMM HH:mm")} weight="Subtitle_Tiny_Regular" className={styles.SmallDescription} style={{ margin: "0", padding: "0" }} />
        </div>
        <Tag backgroundColor='GreenUltraLight' color='Green' size='Large' text='Done' weight='Body_Small_Medium' style={{borderRadius:"8px"}} />
      </div>

      <div className={styles.tabsContainer}>
      {selectedBulkEditStatusSummary.successfulRecordsCount > 0 &&  <div className={clsx(styles.tab, tab == 0 && styles.activeTab)} onClick={() => { setTab(0) }}>
          <Typography text={"Successful"} weight="Body_Middle_Bold" style={{ margin: "0", padding: "0" }} />
          <span className={styles.tabBadge}>{selectedBulkEditStatusSummary.successfulRecordsCount}</span>
          <div className={styles.bottomTab} />
        </div>}
     {selectedBulkEditStatusSummary.failedRecordsCount > 0 &&   <div className={clsx(styles.tab, tab == 1 && styles.activeTab)} onClick={() => { setTab(1) }}>
          <Typography text={"Failed"} weight="Body_Middle_Bold" style={{ margin: "0", padding: "0" }} />
          <span className={styles.tabBadge}>{selectedBulkEditStatusSummary.failedRecordsCount}</span>
          <div className={styles.bottomTab} />
        </div>}
      </div>

      <div className={styles.list}>
        <div className={styles.fistcol}>
          <div className={styles.headerCol}>
            <Typography text={"Order ID"} weight="Body_Small_Bold" />
          </div>
          {selectedBulkEditStatusSummary.records.filter(((zon) => { return  tab == 0 ? zon.failed == false : zon.failed == true })).map((rec)=>{
            return <div className={styles.dataCol}>
            <Typography text={rec.result?.code} weight="Body_Small_Regular"  style={{ margin: "0", padding: "0" }}/>
          </div>
          })}
        </div>
        <div className={styles.secocol} style={tab == 1 ? {flex : 0}:{}}>
          <div className={styles.headerCol}>
            <Typography text={"Details"} weight="Body_Small_Bold" />
          </div>
          {/* <Link to={`/order/${deliveryOrderId}`}><img className={styles.info} src={forward} /> </Link> */}
          {selectedBulkEditStatusSummary.records.filter(((zon) => { return  tab == 0 ? zon.failed == false : zon.failed == true })).map((rec)=>{
            return <div className={styles.dataCol}>
            <Link to={`/order/${rec.result.id}`} onClick={handleClose}><img className={styles.info} src={FORWARD} /> </Link>
          </div>
          })}
        </div>
       {tab == 1 && <div className={styles.secocol}>
          <div className={styles.headerCol}>
            <Typography text={"Result"} weight="Body_Small_Bold" />
          </div>

          {selectedBulkEditStatusSummary.records.filter(((zon) => { return  tab == 0 ? zon.failed == false : zon.failed == true })).map((rec)=>{
            return <div className={styles.dataCol}>
            <Typography text={rec.description} weight="Body_Small_Regular"  style={{ margin: "0", padding: "0" }}/>
          </div>
          })}
        </div>}
      </div>
    </div>
  )
}


export default EditStatusSummary