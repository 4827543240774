import styles from './style.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { SetDriverListData, SetDriverListLoading, SetDriverListloadingOrderedPage } from '../../../../../Redux/actions/actionNames';
import { DriverApi } from '../../../../../service/API/driver';

export const DriversTabs = () => {
    const dispatch = useDispatch();
    const isOnline = useSelector((state) => state.DriversList.isOnline)
    const onlineCount = useSelector((state) => state.DriversList.stats.isOnline)
    const offlineCount = useSelector((state) => state.DriversList.stats.isOffline)

    const { t } = useTranslation();
    const regex = useSelector((state) => state.DriversList.regex)


   async function handleTab(e){
        dispatch(SetDriverListLoading(true))

        // let params ={
        //     page : 1,
        //     perpage : 40 ,
        //     isOnline : e 
        // }
        dispatch(SetDriverListData({
            "drivers": [],
            "startCursor": null,
            "endCursor": null,
            "nextCursor": null
        }))

        dispatch(
            SetDriverListloadingOrderedPage({
                isOnline: e ,       
                // DriverlistLoading: true ,
                // DriverlistPage : 1,
                // Driverlist: [],
                // canLoadmore: false
            })
          );

        //   let Driver = new DriverApi()
        //   let data = await Driver.List(params)
        //   dispatch(SetDriverListData(data.data, true))
        //   dispatch(
        //       SetDriverListloadingOrderedPage({
        //         DriverlistLoading: false ,
        //         DriverlistPage : 1,
        //         canLoadmore: data.data.length == 40
        //       })
        //     );

    }
 
    if(regex.length > 0 ){
        return null
    }
    
    return <div className={styles.DriversTabsContainer}>
        <span className={clsx(styles.tab,isOnline && styles.activeTab)} onClick={()=>{ !isOnline && handleTab(true)}}>{t("Online")} <span className={styles.tag}>{onlineCount}</span> 
            {<div className={clsx(styles.itemBorder, isOnline ? styles.itemBorderActive : null)} />}
        </span>
        <span className={clsx(styles.tab,!isOnline && styles.activeTab)} onClick={()=>{isOnline && handleTab(false)}}>{t("Offline")} <span className={styles.tag}>{offlineCount}</span>
            {<div className={clsx(styles.itemBorder, !isOnline ? styles.itemBorderActive : null)} />}
        </span>

    </div>

};




