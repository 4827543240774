import React, { useState } from 'react'
import styles from './style.module.css';

import clsx from 'clsx';
import SidebarButton from '../../../../../design-system/lib/SidebarButton';
import { DeliveryOnly_active, Deliveryonly_deactive, Ondemand_active, Ondemand_deactive, Orders_active, Orders_deactive, Phubd_deactive, PickupOnly_Deactive, Pickuponly_active, Routes_active, Routes_deactive } from '../../../../../assets';
import { get_tab_orderTypes } from '../../../../../helper/UserData/userdate';
import { useDispatch, useSelector } from 'react-redux';
import { ResetViewTravel, Set_OrderList_OrderTypes, Table_reset, Table_setRouteList, Table_setSelectedBulkEditStatusSummary } from '../../../../../Redux/actions/actionNames';
import history from '../../../../../service/history';



interface ISidebarOrderTypes {
  isOpen: boolean
}




export const SidebarOrderTypes = ({ isOpen }: ISidebarOrderTypes) => {
  const [activeTab, setActiveTab] = useState(null);
  const orderTypes = useSelector((state) => state.SharedData.settings?.orderTypes)
  const ActiveOrderType = useSelector<any>((state) => state.OrderList.ActiveOrderType)
  const dispatch = useDispatch();
  const TableisLoading = useSelector<any>((state) => state.Table.isLoading)
  const routeListISopen = useSelector<any>((state) => state.Table.routeList.open)

  const checkDisabled = (ordertype: string, orderTypes: any) => {
    return orderTypes.findIndex((ord: string) => { return ord === ordertype }) == -1
  }

  const handleTab = React.useCallback((ordertype: string) => {

    dispatch(ResetViewTravel());
    dispatch(Table_setSelectedBulkEditStatusSummary(null))
    dispatch(Set_OrderList_OrderTypes(ordertype))


    if (!TableisLoading) {
      //dispatch(Table_reset())
      //  dispatch(Set_OrderList_OrderTypes(ordertype))
      dispatch(Table_setRouteList({ key: "open", value: false }))
    }


  }, [TableisLoading]);



  const closeSummary = React.useCallback(() => {

    dispatch(Table_setSelectedBulkEditStatusSummary(null))

  }, []);

  const isRoutesOpen = () => {
    if (String(window.location.href).includes("/route")) {
      return true
    } else {
      return false
    }
  }

  
  React.useEffect(() => {

    if (isOndemand()) {
      dispatch(Set_OrderList_OrderTypes("Ondemand"))
    } else if (isPickupDelivery()) {
      dispatch(Set_OrderList_OrderTypes("PickupDelivery"))
    } else if (isPickup()) {
      dispatch(Set_OrderList_OrderTypes("Pickup"))
    } else if (isDelivery()) {
      dispatch(Set_OrderList_OrderTypes("Delivery"))
    }

  }, [])

  return <div className={styles.SidebarOrderTypes}>

    {!checkDisabled("Ondemand", orderTypes) && <div className={clsx(styles.ondemand)}>

      <SidebarButton size='full' type='Primary' disabled={checkDisabled("Ondemand", orderTypes)} onClick={!isOndemand() ? () => { handleTab("Ondemand"); history.push(`/Ondemand`); } : null} activeIcon={Ondemand_active} deActiveIcon={Ondemand_deactive} isActive={"Ondemand" == ActiveOrderType && !isRoutesOpen()} isOpen={isOpen} text='On Demand' />

    </div>}


    {!checkDisabled("PickupDelivery", orderTypes) && <div className={clsx(styles.phubd, ("PickupDelivery" == ActiveOrderType || !!isRoutesOpen()) && styles.phubdActive)} >
      <SidebarButton size='full' type='Primary' className={styles.pickupDelivery} disabled={checkDisabled("PickupDelivery", orderTypes)} activeIcon={Phubd_deactive} deActiveIcon={Phubd_deactive} isActive={false} isOpen={isOpen} text='P_Hub_D' />
      <SidebarButton size='mini' type='Primary' className={styles.subMenu} disabled={checkDisabled("PickupDelivery", orderTypes)} onClick={!isPickupDelivery() ? () => { handleTab("PickupDelivery"); history.push(`/PickupDelivery`); closeSummary() } : null} activeIcon={Orders_active} deActiveIcon={Orders_deactive} isActive={"PickupDelivery" == ActiveOrderType && !isRoutesOpen()} isOpen={isOpen} text='Orders' />
      <SidebarButton size='mini' type='Primary' className={styles.subMenu} disabled={checkDisabled("PickupDelivery", orderTypes)} onClick={() => { history.push(`/routes`); closeSummary()/*dispatch(Table_setRouteList({key:"open",value: !routeListISopen })) */ }} activeIcon={Routes_active} deActiveIcon={Routes_deactive} isActive={isRoutesOpen()} isOpen={isOpen} text='Routes' />
    </div>}

    {(!checkDisabled("Pickup", orderTypes) || !checkDisabled("Delivery", orderTypes)) && <div className={clsx(styles.ondemand)}>
      {!checkDisabled("Pickup", orderTypes) && <SidebarButton size='full' type='Primary' disabled={checkDisabled("Pickup", orderTypes)} onClick={!isPickup() ? () => { handleTab("Pickup"); history.push(`/Pickup`); }: null} activeIcon={Pickuponly_active} deActiveIcon={PickupOnly_Deactive} isActive={"Pickup" == ActiveOrderType && !isRoutesOpen()} isOpen={isOpen} text='Pickup' />}
      {!checkDisabled("Delivery", orderTypes) && <SidebarButton size='full' type='Primary' disabled={checkDisabled("Delivery", orderTypes)} onClick={!isDelivery() ? () => { handleTab("Delivery"); history.push(`/Delivery`); }:null} activeIcon={DeliveryOnly_active} deActiveIcon={Deliveryonly_deactive} isActive={"Delivery" == ActiveOrderType && !isRoutesOpen()} isOpen={isOpen} text='Delivery' />}
    </div>}
  </div>

};


export const isOndemand = () => {
  if (String(window.location.href).includes("Ondemand")) {
    return true
  } else {
    return false
  }
}

export const isPickupDelivery = () => {
  if (String(window.location.href).includes("/PickupDelivery")) {
    return true
  } else {
    return false
  }
}

export const isPickup = () => {
  if (String(window.location.pathname) == "/Pickup") {
    return true
  } else {
    return false
  }
}

export const isDelivery = () => {
  if (String(window.location.pathname) == "/Delivery") {
    return true
  } else {
    return false
  }
}



