export const ENQUEUE_SNACKBAR = "ENQUEUE_SNACKBAR";
export const CLOSE_SNACKBAR = "CLOSE_SNACKBAR";
export const REMOVE_SNACKBAR = "REMOVE_SNACKBAR";
export const OPENCLOSENEWTRAVEL = "OPENCLOSENEWTRAVEL";
export const set_TravelList_loading = "set_TravelList_loading";
export const set_TravelList_page = "set_TravelList_page";
export const set_TravelList = "set_TravelList";
export const set_TravelListWithPage = "set_TravelListWithPage";
export const reset_passenger = "reset_passenger";
export const set_passenger_name_family_phoneNumber = "set_passenger_name_family_phoneNumber";
export const set_service_VehicleTypes = "set_service_VehicleTypes";
export const set_service_activeVehicleType = "set_service_activeVehicleType";
export const add_service_option = "add_service_option";
export const remove_service_option = "remove_service_option";
export const set_auto_driver = "set_auto_driver";
export const set_auto_driverـPickupDeliveryType_Pickup = "set_auto_driverـPickupDeliveryType_Pickup";
export const set_auto_driverـPickupDeliveryType_Dropoff = "set_auto_driverـPickupDeliveryType_Dropoff";

export const set_auto_driver_EditOrder = "set_auto_driver_EditOrder";
export const set_auto_activeVehicleType = "set_auto_activeVehicleType";
export const set_auto_activeVehicleTypeـPickupDeliveryType_Pickup = "set_auto_activeVehicleTypeـPickupDeliveryType_Pickup";
export const set_auto_activeVehicleTypeـPickupDeliveryType_Dropoff = "set_auto_activeVehicleTypeـPickupDeliveryType_Dropoff";

export const set_auto_activeVehicleType_EditOrder = "set_auto_activeVehicleType_EditOrder";
export const Edit_Travel_SetAutoActiveVehicleType = "Edit_Travel_SetAutoActiveVehicleType";
export const remove_driver_option = "remove_driver_option";
export const remove_driver_optionـPickupDeliveryType_Pickup = "remove_driver_optionـPickupDeliveryType_Pickup";
export const add_driver_option = "add_driver_option";
export const add_driver_optionـPickupDeliveryType_Pickup = "add_driver_optionـPickupDeliveryType_Pickup";
export const add_driver_optionـPickupDeliveryType_Dropoff = "add_driver_optionـPickupDeliveryType_Dropoff";
export const remove_driver_optionـPickupDeliveryType_Dropoff = "remove_driver_optionـPickupDeliveryType_Dropoff";

export const remove_driver_option_EditOrder = "remove_driver_option_EditOrder";
export const add_driver_option_EditOrder = "add_driver_option_EditOrder";

export const set_auto_suggestListData = "set_auto_suggestListData";

export const set_manual_activeVehicleType = "set_manual_activeVehicleType";
export const set_manual_activeVehicleTypeـPickupDeliveryType_Pickup = "set_manual_activeVehicleTypeـPickupDeliveryType_Pickup";
export const set_PureـPickupDeliveryType_Pickup = "set_PureـPickupDeliveryType_Pickup";
export const set_PureـPickupDeliveryType_Dropoff = "set_PureـPickupDeliveryType_Dropoff";
export const set_manual_activeVehicleTypeـPickupDeliveryType_Dropoff = "set_manual_activeVehicleTypeـPickupDeliveryType_Dropoff";

export const set_manual_activeVehicleType_EditOrder = "set_manual_activeVehicleType_EditOrder";
export const set_pickup_inputs = "set_pickup_inputs";
export const set_pickup = "set_pickup";
export const add_dropoff = "add_dropoff";
export const set_activePickupDropOff = "set_activePickupDropOff";
export const set_dropOff_inputs = "set_dropOff_inputs";
export const set_DropOff_fields_Dnd = "set_DropOff_fields_Dnd";
export const set_DropOff_fields_Dnd_EditAddress = "set_DropOff_fields_Dnd_EditAddress";

export const set_dropOff_inputs_bulk = "set_dropOff_inputs_bulk";


export const set_Zoom = "set_Zoom";
export const set_latlng = "set_latlng";
export const set_REF = "set_REF";
export const set_realtimePlaceName = "set_realtimePlaceName";
export const set_realtimePlaceNameLoading = "set_realtimePlaceNameLoading";
export const set_SearchAutoComplete = "set_SearchAutoComplete";
export const Set_SearchAutoCompleteNewFav = "Set_SearchAutoCompleteNewFav";
export const set_SearchAutoCompleteLoading = "set_SearchAutoCompleteLoading";
export const set_driver_auto_vehicleTypes = "set_driver_auto_vehicleTypes";
export const set_accesstoken = "set_accesstoken";
export const suggestcustomerlist = "suggestcustomerlist";
export const suggestcustomerlistloading = "suggestcustomerlistloading";
export const setpriceneworder = "setpriceneworder";
export const setcansavetravel = "setcansavetravel";
export const setCanSaveEditOrder = "setCanSaveEditOrder";
export const resetneworder = "resetneworder";
export const addnewordertoList = "addnewordertoList";
export const remove_dropoff = "remove_dropoff";
export const OPENCLOSEVIEWTRAVEL = "OPENCLOSEVIEWTRAVEL";
export const SETSOCKET = "SETSOCKET";
export const UPDATETRAVELINLIST = "UPDATETRAVELINLIST";
export const DELETETRAVELINLIST = "DELETETRAVELINLIST";
export const LOADINGORDERINFO = "LOADINGORDERINFO";
export const SETORDERINFO = "SETORDERINFO";
export const SETORDERINFOERROR = "SETORDERINFOERROR";
export const OPENCLOSEEDITTRAVEL = "OPENCLOSEEDITTRAVEL";
export const EDITTRAVELSETPRICE = "EDITTRAVELSETPRICE";
export const Edit_travel_set_service_VehicleTypes = "Edit_travel_set_service_VehicleTypes";
export const Edit_travel_set_service_activeVehicleType = "Edit_travel_set_service_activeVehicleType";
export const Edit_travel_add_service_option = "Edit_travel_add_service_option";
export const Edit_travel_remove_service_option = "Edit_travel_remove_service_option";
export const Edit_Travel_Set_Customer = "Edit_Travel_Set_Customer";
export const Edit_Travel_Set_Driver_Auto = "Edit_Travel_Set_Driver_Auto";
export const Edit_Travel_Set_Manual_Driver = "Edit_Travel_Set_Manual_Driver";
export const Edit_travel_set_activePickupDropOff = "Edit_travel_set_activePickupDropOff";
export const Edit_Travel_Set_Manual_ActiveVehicleType = "Edit_Travel_Set_Manual_ActiveVehicleType";
export const Edit_Travel_Set_Auto_Driver = "Edit_Travel_Set_Auto_Drivers";
export const EditDriverAddDriverOption = "EditDriverAddDriverOption";
export const EditDriverRemoveDriverOption = "EditDriverRemoveDriverOption";
export const EditTravelresetdriveroptions = "EditTravelresetdriveroptions";
export const EditTravelSetPickup = "EditTravelSetPickup";
export const EditTravelSetDropoffs = "EditTravelSetDropoffs";
export const EditTravelsetpickupinputs = "EditTravelsetpickupinputs";
export const EditTravelset_pickup = "EditTravelset_pickup";
export const EditTravelsetDropoffinputs = "EditTravelsetDropoffinputs";
export const EditTravelset_Dropoff = "EditTravelset_Dropoff";
export const EditTraveladd_dropoff = "EditTraveladd_dropoff";
export const EditTravelremove_dropoff = "EditTravelremove_dropoff";
export const EditTravelSetPrice = "EditTravelSetPrice";
export const EditTravelOperateChanged = "EditTravelOperateChanged";
export const EditTravelReset = "EditTravelReset";
export const EditTravelPaymentMethod = "EditTravelPaymentMethod";
export const NewTravelPaymentMethod = "NewTravelPaymentMethod";
export const Edit_Travel_SetCanSaveTravel = "Edit_Travel_SetCanSaveTravel";
export const monitoringOnOff = "monitoringOnOff";
export const monitoringAddVehicleType = "monitoringAddVehicleType";
export const monitoringRemoveVehicleType = "monitoringRemoveVehicleType";
export const setMonitoringDrivers = "setMonitoringDrivers";
export const resetViewTravel = "resetViewTravel";
export const setViewTravelEstimatedRoute = "setViewTravelEstimatedRoute";
export const addListOrderState = "addListOrderState";
export const removeListOrderState = "removeListOrderState";
export const resetListOrderState = "resetListOrderState";
export const requsetListRegex = "requsetListRegex";
export const requsetListRegexFields = "requsetListRegexFields";
export const requsetListorderTypes = "requsetListorderTypes";
export const setjustme = "setjustme";
export const setNewFav = "setNewFav";
export const openNewFav = "openNewFav";
export const resetNewFav = "resetNewFav";
export const setListNewFav = "setListNewFav";
export const deleteFavAddress = "deleteFavAddress";
export const addFavAddress = "addFavAddress";
export const resetInputNewfav = "resetInputNewfav";
export const feeChange = "feeChange";
export const surchargeChange = "surchargeChange";
export const surcharge = "surcharge";
export const newTravelPickupSetReserve = "newTravelPickupSetReserve";
export const newTravelDropOffSetReserve = "newTravelDropOffSetReserve";
export const newTravelResetReserve = "newTravelResetReserve";
export const changePersonBusiness = "changePersonBusiness";
export const setMonitoringHaveOrder = "setMonitoringHaveOrder";
export const changeViewTravelHistoryMode = "changeViewTravelHistoryMode";
export const setHistoryData = "setHistoryData";
export const openCloseFilterCollapse = "openCloseFilterCollapse";
export const listFilterVehicleTypeAdd = "listFilterVehicleTypeAdd";
export const listFilterVehicleTypeRemove = "listFilterVehicleTypeRemove";
export const listFilterServicesAdd = "listFilterServicesAdd";
export const listFilterServicesReset = "listFilterServicesReset";
export const listFilterServicesTypeRemove = "listFilterServicesRemove";
export const listFilterSetDateRange = "listFilterSetDateRange";
export const set_service_VehicleTypes_RepeatData = "set_service_VehicleTypes_RepeatData";
export const setAdvancedSortKey = "setAdvancedSortKey";
export const setAdvancedSortvalue = "setAdvancedSortvalue";
export const toggleDriverList = "toggleDriverList";
export const setActiveDriverList = "setActiveDriverList";
export const setDriverListloadingOrderedPage = "setDriverListloadingOrderedPage";
export const setDriverListData = "setDriverListData";
export const setDriverOrdersListData = "setDriverOrdersListData";
export const editTravelPickupSetReserve = "editTravelPickupSetReserve";
export const editTravelDropOffSetReserve = "editTravelDropOffSetReserve";
export const setEditRequestForceCalculateCost = "setEditRequestForceCalculateCost";
export const setopenAddressBookInNewOrder = "setopenAddressBookInNewOrder";
export const setsourceOfAddressBookInNewOrder = "setsourceOfAddressBookInNewOrder";
export const SetAddressBookListData = "SetAddressBookListData";
export const SetAddressBookSearchRegex = "SetAddressBookSearchRegex";
export const set_Force_Assaigning = "set_Force_Assaigning";
export const set_Force_AssaigningـPickupDeliveryType_Pickup = "set_Force_AssaigningـPickupDeliveryType_Pickup";
export const set_Force_AssaigningـPickupDeliveryType_Dropoff = "set_Force_AssaigningـPickupDeliveryType_Dropoff";

export const set_searchDriverInput = "set_searchDriverInput";
export const set_searchDriverInputـPickupDeliveryType_Pickup = "set_searchDriverInputـPickupDeliveryType_Pickup";
export const set_searchDriverInputـPickupDeliveryType_Dropoff = "set_searchDriverInputـPickupDeliveryType_Dropoff";

export const set_Edit_Order_DriverAndStatus = "set_Edit_Order_DriverAndStatus";
export const set_searchDriverInput_EditOrder = "set_searchDriverInput_EditOrder";
export const reset_DriverAndStatus = "reset_DriverAndStatus";

export const set_new_order_OrderType = "set_new_order_OrderType";
export const show_event_TimeLine = "show_event_TimeLine";
export const set_viewOrder_events = "set_viewOrder_events";
export const set_New_Event = "set_New_Event";

export const new_Order_Reset_Pickup = "new_Order_Reset_Pickup";
export const new_Order_Reset_DropOffs = "new_Order_Reset_DropOffs";
export const new_Order_Reset_DropOff = "new_Order_Reset_DropOff";

export const set_Order_Sub_Id = "set_Order_Sub_Id";

export const advancedAssaigning_handleDialoge = "advancedAssaigning_handleDialoge";
export const advancedAssaigning_reset = "advancedAssaigning_reset";
export const advancedAssaigning_setVehicleType = "advancedAssaigning_setVehicleType";
export const advancedAssaigning_setRule = "advancedAssaigning_setRule";
export const advancedAssaigning_firstDriver_auto = "advancedAssaigning_firstDriver_auto";
export const advancedAssaigning_firstDriver_auto_switch = "advancedAssaigning_firstDriver_auto_switch";
export const advancedAssaigning_firstDriver_manual = "advancedAssaigning_firstDriver_manual";
export const advancedAssaigning_firstDriver_options_add = "advancedAssaigning_firstDriver_options_add";
export const advancedAssaigning_firstDriver_options_remove = "advancedAssaigning_firstDriver_options_remove";
export const advancedAssaigning_firstDriver_options_reset = "advancedAssaigning_firstDriver_options_reset";
export const monitoringSet_VehicleTypes = "monitoringSet_VehicleTypes";
export const advancedAssaigning_setIniRule = "advancedAssaigning_setIniRule";
export const advancedAssaigning_secondDriver_auto = "advancedAssaigning_secondDriver_auto";
export const advancedAssaigning_secondDriver_auto_switch = "advancedAssaigning_secondDriver_auto_switch";
export const advancedAssaigning_secondDriver_manual = "advancedAssaigning_secondDriver_manual";
export const advancedAssaigning_secondDriver_options_add = "advancedAssaigning_secondDriver_options_add";
export const advancedAssaigning_secondDriver_options_remove = "advancedAssaigning_secondDriver_options_remove";
export const advancedAssaigning_secondDriver_options_reset = "advancedAssaigning_secondDriver_options_reset";
export const set_CheckServiceByCustomer = "set_CheckServiceByCustomer";
export const set_CheckVehicleByCustomer = "set_CheckVehicleByCustomer";

export const orderListFilterService = "orderListFilterService";
export const orderListFilterVehicletypes = "orderListFilterVehicletypes";
export const createNewDraft = "createNewDraft";
export const setActiveImport = "setActiveImport";
export const setActiveServiceImport = "setActiveServiceImport";
export const setActiveServiceImportList = "setActiveServiceImportList";
export const create_new_BulkImport_List = "create_new_BulkImport_List";


export const add_Service_Option_bulkImport = "add_Service_Option_bulkImport";
export const remove_Service_Option_bulkImport = "remove_Service_Option_bulkImport";
export const add_VehicleType_Option_bulkImport = "add_VehicleType_Option_bulkImport";
export const remove_VehicleType_Option_bulkImport = "remove_VehicleType_Option_bulkImport";
export const remove_draft_bulkImport = "remove_draft_bulkImport";


export const setBulkImportList = "setBulkImportList";
export const setCodAmount = "setCodAmount";
export const setReferenceID = "setReferenceID";
export const setRecentAddress = "setRecentAddress";

export const setNotesOrder = "setNotesOrder";


export const setVoipIncommingCallAction = "setVoipIncommingCallAction";
export const setVoipIncommingCall = "setVoipIncommingCall";




export const setOrderListStatsLoading = "setOrderListStatsLoading";
export const setOrderListStatsData = "setOrderListStatsData";
export const setCategoryOrderListLoading = "setCategoryOrderListLoading";
export const setCategoryOrderListData = "setCategoryOrderListData";
export const resetCategoryOrderListData = "resetCategoryOrderListData";
export const setOrderListCursor = "setOrderListCursor";
export const setOrderListCursorSort = "setOrderListCursorSort";
export const setSearchOrderListRegexFields = "setSearchOrderListRegexFields";
export const setSearchOrderListRegexText = "setSearchOrderListRegexText";
export const setSearchOrderListLoading = "setSearchOrderListLoading";
export const setSearchOrderListRequested = "setSearchOrderListRequested";
export const setSearchOrderListData = "setSearchOrderListData";


export const setSharedDataServices = "setSharedDataServices";
export const setSharedDataZone = "setSharedDataZone";
export const setSharedDataVehicleTypes = "setSharedDataVehicleTypes";
export const setOrderListFiltersDueTime = "setOrderListFiltersDueTime";
export const setOrderListFiltersStatus = "setOrderListFiltersStatus";
export const setOrderListFiltersChanged = "setOrderListFiltersChanged";
export const setOrderListFiltersServices = "setOrderListFiltersServices";
export const setOrderListFiltersVehicleTypes = "setOrderListFiltersVehicleTypes";
export const setOrderListFiltersZone = "setOrderListFiltersZone";
export const openCloseFilterDialoge = "openCloseFilterDialoge";
export const resetSearchFilters = "resetSearchFilters";

export const updateSearchOrdersList = "updateSearchOrdersList";
export const updateCategoryOrdersList = "updateCategoryOrdersList";
export const deleteCategoryOrdersList = "deleteCategoryOrdersList";
export const updateMonitoringOrdersList = "updateMonitoringOrdersList";
export const removeMonitoringOrders = "removeMonitoringOrders";
export const addMonitoringOrdersBounding = "addMonitoringOrdersBounding";
export const addMonitoringOrdersList = "addMonitoringOrdersList";


export const updateCategoryOrdersListSorted = "updateCategoryOrdersListSorted";



export const monitoringHandleActiveOrders = "monitoringHandleActiveOrders";
export const monitoringHandleActiveDrivers = "monitoringHandleActiveDrivers";
export const monitoringSetDrivers = "monitoringSetDrivers";
export const monitoringSetOrders = "monitoringSetOrders";

export const increaseStatsCategoryNumber = "increaseStatsCategoryNumber";
export const decreaseStatsCategoryNumber = "decreaseStatsCategoryNumber";
export const setDriverListRegex = "setDriverListRegex";
export const setDriverListOpenDialoge = "setDriverListOpenDialoge";

export const setSelectedMonitoringOrder = "setSelectedMonitoringOrder";
export const setSelectedMonitoringDriver = "setSelectedMonitoringDriver";

export const setDriverPopUp = "setDriverPopUp";
export const setOrderPinPopUp = "setOrderPinPopUp";
export const setOrderPinPopUpPosition = "setOrderPinPopUpPosition";
export const setDriverStatistics = "setDriverStatistics";


export const setDriverListLoading = "setDriverListLoading";

export const updateOrdersCountInDriversList = "updateOrdersCountInDriversList";
export const resetOrderListData = "resetOrderListData";
export const priceDetailsOpen = "priceDetailsOpen";

export const setViewOrderPickupDropoffDetails = "setViewOrderPickupDropoffDetails";
export const setMainLoaded = "setMainLoaded";
export const setCategoryCollapse = "setCategoryCollapse";

export const offeringDetailsOpen = "offeringDetailsOpen";

export const setDriverLocation = "setDriverLocation";
export const setGoogleMapLoaded = "setGoogleMapLoaded";

export const setSettings = "setSettings";


export const setOrderNotFound = "setOrderNotFound";
export const setorderError = "setorderError";
export const setTime = "setTime";


export const newCustomerHandleChange = "newCustomerHandleChange";

export const openEditCustomer = "openEditCustomer";
export const editPriceNewOrder = "editPriceNewOrder";
export const editPriceNewOrderChanged = "editPriceNewOrderChanged";
export const editPrice_PromoCode_NewOrderChanged = "editPrice_PromoCode_NewOrderChanged";
export const set_autoDriver_vehicles_auto_suggestListData = "set_autoDriver_vehicles_auto_suggestListData";


export const set_uid_newOrder = "set_uid_newOrder";
export const rebuildNewOrder = "rebuildNewOrder";
export const editPriceNewOrder_manualy = "editPriceNewOrder_manualy";
export const Table_Init = "Table_Init";

export const Table_SelectOrder = "Table_SelectOrder";
export const Table_DeSelectOrder = "Table_DeSelectOrder";

export const Table_ClearSelectedOrders = "Table_ClearSelectedOrders";

export const Table_SelecteMultiOrders = "Table_SelecteMultiOrders";
export const Table_setData = "Table_setData";
export const Table_reset = "Table_reset";

export const Table_setStatus = "Table_setStatus";
export const Table_setSelectedBulkEditStatusSummary = "Table_setSelectedBulkEditStatusSummary";
export const table_setRouteList = "table_setRouteList";



export const TableInputContainFilter_Add = "TableInputContainFilter_Add";
export const TableInputContainFilter_Delete = "TableInputContainFilter_Delete";
export const TableInputContainFilter_Update = "TableInputContainFilter_Update";

export const TableCheckboxFilter_Update = "TableCheckboxFilter_Update";

export const TableRadioButtonFilter_Update = "TableRadioButtonFilter_Update";
export const TableRangeFilter_Update = "TableRangeFilter_Update";
export const Add_ActiveFilter = "Add_ActiveFilter";
export const clearAll_ActiveFilter = "clearAll_ActiveFilter";

export const TableSubRangeFilter_Update = "TableSubRangeFilter_Update";


export const Route_reset = "Route_reset";
export const handleEditRouteFilter_Name = "handleEditRouteFilter_Name";

export const clearAll_Route_ActiveFilter = "clearAll_Route_ActiveFilter";
export const set_newOrder_draft = "set_newOrder_draft";
export const set_map_loaded= "set_map_loaded";

