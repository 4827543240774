import styles from './style.module.css';
import sprof from "../../../assets/sprof.svg";
import wallet from "../../../assets/wallet.svg";
import call from "../../../assets/call.svg";
import customerProfile from "../../../assets/customerProfile.jpg";
import business_type from "../../../assets/business_type.svg";
import info from "../../../assets/info.svg";

import { useDispatch, useSelector } from 'react-redux';
import { get_currencyCode, get_id, get_lang } from '../../../helper/UserData/userdate';
import { Voip_OutgoingCall } from '../../../Redux/actions/actions';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useCallback } from 'react';
import { OfferingDetailsOpen, PriceDetailsOpen, SetViewOrderPickupDropoffDetails, show_eventTimeLine } from '../../../Redux/actions/actionNames';

export const CustomerService = () => {
    const customer = useSelector((state) => state.ViewTravel.pureData.customer)
    const business = useSelector((state) => state.ViewTravel.pureData.business)
    const orderId = useSelector((state) => state.ViewTravel.pureData.id)
    const service = useSelector((state) => state.ViewTravel.pureData.service)
    const customerId = useSelector((state) => state.ViewTravel.pureData.customerId)
    const customerType = useSelector((state) => state.ViewTravel.pureData.customerType)
    const { t, i18n } = useTranslation();
    const pickup_dropoff_details = useSelector((state) => state.ViewTravel.pickup_dropoff_details)
    const dispatch = useDispatch();
    const orderType = useSelector((state) => state.ViewTravel.pureData.type)


    function serviceOptionsNameExtracter() {
        let names = [];
        if (service) {
            service.options.map((option) => {
                if (option.type === "boolean") {
                    names.push(option.title);
                } else {
                    names.push(option.dataTitle);
                }
            });
            return names
        } else {
            return [];
        }
    };

    function makeOutgoingCall() {
        dispatch(Voip_OutgoingCall(dispatch, {
            "to": customer
                ? customer.phone
                : business
                    ? business.phone
                    : "",
            "orderId": orderId,
            "customerId": customerId,
            "driverId": null,
            "dispatcherId": get_id()
        }))

    }


    const dropOffInfo = useCallback(() => {
        if (pickup_dropoff_details && String(pickup_dropoff_details.type) == String("ServiceOptions")) {
            dispatch(SetViewOrderPickupDropoffDetails(null))
        } else {
            dispatch(SetViewOrderPickupDropoffDetails({ type: "ServiceOptions", data: service.options }))
        }
        dispatch(OfferingDetailsOpen(false))
        dispatch(show_eventTimeLine(false))
        dispatch(PriceDetailsOpen(false))
    }, [pickup_dropoff_details]);

    return <div className={styles.customerService}>

        <span className={styles.profileContainer}>
            <img className={styles.cprof} src={`${customer &&
                customer.avatarPhoto &&
                customer.avatarPhoto.length !== 0
                ? customer.avatarPhoto
                : customerProfile
                }`} />
            <img className={styles.sprof} src={customerType == "individual" ? sprof : business_type} />
        </span>

        <div className={styles.namePhoneContainer}>
            <span className={styles.name}>{customer
                ? customer.name
                : business
                    ? business.title
                    : ""}</span>
            {/* <span className={styles.phone}> {customer
                ? customer.phone
                : business
                    ? business.phone
                    : ""} </span> */}
        </div>

       {["PickupDelivery"].indexOf(orderType) == -1 && <span className={styles.wallet}> <img src={wallet} /> {
            customer &&
            Number(customer.balance).toLocaleString(
                get_lang(),
                {
                    style: "currency",
                    currency: get_currencyCode(),
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                }
            )
        } </span>}
        <img className={styles.call} src={call} onClick={() => makeOutgoingCall()} />

        <hr className={styles.hr} />

        <div className={styles.xdop}>
            <div>
                <img src={
                    service &&
                    service.icon
                } className={styles.serviceImage} />
                <span className={styles.serviceName}> {service && service.title} </span>
            </div>
            <div className={styles.serviceOptions}>
                {/* {serviceOptionsNameExtracter().map((item)=>{
                    return <span className={styles.option}>{item}</span>
                })} */}
                {service.options.length > 0 && <span className={styles.option}>{`${service.options.length} ${t("Options")}`}</span>}
                {service.options.length > 0 && <img className={clsx(styles.info, (pickup_dropoff_details && String(pickup_dropoff_details.type) == String("ServiceOptions")) && styles.infoActive)} src={info} onClick={() => { dropOffInfo() }} />}

            </div>
        </div>


    </div>

};




