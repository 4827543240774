import scootyIGO from "../../../assets/scooty.png";
import FavIcon from "../../../assets/PROJECTS/FavIcon_scooty.png";



export default function Scooty() {
    
    return global.config = {
    "ProjectName": "scooty ",
    "LogoType": scootyIGO,
    "BaseApi": "https://rest.scooty.gr",
    "BaseSocket": "https://socket.scooty.gr",
    "BaseTrack": "https://panel.scooty.gr",
    "MapCenter": {
        "lat": 38.24813812705293,
        "lng": 21.734104487679087
    },
    "Lang": "en",
    "Direction": "ltr",
    "AllLanguages": [{
        "lang": "en",
        "direction": "ltr",
        "i18Lang": "English"
    },{
        "lang": "el",
        "direction": "ltr",
        "i18Lang": "Greek"
    },{
        "lang": "fr",
        "direction": "ltr",
        "i18Lang": "French"
    },
    {
        "lang": "de",
        "direction": "Ltr",
        "i18Lang": "germany",
    },{
        "lang": "es",
        "direction": "ltr",
        "i18Lang": "spanish",
    }, {
        "lang": "nl",
        "direction": "ltr",
        "i18Lang": "Dutch"
    }],
    "FavIcon": FavIcon,
    "Country": "CY",
    "thirdPartyLoginSupport": true  ,
    mapType :"osm", /* osm | google */ 
    googleApiKey : ""
}}