import logoType from "../../../assets/floti.png";
import FavIcon from "../../../assets/PROJECTS/floti_fav_icon.png";

export default function Floti() {
    
    return global.config = {
    "ProjectName": "floti",
    "LogoType": logoType,
    "BaseApi": "https://rest.floti.io",
    "BaseSocket": "https://socket.floti.io",
    "BaseTrack": "https://panel.floti.io",
    "MapCenter": {
        "lat": "13.695397579624686",
        "lng": "-89.21113187723346"
    },
    "Lang": "es",
    "Direction": "ltr",
    "AllLanguages": [
        {
            "lang": "es",
            "direction": "ltr",
            "i18Lang": "spanish",
        },
        {
        "lang": "en",
        "direction": "ltr",
        "i18Lang": "English"
    }],
    "FavIcon": FavIcon,
    "Country": "SV",
    "thirdPartyLoginSupport": true  ,
    mapType :"osm", /* osm | google */ 
    googleApiKey : ""
}
}
