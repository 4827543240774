import React, { useCallback, useEffect, useRef, useState } from 'react'
import styles from './style.module.css';
import styles2 from '../../../table-list/style.module.css';

import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../../../../design-system/lib/Modal';
import Typography from '../../../../../design-system/lib/Typography';
import DropDown from '../../../../../design-system/lib/DropDown';
import { ADD_SQUARE, EDIT, Edit, ROUTE_OPTIMIZE, Routes_deactive, Status, QUESTION } from '../../../../../assets';
import Button from '../../../../../design-system/lib/Button';
import { RouteApi } from '../../../../../service/API/route';
import { OrderApi } from '../../../../../service/API/order';
import { get_id } from '../../../../../helper/UserData/userdate';
import { snack } from '../../../../../utils/SnackbarUtilsConfigurator';
import TableAddRoute from '../addRoute';
import clsx from 'clsx';
import { Table_ClearSelectedOrders } from '../../../../../Redux/actions/actionNames';
import { DispatcherApi } from '../../../../../service/API/dispatcher';
import item from '../../../../../Entries/Main/components/ViewOrder/components/event_TimeLine/listEvent/item';
import { removeNull } from '../../../../main/driver-list';
import Switch from '../../../../../design-system/lib/Switch';
import { LearnMore } from '../../../../learnMore';



const statusWithSource = [{ status: "PickupFailed", type: "PickupFailure", source: null },
{ status: "NotDelivered", type: "DeliveryFailure", source: null },
{ status: "CustomerCanceled", type: "Cancellation", source: "customer" }

]


function TableEditStatusSelectedOrders(props) {
  const selectedOrders = useSelector((state) => state.Table.selectedOrders)
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedRoute, setSelectedRoute] = useState(null);
  const [routesList, setRoutesList] = useState([]);
  const [openEditStatus, setOpenEditStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showAddRoute, setShowAddRoute] = useState(false);
  const [failureReasonsList, setFailureReasonsList] = useState([]);
  const [selectedFailureReason, setSelectedFailureReason] = useState(null);
  const [tabs, setTabs] = useState(0);

  const dispatch = useDispatch();


  const handleEditStatus = React.useCallback(async () => {

    if (!openEditStatus) {
      setRoutesList([])
      setSelectedRoute(null)
      setSelectedStatus(null)
      setOpenEditStatus(!openEditStatus)

    } else {
      setOpenEditStatus(!openEditStatus)
    }

    let Route = new RouteApi()
    let data = await Route.getRoutesList()
    setRoutesList(data)

  }, [openEditStatus]);



  function handleAddRote(e, route) {
    setShowAddRoute(e)

    if (route) {
      setSelectedRoute(route)
    }
  }




  const getRouteList = React.useCallback(async () => {

    let Route = new RouteApi()
    let data = await Route.getRoutesList()
    setRoutesList(data)

  }, [showAddRoute]);


  const canSelectFaildReason = React.useCallback(() => {

    let index = statusWithSource.map((item) => { return item.status }).indexOf(selectedStatus?.title)
    if (index > -1) {
      return true
    } else {

      return false
    }

  }, [selectedStatus]);


  useEffect(() => {

    (async () => {

      let index = statusWithSource.map((item) => { return item.status }).indexOf(selectedStatus?.title)

      if (index > -1) {
        setFailureReasonsList([])
        let Dispatcher = new DispatcherApi()
        let dataP = await Dispatcher.FailureReason(removeNull({ type: statusWithSource[index].type, source: statusWithSource[index].source }))
        if (dataP) {
          setFailureReasonsList(dataP)

        }
      } else {
        setFailureReasonsList([])


      }
    })();



  }, [selectedStatus])



  const handleSave = React.useCallback(async () => {
    setLoading(true)
    let Order = new OrderApi()
    try {
      let data = await Order.BatchUpdateStatus({
        "ids": Object.keys(selectedOrders),
        "status": selectedStatus.title,
        "dispatcherId": get_id(),
        "routeId": (["PickupFailed" ,"NotDelivered" ].indexOf(selectedStatus.title) > -1) ? (tabs == 0 ? undefined : (tabs == 1 ? selectedRoute?.id : null )) : selectedRoute?.id,
        ...(selectedFailureReason && { failureReasonId: String(selectedFailureReason.id), failureReasonText: selectedFailureReason.text })
      })


      if (data) {
        setLoading(false)
        setOpenEditStatus(false)
        snack.BatchOperationalSnackbar(JSON.stringify({
          "id": data.id,
          "status": data.status,
          "action": data.action,
          "totalRecordsCount": data.totalRecordsCount,
          "successfulRecordsCount": data.successfulRecordsCount,
          "failedRecordsCount": data.failedRecordsCount,
          "lastProcessedRecordIndex": data.lastProcessedRecordIndex,
          "records": data.records,
          "createdAt": data.createdAt,
          "doneAt": data.doneAt,
        }))
        dispatch(Table_ClearSelectedOrders())

      } else {
        setLoading(false)
      }

    } catch (error) {

      snack.error_i18("tryagain")
      setLoading(false)

    }



  }, [selectedStatus, selectedRoute, selectedOrders, selectedFailureReason]);


  const hasDriverAssignError = React.useCallback(() => {

    if (selectedStatus && selectedStatus.route && selectedRoute) {
      if (selectedStatus.driver && !selectedRoute.driver) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }


  }, [selectedStatus, selectedRoute]);

  const hasRouteAssignError = React.useCallback(() => {


    if (selectedStatus && selectedStatus.route && selectedRoute) {
      if (!selectedStatus.driver && !!selectedRoute.driver) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }

  }, [selectedStatus, selectedRoute]);

  // useEffect(async () => {
  //   let Route = new RouteApi()
  //   let data = await Route.getRoutesList()
  //   setRoutesList(data)
  // }, [])

  // useEffect(async () => {

  // }, [openEditStatus])

  useEffect(() => {
    (async () => {
      let Route = new RouteApi()
      let data = await Route.getRoutesList()
      setRoutesList(data)
    })();
  }, [showAddRoute]);


  console.log("dsdsfsfsds ", (!selectedStatus || (selectedStatus.route ? selectedRoute == null : false)), (hasDriverAssignError() || hasRouteAssignError()), (canSelectFaildReason() ? !selectedFailureReason : false))

  return (
    <>
      <Button size='Medium' type='Secondary' text='Edit Status' icon={Edit} onClick={() => { handleEditStatus() }} />
      {(openEditStatus && !showAddRoute) && <Modal HeaderExtraBtn={<LearnMore  hastext={false} placement={"bottomEnd"} where='PickupDeliveryEditStatus' />} ConfirmationLoading={loading} ConfirmationDisabled={((!selectedStatus || (selectedStatus?.route ? selectedRoute == null : false)) || (hasDriverAssignError() || hasRouteAssignError()) || (canSelectFaildReason() ? !selectedFailureReason : false) || ( selectedStatus ? (["PickupFailed" ,"NotDelivered" ].indexOf(selectedStatus.title) > -1 && tabs == 1 && !selectedRoute) : false))} size='Large' title='Edit Status' ConfirmationText='Save' onclose={() => { handleEditStatus() }} ConfirmationOnClick={() => { handleSave() }}

        body={<div className={styles.bodyContainer}>
          <div className={styles.firstRow}>
            <span className={styles.P_Hub_D}> <Typography weight='Body_Small_Bold' textColor='TextDark' text='P_Hub_D' /> </span>
            <GetGroups selectedOrders={selectedOrders} />
          </div>

          <div className={styles.divider} />

          <DropDown placement='bottomStart' icon={Status} size='Medium' data={GetStatusList()} text={selectedStatus ? selectedStatus.title : 'Select Status'} onClick={item => {
            setSelectedStatus(item)
            setSelectedFailureReason(null)
            setFailureReasonsList([])

          }} className={styles.DropDown} checkSelected='full' />

          {(canSelectFaildReason() && failureReasonsList.length > 0) && <DropDown style={{ marginTop: "12px" }} placement='bottomStart' icon={QUESTION} size='Medium' data={failureReasonsList.map((item) => { return { ...item, title: item.text } })} text={selectedFailureReason ? selectedFailureReason.title : 'Select Reason'} onClick={item => {
            setSelectedFailureReason(item)
          }} className={styles.DropDown} checkSelected='full' />}

          {(selectedStatus && selectedStatus.route) && <><div className={styles.divider} />

            <div className={styles.firstRow}>
              <DropDown placement='bottomStart' icon={ROUTE_OPTIMIZE} size='Medium' data={GetStatusList()} text={selectedRoute ? selectedRoute.name : 'Select Route'} onClick={item => {
                setSelectedStatus(item)
              }} className={styles.RouteDropDown} checkSelected='full' ItemsList={ItemsList(routesList, selectedRoute, setSelectedRoute)} />
              {/* <Button size='Medium' type='Secondary' icon={EDIT} style={{ marginInlineStart: "10px", paddingTop: "9px", paddingBottom: "9px" }} onClick={() => { }} /> */}
              <Button size='Medium' type='Secondary' icon={ADD_SQUARE} text='Add Route' style={{ marginInlineStart: "10px", paddingTop: "9px", paddingBottom: "9px" }} onClick={() => { setShowAddRoute(true) }} />
            </div></>}

            {(selectedStatus && selectedStatus.tabs) && <>
              <div className={styles.divider} />
              <Switch items={["Don’t change routes","Edit routes","Remove routes"]} index={tabs} handleSelection={(i)=>{setTabs(i)}} />
              { tabs == 1 &&
                <div className={styles.tabRouteContainer}>
                <DropDown style={{flex:"1"}} placement='bottomStart' icon={ROUTE_OPTIMIZE} size='Medium' data={GetStatusList()} text={selectedRoute ? selectedRoute.name : 'Select Route'} onClick={item => {
                setSelectedStatus(item)
              }} className={styles.RouteDropDown} checkSelected='full' ItemsList={ItemsList(routesList, selectedRoute, setSelectedRoute)} />
              {/* <Button size='Medium' type='Secondary' icon={EDIT} style={{ marginInlineStart: "10px", paddingTop: "9px", paddingBottom: "9px" }} onClick={() => { }} /> */}
              <Button size='Medium' type='Secondary' icon={ADD_SQUARE} text='Add Route' style={{ marginInlineStart: "10px", paddingTop: "9px", paddingBottom: "9px" }} onClick={() => { setShowAddRoute(true) }} />
                </div>
              }
      </>}
          {(hasDriverAssignError() || hasRouteAssignError()) && <Typography weight='Body_Middle_Regular' textColor='Red' text={hasDriverAssignError() ? 'You must assign driver to select this route' : 'You must choose an unassigned route or unassign selected route'} className={styles.error} />}
        </div>} />}




      {showAddRoute && <TableAddRoute onClose={handleAddRote} />}

    </>

  )
}


export default TableEditStatusSelectedOrders




function ItemsList(data, selectedRoute, setSelectedRoute) {
  return (
    <div className={styles.itemsConatiner}>
      {data.map((route) => {
        return <div onClick={() => { setSelectedRoute(route) }}>

          <img src={Routes_deactive} />
          <Typography weight={selectedRoute?.id == route.id ? "Body_Middle_Bold" : 'Body_Middle_Regular'} textColor='TextNormal' text={route.name} className={styles.itemName} />
          <Typography weight='Body_Small_Regular' textColor='TextLight' text={route.driver ? route.driver?.name : "Unassigned"} className={styles.itemDriverName} />

        </div>
      })}
    </div>
  )
}


function GetGroups({ selectedOrders }) {

  var objectToArray = Object.keys(selectedOrders).map((key) => selectedOrders[key]);

  const result = Object.groupBy(objectToArray, ({ status }) => status);


  return (
    <div className={styles.groupStatus}>

      {Object.keys(result).map((group) => {
        return <div className={clsx(styles.groupStatusdiv, styles2[group])}>
          <Typography weight='Body_Small_Medium' textColor='TextDark' text={group} />
          <span className={styles.groupStatusspan}>
            <Typography weight='Body_Tiny_Bold' textColor='TextDark' text={result[group].length} />
          </span>
        </div>
      })}


    </div>
  )
}


export const GetStatusList = () => [
  {
    title: "Draft",
    stage: "P",
    driver: false,
    route: false
  },
  {
    title: "Confirmed",
    stage: "P",
    driver: false,
    route: false
  },
  {
    title: "PickupRouted",
    stage: "P",
    driver: false,
    route: true
  },
  {
    title: "ReadyForPickup",
    stage: "P",
    driver: true,
    route: true
  },
  {
    title: "PickedUp",
    stage: "P",
    driver: true,
    route: true
  },
  {
    title: "AtWarehouse",
    stage: "D",
    driver: false,
    route: false
  },
  {
    title: "DeliveryRouted",
    stage: "D",
    driver: false,
    route: true
  },
  {
    title: "ReadyForDelivery",
    stage: "D",
    driver: true,
    route: true
  },
  {
    title: "OutForDelivery",
    stage: "D",
    driver: true,
    route: true
  },
  {
    title: "Delivered",
    stage: "D",
    driver: true,
    route: true
  },
  {
    title: "NotDelivered",
    stage: "D",
    driver: false,
    route: false,
    tabs: true
  },
  {
    title: "ForReturn",
    stage: "D",
    driver: false,
    route: false
  },
  {
    title: "Returned",
    stage: "D",
    driver: false,
    route: false
  },
  {
    title: "CustomerCanceled",
    stage: null,
    driver: false,
    route: false
  },
  {
    title: "PickupFailed",
    stage: "P",
    driver: false,
    route: false,
    tabs: true
  },
  {
    title: "SupportCanceled",
    stage: null,
    driver: false,
    route: false
  }, {
    title: "Lost",
    stage: null,
    driver: false,
    route: false
  },
]