import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';


export function useSearchStatus() {
  const [listNoResult, setListNoResult] = useState(true);
  const [searchNoResult, setSearchNoResult] = useState(true);
  const [listLoading, setListLoading] = useState(false);

  const stats = useSelector((state) => state.OrderList.stats.data)
  const categories = useSelector((state) => state.OrderList.categories)

  const regexText = useSelector((state) => state.OrderList.search.regexText)
  const PinnedData = useSelector((state) => state.OrderList.list["Pinned"].dataLength)
  const DelayedData = useSelector((state) => state.OrderList.list["Delayed"].dataLength)
  const PendingData = useSelector((state) => state.OrderList.list["Pending"].dataLength)
  const AssignedData = useSelector((state) => state.OrderList.list["Assigned"].dataLength)
  const InProgressData = useSelector((state) => state.OrderList.list["InProgress"].dataLength)
  const CollectingData = useSelector((state) => state.OrderList.list["Collecting"].dataLength)
  const DeliveringData = useSelector((state) => state.OrderList.list["Delivering"].dataLength)
  const UnassignedData = useSelector((state) => state.OrderList.list["Unassigned"].dataLength)
  const DispatchLaterData = useSelector((state) => state.OrderList.list["DispatchLater"].dataLength)
  const DispatchingData = useSelector((state) => state.OrderList.list["Dispatching"].dataLength)
  const CanceledData = useSelector((state) => state.OrderList.list["Canceled"].dataLength)
  const DoneData = useSelector((state) => state.OrderList.list["Done"].dataLength)


  // let CanceledData = stats["Canceled"]
  // let DoneData = stats["Done"]

  const Pinnedloading = useSelector((state) => state.OrderList.list["Pinned"].loading)
  const Delayedloading = useSelector((state) => state.OrderList.list["Delayed"].loading)
  const Pendingloading = useSelector((state) => state.OrderList.list["Pending"].loading)
  const Assignedloading = useSelector((state) => state.OrderList.list["Assigned"].loading)
  const InProgressloading = useSelector((state) => state.OrderList.list["InProgress"].loading)
  const Collectingloading = useSelector((state) => state.OrderList.list["Collecting"].loading)
  const Deliveringloading = useSelector((state) => state.OrderList.list["Delivering"].loading)
  const Canceledloading = useSelector((state) => state.OrderList.list["Canceled"].loading)
  const Doneloading = useSelector((state) => state.OrderList.list["Done"].loading)
  const Unassignedloading = useSelector((state) => state.OrderList.list["Unassigned"].loading)
  const DispatchLaterloading = useSelector((state) => state.OrderList.list["DispatchLater"].loading)
  const Dispatchingloading = useSelector((state) => state.OrderList.list["Dispatching"].loading)

  const loading = useSelector((state) => state.OrderList.search.loading)
  const searchData = useSelector((state) => state.OrderList.search.data)

  function checkList(){
    if (Number(Number(PinnedData) + Number(DelayedData) + Number(PendingData) + Number(AssignedData) + Number(InProgressData)+ Number(CanceledData)  +
    Number(CollectingData) + Number(DeliveringData) + Number(UnassignedData)+ Number(DispatchLaterData) + Number(DispatchingData) + Number(DoneData) ) == 0) {
      setListNoResult(true)
  } else {
    setListNoResult(false)
  }
  if (Pinnedloading || Delayedloading || Pendingloading || Assignedloading || InProgressloading || Collectingloading ||
     Deliveringloading   || Dispatchingloading || DispatchLaterloading || Unassignedloading  || Canceledloading || Doneloading ) {
    setListLoading(true)
  } else {
    setListLoading(false)
  }
  }

  useEffect(() => {
    
    if(stats && ( categories.indexOf("Canceled") > -1 || categories.indexOf("Done") > -1) ){

      if(stats["Canceled"] > 0 || stats["Done"] > 0 ){
        setListNoResult(false)
        setListLoading(false)
      }else{
        checkList()
      }

    }else{
      checkList()
    }

    setSearchNoResult(!searchData.length > 0)


  }, [PinnedData, DelayedData, PendingData, AssignedData, InProgressData, CollectingData, DeliveringData,  CanceledData, DoneData  , UnassignedData , DispatchLaterData ,DispatchingData ,  regexText , loading ,searchData  ,
    Pinnedloading, Delayedloading, Pendingloading, Assignedloading, InProgressloading, Collectingloading, Deliveringloading, Canceledloading, Doneloading , Unassignedloading,DispatchLaterloading , Dispatchingloading  , stats
  ]);

  return { regexText, listNoResult , searchNoResult  ,loading  ,listLoading};
};



