export const DutchLabel = {
  "Enter_the_following_information_to_enter": "Vul de volgende informatie in, om verder te gaan",
  "login": "Login",
  "Username": "Gebruikersnaam",
  "password": "Wachtwoord",
  "Orders": "Verzendingen",
  "Online": "Online",
  "Offline": "Offline",
  "Info": "Info",
  "email": "Email",
  "vehicletypes": "Service types",
  "loginstatus": "Login status",
  "lastonlinetime": "Laatst online",
  "fetchingdata": "Informatie verwerken",
  "poperLoading": "Momentje",
  "Noinformationfound": "Geen informatie beschikbaar",
  "Drivers": "Koeriers",
  "otherdropoffs": "Andere afleveringen",
  "paymentmethod": "Betaalmethode",
  "Receiver": "Door ontvanger",
  "Sender": "Door verzender",
  "Balance": "Tegoed",
  "Cash": "Cash",
  "Wallet": "Balans",
  "Close": "Sluiten",
  "noVehicleType": "Geen service beschikbaar",
  "Fname": "Voornaam",
  "Lname": "Achternaam",
  "Pnumber": "Telefoonnummer",
  "driver": "Koerier",
  "Pleasetryanotherkeyword": "Probeer een ander zoekwoord",
  "DropOff": "Afleveradres",
  "Noaddressavailable": "Geen adres beschikbaar",
  "Managefavoriteaddresses": "Adressen bewerken",
  "address": "Adres",
  "Saved": "Opgeslagen",
  "block": "Blokkeren",
  "Recieverfullname": "Naam ontvanger",
  "Recieverphonenumber": "Telefoonnummer ontvanger",
  "notesfordriver": "Informatie voor de koerier",
  "Price": "Tarief",
  "EditOrder": "Wijzig verzending",
  "senderfullname": "Naam verzender",
  "senderphonenumber": "Telefoonnummer verzender",
  "Service": "Service",
  "Passenger": "Klant",
  "Fee": "Vergoeding",
  "Fornow": "Direct",
  now:"Direct",
  "Scheduled": "Ingepland",
  "before": "Voor:",
  "after": "Na:",
  "confirm": "Bevestig",
  "Cancel": "Annuleer",
  "Addresses": "Adressen",
  "Savedaddresses": "Opgeslagen adressen",
  "Editaddress": "Adres wijzigen",
  "Saveaddress": "Wijzig adres",
  "en": "Engels",
  "fa": "Perzisch",
  "English": "Engels",
  "Persian": "Perzisch",
  "Logout": "Uitloggen",
  "logotTitle": "Uitloggen van account?",
  "logoutDescription": "Je wordt uitgelogd en dient later opnieuw in te loggen",
  "confirmLogout": "Ja, log uit",
  "skipLogout": "annuleer bestelling",
  "Ascending": "Laag naar hoog",
  "Descending": "Hoog naar laag",
  "sort[createdAt]": "Datum",
  "sort[pickupScheduleDate]": "Geplande ophaaltijd",
  "sort[statusUpdatedAt]": "Sorteer op laatst bijgewerkt",
  "SelectDateRange": "Periode",
  "StartDate": "Startdatum",
  "EndDate": "Einddatum",
  "Today": "Vandaag",
  "Yesterday": "Gisteren",
  "ThisWeek": "Deze week",
  "LastWeek": "Vorige week",
  "Last7Days": "Afgelopen 7 dagen",
  "ThisMonth": "Deze maand",
  "LastMonth": "Vorige maand",
  Last3Month:"Laatste 3 maanden",
  "monitoring": "Overzicht",
  "Haveorder": "Verzending",
  "Estimated": "Geschat",
  ReceiverCash: "Betalen door ontvanger",
  "SenderCash": "Cash",
  "Auto": "Auto",
  "Manual": "Handmatig",
  "Person": "Persoon",
  "Business": "Bedrijf",
  "Businessname": "Bedrijfsnaam",
  "Adddropoff": "Voeg afleveradres toe",
  "Searchbynameorphonenumber": "Zoek op naam of telefoonnummer",
  "Fullname": "Volledige naam",
  "type": "Type",
  "only": "Alleen xxx",
  "ChooseDriver": "Kies Koerier",
  "AllKey": "Alle",
  "MeKey": "Ik",
  "Vehicletype": "Vervoerstype",
  "status": "Status",
  "statusCanceled": "Geannuleerd",
  "statusPending": "In behandeling",
  "statusUnassigned": "Niet toegewezen",
  "dispatcher": "Centrale",
  "customer": "Klant",
  "regexcode": "Code",
  "regexcustomerPhone": "Geannuleerd door klant",
  "regexcustomerName": "Klantnaam ",
  "regexdriverPhone": "Telefoonnummer koerier",
  "regexdriverName": "Naam Koerier",
  "regexdriverUsername": "Gebruikersnaam Koerier",
  "pickupDescription": "Omschrijving pickup",
  "filter": "Filter",
  "clear": "Verwijderen",
  "orderInfoError": "Fout bij verwerken informatie",
  "History": "Geschiedenis",
  "Phone": "Telefoonnummer",
  "km": "KM",
  "Espath": "Geschatte route",
  "Receipt": "Bon",
  "Copylink": "Kopieer link",
  "repeatOrder": "Herhaal verzending",
  "EditPrice": "Wijzig Tarief",
  "Endtrip": "Beëindig verzending",
  "Canceltravel": "Annuleer verzending",
  "CancellText": "Na annulatie wordt de verzending verwijderd bij de koerier",
  "confirmCancel": "Ja",
  "Endtravel": "Afronden",
  "EndlText": "Bij afronding, wordt de verzending als succesvol aangemerkt voor klant en koerier en het bedrag in rekening gebracht bij de partner",
  "confirmEnd": "Bevestig",
  "End": "Afronden",
  "Deliveredat": "Geleverd op",
  "Nonotes": "Geen notities",
  "Photo": "Foto",
  "Signature": "Handtekening",
  "Nosignature": "Geen handtekening",
  "Nophoto": "Geen foto",
  "ServiceOptions": "Verschillende opties",
  "Events": "Gebeurtenissen",
  "status2": "Status",
  "Creator": "Aangemaakt door",
  "Creationdate": "Datum aangemaakt",
  "Orderid": "Order id",
  "Pickup2": "Ophalen",
  "Dropoff2": "Afleveren",
  "PleaseSelect": "Selecteer",
  "CreateEvent": "Maak event aan",
  "Description": "Omschrijving",
  "detailsAboutEvent": "Details",
  "CustomerVisibility": "Zichtbaarheid klant",
  "LocationOnMap": "Locatie op kaart",
  "Pending": "Aan het verwerken",
  "Unassigned": "Niet toegewezen",
  "CustomerCanceled": "Geannuleerd door klant",
  "DriverCanceled": "Geannuleerd door koerier",
  "SupportCanceled": "Geannuleerd door support",
  "Createdsuccessfully": "Aangemaakt",
  "Estime": "Geschatte tijd",
  "Updatedsuccessfully": "Goed bijgewerkt",
  "statusAssigned": "Toegewezen",
  "statusStarted": "Gestart",
  "statusPickedUp": "Opgepikt",
  "statusDriverCanceled": "Geannuleerd door koerier",
  "statusArrived": "Aangekomen",
  "statusSupportCanceled": "Geannuleerd door support",
  "ContactFullname": "Naam contactpersoon",
  "Contactphonenumber": "Telefoonnummer contactpersoon",
  "Commission": "Commissie",
  "surcharge": "Toeslag",
  "haslogined": "Is ingelogd",
  "Notloggedin": "Niet ingelogd",
  "Schedule": "Inplannen",
  "Date": "Datum",
  "Time": "Tijdstip",
  "noDateToShow": "Geen datum om weer te geven",
  "noTimeToShow": "Geen tijd om weer te geven",
  "Apply": "Toepassen",
  "balanceError": "Tegoed moet minstens gelijk zijn aan prijs voor verzending",
  "Addressbook": "Adresboek",
  "AddressbookSearchbar": "Zoek per adres, titel of ID",
  "AddressbookDropOf": "Adresboek (verzendingen)",
  "AddressbookPickup": "Adresboek (ophaaladressen)",
  "hide": "Verbergen",
  "Nosavedaddress": "NIet opgeslagen adres",
  "Romanian": "Roemeens",
  "business": "Bedrijf",
  "username": "Gebruikersnaam",
  "rememberme": "Onthoud mij",
  "SendOrder": "Verzending versturen",
  "code": "Code",
  "noorder": "Geen verzending",
  "pleaseSellectParsel": "Selecteer service",
  "Order": "Verzending",
  "Code": "Code",
  "Editorder": "Wijzig verzending",
  "all": "Alle",
  "Address": "Adres",
  "Block/Building": "Gebouw",
  "Pickedupat": "Opgehaald om",
  "for": "Voor",
  "fr": "Frans",
  "AllVehicletypes": "Alle vervoerstypen",
  "AllServices": "Alle diensten",
  "French": "Frans",
  "enterDataCorrectly": "Vul informatie juist in",
  "controlThatbeforeLoweEqualAfter": "Tijd \"voor :\" kan niet eerder zijn dan \"na :\"",
  "ForceAssign": "Forceer Toewijzing",
  "Allvehicletypes": "Alle voertuigen",
  "waitforconfirm": "xxx ( Wacht op bevestiging )",
  "waitforconfirm2": "Wacht op bevestiging",
  "Dispatching": "Versturen",
  "NoDriver": "Geen Koerier",
  "OtherDetails": "Overige Informatie",
  "StatusandDriver": "Status en Koerier",
  "EditStatusandDriver": "Wijzig Status en Koerier",
  "Current": "Huidige",
  "EDAS_Pending": "Aan het verwerken",
  "EDAS_Assigned": "Toegewezen",
  "EDAS_PickedUp": "Opgehaald",
  "EDAS_Unassigned": "Niet toegewezen",
  "EDAS_Started": "Gestart",
  "EDAS_Done": "Afgerond",
  "EDAS_CustomerCanceled": "Geannuleerd door klant",
  "EDAS_DriverCanceled": "Geannuleerd door koerier",
  "EDAS_SupportCanceled": "Geannuleerd door support",
  "EDAS_Arrived": "Aangekomen",
  "Pleasechooseadrivertochangestatus": "Koerier wijzigen kan effect hebben op het tarief",
  "icWarningEdit": "Type voertuig wordt veranderd",
  "Vehicletypewillbechanged": "Huidig type voertuig wordt veranderd en zal effect op tarief hebben",
  "Yourcurrentvehicletypeanditwillbechangedandeffectsonprice": "Huidig type voertuig wordt veranderd en zal effect op tarief hebben",
  "Rejected": "Afgewezen",
  "Rejected2": "(Afgewezen)",
  "DriverVehicleTypeError": "Er is geen type voertuig voor koerier",
  "CodeNameFamilyPhonenumber": "Code, Naam, Familie, Telefoonnummer",
  "youcanselectdriverintheedit": "Selecteer koerier bij wijzigen Koerier en Status",
  "Earning": "Verdiensten",
  "savePrice": "Sla prijs op",
  "saveEarning": "Sla verdiensten op",
  "Collecting": "Ophalen",
  "Transit": "Sorteercentrum",
  "Delivering": "Wordt bezorgd",
  "EDAS_Collecting": "Wordt opgehaald",
  "EDAS_Transit": "In sorteercentrum",
  "EDAS_Delivering": "Wordt bezorgd",
  "statusCollecting": "Wordt opgehaald",
  "statusTransit": "In sorteercentrum",
  "statusDelivering": "Wordt bezorgd",
  "event_Created": "Aangemaakt",
  "event_Unassigned": "Niet toegewezen",
  "event_Assigned": "Toegewezen",
  "event_Started": "Gestart",
  "event_Arrived": "Aangekomen",
  "event_PickedUp": "Opgehaald",
  "event_OrderDone": "Order afgerond",
  "event_DropoffDone": "Aflevering afgerond",
  "event_CustomerFeedback": "Klant Feedback",
  "event_AddPod": "Voeg POD toe",
  "event_CustomerCanceled": "Geannuleerd door klant",
  "event_DriverCanceled": "Geannuleerd door koerier",
  "event_SupportCanceled": "Geannuleerd door support",
  "event_Updated": "Geupdate",
  "event_DriverAssignment": "Koerier toewijzing",
  "event_DriverReject": "Weiger Koerier",
  "event_Collecting": "Ophalen",
  "event_InTransit": "In sorteercentrum",
  "event_Delivering": "Wordt afgeleverd",
  "event": "Update",
  "tab_Delivery": "Aflevering afgerond",
  "tab_Ondemand": "On Demand",
  "tab_Pickup": "Ophalen",
  "tab_PickupDelivery": "Bestelling Ophalen",
  "CurrentStatus": "Huidige status",
  "Changestatus": "Wijzig status",
  "Newstatus": "Nieuwe Status",
  "Savechanges": "Sla wijzigingen op",
  "Other": "Ander",
  "Shipmentlabel": "Zendingslabel" ,



  Total:"Total",
  Pricedetails:"Price details",
  "EditPickupDropOff": "Edit Addresses",
  noMoreData:"There is no more information",
  Dutch:"Dutch",
  Ondemand:"Op aanvraag",
  PD:"p &D",
  PD2:"P & D",
  PickupDropoff:"Haal op en laat achter",
  PickupFlag:"p",
  DropOffFlag:"D",
  PickupandDelivery:"Pick-up en levering",
  PickupDelivery:"Pick-up en levering",
  Delivery:"Levering" ,
  serviceCustomerError1 :"xxx is niet beschikbaar voor deze klant." ,
  serviceCustomerError2 :"Hij / zij kan alleen xxx -service kiezen" ,
  serviceCustomerError3 :"Hij / zij kan alleen XXX kiezen",
  OrderCode:"Bestelcode" ,

  CopyOrderlink:"Kopiëer link bestelling" ,
  CopyPickuplink:"Kopieer link ophalen" ,
  CopyDeliverylink:"Kopieer link afleveren"  ,
  Bulkimport:"Bulkimport",
  Downloadsamplecsv:"Download voorbeeld CSV",
  processing:"Verwerken" ,
    done :"Afgerond" ,
    failed:"Mislukt",
    Draft:"Droogte" ,
    Uploading:"uploading" ,
    Draganddropyourfile:"Sleep uw bestand",
    Onlycsvformatissupported:"Alleen .csv-indeling wordt ondersteund" ,
    ORyoucan:"Of je kan",
    Openfile:"Open bestand" ,
    Noimportsyet:"Nog geen import" ,
    NoimportsyetDesc :"U kunt de lijst met uw import hier bekijken en hun status bekijken",
    nosupportdialogeTitle:"Bestands formaat niet ondersteund",
    nosupportdialogeText : "xxx-bestand wordt niet ondersteund.U kunt alleen .csv-bestand uploaden inclusief orderinformatie" ,
    ok:"Oke" ,
    Import  :"Importeren",
    previewTitle :"Voorbeeld en kies Opties",
    previewDesc:"Dit is het eerste item van uw bestanden om te controleren of uw info in de juiste structuur is" ,
    Upload:"Uploaden", 
    Remove:"Verwijderen" ,
    ServiceandOrdertype:"Service en besteltype" ,
    Fileisproccessing:"Bestand is verwerkt ...",
    "proccessingDesc":"U kunt opfrissen om te zien of het resultaat gereed is",
    serverError:"Serverfout",
    isRequired:"xxx is vereist" ,
    Result:"Resultaat",
    ResultText:"Je kunt de problemen hier zien en deze rijen downloaden om apart te herstellen",
    ErrorDetails:"Bestellingen met fout",
    ErrorDetailsText:"Bestellingen met fouten worden vermeld in het bestand dat u hieronder kunt downloaden.Het bevat foutgegevens voor elke rij.",
    DownloadErroredRows:"Download Fouted Rows" ,
    importedsuccessfully:"xxx-bestellingen worden met succes geïmporteerd" ,
    ordershaserrors:"xxx-bestellingen hebben fouten",
    refresh:"Vernieuwen",
    CashOnDelivery:"Cash bij levering",
    EnterAmount:"Voer een bedrag in",
    COD:"Cash bij levering",
    Surchargexxx:"Toeslag xxx",
    AddCreditCard:"Voeg Credit / Debit Card toe",
    CustomerWallet:"Klantportefeuille",
    Add:"Toevoegen",
    Addedsuccessfully: "Succesvol toegevoegd",
    NoRecentAddress:"Geen recent adres",
    NoRecentAddressD:"Klant Recente adressen worden hier vermeld",
    CustomerIsNotChoosed:"Klant wordt niet gekozen",
    CustomerIsNotChoosedD:"Kies de klant om zijn/haar recente adres te tonen",
    SearchAddress:"Zoekadres",
    NoCustomerfound: "Geen klant gevonden" ,
    NoCustomerfoundD:"U kunt de klant maken als deze een nieuwe is",
    NewCustomer : "Nieuwe klant",
    Existing:"Bestaand",
    EmailOtional:"Email ( optioneel )" ,
    phonenumberisnotcorrect:"Het telefoonnummer is niet correct",
    Emailisnotcorrect:"E -mail is niet correct" ,
    Registercustomer:"Register customer",
    incoming:"Binnenkomend telefoongesprek",
    outgoing:"uitgaande oproep",
    recording:"opname -oproep",
    driveroperatorCallStatus:"Bestuurder genaamd Operator",
    customeroperatorCallStatus:"Klant genaamd Operator",
    newCustomeroperatorCallStatus:"Nieuwe klant genaamd Operator",
    operatordriverCallStatus:"Operator genaamd Driver",
    operatorcustomerCallStatus:"Operator belde klant",
    operatornewCustomerCallStatus:"Operator genaamd nieuwe klant",
    dispatcher_Voip:"operator",
    driver_Voip:"Bestuurder",
    customer_Voip:"Klant",
    activeorders:"xxx actieve bestellingen",
    Noactiveorder :"Geen actieve volgorde " ,
    CreateOrder:"Bestelling maken",
    call:"Telefoongesprek",
    spanish:"Spaans",
    es : "Spaans",
    networkError:"Geen internet verbinding",
    Noresultfound:"geen resultaat gevonden",
    loadMore:"Meer laden",
    Searchresult:"Zoekresultaat",
    "PinTop":"Speldop",
    "UnPin":"Een dennen",
    "Itemsonmap":"Items op kaart",
    "Orders":"Bevelen",
    "Drivers":"Bestuurders",
    "min":"xxx Min" ,
    Confirming:"Bevestigend",
    delay:"Vertraging",
    late:"Laat",
    OrderDetails:"Bestel Details",
    Profile:"Profiel",
    Waitingforavailabledrivers:"Wachten op stuurprogramma's die beschikbaar zijn",
    Queue:"Rij",
    OfferingToDrivers:"Aanbod aan xxx -stuurprogramma's",
    WaitforAccept:"Wacht op accepteren" ,




    pleaseSellectService: 'Selecteer het servicetype',
    Floor: 'Vloer',
    Unit: 'Eenheid',
    Firstname: 'Voornaam',
    Lastname: 'Achternaam',
    Phonenumber: 'Telefoonnummer',
    Search: 'Zoeken',
    Pickup: 'Oppakken',
    Settime: 'Tijd instellen',
    new: 'Nieuw',
    Language: 'Taal',
    ascending: 'Oplopende',
    descending: 'Aflopend',
    SenderWallet: 'Portemonnee',
    SendOrderr: 'Bestelling verzenden',
    Neworder: 'Nieuwe opdracht',
    phonenumber: 'Telefoonnummer',
    statusInProgress: 'Bezig',
    statusDone: 'Gedaan',
    Pickupnote: 'Pick -up Note',
    Details: 'details',
    Edit: 'Bewerk',
    EditPrice2: 'Prijs',
    EditEarning: 'Verdienen',
    pod: 'Bewijs van levering',
    Notes: 'Aantekeningen',
    Assigned: 'Bezig',
    PickedUp: 'Bezig',
    Started: 'Bezig',
    Done: 'Gedaan',
    Arrived: 'Bezig',
    Promocode: 'Promotiecode',
    statusCustomerCanceled: 'Customer Canceled',
    Title: 'Titel',
    lowBalance: 'lage balans',
    Methods: 'Methoden',
    todayat: 'Vandaag bij',
    tomorrowat: 'Morgen om',
    nextWeekat: 'Volgende week om',
    lastDayat: 'Laatste dag om',
    lastWeekat: 'Vorige week om',
    createdAt: 'Gemaakt bij',
    dueDate: 'Opleveringsdatum',
    "AssignedAt":"Toewijzen in xxx min",
    "StartedAt":"Begin in xxx min",
    "ArrivedAt":"Aankomen in xxx min",
    "PickedUpAt":"Ophalen in xxx min",
    "DoneAt":"Eindigen in xxx min",
    mindelay: 'xxxm vertraging',
    minlate: 'xxxm laat',
    Thereisnoorder:"Er is geen bestelling",
    mapitems:"Kaartitems",
    Offerings:"Aanbod",
    Reject:"Afwijzen",
    dropoffCustomerDescription:"Aflever noot",
    customerPhoto:"Klantfoto" ,
    driverPhoto:"Driverfoto" ,
    NocustomerPhoto:"Geen klantfoto" ,
    NodriverPhoto:"Geen stuurprogramma -foto",
    initial:"voorletter",
    notifying:"aanmelding",
    received:"ontvangen",
    assigned:"toegewezen",
    rejected:"afgekeurd",
    canceled:"geannuleerd",
    standby:"stand -by",
    terminated:"beëindigd",
    urgentTitle:"Wijzigen in dringende verzending?",
    urgentDesc:"Door dringend in te schakelen, zal de bestelling in de eerste prioriteit zijn voor verzending",
    urgentConfirm:"Ja, maak dringend in",
    urgentClose:"Nee, annuleren",
    Urgent:"Dringend",
    pop:"Proof of pickup",
    photos :"Foto's",
    Portuguese:"Portugees",
    Dispatchat:"Verzending naar xxx",
    AutoDispatch:"Auto -verzending" ,
    Manual:"Handleiding",
    Dispatchtime:"Verzendtijd" ,
    Later:"later",
    DispatchingNow:"Nu verzending",
    Required:"Vereist" ,

    NoResultfromLocalDatabase:"Geen resultaat van de lokale database",
    NoResultfromLocalDatabaseD:"Controleer het opnieuw of probeer een ander trefwoord",
    NoResultfromMapService:"Geen resultaat van kaartservice",
    SearchinMapService:"Zoek in kaartservice" ,
    SearchinLocalDatabase:"Zoek in de lokale database",
    Creditcardnotadded:"Creditcard niet toegevoegd",
    OptimizeDropoffs:"Optimaliseer dropoffs",
    "Greek":"Grieks",
    "el":"Grieks",
    "ReferenceID":"referentie nummer",
    "EnterId":"Voer ID in",
    "de":"Duits",
    "German":"Duits",
    "AustrianGerman":"Oostenrijks Duits",
    "Options":"Opties",
    "VehicleOptions":"Voertuigopties",
    "EditCustomer":"Bewerk de klant",
    "NoDriverfound":"Geen chauffeur gevonden",
    "NoDriverfoundDesc":"Controleer uw info en probeer iets anders",
    "ClearField":"Leeg veld",
    "Map":"Kaart",
    "Local":"Lokaal",
    "CompleteRequiredFeileds":"Voltooi vereiste velden",
    "Senderinfo":"Afzenderinformatie",
    "SchedulePickup(Optional)":"Plan ophalen (optioneel)",
    "ScheduleDelivery(Optional)": "Schedule Delivery (Optional)",

    "AddDropoff":"Voeg dropoff toe",
    "EditPrice":"Prijs bewerken",
    "Promotion":"Promotie",
    "tax":"Belasting",
    "surcharge":"Toeslag xxx",
    "Receiverinfo":"Ontvanger info",
    "tryagain":"Something went wrong, please try again",



    "Selected date and time":"Selected date and time",
    "Reorder stops":"Reorder stops",
    "Bulk Edit Status Summary":"Bulk Edit Status Summary",
    "Successful":"Successful",
    "Failed":"Failed",
    "Order ID":"Order ID",
    "Details":"Details",
    "Result":"Result",
    "Pickup Routed":"Pickup Routed",
    "Delivery Routed":"Delivery Routed",
    "Ref. xxx":"Ref. xxx",
    "Zone":"Zone",
    "Clear":"Clear",
    "Apply":"Apply",
    "No result found":"No result found",
    "Type your keyword":"Type your keyword",
    "Type in the input to start search":"Type in the input to start search",
    "We are sorry ...":"We are sorry ...",
    "The page you are trying to access has restricted access.":"The page you are trying to access has restricted access.",
    "Please refer to your system administrator.":"Please refer to your system administrator.",
    "Failed":"Failed",
    "Done": "Done",
    "Schedule":"Schedule",
    "Proof of delivery":"Proof of delivery",
    "Signature":"Signature",
    "Driver Photo":"Driver Photo",
    "Notes":"Notes",
    "Order Info":"Order Info",
    "Order":"Order",
    "Price":"Price",
    "P_HUB_D Orders":'P_HUB_D Orders',
    "Routes":"Routes",
    "Default pickup":"Default pickup",
    'More':'More',
    'Route name':'Route name',
    'Driver':'Driver',
    'Pickup Orders':'Pickup Orders',
    'Delivery Orders':'Delivery Orders',
    'Ready For Pickup':'Ready For Pickup',
    'PricingAndEarning':"Pricing & Earning",
    "Pricing" : "Pricing",
    "save":"Save",
    "Learn":"Learn",
    "Scheduleoverlap":"The Schedule Pickup time must be before the Schedule Delivery time and have no overlap",
    "PleaseSelectCustomer":"Please select customer",
    "NoRegisteredCustomer":"No registered customer",
    "CreateAtLeastOneCustomerToCreateOrder":"Create at least one customer to create order",
}