import myWorker from "./test.worker";
import { CalculatePriceAction } from "../../../Redux/actions/actions";
import { SetPriceNewOrder } from "../../../Redux/actions/actionNames";

export const CalculatePriceWorker = (data, dispatch, noHttpOrder) => {
  console.log("CalculatePriceWorker data ",data)

  const worker = new myWorker();
  worker.postMessage(data);
  worker.addEventListener("message", (event) => {
      console.log("CalculatePriceWorker ",event.data)

    if (event.data.succeessfull) {
      if (noHttpOrder) {
        dispatch(SetPriceNewOrder({}, { done: true }));
      } else {
        dispatch(CalculatePriceAction(dispatch, event.data.data));
      }
    } else {
      dispatch(SetPriceNewOrder({ done: false }, { done: false ,where:"205" }));
    }
    worker.terminate();
  });
};
