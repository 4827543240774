import { OrdersList } from "./components/orders-list"
import { DriversList } from "./components/drivers-list"
import { useSelector } from "react-redux";


export const Monitoring = (props, force, justShowDrivers = false) => {
    const openViewOrder = useSelector((state) => state.ViewTravel.OpenDialoge)
    const openNewOrder = useSelector((state) => state.NewOrder.OpenDialoge)
    const openEditOrder = useSelector((state) => state.EditOrder.OpenDialoge)

    if (props.justShowDrivers) {
        return <>
            <DriversList  {...props} />

        </>
    }

    return <>
        <DriversList  {...props} />
        <OrdersList {...props} />
    </>


    if (justShowDrivers) {
        return <>
            <DriversList  {...props} />

        </>
    } else if (openViewOrder || openNewOrder || openEditOrder) {
        return null
    } else {
        return <>
            <DriversList  {...props} />
            <OrdersList {...props} />
        </>
    }


};




