import * as React from 'react'
import styles from './style.module.css';
import Button from '../../../design-system/lib/Button';
import { AUTO_DISPATCH, CLOSE, MAP } from '../../../assets';
import DropDown from '../../../design-system/lib/DropDown';
import Modal from '../../../design-system/lib/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { Add_Service_Option, ChangeNewTravelDialoge, NewTravelResetReserve, New_Order_Reset_DropOff, New_Order_Reset_DropOffs, New_Order_Reset_Pickup, New_Travel_PaymentMethod, Remove_Service_Option, ResetNewOrder, Set_Auto_ActiveVehicleType, Set_NewOrder_OrderType, Set_Service_ActiveVehicleType } from '../../../Redux/actions/actionNames';
import { getCenterMap } from '../../../helper/module';
import { GetVehicletypeByServiceAction } from '../../../Redux/actions/actions';
import { useTranslation } from 'react-i18next';
import Input from '../../../design-system/lib/Input';
import { Link } from 'react-router-dom';
import history from '../../../service/history';
import Typography from '../../../design-system/lib/Typography';
import { LearnMore } from '../../learnMore';


function ServiceNewOrder() {
    const [dialoge, setDialoge] = React.useState(null);
    const mapRef = useSelector((state) => state.Map.mapRef)
    const center = useSelector((state) => state.Map.center)
    const activeVehicleType = useSelector((state) => state.NewOrder.newOrder.service.activeVehicleType)
    const serviceOrderTypes = useSelector((state) => state.NewOrder.newOrder.service.activeVehicleType ? state.NewOrder.newOrder.service.activeVehicleType.orderTypes : [])
    const options = useSelector((state) => state.NewOrder.newOrder.service.options)
    const serviceVehicleTypes = useSelector((state) => state.SharedData.services)
    const lang = useSelector((state) => state.LocalData.lang)
    const paymentMethod = useSelector((state) => state.NewOrder.newOrder.paymentMethod.paymentMethod)
    const paymentSide = useSelector((state) => state.NewOrder.newOrder.paymentMethod.paymentSide)
    const orderTypeActive = useSelector((state) => state.NewOrder.newOrder.orderTypeActive)
    const dispatch = useDispatch();
    const { t } = useTranslation();


    const checkSelectedPaymnetMethod = (service) => {
        if (paymentSide + paymentMethod == "SenderCash") {
            if (!service.setting.isCashBySenderActive) {
                dispatch(
                    New_Travel_PaymentMethod({
                        paymentSide: "Sender",
                        paymentMethod: "Wallet",
                    })
                );
            }
        } else if (paymentSide + paymentMethod == "ReceiverCash") {
            if (!service.setting.enableReceiverCash) {
                dispatch(
                    New_Travel_PaymentMethod({
                        paymentSide: "Sender",
                        paymentMethod: "Wallet",
                    })
                );
            }
        }

    }


    const handleOrderType = (type) => {
        dispatch(Set_NewOrder_OrderType(type.data))
        if (type.data == "Pickup") {
            dispatch(New_Order_Reset_DropOffs())
            dispatch(New_Order_Reset_DropOff())

        } else if (type.data == "Delivery") {
            dispatch(New_Order_Reset_Pickup())
            dispatch(New_Order_Reset_DropOffs())

        } else if (type.data == "PickupDelivery") {
            dispatch(New_Order_Reset_DropOffs())
        }
    }



    const handleServiceService = (service) => {

        dispatch(NewTravelResetReserve())
        dispatch(Set_Service_ActiveVehicleType(service))
        if (orderTypeActive) {
            if (service.orderTypes.indexOf(orderTypeActive) == -1) {
                handleOrderType({data:service.orderTypes[0]})
            }
        } else {
            handleOrderType({data:service.orderTypes[0]})
        }


        checkSelectedPaymnetMethod(service)
        let center = getCenterMap(mapRef)

        dispatch(GetVehicletypeByServiceAction(dispatch, {
            latitude: center.lat,
            longitude: center.lng,

            serviceId: service.id
        }, (vt) => {

            if (vt) {
                dispatch(Set_Auto_ActiveVehicleType(vt[0]));
            }

        }))



    }

    React.useEffect(() => {
        if (serviceVehicleTypes.length > 0 && !activeVehicleType) {
            handleServiceService(serviceVehicleTypes[0])
        }
    }, [activeVehicleType , serviceVehicleTypes])

    React.useEffect(() => {
        if (!orderTypeActive) {
            if (serviceOrderTypes.length > 0) {
                handleOrderType({data:serviceOrderTypes[0]})
            }
        }
    }, [orderTypeActive])


    const booleanHandleactive = (OP) => {
        if (booleanOptionisSaved(OP)) {
          dispatch(Remove_Service_Option(OP.id))
        } else {
          dispatch(Add_Service_Option({
            id:OP.id,
            type:"boolean"
          }))
        }
    
      };
    
    
     const booleanOptionisSaved=(OP)=> {
        let booll = false;
         booll = options.find((item) => {
          return item.id === OP.id;
        });
        if(booll){
          return true
        }else{
          return false
        }
      }



     const ListhandleSelectedItem = (OP , item) => {

        if (ListoptionisSaved(item)) {
            dispatch(Remove_Service_Option(item.id))
            dispatch(Add_Service_Option({
                name: item.name,
                id: item.id,
                item:OP,
                type: "list"
              }))
          } else {
            dispatch(Add_Service_Option({
                name: item.name,
                id: item.id,
                item:OP,
                type: "list"
              }))
          }


      };
    
    
     const ListoptionisSaved=(OP)=> {
        let booll = false;
        booll = options.find((item) => {
          return item.id === OP.id;
        });
        if (booll) {
          return true
        } else {
          return false
        }
      }
    
    const ListoptionText=(option)=> {
        let booll = false;
        booll = options.find((item) => {
          return item.id === option.id;
        });
        if (booll) {
          return `${option.title} : ${booll.item.title}`
        } else {
          return false
        }
      }


      const inputHandleactive = (OP) => {
        // if (inputOptionisSaved(OP) && String(e.target.value).trim().length == 0) {
        //   dispatch(Remove_Service_Option(OP.id))
        // } else {
        //   dispatch(Add_Service_Option({
        //     id:OP.id,
        //     inputValue:OP.inputValue,
        //     type:OP.type,
        //     update:inputOptionisSaved(OP)
        //   }))
        // }
        if(OP.inputValue){
          dispatch(Add_Service_Option({
            id:OP.id,
            inputValue:OP.inputValue,
            type:OP.type,
            update:inputOptionisSaved(OP)
          }))
        }
      };
    
    
     const inputOptionisSaved=(OP) =>{
        let booll = false;
         booll = options.find((item) => {
          return item.id === OP.id;
        });
        if(booll){
          return true
        }else{
          return false
        }
      }


      const inputoptionText=(option)=> {
        let booll = false;
        booll = options.find((item) => {
          return item.id === option.id;
        });
        if (booll) {
          return `${option.title} : ${booll.inputValue}`
        } else {
          return false
        }
      }

      const getInputoptionValue=(option)=> {
        let booll = false;
        booll = options.find((item) => {
          return item.id === option.id;
        });
        if (booll) {
          return booll.inputValue
        } else {
          return null
        }
      }

      const InputOptionHandle = React.useCallback(() => {

        if(String(dialoge.inputType).length > 0){
            inputHandleactive(dialoge)
            setTimeout(() => {
                setDialoge(null)
            }, 100);
        }
    
      }, [dialoge]);

      const closeNewOrder = ()=> {
        dispatch(ChangeNewTravelDialoge());
        dispatch(ResetNewOrder())
    
      }


      function ItemsList(data, currentState, setCurrentState) {
        console.log("ItemsList ",data, currentState )
        return (
            <div className={styles.itemsConatiner}>
                {data.map((item) => {
                    return <div onClick={() => { setCurrentState(item) }}>
                        <img src={item.icon}  />
                        <Typography weight={currentState == item.id ? 'Body_Middle_Bold' :'Body_Middle_Regular'} textColor={'TextNormal'} text={item.title} className={styles.dropItem} />

                    </div>
                })}
            </div>
        )
    }
    

    return (
        <div className={styles.ServiceNewOrderContainer}>

            <div className={styles.ServiceNewOrderFirstRow}>
                <Button icon={CLOSE} size={'Medium'} type='Secondary' onClick={()=>{history.goBack();dispatch(ResetNewOrder())}} /> 
                <DropDown size='Medium'  ItemsList={ItemsList(serviceVehicleTypes , activeVehicleType?.id , handleServiceService)}  text={activeVehicleType?.title} icon={activeVehicleType?.icon} className={styles.service} placement='bottomEnd' data={serviceVehicleTypes} onClick={handleServiceService}  checkSelected="full"/>
                <DropDown size='Medium' text={t(orderTypeActive ? t(orderTypeActive) : "PleaseSelect")} placement='bottomEnd' data={serviceOrderTypes.map((item) => { return {title:t(item) , data:item} })} onClick={handleOrderType} checkSelected='full' />
                <div className={styles.divider} />
               <LearnMore hastext={false} placement={"bottomEnd"} where='NewOrder'  />
            </div>

            {(activeVehicleType && activeVehicleType.options.length > 0) && <div className={styles.ServiceNewOrderSecondRow}>
                {activeVehicleType.options.map((item , i) => {
                    return item.type === "list" ? (
                        <DropDown textStyle={styles.DropDownTextStyle} size='Small' text={ListoptionisSaved(item) ? ListoptionText(item) : item.title} placement={i ==0 ? 'bottomStart' : "bottomEnd"} data={item.data} isRequired={ListoptionisSaved(item) ? false :item.isRequired}  requiredText={t("Required")} onClick={(JOJO)=>ListhandleSelectedItem(JOJO , item)} isSelected={ListoptionisSaved(item)}  clearText={t("clear")} clearOnClick={()=>{ ListoptionisSaved(item) && dispatch(Remove_Service_Option(item.id))}}  />
                    ) : item.type === "input" ? (
                        <Button style={{maxWidth:"412px"}} isActive={inputOptionisSaved(item)} size={'Small'} type='Secondary' text={inputOptionisSaved(item) ? inputoptionText(item) : item.title}  isRequired={inputOptionisSaved(item) ? false :item.isRequired} requiredText={t("Required")} onClick={()=>{
                           
                          if(inputOptionisSaved(item)){
                            setDialoge({...item , inputValue:getInputoptionValue(item)})
                          }else{
                            setDialoge(item)
                          }
                          
                          
                        }} />
                    ): <Button  style={{maxWidth:"412px"}}  size={'Small'} type='Secondary'  isRequired={item.isRequired}  requiredText={t("Required")} text={item.title} isActive={booleanOptionisSaved(item)} onClick={()=>{booleanHandleactive(item)}} />
                })}
                {/* <Button size={'Small'} type='Secondary' text='Need Helper' />
                <DropDown size='Small' text='Weight' placement='bottom' data={["10 kg", "20 kg", "30 kg"]} />
                <Button size={'Small'} type='Secondary' text='Width' isRequired requiredText='Required' /> */}

            </div>}

            {/* <Modal size='Small' icon={AUTO_DISPATCH} title='Customer can’t choose this service' description='You shoud choose another customer or choose another service which this customer can choose ' CancelText='Skip' ConfirmationText='OK'/> */}
           
           {dialoge && <Modal ConfirmationOnClick={InputOptionHandle} onclose={()=>{setDialoge(null)}} size='large'  icon={CLOSE}   title={dialoge.title}  body={<div> <Input type={dialoge.inputType} placeHolder={dialoge.hint} value={dialoge.inputValue} onChange={(e)=>{
            setDialoge({
                ...dialoge,
                inputValue:e.target.value
            })
           }} /> </div>}  ConfirmationText={t("Savechanges")} footer={<><Button size={'Medium'} style={{marginInlineEnd:"16px"}} type='Secondary'  text={t("clear")} onClick={()=>{
            dispatch(Remove_Service_Option(dialoge.id))
            setDialoge(null)
           }} /></>} />}

        </div>
    )
}

export default ServiceNewOrder