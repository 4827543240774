import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import close from "../../../../../assets/icon-back.svg";
import {
  ChangeNewTravelDialoge,
  ResetNewOrder,
  ChangeViewTravelDialoge,
  ResetViewTravel,
  ResetNewFav,
  show_eventTimeLine,
  Set_New_Event,
  Set_viewOrder_events,
} from "../../../../../Redux/actions/actionNames";
import { withTranslation, Trans } from "react-i18next";
import { ServicesVehicletypeAction, OrderInfoAction, getFavAddressListAction, OrderGetEventsAction } from "../../../../../Redux/actions/actions";
// import Details_history_switch from "./details_history_switch";
import { CheckSocketOrderState } from "../../../../../helper/module";
import { DeleteOrderFromLIST } from "../../../../../service/socket/helper";
import history from "../../../../../service/history";

class App extends React.Component {
  NewTravelHandler = () => {
    // this.props.dispatch(ChangeNewTravelDialoge());
    history.push("/neworder")    

    this.props.dispatch(
      ServicesVehicletypeAction(this.props.dispatch, this.props.center)
    );
  };
  OncLIck = () => {
    try {
      let orderId = this.props.orderId;
      let state = this.props.status;
      if (state && orderId) {
        CheckSocketOrderState(state, (callx) => {
          
          if (!callx) {
            DeleteOrderFromLIST(orderId);
          }
        });
      }

      if(this.props.orderTypes != this.props.type){
        DeleteOrderFromLIST(orderId);
      }
    } catch (error) { }
 
    this.props.dispatch(ChangeViewTravelDialoge(false));
    this.props.dispatch(ResetViewTravel());
    this.props.dispatch(ResetNewFav());
  };

  getOrderInfo = () => {
    this.props.dispatch(show_eventTimeLine(false))

    this.props.dispatch(
      OrderInfoAction(this.props.dispatch, {
        orderId: this.props.pickupDeliveryOrderId,
      })
    );
    this.props.dispatch(
      ChangeViewTravelDialoge(true, this.props.pickupDeliveryOrderId, this.props.customerId)
    );
  };

  canShowEvent = () => {
    if (this.props.type != "Ondemand") {
      return true
    }

  }

  PandDGenerete = () => {
    if (this.props.type == "Pickup") {
      return (
        <React.Fragment>
          <span className="PickupDropOffFlag">
            <Trans i18nKey={"PickupFlag"}> </Trans>
          </span>

          {this.props.isPickupDelivery && (
            <span className="ListOrderPdFlag">
              <Trans i18nKey={"PD2"}> </Trans>
            </span>
          )}
        </React.Fragment>
      );
    } else if (this.props.type == "Delivery") {
      return (
        <React.Fragment>
          <span className="PickupDropOffFlag">
            <Trans i18nKey={"DropOffFlag"}> </Trans>
          </span>

          {this.props.isPickupDelivery && (
            <span className="ListOrderPdFlag">
              <Trans i18nKey={"PD2"}> </Trans>
            </span>
          )}
        </React.Fragment>
      );
    } else if (this.props.type == "PickupDelivery") {
      return (
        <React.Fragment>
          <span className="PickupDropOffFlag">
            <Trans i18nKey={"PD2"}> </Trans>
          </span>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <span className="PickupDropOffFlag">
            <Trans i18nKey={"Ondemand"}> </Trans>
          </span>
        </React.Fragment>
      );
    }
  };

  eventShowHandler = () => {
    this.props.dispatch(show_eventTimeLine(!this.props.showEventTimeLine))

    if (this.props.showEventTimeLine) {
      this.props.dispatch(Set_New_Event({ canSelectLocation: false }))
      this.props.dispatch(Set_New_Event({ show: false }));
      this.props.dispatch(Set_New_Event({ mapLocation: null }))
      this.props.dispatch(Set_viewOrder_events({ activeEventCordinator: null }))
    }else{
      this.props.dispatch(OrderGetEventsAction(this.props.dispatch ,this.props.orderId ))

    }
  }

  render() {
    return (
      <vRHeader>
        <img onClick={this.OncLIck} className="close" src={close} />
        <span className="title">
          <Trans i18nKey={"Order"}> </Trans>
        </span>
        {!this.props.OrderLoading && (
          <React.Fragment>
            <div className="vrPANDflagContainer">
              {this.PandDGenerete()}
            </div>


            <span className={`vrHeaderEventsTitle ${this.props.showEventTimeLine && "vrHeaderEventsTitleActive"}`} onClick={this.eventShowHandler}> <Trans i18nKey={"Events"}> </Trans> </span>
            
            {this.props.isPickupDelivery && <span onClick={this.getOrderInfo} className="vrHeaderLinkToPandD"> <Trans i18nKey={"PD2"}> </Trans></span>}
          </React.Fragment>
        )}


      </vRHeader>
    );
  }
}

const mapStateToProps = (state) => ({
  center: state.Map.center,
  status: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.status
      ? state.ViewTravel.pureData.status
      : null
    : null,
  orderId: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.id
      ? state.ViewTravel.pureData.id
      : null
    : null,

  type: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.id
      ? state.ViewTravel.pureData.type
      : null
    : null,
  isPickupDelivery: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.id
      ? state.ViewTravel.pureData.isPickupDelivery
      : null
    : null,

  pickupDeliveryOrderId: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.pickupDeliveryOrderId
      ? state.ViewTravel.pureData.pickupDeliveryOrderId
      : null
    : null,
  customerId: state.ViewTravel.pureData
    ? state.ViewTravel.pureData.customerId
      ? state.ViewTravel.pureData.customerId
      : null
    : null,
  OrderLoading: state.ViewTravel.loading,
  showEventTimeLine: state.ViewTravel.eventTimeLine.show,
  orderTypes:state.OrderList.ActiveOrderType

});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
