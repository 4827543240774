import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import Backdrop from "@material-ui/core/Backdrop";
import { withStyles } from "@material-ui/core/styles";
import turnOn from "../../../assets/turn-on.svg";
import close from "../../../assets/icon-close.svg";
import calendar from "../../../assets/ic-calendar.svg";
import time from "../../../assets/ic-time.svg";
import styles from './style.module.css';

import Dialog from "@material-ui/core/Dialog";
import momentJalaali from "moment-jalaali";
import moment from "moment";
import {
  NewTravelPickupSetReserve,
  NewTravelDropOffSetReserve,
} from "../../../Redux/actions/actionNames";
import {
  get_calendarType,
  get_lang,
} from "../../../helper/UserData/userdate";
import Button from "@material-ui/core/Button";
import { default as ButtonD } from "../../../design-system/lib/Button/index";

import jalali from "jalali-moment";
import { snack } from "../../../utils/SnackbarUtilsConfigurator";
import { CALENDAR_CHECK, CLOCK, CLOCK_1, CLOSE, SCHEDULE, TIMER } from "../../../assets";
import Modal from "../../../design-system/lib/Modal";
import Typography from "../../../design-system/lib/Typography";
import IconProvider from "../../../design-system/lib/IconProvider";
import DropDown from "../../../design-system/lib/DropDown";

const BackdropCustom = withStyles({
  backdrop: {
    zIndex: 5000,
    backgroundColor: "rgba(0, 0, 0, 0)",
    color: "#00000",
  },
})((props) => (
  <Backdrop
    {...props}
    style={{
      zIndex: 5000,
      backgroundColor: "rgba(0, 0, 0, 0)",
      color: "#00000",
    }}
  />
));

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showTimePickerDialoge: false,
      TimeSelected: false,
      forNow:
        props.scheduleDateAfter || props.scheduleDateBefore ? true : true,
      Dialoge: false,
      Date: momentJalaali(),
      init: moment(),
      scheduleDateBefore: null,
      scheduleDateAfter: null,
      activeDialoge: null,
      rangeTime: [],
      selectedTime: null,
      min: moment(
        Date.now() +
        props.activeVehicleType.scheduleSetting.scheduleDateRangeAfter
      ),
      max: moment(
        Date.now() +
        props.activeVehicleType.scheduleSetting.scheduleDateRangeBefore
      ),
    };
    this.confirm = this.confirm.bind(this);
    this.timePickerRef = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    try {
      if (
        nextProps.activeVehicleType &&
        nextProps.activeVehicleType.scheduleSetting
      ) {
        this.setState({
          min: moment(
            Date.now() +
            nextProps.activeVehicleType.scheduleSetting.scheduleDateRangeAfter
          ),
          max: moment(
            Date.now() +
            nextProps.activeVehicleType.scheduleSetting
              .scheduleDateRangeBefore
          ),
        });
      }
    } catch (error) { }
  }

  handleToggle = (b) => {
    if (
      parseInt(
        this.props.activeVehicleType.scheduleSetting.scheduleDateRangeBefore
      ) == 0
    ) {
      snack.warning_i18("errorInPanelSetting")

    } else {
      this.setState({
        showTimePickerDialoge: b,
      });
    }
  };

  componentDidMount() { }

  handleDialoge = (e) => {
    //   if (this.props.activePickupDropOff.type === "pickup") {

    this.setState({
      Date: moment(),
      ActiveFild: e,
      activeDialoge: e,
      // scheduleDateBefore: momentJalaali(this.props[e]).isValid()
      //   ? this.props[e]
      //   : momentJalaali(),
      // scheduleDateAfter: momentJalaali(this.props[e]).isValid()
      //   ? this.props[e]
      //   : momentJalaali(),
      selectedTime:null,
        scheduleDateBefore: null,
        scheduleDateAfter: null,
      Dialoge: !this.state.Dialoge,
      TimeSelected: false,
    });
    //  }
  };

  handleSwitch = () => {
    if (this.props.activePickupDropOff.type === "pickup") {
      this.props.dispatch(
        NewTravelPickupSetReserve({
          name: "scheduleDateBefore",
          value: null,
        })
      );
      this.props.dispatch(
        NewTravelPickupSetReserve({
          name: "scheduleDateAfter",
          value: null,
        })
      );
    } else {
      this.props.dispatch(
        NewTravelDropOffSetReserve({
          name: "scheduleDateBefore",
          value: null,
          firstDropOff: this.props.firstDropOff,
          id: this.props.firstDropOff ? "dropoff" : this.props.id,
        })
      );
      this.props.dispatch(
        NewTravelDropOffSetReserve({
          name: "scheduleDateAfter",
          value: null,
          firstDropOff: this.props.firstDropOff,
          id: this.props.firstDropOff ? "dropoff" : this.props.id,
        })
      );
    }

    this.setState({
      forNow: !this.state.forNow,
    });
  };

  checkSekectedTime = () => {
    if (this.state.selectedTime) {
      return `${this.state.selectedTime.scheduleDateAfter.hour}:${this.state.selectedTime.scheduleDateAfter.minute} - ${this.state.selectedTime.scheduleDateBefore.hour}:${this.state.selectedTime.scheduleDateBefore.minute}`
    } else {
      return this.props.t("PleaseSelect")
    }
  }

  SetTime = (date) => {
    //   if (this.props.activePickupDropOff.type === "pickup") {

    let scheduleDateBefore = moment(this.state.selectedDate).set({
      hour: moment(date.data[1]).format("HH"),
      minute: moment(date.data[1]).format("mm"),
    });
    let scheduleDateAfter = moment(this.state.selectedDate).set({
      hour: moment(date.data[0]).format("HH"),
      minute: moment(date.data[0]).format("mm"),
    });

    this.setState(
      {
        scheduleDateBefore: scheduleDateBefore,
        scheduleDateAfter: scheduleDateAfter,
        TimeSelected: true,
        selectedTime: {
          scheduleDateBefore: {
            hour: moment(date.data[1]).format("HH"),
            minute: moment(date.data[1]).format("mm"),
          },
          scheduleDateAfter: {
            hour: moment(date.data[0]).format("HH"),
            minute: moment(date.data[0]).format("mm"),
          },
        },
      },
      () => {
        this.handleToggle(false);
      }
    );
    // }
  };;

  SetDate = (e) => {
    // if (this.props.activePickupDropOff.type === "pickup") {
    let dateStr = momentJalaali(e?.data).format("YYYY-MM-DD"),
      timeStr = momentJalaali(this.state[this.state.ActiveFild]).format(
        "HH:mm"
      ),
      date = moment(dateStr),
      time = moment(timeStr, "HH:mm");

    date.set({
      hour: time.get("hour"),
      minute: time.get("minute"),
      second: time.get("second"),
    });
    this.setState({
      Date: date,
      // scheduleDateBefore: date,
      // scheduleDateAfter: date,
      TimeSelected: false,
      selectedDate: e?.data,
      selectedTime: null
    }, () => {
      this.TimesProvider()
    });
    // this.props.dispatch(
    //   NewTravelPickupSetReserve({
    //     name: this.state.ActiveFild,
    //     value: date,
    //   })
    // );
    //  }
  };

  confirm = () => {
    // if(this.controlThatbeforeAndAfterMustBiggerthanCurrentDate()){
    // ok
    // if (this.controlThatbeforeLoweEqualAfter()) {

    if (this.props.activePickupDropOff.type === "pickup") {

      if(this.props.firstDropOffScheduleDateAfter || this.props.firstDropOffScheduleDateBefore){
        if(Number(this.props.firstDropOffScheduleDateAfter) <  Number(this.state["scheduleDateBefore"]) ){
          snack.error_i18("Scheduleoverlap")
        }else{
          this.setState({
            Dialoge: false,
          });
          this.props.dispatch(
            NewTravelPickupSetReserve({
              name: "scheduleDateBefore",
              value: this.state["scheduleDateBefore"],
            })
          );
          this.props.dispatch(
            NewTravelPickupSetReserve({
              name: "scheduleDateAfter",
              value: this.state["scheduleDateAfter"],
            })
          );
        }
      }else{
        this.setState({
          Dialoge: false,
        });
        this.props.dispatch(
          NewTravelPickupSetReserve({
            name: "scheduleDateBefore",
            value: this.state["scheduleDateBefore"],
          })
        );
        this.props.dispatch(
          NewTravelPickupSetReserve({
            name: "scheduleDateAfter",
            value: this.state["scheduleDateAfter"],
          })
        );
      }


    } else {

      
      if(this.props.pickupScheduleDateAfter || this.props.pickupScheduleDateBefore){
        if(Number(this.state["scheduleDateAfter"]) <  Number(this.props.pickupScheduleDateBefore) ){
          snack.error_i18("Scheduleoverlap")

        }else{
          this.setState({
            Dialoge: false,
          });
          this.props.dispatch(
            NewTravelDropOffSetReserve({
              name: "scheduleDateBefore",
              value: this.state["scheduleDateBefore"],
              firstDropOff: this.props.firstDropOff,
              id: this.props.firstDropOff ? "dropoff" : this.props.id,
            })
          );
          this.props.dispatch(
            NewTravelDropOffSetReserve({
              name: "scheduleDateAfter",
              value: this.state["scheduleDateAfter"],
              firstDropOff: this.props.firstDropOff,
              id: this.props.firstDropOff ? "dropoff" : this.props.id,
            })
          );
        }

      }else{
        this.setState({
          Dialoge: false,
        });
        this.props.dispatch(
          NewTravelDropOffSetReserve({
            name: "scheduleDateBefore",
            value: this.state["scheduleDateBefore"],
            firstDropOff: this.props.firstDropOff,
            id: this.props.firstDropOff ? "dropoff" : this.props.id,
          })
        );
        this.props.dispatch(
          NewTravelDropOffSetReserve({
            name: "scheduleDateAfter",
            value: this.state["scheduleDateAfter"],
            firstDropOff: this.props.firstDropOff,
            id: this.props.firstDropOff ? "dropoff" : this.props.id,
          })
        );
      }



    }

  };

  cancel = () => {
    this.setState({
      [this.state.activeDialoge]: null,
    });
    if (this.props.activePickupDropOff.type === "pickup") {
      this.setState({
        Dialoge: false,
      });
      this.props.dispatch(
        NewTravelPickupSetReserve({
          name: "scheduleDateBefore",
          value: null,
        })
      );
      this.props.dispatch(
        NewTravelPickupSetReserve({
          name: "scheduleDateAfter",
          value: null,
        })
      );
    } else {
      this.setState({
        Dialoge: false,
      });
      this.props.dispatch(
        NewTravelDropOffSetReserve({
          name: "scheduleDateBefore",
          value: null,
          firstDropOff: this.props.firstDropOff,
          id: this.props.firstDropOff ? "dropoff" : this.props.id,
        })
      );

      this.props.dispatch(
        NewTravelDropOffSetReserve({
          name: "scheduleDateAfter",
          value: null,
          firstDropOff: this.props.firstDropOff,
          id: this.props.firstDropOff ? "dropoff" : this.props.id,
        })
      );
    }
  };

  controlThatbeforeLoweEqualAfter = () => {
    if (!this.state.scheduleDateAfter || !this.state.scheduleDateBefore) {
      return true;
    } else if (
      moment(this.state.scheduleDateBefore).isAfter(
        this.state.scheduleDateAfter
      ) ||
      moment(this.state.scheduleDateBefore).isSame(this.state.scheduleDateAfter)
    ) {
      return true;
    } else {
      return false;
    }
  };

  controlThatbeforeAndAfterMustBiggerthanCurrentDate = () => {
    if (
      (this.state.scheduleDateBefore
        ? moment(this.state.scheduleDateBefore).isAfter(new Date())
        : true) &&
      (this.state.scheduleDateAfter
        ? moment(this.state.scheduleDateAfter).isAfter(new Date())
        : true)
    ) {
      return true;
    } else {
      return false;
    }
  };

  diffDays = (IsfirstDay, IslastDay, IsOneDay, start, end, step) => {
    const today = start;
    const endDate = end;
    const minutes = Math.ceil(
      (Math.abs(endDate.unix() - today.unix()) / (1000 * 60)) % 60
    );
    const allSteps = Math.ceil(
      Math.abs(endDate.unix() - today.unix()) / 60 / step
    );
    // )
    // )
    // )
    // )
    // 
    // 
    // .format("HH:mm MM/DD ") , moment(endDate).format("HH:mm MM/DD "))

    let arr = [];
    arr.push([today, moment(today).add(step, "minute")]);

    for (let i = 1; i < allSteps; i++) {
      if (i == allSteps - 1) {
        if (!IsfirstDay && !IslastDay && !IsOneDay) {
          arr.push([
            moment(arr[arr.length - 1][1]),
            moment(endDate).set({ hour: 23, minute: 59 }),
          ]);
        } else if (IsfirstDay) {
          arr.push([moment(arr[arr.length - 1][1]), endDate]);
        }
      } else {
        arr.push([
          moment(arr[arr.length - 1][1]),
          moment(arr[arr.length - 1][1]).add(step, "minute"),
        ]);
      }
    }

    // arr.forEach(element => {
    //   .format("HH:mm MM/DD") , moment(element[1]).format("HH:mm MM/DD"))
    // });

    // return arr;
    this.setState({
      rangeTime: arr
    })
  };

  TimesProvider = () => {
    const step =
      this.props.activeVehicleType.scheduleSetting.scheduleDateStep / 1000 / 60;
    if (
      parseInt(
        this.props.activeVehicleType.scheduleSetting.scheduleDateRangeBefore
      ) == 0
    ) {
      return [];
    } else if (
      moment(this.state.min).format("MM/DD") ==
      moment(this.state.max).format("MM/DD")
    ) {
      //One Day
      return this.diffDays(
        false,
        false,
        true,
        moment(Date.now()).add(step, "minute"),
        moment(this.state.max),
        step
      );
    } else if (
      moment(this.state.init).format("MM/DD") ==
      moment(this.state.Date).format("MM/DD")
    ) {
      //the first
      return this.diffDays(
        true,
        false,
        false,
        moment(Date.now()).add(step, "minute"),
        moment(this.state.min).add(step, "minute").endOf("day"),
        step
      );
    } else if (
      moment(this.state.max).format("MM/DD") ==
      moment(this.state.Date).format("MM/DD")
    ) {
      //the last
      return this.diffDays(
        false,
        true,
        false,
        moment(this.state.max).startOf("day"),
        moment(this.state.max),
        step
      );
    } else {
      //the midd
      return this.diffDays(
        false,
        false,
        false,
        moment(this.state.Date).startOf("day"),
        moment(this.state.Date).endOf("day").add(-3, "minute"),
        step
      );
    }
  };

  getDaysOfDate = () => {
    let diff = moment(this.state.max).diff(moment(this.state.min), "days");

    let diffminute = moment(this.state.max).diff(moment(this.state.min), "minute");


    let arr = [];
    let min = moment(this.state.min).startOf("day");
    let max = moment(this.state.max).startOf("day");

    if (diff > 0) {
      arr.push(min);
      for (let i = 0; i < diff; i++) {
        arr.push(moment(min).add(i + 1, "day"));
      }
      return arr;
    } else {
      let step = this.props.activeVehicleType.scheduleSetting.scheduleDateStep / 1000 / 60;
      if (diffminute = moment(this.state.max).diff(moment(this.state.min), "minute") >= step) {
        arr.push(min);
        if (momentJalaali(this.state.max).format(
          get_lang() == "fa" ? "jMM/jDD" : "MM/DD"
        ) != momentJalaali(this.state.min).format(
          get_lang() == "fa" ? "jMM/jDD" : "MM/DD"
        )) {
          arr.push(max);
          return arr;
        } else {
          return arr;
        }
      } else {
        return arr;
      }

    }
  };

  checkSekectedDate = (day) => {
    if (this.state.selectedDate) {
      if (
        moment(day).format("MM/DD") ==
        moment(this.state.selectedDate).format("MM/DD")
      ) {
        return true
      }
    } else {
      return false;
    }
  };

  render() {
    // this.diffDays();
    const { t } = this.props;
    let DaysOfDate = this.getDaysOfDate()
    // let TimesProvider = this.TimesProvider()

    console.log("xoperth ",this.state , this.props)
    return (
      <div className="ScheduleContainer" style={{ margin: "0px" }}>
        {/* {["Ondemand"].indexOf(this.props.orderTypeActive) > -1 && 
        <div
          className={`Manual_Automatic_Switch ScheduleContainerSwitch  ${
            !this.state.forNow && "Manual_Automatic_SwitchActive"
            }`}
          onClick={this.handleSwitch}
        >
          <img
            className={`Manual_Automatic_SwitchImage ${
              !this.state.forNow ? "Manual_Automatic_SwitchImageActive" : ""
              }`}
            alt=""
            src={turnOn}
          />
          <span
            className={`Manual_Automatic_SwitchText ${
              !this.state.forNow ? "Manual_Automatic_SwitchTextActive" : ""
              }`}
          >
            {" "}
            {this.state.forNow ? (
              <Trans i18nKey={"Fornow"}> </Trans>
            ) : (
                <Trans i18nKey={"Scheduled"}> </Trans>
              )}
          </span>
        </div>
        } */}
        <React.Fragment>

          <ButtonD className={styles.NoMaxWidth} size="Medium" type="Secondary" icon={TIMER} text={momentJalaali(this.props.scheduleDateAfter).isValid() &&
            momentJalaali(this.props.scheduleDateBefore).isValid() ? (
            `${jalali(this.props.scheduleDateAfter)
              .locale(get_lang())
              .format("D MMM HH:mm")} - ${jalali(
                this.props.scheduleDateBefore
              )
                .locale(get_lang())
                .format("HH:mm")}`
          ) : (
            <Trans i18nKey={"Settime"}> </Trans>
          )} style={{ width: "calc(100% - 16px)", justifyContent: "start" }} onClick={() => this.handleDialoge("scheduleDateAfter")} isActive={momentJalaali(this.props.scheduleDateAfter).isValid() && momentJalaali(this.props.scheduleDateBefore).isValid()} />


          {this.state.Dialoge && <Modal ConfirmationOnClick={this.state.selectedTime ? this.confirm : null} CancelOnClick={() => { }} onclose={this.handleDialoge} size='large'
            icon={CLOSE} footer={<div style={{ flex: "1", flexDirection: "row" ,display:"flex",alignItems:"center" }} >
              <div className="ScheduleDialogeFooterDateTime"  style={{ flex: "1"}}>
                <Typography text={(momentJalaali(this.state.scheduleDateAfter).isValid() &&
            momentJalaali(this.state.scheduleDateBefore).isValid()) ? (
                  <p className="time">
                    {moment(this.state.scheduleDateAfter).format("D MMM HH:mm")} -{" "}
                    {moment(this.state.scheduleDateBefore).format("HH:mm")}
                  </p>
                ) : (
                  "- - - - - - "
                )} weight='Body_Middle_Bold' />

                <Typography text={"Selected date and time"} weight='Subtitle_Tiny_Regular' />
              </div>

             {(momentJalaali(this.props.scheduleDateAfter).isValid() &&
            momentJalaali(this.props.scheduleDateBefore).isValid()) && <ButtonD className={styles.NoMaxWidth} size="Medium" type="Secondary" onClick={this.cancel} text={this.props.t("clear")} style={{ marginInlineEnd: "16px" }} />}
            </div>}

            title={this.props.t("Schedule")} body={<div className={styles.twoColumns}>
              <div className={styles.Column}>
                <div className={styles.title}> <IconProvider Icon={CALENDAR_CHECK} /> <Typography text={this.props.t("Date")} weight='Body_Middle_Bold' /></div>
                <DropDown placement="bottomStart" size='Medium' data={DaysOfDate.map((dod) => { return { data: dod, title: momentJalaali(dod).format(get_lang() == "fa" ? "jMMM jD" : "MMM D") } })} onClick={this.SetDate} text={this.state.selectedDate ? moment(this.state.selectedDate).format("MMM D") : (DaysOfDate.length > 0 ? this.props.t("PleaseSelect") : this.props.t("noDateToShow"))} />
              </div>
              <div className={styles.Column}>
                <div className={styles.title}> <IconProvider Icon={CLOCK_1} /> <Typography text={this.props.t("Time")} weight='Body_Middle_Bold' /></div>
                <DropDown placement="bottomStart" size='Medium' data={this.state.rangeTime.map((dod) => {
                  return {
                    data: dod, title: `${moment(dod[0]).format("HH:mm")} - ${moment(
                      dod[1]
                    ).format("HH:mm")}`
                  }
                })} onClick={this.SetTime} text={this.checkSekectedTime()} />
              </div>
            </div>} CancelText={this.props.t("clear")} ConfirmationText={this.props.t("Savechanges")} />}

          {/* <Dialog
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              open={this.state.Dialoge}
              onClose={this.handleDialoge}
              fullScreen={false}
              disableAutoFocus
              disableEnforceFocus 
              disableRestoreFocus 
            >
              <div className="ScheduleDialogeContainer">
                <div className={"ScheduleDialogeDateContainer"}>
                  <p className={"ScheduleDialogeDateTimeHeader"}>
                    {" "}
                    <img src={calendar} /> <Trans i18nKey={"Date"}> </Trans>{" "}
                  </p>

                  <div className="ScheduleDialogeDateTimeItemsContainer">
                    {DaysOfDate.map((day) => {
                      return (
                        <div
                          className={`ScheduleDialogeDateTimeItem ${
                            this.checkSekectedDate(day) &&
                            "ScheduleDialogeDateTimeItemActive"
                            }`}
                          onClick={() => {
                            this.SetDate(day);
                          }}
                        >
                          {
                            momentJalaali(day).format(get_lang() == "fa" ? "jMMM jDD" : "MMM DD")
                          }
          
                        </div>
                      );
                    })}

                    {DaysOfDate.length == 0 && (
                      <p className="noDateToShow">
                        {" "}
                        <Trans i18nKey={"noDateToShow"}> </Trans>{" "}
                      </p>
                    )}
                  </div>
                </div>

                <div className={"ScheduleDialogeTimeContainer"}>
                  <p className={"ScheduleDialogeDateTimeHeader"}>
                    {" "}
                    <img src={time} /> <Trans i18nKey={"Time"}> </Trans>{" "}
                  </p>

                  <div className="ScheduleDialogeDateTimeItemsContainer">
                    {DaysOfDate.length > 0 &&
                      this.state.rangeTime.map((item) => {
                        return (
                          <div
                            className={`ScheduleDialogeDateTimeItem ${
                              this.checkSekectedTime(item) &&
                              "ScheduleDialogeDateTimeItemActive"
                              }`}
                            onClick={() => this.SetTime(item)}
                          >
                            {`${moment(item[0]).format("HH:mm")} - ${moment(
                              item[1]
                            ).format("HH:mm")}`}
                          </div>
                        );
                      })}

                    {DaysOfDate.length == 0 ? (
                      <p className="noDateToShow">
                        {" "}
                        <Trans i18nKey={"noDateToShow"}> </Trans>{" "}
                      </p>
                    ) : this.state.rangeTime.length == 0 ? (
                      <p className="noTimeToShow">
                        {" "}
                        <Trans i18nKey={"noTimeToShow"}> </Trans>{" "}
                      </p>
                    ) : (
                          ""
                        )}
                  </div>
                </div>

                <div className="ScheduleDialogeFooterContainer">
                  <div className="ScheduleDialogeFooterDateTime">
                    <p className="date">
                      {" "}
                      {jalali(this.state.selectedDate)
                        .locale(get_lang())
                        .format("DD/MM/YYYY")}{" "}
                    </p>
                    {this.state.selectedTime ? (
                      <p className="time">
                        {moment(this.state.scheduleDateAfter).format("HH:mm")} -{" "}
                        {moment(this.state.scheduleDateBefore).format("HH:mm")}
                      </p>
                    ) : (
                        "- - - - - - "
                      )}
                  </div>

                  <span className="ScheduleDialogeButttonContainer">
                    <Button
                      onClick={this.state.selectedTime ? this.confirm : null}
                      style={{
                        cursor: this.state.selectedTime ? "" : "not-allowed",margin:"10px",marginTop:"12px"
                      }}
                      className="confirmReserve"
                      variant="outlined"
                      color="primary"
                    >
                      <Trans i18nKey={"confirm"}> </Trans>
                    </Button>
                    <Button
                      onClick={this.cancel}
                      className="CancelReserve"
                      variant="outlined"
                      style={{margin:"10px",marginTop:"12px"}}
                    >
                      <Trans i18nKey={"Cancel"}> </Trans>
                    </Button>
                  </span>
                </div>
              </div>
            </Dialog> */}
        </React.Fragment>

      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  direction: state.LocalData.direction,
  lang: state.LocalData.lang,
  activePickupDropOff: state.NewOrder.newOrder.activePickupDropOff,
  activeVehicleType: state.NewOrder.newOrder.service.activeVehicleType,
  orderTypeActive: state.NewOrder.newOrder.orderTypeActive,

  pickupScheduleDateBefore : state.NewOrder.newOrder.pickup.scheduleDateBefore,
  pickupScheduleDateAfter : state.NewOrder.newOrder.pickup.scheduleDateAfter,

  firstDropOffScheduleDateBefore : state.NewOrder.newOrder.dropOffs.firstDropOff.scheduleDateBefore,
  firstDropOffScheduleDateAfter : state.NewOrder.newOrder.dropOffs.firstDropOff.scheduleDateAfter
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
