import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import close from "../../../../assets/close.svg";
import { SetActiveDriverList, SetSelectedMonitoringDriver, SetSelectedMonitoringOrder, Set_OrderList_OrderTypes } from "../../../../Redux/actions/actionNames";
import OrderlistMapper from "./OrderlistMapper";
import DriverInfoMaper from "./DriverInfoMaper";
import deliveryProfile from "../../../../assets/delivery-man.png";
import iccall from "../../../../assets/ic-call.svg";
import { Voip_OutgoingCall } from "../../../../Redux/actions/actions";
import { get_id } from "../../../../helper/UserData/userdate";
import { getOrderMonitoringBounding } from "./drivers-list/getOrderMonitoring";
import { ARROW_LEFT, CALL, Phubd_deactive, Routes_active, phubd_active } from "../../../../assets";
import Button from "../../../../design-system/lib/Button";
import styles from './driverinfo.module.css';
import clsx from "clsx";
import Typography from "../../../../design-system/lib/Typography";
import ProgressIndicator from "../../../../design-system/lib/ProgressIndicator";
import { width } from "@mui/system";
import history from "../../../../service/history";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: 1,
    };
  }
  handledriver = () => {
    this.props.dispatch(SetActiveDriverList(null));
    this.props.dispatch(SetSelectedMonitoringDriver(null))
    this.props.dispatch(SetSelectedMonitoringOrder(null))

  };
  handleTab = (num) => {
    this.setState({
      active: num,
    });
    this.handleGetOrders()
  };

  handleGetOrders() {

  }

  makeOutgoingCall = () => {
    this.props.dispatch(Voip_OutgoingCall(this.props.dispatch, {
      "to": this.props.activeDriver.phone,
      "orderId": null,
      "customerId": null,
      "driverId": this.props.activeDriver.id,
      "dispatcherId": get_id()
    }))
  }

  ToArray = () => {
    return Object.keys(this.props.OrderList).map(
      (id) => this.props.OrderList[id]
    );
  };


  render() {
    return (
      <div className="driverItemInfo" style={{ backgroundColor: "rgba(244, 244, 247, 1)", position: "absolute", top: "0", bottom: "0", border: "0" }}>
        <div className={styles.container}  >
          <div className={styles.containerf1} >      <Button size="Medium" type="Secondary" icon={ARROW_LEFT} onClick={this.handledriver} />
            <img src={this.props.activeDriver.avatarPhoto} className={clsx(styles.avatar, (this.props.activeDriver.ordersCount > 0 && this.props.activeDriver.isOnline) && styles.withOrder, (this.props.activeDriver.ordersCount == 0 && this.props.activeDriver.isOnline) && styles.noOrder)} onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = deliveryProfile;
            }} />
            <div className={styles.namephone}>
              <Typography weight="Body_Tiny_Medium" text={this.props.activeDriver.name} textColor="TextDark" />
              <Typography weight="Body_Tiny_Regular" text={this.props.activeDriver.phone} textColor="TextLight" style={{direction: "ltr"}} />
            </div>
            {!global.config.dispatcherSettings.isVoipActvie && <a style={{ marginInlineEnd: "auto" }} href={`tel:${this.props.activeDriver.phone}`}><Button size="Medium" type="Secondary" icon={CALL} /></a>}

            {global.config.dispatcherSettings.isVoipActvie && <Button size="Medium" type="Secondary" icon={CALL} onClick={this.makeOutgoingCall} style={{ marginInlineEnd: "auto" }} />}
          </div>

         {this.props.driverInfo?.isShowingRouteTabActive && <div className={styles.containerf2} >
          <Button size="Medium" onClick={()=>{ this.props.dispatch(Set_OrderList_OrderTypes("PickupDelivery")) ;history.push(`/PickupDelivery?driverusername=${this.props.driverInfo.username}`)}} type="Secondary" icon={phubd_active} style={{ marginInlineEnd: "auto" }} text="P_HUB_D Orders" />
          <Button size="Medium" onClick={()=>{history.push(`/routes?driverid=${this.props.driverInfo.id}`)}}  type="Secondary" icon={Routes_active} style={{ marginInlineEnd: "auto" }} text="Routes" />
          </div>}

          <div style={{height:"6px",width:"calc(100% + 32px)",marginLeft:"-4px",backgroundColor:"rgba(244, 244, 247, 1)"}} />

              
          <div className="switchDriverInfo"  style={{width:"calc(100% + 32px)"}}>
            <span className={`tab ${this.state.active === 1 && "tabActive"} ${this.state.active === 1 && styles.tabActive}`}  onClick={() => { this.handleTab(1) }}>
              {" "}
              {this.props.OrderListLoading && <ProgressIndicator theme="On_Light" style={{width:"16px",height:"14px"}} />}  <Trans i18nKey={"Ondemand"}> </Trans> {!this.props.OrderListLoading && <span className={styles.tag}>{this.ToArray().filter((arrx)=> arrx.driver != null).length}</span> } 
            </span>
            <span className={`tab ${this.state.active === 2 && "tabActive"} ${this.state.active === 2 && styles.tabActive}`} onClick={() => { this.handleTab(2) }}>
              {" "}
              <Trans i18nKey={"Info"}> </Trans>{" "}
            </span>
          </div>

        </div>
        {this.state.active === 1 ? <OrderlistMapper /> : <DriverInfoMaper />}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  open: state.DriversList.open,
  EditOrderopen: state.EditOrder.OpenDialoge,
  NewOrderopen: state.NewOrder.OpenDialoge,
  ViewTravelopen: state.ViewTravel.OpenDialoge,
  list: state.DriversList.list,
  activeDriver: state.DriversList.activeDriver,
  OrderList: state.DriversList.OrderList,
  OrderListLoading: state.DriversList.OrderListLoading,
  OrderListOrdered: state.DriversList.OrderListOrdered,
  driverInfo: state.DriversList.driverInfo,

});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
