import { ClickAwayListener } from "@material-ui/core";
import {  OverlayView } from "@react-google-maps/api";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DriverPin } from "../../../../shared/map-driver-pin";
import deliveryProfile from "../../../../../assets/delivery-man.png";
import L from "leaflet";
import { Marker } from "react-leaflet";
import { useEffect } from "react";
import { SetDriverPopUp, SetSelectedMonitoringDriver } from "../../../../../Redux/actions/actionNames";


const createIconSelected = (data) => {
  return (
    <div className="monitoringDriverSelected markerIcon " style={{ marginTop: "-117px", marginLeft: '-76px' }}>
      <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",gap:"5px"}}>
        <img style={{ display: "inline-block" }} src={data.avatarPhoto.length > 0 ? data.avatarPhoto : deliveryProfile} />
        <selectedDriverPhone  > {data.phone}  </selectedDriverPhone>
        <selectedDriverName  > {data.name}  </selectedDriverName>
      </div>

    </div>
  )
};

const createIconSelectedOsm = (data) => {
  return new L.divIcon({
    className: "monitoringDriverSelected markerIcon",
    html: `<div style="display: flex;flex-direction: column;justify-content: center;align-items: center;gap: 5px;"> <img src=${data.avatarPhoto.length > 0 ? data.avatarPhoto : deliveryProfile
      } /> <selectedDriverPhone> ${data.phone
      } </selectedDriverPhone>   <selectedDriverName> ${data.name
      } </selectedDriverName>  </div>`,
    iconSize: [162, 112],
    iconAnchor: [76, 106],
  });
};


export const DriversList = (props) => {
  const drivers = useSelector((state) => state.Monitoring.drivers.data)
  const driversOpen = useSelector((state) => state.Monitoring.drivers.open)
  const DriverPopUp = useSelector((state) => state.Monitoring.DriverPopUp)
  const mapRef = useSelector((state) => state.Map.mapRef)
  const dispatch = useDispatch();
  const viewOrderDriverId = useSelector((state) => state.ViewTravel.pureData?.driverId) 
  const ordersBoundingDriver = useSelector((state) => state.Monitoring.ordersBoundingDriver) 

  // ordersBoundingList: state.Monitoring.ordersBoundingList,
  // ordersBoundingDriver: state.Monitoring.ordersBoundingDriver,
  useEffect(()=>{
    // props.mapType == "osm" && mapRef.current.container.addEventListener("click", (e) => {
    //   e.srcElement.tagName != "ellipse" &&
    //   dispatch(SetDriverPopUp(DriverPopUp ? null : DriverPopUp))
    // });
} , [mapRef] )

  if (!driversOpen) {
    if(ordersBoundingDriver && ordersBoundingDriver.location){
      return  <DriverPin  driver={ordersBoundingDriver} {...props} position={{ lat: ordersBoundingDriver.location[1], lng: ordersBoundingDriver.location[0] }}  hasActiveOrder={ordersBoundingDriver.activeOrdersCount > 0} />
    }else{
      return null
    }
    
  }
  return drivers.filter(dri=>dri.id != viewOrderDriverId).map((driver, i) => {
    return  <DriverPin  driver={driver} {...props} position={{ lat: driver.location[1], lng: driver.location[0] }}  hasActiveOrder={driver.activeOrdersCount > 0} />
  })

};




