import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import { SetCodAmount } from "../../../../../Redux/actions/actionNames";



class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.inputOnchange = this.inputOnchange.bind(this)
  }


  inputOnchange(e){
    this.props.dispatch(SetCodAmount(e.target.value))
  }


  render() {
    const { t } = this.props;
    if(this.props.activeVehicleType && !this.props.activeVehicleType.setting.isCODActive){
      return null
    }
    return (
      <div className="codContainer">

          <span> {t("CashOnDelivery")} </span>
          <input min={0} onChange={this.inputOnchange} placeholder={t("EnterAmount")} type="number" />
      </div>
    
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  activeVehicleType:state.NewOrder.newOrder.service.activeVehicleType,


});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
