import NWACouriers from "../../../assets/NWACourier.png";
import FavIcon from "../../../assets/PROJECTS/NWACourierFavIcon.png";

export default function NWACourier() {
    
    return  global.config = {
    "ProjectName": "NWACourier",
    "LogoType": NWACouriers,
    "BaseApi": "https://rest.nwacourier.com",
    "BaseSocket": "https://socket.nwacourier.com",
    "BaseTrack": "https://admin.nwacourier.com",
    "MapCenter": {
        "lat": "36.37184742999306",
        "lng": "-94.21034928677233"
    },
    "Lang": "en",
    "Direction": "ltr",
    "AllLanguages": [{
        "lang": "en",
        "direction": "ltr",
        "i18Lang": "English"
    }],
    "FavIcon": FavIcon,
    "Country": "US",
    "thirdPartyLoginSupport": true  ,
    mapType :"osm", /* osm | google */ 
    googleApiKey : ""
} }