import styles from './style.module.css';
import icPickup from '../../../../assets/ic-pickup.svg';
import icSchedule from '../../../../assets/ic-schedule-x.svg';
import momentJalaali from "moment-jalaali";
import { get_lang } from "../../../../helper/UserData/userdate";
import { useTranslation } from 'react-i18next';
const _ = require('lodash');

export const PickupRow = ({ data }) => {
    const { t, i18n } = useTranslation();

    if (_.isEmpty(data.pickup)) {
        return null
    }

    return <div className={styles.PickupRowContainer}>
        <span  className={styles.PickupRowImageIcon} >  </span>
        <span className={styles.PickupRowAddress}>{data.pickup.address}</span>
        {(data.pickup.scheduleDateAfter != 0 || data.pickup.scheduleDateBefore != 0) &&
            <span className={styles.PickupRowSchedule}> <img src={icSchedule} className={styles.PickupRowScheduleIcon} /> <span className={styles.PickupRowScheduleText}>{momentJalaali(data.pickup.scheduleDateAfter || data.pickup.scheduleDateBefore).locale(get_lang()).format("D MMM HH:mm")} </span></span>

        }
        {["Pickup", "PickupDelivery"].indexOf(data.type) > -1 &&
            <>
                {data.isPickupDelivery && <span className={styles.OrderTypePD}> {t("tab_PickupDelivery")}</span>}
                {data.type == "Pickup" && <span className={data.isPickupDelivery ? styles.OrderTypewithPD : styles.OrderType}> {t("PickupFlag")} </span>}
            </>
        }
        {/* <span className={styles.OrderType}> P </span> */}


    </div>
};




