import { throttle, isEmpty } from 'lodash';
import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';


export function useMonitoringPin(orderId, orderDriverId ,id) {
  const [orderIsSelected, setOrderIsSelected] = useState(true);
  const selectedOrder = useSelector((state) => state.Monitoring.selectedOrder)
  const selectedOrderDriver = useSelector((state) => state.Monitoring.selectedOrderDriver)

  const deboucesSetOrderIsSelected = throttle(value => {
    setOrderIsSelected(value)
  }, 40);

  const SetOrderIsSelected = useCallback(value => deboucesSetOrderIsSelected(value), []);


  useEffect(() => {
    if(selectedOrder){
      if(selectedOrder == orderId){
        SetOrderIsSelected(true)
      }else{
        SetOrderIsSelected(false)
      }
    }else if(selectedOrderDriver){
      if(selectedOrderDriver == orderDriverId){
        SetOrderIsSelected(true)
      }else{
        SetOrderIsSelected(false)
      }
    }else{
      SetOrderIsSelected(true)
    }

    // if (((selectedOrder || selectedOrderDriver) ? (selectedOrder == orderId || selectedOrderDriver == orderDriverId) : true) != orderIsSelected) {
    //   SetOrderIsSelected((selectedOrder || selectedOrderDriver) ? (selectedOrder == orderId || selectedOrderDriver == orderDriverId) : true)
    // }
 
  }, [selectedOrder, selectedOrderDriver])


  return { orderIsSelected };
  
};




/*
import { throttle, isEmpty } from 'lodash';
import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';


export function useMonitoringPin(orderId, orderDriverId ,id) {
  const [orderIsSelected, setOrderIsSelected] = useState(true);
  const [orderPopUp, setOrderPopUp] = useState(false);
  const selectedOrder = useSelector((state) => state.Monitoring.selectedOrder)
  const selectedOrderDriver = useSelector((state) => state.Monitoring.selectedOrderDriver)
  const OrderPinPopUp = useSelector((state) => state.Monitoring.OrderPinPopUp)

  const deboucesSetOrderIsSelected = throttle(value => {
    setOrderIsSelected(value)
  }, 40);
  const deboucesSetOrderPopUp = throttle(value => {
    setOrderPopUp(value)
  }, 40);

  const SetOrderIsSelected = useCallback(value => deboucesSetOrderIsSelected(value), []);
  const SetOrderPopUp = useCallback(value => deboucesSetOrderPopUp(value), []);


  useEffect(() => {
    if (((selectedOrder || selectedOrderDriver) ? (selectedOrder == orderId || selectedOrderDriver == orderDriverId) : true) != orderIsSelected) {
      SetOrderIsSelected((selectedOrder || selectedOrderDriver) ? (selectedOrder == orderId || selectedOrderDriver == orderDriverId) : true)
    }
    if ((OrderPinPopUp == id) != orderPopUp) {
      SetOrderPopUp(OrderPinPopUp == id)
    }
  }, [selectedOrder, selectedOrderDriver,OrderPinPopUp])


  return { orderIsSelected, orderPopUp };
};





*/
