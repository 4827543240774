import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import arrow from "../../../../../../../assets/chevron-down.svg";
import event_flash from "../../../../../../../assets/event_arrow.png";
import Collapse from "@material-ui/core/Collapse";
import momentJalaali from "moment-jalaali";
import { get_calendarType } from '../../../../../../../helper/UserData/userdate';

class App extends React.Component {


  eventOnclick = () => {
    this.props.onClick(this.props.id == this.props.selectedEvent ? null : this.props.id, this.props.id == this.props.selectedEvent ? null : this.props.coordinates)
  }


  render() {
    
    return (
      <div className="EventTimeLine_ListItem">

        <div className="lineContainer">
          <div className="upper" style={{ backgroundColor: this.props.isFirst && "white" }}></div>
          <div className="loweer" style={{ backgroundColor: this.props.isLast && "white" }}></div>
        </div>
        <div className="circule"></div>
        {this.props.firstItemInArray && <span className="ltext">  {momentJalaali(this.props.createdAt).locale(get_calendarType()).format("D MMM YY")} </span>
        }
        <div className="detailsContainer" onClick={this.eventOnclick}>

          <div className="detailsContainer_details">
            <span className="status"> {this.props.title} </span>
            <span className="source"> <Trans i18nKey={`${this.props.source}`}> </Trans> </span>
            <span className="time">  {momentJalaali(this.props.createdAt).locale(get_calendarType()).format("HH:mm")} </span>
            <span className="statusDescription"> {this.props.description} </span>
            <img
              src={arrow} className={`collapse `}
              style={{ transform: this.props.selectedEvent === this.props.id ? "rotate(180deg)" : "", transition: ".2s all ease-in-out" }}
            />

          </div>

          <Collapse
            className="detailsContaine_collapse"
            in={this.props.selectedEvent === this.props.id}
            timeout="auto"
            unmountOnExit
          >
            <p className="detailsContaine_collapse_description">
              {this.props.name ? this.props.name : "-----"}
            </p>
            <div className="events_detailsContaine_collaps_prenext">
              <span className={`VR_${this.props.orderPreviousStatus}`} ><Trans i18nKey={`status${this.props.orderPreviousStatus}`}> </Trans> </span>
              <img
                src={event_flash} className={`collapse `} />
              <span className={`VR_${this.props.orderStatus}`}> <Trans i18nKey={`status${this.props.orderStatus}`}> </Trans> </span>

            </div>
          </Collapse>
        </div>



      </div>
    );
  }
}

const mapStateToProps = (state) => ({

});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
