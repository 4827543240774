import { SetDriverStatistics } from "../Redux/actions/actionNames";
import { store } from "../Redux/store";
import { DriverApi } from "../service/API/driver";


export const UpdateDriversStatistics = async () => {
  let Driver = new DriverApi()
  let data = await Driver.Statistics()
  store.dispatch(
    SetDriverStatistics({
      isOnline: data.data.isOnline,
      isOffline: data.data.isOffline,
    })
  );
};



