import React from 'react'
import { useDispatch, useSelector } from "react-redux";
import { store } from "../../../../Redux/store";
import { useCallback } from "react";
import { Table_ClearSelectedOrders, Table_DeSelectOrder, Table_SelectOrder, Table_SelecteMultiOrders } from "../../../../Redux/actions/actionNames";
import Typography from "../../../../design-system/lib/Typography";
import Tag from "../../../../design-system/lib/Tag";
import styles from '../style.module.css';
import momentJalaali from "moment-jalaali";
import { get_calendarType, get_currencyCode, get_lang } from '../../../../helper/UserData/userdate';
import Checkbox from '../../../../design-system/lib/Checkbox';
import TooltipOnro from '../../../../design-system/lib/Tooltip';
import Button from '../../../../design-system/lib/Button';
import { COPY, LINK_CIRCLE } from '../../../../assets';


export const getTableDefaultColumn = () => {
  let storeData = store.getState();

  if ("PickupDelivery" == storeData.OrderList.ActiveOrderType) {
    return [
      {
        id: 'select',
        isFirst: true,
        size: 50,
        minSize: 50,
        maxSize: 50,
        header: ({ table }) => (
          <IndeterminateCheckboxHeader
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
              isheader: true
            }}
          />
        ),
        cell: ({ row }) => (
          <div>
            <IndeterminateCheckbox
              {...{
                checked: row.getIsSelected(),
                disabled: !row.getCanSelect(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
                style: { marginInlineStart: "16px" },
                isheader: false,
                ...row,
              }}

            /></div>
        ),
      },
      {
        accessorKey: 'code',
        header: 'OrderID',
        id: 'OrderID',
        minSize: 50,
        can_toggle_show:false,
        cell: (row) => (
          <div>
            <Typography weight='Body_Small_Regular' textColor='TextDark' text={row.getValue()} style={{ paddingInlineStart: "12px", paddingInlineEnd: "12px" }} />
          </div>

        ),
      },
      {
        accessorKey: 'type',
        header: () => 'OrderType',
        can_toggle_show:false,

        cell: (row) => (
          <div >
            <Tag weight='Body_Small_Medium' backgroundColor='GrayUltraLight' color='TextDark' size='Large' text={row.getValue()} style={{ marginInlineStart: "12px", borderRadius: "8px" }} />
          </div>

        ),
        id: 'OrderType',
        minSize: 50,

      },
      {
        accessorKey: 'stage',
        header: () => 'Stage',
        can_toggle_show:false,

        cell: (row) => (
          <div >
            <Tag weight='Body_Small_Medium' backgroundColor='GrayUltraLight' color='TextDark' size='Large' text={row.getValue()} style={{ marginInlineStart: "12px", borderRadius: "8px" }} />
          </div>

        ),
        id: 'stage',
        minSize: 50,
        maxSize: 100
      },
      {
        accessorKey: 'status',
        header: () => 'Status',
        can_toggle_show:false,

        id: 'Status',
        minSize: 50,
        cell: (row) => (
          <div style={{ display: 'flex' }}>
            <Tag weight='Body_Small_Medium' backgroundColor='GrayUltraLight' color='TextDark' size='Large' text={row.getValue()} className={styles[row.getValue()]} />
          </div>

        ),
      },
      {
        can_toggle_show:false,

        accessorKey: 'routeName',
        header: 'Route',
        id: 'routeName',
        minSize: 50,
        cell: (row) => (
          <div>
            <Typography weight='Body_Small_Regular' textColor='TextDark' text={row.getValue()} style={{ paddingInlineStart: "12px", paddingInlineEnd: "12px" }} />
          </div>

        ),
      },
      {
        accessorKey: 'createdAt',
        header: 'CreatedAt',

        id: 'createdAt',
        minSize: 50,
        cell: (row) => (
          <div style={{ display: 'flex' }}>
            <Typography weight='Body_Small_Regular' textColor='TextDark' text={momentJalaali(row.getValue()).locale(get_calendarType()).format("D MMM HH:mm")} style={{ paddingInlineStart: "12px", paddingInlineEnd: "12px" }} className={styles.ellipsis} />
          </div>

        ),
      },
      {
        accessorKey: 'statusUpdatedAt',
        header: () => 'StatusUpdatedAt',

        cell: (row) => (
          <div >
            <Tag weight='Body_Small_Medium' backgroundColor='GrayUltraLight' color='TextDark' size='Large' text={momentJalaali(row.getValue()).locale(get_calendarType()).format("D MMM HH:mm")} style={{ marginInlineStart: "12px", borderRadius: "8px" }} />
          </div>

        ),
        id: 'statusUpdatedAt',
        minSize: 50,
      },
      {
        accessorKey: 'zone',
        header: 'Zone',

        id: 'zone',
        minSize: 50,
        cell: (row) => (
          <div style={{ display: 'flex' }}>
            <Typography weight='Body_Small_Regular' textColor='TextDark' text={String(row.getValue()?.name).length > 0 ? row.getValue()?.name : "---"} style={{ paddingInlineStart: "12px", paddingInlineEnd: "12px" }} className={styles.ellipsis} />
          </div>

        ),
      },
      {
        accessorKey: 'note',
        header: 'Note',

        id: 'note',
        minSize: 50,
        cell: (row) => (
          <div style={{ display: 'flex' }}>
            <Typography weight='Body_Small_Regular' textColor='TextDark' text={String(row.getValue()).length > 0 ? row.getValue() : "---"} style={{ paddingInlineStart: "12px", paddingInlineEnd: "12px" }} className={styles.ellipsis} />
          </div>

        ),
      },
      {
        accessorKey: 'referenceId',
        header: 'ReferenceId',

        id: 'referenceId',
        minSize: 50,
        cell: (row) => (
          <div style={{ display: 'flex' }}>
            <Typography weight='Body_Small_Regular' textColor='TextDark' text={(row.getValue() && String(row.getValue()).length > 1) ? row.getValue() : "---"} style={{ paddingInlineStart: "12px", paddingInlineEnd: "12px" }} className={styles.ellipsis} />
          </div>

        ),
      },
      {
        accessorKey: 'pickup',
        header: 'PickupAddress',

        id: 'PickupAddress',
        minSize: 50,
        size: 200,
        cell: (row) => (
          <TooltipOnro 
          
          tooltipClassName={styles.tooltipClassName} title={<div className={styles.tooltipContainer}>
            <Typography weight="Body_Small_Medium" text={row.getValue().address} textColor='TextDark' style={{paddingInlineEnd:"10px",paddingInlineStart:"10px"}} />
            <span className={styles.divider} />
            <Button size='Medium' type='Secondary' icon={COPY} style={{background:"unset"}}   />
            </div>} placement='top'>
            <div style={{ display: 'flex' ,flexDirection:"column",justifyContent:"center" ,alignItems:"start",height:"40px" }}>
              <Typography weight='Body_Small_Regular' textColor='TextDark' text={row.getValue().address} style={{ paddingInlineStart: "12px", paddingInlineEnd: "12px" }} className={styles.ellipsis} />
            </div>
          </TooltipOnro>
        ),
      },
      {
        accessorKey: 'delivery',
        header: 'DeliveryAddress',

        id: 'DeliveryAddress',
        minSize: 50,
        size: 200,
        cell: (row) => (
          <TooltipOnro tooltipClassName={styles.tooltipClassName} title={<div className={styles.tooltipContainer}>
          <Typography weight="Body_Small_Medium" text={row.getValue().address} textColor='TextDark' style={{paddingInlineEnd:"10px",paddingInlineStart:"10px"}} />
          <span className={styles.divider} />
          <Button size='Medium' type='Secondary' icon={COPY} style={{background:"unset"}}   />
          </div>} placement='top'>
          <div style={{ display: 'flex' ,flexDirection:"column",justifyContent:"center" ,alignItems:"start",height:"40px" }}>
            <Typography weight='Body_Small_Regular' textColor='TextDark' text={row.getValue().address} style={{ paddingInlineStart: "12px", paddingInlineEnd: "12px" }} className={styles.ellipsis} />
          </div>
        </TooltipOnro>

        ),
      },

      {
        accessorKey: 'driver',
        header: () => 'Driver Name',

        id: 'driverName',
        minSize: 50,
        cell: (row) => (
          <div style={{ display: 'flex' }}>
            <Tag weight='Body_Small_Regular' textColor='TextDark' text={row.getValue() ? row.getValue().name : "---"} style={{ paddingInlineStart: "12px", paddingInlineEnd: "12px" }} className={styles.ellipsis} />
          </div>

        ),
      },
      {
        accessorKey: 'driver',
        header: () => 'Driver Phone',

        id: 'driverPhone',
        minSize: 50,
        cell: (row) => (
          <div style={{ display: 'flex' }}>
            <Tag weight='Body_Small_Regular' textColor='TextDark' text={row.getValue() ? row.getValue().phone : "---"} style={{ paddingInlineStart: "12px", paddingInlineEnd: "12px" }} className={styles.ellipsis} />
          </div>

        ),
      },
      {
        accessorKey: 'customer',
        header: () => 'Customer Name',

        id: 'customerName',
        minSize: 50,
        cell: (row) => (
          <div style={{ display: 'flex' }}>
            <Tag weight='Body_Small_Regular' textColor='TextDark' text={row.getValue() ? row.getValue().name : "---"} style={{ paddingInlineStart: "12px", paddingInlineEnd: "12px" }} className={styles.ellipsis} />
          </div>

        ),
      },
      {
        accessorKey: 'customer',
        header: () => 'Customer Phone',

        id: 'customerPhone',
        minSize: 50,
        cell: (row) => (
          <div style={{ display: 'flex' }}>
            <Tag weight='Body_Small_Regular' textColor='TextDark' text={row.getValue() ? (String(row.getValue().phone).startsWith("+") ? row.getValue().phone : (String(row.getValue().phone).startsWith("00") ? "+" + String(row.getValue().phone).slice(2, row.getValue().phone.length)  : "" ) ) : "---"} style={{ paddingInlineStart: "12px", paddingInlineEnd: "12px" }} className={styles.ellipsis} />
          </div>

        ),
      },
      {
        accessorKey: 'service',
        header: 'Service',
        id: 'service',
        minSize: 50,
        cell: (row) => (
          <div>
            <Typography weight='Body_Small_Regular' textColor='TextDark' text={row.getValue() ? row.getValue().title :"------" } style={{ paddingInlineStart: "12px", paddingInlineEnd: "12px" }} />
          </div>

        ),
      },
      {
        accessorKey: 'price',
        header: 'Price',
        id: 'price',
        minSize: 50,
        cell: (row) => (
          <div>
            <Typography weight='Body_Small_Regular' textColor='TextDark' text={Number(row.getValue()).toLocaleString(get_lang(), {
                    style: "currency",
                    currency: get_currencyCode(),
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                })} style={{ paddingInlineStart: "12px", paddingInlineEnd: "12px" }} />
          </div>

        ),
      },
      {
        accessorKey: 'codAmount',
        header: 'COD',
        id: 'codAmount',
        minSize: 40,
        maxSize: 70,
        cell: (row) => (
          <div>
            <Typography weight='Body_Small_Regular' textColor='TextDark' text={Number(row.getValue()).toLocaleString(get_lang(), {
                    style: "currency",
                    currency: get_currencyCode(),
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                })} style={{ paddingInlineStart: "12px", paddingInlineEnd: "12px" }} />
          </div>

        ),
      },
      {
        accessorKey: 'estimatedDistanceInMeters',
        header: () => 'EstimatedDistanceInMeters',

        cell: (row) => (
          <div >
            <Tag weight='Body_Small_Medium' backgroundColor='GrayUltraLight' color='TextDark' size='Large' text={String(row.getValue())} style={{ marginInlineStart: "12px", borderRadius: "8px" }} />
          </div>

        ),
        id: 'estimatedDistanceInMeters',
        minSize: 50,
      },    
      
      {
        accessorKey: 'estimatedDuration',
        header: () => 'EstimatedDuration',

        cell: (row) => (
          <div >
            <Tag weight='Body_Small_Medium' backgroundColor='GrayUltraLight' color='TextDark' size='Large' text={String(row.getValue())} style={{ marginInlineStart: "12px", borderRadius: "8px" }} />
          </div>

        ),
        id: 'estimatedDuration',
        minSize: 50,
      },
      {
        accessorKey: 'paymentMethod',
        header: () => 'Payment Method',

        cell: (row) => (
          <div >
            {console.log("paymentMethod cell ",row)}
            <Tag weight='Body_Small_Medium' backgroundColor='GrayUltraLight' color='TextDark' size='Large' text={row.row?.original ? `${row.row.original.paymentSide}${row.row.original.paymentMethod}` : "-----"} style={{ marginInlineStart: "12px", borderRadius: "8px" }} />
          </div>

        ),
        id: 'Payment',
        minSize: 50,

      },
      {
        accessorKey: 'pickup',
        header: 'PickupAddressDetail',

        id: 'PickupAddressDetail',
        minSize: 50,
        size: 200,
        cell: (row) => (
          <div style={{ display: 'flex' ,flexDirection:"column",justifyContent:"center" ,alignItems:"start",height:"40px" }}>
          <Typography weight='Body_Small_Regular' textColor='TextDark' text={row.getValue().addressDetail} style={{ paddingInlineStart: "12px", paddingInlineEnd: "12px" }} className={styles.ellipsis} />
        </div>
        ),
      },
      {
        accessorKey: 'pickup',
        header: 'PickupCompleteAfter',

        id: 'PickupCompleteAfter',
        minSize: 50,
        size: 200,
        cell: (row) => (
          <div style={{ display: 'flex' ,flexDirection:"column",justifyContent:"center" ,alignItems:"start",height:"40px" }}>
          <Typography weight='Body_Small_Regular' textColor='TextDark' text={row.getValue().completeAfter ? (momentJalaali(row.getValue().completeAfter).locale(get_calendarType()).format("D MMM HH:mm")) : "---"} style={{ paddingInlineStart: "12px", paddingInlineEnd: "12px" }} className={styles.ellipsis} />
        </div>
        ),
      },
      {
        accessorKey: 'pickup',
        header: 'PickupcompleteBefore',
        id: 'PickupcompleteBefore',
        minSize: 50,
        size: 200,
        cell: (row) => (
          <div style={{ display: 'flex' ,flexDirection:"column",justifyContent:"center" ,alignItems:"start",height:"40px" }}>
          <Typography weight='Body_Small_Regular' textColor='TextDark' text={row.getValue().completeBefore ? (momentJalaali(row.getValue().completeBefore).locale(get_calendarType()).format("D MMM HH:mm")) : "---"} style={{ paddingInlineStart: "12px", paddingInlineEnd: "12px" }} className={styles.ellipsis} />
        </div>
        ),
      },
      {
        accessorKey: 'pickup',
        header: 'PickupFullName',
        id: 'PickupFullName',
        minSize: 50,
        size: 200,
        cell: (row) => (
          <div style={{ display: 'flex' ,flexDirection:"column",justifyContent:"center" ,alignItems:"start",height:"40px" }}>
          <Typography weight='Body_Small_Regular' textColor='TextDark' text={String(row.getValue().fullName).length > 0 ? row.getValue().fullName : "---"} style={{ paddingInlineStart: "12px", paddingInlineEnd: "12px" }} className={styles.ellipsis} />
        </div>
        ),
      },

      {
        accessorKey: 'pickup',
        header: 'PickupPhone',
        id: 'PickupPhone',
        minSize: 50,
        size: 200,
        cell: (row) => (
          <div style={{ display: 'flex' ,flexDirection:"column",justifyContent:"center" ,alignItems:"start",height:"40px" }}>
          <Typography weight='Body_Small_Regular' textColor='TextDark' text={String(row.getValue().phone).length > 0 ? row.getValue().phone : "---"} style={{ paddingInlineStart: "12px", paddingInlineEnd: "12px" }} className={styles.ellipsis} />
        </div>
        ),
      },
      {
        accessorKey: 'pickup',
        header: 'PickupEmail',
        id: 'PickupEmail',
        minSize: 50,
        size: 200,
        cell: (row) => (
          <div style={{ display: 'flex' ,flexDirection:"column",justifyContent:"center" ,alignItems:"start",height:"40px" }}>
          <Typography weight='Body_Small_Regular' textColor='TextDark' text={String(row.getValue().email).length > 0 ? row.getValue().email : "---"} style={{ paddingInlineStart: "12px", paddingInlineEnd: "12px" }} className={styles.ellipsis} />
        </div>
        ),
      },
      {
        accessorKey: 'pickup',
        header: 'PickupPodNote',
        id: 'PickupPodNote',
        minSize: 50,
        size: 200,
        cell: (row) => (
          <div style={{ display: 'flex' ,flexDirection:"column",justifyContent:"center" ,alignItems:"start",height:"40px" }}>
          <Typography weight='Body_Small_Regular' textColor='TextDark' text={String(row.getValue().podNote).length > 0 ? row.getValue().podNote : "---"} style={{ paddingInlineStart: "12px", paddingInlineEnd: "12px" }} className={styles.ellipsis} />
        </div>
        ),
      },

      {
        accessorKey: 'pickup',
        header: 'PickupPodPhoto',
        id: 'PickupPodPhoto',
        minSize: 50,
        size: 200,
        cell: (row) => (
          <div style={{ display: 'flex' ,paddingInlineStart:"12px" }}>
          <Button size='Medium' type='Secondary' icon={LINK_CIRCLE} disabled={String(row.getValue().podPhoto).length == 0} onClick={()=>{
                   try {
                    var url = new URL(row.getValue().podPhoto);      
                    window.open(url + "?lng=" + get_lang(), '_blank');
                  } catch (error) { }
          }} />
        </div>
        ),
      },
      {
        accessorKey: 'pickup',
        header: 'PickupPodSignature',
        id: 'PickupPodSignature',
        minSize: 50,
        size: 200,
        cell: (row) => (
          <div style={{ display: 'flex' ,paddingInlineStart:"12px" }}>
          <Button size='Medium' type='Secondary' icon={LINK_CIRCLE} disabled={String(row.getValue().podSignature).length == 0} onClick={()=>{
                   try {
                    var url = new URL(row.getValue().podSignature);      
                    window.open(url + "?lng=" + get_lang(), '_blank');
                  } catch (error) { }
          }} />
        </div>
        ),
      },

      {
        accessorKey: 'pickup',
        header: 'PickupZone',
        id: 'PickupZone',
        minSize: 50,
        size: 200,
        cell: (row) => (
          <div style={{ display: 'flex' ,flexDirection:"column",justifyContent:"center" ,alignItems:"start",height:"40px" }}>
          <Typography weight='Body_Small_Regular' textColor='TextDark' text={String(row.getValue().zone?.name).length > 0 ? row.getValue().zone?.name : "---"} style={{ paddingInlineStart: "12px", paddingInlineEnd: "12px" }} className={styles.ellipsis} />
        </div>
        ),
      },

      {
        accessorKey: 'delivery',
        header: 'DeliveryAddressDetail',

        id: 'DeliveryAddressDetail',
        minSize: 50,
        size: 200,
        cell: (row) => (
          <div style={{ display: 'flex' ,flexDirection:"column",justifyContent:"center" ,alignItems:"start",height:"40px" }}>
          <Typography weight='Body_Small_Regular' textColor='TextDark' text={row.getValue().addressDetail} style={{ paddingInlineStart: "12px", paddingInlineEnd: "12px" }} className={styles.ellipsis} />
        </div>
        ),
      },
      {
        accessorKey: 'delivery',
        header: 'DeliveryCompleteAfter',

        id: 'DeliveryCompleteAfter',
        minSize: 50,
        size: 200,
        cell: (row) => (
          <div style={{ display: 'flex' ,flexDirection:"column",justifyContent:"center" ,alignItems:"start",height:"40px" }}>
          <Typography weight='Body_Small_Regular' textColor='TextDark' text={row.getValue().completeAfter ? (momentJalaali(row.getValue().completeAfter).locale(get_calendarType()).format("D MMM HH:mm")) : "---"} style={{ paddingInlineStart: "12px", paddingInlineEnd: "12px" }} className={styles.ellipsis} />
        </div>
        ),
      },
      {
        accessorKey: 'delivery',
        header: 'DeliverycompleteBefore',
        id: 'DeliverycompleteBefore',
        minSize: 50,
        size: 200,
        cell: (row) => (
          <div style={{ display: 'flex' ,flexDirection:"column",justifyContent:"center" ,alignItems:"start",height:"40px" }}>
          <Typography weight='Body_Small_Regular' textColor='TextDark' text={row.getValue().completeBefore ? (momentJalaali(row.getValue().completeBefore).locale(get_calendarType()).format("D MMM HH:mm")) : "---"} style={{ paddingInlineStart: "12px", paddingInlineEnd: "12px" }} className={styles.ellipsis} />
        </div>
        ),
      },
      {
        accessorKey: 'delivery',
        header: 'DeliveryFullName',
        id: 'DeliveryFullName',
        minSize: 50,
        size: 200,
        cell: (row) => (
          <div style={{ display: 'flex' ,flexDirection:"column",justifyContent:"center" ,alignItems:"start",height:"40px" }}>
          <Typography weight='Body_Small_Regular' textColor='TextDark' text={String(row.getValue().fullName).length > 0 ? row.getValue().fullName : "---"} style={{ paddingInlineStart: "12px", paddingInlineEnd: "12px" }} className={styles.ellipsis} />
        </div>
        ),
      },

      {
        accessorKey: 'delivery',
        header: 'DeliveryPhone',
        id: 'DeliveryPhone',
        minSize: 50,
        size: 200,
        cell: (row) => (
          <div style={{ display: 'flex' ,flexDirection:"column",justifyContent:"center" ,alignItems:"start",height:"40px" }}>
          <Typography weight='Body_Small_Regular' textColor='TextDark' text={String(row.getValue().phone).length > 0 ? row.getValue().phone : "---"} style={{ paddingInlineStart: "12px", paddingInlineEnd: "12px" }} className={styles.ellipsis} />
        </div>
        ),
      },
      {
        accessorKey: 'delivery',
        header: 'DeliveryEmail',
        id: 'DeliveryEmail',
        minSize: 50,
        size: 200,
        cell: (row) => (
          <div style={{ display: 'flex' ,flexDirection:"column",justifyContent:"center" ,alignItems:"start",height:"40px" }}>
          <Typography weight='Body_Small_Regular' textColor='TextDark' text={String(row.getValue().email).length > 0 ? row.getValue().email : "---"} style={{ paddingInlineStart: "12px", paddingInlineEnd: "12px" }} className={styles.ellipsis} />
        </div>
        ),
      },

      {
        accessorKey: 'delivery',
        header: 'DeliveryPodNote',
        id: 'DeliveryPodNote',
        minSize: 50,
        size: 200,
        cell: (row) => (
          <div style={{ display: 'flex' ,flexDirection:"column",justifyContent:"center" ,alignItems:"start",height:"40px" }}>
          <Typography weight='Body_Small_Regular' textColor='TextDark' text={String(row.getValue().podNote).length > 0 ? row.getValue().podNote : "---"} style={{ paddingInlineStart: "12px", paddingInlineEnd: "12px" }} className={styles.ellipsis} />
        </div>
        ),
      },

      {
        accessorKey: 'delivery',
        header: 'DeliveryPodPhoto',
        id: 'DeliveryPodPhoto',
        minSize: 50,
        size: 200,
        cell: (row) => (
          <div style={{ display: 'flex' ,paddingInlineStart:"12px" }}>
          <Button size='Medium' type='Secondary' icon={LINK_CIRCLE} disabled={String(row.getValue().podPhoto).length == 0} onClick={()=>{
                   try {
                    var url = new URL(row.getValue().podPhoto);      
                    window.open(url + "?lng=" + get_lang(), '_blank');
                  } catch (error) { }
          }} />
        </div>
        ),
      },
      {
        accessorKey: 'delivery',
        header: 'DeliveryPodSignature',
        id: 'DeliveryPodSignature',
        minSize: 50,
        size: 200,
        cell: (row) => (
          <div style={{ display: 'flex' ,paddingInlineStart:"12px" }}>
          <Button size='Medium' type='Secondary' icon={LINK_CIRCLE} disabled={String(row.getValue().podSignature).length == 0} onClick={()=>{
                   try {
                    var url = new URL(row.getValue().podSignature);      
                    window.open(url + "?lng=" + get_lang(), '_blank');
                  } catch (error) { }
          }} />
        </div>
        ),
      },

      {
        accessorKey: 'delivery',
        header: 'DeliveryZone',
        id: 'DeliveryZone',
        minSize: 50,
        size: 200,
        cell: (row) => (
          <div style={{ display: 'flex' ,flexDirection:"column",justifyContent:"center" ,alignItems:"start",height:"40px" }}>
          <Typography weight='Body_Small_Regular' textColor='TextDark' text={String(row.getValue().zone?.name).length > 0 ? row.getValue().zone?.name : "---"} style={{ paddingInlineStart: "12px", paddingInlineEnd: "12px" }} className={styles.ellipsis} />
        </div>
        ),
      },
      {
        accessorKey: 'driver',
        header: () => 'Driver Vehicle',

        id: 'driverVehicle',
        minSize: 50,
        cell: (row) => (
          <div style={{ display: 'flex' }}>
            <Tag weight='Body_Small_Regular' textColor='TextDark' text={row.getValue() ? `${row.getValue().vehicle.color} ${row.getValue().vehicle.model} | ${row.getValue().vehicle.plate}` : "---"} style={{ paddingInlineStart: "12px", paddingInlineEnd: "12px" }} className={styles.ellipsis} />
          </div>

        ),
      },

     
      {
        accessorKey: 'driver',
        header: () => 'Driver Username',

        id: 'driverUsername',
        minSize: 50,
        cell: (row) => (
          <div style={{ display: 'flex' }}>
            <Tag weight='Body_Small_Regular' textColor='TextDark' text={row.getValue() ? (String(row.getValue().phone).startsWith("+") ? row.getValue().phone : (String(row.getValue().phone).startsWith("00") ? "+" + String(row.getValue().phone).slice(2, row.getValue().phone.length)  : "" ) ) : "---"} style={{ paddingInlineStart: "12px", paddingInlineEnd: "12px" }} className={styles.ellipsis} />
          </div>

        ),
      },
      {
        accessorKey: 'customerType',
        header: () => 'CustomerType',

        id: 'customerType',
        minSize: 50,
        cell: (row) => (
          <div style={{ display: 'flex' }}>
            <Tag size='Large' backgroundColor='GrayUltraLight' weight='Body_Small_Regular' textColor='TextDark' text={row.getValue()} style={{ paddingInlineStart: "12px", paddingInlineEnd: "12px" }} />
          </div>

        ),
      },

      
    
      {
        accessorKey: 'customer',
        header: () => 'Customer Email',

        id: 'customerEmail',
        minSize: 50,
        cell: (row) => (
          <div style={{ display: 'flex' }}>
            <Tag weight='Body_Small_Regular' textColor='TextDark' text={(String(row.getValue().email).length > 0) ? row.getValue().email : "---"} style={{ paddingInlineStart: "12px", paddingInlineEnd: "12px" }} className={styles.ellipsis} />
          </div>

        ),
      },


      {
        accessorKey: 'paymentProvider',
        header: 'PaymentProvider',

        id: 'paymentProvider',
        minSize: 50,
        cell: (row) => (
          <div style={{ display: 'flex' }}>
            <Typography weight='Body_Small_Regular' textColor='TextDark' text={(row.getValue() && String(row.getValue()).length > 1) ? row.getValue() : "---"} style={{ paddingInlineStart: "12px", paddingInlineEnd: "12px" }} className={styles.ellipsis} />
          </div>

        ),
      },


      
      {
        accessorKey: 'source',
        header: () => 'Source',

        cell: (row) => (
          <div >
            <Tag weight='Body_Small_Medium' backgroundColor='GrayUltraLight' color='TextDark' size='Large' text={row.getValue()} style={{ marginInlineStart: "12px", borderRadius: "8px" }} />
          </div>

        ),
        id: 'source',
        minSize: 50,
      },






      {
        accessorKey: 'customerReceiptUrl',
        header: () => 'Receipt',

        id: 'Receipt',
        minSize: 50,
        maxSize : 70,
        cell: (row) => (
          <div style={{ display: 'flex' ,paddingInlineStart:"12px" }}>
            <Button size='Medium' type='Secondary' icon={LINK_CIRCLE} onClick={()=>{
                     try {
                      var url = new URL(row.getValue());
                      url.searchParams.append("lng", get_lang());
      
                      window.open(url, "_blank");
                    } catch (error) { }
            }} />
          </div>

        ),
      },
      {
        accessorKey: 'shipmentLabelUrl',
        header: () => 'Shipment Label',

        id: 'shipmentLabelUrl',
        minSize: 50,
        maxSize : 120,
        cell: (row) => (
          <div style={{ display: 'flex' ,paddingInlineStart:"12px" }}>
            <Button size='Medium' type='Secondary' icon={LINK_CIRCLE} onClick={()=>{
                     try {
                      var url = new URL(row.getValue());      
                      window.open(url + "?lng=" + get_lang(), '_blank');
                    } catch (error) { }
            }} />
          </div>

        ),
      },
      {
        accessorKey: 'trackOrder',
        header: () => 'Track Order',

        id: 'trackOrder',
        minSize: 50,
        maxSize : 100,
        cell: (row) => (
          <div style={{ display: 'flex' ,paddingInlineStart:"12px" }}>
            <Button size='Medium' type='Secondary' icon={LINK_CIRCLE} onClick={()=>{
                     try {
                      var url = new URL(row.getValue());      
                      window.open(url + "?lng=" + get_lang(), '_blank');
                    } catch (error) { }
            }} />
          </div>

        ),
      },
      // {
      //   accessorKey: 'pickup',
      //   header: 'PickupAddress',

      //   id: 'PickupAddress',
      //   minSize: 50,
      //   size: 200,
      //   cell: (row) => (
      //     <TooltipOnro 
          
      //     tooltipClassName={styles.tooltipClassName} title={<div className={styles.tooltipContainer}>
      //       <Typography weight="Body_Small_Medium" text={row.getValue().address} textColor='TextDark' style={{paddingInlineEnd:"10px",paddingInlineStart:"10px"}} />
      //       <span className={styles.divider} />
      //       <Button size='Medium' type='Secondary' icon={COPY} style={{background:"unset"}}   />
      //       </div>} placement='top'>
      //       <div style={{ display: 'flex' ,flexDirection:"column",justifyContent:"center" ,alignItems:"start",height:"40px" }}>
      //         <Typography weight='Body_Small_Regular' textColor='TextDark' text={row.getValue().address} style={{ paddingInlineStart: "12px", paddingInlineEnd: "12px" }} className={styles.ellipsis} />
      //       </div>
      //     </TooltipOnro>
      //   ),
      // },
  

      






    ]
  } else if ("Pickup" == storeData.OrderList.ActiveOrderType) {
    return [
      {
        id: 'select',
        isFirst: true,
        size: 50,
        minSize: 50,
        maxSize: 50,
        header: ({ table }) => (
          <IndeterminateCheckboxHeader
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
              isheader: true
            }}
          />
        ),
        cell: ({ row }) => (
          <div>
            <IndeterminateCheckbox
              {...{
                checked: row.getIsSelected(),
                disabled: !row.getCanSelect(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
                style: { marginInlineStart: "16px" },
                isheader: false,
                ...row
              }}

            /></div>
        ),
      },
      {
        accessorKey: 'code',
        header: 'OrderID',
        id: 'OrderID',
        minSize: 50,
        cell: (row) => (
          <div>
            <Typography weight='Body_Small_Regular' textColor='TextDark' text={row.getValue()} style={{ paddingInlineStart: "12px", paddingInlineEnd: "12px" }} />
          </div>

        ),
      },
      {
        accessorKey: 'createdAt',
        header: 'CreatedAt',

        id: 'createdAt',
        minSize: 50,
        cell: (row) => (
          <div style={{ display: 'flex' }}>
            <Typography weight='Body_Small_Regular' textColor='TextDark' text={momentJalaali(row.getValue()).locale(get_calendarType()).format("D MMM HH:mm")} style={{ paddingInlineStart: "12px", paddingInlineEnd: "12px" }} className={styles.ellipsis} />
          </div>

        ),
      },



      {
        accessorKey: 'pickup',
        header: 'PickupAddress',

        id: 'PickupAddress',
        minSize: 50,
        size: 200,
        cell: (row) => (
          <TooltipOnro tooltipClassName={styles.tooltipClassName} title={<div className={styles.tooltipContainer}>
          <Typography weight="Body_Small_Medium" text={row.getValue().address} textColor='TextDark' style={{paddingInlineEnd:"10px",paddingInlineStart:"10px"}} />
          <span className={styles.divider} />
          <Button size='Medium' type='Secondary' icon={COPY} style={{background:"unset"}}   />
          </div>} placement='top'>
          <div style={{ display: 'flex' ,flexDirection:"column",justifyContent:"center" ,alignItems:"start",height:"40px" }}>
            <Typography weight='Body_Small_Regular' textColor='TextDark' text={row.getValue().address} style={{ paddingInlineStart: "12px", paddingInlineEnd: "12px" }} className={styles.ellipsis} />
          </div>
        </TooltipOnro>

        ),
      },
      {
        accessorKey: 'driver',
        header: () => 'Driver',

        id: 'driver',
        minSize: 50,
        cell: (row) => (
          <div style={{ display: 'flex' }}>
            <Tag weight='Body_Small_Regular' textColor='TextDark' text={row.getValue() ? row.getValue().name : "---"} style={{ paddingInlineStart: "12px", paddingInlineEnd: "12px" }} className={styles.ellipsis} />
          </div>

        ),
      },
      {
        accessorKey: 'status',
        header: () => 'Status',

        id: 'Status',
        minSize: 50,
        cell: (row) => (
          <div style={{ display: 'flex' }}>
            <Tag weight='Body_Small_Medium' backgroundColor='GrayUltraLight' color='TextDark' size='Large' text={row.getValue()} className={styles[row.getValue()]} />
          </div>

        ),
      },
      {
        accessorKey: 'type',
        header: () => 'OrderType',

        cell: (row) => (
          <div >
            <Tag weight='Body_Small_Medium' backgroundColor='GrayUltraLight' color='TextDark' size='Large' text={row.getValue()} style={{ marginInlineStart: "12px", borderRadius: "8px" }} />
          </div>

        ),
        id: 'OrderType',
        minSize: 50,

      },
    ]
  } else {
    return [
      {
        id: 'select',
        isFirst: true,
        size: 50,
        minSize: 50,
        maxSize: 50,
        header: ({ table }) => (
          <IndeterminateCheckboxHeader
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
              isheader: true
            }}
          />
        ),
        cell: ({ row }) => (
          <div>
            <IndeterminateCheckbox
              {...{
                checked: row.getIsSelected(),
                disabled: !row.getCanSelect(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
                style: { marginInlineStart: "16px" },
                isheader: false,
                ...row,
              }}

            /></div>
        ),
      },
      {
        accessorKey: 'code',
        header: 'OrderID',
        id: 'OrderID',
        minSize: 50,
        cell: (row) => (
          <div>
            <Typography weight='Body_Small_Regular' textColor='TextDark' text={row.getValue()} style={{ paddingInlineStart: "12px", paddingInlineEnd: "12px" }} />
          </div>

        ),
      },
      {
        accessorKey: 'createdAt',
        header: 'CreatedAt',

        id: 'createdAt',
        minSize: 50,
        cell: (row) => (
          <div style={{ display: 'flex' }}>
            <Typography weight='Body_Small_Regular' textColor='TextDark' text={momentJalaali(row.getValue()).locale(get_calendarType()).format("D MMM HH:mm")} style={{ paddingInlineStart: "12px", paddingInlineEnd: "12px" }} className={styles.ellipsis} />
          </div>

        ),
      },
      {
        accessorKey: 'dropoff',
        header: 'DropoffAddress',

        id: 'PickupAddress',
        minSize: 50,
        size: 200,
        cell: (row) => (
          <TooltipOnro tooltipClassName={styles.tooltipClassName} title={<div className={styles.tooltipContainer}>
          <Typography weight="Body_Small_Medium" text={row.getValue().address} textColor='TextDark' style={{paddingInlineEnd:"10px",paddingInlineStart:"10px"}} />
          <span className={styles.divider} />
          <Button size='Medium' type='Secondary' icon={COPY} style={{background:"unset"}}   />
          </div>} placement='top'>
          <div style={{ display: 'flex' ,flexDirection:"column",justifyContent:"center" ,alignItems:"start",height:"40px" }}>
            <Typography weight='Body_Small_Regular' textColor='TextDark' text={row.getValue().address} style={{ paddingInlineStart: "12px", paddingInlineEnd: "12px" }} className={styles.ellipsis} />
          </div>
        </TooltipOnro>

        ),
      }, {
        accessorKey: 'driver',
        header: () => 'Driver',

        id: 'driver',
        minSize: 50,
        cell: (row) => (
          <div style={{ display: 'flex' }}>
            <Tag weight='Body_Small_Regular' textColor='TextDark' text={row.getValue() ? row.getValue().name : "---"} style={{ paddingInlineStart: "12px", paddingInlineEnd: "12px" }} className={styles.ellipsis} />
          </div>

        ),
      }, {
        accessorKey: 'status',
        header: () => 'Status',

        id: 'Status',
        minSize: 50,
        cell: (row) => (
          <div style={{ display: 'flex' }}>
            <Tag weight='Body_Small_Medium' backgroundColor='GrayUltraLight' color='TextDark' size='Large' text={row.getValue()} className={styles[row.getValue()]} />
          </div>

        ),
      }, {
        accessorKey: 'type',
        header: () => 'OrderType',

        cell: (row) => (
          <div >
            <Tag weight='Body_Small_Medium' backgroundColor='GrayUltraLight' color='TextDark' size='Large' text={row.getValue()} style={{ marginInlineStart: "12px", borderRadius: "8px" }} />
          </div>

        ),
        id: 'OrderType',
        minSize: 50,

      },
    ]
  }


};


const defaultColumns = [
  {
    id: 'select',
    isFirst: true,
    size: 50,
    minSize: 50,
    maxSize: 50,
    header: ({ table }) => (
      <IndeterminateCheckboxHeader
        {...{
          checked: table.getIsAllRowsSelected(),
          indeterminate: table.getIsSomeRowsSelected(),
          onChange: table.getToggleAllRowsSelectedHandler(),
          isheader: true
        }}
      />
    ),
    cell: ({ row }) => (
      <div>
        <IndeterminateCheckbox
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
            style: { marginInlineStart: "16px" },
            isheader: false
          }}

        /></div>
    ),
  },
  {
    accessorKey: 'OrderID',
    header: 'OrderID',
    id: 'OrderID',
    minSize: 50,
    cell: (row) => (
      <div>
        <Typography weight='Body_Small_Regular' textColor='TextDark' text={row.getValue()} style={{ paddingInlineStart: "12px", paddingInlineEnd: "12px" }} />
      </div>

    ),
  },
  {
    accessorKey: 'OrderType',
    cell: (row) => (
      <div >
        <Tag weight='Body_Small_Medium' backgroundColor='GrayUltraLight' color='TextDark' size='Large' text={row.getValue()} style={{ marginInlineStart: "12px", borderRadius: "8px" }} />
      </div>

    ),
    id: 'OrderType',
    minSize: 50,

  },
  {
    accessorFn: row => row.Stage,
    id: 'Stage',
    header: () => <span>Stage</span>,
    cell: (row) => (
      <div >
        <Tag weight='Body_Small_Medium' backgroundColor='GrayUltraLight' color='TextDark' size='Large' text={row.getValue()} style={{ marginInlineStart: "12px", borderRadius: "8px" }} />
      </div>

    ),
    minSize: 50,
    maxSize: 90
  },



  {
    accessorKey: 'Status',
    header: () => 'Status',

    id: 'Status',
    minSize: 50,
    cell: (row) => (
      <div style={{ display: 'flex' }}>
        <Tag weight='Body_Small_Medium' backgroundColor='GrayUltraLight' color='TextDark' size='Large' text={row.getValue()} className={styles[row.getValue()]} />
      </div>

    ),
  },

  {
    accessorKey: 'Route',
    header: () => <span>Route</span>,

    id: 'Route',
    minSize: 50,
    cell: (row) => (
      <div style={{ display: 'flex' }}>
        <Typography weight='Body_Small_Regular' textColor='TextDark' text={row.getValue()} style={{ paddingInlineStart: "12px", paddingInlineEnd: "12px" }} className={styles.ellipsis} />
      </div>

    ),
  },

  {
    accessorKey: 'CreatedAt',
    header: 'CreatedAt',

    id: 'createdAt',
    minSize: 50,
    cell: (row) => (
      <div style={{ display: 'flex' }}>
        <Typography weight='Body_Small_Regular' textColor='TextDark' text={row.getValue()} style={{ paddingInlineStart: "12px", paddingInlineEnd: "12px" }} className={styles.ellipsis} />
      </div>

    ),
  },
  {
    accessorKey: 'PickupAddress',
    header: 'PickupAddress',

    id: 'PickupAddress',
    minSize: 50,
    size: 200,
    cell: (row) => (
      <div style={{ display: 'flex' }}>
        <Typography weight='Body_Small_Regular' textColor='TextDark' text={row.getValue()} style={{ paddingInlineStart: "12px", paddingInlineEnd: "12px" }} className={styles.ellipsis} />
      </div>

    ),
  }, {
    accessorKey: 'PickupDriver',
    header: 'PickupDriver',
    cell: (row) => (
      <div>
        <Typography weight='Body_Small_Regular' textColor='TextDark' text={row.getValue()} style={{ paddingInlineStart: "12px", paddingInlineEnd: "12px" }} className={styles.ellipsis} />
      </div>

    ),
    id: 'PickupDriver',
    minSize: 50,
  }, {
    accessorKey: 'DeliveryAddress',
    header: 'DeliveryAddress',

    id: 'DeliveryAddress',
    size: 200,
    minSize: 50,
    cell: (row) => (
      <div style={{ display: 'flex' }}>
        <Typography weight='Body_Small_Regular' textColor='TextDark' text={row.getValue()} style={{ paddingInlineStart: "12px", paddingInlineEnd: "12px" }} className={styles.ellipsis} />
      </div>

    ),
  }, {
    accessorKey: 'DeliveryDriver',
    header: 'DeliveryDriver',
    cell: (row) => (
      <div style={{ display: 'flex' }}>
        <Typography weight='Body_Small_Regular' textColor='TextDark' text={row.getValue()} style={{ paddingInlineStart: "12px", paddingInlineEnd: "12px" }} className={styles.ellipsis} />
      </div>

    ),
    id: 'DeliveryDriver',
    minSize: 50,
  }, {
    accessorKey: 'Schedule',
    header: 'Schedule',
    cell: (row) => (
      <div style={{ display: 'flex' }}>
        <Typography weight='Body_Small_Regular' textColor='TextDark' text={row.getValue()} style={{ paddingInlineStart: "12px", paddingInlineEnd: "12px" }} className={styles.ellipsis} />
      </div>

    ),
    id: 'Schedule',
    minSize: 50,
  },


]



function IndeterminateCheckbox({
  indeterminate,
  className = '',
  checked,
  onChange,
  disabled,
  style,
  isheader,
  ...row

}) {
  const ref = React.useRef(null)
  const dispatch = useDispatch();
  const data = useSelector((state) => state.Table.list.orders)
  const selectedOrders = useSelector((state) => state.Table.selectedOrders)


  const onChangex = useCallback((e) => {

    if (row?.original) {
      if (ischecked()) {
        dispatch(Table_DeSelectOrder(row.original.id))
      } else {
        dispatch(Table_SelectOrder(row.original))
      }
    } else {
      return false
    }
  }, [data, row]);



  const ischecked = useCallback((e) => {

    console.log(" ischecked ", row)
    if (row) {
      if (row.original) {
        return selectedOrders[row.original.id]
      } else {
        return false
      }
    } else {
      return false
    }

  }, [data, row]);

  return (
    <Checkbox size='Small' state={ischecked() ? "checked" : "unchecked"} onClick={(e) => { onChangex(e) }} style={{ marginLeft: "16px" }} />

  )
}


function IndeterminateCheckboxHeader({
  indeterminate,
  className = '',
  checked,
  onChange,
  disabled,
  style,
  isheader,
  ...row

}) {
  const ref = React.useRef(null)
  const dispatch = useDispatch();
  const data = useSelector((state) => state.Table.list.orders)
  const selectedOrders = useSelector((state) => state.Table.selectedOrders)

  const checkAll = useCallback((e) => {
    dispatch(Table_SelecteMultiOrders(data))

  }, [data]);

  const uncheckAll = useCallback((e) => {
    dispatch(Table_ClearSelectedOrders())
  }, [data]);



  const allischecked = useCallback((e) => {

    let all = true

    data.forEach((key2) => {
      if (!selectedOrders[key2.id]) {

        all = false;
      }
    })

    return all

  }, [data, selectedOrders]);


  const someischecked = useCallback((e) => {

    let all = false

    data.forEach((key2) => {
      if (selectedOrders[key2.id]) {

        all = true;
      }
    })

    return all

  }, [data, selectedOrders]);

  return (

    <Checkbox size='Small' state={(!allischecked() && someischecked()) ? "indeterminate" : (allischecked() ? "checked" : "unchecked")} onClick={(e) => { allischecked() ? uncheckAll(e) : checkAll(e) }} style={{ marginLeft: "3px" }} />

  )
}