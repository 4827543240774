import Blinkdss from "../../../assets/Blinkds_logotype.png";
import FavIcon from "../../../assets/PROJECTS/Blinkds.png";

export default function Blinkds() {
    
    return global.config = {
    "ProjectName": "Blink Delivery Software",
    "LogoType": Blinkdss,
    "BaseApi": "https://rest.blinkds.com",
    "BaseSocket": "https://socket.blinkds.com",
    "BaseTrack": "https://track.blinkds.com",
    "MapCenter": {
        "lat": "25.20748918126513",
        "lng": "55.27108994012899"
    },
    "Lang": "en",
    "Direction": "ltr",
    "AllLanguages": [{
        "lang": "en",
        "direction": "ltr",
        "i18Lang": "English"
    }],
    "FavIcon": FavIcon,
    "Country": "AE",
    "thirdPartyLoginSupport": true  ,
    mapType :"osm", /* osm | google */ 
    googleApiKey : ""
} }