import JPCarrierLogo from "../../../assets/JPCarrierlogotype.png";
import FavIcon from "../../../assets/PROJECTS/JPCarrier.png";

export default function JPCarrier() {
    
    return  global.config = {
    "ProjectName": "JPCarrier",
    "LogoType": JPCarrierLogo,
    "BaseApi": "https://rest.jpcarrier.ca",
    "BaseSocket": "https://socket.jpcarrier.ca",
    "BaseTrack": "https://tr.jpcarrier.ca",
    "MapCenter": {
        "lat": "43.82618767737808",
        "lng": "-79.55656010096088"
    },
    "Lang": "en",
    "Direction": "ltr",
    "AllLanguages": [{
        "lang": "en",
        "direction": "ltr",
        "i18Lang": "English"
    },{
        "lang": "fr",
        "direction": "ltr",
        "i18Lang": "French"
    }],
    "FavIcon": FavIcon,
    "Country": "CA",
    "thirdPartyLoginSupport": true  ,
    mapType :"osm", /* osm | google */ 
    googleApiKey : ""
}}