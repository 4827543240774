import { RefreshToken } from "../../helper/api";
import { get_adminToken, get_id, get_refreshToken, reset, set_accessToken, set_name, set_refreshToken, set_username } from "../../helper/UserData/userdate";
import {restClient} from "./axios";
import { LoginApi } from "./login";

export class DispatcherApi {
  
  async Setting({userId ,refreshToken}) {
    try {
      const response = await restClient.request({
        method : 'post',
        url: '/api/v1/dispatcher/setting',
        data: {
          userId: userId ? userId : get_id(),
          refreshToken: refreshToken ? refreshToken : get_refreshToken(),
        }
      });

      console.log("tttt DispatcherApi Setting ",response)


      if(!response.data.data.role?.dispatcher?.base){
          if(!response.data.data.role?.haveAdminAccess){
            reset()
          }else{
            // const loginApi = new LoginApi();                    
            // const Dashboardlogin = await loginApi.Dashboardlogin(get_adminToken());
            window.location.replace(`${window.location.origin}/${'admin/home'}`);
          }
      }else{
        // if(response.data.data.role?.haveAdminAccess){
          // const loginApi = new LoginApi();                    
          // const Dashboardlogin = await loginApi.Dashboardlogin(get_adminToken());
        // }
      }
      
      return {
        applicationMap : response.data.data.applicationMap,
        // applicationMap : {...response.data.data.applicationMap,
        //     type:"osm"
        //  },
        role : response.data.data.role,
        dispatcherHomeMessage : response.data.data.dispatcherHomeMessage,
        calendarType : response.data.data.calendarType,
        currencyCode : response.data.data.currencyCode,
        currencyFormat : response.data.data.currencyFormat,
        defaultLocation : response.data.data.defaultLocation,
        isVoipActvie: response.data.data.isVoipActvie,
        orderTypes: response.data.data.orderTypes,
        storageBaseUrl: response.data.data.storageBaseUrl,
        stripePublicApiKey: response.data.data.stripePublicApiKey,
        isLocalAddressActive: response.data.data.isLocalAddressActive,
        stripeMoto: response.data.data.stripeMoto,
      }

    } catch (error) {
      console.log("tttt DispatcherApi Setting error",error)

      if (error.response && error.response.status === 403) {
        localStorage.clear()
        window.location.reload();
      }else if(error.response &&  error.response.status === 401 && error.response.data.code == "-9"){
        // RefreshToken(() => {
        //   const Dispatcher = new DispatcherApi();
        //   Dispatcher.Setting({ userId: get_id(), refreshToken: get_refreshToken() })
        // });
      }else if(error.response){
        return Promise.reject({
          code : error.response.data.code,
          message: error.response.data.message ,
          status :error.response.status ,
        })
      }else {
        return Promise.reject()
      }
    }


  }

  async Account({userId ,refreshToken}){
    try {
      const response = await restClient.request({
        method : 'get',
        url: '/api/v1/dispatcher/account/' + get_id(),
        params: {
          userId: userId ? userId : get_id(),
          refreshToken: refreshToken ? refreshToken : get_refreshToken(),
        }
      });

      set_username(response.data.data.username );
      set_name(response.data.data.name);

      return {
        username : response.data.data.username ,
        name : response.data.data.name
      }

    } catch (error) {
      if (error.response && error.response.status === 403) {
        localStorage.clear()
        window.location.reload();
      }
      
      if(error.response){
        return Promise.reject({
          code : error.response.data.code,
          message: error.response.data.message ,
          status :error.response.status ,
        })
      }else {
        return Promise.reject()
      }
    }

  }

  async FailureReason(params){
    try {
      const response = await restClient.request({
        method : 'get',
        url: '/api/v1/dispatcher/failure-reason',
        params: params
      });

      return response.data.data


    } catch (error) {
      if (error.response && error.response.status === 403) {
        localStorage.clear()
        window.location.reload();
      }
      
      if(error.response){
        return Promise.reject({
          code : error.response.data.code,
          message: error.response.data.message ,
          status :error.response.status ,
        })
      }else {
        return Promise.reject()
      }
    }

  }
  async AutocompleteDriver(params){
    try {
      const response = await restClient.request({
        method : 'get',
        url: `/api/v1/dispatcher/autocomplete/driver`,
        params: params
      });

      return response.data.data


    } catch (error) {
      if (error.response && error.response.status === 403) {
        localStorage.clear()
        window.location.reload();
      }
      
      if(error.response){
        return Promise.reject({
          code : error.response.data.code,
          message: error.response.data.message ,
          status :error.response.status ,
        })
      }else {
        return Promise.reject()
      }
    }

  }
}
