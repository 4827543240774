import * as React from 'react'
import styles from './style.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Typography from '../../../design-system/lib/Typography';
import { COD, FILTER_1, HASHTAG, notes } from '../../../assets';
import IconProvider from '../../../design-system/lib/IconProvider';
import Input from '../../../design-system/lib/Input';
import { SetCodAmount, SetNotesOrder, SetReferenceID } from '../../../Redux/actions/actionNames';


function Others() {
  const [dialoge, setDialoge] = React.useState(null);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const CODV = useSelector((state) => state.NewOrder.newOrder.codAmount)
  const REFREV = useSelector((state) => state.NewOrder.newOrder.referenceID)
  const activeVehicleType = useSelector((state) => state.NewOrder.newOrder.service.activeVehicleType)
  const orderTypeActive = useSelector((state) => state.NewOrder.newOrder.orderTypeActive)
  const notess = useSelector((state) => state.NewOrder.newOrder.notes)


  const CODinputOnchange = (e) => {
    dispatch(SetCodAmount(e.target.value? e.target.value : ""))
  }

  const inputOnchange = (e) => {
    dispatch(SetReferenceID(e.target.value? e.target.value : ""))
  }
  const notesOnchange = (e) => {
    dispatch(SetNotesOrder(e.target.value? e.target.value : ""))
  }

  if ((activeVehicleType && activeVehicleType.setting.isCODActive) || (activeVehicleType && activeVehicleType.setting.isReferenceIdActive) ) {
    return (
      <div className={styles.otherContainer}>
        <div className={styles.firstRow}>
          <IconProvider Icon={FILTER_1} size="16" />
          <Typography weight='Body_Middle_Bold' text={t("Other")} />
        </div>
        <div className={styles.secondRow}>
          {(activeVehicleType && activeVehicleType.setting.isCODActive) && <Input containerStyle={{width:"calc(50% - 8px)"}} type="number" value={CODV} placeHolder={t("CashOnDelivery")} onChange={CODinputOnchange} iconStart={COD} />
          }
          {(activeVehicleType && activeVehicleType.setting.isReferenceIdActive) && <Input type="text"  containerStyle={{width:"calc(50% - 5px)"}}  value={REFREV} placeHolder={t("ReferenceID")} onChange={inputOnchange} iconStart={HASHTAG} />
          }

          {["PickupDelivery"].indexOf(
            orderTypeActive
          ) > -1 && <Input type="text"  containerStyle={{width:"calc(50% - 9px)"}}  value={notess} placeHolder={t("Notes")} IconStyle={{width:"16px"}} onChange={notesOnchange} iconStart={notes} />
          }
        </div>
      </div>
    )
  }

  return null
}

export default Others