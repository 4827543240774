import { Component } from "react";
import { connect } from "react-redux";
import Pickup from "./pickup";



class App extends Component {

  render() {

    return <Pickup />

  }
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
