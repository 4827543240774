import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import SignOut from "../../../../../../assets/icon-ic-sign-out-menu.svg";
import { LogoutHttp } from "../../../../../../helper/api";


class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openMenu: false,
    };
  }

  handleOpen = () => {
    this.setState({ openMenu: !this.state.openMenu });
  };

  render() {
    return (
      <React.Fragment>
        <span onClick={this.handleOpen} style={{ width: "100%", float: "left", marginTop: "24px" }}>
          <div className="SignOutContainer">
            <img className="SignOut" src={SignOut} />
            <span className="LogoutText">
              {" "}
              <Trans i18nKey={"Logout"}> </Trans>
            </span>
          </div>
        </span>


        <Dialog
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          open={this.state.openMenu}
          onClose={this.handleOpen}
          className="logoutContainer"
          disableAutoFocus
          disableEnforceFocus 
          disableRestoreFocus 
        >
          {" "}
          <p
            className="logotTitle"
          >
             <Trans i18nKey={"logotTitle"}> </Trans>
          </p>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              className="logoutDescription"
            >
               <Trans i18nKey={"logoutDescription"}> </Trans>
            </DialogContentText>
          </DialogContent>
          <div style={{ direction: "ltr", height: "42px" }}>
            <a href="/logout" style={{textDecoration:"none"}}><span
              onClick={() => {
                LogoutHttp()
              }}
              className="confirmLogout"
              variant="contained"
            >
              <Trans i18nKey={"confirmLogout"}> </Trans>
            </span></a>
            <span
              onClick={this.handleOpen}
              variant="contained"
              className="skipLogout"

            >
               <Trans i18nKey={"skipLogout"}> </Trans>
            </span>
          </div>
        </Dialog>

      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
