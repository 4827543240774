import React from "react";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";
import proccessing from "./../../../../assets/proccessing.svg";
import refresh from "./../../../../assets/refresh_bulk.svg";

import { BulkImportList, BulkImportGetInfo } from "../../../../helper/api";
import { SetActiveImport, Remove_draft_bulkImport } from "../../../../Redux/actions/actionNames";



class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
        this.ssss = null
    }

    componentDidMount() {
        // if (this.props.ActiveItem.fromServer) {
        //     this.ssss = setTimeout(() => {
        //         this.props.dispatch(SetActiveImport(null, null))
        //         this.props.dispatch(Remove_draft_bulkImport(this.props.ActiveItem.id))
        //         BulkImportList(this.props.dispatch, null, null)
        //     }, 5000);
        // }

    }
    componentWillUnmount() {
        // if (this.ssss) {
        //     clearTimeout(this.ssss)
        // }
    }


    refresh=()=>{
        BulkImportGetInfo(this.props.dispatch,this.props.ActiveItem.id)
    }

    render() {
        let { t } = this.props;
        return (

            <div style={{ width: "100%", height: "100%", float: "left", display: "flex", justifyContent: "center", flexDirection: "column" }}>
                <div className="uploadingCsvConatiner proccessingConatiner">
                    <img src={proccessing} />
                    <p className="Fileisproccessing"> {t("Fileisproccessing")} </p>
                    <p className="proccessingDesc"> {t("proccessingDesc")} </p>

                    <div className="refreshBulk" onClick={this.refresh}>
                            <img src={refresh} />
                            <span> {t("refresh")} </span>
                    </div>    

                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({

});
const mapDispatchToProps = (dispatch) => ({
    dispatch: dispatch,
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation()(App));
