import momentJalaali from "moment-jalaali";
import { AddMonitoringOrdersBounding } from "../../../Redux/actions/actionNames";
import { store } from "../../../Redux/store";
import { OrderApi } from "../../../service/API/order";
import { DriverApi } from "../../../service/API/driver";

const _ = require("lodash");

export const getOrderMonitoringBounding = async (order) => {


  if(order.driverId){
    let Driver = new DriverApi()
    let driverData = await Driver.Info(order.driverId)

    store.dispatch(AddMonitoringOrdersBounding({ordersBoundingList : [{...order}] , ordersBoundingDriver: driverData.data},"16"))

  }else{

    store.dispatch(AddMonitoringOrdersBounding({ordersBoundingList : [{...order}] , ordersBoundingDriver: null},"20"))

  }

};

