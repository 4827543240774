import { Component } from "react";
import { connect } from "react-redux";
import { CalculatePriceWorker } from "../../../helper/worker/CalculatePriceWorker";
import { DetectOndemandOrReserve } from "../../../helper/worker/DetectOndemandOrReserve";
import { CalculatePrice_Delivery_Worker } from "../../../helper/worker/CalculatePrice_Delivery_Worker";
import { CanOrderCalculateTravelCost } from "../../../helper/worker/CanOrderCalculateTravelCost";
import momentJalaali from "moment-jalaali";
import { EdittPriceNewOrderChanged, EdittPriceNewOrder_manualy } from "../../../Redux/actions/actionNames";

class App extends Component {
  componentDidMount() {
    CalculatePrice_Delivery_Worker(
      {
        // customerType:this.props.person ,

        serviceactiveVehicleType: this.props.serviceactiveVehicleType,
        serviceoptions: this.props.serviceoptions,
        auto: this.props.auto,
        driveroptions: this.props.driveroptions,
        auto_activeVehicleType: this.props.auto_activeVehicleType,
        manual_activeVehicleType:
          this.props.manual_activeVehicleType,
        dropOff: {
          address: this.props.dropOff.address,
          scheduleDateAfter: (this.props.dropOff.scheduleDateAfter && momentJalaali(
            this.props.dropOff.scheduleDateAfter
          ).isValid())
            ? momentJalaali(
                Number(this.props.dropOff.scheduleDateAfter + "000")
              ).unix()
            : 0,
          scheduleDateBefore: (this.props.dropOff.scheduleDateBefore && momentJalaali(
            this.props.dropOff.scheduleDateBefore
          ).isValid())
            ? momentJalaali(
                Number(this.props.dropOff.scheduleDateBefore + "000")
              ).unix()
            : 0,
          // senderFullName: this.props.pickup.senderFullName,
          // senderPhoneNumber: this.props.pickup.senderPhoneNumber,
          // unit: this.props.pickup.unit,
        },
        dropOffadresss_loc: this.props.dropOffadresss_loc,
        isScheduled:DetectIsSchedule(this.props.dropOff)

      },
      this.props.dispatch
    );
  }

  componentWillReceiveProps(nextProps) {
    
    CanOrderCalculateTravelCost(
      {
        // customerType:this.props.person ,
        calculatedPrice: (this.props.Pricechanged == false && (nextProps.Pricechanged !== this.props.Pricechanged)) ? true : false,

        serviceactiveVehicleType: this.props.serviceactiveVehicleType,
        serviceoptions: this.props.serviceoptions,
        driveroptions: this.props.driveroptions,
        auto_activeVehicleType: this.props.auto_activeVehicleType,
        manual_activeVehicleType: this.props.manual_activeVehicleType,
          dropOffadresss_loc: this.props.dropOffadresss_loc,
      },
      {
        // customerType:nextProps.person ,
        calculatedPrice: (nextProps.Pricechanged == true && (nextProps.Pricechanged !== this.props.Pricechanged)) ? false : false,

        serviceactiveVehicleType: nextProps.serviceactiveVehicleType,
        serviceoptions: nextProps.serviceoptions,
        driveroptions: nextProps.driveroptions,
        auto_activeVehicleType: nextProps.auto_activeVehicleType,
        manual_activeVehicleType: nextProps.manual_activeVehicleType,
          dropOffadresss_loc: nextProps.dropOffadresss_loc,
      },
      (call) => {
        

        if (call) {
          CalculatePrice_Delivery_Worker(
            {
              // customerType:nextProps.person,
              PREV : (nextProps.manually_changed && nextProps.PriceCalculated) ? {
                surcharges: nextProps.PriceCalculated.surcharges.map((surchargesItem)=>{
                  return        {
                    id: surchargesItem.id,
                    surchargeId: surchargesItem.surchargeId,
                    price: surchargesItem.price
                  }
                }) ,
                baseFarePrice: nextProps.PriceCalculated.baseFarePrice,
                tax: nextProps.PriceCalculated.tax,
              } : null,
              
              serviceactiveVehicleType: nextProps.serviceactiveVehicleType,
              serviceoptions: nextProps.serviceoptions,
              auto: nextProps.auto,
              driveroptions: nextProps.driveroptions,
              auto_activeVehicleType: nextProps.auto_activeVehicleType,
              manual_activeVehicleType:
                nextProps.manual_activeVehicleType,
                dropOff: {
                address: nextProps.dropOff.address,
                scheduleDateAfter: (nextProps.dropOff.scheduleDateAfter && momentJalaali(
                  nextProps.dropOff.scheduleDateAfter
                ).isValid())
                  ? momentJalaali(
                      Number(nextProps.dropOff.scheduleDateAfter + "000")
                    ).unix()
                  : 0,
                scheduleDateBefore: (nextProps.dropOff.scheduleDateBefore && momentJalaali(
                  nextProps.dropOff.scheduleDateBefore
                ).isValid())
                  ? momentJalaali(
                      Number(nextProps.dropOff.scheduleDateBefore + "000")
                    ).unix()
                  : 0,
                // senderFullName: nextProps.pickup.senderFullName,
                // senderPhoneNumber: nextProps.pickup.senderPhoneNumber,
                // unit: nextProps.pickup.unit,
              },
              dropOffadresss_loc: nextProps.dropOffadresss_loc,
              isScheduled:DetectIsSchedule(nextProps.dropOff)
            },
            this.props.dispatch
          );

          this.props.dispatch(EdittPriceNewOrderChanged(false))
          if(nextProps.manually_changed){
            this.props.dispatch(EdittPriceNewOrder_manualy(false))
          }
        }
      }
    );
  }

  render() {
    return null;
  }
}

const mapStateToProps = (state) => ({
  person: state.NewOrder.newOrder.customer.type || null,
  serviceactiveVehicleType: state.NewOrder.newOrder.service.activeVehicleType,
  serviceoptions: state.NewOrder.newOrder.service.options,

  auto: state.NewOrder.newOrder.driver.auto,
  driveroptions: state.NewOrder.newOrder.driver.options,
  auto_activeVehicleType:
    state.NewOrder.newOrder.driver.auto_driver.auto_activeVehicleType,
  manual_activeVehicleType:
    state.NewOrder.newOrder.driver.manual_driver.manual_activeVehicleType,
  dropOff: state.NewOrder.newOrder.dropOffs.firstDropOff,
  dropOffadresss_loc: state.NewOrder.newOrder.dropOffs.firstDropOff.adresss_loc,

  Pricechanged: state.NewOrder.newOrder.price.changed,
  PriceCalculated: state.NewOrder.newOrder.price.price,
  customer: state.NewOrder.newOrder.customer,
  manually_changed: state.NewOrder.newOrder.price.changed,

});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(App);



function DetectIsSchedule(dropOff) {
  if ( (Number(dropOff.scheduleDateBefore) > 0 ||  dropOff.scheduleDateBefore != null ) || ( Number(dropOff.scheduleDateAfter || dropOff.scheduleDateAfter != null )) > 0) {
    return true
  } else {
    return false

  }

}
