import FAMb from "../../../assets/FAMlogotype.png";
import FavIcon from "../../../assets/PROJECTS/FAM.png";


export default function FAM() {

    return  global.config = {
    "ProjectName": "F.A.M. Valet",
    "LogoType": FAMb,
    "BaseApi": "https://rest.famvalet.com",
    "BaseSocket": "https://socket.famvalet.com",
    "BaseTrack": "https://admin.famvalet.com",
    "MapCenter": {
        "lat": "34.08419623458191",
        "lng": "-118.24144442934359"
    },
    "Lang": "en",
    "Direction": "ltr",
    "AllLanguages": [{
        "lang": "en",
        "direction": "ltr",
        "i18Lang": "English"
    }],
    "FavIcon": FavIcon,
    "Country": "US",
    "thirdPartyLoginSupport": true  ,
    mapType :"osm", /* osm | google */ 
    googleApiKey : ""
}}