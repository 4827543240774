import * as actionTypes from "../actions/actionTypes";

const init = {

  InCommingCall: null,

  SelectedInCommingCallAction: {
    type : null ,/* "Search" | "NewOrder" */ 
    Data : null /* InCommingCall */
  },


};
export const Voip = (state = init, action) => {
  switch (action.type) {
    case actionTypes.setVoipIncommingCallAction :
      return {
          ...state,
          SelectedInCommingCallAction :action.state
      };
      case actionTypes.setVoipIncommingCall :
        return {
            ...state,
            InCommingCall :action.state
        };
    default:
      return state;
  }
};

export default Voip;
