import Axios from 'axios';
import { throttle, isEmpty } from 'lodash';
import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get_accessToken , resetToLogin } from '../helper/UserData/userdate';
import {  Redirect } from "react-router-dom";
import { Set_AccessToken } from '../Redux/actions/actionNames';


export function Logout() {
  const dispatch = useDispatch();
  const [islogouted, setIslogouted] = useState(false);

  useEffect(() => {
    dispatch(Set_AccessToken(null));

    Axios.get(`${window.location.origin}${'/admin/auth/logout'}`)
      .then((response) => {
        setIslogouted(true)
      })
      .catch((error) => {
        setIslogouted(true)
      })

      resetToLogin()

  }, [])



if(islogouted){
    return <Redirect
  to={"/login"}
/>
}else {
  return <div style={{ float: "left", marginLeft: "calc(50vw - 40px)", marginTop: 'calc(50vh - 40px)' }}>
      <div class="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
      </div>
  </div>


}

};




/*
import { throttle, isEmpty } from 'lodash';
import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';


export function useMonitoringPin(orderId, orderDriverId ,id) {
  const [orderIsSelected, setOrderIsSelected] = useState(true);
  const [orderPopUp, setOrderPopUp] = useState(false);
  const selectedOrder = useSelector((state) => state.Monitoring.selectedOrder)
  const selectedOrderDriver = useSelector((state) => state.Monitoring.selectedOrderDriver)
  const OrderPinPopUp = useSelector((state) => state.Monitoring.OrderPinPopUp)

  const deboucesSetOrderIsSelected = throttle(value => {
    setOrderIsSelected(value)
  }, 40);
  const deboucesSetOrderPopUp = throttle(value => {
    setOrderPopUp(value)
  }, 40);

  const SetOrderIsSelected = useCallback(value => deboucesSetOrderIsSelected(value), []);
  const SetOrderPopUp = useCallback(value => deboucesSetOrderPopUp(value), []);


  useEffect(() => {
    if (((selectedOrder || selectedOrderDriver) ? (selectedOrder == orderId || selectedOrderDriver == orderDriverId) : true) != orderIsSelected) {
      SetOrderIsSelected((selectedOrder || selectedOrderDriver) ? (selectedOrder == orderId || selectedOrderDriver == orderDriverId) : true)
    }
    if ((OrderPinPopUp == id) != orderPopUp) {
      SetOrderPopUp(OrderPinPopUp == id)
    }
  }, [selectedOrder, selectedOrderDriver,OrderPinPopUp])


  return { orderIsSelected, orderPopUp };
};





*/
