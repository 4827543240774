import axios from "axios";
import packageJson from "../../../package.json";
import { get_accessToken, get_id, get_lang, get_refreshToken, get_settings_dispatcher, set_accessToken, set_adminToken } from "../../helper/UserData/userdate";
import { store } from "../../Redux/store";
import { LoginApi } from "./login";
// import { Service } from 'axios-middleware';



const restClient = axios.create({
  baseURL: global.config.BaseApi,
  timeout: 30000,
  headers: {
    "Accept-Language": get_lang(),
  }
});

const dashboardRest = axios.create({
  baseURL: global.config.BaseTrack,
  timeout: 30000,
  headers: {
    "Accept-Language": get_lang(),
  }
});


restClient.defaults.headers.common["retry"] = 2;
restClient.defaults.headers.common["retryDelay"] = 3000;
restClient.defaults.headers.common["retryCount"] = 0;
restClient.defaults.headers.common["X-Api-Source"] = 'web';
restClient.defaults.headers.common["X-App-Version-Code"] = packageJson.version;
restClient.defaults.headers.common["X-App-Version-Name"] = packageJson.version;

restClient.defaults.headers.common['Cache-Control'] = 'no-cache';
restClient.defaults.headers.common['Pragma'] = 'no-cache';
restClient.defaults.headers.common['Expires'] = '0';

restClient.interceptors.request.use(
  (config) => {
    const token = "Bearer " + get_accessToken()
    if (token) {
      // config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
      config.headers["Authorization"] = token; // for Node.js Express back-end
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

restClient.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    
    const originalConfig = err.config;


    if (err.response && err.response.status === 401 && err.response.data.code == "-9") {
            // Access Token was expired
            if (!originalConfig._retry) {
              originalConfig._retry = true;
      
              try {
                const rs = await restClient.post("/api/v1/dispatcher/auth/token", {
                  refreshToken: get_refreshToken(),
                  userId: get_id(),
                });
      
                const { adminToken } = rs.data.data;
                const { accessToken } = rs.data.data;

                set_accessToken(accessToken);
                set_adminToken(adminToken);

                if(get_settings_dispatcher()?.role?.haveAdminAccess){
                  const loginApi = new LoginApi();                    
                  const Dashboardlogin = await loginApi.Dashboardlogin(adminToken);
                }

                return restClient(originalConfig);
              } catch (_error) {
                return Promise.reject(_error);
              }
            }
    }else{
      if(err.response){
         store.dispatch({
        type: "addErrorCatch",
        state :{name:`Api ${err.response.config.url} ${err.response.status}` , text:err.response.data.message , body :err.response.config.params},
      })
      }
     
    }



    

    return Promise.reject(err);
  }
);


export {restClient , dashboardRest}