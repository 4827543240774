import { RefreshToken } from "../../helper/api";
import { get_id, get_refreshToken, set_name, set_username } from "../../helper/UserData/userdate";
import { restClient } from "./axios";
const queryString = require('query-string');

export class ServicesApi {

 
  async Services(params) {
    try {

      const response = await restClient.request({
        method: 'get',
        url: '/api/v1/dispatcher/service',
        params: params,
      });

      return {
        "Services": response.data.data
      }

    } catch (error) {
      if (error.response && error.response.status === 403) {
        localStorage.clear()
        window.location.reload();
      } else if (error.response) {
        return Promise.reject({
          code: error.response.data.code,
          message: error.response.data.message,
          status: error.response.status,
        })
      } else {
        return Promise.reject()
      }
    }


  }


  async VehicleType(params) {
    try {

      const response = await restClient.request({
        method: 'get',
        url: '/api/v1/dispatcher/service/vehicle-type',
        params: params,
      });

      return {
        "VehicleTypes": response.data.data
      }

    } catch (error) {
      if (error.response && error.response.status === 403) {
        localStorage.clear()
        window.location.reload();
      } else if (error.response) {
        return Promise.reject({
          code: error.response.data.code,
          message: error.response.data.message,
          status: error.response.status,
        })
      } else {
        return Promise.reject()
      }
    }


  }

  async Zone(params) {
    try {

      const response = await restClient.request({
        method: 'get',
        url: '/api/v1/dispatcher/zone'
      });

      return {
        "Zones": response.data.data
      }

    } catch (error) {
      if (error.response && error.response.status === 403) {
        localStorage.clear()
        window.location.reload();
      } else if (error.response) {
        return Promise.reject({
          code: error.response.data.code,
          message: error.response.data.message,
          status: error.response.status,
        })
      } else {
        return Promise.reject()
      }
    }


  }

}





