import React from "react";
import { connect } from "react-redux";
var _ = require("lodash");




class DriverOrdersPinBounding extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };

  }

  componentWillReceiveProps(nextProps) {
    

try {
    if ( nextProps.ordersBoundingList.length > 0) {
      let mapRef = nextProps.mapRef;
      const bounds = new window.google.maps.LatLngBounds();
        
        if(nextProps.ordersBoundingDriver && nextProps.ordersBoundingDriver.location){
          bounds.extend(new window.google.maps.LatLng(nextProps.ordersBoundingDriver.location[1], nextProps.ordersBoundingDriver.location[0]));
        }
        nextProps.ordersBoundingList.forEach((order) => {
          if (order.pickup) {
            bounds.extend(new window.google.maps.LatLng(order.pickup.coordinates[1], order.pickup.coordinates[0]));
  
          }
          if (order.dropoff) {
            bounds.extend(new window.google.maps.LatLng(order.dropoff.coordinates[1], order.dropoff.coordinates[0]));
  
          }
          if (order.dropoffs) {
            order.dropoffs.forEach((drops, ii) => {
              bounds.extend(new window.google.maps.LatLng(drops.coordinates[1], drops.coordinates[0]));
            })
          }
        })
        
  
        mapRef.fitBounds(bounds);
  
  
      }
} catch (error) {
  
}
  }

  componentDidMount() {
    


  if ( this.props.ordersBoundingList.length > 0 ) {
    let mapRef = this.props.mapRef;
    const bounds = new window.google.maps.LatLngBounds();
    
    if(this.props.ordersBoundingDriver && this.props.ordersBoundingDriver.location){
      bounds.extend(new window.google.maps.LatLng(this.props.ordersBoundingDriver.location[1], this.props.ordersBoundingDriver.location[0]));
    }

      this.props.ordersBoundingList.forEach((order) => {
        if (order.pickup) {
          bounds.extend(new window.google.maps.LatLng(order.pickup.coordinates[1], order.pickup.coordinates[0]));

        }
        if (order.dropoff) {
          bounds.extend(new window.google.maps.LatLng(order.dropoff.coordinates[1], order.dropoff.coordinates[0]));

        }
        if (order.dropoffs) {
          order.dropoffs.forEach((drops, ii) => {
            bounds.extend(new window.google.maps.LatLng(drops.coordinates[1], drops.coordinates[0]));
          })
        }
      })
      

      mapRef.fitBounds(bounds);


    }

  }



  boundsGenerator = () => {
    
  };


 

  render() {

    return (
      null
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  mapRef: state.Map.mapRef,
  MonitoringOrdersOpen: state.Monitoring.orders.open,
  MonitoringDriverSelected: state.Monitoring.selectedOrderDriver,
  ordersBoundingList: state.Monitoring.ordersBoundingList,
  ordersBoundingDriver: state.Monitoring.ordersBoundingDriver,
});
const mapDispatchToProps = (dispatch) => ({
  dispatch: dispatch,
});



export default connect(mapStateToProps, mapDispatchToProps)(DriverOrdersPinBounding)